import React, { useEffect, useState } from 'react';
import { Button, Modal, Nav } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import { database, useAuth } from '../firebase';
import { nextStage } from '../utils/Stages';
import next from '../assets/next.png'
import { Player } from '@lottiefiles/react-lottie-player';
import refresh from '../assets/refresh.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import nightday from '../assets/lottie/nightday.json'
import { fixPlayer } from '../utils/LottiePlayer';




export interface IVideoCallProps { }

const VideoCall: React.FunctionComponent<IVideoCallProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const currentUser = useAuth();

    const getUser = async () => {
        if (currentUser != null) {
            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            setUser(document.data());
        }
    }

    useEffect(
        () => {
            getUser()
        }, [currentUser]
    );

    const [user, setUser] = useState<any>();

    useEffect(() => {
        if (user?.professions_top?.length == 3) {
            setActive(1);
        }

    }, [user])


    const [show, setShow] = useState(false);

    // const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleCloseAndSendData = async () => {
        try {
            setIsLoading(true);
            await nextStage(doc(database, 'users', currentUser.uid));
            setShow(false);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const [active, setActive] = React.useState(0);


    // const professions_top = user?.professions_top;


    const player = React.useRef<Player>(null);

    const waitDude = async () => {//Attendi che il coach inserisca le 3 professioni consigliate per te

        try {
            setIsLoading(true);

            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            setUser(document.data());

            if (document.data()?.professions_top?.length == 3) {
                // window.location.reload();
            } else {
                toast('Attendi che il formatore inserisca le 3 professioni consigliate per te', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }

    }


    return (<div className='videoCall'>
        {/* <div style={{ width: '100%', backgroundColor: 'orange', padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
            <Button id="en" variant="light" onClick={handleShow}>
                Hai finito di fare il video!!!!!
            </Button>
            <div>{currentUser?.email}</div>
            <div className='colorWhite'>
                <h1>{STEP_NAME} =&gt; {STEP_NUMBER} </h1>
            </div >
        </div> */}
        <Nav className='row navStepper'>
            <div className='col-4 colorPurple flexLeft titleStep'>
                <b>
                    {/* Call Coach */}
                </b>
            </div>
            <div className='col-4 colorBlue textAlignCenter'>
            </div>
            <div className='col-4 textAlignRight my-1'>

                {
                    active ?

                        <Button id="endVideoButton" className="purpleButt customButt" onClick={handleCloseAndSendData}
                            disabled={isLoading}>
                            {
                                isLoading
                                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    : (
                                        <div className='flexButt'>
                                            <img src={next} alt="" />
                                            <div className='saveAndGo flexCenter'>
                                                <div>PROSEGUI</div>
                                            </div>
                                        </div>)
                            }
                        </Button>

                        :
                        <Button id="waitButt" className="whitePurpleButt customButt" onClick={waitDude}
                            disabled={isLoading}>
                            {
                                isLoading
                                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    : (<div className='flexButt'>
                                        <img src={refresh} alt="" />
                                        <div className='saveAndGo flexCenter'>
                                            <div>RICARICA</div>
                                        </div>
                                    </div>)
                            }

                        </Button>

                }

            </div>
        </Nav>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='textAlignCenter width100 colorBlue'>
                    Concludi l'Incontro
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='textAlignJustify'>
                Hai terminato la seduta con il formatore. <br /> Adesso puoi proseguire con l'Advisory Model!
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancella
                </Button>
                <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendData}>
                    Prosegui
                </Button>
            </Modal.Footer>
        </Modal>
        <h1>La tua sessione con il formatore</h1>

        {/* <div className='framePlaceholder textAlignCenter'>
            <img src={placeHolder} alt="" />
        </div> */}
        <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ marginTop: '5vh' }}
        />


        <Player
            autoplay
            loop
            src={nightday}
            style={{ maxHeight: '550px', maxWidth: '550px' }}
            ref={player}
            onEvent={(e) => { if (e === 'load') { fixPlayer(player); } }}
        >
        </Player>

        <p className='textAlignCenter'>
            Quando avrai terminato il colloquio con il formatore, ti verranno assegnate le tre professioni più affini al tuo profilo e protrai proseguire con il processo.
        </p>

        {/* <div className="textAlignRight mb-3">
            <Button id="endQuizButton" className="purpleButt" onClick={handleCloseAndSendData}>
                <div className='flexButt'>
                    <img src={floppy} alt="" />
                    <div className='saveAndGo'>
                        <div>SALVA</div>
                        <div>E PROSEGUI</div>
                    </div>
                </div>
            </Button>
        </div>
        */}

    </div>);
};

export default VideoCall;