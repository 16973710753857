import React, { useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import thanks from '../assets/lottie/thankconfetti.json'
import { fixPlayer } from '../utils/LottiePlayer';

export interface IThankYouProps { }

const ThankYou: React.FunctionComponent<IThankYouProps> = (props) => {

    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const player = React.useRef<Player>(null);
    return (<div className='ThankYou containerPages textAlignCenter'>

        {/* <h1 className='my-5'>Fine!</h1> */}

        <div className="lottieFrame">

            <Player
                autoplay
                loop
                src={thanks}
                style={{ maxHeight: '450px', maxWidth: '450px' }}
                ref={player}
                onEvent={(e) => { if (e === 'load') { fixPlayer(player); } }}
            >
            </Player>

        </div>

        {/* <div><b>Grazie!</b></div> */}
        <div>Team Habacus</div>

    </div>
    );
};

export default ThankYou;