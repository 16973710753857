export const professions = [
    { "value": "Accordatore di pianoforte", "label": "Accordatore di pianoforte" },
    { "value": "Acquacoltore(trice)", "label": "Acquacoltore(trice)" },
    { "value": "Addestratore(trice) di animali", "label": "Addestratore(trice) di animali" },
    { "value": "Addestratore(trice) di cani", "label": "Addestratore(trice) di cani" },
    { "value": "Addestratore(trice) di cavalli", "label": "Addestratore(trice) di cavalli" },
    { "value": "Addetto alle prenotazioni", "label": "Addetto alle prenotazioni" },
    { "value": "Addetto alle pubbliche relazioni", "label": "Addetto alle pubbliche relazioni" },
    { "value": "Addetto(a) al collaudo", "label": "Addetto(a) al collaudo" },
    { "value": "Addetto(a) al primo soccorso", "label": "Addetto(a) al primo soccorso" },
    {
        "value": "Addetto(a) all'accoglienza", "label": "Addetto(a) all'accoglienza"
    },
    { "value": "Addetto(a) alla Farmacovigilanza", "label": "Addetto(a) alla Farmacovigilanza" },
    { "value": "Addetto(a) alla manutenzione di edifici", "label": "Addetto(a) alla manutenzione di edifici" },
    { "value": "Addetto(a) allo sviluppo delle energie rinnovabili", "label": "Addetto(a) allo sviluppo delle energie rinnovabili" },
    { "value": "Aerodinamicista", "label": "Aerodinamicista" },
    { "value": "Agente amministrativo(a) (servizi pubblici)", "label": "Agente amministrativo(a) (servizi pubblici)" },
    { "value": "Agente artistico(a)", "label": "Agente artistico(a)" },
    { "value": "Agente di cambio", "label": "Agente di cambio" },
    { "value": "Agente di commercio", "label": "Agente di commercio" },
    { "value": "Agente sportivo", "label": "Agente sportivo" },
    { "value": "Agopuntore(trice)", "label": "Agopuntore(trice)" },
    { "value": "Agricoltore(trice)", "label": "Agricoltore(trice)" },
    { "value": "Agricoltore(trice) bio", "label": "Agricoltore(trice) bio" },
    { "value": "Agrimensore", "label": "Agrimensore" },
    { "value": "Agronomo(a)", "label": "Agronomo(a)" },
    { "value": "Aiuto regista e Supervisore della Continuità", "label": "Aiuto regista e Supervisore della Continuità" },
    { "value": "Aiuto-regista", "label": "Aiuto-regista" },
    { "value": "Allenatore sportivo", "label": "Allenatore sportivo" },
    { "value": "Allevatore(trice)", "label": "Allevatore(trice)" },
    { "value": "Allevatore(trice) di cani", "label": "Allevatore(trice) di cani" },
    { "value": "Allevatore(trice) di cavalli", "label": "Allevatore(trice) di cavalli" },
    { "value": "Allevatore(trice) di insetti commestibili", "label": "Allevatore(trice) di insetti commestibili" },
    { "value": "Amministratore delegato PMI", "label": "Amministratore delegato PMI" },
    { "value": "Amministratore(trice) di condominio", "label": "Amministratore(trice) di condominio" },
    { "value": "Amministratore(trice) di database", "label": "Amministratore(trice) di database" },
    { "value": "Amministratore(trice) di progetti umanitari", "label": "Amministratore(trice) di progetti umanitari" },
    { "value": "Amministratore(trice) di rete", "label": "Amministratore(trice) di rete" },
    { "value": "Amministratore(trice) giudiziario", "label": "Amministratore(trice) giudiziario" },
    {
        "value": "Amministratore(trice) nell'ambito dello spettacolo", "label": "Amministratore(trice) nell'ambito dello spettacolo"
    },
    { "value": "Analista dei rischi", "label": "Analista dei rischi" },
    { "value": "Analista del credito", "label": "Analista del credito" },
    { "value": "Analista di business intelligence", "label": "Analista di business intelligence" },
    { "value": "Analista di sistema", "label": "Analista di sistema" },
    { "value": "Analista finanziario(a)", "label": "Analista finanziario(a)" },
    { "value": "Analista forense", "label": "Analista forense" },
    { "value": "Analista in private equity", "label": "Analista in private equity" },
    { "value": "Analista quantitativo/Quantitative analyst", "label": "Analista quantitativo/Quantitative analyst" },
    { "value": "Analista SOC (Security Operation Center)", "label": "Analista SOC (Security Operation Center)" },
    { "value": "Anestesista", "label": "Anestesista" },
    { "value": "Animatore(trice) 2D e 3D", "label": "Animatore(trice) 2D e 3D" },
    { "value": "Animatore(trice) socioculturale", "label": "Animatore(trice) socioculturale" },
    { "value": "Apicultore(trice)", "label": "Apicultore(trice)" },
    { "value": "Arbitro", "label": "Arbitro" },
    { "value": "Archeologo(a)", "label": "Archeologo(a)" },
    {
        "value": "Architetto d'interni", "label": "Architetto d'interni"
    },
    {
        "value": "Architetto dell'informazione", "label": "Architetto dell'informazione"
    },
    { "value": "Architetto di sistemi informatici", "label": "Architetto di sistemi informatici" },
    { "value": "Architetto Web", "label": "Architetto Web" },
    { "value": "Architetto(a)", "label": "Architetto(a)" },
    { "value": "Architetto(a) di rete", "label": "Architetto(a) di rete" },
    { "value": "Architetto(a) navale", "label": "Architetto(a) navale" },
    { "value": "Armaiolo(a)", "label": "Armaiolo(a)" },
    {
        "value": "Arredatore(trice) d'interni", "label": "Arredatore(trice) d'interni"
    },
    { "value": "Arteterapeuta", "label": "Arteterapeuta" },
    { "value": "Artista circense", "label": "Artista circense" },
    { "value": "Artista di belle arti", "label": "Artista di belle arti" },
    { "value": "Assistente di infanzia", "label": "Assistente di infanzia" },
    { "value": "Assistente domiciliare", "label": "Assistente domiciliare" },
    { "value": "Assistente educativo(a)", "label": "Assistente educativo(a)" },
    { "value": "Assistente esecutivo", "label": "Assistente esecutivo" },
    { "value": "Assistente farmacista", "label": "Assistente farmacista" },
    { "value": "Assistente giuridico", "label": "Assistente giuridico" },
    { "value": "Assistente legale", "label": "Assistente legale" },
    { "value": "Assistente medico-psichiatrico", "label": "Assistente medico-psichiatrico" },
    { "value": "Assistente sanitario domiciliare", "label": "Assistente sanitario domiciliare" },
    { "value": "Assistente servizi sociali e umani", "label": "Assistente servizi sociali e umani" },
    { "value": "Astrofisico(a)", "label": "Astrofisico(a)" },
    { "value": "Astronomo", "label": "Astronomo" },
    { "value": "Atleta professionista", "label": "Atleta professionista" },
    { "value": "Attore(trice)", "label": "Attore(trice)" },
    { "value": "Attuario(a)", "label": "Attuario(a)" },
    { "value": "Auditor energetico", "label": "Auditor energetico" },
    { "value": "Autore(trice) Compositore(trice) Interprete", "label": "Autore(trice) Compositore(trice) Interprete" },
    { "value": "Autore(trice) di contenuti Web", "label": "Autore(trice) di contenuti Web" },
    { "value": "Autore(trice) guide turistiche", "label": "Autore(trice) guide turistiche" },
    { "value": "Avicoltore(trice)", "label": "Avicoltore(trice)" },
    { "value": "Avvocato aziendale", "label": "Avvocato aziendale" },
    {
        "value": "Avvocato d'affari", "label": "Avvocato d'affari"
    },
    { "value": "Avvocato esperto in diritto informatico", "label": "Avvocato esperto in diritto informatico" },
    { "value": "Avvocato(essa)", "label": "Avvocato(essa)" },
    { "value": "Ballerino(a)", "label": "Ballerino(a)" },
    { "value": "Bancario", "label": "Bancario" },
    { "value": "Barista", "label": "Barista" },
    { "value": "Bartender", "label": "Bartender" },
    { "value": "Big Data architect", "label": "Big Data architect" },
    { "value": "Bioinformatico", "label": "Bioinformatico" },
    { "value": "Biologo(a) ambientale", "label": "Biologo(a) ambientale" },
    { "value": "Biologo(a) marino(a)", "label": "Biologo(a) marino(a)" },
    { "value": "Biostatistico(a)", "label": "Biostatistico(a)" },
    { "value": "Birraio-Maltatore", "label": "Birraio-Maltatore" },
    { "value": "Botanista", "label": "Botanista" },
    { "value": "Bozzettista", "label": "Bozzettista" },
    { "value": "Brand manager", "label": "Brand manager" },
    { "value": "Broker", "label": "Broker" },
    { "value": "Bronzista", "label": "Bronzista" },
    { "value": "Business Analyst", "label": "Business Analyst" },
    { "value": "Business Coach", "label": "Business Coach" },
    { "value": "Business Developer", "label": "Business Developer" },
    { "value": "Cablatore", "label": "Cablatore" },
    { "value": "Cacciatore di teste", "label": "Cacciatore di teste" },
    { "value": "Calzolaio(a)", "label": "Calzolaio(a)" },
    { "value": "Cameraman/woman", "label": "Cameraman/woman" },
    { "value": "Cameriere(a)", "label": "Cameriere(a)" },
    { "value": "Camionista", "label": "Camionista" },
    { "value": "Cancelliere(a)", "label": "Cancelliere(a)" },
    { "value": "Cantiniere", "label": "Cantiniere" },
    { "value": "Capitano di nave", "label": "Capitano di nave" },
    { "value": "Capo cantiere", "label": "Capo cantiere" },
    { "value": "Capo della pubblicità", "label": "Capo della pubblicità" },
    { "value": "Capo ufficio tecnico", "label": "Capo ufficio tecnico" },
    { "value": "Capo(a) contabilità", "label": "Capo(a) contabilità" },
    { "value": "Capo(a) di Gabinetto", "label": "Capo(a) di Gabinetto" },
    { "value": "Carrozziere", "label": "Carrozziere" },
    { "value": "Cartografo", "label": "Cartografo" },
    { "value": "Cascatore", "label": "Cascatore" },
    { "value": "Ceramista", "label": "Ceramista" },
    { "value": "Character designer", "label": "Character designer" },
    { "value": "Chartering manager", "label": "Chartering manager" },
    { "value": "Chef", "label": "Chef" },
    { "value": "Chief data officer", "label": "Chief data officer" },
    { "value": "Chief Digital Officer", "label": "Chief Digital Officer" },
    { "value": "Chief happiness Officer", "label": "Chief happiness Officer" },
    { "value": "Chiropratico(a)", "label": "Chiropratico(a)" },
    { "value": "Chirurgo(a)", "label": "Chirurgo(a)" },
    { "value": "Chirurgo(a) plastico(a)", "label": "Chirurgo(a) plastico(a)" },
    { "value": "Ciabattino(a)", "label": "Ciabattino(a)" },
    { "value": "Cioccolatiere", "label": "Cioccolatiere" },
    { "value": "Climatologo", "label": "Climatologo" },
    { "value": "Clinical trial assistant (CTA)", "label": "Clinical trial assistant (CTA)" },
    { "value": "Coltellinaio(a)", "label": "Coltellinaio(a)" },
    { "value": "Commerciale estero", "label": "Commerciale estero" },
    { "value": "Commercialista", "label": "Commercialista" },
    { "value": "Commissario di bordo", "label": "Commissario di bordo" },
    { "value": "Commissario di polizia", "label": "Commissario di polizia" },
    { "value": "Comportamentista cinofilo(a)", "label": "Comportamentista cinofilo(a)" },
    {
        "value": "Concierge d'albergo", "label": "Concierge d'albergo"
    },
    { "value": "Conducente di tram", "label": "Conducente di tram" },
    { "value": "Conduttore televisivo o radiofonico", "label": "Conduttore televisivo o radiofonico" },
    { "value": "Consigliere ambientale", "label": "Consigliere ambientale" },
    {
        "value": "Consigliere per l'agricoltura sostenibile", "label": "Consigliere per l'agricoltura sostenibile"
    },
    { "value": "Consulente aziendale", "label": "Consulente aziendale" },
    { "value": "Consulente creditizio(a)", "label": "Consulente creditizio(a)" },
    {
        "value": "Consulente d'immagine", "label": "Consulente d'immagine"
    },
    { "value": "Consulente di call center", "label": "Consulente di call center" },
    { "value": "Consulente di carriera", "label": "Consulente di carriera" },
    { "value": "Consulente di formazione", "label": "Consulente di formazione" },
    { "value": "Consulente di mobilità ecocompatibile", "label": "Consulente di mobilità ecocompatibile" },
    { "value": "Consulente e-business", "label": "Consulente e-business" },
    { "value": "Consulente energetico", "label": "Consulente energetico" },
    { "value": "Consulente finanziario", "label": "Consulente finanziario" },
    { "value": "Consulente genetico", "label": "Consulente genetico" },
    { "value": "Consulente in Business Intelligence", "label": "Consulente in Business Intelligence" },
    { "value": "Consulente in fusioni e acquisizioni", "label": "Consulente in fusioni e acquisizioni" },
    {
        "value": "Consulente in gestione dell'innovazione", "label": "Consulente in gestione dell'innovazione"
    },
    { "value": "Consulente in Risorse Umane", "label": "Consulente in Risorse Umane" },
    { "value": "Consulente in trasformazione digitale", "label": "Consulente in trasformazione digitale" },
    { "value": "Consulente informatico", "label": "Consulente informatico" },
    { "value": "Consulente matrimoniale e familiare", "label": "Consulente matrimoniale e familiare" },
    { "value": "Consulente per lo sviluppo sostenibile", "label": "Consulente per lo sviluppo sostenibile" },
    { "value": "Consulente scolastico", "label": "Consulente scolastico" },
    { "value": "Consulente SMO (Social Media Optimization)", "label": "Consulente SMO (Social Media Optimization)" },
    { "value": "Consulente zootecnico", "label": "Consulente zootecnico" },
    { "value": "Content manager", "label": "Content manager" },
    { "value": "Controller", "label": "Controller" },
    { "value": "Controllore di volo", "label": "Controllore di volo" },
    { "value": "Controllore(ora) qualità", "label": "Controllore(ora) qualità" },
    { "value": "Coordinatore di eventi culturali", "label": "Coordinatore di eventi culturali" },
    { "value": "Coordinatore(trice) di eventi e-Sport", "label": "Coordinatore(trice) di eventi e-Sport" },
    { "value": "Copywriter pubblicitario", "label": "Copywriter pubblicitario" },
    { "value": "Coreografo", "label": "Coreografo" },
    { "value": "Corniciaio", "label": "Corniciaio" },
    { "value": "Coroner", "label": "Coroner" },
    { "value": "Correttore(trice) di bozze", "label": "Correttore(trice) di bozze" },
    { "value": "Corrispondente speciale", "label": "Corrispondente speciale" },
    { "value": "Costruttore(trice) di strumenti musicali", "label": "Costruttore(trice) di strumenti musicali" },
    { "value": "Costumista", "label": "Costumista" },
    { "value": "Credit manager", "label": "Credit manager" },
    { "value": "Criminologo(a)", "label": "Criminologo(a)" },
    { "value": "Crittografo", "label": "Crittografo" },
    { "value": "Croupier", "label": "Croupier" },
    { "value": "Cuoco(a)", "label": "Cuoco(a)" },
    { "value": "Curatore fallimentare", "label": "Curatore fallimentare" },
    { "value": "Data analyst", "label": "Data analyst" },
    { "value": "Data manager", "label": "Data manager" },
    { "value": "Data protection officer", "label": "Data protection officer" },
    { "value": "Data scientist", "label": "Data scientist" },
    { "value": "Decoratore(trice) floreale", "label": "Decoratore(trice) floreale" },
    { "value": "Demografo(a)", "label": "Demografo(a)" },
    { "value": "Dermatologo(a)", "label": "Dermatologo(a)" },
    { "value": "Designer di automobili", "label": "Designer di automobili" },
    { "value": "Designer di videogiochi", "label": "Designer di videogiochi" },
    { "value": "Designer industriale", "label": "Designer industriale" },
    { "value": "Designer occhiali", "label": "Designer occhiali" },
    { "value": "Designer tessile", "label": "Designer tessile" },
    { "value": "Dietologo(a)", "label": "Dietologo(a)" },
    { "value": "Digital Business Developer", "label": "Digital Business Developer" },
    { "value": "Digital planner", "label": "Digital planner" },
    { "value": "Digital Product manager", "label": "Digital Product manager" },
    { "value": "Diplomatico", "label": "Diplomatico" },
    { "value": "Direttore dei lavori", "label": "Direttore dei lavori" },
    {
        "value": "Direttore dell'educazione", "label": "Direttore dell'educazione"
    },
    { "value": "Direttore delle operazioni portuali", "label": "Direttore delle operazioni portuali" },
    { "value": "Direttore delle ricerche di marketing", "label": "Direttore delle ricerche di marketing" },
    { "value": "Direttore delle vendite", "label": "Direttore delle vendite" },
    { "value": "Direttore di parco divertimenti", "label": "Direttore di parco divertimenti" },
    { "value": "Direttore di zoo", "label": "Direttore di zoo" },
    { "value": "Direttore reparto vendita al dettaglio", "label": "Direttore reparto vendita al dettaglio" },
    { "value": "Direttore vendite riciclaggio", "label": "Direttore vendite riciclaggio" },
    { "value": "Direttore(trice) amministrativo e finanziario", "label": "Direttore(trice) amministrativo e finanziario" },
    { "value": "Direttore(trice) artistico (pubblicità)", "label": "Direttore(trice) artistico (pubblicità)" },
    { "value": "Direttore(trice) commerciale", "label": "Direttore(trice) commerciale" },
    { "value": "Direttore(trice) degli investimenti", "label": "Direttore(trice) degli investimenti" },
    { "value": "Direttore(trice) dei lavori pubblici", "label": "Direttore(trice) dei lavori pubblici" },
    { "value": "Direttore(trice) dei sistemi informatici (CIO)", "label": "Direttore(trice) dei sistemi informatici (CIO)" },
    { "value": "Direttore(trice) della comunicazione", "label": "Direttore(trice) della comunicazione" },
    { "value": "Direttore(trice) della creazione", "label": "Direttore(trice) della creazione" },
    { "value": "Direttore(trice) della fotografia", "label": "Direttore(trice) della fotografia" },
    { "value": "Direttore(trice) di albergo", "label": "Direttore(trice) di albergo" },
    { "value": "Direttore(trice) di biblioteca", "label": "Direttore(trice) di biblioteca" },
    { "value": "Direttore(trice) di centro penitenziario", "label": "Direttore(trice) di centro penitenziario" },
    { "value": "Direttore(trice) di filiale bancaria", "label": "Direttore(trice) di filiale bancaria" },
    { "value": "Direttore(trice) di negozio", "label": "Direttore(trice) di negozio" },
    { "value": "Direttore(trice) di parco naturale", "label": "Direttore(trice) di parco naturale" },
    { "value": "Direttore(trice) di produzione artistica", "label": "Direttore(trice) di produzione artistica" },
    { "value": "Direttore(trice) di ristorante", "label": "Direttore(trice) di ristorante" },
    { "value": "Direttore(trice) di SPA", "label": "Direttore(trice) di SPA" },
    { "value": "Direttore(trice) di supermarket", "label": "Direttore(trice) di supermarket" },
    { "value": "Direttore(trice) marketing", "label": "Direttore(trice) marketing" },
    { "value": "Direttore(trice) Ricerca e Sviluppo", "label": "Direttore(trice) Ricerca e Sviluppo" },
    { "value": "Direttore(trice) sanitario(a)", "label": "Direttore(trice) sanitario(a)" },
    { "value": "Direttore(trice) tecnico(a)", "label": "Direttore(trice) tecnico(a)" },
    { "value": "Dirigente polizia municipale", "label": "Dirigente polizia municipale" },
    { "value": "Dirigente scolastico(a)", "label": "Dirigente scolastico(a)" },
    { "value": "Disc-Jockey (DJ)", "label": "Disc-Jockey (DJ)" },
    { "value": "Disegnatore(trice)-progettista", "label": "Disegnatore(trice)-progettista" },
    { "value": "Docente-Ricercatore(trice)", "label": "Docente-Ricercatore(trice)" },
    { "value": "Documentalista", "label": "Documentalista" },
    { "value": "Doula", "label": "Doula" },
    { "value": "Dropshipping", "label": "Dropshipping" },
    { "value": "Ebanista", "label": "Ebanista" },
    { "value": "Eco-progettista", "label": "Eco-progettista" },
    {
        "value": "Economista d'impresa", "label": "Economista d'impresa"
    },
    { "value": "Economo", "label": "Economo" },
    { "value": "Ecotossicologo", "label": "Ecotossicologo" },
    { "value": "Editore(trice)", "label": "Editore(trice)" },
    { "value": "Educatore(trice) di comunità infantile", "label": "Educatore(trice) di comunità infantile" },
    { "value": "Educatore(trice) sociale", "label": "Educatore(trice) sociale" },
    { "value": "Educatore(trice) sportivo(a)", "label": "Educatore(trice) sportivo(a)" },
    { "value": "Elettricista industriale", "label": "Elettricista industriale" },
    { "value": "Elettricista Installatore", "label": "Elettricista Installatore" },
    { "value": "Elettrotecnico(a)", "label": "Elettrotecnico(a)" },
    { "value": "Energy broker", "label": "Energy broker" },
    { "value": "Enologo(a)", "label": "Enologo(a)" },
    { "value": "Erettore acciaio", "label": "Erettore acciaio" },
    { "value": "Ergonomista Web", "label": "Ergonomista Web" },
    { "value": "Ergonomo(a)", "label": "Ergonomo(a)" },
    { "value": "Ergoterapista", "label": "Ergoterapista" },
    { "value": "Esaminatore di guida", "label": "Esaminatore di guida" },
    { "value": "Esperto contabile", "label": "Esperto contabile" },
    { "value": "Esperto di diritto del lavoro", "label": "Esperto di diritto del lavoro" },
    {
        "value": "Esperto di diritto dell'ambiente", "label": "Esperto di diritto dell'ambiente"
    },
    { "value": "Esperto di etiopatia", "label": "Esperto di etiopatia" },
    { "value": "Esperto di psicologia della salute occupazionale", "label": "Esperto di psicologia della salute occupazionale" },
    { "value": "Esperto di tecniche di stampa 3D", "label": "Esperto di tecniche di stampa 3D" },
    { "value": "Esperto di tecnologia creativa", "label": "Esperto di tecnologia creativa" },
    { "value": "Esperto in diagnostica dei beni culturali", "label": "Esperto in diagnostica dei beni culturali" },
    { "value": "Esperto in diritto della proprietà intellettuale", "label": "Esperto in diritto della proprietà intellettuale" },
    { "value": "Esperto in psicologia ambientale", "label": "Esperto in psicologia ambientale" },
    { "value": "Esperto legale di Cybersecurity", "label": "Esperto legale di Cybersecurity" },
    { "value": "Esperto(a) di logistica umanitaria", "label": "Esperto(a) di logistica umanitaria" },
    { "value": "Esperto(a) di scienze forensi", "label": "Esperto(a) di scienze forensi" },
    { "value": "Esperto(a) in domotica", "label": "Esperto(a) in domotica" },
    { "value": "Esperto(a) in logistica", "label": "Esperto(a) in logistica" },
    { "value": "Esperto(a) in sicurezza informatica", "label": "Esperto(a) in sicurezza informatica" },
    { "value": "Espositore", "label": "Espositore" },
    { "value": "Estetista", "label": "Estetista" },
    { "value": "Etnologo(a)", "label": "Etnologo(a)" },
    { "value": "Etologo(a)", "label": "Etologo(a)" },
    { "value": "Exhibition designer (Progettista di allestimenti)", "label": "Exhibition designer (Progettista di allestimenti)" },
    { "value": "Fabbro", "label": "Fabbro" },
    { "value": "Facility manager", "label": "Facility manager" },
    { "value": "Farmacista", "label": "Farmacista" },
    { "value": "Farmacista industriale", "label": "Farmacista industriale" },
    { "value": "Farmacologo", "label": "Farmacologo" },
    { "value": "Feel-good manager", "label": "Feel-good manager" },
    { "value": "Fisioterapista", "label": "Fisioterapista" },
    { "value": "Fitochirurgo", "label": "Fitochirurgo" },
    { "value": "Fitoterapeuta", "label": "Fitoterapeuta" },
    { "value": "Flavorist", "label": "Flavorist" },
    { "value": "Formatore(trice) blended-learning", "label": "Formatore(trice) blended-learning" },
    { "value": "Formatore(trice) per adulti", "label": "Formatore(trice) per adulti" },
    { "value": "Formulatore(trice) cosmetico(a)", "label": "Formulatore(trice) cosmetico(a)" },
    { "value": "Fornitore di servizi editoriali", "label": "Fornitore di servizi editoriali" },
    { "value": "Fotografo(a)", "label": "Fotografo(a)" },
    { "value": "Fotografo(a) di moda", "label": "Fotografo(a) di moda" },
    { "value": "Fotografo(a) scientifico(a)", "label": "Fotografo(a) scientifico(a)" },
    { "value": "Fotoreporter", "label": "Fotoreporter" },
    { "value": "Fumettista", "label": "Fumettista" },
    { "value": "Fundraiser (responsabile della raccolta fondi)", "label": "Fundraiser (responsabile della raccolta fondi)" },
    { "value": "Funzionario per il turismo", "label": "Funzionario per il turismo" },
    { "value": "Gallerista", "label": "Gallerista" },
    { "value": "Game designer (sviluppatore)", "label": "Game designer (sviluppatore)" },
    { "value": "Gemmologo(a)", "label": "Gemmologo(a)" },
    { "value": "Genealogista", "label": "Genealogista" },
    { "value": "Geofisico", "label": "Geofisico" },
    { "value": "Geografo(a)", "label": "Geografo(a)" },
    { "value": "Geologo", "label": "Geologo" },
    { "value": "Geotecnico", "label": "Geotecnico" },
    { "value": "Gestore del rischio", "label": "Gestore del rischio" },
    { "value": "Gestore di beni immobiliari", "label": "Gestore di beni immobiliari" },
    { "value": "Gestore di patrimonio", "label": "Gestore di patrimonio" },
    { "value": "Gestore patrimoniale", "label": "Gestore patrimoniale" },
    { "value": "Gestore(trice) di mensa", "label": "Gestore(trice) di mensa" },
    { "value": "Gestore(trice) di portafoglio", "label": "Gestore(trice) di portafoglio" },
    { "value": "Ginecologo(a) Ostetrico(a)", "label": "Ginecologo(a) Ostetrico(a)" },
    {
        "value": "Giornalista / Critico(a) d'arte", "label": "Giornalista / Critico(a) d'arte"
    },
    { "value": "Giornalista economico(a)", "label": "Giornalista economico(a)" },
    { "value": "Giornalista finanziario(a)", "label": "Giornalista finanziario(a)" },
    { "value": "Giornalista politico(a)", "label": "Giornalista politico(a)" },
    { "value": "Giornalista scientifico(a)", "label": "Giornalista scientifico(a)" },
    { "value": "Giornalista sportivo(a)", "label": "Giornalista sportivo(a)" },
    { "value": "Giudice minorile", "label": "Giudice minorile" },
    {
        "value": "Governante d'albergo", "label": "Governante d'albergo"
    },
    { "value": "Grafico editoriale", "label": "Grafico editoriale" },
    { "value": "Grafico(a)", "label": "Grafico(a)" },
    { "value": "Grafologo", "label": "Grafologo" },
    { "value": "Greenkeeper", "label": "Greenkeeper" },
    { "value": "Griglista", "label": "Griglista" },
    { "value": "Growth Hacker", "label": "Growth Hacker" },
    { "value": "Gruista", "label": "Gruista" },
    { "value": "Guardia carceraria", "label": "Guardia carceraria" },
    { "value": "Guardia forestale", "label": "Guardia forestale" },
    { "value": "Guardia giurata", "label": "Guardia giurata" },
    { "value": "Guardiano(a) dello zoo", "label": "Guardiano(a) dello zoo" },
    { "value": "Guest relation Manager", "label": "Guest relation Manager" },
    { "value": "Guida alpina", "label": "Guida alpina" },
    { "value": "Guida ambientale", "label": "Guida ambientale" },
    { "value": "Guida turistica", "label": "Guida turistica" },
    { "value": "Guida turistica-interprete", "label": "Guida turistica-interprete" },
    { "value": "Home stager", "label": "Home stager" },
    { "value": "HR Data Analyst", "label": "HR Data Analyst" },
    { "value": "HR manager /Responsabile delle Risorse Umane", "label": "HR manager /Responsabile delle Risorse Umane" },
    { "value": "Idro-geologo(a)", "label": "Idro-geologo(a)" },
    { "value": "Idrologo", "label": "Idrologo" },
    { "value": "Illustratore(trice)", "label": "Illustratore(trice)" },
    { "value": "Impiantista", "label": "Impiantista" },
    { "value": "Impiegato assicurativo", "label": "Impiegato assicurativo" },
    { "value": "Impiegato(a) contabile", "label": "Impiegato(a) contabile" },
    { "value": "Impiegato(a) di parco ricreativo", "label": "Impiegato(a) di parco ricreativo" },
    { "value": "Imprenditore", "label": "Imprenditore" },
    { "value": "Impresario(a) di pompe funebre", "label": "Impresario(a) di pompe funebre" },
    { "value": "Incisore", "label": "Incisore" },
    { "value": "Infermiere(a) anestesista", "label": "Infermiere(a) anestesista" },
    { "value": "Infermiere(a) di famiglia", "label": "Infermiere(a) di famiglia" },
    { "value": "Infermiere(a) di pronto soccorso", "label": "Infermiere(a) di pronto soccorso" },
    { "value": "Infermiere(a) ospedaliero(a)", "label": "Infermiere(a) ospedaliero(a)" },
    { "value": "Infermiere(a) umanitario(a)", "label": "Infermiere(a) umanitario(a)" },
    { "value": "Infografico", "label": "Infografico" },
    { "value": "Ingegnere acustico", "label": "Ingegnere acustico" },
    { "value": "Ingegnere aeronautico(a)", "label": "Ingegnere aeronautico(a)" },
    { "value": "Ingegnere cartario", "label": "Ingegnere cartario" },
    { "value": "Ingegnere chimico", "label": "Ingegnere chimico" },
    { "value": "Ingegnere civile", "label": "Ingegnere civile" },
    { "value": "Ingegnere del suono", "label": "Ingegnere del suono" },
    { "value": "Ingegnere del traffico", "label": "Ingegnere del traffico" },
    {
        "value": "Ingegnere dell'automazione", "label": "Ingegnere dell'automazione"
    },
    { "value": "Ingegnere della conoscenza", "label": "Ingegnere della conoscenza" },
    { "value": "Ingegnere delle materie plastiche", "label": "Ingegnere delle materie plastiche" },
    { "value": "Ingegnere delle nanotecnologie", "label": "Ingegnere delle nanotecnologie" },
    { "value": "Ingegnere delle telecomunicazioni", "label": "Ingegnere delle telecomunicazioni" },
    { "value": "Ingegnere DevOps", "label": "Ingegnere DevOps" },
    { "value": "Ingegnere di cloud computing", "label": "Ingegnere di cloud computing" },
    { "value": "Ingegnere elettrico", "label": "Ingegnere elettrico" },
    { "value": "Ingegnere elettronico", "label": "Ingegnere elettronico" },
    { "value": "Ingegnere elettrotecnico", "label": "Ingegnere elettrotecnico" },
    { "value": "Ingegnere esperto in energia rinnovabile", "label": "Ingegnere esperto in energia rinnovabile" },
    { "value": "Ingegnere forestale", "label": "Ingegnere forestale" },
    { "value": "Ingegnere geotermico", "label": "Ingegnere geotermico" },
    { "value": "Ingegnere HVAC", "label": "Ingegnere HVAC" },
    { "value": "Ingegnere HVAC (impianti termici e climatizzazione)", "label": "Ingegnere HVAC (impianti termici e climatizzazione)" },
    { "value": "Ingegnere idraulico", "label": "Ingegnere idraulico" },
    { "value": "Ingegnere in ricerca e sviluppo (energie rinnovabili)", "label": "Ingegnere in ricerca e sviluppo (energie rinnovabili)" },
    { "value": "Ingegnere informatico(a)", "label": "Ingegnere informatico(a)" },
    { "value": "Ingegnere meccanico(a)", "label": "Ingegnere meccanico(a)" },
    { "value": "Ingegnere metrologo", "label": "Ingegnere metrologo" },
    { "value": "Ingegnere navale", "label": "Ingegnere navale" },
    { "value": "Ingegnere nucleare", "label": "Ingegnere nucleare" },
    { "value": "Ingegnere optoelettronico(a)", "label": "Ingegnere optoelettronico(a)" },
    { "value": "Ingegnere petrolifero", "label": "Ingegnere petrolifero" },
    { "value": "Ingegnere robotico", "label": "Ingegnere robotico" },
    { "value": "Ingegnere tecnico commerciale", "label": "Ingegnere tecnico commerciale" },
    { "value": "Ingegnere tessile", "label": "Ingegnere tessile" },
    { "value": "Innovation manager", "label": "Innovation manager" },
    {
        "value": "Insegnante d'arte", "label": "Insegnante d'arte"
    },
    { "value": "Insegnante di agraria", "label": "Insegnante di agraria" },
    { "value": "Insegnante di educazione fisica", "label": "Insegnante di educazione fisica" },
    { "value": "Insegnante di fitness", "label": "Insegnante di fitness" },
    { "value": "Insegnante di istituto professionale", "label": "Insegnante di istituto professionale" },
    { "value": "Insegnante di italiano L2", "label": "Insegnante di italiano L2" },
    { "value": "Insegnante di materie scientifiche", "label": "Insegnante di materie scientifiche" },
    { "value": "Insegnante di musica e danza", "label": "Insegnante di musica e danza" },
    { "value": "Insegnante di scuola secondaria", "label": "Insegnante di scuola secondaria" },
    { "value": "Insegnante di sostegno", "label": "Insegnante di sostegno" },
    { "value": "Insegnante di yoga", "label": "Insegnante di yoga" },
    { "value": "Insegnante online", "label": "Insegnante online" },
    { "value": "Insegnante umanitario(a)", "label": "Insegnante umanitario(a)" },
    {
        "value": "Inserviente d'albergo", "label": "Inserviente d'albergo"
    },
    { "value": "Installatore di pannelli solari", "label": "Installatore di pannelli solari" },
    { "value": "Installatore-Montatore", "label": "Installatore-Montatore" },
    { "value": "Installatore(trice) di reti elettrice", "label": "Installatore(trice) di reti elettrice" },
    { "value": "Installatore(trice) di sistemi di allarme antincendio e di sicurezza", "label": "Installatore(trice) di sistemi di allarme antincendio e di sicurezza" },
    { "value": "Installatore(trice) di telecomunicazioni", "label": "Installatore(trice) di telecomunicazioni" },
    { "value": "Intermediario assicurativo", "label": "Intermediario assicurativo" },
    { "value": "Interprete", "label": "Interprete" },
    { "value": "Interprete della lingua dei segni (LIS)", "label": "Interprete della lingua dei segni (LIS)" },
    { "value": "Intervistatore sul campo", "label": "Intervistatore sul campo" },
    { "value": "Intervistatore(trice) telefonico", "label": "Intervistatore(trice) telefonico" },
    { "value": "Investigatore privato", "label": "Investigatore privato" },
    { "value": "Ipnoterapista", "label": "Ipnoterapista" },
    { "value": "Ispettore centro di revisione veicoli", "label": "Ispettore centro di revisione veicoli" },
    { "value": "Ispettore del lavoro", "label": "Ispettore del lavoro" },
    { "value": "Ispettore del trasporto pubblico", "label": "Ispettore del trasporto pubblico" },
    { "value": "Ispettore(trice) commerciale assicuratico(a)", "label": "Ispettore(trice) commerciale assicuratico(a)" },
    { "value": "Ispettore(trice) del lavoro", "label": "Ispettore(trice) del lavoro" },
    { "value": "Ispettore(trice) di banca", "label": "Ispettore(trice) di banca" },
    { "value": "Istruttore informatico", "label": "Istruttore informatico" },
    { "value": "Istruttore(trice) di equitazione", "label": "Istruttore(trice) di equitazione" },
    { "value": "Istruttore(trice) di guida", "label": "Istruttore(trice) di guida" },
    { "value": "Istruttore(trice) di sci", "label": "Istruttore(trice) di sci" },
    { "value": "Istruttore/Educatore", "label": "Istruttore/Educatore" },
    { "value": "IT Manager", "label": "IT Manager" },
    { "value": "IT project manager", "label": "IT project manager" },
    { "value": "IT Tester", "label": "IT Tester" },
    { "value": "Juicer /Addetto alla preparazione di succhi", "label": "Juicer /Addetto alla preparazione di succhi" },
    { "value": "Knowledge manager", "label": "Knowledge manager" },
    { "value": "Lad-jockey, Lad-driver", "label": "Lad-jockey, Lad-driver" },
    { "value": "Levatrice", "label": "Levatrice" },
    { "value": "Libraio", "label": "Libraio" },
    { "value": "Life coach", "label": "Life coach" },
    { "value": "Linguista", "label": "Linguista" },
    { "value": "Liutaio(a)", "label": "Liutaio(a)" },
    { "value": "Lobbista", "label": "Lobbista" },
    { "value": "Macchinista", "label": "Macchinista" },
    { "value": "Macellaio(a)", "label": "Macellaio(a)" },
    { "value": "Maestro vetraio", "label": "Maestro vetraio" },
    { "value": "Maestro(a) di scuola elementare", "label": "Maestro(a) di scuola elementare" },
    { "value": "Magistrato", "label": "Magistrato" },
    { "value": "Manager culturale", "label": "Manager culturale" },
    { "value": "Manager della reputazione online / Online Reputation Manager", "label": "Manager della reputazione online / Online Reputation Manager" },
    { "value": "Mangaka", "label": "Mangaka" },
    { "value": "Manutentore aereonautico", "label": "Manutentore aereonautico" },
    { "value": "Manutentore attrezzature antincendio", "label": "Manutentore attrezzature antincendio" },
    { "value": "Manutentore meccanico", "label": "Manutentore meccanico" },
    { "value": "Marinaio della Marina", "label": "Marinaio della Marina" },
    { "value": "Marinaio mercantile", "label": "Marinaio mercantile" },
    { "value": "Mastro cantiniere", "label": "Mastro cantiniere" },
    { "value": "Matematico(a)", "label": "Matematico(a)" },
    { "value": "Meccanico addetto alla riparazione di macchinari agricoli", "label": "Meccanico addetto alla riparazione di macchinari agricoli" },
    { "value": "Meccanico affilatore", "label": "Meccanico affilatore" },
    { "value": "Meccanico di impianti di costruzione", "label": "Meccanico di impianti di costruzione" },
    { "value": "Meccanico motociclistico", "label": "Meccanico motociclistico" },
    { "value": "Media buyer", "label": "Media buyer" },
    { "value": "Media planner", "label": "Media planner" },
    { "value": "Mediatore(trice) civile", "label": "Mediatore(trice) civile" },
    { "value": "Mediatore(trice) culturale", "label": "Mediatore(trice) culturale" },
    { "value": "Mediatore(trice) familiare", "label": "Mediatore(trice) familiare" },
    { "value": "Medico del lavoro", "label": "Medico del lavoro" },
    { "value": "Medico del pronto soccorso / medico di guardia", "label": "Medico del pronto soccorso / medico di guardia" },
    { "value": "Medico generico", "label": "Medico generico" },
    { "value": "Medico legale", "label": "Medico legale" },
    { "value": "Medico militare", "label": "Medico militare" },
    { "value": "Medico ospedaliero", "label": "Medico ospedaliero" },
    { "value": "Medico specialista in medicina preventiva", "label": "Medico specialista in medicina preventiva" },
    { "value": "Medico umanitario", "label": "Medico umanitario" },
    { "value": "Meteorologo(a)", "label": "Meteorologo(a)" },
    { "value": "Modista", "label": "Modista" },
    { "value": "Molluschicoltore", "label": "Molluschicoltore" },
    { "value": "Montatore di strutture metalliche", "label": "Montatore di strutture metalliche" },
    { "value": "Montatore(trice)", "label": "Montatore(trice)" },
    { "value": "Motion designer", "label": "Motion designer" },
    { "value": "Muratore", "label": "Muratore" },
    { "value": "Musicista", "label": "Musicista" },
    { "value": "Musicologo", "label": "Musicologo" },
    { "value": "Musicoterapeuta", "label": "Musicoterapeuta" },
    { "value": "Mystery shopper/Cliente misterioso(a)", "label": "Mystery shopper/Cliente misterioso(a)" },
    { "value": "Naturopata", "label": "Naturopata" },
    { "value": "Necroforo", "label": "Necroforo" },
    { "value": "Noleggiatore(trice) di automobili", "label": "Noleggiatore(trice) di automobili" },
    { "value": "Notaio", "label": "Notaio" },
    { "value": "Notaio di diritto inglese", "label": "Notaio di diritto inglese" },
    { "value": "Nutrizionista", "label": "Nutrizionista" },
    { "value": "Oceanografo / Oceanologo", "label": "Oceanografo / Oceanologo" },
    { "value": "Odontoiatra", "label": "Odontoiatra" },
    { "value": "Odontotecnico", "label": "Odontotecnico" },
    { "value": "Operaio edile", "label": "Operaio edile" },
    { "value": "Operaio(a) agricolo generico", "label": "Operaio(a) agricolo generico" },
    { "value": "Operaio(a) forestale", "label": "Operaio(a) forestale" },
    { "value": "Operaio(a) metalmeccanico(a)", "label": "Operaio(a) metalmeccanico(a)" },
    { "value": "Operaio(a) stradale", "label": "Operaio(a) stradale" },
    { "value": "Operatore aeroportuale", "label": "Operatore aeroportuale" },
    { "value": "Operatore di call center", "label": "Operatore di call center" },
    { "value": "Operatore di catena di montaggio automatizzata", "label": "Operatore di catena di montaggio automatizzata" },
    { "value": "Operatore di impianti di risalita", "label": "Operatore di impianti di risalita" },
    { "value": "Operatore ecologico", "label": "Operatore ecologico" },
    { "value": "Operatore sociale", "label": "Operatore sociale" },
    { "value": "Operatore socio-sanitario", "label": "Operatore socio-sanitario" },
    {
        "value": "Operatore(trice) addetto(a) all'inserimento dati", "label": "Operatore(trice) addetto(a) all'inserimento dati"
    },
    { "value": "Operatore(trice) della ristorazione", "label": "Operatore(trice) della ristorazione" },
    { "value": "Operatore(trice) di macchine agricole", "label": "Operatore(trice) di macchine agricole" },
    { "value": "Operatore(trice) di mezzi pesanti", "label": "Operatore(trice) di mezzi pesanti" },
    { "value": "Operatore(trice) socio-sanitario(a)", "label": "Operatore(trice) socio-sanitario(a)" },
    { "value": "Operatore(trice) su macchine a controllo numerico", "label": "Operatore(trice) su macchine a controllo numerico" },
    { "value": "Orafo", "label": "Orafo" },
    { "value": "Orefice gioielliere", "label": "Orefice gioielliere" },
    { "value": "Organizzatore(trice) di eventi", "label": "Organizzatore(trice) di eventi" },
    { "value": "Ornitologo", "label": "Ornitologo" },
    { "value": "Orologiaio(a)", "label": "Orologiaio(a)" },
    { "value": "Orticoltore(trice)", "label": "Orticoltore(trice)" },
    { "value": "Ortofonista", "label": "Ortofonista" },
    { "value": "Ortottista", "label": "Ortottista" },
    { "value": "Osteopata", "label": "Osteopata" },
    { "value": "Ottico(a)-Oculista", "label": "Ottico(a)-Oculista" },
    { "value": "Paesaggista", "label": "Paesaggista" },
    { "value": "Paleontologo", "label": "Paleontologo" },
    { "value": "Panettiere(a)", "label": "Panettiere(a)" },
    { "value": "Parrucchiere(a)", "label": "Parrucchiere(a)" },
    { "value": "Partnership manager", "label": "Partnership manager" },
    { "value": "Pasticcere(cera)", "label": "Pasticcere(cera)" },
    { "value": "Pastore(a)", "label": "Pastore(a)" },
    { "value": "Pediatra", "label": "Pediatra" },
    { "value": "Pelletiere", "label": "Pelletiere" },
    { "value": "Penetration tester", "label": "Penetration tester" },
    { "value": "Perito assicurativo automobilistico", "label": "Perito assicurativo automobilistico" },
    { "value": "Perito meccatronico", "label": "Perito meccatronico" },
    { "value": "Perito(a) agrario(a)", "label": "Perito(a) agrario(a)" },
    { "value": "Perito(a) chimico(a)", "label": "Perito(a) chimico(a)" },
    { "value": "Pilota di droni", "label": "Pilota di droni" },
    { "value": "Pilota di linea", "label": "Pilota di linea" },
    { "value": "Piscicoltore(trice)", "label": "Piscicoltore(trice)" },
    { "value": "Pittore decoratore", "label": "Pittore decoratore" },
    { "value": "Podologo", "label": "Podologo" },
    { "value": "Portavoce", "label": "Portavoce" },
    {
        "value": "Portiere d'albergo", "label": "Portiere d'albergo"
    },
    { "value": "Potatore(trice)", "label": "Potatore(trice)" },
    { "value": "Product manager", "label": "Product manager" },
    { "value": "Produttore(trice) audiovisivo(a)", "label": "Produttore(trice) audiovisivo(a)" },
    { "value": "Produttore(trice) cerealicola", "label": "Produttore(trice) cerealicola" },
    { "value": "Produttore(trice) di vino", "label": "Produttore(trice) di vino" },
    { "value": "Professionista di vendita IT", "label": "Professionista di vendita IT" },
    { "value": "Professore(essa) documentalista", "label": "Professore(essa) documentalista" },
    { "value": "Professore(essa) nelle scuole private", "label": "Professore(essa) nelle scuole private" },
    { "value": "Profiler (analista comportamentale)", "label": "Profiler (analista comportamentale)" },
    { "value": "Progettista", "label": "Progettista" },
    { "value": "Progettista di livelli di gioco", "label": "Progettista di livelli di gioco" },
    { "value": "Progettista meccanico", "label": "Progettista meccanico" },
    { "value": "Progettista multimediale", "label": "Progettista multimediale" },
    { "value": "Proiezionista", "label": "Proiezionista" },
    { "value": "Project Manager ambientale", "label": "Project Manager ambientale" },
    { "value": "Project manager di sviluppo locale", "label": "Project manager di sviluppo locale" },
    { "value": "Project manager immobiliare", "label": "Project manager immobiliare" },
    { "value": "Project manager nel campo umanitario", "label": "Project manager nel campo umanitario" },
    { "value": "Project Manager nel settore delle costruzioni", "label": "Project Manager nel settore delle costruzioni" },
    { "value": "Property manager", "label": "Property manager" },
    { "value": "Protesista ortopedico", "label": "Protesista ortopedico" },
    { "value": "Psichiatra infantile", "label": "Psichiatra infantile" },
    { "value": "Psicologo clinico", "label": "Psicologo clinico" },
    { "value": "Psicologo del lavoro", "label": "Psicologo del lavoro" },
    { "value": "Psicologo scolastico", "label": "Psicologo scolastico" },
    { "value": "Psicomotricista", "label": "Psicomotricista" },
    { "value": "Psicoterapeuta", "label": "Psicoterapeuta" },
    { "value": "Puericultore(trice)", "label": "Puericultore(trice)" },
    { "value": "Raccomandatario marittimo", "label": "Raccomandatario marittimo" },
    { "value": "Radiologo(a)", "label": "Radiologo(a)" },
    { "value": "Rappresentante farmaceutico(a)", "label": "Rappresentante farmaceutico(a)" },
    { "value": "Records manager", "label": "Records manager" },
    { "value": "Redattore(trice) capo", "label": "Redattore(trice) capo" },
    { "value": "Redattore(trice) Web", "label": "Redattore(trice) Web" },
    { "value": "Regional manager", "label": "Regional manager" },
    { "value": "Regista", "label": "Regista" },
    { "value": "Regista VR (Virtual Reality)", "label": "Regista VR (Virtual Reality)" },
    { "value": "Reporter freelance", "label": "Reporter freelance" },
    { "value": "Reporter/Corrispondente", "label": "Reporter/Corrispondente" },
    { "value": "Responsabile affiliazione Web", "label": "Responsabile affiliazione Web" },
    { "value": "Responsabile ambientale", "label": "Responsabile ambientale" },
    { "value": "Responsabile asilo nido", "label": "Responsabile asilo nido" },
    { "value": "Responsabile assicurazione qualità", "label": "Responsabile assicurazione qualità" },
    { "value": "Responsabile assistenza clienti", "label": "Responsabile assistenza clienti" },
    { "value": "Responsabile buste paga", "label": "Responsabile buste paga" },
    { "value": "Responsabile comunicazione interna", "label": "Responsabile comunicazione interna" },
    { "value": "Responsabile comunicazione Web", "label": "Responsabile comunicazione Web" },
    { "value": "Responsabile contenuto Web", "label": "Responsabile contenuto Web" },
    { "value": "Responsabile dei trasporti", "label": "Responsabile dei trasporti" },
    { "value": "Responsabile del marketing operativo", "label": "Responsabile del marketing operativo" },
    { "value": "Responsabile del servizio clienti", "label": "Responsabile del servizio clienti" },
    { "value": "Responsabile della conformità - Compliance officer", "label": "Responsabile della conformità - Compliance officer" },
    { "value": "Responsabile della fatturazione", "label": "Responsabile della fatturazione" },
    { "value": "Responsabile della formazione", "label": "Responsabile della formazione" },
    { "value": "Responsabile della pubblica amministrazione", "label": "Responsabile della pubblica amministrazione" },
    { "value": "Responsabile della retribuzione", "label": "Responsabile della retribuzione" },
    { "value": "Responsabile delle assunzioni", "label": "Responsabile delle assunzioni" },
    { "value": "Responsabile delle comunicazioni", "label": "Responsabile delle comunicazioni" },
    { "value": "Responsabile delle operazioni bancarie", "label": "Responsabile delle operazioni bancarie" },
    { "value": "Responsabile delle pubbliche relazioni", "label": "Responsabile delle pubbliche relazioni" },
    { "value": "Responsabile delle relazioni internazionali", "label": "Responsabile delle relazioni internazionali" },
    { "value": "Responsabile dello sviluppo Risorse Umane", "label": "Responsabile dello sviluppo Risorse Umane" },
    { "value": "Responsabile di allevamento", "label": "Responsabile di allevamento" },
    { "value": "Responsabile di canale televisivo", "label": "Responsabile di canale televisivo" },
    { "value": "Responsabile di casa di riposo", "label": "Responsabile di casa di riposo" },
    { "value": "Responsabile di caseificio", "label": "Responsabile di caseificio" },
    { "value": "Responsabile di catering", "label": "Responsabile di catering" },
    { "value": "Responsabile di centro ricreativo", "label": "Responsabile di centro ricreativo" },
    { "value": "Responsabile di front office", "label": "Responsabile di front office" },
    { "value": "Responsabile di pianificazione", "label": "Responsabile di pianificazione" },
    { "value": "Responsabile di produzione digitale", "label": "Responsabile di produzione digitale" },
    { "value": "Responsabile di progetto meccanico", "label": "Responsabile di progetto meccanico" },
    { "value": "Responsabile di ufficio del turismo", "label": "Responsabile di ufficio del turismo" },
    { "value": "Responsabile e-commerce", "label": "Responsabile e-commerce" },
    { "value": "Responsabile eCRM (Customer Relationship Management)", "label": "Responsabile eCRM (Customer Relationship Management)" },
    { "value": "Responsabile export", "label": "Responsabile export" },
    { "value": "Responsabile gestione clienti", "label": "Responsabile gestione clienti" },
    { "value": "Responsabile IT Procurement", "label": "Responsabile IT Procurement" },
    { "value": "Responsabile marketing digitale", "label": "Responsabile marketing digitale" },
    { "value": "Responsabile organizzazione trasporti", "label": "Responsabile organizzazione trasporti" },
    { "value": "Responsabile pianificazione degli orari ferroviari", "label": "Responsabile pianificazione degli orari ferroviari" },
    { "value": "Responsabile produzione industriale", "label": "Responsabile produzione industriale" },
    { "value": "Responsabile programmi di servizio sociale", "label": "Responsabile programmi di servizio sociale" },
    { "value": "Responsabile promozione vendite", "label": "Responsabile promozione vendite" },
    { "value": "Responsabile qualità Web", "label": "Responsabile qualità Web" },
    { "value": "Responsabile raccolta fondi (Fundraiser)", "label": "Responsabile raccolta fondi (Fundraiser)" },
    { "value": "Responsabile raccolta rifiuti", "label": "Responsabile raccolta rifiuti" },
    { "value": "Responsabile salute e sicurezza sul lavoro", "label": "Responsabile salute e sicurezza sul lavoro" },
    { "value": "Responsabile Sicurezza Sistemi Informatici (ICT)", "label": "Responsabile Sicurezza Sistemi Informatici (ICT)" },
    { "value": "Responsabile sviluppo di carriera", "label": "Responsabile sviluppo di carriera" },
    { "value": "Responsabile sviluppo sostenibile", "label": "Responsabile sviluppo sostenibile" },
    { "value": "Responsabile Trade marketing", "label": "Responsabile Trade marketing" },
    {
        "value": "Restauratore(trice) di opere d'arte", "label": "Restauratore(trice) di opere d'arte"
    },
    { "value": "Revenue manager", "label": "Revenue manager" },
    { "value": "Revisore", "label": "Revisore" },
    { "value": "Revisore dei conti", "label": "Revisore dei conti" },
    { "value": "Revisore finanziario", "label": "Revisore finanziario" },
    { "value": "Revisore interno", "label": "Revisore interno" },
    { "value": "Revisore legale", "label": "Revisore legale" },
    { "value": "Ricercatore(trice) biologo(a) da campo", "label": "Ricercatore(trice) biologo(a) da campo" },
    { "value": "Ricercatore(trice) chimico(a) da campo", "label": "Ricercatore(trice) chimico(a) da campo" },
    { "value": "Sarto(a)/cucitore(trice)", "label": "Sarto(a)/cucitore(trice)" },
    { "value": "Scienziato ambientale", "label": "Scienziato ambientale" },
    { "value": "Scrum Master", "label": "Scrum Master" },
    { "value": "Sellaio(a)", "label": "Sellaio(a)" },
    { "value": "SEO (ottimizzatore per motori di ricerca)", "label": "SEO (ottimizzatore per motori di ricerca)" },
    { "value": "Sessuologo(a)", "label": "Sessuologo(a)" },
    { "value": "Sistemista", "label": "Sistemista" },
    { "value": "Skipper", "label": "Skipper" },
    { "value": "Social media manager", "label": "Social media manager" },
    { "value": "Sociologo(a)", "label": "Sociologo(a)" },
    { "value": "Soffiatore(trice) di vetro", "label": "Soffiatore(trice) di vetro" },
    { "value": "Sofrologo", "label": "Sofrologo" },
    { "value": "Soldato di fanteria", "label": "Soldato di fanteria" },
    { "value": "Sommelier", "label": "Sommelier" },
    { "value": "Sommozzatore", "label": "Sommozzatore" },
    { "value": "Sostituto procuratore", "label": "Sostituto procuratore" },
    {
        "value": "Sottufficiale dell'aeronautica", "label": "Sottufficiale dell'aeronautica"
    },
    {
        "value": "Sottufficiale dell'esercito", "label": "Sottufficiale dell'esercito"
    },
    { "value": "Sound designer", "label": "Sound designer" },
    { "value": "Specialista di marketing web", "label": "Specialista di marketing web" },
    { "value": "Specialista IA", "label": "Specialista IA" },
    { "value": "Specialista in progettazione di cucina e bagni", "label": "Specialista in progettazione di cucina e bagni" },
    { "value": "Specialista in risparmio energetico", "label": "Specialista in risparmio energetico" },
    { "value": "Specialista SEA", "label": "Specialista SEA" },
    { "value": "Specialista SEO", "label": "Specialista SEO" },
    { "value": "Specialista supporto informatico", "label": "Specialista supporto informatico" },
    { "value": "Stampista", "label": "Stampista" },
    { "value": "Statistico(a)", "label": "Statistico(a)" },
    { "value": "Sterratore(trice)", "label": "Sterratore(trice)" },
    { "value": "Stilista", "label": "Stilista" },
    { "value": "Storico(a)", "label": "Storico(a)" },
    { "value": "Storyboarder", "label": "Storyboarder" },
    { "value": "Strategic planner", "label": "Strategic planner" },
    { "value": "Sviluppatore multimediale", "label": "Sviluppatore multimediale" },
    { "value": "Sviluppatore(trice) di applicazioni mobili", "label": "Sviluppatore(trice) di applicazioni mobili" },
    { "value": "Sviluppatore(trice) informatica", "label": "Sviluppatore(trice) informatica" },
    { "value": "Sviluppatore(trice) WEB", "label": "Sviluppatore(trice) WEB" },
    { "value": "Tagliatore(trice) di diamanti", "label": "Tagliatore(trice) di diamanti" },
    { "value": "Tassista", "label": "Tassista" },
    { "value": "Tatuatore(trice)", "label": "Tatuatore(trice)" },
    { "value": "Tecnico addetto alla finitura di stampa", "label": "Tecnico addetto alla finitura di stampa" },
    { "value": "Tecnico audioprotesista", "label": "Tecnico audioprotesista" },
    {
        "value": "Tecnico dell'aeronautica militare", "label": "Tecnico dell'aeronautica militare"
    },
    { "value": "Tecnico delle luci", "label": "Tecnico delle luci" },
    { "value": "Tecnico elettronico", "label": "Tecnico elettronico" },
    { "value": "Tecnico sanitario", "label": "Tecnico sanitario" },
    { "value": "Tecnico specialista in nanotecnologie", "label": "Tecnico specialista in nanotecnologie" },
    { "value": "Tecnico veterinario", "label": "Tecnico veterinario" },
    { "value": "Tecnico(a) addetto allo smaltimento dei rifiuti ", "label": "Tecnico(a) addetto allo smaltimento dei rifiuti " },
    { "value": "Tecnico(a) automotive", "label": "Tecnico(a) automotive" },
    { "value": "Tecnico(a) di fonderia", "label": "Tecnico(a) di fonderia" },
    { "value": "Tecnico(a) in energia rinnovabile", "label": "Tecnico(a) in energia rinnovabile" },
    { "value": "Tecnico(a) informatico", "label": "Tecnico(a) informatico" },
    { "value": "Tecnico(a) reti e telecomunicazioni", "label": "Tecnico(a) reti e telecomunicazioni" },
    { "value": "Tecnologo(a) alimentare", "label": "Tecnologo(a) alimentare" },
    { "value": "Terapeuta di fine vita", "label": "Terapeuta di fine vita" },
    { "value": "Terminologo(a)", "label": "Terminologo(a)" },
    { "value": "Tesoriere aziendale", "label": "Tesoriere aziendale" },
    { "value": "Tiratore(trice) scelto(a)", "label": "Tiratore(trice) scelto(a)" },
    { "value": "Tornitore", "label": "Tornitore" },
    { "value": "Trader", "label": "Trader" },
    { "value": "Trader Sportivo", "label": "Trader Sportivo" },
    { "value": "Traduttore(trice)", "label": "Traduttore(trice)" },
    { "value": "Traffic manager della pubblicità online", "label": "Traffic manager della pubblicità online" },
    { "value": "Travel designer", "label": "Travel designer" },
    { "value": "Travel manager aziendale", "label": "Travel manager aziendale" },
    { "value": "Tributarista", "label": "Tributarista" },
    { "value": "Truccatore(trice)", "label": "Truccatore(trice)" },
    { "value": "Tubista", "label": "Tubista" },
    { "value": "Ufficiale addetto alle operazioni di volo", "label": "Ufficiale addetto alle operazioni di volo" },
    { "value": "Ufficiale dei carabinieri", "label": "Ufficiale dei carabinieri" },
    {
        "value": "Ufficiale dell'aeronautica", "label": "Ufficiale dell'aeronautica"
    },
    {
        "value": "Ufficiale dell'esercito", "label": "Ufficiale dell'esercito"
    },
    { "value": "Ufficiale della Marina", "label": "Ufficiale della Marina" },
    { "value": "Ufficiale della marina mercantile", "label": "Ufficiale della marina mercantile" },
    { "value": "Ufficiale di Polizia", "label": "Ufficiale di Polizia" },
    { "value": "Ufficiale Giudiziario(a)", "label": "Ufficiale Giudiziario(a)" },
    { "value": "Ui Designer", "label": "Ui Designer" },
    { "value": "Urbanista", "label": "Urbanista" },
    { "value": "Urbanista pianificatore", "label": "Urbanista pianificatore" },
    { "value": "Ux Designer", "label": "Ux Designer" },
    { "value": "Venditore online", "label": "Venditore online" },
    { "value": "Venditore(trice) ambulante", "label": "Venditore(trice) ambulante" },
    { "value": "Venditore(trice) di automobili", "label": "Venditore(trice) di automobili" },
    { "value": "Venditore(trice) di giocattoli", "label": "Venditore(trice) di giocattoli" },
    { "value": "Venditore(trice) di moto", "label": "Venditore(trice) di moto" },
    { "value": "Veterinario", "label": "Veterinario" },
    { "value": "Vetraio(a)", "label": "Vetraio(a)" },
    { "value": "Vetrinista", "label": "Vetrinista" },
    { "value": "Videogiornalista", "label": "Videogiornalista" },
    { "value": "Videografo", "label": "Videografo" },
    { "value": "Vigile del fuoco", "label": "Vigile del fuoco" },
    { "value": "Vigile urbano", "label": "Vigile urbano" },
    { "value": "Visual effect artist", "label": "Visual effect artist" },
    { "value": "Viticoltore(trice)", "label": "Viticoltore(trice)" },
    { "value": "Web analyst", "label": "Web analyst" },
    { "value": "Web designer", "label": "Web designer" },
    { "value": "Web integrator", "label": "Web integrator" },
    { "value": "Web mobile designer", "label": "Web mobile designer" },
    { "value": "Web project manager", "label": "Web project manager" },
    { "value": "Web surfer", "label": "Web surfer" },
    { "value": "Webmaster", "label": "Webmaster" },
    { "value": "Wedding planner", "label": "Wedding planner" },
    { "value": "Youtuber", "label": "Youtuber" },
    { "value": "Zoologo", "label": "Zoologo" },
];