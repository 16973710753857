import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export interface IAuthHabacusRouteProps {
    children: any;
}

const AuthHabacusRoute: React.FunctionComponent<IAuthHabacusRouteProps> = (props) => {
    const { children } = props;
    const auth = getAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const AuthCheck = onAuthStateChanged(auth, (user) => {
            if (user && user.email?.includes('@habacus.com')) {
                setLoading(false);
            } else {
                console.log('unauthorized');
                navigate('/login?redirect=mission-control-center&function=login');
            }
        });

        return () => AuthCheck();
    }, [auth]);

    if (loading) return <p>loading ...</p>;

    return <>{children}</>;
};

export default AuthHabacusRoute;