import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";

export const fixPlayer = (player: React.RefObject<Player>) => {
    if (player?.current?.container
        && player?.current?.container?.childNodes.length > 1) {
        while (player?.current?.container?.childNodes.length > 1) {
            player?.current?.container?.removeChild(player?.current?.container?.childNodes[0]);
        }
    }
}