export const course_types = [
    { "value": "Diploma Scuola Superiore", "label": "Diploma Scuola Superiore" },
    { "value": "AFAM", "label": "Corso AFAM" },
    { "value": "ITS", "label": "Corso ITS" },
    { "value": "Vocational", "label": "Corso Professionalizzante" },
    { "value": "SSML", "label": "Corso SSML" },
    { "value": "phd", "label": "Dottorato" },
    { "value": "LT", "label": "Laurea Triennale" },
    { "value": "LM", "label": "Laurea Magistrale" },
    { "value": "LMCU", "label": "Laurea Magistrale a Ciclo Unico" },
    { "value": "M1", "label": "Master I Livello" },
    { "value": "M2", "label": "Master II Livello" },
]

export const schools = [
    { 'id': 4245, 'value': '3iL École d\'ingénieurs', 'label': '3iL École d\'ingénieurs' },
    { 'id': 12205, 'value': 'A.T. Still University', 'label': 'A.T. Still University' },
    { 'id': 3814, 'value': 'Aalborg Universitet', 'label': 'Aalborg Universitet' },
    { 'id': 217, 'value': 'Aalborg University Copenhagen', 'label': 'Aalborg University Copenhagen' },
    { 'id': 14420, 'value': 'Aalto University', 'label': 'Aalto University' },
    { 'id': 4207, 'value': 'Aalto-yliopisto', 'label': 'Aalto-yliopisto' },
    { 'id': 3815, 'value': 'Aarhus Universitet', 'label': 'Aarhus Universitet' },
    { 'id': 236, 'value': 'Aarhus University', 'label': 'Aarhus University' },
    { 'id': 2105, 'value': 'Aba Teachers University', 'label': 'Aba Teachers University' },
    { 'id': 6903, 'value': 'ABABO - Accademia di Belle Arti di Bologna', 'label': 'ABABO - Accademia di Belle Arti di Bologna' },
    { 'id': 83, 'value': 'ABANA - Accademia di Belle Arti di Napoli', 'label': 'ABANA - Accademia di Belle Arti di Napoli' },
    { 'id': 10045, 'value': 'Abasyn University', 'label': 'Abasyn University' },
    { 'id': 10046, 'value': 'Abbottabad University of Science and Technology', 'label': 'Abbottabad University of Science and Technology' },
    { 'id': 10047, 'value': 'Abdul Wali Khan University Mardan', 'label': 'Abdul Wali Khan University Mardan' },
    { 'id': 11622, 'value': 'Abdullah Gül Üniversitesi', 'label': 'Abdullah Gül Üniversitesi' },
    { 'id': 11947, 'value': 'Abdulrahman Al-Sumait Memorial University', 'label': 'Abdulrahman Al-Sumait Memorial University' },
    { 'id': 4497, 'value': 'Abertay University', 'label': 'Abertay University' },
    { 'id': 4498, 'value': 'Aberystwyth University', 'label': 'Aberystwyth University' },
    { 'id': 5565, 'value': 'Abhilashi University', 'label': 'Abhilashi University' },
    { 'id': 9299, 'value': 'Abia State University', 'label': 'Abia State University' },
    { 'id': 12206, 'value': 'Abilene Christian University', 'label': 'Abilene Christian University' },
    { 'id': 1786, 'value': 'ABM University College', 'label': 'ABM University College' },
    { 'id': 4208, 'value': 'Åbo Akademi', 'label': 'Åbo Akademi' },
    { 'id': 679, 'value': 'Abourihan Higher Education Institute', 'label': 'Abourihan Higher Education Institute' },
    { 'id': 9815, 'value': 'Abra State Institute of Science and Technology', 'label': 'Abra State Institute of Science and Technology' },
    { 'id': 12207, 'value': 'Abraham Baldwin Agricultural College', 'label': 'Abraham Baldwin Agricultural College' },
    { 'id': 13982, 'value': 'Abu Ali ibn Sino nomidagi Buxoro Davlat Tibbiyot Instituti', 'label': 'Abu Ali ibn Sino nomidagi Buxoro Davlat Tibbiyot Instituti' },
    { 'id': 615, 'value': 'Abu Dhabi Polytechnic', 'label': 'Abu Dhabi Polytechnic' },
    { 'id': 616, 'value': 'Abu Dhabi School of Management', 'label': 'Abu Dhabi School of Management' },
    { 'id': 617, 'value': 'Abu Dhabi University', 'label': 'Abu Dhabi University' },
    { 'id': 9300, 'value': 'Abubakar Tafawa Balewa University', 'label': 'Abubakar Tafawa Balewa University' },
    { 'id': 10519, 'value': 'Academia de Muzica Gheorghe Dima', 'label': 'Academia de Muzica Gheorghe Dima' },
    { 'id': 8447, 'value': 'Academia de Muzica, Teatru si Arte Plastice', 'label': 'Academia de Muzica, Teatru si Arte Plastice' },
    { 'id': 10520, 'value': 'Academia de Studii Economice din Bucuresti', 'label': 'Academia de Studii Economice din Bucuresti' },
    { 'id': 8448, 'value': 'Academia de Studii Economice din Moldova', 'label': 'Academia de Studii Economice din Moldova' },
    { 'id': 10404, 'value': 'Academia Nacional Superior de Orquestra', 'label': 'Academia Nacional Superior de Orquestra' },
    { 'id': 3391, 'value': 'Academia Rerum Civilium', 'label': 'Academia Rerum Civilium' },
    { 'id': 4663, 'value': 'Academic City University College', 'label': 'Academic City University College' },
    { 'id': 1341, 'value': 'Académie des Beaux-Arts de Tournai', 'label': 'Académie des Beaux-Arts de Tournai' },
    { 'id': 4473, 'value': 'Académie Franco-Américaine de Management', 'label': 'Académie Franco-Américaine de Management' },
    { 'id': 11282, 'value': 'Academie voor Hoger Kunst- en Cultuuronderwijs', 'label': 'Academie voor Hoger Kunst- en Cultuuronderwijs' },
    { 'id': 12208, 'value': 'Academy of Art University', 'label': 'Academy of Art University' },
    { 'id': 7842, 'value': 'Academy of Education of Kyrgyzstan', 'label': 'Academy of Education of Kyrgyzstan' },
    { 'id': 8396, 'value': 'Academy of Graduate Studies', 'label': 'Academy of Graduate Studies' },
    { 'id': 1421, 'value': 'Academy of Music, Dance and Fine Arts', 'label': 'Academy of Music, Dance and Fine Arts' },
    { 'id': 1799, 'value': 'Academy of Public Administration under the aegis of the President of the Republic of Belarus', 'label': 'Academy of Public Administration under the aegis of the President of the Republic of Belarus' },
    { 'id': 8164, 'value': 'Academy of Public Administration under the President of Kazakhstan', 'label': 'Academy of Public Administration under the President of Kazakhstan' },
    { 'id': 7843, 'value': 'Academy of Public Administration under the President of the Kyrgyz Republic', 'label': 'Academy of Public Administration under the President of the Kyrgyz Republic' },
    { 'id': 1800, 'value': 'Academy of the Ministry of Internal Affairs', 'label': 'Academy of the Ministry of Internal Affairs' },
    { 'id': 1849, 'value': 'Acadia University', 'label': 'Acadia University' },
    { 'id': 7015, 'value': 'Accademia ABADIR sede Sant\'Agata Li Battiati', 'label': 'Accademia ABADIR sede Sant\'Agata Li Battiati' },
    { 'id': 504, 'value': 'Accademia Albertina di Belle Arti di Torino', 'label': 'Accademia Albertina di Belle Arti di Torino' },
    { 'id': 242, 'value': 'Accademia Belle Arti "Mario Sironi" Sassari', 'label': 'Accademia Belle Arti "Mario Sironi" Sassari' },
    { 'id': 197, 'value': 'Accademia Belle Arti di Roma', 'label': 'Accademia Belle Arti di Roma' },
    { 'id': 589, 'value': 'Accademia Belle Arti di Udine GB Tiepolo', 'label': 'Accademia Belle Arti di Udine GB Tiepolo' },
    { 'id': 259, 'value': 'Accademia Costume e Moda', 'label': 'Accademia Costume e Moda' },
    { 'id': 6902, 'value': 'Accademia della Moda - NAPOLI', 'label': 'Accademia della Moda - NAPOLI' },
    { 'id': 6885, 'value': 'Accademia della Moda - NAPOLI (sede di MILANO)', 'label': 'Accademia della Moda - NAPOLI (sede di MILANO)' },
    { 'id': 207, 'value': 'Accademia delle Arti e Nuove Tecnologie (AANT)', 'label': 'Accademia delle Arti e Nuove Tecnologie (AANT)' },
    { 'id': 177, 'value': 'Accademia delle belle arti di Bologna', 'label': 'Accademia delle belle arti di Bologna' },
    { 'id': 405, 'value': 'Accademia delle Belle Arti G. Carrara Bergamo', 'label': 'Accademia delle Belle Arti G. Carrara Bergamo' },
    { 'id': 391, 'value': 'Accademia delle Belle Arti Santa Giulia', 'label': 'Accademia delle Belle Arti Santa Giulia' },
    { 'id': 390, 'value': 'Accademia delle Belle Arti Venezia', 'label': 'Accademia delle Belle Arti Venezia' },
    { 'id': 14422, 'value': 'Accademia delle professioni - Fondazione San Nicolò', 'label': 'Accademia delle professioni - Fondazione San Nicolò' },
    { 'id': 6918, 'value': 'Accademia di Belle Arti "G.B. Tiepolo" - UDINE', 'label': 'Accademia di Belle Arti "G.B. Tiepolo" - UDINE' },
    { 'id': 14335, 'value': 'Accademia di Belle Arti “Rosario Gagliardi”', 'label': 'Accademia di Belle Arti “Rosario Gagliardi”' },
    { 'id': 580, 'value': 'Accademia di Belle Arti Aldo Galli', 'label': 'Accademia di Belle Arti Aldo Galli' },
    { 'id': 14445, 'value': 'Accademia di Belle Arti Aldo Galli', 'label': 'Accademia di Belle Arti Aldo Galli' },
    { 'id': 14446, 'value': 'Accademia di Belle Arti Aldo Galli', 'label': 'Accademia di Belle Arti Aldo Galli' },
    { 'id': 6978, 'value': 'Accademia di Belle Arti di BARI', 'label': 'Accademia di Belle Arti di BARI' },
    { 'id': 449, 'value': 'Accademia di Belle Arti di Brera', 'label': 'Accademia di Belle Arti di Brera' },
    { 'id': 436, 'value': 'Accademia di Belle Arti di Carrara', 'label': 'Accademia di Belle Arti di Carrara' },
    { 'id': 113, 'value': 'Accademia di belle arti di Catania', 'label': 'Accademia di belle arti di Catania' },
    { 'id': 6889, 'value': 'Accademia di Belle Arti di CATANZARO', 'label': 'Accademia di Belle Arti di CATANZARO' },
    { 'id': 377, 'value': 'Accademia di Belle Arti di Cuneo', 'label': 'Accademia di Belle Arti di Cuneo' },
    { 'id': 435, 'value': 'Accademia di Belle Arti di Firenze', 'label': 'Accademia di Belle Arti di Firenze' },
    { 'id': 457, 'value': 'Accademia di Belle Arti di Foggia', 'label': 'Accademia di Belle Arti di Foggia' },
    { 'id': 6919, 'value': 'Accademia di Belle Arti di FROSINONE', 'label': 'Accademia di Belle Arti di FROSINONE' },
    { 'id': 6879, 'value': 'Accademia di Belle Arti di L\'AQUILA', 'label': 'Accademia di Belle Arti di L\'AQUILA' },
    { 'id': 6979, 'value': 'Accademia di Belle Arti di LECCE', 'label': 'Accademia di Belle Arti di LECCE' },
    { 'id': 14322, 'value': 'Accademia di Belle Arti di LECCE', 'label': 'Accademia di Belle Arti di LECCE' },
    { 'id': 538, 'value': 'Accademia di Belle Arti di Macerata', 'label': 'Accademia di Belle Arti di Macerata' },
    { 'id': 6942, 'value': 'Accademia di Belle Arti di MILANO "Brera"', 'label': 'Accademia di Belle Arti di MILANO "Brera"' },
    { 'id': 6897, 'value': 'Accademia di Belle Arti di NAPOLI', 'label': 'Accademia di Belle Arti di NAPOLI' },
    { 'id': 537, 'value': 'Accademia di Belle Arti di Palermo', 'label': 'Accademia di Belle Arti di Palermo' },
    { 'id': 585, 'value': 'Accademia di Belle Arti di Ravenna', 'label': 'Accademia di Belle Arti di Ravenna' },
    { 'id': 6890, 'value': 'Accademia di Belle Arti di REGGIO CALABRIA', 'label': 'Accademia di Belle Arti di REGGIO CALABRIA' },
    { 'id': 6920, 'value': 'Accademia di Belle Arti di ROMA', 'label': 'Accademia di Belle Arti di ROMA' },
    { 'id': 6987, 'value': 'Accademia di Belle Arti di SASSARI', 'label': 'Accademia di Belle Arti di SASSARI' },
    { 'id': 6968, 'value': 'Accademia di Belle Arti di TORINO "Albertina"', 'label': 'Accademia di Belle Arti di TORINO "Albertina"' },
    { 'id': 6962, 'value': 'Accademia di Belle Arti di URBINO', 'label': 'Accademia di Belle Arti di URBINO' },
    { 'id': 7018, 'value': 'Accademia di Belle Arti di VENEZIA', 'label': 'Accademia di Belle Arti di VENEZIA' },
    { 'id': 6943, 'value': 'Accademia di Belle Arti legalmente riconosciuta di BERGAMO "Carrara"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di BERGAMO "Carrara"' },
    { 'id': 6944, 'value': 'Accademia di Belle Arti legalmente riconosciuta di BRESCIA "LABA" - libera accademia di belle arti', 'label': 'Accademia di Belle Arti legalmente riconosciuta di BRESCIA "LABA" - libera accademia di belle arti' },
    { 'id': 6945, 'value': 'Accademia di Belle Arti legalmente riconosciuta di BRESCIA "Santagiulia"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di BRESCIA "Santagiulia"' },
    { 'id': 6946, 'value': 'Accademia di Belle Arti legalmente riconosciuta di COMO "Aldo Galli"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di COMO "Aldo Galli"' },
    { 'id': 6969, 'value': 'Accademia di Belle Arti legalmente riconosciuta di CUNEO', 'label': 'Accademia di Belle Arti legalmente riconosciuta di CUNEO' },
    { 'id': 6937, 'value': 'Accademia di Belle Arti legalmente riconosciuta di GENOVA "Accademia Ligustica"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di GENOVA "Accademia Ligustica"' },
    { 'id': 6947, 'value': 'Accademia di Belle Arti legalmente riconosciuta di MILANO "ACME"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di MILANO "ACME"' },
    { 'id': 6948, 'value': 'Accademia di Belle Arti legalmente riconosciuta di MILANO "NABA" - nuova accademia di belle arti', 'label': 'Accademia di Belle Arti legalmente riconosciuta di MILANO "NABA" - nuova accademia di belle arti' },
    { 'id': 6970, 'value': 'Accademia di Belle Arti legalmente riconosciuta di NOVARA "ACME"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di NOVARA "ACME"' },
    { 'id': 7011, 'value': 'Accademia di Belle Arti legalmente riconosciuta di PERUGIA "Pietro Vannucci"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di PERUGIA "Pietro Vannucci"' },
    { 'id': 7012, 'value': 'Accademia di Belle Arti legalmente riconosciuta di RAGUSA "Mediterranea"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di RAGUSA "Mediterranea"' },
    { 'id': 6904, 'value': 'Accademia di Belle Arti legalmente riconosciuta di RAVENNA', 'label': 'Accademia di Belle Arti legalmente riconosciuta di RAVENNA' },
    { 'id': 6921, 'value': 'Accademia di Belle Arti legalmente riconosciuta di ROMA "Rome University of Fine Arts"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di ROMA "Rome University of Fine Arts"' },
    { 'id': 6991, 'value': 'Accademia di Belle Arti legalmente riconosciuta di S.AGATA LI BATTIATI "Abadir" accademia di belle arti e restauro', 'label': 'Accademia di Belle Arti legalmente riconosciuta di S.AGATA LI BATTIATI "Abadir" accademia di belle arti e restauro' },
    { 'id': 6992, 'value': 'Accademia di Belle Arti legalmente riconosciuta di S.MARTINO DELLE SCALE "Abadir"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di S.MARTINO DELLE SCALE "Abadir"' },
    { 'id': 6938, 'value': 'Accademia di Belle Arti legalmente riconosciuta di SANREMO "Istituto I. Duncan"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di SANREMO "Istituto I. Duncan"' },
    { 'id': 6993, 'value': 'Accademia di Belle Arti legalmente riconosciuta di Siracusa - Made Program', 'label': 'Accademia di Belle Arti legalmente riconosciuta di Siracusa - Made Program' },
    { 'id': 6891, 'value': 'Accademia di Belle Arti legalmente riconosciuta di STEFANACONI "Fidia"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di STEFANACONI "Fidia"' },
    { 'id': 6994, 'value': 'Accademia di Belle Arti legalmente riconosciuta di TRAPANI "Kandinskij"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di TRAPANI "Kandinskij"' },
    { 'id': 7019, 'value': 'Accademia di Belle Arti legalmente riconosciuta di VERONA', 'label': 'Accademia di Belle Arti legalmente riconosciuta di VERONA' },
    { 'id': 6922, 'value': 'Accademia di Belle Arti legalmente riconosciuta di VITERBO "Lorenzo da Viterbo"', 'label': 'Accademia di Belle Arti legalmente riconosciuta di VITERBO "Lorenzo da Viterbo"' },
    { 'id': 7016, 'value': 'Accademia di Belle Arti Michelangelo di Agrigento', 'label': 'Accademia di Belle Arti Michelangelo di Agrigento' },
    { 'id': 6928, 'value': 'Accademia di Costume e Moda - ROMA', 'label': 'Accademia di Costume e Moda - ROMA' },
    { 'id': 444, 'value': 'Accademia di Musica di Pinerolo', 'label': 'Accademia di Musica di Pinerolo' },
    { 'id': 14414, 'value': 'Accademia Foodlab', 'label': 'Accademia Foodlab' },
    { 'id': 6929, 'value': 'Accademia Internazionale di Teatro - ROMA', 'label': 'Accademia Internazionale di Teatro - ROMA' },
    { 'id': 14346, 'value': 'ACCADEMIA INTERNAZIONALE DI TEATRO - ROMA', 'label': 'ACCADEMIA INTERNAZIONALE DI TEATRO - ROMA' },
    { 'id': 245, 'value': 'Accademia Italiana della Marina Mercantile', 'label': 'Accademia Italiana della Marina Mercantile' },
    { 'id': 7004, 'value': 'Accademia Italiana di Arte, Moda e Design - FIRENZE', 'label': 'Accademia Italiana di Arte, Moda e Design - FIRENZE' },
    { 'id': 6930, 'value': 'Accademia Italiana di Arte, Moda e Design - ROMA', 'label': 'Accademia Italiana di Arte, Moda e Design - ROMA' },
    { 'id': 14330, 'value': 'Accademia Italiana,Università di Moda, Design e Fotografia - FIRENZE', 'label': 'Accademia Italiana,Università di Moda, Design e Fotografia - FIRENZE' },
    { 'id': 448, 'value': 'Accademia Lingustica di Belle Arti di Genova', 'label': 'Accademia Lingustica di Belle Arti di Genova' },
    { 'id': 6923, 'value': 'Accademia nazionale di arte drammatica di ROMA "Silvio d\'Amico"', 'label': 'Accademia nazionale di arte drammatica di ROMA "Silvio d\'Amico"' },
    { 'id': 14352, 'value': 'ACCADEMIA NAZIONALE DI ARTE DRAMMATICA DI ROMA "SILVIO D\'AMICO"', 'label': 'ACCADEMIA NAZIONALE DI ARTE DRAMMATICA DI ROMA "SILVIO D\'AMICO"' },
    { 'id': 385, 'value': 'Accademia Nazionale di Danza di Roma', 'label': 'Accademia Nazionale di Danza di Roma' },
    { 'id': 597, 'value': 'Accademia Teatro alla Scala', 'label': 'Accademia Teatro alla Scala' },
    { 'id': 4664, 'value': 'Accra Institute of Technology', 'label': 'Accra Institute of Technology' },
    { 'id': 4665, 'value': 'Accra Technical University', 'label': 'Accra Technical University' },
    { 'id': 8589, 'value': 'Ach Medical University', 'label': 'Ach Medical University' },
    { 'id': 5566, 'value': 'Acharya N.G. Ranga Agricultural University', 'label': 'Acharya N.G. Ranga Agricultural University' },
    { 'id': 5567, 'value': 'Acharya Nagarjuna University', 'label': 'Acharya Nagarjuna University' },
    { 'id': 9301, 'value': 'Achievers University, Owo', 'label': 'Achievers University, Owo' },
    { 'id': 11623, 'value': 'Acibadem Üniversitesi', 'label': 'Acibadem Üniversitesi' },
    { 'id': 376, 'value': 'ACME - Accademia Belle Arti Novara', 'label': 'ACME - Accademia Belle Arti Novara' },
    { 'id': 3340, 'value': 'Ada Kent Üniversitesi', 'label': 'Ada Kent Üniversitesi' },
    { 'id': 1112, 'value': 'ADA Universiteti', 'label': 'ADA Universiteti' },
    { 'id': 4173, 'value': 'Adama Science and Technology University', 'label': 'Adama Science and Technology University' },
    { 'id': 5568, 'value': 'Adamas University', 'label': 'Adamas University' },
    { 'id': 9302, 'value': 'Adamawa State University', 'label': 'Adamawa State University' },
    { 'id': 12209, 'value': 'Adams State University', 'label': 'Adams State University' },
    { 'id': 9816, 'value': 'Adamson University', 'label': 'Adamson University' },
    { 'id': 11624, 'value': 'Adana Alparslan Türkes Bilim ve Teknoloji Üniversitesi', 'label': 'Adana Alparslan Türkes Bilim ve Teknoloji Üniversitesi' },
    { 'id': 4174, 'value': 'Addis Ababa Science and Technology University', 'label': 'Addis Ababa Science and Technology University' },
    { 'id': 4175, 'value': 'Addis Ababa University', 'label': 'Addis Ababa University' },
    { 'id': 9303, 'value': 'Adekunle Ajasin University', 'label': 'Adekunle Ajasin University' },
    { 'id': 9304, 'value': 'Adeleke University', 'label': 'Adeleke University' },
    { 'id': 12210, 'value': 'Adelphi University', 'label': 'Adelphi University' },
    { 'id': 5569, 'value': 'Adesh University', 'label': 'Adesh University' },
    { 'id': 4093, 'value': 'Adi-Keih College of Arts and Social Sciences', 'label': 'Adi-Keih College of Arts and Social Sciences' },
    { 'id': 4176, 'value': 'Adigrat University', 'label': 'Adigrat University' },
    { 'id': 5570, 'value': 'Adikavi Nannaya University', 'label': 'Adikavi Nannaya University' },
    { 'id': 11625, 'value': 'Adiyaman Üniversitesi', 'label': 'Adiyaman Üniversitesi' },
    { 'id': 12211, 'value': 'Adler Graduate School', 'label': 'Adler Graduate School' },
    { 'id': 12212, 'value': 'Adler University', 'label': 'Adler University' },
    { 'id': 11979, 'value': 'Admiral Makarov National University of Shipbuilding', 'label': 'Admiral Makarov National University of Shipbuilding' },
    { 'id': 10618, 'value': 'Admiral Makarov State University of Maritime and Inland Shipping', 'label': 'Admiral Makarov State University of Maritime and Inland Shipping' },
    { 'id': 9305, 'value': 'Admiralty University of Nigeria', 'label': 'Admiralty University of Nigeria' },
    { 'id': 12213, 'value': 'Adrian College', 'label': 'Adrian College' },
    { 'id': 4666, 'value': 'Advanced Business College', 'label': 'Advanced Business College' },
    { 'id': 7063, 'value': 'Advanced Institute of Industrial Technology', 'label': 'Advanced Institute of Industrial Technology' },
    { 'id': 12214, 'value': 'AdventHealth University', 'label': 'AdventHealth University' },
    { 'id': 9817, 'value': 'Adventist International Institute of Advanced Studies', 'label': 'Adventist International Institute of Advanced Studies' },
    { 'id': 7780, 'value': 'Adventist University of Africa', 'label': 'Adventist University of Africa' },
    { 'id': 10991, 'value': 'Adventist University of Central Africa', 'label': 'Adventist University of Central Africa' },
    { 'id': 9818, 'value': 'Adventist University of the Philippines', 'label': 'Adventist University of the Philippines' },
    { 'id': 4888, 'value': 'Adventista Teológiai Fõiskola', 'label': 'Adventista Teológiai Fõiskola' },
    { 'id': 10619, 'value': 'Adyghe State University', 'label': 'Adyghe State University' },
    { 'id': 9511, 'value': 'Aeres Hogeschool', 'label': 'Aeres Hogeschool' },
    { 'id': 9306, 'value': 'Afe Babalola University', 'label': 'Afe Babalola University' },
    { 'id': 5531, 'value': 'Afeka Tel Aviv Academic College of Engineering', 'label': 'Afeka Tel Aviv Academic College of Engineering' },
    { 'id': 680, 'value': 'Afghan Pamir Higher Education Institute', 'label': 'Afghan Pamir Higher Education Institute' },
    { 'id': 681, 'value': 'Afghan Swiss Medical Institute of Higher Education', 'label': 'Afghan Swiss Medical Institute of Higher Education' },
    { 'id': 14246, 'value': 'Africa Christian University', 'label': 'Africa Christian University' },
    { 'id': 7781, 'value': 'Africa International University', 'label': 'Africa International University' },
    { 'id': 7782, 'value': 'Africa Nazarene University', 'label': 'Africa Nazarene University' },
    { 'id': 12158, 'value': 'Africa Renewal University', 'label': 'Africa Renewal University' },
    { 'id': 14247, 'value': 'Africa Research University', 'label': 'Africa Research University' },
    { 'id': 14300, 'value': 'Africa University', 'label': 'Africa University' },
    { 'id': 12159, 'value': 'African Bible University', 'label': 'African Bible University' },
    { 'id': 10992, 'value': 'African Leadership University, Rwanda', 'label': 'African Leadership University, Rwanda' },
    { 'id': 8326, 'value': 'African Methodist Episcopal University', 'label': 'African Methodist Episcopal University' },
    { 'id': 12160, 'value': 'African Rural University', 'label': 'African Rural University' },
    { 'id': 4667, 'value': 'African University College of Communications', 'label': 'African University College of Communications' },
    { 'id': 4474, 'value': 'African University of Management', 'label': 'African University of Management' },
    { 'id': 9307, 'value': 'African University of Science and Technology', 'label': 'African University of Science and Technology' },
    { 'id': 11626, 'value': 'Afyon Kocatepe Üniversitesi', 'label': 'Afyon Kocatepe Üniversitesi' },
    { 'id': 10048, 'value': 'Aga Khan University', 'label': 'Aga Khan University' },
    { 'id': 12215, 'value': 'Agnes Scott College', 'label': 'Agnes Scott College' },
    { 'id': 11627, 'value': 'Agri Ibrahim Çeçen Universitesi', 'label': 'Agri Ibrahim Çeçen Universitesi' },
    { 'id': 4736, 'value': 'Agricultural University of Athens', 'label': 'Agricultural University of Athens' },
    { 'id': 4615, 'value': 'Agricultural University of Georgia', 'label': 'Agricultural University of Georgia' },
    { 'id': 2106, 'value': 'Agricultural University of Hebei', 'label': 'Agricultural University of Hebei' },
    { 'id': 6810, 'value': 'Agricultural University of Iceland', 'label': 'Agricultural University of Iceland' },
    { 'id': 1422, 'value': 'Agricultural University of Plovdiv', 'label': 'Agricultural University of Plovdiv' },
    { 'id': 9591, 'value': 'Agriculture and Forestry University', 'label': 'Agriculture and Forestry University' },
    { 'id': 4246, 'value': 'Agrocampus Ouest', 'label': 'Agrocampus Ouest' },
    { 'id': 4247, 'value': 'AgroParisTech', 'label': 'AgroParisTech' },
    { 'id': 11057, 'value': 'Ahfad University for Women', 'label': 'Ahfad University for Women' },
    { 'id': 11628, 'value': 'Ahi Evran Üniversitesi', 'label': 'Ahi Evran Üniversitesi' },
    { 'id': 1468, 'value': 'Ahlia University', 'label': 'Ahlia University' },
    { 'id': 682, 'value': 'Ahlobait Institute of Higher Education', 'label': 'Ahlobait Institute of Higher Education' },
    { 'id': 6500, 'value': 'Ahlul Bayt International University', 'label': 'Ahlul Bayt International University' },
    { 'id': 9308, 'value': 'Ahmadu Bello University', 'label': 'Ahmadu Bello University' },
    { 'id': 5571, 'value': 'Ahmedabad University', 'label': 'Ahmedabad University' },
    { 'id': 8165, 'value': 'Ahmet Yesevi Üniversitesi', 'label': 'Ahmet Yesevi Üniversitesi' },
    { 'id': 4039, 'value': 'Ahram Canadian University', 'label': 'Ahram Canadian University' },
    { 'id': 1203, 'value': 'Ahsanullah University of Science and Technology', 'label': 'Ahsanullah University of Science and Technology' },
    { 'id': 6501, 'value': 'Ahvaz Jundishapur University of Medical Sciences', 'label': 'Ahvaz Jundishapur University of Medical Sciences' },
    { 'id': 14428, 'value': 'AIAF Formazione', 'label': 'AIAF Formazione' },
    { 'id': 7064, 'value': 'Aichi Bunkyo University', 'label': 'Aichi Bunkyo University' },
    { 'id': 7065, 'value': 'Aichi Gakuin University', 'label': 'Aichi Gakuin University' },
    { 'id': 7066, 'value': 'Aichi Gakusen University', 'label': 'Aichi Gakusen University' },
    { 'id': 7067, 'value': 'Aichi Institute of Technology', 'label': 'Aichi Institute of Technology' },
    { 'id': 7068, 'value': 'Aichi Medical University', 'label': 'Aichi Medical University' },
    { 'id': 7069, 'value': 'Aichi Mizuho College', 'label': 'Aichi Mizuho College' },
    { 'id': 7070, 'value': 'Aichi Prefectural University', 'label': 'Aichi Prefectural University' },
    { 'id': 7071, 'value': 'Aichi Prefectural University of Fine Arts and Music', 'label': 'Aichi Prefectural University of Fine Arts and Music' },
    { 'id': 7072, 'value': 'Aichi Sangyo University', 'label': 'Aichi Sangyo University' },
    { 'id': 7073, 'value': 'Aichi Shukutoku University', 'label': 'Aichi Shukutoku University' },
    { 'id': 7074, 'value': 'Aichi Toho University', 'label': 'Aichi Toho University' },
    { 'id': 7075, 'value': 'Aichi University', 'label': 'Aichi University' },
    { 'id': 7076, 'value': 'Aichi University of Education', 'label': 'Aichi University of Education' },
    { 'id': 7077, 'value': 'Aichi University of Technology', 'label': 'Aichi University of Technology' },
    { 'id': 7078, 'value': 'Aikoku Gakuen University', 'label': 'Aikoku Gakuen University' },
    { 'id': 4040, 'value': 'Ain Shams University', 'label': 'Ain Shams University' },
    { 'id': 7079, 'value': 'Aino University', 'label': 'Aino University' },
    { 'id': 12216, 'value': 'Air Force Institute of Technology', 'label': 'Air Force Institute of Technology' },
    { 'id': 10049, 'value': 'Air University', 'label': 'Air University' },
    { 'id': 603, 'value': 'Aix-Marseille Université', 'label': 'Aix-Marseille Université' },
    { 'id': 9309, 'value': 'Ajayi Crowther University', 'label': 'Ajayi Crowther University' },
    { 'id': 5572, 'value': 'Ajeenkya D.Y. Patil University', 'label': 'Ajeenkya D.Y. Patil University' },
    { 'id': 7033, 'value': 'Ajloun National Private University', 'label': 'Ajloun National Private University' },
    { 'id': 618, 'value': 'Ajman University', 'label': 'Ajman University' },
    { 'id': 7966, 'value': 'Ajou University', 'label': 'Ajou University' },
    { 'id': 3450, 'value': 'AKAD Privathochschulen', 'label': 'AKAD Privathochschulen' },
    { 'id': 10218, 'value': 'Akademia Finansów i Biznesu Vistula', 'label': 'Akademia Finansów i Biznesu Vistula' },
    { 'id': 10219, 'value': 'Akademia Górniczo-Hutnicza', 'label': 'Akademia Górniczo-Hutnicza' },
    { 'id': 10220, 'value': 'Akademia Humanistyczna im. Aleksandra Gieysztora', 'label': 'Akademia Humanistyczna im. Aleksandra Gieysztora' },
    { 'id': 10221, 'value': 'Akademia Humanistyczno-Ekonomiczna w Lodzi', 'label': 'Akademia Humanistyczno-Ekonomiczna w Lodzi' },
    { 'id': 10222, 'value': 'Akademia Leona Kozminskiego', 'label': 'Akademia Leona Kozminskiego' },
    { 'id': 10223, 'value': 'Akademia Morska w Szczecinie', 'label': 'Akademia Morska w Szczecinie' },
    { 'id': 10224, 'value': 'Akademia Muzyczna im. Feliksa Nowowiejskiego w Bydgoszczy', 'label': 'Akademia Muzyczna im. Feliksa Nowowiejskiego w Bydgoszczy' },
    { 'id': 10225, 'value': 'Akademia Muzyczna im. Grazyny i Kiejstuta Bacewiczów w Lodzi', 'label': 'Akademia Muzyczna im. Grazyny i Kiejstuta Bacewiczów w Lodzi' },
    { 'id': 10226, 'value': 'Akademia Muzyczna im. Ignacego Jana Paderewskiego w Poznaniu', 'label': 'Akademia Muzyczna im. Ignacego Jana Paderewskiego w Poznaniu' },
    { 'id': 10227, 'value': 'Akademia Muzyczna im. Karola Lipinskiego we Wroclawiu', 'label': 'Akademia Muzyczna im. Karola Lipinskiego we Wroclawiu' },
    { 'id': 10228, 'value': 'Akademia Muzyczna im. Karola Szymanowskiego w Katowicach', 'label': 'Akademia Muzyczna im. Karola Szymanowskiego w Katowicach' },
    { 'id': 10229, 'value': 'Akademia Muzyczna im. Stanislawa Moniuszki w Gdansku', 'label': 'Akademia Muzyczna im. Stanislawa Moniuszki w Gdansku' },
    { 'id': 10230, 'value': 'Akademia Muzyczna w Krakowie', 'label': 'Akademia Muzyczna w Krakowie' },
    { 'id': 10231, 'value': 'Akademia Pedagogiki Specjalnej im. Marii Grzegorzewskiej', 'label': 'Akademia Pedagogiki Specjalnej im. Marii Grzegorzewskiej' },
    { 'id': 10232, 'value': 'Akademia Polonijna w Czestochowie', 'label': 'Akademia Polonijna w Czestochowie' },
    { 'id': 10233, 'value': 'Akademia Pomorska w Slupsku', 'label': 'Akademia Pomorska w Slupsku' },
    { 'id': 10234, 'value': 'Akademia Sztuk Pieknych im. Eugeniusza Gepperta we Wroclawiu', 'label': 'Akademia Sztuk Pieknych im. Eugeniusza Gepperta we Wroclawiu' },
    { 'id': 10235, 'value': 'Akademia Sztuk Pieknych im. Jana Matejki w Krakowie', 'label': 'Akademia Sztuk Pieknych im. Jana Matejki w Krakowie' },
    { 'id': 10236, 'value': 'Akademia Sztuk Pieknych im. Wladyslawa Strzeminskiego w Lodzi', 'label': 'Akademia Sztuk Pieknych im. Wladyslawa Strzeminskiego w Lodzi' },
    { 'id': 10237, 'value': 'Akademia Sztuk Pieknych w Gdansku', 'label': 'Akademia Sztuk Pieknych w Gdansku' },
    { 'id': 10238, 'value': 'Akademia Sztuk Pieknych w Katowicach', 'label': 'Akademia Sztuk Pieknych w Katowicach' },
    { 'id': 10239, 'value': 'Akademia Sztuk Pieknych w Warszawie', 'label': 'Akademia Sztuk Pieknych w Warszawie' },
    { 'id': 10240, 'value': 'Akademia Sztuk Teatralnych im. Stanislawa Wyspianskiego w Krakowie', 'label': 'Akademia Sztuk Teatralnych im. Stanislawa Wyspianskiego w Krakowie' },
    { 'id': 10241, 'value': 'Akademia Techniczno-Humanistyczna', 'label': 'Akademia Techniczno-Humanistyczna' },
    { 'id': 11186, 'value': 'Akadémia umení v Banskej Bystrici', 'label': 'Akadémia umení v Banskej Bystrici' },
    { 'id': 10242, 'value': 'Akademia Wychowania Fizycznego im. Bronislawa Czecha w Krakowie', 'label': 'Akademia Wychowania Fizycznego im. Bronislawa Czecha w Krakowie' },
    { 'id': 10243, 'value': 'Akademia Wychowania Fizycznego im. Eugeniusza Piaseckiego w Poznaniu', 'label': 'Akademia Wychowania Fizycznego im. Eugeniusza Piaseckiego w Poznaniu' },
    { 'id': 10244, 'value': 'Akademia Wychowania Fizycznego im. Jerzego Kukuczki w Katowicach', 'label': 'Akademia Wychowania Fizycznego im. Jerzego Kukuczki w Katowicach' },
    { 'id': 10245, 'value': 'Akademia Wychowania Fizycznego Józefa Pilsudskiego w Warszawie', 'label': 'Akademia Wychowania Fizycznego Józefa Pilsudskiego w Warszawie' },
    { 'id': 10246, 'value': 'Akademia Wychowania Fizycznego we Wroclawiu', 'label': 'Akademia Wychowania Fizycznego we Wroclawiu' },
    { 'id': 3451, 'value': 'Akademie der Bildenden Künste München', 'label': 'Akademie der Bildenden Künste München' },
    { 'id': 3452, 'value': 'Akademie der Bildenden Künste Nürnberg', 'label': 'Akademie der Bildenden Künste Nürnberg' },
    { 'id': 999, 'value': 'Akademie der Bildenden Künste Wien', 'label': 'Akademie der Bildenden Künste Wien' },
    { 'id': 3392, 'value': 'Akademie múzických umení v Praze', 'label': 'Akademie múzických umení v Praze' },
    { 'id': 3393, 'value': 'Akademie STING', 'label': 'Akademie STING' },
    { 'id': 3394, 'value': 'Akademie výtvarných umení v Praze', 'label': 'Akademie výtvarných umení v Praze' },
    { 'id': 4616, 'value': 'Akaki Tsereteli State University', 'label': 'Akaki Tsereteli State University' },
    { 'id': 5573, 'value': 'Akal University', 'label': 'Akal University' },
    { 'id': 3395, 'value': 'AKCENT College', 'label': 'AKCENT College' },
    { 'id': 3341, 'value': 'Akdeniz Karpaz Üniversitesi', 'label': 'Akdeniz Karpaz Üniversitesi' },
    { 'id': 11629, 'value': 'Akdeniz Üniversitesi', 'label': 'Akdeniz Üniversitesi' },
    { 'id': 7080, 'value': 'Akita International University', 'label': 'Akita International University' },
    { 'id': 7081, 'value': 'Akita Prefectural University', 'label': 'Akita Prefectural University' },
    { 'id': 7082, 'value': 'Akita University', 'label': 'Akita University' },
    { 'id': 7083, 'value': 'Akita University of Nursing and Welfare', 'label': 'Akita University of Nursing and Welfare' },
    { 'id': 3453, 'value': 'Akkon Hochschule für Humanwissenschaften', 'label': 'Akkon Hochschule für Humanwissenschaften' },
    { 'id': 9819, 'value': 'Aklan State University', 'label': 'Aklan State University' },
    { 'id': 11286, 'value': 'Akobo Heritage and Memorial University', 'label': 'Akobo Heritage and Memorial University' },
    { 'id': 5574, 'value': 'AKS University', 'label': 'AKS University' },
    { 'id': 11630, 'value': 'Aksaray Üniversitesi', 'label': 'Aksaray Üniversitesi' },
    { 'id': 4177, 'value': 'Aksum University', 'label': 'Aksum University' },
    { 'id': 8166, 'value': 'Aktobe Regional State University', 'label': 'Aktobe Regional State University' },
    { 'id': 8167, 'value': 'Aktobe University S. Baisheva', 'label': 'Aktobe University S. Baisheva' },
    { 'id': 9310, 'value': 'Akwa Ibom State University', 'label': 'Akwa Ibom State University' },
    { 'id': 619, 'value': 'Al Ain University', 'label': 'Al Ain University' },
    { 'id': 7034, 'value': 'Al al-Bayt University', 'label': 'Al al-Bayt University' },
    { 'id': 4041, 'value': 'Al Alamein International University', 'label': 'Al Alamein International University' },
    { 'id': 11330, 'value': 'Al Andalus University for Medical Sciences', 'label': 'Al Andalus University for Medical Sciences' },
    { 'id': 10377, 'value': 'Al Azhar University-Gaza', 'label': 'Al Azhar University-Gaza' },
    { 'id': 11017, 'value': 'Al Baha University', 'label': 'Al Baha University' },
    { 'id': 683, 'value': 'Al Beroni University', 'label': 'Al Beroni University' },
    { 'id': 620, 'value': 'Al Dar University College', 'label': 'Al Dar University College' },
    { 'id': 621, 'value': 'Al Falah University', 'label': 'Al Falah University' },
    { 'id': 11058, 'value': 'Al Fashir University', 'label': 'Al Fashir University' },
    { 'id': 622, 'value': 'Al Ghurair University', 'label': 'Al Ghurair University' },
    { 'id': 7035, 'value': 'Al Hussein Technical University', 'label': 'Al Hussein Technical University' },
    { 'id': 8273, 'value': 'Al Imam Al-Ouzai University', 'label': 'Al Imam Al-Ouzai University' },
    { 'id': 6439, 'value': 'Al Iraqia University', 'label': 'Al Iraqia University' },
    { 'id': 10378, 'value': 'Al Istiqlal University', 'label': 'Al Istiqlal University' },
    { 'id': 14177, 'value': 'Al Jazeera University', 'label': 'Al Jazeera University' },
    { 'id': 11018, 'value': 'Al Jouf University', 'label': 'Al Jouf University' },
    { 'id': 9608, 'value': 'Al Musanna College of Technology', 'label': 'Al Musanna College of Technology' },
    { 'id': 6502, 'value': 'Al Mustafa International University', 'label': 'Al Mustafa International University' },
    { 'id': 6440, 'value': 'Al Muthanna University', 'label': 'Al Muthanna University' },
    { 'id': 6441, 'value': 'Al Nahrain University', 'label': 'Al Nahrain University' },
    { 'id': 623, 'value': 'Al Qasimia University', 'label': 'Al Qasimia University' },
    { 'id': 11331, 'value': 'Al Rasheed International Private University for Science and Technology', 'label': 'Al Rasheed International Private University for Science and Technology' },
    { 'id': 9609, 'value': 'Al Sharqiyah University', 'label': 'Al Sharqiyah University' },
    { 'id': 624, 'value': 'Al Wasl University', 'label': 'Al Wasl University' },
    { 'id': 11019, 'value': 'Al Yamamah University', 'label': 'Al Yamamah University' },
    { 'id': 11059, 'value': 'Al Zaiem Al Azhari University', 'label': 'Al Zaiem Al Azhari University' },
    { 'id': 14178, 'value': 'Al-Ahgaff University', 'label': 'Al-Ahgaff University' },
    { 'id': 7036, 'value': 'Al-Ahliyya Amman University', 'label': 'Al-Ahliyya Amman University' },
    { 'id': 10379, 'value': 'Al-Aqsa University', 'label': 'Al-Aqsa University' },
    { 'id': 4042, 'value': 'Al-Azhar University', 'label': 'Al-Azhar University' },
    { 'id': 11332, 'value': 'Al-Baath University', 'label': 'Al-Baath University' },
    { 'id': 7037, 'value': 'Al-Balqa\' Applied University', 'label': 'Al-Balqa\' Applied University' },
    { 'id': 9610, 'value': 'Al-Buraimi University College', 'label': 'Al-Buraimi University College' },
    { 'id': 14181, 'value': 'Al-Eman University', 'label': 'Al-Eman University' },
    { 'id': 5576, 'value': 'Al-Falah University', 'label': 'Al-Falah University' },
    { 'id': 8168, 'value': 'Al-Farabi Kazakh National University', 'label': 'Al-Farabi Kazakh National University' },
    { 'id': 6442, 'value': 'Al-Furat Al-Awsat Technical University', 'label': 'Al-Furat Al-Awsat Technical University' },
    { 'id': 11333, 'value': 'Al-Furat University', 'label': 'Al-Furat University' },
    { 'id': 11334, 'value': 'Al-Hawash Private University', 'label': 'Al-Hawash Private University' },
    { 'id': 14182, 'value': 'Al-Hikma University', 'label': 'Al-Hikma University' },
    { 'id': 9312, 'value': 'Al-Hikmah University', 'label': 'Al-Hikmah University' },
    { 'id': 7038, 'value': 'Al-Hussein Bin Talal University', 'label': 'Al-Hussein Bin Talal University' },
    { 'id': 11021, 'value': 'Al-Imam Muhammad Ibn Saud Islamic University', 'label': 'Al-Imam Muhammad Ibn Saud Islamic University' },
    { 'id': 6443, 'value': 'Al-Karkh University of Science', 'label': 'Al-Karkh University of Science' },
    { 'id': 9238, 'value': 'Al-Madinah International University', 'label': 'Al-Madinah International University' },
    { 'id': 8397, 'value': 'Al-Mergib University', 'label': 'Al-Mergib University' },
    { 'id': 14183, 'value': 'Al-Nasser University', 'label': 'Al-Nasser University' },
    { 'id': 9313, 'value': 'Al-Qalam University, Katsina', 'label': 'Al-Qalam University, Katsina' },
    { 'id': 6444, 'value': 'Al-Qasim Green University', 'label': 'Al-Qasim Green University' },
    { 'id': 10380, 'value': 'Al-Quds University', 'label': 'Al-Quds University' },
    { 'id': 14184, 'value': 'Al-Rayan University', 'label': 'Al-Rayan University' },
    { 'id': 14185, 'value': 'Al-Razi University', 'label': 'Al-Razi University' },
    { 'id': 14186, 'value': 'Al-Saeeda University', 'label': 'Al-Saeeda University' },
    { 'id': 11336, 'value': 'Al-Shahbaa University', 'label': 'Al-Shahbaa University' },
    { 'id': 11337, 'value': 'Al-Sham Private University', 'label': 'Al-Sham Private University' },
    { 'id': 686, 'value': 'Al-taqwa Institute of Higher Education', 'label': 'Al-taqwa Institute of Higher Education' },
    { 'id': 11338, 'value': 'Al-Wataniya Private University', 'label': 'Al-Wataniya Private University' },
    { 'id': 9611, 'value': 'Al-Zahra College for Women', 'label': 'Al-Zahra College for Women' },
    { 'id': 7039, 'value': 'Al-Zaytoonah University of Jordan', 'label': 'Al-Zaytoonah University of Jordan' },
    { 'id': 7844, 'value': 'Ala-Too Uluslararasi Üniversitesi', 'label': 'Ala-Too Uluslararasi Üniversitesi' },
    { 'id': 12217, 'value': 'Alabama A&M University', 'label': 'Alabama A&M University' },
    { 'id': 12218, 'value': 'Alabama State University', 'label': 'Alabama State University' },
    { 'id': 5575, 'value': 'Alagappa University', 'label': 'Alagappa University' },
    { 'id': 14179, 'value': 'Alandalus University for Science and Technology', 'label': 'Alandalus University for Science and Technology' },
    { 'id': 3454, 'value': 'Alanus Hochschule für Kunst und Gesellschaft', 'label': 'Alanus Hochschule für Kunst und Gesellschaft' },
    { 'id': 11631, 'value': 'Alanya Alaaddin Keykubat Üniversitesi', 'label': 'Alanya Alaaddin Keykubat Üniversitesi' },
    { 'id': 11632, 'value': 'Alanya Hamdullah Emin Pasa Üniversitesi', 'label': 'Alanya Hamdullah Emin Pasa Üniversitesi' },
    { 'id': 12219, 'value': 'Alaska Bible College', 'label': 'Alaska Bible College' },
    { 'id': 12220, 'value': 'Alaska Pacific University', 'label': 'Alaska Pacific University' },
    { 'id': 12221, 'value': 'Albany College of Pharmacy and Health Sciences', 'label': 'Albany College of Pharmacy and Health Sciences' },
    { 'id': 12222, 'value': 'Albany Law School', 'label': 'Albany Law School' },
    { 'id': 12223, 'value': 'Albany Medical College', 'label': 'Albany Medical College' },
    { 'id': 12224, 'value': 'Albany State University', 'label': 'Albany State University' },
    { 'id': 14180, 'value': 'Albaydaa University', 'label': 'Albaydaa University' },
    { 'id': 3455, 'value': 'Albert-Ludwigs-Universität Freiburg', 'label': 'Albert-Ludwigs-Universität Freiburg' },
    { 'id': 12225, 'value': 'Albertus Magnus College', 'label': 'Albertus Magnus College' },
    { 'id': 12226, 'value': 'Albion College', 'label': 'Albion College' },
    { 'id': 684, 'value': 'Alborz Institute of Higher Education', 'label': 'Alborz Institute of Higher Education' },
    { 'id': 12227, 'value': 'Albright College', 'label': 'Albright College' },
    { 'id': 12228, 'value': 'Alcorn State University', 'label': 'Alcorn State University' },
    { 'id': 9820, 'value': 'Aldersgate College', 'label': 'Aldersgate College' },
    { 'id': 12229, 'value': 'Alderson Broaddus University', 'label': 'Alderson Broaddus University' },
    { 'id': 11804, 'value': 'Aletheia University', 'label': 'Aletheia University' },
    { 'id': 9311, 'value': 'Alex Ekwueme Federal University, Ndufu-Alike', 'label': 'Alex Ekwueme Federal University, Ndufu-Alike' },
    { 'id': 4788, 'value': 'Alexander American University School Of Medicine', 'label': 'Alexander American University School Of Medicine' },
    { 'id': 3342, 'value': 'Alexander College', 'label': 'Alexander College' },
    { 'id': 4737, 'value': 'Alexander Technological Educational Institute of Thessaloniki', 'label': 'Alexander Technological Educational Institute of Thessaloniki' },
    { 'id': 4043, 'value': 'Alexandria University', 'label': 'Alexandria University' },
    { 'id': 10596, 'value': 'Alfa BK Univerzitet', 'label': 'Alfa BK Univerzitet' },
    { 'id': 11020, 'value': 'Alfaisal University', 'label': 'Alfaisal University' },
    { 'id': 11980, 'value': 'Alfred Nobel University', 'label': 'Alfred Nobel University' },
    { 'id': 12230, 'value': 'Alfred State College', 'label': 'Alfred State College' },
    { 'id': 12231, 'value': 'Alfred University', 'label': 'Alfred University' },
    { 'id': 1850, 'value': 'Algoma University', 'label': 'Algoma University' },
    { 'id': 10050, 'value': 'Alhamd Islamic University', 'label': 'Alhamd Islamic University' },
    { 'id': 10051, 'value': 'Ali Institute of Education', 'label': 'Ali Institute of Education' },
    { 'id': 5577, 'value': 'Aliah University', 'label': 'Aliah University' },
    { 'id': 12232, 'value': 'Alice Lloyd College', 'label': 'Alice Lloyd College' },
    { 'id': 3456, 'value': 'Alice Salomon Hochschule Berlin', 'label': 'Alice Salomon Hochschule Berlin' },
    { 'id': 5578, 'value': 'Aligarh Muslim University', 'label': 'Aligarh Muslim University' },
    { 'id': 246, 'value': 'Alitalia Spa', 'label': 'Alitalia Spa' },
    { 'id': 11335, 'value': 'Aljazeera University', 'label': 'Aljazeera University' },
    { 'id': 5503, 'value': 'All Hallows College', 'label': 'All Hallows College' },
    { 'id': 5579, 'value': 'All India Institute of Medical Sciences Bhopal', 'label': 'All India Institute of Medical Sciences Bhopal' },
    { 'id': 5580, 'value': 'All India Institute of Medical Sciences Bhubaneswar', 'label': 'All India Institute of Medical Sciences Bhubaneswar' },
    { 'id': 5581, 'value': 'All India Institute of Medical Sciences Delhi', 'label': 'All India Institute of Medical Sciences Delhi' },
    { 'id': 5582, 'value': 'All India Institute of Medical Sciences Jodhpur', 'label': 'All India Institute of Medical Sciences Jodhpur' },
    { 'id': 5583, 'value': 'All India Institute of Medical Sciences Patna', 'label': 'All India Institute of Medical Sciences Patna' },
    { 'id': 5584, 'value': 'All India Institute of Medical Sciences Raipur', 'label': 'All India Institute of Medical Sciences Raipur' },
    { 'id': 5585, 'value': 'All India Institute of Medical Sciences Rishikesh', 'label': 'All India Institute of Medical Sciences Rishikesh' },
    { 'id': 4668, 'value': 'All Nations University College', 'label': 'All Nations University College' },
    { 'id': 12161, 'value': 'All Saints University', 'label': 'All Saints University' },
    { 'id': 5586, 'value': 'Allahabad State University', 'label': 'Allahabad State University' },
    { 'id': 685, 'value': 'Allameh Institute of Higher Education', 'label': 'Allameh Institute of Higher Education' },
    { 'id': 6503, 'value': 'Allameh Mohaddes Nouri University', 'label': 'Allameh Mohaddes Nouri University' },
    { 'id': 6504, 'value': 'Allameh Tabataba\'i University', 'label': 'Allameh Tabataba\'i University' },
    { 'id': 12233, 'value': 'Allegheny College', 'label': 'Allegheny College' },
    { 'id': 12234, 'value': 'Allen College', 'label': 'Allen College' },
    { 'id': 12235, 'value': 'Allen University', 'label': 'Allen University' },
    { 'id': 3457, 'value': 'Allensbach Hochschule Konstanz', 'label': 'Allensbach Hochschule Konstanz' },
    { 'id': 5587, 'value': 'Alliance University', 'label': 'Alliance University' },
    { 'id': 12236, 'value': 'Alliant International University', 'label': 'Alliant International University' },
    { 'id': 596, 'value': 'ALMA - Scuola Internazionale di Cucina Italiana', 'label': 'ALMA - Scuola Internazionale di Cucina Italiana' },
    { 'id': 196, 'value': 'Alma Artis - Accademia di Belle Arti', 'label': 'Alma Artis - Accademia di Belle Arti' },
    { 'id': 12237, 'value': 'Alma College', 'label': 'Alma College' },
    { 'id': 11155, 'value': 'Alma Mater Europaea', 'label': 'Alma Mater Europaea' },
    { 'id': 8169, 'value': 'Almaty Academy of Economics and Statistics', 'label': 'Almaty Academy of Economics and Statistics' },
    { 'id': 8170, 'value': 'Almaty Management University', 'label': 'Almaty Management University' },
    { 'id': 8171, 'value': 'Almaty Technological University', 'label': 'Almaty Technological University' },
    { 'id': 8172, 'value': 'Almaty University', 'label': 'Almaty University' },
    { 'id': 8173, 'value': 'Almaty University of Power Engineering and Telecommunications', 'label': 'Almaty University of Power Engineering and Telecommunications' },
    { 'id': 11060, 'value': 'AlMughtaribeen University', 'label': 'AlMughtaribeen University' },
    { 'id': 1000, 'value': 'Alpen-Adria-Universität Klagenfurt', 'label': 'Alpen-Adria-Universität Klagenfurt' },
    { 'id': 11061, 'value': 'Alsalam University', 'label': 'Alsalam University' },
    { 'id': 10620, 'value': 'Altai State Academy of Education', 'label': 'Altai State Academy of Education' },
    { 'id': 10621, 'value': 'Altai State Medical University', 'label': 'Altai State Medical University' },
    { 'id': 10622, 'value': 'Altai State Pedagogical Academy', 'label': 'Altai State Pedagogical Academy' },
    { 'id': 10623, 'value': 'Altai State University', 'label': 'Altai State University' },
    { 'id': 10624, 'value': 'Altai State University of Agriculture', 'label': 'Altai State University of Agriculture' },
    { 'id': 11633, 'value': 'Altinbas Üniversitesi', 'label': 'Altinbas Üniversitesi' },
    { 'id': 12238, 'value': 'Alvernia University', 'label': 'Alvernia University' },
    { 'id': 12239, 'value': 'Alverno College', 'label': 'Alverno College' },
    { 'id': 8332, 'value': 'Alytaus kolegija', 'label': 'Alytaus kolegija' },
    { 'id': 6505, 'value': 'Alzahra University', 'label': 'Alzahra University' },
    { 'id': 308, 'value': 'AM4 – Software Training Center', 'label': 'AM4 – Software Training Center' },
    { 'id': 9821, 'value': 'AMA Computer University', 'label': 'AMA Computer University' },
    { 'id': 1469, 'value': 'AMA International University Bahrain', 'label': 'AMA International University Bahrain' },
    { 'id': 260, 'value': 'Amani Institute', 'label': 'Amani Institute' },
    { 'id': 11634, 'value': 'Amasya Üniversitesi', 'label': 'Amasya Üniversitesi' },
    { 'id': 14248, 'value': 'Ambassador International University', 'label': 'Ambassador International University' },
    { 'id': 5588, 'value': 'Ambedkar University Delhi', 'label': 'Ambedkar University Delhi' },
    { 'id': 12240, 'value': 'Amberton University', 'label': 'Amberton University' },
    { 'id': 4178, 'value': 'Ambo University', 'label': 'Ambo University' },
    { 'id': 9314, 'value': 'Ambrose Alli University', 'label': 'Ambrose Alli University' },
    { 'id': 1851, 'value': 'Ambrose University', 'label': 'Ambrose University' },
    { 'id': 3458, 'value': 'AMD Akademie Mode and Design', 'label': 'AMD Akademie Mode and Design' },
    { 'id': 255, 'value': 'AMD Akademie Mode e Design', 'label': 'AMD Akademie Mode e Design' },
    { 'id': 12241, 'value': 'American Baptist College', 'label': 'American Baptist College' },
    { 'id': 3343, 'value': 'American College', 'label': 'American College' },
    { 'id': 625, 'value': 'American College of Dubai', 'label': 'American College of Dubai' },
    { 'id': 12242, 'value': 'American Film Institute Conservatory', 'label': 'American Film Institute Conservatory' },
    { 'id': 12243, 'value': 'American International College', 'label': 'American International College' },
    { 'id': 4789, 'value': 'American International School of Medicine', 'label': 'American International School of Medicine' },
    { 'id': 4731, 'value': 'American International University West Africa', 'label': 'American International University West Africa' },
    { 'id': 1204, 'value': 'American International University-Bangladesh', 'label': 'American International University-Bangladesh' },
    { 'id': 12244, 'value': 'American Jewish University', 'label': 'American Jewish University' },
    { 'id': 12245, 'value': 'American University', 'label': 'American University' },
    { 'id': 1423, 'value': 'American University in Bulgaria', 'label': 'American University in Bulgaria' },
    { 'id': 626, 'value': 'American University in Dubai', 'label': 'American University in Dubai' },
    { 'id': 11551, 'value': 'American University in North Africa', 'label': 'American University in North Africa' },
    { 'id': 627, 'value': 'American University in the Emirates', 'label': 'American University in the Emirates' },
    { 'id': 776, 'value': 'American University of Antigua', 'label': 'American University of Antigua' },
    { 'id': 809, 'value': 'American University of Armenia', 'label': 'American University of Armenia' },
    { 'id': 1200, 'value': 'American University of Barbados', 'label': 'American University of Barbados' },
    { 'id': 8274, 'value': 'American University of Beirut', 'label': 'American University of Beirut' },
    { 'id': 7845, 'value': 'American University of Central Asia', 'label': 'American University of Central Asia' },
    { 'id': 8275, 'value': 'American University of Culture and Education', 'label': 'American University of Culture and Education' },
    { 'id': 1201, 'value': 'American University of Integrative Sciences', 'label': 'American University of Integrative Sciences' },
    { 'id': 8155, 'value': 'American University of Kuwait', 'label': 'American University of Kuwait' },
    { 'id': 7040, 'value': 'American University of Madaba', 'label': 'American University of Madaba' },
    { 'id': 8666, 'value': 'American University of Malta', 'label': 'American University of Malta' },
    { 'id': 9315, 'value': 'American University of Nigeria', 'label': 'American University of Nigeria' },
    { 'id': 7886, 'value': 'American University of Phnom Penh', 'label': 'American University of Phnom Penh' },
    { 'id': 10344, 'value': 'American University of Puerto Rico', 'label': 'American University of Puerto Rico' },
    { 'id': 628, 'value': 'American University of Ras Al Khaimah', 'label': 'American University of Ras Al Khaimah' },
    { 'id': 582, 'value': 'American University of Rome', 'label': 'American University of Rome' },
    { 'id': 8276, 'value': 'American University of Science and Technology', 'label': 'American University of Science and Technology' },
    { 'id': 629, 'value': 'American University of Sharjah', 'label': 'American University of Sharjah' },
    { 'id': 8277, 'value': 'American University of Technology', 'label': 'American University of Technology' },
    { 'id': 4863, 'value': 'American University of the Caribbean', 'label': 'American University of the Caribbean' },
    { 'id': 11329, 'value': 'American University of the Caribbean - School of Medicine', 'label': 'American University of the Caribbean - School of Medicine' },
    { 'id': 1154, 'value': 'Americki Univerzitet u Bosni i Hercegovini', 'label': 'Americki Univerzitet u Bosni i Hercegovini' },
    { 'id': 12246, 'value': 'Amherst College', 'label': 'Amherst College' },
    { 'id': 6506, 'value': 'Amirkabir University of Technology', 'label': 'Amirkabir University of Technology' },
    { 'id': 5589, 'value': 'Amity University', 'label': 'Amity University' },
    { 'id': 630, 'value': 'Amity University Dubai', 'label': 'Amity University Dubai' },
    { 'id': 7041, 'value': 'Amman Arab University', 'label': 'Amman Arab University' },
    { 'id': 7042, 'value': 'Ammon Applied University College', 'label': 'Ammon Applied University College' },
    { 'id': 257, 'value': 'AMOS - Sport Business School', 'label': 'AMOS - Sport Business School' },
    { 'id': 14187, 'value': 'Amran University', 'label': 'Amran University' },
    { 'id': 7783, 'value': 'Amref International University', 'label': 'Amref International University' },
    { 'id': 5590, 'value': 'Amrita Vishwa Vidyapeetham', 'label': 'Amrita Vishwa Vidyapeetham' },
    { 'id': 327, 'value': 'Amsterdam University of the Arts', 'label': 'Amsterdam University of the Arts' },
    { 'id': 9512, 'value': 'Amsterdamse Hogeschool voor de Kunsten', 'label': 'Amsterdamse Hogeschool voor de Kunsten' },
    { 'id': 10625, 'value': 'Amur State Medical Academy', 'label': 'Amur State Medical Academy' },
    { 'id': 10626, 'value': 'Amur State University', 'label': 'Amur State University' },
    { 'id': 10627, 'value': 'Amur State University of Humanities and Pedagogy', 'label': 'Amur State University of Humanities and Pedagogy' },
    { 'id': 10381, 'value': 'An-Najah National University', 'label': 'An-Najah National University' },
    { 'id': 11635, 'value': 'Anadolu Üniversitesi', 'label': 'Anadolu Üniversitesi' },
    { 'id': 5591, 'value': 'Anand Agricultural University', 'label': 'Anand Agricultural University' },
    { 'id': 810, 'value': 'Anania Shirakatsi University of International Relations', 'label': 'Anania Shirakatsi University of International Relations' },
    { 'id': 5592, 'value': 'Anant National University', 'label': 'Anant National University' },
    { 'id': 9316, 'value': 'Anchor University, Lagos', 'label': 'Anchor University, Lagos' },
    { 'id': 12247, 'value': 'Anderson University', 'label': 'Anderson University' },
    { 'id': 12248, 'value': 'Anderson University, Indiana', 'label': 'Anderson University, Indiana' },
    { 'id': 5593, 'value': 'Andhra University', 'label': 'Andhra University' },
    { 'id': 13983, 'value': 'Andijon Davlat Tibbiyot Instituti', 'label': 'Andijon Davlat Tibbiyot Instituti' },
    { 'id': 13984, 'value': 'Andijon Davlat Universiteti', 'label': 'Andijon Davlat Universiteti' },
    { 'id': 13985, 'value': 'Andijon Mashinasozlik Instituti', 'label': 'Andijon Mashinasozlik Instituti' },
    { 'id': 13986, 'value': 'Andijon Qishloq Xo\'jalik Instituti', 'label': 'Andijon Qishloq Xo\'jalik Instituti' },
    { 'id': 7967, 'value': 'Andong National University', 'label': 'Andong National University' },
    { 'id': 4889, 'value': 'Andrássy Universität Budapest', 'label': 'Andrássy Universität Budapest' },
    { 'id': 12249, 'value': 'Andrews University', 'label': 'Andrews University' },
    { 'id': 10628, 'value': 'Angarsk State Technical University', 'label': 'Angarsk State Technical University' },
    { 'id': 1424, 'value': 'Angel Kanchev University of Ruse', 'label': 'Angel Kanchev University of Ruse' },
    { 'id': 9822, 'value': 'Angeles University Foundation', 'label': 'Angeles University Foundation' },
    { 'id': 12250, 'value': 'Angelo State University', 'label': 'Angelo State University' },
    { 'id': 7887, 'value': 'Angkor Khemara University', 'label': 'Angkor Khemara University' },
    { 'id': 7888, 'value': 'Angkor University', 'label': 'Angkor University' },
    { 'id': 4499, 'value': 'Anglia Ruskin University', 'label': 'Anglia Ruskin University' },
    { 'id': 4669, 'value': 'Anglican University College of Technology', 'label': 'Anglican University College of Technology' },
    { 'id': 3396, 'value': 'Anglo-americká vysoká škola', 'label': 'Anglo-americká vysoká škola' },
    { 'id': 2107, 'value': 'Anhui Agricultural University', 'label': 'Anhui Agricultural University' },
    { 'id': 2108, 'value': 'Anhui Foreign Languages University', 'label': 'Anhui Foreign Languages University' },
    { 'id': 2109, 'value': 'Anhui Institute of Information Technology', 'label': 'Anhui Institute of Information Technology' },
    { 'id': 2110, 'value': 'Anhui Jianzhu University', 'label': 'Anhui Jianzhu University' },
    { 'id': 2111, 'value': 'Anhui Medical University', 'label': 'Anhui Medical University' },
    { 'id': 2112, 'value': 'Anhui Normal University', 'label': 'Anhui Normal University' },
    { 'id': 2113, 'value': 'Anhui Polytechnic University', 'label': 'Anhui Polytechnic University' },
    { 'id': 2114, 'value': 'Anhui Sanlian University', 'label': 'Anhui Sanlian University' },
    { 'id': 2115, 'value': 'Anhui Science and Technology University', 'label': 'Anhui Science and Technology University' },
    { 'id': 2116, 'value': 'Anhui University', 'label': 'Anhui University' },
    { 'id': 2117, 'value': 'Anhui University of Chinese Medicine', 'label': 'Anhui University of Chinese Medicine' },
    { 'id': 2118, 'value': 'Anhui University of Finance and Economics', 'label': 'Anhui University of Finance and Economics' },
    { 'id': 2119, 'value': 'Anhui University of Science and Technology', 'label': 'Anhui University of Science and Technology' },
    { 'id': 2120, 'value': 'Anhui University of Technology', 'label': 'Anhui University of Technology' },
    { 'id': 2121, 'value': 'Anhui Wonder University of Information Engineering', 'label': 'Anhui Wonder University of Information Engineering' },
    { 'id': 2122, 'value': 'Anhui Xinhua University', 'label': 'Anhui Xinhua University' },
    { 'id': 11636, 'value': 'Anka Teknoloji Üniversitesi', 'label': 'Anka Teknoloji Üniversitesi' },
    { 'id': 2123, 'value': 'Ankang University', 'label': 'Ankang University' },
    { 'id': 11637, 'value': 'Ankara Sosyal Bilimler Üniversitesi', 'label': 'Ankara Sosyal Bilimler Üniversitesi' },
    { 'id': 11638, 'value': 'Ankara Üniversitesi', 'label': 'Ankara Üniversitesi' },
    { 'id': 11639, 'value': 'Ankara Yildirim Beyazit Üniversitesi', 'label': 'Ankara Yildirim Beyazit Üniversitesi' },
    { 'id': 12162, 'value': 'Ankole Western University', 'label': 'Ankole Western University' },
    { 'id': 12251, 'value': 'Anna Maria College', 'label': 'Anna Maria College' },
    { 'id': 5594, 'value': 'Anna University', 'label': 'Anna University' },
    { 'id': 5595, 'value': 'Annamalai University', 'label': 'Annamalai University' },
    { 'id': 2124, 'value': 'Anqing Normal University', 'label': 'Anqing Normal University' },
    { 'id': 5596, 'value': 'Ansal University', 'label': 'Ansal University' },
    { 'id': 2125, 'value': 'Anshan Normal University', 'label': 'Anshan Normal University' },
    { 'id': 2126, 'value': 'Anshun University', 'label': 'Anshun University' },
    { 'id': 11640, 'value': 'Antalya Akev Üniversitesi', 'label': 'Antalya Akev Üniversitesi' },
    { 'id': 11641, 'value': 'Antalya Bilim Üniversitesi', 'label': 'Antalya Bilim Üniversitesi' },
    { 'id': 12252, 'value': 'Antioch University', 'label': 'Antioch University' },
    { 'id': 12253, 'value': 'Antioch University Los Angeles', 'label': 'Antioch University Los Angeles' },
    { 'id': 12254, 'value': 'Antioch University New England', 'label': 'Antioch University New England' },
    { 'id': 12255, 'value': 'Antioch University Santa Barbara', 'label': 'Antioch University Santa Barbara' },
    { 'id': 12256, 'value': 'Antioch University Seattle', 'label': 'Antioch University Seattle' },
    { 'id': 1001, 'value': 'Anton Bruckner Privatuniversität', 'label': 'Anton Bruckner Privatuniversität' },
    { 'id': 11283, 'value': 'Anton de Kom Universiteit van Suriname', 'label': 'Anton de Kom Universiteit van Suriname' },
    { 'id': 507, 'value': 'Antwerp Management School', 'label': 'Antwerp Management School' },
    { 'id': 1205, 'value': 'Anwer Khan Modern University', 'label': 'Anwer Khan Modern University' },
    { 'id': 2127, 'value': 'Anyang Institute of Technology', 'label': 'Anyang Institute of Technology' },
    { 'id': 2128, 'value': 'Anyang Normal University', 'label': 'Anyang Normal University' },
    { 'id': 2129, 'value': 'Anyang University', 'label': 'Anyang University' },
    { 'id': 7084, 'value': 'Aomori Chuo Gakuin University', 'label': 'Aomori Chuo Gakuin University' },
    { 'id': 7085, 'value': 'Aomori Public University', 'label': 'Aomori Public University' },
    { 'id': 7086, 'value': 'Aomori University', 'label': 'Aomori University' },
    { 'id': 7087, 'value': 'Aomori University of Health and Welfare', 'label': 'Aomori University of Health and Welfare' },
    { 'id': 7088, 'value': 'Aoyama Gakuin University', 'label': 'Aoyama Gakuin University' },
    { 'id': 5597, 'value': 'AP Goyal Shimla University', 'label': 'AP Goyal Shimla University' },
    { 'id': 1342, 'value': 'AP Hogeschool Antwerpen', 'label': 'AP Hogeschool Antwerpen' },
    { 'id': 5598, 'value': 'Apeejay Stya University', 'label': 'Apeejay Stya University' },
    { 'id': 5599, 'value': 'Apex Professional University', 'label': 'Apex Professional University' },
    { 'id': 5600, 'value': 'APJ Abdul Kalam Technological University', 'label': 'APJ Abdul Kalam Technological University' },
    { 'id': 12257, 'value': 'Appalachian Bible College', 'label': 'Appalachian Bible College' },
    { 'id': 12258, 'value': 'Appalachian College of Pharmacy', 'label': 'Appalachian College of Pharmacy' },
    { 'id': 12259, 'value': 'Appalachian State University', 'label': 'Appalachian State University' },
    { 'id': 7043, 'value': 'Applied Science Private University', 'label': 'Applied Science Private University' },
    { 'id': 1470, 'value': 'Applied Science University', 'label': 'Applied Science University' },
    { 'id': 7044, 'value': 'Aqaba University of Technology', 'label': 'Aqaba University of Technology' },
    { 'id': 12260, 'value': 'Aquinas College', 'label': 'Aquinas College' },
    { 'id': 12261, 'value': 'Aquinas College, Tennessee', 'label': 'Aquinas College, Tennessee' },
    { 'id': 9823, 'value': 'Aquinas University of Legazpi', 'label': 'Aquinas University of Legazpi' },
    { 'id': 4044, 'value': 'Arab Academy for Science, Technology and Maritime Transport', 'label': 'Arab Academy for Science, Technology and Maritime Transport' },
    { 'id': 10382, 'value': 'Arab American University', 'label': 'Arab American University' },
    { 'id': 10383, 'value': 'Arab College of Applied Sciences', 'label': 'Arab College of Applied Sciences' },
    { 'id': 11339, 'value': 'Arab International University', 'label': 'Arab International University' },
    { 'id': 8278, 'value': 'Arab Open University Lebanon', 'label': 'Arab Open University Lebanon' },
    { 'id': 14188, 'value': 'Arab University for Science and Technology', 'label': 'Arab University for Science and Technology' },
    { 'id': 11340, 'value': 'Arab University of Science and Technology', 'label': 'Arab University of Science and Technology' },
    { 'id': 1471, 'value': 'Arabian Gulf University', 'label': 'Arabian Gulf University' },
    { 'id': 6507, 'value': 'Arak University', 'label': 'Arak University' },
    { 'id': 6508, 'value': 'Arak University of Medical Sciences', 'label': 'Arak University of Medical Sciences' },
    { 'id': 6509, 'value': 'Arak University of Technology', 'label': 'Arak University of Technology' },
    { 'id': 4179, 'value': 'Arba Minch University', 'label': 'Arba Minch University' },
    { 'id': 12262, 'value': 'Arcadia University', 'label': 'Arcadia University' },
    { 'id': 301, 'value': 'Architectural Association - School of Architecture', 'label': 'Architectural Association - School of Architecture' },
    { 'id': 3397, 'value': 'Architectural Institute in Prague', 'label': 'Architectural Institute in Prague' },
    { 'id': 6510, 'value': 'Ardabil University of Medical Sciences', 'label': 'Ardabil University of Medical Sciences' },
    { 'id': 11642, 'value': 'Ardahan Üniversitesi', 'label': 'Ardahan Üniversitesi' },
    { 'id': 6511, 'value': 'Ardakan University', 'label': 'Ardakan University' },
    { 'id': 14390, 'value': 'Arden University', 'label': 'Arden University' },
    { 'id': 11948, 'value': 'Ardhi University', 'label': 'Ardhi University' },
    { 'id': 9824, 'value': 'Arellano University', 'label': 'Arellano University' },
    { 'id': 687, 'value': 'Aria University', 'label': 'Aria University' },
    { 'id': 688, 'value': 'Ariana Institute of Higher Education', 'label': 'Ariana Institute of Higher Education' },
    { 'id': 5532, 'value': 'Ariel University', 'label': 'Ariel University' },
    { 'id': 4045, 'value': 'Arish University', 'label': 'Arish University' },
    { 'id': 4738, 'value': 'Aristotle University of Thessaloniki', 'label': 'Aristotle University of Thessaloniki' },
    { 'id': 12263, 'value': 'Arizona Christian University', 'label': 'Arizona Christian University' },
    { 'id': 12264, 'value': 'Arizona State University', 'label': 'Arizona State University' },
    { 'id': 5601, 'value': 'Arka Jain University', 'label': 'Arka Jain University' },
    { 'id': 8174, 'value': 'Arkalyk State Pedagogical Institute', 'label': 'Arkalyk State Pedagogical Institute' },
    { 'id': 12265, 'value': 'Arkansas Baptist College', 'label': 'Arkansas Baptist College' },
    { 'id': 12266, 'value': 'Arkansas State University', 'label': 'Arkansas State University' },
    { 'id': 12267, 'value': 'Arkansas Tech University', 'label': 'Arkansas Tech University' },
    { 'id': 3344, 'value': 'Arkin Yaratici Sanatlar ve Tasarim Üniversitesi', 'label': 'Arkin Yaratici Sanatlar ve Tasarim Üniversitesi' },
    { 'id': 3816, 'value': 'Arkitektskolen Aarhus', 'label': 'Arkitektskolen Aarhus' },
    { 'id': 9562, 'value': 'Arkitektur- og designhøgskolen i Oslo', 'label': 'Arkitektur- og designhøgskolen i Oslo' },
    { 'id': 12268, 'value': 'Arlington Baptist University', 'label': 'Arlington Baptist University' },
    { 'id': 811, 'value': 'Armenian Medical Institute', 'label': 'Armenian Medical Institute' },
    { 'id': 812, 'value': 'Armenian National Agrarian University', 'label': 'Armenian National Agrarian University' },
    { 'id': 813, 'value': 'Armenian State Institute of Physical Culture', 'label': 'Armenian State Institute of Physical Culture' },
    { 'id': 814, 'value': 'Armenian State Pedagogical University', 'label': 'Armenian State Pedagogical University' },
    { 'id': 815, 'value': 'Armenian State University of Economics', 'label': 'Armenian State University of Economics' },
    { 'id': 5602, 'value': 'Arni University', 'label': 'Arni University' },
    { 'id': 14301, 'value': 'Arrupe Jesuit University', 'label': 'Arrupe Jesuit University' },
    { 'id': 12269, 'value': 'Art Academy of Cincinnati', 'label': 'Art Academy of Cincinnati' },
    { 'id': 3398, 'value': 'Art and Design Institut', 'label': 'Art and Design Institut' },
    { 'id': 441, 'value': 'Art University Bournemouth', 'label': 'Art University Bournemouth' },
    { 'id': 6512, 'value': 'Art University of Isfahan', 'label': 'Art University of Isfahan' },
    { 'id': 12270, 'value': 'ArtCenter College of Design', 'label': 'ArtCenter College of Design' },
    { 'id': 8699, 'value': 'Arte A.C.', 'label': 'Arte A.C.' },
    { 'id': 3345, 'value': 'ARTE Music Academy', 'label': 'ARTE Music Academy' },
    { 'id': 1343, 'value': 'Arteveldehogeschool', 'label': 'Arteveldehogeschool' },
    { 'id': 9513, 'value': 'ArtEZ Hogeschool voor de kunsten', 'label': 'ArtEZ Hogeschool voor de kunsten' },
    { 'id': 11156, 'value': 'ArtHouse - Šola za risanje in slikanje', 'label': 'ArtHouse - Šola za risanje in slikanje' },
    { 'id': 9317, 'value': 'Arthur Jarvis University', 'label': 'Arthur Jarvis University' },
    { 'id': 4248, 'value': 'Arts et Métiers ParisTech', 'label': 'Arts et Métiers ParisTech' },
    { 'id': 4500, 'value': 'Arts University Bournemouth', 'label': 'Arts University Bournemouth' },
    { 'id': 8279, 'value': 'Arts, Sciences and Technology University in Lebanon', 'label': 'Arts, Sciences and Technology University in Lebanon' },
    { 'id': 1344, 'value': 'Arts² - École supérieure des Arts', 'label': 'Arts² - École supérieure des Arts' },
    { 'id': 816, 'value': 'Artsakh State University', 'label': 'Artsakh State University' },
    { 'id': 11643, 'value': 'Artvin Çoruh Üniversitesi', 'label': 'Artvin Çoruh Üniversitesi' },
    { 'id': 5603, 'value': 'Arunachal University of Studies', 'label': 'Arunachal University of Studies' },
    { 'id': 5604, 'value': 'Arunodaya University', 'label': 'Arunodaya University' },
    { 'id': 5605, 'value': 'Aryabhatta Knowledge University', 'label': 'Aryabhatta Knowledge University' },
    { 'id': 1206, 'value': 'ASA University Bangladesh', 'label': 'ASA University Bangladesh' },
    { 'id': 7089, 'value': 'Asahi University', 'label': 'Asahi University' },
    { 'id': 7090, 'value': 'Asahikawa Medical University', 'label': 'Asahikawa Medical University' },
    { 'id': 7091, 'value': 'Asahikawa University', 'label': 'Asahikawa University' },
    { 'id': 12271, 'value': 'Asbury University', 'label': 'Asbury University' },
    { 'id': 4670, 'value': 'Ashesi University', 'label': 'Ashesi University' },
    { 'id': 7092, 'value': 'Ashikaga Institute of Technology', 'label': 'Ashikaga Institute of Technology' },
    { 'id': 7093, 'value': 'Ashiya University', 'label': 'Ashiya University' },
    { 'id': 12272, 'value': 'Ashland University', 'label': 'Ashland University' },
    { 'id': 689, 'value': 'Ashna Institute of Higher Education', 'label': 'Ashna Institute of Higher Education' },
    { 'id': 5606, 'value': 'Ashoka University', 'label': 'Ashoka University' },
    { 'id': 11805, 'value': 'Asia - Pacific Institute of Creativity', 'label': 'Asia - Pacific Institute of Creativity' },
    { 'id': 9239, 'value': 'Asia e University', 'label': 'Asia e University' },
    { 'id': 7889, 'value': 'Asia Euro University', 'label': 'Asia Euro University' },
    { 'id': 9825, 'value': 'Asia Pacific College', 'label': 'Asia Pacific College' },
    { 'id': 7094, 'value': 'Asia University', 'label': 'Asia University' },
    { 'id': 11806, 'value': 'Asia University, Taiwan', 'label': 'Asia University, Taiwan' },
    { 'id': 11377, 'value': 'Asia-Pacific International University', 'label': 'Asia-Pacific International University' },
    { 'id': 9826, 'value': 'Asian Institute of Journalism and Communication', 'label': 'Asian Institute of Journalism and Communication' },
    { 'id': 9827, 'value': 'Asian Institute of Management', 'label': 'Asian Institute of Management' },
    { 'id': 9828, 'value': 'Asian Institute of Maritime Studies', 'label': 'Asian Institute of Maritime Studies' },
    { 'id': 11376, 'value': 'Asian Institute of Technology', 'label': 'Asian Institute of Technology' },
    { 'id': 7846, 'value': 'Asian Medical Institute', 'label': 'Asian Medical Institute' },
    { 'id': 9829, 'value': 'Asian Social Institute', 'label': 'Asian Social Institute' },
    { 'id': 1207, 'value': 'Asian University for Women', 'label': 'Asian University for Women' },
    { 'id': 1208, 'value': 'Asian University of Bangladesh', 'label': 'Asian University of Bangladesh' },
    { 'id': 4094, 'value': 'Asmara College of Health Sciences', 'label': 'Asmara College of Health Sciences' },
    { 'id': 8398, 'value': 'Asmarya University for Islamic Sciences', 'label': 'Asmarya University for Islamic Sciences' },
    { 'id': 14416, 'value': 'ASOMI Osteopatia', 'label': 'ASOMI Osteopatia' },
    { 'id': 5607, 'value': 'Assam Agricultural University', 'label': 'Assam Agricultural University' },
    { 'id': 5608, 'value': 'Assam Don Bosco University', 'label': 'Assam Don Bosco University' },
    { 'id': 5609, 'value': 'Assam Down Town University', 'label': 'Assam Down Town University' },
    { 'id': 5610, 'value': 'Assam Rajiv Gandhi University of Cooperative Management', 'label': 'Assam Rajiv Gandhi University of Cooperative Management' },
    { 'id': 5611, 'value': 'Assam Science and Technology University', 'label': 'Assam Science and Technology University' },
    { 'id': 5612, 'value': 'Assam University', 'label': 'Assam University' },
    { 'id': 5613, 'value': 'Assam Women\'s University', 'label': 'Assam Women\'s University' },
    { 'id': 4046, 'value': 'Assiut University', 'label': 'Assiut University' },
    { 'id': 4180, 'value': 'Assosa University', 'label': 'Assosa University' },
    { 'id': 12273, 'value': 'Assumption College', 'label': 'Assumption College' },
    { 'id': 9830, 'value': 'Assumption College San Lorenzo', 'label': 'Assumption College San Lorenzo' },
    { 'id': 11378, 'value': 'Assumption University', 'label': 'Assumption University' },
    { 'id': 8175, 'value': 'Astana Medical University', 'label': 'Astana Medical University' },
    { 'id': 8176, 'value': 'Astana University', 'label': 'Astana University' },
    { 'id': 4501, 'value': 'Aston University', 'label': 'Aston University' },
    { 'id': 10629, 'value': 'Astrakhan State Conservatory', 'label': 'Astrakhan State Conservatory' },
    { 'id': 10630, 'value': 'Astrakhan State Medical Academy', 'label': 'Astrakhan State Medical Academy' },
    { 'id': 10631, 'value': 'Astrakhan State Technical University', 'label': 'Astrakhan State Technical University' },
    { 'id': 10632, 'value': 'Astrakhan State University', 'label': 'Astrakhan State University' },
    { 'id': 4047, 'value': 'Aswan University', 'label': 'Aswan University' },
    { 'id': 5614, 'value': 'Atal Bihari Vajpayee Hindi Vishwavidyalaya', 'label': 'Atal Bihari Vajpayee Hindi Vishwavidyalaya' },
    { 'id': 5615, 'value': 'Atal Bihari Vajpayee Vishwavidyalaya', 'label': 'Atal Bihari Vajpayee Vishwavidyalaya' },
    { 'id': 11644, 'value': 'Atatürk Üniversitesi', 'label': 'Atatürk Üniversitesi' },
    { 'id': 9831, 'value': 'Ateneo de Davao University', 'label': 'Ateneo de Davao University' },
    { 'id': 9832, 'value': 'Ateneo de Manila University', 'label': 'Ateneo de Manila University' },
    { 'id': 9833, 'value': 'Ateneo de Naga University', 'label': 'Ateneo de Naga University' },
    { 'id': 9834, 'value': 'Ateneo de Zamboanga University', 'label': 'Ateneo de Zamboanga University' },
    { 'id': 4739, 'value': 'Athens School of Fine Arts', 'label': 'Athens School of Fine Arts' },
    { 'id': 12274, 'value': 'Athens State University', 'label': 'Athens State University' },
    { 'id': 4740, 'value': 'Athens University of Economics and Business', 'label': 'Athens University of Economics and Business' },
    { 'id': 5504, 'value': 'Athlone Institute of Technology', 'label': 'Athlone Institute of Technology' },
    { 'id': 9318, 'value': 'Atiba University', 'label': 'Atiba University' },
    { 'id': 11645, 'value': 'Atilim Üniversitesi', 'label': 'Atilim Üniversitesi' },
    { 'id': 1209, 'value': 'Atish Dipankar University of Science and Technology', 'label': 'Atish Dipankar University of Science and Technology' },
    { 'id': 12275, 'value': 'Atlanta Metropolitan State College', 'label': 'Atlanta Metropolitan State College' },
    { 'id': 3346, 'value': 'Atlantis College', 'label': 'Atlantis College' },
    { 'id': 7095, 'value': 'Atomi University', 'label': 'Atomi University' },
    { 'id': 8177, 'value': 'Atyrau Institute of Engineering and Humanities', 'label': 'Atyrau Institute of Engineering and Humanities' },
    { 'id': 8178, 'value': 'Atyrau State University', 'label': 'Atyrau State University' },
    { 'id': 8179, 'value': 'Atyrau University of Oil and Gas', 'label': 'Atyrau University of Oil and Gas' },
    { 'id': 12276, 'value': 'Auburn University', 'label': 'Auburn University' },
    { 'id': 12277, 'value': 'Auburn University at Montgomery', 'label': 'Auburn University at Montgomery' },
    { 'id': 9600, 'value': 'Auckland University of Technology', 'label': 'Auckland University of Technology' },
    { 'id': 4249, 'value': 'Audencia Business School', 'label': 'Audencia Business School' },
    { 'id': 12278, 'value': 'Augsburg College', 'label': 'Augsburg College' },
    { 'id': 12279, 'value': 'Augusta University', 'label': 'Augusta University' },
    { 'id': 12280, 'value': 'Augustana College', 'label': 'Augustana College' },
    { 'id': 12281, 'value': 'Augustana University', 'label': 'Augustana University' },
    { 'id': 3459, 'value': 'Augustana-Hochschule', 'label': 'Augustana-Hochschule' },
    { 'id': 9319, 'value': 'Augustine University', 'label': 'Augustine University' },
    { 'id': 14656, 'value': 'Aulab Hackademy', 'label': 'Aulab Hackademy' },
    { 'id': 1108, 'value': 'Aureus University School of Medicine', 'label': 'Aureus University School of Medicine' },
    { 'id': 5616, 'value': 'AURO University', 'label': 'AURO University' },
    { 'id': 1852, 'value': 'Aurora College', 'label': 'Aurora College' },
    { 'id': 9835, 'value': 'Aurora State College of Technology', 'label': 'Aurora State College of Technology' },
    { 'id': 12282, 'value': 'Aurora University', 'label': 'Aurora University' },
    { 'id': 12283, 'value': 'Austin College', 'label': 'Austin College' },
    { 'id': 12284, 'value': 'Austin Peay State University', 'label': 'Austin Peay State University' },
    { 'id': 1071, 'value': 'Australian Catholic University', 'label': 'Australian Catholic University' },
    { 'id': 8156, 'value': 'Australian College of Kuwait', 'label': 'Australian College of Kuwait' },
    { 'id': 395, 'value': 'Australian National University', 'label': 'Australian National University' },
    { 'id': 12163, 'value': 'Avance International University', 'label': 'Avance International University' },
    { 'id': 9514, 'value': 'AVANS Hogeschool', 'label': 'AVANS Hogeschool' },
    { 'id': 5617, 'value': 'Avantika University', 'label': 'Avantika University' },
    { 'id': 8590, 'value': 'Avarga Institute', 'label': 'Avarga Institute' },
    { 'id': 12285, 'value': 'Ave Maria University', 'label': 'Ave Maria University' },
    { 'id': 12286, 'value': 'Averett University', 'label': 'Averett University' },
    { 'id': 690, 'value': 'Avicenna University', 'label': 'Avicenna University' },
    { 'id': 4250, 'value': 'Avignon Université', 'label': 'Avignon Université' },
    { 'id': 12287, 'value': 'Avila University', 'label': 'Avila University' },
    { 'id': 5618, 'value': 'Avinashilingam University', 'label': 'Avinashilingam University' },
    { 'id': 11646, 'value': 'Avrasya Üniversitesi', 'label': 'Avrasya Üniversitesi' },
    { 'id': 5619, 'value': 'Awadhesh Pratap Singh University', 'label': 'Awadhesh Pratap Singh University' },
    { 'id': 6513, 'value': 'Ayatollah Borujerdi University', 'label': 'Ayatollah Borujerdi University' },
    { 'id': 11647, 'value': 'Aydin Adnan Menderes Üniversitesi', 'label': 'Aydin Adnan Menderes Üniversitesi' },
    { 'id': 5620, 'value': 'Ayush and Health Sciences University of Chhattisgarh', 'label': 'Ayush and Health Sciences University of Chhattisgarh' },
    { 'id': 7096, 'value': 'Azabu University', 'label': 'Azabu University' },
    { 'id': 14189, 'value': 'Azal University of Human Development', 'label': 'Azal University of Human Development' },
    { 'id': 14190, 'value': 'Azal University of Science and Technology', 'label': 'Azal University of Science and Technology' },
    { 'id': 6514, 'value': 'Azarbaijan Shahid Madani University', 'label': 'Azarbaijan Shahid Madani University' },
    { 'id': 1113, 'value': 'Azerbaycan Diller Universiteti', 'label': 'Azerbaycan Diller Universiteti' },
    { 'id': 1114, 'value': 'Azerbaycan Dövlet Aqrar Universiteti', 'label': 'Azerbaycan Dövlet Aqrar Universiteti' },
    { 'id': 1115, 'value': 'Azerbaycan Dövlet Beden Terbiyesi ve Idman Akademiyasi', 'label': 'Azerbaycan Dövlet Beden Terbiyesi ve Idman Akademiyasi' },
    { 'id': 1116, 'value': 'Azerbaycan Dövlet Deniz Akademiyasi', 'label': 'Azerbaycan Dövlet Deniz Akademiyasi' },
    { 'id': 1117, 'value': 'Azerbaycan Dövlet Iqtisad Universiteti', 'label': 'Azerbaycan Dövlet Iqtisad Universiteti' },
    { 'id': 1118, 'value': 'Azerbaycan Dövlet Medeniyyet ve Incesenet Universiteti', 'label': 'Azerbaycan Dövlet Medeniyyet ve Incesenet Universiteti' },
    { 'id': 1119, 'value': 'Azerbaycan Dövlet Neft ve Senaye Universiteti', 'label': 'Azerbaycan Dövlet Neft ve Senaye Universiteti' },
    { 'id': 1120, 'value': 'Azerbaycan Dövlet Pedaqoji Universiteti', 'label': 'Azerbaycan Dövlet Pedaqoji Universiteti' },
    { 'id': 1121, 'value': 'Azerbaycan Dövlet Ressamliq Akademiyasi', 'label': 'Azerbaycan Dövlet Ressamliq Akademiyasi' },
    { 'id': 1122, 'value': 'Azerbaycan Emek ve Sosial Münasibetler Akademiyasi', 'label': 'Azerbaycan Emek ve Sosial Münasibetler Akademiyasi' },
    { 'id': 1123, 'value': 'Azerbaycan Kooperasiya Universiteti', 'label': 'Azerbaycan Kooperasiya Universiteti' },
    { 'id': 1124, 'value': 'Azerbaycan Memarliq ve Insaat Universiteti', 'label': 'Azerbaycan Memarliq ve Insaat Universiteti' },
    { 'id': 1125, 'value': 'Azerbaycan Milli Konservatoriyasi', 'label': 'Azerbaycan Milli Konservatoriyasi' },
    { 'id': 1126, 'value': 'Azerbaycan Texniki Universiteti', 'label': 'Azerbaycan Texniki Universiteti' },
    { 'id': 1127, 'value': 'Azerbaycan Texnologiya Universiteti', 'label': 'Azerbaycan Texnologiya Universiteti' },
    { 'id': 1128, 'value': 'Azerbaycan Tibb Universiteti', 'label': 'Azerbaycan Tibb Universiteti' },
    { 'id': 1129, 'value': 'Azerbaycan Turizm ve Menecment Universiteti', 'label': 'Azerbaycan Turizm ve Menecment Universiteti' },
    { 'id': 1130, 'value': 'Azerbaycan Universiteti', 'label': 'Azerbaycan Universiteti' },
    { 'id': 691, 'value': 'Azhar Higher Education Institute', 'label': 'Azhar Higher Education Institute' },
    { 'id': 5621, 'value': 'Azim Premji University', 'label': 'Azim Premji University' },
    { 'id': 12288, 'value': 'Azusa Pacific University', 'label': 'Azusa Pacific University' },
    { 'id': 8399, 'value': 'Azzaytuna University', 'label': 'Azzaytuna University' },
    { 'id': 5622, 'value': 'B.S. Abdur Rahman Crescent Institute of Science and Technology', 'label': 'B.S. Abdur Rahman Crescent Institute of Science and Technology' },
    { 'id': 11157, 'value': 'B2 Visoka šola za poslovne vede', 'label': 'B2 Visoka šola za poslovne vede' },
    { 'id': 1787, 'value': 'BA ISAGO University', 'label': 'BA ISAGO University' },
    { 'id': 5623, 'value': 'Baba Farid University of Health Sciences', 'label': 'Baba Farid University of Health Sciences' },
    { 'id': 5624, 'value': 'Baba Ghulam Shah Badhshah University', 'label': 'Baba Ghulam Shah Badhshah University' },
    { 'id': 5625, 'value': 'Baba Mastnath University', 'label': 'Baba Mastnath University' },
    { 'id': 5626, 'value': 'Babasaheb Bhimrao Ambedkar Bihar University', 'label': 'Babasaheb Bhimrao Ambedkar Bihar University' },
    { 'id': 5627, 'value': 'Babasaheb Bhimrao Ambedkar University', 'label': 'Babasaheb Bhimrao Ambedkar University' },
    { 'id': 9320, 'value': 'Babcock University', 'label': 'Babcock University' },
    { 'id': 6515, 'value': 'Babol Noshirvani University of Technology', 'label': 'Babol Noshirvani University of Technology' },
    { 'id': 6516, 'value': 'Babol University of Medical Sciences', 'label': 'Babol University of Medical Sciences' },
    { 'id': 12289, 'value': 'Babson College', 'label': 'Babson College' },
    { 'id': 5628, 'value': 'Babu Banarasi Das University', 'label': 'Babu Banarasi Das University' },
    { 'id': 14109, 'value': 'Bac Lieu University', 'label': 'Bac Lieu University' },
    { 'id': 10052, 'value': 'Bacha Khan University', 'label': 'Bacha Khan University' },
    { 'id': 12290, 'value': 'Bacone College', 'label': 'Bacone College' },
    { 'id': 692, 'value': 'Badakhshan University', 'label': 'Badakhshan University' },
    { 'id': 5629, 'value': 'Baddi University of Emerging Sciences and Technologies', 'label': 'Baddi University of Emerging Sciences and Technologies' },
    { 'id': 4048, 'value': 'Badr University in Cairo', 'label': 'Badr University in Cairo' },
    { 'id': 7968, 'value': 'Baekseok University', 'label': 'Baekseok University' },
    { 'id': 693, 'value': 'Baghlan University', 'label': 'Baghlan University' },
    { 'id': 8500, 'value': 'Bago University', 'label': 'Bago University' },
    { 'id': 10053, 'value': 'Bahauddin Zakariya University', 'label': 'Bahauddin Zakariya University' },
    { 'id': 3347, 'value': 'Bahçesehir Kibris Üniversitesi', 'label': 'Bahçesehir Kibris Üniversitesi' },
    { 'id': 11648, 'value': 'Bahçesehir Üniversitesi', 'label': 'Bahçesehir Üniversitesi' },
    { 'id': 4181, 'value': 'Bahir Dar University', 'label': 'Bahir Dar University' },
    { 'id': 5630, 'value': 'BAHRA University', 'label': 'BAHRA University' },
    { 'id': 1472, 'value': 'Bahrain Institute for Banking and Finance', 'label': 'Bahrain Institute for Banking and Finance' },
    { 'id': 10054, 'value': 'Bahria University', 'label': 'Bahria University' },
    { 'id': 2130, 'value': 'Baicheng Normal University', 'label': 'Baicheng Normal University' },
    { 'id': 7097, 'value': 'Baika Women\'s University', 'label': 'Baika Women\'s University' },
    { 'id': 10633, 'value': 'Baikal State University', 'label': 'Baikal State University' },
    { 'id': 7098, 'value': 'Baiko Gakuin University', 'label': 'Baiko Gakuin University' },
    { 'id': 2131, 'value': 'Baise University', 'label': 'Baise University' },
    { 'id': 12291, 'value': 'Baker College', 'label': 'Baker College' },
    { 'id': 12292, 'value': 'Baker University', 'label': 'Baker University' },
    { 'id': 694, 'value': 'Bakhtar University', 'label': 'Bakhtar University' },
    { 'id': 1131, 'value': 'Baki Ali Neft Mektebi', 'label': 'Baki Ali Neft Mektebi' },
    { 'id': 1132, 'value': 'Baki Avrasiya Universiteti', 'label': 'Baki Avrasiya Universiteti' },
    { 'id': 1133, 'value': 'Baki Biznes Universiteti', 'label': 'Baki Biznes Universiteti' },
    { 'id': 1134, 'value': 'Baki Dövlet Universiteti', 'label': 'Baki Dövlet Universiteti' },
    { 'id': 1135, 'value': 'Baki Islam Universiteti', 'label': 'Baki Islam Universiteti' },
    { 'id': 1136, 'value': 'Baki Muhendislik Universiteti', 'label': 'Baki Muhendislik Universiteti' },
    { 'id': 1137, 'value': 'Baki Musiqi Akademiyasi', 'label': 'Baki Musiqi Akademiyasi' },
    { 'id': 1138, 'value': 'Baki Qizlar Universiteti', 'label': 'Baki Qizlar Universiteti' },
    { 'id': 1139, 'value': 'Baki Slavyan Universiteti', 'label': 'Baki Slavyan Universiteti' },
    { 'id': 11649, 'value': 'Bakirçay Üniversitesi', 'label': 'Bakirçay Üniversitesi' },
    { 'id': 12293, 'value': 'Baldwin Wallace University', 'label': 'Baldwin Wallace University' },
    { 'id': 11650, 'value': 'Balikesir Üniversitesi', 'label': 'Balikesir Üniversitesi' },
    { 'id': 9836, 'value': 'Baliuag University', 'label': 'Baliuag University' },
    { 'id': 695, 'value': 'Balkh University', 'label': 'Balkh University' },
    { 'id': 12294, 'value': 'Ball State University', 'label': 'Ball State University' },
    { 'id': 10055, 'value': 'Balochistan University of Engineering and Technology', 'label': 'Balochistan University of Engineering and Technology' },
    { 'id': 10056, 'value': 'Balochistan University of Information Technology, Engineering and Management Sciences', 'label': 'Balochistan University of Information Technology, Engineering and Management Sciences' },
    { 'id': 10634, 'value': 'Baltic State Technical University "Voenmeh"', 'label': 'Baltic State Technical University "Voenmeh"' },
    { 'id': 8369, 'value': 'Baltijas Starptautiska akademija', 'label': 'Baltijas Starptautiska akademija' },
    { 'id': 6517, 'value': 'Bam University', 'label': 'Bam University' },
    { 'id': 2090, 'value': 'Bamenda University of Science and Technology', 'label': 'Bamenda University of Science and Technology' },
    { 'id': 696, 'value': 'Bamiyan University', 'label': 'Bamiyan University' },
    { 'id': 5631, 'value': 'Banaras Hindu University', 'label': 'Banaras Hindu University' },
    { 'id': 5632, 'value': 'Banasthali Vidyapith', 'label': 'Banasthali Vidyapith' },
    { 'id': 5633, 'value': 'Banda University of Agriculture and Technology', 'label': 'Banda University of Agriculture and Technology' },
    { 'id': 1210, 'value': 'Bandarban University', 'label': 'Bandarban University' },
    { 'id': 11651, 'value': 'Bandirma Onyedi Eylül Üniversitesi', 'label': 'Bandirma Onyedi Eylül Üniversitesi' },
    { 'id': 1211, 'value': 'Bangabandhu Sheikh Mujib Medical University', 'label': 'Bangabandhu Sheikh Mujib Medical University' },
    { 'id': 1212, 'value': 'Bangabandhu Sheikh Mujibur Rahman Agricultural University', 'label': 'Bangabandhu Sheikh Mujibur Rahman Agricultural University' },
    { 'id': 1213, 'value': 'Bangabandhu Sheikh Mujibur Rahman Maritime University', 'label': 'Bangabandhu Sheikh Mujibur Rahman Maritime University' },
    { 'id': 1214, 'value': 'Bangabandhu Sheikh Mujibur Rahman Science and Technology University', 'label': 'Bangabandhu Sheikh Mujibur Rahman Science and Technology University' },
    { 'id': 5634, 'value': 'Bangalore University', 'label': 'Bangalore University' },
    { 'id': 1215, 'value': 'Bangamata Sheikh Fojilatunnesa Mujib Science and Technology University', 'label': 'Bangamata Sheikh Fojilatunnesa Mujib Science and Technology University' },
    { 'id': 11379, 'value': 'Bangkok Suvarnabhumi University', 'label': 'Bangkok Suvarnabhumi University' },
    { 'id': 11380, 'value': 'Bangkok Thonburi University', 'label': 'Bangkok Thonburi University' },
    { 'id': 11381, 'value': 'Bangkok University', 'label': 'Bangkok University' },
    { 'id': 1216, 'value': 'Bangladesh Agricultural University', 'label': 'Bangladesh Agricultural University' },
    { 'id': 1217, 'value': 'Bangladesh Islami University', 'label': 'Bangladesh Islami University' },
    { 'id': 1218, 'value': 'Bangladesh University', 'label': 'Bangladesh University' },
    { 'id': 1219, 'value': 'Bangladesh University of Business and Technology', 'label': 'Bangladesh University of Business and Technology' },
    { 'id': 1220, 'value': 'Bangladesh University of Engineering and Technology', 'label': 'Bangladesh University of Engineering and Technology' },
    { 'id': 1221, 'value': 'Bangladesh University of Health Sciences', 'label': 'Bangladesh University of Health Sciences' },
    { 'id': 1222, 'value': 'Bangladesh University of Professionals', 'label': 'Bangladesh University of Professionals' },
    { 'id': 1223, 'value': 'Bangladesh University of Textiles', 'label': 'Bangladesh University of Textiles' },
    { 'id': 446, 'value': 'Bangor University', 'label': 'Bangor University' },
    { 'id': 1155, 'value': 'Banja Luka College', 'label': 'Banja Luka College' },
    { 'id': 4617, 'value': 'Bank of Georgia University', 'label': 'Bank of Georgia University' },
    { 'id': 12295, 'value': 'Bank Street College of Education', 'label': 'Bank Street College of Education' },
    { 'id': 13987, 'value': 'Bank-moliya Akademiyasi', 'label': 'Bank-moliya Akademiyasi' },
    { 'id': 11981, 'value': 'Banking University', 'label': 'Banking University' },
    { 'id': 8370, 'value': 'Banku augstskola', 'label': 'Banku augstskola' },
    { 'id': 5635, 'value': 'Bankura University', 'label': 'Bankura University' },
    { 'id': 11382, 'value': 'Bansomdejchaopraya Rajabhat University', 'label': 'Bansomdejchaopraya Rajabhat University' },
    { 'id': 2132, 'value': 'Baoding University', 'label': 'Baoding University' },
    { 'id': 2133, 'value': 'Baoji University of Arts and Sciences', 'label': 'Baoji University of Arts and Sciences' },
    { 'id': 2134, 'value': 'Baoshan University', 'label': 'Baoshan University' },
    { 'id': 12296, 'value': 'Baptist Bible College', 'label': 'Baptist Bible College' },
    { 'id': 12297, 'value': 'Baptist Memorial College of Health Sciences', 'label': 'Baptist Memorial College of Health Sciences' },
    { 'id': 12298, 'value': 'Baptist University of the Americas', 'label': 'Baptist University of the Americas' },
    { 'id': 10057, 'value': 'Baqai Medical University', 'label': 'Baqai Medical University' },
    { 'id': 6518, 'value': 'Baqir al-olum University', 'label': 'Baqir al-olum University' },
    { 'id': 6519, 'value': 'Baqiyatallah Medical Sciences University', 'label': 'Baqiyatallah Medical Sciences University' },
    { 'id': 5533, 'value': 'Bar-Ilan University', 'label': 'Bar-Ilan University' },
    { 'id': 1801, 'value': 'Baranovichi State University', 'label': 'Baranovichi State University' },
    { 'id': 12299, 'value': 'Barclay College', 'label': 'Barclay College' },
    { 'id': 12300, 'value': 'Bard College', 'label': 'Bard College' },
    { 'id': 12301, 'value': 'Bard College at Simon\'s Rock', 'label': 'Bard College at Simon\'s Rock' },
    { 'id': 3460, 'value': 'Bard College Berlin', 'label': 'Bard College Berlin' },
    { 'id': 5636, 'value': 'Bareilly International University', 'label': 'Bareilly International University' },
    { 'id': 14110, 'value': 'Baria Vungtau University', 'label': 'Baria Vungtau University' },
    { 'id': 5637, 'value': 'Barkatullah University', 'label': 'Barkatullah University' },
    { 'id': 3841, 'value': 'Barna Business School', 'label': 'Barna Business School' },
    { 'id': 12302, 'value': 'Barnard College', 'label': 'Barnard College' },
    { 'id': 12303, 'value': 'Barnes-Jewish College Goldfarb School of Nursing', 'label': 'Barnes-Jewish College Goldfarb School of Nursing' },
    { 'id': 12304, 'value': 'Barry University', 'label': 'Barry University' },
    { 'id': 11652, 'value': 'Bartin Üniversitesi', 'label': 'Bartin Üniversitesi' },
    { 'id': 12305, 'value': 'Barton College', 'label': 'Barton College' },
    { 'id': 12306, 'value': 'Baruch College, CUNY', 'label': 'Baruch College, CUNY' },
    { 'id': 10635, 'value': 'Bashkir State Agricultural University', 'label': 'Bashkir State Agricultural University' },
    { 'id': 10636, 'value': 'Bashkir State Medical University', 'label': 'Bashkir State Medical University' },
    { 'id': 10637, 'value': 'Bashkir State University', 'label': 'Bashkir State University' },
    { 'id': 11653, 'value': 'Baskent Üniversitesi', 'label': 'Baskent Üniversitesi' },
    { 'id': 6445, 'value': 'Basra University of Oil an Gas', 'label': 'Basra University of Oil an Gas' },
    { 'id': 5638, 'value': 'Bastar Vishwavidyalaya', 'label': 'Bastar Vishwavidyalaya' },
    { 'id': 12307, 'value': 'Bastyr University', 'label': 'Bastyr University' },
    { 'id': 9837, 'value': 'Bataan Peninsula State University', 'label': 'Bataan Peninsula State University' },
    { 'id': 9838, 'value': 'Batangas State University', 'label': 'Batangas State University' },
    { 'id': 12308, 'value': 'Bates College', 'label': 'Bates College' },
    { 'id': 4502, 'value': 'Bath Spa University', 'label': 'Bath Spa University' },
    { 'id': 7847, 'value': 'Batken State University', 'label': 'Batken State University' },
    { 'id': 11654, 'value': 'Batman Üniversitesi', 'label': 'Batman Üniversitesi' },
    { 'id': 4618, 'value': 'Batumi Art Teaching University', 'label': 'Batumi Art Teaching University' },
    { 'id': 4619, 'value': 'Batumi Navigation Teaching University', 'label': 'Batumi Navigation Teaching University' },
    { 'id': 4620, 'value': 'Batumi Shota Rustaveli State University', 'label': 'Batumi Shota Rustaveli State University' },
    { 'id': 4621, 'value': 'Batumi State Maritime Academy', 'label': 'Batumi State Maritime Academy' },
    { 'id': 4622, 'value': 'BAU International University, Batumi', 'label': 'BAU International University, Batumi' },
    { 'id': 9321, 'value': 'Bauchi State University', 'label': 'Bauchi State University' },
    { 'id': 3461, 'value': 'Bauhaus-Universität Weimar', 'label': 'Bauhaus-Universität Weimar' },
    { 'id': 10638, 'value': 'Bauman Moscow State Technical University', 'label': 'Bauman Moscow State Technical University' },
    { 'id': 12309, 'value': 'Bay Path University', 'label': 'Bay Path University' },
    { 'id': 12310, 'value': 'Bay State College', 'label': 'Bay State College' },
    { 'id': 9612, 'value': 'Bayan College', 'label': 'Bayan College' },
    { 'id': 6446, 'value': 'Bayan University', 'label': 'Bayan University' },
    { 'id': 11655, 'value': 'Bayburt Üniversitesi', 'label': 'Bayburt Üniversitesi' },
    { 'id': 9322, 'value': 'Bayero University Kano', 'label': 'Bayero University Kano' },
    { 'id': 12311, 'value': 'Baylor College of Medicine', 'label': 'Baylor College of Medicine' },
    { 'id': 12312, 'value': 'Baylor University', 'label': 'Baylor University' },
    { 'id': 9323, 'value': 'Baze University', 'label': 'Baze University' },
    { 'id': 4475, 'value': 'BBS School of Management', 'label': 'BBS School of Management' },
    { 'id': 3462, 'value': 'bbw Hochschule', 'label': 'bbw Hochschule' },
    { 'id': 12313, 'value': 'Beacon College', 'label': 'Beacon College' },
    { 'id': 10058, 'value': 'Beaconhouse National University', 'label': 'Beaconhouse National University' },
    { 'id': 12314, 'value': 'Becker College', 'label': 'Becker College' },
    { 'id': 11109, 'value': 'Beckmans Designhögskola', 'label': 'Beckmans Designhögskola' },
    { 'id': 1224, 'value': 'Begum Gulchemonara Trust University', 'label': 'Begum Gulchemonara Trust University' },
    { 'id': 1225, 'value': 'Begum Rokeya University', 'label': 'Begum Rokeya University' },
    { 'id': 6520, 'value': 'Behbahan Khatam Alanbia University of Technology', 'label': 'Behbahan Khatam Alanbia University of Technology' },
    { 'id': 2135, 'value': 'Beihai College of Art and Design', 'label': 'Beihai College of Art and Design' },
    { 'id': 2136, 'value': 'Beihang University', 'label': 'Beihang University' },
    { 'id': 2137, 'value': 'Beihua University', 'label': 'Beihua University' },
    { 'id': 2138, 'value': 'Beijing City University', 'label': 'Beijing City University' },
    { 'id': 2139, 'value': 'Beijing Dance Academy', 'label': 'Beijing Dance Academy' },
    { 'id': 2140, 'value': 'Beijing Electronic Science and Technology Institute', 'label': 'Beijing Electronic Science and Technology Institute' },
    { 'id': 2141, 'value': 'Beijing Film Academy', 'label': 'Beijing Film Academy' },
    { 'id': 2142, 'value': 'Beijing Foreign Studies University', 'label': 'Beijing Foreign Studies University' },
    { 'id': 2143, 'value': 'Beijing Forestry University', 'label': 'Beijing Forestry University' },
    { 'id': 2144, 'value': 'Beijing Geely University', 'label': 'Beijing Geely University' },
    { 'id': 2145, 'value': 'Beijing Information Science and Technology University', 'label': 'Beijing Information Science and Technology University' },
    { 'id': 2146, 'value': 'Beijing Institute of Fashion Technology', 'label': 'Beijing Institute of Fashion Technology' },
    { 'id': 2147, 'value': 'Beijing Institute of Graphic Communication', 'label': 'Beijing Institute of Graphic Communication' },
    { 'id': 2148, 'value': 'Beijing Institute of Petrochemical Technology', 'label': 'Beijing Institute of Petrochemical Technology' },
    { 'id': 2149, 'value': 'Beijing Institute of Technology', 'label': 'Beijing Institute of Technology' },
    { 'id': 2150, 'value': 'Beijing International Studies University', 'label': 'Beijing International Studies University' },
    { 'id': 2151, 'value': 'Beijing Jiaotong University', 'label': 'Beijing Jiaotong University' },
    { 'id': 2152, 'value': 'Beijing Language and Culture University', 'label': 'Beijing Language and Culture University' },
    { 'id': 2153, 'value': 'Beijing Normal University', 'label': 'Beijing Normal University' },
    { 'id': 2154, 'value': 'Beijing Sport University', 'label': 'Beijing Sport University' },
    { 'id': 2155, 'value': 'Beijing Technology and Business University', 'label': 'Beijing Technology and Business University' },
    { 'id': 2156, 'value': 'Beijing Union University', 'label': 'Beijing Union University' },
    { 'id': 2157, 'value': 'Beijing University of Agriculture', 'label': 'Beijing University of Agriculture' },
    { 'id': 2158, 'value': 'Beijing University of Chemical Technology', 'label': 'Beijing University of Chemical Technology' },
    { 'id': 2159, 'value': 'Beijing University of Chinese Medicine', 'label': 'Beijing University of Chinese Medicine' },
    { 'id': 2160, 'value': 'Beijing University of Civil Engineering and Architecture', 'label': 'Beijing University of Civil Engineering and Architecture' },
    { 'id': 2161, 'value': 'Beijing University of Posts and Telecommunications', 'label': 'Beijing University of Posts and Telecommunications' },
    { 'id': 2162, 'value': 'Beijing University of Technology', 'label': 'Beijing University of Technology' },
    { 'id': 2163, 'value': 'Beijing Wuzi University', 'label': 'Beijing Wuzi University' },
    { 'id': 8280, 'value': 'Beirut Arab University', 'label': 'Beirut Arab University' },
    { 'id': 8281, 'value': 'Beirut Islamic University', 'label': 'Beirut Islamic University' },
    { 'id': 1802, 'value': 'Belarusian National Technical University', 'label': 'Belarusian National Technical University' },
    { 'id': 1803, 'value': 'Belarusian State Academy of Arts', 'label': 'Belarusian State Academy of Arts' },
    { 'id': 1804, 'value': 'Belarusian State Academy of Aviation', 'label': 'Belarusian State Academy of Aviation' },
    { 'id': 1805, 'value': 'Belarusian State Academy of Communications', 'label': 'Belarusian State Academy of Communications' },
    { 'id': 1806, 'value': 'Belarusian State Academy of Music', 'label': 'Belarusian State Academy of Music' },
    { 'id': 1807, 'value': 'Belarusian State Agrarian Technical University', 'label': 'Belarusian State Agrarian Technical University' },
    { 'id': 1808, 'value': 'Belarusian State Agricultural Academy', 'label': 'Belarusian State Agricultural Academy' },
    { 'id': 1809, 'value': 'Belarusian State Economic University', 'label': 'Belarusian State Economic University' },
    { 'id': 1810, 'value': 'Belarusian State Medical University', 'label': 'Belarusian State Medical University' },
    { 'id': 1811, 'value': 'Belarusian State Pedagogical University', 'label': 'Belarusian State Pedagogical University' },
    { 'id': 1812, 'value': 'Belarusian State Technological University', 'label': 'Belarusian State Technological University' },
    { 'id': 1813, 'value': 'Belarusian State University', 'label': 'Belarusian State University' },
    { 'id': 1814, 'value': 'Belarusian State University of Culture and Arts', 'label': 'Belarusian State University of Culture and Arts' },
    { 'id': 1815, 'value': 'Belarusian State University of Informatics and Radioelectronics', 'label': 'Belarusian State University of Informatics and Radioelectronics' },
    { 'id': 1816, 'value': 'Belarusian State University of Physical Culture', 'label': 'Belarusian State University of Physical Culture' },
    { 'id': 1817, 'value': 'Belarusian State University of Transport', 'label': 'Belarusian State University of Transport' },
    { 'id': 1818, 'value': 'Belarusian Trade and Economics University of Consumer Cooperatives', 'label': 'Belarusian Trade and Economics University of Consumer Cooperatives' },
    { 'id': 1819, 'value': 'Belarusian-Russian University', 'label': 'Belarusian-Russian University' },
    { 'id': 10639, 'value': 'Belgorod State Agricultural University', 'label': 'Belgorod State Agricultural University' },
    { 'id': 10640, 'value': 'Belgorod State Technological University', 'label': 'Belgorod State Technological University' },
    { 'id': 10641, 'value': 'Belgorod State University', 'label': 'Belgorod State University' },
    { 'id': 12315, 'value': 'Belhaven University', 'label': 'Belhaven University' },
    { 'id': 12316, 'value': 'Bellarmine University', 'label': 'Bellarmine University' },
    { 'id': 12317, 'value': 'Bellevue College', 'label': 'Bellevue College' },
    { 'id': 12318, 'value': 'Bellevue University', 'label': 'Bellevue University' },
    { 'id': 12319, 'value': 'Bellin College', 'label': 'Bellin College' },
    { 'id': 9324, 'value': 'Bells University of Technology', 'label': 'Bells University of Technology' },
    { 'id': 12320, 'value': 'Belmont Abbey College', 'label': 'Belmont Abbey College' },
    { 'id': 12321, 'value': 'Belmont University', 'label': 'Belmont University' },
    { 'id': 12322, 'value': 'Beloit College', 'label': 'Beloit College' },
    { 'id': 7890, 'value': 'Beltei International University', 'label': 'Beltei International University' },
    { 'id': 12323, 'value': 'Bemidji State University', 'label': 'Bemidji State University' },
    { 'id': 5534, 'value': 'Ben-Gurion University of the Negev', 'label': 'Ben-Gurion University of the Negev' },
    { 'id': 697, 'value': 'Benawa Institute of Higher Education', 'label': 'Benawa Institute of Higher Education' },
    { 'id': 10059, 'value': 'Benazir Bhutto Shaheed University Lyari', 'label': 'Benazir Bhutto Shaheed University Lyari' },
    { 'id': 12324, 'value': 'Benedict College', 'label': 'Benedict College' },
    { 'id': 12325, 'value': 'Benedictine College', 'label': 'Benedictine College' },
    { 'id': 12326, 'value': 'Benedictine University', 'label': 'Benedictine University' },
    { 'id': 8700, 'value': 'Benemerita Universidad Autonoma de Puebla', 'label': 'Benemerita Universidad Autonoma de Puebla' },
    { 'id': 5639, 'value': 'Bengaluru North University', 'label': 'Bengaluru North University' },
    { 'id': 2164, 'value': 'Bengbu Medical College', 'label': 'Bengbu Medical College' },
    { 'id': 2165, 'value': 'Bengbu University', 'label': 'Bengbu University' },
    { 'id': 9839, 'value': 'Benguet State University', 'label': 'Benguet State University' },
    { 'id': 4049, 'value': 'Benha University', 'label': 'Benha University' },
    { 'id': 4050, 'value': 'Beni-Suef University', 'label': 'Beni-Suef University' },
    { 'id': 12327, 'value': 'Benjamin Franklin Institute of Technology', 'label': 'Benjamin Franklin Institute of Technology' },
    { 'id': 12328, 'value': 'Bennett College', 'label': 'Bennett College' },
    { 'id': 5640, 'value': 'Bennett University', 'label': 'Bennett University' },
    { 'id': 12329, 'value': 'Bennington College', 'label': 'Bennington College' },
    { 'id': 9325, 'value': 'Benson Idahosa University', 'label': 'Benson Idahosa University' },
    { 'id': 12330, 'value': 'Bentley University', 'label': 'Bentley University' },
    { 'id': 9326, 'value': 'Benue State University', 'label': 'Benue State University' },
    { 'id': 7099, 'value': 'Beppu University', 'label': 'Beppu University' },
    { 'id': 11982, 'value': 'Berdyansk State Pedagogical University', 'label': 'Berdyansk State Pedagogical University' },
    { 'id': 11983, 'value': 'Berdyansk University of Management and Business', 'label': 'Berdyansk University of Management and Business' },
    { 'id': 12331, 'value': 'Berea College', 'label': 'Berea College' },
    { 'id': 3463, 'value': 'Bergische Universität Wuppertal', 'label': 'Bergische Universität Wuppertal' },
    { 'id': 5641, 'value': 'Berhampur University', 'label': 'Berhampur University' },
    { 'id': 12332, 'value': 'Berkeley College', 'label': 'Berkeley College' },
    { 'id': 247, 'value': 'Berklee College of Music', 'label': 'Berklee College of Music' },
    { 'id': 1527, 'value': 'Bermuda College', 'label': 'Bermuda College' },
    { 'id': 1999, 'value': 'Berner Fachhochschule', 'label': 'Berner Fachhochschule' },
    { 'id': 12333, 'value': 'Berry College', 'label': 'Berry College' },
    { 'id': 1002, 'value': 'Bertha von Suttner Privatuniversität', 'label': 'Bertha von Suttner Privatuniversität' },
    { 'id': 12334, 'value': 'Bethany College', 'label': 'Bethany College' },
    { 'id': 12335, 'value': 'Bethany College, Kansas', 'label': 'Bethany College, Kansas' },
    { 'id': 12336, 'value': 'Bethany Lutheran College', 'label': 'Bethany Lutheran College' },
    { 'id': 12337, 'value': 'Bethel College', 'label': 'Bethel College' },
    { 'id': 12338, 'value': 'Bethel University', 'label': 'Bethel University' },
    { 'id': 12339, 'value': 'Bethel University, Tennessee', 'label': 'Bethel University, Tennessee' },
    { 'id': 14249, 'value': 'Bethel University, Zambia', 'label': 'Bethel University, Zambia' },
    { 'id': 12340, 'value': 'Bethesda University', 'label': 'Bethesda University' },
    { 'id': 10384, 'value': 'Bethlehem University', 'label': 'Bethlehem University' },
    { 'id': 12341, 'value': 'Bethune-Cookman University', 'label': 'Bethune-Cookman University' },
    { 'id': 12342, 'value': 'Beulah Heights University', 'label': 'Beulah Heights University' },
    { 'id': 3464, 'value': 'Beuth Hochschule für Technik Berlin', 'label': 'Beuth Hochschule für Technik Berlin' },
    { 'id': 11656, 'value': 'Beykent Üniversitesi', 'label': 'Beykent Üniversitesi' },
    { 'id': 11657, 'value': 'Beykoz Üniversitesi', 'label': 'Beykoz Üniversitesi' },
    { 'id': 5535, 'value': 'Bezalel Academy of Arts and Design', 'label': 'Bezalel Academy of Arts and Design' },
    { 'id': 11658, 'value': 'Bezmiâlem Vakif Üniversitesi', 'label': 'Bezmiâlem Vakif Üniversitesi' },
    { 'id': 1226, 'value': 'BGMEA University of Fashion and Technology', 'label': 'BGMEA University of Fashion and Technology' },
    { 'id': 5642, 'value': 'Bhagat Phool Singh Mahila Vishwavidyalaya', 'label': 'Bhagat Phool Singh Mahila Vishwavidyalaya' },
    { 'id': 5643, 'value': 'Bhagwant University', 'label': 'Bhagwant University' },
    { 'id': 5644, 'value': 'Bhakta Kavi Narsinh Mehta University', 'label': 'Bhakta Kavi Narsinh Mehta University' },
    { 'id': 8501, 'value': 'Bhamo University', 'label': 'Bhamo University' },
    { 'id': 5645, 'value': 'Bharath Institute of Higher Education and Research', 'label': 'Bharath Institute of Higher Education and Research' },
    { 'id': 5646, 'value': 'Bharathiar University', 'label': 'Bharathiar University' },
    { 'id': 5647, 'value': 'Bharathidasan University', 'label': 'Bharathidasan University' },
    { 'id': 5648, 'value': 'Bharati Vidyapeeth Deemed University', 'label': 'Bharati Vidyapeeth Deemed University' },
    { 'id': 5649, 'value': 'Bhartiya Skill Development University', 'label': 'Bhartiya Skill Development University' },
    { 'id': 5650, 'value': 'Bhatkhande Music Institute', 'label': 'Bhatkhande Music Institute' },
    { 'id': 8310, 'value': 'Bhiksu University of Sri Lanka', 'label': 'Bhiksu University of Sri Lanka' },
    { 'id': 5651, 'value': 'Bhupal Nobles University', 'label': 'Bhupal Nobles University' },
    { 'id': 5652, 'value': 'Bhupendra Narayan Mandal University', 'label': 'Bhupendra Narayan Mandal University' },
    { 'id': 66, 'value': 'Bicocca - Università degli Studi di Milano', 'label': 'Bicocca - Università degli Studi di Milano' },
    { 'id': 9840, 'value': 'Bicol University', 'label': 'Bicol University' },
    { 'id': 5653, 'value': 'Bidhan Chandra Krishi Vishwavidyalaya', 'label': 'Bidhan Chandra Krishi Vishwavidyalaya' },
    { 'id': 10247, 'value': 'Bielska Wyzsza Szkola im. Józefa Tyszkiewicza', 'label': 'Bielska Wyzsza Szkola im. Józefa Tyszkiewicza' },
    { 'id': 5654, 'value': 'Bihar Agricultural University', 'label': 'Bihar Agricultural University' },
    { 'id': 5655, 'value': 'Biju Patnaik University of Technology', 'label': 'Biju Patnaik University of Technology' },
    { 'id': 11984, 'value': 'Bila Tserkva National Agrarian University', 'label': 'Bila Tserkva National Agrarian University' },
    { 'id': 11659, 'value': 'Bilecik Seyh Edebali Üniversitesi', 'label': 'Bilecik Seyh Edebali Üniversitesi' },
    { 'id': 9841, 'value': 'Biliran Province State University', 'label': 'Biliran Province State University' },
    { 'id': 11660, 'value': 'Bilkent Üniversitesi', 'label': 'Bilkent Üniversitesi' },
    { 'id': 14417, 'value': 'BIMM Institute Berlin', 'label': 'BIMM Institute Berlin' },
    { 'id': 14302, 'value': 'Bindura University of Science Education', 'label': 'Bindura University of Science Education' },
    { 'id': 9327, 'value': 'Bingham University', 'label': 'Bingham University' },
    { 'id': 12343, 'value': 'Binghamton University, State University of New York', 'label': 'Binghamton University, State University of New York' },
    { 'id': 11661, 'value': 'Bingöl Üniversitesi', 'label': 'Bingöl Üniversitesi' },
    { 'id': 2166, 'value': 'Binzhou Medical University', 'label': 'Binzhou Medical University' },
    { 'id': 2167, 'value': 'Binzhou University', 'label': 'Binzhou University' },
    { 'id': 12344, 'value': 'Biola University', 'label': 'Biola University' },
    { 'id': 1820, 'value': 'BIP - Institute of Law', 'label': 'BIP - Institute of Law' },
    { 'id': 6521, 'value': 'Birjand University of Medical Sciences', 'label': 'Birjand University of Medical Sciences' },
    { 'id': 6522, 'value': 'Birjand University of Technology', 'label': 'Birjand University of Technology' },
    { 'id': 158, 'value': 'Birkbeck University of London', 'label': 'Birkbeck University of London' },
    { 'id': 4503, 'value': 'Birkbeck, University of London', 'label': 'Birkbeck, University of London' },
    { 'id': 5656, 'value': 'Birla Institute of Technology', 'label': 'Birla Institute of Technology' },
    { 'id': 5657, 'value': 'Birla Institute of Technology and Science', 'label': 'Birla Institute of Technology and Science' },
    { 'id': 4504, 'value': 'Birmingham City University', 'label': 'Birmingham City University' },
    { 'id': 12345, 'value': 'Birmingham-Southern College', 'label': 'Birmingham-Southern College' },
    { 'id': 5658, 'value': 'Birsa Agricultural University', 'label': 'Birsa Agricultural University' },
    { 'id': 11662, 'value': 'Biruni Üniversitesi', 'label': 'Biruni Üniversitesi' },
    { 'id': 10385, 'value': 'Birzeit University', 'label': 'Birzeit University' },
    { 'id': 7848, 'value': 'Bishkek Academy of Finance and Economics', 'label': 'Bishkek Academy of Finance and Economics' },
    { 'id': 7849, 'value': 'Bishkek Humanities University', 'label': 'Bishkek Humanities University' },
    { 'id': 4505, 'value': 'Bishop Grosseteste University', 'label': 'Bishop Grosseteste University' },
    { 'id': 12164, 'value': 'Bishop Stuart University', 'label': 'Bishop Stuart University' },
    { 'id': 1853, 'value': 'Bishop\'s University', 'label': 'Bishop\'s University' },
    { 'id': 12346, 'value': 'Bismarck State College', 'label': 'Bismarck State College' },
    { 'id': 11663, 'value': 'Bitlis Eren Üniversitesi', 'label': 'Bitlis Eren Üniversitesi' },
    { 'id': 7100, 'value': 'Biwako Seikei Sport College', 'label': 'Biwako Seikei Sport College' },
    { 'id': 8371, 'value': 'Biznesa augstskola Turiba', 'label': 'Biznesa augstskola Turiba' },
    { 'id': 12347, 'value': 'Black Hills State University', 'label': 'Black Hills State University' },
    { 'id': 11985, 'value': 'Black Sea National University', 'label': 'Black Sea National University' },
    { 'id': 12348, 'value': 'Blackburn College', 'label': 'Blackburn College' },
    { 'id': 10642, 'value': 'Blagoveshchensk State Pedagogical University', 'label': 'Blagoveshchensk State Pedagogical University' },
    { 'id': 8677, 'value': 'Blantyre International University', 'label': 'Blantyre International University' },
    { 'id': 5659, 'value': 'BLDE University', 'label': 'BLDE University' },
    { 'id': 11110, 'value': 'Blekinge Tekniska Högskola', 'label': 'Blekinge Tekniska Högskola' },
    { 'id': 14250, 'value': 'Blessing University of Excellence', 'label': 'Blessing University of Excellence' },
    { 'id': 12349, 'value': 'Blessing-Rieman College of Nursing', 'label': 'Blessing-Rieman College of Nursing' },
    { 'id': 12350, 'value': 'Bloomfield College', 'label': 'Bloomfield College' },
    { 'id': 12351, 'value': 'Bloomsburg University of Pennsylvania', 'label': 'Bloomsburg University of Pennsylvania' },
    { 'id': 12352, 'value': 'Blue Mountain College', 'label': 'Blue Mountain College' },
    { 'id': 4671, 'value': 'BlueCrest College', 'label': 'BlueCrest College' },
    { 'id': 12353, 'value': 'Bluefield College', 'label': 'Bluefield College' },
    { 'id': 12354, 'value': 'Bluefield State College', 'label': 'Bluefield State College' },
    { 'id': 9459, 'value': 'Bluefields Indian and Caribbean University', 'label': 'Bluefields Indian and Caribbean University' },
    { 'id': 12355, 'value': 'Bluffton University', 'label': 'Bluffton University' },
    { 'id': 14384, 'value': 'BMCC - Borough Manhattan Community College', 'label': 'BMCC - Borough Manhattan Community College' },
    { 'id': 5660, 'value': 'BML Munjal University', 'label': 'BML Munjal University' },
    { 'id': 12356, 'value': 'Bob Jones University', 'label': 'Bob Jones University' },
    { 'id': 5661, 'value': 'Bodoland University', 'label': 'Bodoland University' },
    { 'id': 11664, 'value': 'Bogaziçi Üniversitesi', 'label': 'Bogaziçi Üniversitesi' },
    { 'id': 2168, 'value': 'Bohai University', 'label': 'Bohai University' },
    { 'id': 9842, 'value': 'Bohol Island State University', 'label': 'Bohol Island State University' },
    { 'id': 12357, 'value': 'Boise Bible College', 'label': 'Boise Bible College' },
    { 'id': 12358, 'value': 'Boise State University', 'label': 'Boise State University' },
    { 'id': 1788, 'value': 'Boitekanelo College', 'label': 'Boitekanelo College' },
    { 'id': 4672, 'value': 'Bolgatanga Polytechnic', 'label': 'Bolgatanga Polytechnic' },
    { 'id': 11665, 'value': 'Bolu Abant Izzet Baysal Üniversitesi', 'label': 'Bolu Abant Izzet Baysal Üniversitesi' },
    { 'id': 1072, 'value': 'Bond University', 'label': 'Bond University' },
    { 'id': 14405, 'value': 'Boolean', 'label': 'Boolean' },
    { 'id': 4251, 'value': 'Bordeaux INP', 'label': 'Bordeaux INP' },
    { 'id': 12359, 'value': 'Boricua College', 'label': 'Boricua College' },
    { 'id': 9328, 'value': 'Borno State University', 'label': 'Borno State University' },
    { 'id': 11986, 'value': 'Borys Grinchenko Kyiv University', 'label': 'Borys Grinchenko Kyiv University' },
    { 'id': 698, 'value': 'Bost University', 'label': 'Bost University' },
    { 'id': 12360, 'value': 'Boston Architectural College', 'label': 'Boston Architectural College' },
    { 'id': 12361, 'value': 'Boston College', 'label': 'Boston College' },
    { 'id': 12362, 'value': 'Boston Graduate School of Psychoanalysis', 'label': 'Boston Graduate School of Psychoanalysis' },
    { 'id': 12363, 'value': 'Boston University', 'label': 'Boston University' },
    { 'id': 1789, 'value': 'Botho University', 'label': 'Botho University' },
    { 'id': 1790, 'value': 'Botswana Accountancy College', 'label': 'Botswana Accountancy College' },
    { 'id': 1791, 'value': 'Botswana International University of Science and Technology', 'label': 'Botswana International University of Science and Technology' },
    { 'id': 1792, 'value': 'Botswana University of Agriculture and Natural Resources', 'label': 'Botswana University of Agriculture and Natural Resources' },
    { 'id': 86, 'value': 'Bournemouth University', 'label': 'Bournemouth University' },
    { 'id': 12364, 'value': 'Bowdoin College', 'label': 'Bowdoin College' },
    { 'id': 9329, 'value': 'Bowen University', 'label': 'Bowen University' },
    { 'id': 12365, 'value': 'Bowie State University', 'label': 'Bowie State University' },
    { 'id': 12366, 'value': 'Bowling Green State University', 'label': 'Bowling Green State University' },
    { 'id': 2169, 'value': 'Bozhou University', 'label': 'Bozhou University' },
    { 'id': 4506, 'value': 'BPP University', 'label': 'BPP University' },
    { 'id': 1227, 'value': 'BRAC University', 'label': 'BRAC University' },
    { 'id': 12367, 'value': 'Bradley University', 'label': 'Bradley University' },
    { 'id': 5662, 'value': 'Brainware University', 'label': 'Brainware University' },
    { 'id': 3465, 'value': 'Brand University of Applied Sciences', 'label': 'Brand University of Applied Sciences' },
    { 'id': 12368, 'value': 'Brandeis University', 'label': 'Brandeis University' },
    { 'id': 3466, 'value': 'Brandenburgische Technische Universität Cottbus-Senftenberg', 'label': 'Brandenburgische Technische Universität Cottbus-Senftenberg' },
    { 'id': 12369, 'value': 'Brandman University', 'label': 'Brandman University' },
    { 'id': 1854, 'value': 'Brandon University', 'label': 'Brandon University' },
    { 'id': 11187, 'value': 'Bratislavská medzinárodná škola liberálnych štúdií', 'label': 'Bratislavská medzinárodná škola liberálnych štúdií' },
    { 'id': 10643, 'value': 'Bratsk State University', 'label': 'Bratsk State University' },
    { 'id': 12370, 'value': 'Brazosport College', 'label': 'Brazosport College' },
    { 'id': 9515, 'value': 'Breda University of Applied Sciences', 'label': 'Breda University of Applied Sciences' },
    { 'id': 12371, 'value': 'Brenau University', 'label': 'Brenau University' },
    { 'id': 12372, 'value': 'Brescia University', 'label': 'Brescia University' },
    { 'id': 4252, 'value': 'Brest Business School', 'label': 'Brest Business School' },
    { 'id': 1821, 'value': 'Brest State Technical University', 'label': 'Brest State Technical University' },
    { 'id': 1822, 'value': 'Brest State University', 'label': 'Brest State University' },
    { 'id': 12373, 'value': 'Brevard College', 'label': 'Brevard College' },
    { 'id': 12374, 'value': 'Brewton-Parker College', 'label': 'Brewton-Parker College' },
    { 'id': 12375, 'value': 'Briar Cliff University', 'label': 'Briar Cliff University' },
    { 'id': 12376, 'value': 'Bridgewater College', 'label': 'Bridgewater College' },
    { 'id': 12377, 'value': 'Bridgewater State University', 'label': 'Bridgewater State University' },
    { 'id': 12378, 'value': 'Brigham Young University', 'label': 'Brigham Young University' },
    { 'id': 12379, 'value': 'Brigham Young University-Hawaii', 'label': 'Brigham Young University-Hawaii' },
    { 'id': 12380, 'value': 'Brigham Young University-Idaho', 'label': 'Brigham Young University-Idaho' },
    { 'id': 1228, 'value': 'Britannia University', 'label': 'Britannia University' },
    { 'id': 1855, 'value': 'British Columbia Institute of Technology', 'label': 'British Columbia Institute of Technology' },
    { 'id': 14191, 'value': 'British University in Yemen', 'label': 'British University in Yemen' },
    { 'id': 1856, 'value': 'Brock University', 'label': 'Brock University' },
    { 'id': 14251, 'value': 'Brook Besor University', 'label': 'Brook Besor University' },
    { 'id': 12381, 'value': 'Brooklyn College', 'label': 'Brooklyn College' },
    { 'id': 12382, 'value': 'Brooklyn Law School', 'label': 'Brooklyn Law School' },
    { 'id': 12383, 'value': 'Broward College', 'label': 'Broward College' },
    { 'id': 12384, 'value': 'Brown Mackie College', 'label': 'Brown Mackie College' },
    { 'id': 12385, 'value': 'Brown University', 'label': 'Brown University' },
    { 'id': 4507, 'value': 'Brunel University London', 'label': 'Brunel University London' },
    { 'id': 12386, 'value': 'Bryan College', 'label': 'Bryan College' },
    { 'id': 12387, 'value': 'Bryan College of Health Sciences', 'label': 'Bryan College of Health Sciences' },
    { 'id': 10644, 'value': 'Bryansk State Agrarian University', 'label': 'Bryansk State Agrarian University' },
    { 'id': 10645, 'value': 'Bryansk State Technical University', 'label': 'Bryansk State Technical University' },
    { 'id': 10646, 'value': 'Bryansk State University', 'label': 'Bryansk State University' },
    { 'id': 12388, 'value': 'Bryant and Stratton College', 'label': 'Bryant and Stratton College' },
    { 'id': 12389, 'value': 'Bryant University', 'label': 'Bryant University' },
    { 'id': 12390, 'value': 'Bryn Athyn College', 'label': 'Bryn Athyn College' },
    { 'id': 12391, 'value': 'Bryn Mawr College', 'label': 'Bryn Mawr College' },
    { 'id': 3467, 'value': 'BSP Business School Berlin', 'label': 'BSP Business School Berlin' },
    { 'id': 6523, 'value': 'Bu-Ali Sina University', 'label': 'Bu-Ali Sina University' },
    { 'id': 3468, 'value': 'Bucerius Law School', 'label': 'Bucerius Law School' },
    { 'id': 4508, 'value': 'Buckinghamshire New University', 'label': 'Buckinghamshire New University' },
    { 'id': 12392, 'value': 'Bucknell University', 'label': 'Bucknell University' },
    { 'id': 556, 'value': 'Budapest Metropolitan University', 'label': 'Budapest Metropolitan University' },
    { 'id': 4890, 'value': 'Budapesti Corvinus Egyetem', 'label': 'Budapesti Corvinus Egyetem' },
    { 'id': 4891, 'value': 'Budapesti Gazdasági Egyetem', 'label': 'Budapesti Gazdasági Egyetem' },
    { 'id': 4892, 'value': 'Budapesti Metropolitan Egyetem', 'label': 'Budapesti Metropolitan Egyetem' },
    { 'id': 4893, 'value': 'Budapesti Muszaki és Gazdaságtudományi Egyetem', 'label': 'Budapesti Muszaki és Gazdaságtudományi Egyetem' },
    { 'id': 8311, 'value': 'Buddhist and Pali University', 'label': 'Buddhist and Pali University' },
    { 'id': 12393, 'value': 'Buena Vista University', 'label': 'Buena Vista University' },
    { 'id': 12394, 'value': 'Buffalo State College', 'label': 'Buffalo State College' },
    { 'id': 12165, 'value': 'Bugema University', 'label': 'Bugema University' },
    { 'id': 7891, 'value': 'Build Bright University', 'label': 'Build Bright University' },
    { 'id': 1480, 'value': 'Bujumbura International University', 'label': 'Bujumbura International University' },
    { 'id': 9843, 'value': 'Bukidnon State University', 'label': 'Bukidnon State University' },
    { 'id': 7101, 'value': 'Bukkyo University', 'label': 'Bukkyo University' },
    { 'id': 11987, 'value': 'Bukovinian State Medical University', 'label': 'Bukovinian State Medical University' },
    { 'id': 9844, 'value': 'Bulacan Agricultural State College', 'label': 'Bulacan Agricultural State College' },
    { 'id': 9845, 'value': 'Bulacan State University', 'label': 'Bulacan State University' },
    { 'id': 4182, 'value': 'Bule Hora University', 'label': 'Bule Hora University' },
    { 'id': 11666, 'value': 'Bülent Ecevit Üniversitesi', 'label': 'Bülent Ecevit Üniversitesi' },
    { 'id': 5663, 'value': 'Bundelkhand University', 'label': 'Bundelkhand University' },
    { 'id': 7102, 'value': 'Bunka Fashion Graduate University', 'label': 'Bunka Fashion Graduate University' },
    { 'id': 7103, 'value': 'Bunka Gakuen University', 'label': 'Bunka Gakuen University' },
    { 'id': 7104, 'value': 'Bunkyo Gakuin University', 'label': 'Bunkyo Gakuin University' },
    { 'id': 7105, 'value': 'Bunkyo University', 'label': 'Bunkyo University' },
    { 'id': 7106, 'value': 'Bunri University of Hospitality', 'label': 'Bunri University of Hospitality' },
    { 'id': 7107, 'value': 'Bunsei University of Art', 'label': 'Bunsei University of Art' },
    { 'id': 11383, 'value': 'Burapha University', 'label': 'Burapha University' },
    { 'id': 11667, 'value': 'Burdur Mehmet Akif Ersoy Üniversitesi', 'label': 'Burdur Mehmet Akif Ersoy Üniversitesi' },
    { 'id': 3469, 'value': 'Burg Giebichenstein Kunsthochschule Halle', 'label': 'Burg Giebichenstein Kunsthochschule Halle' },
    { 'id': 1425, 'value': 'Burgas Free University', 'label': 'Burgas Free University' },
    { 'id': 11384, 'value': 'Buriram Rajabhat University', 'label': 'Buriram Rajabhat University' },
    { 'id': 1857, 'value': 'Burman University', 'label': 'Burman University' },
    { 'id': 11668, 'value': 'Bursa Teknik Üniversitesi', 'label': 'Bursa Teknik Üniversitesi' },
    { 'id': 10647, 'value': 'Buryat State Academy of Agriculture', 'label': 'Buryat State Academy of Agriculture' },
    { 'id': 10648, 'value': 'Buryat State University', 'label': 'Buryat State University' },
    { 'id': 7969, 'value': 'Busan National University of Education', 'label': 'Busan National University of Education' },
    { 'id': 7970, 'value': 'Busan Presbyterian University', 'label': 'Busan Presbyterian University' },
    { 'id': 7971, 'value': 'Busan University of Foreign Studies', 'label': 'Busan University of Foreign Studies' },
    { 'id': 7972, 'value': 'Busan Women\'s College', 'label': 'Busan Women\'s College' },
    { 'id': 6524, 'value': 'Bushehr University of Medical Sciences', 'label': 'Bushehr University of Medical Sciences' },
    { 'id': 4623, 'value': 'Business and Technology University', 'label': 'Business and Technology University' },
    { 'id': 12166, 'value': 'Busitema University', 'label': 'Busitema University' },
    { 'id': 12167, 'value': 'Busoga University', 'label': 'Busoga University' },
    { 'id': 12395, 'value': 'Butler University', 'label': 'Butler University' },
    { 'id': 13988, 'value': 'Buxoro Davlat Universiteti', 'label': 'Buxoro Davlat Universiteti' },
    { 'id': 13989, 'value': 'Buxoro muhandislik-texnologiya instituti', 'label': 'Buxoro muhandislik-texnologiya instituti' },
    { 'id': 3348, 'value': 'C.D.A. College', 'label': 'C.D.A. College' },
    { 'id': 5664, 'value': 'C.U. Shah University', 'label': 'C.U. Shah University' },
    { 'id': 12396, 'value': 'Cabarrus College of Health Sciences', 'label': 'Cabarrus College of Health Sciences' },
    { 'id': 12397, 'value': 'Cabrini University', 'label': 'Cabrini University' },
    { 'id': 11669, 'value': 'Çag Üniversitesi', 'label': 'Çag Üniversitesi' },
    { 'id': 9846, 'value': 'Cagayan State University', 'label': 'Cagayan State University' },
    { 'id': 12398, 'value': 'Cairn University', 'label': 'Cairn University' },
    { 'id': 4051, 'value': 'Cairo University', 'label': 'Cairo University' },
    { 'id': 12399, 'value': 'Caldwell University', 'label': 'Caldwell University' },
    { 'id': 9330, 'value': 'Caleb University', 'label': 'Caleb University' },
    { 'id': 9613, 'value': 'Caledonian College of Engineering', 'label': 'Caledonian College of Engineering' },
    { 'id': 12400, 'value': 'California Baptist University', 'label': 'California Baptist University' },
    { 'id': 12401, 'value': 'California College of the Arts', 'label': 'California College of the Arts' },
    { 'id': 12402, 'value': 'California Institute of Integral Studies', 'label': 'California Institute of Integral Studies' },
    { 'id': 12403, 'value': 'California Institute of Technology', 'label': 'California Institute of Technology' },
    { 'id': 12404, 'value': 'California Institute of the Arts', 'label': 'California Institute of the Arts' },
    { 'id': 12405, 'value': 'California Lutheran University', 'label': 'California Lutheran University' },
    { 'id': 12406, 'value': 'California Polytechnic State University, San Luis Obispo', 'label': 'California Polytechnic State University, San Luis Obispo' },
    { 'id': 12407, 'value': 'California State Polytechnic University, Pomona', 'label': 'California State Polytechnic University, Pomona' },
    { 'id': 502, 'value': 'California State University - CAL', 'label': 'California State University - CAL' },
    { 'id': 12408, 'value': 'California State University Channel Islands', 'label': 'California State University Channel Islands' },
    { 'id': 12409, 'value': 'California State University Maritime Academy', 'label': 'California State University Maritime Academy' },
    { 'id': 12410, 'value': 'California State University San Marcos', 'label': 'California State University San Marcos' },
    { 'id': 12411, 'value': 'California State University, Bakersfield', 'label': 'California State University, Bakersfield' },
    { 'id': 12412, 'value': 'California State University, Chico', 'label': 'California State University, Chico' },
    { 'id': 12413, 'value': 'California State University, Dominguez Hills', 'label': 'California State University, Dominguez Hills' },
    { 'id': 12414, 'value': 'California State University, East Bay', 'label': 'California State University, East Bay' },
    { 'id': 12415, 'value': 'California State University, Fresno', 'label': 'California State University, Fresno' },
    { 'id': 12416, 'value': 'California State University, Fullerton', 'label': 'California State University, Fullerton' },
    { 'id': 12417, 'value': 'California State University, Long Beach', 'label': 'California State University, Long Beach' },
    { 'id': 12418, 'value': 'California State University, Los Angeles', 'label': 'California State University, Los Angeles' },
    { 'id': 12419, 'value': 'California State University, Monterey Bay', 'label': 'California State University, Monterey Bay' },
    { 'id': 12420, 'value': 'California State University, Northridge', 'label': 'California State University, Northridge' },
    { 'id': 276, 'value': 'California State University, Sacramento', 'label': 'California State University, Sacramento' },
    { 'id': 12421, 'value': 'California State University, San Bernardino', 'label': 'California State University, San Bernardino' },
    { 'id': 12422, 'value': 'California State University, Stanislaus', 'label': 'California State University, Stanislaus' },
    { 'id': 12423, 'value': 'California University of Pennsylvania', 'label': 'California University of Pennsylvania' },
    { 'id': 12424, 'value': 'California Western School of Law', 'label': 'California Western School of Law' },
    { 'id': 12425, 'value': 'Calumet College of St. Joseph', 'label': 'Calumet College of St. Joseph' },
    { 'id': 12426, 'value': 'Calvary University', 'label': 'Calvary University' },
    { 'id': 12427, 'value': 'Calvin College', 'label': 'Calvin College' },
    { 'id': 7973, 'value': 'Calvin University', 'label': 'Calvin University' },
    { 'id': 9847, 'value': 'Camarines Norte State College', 'label': 'Camarines Norte State College' },
    { 'id': 9848, 'value': 'Camarines Sur Polytechnic Colleges', 'label': 'Camarines Sur Polytechnic Colleges' },
    { 'id': 7892, 'value': 'Cambodia University for Specialties', 'label': 'Cambodia University for Specialties' },
    { 'id': 7893, 'value': 'Cambodian Mekong University', 'label': 'Cambodian Mekong University' },
    { 'id': 12428, 'value': 'Cambridge College', 'label': 'Cambridge College' },
    { 'id': 121, 'value': 'Cambridge University', 'label': 'Cambridge University' },
    { 'id': 7894, 'value': 'CamEd Business School', 'label': 'CamEd Business School' },
    { 'id': 12429, 'value': 'Cameron University', 'label': 'Cameron University' },
    { 'id': 12430, 'value': 'Campbell University', 'label': 'Campbell University' },
    { 'id': 12431, 'value': 'Campbellsville University', 'label': 'Campbellsville University' },
    { 'id': 1858, 'value': 'Campion College at the University of Regina', 'label': 'Campion College at the University of Regina' },
    { 'id': 1003, 'value': 'CAMPUS 02 Fachhochschule der Wirtschaft', 'label': 'CAMPUS 02 Fachhochschule der Wirtschaft' },
    { 'id': 4253, 'value': 'Campus Adventiste du Salève', 'label': 'Campus Adventiste du Salève' },
    { 'id': 404, 'value': 'Campus Ciels Milano', 'label': 'Campus Ciels Milano' },
    { 'id': 97, 'value': 'Campus Ciels Padova', 'label': 'Campus Ciels Padova' },
    { 'id': 14111, 'value': 'Can Tho University', 'label': 'Can Tho University' },
    { 'id': 1859, 'value': 'Canadian Mennonite University', 'label': 'Canadian Mennonite University' },
    { 'id': 1229, 'value': 'Canadian University of Bangladesh', 'label': 'Canadian University of Bangladesh' },
    { 'id': 631, 'value': 'Canadian University of Dubai', 'label': 'Canadian University of Dubai' },
    { 'id': 11670, 'value': 'Çanakkale Onsekiz Mart Üniversitesi', 'label': 'Çanakkale Onsekiz Mart Üniversitesi' },
    { 'id': 2170, 'value': 'Cangzhou Normal University', 'label': 'Cangzhou Normal University' },
    { 'id': 12432, 'value': 'Canisius College', 'label': 'Canisius College' },
    { 'id': 11671, 'value': 'Çankaya Üniversitesi', 'label': 'Çankaya Üniversitesi' },
    { 'id': 11672, 'value': 'Çankiri Karatekin Üniversitesi', 'label': 'Çankiri Karatekin Üniversitesi' },
    { 'id': 4509, 'value': 'Canterbury Christ Church University', 'label': 'Canterbury Christ Church University' },
    { 'id': 1860, 'value': 'Cape Breton University', 'label': 'Cape Breton University' },
    { 'id': 4673, 'value': 'Cape Coast Technical University', 'label': 'Cape Coast Technical University' },
    { 'id': 14221, 'value': 'Cape Peninsula University of Technology', 'label': 'Cape Peninsula University of Technology' },
    { 'id': 1861, 'value': 'Capilano University', 'label': 'Capilano University' },
    { 'id': 2171, 'value': 'Capital Medical University', 'label': 'Capital Medical University' },
    { 'id': 2172, 'value': 'Capital Normal University', 'label': 'Capital Normal University' },
    { 'id': 12433, 'value': 'Capital University', 'label': 'Capital University' },
    { 'id': 2173, 'value': 'Capital University of Economics and Business', 'label': 'Capital University of Economics and Business' },
    { 'id': 2174, 'value': 'Capital University of Physical Education and Sports', 'label': 'Capital University of Physical Education and Sports' },
    { 'id': 12434, 'value': 'Capitol Technology University', 'label': 'Capitol Technology University' },
    { 'id': 9849, 'value': 'Capitol University', 'label': 'Capitol University' },
    { 'id': 9850, 'value': 'Capiz State University', 'label': 'Capiz State University' },
    { 'id': 9851, 'value': 'Caraga State University', 'label': 'Caraga State University' },
    { 'id': 4510, 'value': 'Cardiff Metropolitan University', 'label': 'Cardiff Metropolitan University' },
    { 'id': 4511, 'value': 'Cardiff University', 'label': 'Cardiff University' },
    { 'id': 12435, 'value': 'Cardinal Stritch University', 'label': 'Cardinal Stritch University' },
    { 'id': 5665, 'value': 'Career Point University', 'label': 'Career Point University' },
    { 'id': 11797, 'value': 'Caribbean Nazarene College', 'label': 'Caribbean Nazarene College' },
    { 'id': 10345, 'value': 'Caribbean University', 'label': 'Caribbean University' },
    { 'id': 9331, 'value': 'Caritas University', 'label': 'Caritas University' },
    { 'id': 3470, 'value': 'Carl von Ossietzky Universität Oldenburg', 'label': 'Carl von Ossietzky Universität Oldenburg' },
    { 'id': 12436, 'value': 'Carleton College', 'label': 'Carleton College' },
    { 'id': 1862, 'value': 'Carleton University', 'label': 'Carleton University' },
    { 'id': 10346, 'value': 'Carlos Albizu University', 'label': 'Carlos Albizu University' },
    { 'id': 5505, 'value': 'Carlow College', 'label': 'Carlow College' },
    { 'id': 12437, 'value': 'Carlow University', 'label': 'Carlow University' },
    { 'id': 12438, 'value': 'Carnegie Mellon University', 'label': 'Carnegie Mellon University' },
    { 'id': 10505, 'value': 'Carnegie Mellon University in Qatar', 'label': 'Carnegie Mellon University in Qatar' },
    { 'id': 12439, 'value': 'Carroll College', 'label': 'Carroll College' },
    { 'id': 12440, 'value': 'Carroll University', 'label': 'Carroll University' },
    { 'id': 12441, 'value': 'Carson-Newman University', 'label': 'Carson-Newman University' },
    { 'id': 12442, 'value': 'Carthage College', 'label': 'Carthage College' },
    { 'id': 3349, 'value': 'Casa College', 'label': 'Casa College' },
    { 'id': 12443, 'value': 'Case Western Reserve University', 'label': 'Case Western Reserve University' },
    { 'id': 8180, 'value': 'Caspian State University of Technologies and Engineering', 'label': 'Caspian State University of Technologies and Engineering' },
    { 'id': 8181, 'value': 'Caspian University', 'label': 'Caspian University' },
    { 'id': 352, 'value': 'Cass Business School', 'label': 'Cass Business School' },
    { 'id': 14329, 'value': 'CAST Alimenti s.r.l.', 'label': 'CAST Alimenti s.r.l.' },
    { 'id': 12444, 'value': 'Castleton University', 'label': 'Castleton University' },
    { 'id': 9852, 'value': 'Catanduanes State University', 'label': 'Catanduanes State University' },
    { 'id': 12445, 'value': 'Catawba College', 'label': 'Catawba College' },
    { 'id': 4674, 'value': 'Catholic Institute of Business and Technology', 'label': 'Catholic Institute of Business and Technology' },
    { 'id': 7974, 'value': 'Catholic Kwandong University', 'label': 'Catholic Kwandong University' },
    { 'id': 4675, 'value': 'Catholic University College of Ghana', 'label': 'Catholic University College of Ghana' },
    { 'id': 2091, 'value': 'Catholic University of Cameroon', 'label': 'Catholic University of Cameroon' },
    { 'id': 7975, 'value': 'Catholic University of Daegu', 'label': 'Catholic University of Daegu' },
    { 'id': 7976, 'value': 'Catholic University of Daejeon', 'label': 'Catholic University of Daejeon' },
    { 'id': 11949, 'value': 'Catholic University of Health and Allied Sciences', 'label': 'Catholic University of Health and Allied Sciences' },
    { 'id': 7977, 'value': 'Catholic University of Pusan', 'label': 'Catholic University of Pusan' },
    { 'id': 10993, 'value': 'Catholic University of Rwanda', 'label': 'Catholic University of Rwanda' },
    { 'id': 14303, 'value': 'Catholic University of Zimbabwe', 'label': 'Catholic University of Zimbabwe' },
    { 'id': 4624, 'value': 'Caucasus International University', 'label': 'Caucasus International University' },
    { 'id': 4625, 'value': 'Caucasus University', 'label': 'Caucasus University' },
    { 'id': 12168, 'value': 'Cavendish University Uganda', 'label': 'Cavendish University Uganda' },
    { 'id': 14252, 'value': 'Cavendish University Zambia', 'label': 'Cavendish University Zambia' },
    { 'id': 9853, 'value': 'Cavite State University', 'label': 'Cavite State University' },
    { 'id': 12446, 'value': 'Cazenovia College', 'label': 'Cazenovia College' },
    { 'id': 3471, 'value': 'CBS International Business School', 'label': 'CBS International Business School' },
    { 'id': 1230, 'value': 'CCN University of Science and Technology', 'label': 'CCN University of Science and Technology' },
    { 'id': 9854, 'value': 'Cebu Doctors\' University', 'label': 'Cebu Doctors\' University' },
    { 'id': 9855, 'value': 'Cebu Institute of Medicine', 'label': 'Cebu Institute of Medicine' },
    { 'id': 9856, 'value': 'Cebu Institute of Technology', 'label': 'Cebu Institute of Technology' },
    { 'id': 9857, 'value': 'Cebu Normal University', 'label': 'Cebu Normal University' },
    { 'id': 9858, 'value': 'Cebu Technological University', 'label': 'Cebu Technological University' },
    { 'id': 10060, 'value': 'CECOS University', 'label': 'CECOS University' },
    { 'id': 12447, 'value': 'Cedar Crest College', 'label': 'Cedar Crest College' },
    { 'id': 12448, 'value': 'Cedarville University', 'label': 'Cedarville University' },
    { 'id': 383, 'value': 'CEDEL- Cooperativa sociale Educativa ELIS', 'label': 'CEDEL- Cooperativa sociale Educativa ELIS' },
    { 'id': 1156, 'value': 'Centar za multidisciplinarne studije u Tuzli', 'label': 'Centar za multidisciplinarne studije u Tuzli' },
    { 'id': 12449, 'value': 'Centenary College of Louisiana', 'label': 'Centenary College of Louisiana' },
    { 'id': 12450, 'value': 'Centenary University', 'label': 'Centenary University' },
    { 'id': 5666, 'value': 'Central Agricultural University', 'label': 'Central Agricultural University' },
    { 'id': 1846, 'value': 'Central America Health Sciences University, Belize Medical College', 'label': 'Central America Health Sciences University, Belize Medical College' },
    { 'id': 12451, 'value': 'Central Baptist College', 'label': 'Central Baptist College' },
    { 'id': 2175, 'value': 'Central China Normal University', 'label': 'Central China Normal University' },
    { 'id': 12452, 'value': 'Central Christian College of Kansas', 'label': 'Central Christian College of Kansas' },
    { 'id': 12453, 'value': 'Central Christian College of the Bible', 'label': 'Central Christian College of the Bible' },
    { 'id': 12454, 'value': 'Central College', 'label': 'Central College' },
    { 'id': 9859, 'value': 'Central Colleges of the Philippines', 'label': 'Central Colleges of the Philippines' },
    { 'id': 12455, 'value': 'Central Connecticut State University', 'label': 'Central Connecticut State University' },
    { 'id': 2176, 'value': 'Central Conservatory of Music', 'label': 'Central Conservatory of Music' },
    { 'id': 14413, 'value': 'Central European University', 'label': 'Central European University' },
    { 'id': 5667, 'value': 'Central Institute of Fisheries Education', 'label': 'Central Institute of Fisheries Education' },
    { 'id': 8182, 'value': 'Central Kazakhstan Academy', 'label': 'Central Kazakhstan Academy' },
    { 'id': 9860, 'value': 'Central Luzon State University', 'label': 'Central Luzon State University' },
    { 'id': 12456, 'value': 'Central Methodist University', 'label': 'Central Methodist University' },
    { 'id': 12457, 'value': 'Central Michigan University', 'label': 'Central Michigan University' },
    { 'id': 9861, 'value': 'Central Mindanao University', 'label': 'Central Mindanao University' },
    { 'id': 12458, 'value': 'Central Penn College', 'label': 'Central Penn College' },
    { 'id': 9862, 'value': 'Central Philippine Adventist College', 'label': 'Central Philippine Adventist College' },
    { 'id': 9863, 'value': 'Central Philippine University', 'label': 'Central Philippine University' },
    { 'id': 1073, 'value': 'Central Queensland University', 'label': 'Central Queensland University' },
    { 'id': 2177, 'value': 'Central South University', 'label': 'Central South University' },
    { 'id': 2178, 'value': 'Central South University of Forestry and Technology', 'label': 'Central South University of Forestry and Technology' },
    { 'id': 12459, 'value': 'Central State University', 'label': 'Central State University' },
    { 'id': 11807, 'value': 'Central Taiwan University of Science and Technology', 'label': 'Central Taiwan University of Science and Technology' },
    { 'id': 4676, 'value': 'Central University', 'label': 'Central University' },
    { 'id': 2179, 'value': 'Central University of Finance and Economics', 'label': 'Central University of Finance and Economics' },
    { 'id': 5668, 'value': 'Central University of Gujarat', 'label': 'Central University of Gujarat' },
    { 'id': 5669, 'value': 'Central University of Haryana', 'label': 'Central University of Haryana' },
    { 'id': 5670, 'value': 'Central University of Himachal Pradesh', 'label': 'Central University of Himachal Pradesh' },
    { 'id': 5671, 'value': 'Central University of Jammu', 'label': 'Central University of Jammu' },
    { 'id': 5672, 'value': 'Central University of Jharkhand', 'label': 'Central University of Jharkhand' },
    { 'id': 5673, 'value': 'Central University of Karnataka', 'label': 'Central University of Karnataka' },
    { 'id': 5674, 'value': 'Central University of Kashmir', 'label': 'Central University of Kashmir' },
    { 'id': 5675, 'value': 'Central University of Kerala', 'label': 'Central University of Kerala' },
    { 'id': 5676, 'value': 'Central University of Orissa', 'label': 'Central University of Orissa' },
    { 'id': 5677, 'value': 'Central University of Punjab', 'label': 'Central University of Punjab' },
    { 'id': 5678, 'value': 'Central University of Rajasthan', 'label': 'Central University of Rajasthan' },
    { 'id': 1231, 'value': 'Central University of Science and Technology', 'label': 'Central University of Science and Technology' },
    { 'id': 5679, 'value': 'Central University of South Bihar', 'label': 'Central University of South Bihar' },
    { 'id': 5680, 'value': 'Central University of Tamil Nadu', 'label': 'Central University of Tamil Nadu' },
    { 'id': 14222, 'value': 'Central University of Technology', 'label': 'Central University of Technology' },
    { 'id': 5681, 'value': 'Central University of Tibetan Studies', 'label': 'Central University of Tibetan Studies' },
    { 'id': 12460, 'value': 'Central Washington University', 'label': 'Central Washington University' },
    { 'id': 1232, 'value': 'Central Women\'s University', 'label': 'Central Women\'s University' },
    { 'id': 4254, 'value': 'Centrale Lille', 'label': 'Centrale Lille' },
    { 'id': 4255, 'value': 'CentraleSupélec', 'label': 'CentraleSupélec' },
    { 'id': 12461, 'value': 'Centralia College', 'label': 'Centralia College' },
    { 'id': 12462, 'value': 'Centre College', 'label': 'Centre College' },
    { 'id': 1403, 'value': 'Centre Universitaire Polytechnique de Kaya', 'label': 'Centre Universitaire Polytechnique de Kaya' },
    { 'id': 1404, 'value': 'Centre Universitaire Polytechnique de Ouahigouya', 'label': 'Centre Universitaire Polytechnique de Ouahigouya' },
    { 'id': 4209, 'value': 'Centria ammattikorkeakoulu', 'label': 'Centria ammattikorkeakoulu' },
    { 'id': 4807, 'value': 'Centro de Diseño, Arquitectura y Construcción', 'label': 'Centro de Diseño, Arquitectura y Construcción' },
    { 'id': 10347, 'value': 'Centro de Estudios Avanzados de Puerto Rico y El Caribe', 'label': 'Centro de Estudios Avanzados de Puerto Rico y El Caribe' },
    { 'id': 8701, 'value': 'Centro de Estudios Superiores de Diseño de Monterrey S.C.', 'label': 'Centro de Estudios Superiores de Diseño de Monterrey S.C.' },
    { 'id': 8702, 'value': 'Centro de Estudios Superiores de San Ángel S.C.', 'label': 'Centro de Estudios Superiores de San Ángel S.C.' },
    { 'id': 8703, 'value': 'Centro de Estudios Superiores del Noroeste', 'label': 'Centro de Estudios Superiores del Noroeste' },
    { 'id': 8704, 'value': 'Centro de Estudios Universitarios', 'label': 'Centro de Estudios Universitarios' },
    { 'id': 8705, 'value': 'Centro de Estudios Universitarios Xochicalco', 'label': 'Centro de Estudios Universitarios Xochicalco' },
    { 'id': 8706, 'value': 'Centro de Investigación y Docencia Económicas', 'label': 'Centro de Investigación y Docencia Económicas' },
    { 'id': 9864, 'value': 'Centro Escolar University', 'label': 'Centro Escolar University' },
    { 'id': 366, 'value': 'Centro Sperimentale di Cinematografia', 'label': 'Centro Sperimentale di Cinematografia' },
    { 'id': 8707, 'value': 'Centro Universitario de Integración Humanística A.C.', 'label': 'Centro Universitario de Integración Humanística A.C.' },
    { 'id': 5682, 'value': 'Centurion University of Technology and Management', 'label': 'Centurion University of Technology and Management' },
    { 'id': 1157, 'value': 'CEPS-Centar za poslovne studije', 'label': 'CEPS-Centar za poslovne studije' },
    { 'id': 5683, 'value': 'CEPT University', 'label': 'CEPT University' },
    { 'id': 4256, 'value': 'CESI École d\'Ingénieurs', 'label': 'CESI École d\'Ingénieurs' },
    { 'id': 3399, 'value': 'Ceská zemedelská univerzita v Praze', 'label': 'Ceská zemedelská univerzita v Praze' },
    { 'id': 3400, 'value': 'Ceské vysoké ucení technické v Praze', 'label': 'Ceské vysoké ucení technické v Praze' },
    { 'id': 160, 'value': 'CESPU - Cooperativa De Ensino Superior Politécnico Universitário', 'label': 'CESPU - Cooperativa De Ensino Superior Politécnico Universitário' },
    { 'id': 8708, 'value': 'CETYS Universidad', 'label': 'CETYS Universidad' },
    { 'id': 3401, 'value': 'CEVRO Institut', 'label': 'CEVRO Institut' },
    { 'id': 7978, 'value': 'CHA University', 'label': 'CHA University' },
    { 'id': 6525, 'value': 'Chabahar Maritime University', 'label': 'Chabahar Maritime University' },
    { 'id': 12463, 'value': 'Chadron State College', 'label': 'Chadron State College' },
    { 'id': 11385, 'value': 'Chaiyaphum Rajabhat University', 'label': 'Chaiyaphum Rajabhat University' },
    { 'id': 11386, 'value': 'Chalermkarnchana University', 'label': 'Chalermkarnchana University' },
    { 'id': 14253, 'value': 'Chalimbana University', 'label': 'Chalimbana University' },
    { 'id': 11111, 'value': 'Chalmers tekniska högskola', 'label': 'Chalmers tekniska högskola' },
    { 'id': 12464, 'value': 'Chamberlain University', 'label': 'Chamberlain University' },
    { 'id': 12465, 'value': 'Chaminade University of Honolulu', 'label': 'Chaminade University of Honolulu' },
    { 'id': 12466, 'value': 'Champlain College', 'label': 'Champlain College' },
    { 'id': 7895, 'value': 'Chamroeun University of Polytechnology', 'label': 'Chamroeun University of Polytechnology' },
    { 'id': 5684, 'value': 'Chanakya National Law University', 'label': 'Chanakya National Law University' },
    { 'id': 5685, 'value': 'Chandigarh University', 'label': 'Chandigarh University' },
    { 'id': 5686, 'value': 'Chandra Shekhar Azad University of Agriculture and Technology', 'label': 'Chandra Shekhar Azad University of Agriculture and Technology' },
    { 'id': 11387, 'value': 'Chandrakasem Rajabhat University', 'label': 'Chandrakasem Rajabhat University' },
    { 'id': 11808, 'value': 'Chang Gung University', 'label': 'Chang Gung University' },
    { 'id': 11809, 'value': 'Chang Gung University of Science and Technology', 'label': 'Chang Gung University of Science and Technology' },
    { 'id': 11810, 'value': 'Chang Jung Christian University', 'label': 'Chang Jung Christian University' },
    { 'id': 2180, 'value': 'Chang\'an University', 'label': 'Chang\'an University' },
    { 'id': 2181, 'value': 'Changchun Architecture and Civil Engineering College', 'label': 'Changchun Architecture and Civil Engineering College' },
    { 'id': 2182, 'value': 'Changchun Guanghua University', 'label': 'Changchun Guanghua University' },
    { 'id': 2183, 'value': 'Changchun Institute of Technology', 'label': 'Changchun Institute of Technology' },
    { 'id': 2184, 'value': 'Changchun Normal University', 'label': 'Changchun Normal University' },
    { 'id': 2185, 'value': 'Changchun University', 'label': 'Changchun University' },
    { 'id': 2186, 'value': 'Changchun University of Chinese Medicine', 'label': 'Changchun University of Chinese Medicine' },
    { 'id': 2187, 'value': 'Changchun University of Finance and Economics', 'label': 'Changchun University of Finance and Economics' },
    { 'id': 2188, 'value': 'Changchun University of Science and Technology', 'label': 'Changchun University of Science and Technology' },
    { 'id': 2189, 'value': 'Changchun University of Technology', 'label': 'Changchun University of Technology' },
    { 'id': 2190, 'value': 'Changji University', 'label': 'Changji University' },
    { 'id': 2191, 'value': 'Changsha Medical University', 'label': 'Changsha Medical University' },
    { 'id': 2192, 'value': 'Changsha Normal University', 'label': 'Changsha Normal University' },
    { 'id': 2193, 'value': 'Changsha University', 'label': 'Changsha University' },
    { 'id': 2194, 'value': 'Changsha University of Science and Technology', 'label': 'Changsha University of Science and Technology' },
    { 'id': 2195, 'value': 'Changshu Institute of Technology', 'label': 'Changshu Institute of Technology' },
    { 'id': 7979, 'value': 'Changwon National University', 'label': 'Changwon National University' },
    { 'id': 2196, 'value': 'Changzhi Medical College', 'label': 'Changzhi Medical College' },
    { 'id': 2197, 'value': 'Changzhi University', 'label': 'Changzhi University' },
    { 'id': 2198, 'value': 'Changzhou Institute of Technology', 'label': 'Changzhou Institute of Technology' },
    { 'id': 2199, 'value': 'Changzhou University', 'label': 'Changzhou University' },
    { 'id': 2200, 'value': 'Chaohu University', 'label': 'Chaohu University' },
    { 'id': 11388, 'value': 'Chaopraya University', 'label': 'Chaopraya University' },
    { 'id': 11811, 'value': 'Chaoyang University of Technology', 'label': 'Chaoyang University of Technology' },
    { 'id': 12467, 'value': 'Chapman University', 'label': 'Chapman University' },
    { 'id': 3472, 'value': 'Charité - Universitätsmedizin Berlin', 'label': 'Charité - Universitätsmedizin Berlin' },
    { 'id': 1074, 'value': 'Charles Darwin University', 'label': 'Charles Darwin University' },
    { 'id': 12468, 'value': 'Charles R. Drew University of Medicine and Science', 'label': 'Charles R. Drew University of Medicine and Science' },
    { 'id': 1075, 'value': 'Charles Sturt University', 'label': 'Charles Sturt University' },
    { 'id': 277, 'value': 'Charles University', 'label': 'Charles University' },
    { 'id': 12469, 'value': 'Charleston Southern University', 'label': 'Charleston Southern University' },
    { 'id': 6447, 'value': 'Charmo University', 'label': 'Charmo University' },
    { 'id': 5687, 'value': 'Charotar University of Science and Technology', 'label': 'Charotar University of Science and Technology' },
    { 'id': 12470, 'value': 'Chatham University', 'label': 'Chatham University' },
    { 'id': 5688, 'value': 'Chaudhary Bansi Lal University', 'label': 'Chaudhary Bansi Lal University' },
    { 'id': 5689, 'value': 'Chaudhary Charan Singh Haryana Agricultural University', 'label': 'Chaudhary Charan Singh Haryana Agricultural University' },
    { 'id': 5690, 'value': 'Chaudhary Charan Singh University', 'label': 'Chaudhary Charan Singh University' },
    { 'id': 5691, 'value': 'Chaudhary Devi Lal University', 'label': 'Chaudhary Devi Lal University' },
    { 'id': 5692, 'value': 'Chaudhary Ranbir Singh University', 'label': 'Chaudhary Ranbir Singh University' },
    { 'id': 7896, 'value': 'Chea Sim University of Kamchaymear', 'label': 'Chea Sim University of Kamchaymear' },
    { 'id': 10649, 'value': 'Chechen State University', 'label': 'Chechen State University' },
    { 'id': 14408, 'value': 'Chef Academy', 'label': 'Chef Academy' },
    { 'id': 10650, 'value': 'Chelyabinsk State Agroengineering Academy', 'label': 'Chelyabinsk State Agroengineering Academy' },
    { 'id': 10651, 'value': 'Chelyabinsk State Medical Academy', 'label': 'Chelyabinsk State Medical Academy' },
    { 'id': 10652, 'value': 'Chelyabinsk State Pedagogical University', 'label': 'Chelyabinsk State Pedagogical University' },
    { 'id': 10653, 'value': 'Chelyabinsk State University', 'label': 'Chelyabinsk State University' },
    { 'id': 11812, 'value': 'Cheng Shiu University', 'label': 'Cheng Shiu University' },
    { 'id': 2201, 'value': 'Chengde Medical University', 'label': 'Chengde Medical University' },
    { 'id': 2202, 'value': 'Chengdu College of Arts and Sciences', 'label': 'Chengdu College of Arts and Sciences' },
    { 'id': 2203, 'value': 'Chengdu Medical College', 'label': 'Chengdu Medical College' },
    { 'id': 2204, 'value': 'Chengdu Neusoft University', 'label': 'Chengdu Neusoft University' },
    { 'id': 2205, 'value': 'Chengdu Normal University', 'label': 'Chengdu Normal University' },
    { 'id': 2206, 'value': 'Chengdu Sport University', 'label': 'Chengdu Sport University' },
    { 'id': 2207, 'value': 'Chengdu Technological University', 'label': 'Chengdu Technological University' },
    { 'id': 2208, 'value': 'Chengdu University', 'label': 'Chengdu University' },
    { 'id': 2209, 'value': 'Chengdu University of Information Technology', 'label': 'Chengdu University of Information Technology' },
    { 'id': 2210, 'value': 'Chengdu University of Technology', 'label': 'Chengdu University of Technology' },
    { 'id': 2211, 'value': 'Chengdu University of Traditional Chinese Medicine', 'label': 'Chengdu University of Traditional Chinese Medicine' },
    { 'id': 7897, 'value': 'Chenla University', 'label': 'Chenla University' },
    { 'id': 5693, 'value': 'Chennai Mathematical Institute', 'label': 'Chennai Mathematical Institute' },
    { 'id': 7980, 'value': 'Cheongju National University of Education', 'label': 'Cheongju National University of Education' },
    { 'id': 7981, 'value': 'Cheongju University', 'label': 'Cheongju University' },
    { 'id': 699, 'value': 'Cheragh Medical Institute', 'label': 'Cheragh Medical Institute' },
    { 'id': 10654, 'value': 'Cherepovets State University', 'label': 'Cherepovets State University' },
    { 'id': 11988, 'value': 'Cherkasy State Business College', 'label': 'Cherkasy State Business College' },
    { 'id': 11989, 'value': 'Cherkasy State Technological University', 'label': 'Cherkasy State Technological University' },
    { 'id': 11990, 'value': 'Chernihiv National University of Technology', 'label': 'Chernihiv National University of Technology' },
    { 'id': 11991, 'value': 'Chernihiv State Pedagogical University', 'label': 'Chernihiv State Pedagogical University' },
    { 'id': 12471, 'value': 'Chestnut Hill College', 'label': 'Chestnut Hill College' },
    { 'id': 12472, 'value': 'Cheyney University of Pennsylvania', 'label': 'Cheyney University of Pennsylvania' },
    { 'id': 5694, 'value': 'Chhatrapati Shahu Ji Maharaj University', 'label': 'Chhatrapati Shahu Ji Maharaj University' },
    { 'id': 5695, 'value': 'Chhattisgarh Kamdhenu Vishwavidyalaya', 'label': 'Chhattisgarh Kamdhenu Vishwavidyalaya' },
    { 'id': 5696, 'value': 'Chhattisgarh Swami Vivekananda Technical University', 'label': 'Chhattisgarh Swami Vivekananda Technical University' },
    { 'id': 11813, 'value': 'Chia Nan University of Pharmacy and Science', 'label': 'Chia Nan University of Pharmacy and Science' },
    { 'id': 9865, 'value': 'Chiang Kai Shek College', 'label': 'Chiang Kai Shek College' },
    { 'id': 11389, 'value': 'Chiang Mai Rajabhat University', 'label': 'Chiang Mai Rajabhat University' },
    { 'id': 11390, 'value': 'Chiang Mai University', 'label': 'Chiang Mai University' },
    { 'id': 11391, 'value': 'Chiang Rai Rajabhat University', 'label': 'Chiang Rai Rajabhat University' },
    { 'id': 7108, 'value': 'Chiba Institute of Science', 'label': 'Chiba Institute of Science' },
    { 'id': 7109, 'value': 'Chiba Institute of Technology', 'label': 'Chiba Institute of Technology' },
    { 'id': 7110, 'value': 'Chiba Keizai University', 'label': 'Chiba Keizai University' },
    { 'id': 7111, 'value': 'Chiba University', 'label': 'Chiba University' },
    { 'id': 7112, 'value': 'Chiba University of Commerce', 'label': 'Chiba University of Commerce' },
    { 'id': 12473, 'value': 'Chicago State University', 'label': 'Chicago State University' },
    { 'id': 11814, 'value': 'Chien Hsin University of Science and Technology', 'label': 'Chien Hsin University of Science and Technology' },
    { 'id': 11815, 'value': 'Chienkuo Technology University', 'label': 'Chienkuo Technology University' },
    { 'id': 2212, 'value': 'Chifeng University', 'label': 'Chifeng University' },
    { 'id': 11816, 'value': 'Chihlee University of Technology', 'label': 'Chihlee University of Technology' },
    { 'id': 7113, 'value': 'Chikushi Jogakuen University', 'label': 'Chikushi Jogakuen University' },
    { 'id': 5697, 'value': 'Children\'s University', 'label': 'Children\'s University' },
    { 'id': 2213, 'value': 'China Academy of Art', 'label': 'China Academy of Art' },
    { 'id': 2214, 'value': 'China Agricultural University', 'label': 'China Agricultural University' },
    { 'id': 2215, 'value': 'China Central Academy of Fine Arts', 'label': 'China Central Academy of Fine Arts' },
    { 'id': 2216, 'value': 'China Conservatory of Music', 'label': 'China Conservatory of Music' },
    { 'id': 2217, 'value': 'China Europe International Business School', 'label': 'China Europe International Business School' },
    { 'id': 2218, 'value': 'China Foreign Affairs University', 'label': 'China Foreign Affairs University' },
    { 'id': 2219, 'value': 'China Jiliang University', 'label': 'China Jiliang University' },
    { 'id': 2220, 'value': 'China Medical University', 'label': 'China Medical University' },
    { 'id': 11817, 'value': 'China Medical University, Taiwan', 'label': 'China Medical University, Taiwan' },
    { 'id': 2221, 'value': 'China Pharmaceutical University', 'label': 'China Pharmaceutical University' },
    { 'id': 2222, 'value': 'China Three Gorges University', 'label': 'China Three Gorges University' },
    { 'id': 2223, 'value': 'China University of Geosciences Beijing', 'label': 'China University of Geosciences Beijing' },
    { 'id': 2224, 'value': 'China University of Geosciences Wuhan', 'label': 'China University of Geosciences Wuhan' },
    { 'id': 2225, 'value': 'China University of Labor Relations', 'label': 'China University of Labor Relations' },
    { 'id': 2226, 'value': 'China University of Mining and Technology', 'label': 'China University of Mining and Technology' },
    { 'id': 2227, 'value': 'China University of Petroleum', 'label': 'China University of Petroleum' },
    { 'id': 2228, 'value': 'China University of Political Science and Law', 'label': 'China University of Political Science and Law' },
    { 'id': 11818, 'value': 'China University of Science and Technology', 'label': 'China University of Science and Technology' },
    { 'id': 11819, 'value': 'China University of Technology', 'label': 'China University of Technology' },
    { 'id': 2229, 'value': 'China West Normal University', 'label': 'China West Normal University' },
    { 'id': 2230, 'value': 'China Women\'s University', 'label': 'China Women\'s University' },
    { 'id': 2231, 'value': 'China Youth University for Political Sciences', 'label': 'China Youth University for Political Sciences' },
    { 'id': 11820, 'value': 'Chinese Culture University', 'label': 'Chinese Culture University' },
    { 'id': 11821, 'value': 'Ching Kuo Institute of Management and Health', 'label': 'Ching Kuo Institute of Management and Health' },
    { 'id': 8591, 'value': 'Chinggis Khaan Institute', 'label': 'Chinggis Khaan Institute' },
    { 'id': 14304, 'value': 'Chinhoyi University of Technology', 'label': 'Chinhoyi University of Technology' },
    { 'id': 7982, 'value': 'Chinju National University of Education', 'label': 'Chinju National University of Education' },
    { 'id': 12474, 'value': 'Chipola College', 'label': 'Chipola College' },
    { 'id': 10655, 'value': 'Chita State Academy of Medicine', 'label': 'Chita State Academy of Medicine' },
    { 'id': 5698, 'value': 'Chitkara University', 'label': 'Chitkara University' },
    { 'id': 7114, 'value': 'Chitose Institute of Science and Technology', 'label': 'Chitose Institute of Science and Technology' },
    { 'id': 1233, 'value': 'Chittagong Independent University', 'label': 'Chittagong Independent University' },
    { 'id': 1234, 'value': 'Chittagong Medical University', 'label': 'Chittagong Medical University' },
    { 'id': 1235, 'value': 'Chittagong University of Engineering and Technology', 'label': 'Chittagong University of Engineering and Technology' },
    { 'id': 1236, 'value': 'Chittagong Veterinary and Animal Sciences University', 'label': 'Chittagong Veterinary and Animal Sciences University' },
    { 'id': 2232, 'value': 'Chizhou University', 'label': 'Chizhou University' },
    { 'id': 7983, 'value': 'Chodang University', 'label': 'Chodang University' },
    { 'id': 7984, 'value': 'Chonbuk National University', 'label': 'Chonbuk National University' },
    { 'id': 7940, 'value': 'Chongjin Medical University', 'label': 'Chongjin Medical University' },
    { 'id': 7941, 'value': 'Chongjin Metal Mining University', 'label': 'Chongjin Metal Mining University' },
    { 'id': 7942, 'value': 'Chongjin University of Light Industry', 'label': 'Chongjin University of Light Industry' },
    { 'id': 7943, 'value': 'Chongjin University of Pedagogy', 'label': 'Chongjin University of Pedagogy' },
    { 'id': 2233, 'value': 'Chongqing College of Humanities, Science and Technology', 'label': 'Chongqing College of Humanities, Science and Technology' },
    { 'id': 2234, 'value': 'Chongqing Institute of Engineering', 'label': 'Chongqing Institute of Engineering' },
    { 'id': 2235, 'value': 'Chongqing Jiaotong University', 'label': 'Chongqing Jiaotong University' },
    { 'id': 2236, 'value': 'Chongqing Medical University', 'label': 'Chongqing Medical University' },
    { 'id': 2237, 'value': 'Chongqing Normal University', 'label': 'Chongqing Normal University' },
    { 'id': 2238, 'value': 'Chongqing Technology and Business University', 'label': 'Chongqing Technology and Business University' },
    { 'id': 2239, 'value': 'Chongqing Three Gorges University', 'label': 'Chongqing Three Gorges University' },
    { 'id': 2240, 'value': 'Chongqing University', 'label': 'Chongqing University' },
    { 'id': 2241, 'value': 'Chongqing University of Arts and Sciences', 'label': 'Chongqing University of Arts and Sciences' },
    { 'id': 2242, 'value': 'Chongqing University of Education', 'label': 'Chongqing University of Education' },
    { 'id': 2243, 'value': 'Chongqing University of Posts and Telecommunications', 'label': 'Chongqing University of Posts and Telecommunications' },
    { 'id': 2244, 'value': 'Chongqing University of Science and Technology', 'label': 'Chongqing University of Science and Technology' },
    { 'id': 2245, 'value': 'Chongqing University of Technology', 'label': 'Chongqing University of Technology' },
    { 'id': 7985, 'value': 'Chongshin University', 'label': 'Chongshin University' },
    { 'id': 7986, 'value': 'Chonnam National University', 'label': 'Chonnam National University' },
    { 'id': 7987, 'value': 'Chosun University', 'label': 'Chosun University' },
    { 'id': 12475, 'value': 'Chowan University', 'label': 'Chowan University' },
    { 'id': 14254, 'value': 'Chreso University', 'label': 'Chreso University' },
    { 'id': 9332, 'value': 'Chrisland University', 'label': 'Chrisland University' },
    { 'id': 4677, 'value': 'Christ Apostolic University College', 'label': 'Christ Apostolic University College' },
    { 'id': 9866, 'value': 'Christ the King College', 'label': 'Christ the King College' },
    { 'id': 5699, 'value': 'Christ University', 'label': 'Christ University' },
    { 'id': 292, 'value': 'Christ\'s College Cambridge', 'label': 'Christ\'s College Cambridge' },
    { 'id': 9516, 'value': 'Christelijke Hogeschool Ede', 'label': 'Christelijke Hogeschool Ede' },
    { 'id': 12476, 'value': 'Christian Brothers University', 'label': 'Christian Brothers University' },
    { 'id': 4678, 'value': 'Christian Service University College', 'label': 'Christian Service University College' },
    { 'id': 10994, 'value': 'Christian University of Rwanda', 'label': 'Christian University of Rwanda' },
    { 'id': 11392, 'value': 'Christian University of Thailand', 'label': 'Christian University of Thailand' },
    { 'id': 3473, 'value': 'Christian-Albrechts-Universität zu Kiel', 'label': 'Christian-Albrechts-Universität zu Kiel' },
    { 'id': 12477, 'value': 'Christopher Newport University', 'label': 'Christopher Newport University' },
    { 'id': 9333, 'value': 'Christopher University', 'label': 'Christopher University' },
    { 'id': 10248, 'value': 'Chrzescijanska Akademia Teologiczna w Warszawie', 'label': 'Chrzescijanska Akademia Teologiczna w Warszawie' },
    { 'id': 4796, 'value': 'Chu Hai College of Higher Education', 'label': 'Chu Hai College of Higher Education' },
    { 'id': 7115, 'value': 'Chubu Gakuin University', 'label': 'Chubu Gakuin University' },
    { 'id': 7116, 'value': 'Chubu University', 'label': 'Chubu University' },
    { 'id': 7117, 'value': 'Chugoku Gakuen University', 'label': 'Chugoku Gakuen University' },
    { 'id': 7988, 'value': 'Chugye University for the Arts', 'label': 'Chugye University for the Arts' },
    { 'id': 7850, 'value': 'Chui University', 'label': 'Chui University' },
    { 'id': 7784, 'value': 'Chuka University', 'label': 'Chuka University' },
    { 'id': 9334, 'value': 'Chukwuemeka Odumegwu Ojukwu University', 'label': 'Chukwuemeka Odumegwu Ojukwu University' },
    { 'id': 7118, 'value': 'Chukyo Gakuin University', 'label': 'Chukyo Gakuin University' },
    { 'id': 7119, 'value': 'Chukyo University', 'label': 'Chukyo University' },
    { 'id': 11393, 'value': 'Chulabhorn Royal Academy', 'label': 'Chulabhorn Royal Academy' },
    { 'id': 11394, 'value': 'Chulalongkorn University', 'label': 'Chulalongkorn University' },
    { 'id': 7989, 'value': 'Chuncheon National University of Education', 'label': 'Chuncheon National University of Education' },
    { 'id': 11822, 'value': 'Chung Chou University of Science and Technology', 'label': 'Chung Chou University of Science and Technology' },
    { 'id': 11823, 'value': 'Chung Hua University', 'label': 'Chung Hua University' },
    { 'id': 11824, 'value': 'Chung Hwa University of Medical Technology', 'label': 'Chung Hwa University of Medical Technology' },
    { 'id': 11825, 'value': 'Chung Shan Medical University', 'label': 'Chung Shan Medical University' },
    { 'id': 11826, 'value': 'Chung Yuan Christian University', 'label': 'Chung Yuan Christian University' },
    { 'id': 7990, 'value': 'Chung-Ang University', 'label': 'Chung-Ang University' },
    { 'id': 7991, 'value': 'Chungbuk National University', 'label': 'Chungbuk National University' },
    { 'id': 7992, 'value': 'Chungnam National University', 'label': 'Chungnam National University' },
    { 'id': 7993, 'value': 'Chungwoon University', 'label': 'Chungwoon University' },
    { 'id': 11827, 'value': 'Chungyu University of Film and Arts Technology', 'label': 'Chungyu University of Film and Arts Technology' },
    { 'id': 7120, 'value': 'Chuo Gakuin University', 'label': 'Chuo Gakuin University' },
    { 'id': 7121, 'value': 'Chuo University', 'label': 'Chuo University' },
    { 'id': 10656, 'value': 'Chuvash State Agricultural Academy', 'label': 'Chuvash State Agricultural Academy' },
    { 'id': 10657, 'value': 'Chuvash State Pedagogical University', 'label': 'Chuvash State Pedagogical University' },
    { 'id': 10658, 'value': 'Chuvash State University', 'label': 'Chuvash State University' },
    { 'id': 2246, 'value': 'Chuxiong Normal University', 'label': 'Chuxiong Normal University' },
    { 'id': 2247, 'value': 'Chuzhou University', 'label': 'Chuzhou University' },
    { 'id': 6448, 'value': 'Cihan University', 'label': 'Cihan University' },
    { 'id': 12478, 'value': 'Cincinnati Christian University', 'label': 'Cincinnati Christian University' },
    { 'id': 11798, 'value': 'Cipriani College of Labour and Cooperative Studies', 'label': 'Cipriani College of Labour and Cooperative Studies' },
    { 'id': 8592, 'value': 'Citi University', 'label': 'Citi University' },
    { 'id': 14255, 'value': 'Citizen University', 'label': 'Citizen University' },
    { 'id': 3350, 'value': 'City Unity College Nicosia', 'label': 'City Unity College Nicosia' },
    { 'id': 1237, 'value': 'City University', 'label': 'City University' },
    { 'id': 632, 'value': 'City University College of Ajman', 'label': 'City University College of Ajman' },
    { 'id': 4797, 'value': 'City University of Hong Kong', 'label': 'City University of Hong Kong' },
    { 'id': 10061, 'value': 'City University of Science and Information Technology', 'label': 'City University of Science and Information Technology' },
    { 'id': 14256, 'value': 'City University of Science and Technology', 'label': 'City University of Science and Technology' },
    { 'id': 12479, 'value': 'City University of Seattle', 'label': 'City University of Seattle' },
    { 'id': 568, 'value': 'City, University of London', 'label': 'City, University of London' },
    { 'id': 14535, 'value': 'civica Scuola di Cinema Luchino Visconti - Milano', 'label': 'civica Scuola di Cinema Luchino Visconti - Milano' },
    { 'id': 6958, 'value': 'Civica Scuola di Musica - MILANO', 'label': 'Civica Scuola di Musica - MILANO' },
    { 'id': 6886, 'value': 'Civica Scuola di Teatro Paolo Grassi', 'label': 'Civica Scuola di Teatro Paolo Grassi' },
    { 'id': 2248, 'value': 'Civil Aviation Flight University of China', 'label': 'Civil Aviation Flight University of China' },
    { 'id': 2249, 'value': 'Civil Aviation University of China', 'label': 'Civil Aviation University of China' },
    { 'id': 12480, 'value': 'Claflin University', 'label': 'Claflin University' },
    { 'id': 12481, 'value': 'Claremont Graduate University', 'label': 'Claremont Graduate University' },
    { 'id': 12482, 'value': 'Claremont McKenna College', 'label': 'Claremont McKenna College' },
    { 'id': 12483, 'value': 'Clarion University of Pennsylvania', 'label': 'Clarion University of Pennsylvania' },
    { 'id': 12484, 'value': 'Clark Atlanta University', 'label': 'Clark Atlanta University' },
    { 'id': 12485, 'value': 'Clark University', 'label': 'Clark University' },
    { 'id': 12169, 'value': 'Clarke International University', 'label': 'Clarke International University' },
    { 'id': 12486, 'value': 'Clarke University', 'label': 'Clarke University' },
    { 'id': 12487, 'value': 'Clarks Summit University', 'label': 'Clarks Summit University' },
    { 'id': 12488, 'value': 'Clarkson College', 'label': 'Clarkson College' },
    { 'id': 12489, 'value': 'Clarkson University', 'label': 'Clarkson University' },
    { 'id': 12490, 'value': 'Clayton State University', 'label': 'Clayton State University' },
    { 'id': 12491, 'value': 'Clear Creek Baptist Bible College', 'label': 'Clear Creek Baptist Bible College' },
    { 'id': 12492, 'value': 'Cleary University', 'label': 'Cleary University' },
    { 'id': 12493, 'value': 'Clemson University', 'label': 'Clemson University' },
    { 'id': 12494, 'value': 'Cleveland Institute of Art', 'label': 'Cleveland Institute of Art' },
    { 'id': 12495, 'value': 'Cleveland Institute of Music', 'label': 'Cleveland Institute of Music' },
    { 'id': 12496, 'value': 'Cleveland State University', 'label': 'Cleveland State University' },
    { 'id': 12497, 'value': 'Cleveland University-Kansas City', 'label': 'Cleveland University-Kansas City' },
    { 'id': 9335, 'value': 'Clifford University', 'label': 'Clifford University' },
    { 'id': 5700, 'value': 'Cluster University of Jammu', 'label': 'Cluster University of Jammu' },
    { 'id': 5701, 'value': 'CMJ University', 'label': 'CMJ University' },
    { 'id': 5702, 'value': 'CMR University', 'label': 'CMR University' },
    { 'id': 9336, 'value': 'Coal City University', 'label': 'Coal City University' },
    { 'id': 12498, 'value': 'Coastal Carolina University', 'label': 'Coastal Carolina University' },
    { 'id': 5703, 'value': 'Cochin University of Science and Technology', 'label': 'Cochin University of Science and Technology' },
    { 'id': 553, 'value': 'Codarts Rotterdam', 'label': 'Codarts Rotterdam' },
    { 'id': 12499, 'value': 'Coe College', 'label': 'Coe College' },
    { 'id': 12500, 'value': 'Cogswell Polytechnical College', 'label': 'Cogswell Polytechnical College' },
    { 'id': 12501, 'value': 'Coker University', 'label': 'Coker University' },
    { 'id': 12502, 'value': 'Colby College', 'label': 'Colby College' },
    { 'id': 12503, 'value': 'Colby-Sawyer College', 'label': 'Colby-Sawyer College' },
    { 'id': 9867, 'value': 'Colegio de Dagupan', 'label': 'Colegio de Dagupan' },
    { 'id': 3038, 'value': 'Colegio de Estudios Superiores de Administración', 'label': 'Colegio de Estudios Superiores de Administración' },
    { 'id': 9868, 'value': 'Colegio de San Juan de Letran', 'label': 'Colegio de San Juan de Letran' },
    { 'id': 3039, 'value': 'Colegio Mayor de Antioquia', 'label': 'Colegio Mayor de Antioquia' },
    { 'id': 8709, 'value': 'Colegio St. John\'s S.C.', 'label': 'Colegio St. John\'s S.C.' },
    { 'id': 10348, 'value': 'Colegio Universitario de San Juan', 'label': 'Colegio Universitario de San Juan' },
    { 'id': 12504, 'value': 'Colgate University', 'label': 'Colgate University' },
    { 'id': 1345, 'value': 'Collège d\'Europe', 'label': 'Collège d\'Europe' },
    { 'id': 12505, 'value': 'College for Creative Studies', 'label': 'College for Creative Studies' },
    { 'id': 7027, 'value': 'College of Agriculture Science and Education', 'label': 'College of Agriculture Science and Education' },
    { 'id': 9614, 'value': 'College of Applied Sciences, Ibri', 'label': 'College of Applied Sciences, Ibri' },
    { 'id': 9615, 'value': 'College of Applied Sciences, Nizwa', 'label': 'College of Applied Sciences, Nizwa' },
    { 'id': 9616, 'value': 'College of Applied Sciences, Salalah', 'label': 'College of Applied Sciences, Salalah' },
    { 'id': 9617, 'value': 'College of Applied Sciences, Sohar', 'label': 'College of Applied Sciences, Sohar' },
    { 'id': 9618, 'value': 'College of Applied Sciences, Sur', 'label': 'College of Applied Sciences, Sur' },
    { 'id': 9619, 'value': 'College of Banking and Financial Studies', 'label': 'College of Banking and Financial Studies' },
    { 'id': 12506, 'value': 'College of Biblical Studies', 'label': 'College of Biblical Studies' },
    { 'id': 12507, 'value': 'College of Central Florida', 'label': 'College of Central Florida' },
    { 'id': 12508, 'value': 'College of Charleston', 'label': 'College of Charleston' },
    { 'id': 12509, 'value': 'College of Coastal Georgia', 'label': 'College of Coastal Georgia' },
    { 'id': 182, 'value': 'College of Europe', 'label': 'College of Europe' },
    { 'id': 5536, 'value': 'College of Law and Business', 'label': 'College of Law and Business' },
    { 'id': 12510, 'value': 'College of Mount Saint Vincent', 'label': 'College of Mount Saint Vincent' },
    { 'id': 12511, 'value': 'College of Our Lady of the Elms', 'label': 'College of Our Lady of the Elms' },
    { 'id': 12512, 'value': 'College of Saint Benedict/Saint John\'s University', 'label': 'College of Saint Benedict/Saint John\'s University' },
    { 'id': 12513, 'value': 'College of Saint Elizabeth', 'label': 'College of Saint Elizabeth' },
    { 'id': 12514, 'value': 'College of Saint Mary', 'label': 'College of Saint Mary' },
    { 'id': 11799, 'value': 'College of Science, Technology and Applied Arts of Trinidad and Tobago', 'label': 'College of Science, Technology and Applied Arts of Trinidad and Tobago' },
    { 'id': 9620, 'value': 'College of Shari\'a Sciences', 'label': 'College of Shari\'a Sciences' },
    { 'id': 12515, 'value': 'College of Southern Nevada', 'label': 'College of Southern Nevada' },
    { 'id': 12516, 'value': 'College of St. Joseph', 'label': 'College of St. Joseph' },
    { 'id': 12517, 'value': 'College of Staten Island', 'label': 'College of Staten Island' },
    { 'id': 12518, 'value': 'College of the Atlantic', 'label': 'College of the Atlantic' },
    { 'id': 12519, 'value': 'College of the Holy Cross', 'label': 'College of the Holy Cross' },
    { 'id': 9869, 'value': 'College of the Holy Spirit', 'label': 'College of the Holy Spirit' },
    { 'id': 1863, 'value': 'College of the North Atlantic', 'label': 'College of the North Atlantic' },
    { 'id': 10506, 'value': 'College of the North Atlantic - Qatar', 'label': 'College of the North Atlantic - Qatar' },
    { 'id': 12520, 'value': 'College of the Ozarks', 'label': 'College of the Ozarks' },
    { 'id': 3351, 'value': 'College of Tourism and Hotel Management', 'label': 'College of Tourism and Hotel Management' },
    { 'id': 1426, 'value': 'College of Tourism Blagoevgrad', 'label': 'College of Tourism Blagoevgrad' },
    { 'id': 12521, 'value': 'College of William & Mary', 'label': 'College of William & Mary' },
    { 'id': 1864, 'value': 'Collège Universitaire Dominicain', 'label': 'Collège Universitaire Dominicain' },
    { 'id': 12522, 'value': 'Colorado Christian University', 'label': 'Colorado Christian University' },
    { 'id': 12523, 'value': 'Colorado College', 'label': 'Colorado College' },
    { 'id': 12524, 'value': 'Colorado Mesa University', 'label': 'Colorado Mesa University' },
    { 'id': 12525, 'value': 'Colorado School of Mines', 'label': 'Colorado School of Mines' },
    { 'id': 12526, 'value': 'Colorado State University', 'label': 'Colorado State University' },
    { 'id': 12527, 'value': 'Colorado State University-Pueblo', 'label': 'Colorado State University-Pueblo' },
    { 'id': 12528, 'value': 'Colorado Technical University', 'label': 'Colorado Technical University' },
    { 'id': 12529, 'value': 'Columbia Basin College', 'label': 'Columbia Basin College' },
    { 'id': 397, 'value': 'Columbia Business School', 'label': 'Columbia Business School' },
    { 'id': 10349, 'value': 'Columbia Central University', 'label': 'Columbia Central University' },
    { 'id': 12530, 'value': 'Columbia College', 'label': 'Columbia College' },
    { 'id': 12531, 'value': 'Columbia College Chicago', 'label': 'Columbia College Chicago' },
    { 'id': 12532, 'value': 'Columbia College Hollywood', 'label': 'Columbia College Hollywood' },
    { 'id': 12533, 'value': 'Columbia College of Nursing', 'label': 'Columbia College of Nursing' },
    { 'id': 12534, 'value': 'Columbia College, South Carolina', 'label': 'Columbia College, South Carolina' },
    { 'id': 12535, 'value': 'Columbia International University', 'label': 'Columbia International University' },
    { 'id': 136, 'value': 'Columbia Law School', 'label': 'Columbia Law School' },
    { 'id': 12536, 'value': 'Columbia University in the City of New York', 'label': 'Columbia University in the City of New York' },
    { 'id': 12537, 'value': 'Columbus College of Art and Design', 'label': 'Columbus College of Art and Design' },
    { 'id': 12538, 'value': 'Columbus State University', 'label': 'Columbus State University' },
    { 'id': 9649, 'value': 'Columbus University', 'label': 'Columbus University' },
    { 'id': 561, 'value': 'Comenius University of Bratislava', 'label': 'Comenius University of Bratislava' },
    { 'id': 1238, 'value': 'Comilla University', 'label': 'Comilla University' },
    { 'id': 10062, 'value': 'Commecs Institute of Business and Emerging Sciences', 'label': 'Commecs Institute of Business and Emerging Sciences' },
    { 'id': 2250, 'value': 'Communication University of China', 'label': 'Communication University of China' },
    { 'id': 2251, 'value': 'Communication University of Shanxi', 'label': 'Communication University of Shanxi' },
    { 'id': 2252, 'value': 'Communication University of Zhejiang', 'label': 'Communication University of Zhejiang' },
    { 'id': 10063, 'value': 'COMSATS Institute of Information Technology', 'label': 'COMSATS Institute of Information Technology' },
    { 'id': 12539, 'value': 'Concord University', 'label': 'Concord University' },
    { 'id': 12540, 'value': 'Concordia College', 'label': 'Concordia College' },
    { 'id': 12541, 'value': 'Concordia College Alabama', 'label': 'Concordia College Alabama' },
    { 'id': 12542, 'value': 'Concordia College-New York', 'label': 'Concordia College-New York' },
    { 'id': 361, 'value': 'Concordia University', 'label': 'Concordia University' },
    { 'id': 12543, 'value': 'Concordia University Ann Arbor', 'label': 'Concordia University Ann Arbor' },
    { 'id': 12544, 'value': 'Concordia University Chicago', 'label': 'Concordia University Chicago' },
    { 'id': 12545, 'value': 'Concordia University Irvine', 'label': 'Concordia University Irvine' },
    { 'id': 1865, 'value': 'Concordia University of Edmonton', 'label': 'Concordia University of Edmonton' },
    { 'id': 12546, 'value': 'Concordia University Texas', 'label': 'Concordia University Texas' },
    { 'id': 12547, 'value': 'Concordia University Wisconsin', 'label': 'Concordia University Wisconsin' },
    { 'id': 12548, 'value': 'Concordia University, Nebraska', 'label': 'Concordia University, Nebraska' },
    { 'id': 12549, 'value': 'Concordia University, Oregon', 'label': 'Concordia University, Oregon' },
    { 'id': 12550, 'value': 'Concordia University, St. Paul', 'label': 'Concordia University, St. Paul' },
    { 'id': 281, 'value': 'Condé Nast College of Fashion & Design', 'label': 'Condé Nast College of Fashion & Design' },
    { 'id': 12551, 'value': 'Connecticut College', 'label': 'Connecticut College' },
    { 'id': 1346, 'value': 'Conservatoire Royal de Bruxelles', 'label': 'Conservatoire Royal de Bruxelles' },
    { 'id': 1347, 'value': 'Conservatoire Royal de Liège', 'label': 'Conservatoire Royal de Liège' },
    { 'id': 262, 'value': 'Conservatorio “G.Verdi” Milano', 'label': 'Conservatorio “G.Verdi” Milano' },
    { 'id': 10350, 'value': 'Conservatorio de Música de Puerto Rico', 'label': 'Conservatorio de Música de Puerto Rico' },
    { 'id': 3040, 'value': 'Conservatorio del Tolima', 'label': 'Conservatorio del Tolima' },
    { 'id': 319, 'value': 'Conservatorio della Svizzera italiana', 'label': 'Conservatorio della Svizzera italiana' },
    { 'id': 206, 'value': 'Conservatorio di Musica "Stanislao Giacomantonio Cosenza', 'label': 'Conservatorio di Musica "Stanislao Giacomantonio Cosenza' },
    { 'id': 549, 'value': 'Conservatorio di Musica “Francesco Morlacchi”', 'label': 'Conservatorio di Musica “Francesco Morlacchi”' },
    { 'id': 427, 'value': 'Conservatorio di Musica A. Boito di Parma', 'label': 'Conservatorio di Musica A. Boito di Parma' },
    { 'id': 7020, 'value': 'Conservatorio di musica di ADRIA "Antonio Buzzolla"', 'label': 'Conservatorio di musica di ADRIA "Antonio Buzzolla"' },
    { 'id': 6971, 'value': 'Conservatorio di musica di ALESSANDRIA "Antonio Vivaldi"', 'label': 'Conservatorio di musica di ALESSANDRIA "Antonio Vivaldi"' },
    { 'id': 6898, 'value': 'Conservatorio di musica di AVELLINO "Domenico Cimarosa"', 'label': 'Conservatorio di musica di AVELLINO "Domenico Cimarosa"' },
    { 'id': 6980, 'value': 'Conservatorio di musica di BARI "Niccolò Piccinni"', 'label': 'Conservatorio di musica di BARI "Niccolò Piccinni"' },
    { 'id': 6899, 'value': 'Conservatorio di musica di BENEVENTO "Nicola Sala"', 'label': 'Conservatorio di musica di BENEVENTO "Nicola Sala"' },
    { 'id': 6905, 'value': 'Conservatorio di musica di BOLOGNA "Giovan B. Martini"', 'label': 'Conservatorio di musica di BOLOGNA "Giovan B. Martini"' },
    { 'id': 7008, 'value': 'Conservatorio di musica di BOLZANO "Claudio Monteverdi"', 'label': 'Conservatorio di musica di BOLZANO "Claudio Monteverdi"' },
    { 'id': 6949, 'value': 'Conservatorio di musica di BRESCIA "Luca Marenzio"', 'label': 'Conservatorio di musica di BRESCIA "Luca Marenzio"' },
    { 'id': 6950, 'value': 'Conservatorio di musica di BRESCIA "Luca Marenzio" - sezione staccata di DARFO BOARIO TERME', 'label': 'Conservatorio di musica di BRESCIA "Luca Marenzio" - sezione staccata di DARFO BOARIO TERME' },
    { 'id': 6988, 'value': 'Conservatorio di musica di CAGLIARI "Pierluigi da Palestrina"', 'label': 'Conservatorio di musica di CAGLIARI "Pierluigi da Palestrina"' },
    { 'id': 6967, 'value': 'Conservatorio di musica di CAMPOBASSO "Lorenzo Perosi"', 'label': 'Conservatorio di musica di CAMPOBASSO "Lorenzo Perosi"' },
    { 'id': 7021, 'value': 'Conservatorio di musica di CASTELFRANCO VENETO "Agostino Steffani"', 'label': 'Conservatorio di musica di CASTELFRANCO VENETO "Agostino Steffani"' },
    { 'id': 6906, 'value': 'Conservatorio di musica di CESENA "Bruno Maderna"', 'label': 'Conservatorio di musica di CESENA "Bruno Maderna"' },
    { 'id': 6951, 'value': 'Conservatorio di musica di COMO "Giuseppe Verdi"', 'label': 'Conservatorio di musica di COMO "Giuseppe Verdi"' },
    { 'id': 6892, 'value': 'Conservatorio di musica di COSENZA "Stanislao Giacomantonio"', 'label': 'Conservatorio di musica di COSENZA "Stanislao Giacomantonio"' },
    { 'id': 14324, 'value': 'Conservatorio di musica di Cuneo - G.F. Ghedini', 'label': 'Conservatorio di musica di Cuneo - G.F. Ghedini' },
    { 'id': 6963, 'value': 'Conservatorio di musica di FERMO "Giovambattista Pergolesi"', 'label': 'Conservatorio di musica di FERMO "Giovambattista Pergolesi"' },
    { 'id': 6907, 'value': 'Conservatorio di musica di FERRARA "Girolamo Frescobaldi"', 'label': 'Conservatorio di musica di FERRARA "Girolamo Frescobaldi"' },
    { 'id': 433, 'value': 'Conservatorio di musica di Firenze "Luigi Cherubini"', 'label': 'Conservatorio di musica di Firenze "Luigi Cherubini"' },
    { 'id': 6981, 'value': 'Conservatorio di musica di FOGGIA "Umberto Giordano"', 'label': 'Conservatorio di musica di FOGGIA "Umberto Giordano"' },
    { 'id': 6982, 'value': 'Conservatorio di musica di FOGGIA "Umberto Giordano" - sezione staccata di RODI GARGANICO', 'label': 'Conservatorio di musica di FOGGIA "Umberto Giordano" - sezione staccata di RODI GARGANICO' },
    { 'id': 6924, 'value': 'Conservatorio di musica di FROSINONE "Licinio Refice"', 'label': 'Conservatorio di musica di FROSINONE "Licinio Refice"' },
    { 'id': 6939, 'value': 'Conservatorio di musica di GENOVA "Nicolò Paganini"', 'label': 'Conservatorio di musica di GENOVA "Nicolò Paganini"' },
    { 'id': 6880, 'value': 'Conservatorio di musica di L\'AQUILA "Alfredo Casella"', 'label': 'Conservatorio di musica di L\'AQUILA "Alfredo Casella"' },
    { 'id': 6940, 'value': 'Conservatorio di musica di LA SPEZIA "Giacomo Puccini"', 'label': 'Conservatorio di musica di LA SPEZIA "Giacomo Puccini"' },
    { 'id': 6983, 'value': 'Conservatorio di musica di LECCE "Tito Schipa"', 'label': 'Conservatorio di musica di LECCE "Tito Schipa"' },
    { 'id': 6984, 'value': 'Conservatorio di musica di LECCE "Tito Schipa" - sezione staccata di CEGLIE MESSAPICA', 'label': 'Conservatorio di musica di LECCE "Tito Schipa" - sezione staccata di CEGLIE MESSAPICA' },
    { 'id': 6952, 'value': 'Conservatorio di musica di MANTOVA "Lucio Campiani"', 'label': 'Conservatorio di musica di MANTOVA "Lucio Campiani"' },
    { 'id': 6883, 'value': 'Conservatorio di musica di MATERA "Egidio R. Duni"', 'label': 'Conservatorio di musica di MATERA "Egidio R. Duni"' },
    { 'id': 6995, 'value': 'Conservatorio di musica di MESSINA "Arcangelo Corelli"', 'label': 'Conservatorio di musica di MESSINA "Arcangelo Corelli"' },
    { 'id': 6953, 'value': 'Conservatorio di musica di MILANO "Giuseppe Verdi"', 'label': 'Conservatorio di musica di MILANO "Giuseppe Verdi"' },
    { 'id': 6985, 'value': 'Conservatorio di musica di MONOPOLI "Nino Rota"', 'label': 'Conservatorio di musica di MONOPOLI "Nino Rota"' },
    { 'id': 6900, 'value': 'Conservatorio di musica di NAPOLI "S. Pietro a Majella"', 'label': 'Conservatorio di musica di NAPOLI "S. Pietro a Majella"' },
    { 'id': 6973, 'value': 'Conservatorio di musica di NOVARA "Guido Cantelli"', 'label': 'Conservatorio di musica di NOVARA "Guido Cantelli"' },
    { 'id': 7022, 'value': 'Conservatorio di musica di PADOVA "Cesare Pollini"', 'label': 'Conservatorio di musica di PADOVA "Cesare Pollini"' },
    { 'id': 609, 'value': 'Conservatorio di musica di PALERMO "Vincenzo Bellini"', 'label': 'Conservatorio di musica di PALERMO "Vincenzo Bellini"' },
    { 'id': 6908, 'value': 'Conservatorio di musica di PARMA "Arrigo Boito"', 'label': 'Conservatorio di musica di PARMA "Arrigo Boito"' },
    { 'id': 6964, 'value': 'Conservatorio di musica di PESARO "Gioacchino Rossini"', 'label': 'Conservatorio di musica di PESARO "Gioacchino Rossini"' },
    { 'id': 6881, 'value': 'Conservatorio di musica di PESCARA "Luisa d\'Annunzio"', 'label': 'Conservatorio di musica di PESCARA "Luisa d\'Annunzio"' },
    { 'id': 6909, 'value': 'Conservatorio di musica di PIACENZA "Giuseppe Nicolini"', 'label': 'Conservatorio di musica di PIACENZA "Giuseppe Nicolini"' },
    { 'id': 6884, 'value': 'Conservatorio di musica di POTENZA "Gesualdo da Venosa"', 'label': 'Conservatorio di musica di POTENZA "Gesualdo da Venosa"' },
    { 'id': 6893, 'value': 'Conservatorio di musica di REGGIO CALABRIA "Francesco Cilea"', 'label': 'Conservatorio di musica di REGGIO CALABRIA "Francesco Cilea"' },
    { 'id': 6926, 'value': 'Conservatorio di musica di ROMA "Santa Cecilia"', 'label': 'Conservatorio di musica di ROMA "Santa Cecilia"' },
    { 'id': 7023, 'value': 'Conservatorio di musica di ROVIGO "Francesco Venezze"', 'label': 'Conservatorio di musica di ROVIGO "Francesco Venezze"' },
    { 'id': 6901, 'value': 'Conservatorio di musica di SALERNO "G. Martucci"', 'label': 'Conservatorio di musica di SALERNO "G. Martucci"' },
    { 'id': 6989, 'value': 'Conservatorio di musica di SASSARI "Luigi Canepa"', 'label': 'Conservatorio di musica di SASSARI "Luigi Canepa"' },
    { 'id': 6974, 'value': 'Conservatorio di musica di TORINO "Giuseppe Verdi"', 'label': 'Conservatorio di musica di TORINO "Giuseppe Verdi"' },
    { 'id': 6996, 'value': 'Conservatorio di musica di TRAPANI "Antonio Scontrino"', 'label': 'Conservatorio di musica di TRAPANI "Antonio Scontrino"' },
    { 'id': 7009, 'value': 'Conservatorio di musica di TRENTO "Francesco A. Bonporti"', 'label': 'Conservatorio di musica di TRENTO "Francesco A. Bonporti"' },
    { 'id': 7010, 'value': 'Conservatorio di musica di TRENTO "Francesco A. Bonporti" - sezione staccata di RIVA DEL GARDA', 'label': 'Conservatorio di musica di TRENTO "Francesco A. Bonporti" - sezione staccata di RIVA DEL GARDA' },
    { 'id': 6916, 'value': 'Conservatorio di musica di TRIESTE "Giuseppe Tartini"', 'label': 'Conservatorio di musica di TRIESTE "Giuseppe Tartini"' },
    { 'id': 6917, 'value': 'Conservatorio di musica di UDINE "Jacopo Tomadini"', 'label': 'Conservatorio di musica di UDINE "Jacopo Tomadini"' },
    { 'id': 7024, 'value': 'Conservatorio di musica di VENEZIA "Benedetto Marcello" - palazzo pisani', 'label': 'Conservatorio di musica di VENEZIA "Benedetto Marcello" - palazzo pisani' },
    { 'id': 7025, 'value': 'Conservatorio di musica di VERONA "E. F. dall\'Abaco"', 'label': 'Conservatorio di musica di VERONA "E. F. dall\'Abaco"' },
    { 'id': 6894, 'value': 'Conservatorio di musica di VIBO VALENTIA "Fausto Torrefranca"', 'label': 'Conservatorio di musica di VIBO VALENTIA "Fausto Torrefranca"' },
    { 'id': 7026, 'value': 'Conservatorio di musica di VICENZA "Arrigo Pedrollo"', 'label': 'Conservatorio di musica di VICENZA "Arrigo Pedrollo"' },
    { 'id': 480, 'value': 'CONSERVATORIO DI MUSICA G.B. PERGOLESI', 'label': 'CONSERVATORIO DI MUSICA G.B. PERGOLESI' },
    { 'id': 581, 'value': 'Conservatorio di Musica Giuseppe Martucci', 'label': 'Conservatorio di Musica Giuseppe Martucci' },
    { 'id': 437, 'value': 'Conservatorio di Musica Lorenzo Perosi Campobasso', 'label': 'Conservatorio di Musica Lorenzo Perosi Campobasso' },
    { 'id': 378, 'value': 'Conservatorio G. Tartini - Trieste', 'label': 'Conservatorio G. Tartini - Trieste' },
    { 'id': 451, 'value': 'Conservatorio Girolamo Frescobaldi Ferrara', 'label': 'Conservatorio Girolamo Frescobaldi Ferrara' },
    { 'id': 525, 'value': 'Conservatorio Ottorino Respighi di Latina', 'label': 'Conservatorio Ottorino Respighi di Latina' },
    { 'id': 14374, 'value': 'Conservatorio statale di Musica "Alfredo Casella"', 'label': 'Conservatorio statale di Musica "Alfredo Casella"' },
    { 'id': 14375, 'value': 'Conservatorio statale di Musica "Alfredo Casella"', 'label': 'Conservatorio statale di Musica "Alfredo Casella"' },
    { 'id': 14376, 'value': 'Conservatorio statale di Musica "Alfredo Casella"', 'label': 'Conservatorio statale di Musica "Alfredo Casella"' },
    { 'id': 112, 'value': 'Conservatorio Statale di Musica "Cesare Pollini"', 'label': 'Conservatorio Statale di Musica "Cesare Pollini"' },
    { 'id': 459, 'value': 'Conservatorio Statale di Musica "G. Nicolini"', 'label': 'Conservatorio Statale di Musica "G. Nicolini"' },
    { 'id': 54, 'value': 'Conservatorio Statale di Musica "Niccolò Paganini"', 'label': 'Conservatorio Statale di Musica "Niccolò Paganini"' },
    { 'id': 140, 'value': 'Conservatorio Statale di Musica di Potenza', 'label': 'Conservatorio Statale di Musica di Potenza' },
    { 'id': 14348, 'value': 'CONSERVATORIO STATALE DI MUSICA GIUSEPPE VERDI TORINO', 'label': 'CONSERVATORIO STATALE DI MUSICA GIUSEPPE VERDI TORINO' },
    { 'id': 539, 'value': 'Conservatorio Statale di Musica Santa Cecilia', 'label': 'Conservatorio Statale di Musica Santa Cecilia' },
    { 'id': 10405, 'value': 'Conservatório Superior de Música de Gaia', 'label': 'Conservatório Superior de Música de Gaia' },
    { 'id': 12552, 'value': 'Converse College', 'label': 'Converse College' },
    { 'id': 12553, 'value': 'Conway School of Landscape Design', 'label': 'Conway School of Landscape Design' },
    { 'id': 5704, 'value': 'Cooch Behar Panchanan Barma University', 'label': 'Cooch Behar Panchanan Barma University' },
    { 'id': 342, 'value': 'COPENHAGEN BUSINESS SCHOOL', 'label': 'COPENHAGEN BUSINESS SCHOOL' },
    { 'id': 14257, 'value': 'Copperstone University', 'label': 'Copperstone University' },
    { 'id': 12554, 'value': 'Coppin State University', 'label': 'Coppin State University' },
    { 'id': 9870, 'value': 'Cor Jesu College', 'label': 'Cor Jesu College' },
    { 'id': 12555, 'value': 'Corban University', 'label': 'Corban University' },
    { 'id': 11341, 'value': 'Cordoba Private University', 'label': 'Cordoba Private University' },
    { 'id': 14344, 'value': 'COREP', 'label': 'COREP' },
    { 'id': 5506, 'value': 'Cork Institute of Technology', 'label': 'Cork Institute of Technology' },
    { 'id': 12556, 'value': 'Cornell College', 'label': 'Cornell College' },
    { 'id': 514, 'value': 'Cornell University', 'label': 'Cornell University' },
    { 'id': 12557, 'value': 'Cornerstone University', 'label': 'Cornerstone University' },
    { 'id': 12558, 'value': 'Cornish College of the Arts', 'label': 'Cornish College of the Arts' },
    { 'id': 3041, 'value': 'Corporacion Colegiatura Colombiana', 'label': 'Corporacion Colegiatura Colombiana' },
    { 'id': 3042, 'value': 'Corporacion Escuela de Artes y Letras', 'label': 'Corporacion Escuela de Artes y Letras' },
    { 'id': 3043, 'value': 'Corporacion Escuela Tecnologica del Oriente', 'label': 'Corporacion Escuela Tecnologica del Oriente' },
    { 'id': 3044, 'value': 'Corporacion Universidad de Investigacion y Desarrollo', 'label': 'Corporacion Universidad de Investigacion y Desarrollo' },
    { 'id': 3045, 'value': 'Corporación Universitaria Adventista', 'label': 'Corporación Universitaria Adventista' },
    { 'id': 3046, 'value': 'Corporacion Universitaria Americana', 'label': 'Corporacion Universitaria Americana' },
    { 'id': 3047, 'value': 'Corporacion Universitaria Antonio Jose de Sucre', 'label': 'Corporacion Universitaria Antonio Jose de Sucre' },
    { 'id': 3048, 'value': 'Corporacion Universitaria Autonoma de Nariño', 'label': 'Corporacion Universitaria Autonoma de Nariño' },
    { 'id': 3049, 'value': 'Corporacion Universitaria Autonoma del Cauca', 'label': 'Corporacion Universitaria Autonoma del Cauca' },
    { 'id': 3050, 'value': 'Corporacion Universitaria Centro Superior', 'label': 'Corporacion Universitaria Centro Superior' },
    { 'id': 3051, 'value': 'Corporacion Universitaria Comfacauca', 'label': 'Corporacion Universitaria Comfacauca' },
    { 'id': 3052, 'value': 'Corporacion Universitaria de Asturias', 'label': 'Corporacion Universitaria de Asturias' },
    { 'id': 3053, 'value': 'Corporacion Universitaria de Cataluña', 'label': 'Corporacion Universitaria de Cataluña' },
    { 'id': 3054, 'value': 'Corporacion Universitaria de Ciencia y Desarrollo', 'label': 'Corporacion Universitaria de Ciencia y Desarrollo' },
    { 'id': 3055, 'value': 'Corporacion Universitaria de Ciencias Empresariales, Educacion y Salud', 'label': 'Corporacion Universitaria de Ciencias Empresariales, Educacion y Salud' },
    { 'id': 3056, 'value': 'Corporacion Universitaria de Colombia Ideas', 'label': 'Corporacion Universitaria de Colombia Ideas' },
    { 'id': 3057, 'value': 'Corporación Universitaria de la Costa', 'label': 'Corporación Universitaria de la Costa' },
    { 'id': 3058, 'value': 'Corporacion Universitaria de Sabaneta', 'label': 'Corporacion Universitaria de Sabaneta' },
    { 'id': 3059, 'value': 'Corporacion Universitaria de Santa Rosa de Cabal', 'label': 'Corporacion Universitaria de Santa Rosa de Cabal' },
    { 'id': 3060, 'value': 'Corporacion Universitaria del Caribe', 'label': 'Corporacion Universitaria del Caribe' },
    { 'id': 3061, 'value': 'Corporacion Universitaria del Huila', 'label': 'Corporacion Universitaria del Huila' },
    { 'id': 3062, 'value': 'Corporacion Universitaria del Meta', 'label': 'Corporacion Universitaria del Meta' },
    { 'id': 3063, 'value': 'Corporacion Universitaria Empresarial Alexander Von Humboldt', 'label': 'Corporacion Universitaria Empresarial Alexander Von Humboldt' },
    { 'id': 3064, 'value': 'Corporacion Universitaria Empresarial de Salamanca', 'label': 'Corporacion Universitaria Empresarial de Salamanca' },
    { 'id': 3065, 'value': 'Corporacion Universitaria Iberoamericana', 'label': 'Corporacion Universitaria Iberoamericana' },
    { 'id': 3066, 'value': 'Corporación Universitaria Lasallista', 'label': 'Corporación Universitaria Lasallista' },
    { 'id': 3067, 'value': 'Corporacion Universitaria Latinoamericana', 'label': 'Corporacion Universitaria Latinoamericana' },
    { 'id': 3068, 'value': 'Corporación Universitaria Minuto de Dios', 'label': 'Corporación Universitaria Minuto de Dios' },
    { 'id': 3069, 'value': 'Corporacion Universitaria Rafael Nuñez', 'label': 'Corporacion Universitaria Rafael Nuñez' },
    { 'id': 3070, 'value': 'Corporacion Universitaria Reformada', 'label': 'Corporacion Universitaria Reformada' },
    { 'id': 3071, 'value': 'Corporacion Universitaria Remington', 'label': 'Corporacion Universitaria Remington' },
    { 'id': 3072, 'value': 'Corporacion Universitaria Republicana', 'label': 'Corporacion Universitaria Republicana' },
    { 'id': 3073, 'value': 'Corporacion Universitaria Taller Cinco', 'label': 'Corporacion Universitaria Taller Cinco' },
    { 'id': 3074, 'value': 'Corporacion Universitaria U de Colombia', 'label': 'Corporacion Universitaria U de Colombia' },
    { 'id': 3075, 'value': 'Corporación Universitaria Unitec', 'label': 'Corporación Universitaria Unitec' },
    { 'id': 12559, 'value': 'Cottey College', 'label': 'Cottey College' },
    { 'id': 5705, 'value': 'Cotton University', 'label': 'Cotton University' },
    { 'id': 12560, 'value': 'Covenant College', 'label': 'Covenant College' },
    { 'id': 9337, 'value': 'Covenant University', 'label': 'Covenant University' },
    { 'id': 4512, 'value': 'Coventry University', 'label': 'Coventry University' },
    { 'id': 375, 'value': 'Coventry University London', 'label': 'Coventry University London' },
    { 'id': 12561, 'value': 'Cox College', 'label': 'Cox College' },
    { 'id': 1239, 'value': 'Cox\'s Bazar International University', 'label': 'Cox\'s Bazar International University' },
    { 'id': 586, 'value': 'CPM Music Institute', 'label': 'CPM Music Institute' },
    { 'id': 12562, 'value': 'Cranbrook Academy of Art', 'label': 'Cranbrook Academy of Art' },
    { 'id': 1866, 'value': 'Crandall University', 'label': 'Crandall University' },
    { 'id': 499, 'value': 'Cranfield University', 'label': 'Cranfield University' },
    { 'id': 9338, 'value': 'Crawford University', 'label': 'Crawford University' },
    { 'id': 12563, 'value': 'Creighton University', 'label': 'Creighton University' },
    { 'id': 9339, 'value': 'Crescent University, Abeokuta', 'label': 'Crescent University, Abeokuta' },
    { 'id': 12564, 'value': 'Criswell College', 'label': 'Criswell College' },
    { 'id': 9340, 'value': 'Cross River University of Technology', 'label': 'Cross River University of Technology' },
    { 'id': 12565, 'value': 'Crossroads College', 'label': 'Crossroads College' },
    { 'id': 12566, 'value': 'Crowley\'s Ridge College', 'label': 'Crowley\'s Ridge College' },
    { 'id': 12567, 'value': 'Crown College', 'label': 'Crown College' },
    { 'id': 9341, 'value': 'Crown Hill University', 'label': 'Crown Hill University' },
    { 'id': 5706, 'value': 'CSK Himachal Pradesh Krishi Vishvavidyalaya', 'label': 'CSK Himachal Pradesh Krishi Vishvavidyalaya' },
    { 'id': 11828, 'value': 'CTBC Financial Management College', 'label': 'CTBC Financial Management College' },
    { 'id': 11673, 'value': 'Çukurova Üniversitesi', 'label': 'Çukurova Üniversitesi' },
    { 'id': 12568, 'value': 'Culver-Stockton College', 'label': 'Culver-Stockton College' },
    { 'id': 12569, 'value': 'Cumberland University', 'label': 'Cumberland University' },
    { 'id': 12570, 'value': 'CUNY School of Law', 'label': 'CUNY School of Law' },
    { 'id': 12571, 'value': 'Curry College', 'label': 'Curry College' },
    { 'id': 1076, 'value': 'Curtin University', 'label': 'Curtin University' },
    { 'id': 12572, 'value': 'Curtis Institute of Music', 'label': 'Curtis Institute of Music' },
    { 'id': 8327, 'value': 'Cuttington University', 'label': 'Cuttington University' },
    { 'id': 4257, 'value': 'CY Cergy Paris Université', 'label': 'CY Cergy Paris Université' },
    { 'id': 7122, 'value': 'Cyber University', 'label': 'Cyber University' },
    { 'id': 3352, 'value': 'Cyprus College', 'label': 'Cyprus College' },
    { 'id': 3353, 'value': 'Cyprus International Institute of Management', 'label': 'Cyprus International Institute of Management' },
    { 'id': 3354, 'value': 'Cyprus School of Molecular Medicine', 'label': 'Cyprus School of Molecular Medicine' },
    { 'id': 3355, 'value': 'Cyprus University of Technology', 'label': 'Cyprus University of Technology' },
    { 'id': 1427, 'value': 'D.A. Tsenov Academy of Economics', 'label': 'D.A. Tsenov Academy of Economics' },
    { 'id': 12615, 'value': 'D\'Youville College', 'label': 'D\'Youville College' },
    { 'id': 11830, 'value': 'Da-Yeh University', 'label': 'Da-Yeh University' },
    { 'id': 10064, 'value': 'Dadabhoy Institute of Higher Education', 'label': 'Dadabhoy Institute of Higher Education' },
    { 'id': 7994, 'value': 'Daegu Arts University', 'label': 'Daegu Arts University' },
    { 'id': 7995, 'value': 'Daegu Haany University', 'label': 'Daegu Haany University' },
    { 'id': 7996, 'value': 'Daegu National University of Education', 'label': 'Daegu National University of Education' },
    { 'id': 7997, 'value': 'Daegu University', 'label': 'Daegu University' },
    { 'id': 7998, 'value': 'Daejeon University', 'label': 'Daejeon University' },
    { 'id': 7999, 'value': 'Daejin University', 'label': 'Daejin University' },
    { 'id': 12573, 'value': 'Daemen College', 'label': 'Daemen College' },
    { 'id': 8000, 'value': 'Daeshin University', 'label': 'Daeshin University' },
    { 'id': 8678, 'value': 'Daeyang University', 'label': 'Daeyang University' },
    { 'id': 1240, 'value': 'Daffodil International University', 'label': 'Daffodil International University' },
    { 'id': 10659, 'value': 'Dagestan State Agrarian University', 'label': 'Dagestan State Agrarian University' },
    { 'id': 10660, 'value': 'Dagestan State Medical University', 'label': 'Dagestan State Medical University' },
    { 'id': 10661, 'value': 'Dagestan State Pedagogical University', 'label': 'Dagestan State Pedagogical University' },
    { 'id': 10662, 'value': 'Dagestan State Technical University', 'label': 'Dagestan State Technical University' },
    { 'id': 10663, 'value': 'Dagestan State University', 'label': 'Dagestan State University' },
    { 'id': 8502, 'value': 'Dagon University', 'label': 'Dagon University' },
    { 'id': 11829, 'value': 'Dahan Institute of Technology', 'label': 'Dahan Institute of Technology' },
    { 'id': 7123, 'value': 'Daido University', 'label': 'Daido University' },
    { 'id': 7124, 'value': 'Daiichi Institute of Technology', 'label': 'Daiichi Institute of Technology' },
    { 'id': 7125, 'value': 'Daiichi University of Pharmacy', 'label': 'Daiichi University of Pharmacy' },
    { 'id': 7126, 'value': 'Daito Bunka University', 'label': 'Daito Bunka University' },
    { 'id': 12574, 'value': 'Dakota State University', 'label': 'Dakota State University' },
    { 'id': 12575, 'value': 'Dakota Wesleyan University', 'label': 'Dakota Wesleyan University' },
    { 'id': 5707, 'value': 'Dakshina Bharat Hindi Prachar Sabha', 'label': 'Dakshina Bharat Hindi Prachar Sabha' },
    { 'id': 11062, 'value': 'Dalanj University', 'label': 'Dalanj University' },
    { 'id': 14112, 'value': 'Dalat University', 'label': 'Dalat University' },
    { 'id': 278, 'value': 'Dalhousie University', 'label': 'Dalhousie University' },
    { 'id': 2253, 'value': 'Dali University', 'label': 'Dali University' },
    { 'id': 2254, 'value': 'Dalian Art College', 'label': 'Dalian Art College' },
    { 'id': 2255, 'value': 'Dalian Jiaotong University', 'label': 'Dalian Jiaotong University' },
    { 'id': 2256, 'value': 'Dalian Maritime University', 'label': 'Dalian Maritime University' },
    { 'id': 2257, 'value': 'Dalian Medical University', 'label': 'Dalian Medical University' },
    { 'id': 2258, 'value': 'Dalian Nationalities University', 'label': 'Dalian Nationalities University' },
    { 'id': 2259, 'value': 'Dalian Neusoft University of Information', 'label': 'Dalian Neusoft University of Information' },
    { 'id': 2260, 'value': 'Dalian Ocean University', 'label': 'Dalian Ocean University' },
    { 'id': 2261, 'value': 'Dalian Polytechnic University', 'label': 'Dalian Polytechnic University' },
    { 'id': 2262, 'value': 'Dalian University', 'label': 'Dalian University' },
    { 'id': 2263, 'value': 'Dalian University of Finance and Economics', 'label': 'Dalian University of Finance and Economics' },
    { 'id': 2264, 'value': 'Dalian University of Foreign Languages', 'label': 'Dalian University of Foreign Languages' },
    { 'id': 2265, 'value': 'Dalian University of Science and Technology', 'label': 'Dalian University of Science and Technology' },
    { 'id': 2266, 'value': 'Dalian University of Technology', 'label': 'Dalian University of Technology' },
    { 'id': 12576, 'value': 'Dallas Baptist University', 'label': 'Dallas Baptist University' },
    { 'id': 12577, 'value': 'Dallas Christian College', 'label': 'Dallas Christian College' },
    { 'id': 12578, 'value': 'Dalton State College', 'label': 'Dalton State College' },
    { 'id': 4052, 'value': 'Damanhour University', 'label': 'Damanhour University' },
    { 'id': 11342, 'value': 'Damascus University', 'label': 'Damascus University' },
    { 'id': 6526, 'value': 'Damghan University', 'label': 'Damghan University' },
    { 'id': 4053, 'value': 'Damietta University', 'label': 'Damietta University' },
    { 'id': 5708, 'value': 'Damodaram Sanjivayya National Law University', 'label': 'Damodaram Sanjivayya National Law University' },
    { 'id': 11499, 'value': 'Dangara State University', 'label': 'Dangara State University' },
    { 'id': 700, 'value': 'Danish Institute of Higher Education', 'label': 'Danish Institute of Higher Education' },
    { 'id': 8001, 'value': 'Dankook University', 'label': 'Dankook University' },
    { 'id': 3817, 'value': 'Danmarks Medie- og journalisthøjskole', 'label': 'Danmarks Medie- og journalisthøjskole' },
    { 'id': 3818, 'value': 'Danmarks Tekniske Universitet', 'label': 'Danmarks Tekniske Universitet' },
    { 'id': 1004, 'value': 'Danube Private University', 'label': 'Danube Private University' },
    { 'id': 2267, 'value': 'Daqing Normal University', 'label': 'Daqing Normal University' },
    { 'id': 11022, 'value': 'Dar Al Uloom University', 'label': 'Dar Al Uloom University' },
    { 'id': 11023, 'value': 'Dar Al-Hekma University', 'label': 'Dar Al-Hekma University' },
    { 'id': 10386, 'value': 'Dar al-Kalima University College of Arts and Culture', 'label': 'Dar al-Kalima University College of Arts and Culture' },
    { 'id': 14192, 'value': 'Dar Al-Salam International University for Science and Technology', 'label': 'Dar Al-Salam International University for Science and Technology' },
    { 'id': 8593, 'value': 'Darkhan Institute', 'label': 'Darkhan Institute' },
    { 'id': 12579, 'value': 'Dartmouth College', 'label': 'Dartmouth College' },
    { 'id': 4679, 'value': 'Data Link Institute', 'label': 'Data Link Institute' },
    { 'id': 5709, 'value': 'Datta Meghe Institute of Medical Sciences', 'label': 'Datta Meghe Institute of Medical Sciences' },
    { 'id': 8372, 'value': 'Daugavpils Universitate', 'label': 'Daugavpils Universitate' },
    { 'id': 5710, 'value': 'DAV University', 'label': 'DAV University' },
    { 'id': 5711, 'value': 'Davangere University', 'label': 'Davangere University' },
    { 'id': 9871, 'value': 'Davao Doctors College', 'label': 'Davao Doctors College' },
    { 'id': 12580, 'value': 'Davenport University', 'label': 'Davenport University' },
    { 'id': 4626, 'value': 'David Aghmashenebeli University of Georgia', 'label': 'David Aghmashenebeli University of Georgia' },
    { 'id': 4627, 'value': 'David Tvildiani Medical University', 'label': 'David Tvildiani Medical University' },
    { 'id': 12581, 'value': 'Davidson College', 'label': 'Davidson College' },
    { 'id': 12582, 'value': 'Davis & Elkins College', 'label': 'Davis & Elkins College' },
    { 'id': 12583, 'value': 'Davis College', 'label': 'Davis College' },
    { 'id': 701, 'value': 'Dawat University', 'label': 'Dawat University' },
    { 'id': 8503, 'value': 'Dawei University', 'label': 'Dawei University' },
    { 'id': 10065, 'value': 'Dawood University of Engineering and Technology', 'label': 'Dawood University of Engineering and Technology' },
    { 'id': 5712, 'value': 'Dayalbagh Educational Institute', 'label': 'Dayalbagh Educational Institute' },
    { 'id': 5713, 'value': 'Dayananda Sagar University', 'label': 'Dayananda Sagar University' },
    { 'id': 7785, 'value': 'Daystar University', 'label': 'Daystar University' },
    { 'id': 12584, 'value': 'Daytona State College', 'label': 'Daytona State College' },
    { 'id': 9517, 'value': 'De Haagse Hogeschool', 'label': 'De Haagse Hogeschool' },
    { 'id': 9872, 'value': 'De La Salle Lipa', 'label': 'De La Salle Lipa' },
    { 'id': 9873, 'value': 'De La Salle Medical and Health Sciences Institute', 'label': 'De La Salle Medical and Health Sciences Institute' },
    { 'id': 9874, 'value': 'De La Salle University', 'label': 'De La Salle University' },
    { 'id': 9875, 'value': 'De La Salle-College of Saint Benilde', 'label': 'De La Salle-College of Saint Benilde' },
    { 'id': 4513, 'value': 'De Montfort University', 'label': 'De Montfort University' },
    { 'id': 1077, 'value': 'Deakin University', 'label': 'Deakin University' },
    { 'id': 12585, 'value': 'Dean College', 'label': 'Dean College' },
    { 'id': 4183, 'value': 'Debre Berhan University', 'label': 'Debre Berhan University' },
    { 'id': 4184, 'value': 'Debre Markos University', 'label': 'Debre Markos University' },
    { 'id': 4185, 'value': 'Debre Tabor University', 'label': 'Debre Tabor University' },
    { 'id': 4894, 'value': 'Debreceni Egyetem', 'label': 'Debreceni Egyetem' },
    { 'id': 4895, 'value': 'Debreceni Református Hittudományi Egyetem', 'label': 'Debreceni Református Hittudományi Egyetem' },
    { 'id': 5714, 'value': 'Deccan College Post-Graduate and Research Institute', 'label': 'Deccan College Post-Graduate and Research Institute' },
    { 'id': 7786, 'value': 'Dedan Kimathi University of Technology', 'label': 'Dedan Kimathi University of Technology' },
    { 'id': 5715, 'value': 'Deen Dayal Upadhyay Gorakhpur University', 'label': 'Deen Dayal Upadhyay Gorakhpur University' },
    { 'id': 5716, 'value': 'Deenbandhu Chhotu Ram University of Science and Technology', 'label': 'Deenbandhu Chhotu Ram University of Science and Technology' },
    { 'id': 12586, 'value': 'Defiance College', 'label': 'Defiance College' },
    { 'id': 3474, 'value': 'DEKRA Hochschule Berlin', 'label': 'DEKRA Hochschule Berlin' },
    { 'id': 12587, 'value': 'Delaware State University', 'label': 'Delaware State University' },
    { 'id': 12588, 'value': 'Delaware Valley University', 'label': 'Delaware Valley University' },
    { 'id': 5717, 'value': 'Delhi Pharmaceutical Sciences and Research University', 'label': 'Delhi Pharmaceutical Sciences and Research University' },
    { 'id': 5718, 'value': 'Delhi Technological University', 'label': 'Delhi Technological University' },
    { 'id': 12589, 'value': 'Delta State University', 'label': 'Delta State University' },
    { 'id': 9342, 'value': 'Delta State University, Abraka', 'label': 'Delta State University, Abraka' },
    { 'id': 4054, 'value': 'Delta University for Science and Technology', 'label': 'Delta University for Science and Technology' },
    { 'id': 11674, 'value': 'Demiroglu Bilim Üniversitesi', 'label': 'Demiroglu Bilim Üniversitesi' },
    { 'id': 4741, 'value': 'Democritus University of Thrace', 'label': 'Democritus University of Thrace' },
    { 'id': 3819, 'value': 'Den Danske Scenekunstskole', 'label': 'Den Danske Scenekunstskole' },
    { 'id': 7127, 'value': 'Den-en Chofu University', 'label': 'Den-en Chofu University' },
    { 'id': 12590, 'value': 'Denison University', 'label': 'Denison University' },
    { 'id': 12591, 'value': 'Denver College of Nursing', 'label': 'Denver College of Nursing' },
    { 'id': 12592, 'value': 'DePaul University', 'label': 'DePaul University' },
    { 'id': 12593, 'value': 'DePauw University', 'label': 'DePauw University' },
    { 'id': 4055, 'value': 'Deraya University', 'label': 'Deraya University' },
    { 'id': 12594, 'value': 'Des Moines University', 'label': 'Des Moines University' },
    { 'id': 12595, 'value': 'DeSales University', 'label': 'DeSales University' },
    { 'id': 5719, 'value': 'Desh Bhagat University', 'label': 'Desh Bhagat University' },
    { 'id': 9518, 'value': 'Design Academy Eindhoven', 'label': 'Design Academy Eindhoven' },
    { 'id': 3475, 'value': 'design akademie berlin', 'label': 'design akademie berlin' },
    { 'id': 3820, 'value': 'Designskolen Kolding', 'label': 'Designskolen Kolding' },
    { 'id': 3821, 'value': 'Det Jyske Musikkonservatorium', 'label': 'Det Jyske Musikkonservatorium' },
    { 'id': 3822, 'value': 'Det Kongelige Danske Kunstakademi, Billedkunstskolerne', 'label': 'Det Kongelige Danske Kunstakademi, Billedkunstskolerne' },
    { 'id': 3823, 'value': 'Det Kongelige Danske Kunstakademis Skoler for Arkitektur, Design og Konservering', 'label': 'Det Kongelige Danske Kunstakademis Skoler for Arkitektur, Design og Konservering' },
    { 'id': 3824, 'value': 'Det Kongelige Danske Musikkonservatorium', 'label': 'Det Kongelige Danske Musikkonservatorium' },
    { 'id': 9563, 'value': 'Det Teologiske Menighetsfakultet', 'label': 'Det Teologiske Menighetsfakultet' },
    { 'id': 8183, 'value': 'Deutsch-Kasachische Universität', 'label': 'Deutsch-Kasachische Universität' },
    { 'id': 3476, 'value': 'Deutsche Hochschule für Gesundheit und Sport', 'label': 'Deutsche Hochschule für Gesundheit und Sport' },
    { 'id': 3477, 'value': 'Deutsche Sporthochschule Köln', 'label': 'Deutsche Sporthochschule Köln' },
    { 'id': 3478, 'value': 'Deutsche Universität für Verwaltungswissenschaften Speyer', 'label': 'Deutsche Universität für Verwaltungswissenschaften Speyer' },
    { 'id': 5720, 'value': 'Dev Sanskriti Vishwavidyalaya', 'label': 'Dev Sanskriti Vishwavidyalaya' },
    { 'id': 5721, 'value': 'Devi Ahilya Vishwavidyalaya', 'label': 'Devi Ahilya Vishwavidyalaya' },
    { 'id': 12596, 'value': 'DeVry University', 'label': 'DeVry University' },
    { 'id': 7898, 'value': 'Dewey International University', 'label': 'Dewey International University' },
    { 'id': 2268, 'value': 'Dezhou University', 'label': 'Dezhou University' },
    { 'id': 10066, 'value': 'DHA Suffa University', 'label': 'DHA Suffa University' },
    { 'id': 1241, 'value': 'Dhaka International University', 'label': 'Dhaka International University' },
    { 'id': 1242, 'value': 'Dhaka University of Engineering and Technology', 'label': 'Dhaka University of Engineering and Technology' },
    { 'id': 11831, 'value': 'Dharma Drum Institute of Liberal Arts', 'label': 'Dharma Drum Institute of Liberal Arts' },
    { 'id': 12597, 'value': 'Dharma Realm Buddhist University', 'label': 'Dharma Realm Buddhist University' },
    { 'id': 5722, 'value': 'Dharmsinh Desai University', 'label': 'Dharmsinh Desai University' },
    { 'id': 5723, 'value': 'Dhirubhai Ambani Institute of Information and Communication Technology', 'label': 'Dhirubhai Ambani Institute of Information and Communication Technology' },
    { 'id': 9621, 'value': 'Dhofar University', 'label': 'Dhofar University' },
    { 'id': 11395, 'value': 'Dhonburi Rajabhat University', 'label': 'Dhonburi Rajabhat University' },
    { 'id': 11396, 'value': 'Dhurakij Pundit University', 'label': 'Dhurakij Pundit University' },
    { 'id': 4210, 'value': 'Diakonia-ammattikorkeakoulu', 'label': 'Diakonia-ammattikorkeakoulu' },
    { 'id': 5724, 'value': 'Diamond Harbour Women\'s University', 'label': 'Diamond Harbour Women\'s University' },
    { 'id': 5725, 'value': 'Dibrugarh University', 'label': 'Dibrugarh University' },
    { 'id': 12598, 'value': 'Dickinson College', 'label': 'Dickinson College' },
    { 'id': 12599, 'value': 'Dickinson State University', 'label': 'Dickinson State University' },
    { 'id': 11675, 'value': 'Dicle Üniversitesi', 'label': 'Dicle Üniversitesi' },
    { 'id': 7128, 'value': 'Digital Hollywood University', 'label': 'Digital Hollywood University' },
    { 'id': 11526, 'value': 'Dili Institute of Technology', 'label': 'Dili Institute of Technology' },
    { 'id': 4186, 'value': 'Dilla University', 'label': 'Dilla University' },
    { 'id': 12600, 'value': 'Dillard University', 'label': 'Dillard University' },
    { 'id': 7851, 'value': 'Diplomatic Academy of the Ministry of Foreign Affairs of the Kyrgyz Republic', 'label': 'Diplomatic Academy of the Ministry of Foreign Affairs of the Kyrgyz Republic' },
    { 'id': 1005, 'value': 'Diplomatische Akademie Wien', 'label': 'Diplomatische Akademie Wien' },
    { 'id': 4187, 'value': 'Dire Dawa University', 'label': 'Dire Dawa University' },
    { 'id': 5726, 'value': 'DIT University', 'label': 'DIT University' },
    { 'id': 9809, 'value': 'Divine Word University', 'label': 'Divine Word University' },
    { 'id': 12601, 'value': 'Dixie State University', 'label': 'Dixie State University' },
    { 'id': 14258, 'value': 'DMI-St. Eugene University', 'label': 'DMI-St. Eugene University' },
    { 'id': 11992, 'value': 'Dnepropetrovsk State University of Internal Affairs', 'label': 'Dnepropetrovsk State University of Internal Affairs' },
    { 'id': 452, 'value': 'Dnipro Medical Institute', 'label': 'Dnipro Medical Institute' },
    { 'id': 11993, 'value': 'Dnipropetrovsk Medical Academy', 'label': 'Dnipropetrovsk Medical Academy' },
    { 'id': 11994, 'value': 'Dnipropetrovsk National University', 'label': 'Dnipropetrovsk National University' },
    { 'id': 11995, 'value': 'Dnipropetrovsk National University of Railway Transport', 'label': 'Dnipropetrovsk National University of Railway Transport' },
    { 'id': 11996, 'value': 'Dnipropetrovsk State Agrarian and Economic University', 'label': 'Dnipropetrovsk State Agrarian and Economic University' },
    { 'id': 11997, 'value': 'Dniprovsk State Technical University', 'label': 'Dniprovsk State Technical University' },
    { 'id': 12602, 'value': 'Doane University', 'label': 'Doane University' },
    { 'id': 11158, 'value': 'DOBA Fakultet', 'label': 'DOBA Fakultet' },
    { 'id': 3356, 'value': 'Dogu Akdeniz Üniversitesi', 'label': 'Dogu Akdeniz Üniversitesi' },
    { 'id': 11676, 'value': 'Dogus Üniversitesi', 'label': 'Dogus Üniversitesi' },
    { 'id': 10507, 'value': 'Doha Institute for Graduate Studies', 'label': 'Doha Institute for Graduate Studies' },
    { 'id': 7129, 'value': 'Doho University', 'label': 'Doho University' },
    { 'id': 7130, 'value': 'Dokkyo Medical University', 'label': 'Dokkyo Medical University' },
    { 'id': 7131, 'value': 'Dokkyo University', 'label': 'Dokkyo University' },
    { 'id': 11677, 'value': 'Dokuz Eylül Üniversitesi', 'label': 'Dokuz Eylül Üniversitesi' },
    { 'id': 12603, 'value': 'Dominican College', 'label': 'Dominican College' },
    { 'id': 12604, 'value': 'Dominican School of Philosophy & Theology', 'label': 'Dominican School of Philosophy & Theology' },
    { 'id': 12605, 'value': 'Dominican University', 'label': 'Dominican University' },
    { 'id': 12606, 'value': 'Dominican University of California', 'label': 'Dominican University of California' },
    { 'id': 9343, 'value': 'Dominican University, Ibadan', 'label': 'Dominican University, Ibadan' },
    { 'id': 4680, 'value': 'Dominion University College', 'label': 'Dominion University College' },
    { 'id': 14342, 'value': 'Domus Academy', 'label': 'Domus Academy' },
    { 'id': 9876, 'value': 'Don Bosco Technical College', 'label': 'Don Bosco Technical College' },
    { 'id': 9877, 'value': 'Don Honorio Ventura Technological State University', 'label': 'Don Honorio Ventura Technological State University' },
    { 'id': 9878, 'value': 'Don Mariano Marcos Memorial State University', 'label': 'Don Mariano Marcos Memorial State University' },
    { 'id': 10664, 'value': 'Don State Technical University', 'label': 'Don State Technical University' },
    { 'id': 10665, 'value': 'Don State University of Agriculture', 'label': 'Don State University of Agriculture' },
    { 'id': 1006, 'value': 'Donau-Universität Krems', 'label': 'Donau-Universität Krems' },
    { 'id': 11998, 'value': 'Donbas National Academy of Civil Engineering and Architecture', 'label': 'Donbas National Academy of Civil Engineering and Architecture' },
    { 'id': 11999, 'value': 'Donbass State Pedagogical University', 'label': 'Donbass State Pedagogical University' },
    { 'id': 12000, 'value': 'Donbass State Technical University', 'label': 'Donbass State Technical University' },
    { 'id': 12001, 'value': 'Donetsk National Medical University', 'label': 'Donetsk National Medical University' },
    { 'id': 12002, 'value': 'Donetsk National Technical University', 'label': 'Donetsk National Technical University' },
    { 'id': 12003, 'value': 'Donetsk National University of Economics and Trade', 'label': 'Donetsk National University of Economics and Trade' },
    { 'id': 12004, 'value': 'Donetsk State University of Management', 'label': 'Donetsk State University of Management' },
    { 'id': 14113, 'value': 'Dong Do International University', 'label': 'Dong Do International University' },
    { 'id': 14114, 'value': 'Dong Nai University of Technology', 'label': 'Dong Nai University of Technology' },
    { 'id': 8002, 'value': 'Dong-A University', 'label': 'Dong-A University' },
    { 'id': 8004, 'value': 'Dong-Eui University', 'label': 'Dong-Eui University' },
    { 'id': 2269, 'value': 'Dongbei University of Finance and Economics', 'label': 'Dongbei University of Finance and Economics' },
    { 'id': 8003, 'value': 'Dongduk Women\'s University', 'label': 'Dongduk Women\'s University' },
    { 'id': 2270, 'value': 'Dongguan University of Technology', 'label': 'Dongguan University of Technology' },
    { 'id': 8005, 'value': 'Dongguk University', 'label': 'Dongguk University' },
    { 'id': 2271, 'value': 'Donghua University', 'label': 'Donghua University' },
    { 'id': 8006, 'value': 'Dongseo University', 'label': 'Dongseo University' },
    { 'id': 8007, 'value': 'Dongshin University', 'label': 'Dongshin University' },
    { 'id': 8008, 'value': 'Dongyang University', 'label': 'Dongyang University' },
    { 'id': 12607, 'value': 'Donnelly College', 'label': 'Donnelly College' },
    { 'id': 5727, 'value': 'Doon University', 'label': 'Doon University' },
    { 'id': 12608, 'value': 'Dordt University', 'label': 'Dordt University' },
    { 'id': 8594, 'value': 'Dornod University', 'label': 'Dornod University' },
    { 'id': 7132, 'value': 'Doshisha University', 'label': 'Doshisha University' },
    { 'id': 7133, 'value': 'Doshisha Women\'s College of Liberal Arts', 'label': 'Doshisha Women\'s College of Liberal Arts' },
    { 'id': 10666, 'value': 'Dostoevsky Omsk State University', 'label': 'Dostoevsky Omsk State University' },
    { 'id': 1140, 'value': 'Dövlet Idareçilik Akademiyasi', 'label': 'Dövlet Idareçilik Akademiyasi' },
    { 'id': 10067, 'value': 'DOW University of Health Sciences', 'label': 'DOW University of Health Sciences' },
    { 'id': 3479, 'value': 'DPFA Hochschule Sachsen', 'label': 'DPFA Hochschule Sachsen' },
    { 'id': 5728, 'value': 'Dr K.N. Modi University', 'label': 'Dr K.N. Modi University' },
    { 'id': 5729, 'value': 'Dr. A.P.J Abdul Kalam University', 'label': 'Dr. A.P.J Abdul Kalam University' },
    { 'id': 5730, 'value': 'Dr. A.P.J. Abdul Kalam Technical University', 'label': 'Dr. A.P.J. Abdul Kalam Technical University' },
    { 'id': 5731, 'value': 'Dr. B R Ambedkar National Institute of Technology Jalandhar', 'label': 'Dr. B R Ambedkar National Institute of Technology Jalandhar' },
    { 'id': 5732, 'value': 'Dr. B.R. Ambedkar University', 'label': 'Dr. B.R. Ambedkar University' },
    { 'id': 5733, 'value': 'Dr. B.R. Ambedkar University of Social Sciences', 'label': 'Dr. B.R. Ambedkar University of Social Sciences' },
    { 'id': 5734, 'value': 'Dr. Babasaheb Ambedkar Marathwada University', 'label': 'Dr. Babasaheb Ambedkar Marathwada University' },
    { 'id': 5735, 'value': 'Dr. Babasaheb Ambedkar Technological University', 'label': 'Dr. Babasaheb Ambedkar Technological University' },
    { 'id': 5736, 'value': 'Dr. Balasaheb Sawant Konkan Krishi Vidyapeeth', 'label': 'Dr. Balasaheb Sawant Konkan Krishi Vidyapeeth' },
    { 'id': 5737, 'value': 'Dr. Bhimrao Ambedkar University', 'label': 'Dr. Bhimrao Ambedkar University' },
    { 'id': 5738, 'value': 'Dr. C.V. Raman University', 'label': 'Dr. C.V. Raman University' },
    { 'id': 5739, 'value': 'Dr. D.Y. Patil Vidyapeeth', 'label': 'Dr. D.Y. Patil Vidyapeeth' },
    { 'id': 5740, 'value': 'Dr. Hari Singh Gour University', 'label': 'Dr. Hari Singh Gour University' },
    { 'id': 5741, 'value': 'Dr. M.G.R. Educational and Research Institute', 'label': 'Dr. M.G.R. Educational and Research Institute' },
    { 'id': 5742, 'value': 'Dr. N.T.R. University of Health Sciences', 'label': 'Dr. N.T.R. University of Health Sciences' },
    { 'id': 5743, 'value': 'Dr. Panjabrao Deshmukh Krishi Vidyapeeth', 'label': 'Dr. Panjabrao Deshmukh Krishi Vidyapeeth' },
    { 'id': 5744, 'value': 'Dr. Rajendra Prasad Central Agricultural University', 'label': 'Dr. Rajendra Prasad Central Agricultural University' },
    { 'id': 5745, 'value': 'Dr. Ram Manohar Lohia Avadh University', 'label': 'Dr. Ram Manohar Lohia Avadh University' },
    { 'id': 5746, 'value': 'Dr. Ram Manohar Lohiya National Law University', 'label': 'Dr. Ram Manohar Lohiya National Law University' },
    { 'id': 5747, 'value': 'Dr. Sarvepalli Radhakrishnan Rajasthan Ayurved University', 'label': 'Dr. Sarvepalli Radhakrishnan Rajasthan Ayurved University' },
    { 'id': 5748, 'value': 'Dr. Shakuntala Misra Rehabilitation University', 'label': 'Dr. Shakuntala Misra Rehabilitation University' },
    { 'id': 5749, 'value': 'Dr. Vishwanath Karad MIT World Peace University', 'label': 'Dr. Vishwanath Karad MIT World Peace University' },
    { 'id': 5750, 'value': 'Dr. Y.S. Parmar University of Horticulture and Forestry', 'label': 'Dr. Y.S. Parmar University of Horticulture and Forestry' },
    { 'id': 5751, 'value': 'Dr.Y.S.R. Horticultural University', 'label': 'Dr.Y.S.R. Horticultural University' },
    { 'id': 12609, 'value': 'Drake University', 'label': 'Drake University' },
    { 'id': 5752, 'value': 'Dravidian University', 'label': 'Dravidian University' },
    { 'id': 12610, 'value': 'Drew University', 'label': 'Drew University' },
    { 'id': 12611, 'value': 'Drexel University', 'label': 'Drexel University' },
    { 'id': 9519, 'value': 'Driestar Hogeschool', 'label': 'Driestar Hogeschool' },
    { 'id': 12005, 'value': 'Drohobych State Pedagogical University', 'label': 'Drohobych State Pedagogical University' },
    { 'id': 9564, 'value': 'Dronning Mauds Minne Høgskole for barnehagelærerutdanning', 'label': 'Dronning Mauds Minne Høgskole for barnehagelærerutdanning' },
    { 'id': 12612, 'value': 'Drury University', 'label': 'Drury University' },
    { 'id': 10597, 'value': 'Državni univerzitet u Novom Pazaru', 'label': 'Državni univerzitet u Novom Pazaru' },
    { 'id': 3480, 'value': 'Duale Hochschule Gera-Eisenach', 'label': 'Duale Hochschule Gera-Eisenach' },
    { 'id': 633, 'value': 'Dubai Medical College', 'label': 'Dubai Medical College' },
    { 'id': 634, 'value': 'Dubai Pharmacy College', 'label': 'Dubai Pharmacy College' },
    { 'id': 14396, 'value': 'Dublin Business School', 'label': 'Dublin Business School' },
    { 'id': 5507, 'value': 'Dublin City University', 'label': 'Dublin City University' },
    { 'id': 10667, 'value': 'Dubna State University', 'label': 'Dubna State University' },
    { 'id': 6449, 'value': 'Duhok Polytechnic University', 'label': 'Duhok Polytechnic University' },
    { 'id': 475, 'value': 'Duke Kunshan University', 'label': 'Duke Kunshan University' },
    { 'id': 516, 'value': 'Duke University', 'label': 'Duke University' },
    { 'id': 8009, 'value': 'Duksung Women\'s University', 'label': 'Duksung Women\'s University' },
    { 'id': 5508, 'value': 'Dún Laoghaire Institute of Art, Design and Technology', 'label': 'Dún Laoghaire Institute of Art, Design and Technology' },
    { 'id': 431, 'value': 'Dunarea de Jos', 'label': 'Dunarea de Jos' },
    { 'id': 4896, 'value': 'Dunaújvárosi Egyetem', 'label': 'Dunaújvárosi Egyetem' },
    { 'id': 5509, 'value': 'Dundalk Institute of Technology', 'label': 'Dundalk Institute of Technology' },
    { 'id': 12613, 'value': 'Dunwoody College of Technology', 'label': 'Dunwoody College of Technology' },
    { 'id': 702, 'value': 'Dunya University of Afghanistan', 'label': 'Dunya University of Afghanistan' },
    { 'id': 12614, 'value': 'Duquesne University', 'label': 'Duquesne University' },
    { 'id': 14223, 'value': 'Durban University of Technology', 'label': 'Durban University of Technology' },
    { 'id': 455, 'value': 'Durham University', 'label': 'Durham University' },
    { 'id': 14115, 'value': 'Duy Tan University', 'label': 'Duy Tan University' },
    { 'id': 11678, 'value': 'Düzce Üniversitesi', 'label': 'Düzce Üniversitesi' },
    { 'id': 14418, 'value': 'eada business school', 'label': 'eada business school' },
    { 'id': 12616, 'value': 'Earlham College', 'label': 'Earlham College' },
    { 'id': 1481, 'value': 'East Africa Star University', 'label': 'East Africa Star University' },
    { 'id': 10995, 'value': 'East African University Rwanda', 'label': 'East African University Rwanda' },
    { 'id': 7899, 'value': 'East Asia Management University', 'label': 'East Asia Management University' },
    { 'id': 12617, 'value': 'East Carolina University', 'label': 'East Carolina University' },
    { 'id': 12618, 'value': 'East Central University', 'label': 'East Central University' },
    { 'id': 2272, 'value': 'East China Jiaotong University', 'label': 'East China Jiaotong University' },
    { 'id': 2273, 'value': 'East China Normal University', 'label': 'East China Normal University' },
    { 'id': 2274, 'value': 'East China University of Political Science and Law', 'label': 'East China University of Political Science and Law' },
    { 'id': 2275, 'value': 'East China University of Science and Technology', 'label': 'East China University of Science and Technology' },
    { 'id': 2276, 'value': 'East China University of Technology', 'label': 'East China University of Technology' },
    { 'id': 1243, 'value': 'East Delta University', 'label': 'East Delta University' },
    { 'id': 12006, 'value': 'East European University', 'label': 'East European University' },
    { 'id': 4628, 'value': 'East European University, Georgia', 'label': 'East European University, Georgia' },
    { 'id': 12619, 'value': 'East Georgia State College', 'label': 'East Georgia State College' },
    { 'id': 10668, 'value': 'East Siberia State University of Technology and Management', 'label': 'East Siberia State University of Technology and Management' },
    { 'id': 12620, 'value': 'East Stroudsburg University', 'label': 'East Stroudsburg University' },
    { 'id': 12621, 'value': 'East Tennessee State University', 'label': 'East Tennessee State University' },
    { 'id': 12622, 'value': 'East Texas Baptist University', 'label': 'East Texas Baptist University' },
    { 'id': 11527, 'value': 'East Timor Coffee Institute', 'label': 'East Timor Coffee Institute' },
    { 'id': 11528, 'value': 'East Timor Institute of Business', 'label': 'East Timor Institute of Business' },
    { 'id': 12007, 'value': 'East Ukrainian National University', 'label': 'East Ukrainian National University' },
    { 'id': 2277, 'value': 'East University of Heilongjiang', 'label': 'East University of Heilongjiang' },
    { 'id': 1244, 'value': 'East West University', 'label': 'East West University' },
    { 'id': 8504, 'value': 'East Yangon University', 'label': 'East Yangon University' },
    { 'id': 8184, 'value': 'East-Kazakhstan State Technical University', 'label': 'East-Kazakhstan State Technical University' },
    { 'id': 8185, 'value': 'East-Kazakhstan State University', 'label': 'East-Kazakhstan State University' },
    { 'id': 12634, 'value': 'East-West University', 'label': 'East-West University' },
    { 'id': 11397, 'value': 'Eastern Asia University', 'label': 'Eastern Asia University' },
    { 'id': 12623, 'value': 'Eastern Connecticut State University', 'label': 'Eastern Connecticut State University' },
    { 'id': 12624, 'value': 'Eastern Illinois University', 'label': 'Eastern Illinois University' },
    { 'id': 12625, 'value': 'Eastern Kentucky University', 'label': 'Eastern Kentucky University' },
    { 'id': 2278, 'value': 'Eastern Liaoning University', 'label': 'Eastern Liaoning University' },
    { 'id': 12626, 'value': 'Eastern Mennonite University', 'label': 'Eastern Mennonite University' },
    { 'id': 12627, 'value': 'Eastern Michigan University', 'label': 'Eastern Michigan University' },
    { 'id': 12628, 'value': 'Eastern Nazarene College', 'label': 'Eastern Nazarene College' },
    { 'id': 12629, 'value': 'Eastern New Mexico University', 'label': 'Eastern New Mexico University' },
    { 'id': 12630, 'value': 'Eastern Oregon University', 'label': 'Eastern Oregon University' },
    { 'id': 9344, 'value': 'Eastern Palm University', 'label': 'Eastern Palm University' },
    { 'id': 11218, 'value': 'Eastern Polytechnic', 'label': 'Eastern Polytechnic' },
    { 'id': 9879, 'value': 'Eastern Samar State University', 'label': 'Eastern Samar State University' },
    { 'id': 12631, 'value': 'Eastern University', 'label': 'Eastern University' },
    { 'id': 7852, 'value': 'Eastern University n.a. Mahmud Kashgari-Barskani', 'label': 'Eastern University n.a. Mahmud Kashgari-Barskani' },
    { 'id': 8312, 'value': 'Eastern University of Sri Lanka', 'label': 'Eastern University of Sri Lanka' },
    { 'id': 14116, 'value': 'Eastern University of Technology', 'label': 'Eastern University of Technology' },
    { 'id': 1245, 'value': 'Eastern University, Bangladesh', 'label': 'Eastern University, Bangladesh' },
    { 'id': 12632, 'value': 'Eastern Virginia Medical School', 'label': 'Eastern Virginia Medical School' },
    { 'id': 9880, 'value': 'Eastern Visayas State University', 'label': 'Eastern Visayas State University' },
    { 'id': 12633, 'value': 'Eastern Washington University', 'label': 'Eastern Washington University' },
    { 'id': 3481, 'value': 'EBC Hochschule Hamburg', 'label': 'EBC Hochschule Hamburg' },
    { 'id': 3482, 'value': 'Eberhard Karls Universität Tübingen', 'label': 'Eberhard Karls Universität Tübingen' },
    { 'id': 11343, 'value': 'Ebla Private University', 'label': 'Ebla Private University' },
    { 'id': 9345, 'value': 'Ebonyi State University', 'label': 'Ebonyi State University' },
    { 'id': 4023, 'value': 'EBS Rahvusvaheline ülikool', 'label': 'EBS Rahvusvaheline ülikool' },
    { 'id': 3483, 'value': 'EBS Universität für Wirtschaft und Recht', 'label': 'EBS Universität für Wirtschaft und Recht' },
    { 'id': 3484, 'value': 'EBZ Business School', 'label': 'EBZ Business School' },
    { 'id': 310, 'value': 'ECAL Ecole cantonale d’art de Lausanne', 'label': 'ECAL Ecole cantonale d’art de Lausanne' },
    { 'id': 4258, 'value': 'ECAM Lyon', 'label': 'ECAM Lyon' },
    { 'id': 4259, 'value': 'ECAM Rennes Louis de Broglie', 'label': 'ECAM Rennes Louis de Broglie' },
    { 'id': 4260, 'value': 'ECAM Strasbourg-Europe', 'label': 'ECAM Strasbourg-Europe' },
    { 'id': 4261, 'value': 'ECAM-EPMI', 'label': 'ECAM-EPMI' },
    { 'id': 12635, 'value': 'Ecclesia College', 'label': 'Ecclesia College' },
    { 'id': 4262, 'value': 'ECE Paris', 'label': 'ECE Paris' },
    { 'id': 12636, 'value': 'Eckerd College', 'label': 'Eckerd College' },
    { 'id': 11950, 'value': 'Eckernforde Tanga University', 'label': 'Eckernforde Tanga University' },
    { 'id': 8409, 'value': 'Ecole Centrale Casablanca', 'label': 'Ecole Centrale Casablanca' },
    { 'id': 4263, 'value': 'École Centrale de Lyon', 'label': 'École Centrale de Lyon' },
    { 'id': 4264, 'value': 'École Centrale de Marseille', 'label': 'École Centrale de Marseille' },
    { 'id': 4265, 'value': 'École Centrale de Nantes', 'label': 'École Centrale de Nantes' },
    { 'id': 11226, 'value': 'École Centrale des Logiciels Libres et des Télécommunications', 'label': 'École Centrale des Logiciels Libres et des Télécommunications' },
    { 'id': 11552, 'value': 'École Centrale Supérieure Privée de Technologie', 'label': 'École Centrale Supérieure Privée de Technologie' },
    { 'id': 4279, 'value': 'École d\'ingénieur généraliste en informatique et technologies du numérique', 'label': 'École d\'ingénieur généraliste en informatique et technologies du numérique' },
    { 'id': 4280, 'value': 'École d\'Ingénieurs de Purpan', 'label': 'École d\'Ingénieurs de Purpan' },
    { 'id': 4281, 'value': 'École d\'Ingénieurs Généralistes La Rochelle', 'label': 'École d\'Ingénieurs Généralistes La Rochelle' },
    { 'id': 4282, 'value': 'École d\'ingénieurs SIGMA Clermont', 'label': 'École d\'ingénieurs SIGMA Clermont' },
    { 'id': 4266, 'value': 'École de Biologie Industrielle', 'label': 'École de Biologie Industrielle' },
    { 'id': 4267, 'value': 'École de Commerce de Valence', 'label': 'École de Commerce de Valence' },
    { 'id': 4268, 'value': 'École de Gestion et de Commerce d\'Occitanie', 'label': 'École de Gestion et de Commerce d\'Occitanie' },
    { 'id': 4269, 'value': 'École de Management de Normandie', 'label': 'École de Management de Normandie' },
    { 'id': 4476, 'value': 'École de Management du Gabon', 'label': 'École de Management du Gabon' },
    { 'id': 4270, 'value': 'École de Management Léonard de Vinci', 'label': 'École de Management Léonard de Vinci' },
    { 'id': 1348, 'value': 'École de Recherche Graphique', 'label': 'École de Recherche Graphique' },
    { 'id': 1867, 'value': 'École de Technologie Supérieure', 'label': 'École de Technologie Supérieure' },
    { 'id': 4271, 'value': 'École des Dirigeants et Créateurs d\'entreprise', 'label': 'École des Dirigeants et Créateurs d\'entreprise' },
    { 'id': 3884, 'value': 'École des Hautes Etudes Commerciales', 'label': 'École des Hautes Etudes Commerciales' },
    { 'id': 4272, 'value': 'École des Hautes Études Commerciales de Paris', 'label': 'École des Hautes Études Commerciales de Paris' },
    { 'id': 4273, 'value': 'École des Hautes Études Commerciales du Nord', 'label': 'École des Hautes Études Commerciales du Nord' },
    { 'id': 4274, 'value': 'École des Hautes Études en Santé Publique', 'label': 'École des Hautes Études en Santé Publique' },
    { 'id': 4275, 'value': 'École des Hautes Études en Sciences Sociales', 'label': 'École des Hautes Études en Sciences Sociales' },
    { 'id': 4276, 'value': 'École des Ingénieurs de la Ville de Paris', 'label': 'École des Ingénieurs de la Ville de Paris' },
    { 'id': 4277, 'value': 'École des Métiers de l\'Environnement', 'label': 'École des Métiers de l\'Environnement' },
    { 'id': 4477, 'value': 'École des Mines et de la Métallurgie de Moanda', 'label': 'École des Mines et de la Métallurgie de Moanda' },
    { 'id': 4278, 'value': 'École des Ponts ParisTech', 'label': 'École des Ponts ParisTech' },
    { 'id': 4283, 'value': 'École Européenne de Management', 'label': 'École Européenne de Management' },
    { 'id': 4284, 'value': 'École Internationale des Sciences du Traitement de l\'Information', 'label': 'École Internationale des Sciences du Traitement de l\'Information' },
    { 'id': 11553, 'value': 'École Internationale Supérieure Privée de Droit et des Affaires', 'label': 'École Internationale Supérieure Privée de Droit et des Affaires' },
    { 'id': 4285, 'value': 'École Nationale d\'Administration', 'label': 'École Nationale d\'Administration' },
    { 'id': 2018, 'value': 'École Nationale d\'Administration de Côte d\'Ivoire', 'label': 'École Nationale d\'Administration de Côte d\'Ivoire' },
    { 'id': 3885, 'value': 'École Nationale d\'Administration Moulay Ahmed Medeghri', 'label': 'École Nationale d\'Administration Moulay Ahmed Medeghri' },
    { 'id': 1868, 'value': 'École Nationale d\'Administration Publique', 'label': 'École Nationale d\'Administration Publique' },
    { 'id': 1482, 'value': 'École Nationale d\'Administration, Burundi', 'label': 'École Nationale d\'Administration, Burundi' },
    { 'id': 8662, 'value': 'École Nationale d\'Administration, de Journalisme et de Magistrature', 'label': 'École Nationale d\'Administration, de Journalisme et de Magistrature' },
    { 'id': 11363, 'value': 'École Nationale d\'Administration, Tchad', 'label': 'École Nationale d\'Administration, Tchad' },
    { 'id': 8410, 'value': 'École Nationale d\'Agriculture de Meknes', 'label': 'École Nationale d\'Agriculture de Meknes' },
    { 'id': 8411, 'value': 'École Nationale d\'Architecture', 'label': 'École Nationale d\'Architecture' },
    { 'id': 8490, 'value': 'École Nationale d\'Ingénieurs Abderhame Baba Touré', 'label': 'École Nationale d\'Ingénieurs Abderhame Baba Touré' },
    { 'id': 4291, 'value': 'École Nationale d\'Ingénieurs de Brest', 'label': 'École Nationale d\'Ingénieurs de Brest' },
    { 'id': 4292, 'value': 'École Nationale d\'Ingénieurs de Saint-Étienne', 'label': 'École Nationale d\'Ingénieurs de Saint-Étienne' },
    { 'id': 4293, 'value': 'École Nationale d\'Ingénieurs de Tarbes', 'label': 'École Nationale d\'Ingénieurs de Tarbes' },
    { 'id': 4288, 'value': 'École Nationale de l\'Aviation Civile', 'label': 'École Nationale de l\'Aviation Civile' },
    { 'id': 4286, 'value': 'École Nationale de la Météorologie', 'label': 'École Nationale de la Météorologie' },
    { 'id': 4287, 'value': 'École Nationale de la Statistique et de l\'Analyse de l\'information', 'label': 'École Nationale de la Statistique et de l\'Analyse de l\'information' },
    { 'id': 4478, 'value': 'École Nationale des Eaux et Forêts', 'label': 'École Nationale des Eaux et Forêts' },
    { 'id': 4289, 'value': 'École Nationale des Sciences Géographiques', 'label': 'École Nationale des Sciences Géographiques' },
    { 'id': 4290, 'value': 'École Nationale des Travaux Publics de l\'État', 'label': 'École Nationale des Travaux Publics de l\'État' },
    { 'id': 3886, 'value': 'École Nationale Polytechnique', 'label': 'École Nationale Polytechnique' },
    { 'id': 3888, 'value': 'École Nationale Polytechnique d\'Oran', 'label': 'École Nationale Polytechnique d\'Oran' },
    { 'id': 3887, 'value': 'École Nationale Polytechnique de Constantine', 'label': 'École Nationale Polytechnique de Constantine' },
    { 'id': 3889, 'value': 'École Nationale Supérieure Agronomique', 'label': 'École Nationale Supérieure Agronomique' },
    { 'id': 4294, 'value': 'École Nationale Supérieure Agronomique de Toulouse', 'label': 'École Nationale Supérieure Agronomique de Toulouse' },
    { 'id': 4295, 'value': 'École Nationale Supérieure d\'Architecture de Nancy', 'label': 'École Nationale Supérieure d\'Architecture de Nancy' },
    { 'id': 3898, 'value': 'École Nationale Supérieure d\'Hydraulique', 'label': 'École Nationale Supérieure d\'Hydraulique' },
    { 'id': 3899, 'value': 'École Nationale Supérieure d\'Informatique', 'label': 'École Nationale Supérieure d\'Informatique' },
    { 'id': 4308, 'value': 'École Nationale Supérieure d\'Informatique pour l\'Industrie et l\'Entreprise', 'label': 'École Nationale Supérieure d\'Informatique pour l\'Industrie et l\'Entreprise' },
    { 'id': 4309, 'value': 'École Nationale Supérieure d\'Ingénieurs de Caen', 'label': 'École Nationale Supérieure d\'Ingénieurs de Caen' },
    { 'id': 3890, 'value': 'École Nationale Supérieure de Biotechnologie', 'label': 'École Nationale Supérieure de Biotechnologie' },
    { 'id': 4296, 'value': 'École Nationale Supérieure de Chimie de Lille', 'label': 'École Nationale Supérieure de Chimie de Lille' },
    { 'id': 4297, 'value': 'École Nationale Supérieure de Chimie de Montpellier', 'label': 'École Nationale Supérieure de Chimie de Montpellier' },
    { 'id': 4298, 'value': 'École Nationale Supérieure de Chimie de Paris', 'label': 'École Nationale Supérieure de Chimie de Paris' },
    { 'id': 4299, 'value': 'École Nationale Supérieure de Chimie de Rennes', 'label': 'École Nationale Supérieure de Chimie de Rennes' },
    { 'id': 3891, 'value': 'École Nationale Supérieure de Journalisme et des Sciences de l\'Information', 'label': 'École Nationale Supérieure de Journalisme et des Sciences de l\'Information' },
    { 'id': 8412, 'value': 'École Nationale Supérieure de l\'Administration', 'label': 'École Nationale Supérieure de l\'Administration' },
    { 'id': 4300, 'value': 'École Nationale Supérieure de l\'Électronique et de ses Applications', 'label': 'École Nationale Supérieure de l\'Électronique et de ses Applications' },
    { 'id': 3892, 'value': 'École Nationale Supérieure de Management', 'label': 'École Nationale Supérieure de Management' },
    { 'id': 4301, 'value': 'École Nationale Supérieure de Mécanique et d\'Aérotechnique', 'label': 'École Nationale Supérieure de Mécanique et d\'Aérotechnique' },
    { 'id': 4302, 'value': 'École Nationale Supérieure de Mécanique et des Microtechniques', 'label': 'École Nationale Supérieure de Mécanique et des Microtechniques' },
    { 'id': 3893, 'value': 'École Nationale Supérieure de Sciences Politiques', 'label': 'École Nationale Supérieure de Sciences Politiques' },
    { 'id': 2019, 'value': 'École Nationale Supérieure de Statistique et d\'Economie Appliquée', 'label': 'École Nationale Supérieure de Statistique et d\'Economie Appliquée' },
    { 'id': 4303, 'value': 'École Nationale Supérieure de Techniques Avancées Bretagne', 'label': 'École Nationale Supérieure de Techniques Avancées Bretagne' },
    { 'id': 3894, 'value': 'École Nationale Supérieure de Technologie', 'label': 'École Nationale Supérieure de Technologie' },
    { 'id': 4304, 'value': 'École Nationale Supérieure des Arts et Industries Textiles', 'label': 'École Nationale Supérieure des Arts et Industries Textiles' },
    { 'id': 1349, 'value': 'École Nationale Supérieure des Arts Visuels de La Cambre', 'label': 'École Nationale Supérieure des Arts Visuels de La Cambre' },
    { 'id': 4305, 'value': 'École Nationale Supérieure des Mines de St-Etienne', 'label': 'École Nationale Supérieure des Mines de St-Etienne' },
    { 'id': 3895, 'value': 'École Nationale Supérieure des Mines et de la Métallurgie', 'label': 'École Nationale Supérieure des Mines et de la Métallurgie' },
    { 'id': 4306, 'value': 'École Nationale Supérieure des Sciences Agronomiques', 'label': 'École Nationale Supérieure des Sciences Agronomiques' },
    { 'id': 491, 'value': 'Ecole Nationale Supérieure des Sciences Agronomiques de Bordeaux-Aquitaine', 'label': 'Ecole Nationale Supérieure des Sciences Agronomiques de Bordeaux-Aquitaine' },
    { 'id': 4307, 'value': 'École Nationale Supérieure des Sciences de l\'Information et des Bibliothèques', 'label': 'École Nationale Supérieure des Sciences de l\'Information et des Bibliothèques' },
    { 'id': 3896, 'value': 'École Nationale Supérieure des Sciences de la Mer et de l\'Aménagement du Littoral', 'label': 'École Nationale Supérieure des Sciences de la Mer et de l\'Aménagement du Littoral' },
    { 'id': 3897, 'value': 'École Nationale Supérieure des Travaux Publics', 'label': 'École Nationale Supérieure des Travaux Publics' },
    { 'id': 3900, 'value': 'École Nationale Supérieure en Statistique et en Economie Appliquée', 'label': 'École Nationale Supérieure en Statistique et en Economie Appliquée' },
    { 'id': 4310, 'value': 'École Nationale supérieure Mines-Télécom Lille-Douai', 'label': 'École Nationale supérieure Mines-Télécom Lille-Douai' },
    { 'id': 3901, 'value': 'École Nationale Supérieure Vétérinaire d\'Alger', 'label': 'École Nationale Supérieure Vétérinaire d\'Alger' },
    { 'id': 4311, 'value': 'École Nationale Vétérinaire d\'Alfort', 'label': 'École Nationale Vétérinaire d\'Alfort' },
    { 'id': 4312, 'value': 'École Nationale Vétérinaire de Toulouse', 'label': 'École Nationale Vétérinaire de Toulouse' },
    { 'id': 4313, 'value': 'École Nationale Vétérinaire, Agroalimentaire et de l\'alimentation de Nantes-Atlantique', 'label': 'École Nationale Vétérinaire, Agroalimentaire et de l\'alimentation de Nantes-Atlantique' },
    { 'id': 8491, 'value': 'École Normale d\'Enseignement Technique et Professionnel', 'label': 'École Normale d\'Enseignement Technique et Professionnel' },
    { 'id': 4314, 'value': 'École Normale Supérieure', 'label': 'École Normale Supérieure' },
    { 'id': 2020, 'value': 'École Normale Supérieure d\'Abidjan', 'label': 'École Normale Supérieure d\'Abidjan' },
    { 'id': 8492, 'value': 'École Normale Supérieure de Bamako', 'label': 'École Normale Supérieure de Bamako' },
    { 'id': 1483, 'value': 'École Normale Supérieure de Bujumbura', 'label': 'École Normale Supérieure de Bujumbura' },
    { 'id': 4479, 'value': 'École Normale Supérieure de l\'Enseignement Technique', 'label': 'École Normale Supérieure de l\'Enseignement Technique' },
    { 'id': 4480, 'value': 'École Normale Supérieure de Libreville', 'label': 'École Normale Supérieure de Libreville' },
    { 'id': 4315, 'value': 'École Normale Supérieure de Lyon', 'label': 'École Normale Supérieure de Lyon' },
    { 'id': 4316, 'value': 'École Normale Supérieure de Rennes', 'label': 'École Normale Supérieure de Rennes' },
    { 'id': 4317, 'value': 'École Normale Supérieure Paris-Saclay', 'label': 'École Normale Supérieure Paris-Saclay' },
    { 'id': 210, 'value': 'École polytechnique', 'label': 'École polytechnique' },
    { 'id': 1869, 'value': 'École Polytechnique de Montréal', 'label': 'École Polytechnique de Montréal' },
    { 'id': 11227, 'value': 'École polytechnique de Thiès', 'label': 'École polytechnique de Thiès' },
    { 'id': 293, 'value': 'Ecole Polytechnique Fédérale de Lausanne', 'label': 'Ecole Polytechnique Fédérale de Lausanne' },
    { 'id': 11554, 'value': 'École Polytechnique Internationale Privée de Tunis', 'label': 'École Polytechnique Internationale Privée de Tunis' },
    { 'id': 3902, 'value': 'École Polytehnique d\'Architecture et d\'Urbanisme', 'label': 'École Polytehnique d\'Architecture et d\'Urbanisme' },
    { 'id': 4318, 'value': 'École pour l\'Informatique et les Techniques Avancées', 'label': 'École pour l\'Informatique et les Techniques Avancées' },
    { 'id': 7900, 'value': 'École Royale d\'Administration', 'label': 'École Royale d\'Administration' },
    { 'id': 4319, 'value': 'École Spéciale des Travaux Publics, du Bâtiment et de l\'Industrie', 'label': 'École Spéciale des Travaux Publics, du Bâtiment et de l\'Industrie' },
    { 'id': 3903, 'value': 'École Supérieur en Génie Electrique et Energétique d\'Oran', 'label': 'École Supérieur en Génie Electrique et Energétique d\'Oran' },
    { 'id': 2021, 'value': 'École Supérieure Africaine des Technologies de l\'Information et de la Communication', 'label': 'École Supérieure Africaine des Technologies de l\'Information et de la Communication' },
    { 'id': 1501, 'value': 'École Supérieure d\'Administration, d\'Economie, de Journalisme et des Métiers de l\'Audiovisuel', 'label': 'École Supérieure d\'Administration, d\'Economie, de Journalisme et des Métiers de l\'Audiovisuel' },
    { 'id': 4320, 'value': 'École Supérieure d\'Agriculture d\'Angers', 'label': 'École Supérieure d\'Agriculture d\'Angers' },
    { 'id': 4321, 'value': 'École Supérieure d\'Agro-développement International', 'label': 'École Supérieure d\'Agro-développement International' },
    { 'id': 3909, 'value': 'École Supérieure d\'Economie d\'Oran', 'label': 'École Supérieure d\'Economie d\'Oran' },
    { 'id': 11231, 'value': 'École Supérieure d\'Electricité, du Bâtiment et des Travaux Publics', 'label': 'École Supérieure d\'Electricité, du Bâtiment et des Travaux Publics' },
    { 'id': 4332, 'value': 'École Supérieure d\'Électronique de l\'Ouest', 'label': 'École Supérieure d\'Électronique de l\'Ouest' },
    { 'id': 4337, 'value': 'École Supérieure d\'Informatique, Électronique, Automatique', 'label': 'École Supérieure d\'Informatique, Électronique, Automatique' },
    { 'id': 4338, 'value': 'École Supérieure d\'Ingénieurs des Travaux de la Construction de Caen', 'label': 'École Supérieure d\'Ingénieurs des Travaux de la Construction de Caen' },
    { 'id': 4339, 'value': 'École Supérieure d\'Ingénieurs des Travaux de la Construction de Metz', 'label': 'École Supérieure d\'Ingénieurs des Travaux de la Construction de Metz' },
    { 'id': 4340, 'value': 'École Supérieure d\'Ingénieurs des Travaux de la Construction de Paris', 'label': 'École Supérieure d\'Ingénieurs des Travaux de la Construction de Paris' },
    { 'id': 4341, 'value': 'École Supérieure d\'Ingénieurs en Génie Électrique', 'label': 'École Supérieure d\'Ingénieurs en Génie Électrique' },
    { 'id': 4342, 'value': 'École Supérieure d\'Ingénieurs Léonard de Vinci', 'label': 'École Supérieure d\'Ingénieurs Léonard de Vinci' },
    { 'id': 11555, 'value': 'École Superieure d\'Ingénieurs Privée de Gafsa', 'label': 'École Superieure d\'Ingénieurs Privée de Gafsa' },
    { 'id': 8413, 'value': 'Ecole Supérieure d’Architecture de Casablanca', 'label': 'Ecole Supérieure d’Architecture de Casablanca' },
    { 'id': 4322, 'value': 'École Supérieure de Chimie Organique et Minérale', 'label': 'École Supérieure de Chimie Organique et Minérale' },
    { 'id': 4323, 'value': 'École Supérieure de Chimie Physique Électronique de Lyon', 'label': 'École Supérieure de Chimie Physique Électronique de Lyon' },
    { 'id': 3904, 'value': 'École Supérieure de Commerce', 'label': 'École Supérieure de Commerce' },
    { 'id': 4324, 'value': 'École Supérieure de Commerce d\'Amiens', 'label': 'École Supérieure de Commerce d\'Amiens' },
    { 'id': 11228, 'value': 'École Supérieure de Commerce de Dakar', 'label': 'École Supérieure de Commerce de Dakar' },
    { 'id': 4325, 'value': 'École Supérieure de Commerce de Dijon-Bourgogne', 'label': 'École Supérieure de Commerce de Dijon-Bourgogne' },
    { 'id': 8414, 'value': 'Ecole Superieure de Commerce de Marrakech', 'label': 'Ecole Superieure de Commerce de Marrakech' },
    { 'id': 4326, 'value': 'École Supérieure de Commerce de Pau', 'label': 'École Supérieure de Commerce de Pau' },
    { 'id': 4327, 'value': 'École Supérieure de Commerce de Saint-Etienne', 'label': 'École Supérieure de Commerce de Saint-Etienne' },
    { 'id': 4328, 'value': 'École Supérieure de Commerce et de Développement 3A', 'label': 'École Supérieure de Commerce et de Développement 3A' },
    { 'id': 4329, 'value': 'École Supérieure de Commerce et de Management', 'label': 'École Supérieure de Commerce et de Management' },
    { 'id': 3905, 'value': 'École Supérieure de Comptabilité et de Finances de Constantine', 'label': 'École Supérieure de Comptabilité et de Finances de Constantine' },
    { 'id': 4330, 'value': 'École Supérieure de Fonderie et de Forge', 'label': 'École Supérieure de Fonderie et de Forge' },
    { 'id': 1502, 'value': 'École Supérieure de Génie Civil Verechaguine AK', 'label': 'École Supérieure de Génie Civil Verechaguine AK' },
    { 'id': 1503, 'value': 'École Supérieure de Génie Telecoms et TIC', 'label': 'École Supérieure de Génie Telecoms et TIC' },
    { 'id': 11229, 'value': 'École Supérieure de Génies', 'label': 'École Supérieure de Génies' },
    { 'id': 4481, 'value': 'École Supérieure de Gestion et d\'Expertise Comptable', 'label': 'École Supérieure de Gestion et d\'Expertise Comptable' },
    { 'id': 4482, 'value': 'École Supérieure de Gestion, d\'Informatique et des Sciences', 'label': 'École Supérieure de Gestion, d\'Informatique et des Sciences' },
    { 'id': 3906, 'value': 'École Supérieure de la Magistrature', 'label': 'École Supérieure de la Magistrature' },
    { 'id': 4483, 'value': 'École Supérieure de la Mer', 'label': 'École Supérieure de la Mer' },
    { 'id': 1504, 'value': 'École Supérieure de Management Benin', 'label': 'École Supérieure de Management Benin' },
    { 'id': 3907, 'value': 'École Supérieure de Management de Tlemcen', 'label': 'École Supérieure de Management de Tlemcen' },
    { 'id': 4331, 'value': 'École supérieure de physique et de chimie industrielles de la Ville de Paris', 'label': 'École supérieure de physique et de chimie industrielles de la Ville de Paris' },
    { 'id': 3908, 'value': 'École Supérieure de Technologies Industrielles d’Annaba', 'label': 'École Supérieure de Technologies Industrielles d’Annaba' },
    { 'id': 11230, 'value': 'École Supérieure de Télécommunication d\'Informatique et de Management', 'label': 'École Supérieure de Télécommunication d\'Informatique et de Management' },
    { 'id': 8282, 'value': 'École Supérieure des Affaires', 'label': 'École Supérieure des Affaires' },
    { 'id': 1350, 'value': 'École Supérieure des Arts de la Ville de Liège', 'label': 'École Supérieure des Arts de la Ville de Liège' },
    { 'id': 1351, 'value': 'École Supérieure des Arts Saint-Luc de Liège', 'label': 'École Supérieure des Arts Saint-Luc de Liège' },
    { 'id': 1505, 'value': 'École Supérieure des Métiers des Énergies Renouvelables', 'label': 'École Supérieure des Métiers des Énergies Renouvelables' },
    { 'id': 3910, 'value': 'École Supérieure des Sciences Appliquées d\'Alger', 'label': 'École Supérieure des Sciences Appliquées d\'Alger' },
    { 'id': 3911, 'value': 'École Supérieure des Sciences de Gestion de Annaba', 'label': 'École Supérieure des Sciences de Gestion de Annaba' },
    { 'id': 3912, 'value': 'École Supérieure des Sciences de l\'Aliment et Industries Agroalimentaires', 'label': 'École Supérieure des Sciences de l\'Aliment et Industries Agroalimentaires' },
    { 'id': 4333, 'value': 'École Supérieure des Sciences Économiques et Commerciales', 'label': 'École Supérieure des Sciences Économiques et Commerciales' },
    { 'id': 4334, 'value': 'École Supérieure des Techniques Aéronautiques et de Construction Automobile', 'label': 'École Supérieure des Techniques Aéronautiques et de Construction Automobile' },
    { 'id': 4335, 'value': 'École supérieure des technologies et des affaires de Belfort', 'label': 'École supérieure des technologies et des affaires de Belfort' },
    { 'id': 4336, 'value': 'École Supérieure des Technologies Industrielles Avancées', 'label': 'École Supérieure des Technologies Industrielles Avancées' },
    { 'id': 11232, 'value': 'École Supérieure du Bâtiment', 'label': 'École Supérieure du Bâtiment' },
    { 'id': 4343, 'value': 'École Supérieure du Bois', 'label': 'École Supérieure du Bois' },
    { 'id': 4344, 'value': 'École Supérieure du Commerce Extérieur', 'label': 'École Supérieure du Commerce Extérieur' },
    { 'id': 3913, 'value': 'École Supérieure en Informatique', 'label': 'École Supérieure en Informatique' },
    { 'id': 8415, 'value': 'Ecole Supérieure en Ingénierie de l\'Information, Télécoms, Management et Génie Civil', 'label': 'Ecole Supérieure en Ingénierie de l\'Information, Télécoms, Management et Génie Civil' },
    { 'id': 3914, 'value': 'École Supérieure en Sciences Appliquées de Tlemcen', 'label': 'École Supérieure en Sciences Appliquées de Tlemcen' },
    { 'id': 3915, 'value': 'École Supérieure en Sciences Biologiques d\'Oran', 'label': 'École Supérieure en Sciences Biologiques d\'Oran' },
    { 'id': 1506, 'value': 'École Supérieure Jean Michel le FAUCON', 'label': 'École Supérieure Jean Michel le FAUCON' },
    { 'id': 11556, 'value': 'École Supérieure Polytechnique Internationale Privée de Sfax', 'label': 'École Supérieure Polytechnique Internationale Privée de Sfax' },
    { 'id': 11557, 'value': 'École Supérieure Polytechnique Privée de Monastir', 'label': 'École Supérieure Polytechnique Privée de Monastir' },
    { 'id': 11558, 'value': 'École Supérieure Polytechnique Privée de Sousse', 'label': 'École Supérieure Polytechnique Privée de Sousse' },
    { 'id': 11559, 'value': 'École Supérieure Privée d\'Administration des Affaires et de Droit Avicenne', 'label': 'École Supérieure Privée d\'Administration des Affaires et de Droit Avicenne' },
    { 'id': 11560, 'value': 'École Supérieure Privée d\'Architecture, d\'Audiovisuel et de Design', 'label': 'École Supérieure Privée d\'Architecture, d\'Audiovisuel et de Design' },
    { 'id': 11561, 'value': 'École Supérieure Privée d\'Assurance et de Finance', 'label': 'École Supérieure Privée d\'Assurance et de Finance' },
    { 'id': 11568, 'value': 'École Supérieure Privée d\'Informatique et d’Administration des Affaires', 'label': 'École Supérieure Privée d\'Informatique et d’Administration des Affaires' },
    { 'id': 11569, 'value': 'École Supérieure Privée d\'Ingénierie et de Technologie', 'label': 'École Supérieure Privée d\'Ingénierie et de Technologie' },
    { 'id': 11570, 'value': 'École Supérieure Privée d\'Ingénierie et de Technologie Appliquée', 'label': 'École Supérieure Privée d\'Ingénierie et de Technologie Appliquée' },
    { 'id': 11571, 'value': 'École Supérieure Privée d\'Ingénieurs de Monastir', 'label': 'École Supérieure Privée d\'Ingénieurs de Monastir' },
    { 'id': 11562, 'value': 'École Supérieure Privée de l\'Aéronautique et des Technologies', 'label': 'École Supérieure Privée de l\'Aéronautique et des Technologies' },
    { 'id': 11563, 'value': 'École Supérieure Privée de Technologie et de Management', 'label': 'École Supérieure Privée de Technologie et de Management' },
    { 'id': 11564, 'value': 'École Supérieure Privée des Etudes Administratives et Commerciales', 'label': 'École Supérieure Privée des Etudes Administratives et Commerciales' },
    { 'id': 11566, 'value': 'École Supérieure Privée des Technologies d\'Informatique et de Management', 'label': 'École Supérieure Privée des Technologies d\'Informatique et de Management' },
    { 'id': 11565, 'value': 'École Supérieure Privée des Technologies de l\'Information et de Management de Nabeul', 'label': 'École Supérieure Privée des Technologies de l\'Information et de Management de Nabeul' },
    { 'id': 11567, 'value': 'École Supérieure Privée des Technologies et Ingénierie', 'label': 'École Supérieure Privée des Technologies et Ingénierie' },
    { 'id': 1507, 'value': 'École Supérieure Sainte Félicité', 'label': 'École Supérieure Sainte Félicité' },
    { 'id': 7901, 'value': 'Economics and Finance Institute', 'label': 'Economics and Finance Institute' },
    { 'id': 14341, 'value': 'Economist and Jurist School', 'label': 'Economist and Jurist School' },
    { 'id': 12637, 'value': 'ECPI University', 'label': 'ECPI University' },
    { 'id': 14259, 'value': 'Eden University', 'label': 'Eden University' },
    { 'id': 4514, 'value': 'Edge Hill University', 'label': 'Edge Hill University' },
    { 'id': 12638, 'value': 'Edgewood College', 'label': 'Edgewood College' },
    { 'id': 243, 'value': 'EDHEC Business School', 'label': 'EDHEC Business School' },
    { 'id': 12639, 'value': 'Edinboro University of Pennsylvania', 'label': 'Edinboro University of Pennsylvania' },
    { 'id': 324, 'value': 'Edinburgh Napier University', 'label': 'Edinburgh Napier University' },
    { 'id': 1078, 'value': 'Edith Cowan University', 'label': 'Edith Cowan University' },
    { 'id': 9346, 'value': 'Edo University', 'label': 'Edo University' },
    { 'id': 7134, 'value': 'Edogawa University', 'label': 'Edogawa University' },
    { 'id': 10351, 'value': 'EDP University', 'label': 'EDP University' },
    { 'id': 703, 'value': 'Edrak Institute of Higher Education', 'label': 'Edrak Institute of Higher Education' },
    { 'id': 4897, 'value': 'Edutus Egyetem', 'label': 'Edutus Egyetem' },
    { 'id': 4819, 'value': 'Edward Bernays Visoka škola za komunikacijski menadžment', 'label': 'Edward Bernays Visoka škola za komunikacijski menadžment' },
    { 'id': 12640, 'value': 'Edward Waters College', 'label': 'Edward Waters College' },
    { 'id': 9347, 'value': 'Edwin Clark University', 'label': 'Edwin Clark University' },
    { 'id': 4024, 'value': 'EELK Usuteaduse Instituut', 'label': 'EELK Usuteaduse Instituut' },
    { 'id': 4025, 'value': 'Eesti Ettevõtluskõrgkool Mainor', 'label': 'Eesti Ettevõtluskõrgkool Mainor' },
    { 'id': 4026, 'value': 'Eesti Kunstiakadeemia', 'label': 'Eesti Kunstiakadeemia' },
    { 'id': 4027, 'value': 'Eesti Lennuakadeemia', 'label': 'Eesti Lennuakadeemia' },
    { 'id': 4028, 'value': 'Eesti Maaülikool', 'label': 'Eesti Maaülikool' },
    { 'id': 4029, 'value': 'Eesti Muusika- ja Teatriakadeemia', 'label': 'Eesti Muusika- ja Teatriakadeemia' },
    { 'id': 11024, 'value': 'Effat University', 'label': 'Effat University' },
    { 'id': 14340, 'value': 'EFI | EUROPEAN FORENSIC INSTITUTE', 'label': 'EFI | EUROPEAN FORENSIC INSTITUTE' },
    { 'id': 11679, 'value': 'Ege Üniversitesi', 'label': 'Ege Üniversitesi' },
    { 'id': 7787, 'value': 'Egerton University', 'label': 'Egerton University' },
    { 'id': 4058, 'value': 'Egypt-Japan University of Science and Technology', 'label': 'Egypt-Japan University of Science and Technology' },
    { 'id': 4056, 'value': 'Egyptian Chinese University', 'label': 'Egyptian Chinese University' },
    { 'id': 4057, 'value': 'Egyptian Russian University', 'label': 'Egyptian Russian University' },
    { 'id': 7135, 'value': 'Ehime Prefectural University of Health Sciences', 'label': 'Ehime Prefectural University of Health Sciences' },
    { 'id': 7136, 'value': 'Ehime University', 'label': 'Ehime University' },
    { 'id': 338, 'value': 'EHL- École Hôtelière de Lausanne', 'label': 'EHL- École Hôtelière de Lausanne' },
    { 'id': 2000, 'value': 'Eidgenössische Technische Hochschule Zürich', 'label': 'Eidgenössische Technische Hochschule Zürich' },
    { 'id': 5753, 'value': 'EIILM University', 'label': 'EIILM University' },
    { 'id': 14359, 'value': 'EIMS - European Institute for Medical Studies', 'label': 'EIMS - European Institute for Medical Studies' },
    { 'id': 555, 'value': 'Eindhoven University of Technology', 'label': 'Eindhoven University of Technology' },
    { 'id': 9348, 'value': 'Ekiti State University, Ado Ekiti', 'label': 'Ekiti State University, Ado Ekiti' },
    { 'id': 9349, 'value': 'Eko University of Medical and Health Sciences', 'label': 'Eko University of Medical and Health Sciences' },
    { 'id': 11188, 'value': 'Ekonomická univerzita v Bratislave', 'label': 'Ekonomická univerzita v Bratislave' },
    { 'id': 8373, 'value': 'Ekonomikas un kulturas augstskola', 'label': 'Ekonomikas un kulturas augstskola' },
    { 'id': 8710, 'value': 'El Colegio de Chihuahua', 'label': 'El Colegio de Chihuahua' },
    { 'id': 8711, 'value': 'El Colegio de México', 'label': 'El Colegio de México' },
    { 'id': 8712, 'value': 'El Colegio de Sonora', 'label': 'El Colegio de Sonora' },
    { 'id': 8713, 'value': 'El Colegio Mexiquense A.C.', 'label': 'El Colegio Mexiquense A.C.' },
    { 'id': 11063, 'value': 'Eldaein University', 'label': 'Eldaein University' },
    { 'id': 7137, 'value': 'Elisabeth University of Music', 'label': 'Elisabeth University of Music' },
    { 'id': 3076, 'value': 'Elite- Escuela Latinoamericana de Ingenieros, Tecnologos y Empresarios', 'label': 'Elite- Escuela Latinoamericana de Ingenieros, Tecnologos y Empresarios' },
    { 'id': 12641, 'value': 'Elizabeth City State University', 'label': 'Elizabeth City State University' },
    { 'id': 12642, 'value': 'Elizabethtown College', 'label': 'Elizabethtown College' },
    { 'id': 9350, 'value': 'Elizade University', 'label': 'Elizade University' },
    { 'id': 12643, 'value': 'Elmhurst College', 'label': 'Elmhurst College' },
    { 'id': 12644, 'value': 'Elmira College', 'label': 'Elmira College' },
    { 'id': 12645, 'value': 'Elon University', 'label': 'Elon University' },
    { 'id': 11064, 'value': 'Elrazi University', 'label': 'Elrazi University' },
    { 'id': 11065, 'value': 'Elsheikh Abdallah Elbadri University', 'label': 'Elsheikh Abdallah Elbadri University' },
    { 'id': 12646, 'value': 'Embry-Riddle Aeronautical University', 'label': 'Embry-Riddle Aeronautical University' },
    { 'id': 12647, 'value': 'Emerson College', 'label': 'Emerson College' },
    { 'id': 9881, 'value': 'Emilio Aguinaldo College', 'label': 'Emilio Aguinaldo College' },
    { 'id': 1870, 'value': 'Emily Carr University of Art and Design', 'label': 'Emily Carr University of Art and Design' },
    { 'id': 635, 'value': 'Emirates Aviation University', 'label': 'Emirates Aviation University' },
    { 'id': 636, 'value': 'Emirates College for Advanced Education', 'label': 'Emirates College for Advanced Education' },
    { 'id': 637, 'value': 'Emirates College of Technology', 'label': 'Emirates College of Technology' },
    { 'id': 638, 'value': 'Emirates Institute for Banking and Financial Studies', 'label': 'Emirates Institute for Banking and Financial Studies' },
    { 'id': 14193, 'value': 'Emirates International University', 'label': 'Emirates International University' },
    { 'id': 4345, 'value': 'EMLYON Business School', 'label': 'EMLYON Business School' },
    { 'id': 12648, 'value': 'Emmanuel College', 'label': 'Emmanuel College' },
    { 'id': 12649, 'value': 'Emmanuel College, Georgia', 'label': 'Emmanuel College, Georgia' },
    { 'id': 12650, 'value': 'Emmaus Bible College', 'label': 'Emmaus Bible College' },
    { 'id': 12651, 'value': 'Emory & Henry College', 'label': 'Emory & Henry College' },
    { 'id': 12652, 'value': 'Emory University', 'label': 'Emory University' },
    { 'id': 1158, 'value': 'eMPIRICA', 'label': 'eMPIRICA' },
    { 'id': 12653, 'value': 'Emporia State University', 'label': 'Emporia State University' },
    { 'id': 12654, 'value': 'Endicott College', 'label': 'Endicott College' },
    { 'id': 8595, 'value': 'Enerel Medical Institute', 'label': 'Enerel Medical Institute' },
    { 'id': 6527, 'value': 'Engineering Higher Education Complex of Esfarayen', 'label': 'Engineering Higher Education Complex of Esfarayen' },
    { 'id': 4346, 'value': 'ENSAE ParisTech', 'label': 'ENSAE ParisTech' },
    { 'id': 4347, 'value': 'ENSTA ParisTech', 'label': 'ENSTA ParisTech' },
    { 'id': 11233, 'value': 'Ensup Afrique', 'label': 'Ensup Afrique' },
    { 'id': 9351, 'value': 'Enugu State University of Science and Technology', 'label': 'Enugu State University of Science and Technology' },
    { 'id': 4898, 'value': 'Eötvös József Foiskola', 'label': 'Eötvös József Foiskola' },
    { 'id': 4899, 'value': 'Eötvös Loránd Tudományegyetem', 'label': 'Eötvös Loránd Tudományegyetem' },
    { 'id': 4348, 'value': 'EPF École d\'Ingénieurs', 'label': 'EPF École d\'Ingénieurs' },
    { 'id': 14380, 'value': 'EPFL - École polytechnique fédérale de Lausanne', 'label': 'EPFL - École polytechnique fédérale de Lausanne' },
    { 'id': 11572, 'value': 'EPI Sousse', 'label': 'EPI Sousse' },
    { 'id': 14407, 'value': 'Epicode - epic education', 'label': 'Epicode - epic education' },
    { 'id': 5754, 'value': 'Era University', 'label': 'Era University' },
    { 'id': 101, 'value': 'Erasmus University Rotterdam', 'label': 'Erasmus University Rotterdam' },
    { 'id': 1352, 'value': 'Erasmushogeschool Brussel', 'label': 'Erasmushogeschool Brussel' },
    { 'id': 6450, 'value': 'Erbil Polytechnic University', 'label': 'Erbil Polytechnic University' },
    { 'id': 11680, 'value': 'Erciyes Üniversitesi', 'label': 'Erciyes Üniversitesi' },
    { 'id': 3825, 'value': 'Erhvervsakademi Aarhus', 'label': 'Erhvervsakademi Aarhus' },
    { 'id': 3826, 'value': 'Erhvervsakademi Dania', 'label': 'Erhvervsakademi Dania' },
    { 'id': 3827, 'value': 'Erhvervsakademi Midtvest', 'label': 'Erhvervsakademi Midtvest' },
    { 'id': 3828, 'value': 'Erhvervsakademi Sjælland', 'label': 'Erhvervsakademi Sjælland' },
    { 'id': 3829, 'value': 'Erhvervsakademi SydVest', 'label': 'Erhvervsakademi SydVest' },
    { 'id': 3830, 'value': 'Erhvervsakademiet Copenhagen Business', 'label': 'Erhvervsakademiet Copenhagen Business' },
    { 'id': 4095, 'value': 'Eritrea Institute of Technology', 'label': 'Eritrea Institute of Technology' },
    { 'id': 11219, 'value': 'Ernest Bai Koroma University of Science and Technology', 'label': 'Ernest Bai Koroma University of Science and Technology' },
    { 'id': 3485, 'value': 'Ernst-Abbe-Hochschule Jena', 'label': 'Ernst-Abbe-Hochschule Jena' },
    { 'id': 3486, 'value': 'Ernst-Moritz-Arndt-Universität Greifswald', 'label': 'Ernst-Moritz-Arndt-Universität Greifswald' },
    { 'id': 12655, 'value': 'Erskine College', 'label': 'Erskine College' },
    { 'id': 11112, 'value': 'Ersta Sköndal Bräcke högskola', 'label': 'Ersta Sköndal Bräcke högskola' },
    { 'id': 11681, 'value': 'Erzincan Binali Yildirim Üniversitesi', 'label': 'Erzincan Binali Yildirim Üniversitesi' },
    { 'id': 11682, 'value': 'Erzurum Teknik Üniversitesi', 'label': 'Erzurum Teknik Üniversitesi' },
    { 'id': 357, 'value': 'ESADE Ramon Llul University', 'label': 'ESADE Ramon Llul University' },
    { 'id': 4349, 'value': 'ESAIP École d\'Ingénieurs', 'label': 'ESAIP École d\'Ingénieurs' },
    { 'id': 8416, 'value': 'ESCA École de Management', 'label': 'ESCA École de Management' },
    { 'id': 10406, 'value': 'Escola Superior Artística de Guimarães', 'label': 'Escola Superior Artística de Guimarães' },
    { 'id': 10407, 'value': 'Escola Superior Artística do Porto', 'label': 'Escola Superior Artística do Porto' },
    { 'id': 10408, 'value': 'Escola Superior de Artes e Design', 'label': 'Escola Superior de Artes e Design' },
    { 'id': 10409, 'value': 'Escola Superior de Atividades Imobiliárias', 'label': 'Escola Superior de Atividades Imobiliárias' },
    { 'id': 10410, 'value': 'Escola Superior de Educação Almeida Garrett', 'label': 'Escola Superior de Educação Almeida Garrett' },
    { 'id': 10411, 'value': 'Escola Superior de Educação de João de Deus', 'label': 'Escola Superior de Educação de João de Deus' },
    { 'id': 10412, 'value': 'Escola Superior de Educação de Paula Frassinetti', 'label': 'Escola Superior de Educação de Paula Frassinetti' },
    { 'id': 10413, 'value': 'Escola Superior de Educadores de Infância Maria Ulrich', 'label': 'Escola Superior de Educadores de Infância Maria Ulrich' },
    { 'id': 10414, 'value': 'Escola Superior de Enfermagem Cruz Vermelha Portuguesa', 'label': 'Escola Superior de Enfermagem Cruz Vermelha Portuguesa' },
    { 'id': 10415, 'value': 'Escola Superior de Enfermagem de Coimbra', 'label': 'Escola Superior de Enfermagem de Coimbra' },
    { 'id': 10416, 'value': 'Escola Superior de Enfermagem de Lisboa', 'label': 'Escola Superior de Enfermagem de Lisboa' },
    { 'id': 10417, 'value': 'Escola Superior de Enfermagem de São José de Cluny', 'label': 'Escola Superior de Enfermagem de São José de Cluny' },
    { 'id': 10418, 'value': 'Escola Superior de Enfermagem do Porto', 'label': 'Escola Superior de Enfermagem do Porto' },
    { 'id': 10419, 'value': 'Escola Superior de Enfermagem São Francisco das Misericórdias', 'label': 'Escola Superior de Enfermagem São Francisco das Misericórdias' },
    { 'id': 10420, 'value': 'Escola Superior de Hotelaria e Turismo do Estoril', 'label': 'Escola Superior de Hotelaria e Turismo do Estoril' },
    { 'id': 10421, 'value': 'Escola Superior de Negócios Atlântico', 'label': 'Escola Superior de Negócios Atlântico' },
    { 'id': 10422, 'value': 'Escola Superior de Saúde da Cruz Vermelha Portuguesa', 'label': 'Escola Superior de Saúde da Cruz Vermelha Portuguesa' },
    { 'id': 10423, 'value': 'Escola Superior de Saúde de Santa Maria', 'label': 'Escola Superior de Saúde de Santa Maria' },
    { 'id': 10424, 'value': 'Escola Superior de Saúde do Alcoitão', 'label': 'Escola Superior de Saúde do Alcoitão' },
    { 'id': 10425, 'value': 'Escola Superior de Saúde Egas Moniz', 'label': 'Escola Superior de Saúde Egas Moniz' },
    { 'id': 10426, 'value': 'Escola Superior de Saúde Norte da Cruz Vermelha Portuguesa', 'label': 'Escola Superior de Saúde Norte da Cruz Vermelha Portuguesa' },
    { 'id': 10427, 'value': 'Escola Superior de Tecnologias e Artes de Lisboa', 'label': 'Escola Superior de Tecnologias e Artes de Lisboa' },
    { 'id': 10428, 'value': 'Escola Superior Gallaecia', 'label': 'Escola Superior Gallaecia' },
    { 'id': 10429, 'value': 'Escola Superior Náutica Infante D. Henrique', 'label': 'Escola Superior Náutica Infante D. Henrique' },
    { 'id': 10430, 'value': 'Escola Universitária Vasco da Gama', 'label': 'Escola Universitária Vasco da Gama' },
    { 'id': 174, 'value': 'ESCP EUROPE', 'label': 'ESCP EUROPE' },
    { 'id': 4808, 'value': 'Escuela Agrícola Panamericana, Zamorano', 'label': 'Escuela Agrícola Panamericana, Zamorano' },
    { 'id': 8714, 'value': 'Escuela Bancaria y Comercial', 'label': 'Escuela Bancaria y Comercial' },
    { 'id': 3077, 'value': 'Escuela Colombiana de Ingenieria Julio Garavito', 'label': 'Escuela Colombiana de Ingenieria Julio Garavito' },
    { 'id': 10352, 'value': 'Escuela de Artes Plasticas y Diseno de Puerto Rico', 'label': 'Escuela de Artes Plasticas y Diseno de Puerto Rico' },
    { 'id': 11298, 'value': 'Escuela de Comunicación Mónica Herrera', 'label': 'Escuela de Comunicación Mónica Herrera' },
    { 'id': 3078, 'value': 'Escuela de Ingeniería de Antioquia', 'label': 'Escuela de Ingeniería de Antioquia' },
    { 'id': 11299, 'value': 'Escuela Especializada en Ingeniería ITCA-FEPADE', 'label': 'Escuela Especializada en Ingeniería ITCA-FEPADE' },
    { 'id': 3284, 'value': 'Escuela Latinoamericana de Medicina', 'label': 'Escuela Latinoamericana de Medicina' },
    { 'id': 1532, 'value': 'Escuela Militar de Ingeniería', 'label': 'Escuela Militar de Ingeniería' },
    { 'id': 3842, 'value': 'Escuela Nacional de la Judicatura', 'label': 'Escuela Nacional de la Judicatura' },
    { 'id': 3079, 'value': 'Escuela Nacional del Deporte', 'label': 'Escuela Nacional del Deporte' },
    { 'id': 3965, 'value': 'Escuela Politécnica Nacional', 'label': 'Escuela Politécnica Nacional' },
    { 'id': 11300, 'value': 'Escuela Superior de Economía y Negocios', 'label': 'Escuela Superior de Economía y Negocios' },
    { 'id': 11301, 'value': 'Escuela Superior Franciscana Especializada AGAPE', 'label': 'Escuela Superior Franciscana Especializada AGAPE' },
    { 'id': 3966, 'value': 'Escuela Superior Politecnica Agropecuaria de Manabi', 'label': 'Escuela Superior Politecnica Agropecuaria de Manabi' },
    { 'id': 3967, 'value': 'Escuela Superior Politecnica de Chimborazo', 'label': 'Escuela Superior Politecnica de Chimborazo' },
    { 'id': 3968, 'value': 'Escuela Superior Politécnica del Litoral', 'label': 'Escuela Superior Politécnica del Litoral' },
    { 'id': 3080, 'value': 'Escuela Superiore de Administración Pública', 'label': 'Escuela Superiore de Administración Pública' },
    { 'id': 415, 'value': 'ESCUELA TECNICA SUPERIOR DE ARQUITECTURA', 'label': 'ESCUELA TECNICA SUPERIOR DE ARQUITECTURA' },
    { 'id': 3081, 'value': 'Escuela Tecnologica Instituto Tecnico Central', 'label': 'Escuela Tecnologica Instituto Tecnico Central' },
    { 'id': 1508, 'value': 'Esep Le Berger', 'label': 'Esep Le Berger' },
    { 'id': 10387, 'value': 'ESF College of Women\'s Society in Ramallah', 'label': 'ESF College of Women\'s Society in Ramallah' },
    { 'id': 704, 'value': 'Eshrag Institute of Higher Education', 'label': 'Eshrag Institute of Higher Education' },
    { 'id': 4351, 'value': 'ESIEE Paris', 'label': 'ESIEE Paris' },
    { 'id': 11683, 'value': 'Eskisehir Osmangazi Üniversitesi', 'label': 'Eskisehir Osmangazi Üniversitesi' },
    { 'id': 4352, 'value': 'ESME Sudria', 'label': 'ESME Sudria' },
    { 'id': 3487, 'value': 'ESMT Berlin', 'label': 'ESMT Berlin' },
    { 'id': 4353, 'value': 'ESSCA School of Management', 'label': 'ESSCA School of Management' },
    { 'id': 410, 'value': 'ESSEC business school', 'label': 'ESSEC business school' },
    { 'id': 705, 'value': 'Esteghlal Institute of Higher Education', 'label': 'Esteghlal Institute of Higher Education' },
    { 'id': 706, 'value': 'Estiqamat Higher Education Institute', 'label': 'Estiqamat Higher Education Institute' },
    { 'id': 4900, 'value': 'Esztergomi Hittudományi Fõiskola', 'label': 'Esztergomi Hittudományi Fõiskola' },
    { 'id': 4901, 'value': 'Eszterházy Károly Egyetem', 'label': 'Eszterházy Károly Egyetem' },
    { 'id': 5755, 'value': 'Eternal University', 'label': 'Eternal University' },
    { 'id': 360, 'value': 'ETH Zürich', 'label': 'ETH Zürich' },
    { 'id': 8596, 'value': 'Etugen University', 'label': 'Etugen University' },
    { 'id': 8010, 'value': 'Eulji University', 'label': 'Eulji University' },
    { 'id': 9882, 'value': 'Eulogio "Amang" Rodriguez Institute of Science and Technology', 'label': 'Eulogio "Amang" Rodriguez Institute of Science and Technology' },
    { 'id': 817, 'value': 'Eurasia International University', 'label': 'Eurasia International University' },
    { 'id': 8186, 'value': 'Eurasian Academy of Law after D.A. Kunaev', 'label': 'Eurasian Academy of Law after D.A. Kunaev' },
    { 'id': 8187, 'value': 'Eurasian Institute for the Humanities', 'label': 'Eurasian Institute for the Humanities' },
    { 'id': 8188, 'value': 'Eurasian Technological University', 'label': 'Eurasian Technological University' },
    { 'id': 4354, 'value': 'Eurecom', 'label': 'Eurecom' },
    { 'id': 12656, 'value': 'Eureka College', 'label': 'Eureka College' },
    { 'id': 4030, 'value': 'Euroakadeemia', 'label': 'Euroakadeemia' },
    { 'id': 3490, 'value': 'Europa-Universität Flensburg', 'label': 'Europa-Universität Flensburg' },
    { 'id': 3491, 'value': 'Europa-Universität Viadrina Frankfurt (Oder)', 'label': 'Europa-Universität Viadrina Frankfurt (Oder)' },
    { 'id': 3488, 'value': 'Europäische Fachhochschule', 'label': 'Europäische Fachhochschule' },
    { 'id': 3489, 'value': 'Europäische Fernhochschule Hamburg', 'label': 'Europäische Fernhochschule Hamburg' },
    { 'id': 1428, 'value': 'European Higher School of Economics and Management', 'label': 'European Higher School of Economics and Management' },
    { 'id': 639, 'value': 'European International College', 'label': 'European International College' },
    { 'id': 1429, 'value': 'European Polytechnical University', 'label': 'European Polytechnical University' },
    { 'id': 613, 'value': 'European School of Economics', 'label': 'European School of Economics' },
    { 'id': 818, 'value': 'European University', 'label': 'European University' },
    { 'id': 10669, 'value': 'European University at St. Petersburg', 'label': 'European University at St. Petersburg' },
    { 'id': 640, 'value': 'European University College', 'label': 'European University College' },
    { 'id': 495, 'value': 'European University Cyprus', 'label': 'European University Cyprus' },
    { 'id': 1246, 'value': 'European University of Bangladesh', 'label': 'European University of Bangladesh' },
    { 'id': 8333, 'value': 'Europos Humanitarinis Universitetas', 'label': 'Europos Humanitarinis Universitetas' },
    { 'id': 4820, 'value': 'Europska poslovna škola Zagreb', 'label': 'Europska poslovna škola Zagreb' },
    { 'id': 12657, 'value': 'Evangel University', 'label': 'Evangel University' },
    { 'id': 9352, 'value': 'Evangel University, Akaeze', 'label': 'Evangel University, Akaeze' },
    { 'id': 4681, 'value': 'Evangelical Presbyterian University College', 'label': 'Evangelical Presbyterian University College' },
    { 'id': 14260, 'value': 'Evangelical University', 'label': 'Evangelical University' },
    { 'id': 3492, 'value': 'Evangelische Hochschule Berlin', 'label': 'Evangelische Hochschule Berlin' },
    { 'id': 3493, 'value': 'Evangelische Hochschule Darmstadt', 'label': 'Evangelische Hochschule Darmstadt' },
    { 'id': 3494, 'value': 'Evangelische Hochschule Dresden', 'label': 'Evangelische Hochschule Dresden' },
    { 'id': 3495, 'value': 'Evangelische Hochschule Freiburg', 'label': 'Evangelische Hochschule Freiburg' },
    { 'id': 3496, 'value': 'Evangelische Hochschule für Kirchenmusik', 'label': 'Evangelische Hochschule für Kirchenmusik' },
    { 'id': 3497, 'value': 'Evangelische Hochschule für Soziale Arbeit und Diakonie', 'label': 'Evangelische Hochschule für Soziale Arbeit und Diakonie' },
    { 'id': 3498, 'value': 'Evangelische Hochschule Ludwigsburg', 'label': 'Evangelische Hochschule Ludwigsburg' },
    { 'id': 3499, 'value': 'Evangelische Hochschule Nürnberg', 'label': 'Evangelische Hochschule Nürnberg' },
    { 'id': 3500, 'value': 'Evangelische Hochschule Rheinland-Westfalen-Lippe', 'label': 'Evangelische Hochschule Rheinland-Westfalen-Lippe' },
    { 'id': 1353, 'value': 'Evangelische Theologische Faculteit', 'label': 'Evangelische Theologische Faculteit' },
    { 'id': 12658, 'value': 'Everglades University', 'label': 'Everglades University' },
    { 'id': 11159, 'value': 'Evropska pravna fakulteta', 'label': 'Evropska pravna fakulteta' },
    { 'id': 10598, 'value': 'Evropski Univerzitet', 'label': 'Evropski Univerzitet' },
    { 'id': 1159, 'value': 'Evropski univerzitet Brcko distrikt', 'label': 'Evropski univerzitet Brcko distrikt' },
    { 'id': 1160, 'value': 'Evropski univerzitet Kallos Tuzla', 'label': 'Evropski univerzitet Kallos Tuzla' },
    { 'id': 3402, 'value': 'Evropský polytechnický institut', 'label': 'Evropský polytechnický institut' },
    { 'id': 8011, 'value': 'Ewha Womans University', 'label': 'Ewha Womans University' },
    { 'id': 1247, 'value': 'Exim Bank Agricultural University of Bangladesh', 'label': 'Exim Bank Agricultural University of Bangladesh' },
    { 'id': 8679, 'value': 'Exploits University', 'label': 'Exploits University' },
    { 'id': 3501, 'value': 'Fachhochschule Aachen', 'label': 'Fachhochschule Aachen' },
    { 'id': 3502, 'value': 'Fachhochschule Bielefeld', 'label': 'Fachhochschule Bielefeld' },
    { 'id': 1007, 'value': 'Fachhochschule Burgenland', 'label': 'Fachhochschule Burgenland' },
    { 'id': 1008, 'value': 'Fachhochschule Campus Wien', 'label': 'Fachhochschule Campus Wien' },
    { 'id': 3503, 'value': 'Fachhochschule der Diakonie', 'label': 'Fachhochschule der Diakonie' },
    { 'id': 3504, 'value': 'Fachhochschule der Wirtschaft', 'label': 'Fachhochschule der Wirtschaft' },
    { 'id': 1009, 'value': 'Fachhochschule des bfi Wien', 'label': 'Fachhochschule des bfi Wien' },
    { 'id': 3505, 'value': 'Fachhochschule des Mittelstands', 'label': 'Fachhochschule des Mittelstands' },
    { 'id': 3506, 'value': 'Fachhochschule Dortmund', 'label': 'Fachhochschule Dortmund' },
    { 'id': 3507, 'value': 'Fachhochschule Dresden', 'label': 'Fachhochschule Dresden' },
    { 'id': 3508, 'value': 'Fachhochschule Erfurt', 'label': 'Fachhochschule Erfurt' },
    { 'id': 3509, 'value': 'Fachhochschule für die Wirtschaft Hannover', 'label': 'Fachhochschule für die Wirtschaft Hannover' },
    { 'id': 3510, 'value': 'Fachhochschule für Finanzen NRW', 'label': 'Fachhochschule für Finanzen NRW' },
    { 'id': 3511, 'value': 'Fachhochschule für Rechtspflege Nordrhein-Westfalen', 'label': 'Fachhochschule für Rechtspflege Nordrhein-Westfalen' },
    { 'id': 3512, 'value': 'Fachhochschule für Sport und Management Potsdam', 'label': 'Fachhochschule für Sport und Management Potsdam' },
    { 'id': 1010, 'value': 'Fachhochschule Kärnten', 'label': 'Fachhochschule Kärnten' },
    { 'id': 3513, 'value': 'Fachhochschule Kiel', 'label': 'Fachhochschule Kiel' },
    { 'id': 1011, 'value': 'Fachhochschule Kufstein Tirol', 'label': 'Fachhochschule Kufstein Tirol' },
    { 'id': 3514, 'value': 'Fachhochschule Lübeck', 'label': 'Fachhochschule Lübeck' },
    { 'id': 3515, 'value': 'Fachhochschule Münster', 'label': 'Fachhochschule Münster' },
    { 'id': 576, 'value': 'Fachhochschule Nordwestschweiz', 'label': 'Fachhochschule Nordwestschweiz' },
    { 'id': 2001, 'value': 'Fachhochschule Ostschweiz', 'label': 'Fachhochschule Ostschweiz' },
    { 'id': 3516, 'value': 'Fachhochschule Potsdam', 'label': 'Fachhochschule Potsdam' },
    { 'id': 1012, 'value': 'Fachhochschule Salzburg', 'label': 'Fachhochschule Salzburg' },
    { 'id': 1013, 'value': 'Fachhochschule St. Pölten', 'label': 'Fachhochschule St. Pölten' },
    { 'id': 3517, 'value': 'Fachhochschule Südwestfalen', 'label': 'Fachhochschule Südwestfalen' },
    { 'id': 1014, 'value': 'Fachhochschule Vorarlberg', 'label': 'Fachhochschule Vorarlberg' },
    { 'id': 3518, 'value': 'Fachhochschule Wedel', 'label': 'Fachhochschule Wedel' },
    { 'id': 3519, 'value': 'Fachhochschule Westküste', 'label': 'Fachhochschule Westküste' },
    { 'id': 1015, 'value': 'Fachhochschule Wiener Neustadt', 'label': 'Fachhochschule Wiener Neustadt' },
    { 'id': 13966, 'value': 'Facultad de Teología del Uruguay Mons. Mariano Soler', 'label': 'Facultad de Teología del Uruguay Mons. Mariano Soler' },
    { 'id': 872, 'value': 'Facultad Latinoamericana de Ciencias Sociales', 'label': 'Facultad Latinoamericana de Ciencias Sociales' },
    { 'id': 3969, 'value': 'Facultad Latinoamericana de Ciencias Sociales, Ecuador', 'label': 'Facultad Latinoamericana de Ciencias Sociales, Ecuador' },
    { 'id': 11573, 'value': 'Faculté des Sciences de la Santés', 'label': 'Faculté des Sciences de la Santés' },
    { 'id': 11574, 'value': 'Faculté Privée de Management et des Sciences de l’Administration de Sousse', 'label': 'Faculté Privée de Management et des Sciences de l’Administration de Sousse' },
    { 'id': 11575, 'value': 'Faculté Privée des Sciences Paramédicales de Sfax', 'label': 'Faculté Privée des Sciences Paramédicales de Sfax' },
    { 'id': 11025, 'value': 'Fahad Bin Sultan University', 'label': 'Fahad Bin Sultan University' },
    { 'id': 12659, 'value': 'Fairfield University', 'label': 'Fairfield University' },
    { 'id': 12660, 'value': 'Fairleigh Dickinson University', 'label': 'Fairleigh Dickinson University' },
    { 'id': 12661, 'value': 'Fairmont State University', 'label': 'Fairmont State University' },
    { 'id': 12662, 'value': 'Faith Baptist Bible College and Theological Seminary', 'label': 'Faith Baptist Bible College and Theological Seminary' },
    { 'id': 5756, 'value': 'Fakir Mohan University', 'label': 'Fakir Mohan University' },
    { 'id': 11160, 'value': 'Fakulteta za državne in evropske študije', 'label': 'Fakulteta za državne in evropske študije' },
    { 'id': 11161, 'value': 'Fakulteta za komercialne in poslovne vede', 'label': 'Fakulteta za komercialne in poslovne vede' },
    { 'id': 11162, 'value': 'Fakulteta za medije', 'label': 'Fakulteta za medije' },
    { 'id': 11163, 'value': 'Fakulteta za pravo in poslovne vede', 'label': 'Fakulteta za pravo in poslovne vede' },
    { 'id': 11164, 'value': 'Fakulteta za psihoterapevtsko znanost Univerze Sigmunda Freuda v Ljubljani', 'label': 'Fakulteta za psihoterapevtsko znanost Univerze Sigmunda Freuda v Ljubljani' },
    { 'id': 11165, 'value': 'Fakulteta za tehnologijo polimerov', 'label': 'Fakulteta za tehnologijo polimerov' },
    { 'id': 11166, 'value': 'Fakulteta za uporabne družbene študije v Novi Gorici', 'label': 'Fakulteta za uporabne družbene študije v Novi Gorici' },
    { 'id': 11167, 'value': 'Fakulteta za zdravstvo Angele Boškin', 'label': 'Fakulteta za zdravstvo Angele Boškin' },
    { 'id': 497, 'value': 'Falmouth University', 'label': 'Falmouth University' },
    { 'id': 707, 'value': 'Fanoos Institute of Higher Education', 'label': 'Fanoos Institute of Higher Education' },
    { 'id': 10670, 'value': 'Far East State Agrarian University', 'label': 'Far East State Agrarian University' },
    { 'id': 11832, 'value': 'Far East University', 'label': 'Far East University' },
    { 'id': 8012, 'value': 'Far East University, Korea', 'label': 'Far East University, Korea' },
    { 'id': 10671, 'value': 'Far Eastern Federal University', 'label': 'Far Eastern Federal University' },
    { 'id': 10672, 'value': 'Far Eastern State Medical University', 'label': 'Far Eastern State Medical University' },
    { 'id': 10673, 'value': 'Far Eastern State Technical Fisheries University', 'label': 'Far Eastern State Technical Fisheries University' },
    { 'id': 10674, 'value': 'Far Eastern State Transport University', 'label': 'Far Eastern State Transport University' },
    { 'id': 9883, 'value': 'Far Eastern University', 'label': 'Far Eastern University' },
    { 'id': 9592, 'value': 'Far Western University', 'label': 'Far Western University' },
    { 'id': 1248, 'value': 'Fareast International University', 'label': 'Fareast International University' },
    { 'id': 13990, 'value': 'Farg\'ona Davlat Universiteti', 'label': 'Farg\'ona Davlat Universiteti' },
    { 'id': 13991, 'value': 'Farg\'ona Politexnika Instituti', 'label': 'Farg\'ona Politexnika Instituti' },
    { 'id': 12663, 'value': 'Farmingdale State College', 'label': 'Farmingdale State College' },
    { 'id': 708, 'value': 'Faryab University', 'label': 'Faryab University' },
    { 'id': 6528, 'value': 'Farzanegan Semnan University', 'label': 'Farzanegan Semnan University' },
    { 'id': 6529, 'value': 'Fasa University', 'label': 'Fasa University' },
    { 'id': 6530, 'value': 'Fasa University of Medical Sciences', 'label': 'Fasa University of Medical Sciences' },
    { 'id': 12664, 'value': 'Fashion Institute of Technology', 'label': 'Fashion Institute of Technology' },
    { 'id': 10068, 'value': 'FATA University', 'label': 'FATA University' },
    { 'id': 9884, 'value': 'Father Saturnino Urios University', 'label': 'Father Saturnino Urios University' },
    { 'id': 11684, 'value': 'Fatih Sultan Mehmet Vakif Üniversitesi', 'label': 'Fatih Sultan Mehmet Vakif Üniversitesi' },
    { 'id': 641, 'value': 'Fatima College of Health Sciences', 'label': 'Fatima College of Health Sciences' },
    { 'id': 10069, 'value': 'Fatima Jinnah Medical University', 'label': 'Fatima Jinnah Medical University' },
    { 'id': 10070, 'value': 'Fatima Jinnah Women University', 'label': 'Fatima Jinnah Women University' },
    { 'id': 11398, 'value': 'Fatoni University', 'label': 'Fatoni University' },
    { 'id': 12665, 'value': 'Faulkner University', 'label': 'Faulkner University' },
    { 'id': 12666, 'value': 'Fayetteville State University', 'label': 'Fayetteville State University' },
    { 'id': 4059, 'value': 'Fayoum University', 'label': 'Fayoum University' },
    { 'id': 9885, 'value': 'FEATI University', 'label': 'FEATI University' },
    { 'id': 9353, 'value': 'Federal University of Agriculture, Abeokuta', 'label': 'Federal University of Agriculture, Abeokuta' },
    { 'id': 9354, 'value': 'Federal University of Petroleum Resources', 'label': 'Federal University of Petroleum Resources' },
    { 'id': 9355, 'value': 'Federal University of Technology, Akure', 'label': 'Federal University of Technology, Akure' },
    { 'id': 9356, 'value': 'Federal University of Technology, Minna', 'label': 'Federal University of Technology, Minna' },
    { 'id': 9357, 'value': 'Federal University of Technology, Owerri', 'label': 'Federal University of Technology, Owerri' },
    { 'id': 9358, 'value': 'Federal University, Birnin Kebbi', 'label': 'Federal University, Birnin Kebbi' },
    { 'id': 9359, 'value': 'Federal University, Dutse', 'label': 'Federal University, Dutse' },
    { 'id': 9360, 'value': 'Federal University, Dutsin-Ma', 'label': 'Federal University, Dutsin-Ma' },
    { 'id': 9361, 'value': 'Federal University, Gashua', 'label': 'Federal University, Gashua' },
    { 'id': 9362, 'value': 'Federal University, Gusau', 'label': 'Federal University, Gusau' },
    { 'id': 9363, 'value': 'Federal University, Kashere', 'label': 'Federal University, Kashere' },
    { 'id': 9364, 'value': 'Federal University, Lafia', 'label': 'Federal University, Lafia' },
    { 'id': 9365, 'value': 'Federal University, Lokoja', 'label': 'Federal University, Lokoja' },
    { 'id': 9366, 'value': 'Federal University, Otuoke', 'label': 'Federal University, Otuoke' },
    { 'id': 9367, 'value': 'Federal University, Oye-Ekiti', 'label': 'Federal University, Oye-Ekiti' },
    { 'id': 9368, 'value': 'Federal University, Wukari', 'label': 'Federal University, Wukari' },
    { 'id': 10071, 'value': 'Federal Urdu University of Arts, Sciences and Technology', 'label': 'Federal Urdu University of Arts, Sciences and Technology' },
    { 'id': 1079, 'value': 'Federation University Australia', 'label': 'Federation University Australia' },
    { 'id': 12667, 'value': 'Felician University', 'label': 'Felician University' },
    { 'id': 11685, 'value': 'Fenerbahçe Üniversitesi', 'label': 'Fenerbahçe Üniversitesi' },
    { 'id': 11833, 'value': 'Feng Chia University', 'label': 'Feng Chia University' },
    { 'id': 1249, 'value': 'Feni University', 'label': 'Feni University' },
    { 'id': 1016, 'value': 'Ferdinand Porsche FernFH', 'label': 'Ferdinand Porsche FernFH' },
    { 'id': 6531, 'value': 'Ferdowsi University of Mashhad', 'label': 'Ferdowsi University of Mashhad' },
    { 'id': 3520, 'value': 'FernUniversität in Hagen', 'label': 'FernUniversität in Hagen' },
    { 'id': 12668, 'value': 'Ferris State University', 'label': 'Ferris State University' },
    { 'id': 7138, 'value': 'Ferris University', 'label': 'Ferris University' },
    { 'id': 12669, 'value': 'Ferrum College', 'label': 'Ferrum College' },
    { 'id': 9886, 'value': 'FEU Institute of Technology', 'label': 'FEU Institute of Technology' },
    { 'id': 1017, 'value': 'FH Gesundheitsberufe OÖ', 'label': 'FH Gesundheitsberufe OÖ' },
    { 'id': 1018, 'value': 'FH Joanneum', 'label': 'FH Joanneum' },
    { 'id': 1019, 'value': 'FH Oberösterreich', 'label': 'FH Oberösterreich' },
    { 'id': 1020, 'value': 'FH Technikum Wien', 'label': 'FH Technikum Wien' },
    { 'id': 1021, 'value': 'FHG - Zentrum für Gesundheitsberufe Tirol', 'label': 'FHG - Zentrum für Gesundheitsberufe Tirol' },
    { 'id': 1022, 'value': 'FHWien der WKW', 'label': 'FHWien der WKW' },
    { 'id': 12670, 'value': 'Fielding Graduate University', 'label': 'Fielding Graduate University' },
    { 'id': 4242, 'value': 'Fiji National University', 'label': 'Fiji National University' },
    { 'id': 9887, 'value': 'Filamer Christian University', 'label': 'Filamer Christian University' },
    { 'id': 3403, 'value': 'Filmová akademie Miroslava Ondrícka v Písku', 'label': 'Filmová akademie Miroslava Ondrícka v Písku' },
    { 'id': 3521, 'value': 'Filmuniversität Babelsberg Konrad Wolf', 'label': 'Filmuniversität Babelsberg Konrad Wolf' },
    { 'id': 10675, 'value': 'Finance Academy under the Government of the Russian Federation', 'label': 'Finance Academy under the Government of the Russian Federation' },
    { 'id': 8189, 'value': 'Financial Academy', 'label': 'Financial Academy' },
    { 'id': 12671, 'value': 'Finlandia University', 'label': 'Finlandia University' },
    { 'id': 11686, 'value': 'Firat Üniversitesi', 'label': 'Firat Üniversitesi' },
    { 'id': 1250, 'value': 'First Capital University of Bangladesh', 'label': 'First Capital University of Bangladesh' },
    { 'id': 1871, 'value': 'First Nations University of Canada', 'label': 'First Nations University of Canada' },
    { 'id': 12672, 'value': 'Fisher College', 'label': 'Fisher College' },
    { 'id': 12673, 'value': 'Fisk University', 'label': 'Fisk University' },
    { 'id': 12674, 'value': 'Fitchburg State University', 'label': 'Fitchburg State University' },
    { 'id': 12675, 'value': 'Five Towns College', 'label': 'Five Towns College' },
    { 'id': 12676, 'value': 'Flagler College', 'label': 'Flagler College' },
    { 'id': 5757, 'value': 'Flame University', 'label': 'Flame University' },
    { 'id': 3522, 'value': 'Fliedner Fachhochschule Düsseldorf', 'label': 'Fliedner Fachhochschule Düsseldorf' },
    { 'id': 1080, 'value': 'Flinders University', 'label': 'Flinders University' },
    { 'id': 12677, 'value': 'Florida Agricultural and Mechanical University', 'label': 'Florida Agricultural and Mechanical University' },
    { 'id': 12678, 'value': 'Florida Atlantic University', 'label': 'Florida Atlantic University' },
    { 'id': 12679, 'value': 'Florida College', 'label': 'Florida College' },
    { 'id': 12680, 'value': 'Florida Gateway College', 'label': 'Florida Gateway College' },
    { 'id': 12681, 'value': 'Florida Gulf Coast University', 'label': 'Florida Gulf Coast University' },
    { 'id': 12682, 'value': 'Florida Institute of Technology', 'label': 'Florida Institute of Technology' },
    { 'id': 12683, 'value': 'Florida International University', 'label': 'Florida International University' },
    { 'id': 12684, 'value': 'Florida Memorial University', 'label': 'Florida Memorial University' },
    { 'id': 12685, 'value': 'Florida National University', 'label': 'Florida National University' },
    { 'id': 12686, 'value': 'Florida Southern College', 'label': 'Florida Southern College' },
    { 'id': 12687, 'value': 'Florida SouthWestern State College', 'label': 'Florida SouthWestern State College' },
    { 'id': 12688, 'value': 'Florida State College at Jacksonville', 'label': 'Florida State College at Jacksonville' },
    { 'id': 12689, 'value': 'Florida State University', 'label': 'Florida State University' },
    { 'id': 9650, 'value': 'Florida State University Panama City', 'label': 'Florida State University Panama City' },
    { 'id': 11834, 'value': 'Fo Guang University', 'label': 'Fo Guang University' },
    { 'id': 3523, 'value': 'Folkwang Universität der Künste', 'label': 'Folkwang Universität der Künste' },
    { 'id': 3524, 'value': 'FOM Hochschule für Oekonomie und Management', 'label': 'FOM Hochschule für Oekonomie und Management' },
    { 'id': 8475, 'value': 'FON Univerzitet', 'label': 'FON Univerzitet' },
    { 'id': 14357, 'value': 'Fondazione ITS Energia Ambiente e Edilizia Sostenibile', 'label': 'Fondazione ITS Energia Ambiente e Edilizia Sostenibile' },
    { 'id': 14626, 'value': 'Fondazione ITS ICT', 'label': 'Fondazione ITS ICT' },
    { 'id': 454, 'value': 'Fondazione ITS Recanati', 'label': 'Fondazione ITS Recanati' },
    { 'id': 12690, 'value': 'Fontbonne University', 'label': 'Fontbonne University' },
    { 'id': 9521, 'value': 'Fontys Hogescholen', 'label': 'Fontys Hogescholen' },
    { 'id': 11835, 'value': 'Fooyin University', 'label': 'Fooyin University' },
    { 'id': 12691, 'value': 'Fordham University', 'label': 'Fordham University' },
    { 'id': 14117, 'value': 'Foreign Trade University', 'label': 'Foreign Trade University' },
    { 'id': 5758, 'value': 'Forest Research Institute', 'label': 'Forest Research Institute' },
    { 'id': 10072, 'value': 'Forman Christian College', 'label': 'Forman Christian College' },
    { 'id': 12692, 'value': 'Fort Hays State University', 'label': 'Fort Hays State University' },
    { 'id': 12693, 'value': 'Fort Lewis College', 'label': 'Fort Lewis College' },
    { 'id': 12694, 'value': 'Fort Valley State University', 'label': 'Fort Valley State University' },
    { 'id': 11836, 'value': 'Fortune Institute of Technology', 'label': 'Fortune Institute of Technology' },
    { 'id': 2279, 'value': 'Foshan University', 'label': 'Foshan University' },
    { 'id': 10073, 'value': 'Foundation University Islamabad', 'label': 'Foundation University Islamabad' },
    { 'id': 9369, 'value': 'Fountain University, Osogbo', 'label': 'Fountain University, Osogbo' },
    { 'id': 14118, 'value': 'FPT University', 'label': 'FPT University' },
    { 'id': 12695, 'value': 'Framingham State University', 'label': 'Framingham State University' },
    { 'id': 12696, 'value': 'Francis Marion University', 'label': 'Francis Marion University' },
    { 'id': 12697, 'value': 'Franciscan Missionaries of Our Lady University', 'label': 'Franciscan Missionaries of Our Lady University' },
    { 'id': 12698, 'value': 'Franciscan University of Steubenville', 'label': 'Franciscan University of Steubenville' },
    { 'id': 3525, 'value': 'Frankfurt School of Finance and Management', 'label': 'Frankfurt School of Finance and Management' },
    { 'id': 3526, 'value': 'Frankfurt University of Applied Sciences', 'label': 'Frankfurt University of Applied Sciences' },
    { 'id': 12699, 'value': 'Franklin and Marshall College', 'label': 'Franklin and Marshall College' },
    { 'id': 12700, 'value': 'Franklin College', 'label': 'Franklin College' },
    { 'id': 12701, 'value': 'Franklin Pierce University', 'label': 'Franklin Pierce University' },
    { 'id': 12702, 'value': 'Franklin University', 'label': 'Franklin University' },
    { 'id': 12703, 'value': 'Franklin W. Olin College of Engineering', 'label': 'Franklin W. Olin College of Engineering' },
    { 'id': 3357, 'value': 'Frederick University', 'label': 'Frederick University' },
    { 'id': 4629, 'value': 'Free University of Tbilisi', 'label': 'Free University of Tbilisi' },
    { 'id': 12704, 'value': 'Freed-Hardeman University', 'label': 'Freed-Hardeman University' },
    { 'id': 3527, 'value': 'Freie Theologische Hochschule Giessen', 'label': 'Freie Theologische Hochschule Giessen' },
    { 'id': 3528, 'value': 'Freie Universität Berlin', 'label': 'Freie Universität Berlin' },
    { 'id': 12705, 'value': 'Fresno Pacific University', 'label': 'Fresno Pacific University' },
    { 'id': 3529, 'value': 'Friedrich-Alexander-Universität Erlangen-Nürnberg', 'label': 'Friedrich-Alexander-Universität Erlangen-Nürnberg' },
    { 'id': 3530, 'value': 'Friedrich-Schiller-Universität Jena', 'label': 'Friedrich-Schiller-Universität Jena' },
    { 'id': 12706, 'value': 'Friends University', 'label': 'Friends University' },
    { 'id': 12707, 'value': 'Frostburg State University', 'label': 'Frostburg State University' },
    { 'id': 11837, 'value': 'Fu Jen Catholic University', 'label': 'Fu Jen Catholic University' },
    { 'id': 2280, 'value': 'Fudan University', 'label': 'Fudan University' },
    { 'id': 7139, 'value': 'Fuji University', 'label': 'Fuji University' },
    { 'id': 7140, 'value': 'Fuji Women\'s University', 'label': 'Fuji Women\'s University' },
    { 'id': 2281, 'value': 'Fujian Agriculture and Forestry University', 'label': 'Fujian Agriculture and Forestry University' },
    { 'id': 2282, 'value': 'Fujian Business University', 'label': 'Fujian Business University' },
    { 'id': 2283, 'value': 'Fujian Jiangxia University', 'label': 'Fujian Jiangxia University' },
    { 'id': 2284, 'value': 'Fujian Medical University', 'label': 'Fujian Medical University' },
    { 'id': 2285, 'value': 'Fujian Normal University', 'label': 'Fujian Normal University' },
    { 'id': 2286, 'value': 'Fujian University of Technology', 'label': 'Fujian University of Technology' },
    { 'id': 2287, 'value': 'Fujian University of Traditional Chinese Medicine', 'label': 'Fujian University of Traditional Chinese Medicine' },
    { 'id': 7141, 'value': 'Fujita Health University', 'label': 'Fujita Health University' },
    { 'id': 7142, 'value': 'Fukui Prefectural University', 'label': 'Fukui Prefectural University' },
    { 'id': 7143, 'value': 'Fukui University of Technology', 'label': 'Fukui University of Technology' },
    { 'id': 7144, 'value': 'Fukuoka Dental College', 'label': 'Fukuoka Dental College' },
    { 'id': 7145, 'value': 'Fukuoka Institute of Technology', 'label': 'Fukuoka Institute of Technology' },
    { 'id': 7146, 'value': 'Fukuoka International University', 'label': 'Fukuoka International University' },
    { 'id': 7147, 'value': 'Fukuoka Jo Gakuin University', 'label': 'Fukuoka Jo Gakuin University' },
    { 'id': 7148, 'value': 'Fukuoka Prefectural University', 'label': 'Fukuoka Prefectural University' },
    { 'id': 7149, 'value': 'Fukuoka University', 'label': 'Fukuoka University' },
    { 'id': 7150, 'value': 'Fukuoka University of Education', 'label': 'Fukuoka University of Education' },
    { 'id': 7151, 'value': 'Fukuoka Women\'s University', 'label': 'Fukuoka Women\'s University' },
    { 'id': 7152, 'value': 'Fukushima College', 'label': 'Fukushima College' },
    { 'id': 7153, 'value': 'Fukushima Medical University', 'label': 'Fukushima Medical University' },
    { 'id': 7154, 'value': 'Fukushima University', 'label': 'Fukushima University' },
    { 'id': 7155, 'value': 'Fukuyama Heisei University', 'label': 'Fukuyama Heisei University' },
    { 'id': 7156, 'value': 'Fukuyama University', 'label': 'Fukuyama University' },
    { 'id': 430, 'value': 'Fullerton College', 'label': 'Fullerton College' },
    { 'id': 3082, 'value': 'Fundacion Centro Universitario de Bienestar Rural', 'label': 'Fundacion Centro Universitario de Bienestar Rural' },
    { 'id': 3083, 'value': 'Fundacion de Estudios Superiores Monseñor Abraham Escudero Montoya', 'label': 'Fundacion de Estudios Superiores Monseñor Abraham Escudero Montoya' },
    { 'id': 3084, 'value': 'Fundacion de Estudios Superiores Universitarios de Uraba Antonio Roldan Betancur', 'label': 'Fundacion de Estudios Superiores Universitarios de Uraba Antonio Roldan Betancur' },
    { 'id': 3085, 'value': 'Fundacion Escuela Colombiana de Mercadotecnia', 'label': 'Fundacion Escuela Colombiana de Mercadotecnia' },
    { 'id': 3086, 'value': 'Fundacion Escuela Colombiana de Rehabilitacion', 'label': 'Fundacion Escuela Colombiana de Rehabilitacion' },
    { 'id': 3087, 'value': 'Fundacion Escuela Tecnologica de Neiva Jesus Oviedo Perez', 'label': 'Fundacion Escuela Tecnologica de Neiva Jesus Oviedo Perez' },
    { 'id': 3088, 'value': 'Fundacion Universitaria Agraria de Colombia', 'label': 'Fundacion Universitaria Agraria de Colombia' },
    { 'id': 3089, 'value': 'Fundacion Universitaria Autonoma de Las Americas', 'label': 'Fundacion Universitaria Autonoma de Las Americas' },
    { 'id': 3090, 'value': 'Fundacion Universitaria Bellas Artes', 'label': 'Fundacion Universitaria Bellas Artes' },
    { 'id': 3091, 'value': 'Fundacion Universitaria Cafam', 'label': 'Fundacion Universitaria Cafam' },
    { 'id': 3092, 'value': 'Fundacion Universitaria Catolica del Norte', 'label': 'Fundacion Universitaria Catolica del Norte' },
    { 'id': 3093, 'value': 'Fundacion Universitaria Catolica Lumen Gentium', 'label': 'Fundacion Universitaria Catolica Lumen Gentium' },
    { 'id': 3094, 'value': 'Fundacion Universitaria Ceipa', 'label': 'Fundacion Universitaria Ceipa' },
    { 'id': 3095, 'value': 'Fundación Universitaria Cervantes San Agustín', 'label': 'Fundación Universitaria Cervantes San Agustín' },
    { 'id': 3096, 'value': 'Fundacion Universitaria Claretiana', 'label': 'Fundacion Universitaria Claretiana' },
    { 'id': 3097, 'value': 'Fundacion Universitaria Colombo Internacional', 'label': 'Fundacion Universitaria Colombo Internacional' },
    { 'id': 3098, 'value': 'Fundacion Universitaria Comfamiliar Risaralda', 'label': 'Fundacion Universitaria Comfamiliar Risaralda' },
    { 'id': 3099, 'value': 'Fundacion Universitaria Comfenalco Santander', 'label': 'Fundacion Universitaria Comfenalco Santander' },
    { 'id': 3100, 'value': 'Fundacion Universitaria de Ciencias de La Salud', 'label': 'Fundacion Universitaria de Ciencias de La Salud' },
    { 'id': 3101, 'value': 'Fundacion Universitaria de Popayan', 'label': 'Fundacion Universitaria de Popayan' },
    { 'id': 3102, 'value': 'Fundacion Universitaria de San Gil', 'label': 'Fundacion Universitaria de San Gil' },
    { 'id': 3103, 'value': 'Fundación Universitaria del Area Andina', 'label': 'Fundación Universitaria del Area Andina' },
    { 'id': 3104, 'value': 'Fundacion Universitaria Empresarial de La Camara de Comercio de Bogota', 'label': 'Fundacion Universitaria Empresarial de La Camara de Comercio de Bogota' },
    { 'id': 3105, 'value': 'Fundacion Universitaria Horizonte', 'label': 'Fundacion Universitaria Horizonte' },
    { 'id': 3106, 'value': 'Fundacion Universitaria Internacional de Colombia', 'label': 'Fundacion Universitaria Internacional de Colombia' },
    { 'id': 3107, 'value': 'Fundacion Universitaria Internacional del Tropico Americano', 'label': 'Fundacion Universitaria Internacional del Tropico Americano' },
    { 'id': 3108, 'value': 'Fundacion Universitaria Juan de Castellanos', 'label': 'Fundacion Universitaria Juan de Castellanos' },
    { 'id': 3109, 'value': 'Fundación Universitaria Juan N. Corpas', 'label': 'Fundación Universitaria Juan N. Corpas' },
    { 'id': 3110, 'value': 'Fundacion Universitaria Konrad Lorenz', 'label': 'Fundacion Universitaria Konrad Lorenz' },
    { 'id': 3111, 'value': 'Fundacion Universitaria Luis G. Paez', 'label': 'Fundacion Universitaria Luis G. Paez' },
    { 'id': 3112, 'value': 'Fundacion Universitaria Maria Cano', 'label': 'Fundacion Universitaria Maria Cano' },
    { 'id': 3113, 'value': 'Fundacion Universitaria Monserrate', 'label': 'Fundacion Universitaria Monserrate' },
    { 'id': 3114, 'value': 'Fundacion Universitaria Navarra', 'label': 'Fundacion Universitaria Navarra' },
    { 'id': 3115, 'value': 'Fundacion Universitaria Panamericana', 'label': 'Fundacion Universitaria Panamericana' },
    { 'id': 3116, 'value': 'Fundacion Universitaria Para el Desarrollo Humano', 'label': 'Fundacion Universitaria Para el Desarrollo Humano' },
    { 'id': 3117, 'value': 'Fundacion Universitaria Salesiana', 'label': 'Fundacion Universitaria Salesiana' },
    { 'id': 3118, 'value': 'Fundación Universitaria San Alfonso', 'label': 'Fundación Universitaria San Alfonso' },
    { 'id': 3119, 'value': 'Fundación Universitaria San Martín', 'label': 'Fundación Universitaria San Martín' },
    { 'id': 3120, 'value': 'Fundacion Universitaria San Mateo', 'label': 'Fundacion Universitaria San Mateo' },
    { 'id': 3121, 'value': 'Fundacion Universitaria Sanitas', 'label': 'Fundacion Universitaria Sanitas' },
    { 'id': 3122, 'value': 'Fundacion Universitaria Tecnologico Comfenalco - Cartagena', 'label': 'Fundacion Universitaria Tecnologico Comfenalco - Cartagena' },
    { 'id': 12708, 'value': 'Furman University', 'label': 'Furman University' },
    { 'id': 14194, 'value': 'Future University', 'label': 'Future University' },
    { 'id': 7157, 'value': 'Future University Hakodate', 'label': 'Future University Hakodate' },
    { 'id': 4060, 'value': 'Future University in Egypt', 'label': 'Future University in Egypt' },
    { 'id': 2288, 'value': 'Fuyang Normal University', 'label': 'Fuyang Normal University' },
    { 'id': 2289, 'value': 'Fuzhou Institute of Technology', 'label': 'Fuzhou Institute of Technology' },
    { 'id': 2290, 'value': 'Fuzhou University', 'label': 'Fuzhou University' },
    { 'id': 5759, 'value': 'G H Raisoni University', 'label': 'G H Raisoni University' },
    { 'id': 5760, 'value': 'G.L.S. University', 'label': 'G.L.S. University' },
    { 'id': 4902, 'value': 'Gábor Dénes Foiskola', 'label': 'Gábor Dénes Foiskola' },
    { 'id': 1793, 'value': 'Gaborone University College of Law and Professional Studies', 'label': 'Gaborone University College of Law and Professional Studies' },
    { 'id': 8013, 'value': 'Gachon University', 'label': 'Gachon University' },
    { 'id': 10676, 'value': 'Gaidar Institute for Economic Policy', 'label': 'Gaidar Institute for Economic Policy' },
    { 'id': 7158, 'value': 'Gakushuin University', 'label': 'Gakushuin University' },
    { 'id': 7159, 'value': 'Gakushuin Women\'s College', 'label': 'Gakushuin Women\'s College' },
    { 'id': 11687, 'value': 'Galatasaray Üniversitesi', 'label': 'Galatasaray Üniversitesi' },
    { 'id': 1847, 'value': 'Galen University', 'label': 'Galen University' },
    { 'id': 5761, 'value': 'Galgotias University', 'label': 'Galgotias University' },
    { 'id': 819, 'value': 'GALIK University', 'label': 'GALIK University' },
    { 'id': 5537, 'value': 'Galilee International Management Institute', 'label': 'Galilee International Management Institute' },
    { 'id': 12709, 'value': 'Gallaudet University', 'label': 'Gallaudet University' },
    { 'id': 5510, 'value': 'Galway-Mayo Institute of Technology', 'label': 'Galway-Mayo Institute of Technology' },
    { 'id': 10074, 'value': 'Gambat Institute of Medical Sciences', 'label': 'Gambat Institute of Medical Sciences' },
    { 'id': 10075, 'value': 'Gandhara University', 'label': 'Gandhara University' },
    { 'id': 5762, 'value': 'Gandhi Institute of Technology and Management', 'label': 'Gandhi Institute of Technology and Management' },
    { 'id': 5763, 'value': 'Gandhigram Rural University', 'label': 'Gandhigram Rural University' },
    { 'id': 5764, 'value': 'Gangadhar Meher University', 'label': 'Gangadhar Meher University' },
    { 'id': 8014, 'value': 'Gangneung-Wonju National University', 'label': 'Gangneung-Wonju National University' },
    { 'id': 2291, 'value': 'Gannan Medical University', 'label': 'Gannan Medical University' },
    { 'id': 2292, 'value': 'Gannan Normal University', 'label': 'Gannan Normal University' },
    { 'id': 12710, 'value': 'Gannon University', 'label': 'Gannon University' },
    { 'id': 5765, 'value': 'Ganpat University', 'label': 'Ganpat University' },
    { 'id': 2293, 'value': 'Gansu Agricultural University', 'label': 'Gansu Agricultural University' },
    { 'id': 2294, 'value': 'Gansu Institute of Political Science and Law', 'label': 'Gansu Institute of Political Science and Law' },
    { 'id': 2295, 'value': 'Gansu Medical College', 'label': 'Gansu Medical College' },
    { 'id': 2296, 'value': 'Gansu Normal University for Nationalities', 'label': 'Gansu Normal University for Nationalities' },
    { 'id': 2297, 'value': 'Gansu University of Chinese Medicine', 'label': 'Gansu University of Chinese Medicine' },
    { 'id': 5766, 'value': 'Garden City University', 'label': 'Garden City University' },
    { 'id': 4682, 'value': 'Garden City University College', 'label': 'Garden City University College' },
    { 'id': 12711, 'value': 'Gardner-Webb University', 'label': 'Gardner-Webb University' },
    { 'id': 7788, 'value': 'Garissa University', 'label': 'Garissa University' },
    { 'id': 5767, 'value': 'Gauhati University', 'label': 'Gauhati University' },
    { 'id': 5768, 'value': 'Gautam Buddha University', 'label': 'Gautam Buddha University' },
    { 'id': 820, 'value': 'Gavar State University', 'label': 'Gavar State University' },
    { 'id': 709, 'value': 'Gawharshad Institute of Higher Education', 'label': 'Gawharshad Institute of Higher Education' },
    { 'id': 10388, 'value': 'Gaza University', 'label': 'Gaza University' },
    { 'id': 8597, 'value': 'Gazarchin University of Mongolia', 'label': 'Gazarchin University of Mongolia' },
    { 'id': 11688, 'value': 'Gazi Üniversitesi', 'label': 'Gazi Üniversitesi' },
    { 'id': 11689, 'value': 'Gaziantep Üniversitesi', 'label': 'Gaziantep Üniversitesi' },
    { 'id': 5769, 'value': 'GD Goenka University', 'label': 'GD Goenka University' },
    { 'id': 10249, 'value': 'Gdanski Uniwersytet Medyczny', 'label': 'Gdanski Uniwersytet Medyczny' },
    { 'id': 11168, 'value': 'GEA College', 'label': 'GEA College' },
    { 'id': 11690, 'value': 'Gebze Teknik Üniversitesi', 'label': 'Gebze Teknik Üniversitesi' },
    { 'id': 14410, 'value': 'Geeks Academy', 'label': 'Geeks Academy' },
    { 'id': 5770, 'value': 'Geetanjali University', 'label': 'Geetanjali University' },
    { 'id': 8598, 'value': 'Gegee College', 'label': 'Gegee College' },
    { 'id': 12712, 'value': 'Geisinger Commonwealth School of Medicine', 'label': 'Geisinger Commonwealth School of Medicine' },
    { 'id': 1141, 'value': 'Gence Dövlet Universiteti', 'label': 'Gence Dövlet Universiteti' },
    { 'id': 8334, 'value': 'Generolo Jono Žemaicio Lietuvos karo akademija', 'label': 'Generolo Jono Žemaicio Lietuvos karo akademija' },
    { 'id': 567, 'value': 'Geneva Academy of International Humanitarian Law and Human Rights', 'label': 'Geneva Academy of International Humanitarian Law and Human Rights' },
    { 'id': 12713, 'value': 'Geneva College', 'label': 'Geneva College' },
    { 'id': 3531, 'value': 'Georg-August-Universität Göttingen', 'label': 'Georg-August-Universität Göttingen' },
    { 'id': 12714, 'value': 'George Fox University', 'label': 'George Fox University' },
    { 'id': 12715, 'value': 'George Mason University', 'label': 'George Mason University' },
    { 'id': 12716, 'value': 'George Washington University', 'label': 'George Washington University' },
    { 'id': 4790, 'value': 'Georgetown American University', 'label': 'Georgetown American University' },
    { 'id': 12717, 'value': 'Georgetown College', 'label': 'Georgetown College' },
    { 'id': 186, 'value': 'Georgetown University', 'label': 'Georgetown University' },
    { 'id': 10508, 'value': 'Georgetown University in Qatar', 'label': 'Georgetown University in Qatar' },
    { 'id': 12718, 'value': 'Georgia College and State University', 'label': 'Georgia College and State University' },
    { 'id': 12719, 'value': 'Georgia Gwinnett College', 'label': 'Georgia Gwinnett College' },
    { 'id': 12720, 'value': 'Georgia Institute of Technology', 'label': 'Georgia Institute of Technology' },
    { 'id': 12721, 'value': 'Georgia Southern University', 'label': 'Georgia Southern University' },
    { 'id': 12722, 'value': 'Georgia Southwestern State University', 'label': 'Georgia Southwestern State University' },
    { 'id': 12723, 'value': 'Georgia State University', 'label': 'Georgia State University' },
    { 'id': 14404, 'value': 'Georgia State University', 'label': 'Georgia State University' },
    { 'id': 4630, 'value': 'Georgian American University', 'label': 'Georgian American University' },
    { 'id': 4631, 'value': 'Georgian Aviation University', 'label': 'Georgian Aviation University' },
    { 'id': 12724, 'value': 'Georgian Court University', 'label': 'Georgian Court University' },
    { 'id': 4632, 'value': 'Georgian Institute of Public Affairs', 'label': 'Georgian Institute of Public Affairs' },
    { 'id': 4633, 'value': 'Georgian National Universty SEU', 'label': 'Georgian National Universty SEU' },
    { 'id': 4634, 'value': 'Georgian State Teaching University of Physical Education and Sport', 'label': 'Georgian State Teaching University of Physical Education and Sport' },
    { 'id': 4635, 'value': 'Georgian Technical University', 'label': 'Georgian Technical University' },
    { 'id': 3532, 'value': 'German Graduate School of Management and Law', 'label': 'German Graduate School of Management and Law' },
    { 'id': 7045, 'value': 'German Jordanian University', 'label': 'German Jordanian University' },
    { 'id': 1251, 'value': 'German University Bangladesh', 'label': 'German University Bangladesh' },
    { 'id': 9622, 'value': 'German University of Technology in Oman', 'label': 'German University of Technology in Oman' },
    { 'id': 564, 'value': 'Gerrit Rietveld Academie', 'label': 'Gerrit Rietveld Academie' },
    { 'id': 12725, 'value': 'Gettysburg College', 'label': 'Gettysburg College' },
    { 'id': 8015, 'value': 'Geumgang University', 'label': 'Geumgang University' },
    { 'id': 710, 'value': 'Ghalib University', 'label': 'Ghalib University' },
    { 'id': 4683, 'value': 'Ghana Baptist University College', 'label': 'Ghana Baptist University College' },
    { 'id': 4684, 'value': 'Ghana Christian University College', 'label': 'Ghana Christian University College' },
    { 'id': 4685, 'value': 'Ghana Institute of Journalism', 'label': 'Ghana Institute of Journalism' },
    { 'id': 4686, 'value': 'Ghana Institute of Languages', 'label': 'Ghana Institute of Languages' },
    { 'id': 4687, 'value': 'Ghana Institute of Management and Public Administration', 'label': 'Ghana Institute of Management and Public Administration' },
    { 'id': 4688, 'value': 'Ghana Technology University College', 'label': 'Ghana Technology University College' },
    { 'id': 711, 'value': 'Gharjistan University', 'label': 'Gharjistan University' },
    { 'id': 712, 'value': 'Ghazi Amanullah Khan Institute of Higher Education', 'label': 'Ghazi Amanullah Khan Institute of Higher Education' },
    { 'id': 10076, 'value': 'Ghazi University', 'label': 'Ghazi University' },
    { 'id': 713, 'value': 'Ghazni University', 'label': 'Ghazni University' },
    { 'id': 10077, 'value': 'Ghulam Ishaq Khan Institute of Engineering Sciences and Technology', 'label': 'Ghulam Ishaq Khan Institute of Engineering Sciences and Technology' },
    { 'id': 14261, 'value': 'Gideon Robert University', 'label': 'Gideon Robert University' },
    { 'id': 10078, 'value': 'GIFT University', 'label': 'GIFT University' },
    { 'id': 7160, 'value': 'Gifu College of Nursing', 'label': 'Gifu College of Nursing' },
    { 'id': 7161, 'value': 'Gifu Kyoritsu University', 'label': 'Gifu Kyoritsu University' },
    { 'id': 7162, 'value': 'Gifu Pharmaceutical University', 'label': 'Gifu Pharmaceutical University' },
    { 'id': 7163, 'value': 'Gifu Shotoku Gakuen University', 'label': 'Gifu Shotoku Gakuen University' },
    { 'id': 7164, 'value': 'Gifu University', 'label': 'Gifu University' },
    { 'id': 7165, 'value': 'Gifu University of Medical Science', 'label': 'Gifu University of Medical Science' },
    { 'id': 7166, 'value': 'Gifu Women\'s University', 'label': 'Gifu Women\'s University' },
    { 'id': 6532, 'value': 'Gilan University of Medical Sciences', 'label': 'Gilan University of Medical Sciences' },
    { 'id': 8016, 'value': 'Gimcheon University', 'label': 'Gimcheon University' },
    { 'id': 11691, 'value': 'Giresun Üniversitesi', 'label': 'Giresun Üniversitesi' },
    { 'id': 3358, 'value': 'Girne Amerikan Üniversitesi', 'label': 'Girne Amerikan Üniversitesi' },
    { 'id': 3359, 'value': 'Girne Üniversitesi', 'label': 'Girne Üniversitesi' },
    { 'id': 5771, 'value': 'GLA University', 'label': 'GLA University' },
    { 'id': 4515, 'value': 'Glasgow Caledonian University', 'label': 'Glasgow Caledonian University' },
    { 'id': 4516, 'value': 'Glasgow School of Art', 'label': 'Glasgow School of Art' },
    { 'id': 12726, 'value': 'Glenville State College', 'label': 'Glenville State College' },
    { 'id': 2002, 'value': 'Glion Institute of Higher Education', 'label': 'Glion Institute of Higher Education' },
    { 'id': 3360, 'value': 'Global College', 'label': 'Global College' },
    { 'id': 9623, 'value': 'Global College of Engineering and Technology', 'label': 'Global College of Engineering and Technology' },
    { 'id': 10079, 'value': 'Global Institute', 'label': 'Global Institute' },
    { 'id': 8599, 'value': 'Global Leadership University', 'label': 'Global Leadership University' },
    { 'id': 8283, 'value': 'Global University', 'label': 'Global University' },
    { 'id': 1252, 'value': 'Global University Bangladesh', 'label': 'Global University Bangladesh' },
    { 'id': 5772, 'value': 'Glocal University', 'label': 'Glocal University' },
    { 'id': 9370, 'value': 'Glorious Vision University', 'label': 'Glorious Vision University' },
    { 'id': 5773, 'value': 'GNA University', 'label': 'GNA University' },
    { 'id': 5774, 'value': 'Goa University', 'label': 'Goa University' },
    { 'id': 8476, 'value': 'Goce Delcev University of Štip', 'label': 'Goce Delcev University of Štip' },
    { 'id': 12728, 'value': 'God\'s Bible School and College', 'label': 'God\'s Bible School and College' },
    { 'id': 12727, 'value': 'Goddard College', 'label': 'Goddard College' },
    { 'id': 9371, 'value': 'Godfrey Okoye University', 'label': 'Godfrey Okoye University' },
    { 'id': 3533, 'value': 'Goethe-Universität Frankfurt am Main', 'label': 'Goethe-Universität Frankfurt am Main' },
    { 'id': 5775, 'value': 'Gokhale Institute of Politics and Economics', 'label': 'Gokhale Institute of Politics and Economics' },
    { 'id': 6533, 'value': 'Golbahar University of Science and New Technology', 'label': 'Golbahar University of Science and New Technology' },
    { 'id': 12729, 'value': 'Golden Gate University', 'label': 'Golden Gate University' },
    { 'id': 12730, 'value': 'Goldey-Beacom College', 'label': 'Goldey-Beacom College' },
    { 'id': 118, 'value': 'Goldsmiths University of London', 'label': 'Goldsmiths University of London' },
    { 'id': 4517, 'value': 'Goldsmiths, University of London', 'label': 'Goldsmiths, University of London' },
    { 'id': 6534, 'value': 'Golestan University', 'label': 'Golestan University' },
    { 'id': 6535, 'value': 'Golestan University of Medical Sciences', 'label': 'Golestan University of Medical Sciences' },
    { 'id': 6536, 'value': 'Golpayegan University of Technology', 'label': 'Golpayegan University of Technology' },
    { 'id': 10080, 'value': 'Gomal University', 'label': 'Gomal University' },
    { 'id': 9372, 'value': 'Gombe State University', 'label': 'Gombe State University' },
    { 'id': 9373, 'value': 'Gombe State University of Science and Technology', 'label': 'Gombe State University of Science and Technology' },
    { 'id': 1823, 'value': 'Gomel State Medical University', 'label': 'Gomel State Medical University' },
    { 'id': 1824, 'value': 'Gomel State University', 'label': 'Gomel State University' },
    { 'id': 6537, 'value': 'Gonabad University of Medical Sciences', 'label': 'Gonabad University of Medical Sciences' },
    { 'id': 6538, 'value': 'Gonbad Kavous University', 'label': 'Gonbad Kavous University' },
    { 'id': 5776, 'value': 'Gondwana University', 'label': 'Gondwana University' },
    { 'id': 8017, 'value': 'Gongju National University of Education', 'label': 'Gongju National University of Education' },
    { 'id': 1253, 'value': 'Gono Bishwabidyalay', 'label': 'Gono Bishwabidyalay' },
    { 'id': 12731, 'value': 'Gonzaga University', 'label': 'Gonzaga University' },
    { 'id': 12732, 'value': 'Good Samaritan College of Nursing and Health Science', 'label': 'Good Samaritan College of Nursing and Health Science' },
    { 'id': 12733, 'value': 'Goodwin College', 'label': 'Goodwin College' },
    { 'id': 12734, 'value': 'Gordon College', 'label': 'Gordon College' },
    { 'id': 12735, 'value': 'Gordon State College', 'label': 'Gordon State College' },
    { 'id': 6539, 'value': 'Gorgan University of Agricultural Sciences and Natural Resources', 'label': 'Gorgan University of Agricultural Sciences and Natural Resources' },
    { 'id': 4636, 'value': 'Gori State Teaching University', 'label': 'Gori State Teaching University' },
    { 'id': 821, 'value': 'Goris State University', 'label': 'Goris State University' },
    { 'id': 10677, 'value': 'Gorno-Altaisk State University', 'label': 'Gorno-Altaisk State University' },
    { 'id': 10250, 'value': 'Górnoslaska Wyzsza Szkola Handlowa im. Wojciecha Korfantego w Katowicach', 'label': 'Górnoslaska Wyzsza Szkola Handlowa im. Wojciecha Korfantego w Katowicach' },
    { 'id': 10678, 'value': 'Gorsky State Agrarian University', 'label': 'Gorsky State Agrarian University' },
    { 'id': 12736, 'value': 'Goshen College', 'label': 'Goshen College' },
    { 'id': 11113, 'value': 'Göteborgs universitet', 'label': 'Göteborgs universitet' },
    { 'id': 12737, 'value': 'Goucher College', 'label': 'Goucher College' },
    { 'id': 10081, 'value': 'Government College for Women University, Sialkot', 'label': 'Government College for Women University, Sialkot' },
    { 'id': 10082, 'value': 'Government College University, Faisalabad', 'label': 'Government College University, Faisalabad' },
    { 'id': 10083, 'value': 'Government College University, Lahore', 'label': 'Government College University, Lahore' },
    { 'id': 10084, 'value': 'Government College Women University, Faisalabad', 'label': 'Government College Women University, Faisalabad' },
    { 'id': 12738, 'value': 'Governors State University', 'label': 'Governors State University' },
    { 'id': 5777, 'value': 'Govind Ballabh Pant University of Agriculture and Technology', 'label': 'Govind Ballabh Pant University of Agriculture and Technology' },
    { 'id': 5778, 'value': 'Govind Guru Tribal University', 'label': 'Govind Guru Tribal University' },
    { 'id': 12739, 'value': 'Grace Christian University', 'label': 'Grace Christian University' },
    { 'id': 12740, 'value': 'Grace College', 'label': 'Grace College' },
    { 'id': 12741, 'value': 'Graceland University', 'label': 'Graceland University' },
    { 'id': 7167, 'value': 'Graduate Institute for Entrepreneurial Studies', 'label': 'Graduate Institute for Entrepreneurial Studies' },
    { 'id': 493, 'value': 'Graduate Institute of Geneva - IHEID', 'label': 'Graduate Institute of Geneva - IHEID' },
    { 'id': 7168, 'value': 'Graduate School of Management, Globis University', 'label': 'Graduate School of Management, Globis University' },
    { 'id': 1825, 'value': 'Graduate School of the National Academy of Sciences of Belarus', 'label': 'Graduate School of the National Academy of Sciences of Belarus' },
    { 'id': 6540, 'value': 'Graduate University of Advanced Technology', 'label': 'Graduate University of Advanced Technology' },
    { 'id': 8335, 'value': 'Graiciuno aukštoji vadybos mokykla', 'label': 'Graiciuno aukštoji vadybos mokykla' },
    { 'id': 12742, 'value': 'Grambling State University', 'label': 'Grambling State University' },
    { 'id': 6867, 'value': 'Gran Sasso Science Institute - Scuola di dottorato internazionale dell\'Aquila', 'label': 'Gran Sasso Science Institute - Scuola di dottorato internazionale dell\'Aquila' },
    { 'id': 12743, 'value': 'Grand Canyon University', 'label': 'Grand Canyon University' },
    { 'id': 12744, 'value': 'Grand Valley State University', 'label': 'Grand Valley State University' },
    { 'id': 12745, 'value': 'Grand View University', 'label': 'Grand View University' },
    { 'id': 12746, 'value': 'Granite State College', 'label': 'Granite State College' },
    { 'id': 5779, 'value': 'Graphic Era Hill University', 'label': 'Graphic Era Hill University' },
    { 'id': 5780, 'value': 'Graphic Era University', 'label': 'Graphic Era University' },
    { 'id': 12747, 'value': 'Gratz College', 'label': 'Gratz College' },
    { 'id': 12748, 'value': 'Great Basin College', 'label': 'Great Basin College' },
    { 'id': 12749, 'value': 'Great Lakes Christian College', 'label': 'Great Lakes Christian College' },
    { 'id': 7789, 'value': 'Great Lakes University of Kisumu', 'label': 'Great Lakes University of Kisumu' },
    { 'id': 8600, 'value': 'Great Mongol Institute', 'label': 'Great Mongol Institute' },
    { 'id': 14305, 'value': 'Great Zimbabwe University', 'label': 'Great Zimbabwe University' },
    { 'id': 1254, 'value': 'Green University of Bangladesh', 'label': 'Green University of Bangladesh' },
    { 'id': 4791, 'value': 'GreenHeart Medical University', 'label': 'GreenHeart Medical University' },
    { 'id': 12750, 'value': 'Greensboro College', 'label': 'Greensboro College' },
    { 'id': 12751, 'value': 'Greenville University', 'label': 'Greenville University' },
    { 'id': 10085, 'value': 'Greenwich University', 'label': 'Greenwich University' },
    { 'id': 9374, 'value': 'Gregory University, Uturu', 'label': 'Gregory University, Uturu' },
    { 'id': 215, 'value': 'Grenoble École de management', 'label': 'Grenoble École de management' },
    { 'id': 4355, 'value': 'Grenoble INP', 'label': 'Grenoble INP' },
    { 'id': 7790, 'value': 'Gretsa University', 'label': 'Gretsa University' },
    { 'id': 1081, 'value': 'Griffith University', 'label': 'Griffith University' },
    { 'id': 4637, 'value': 'Grigol Robakidze University', 'label': 'Grigol Robakidze University' },
    { 'id': 822, 'value': 'Grigor Lusavorich University of Echmiadzin', 'label': 'Grigor Lusavorich University of Echmiadzin' },
    { 'id': 12752, 'value': 'Grinnell College', 'label': 'Grinnell College' },
    { 'id': 1826, 'value': 'Grodno State Agrarian University', 'label': 'Grodno State Agrarian University' },
    { 'id': 1827, 'value': 'Grodno State Medical University', 'label': 'Grodno State Medical University' },
    { 'id': 4356, 'value': 'Groupe ISA', 'label': 'Groupe ISA' },
    { 'id': 12753, 'value': 'Grove City College', 'label': 'Grove City College' },
    { 'id': 5781, 'value': 'GSFC University', 'label': 'GSFC University' },
    { 'id': 9888, 'value': 'Guagua National Colleges', 'label': 'Guagua National Colleges' },
    { 'id': 2298, 'value': 'Guang Dong Polytechnic College', 'label': 'Guang Dong Polytechnic College' },
    { 'id': 2299, 'value': 'Guangdong Baiyun University', 'label': 'Guangdong Baiyun University' },
    { 'id': 2300, 'value': 'Guangdong Medical University', 'label': 'Guangdong Medical University' },
    { 'id': 2301, 'value': 'Guangdong Ocean University', 'label': 'Guangdong Ocean University' },
    { 'id': 2302, 'value': 'Guangdong Peizheng College', 'label': 'Guangdong Peizheng College' },
    { 'id': 2303, 'value': 'Guangdong Pharmaceutical University', 'label': 'Guangdong Pharmaceutical University' },
    { 'id': 2304, 'value': 'Guangdong Polytechnic Normal University', 'label': 'Guangdong Polytechnic Normal University' },
    { 'id': 2305, 'value': 'Guangdong Technion Israel Institute of Technology', 'label': 'Guangdong Technion Israel Institute of Technology' },
    { 'id': 2306, 'value': 'Guangdong University of Education', 'label': 'Guangdong University of Education' },
    { 'id': 2307, 'value': 'Guangdong University of Finance', 'label': 'Guangdong University of Finance' },
    { 'id': 2308, 'value': 'Guangdong University of Finance and Economics', 'label': 'Guangdong University of Finance and Economics' },
    { 'id': 2309, 'value': 'Guangdong University of Foreign Studies', 'label': 'Guangdong University of Foreign Studies' },
    { 'id': 2310, 'value': 'Guangdong University of Petrochemical Technology', 'label': 'Guangdong University of Petrochemical Technology' },
    { 'id': 2311, 'value': 'Guangdong University of Science and Technology', 'label': 'Guangdong University of Science and Technology' },
    { 'id': 2312, 'value': 'Guangdong University of Technology', 'label': 'Guangdong University of Technology' },
    { 'id': 2313, 'value': 'Guangxi Arts University', 'label': 'Guangxi Arts University' },
    { 'id': 2314, 'value': 'Guangxi Medical University', 'label': 'Guangxi Medical University' },
    { 'id': 2315, 'value': 'Guangxi Normal University', 'label': 'Guangxi Normal University' },
    { 'id': 2316, 'value': 'Guangxi Normal University for Nationalities', 'label': 'Guangxi Normal University for Nationalities' },
    { 'id': 2317, 'value': 'Guangxi Science and Technology Normal University', 'label': 'Guangxi Science and Technology Normal University' },
    { 'id': 2318, 'value': 'Guangxi Teachers Education University', 'label': 'Guangxi Teachers Education University' },
    { 'id': 2319, 'value': 'Guangxi University', 'label': 'Guangxi University' },
    { 'id': 2320, 'value': 'Guangxi University for Nationalities', 'label': 'Guangxi University for Nationalities' },
    { 'id': 2321, 'value': 'Guangxi University of Chinese Medicine', 'label': 'Guangxi University of Chinese Medicine' },
    { 'id': 2322, 'value': 'Guangxi University of Finance and Economics', 'label': 'Guangxi University of Finance and Economics' },
    { 'id': 2323, 'value': 'Guangxi University of Foreign Languages', 'label': 'Guangxi University of Foreign Languages' },
    { 'id': 2324, 'value': 'Guangxi University of Science and Technology', 'label': 'Guangxi University of Science and Technology' },
    { 'id': 2325, 'value': 'Guangzhou Academy of Fine Arts', 'label': 'Guangzhou Academy of Fine Arts' },
    { 'id': 2326, 'value': 'Guangzhou College of Commerce', 'label': 'Guangzhou College of Commerce' },
    { 'id': 2327, 'value': 'Guangzhou Maritime Institute', 'label': 'Guangzhou Maritime Institute' },
    { 'id': 2328, 'value': 'Guangzhou Medical University', 'label': 'Guangzhou Medical University' },
    { 'id': 2329, 'value': 'Guangzhou Sport University', 'label': 'Guangzhou Sport University' },
    { 'id': 2330, 'value': 'Guangzhou University', 'label': 'Guangzhou University' },
    { 'id': 2331, 'value': 'Guangzhou University of Chinese Medicine', 'label': 'Guangzhou University of Chinese Medicine' },
    { 'id': 154, 'value': 'guildhall school of music and drama', 'label': 'guildhall school of music and drama' },
    { 'id': 12754, 'value': 'Guilford College', 'label': 'Guilford College' },
    { 'id': 2332, 'value': 'Guilin Medical University', 'label': 'Guilin Medical University' },
    { 'id': 2333, 'value': 'Guilin Tourism University', 'label': 'Guilin Tourism University' },
    { 'id': 2334, 'value': 'Guilin University of Aerospace Technology', 'label': 'Guilin University of Aerospace Technology' },
    { 'id': 2335, 'value': 'Guilin University of Electronic Technology', 'label': 'Guilin University of Electronic Technology' },
    { 'id': 2336, 'value': 'Guilin University of Technology', 'label': 'Guilin University of Technology' },
    { 'id': 9889, 'value': 'Guimaras State College', 'label': 'Guimaras State College' },
    { 'id': 2337, 'value': 'Guiyang College of Traditional Chinese Medicine', 'label': 'Guiyang College of Traditional Chinese Medicine' },
    { 'id': 2338, 'value': 'Guiyang University', 'label': 'Guiyang University' },
    { 'id': 2339, 'value': 'Guizhou Education University', 'label': 'Guizhou Education University' },
    { 'id': 2340, 'value': 'Guizhou Institute of Technology', 'label': 'Guizhou Institute of Technology' },
    { 'id': 2341, 'value': 'Guizhou Medical University', 'label': 'Guizhou Medical University' },
    { 'id': 2342, 'value': 'Guizhou Minzu University', 'label': 'Guizhou Minzu University' },
    { 'id': 2343, 'value': 'Guizhou Normal University', 'label': 'Guizhou Normal University' },
    { 'id': 2344, 'value': 'Guizhou University', 'label': 'Guizhou University' },
    { 'id': 2345, 'value': 'Guizhou University of Commerce', 'label': 'Guizhou University of Commerce' },
    { 'id': 2346, 'value': 'Guizhou University of Engineering Science', 'label': 'Guizhou University of Engineering Science' },
    { 'id': 2347, 'value': 'Guizhou University of Finance and Economics', 'label': 'Guizhou University of Finance and Economics' },
    { 'id': 5782, 'value': 'Gujarat Ayurved University', 'label': 'Gujarat Ayurved University' },
    { 'id': 5783, 'value': 'Gujarat Forensic Sciences University', 'label': 'Gujarat Forensic Sciences University' },
    { 'id': 5784, 'value': 'Gujarat National Law University', 'label': 'Gujarat National Law University' },
    { 'id': 5785, 'value': 'Gujarat Technological University', 'label': 'Gujarat Technological University' },
    { 'id': 5786, 'value': 'Gujarat University', 'label': 'Gujarat University' },
    { 'id': 5787, 'value': 'Gujarat University of Transplantation Sciences', 'label': 'Gujarat University of Transplantation Sciences' },
    { 'id': 5788, 'value': 'Gujarat Vidyapith', 'label': 'Gujarat Vidyapith' },
    { 'id': 5789, 'value': 'Gulbarga University', 'label': 'Gulbarga University' },
    { 'id': 12755, 'value': 'Gulf Coast State College', 'label': 'Gulf Coast State College' },
    { 'id': 9624, 'value': 'Gulf College', 'label': 'Gulf College' },
    { 'id': 642, 'value': 'Gulf Medical University', 'label': 'Gulf Medical University' },
    { 'id': 1473, 'value': 'Gulf University', 'label': 'Gulf University' },
    { 'id': 8157, 'value': 'Gulf University for Science and Technology', 'label': 'Gulf University for Science and Technology' },
    { 'id': 13992, 'value': 'Guliston Davlat Universiteti', 'label': 'Guliston Davlat Universiteti' },
    { 'id': 12170, 'value': 'Gulu University', 'label': 'Gulu University' },
    { 'id': 7169, 'value': 'Gumma Paz College', 'label': 'Gumma Paz College' },
    { 'id': 11692, 'value': 'Gümüshane Üniversitesi', 'label': 'Gümüshane Üniversitesi' },
    { 'id': 7170, 'value': 'Gunma Prefectural College of Health Sciences', 'label': 'Gunma Prefectural College of Health Sciences' },
    { 'id': 7171, 'value': 'Gunma Prefectural Women\'s University', 'label': 'Gunma Prefectural Women\'s University' },
    { 'id': 7172, 'value': 'Gunma University', 'label': 'Gunma University' },
    { 'id': 7173, 'value': 'Gunma University of Social Welfare', 'label': 'Gunma University of Social Welfare' },
    { 'id': 4638, 'value': 'Guram Tavartkiladze Teaching University', 'label': 'Guram Tavartkiladze Teaching University' },
    { 'id': 5790, 'value': 'Guru Angad Dev Veterinary and Animal Sciences University', 'label': 'Guru Angad Dev Veterinary and Animal Sciences University' },
    { 'id': 5791, 'value': 'Guru Ghasidas Vishwavidyalaya', 'label': 'Guru Ghasidas Vishwavidyalaya' },
    { 'id': 5792, 'value': 'Guru Gobind Singh Indraprastha University', 'label': 'Guru Gobind Singh Indraprastha University' },
    { 'id': 5793, 'value': 'Guru Jambheshwar University of Science and Technology', 'label': 'Guru Jambheshwar University of Science and Technology' },
    { 'id': 5794, 'value': 'Guru Kashi University', 'label': 'Guru Kashi University' },
    { 'id': 5795, 'value': 'Guru Nanak Dev University', 'label': 'Guru Nanak Dev University' },
    { 'id': 5796, 'value': 'Guru Ravidas Ayurved University', 'label': 'Guru Ravidas Ayurved University' },
    { 'id': 5797, 'value': 'Gurukul Kangri Vishwavidyalaya', 'label': 'Gurukul Kangri Vishwavidyalaya' },
    { 'id': 8601, 'value': 'Gurvan Erdene Institute of Pedagogy', 'label': 'Gurvan Erdene Institute of Pedagogy' },
    { 'id': 8602, 'value': 'Gurvan Tamir College', 'label': 'Gurvan Tamir College' },
    { 'id': 1023, 'value': 'Gustav Mahler Privatuniversität für Musik', 'label': 'Gustav Mahler Privatuniversität für Musik' },
    { 'id': 12756, 'value': 'Gustavus Adolphus College', 'label': 'Gustavus Adolphus College' },
    { 'id': 14306, 'value': 'Gwanda State University', 'label': 'Gwanda State University' },
    { 'id': 8018, 'value': 'Gwangju Catholic University', 'label': 'Gwangju Catholic University' },
    { 'id': 8019, 'value': 'Gwangju National University of Education', 'label': 'Gwangju National University of Education' },
    { 'id': 8020, 'value': 'Gwangju University', 'label': 'Gwangju University' },
    { 'id': 12757, 'value': 'Gwynedd Mercy University', 'label': 'Gwynedd Mercy University' },
    { 'id': 8021, 'value': 'Gyeongin National University of Education', 'label': 'Gyeongin National University of Education' },
    { 'id': 8022, 'value': 'Gyeongju University', 'label': 'Gyeongju University' },
    { 'id': 8023, 'value': 'Gyeongnam National University of Science and Technology', 'label': 'Gyeongnam National University of Science and Technology' },
    { 'id': 8024, 'value': 'Gyeongsang National University', 'label': 'Gyeongsang National University' },
    { 'id': 11114, 'value': 'Gymnastik- och idrottshögskolan', 'label': 'Gymnastik- och idrottshögskolan' },
    { 'id': 4211, 'value': 'HAAGA-HELIA ammattikorkeakoulu', 'label': 'HAAGA-HELIA ammattikorkeakoulu' },
    { 'id': 14343, 'value': 'Habacus University', 'label': 'Habacus University' },
    { 'id': 10086, 'value': 'Habib University', 'label': 'Habib University' },
    { 'id': 11693, 'value': 'Hacettepe Üniversitesi', 'label': 'Hacettepe Üniversitesi' },
    { 'id': 7174, 'value': 'Hachinohe Institute of Technology', 'label': 'Hachinohe Institute of Technology' },
    { 'id': 7175, 'value': 'Hachinohe University', 'label': 'Hachinohe University' },
    { 'id': 5538, 'value': 'Hadassah Academic College', 'label': 'Hadassah Academic College' },
    { 'id': 14195, 'value': 'Hadhramout University', 'label': 'Hadhramout University' },
    { 'id': 3534, 'value': 'HafenCity Universität Hamburg', 'label': 'HafenCity Universität Hamburg' },
    { 'id': 7176, 'value': 'Hagoromo University of International Studies', 'label': 'Hagoromo University of International Studies' },
    { 'id': 14119, 'value': 'Hai Phong Medical University', 'label': 'Hai Phong Medical University' },
    { 'id': 8284, 'value': 'Haigazian University', 'label': 'Haigazian University' },
    { 'id': 2348, 'value': 'Haikou College of Economics', 'label': 'Haikou College of Economics' },
    { 'id': 2349, 'value': 'Hainan Medical University', 'label': 'Hainan Medical University' },
    { 'id': 2350, 'value': 'Hainan Normal University', 'label': 'Hainan Normal University' },
    { 'id': 2351, 'value': 'Hainan Tropical Ocean University', 'label': 'Hainan Tropical Ocean University' },
    { 'id': 2352, 'value': 'Hainan University', 'label': 'Hainan University' },
    { 'id': 1255, 'value': 'Hajee Mohammad Danesh Science and Technology University', 'label': 'Hajee Mohammad Danesh Science and Technology University' },
    { 'id': 14196, 'value': 'Hajjah University', 'label': 'Hajjah University' },
    { 'id': 10087, 'value': 'Hajvery University', 'label': 'Hajvery University' },
    { 'id': 6541, 'value': 'Hakim Sabzevari University', 'label': 'Hakim Sabzevari University' },
    { 'id': 714, 'value': 'Hakim Sanayee Institute of Higher Education', 'label': 'Hakim Sanayee Institute of Higher Education' },
    { 'id': 11694, 'value': 'Hakkari Üniversitesi', 'label': 'Hakkari Üniversitesi' },
    { 'id': 7177, 'value': 'Hakodate University', 'label': 'Hakodate University' },
    { 'id': 7178, 'value': 'Hakuoh University', 'label': 'Hakuoh University' },
    { 'id': 4096, 'value': 'Halhale College of Business and Economics', 'label': 'Halhale College of Business and Economics' },
    { 'id': 11695, 'value': 'Haliç Üniversitesi', 'label': 'Haliç Üniversitesi' },
    { 'id': 8025, 'value': 'Halla University', 'label': 'Halla University' },
    { 'id': 9375, 'value': 'Hallmark University, Ijebu-Itele', 'label': 'Hallmark University, Ijebu-Itele' },
    { 'id': 8026, 'value': 'Hallym University', 'label': 'Hallym University' },
    { 'id': 10509, 'value': 'Hamad Bin Khalifa University', 'label': 'Hamad Bin Khalifa University' },
    { 'id': 6542, 'value': 'Hamadan University of Medical Sciences', 'label': 'Hamadan University of Medical Sciences' },
    { 'id': 6543, 'value': 'Hamadan University of Technology', 'label': 'Hamadan University of Technology' },
    { 'id': 7179, 'value': 'Hamamatsu Gakuin University', 'label': 'Hamamatsu Gakuin University' },
    { 'id': 7180, 'value': 'Hamamatsu University School of Medicine', 'label': 'Hamamatsu University School of Medicine' },
    { 'id': 3535, 'value': 'Hamburg School of Business Administration', 'label': 'Hamburg School of Business Administration' },
    { 'id': 10088, 'value': 'Hamdard University', 'label': 'Hamdard University' },
    { 'id': 1256, 'value': 'Hamdard University of Bangladesh', 'label': 'Hamdard University of Bangladesh' },
    { 'id': 4212, 'value': 'Hämeen ammattikorkeakoulu', 'label': 'Hämeen ammattikorkeakoulu' },
    { 'id': 4097, 'value': 'Hamelmalo College of Agriculture', 'label': 'Hamelmalo College of Agriculture' },
    { 'id': 7944, 'value': 'Hamhung University of Chemical Industry', 'label': 'Hamhung University of Chemical Industry' },
    { 'id': 7945, 'value': 'Hamhung University of Education', 'label': 'Hamhung University of Education' },
    { 'id': 7946, 'value': 'Hamhung University of Mathematical and Physical Sciences', 'label': 'Hamhung University of Mathematical and Physical Sciences' },
    { 'id': 12758, 'value': 'Hamilton College', 'label': 'Hamilton College' },
    { 'id': 12759, 'value': 'Hamline University', 'label': 'Hamline University' },
    { 'id': 12760, 'value': 'Hampden-Sydney College', 'label': 'Hampden-Sydney College' },
    { 'id': 12761, 'value': 'Hampshire College', 'label': 'Hampshire College' },
    { 'id': 12762, 'value': 'Hampton University', 'label': 'Hampton University' },
    { 'id': 7181, 'value': 'Hanazono University', 'label': 'Hanazono University' },
    { 'id': 8027, 'value': 'Hanbat National University', 'label': 'Hanbat National University' },
    { 'id': 2353, 'value': 'Handan College', 'label': 'Handan College' },
    { 'id': 11115, 'value': 'Handelshögskolan i Stockholm', 'label': 'Handelshögskolan i Stockholm' },
    { 'id': 3831, 'value': 'Handelshøjskolen i København', 'label': 'Handelshøjskolen i København' },
    { 'id': 9565, 'value': 'Handelshøyskolen BI', 'label': 'Handelshøyskolen BI' },
    { 'id': 8028, 'value': 'Handong Global University', 'label': 'Handong Global University' },
    { 'id': 2354, 'value': 'Hangzhou Dianzi University', 'label': 'Hangzhou Dianzi University' },
    { 'id': 2355, 'value': 'Hangzhou Medical College', 'label': 'Hangzhou Medical College' },
    { 'id': 2356, 'value': 'Hangzhou Normal University', 'label': 'Hangzhou Normal University' },
    { 'id': 2357, 'value': 'Hanjiang Normal University', 'label': 'Hanjiang Normal University' },
    { 'id': 4213, 'value': 'Hanken Svenska handelshögskolan', 'label': 'Hanken Svenska handelshögskolan' },
    { 'id': 2358, 'value': 'Hankou University', 'label': 'Hankou University' },
    { 'id': 8029, 'value': 'Hankuk University of Foreign Studies', 'label': 'Hankuk University of Foreign Studies' },
    { 'id': 8030, 'value': 'Hankyong National University', 'label': 'Hankyong National University' },
    { 'id': 8031, 'value': 'Hanlyo University', 'label': 'Hanlyo University' },
    { 'id': 8032, 'value': 'Hannam University', 'label': 'Hannam University' },
    { 'id': 7182, 'value': 'Hannan University', 'label': 'Hannan University' },
    { 'id': 12763, 'value': 'Hannibal-LaGrange University', 'label': 'Hannibal-LaGrange University' },
    { 'id': 14120, 'value': 'Hanoi Architectural University', 'label': 'Hanoi Architectural University' },
    { 'id': 14121, 'value': 'Hanoi Industrial Textile Garnment University', 'label': 'Hanoi Industrial Textile Garnment University' },
    { 'id': 14122, 'value': 'Hanoi Law University', 'label': 'Hanoi Law University' },
    { 'id': 14123, 'value': 'Hanoi Medical University', 'label': 'Hanoi Medical University' },
    { 'id': 14124, 'value': 'Hanoi National University of Education', 'label': 'Hanoi National University of Education' },
    { 'id': 14125, 'value': 'Hanoi University', 'label': 'Hanoi University' },
    { 'id': 14126, 'value': 'Hanoi University of Business and Technology', 'label': 'Hanoi University of Business and Technology' },
    { 'id': 14127, 'value': 'Hanoi University of Culture', 'label': 'Hanoi University of Culture' },
    { 'id': 14128, 'value': 'Hanoi University of Industrial Fine Arts', 'label': 'Hanoi University of Industrial Fine Arts' },
    { 'id': 14129, 'value': 'Hanoi University of Industry', 'label': 'Hanoi University of Industry' },
    { 'id': 14130, 'value': 'Hanoi University of Mining and Geology', 'label': 'Hanoi University of Mining and Geology' },
    { 'id': 14131, 'value': 'Hanoi University of Pharmacy', 'label': 'Hanoi University of Pharmacy' },
    { 'id': 14132, 'value': 'Hanoi University of Science and Technology', 'label': 'Hanoi University of Science and Technology' },
    { 'id': 12764, 'value': 'Hanover College', 'label': 'Hanover College' },
    { 'id': 8033, 'value': 'Hansei University', 'label': 'Hansei University' },
    { 'id': 8034, 'value': 'Hanseo University', 'label': 'Hanseo University' },
    { 'id': 2359, 'value': 'Hanshan Normal University', 'label': 'Hanshan Normal University' },
    { 'id': 8035, 'value': 'Hanshin University', 'label': 'Hanshin University' },
    { 'id': 8036, 'value': 'Hansung University', 'label': 'Hansung University' },
    { 'id': 8037, 'value': 'Hanyang University', 'label': 'Hanyang University' },
    { 'id': 73, 'value': 'Hanze University', 'label': 'Hanze University' },
    { 'id': 9522, 'value': 'Hanzehogeschool Groningen', 'label': 'Hanzehogeschool Groningen' },
    { 'id': 4188, 'value': 'Haramaya University', 'label': 'Haramaya University' },
    { 'id': 14307, 'value': 'Harare Institute of Technology', 'label': 'Harare Institute of Technology' },
    { 'id': 2360, 'value': 'Harbin Cambridge University', 'label': 'Harbin Cambridge University' },
    { 'id': 2361, 'value': 'Harbin Conservatory of Music', 'label': 'Harbin Conservatory of Music' },
    { 'id': 2362, 'value': 'Harbin Engineering University', 'label': 'Harbin Engineering University' },
    { 'id': 2363, 'value': 'Harbin Far East Institute of Technology', 'label': 'Harbin Far East Institute of Technology' },
    { 'id': 2364, 'value': 'Harbin Finance University', 'label': 'Harbin Finance University' },
    { 'id': 2365, 'value': 'Harbin Guangxia University', 'label': 'Harbin Guangxia University' },
    { 'id': 2366, 'value': 'Harbin Huade University', 'label': 'Harbin Huade University' },
    { 'id': 2367, 'value': 'Harbin Institute of Information Technology', 'label': 'Harbin Institute of Information Technology' },
    { 'id': 2368, 'value': 'Harbin Institute of Petroleum', 'label': 'Harbin Institute of Petroleum' },
    { 'id': 2369, 'value': 'Harbin Institute of Physical Education', 'label': 'Harbin Institute of Physical Education' },
    { 'id': 2370, 'value': 'Harbin Institute of Technology', 'label': 'Harbin Institute of Technology' },
    { 'id': 2371, 'value': 'Harbin Medical University', 'label': 'Harbin Medical University' },
    { 'id': 2372, 'value': 'Harbin Normal University', 'label': 'Harbin Normal University' },
    { 'id': 2373, 'value': 'Harbin University', 'label': 'Harbin University' },
    { 'id': 2374, 'value': 'Harbin University of Commerce', 'label': 'Harbin University of Commerce' },
    { 'id': 2375, 'value': 'Harbin University of Science and Technology', 'label': 'Harbin University of Science and Technology' },
    { 'id': 5798, 'value': 'Harcourt Butler Technical University', 'label': 'Harcourt Butler Technical University' },
    { 'id': 12766, 'value': 'Hardin-Simmons University', 'label': 'Hardin-Simmons University' },
    { 'id': 12765, 'value': 'Harding University', 'label': 'Harding University' },
    { 'id': 5799, 'value': 'Haridev Joshi University of Journalism and Mass Communication', 'label': 'Haridev Joshi University of Journalism and Mass Communication' },
    { 'id': 4742, 'value': 'Harokopio University', 'label': 'Harokopio University' },
    { 'id': 4518, 'value': 'Harper Adams University', 'label': 'Harper Adams University' },
    { 'id': 11696, 'value': 'Harran Üniversitesi', 'label': 'Harran Üniversitesi' },
    { 'id': 12768, 'value': 'Harris-Stowe State University', 'label': 'Harris-Stowe State University' },
    { 'id': 12767, 'value': 'Harrisburg University of Science and Technology', 'label': 'Harrisburg University of Science and Technology' },
    { 'id': 12769, 'value': 'Hartwick College', 'label': 'Hartwick College' },
    { 'id': 542, 'value': 'Harvard Business School', 'label': 'Harvard Business School' },
    { 'id': 144, 'value': 'Harvard Kennedy School', 'label': 'Harvard Kennedy School' },
    { 'id': 12770, 'value': 'Harvard University', 'label': 'Harvard University' },
    { 'id': 14262, 'value': 'Harvest University', 'label': 'Harvest University' },
    { 'id': 12771, 'value': 'Harvey Mudd College', 'label': 'Harvey Mudd College' },
    { 'id': 9523, 'value': 'HAS Hogeschool', 'label': 'HAS Hogeschool' },
    { 'id': 11697, 'value': 'Hasan Kalyoncu Üniversitesi', 'label': 'Hasan Kalyoncu Üniversitesi' },
    { 'id': 6811, 'value': 'Háskóli Íslands', 'label': 'Háskóli Íslands' },
    { 'id': 6812, 'value': 'Háskólinn á Akureyri', 'label': 'Háskólinn á Akureyri' },
    { 'id': 6813, 'value': 'Háskólinn á Bifröst', 'label': 'Háskólinn á Bifröst' },
    { 'id': 6814, 'value': 'Háskólinn á Hólum', 'label': 'Háskólinn á Hólum' },
    { 'id': 6815, 'value': 'Háskólinn í Reykjavík', 'label': 'Háskólinn í Reykjavík' },
    { 'id': 12772, 'value': 'Hastings College', 'label': 'Hastings College' },
    { 'id': 11698, 'value': 'Hatay Mustafa Kemal Üniversitesi', 'label': 'Hatay Mustafa Kemal Üniversitesi' },
    { 'id': 11399, 'value': 'Hatyai University', 'label': 'Hatyai University' },
    { 'id': 1354, 'value': 'Haute École Albert Jacquard', 'label': 'Haute École Albert Jacquard' },
    { 'id': 1355, 'value': 'Haute École Bruxelles-Brabant', 'label': 'Haute École Bruxelles-Brabant' },
    { 'id': 1356, 'value': 'Haute École Charlemagne', 'label': 'Haute École Charlemagne' },
    { 'id': 1509, 'value': 'Haute École de Commerce et de Management', 'label': 'Haute École de Commerce et de Management' },
    { 'id': 1357, 'value': 'Haute École de la Communauté française en Hainaut', 'label': 'Haute École de la Communauté française en Hainaut' },
    { 'id': 1358, 'value': 'Haute École de la Province de Liège', 'label': 'Haute École de la Province de Liège' },
    { 'id': 1359, 'value': 'Haute École de la Province de Namur', 'label': 'Haute École de la Province de Namur' },
    { 'id': 1360, 'value': 'Haute École de la Ville de Liège', 'label': 'Haute École de la Ville de Liège' },
    { 'id': 1361, 'value': 'Haute École de Namur-Liège-Luxembourg', 'label': 'Haute École de Namur-Liège-Luxembourg' },
    { 'id': 1362, 'value': 'Haute École EPHEC', 'label': 'Haute École EPHEC' },
    { 'id': 1363, 'value': 'Haute École Francisco Ferrer', 'label': 'Haute École Francisco Ferrer' },
    { 'id': 1364, 'value': 'Haute École Galilée', 'label': 'Haute École Galilée' },
    { 'id': 1365, 'value': 'Haute École Léonard de Vinci', 'label': 'Haute École Léonard de Vinci' },
    { 'id': 1366, 'value': 'Haute École Libre de Bruxelles Ilya Prigogine', 'label': 'Haute École Libre de Bruxelles Ilya Prigogine' },
    { 'id': 1367, 'value': 'Haute École Libre Mosane', 'label': 'Haute École Libre Mosane' },
    { 'id': 1368, 'value': 'Haute École Louvain en Hainaut', 'label': 'Haute École Louvain en Hainaut' },
    { 'id': 1369, 'value': 'Haute École Lucia de Brouckère', 'label': 'Haute École Lucia de Brouckère' },
    { 'id': 1370, 'value': 'Haute École Provinciale de Hainaut-Condorcet', 'label': 'Haute École Provinciale de Hainaut-Condorcet' },
    { 'id': 1371, 'value': 'Haute École Robert Schuman', 'label': 'Haute École Robert Schuman' },
    { 'id': 2003, 'value': 'Haute École Spécialisée de Suisse Occidentale', 'label': 'Haute École Spécialisée de Suisse Occidentale' },
    { 'id': 4357, 'value': 'Hautes Études d\'Ingénieur', 'label': 'Hautes Études d\'Ingénieur' },
    { 'id': 12773, 'value': 'Haverford College', 'label': 'Haverford College' },
    { 'id': 12774, 'value': 'Hawaii Pacific University', 'label': 'Hawaii Pacific University' },
    { 'id': 4189, 'value': 'Hawassa University', 'label': 'Hawassa University' },
    { 'id': 3536, 'value': 'HAWK Hochschule Hildesheim/Holzminden/Göttingen', 'label': 'HAWK Hochschule Hildesheim/Holzminden/Göttingen' },
    { 'id': 6451, 'value': 'Hawler Medical University', 'label': 'Hawler Medical University' },
    { 'id': 823, 'value': 'Haybusak University of Yerevan', 'label': 'Haybusak University of Yerevan' },
    { 'id': 10089, 'value': 'Hazara University', 'label': 'Hazara University' },
    { 'id': 6544, 'value': 'Hazrat-e Masoumeh University', 'label': 'Hazrat-e Masoumeh University' },
    { 'id': 6545, 'value': 'Hazrat-e Narjes University of Rafsanjan', 'label': 'Hazrat-e Narjes University of Rafsanjan' },
    { 'id': 2376, 'value': 'He University', 'label': 'He University' },
    { 'id': 7183, 'value': 'Health Science University', 'label': 'Health Science University' },
    { 'id': 7184, 'value': 'Health Sciences University of Hokkaido', 'label': 'Health Sciences University of Hokkaido' },
    { 'id': 2377, 'value': 'Hebei Academy of Fine Arts', 'label': 'Hebei Academy of Fine Arts' },
    { 'id': 2378, 'value': 'Hebei College of Science and Technology', 'label': 'Hebei College of Science and Technology' },
    { 'id': 2379, 'value': 'Hebei Finance University', 'label': 'Hebei Finance University' },
    { 'id': 2380, 'value': 'Hebei Foreign Studies University', 'label': 'Hebei Foreign Studies University' },
    { 'id': 2381, 'value': 'Hebei GEO University', 'label': 'Hebei GEO University' },
    { 'id': 2382, 'value': 'Hebei Institute of Communication', 'label': 'Hebei Institute of Communication' },
    { 'id': 2383, 'value': 'Hebei Institute of Physical Education', 'label': 'Hebei Institute of Physical Education' },
    { 'id': 2384, 'value': 'Hebei Medical University', 'label': 'Hebei Medical University' },
    { 'id': 2385, 'value': 'Hebei Normal University', 'label': 'Hebei Normal University' },
    { 'id': 2386, 'value': 'Hebei Normal University for Nationalities', 'label': 'Hebei Normal University for Nationalities' },
    { 'id': 2387, 'value': 'Hebei Normal University of Science and Technology', 'label': 'Hebei Normal University of Science and Technology' },
    { 'id': 2388, 'value': 'Hebei North University', 'label': 'Hebei North University' },
    { 'id': 2389, 'value': 'Hebei Oriental University', 'label': 'Hebei Oriental University' },
    { 'id': 2390, 'value': 'Hebei Polytechnic Institute', 'label': 'Hebei Polytechnic Institute' },
    { 'id': 2391, 'value': 'Hebei University', 'label': 'Hebei University' },
    { 'id': 2392, 'value': 'Hebei University of Architecture', 'label': 'Hebei University of Architecture' },
    { 'id': 2393, 'value': 'Hebei University of Chinese Medicine', 'label': 'Hebei University of Chinese Medicine' },
    { 'id': 2394, 'value': 'Hebei University of Economics and Business', 'label': 'Hebei University of Economics and Business' },
    { 'id': 2395, 'value': 'Hebei University of Engineering', 'label': 'Hebei University of Engineering' },
    { 'id': 2396, 'value': 'Hebei University of Environmental Engineering', 'label': 'Hebei University of Environmental Engineering' },
    { 'id': 2397, 'value': 'Hebei University of Science and Technology', 'label': 'Hebei University of Science and Technology' },
    { 'id': 2398, 'value': 'Hebei University of Technology', 'label': 'Hebei University of Technology' },
    { 'id': 12775, 'value': 'Hebrew College', 'label': 'Hebrew College' },
    { 'id': 5539, 'value': 'Hebrew University of Jerusalem', 'label': 'Hebrew University of Jerusalem' },
    { 'id': 10389, 'value': 'Hebron University', 'label': 'Hebron University' },
    { 'id': 606, 'value': 'HEC Lausanne - University of Lausanne', 'label': 'HEC Lausanne - University of Lausanne' },
    { 'id': 8417, 'value': 'HEC Maroc', 'label': 'HEC Maroc' },
    { 'id': 1872, 'value': 'HEC Montréal', 'label': 'HEC Montréal' },
    { 'id': 143, 'value': 'HEC Paris', 'label': 'HEC Paris' },
    { 'id': 2399, 'value': 'Hechi University', 'label': 'Hechi University' },
    { 'id': 2400, 'value': 'Hefei Normal University', 'label': 'Hefei Normal University' },
    { 'id': 2401, 'value': 'Hefei University', 'label': 'Hefei University' },
    { 'id': 2402, 'value': 'Hefei University of Technology', 'label': 'Hefei University of Technology' },
    { 'id': 7185, 'value': 'Heian Jogakuin University', 'label': 'Heian Jogakuin University' },
    { 'id': 400, 'value': 'Heidelberg University', 'label': 'Heidelberg University' },
    { 'id': 2403, 'value': 'Heihe University', 'label': 'Heihe University' },
    { 'id': 2404, 'value': 'Heilongjiang Bayi Agricultural University', 'label': 'Heilongjiang Bayi Agricultural University' },
    { 'id': 2405, 'value': 'Heilongjiang College of Business and Technology', 'label': 'Heilongjiang College of Business and Technology' },
    { 'id': 2406, 'value': 'Heilongjiang Institute of Technology', 'label': 'Heilongjiang Institute of Technology' },
    { 'id': 2407, 'value': 'Heilongjiang International University', 'label': 'Heilongjiang International University' },
    { 'id': 2408, 'value': 'Heilongjiang University', 'label': 'Heilongjiang University' },
    { 'id': 2409, 'value': 'Heilongjiang University of Chinese Medicine', 'label': 'Heilongjiang University of Chinese Medicine' },
    { 'id': 2410, 'value': 'Heilongjiang University of Finance and Economics', 'label': 'Heilongjiang University of Finance and Economics' },
    { 'id': 2411, 'value': 'Heilongjiang University of Science and Technology', 'label': 'Heilongjiang University of Science and Technology' },
    { 'id': 2412, 'value': 'Heilongjiang University of Technology', 'label': 'Heilongjiang University of Technology' },
    { 'id': 494, 'value': 'Heinrich Heine Universitat', 'label': 'Heinrich Heine Universitat' },
    { 'id': 3537, 'value': 'Heinrich-Heine-Universität Düsseldorf', 'label': 'Heinrich-Heine-Universität Düsseldorf' },
    { 'id': 7186, 'value': 'Heisei College of Music', 'label': 'Heisei College of Music' },
    { 'id': 7187, 'value': 'Heisei International University', 'label': 'Heisei International University' },
    { 'id': 12776, 'value': 'Helene Fuld College of Nursing', 'label': 'Helene Fuld College of Nursing' },
    { 'id': 4061, 'value': 'Heliopolis University', 'label': 'Heliopolis University' },
    { 'id': 4743, 'value': 'Hellenic Naval Academy', 'label': 'Hellenic Naval Academy' },
    { 'id': 715, 'value': 'Helmand University', 'label': 'Helmand University' },
    { 'id': 3538, 'value': 'Helmut-Schmidt-Universität', 'label': 'Helmut-Schmidt-Universität' },
    { 'id': 9240, 'value': 'HELP University', 'label': 'HELP University' },
    { 'id': 4214, 'value': 'Helsingin yliopisto', 'label': 'Helsingin yliopisto' },
    { 'id': 4062, 'value': 'Helwan University', 'label': 'Helwan University' },
    { 'id': 8418, 'value': 'HEM Institut des Hautes Etudes de Management', 'label': 'HEM Institut des Hautes Etudes de Management' },
    { 'id': 5800, 'value': 'Hemchandracharya North Gujarat University', 'label': 'Hemchandracharya North Gujarat University' },
    { 'id': 5801, 'value': 'Hemwati Nandan Bahuguna Garhwal University', 'label': 'Hemwati Nandan Bahuguna Garhwal University' },
    { 'id': 5802, 'value': 'Hemwati Nandan Bahuguna Medical University', 'label': 'Hemwati Nandan Bahuguna Medical University' },
    { 'id': 2413, 'value': 'Henan Agricultural University', 'label': 'Henan Agricultural University' },
    { 'id': 2414, 'value': 'Henan Finance University', 'label': 'Henan Finance University' },
    { 'id': 2415, 'value': 'Henan Institute of Engineering', 'label': 'Henan Institute of Engineering' },
    { 'id': 2416, 'value': 'Henan Institute of Science and Technology', 'label': 'Henan Institute of Science and Technology' },
    { 'id': 2417, 'value': 'Henan Institute of Technology', 'label': 'Henan Institute of Technology' },
    { 'id': 2418, 'value': 'Henan Normal University', 'label': 'Henan Normal University' },
    { 'id': 2419, 'value': 'Henan Polytechnic University', 'label': 'Henan Polytechnic University' },
    { 'id': 2420, 'value': 'Henan University', 'label': 'Henan University' },
    { 'id': 2421, 'value': 'Henan University of Animal Husbandry and Economy', 'label': 'Henan University of Animal Husbandry and Economy' },
    { 'id': 2422, 'value': 'Henan University of Economics and Law', 'label': 'Henan University of Economics and Law' },
    { 'id': 2423, 'value': 'Henan University of Science and Technology', 'label': 'Henan University of Science and Technology' },
    { 'id': 2424, 'value': 'Henan University of Technology', 'label': 'Henan University of Technology' },
    { 'id': 2425, 'value': 'Henan University of Traditional Chinese Medicine', 'label': 'Henan University of Traditional Chinese Medicine' },
    { 'id': 2426, 'value': 'Henan University of Urban Construction', 'label': 'Henan University of Urban Construction' },
    { 'id': 12777, 'value': 'Henderson State University', 'label': 'Henderson State University' },
    { 'id': 12778, 'value': 'Hendrix College', 'label': 'Hendrix College' },
    { 'id': 2427, 'value': 'Hengshui University', 'label': 'Hengshui University' },
    { 'id': 2428, 'value': 'Hengyang Normal University', 'label': 'Hengyang Normal University' },
    { 'id': 716, 'value': 'Herat University', 'label': 'Herat University' },
    { 'id': 117, 'value': 'Heriot Watt University', 'label': 'Heriot Watt University' },
    { 'id': 4519, 'value': 'Heriot-Watt University', 'label': 'Heriot-Watt University' },
    { 'id': 12779, 'value': 'Heritage Christian University', 'label': 'Heritage Christian University' },
    { 'id': 12780, 'value': 'Heritage University', 'label': 'Heritage University' },
    { 'id': 269, 'value': 'Hertie School of Governance', 'label': 'Hertie School of Governance' },
    { 'id': 10679, 'value': 'Herzen State Pedagogical University of Russia', 'label': 'Herzen State Pedagogical University of Russia' },
    { 'id': 12781, 'value': 'Herzing University', 'label': 'Herzing University' },
    { 'id': 2429, 'value': 'Hetao College', 'label': 'Hetao College' },
    { 'id': 2430, 'value': 'Hexi University', 'label': 'Hexi University' },
    { 'id': 4520, 'value': 'Heythrop College', 'label': 'Heythrop College' },
    { 'id': 2431, 'value': 'Heze University', 'label': 'Heze University' },
    { 'id': 9376, 'value': 'Hezekiah University', 'label': 'Hezekiah University' },
    { 'id': 2432, 'value': 'Hezhou University', 'label': 'Hezhou University' },
    { 'id': 3539, 'value': 'HFH Hamburger Fern-Hochschule', 'label': 'HFH Hamburger Fern-Hochschule' },
    { 'id': 3540, 'value': 'HHL', 'label': 'HHL' },
    { 'id': 5803, 'value': 'Hidayatullah National Law University', 'label': 'Hidayatullah National Law University' },
    { 'id': 7188, 'value': 'Higashi Nippon International University', 'label': 'Higashi Nippon International University' },
    { 'id': 7189, 'value': 'Higashiosaka College', 'label': 'Higashiosaka College' },
    { 'id': 12782, 'value': 'High Point University', 'label': 'High Point University' },
    { 'id': 8419, 'value': 'HIGH-TECH', 'label': 'HIGH-TECH' },
    { 'id': 9625, 'value': 'Higher College of Technology', 'label': 'Higher College of Technology' },
    { 'id': 643, 'value': 'Higher Colleges of Technology', 'label': 'Higher Colleges of Technology' },
    { 'id': 6546, 'value': 'Higher Education Complex of Larestan', 'label': 'Higher Education Complex of Larestan' },
    { 'id': 6547, 'value': 'Higher Education Complex of Saravan', 'label': 'Higher Education Complex of Saravan' },
    { 'id': 6548, 'value': 'Higher Education Complex of Torbat-e-Jam', 'label': 'Higher Education Complex of Torbat-e-Jam' },
    { 'id': 6549, 'value': 'Higher Education Complex of Zarand', 'label': 'Higher Education Complex of Zarand' },
    { 'id': 11344, 'value': 'Higher Institute of Business Administration', 'label': 'Higher Institute of Business Administration' },
    { 'id': 1430, 'value': 'Higher School of Telecommunications and Post', 'label': 'Higher School of Telecommunications and Post' },
    { 'id': 7190, 'value': 'Hijiyama University', 'label': 'Hijiyama University' },
    { 'id': 12783, 'value': 'Hilbert College', 'label': 'Hilbert College' },
    { 'id': 12784, 'value': 'Hillsdale College', 'label': 'Hillsdale College' },
    { 'id': 5804, 'value': 'Himachal Pradesh Technical University', 'label': 'Himachal Pradesh Technical University' },
    { 'id': 5805, 'value': 'Himachal Pradesh University', 'label': 'Himachal Pradesh University' },
    { 'id': 5806, 'value': 'Himalayan Garhwal University', 'label': 'Himalayan Garhwal University' },
    { 'id': 5807, 'value': 'Himalayan University', 'label': 'Himalayan University' },
    { 'id': 7191, 'value': 'Himeji Dokkyo University', 'label': 'Himeji Dokkyo University' },
    { 'id': 7192, 'value': 'Himeji University', 'label': 'Himeji University' },
    { 'id': 5808, 'value': 'Himgiri ZEE University', 'label': 'Himgiri ZEE University' },
    { 'id': 5809, 'value': 'Hindustan Institute of Technology and Science', 'label': 'Hindustan Institute of Technology and Science' },
    { 'id': 8505, 'value': 'Hinthada University', 'label': 'Hinthada University' },
    { 'id': 12785, 'value': 'Hiram College', 'label': 'Hiram College' },
    { 'id': 7193, 'value': 'Hirosaki Gakuin University', 'label': 'Hirosaki Gakuin University' },
    { 'id': 7194, 'value': 'Hirosaki University', 'label': 'Hirosaki University' },
    { 'id': 7195, 'value': 'Hiroshima Bunka Gakuen University', 'label': 'Hiroshima Bunka Gakuen University' },
    { 'id': 7196, 'value': 'Hiroshima Bunkyo Women\'s University', 'label': 'Hiroshima Bunkyo Women\'s University' },
    { 'id': 7197, 'value': 'Hiroshima City University', 'label': 'Hiroshima City University' },
    { 'id': 7198, 'value': 'Hiroshima Institute of Technology', 'label': 'Hiroshima Institute of Technology' },
    { 'id': 7199, 'value': 'Hiroshima International University', 'label': 'Hiroshima International University' },
    { 'id': 7200, 'value': 'Hiroshima Jogakuin University', 'label': 'Hiroshima Jogakuin University' },
    { 'id': 7201, 'value': 'Hiroshima Kokusai Gakuin University', 'label': 'Hiroshima Kokusai Gakuin University' },
    { 'id': 7202, 'value': 'Hiroshima Shudo University', 'label': 'Hiroshima Shudo University' },
    { 'id': 7203, 'value': 'Hiroshima University', 'label': 'Hiroshima University' },
    { 'id': 7204, 'value': 'Hiroshima University of Economics', 'label': 'Hiroshima University of Economics' },
    { 'id': 10090, 'value': 'HITEC University', 'label': 'HITEC University' },
    { 'id': 11699, 'value': 'Hitit Üniversitesi', 'label': 'Hitit Üniversitesi' },
    { 'id': 7205, 'value': 'Hitotsubashi University', 'label': 'Hitotsubashi University' },
    { 'id': 12008, 'value': 'Hlukhiv National Pedagogical University', 'label': 'Hlukhiv National Pedagogical University' },
    { 'id': 3541, 'value': 'HMKW Hochschule für Medien, Kommunikation und Wirtschaft', 'label': 'HMKW Hochschule für Medien, Kommunikation und Wirtschaft' },
    { 'id': 14133, 'value': 'Ho Chi Minh City International University', 'label': 'Ho Chi Minh City International University' },
    { 'id': 14134, 'value': 'Ho Chi Minh City University of Agriculture and Forestry', 'label': 'Ho Chi Minh City University of Agriculture and Forestry' },
    { 'id': 14135, 'value': 'Ho Chi Minh City University of Architecture', 'label': 'Ho Chi Minh City University of Architecture' },
    { 'id': 14136, 'value': 'Ho Chi Minh City University of Education', 'label': 'Ho Chi Minh City University of Education' },
    { 'id': 14137, 'value': 'Ho Chi Minh City University of Foreign Languages and Information Technology', 'label': 'Ho Chi Minh City University of Foreign Languages and Information Technology' },
    { 'id': 14138, 'value': 'Ho Chi Minh City University of Information Technology', 'label': 'Ho Chi Minh City University of Information Technology' },
    { 'id': 14139, 'value': 'Ho Chi Minh City University of Law', 'label': 'Ho Chi Minh City University of Law' },
    { 'id': 14140, 'value': 'Ho Chi Minh City University of Social Sciences and Humanities', 'label': 'Ho Chi Minh City University of Social Sciences and Humanities' },
    { 'id': 14141, 'value': 'Ho Chi Minh City University of Technology', 'label': 'Ho Chi Minh City University of Technology' },
    { 'id': 14142, 'value': 'Ho Chi Minh City University of Technology and Education', 'label': 'Ho Chi Minh City University of Technology and Education' },
    { 'id': 14143, 'value': 'Ho Chi Minh City University of Transport', 'label': 'Ho Chi Minh City University of Transport' },
    { 'id': 4689, 'value': 'Ho Technical University', 'label': 'Ho Technical University' },
    { 'id': 12786, 'value': 'Hobart and William Smith Colleges', 'label': 'Hobart and William Smith Colleges' },
    { 'id': 3542, 'value': 'hochschule 21', 'label': 'hochschule 21' },
    { 'id': 3543, 'value': 'Hochschule Aalen', 'label': 'Hochschule Aalen' },
    { 'id': 3544, 'value': 'Hochschule Albstadt-Sigmaringen', 'label': 'Hochschule Albstadt-Sigmaringen' },
    { 'id': 3545, 'value': 'Hochschule Anhalt', 'label': 'Hochschule Anhalt' },
    { 'id': 3546, 'value': 'Hochschule Ansbach', 'label': 'Hochschule Ansbach' },
    { 'id': 3547, 'value': 'Hochschule Aschaffenburg', 'label': 'Hochschule Aschaffenburg' },
    { 'id': 3548, 'value': 'Hochschule Augsburg', 'label': 'Hochschule Augsburg' },
    { 'id': 3549, 'value': 'Hochschule Biberach', 'label': 'Hochschule Biberach' },
    { 'id': 3550, 'value': 'Hochschule Bochum', 'label': 'Hochschule Bochum' },
    { 'id': 3551, 'value': 'Hochschule Bonn-Rhein-Sieg', 'label': 'Hochschule Bonn-Rhein-Sieg' },
    { 'id': 3552, 'value': 'Hochschule Bremen', 'label': 'Hochschule Bremen' },
    { 'id': 3553, 'value': 'Hochschule Bremerhaven', 'label': 'Hochschule Bremerhaven' },
    { 'id': 3554, 'value': 'Hochschule Coburg', 'label': 'Hochschule Coburg' },
    { 'id': 3555, 'value': 'Hochschule Darmstadt', 'label': 'Hochschule Darmstadt' },
    { 'id': 3556, 'value': 'Hochschule der Bildenden Künste Saar', 'label': 'Hochschule der Bildenden Künste Saar' },
    { 'id': 3557, 'value': 'Hochschule der Bundesagentur für Arbeit', 'label': 'Hochschule der Bundesagentur für Arbeit' },
    { 'id': 3558, 'value': 'Hochschule der Deutschen Bundesbank', 'label': 'Hochschule der Deutschen Bundesbank' },
    { 'id': 3559, 'value': 'Hochschule der Medien', 'label': 'Hochschule der Medien' },
    { 'id': 3560, 'value': 'Hochschule der Sparkassen-Finanzgruppe', 'label': 'Hochschule der Sparkassen-Finanzgruppe' },
    { 'id': 3561, 'value': 'Hochschule der Wirtschaft für Management', 'label': 'Hochschule der Wirtschaft für Management' },
    { 'id': 3562, 'value': 'Hochschule des Bundes für öffentliche Verwaltung', 'label': 'Hochschule des Bundes für öffentliche Verwaltung' },
    { 'id': 3563, 'value': 'Hochschule Düsseldorf', 'label': 'Hochschule Düsseldorf' },
    { 'id': 3564, 'value': 'Hochschule Emden/Leer', 'label': 'Hochschule Emden/Leer' },
    { 'id': 3565, 'value': 'Hochschule Esslingen', 'label': 'Hochschule Esslingen' },
    { 'id': 3566, 'value': 'Hochschule Flensburg', 'label': 'Hochschule Flensburg' },
    { 'id': 3567, 'value': 'Hochschule Fresenius', 'label': 'Hochschule Fresenius' },
    { 'id': 548, 'value': 'Hochschule Fresenius München - University of applied sciences', 'label': 'Hochschule Fresenius München - University of applied sciences' },
    { 'id': 3568, 'value': 'Hochschule Fulda', 'label': 'Hochschule Fulda' },
    { 'id': 1024, 'value': 'Hochschule für Agrar- und Umweltpädagogik Wien', 'label': 'Hochschule für Agrar- und Umweltpädagogik Wien' },
    { 'id': 3569, 'value': 'Hochschule für Angewandte Wissenschaften Hamburg', 'label': 'Hochschule für Angewandte Wissenschaften Hamburg' },
    { 'id': 3570, 'value': 'Hochschule für angewandte Wissenschaften München', 'label': 'Hochschule für angewandte Wissenschaften München' },
    { 'id': 3571, 'value': 'Hochschule für angewandte Wissenschaften Würzburg-Schweinfurt', 'label': 'Hochschule für angewandte Wissenschaften Würzburg-Schweinfurt' },
    { 'id': 3572, 'value': 'Hochschule für angewandtes Management', 'label': 'Hochschule für angewandtes Management' },
    { 'id': 3573, 'value': 'Hochschule für Bildende Künste Braunschweig', 'label': 'Hochschule für Bildende Künste Braunschweig' },
    { 'id': 3574, 'value': 'Hochschule für Bildende Künste Dresden', 'label': 'Hochschule für Bildende Künste Dresden' },
    { 'id': 3575, 'value': 'Hochschule für Bildende Künste Hamburg', 'label': 'Hochschule für Bildende Künste Hamburg' },
    { 'id': 3576, 'value': 'Hochschule für Bildende Künste-Städelschule', 'label': 'Hochschule für Bildende Künste-Städelschule' },
    { 'id': 3577, 'value': 'Hochschule für Evangelische Kirchenmusik Bayreuth', 'label': 'Hochschule für Evangelische Kirchenmusik Bayreuth' },
    { 'id': 3578, 'value': 'Hochschule für Fernsehen und Film München', 'label': 'Hochschule für Fernsehen und Film München' },
    { 'id': 3579, 'value': 'Hochschule für Finanzen und Landesfinanzschule', 'label': 'Hochschule für Finanzen und Landesfinanzschule' },
    { 'id': 3580, 'value': 'Hochschule für Forstwirtschaft Rottenburg', 'label': 'Hochschule für Forstwirtschaft Rottenburg' },
    { 'id': 3581, 'value': 'Hochschule für Gestaltung Offenbach am Main', 'label': 'Hochschule für Gestaltung Offenbach am Main' },
    { 'id': 3582, 'value': 'Hochschule für Gestaltung Schwäbisch Gmünd', 'label': 'Hochschule für Gestaltung Schwäbisch Gmünd' },
    { 'id': 3583, 'value': 'Hochschule für Gesundheit', 'label': 'Hochschule für Gesundheit' },
    { 'id': 3584, 'value': 'Hochschule für Grafik und Buchkunst Leipzig', 'label': 'Hochschule für Grafik und Buchkunst Leipzig' },
    { 'id': 3585, 'value': 'Hochschule für Jüdische Studien Heidelberg', 'label': 'Hochschule für Jüdische Studien Heidelberg' },
    { 'id': 3586, 'value': 'Hochschule für Katholische Kirchenmusik und Musikpädagogik', 'label': 'Hochschule für Katholische Kirchenmusik und Musikpädagogik' },
    { 'id': 3587, 'value': 'Hochschule für Kirchenmusik Herford-Witten', 'label': 'Hochschule für Kirchenmusik Herford-Witten' },
    { 'id': 3588, 'value': 'Hochschule für Künste Bremen', 'label': 'Hochschule für Künste Bremen' },
    { 'id': 3589, 'value': 'Hochschule für Künste im Sozialen Ottersberg', 'label': 'Hochschule für Künste im Sozialen Ottersberg' },
    { 'id': 3590, 'value': 'Hochschule für Musik Carl Maria von Weber Dresden', 'label': 'Hochschule für Musik Carl Maria von Weber Dresden' },
    { 'id': 3591, 'value': 'Hochschule für Musik Detmold', 'label': 'Hochschule für Musik Detmold' },
    { 'id': 3592, 'value': 'Hochschule für Musik Franz Liszt Weimar', 'label': 'Hochschule für Musik Franz Liszt Weimar' },
    { 'id': 3593, 'value': 'Hochschule für Musik Freiburg', 'label': 'Hochschule für Musik Freiburg' },
    { 'id': 3594, 'value': 'Hochschule für Musik Hanns Eisler', 'label': 'Hochschule für Musik Hanns Eisler' },
    { 'id': 3595, 'value': 'Hochschule für Musik Karlsruhe', 'label': 'Hochschule für Musik Karlsruhe' },
    { 'id': 3596, 'value': 'Hochschule für Musik Nürnberg', 'label': 'Hochschule für Musik Nürnberg' },
    { 'id': 3597, 'value': 'Hochschule für Musik Saar', 'label': 'Hochschule für Musik Saar' },
    { 'id': 3598, 'value': 'Hochschule für Musik und Darstellende Kunst Frankfurt am Main', 'label': 'Hochschule für Musik und Darstellende Kunst Frankfurt am Main' },
    { 'id': 3599, 'value': 'Hochschule für Musik und Tanz Köln', 'label': 'Hochschule für Musik und Tanz Köln' },
    { 'id': 3600, 'value': 'Hochschule für Musik und Theater Hamburg', 'label': 'Hochschule für Musik und Theater Hamburg' },
    { 'id': 3601, 'value': 'Hochschule für Musik und Theater Leipzig', 'label': 'Hochschule für Musik und Theater Leipzig' },
    { 'id': 3602, 'value': 'Hochschule für Musik und Theater München', 'label': 'Hochschule für Musik und Theater München' },
    { 'id': 3603, 'value': 'Hochschule für Musik und Theater Rostock', 'label': 'Hochschule für Musik und Theater Rostock' },
    { 'id': 3604, 'value': 'Hochschule für Musik Würzburg', 'label': 'Hochschule für Musik Würzburg' },
    { 'id': 3605, 'value': 'Hochschule für Musik, Theater und Medien Hannover', 'label': 'Hochschule für Musik, Theater und Medien Hannover' },
    { 'id': 3606, 'value': 'Hochschule für Nachhaltige Entwicklung Eberswalde', 'label': 'Hochschule für Nachhaltige Entwicklung Eberswalde' },
    { 'id': 3607, 'value': 'Hochschule für Öffentliche Verwaltung Bremen', 'label': 'Hochschule für Öffentliche Verwaltung Bremen' },
    { 'id': 3608, 'value': 'Hochschule für öffentliche Verwaltung Kehl', 'label': 'Hochschule für öffentliche Verwaltung Kehl' },
    { 'id': 3609, 'value': 'Hochschule für öffentliche Verwaltung Rheinland-Pfalz', 'label': 'Hochschule für öffentliche Verwaltung Rheinland-Pfalz' },
    { 'id': 3610, 'value': 'Hochschule für öffentliche Verwaltung und Finanzen Ludwigsburg', 'label': 'Hochschule für öffentliche Verwaltung und Finanzen Ludwigsburg' },
    { 'id': 3611, 'value': 'Hochschule für Philosophie München', 'label': 'Hochschule für Philosophie München' },
    { 'id': 3612, 'value': 'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen', 'label': 'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen' },
    { 'id': 3613, 'value': 'Hochschule für Rechtspflege Schwetzingen', 'label': 'Hochschule für Rechtspflege Schwetzingen' },
    { 'id': 3614, 'value': 'Hochschule für Schauspielkunst Ernst Busch', 'label': 'Hochschule für Schauspielkunst Ernst Busch' },
    { 'id': 3615, 'value': 'Hochschule für Technik Stuttgart', 'label': 'Hochschule für Technik Stuttgart' },
    { 'id': 3616, 'value': 'Hochschule für Technik und Wirtschaft Berlin', 'label': 'Hochschule für Technik und Wirtschaft Berlin' },
    { 'id': 3617, 'value': 'Hochschule für Technik und Wirtschaft des Saarlandes', 'label': 'Hochschule für Technik und Wirtschaft des Saarlandes' },
    { 'id': 3618, 'value': 'Hochschule für Technik und Wirtschaft Dresden', 'label': 'Hochschule für Technik und Wirtschaft Dresden' },
    { 'id': 3619, 'value': 'Hochschule für Technik, Wirtschaft und Kultur Leipzig', 'label': 'Hochschule für Technik, Wirtschaft und Kultur Leipzig' },
    { 'id': 3620, 'value': 'Hochschule für Telekommunikation Leipzig', 'label': 'Hochschule für Telekommunikation Leipzig' },
    { 'id': 3621, 'value': 'Hochschule für Wirtschaft und Gesellschaft Ludwigshafen', 'label': 'Hochschule für Wirtschaft und Gesellschaft Ludwigshafen' },
    { 'id': 591, 'value': 'Hochschule fur Wirtschaft und Recht Berlin', 'label': 'Hochschule fur Wirtschaft und Recht Berlin' },
    { 'id': 3622, 'value': 'Hochschule für Wirtschaft und Umwelt Nürtingen-Geislingen', 'label': 'Hochschule für Wirtschaft und Umwelt Nürtingen-Geislingen' },
    { 'id': 3623, 'value': 'Hochschule für Wirtschaft, Technik und Kultur', 'label': 'Hochschule für Wirtschaft, Technik und Kultur' },
    { 'id': 3624, 'value': 'Hochschule Geisenheim', 'label': 'Hochschule Geisenheim' },
    { 'id': 3625, 'value': 'Hochschule Hamm-Lippstadt', 'label': 'Hochschule Hamm-Lippstadt' },
    { 'id': 3626, 'value': 'Hochschule Hannover', 'label': 'Hochschule Hannover' },
    { 'id': 3627, 'value': 'Hochschule Harz', 'label': 'Hochschule Harz' },
    { 'id': 3628, 'value': 'Hochschule Heilbronn', 'label': 'Hochschule Heilbronn' },
    { 'id': 3629, 'value': 'Hochschule Hof', 'label': 'Hochschule Hof' },
    { 'id': 3630, 'value': 'Hochschule Kaiserslautern', 'label': 'Hochschule Kaiserslautern' },
    { 'id': 3631, 'value': 'Hochschule Karlsruhe - Technik und Wirtschaft', 'label': 'Hochschule Karlsruhe - Technik und Wirtschaft' },
    { 'id': 3632, 'value': 'Hochschule Kempten', 'label': 'Hochschule Kempten' },
    { 'id': 3633, 'value': 'Hochschule Koblenz', 'label': 'Hochschule Koblenz' },
    { 'id': 3634, 'value': 'Hochschule Konstanz', 'label': 'Hochschule Konstanz' },
    { 'id': 3635, 'value': 'Hochschule Landshut', 'label': 'Hochschule Landshut' },
    { 'id': 2004, 'value': 'Hochschule Luzern', 'label': 'Hochschule Luzern' },
    { 'id': 3636, 'value': 'Hochschule Macromedia', 'label': 'Hochschule Macromedia' },
    { 'id': 3637, 'value': 'Hochschule Magdeburg-Stendal', 'label': 'Hochschule Magdeburg-Stendal' },
    { 'id': 3638, 'value': 'Hochschule Mainz', 'label': 'Hochschule Mainz' },
    { 'id': 3639, 'value': 'Hochschule Mannheim', 'label': 'Hochschule Mannheim' },
    { 'id': 3640, 'value': 'Hochschule Merseburg', 'label': 'Hochschule Merseburg' },
    { 'id': 550, 'value': 'Hochschule Mittweida', 'label': 'Hochschule Mittweida' },
    { 'id': 3642, 'value': 'Hochschule Neu-Ulm', 'label': 'Hochschule Neu-Ulm' },
    { 'id': 3641, 'value': 'Hochschule Neubrandenburg', 'label': 'Hochschule Neubrandenburg' },
    { 'id': 3643, 'value': 'Hochschule Niederrhein', 'label': 'Hochschule Niederrhein' },
    { 'id': 3644, 'value': 'Hochschule Nordhausen', 'label': 'Hochschule Nordhausen' },
    { 'id': 3645, 'value': 'Hochschule Offenburg', 'label': 'Hochschule Offenburg' },
    { 'id': 3646, 'value': 'Hochschule Osnabrück', 'label': 'Hochschule Osnabrück' },
    { 'id': 3647, 'value': 'Hochschule Pforzheim', 'label': 'Hochschule Pforzheim' },
    { 'id': 3648, 'value': 'Hochschule Ravensburg-Weingarten', 'label': 'Hochschule Ravensburg-Weingarten' },
    { 'id': 3649, 'value': 'Hochschule Reutlingen', 'label': 'Hochschule Reutlingen' },
    { 'id': 3651, 'value': 'Hochschule Rhein-Waal', 'label': 'Hochschule Rhein-Waal' },
    { 'id': 3650, 'value': 'Hochschule RheinMain', 'label': 'Hochschule RheinMain' },
    { 'id': 3652, 'value': 'Hochschule Rosenheim', 'label': 'Hochschule Rosenheim' },
    { 'id': 3653, 'value': 'Hochschule Ruhr West', 'label': 'Hochschule Ruhr West' },
    { 'id': 3654, 'value': 'Hochschule Schmalkalden', 'label': 'Hochschule Schmalkalden' },
    { 'id': 3655, 'value': 'Hochschule Stralsund', 'label': 'Hochschule Stralsund' },
    { 'id': 3656, 'value': 'Hochschule Trier', 'label': 'Hochschule Trier' },
    { 'id': 3657, 'value': 'Hochschule Ulm', 'label': 'Hochschule Ulm' },
    { 'id': 3658, 'value': 'Hochschule Weihenstephan-Triesdorf', 'label': 'Hochschule Weihenstephan-Triesdorf' },
    { 'id': 3659, 'value': 'Hochschule Weserbergland', 'label': 'Hochschule Weserbergland' },
    { 'id': 3660, 'value': 'Hochschule Wismar', 'label': 'Hochschule Wismar' },
    { 'id': 3661, 'value': 'Hochschule Worms', 'label': 'Hochschule Worms' },
    { 'id': 3662, 'value': 'Hochschule Zittau/Görlitz', 'label': 'Hochschule Zittau/Görlitz' },
    { 'id': 3663, 'value': 'Hochuschule Furtwangen', 'label': 'Hochuschule Furtwangen' },
    { 'id': 14197, 'value': 'Hodeidah University', 'label': 'Hodeidah University' },
    { 'id': 12787, 'value': 'Hodges University', 'label': 'Hodges University' },
    { 'id': 12788, 'value': 'Hofstra University', 'label': 'Hofstra University' },
    { 'id': 1372, 'value': 'Hogere Zeevaartschool Antwerpen', 'label': 'Hogere Zeevaartschool Antwerpen' },
    { 'id': 9524, 'value': 'Hogeschool de Kempel', 'label': 'Hogeschool de Kempel' },
    { 'id': 1373, 'value': 'Hogeschool Gent', 'label': 'Hogeschool Gent' },
    { 'id': 9525, 'value': 'Hogeschool INHolland', 'label': 'Hogeschool INHolland' },
    { 'id': 9526, 'value': 'Hogeschool iPABO', 'label': 'Hogeschool iPABO' },
    { 'id': 9527, 'value': 'Hogeschool Leiden', 'label': 'Hogeschool Leiden' },
    { 'id': 1374, 'value': 'Hogeschool PXL', 'label': 'Hogeschool PXL' },
    { 'id': 9528, 'value': 'Hogeschool Rotterdam', 'label': 'Hogeschool Rotterdam' },
    { 'id': 9529, 'value': 'Hogeschool Saxion', 'label': 'Hogeschool Saxion' },
    { 'id': 9530, 'value': 'Hogeschool Utrecht', 'label': 'Hogeschool Utrecht' },
    { 'id': 9531, 'value': 'Hogeschool van Amsterdam', 'label': 'Hogeschool van Amsterdam' },
    { 'id': 9532, 'value': 'Hogeschool van Arnhem en Nijmegen', 'label': 'Hogeschool van Arnhem en Nijmegen' },
    { 'id': 9533, 'value': 'Hogeschool Viaa', 'label': 'Hogeschool Viaa' },
    { 'id': 9534, 'value': 'Hogeschool voor de Kunsten Utrecht', 'label': 'Hogeschool voor de Kunsten Utrecht' },
    { 'id': 1375, 'value': 'Hogeschool West-Vlaanderen', 'label': 'Hogeschool West-Vlaanderen' },
    { 'id': 9535, 'value': 'Hogeschool Windesheim', 'label': 'Hogeschool Windesheim' },
    { 'id': 9536, 'value': 'Hogeschool Zeeland', 'label': 'Hogeschool Zeeland' },
    { 'id': 11116, 'value': 'Högskolan Dalarna', 'label': 'Högskolan Dalarna' },
    { 'id': 11117, 'value': 'Högskolan i Borås', 'label': 'Högskolan i Borås' },
    { 'id': 11118, 'value': 'Högskolan i Gävle', 'label': 'Högskolan i Gävle' },
    { 'id': 11119, 'value': 'Högskolan i Halmstad', 'label': 'Högskolan i Halmstad' },
    { 'id': 11120, 'value': 'Högskolan i Skövde', 'label': 'Högskolan i Skövde' },
    { 'id': 11121, 'value': 'Högskolan Kristianstad', 'label': 'Högskolan Kristianstad' },
    { 'id': 4215, 'value': 'Högskolan på Åland', 'label': 'Högskolan på Åland' },
    { 'id': 11122, 'value': 'Högskolan Väst', 'label': 'Högskolan Väst' },
    { 'id': 9566, 'value': 'Høgskolen i Innlandet', 'label': 'Høgskolen i Innlandet' },
    { 'id': 9567, 'value': 'Høgskolen i Molde', 'label': 'Høgskolen i Molde' },
    { 'id': 9568, 'value': 'Høgskolen i Østfold', 'label': 'Høgskolen i Østfold' },
    { 'id': 9569, 'value': 'Høgskulen i Volda', 'label': 'Høgskulen i Volda' },
    { 'id': 9570, 'value': 'Høgskulen på Vestlandet', 'label': 'Høgskulen på Vestlandet' },
    { 'id': 2433, 'value': 'Hohai University', 'label': 'Hohai University' },
    { 'id': 7206, 'value': 'Hokkai School of Commerce', 'label': 'Hokkai School of Commerce' },
    { 'id': 7212, 'value': 'Hokkai-Gakuen University', 'label': 'Hokkai-Gakuen University' },
    { 'id': 7207, 'value': 'Hokkaido Bunkyo University', 'label': 'Hokkaido Bunkyo University' },
    { 'id': 7208, 'value': 'Hokkaido Information University', 'label': 'Hokkaido Information University' },
    { 'id': 7209, 'value': 'Hokkaido University', 'label': 'Hokkaido University' },
    { 'id': 7210, 'value': 'Hokkaido University of Education', 'label': 'Hokkaido University of Education' },
    { 'id': 7211, 'value': 'Hokkaido University of Science', 'label': 'Hokkaido University of Science' },
    { 'id': 7213, 'value': 'Hokuriku University', 'label': 'Hokuriku University' },
    { 'id': 7214, 'value': 'Hokusei Gakuen University', 'label': 'Hokusei Gakuen University' },
    { 'id': 7215, 'value': 'Hokusho University', 'label': 'Hokusho University' },
    { 'id': 12789, 'value': 'Hollins University', 'label': 'Hollins University' },
    { 'id': 5540, 'value': 'Holon Institute of Technology', 'label': 'Holon Institute of Technology' },
    { 'id': 9890, 'value': 'Holy Angel University', 'label': 'Holy Angel University' },
    { 'id': 12790, 'value': 'Holy Apostles College and Seminary', 'label': 'Holy Apostles College and Seminary' },
    { 'id': 8038, 'value': 'Holy City University', 'label': 'Holy City University' },
    { 'id': 12791, 'value': 'Holy Cross College', 'label': 'Holy Cross College' },
    { 'id': 9891, 'value': 'Holy Cross of Davao College', 'label': 'Holy Cross of Davao College' },
    { 'id': 12792, 'value': 'Holy Family College', 'label': 'Holy Family College' },
    { 'id': 12793, 'value': 'Holy Family University', 'label': 'Holy Family University' },
    { 'id': 9892, 'value': 'Holy Name University', 'label': 'Holy Name University' },
    { 'id': 12794, 'value': 'Holy Names University', 'label': 'Holy Names University' },
    { 'id': 9893, 'value': 'Holy Trinity University', 'label': 'Holy Trinity University' },
    { 'id': 5810, 'value': 'Homi Bhabha National Institute', 'label': 'Homi Bhabha National Institute' },
    { 'id': 8039, 'value': 'Honam University', 'label': 'Honam University' },
    { 'id': 14144, 'value': 'Hong Bang International University', 'label': 'Hong Bang International University' },
    { 'id': 14145, 'value': 'Hong Duc University', 'label': 'Hong Duc University' },
    { 'id': 4798, 'value': 'Hong Kong Baptist University', 'label': 'Hong Kong Baptist University' },
    { 'id': 4799, 'value': 'Hong Kong Shue Yan University', 'label': 'Hong Kong Shue Yan University' },
    { 'id': 2434, 'value': 'Honghe University', 'label': 'Honghe University' },
    { 'id': 8040, 'value': 'Hongik University', 'label': 'Hongik University' },
    { 'id': 12795, 'value': 'Hood College', 'label': 'Hood College' },
    { 'id': 12796, 'value': 'Hope College', 'label': 'Hope College' },
    { 'id': 12797, 'value': 'Hope International University', 'label': 'Hope International University' },
    { 'id': 6550, 'value': 'Hormozgan University', 'label': 'Hormozgan University' },
    { 'id': 6551, 'value': 'Hormozgan University of Medical Sciences', 'label': 'Hormozgan University of Medical Sciences' },
    { 'id': 7216, 'value': 'Hosei University', 'label': 'Hosei University' },
    { 'id': 8041, 'value': 'Hoseo University', 'label': 'Hoseo University' },
    { 'id': 7217, 'value': 'Hoshi University', 'label': 'Hoshi University' },
    { 'id': 9537, 'value': 'Hotelschool The Hague', 'label': 'Hotelschool The Hague' },
    { 'id': 12798, 'value': 'Houghton College', 'label': 'Houghton College' },
    { 'id': 12799, 'value': 'Houston Baptist University', 'label': 'Houston Baptist University' },
    { 'id': 12800, 'value': 'Howard Payne University', 'label': 'Howard Payne University' },
    { 'id': 12801, 'value': 'Howard University', 'label': 'Howard University' },
    { 'id': 8042, 'value': 'Howon University', 'label': 'Howon University' },
    { 'id': 9571, 'value': 'Høyskolen Kristiania', 'label': 'Høyskolen Kristiania' },
    { 'id': 8506, 'value': 'Hpaan University', 'label': 'Hpaan University' },
    { 'id': 4821, 'value': 'Hrvatsko katolicko sveucilište', 'label': 'Hrvatsko katolicko sveucilište' },
    { 'id': 11838, 'value': 'Hsing Wu University', 'label': 'Hsing Wu University' },
    { 'id': 11839, 'value': 'Hsiuping University of Science and Technology', 'label': 'Hsiuping University of Science and Technology' },
    { 'id': 11840, 'value': 'Hsuan Chuang University', 'label': 'Hsuan Chuang University' },
    { 'id': 11400, 'value': 'Huachiew Chalermprakiet University', 'label': 'Huachiew Chalermprakiet University' },
    { 'id': 11841, 'value': 'Huafan University', 'label': 'Huafan University' },
    { 'id': 2435, 'value': 'Huaibei Normal University', 'label': 'Huaibei Normal University' },
    { 'id': 2436, 'value': 'Huaihai Institute of Technology', 'label': 'Huaihai Institute of Technology' },
    { 'id': 2437, 'value': 'Huaihua University', 'label': 'Huaihua University' },
    { 'id': 2438, 'value': 'Huainan Normal University', 'label': 'Huainan Normal University' },
    { 'id': 2439, 'value': 'Huaiyin Institute of Technology', 'label': 'Huaiyin Institute of Technology' },
    { 'id': 2440, 'value': 'Huaiyin Normal University', 'label': 'Huaiyin Normal University' },
    { 'id': 2441, 'value': 'Huanggang Normal University', 'label': 'Huanggang Normal University' },
    { 'id': 2442, 'value': 'Huanghe Jiaotong University', 'label': 'Huanghe Jiaotong University' },
    { 'id': 2443, 'value': 'Huanghe Science and Technology College', 'label': 'Huanghe Science and Technology College' },
    { 'id': 2444, 'value': 'Huanghuai University', 'label': 'Huanghuai University' },
    { 'id': 2445, 'value': 'Huangshan University', 'label': 'Huangshan University' },
    { 'id': 2446, 'value': 'Huaqiao University', 'label': 'Huaqiao University' },
    { 'id': 2447, 'value': 'Huazhong Agricultural University', 'label': 'Huazhong Agricultural University' },
    { 'id': 2448, 'value': 'Huazhong University of Science and Technology', 'label': 'Huazhong University of Science and Technology' },
    { 'id': 2449, 'value': 'Hubei Business College', 'label': 'Hubei Business College' },
    { 'id': 2450, 'value': 'Hubei Engineering University', 'label': 'Hubei Engineering University' },
    { 'id': 2451, 'value': 'Hubei Institute of Fine Arts', 'label': 'Hubei Institute of Fine Arts' },
    { 'id': 2452, 'value': 'Hubei Normal University', 'label': 'Hubei Normal University' },
    { 'id': 2453, 'value': 'Hubei Polytechnic University', 'label': 'Hubei Polytechnic University' },
    { 'id': 2454, 'value': 'Hubei University', 'label': 'Hubei University' },
    { 'id': 2455, 'value': 'Hubei University for Nationalities', 'label': 'Hubei University for Nationalities' },
    { 'id': 2456, 'value': 'Hubei University of Arts and Science', 'label': 'Hubei University of Arts and Science' },
    { 'id': 2457, 'value': 'Hubei University of Automotive Technology', 'label': 'Hubei University of Automotive Technology' },
    { 'id': 2458, 'value': 'Hubei University of Chinese Medicine', 'label': 'Hubei University of Chinese Medicine' },
    { 'id': 2459, 'value': 'Hubei University of Economics', 'label': 'Hubei University of Economics' },
    { 'id': 2460, 'value': 'Hubei University of Education', 'label': 'Hubei University of Education' },
    { 'id': 2461, 'value': 'Hubei University of Medicine', 'label': 'Hubei University of Medicine' },
    { 'id': 2462, 'value': 'Hubei University of Science and Technology', 'label': 'Hubei University of Science and Technology' },
    { 'id': 2463, 'value': 'Hubei University of Technology', 'label': 'Hubei University of Technology' },
    { 'id': 11951, 'value': 'Hubert Kairuki Memorial University', 'label': 'Hubert Kairuki Memorial University' },
    { 'id': 11189, 'value': 'Hudobná a umelecká akadémia Jána Albrechta', 'label': 'Hudobná a umelecká akadémia Jána Albrechta' },
    { 'id': 14146, 'value': 'Hue University', 'label': 'Hue University' },
    { 'id': 7947, 'value': 'Huichon University of Telecommunications', 'label': 'Huichon University of Telecommunications' },
    { 'id': 2464, 'value': 'Huizhou University', 'label': 'Huizhou University' },
    { 'id': 12802, 'value': 'Hult International Business School', 'label': 'Hult International Business School' },
    { 'id': 396, 'value': 'Hult International Business School  (Boston)', 'label': 'Hult International Business School  (Boston)' },
    { 'id': 241, 'value': 'Hult International Business School (London)', 'label': 'Hult International Business School (London)' },
    { 'id': 2465, 'value': 'Hulunbeier University', 'label': 'Hulunbeier University' },
    { 'id': 7902, 'value': 'Human Resources University', 'label': 'Human Resources University' },
    { 'id': 4216, 'value': 'Humanistinen ammattikorkeakoulu', 'label': 'Humanistinen ammattikorkeakoulu' },
    { 'id': 824, 'value': 'Humanitarian Institute of Hrazdan', 'label': 'Humanitarian Institute of Hrazdan' },
    { 'id': 8190, 'value': 'Humanitarian-Technical Academy', 'label': 'Humanitarian-Technical Academy' },
    { 'id': 6827, 'value': 'Humanitas University', 'label': 'Humanitas University' },
    { 'id': 12803, 'value': 'Humboldt State University', 'label': 'Humboldt State University' },
    { 'id': 412, 'value': 'Humboldt Universitat zu Berlin', 'label': 'Humboldt Universitat zu Berlin' },
    { 'id': 3664, 'value': 'Humboldt-Universität zu Berlin', 'label': 'Humboldt-Universität zu Berlin' },
    { 'id': 12804, 'value': 'Humphreys University', 'label': 'Humphreys University' },
    { 'id': 2466, 'value': 'Hunan Agricultural University', 'label': 'Hunan Agricultural University' },
    { 'id': 2467, 'value': 'Hunan Applied Technology University', 'label': 'Hunan Applied Technology University' },
    { 'id': 2468, 'value': 'Hunan City University', 'label': 'Hunan City University' },
    { 'id': 2469, 'value': 'Hunan First Normal University', 'label': 'Hunan First Normal University' },
    { 'id': 2470, 'value': 'Hunan Institute of Engineering', 'label': 'Hunan Institute of Engineering' },
    { 'id': 2471, 'value': 'Hunan Institute of Information Technology', 'label': 'Hunan Institute of Information Technology' },
    { 'id': 2472, 'value': 'Hunan Institute of Science and Technology', 'label': 'Hunan Institute of Science and Technology' },
    { 'id': 2473, 'value': 'Hunan Institute of Technology', 'label': 'Hunan Institute of Technology' },
    { 'id': 2474, 'value': 'Hunan Institute of Traffic Engineering', 'label': 'Hunan Institute of Traffic Engineering' },
    { 'id': 2475, 'value': 'Hunan International Economics University', 'label': 'Hunan International Economics University' },
    { 'id': 2476, 'value': 'Hunan Normal University', 'label': 'Hunan Normal University' },
    { 'id': 2477, 'value': 'Hunan University', 'label': 'Hunan University' },
    { 'id': 2478, 'value': 'Hunan University of Arts and Science', 'label': 'Hunan University of Arts and Science' },
    { 'id': 2479, 'value': 'Hunan University of Chinese Medicine', 'label': 'Hunan University of Chinese Medicine' },
    { 'id': 2480, 'value': 'Hunan University of Commerce', 'label': 'Hunan University of Commerce' },
    { 'id': 2481, 'value': 'Hunan University of Finance and Economics', 'label': 'Hunan University of Finance and Economics' },
    { 'id': 2482, 'value': 'Hunan University of Humanities, Science and Technology', 'label': 'Hunan University of Humanities, Science and Technology' },
    { 'id': 2483, 'value': 'Hunan University of Medicine', 'label': 'Hunan University of Medicine' },
    { 'id': 2484, 'value': 'Hunan University of Science and Engineering', 'label': 'Hunan University of Science and Engineering' },
    { 'id': 2485, 'value': 'Hunan University of Science and Technology', 'label': 'Hunan University of Science and Technology' },
    { 'id': 2486, 'value': 'Hunan University of Technology', 'label': 'Hunan University of Technology' },
    { 'id': 2487, 'value': 'Hunan Women\'s University', 'label': 'Hunan Women\'s University' },
    { 'id': 14147, 'value': 'Hung Vuong University', 'label': 'Hung Vuong University' },
    { 'id': 11842, 'value': 'Hungkuang University', 'label': 'Hungkuang University' },
    { 'id': 11843, 'value': 'HungKuo Delin University of Technology', 'label': 'HungKuo Delin University of Technology' },
    { 'id': 12805, 'value': 'Hunter College, CUNY', 'label': 'Hunter College, CUNY' },
    { 'id': 12806, 'value': 'Huntingdon College', 'label': 'Huntingdon College' },
    { 'id': 12807, 'value': 'Huntington University', 'label': 'Huntington University' },
    { 'id': 8603, 'value': 'Huree University', 'label': 'Huree University' },
    { 'id': 12808, 'value': 'Husson University', 'label': 'Husson University' },
    { 'id': 12809, 'value': 'Huston-Tillotson University', 'label': 'Huston-Tillotson University' },
    { 'id': 14148, 'value': 'HUTECH University', 'label': 'HUTECH University' },
    { 'id': 2488, 'value': 'Huzhou University', 'label': 'Huzhou University' },
    { 'id': 11844, 'value': 'Hwa Hsia University of Technology', 'label': 'Hwa Hsia University of Technology' },
    { 'id': 7218, 'value': 'Hyogo College of Medicine', 'label': 'Hyogo College of Medicine' },
    { 'id': 7219, 'value': 'Hyogo University', 'label': 'Hyogo University' },
    { 'id': 7220, 'value': 'Hyogo University of Teacher Education', 'label': 'Hyogo University of Teacher Education' },
    { 'id': 8043, 'value': 'Hyupsung University', 'label': 'Hyupsung University' },
    { 'id': 563, 'value': 'HZ University of Applied Sciences', 'label': 'HZ University of Applied Sciences' },
    { 'id': 11845, 'value': 'I-Shou University', 'label': 'I-Shou University' },
    { 'id': 14371, 'value': 'I.CO.TE.A C.A.T. S.R.L.', 'label': 'I.CO.TE.A C.A.T. S.R.L.' },
    { 'id': 14389, 'value': 'I.F.S.E. Italian Food Style Education', 'label': 'I.F.S.E. Italian Food Style Education' },
    { 'id': 1142, 'value': 'I.M. Seçenov adina Birinci Moskva Dövlet Tibb Universitetinin Baki', 'label': 'I.M. Seçenov adina Birinci Moskva Dövlet Tibb Universitetinin Baki' },
    { 'id': 14377, 'value': 'I.S.S.M. Conservatorio “Guido Cantelli” di Novara', 'label': 'I.S.S.M. Conservatorio “Guido Cantelli” di Novara' },
    { 'id': 500, 'value': 'IAAD - The Italian University for Design', 'label': 'IAAD - The Italian University for Design' },
    { 'id': 4639, 'value': 'Iakob Gogebashvili Telavi State University', 'label': 'Iakob Gogebashvili Telavi State University' },
    { 'id': 3665, 'value': 'IB-Hochschule Berlin', 'label': 'IB-Hochschule Berlin' },
    { 'id': 3832, 'value': 'IBA Erhvervsakademi Kolding', 'label': 'IBA Erhvervsakademi Kolding' },
    { 'id': 1257, 'value': 'IBAIS University', 'label': 'IBAIS University' },
    { 'id': 12171, 'value': 'Ibanda University', 'label': 'Ibanda University' },
    { 'id': 7221, 'value': 'Ibaraki Christian University', 'label': 'Ibaraki Christian University' },
    { 'id': 7222, 'value': 'Ibaraki Prefectural University of Health Sciences', 'label': 'Ibaraki Prefectural University of Health Sciences' },
    { 'id': 7223, 'value': 'Ibaraki University', 'label': 'Ibaraki University' },
    { 'id': 14198, 'value': 'Ibb University', 'label': 'Ibb University' },
    { 'id': 11700, 'value': 'Ibn Haldun Üniversitesi', 'label': 'Ibn Haldun Üniversitesi' },
    { 'id': 14199, 'value': 'Ibn Khaldoun University', 'label': 'Ibn Khaldoun University' },
    { 'id': 10390, 'value': 'Ibn Sina College for Health Sciences', 'label': 'Ibn Sina College for Health Sciences' },
    { 'id': 11066, 'value': 'Ibn Sina University', 'label': 'Ibn Sina University' },
    { 'id': 6452, 'value': 'Ibn Sina University of Medical and Pharmaceutical Sciences', 'label': 'Ibn Sina University of Medical and Pharmaceutical Sciences' },
    { 'id': 9626, 'value': 'Ibra College of Technology', 'label': 'Ibra College of Technology' },
    { 'id': 9377, 'value': 'Ibrahim Badamasi Babangida University', 'label': 'Ibrahim Badamasi Babangida University' },
    { 'id': 9627, 'value': 'Ibri College of Technology', 'label': 'Ibri College of Technology' },
    { 'id': 11169, 'value': 'IBS Mednarodna poslovna šola Ljubljana', 'label': 'IBS Mednarodna poslovna šola Ljubljana' },
    { 'id': 12810, 'value': 'Icahn School of Medicine at Mount Sinai', 'label': 'Icahn School of Medicine at Mount Sinai' },
    { 'id': 4358, 'value': 'ICAM - Institut Catholiques d\'Arts et Métiers', 'label': 'ICAM - Institut Catholiques d\'Arts et Métiers' },
    { 'id': 5811, 'value': 'ICFAI University, Dehradun', 'label': 'ICFAI University, Dehradun' },
    { 'id': 5812, 'value': 'ICFAI University, Himachal Pradesh', 'label': 'ICFAI University, Himachal Pradesh' },
    { 'id': 5813, 'value': 'ICFAI University, Jaipur', 'label': 'ICFAI University, Jaipur' },
    { 'id': 5814, 'value': 'ICFAI University, Jharkhand', 'label': 'ICFAI University, Jharkhand' },
    { 'id': 5815, 'value': 'ICFAI University, Meghalaya', 'label': 'ICFAI University, Meghalaya' },
    { 'id': 5816, 'value': 'ICFAI University, Mizoram', 'label': 'ICFAI University, Mizoram' },
    { 'id': 5817, 'value': 'ICFAI University, Nagaland', 'label': 'ICFAI University, Nagaland' },
    { 'id': 5818, 'value': 'ICFAI University, Raipur', 'label': 'ICFAI University, Raipur' },
    { 'id': 5819, 'value': 'ICFAI University, Sikkim', 'label': 'ICFAI University, Sikkim' },
    { 'id': 5820, 'value': 'ICFAI University, Tripura', 'label': 'ICFAI University, Tripura' },
    { 'id': 4359, 'value': 'ICN Business School', 'label': 'ICN Business School' },
    { 'id': 12811, 'value': 'Idaho State University', 'label': 'Idaho State University' },
    { 'id': 8604, 'value': 'Ider University', 'label': 'Ider University' },
    { 'id': 4100, 'value': 'IE University', 'label': 'IE University' },
    { 'id': 156, 'value': 'IE University Madrid', 'label': 'IE University Madrid' },
    { 'id': 5821, 'value': 'IEC University', 'label': 'IEC University' },
    { 'id': 153, 'value': 'IED - Istituto Europeo di Design', 'label': 'IED - Istituto Europeo di Design' },
    { 'id': 14337, 'value': 'IED - Istituto Europeo di Design (BARCELLONA)', 'label': 'IED - Istituto Europeo di Design (BARCELLONA)' },
    { 'id': 508, 'value': 'IED - Istituto Europeo di Design (CAGLIARI)', 'label': 'IED - Istituto Europeo di Design (CAGLIARI)' },
    { 'id': 517, 'value': 'IED - Istituto Europeo di Design (FIRENZE)', 'label': 'IED - Istituto Europeo di Design (FIRENZE)' },
    { 'id': 486, 'value': 'IED - Istituto Europeo di Design (MILANO)', 'label': 'IED - Istituto Europeo di Design (MILANO)' },
    { 'id': 485, 'value': 'IED - Istituto Europeo di Design (ROMA)', 'label': 'IED - Istituto Europeo di Design (ROMA)' },
    { 'id': 484, 'value': 'IED - Istituto Europeo di Design (TORINO)', 'label': 'IED - Istituto Europeo di Design (TORINO)' },
    { 'id': 11170, 'value': 'IEDC-Bled School of Management', 'label': 'IEDC-Bled School of Management' },
    { 'id': 130, 'value': 'IEMO - IST. EUR. MED. OSTEOPATIA', 'label': 'IEMO - IST. EUR. MED. OSTEOPATIA' },
    { 'id': 284, 'value': 'IESEG School of Management', 'label': 'IESEG School of Management' },
    { 'id': 5822, 'value': 'IFHE Hyderabad', 'label': 'IFHE Hyderabad' },
    { 'id': 4360, 'value': 'IFP School', 'label': 'IFP School' },
    { 'id': 5823, 'value': 'IFTM University', 'label': 'IFTM University' },
    { 'id': 9378, 'value': 'Igbinedion University Okada', 'label': 'Igbinedion University Okada' },
    { 'id': 11701, 'value': 'Igdir Üniversitesi', 'label': 'Igdir Üniversitesi' },
    { 'id': 9379, 'value': 'Ignatius Ajuru University of Education', 'label': 'Ignatius Ajuru University of Education' },
    { 'id': 9538, 'value': 'IHE Delft Institute for Water Education', 'label': 'IHE Delft Institute for Water Education' },
    { 'id': 7903, 'value': 'IIC University of Technology', 'label': 'IIC University of Technology' },
    { 'id': 5824, 'value': 'IIHMR University', 'label': 'IIHMR University' },
    { 'id': 5825, 'value': 'IIMT University', 'label': 'IIMT University' },
    { 'id': 6552, 'value': 'Ilam University', 'label': 'Ilam University' },
    { 'id': 4640, 'value': 'Ilia State University', 'label': 'Ilia State University' },
    { 'id': 4730, 'value': 'Ilisimatusarfik', 'label': 'Ilisimatusarfik' },
    { 'id': 12812, 'value': 'Illinois College', 'label': 'Illinois College' },
    { 'id': 12813, 'value': 'Illinois College of Optometry', 'label': 'Illinois College of Optometry' },
    { 'id': 12814, 'value': 'Illinois Institute of Technology', 'label': 'Illinois Institute of Technology' },
    { 'id': 12815, 'value': 'Illinois State University', 'label': 'Illinois State University' },
    { 'id': 12816, 'value': 'Illinois Wesleyan University', 'label': 'Illinois Wesleyan University' },
    { 'id': 10091, 'value': 'Ilma University', 'label': 'Ilma University' },
    { 'id': 9894, 'value': 'Ilocos Sur Polytechnic State College', 'label': 'Ilocos Sur Polytechnic State College' },
    { 'id': 9895, 'value': 'Iloilo Science and Technology University', 'label': 'Iloilo Science and Technology University' },
    { 'id': 11026, 'value': 'Imam Abdulrahman Bin Faisal University', 'label': 'Imam Abdulrahman Bin Faisal University' },
    { 'id': 6553, 'value': 'Imam Hossein University', 'label': 'Imam Hossein University' },
    { 'id': 6453, 'value': 'Imam Ja\'afar Al-sadiq University', 'label': 'Imam Ja\'afar Al-sadiq University' },
    { 'id': 6554, 'value': 'Imam Khomeini Education and Research Institute', 'label': 'Imam Khomeini Education and Research Institute' },
    { 'id': 6555, 'value': 'Imam Khomeini International University', 'label': 'Imam Khomeini International University' },
    { 'id': 644, 'value': 'Imam Malik College for Islamic Sharia and Law', 'label': 'Imam Malik College for Islamic Sharia and Law' },
    { 'id': 6556, 'value': 'Imam Reza University', 'label': 'Imam Reza University' },
    { 'id': 6557, 'value': 'Imam Sadiq University', 'label': 'Imam Sadiq University' },
    { 'id': 14200, 'value': 'Imam Shafei College of Islamic Sciences', 'label': 'Imam Shafei College of Islamic Sciences' },
    { 'id': 1025, 'value': 'IMC Fachhochschule Krems', 'label': 'IMC Fachhochschule Krems' },
    { 'id': 370, 'value': 'IMD Lausanne', 'label': 'IMD Lausanne' },
    { 'id': 12817, 'value': 'Immaculata University', 'label': 'Immaculata University' },
    { 'id': 10680, 'value': 'Immanuel Kant Baltic Federal University', 'label': 'Immanuel Kant Baltic Federal University' },
    { 'id': 9380, 'value': 'Imo State University', 'label': 'Imo State University' },
    { 'id': 211, 'value': 'Imperial College London', 'label': 'Imperial College London' },
    { 'id': 10092, 'value': 'Imperial College of Business Studies', 'label': 'Imperial College of Business Studies' },
    { 'id': 1794, 'value': 'Imperial School of Business and Science', 'label': 'Imperial School of Business and Science' },
    { 'id': 5826, 'value': 'IMS Unison University', 'label': 'IMS Unison University' },
    { 'id': 4361, 'value': 'IMT Atlantique', 'label': 'IMT Atlantique' },
    { 'id': 4362, 'value': 'IMT Mines Albi', 'label': 'IMT Mines Albi' },
    { 'id': 4363, 'value': 'IMT Mines Alès', 'label': 'IMT Mines Alès' },
    { 'id': 350, 'value': 'INALCO - Institut National des Langues et Civilisations Orientales', 'label': 'INALCO - Institut National des Langues et Civilisations Orientales' },
    { 'id': 3227, 'value': 'INCAE Business School, Costa Rica', 'label': 'INCAE Business School, Costa Rica' },
    { 'id': 9460, 'value': 'INCAE Business School, Nicaragua', 'label': 'INCAE Business School, Nicaragua' },
    { 'id': 8044, 'value': 'Incheon Catholic University', 'label': 'Incheon Catholic University' },
    { 'id': 1258, 'value': 'Independent University, Bangladesh', 'label': 'Independent University, Bangladesh' },
    { 'id': 5827, 'value': 'Indian Agricultural Research Institute', 'label': 'Indian Agricultural Research Institute' },
    { 'id': 5828, 'value': 'Indian Institute of Engineering Science and Technology, Shibpur', 'label': 'Indian Institute of Engineering Science and Technology, Shibpur' },
    { 'id': 5829, 'value': 'Indian Institute of Foreign Trade', 'label': 'Indian Institute of Foreign Trade' },
    { 'id': 5830, 'value': 'Indian Institute of Information Technology Allahabad', 'label': 'Indian Institute of Information Technology Allahabad' },
    { 'id': 5831, 'value': 'Indian Institute of Information Technology and Management Gwalior', 'label': 'Indian Institute of Information Technology and Management Gwalior' },
    { 'id': 5832, 'value': 'Indian Institute of Information Technology, Design and Manufacturing', 'label': 'Indian Institute of Information Technology, Design and Manufacturing' },
    { 'id': 5833, 'value': 'Indian Institute of Information Technology, Guwahati', 'label': 'Indian Institute of Information Technology, Guwahati' },
    { 'id': 5834, 'value': 'Indian Institute of Information Technology, Kalyani', 'label': 'Indian Institute of Information Technology, Kalyani' },
    { 'id': 5835, 'value': 'Indian Institute of Information Technology, Kottayam', 'label': 'Indian Institute of Information Technology, Kottayam' },
    { 'id': 5836, 'value': 'Indian Institute of Information Technology, Lucknow', 'label': 'Indian Institute of Information Technology, Lucknow' },
    { 'id': 5837, 'value': 'Indian Institute of Information Technology, Manipur', 'label': 'Indian Institute of Information Technology, Manipur' },
    { 'id': 5838, 'value': 'Indian Institute of Information Technology, Sri City', 'label': 'Indian Institute of Information Technology, Sri City' },
    { 'id': 5839, 'value': 'Indian Institute of Information Technology, Una', 'label': 'Indian Institute of Information Technology, Una' },
    { 'id': 5840, 'value': 'Indian Institute of Information Technology, Vadodara', 'label': 'Indian Institute of Information Technology, Vadodara' },
    { 'id': 5841, 'value': 'Indian Institute of Public Health', 'label': 'Indian Institute of Public Health' },
    { 'id': 5842, 'value': 'Indian Institute of Science', 'label': 'Indian Institute of Science' },
    { 'id': 5843, 'value': 'Indian Institute of Science Education and Research, Bhopal', 'label': 'Indian Institute of Science Education and Research, Bhopal' },
    { 'id': 5844, 'value': 'Indian Institute of Science Education and Research, Kolkata', 'label': 'Indian Institute of Science Education and Research, Kolkata' },
    { 'id': 5845, 'value': 'Indian Institute of Science Education and Research, Mohali', 'label': 'Indian Institute of Science Education and Research, Mohali' },
    { 'id': 5846, 'value': 'Indian Institute of Science Education and Research, Pune', 'label': 'Indian Institute of Science Education and Research, Pune' },
    { 'id': 5847, 'value': 'Indian Institute of Science Education and Research, Thiruvananthapuram', 'label': 'Indian Institute of Science Education and Research, Thiruvananthapuram' },
    { 'id': 5848, 'value': 'Indian Institute of Space Science and Technology', 'label': 'Indian Institute of Space Science and Technology' },
    { 'id': 5849, 'value': 'Indian Institute of Teacher Education', 'label': 'Indian Institute of Teacher Education' },
    { 'id': 5850, 'value': 'Indian Institute of Technology Bhubaneswar', 'label': 'Indian Institute of Technology Bhubaneswar' },
    { 'id': 5851, 'value': 'Indian Institute of Technology Bombay', 'label': 'Indian Institute of Technology Bombay' },
    { 'id': 5852, 'value': 'Indian Institute of Technology Delhi', 'label': 'Indian Institute of Technology Delhi' },
    { 'id': 5853, 'value': 'Indian Institute of Technology Gandhinagar', 'label': 'Indian Institute of Technology Gandhinagar' },
    { 'id': 5854, 'value': 'Indian Institute of Technology Guwahati', 'label': 'Indian Institute of Technology Guwahati' },
    { 'id': 5855, 'value': 'Indian Institute of Technology Hyderabad', 'label': 'Indian Institute of Technology Hyderabad' },
    { 'id': 5856, 'value': 'Indian Institute of Technology Indore', 'label': 'Indian Institute of Technology Indore' },
    { 'id': 5857, 'value': 'Indian Institute of Technology Jodhpur', 'label': 'Indian Institute of Technology Jodhpur' },
    { 'id': 5858, 'value': 'Indian Institute of Technology Kanpur', 'label': 'Indian Institute of Technology Kanpur' },
    { 'id': 5859, 'value': 'Indian Institute of Technology Kharagpur', 'label': 'Indian Institute of Technology Kharagpur' },
    { 'id': 5860, 'value': 'Indian Institute of Technology Madras', 'label': 'Indian Institute of Technology Madras' },
    { 'id': 5861, 'value': 'Indian Institute of Technology Mandi', 'label': 'Indian Institute of Technology Mandi' },
    { 'id': 5862, 'value': 'Indian Institute of Technology Patna', 'label': 'Indian Institute of Technology Patna' },
    { 'id': 5863, 'value': 'Indian Institute of Technology Roorkee', 'label': 'Indian Institute of Technology Roorkee' },
    { 'id': 5864, 'value': 'Indian Institute of Technology Ropar', 'label': 'Indian Institute of Technology Ropar' },
    { 'id': 5865, 'value': 'Indian Institute of Technology, BHU', 'label': 'Indian Institute of Technology, BHU' },
    { 'id': 5866, 'value': 'Indian Maritime University', 'label': 'Indian Maritime University' },
    { 'id': 12818, 'value': 'Indian River State College', 'label': 'Indian River State College' },
    { 'id': 5867, 'value': 'Indian School of Mines', 'label': 'Indian School of Mines' },
    { 'id': 5868, 'value': 'Indian Statistical Institute', 'label': 'Indian Statistical Institute' },
    { 'id': 5869, 'value': 'Indian Veterinary Research Institute', 'label': 'Indian Veterinary Research Institute' },
    { 'id': 12819, 'value': 'Indiana Institute of Technology', 'label': 'Indiana Institute of Technology' },
    { 'id': 12820, 'value': 'Indiana State University', 'label': 'Indiana State University' },
    { 'id': 12821, 'value': 'Indiana University - Purdue University Indianapolis', 'label': 'Indiana University - Purdue University Indianapolis' },
    { 'id': 12822, 'value': 'Indiana University Bloomington', 'label': 'Indiana University Bloomington' },
    { 'id': 12823, 'value': 'Indiana University East', 'label': 'Indiana University East' },
    { 'id': 12824, 'value': 'Indiana University Fort Wayne', 'label': 'Indiana University Fort Wayne' },
    { 'id': 12825, 'value': 'Indiana University Kokomo', 'label': 'Indiana University Kokomo' },
    { 'id': 12826, 'value': 'Indiana University Northwest', 'label': 'Indiana University Northwest' },
    { 'id': 12827, 'value': 'Indiana University of Pennsylvania', 'label': 'Indiana University of Pennsylvania' },
    { 'id': 12828, 'value': 'Indiana University South Bend', 'label': 'Indiana University South Bend' },
    { 'id': 12829, 'value': 'Indiana University Southeast', 'label': 'Indiana University Southeast' },
    { 'id': 12830, 'value': 'Indiana Wesleyan University', 'label': 'Indiana Wesleyan University' },
    { 'id': 5870, 'value': 'Indira Gandhi Delhi Technical University for Women', 'label': 'Indira Gandhi Delhi Technical University for Women' },
    { 'id': 5871, 'value': 'Indira Gandhi Institute of Development Research', 'label': 'Indira Gandhi Institute of Development Research' },
    { 'id': 5872, 'value': 'Indira Gandhi Institute of Medical Sciences, Sheikhpura', 'label': 'Indira Gandhi Institute of Medical Sciences, Sheikhpura' },
    { 'id': 5873, 'value': 'Indira Gandhi Krishi Vishwavidyalaya', 'label': 'Indira Gandhi Krishi Vishwavidyalaya' },
    { 'id': 5874, 'value': 'Indira Gandhi National Tribal University', 'label': 'Indira Gandhi National Tribal University' },
    { 'id': 5875, 'value': 'Indira Gandhi Technological and Medical Sciences University', 'label': 'Indira Gandhi Technological and Medical Sciences University' },
    { 'id': 5876, 'value': 'Indira Gandhi University, Meerpur', 'label': 'Indira Gandhi University, Meerpur' },
    { 'id': 5877, 'value': 'Indira Kala Sangeet Vishwavidyalaya', 'label': 'Indira Kala Sangeet Vishwavidyalaya' },
    { 'id': 5878, 'value': 'Indraprastha Institute of Information Technology', 'label': 'Indraprastha Institute of Information Technology' },
    { 'id': 5879, 'value': 'Indrashil University', 'label': 'Indrashil University' },
    { 'id': 5880, 'value': 'Indus International University', 'label': 'Indus International University' },
    { 'id': 5881, 'value': 'Indus University', 'label': 'Indus University' },
    { 'id': 10093, 'value': 'Indus University, Pakistan', 'label': 'Indus University, Pakistan' },
    { 'id': 10094, 'value': 'Indus Valley School of Art and Architecture', 'label': 'Indus Valley School of Art and Architecture' },
    { 'id': 8374, 'value': 'Informacijas sistemu menedžmenta augstskola', 'label': 'Informacijas sistemu menedžmenta augstskola' },
    { 'id': 14263, 'value': 'Information and Communication University', 'label': 'Information and Communication University' },
    { 'id': 10095, 'value': 'Information Technology University', 'label': 'Information Technology University' },
    { 'id': 10681, 'value': 'Ingush State University', 'label': 'Ingush State University' },
    { 'id': 8045, 'value': 'Inha University', 'label': 'Inha University' },
    { 'id': 8046, 'value': 'Inje University', 'label': 'Inje University' },
    { 'id': 2489, 'value': 'Inner Mongolia Agricultural University', 'label': 'Inner Mongolia Agricultural University' },
    { 'id': 2490, 'value': 'Inner Mongolia Arts University', 'label': 'Inner Mongolia Arts University' },
    { 'id': 2491, 'value': 'Inner Mongolia Medical University', 'label': 'Inner Mongolia Medical University' },
    { 'id': 2492, 'value': 'Inner Mongolia Nationality College', 'label': 'Inner Mongolia Nationality College' },
    { 'id': 2493, 'value': 'Inner Mongolia Normal University', 'label': 'Inner Mongolia Normal University' },
    { 'id': 2494, 'value': 'Inner Mongolia University', 'label': 'Inner Mongolia University' },
    { 'id': 2495, 'value': 'Inner Mongolia University for the Nationalities', 'label': 'Inner Mongolia University for the Nationalities' },
    { 'id': 2496, 'value': 'Inner Mongolia University of Finance and Economics', 'label': 'Inner Mongolia University of Finance and Economics' },
    { 'id': 2497, 'value': 'Inner Mongolia University of Science and Technology', 'label': 'Inner Mongolia University of Science and Technology' },
    { 'id': 2498, 'value': 'Inner Mongolia University of Technology', 'label': 'Inner Mongolia University of Technology' },
    { 'id': 8191, 'value': 'Innovative University of Eurasia', 'label': 'Innovative University of Eurasia' },
    { 'id': 11702, 'value': 'Inönü Üniversitesi', 'label': 'Inönü Üniversitesi' },
    { 'id': 14338, 'value': 'INSEAD - Institut européen d\'administration des affaires', 'label': 'INSEAD - Institut européen d\'administration des affaires' },
    { 'id': 4364, 'value': 'INSEEC Alpes-Savoie', 'label': 'INSEEC Alpes-Savoie' },
    { 'id': 3123, 'value': 'Institución Universitaria Antonio José Camacho', 'label': 'Institución Universitaria Antonio José Camacho' },
    { 'id': 3124, 'value': 'Institución Universitaria Bellas Artes y Ciencias de Bolívar', 'label': 'Institución Universitaria Bellas Artes y Ciencias de Bolívar' },
    { 'id': 3125, 'value': 'Institucion Universitaria Centro de Estudios Superiores Maria Goretti', 'label': 'Institucion Universitaria Centro de Estudios Superiores Maria Goretti' },
    { 'id': 3126, 'value': 'Institución Universitaria Colegio Mayor del Cauca', 'label': 'Institución Universitaria Colegio Mayor del Cauca' },
    { 'id': 3127, 'value': 'Institucion Universitaria Colegios de Colombia', 'label': 'Institucion Universitaria Colegios de Colombia' },
    { 'id': 3128, 'value': 'Institucion Universitaria Colombo Americana', 'label': 'Institucion Universitaria Colombo Americana' },
    { 'id': 3129, 'value': 'Institucion Universitaria de Colombia - Universitaria de Colombia', 'label': 'Institucion Universitaria de Colombia - Universitaria de Colombia' },
    { 'id': 3130, 'value': 'Institución Universitaria de Envigado', 'label': 'Institución Universitaria de Envigado' },
    { 'id': 3131, 'value': 'Institucion Universitaria Eam', 'label': 'Institucion Universitaria Eam' },
    { 'id': 3132, 'value': 'Institución Universitaria Esumer', 'label': 'Institución Universitaria Esumer' },
    { 'id': 3133, 'value': 'Institución Universitaria ITSA', 'label': 'Institución Universitaria ITSA' },
    { 'id': 3134, 'value': 'Institucion Universitaria Latina', 'label': 'Institucion Universitaria Latina' },
    { 'id': 3135, 'value': 'Institucion Universitaria Marco Fidel Suarez', 'label': 'Institucion Universitaria Marco Fidel Suarez' },
    { 'id': 3136, 'value': 'Institucion Universitaria Salazar y Herrera', 'label': 'Institucion Universitaria Salazar y Herrera' },
    { 'id': 11234, 'value': 'Institut Africain de Commerce et de Marketing', 'label': 'Institut Africain de Commerce et de Marketing' },
    { 'id': 4365, 'value': 'Institut Catholique de Paris', 'label': 'Institut Catholique de Paris' },
    { 'id': 4366, 'value': 'Institut Catholique de Toulouse', 'label': 'Institut Catholique de Toulouse' },
    { 'id': 2092, 'value': 'Institut Catholique de Yaoundé', 'label': 'Institut Catholique de Yaoundé' },
    { 'id': 1376, 'value': 'Institut Catholique des Hautes Études Commerciales', 'label': 'Institut Catholique des Hautes Études Commerciales' },
    { 'id': 10996, 'value': 'Institut d\'Enséignement Supérieur de Ruhengeri', 'label': 'Institut d\'Enséignement Supérieur de Ruhengeri' },
    { 'id': 4367, 'value': 'Institut d\'Études Politiques de Paris', 'label': 'Institut d\'Études Politiques de Paris' },
    { 'id': 4368, 'value': 'Institut d\'Optique Graduate School', 'label': 'Institut d\'Optique Graduate School' },
    { 'id': 2005, 'value': 'Institut de hautes études internationales et du développement', 'label': 'Institut de hautes études internationales et du développement' },
    { 'id': 4484, 'value': 'Institut de Technologies d’Owendo', 'label': 'Institut de Technologies d’Owendo' },
    { 'id': 1377, 'value': 'Institut des Arts de Diffusion', 'label': 'Institut des Arts de Diffusion' },
    { 'id': 401, 'value': 'Institut des Sciences de la Vigne et du Vin', 'label': 'Institut des Sciences de la Vigne et du Vin' },
    { 'id': 2022, 'value': 'Institut des Sciences et Techniques de la Communication', 'label': 'Institut des Sciences et Techniques de la Communication' },
    { 'id': 4485, 'value': 'Institut des Techniques Avancées', 'label': 'Institut des Techniques Avancées' },
    { 'id': 309, 'value': 'Institut européen d\'administration des affaires', 'label': 'Institut européen d\'administration des affaires' },
    { 'id': 303, 'value': 'Institut Français de la Mode', 'label': 'Institut Français de la Mode' },
    { 'id': 11171, 'value': 'Inštitut in akademija za multimedije', 'label': 'Inštitut in akademija za multimedije' },
    { 'id': 11235, 'value': 'Institut International de Management', 'label': 'Institut International de Management' },
    { 'id': 11576, 'value': 'Institut International de Technologie', 'label': 'Institut International de Technologie' },
    { 'id': 4369, 'value': 'Institut International du Commerce et du Développement', 'label': 'Institut International du Commerce et du Développement' },
    { 'id': 11577, 'value': 'Institut International du Numérique et de l’Audiovisuel', 'label': 'Institut International du Numérique et de l’Audiovisuel' },
    { 'id': 1510, 'value': 'Institut Jean-Paul II', 'label': 'Institut Jean-Paul II' },
    { 'id': 11345, 'value': 'Institut National d\'Administration', 'label': 'Institut National d\'Administration' },
    { 'id': 2023, 'value': 'Institut National de la Jeunesse et des Sports', 'label': 'Institut National de la Jeunesse et des Sports' },
    { 'id': 4486, 'value': 'Institut National de la Poste des Technologies de l\'Information et de la Communication', 'label': 'Institut National de la Poste des Technologies de l\'Information et de la Communication' },
    { 'id': 1484, 'value': 'Institut National de Santé Publique', 'label': 'Institut National de Santé Publique' },
    { 'id': 4370, 'value': 'Institut National des Langues et Civilisations Orientales', 'label': 'Institut National des Langues et Civilisations Orientales' },
    { 'id': 4371, 'value': 'Institut National des Sciences Appliquées Centre Val de Loire', 'label': 'Institut National des Sciences Appliquées Centre Val de Loire' },
    { 'id': 4372, 'value': 'Institut National des Sciences Appliquées de Lyon', 'label': 'Institut National des Sciences Appliquées de Lyon' },
    { 'id': 4373, 'value': 'Institut National des Sciences Appliquées de Rennes', 'label': 'Institut National des Sciences Appliquées de Rennes' },
    { 'id': 4374, 'value': 'Institut National des Sciences Appliquées de Rouen', 'label': 'Institut National des Sciences Appliquées de Rouen' },
    { 'id': 4375, 'value': 'Institut National des Sciences Appliquées de Strasbourg', 'label': 'Institut National des Sciences Appliquées de Strasbourg' },
    { 'id': 4376, 'value': 'Institut National des Sciences Appliquées de Toulouse', 'label': 'Institut National des Sciences Appliquées de Toulouse' },
    { 'id': 4487, 'value': 'Institut National des Sciences de Gestion', 'label': 'Institut National des Sciences de Gestion' },
    { 'id': 4377, 'value': 'Institut National Polytechnique de Toulouse', 'label': 'Institut National Polytechnique de Toulouse' },
    { 'id': 2024, 'value': 'Institut National Polytechnique Félix Houphouët-Boigny', 'label': 'Institut National Polytechnique Félix Houphouët-Boigny' },
    { 'id': 1378, 'value': 'Institut national supérieur des Arts du Spectacle et des Techniques de Diffusion', 'label': 'Institut national supérieur des Arts du Spectacle et des Techniques de Diffusion' },
    { 'id': 2025, 'value': 'Institut National Supérieur des Arts et de l\'Action Culturelle', 'label': 'Institut National Supérieur des Arts et de l\'Action Culturelle' },
    { 'id': 4378, 'value': 'Institut National supérieur des sciences agronomiques, de l\'alimentation et de l\'environnement', 'label': 'Institut National supérieur des sciences agronomiques, de l\'alimentation et de l\'environnement' },
    { 'id': 4379, 'value': 'Institut National Universitaire Champollion', 'label': 'Institut National Universitaire Champollion' },
    { 'id': 4930, 'value': 'Institut Pertanian Bogor', 'label': 'Institut Pertanian Bogor' },
    { 'id': 1405, 'value': 'Institut Polytechnique Africain', 'label': 'Institut Polytechnique Africain' },
    { 'id': 11236, 'value': 'Institut Polytechnique de Dakar', 'label': 'Institut Polytechnique de Dakar' },
    { 'id': 4380, 'value': 'Institut Polytechnique des Sciences Avancées', 'label': 'Institut Polytechnique des Sciences Avancées' },
    { 'id': 8493, 'value': 'Institut Polytechnique Rural de Formation et de Recherches Appliquées de Katibougou', 'label': 'Institut Polytechnique Rural de Formation et de Recherches Appliquées de Katibougou' },
    { 'id': 11237, 'value': 'Institut Privé de Formation et de Recherches Médicales de Dakar', 'label': 'Institut Privé de Formation et de Recherches Médicales de Dakar' },
    { 'id': 1406, 'value': 'Institut privé des hautes Etudes Cheick Modibo Diarra', 'label': 'Institut privé des hautes Etudes Cheick Modibo Diarra' },
    { 'id': 11578, 'value': 'Institut Privée des Hautes Etudes à Sousse', 'label': 'Institut Privée des Hautes Etudes à Sousse' },
    { 'id': 11579, 'value': 'Institut Privée des Hautes Etudes à Tunis', 'label': 'Institut Privée des Hautes Etudes à Tunis' },
    { 'id': 1379, 'value': 'Institut Saint-Luc Tournai', 'label': 'Institut Saint-Luc Tournai' },
    { 'id': 1407, 'value': 'Institut Sciences Campus', 'label': 'Institut Sciences Campus' },
    { 'id': 4931, 'value': 'Institut Seni Indonesia Denpasar', 'label': 'Institut Seni Indonesia Denpasar' },
    { 'id': 4932, 'value': 'Institut Seni Indonesia Padang Panjang', 'label': 'Institut Seni Indonesia Padang Panjang' },
    { 'id': 4933, 'value': 'Institut Seni Indonesia Surakarta', 'label': 'Institut Seni Indonesia Surakarta' },
    { 'id': 4934, 'value': 'Institut Seni Indonesia Yogyakarta', 'label': 'Institut Seni Indonesia Yogyakarta' },
    { 'id': 4381, 'value': 'Institut Supérieur d\'Agriculture et d\'Agroalimentaire Rhône-Alpes', 'label': 'Institut Supérieur d\'Agriculture et d\'Agroalimentaire Rhône-Alpes' },
    { 'id': 4385, 'value': 'Institut Supérieur d\'Électronique de Paris', 'label': 'Institut Supérieur d\'Électronique de Paris' },
    { 'id': 8663, 'value': 'Institut Supérieur d\'Etudes et de Recherche Islamiques', 'label': 'Institut Supérieur d\'Etudes et de Recherche Islamiques' },
    { 'id': 1511, 'value': 'Institut Superieur de Communication et de Gestion', 'label': 'Institut Superieur de Communication et de Gestion' },
    { 'id': 1380, 'value': 'Institut Supérieur de Formation Sociale et de Communication', 'label': 'Institut Supérieur de Formation Sociale et de Communication' },
    { 'id': 4382, 'value': 'Institut Supérieur de Gestion', 'label': 'Institut Supérieur de Gestion' },
    { 'id': 1485, 'value': 'Institut Supérieur de Gestion des Entreprises', 'label': 'Institut Supérieur de Gestion des Entreprises' },
    { 'id': 4383, 'value': 'Institut Supérieur de l\'Aéronautique et de l\'Espace', 'label': 'Institut Supérieur de l\'Aéronautique et de l\'Espace' },
    { 'id': 4384, 'value': 'Institut Supérieur de l\'Électronique et du Numérique', 'label': 'Institut Supérieur de l\'Électronique et du Numérique' },
    { 'id': 1512, 'value': 'Institut Supérieur de Management Adonaï', 'label': 'Institut Supérieur de Management Adonaï' },
    { 'id': 1381, 'value': 'Institut Supérieur de Musique et de Pédagogie', 'label': 'Institut Supérieur de Musique et de Pédagogie' },
    { 'id': 4488, 'value': 'Institut Supérieur de Technologie', 'label': 'Institut Supérieur de Technologie' },
    { 'id': 4386, 'value': 'Institut Supérieur des Biotechnologies de Paris', 'label': 'Institut Supérieur des Biotechnologies de Paris' },
    { 'id': 11580, 'value': 'Institut Supérieur des Etudes Paramédicales et Sciences Infirmières', 'label': 'Institut Supérieur des Etudes Paramédicales et Sciences Infirmières' },
    { 'id': 4387, 'value': 'Institut Supérieur des Matériaux et Mécaniques Avancés du Mans', 'label': 'Institut Supérieur des Matériaux et Mécaniques Avancés du Mans' },
    { 'id': 1513, 'value': 'Institut Supérieur des Métiers de l’Audiovisuel', 'label': 'Institut Supérieur des Métiers de l’Audiovisuel' },
    { 'id': 11238, 'value': 'Institut Supérieur des Nouvelles Technologies de Commerce de Bâtiment et de Santé', 'label': 'Institut Supérieur des Nouvelles Technologies de Commerce de Bâtiment et de Santé' },
    { 'id': 1514, 'value': 'Institut Supérieur des Sciences de l\'Information et de la Communication', 'label': 'Institut Supérieur des Sciences de l\'Information et de la Communication' },
    { 'id': 4388, 'value': 'Institut Supérieur du Bâtiment et des Travaux Publics', 'label': 'Institut Supérieur du Bâtiment et des Travaux Publics' },
    { 'id': 8420, 'value': 'Institut Supérieur du Génie Appliqué', 'label': 'Institut Supérieur du Génie Appliqué' },
    { 'id': 11581, 'value': 'Institut Supérieur Privé d’Administrationdes Entreprises', 'label': 'Institut Supérieur Privé d’Administrationdes Entreprises' },
    { 'id': 11582, 'value': 'Institut Supérieur Privé des Sciences de la Santé de Sousse', 'label': 'Institut Supérieur Privé des Sciences de la Santé de Sousse' },
    { 'id': 11583, 'value': 'Institut Supérieur Privé des Sciences Infirmières de Kairouan', 'label': 'Institut Supérieur Privé des Sciences Infirmières de Kairouan' },
    { 'id': 11584, 'value': 'Institut Supérieur Privé des Sciences Infirmières de Sousse', 'label': 'Institut Supérieur Privé des Sciences Infirmières de Sousse' },
    { 'id': 11585, 'value': 'Institut Supérieur Privé des Sciences Infirmières El Amed', 'label': 'Institut Supérieur Privé des Sciences Infirmières El Amed' },
    { 'id': 11586, 'value': 'Institut Supérieur Privé des Sciences, de la Technologie et des Affaires du Centre', 'label': 'Institut Supérieur Privé des Sciences, de la Technologie et des Affaires du Centre' },
    { 'id': 11587, 'value': 'Institut Supérieur Privé Méditerranéen de Technologie', 'label': 'Institut Supérieur Privé Méditerranéen de Technologie' },
    { 'id': 4935, 'value': 'Institut Teknologi Bandung', 'label': 'Institut Teknologi Bandung' },
    { 'id': 4936, 'value': 'Institut Teknologi Nasional', 'label': 'Institut Teknologi Nasional' },
    { 'id': 4937, 'value': 'Institut Teknologi Nasional Malang', 'label': 'Institut Teknologi Nasional Malang' },
    { 'id': 4938, 'value': 'Institut Teknologi Sepuluh Nopember', 'label': 'Institut Teknologi Sepuluh Nopember' },
    { 'id': 11239, 'value': 'Institut Universitaire de l\'Entreprise et du Développement', 'label': 'Institut Universitaire de l\'Entreprise et du Développement' },
    { 'id': 4489, 'value': 'Institut Universitaire des Sciences de l\'Organisation', 'label': 'Institut Universitaire des Sciences de l\'Organisation' },
    { 'id': 1515, 'value': 'Institut Universitaire Panafricain', 'label': 'Institut Universitaire Panafricain' },
    { 'id': 6558, 'value': 'Institute for Advanced Studies in Basic Sciences', 'label': 'Institute for Advanced Studies in Basic Sciences' },
    { 'id': 6559, 'value': 'Institute for Research in Fundamental Sciences', 'label': 'Institute for Research in Fundamental Sciences' },
    { 'id': 11284, 'value': 'Institute for Training of Advanced Teachers', 'label': 'Institute for Training of Advanced Teachers' },
    { 'id': 7224, 'value': 'Institute of Advanced Media Arts and Sciences', 'label': 'Institute of Advanced Media Arts and Sciences' },
    { 'id': 5882, 'value': 'Institute of Advanced Research', 'label': 'Institute of Advanced Research' },
    { 'id': 5883, 'value': 'Institute of Advanced Studies in Education', 'label': 'Institute of Advanced Studies in Education' },
    { 'id': 10096, 'value': 'Institute of Business Administration', 'label': 'Institute of Business Administration' },
    { 'id': 10097, 'value': 'Institute of Business Management', 'label': 'Institute of Business Management' },
    { 'id': 5884, 'value': 'Institute of Chemical Technology', 'label': 'Institute of Chemical Technology' },
    { 'id': 11500, 'value': 'Institute of Energy of Tajikistan', 'label': 'Institute of Energy of Tajikistan' },
    { 'id': 8605, 'value': 'Institute of Engineering and Technology', 'label': 'Institute of Engineering and Technology' },
    { 'id': 7225, 'value': 'Institute of Information Security', 'label': 'Institute of Information Security' },
    { 'id': 5885, 'value': 'Institute of Infrastructure, Technology, Research and Management', 'label': 'Institute of Infrastructure, Technology, Research and Management' },
    { 'id': 4389, 'value': 'Institute of Intercultural Management and Communication', 'label': 'Institute of Intercultural Management and Communication' },
    { 'id': 10682, 'value': 'Institute of International Business Education', 'label': 'Institute of International Business Education' },
    { 'id': 8606, 'value': 'Institute of International Economics and Business', 'label': 'Institute of International Economics and Business' },
    { 'id': 11534, 'value': 'Institute of International Relations', 'label': 'Institute of International Relations' },
    { 'id': 10997, 'value': 'Institute of Legal Practice and Development', 'label': 'Institute of Legal Practice and Development' },
    { 'id': 1828, 'value': 'Institute of Management and Entrepreneurship', 'label': 'Institute of Management and Entrepreneurship' },
    { 'id': 10098, 'value': 'Institute of Management Sciences', 'label': 'Institute of Management Sciences' },
    { 'id': 645, 'value': 'Institute of Management Technology Dubai', 'label': 'Institute of Management Technology Dubai' },
    { 'id': 1829, 'value': 'Institute of Modern Knowledge', 'label': 'Institute of Modern Knowledge' },
    { 'id': 7948, 'value': 'Institute of Natural Science', 'label': 'Institute of Natural Science' },
    { 'id': 1830, 'value': 'Institute of Parliamentarism and Private Enterprise', 'label': 'Institute of Parliamentarism and Private Enterprise' },
    { 'id': 5511, 'value': 'Institute of Public Administration', 'label': 'Institute of Public Administration' },
    { 'id': 11501, 'value': 'Institute of Public Administration under the President of the Republic of Tajikistan', 'label': 'Institute of Public Administration under the President of the Republic of Tajikistan' },
    { 'id': 11027, 'value': 'Institute of Public Administration, Saudi Arabia', 'label': 'Institute of Public Administration, Saudi Arabia' },
    { 'id': 1026, 'value': 'Institute of Science and Technology Austria', 'label': 'Institute of Science and Technology Austria' },
    { 'id': 8607, 'value': 'Institute of Social Science', 'label': 'Institute of Social Science' },
    { 'id': 10099, 'value': 'Institute of Southern Punjab', 'label': 'Institute of Southern Punjab' },
    { 'id': 10100, 'value': 'Institute of Space Technology', 'label': 'Institute of Space Technology' },
    { 'id': 7226, 'value': 'Institute of Technologists', 'label': 'Institute of Technologists' },
    { 'id': 5512, 'value': 'Institute of Technology Carlow', 'label': 'Institute of Technology Carlow' },
    { 'id': 7904, 'value': 'Institute of Technology of Cambodia', 'label': 'Institute of Technology of Cambodia' },
    { 'id': 5513, 'value': 'Institute of Technology Sligo', 'label': 'Institute of Technology Sligo' },
    { 'id': 5514, 'value': 'Institute of Technology, Tralee', 'label': 'Institute of Technology, Tralee' },
    { 'id': 8667, 'value': 'Institute of Tourism Studies Malta', 'label': 'Institute of Tourism Studies Malta' },
    { 'id': 11529, 'value': 'Institute Superior Cristal', 'label': 'Institute Superior Cristal' },
    { 'id': 3137, 'value': 'Instituto Caro y Cuervo', 'label': 'Instituto Caro y Cuervo' },
    { 'id': 3970, 'value': 'Instituto de Altos Estudios Nacionales', 'label': 'Instituto de Altos Estudios Nacionales' },
    { 'id': 3285, 'value': 'Instituto de Ciencia Animal', 'label': 'Instituto de Ciencia Animal' },
    { 'id': 3843, 'value': 'Instituto de Educación Superior en Formación Diplomatica y Consular', 'label': 'Instituto de Educación Superior en Formación Diplomatica y Consular' },
    { 'id': 873, 'value': 'Instituto de Enseñanza Superior del Ejército', 'label': 'Instituto de Enseñanza Superior del Ejército' },
    { 'id': 8715, 'value': 'Instituto de Especialización para Ejecutivos', 'label': 'Instituto de Especialización para Ejecutivos' },
    { 'id': 8716, 'value': 'Instituto de Estudios Superiores de Chiapas S.C.', 'label': 'Instituto de Estudios Superiores de Chiapas S.C.' },
    { 'id': 8717, 'value': 'Instituto de Estudios Superiores de Tamaulipas A.C.', 'label': 'Instituto de Estudios Superiores de Tamaulipas A.C.' },
    { 'id': 8718, 'value': 'Instituto de Estudios Superiores del Bajío', 'label': 'Instituto de Estudios Superiores del Bajío' },
    { 'id': 8719, 'value': 'Instituto de Estudios Universitarios A.C.', 'label': 'Instituto de Estudios Universitarios A.C.' },
    { 'id': 10431, 'value': 'Instituto de Estudos Superiores de Fafe', 'label': 'Instituto de Estudos Superiores de Fafe' },
    { 'id': 8654, 'value': 'Instituto de Formação Turística', 'label': 'Instituto de Formação Turística' },
    { 'id': 3138, 'value': 'Instituto Departamental de Bellas Artes', 'label': 'Instituto Departamental de Bellas Artes' },
    { 'id': 3844, 'value': 'Instituto Dominicano de Tecnología', 'label': 'Instituto Dominicano de Tecnología' },
    { 'id': 11302, 'value': 'Instituto Especializado de Educación Superior de Profesionales de la Salud de El Salvador', 'label': 'Instituto Especializado de Educación Superior de Profesionales de la Salud de El Salvador' },
    { 'id': 11303, 'value': 'Instituto Especializado de Educación Superior El Espíritu Santo', 'label': 'Instituto Especializado de Educación Superior El Espíritu Santo' },
    { 'id': 11304, 'value': 'Instituto Especializado de Educación Superior para la Formación Diplomática', 'label': 'Instituto Especializado de Educación Superior para la Formación Diplomática' },
    { 'id': 3845, 'value': 'Instituto Especializado de Estudios Superires de Derecho Empresarial', 'label': 'Instituto Especializado de Estudios Superires de Derecho Empresarial' },
    { 'id': 3846, 'value': 'Instituto Especializado de Investigación y Formación en Ciencias Jurídicas', 'label': 'Instituto Especializado de Investigación y Formación en Ciencias Jurídicas' },
    { 'id': 3847, 'value': 'Instituto Global de Altos Estudios en Ciencias Sociales', 'label': 'Instituto Global de Altos Estudios en Ciencias Sociales' },
    { 'id': 13967, 'value': 'Instituto Metodista Universitario Crandon', 'label': 'Instituto Metodista Universitario Crandon' },
    { 'id': 3286, 'value': 'Instituto Nacional de Ciencias Agrícolas', 'label': 'Instituto Nacional de Ciencias Agrícolas' },
    { 'id': 8720, 'value': 'Instituto Nacional de Estudios Fiscales', 'label': 'Instituto Nacional de Estudios Fiscales' },
    { 'id': 874, 'value': 'Instituto para el Desarrollo Empresarial de la Argentina', 'label': 'Instituto para el Desarrollo Empresarial de la Argentina' },
    { 'id': 1109, 'value': 'Instituto Pedagogico Arubano', 'label': 'Instituto Pedagogico Arubano' },
    { 'id': 10432, 'value': 'Instituto Piaget', 'label': 'Instituto Piaget' },
    { 'id': 10433, 'value': 'Instituto Politécnico da Guarda', 'label': 'Instituto Politécnico da Guarda' },
    { 'id': 10434, 'value': 'Instituto Politécnico da Lusofonia', 'label': 'Instituto Politécnico da Lusofonia' },
    { 'id': 10435, 'value': 'Instituto Politécnico da Maia', 'label': 'Instituto Politécnico da Maia' },
    { 'id': 10436, 'value': 'Instituto Politécnico de Beja', 'label': 'Instituto Politécnico de Beja' },
    { 'id': 10437, 'value': 'Instituto Politécnico de Bragança', 'label': 'Instituto Politécnico de Bragança' },
    { 'id': 10438, 'value': 'Instituto Politécnico de Castelo Branco', 'label': 'Instituto Politécnico de Castelo Branco' },
    { 'id': 10439, 'value': 'Instituto Politécnico de Coimbra', 'label': 'Instituto Politécnico de Coimbra' },
    { 'id': 10440, 'value': 'Instituto Politécnico de Gestão e Tecnologia', 'label': 'Instituto Politécnico de Gestão e Tecnologia' },
    { 'id': 10441, 'value': 'Instituto Politécnico de Leiria', 'label': 'Instituto Politécnico de Leiria' },
    { 'id': 10442, 'value': 'Instituto Politécnico de Lisboa', 'label': 'Instituto Politécnico de Lisboa' },
    { 'id': 8655, 'value': 'Instituto Politécnico de Macau', 'label': 'Instituto Politécnico de Macau' },
    { 'id': 10443, 'value': 'Instituto Politécnico de Portalegre', 'label': 'Instituto Politécnico de Portalegre' },
    { 'id': 10444, 'value': 'Instituto Politécnico de Santarém', 'label': 'Instituto Politécnico de Santarém' },
    { 'id': 10445, 'value': 'Instituto Politécnico de Setúbal', 'label': 'Instituto Politécnico de Setúbal' },
    { 'id': 10446, 'value': 'Instituto Politécnico de Tomar', 'label': 'Instituto Politécnico de Tomar' },
    { 'id': 10447, 'value': 'Instituto Politécnico de Viana do Castelo', 'label': 'Instituto Politécnico de Viana do Castelo' },
    { 'id': 10448, 'value': 'Instituto Politécnico de Viseu', 'label': 'Instituto Politécnico de Viseu' },
    { 'id': 10449, 'value': 'Instituto Politécnico do Cávado e do Ave', 'label': 'Instituto Politécnico do Cávado e do Ave' },
    { 'id': 10450, 'value': 'Instituto Politécnico do Porto', 'label': 'Instituto Politécnico do Porto' },
    { 'id': 3848, 'value': 'Instituto Politécnico Loyola', 'label': 'Instituto Politécnico Loyola' },
    { 'id': 8721, 'value': 'Instituto Politécnico Nacional', 'label': 'Instituto Politécnico Nacional' },
    { 'id': 10451, 'value': 'Instituto Português de Administração de Marketing', 'label': 'Instituto Português de Administração de Marketing' },
    { 'id': 10452, 'value': 'Instituto Superior D. Dinis', 'label': 'Instituto Superior D. Dinis' },
    { 'id': 10453, 'value': 'Instituto Superior de Administração e Gestão', 'label': 'Instituto Superior de Administração e Gestão' },
    { 'id': 10454, 'value': 'Instituto Superior de Administração e Línguas', 'label': 'Instituto Superior de Administração e Línguas' },
    { 'id': 10455, 'value': 'Instituto Superior de Ciências da Informação e da Administração', 'label': 'Instituto Superior de Ciências da Informação e da Administração' },
    { 'id': 3330, 'value': 'Instituto Superior de Ciências Económicas e Empresariais', 'label': 'Instituto Superior de Ciências Económicas e Empresariais' },
    { 'id': 10456, 'value': 'Instituto Superior de Ciências Educativas', 'label': 'Instituto Superior de Ciências Educativas' },
    { 'id': 10457, 'value': 'Instituto Superior de Ciências Educativas do Douro', 'label': 'Instituto Superior de Ciências Educativas do Douro' },
    { 'id': 10458, 'value': 'Instituto Superior de Ciências Empresariais e do Turismo', 'label': 'Instituto Superior de Ciências Empresariais e do Turismo' },
    { 'id': 3331, 'value': 'Instituto Superior de Ciências Jurídicas e Sociais', 'label': 'Instituto Superior de Ciências Jurídicas e Sociais' },
    { 'id': 3287, 'value': 'Instituto Superior de Diseño Industrial', 'label': 'Instituto Superior de Diseño Industrial' },
    { 'id': 11305, 'value': 'Instituto Superior de Economia y Administración de Empresas', 'label': 'Instituto Superior de Economia y Administración de Empresas' },
    { 'id': 10459, 'value': 'Instituto Superior de Educação e Ciências', 'label': 'Instituto Superior de Educação e Ciências' },
    { 'id': 10460, 'value': 'Instituto Superior de Entre Douro e Vouga', 'label': 'Instituto Superior de Entre Douro e Vouga' },
    { 'id': 3849, 'value': 'Instituto Superior de Formación Docente Salomé Ureña', 'label': 'Instituto Superior de Formación Docente Salomé Ureña' },
    { 'id': 10461, 'value': 'Instituto Superior de Gestão', 'label': 'Instituto Superior de Gestão' },
    { 'id': 10462, 'value': 'Instituto Superior de Línguas e Administração de Leiria', 'label': 'Instituto Superior de Línguas e Administração de Leiria' },
    { 'id': 10463, 'value': 'Instituto Superior de Novas Profissões', 'label': 'Instituto Superior de Novas Profissões' },
    { 'id': 10464, 'value': 'Instituto Superior de Paços de Brandão', 'label': 'Instituto Superior de Paços de Brandão' },
    { 'id': 3288, 'value': 'Instituto Superior de Relaciones Internacionales Raúl Roa García', 'label': 'Instituto Superior de Relaciones Internacionales Raúl Roa García' },
    { 'id': 10465, 'value': 'Instituto Superior de Saúde do Alto Ave', 'label': 'Instituto Superior de Saúde do Alto Ave' },
    { 'id': 10466, 'value': 'Instituto Superior de Serviço Social do Porto', 'label': 'Instituto Superior de Serviço Social do Porto' },
    { 'id': 3850, 'value': 'Instituto Superior de Tecnología Universal', 'label': 'Instituto Superior de Tecnología Universal' },
    { 'id': 10467, 'value': 'Instituto Superior de Tecnologias Avançadas de Lisboa', 'label': 'Instituto Superior de Tecnologias Avançadas de Lisboa' },
    { 'id': 3289, 'value': 'Instituto Superior de Tecnologías y Ciencias Aplicadas', 'label': 'Instituto Superior de Tecnologías y Ciencias Aplicadas' },
    { 'id': 10468, 'value': 'Instituto Superior Manuel Teixeira Gomes', 'label': 'Instituto Superior Manuel Teixeira Gomes' },
    { 'id': 10469, 'value': 'Instituto Superior Miguel Torga', 'label': 'Instituto Superior Miguel Torga' },
    { 'id': 3851, 'value': 'Instituto Superior Pedro Francisco Bonó', 'label': 'Instituto Superior Pedro Francisco Bonó' },
    { 'id': 10470, 'value': 'Instituto Superior Politécnico do Oeste', 'label': 'Instituto Superior Politécnico do Oeste' },
    { 'id': 10471, 'value': 'Instituto Superior Politécnico Gaya', 'label': 'Instituto Superior Politécnico Gaya' },
    { 'id': 3852, 'value': 'Instituto Técnico Superior Comunitario', 'label': 'Instituto Técnico Superior Comunitario' },
    { 'id': 3853, 'value': 'Instituto Técnico Superior Mercy Jácquez', 'label': 'Instituto Técnico Superior Mercy Jácquez' },
    { 'id': 8722, 'value': 'Instituto Tecnológico Autonómo de México', 'label': 'Instituto Tecnológico Autonómo de México' },
    { 'id': 8723, 'value': 'Instituto Tecnológico de Acapulco', 'label': 'Instituto Tecnológico de Acapulco' },
    { 'id': 8724, 'value': 'Instituto Tecnológico de Agua Prieta', 'label': 'Instituto Tecnológico de Agua Prieta' },
    { 'id': 8725, 'value': 'Instituto Tecnológico de Aguascalientes', 'label': 'Instituto Tecnológico de Aguascalientes' },
    { 'id': 8726, 'value': 'Instituto Tecnológico de Altamira', 'label': 'Instituto Tecnológico de Altamira' },
    { 'id': 8727, 'value': 'Instituto Tecnológico de Altiplano de Tlaxcala', 'label': 'Instituto Tecnológico de Altiplano de Tlaxcala' },
    { 'id': 8728, 'value': 'Instituto Tecnológico de Álvaro Obregón', 'label': 'Instituto Tecnológico de Álvaro Obregón' },
    { 'id': 8729, 'value': 'Instituto Tecnológico de Apizaco', 'label': 'Instituto Tecnológico de Apizaco' },
    { 'id': 8730, 'value': 'Instituto Tecnológico de Atitalaquia', 'label': 'Instituto Tecnológico de Atitalaquia' },
    { 'id': 8731, 'value': 'Instituto Tecnológico de Bahía de Banderas', 'label': 'Instituto Tecnológico de Bahía de Banderas' },
    { 'id': 8732, 'value': 'Instituto Tecnológico de Boca del Río', 'label': 'Instituto Tecnológico de Boca del Río' },
    { 'id': 875, 'value': 'Instituto Tecnológico de Buenos Aires', 'label': 'Instituto Tecnológico de Buenos Aires' },
    { 'id': 8733, 'value': 'Instituto Tecnológico de Campeche', 'label': 'Instituto Tecnológico de Campeche' },
    { 'id': 8734, 'value': 'Instituto Tecnológico de Cancún', 'label': 'Instituto Tecnológico de Cancún' },
    { 'id': 8735, 'value': 'Instituto Tecnológico de Celaya', 'label': 'Instituto Tecnológico de Celaya' },
    { 'id': 8736, 'value': 'Instituto Tecnológico de Cerro Azul', 'label': 'Instituto Tecnológico de Cerro Azul' },
    { 'id': 8737, 'value': 'Instituto Tecnológico de Chetumal', 'label': 'Instituto Tecnológico de Chetumal' },
    { 'id': 8738, 'value': 'Instituto Tecnológico de Chihuahua', 'label': 'Instituto Tecnológico de Chihuahua' },
    { 'id': 8739, 'value': 'Instituto Tecnológico de Chihuahua II', 'label': 'Instituto Tecnológico de Chihuahua II' },
    { 'id': 8740, 'value': 'Instituto Tecnológico de Chilpancingo', 'label': 'Instituto Tecnológico de Chilpancingo' },
    { 'id': 8741, 'value': 'Instituto Tecnológico de Chiná', 'label': 'Instituto Tecnológico de Chiná' },
    { 'id': 8742, 'value': 'Instituto Tecnológico de Ciudad Altamirano', 'label': 'Instituto Tecnológico de Ciudad Altamirano' },
    { 'id': 8743, 'value': 'Instituto Tecnológico de Ciudad Cuauhtémoc', 'label': 'Instituto Tecnológico de Ciudad Cuauhtémoc' },
    { 'id': 8744, 'value': 'Instituto Tecnológico de Ciudad Guzmán', 'label': 'Instituto Tecnológico de Ciudad Guzmán' },
    { 'id': 8745, 'value': 'Instituto Tecnológico de Ciudad Jiménez', 'label': 'Instituto Tecnológico de Ciudad Jiménez' },
    { 'id': 8746, 'value': 'Instituto Tecnológico de Ciudad Juárez', 'label': 'Instituto Tecnológico de Ciudad Juárez' },
    { 'id': 8747, 'value': 'Instituto Tecnológico de Ciudad Madero', 'label': 'Instituto Tecnológico de Ciudad Madero' },
    { 'id': 8748, 'value': 'Instituto Tecnológico de Ciudad Valles', 'label': 'Instituto Tecnológico de Ciudad Valles' },
    { 'id': 8749, 'value': 'Instituto Tecnológico de Ciudad Victoria', 'label': 'Instituto Tecnológico de Ciudad Victoria' },
    { 'id': 8750, 'value': 'Instituto Tecnológico de Colima', 'label': 'Instituto Tecnológico de Colima' },
    { 'id': 8751, 'value': 'Instituto Tecnológico de Comitán', 'label': 'Instituto Tecnológico de Comitán' },
    { 'id': 8752, 'value': 'Instituto Tecnológico de Comitancillo', 'label': 'Instituto Tecnológico de Comitancillo' },
    { 'id': 8753, 'value': 'Instituto Tecnológico de Conkal', 'label': 'Instituto Tecnológico de Conkal' },
    { 'id': 3228, 'value': 'Instituto Tecnológico de Costa Rica', 'label': 'Instituto Tecnológico de Costa Rica' },
    { 'id': 8754, 'value': 'Instituto Tecnológico de Cuautla', 'label': 'Instituto Tecnológico de Cuautla' },
    { 'id': 8755, 'value': 'Instituto Tecnológico de Culiacán', 'label': 'Instituto Tecnológico de Culiacán' },
    { 'id': 8756, 'value': 'Instituto Tecnológico de Delicias', 'label': 'Instituto Tecnológico de Delicias' },
    { 'id': 8757, 'value': 'Instituto Tecnológico de Durango', 'label': 'Instituto Tecnológico de Durango' },
    { 'id': 8758, 'value': 'Instituto Tecnológico de El Llano Aguascalientes', 'label': 'Instituto Tecnológico de El Llano Aguascalientes' },
    { 'id': 8759, 'value': 'Instituto Tecnológico de El Salto', 'label': 'Instituto Tecnológico de El Salto' },
    { 'id': 8760, 'value': 'Instituto Tecnológico de Ensenada', 'label': 'Instituto Tecnológico de Ensenada' },
    { 'id': 8761, 'value': 'Instituto Tecnológico de Frontera Comalapa', 'label': 'Instituto Tecnológico de Frontera Comalapa' },
    { 'id': 8762, 'value': 'Instituto Tecnológico de Guaymas', 'label': 'Instituto Tecnológico de Guaymas' },
    { 'id': 8763, 'value': 'Instituto Tecnológico de Gustavo A. Madero', 'label': 'Instituto Tecnológico de Gustavo A. Madero' },
    { 'id': 8764, 'value': 'Instituto Tecnológico de Gustavo A. Madero II', 'label': 'Instituto Tecnológico de Gustavo A. Madero II' },
    { 'id': 8765, 'value': 'Instituto Tecnológico de Hermosillo', 'label': 'Instituto Tecnológico de Hermosillo' },
    { 'id': 8766, 'value': 'Instituto Tecnológico de Huatabampo', 'label': 'Instituto Tecnológico de Huatabampo' },
    { 'id': 8767, 'value': 'Instituto Tecnológico de Huejutla', 'label': 'Instituto Tecnológico de Huejutla' },
    { 'id': 8768, 'value': 'Instituto Tecnológico de Huimanguillo', 'label': 'Instituto Tecnológico de Huimanguillo' },
    { 'id': 8769, 'value': 'Instituto Tecnológico de Iguala', 'label': 'Instituto Tecnológico de Iguala' },
    { 'id': 8770, 'value': 'Instituto Tecnológico de Iztapalapa', 'label': 'Instituto Tecnológico de Iztapalapa' },
    { 'id': 8771, 'value': 'Instituto Tecnológico de Iztapalapa II', 'label': 'Instituto Tecnológico de Iztapalapa II' },
    { 'id': 8772, 'value': 'Instituto Tecnológico de Iztapalapa III', 'label': 'Instituto Tecnológico de Iztapalapa III' },
    { 'id': 8773, 'value': 'Instituto Tecnológico de Jiquilpan', 'label': 'Instituto Tecnológico de Jiquilpan' },
    { 'id': 8774, 'value': 'Instituto Tecnológico de la Chontalpa', 'label': 'Instituto Tecnológico de la Chontalpa' },
    { 'id': 8775, 'value': 'Instituto Tecnológico de la Costa Grande', 'label': 'Instituto Tecnológico de la Costa Grande' },
    { 'id': 8776, 'value': 'Instituto Tecnológico de La Cuenca del Papaloapan', 'label': 'Instituto Tecnológico de La Cuenca del Papaloapan' },
    { 'id': 8777, 'value': 'Instituto Tecnológico de La Laguna', 'label': 'Instituto Tecnológico de La Laguna' },
    { 'id': 8778, 'value': 'Instituto Tecnológico de La Paz', 'label': 'Instituto Tecnológico de La Paz' },
    { 'id': 8779, 'value': 'Instituto Tecnológico de la Piedad', 'label': 'Instituto Tecnológico de la Piedad' },
    { 'id': 8780, 'value': 'Instituto Tecnológico de La Zona Maya', 'label': 'Instituto Tecnológico de La Zona Maya' },
    { 'id': 8781, 'value': 'Instituto Tecnológico de La Zona Olmeca', 'label': 'Instituto Tecnológico de La Zona Olmeca' },
    { 'id': 3854, 'value': 'Instituto Tecnológico de Las Américas', 'label': 'Instituto Tecnológico de Las Américas' },
    { 'id': 8782, 'value': 'Instituto Tecnológico de Lázaro Cárdenas', 'label': 'Instituto Tecnológico de Lázaro Cárdenas' },
    { 'id': 8783, 'value': 'Instituto Tecnológico de León', 'label': 'Instituto Tecnológico de León' },
    { 'id': 8784, 'value': 'Instituto Tecnológico de Lerma', 'label': 'Instituto Tecnológico de Lerma' },
    { 'id': 8785, 'value': 'Instituto Tecnológico de Linares', 'label': 'Instituto Tecnológico de Linares' },
    { 'id': 8786, 'value': 'Instituto Tecnológico de Los Mochis', 'label': 'Instituto Tecnológico de Los Mochis' },
    { 'id': 8787, 'value': 'Instituto Tecnológico de Matamoros', 'label': 'Instituto Tecnológico de Matamoros' },
    { 'id': 8788, 'value': 'Instituto Tecnológico de Matehuala', 'label': 'Instituto Tecnológico de Matehuala' },
    { 'id': 8789, 'value': 'Instituto Tecnológico de Mazatlán', 'label': 'Instituto Tecnológico de Mazatlán' },
    { 'id': 8790, 'value': 'Instituto Tecnológico de Mérida', 'label': 'Instituto Tecnológico de Mérida' },
    { 'id': 8791, 'value': 'Instituto Tecnológico de Mexicali', 'label': 'Instituto Tecnológico de Mexicali' },
    { 'id': 8792, 'value': 'Instituto Tecnológico de Milpa Alta', 'label': 'Instituto Tecnológico de Milpa Alta' },
    { 'id': 8793, 'value': 'Instituto Tecnológico de Milpa Alta II', 'label': 'Instituto Tecnológico de Milpa Alta II' },
    { 'id': 8794, 'value': 'Instituto Tecnológico de Minatitlán', 'label': 'Instituto Tecnológico de Minatitlán' },
    { 'id': 8795, 'value': 'Instituto Tecnológico de Morelia', 'label': 'Instituto Tecnológico de Morelia' },
    { 'id': 8796, 'value': 'Instituto Tecnológico de Nogales', 'label': 'Instituto Tecnológico de Nogales' },
    { 'id': 8797, 'value': 'Instituto Tecnológico de Nuevo Laredo', 'label': 'Instituto Tecnológico de Nuevo Laredo' },
    { 'id': 8798, 'value': 'Instituto Tecnológico de Nuevo León', 'label': 'Instituto Tecnológico de Nuevo León' },
    { 'id': 8799, 'value': 'Instituto Tecnológico de Oaxaca', 'label': 'Instituto Tecnológico de Oaxaca' },
    { 'id': 8800, 'value': 'Instituto Tecnológico de Ocotlán', 'label': 'Instituto Tecnológico de Ocotlán' },
    { 'id': 8801, 'value': 'Instituto Tecnológico de Orizaba', 'label': 'Instituto Tecnológico de Orizaba' },
    { 'id': 8802, 'value': 'Instituto Tecnológico de Pabellón de Arteaga', 'label': 'Instituto Tecnológico de Pabellón de Arteaga' },
    { 'id': 8803, 'value': 'Instituto Tecnológico de Pachuca', 'label': 'Instituto Tecnológico de Pachuca' },
    { 'id': 8804, 'value': 'Instituto Tecnológico de Parral', 'label': 'Instituto Tecnológico de Parral' },
    { 'id': 8805, 'value': 'Instituto Tecnológico de Piedras Negras', 'label': 'Instituto Tecnológico de Piedras Negras' },
    { 'id': 8806, 'value': 'Instituto Tecnológico de Pinotepa', 'label': 'Instituto Tecnológico de Pinotepa' },
    { 'id': 8807, 'value': 'Instituto Tecnológico de Pochutla', 'label': 'Instituto Tecnológico de Pochutla' },
    { 'id': 8808, 'value': 'Instituto Tecnológico de Puebla', 'label': 'Instituto Tecnológico de Puebla' },
    { 'id': 8809, 'value': 'Instituto Tecnológico de Querétaro', 'label': 'Instituto Tecnológico de Querétaro' },
    { 'id': 8810, 'value': 'Instituto Tecnológico de Reynosa', 'label': 'Instituto Tecnológico de Reynosa' },
    { 'id': 8811, 'value': 'Instituto Tecnológico de Roque', 'label': 'Instituto Tecnológico de Roque' },
    { 'id': 8812, 'value': 'Instituto Tecnológico de Salina Cruz', 'label': 'Instituto Tecnológico de Salina Cruz' },
    { 'id': 8813, 'value': 'Instituto Tecnológico de Saltillo', 'label': 'Instituto Tecnológico de Saltillo' },
    { 'id': 8814, 'value': 'Instituto Tecnológico de San Juan del Río', 'label': 'Instituto Tecnológico de San Juan del Río' },
    { 'id': 8815, 'value': 'Instituto Tecnológico de San Luís Potosí', 'label': 'Instituto Tecnológico de San Luís Potosí' },
    { 'id': 8816, 'value': 'Instituto Tecnológico de San Marcos', 'label': 'Instituto Tecnológico de San Marcos' },
    { 'id': 3855, 'value': 'Instituto Tecnológico de Santo Domingo', 'label': 'Instituto Tecnológico de Santo Domingo' },
    { 'id': 8817, 'value': 'Instituto Tecnológico de Sinaloa de Leyva', 'label': 'Instituto Tecnológico de Sinaloa de Leyva' },
    { 'id': 8818, 'value': 'Instituto Tecnológico de Sonora', 'label': 'Instituto Tecnológico de Sonora' },
    { 'id': 8819, 'value': 'Instituto Tecnológico de Tapachula', 'label': 'Instituto Tecnológico de Tapachula' },
    { 'id': 8820, 'value': 'Instituto Tecnológico de Tecomatlán', 'label': 'Instituto Tecnológico de Tecomatlán' },
    { 'id': 8821, 'value': 'Instituto Tecnológico de Tehuacán', 'label': 'Instituto Tecnológico de Tehuacán' },
    { 'id': 8822, 'value': 'Instituto Tecnológico de Tepic', 'label': 'Instituto Tecnológico de Tepic' },
    { 'id': 8823, 'value': 'Instituto Tecnológico de Tijuana', 'label': 'Instituto Tecnológico de Tijuana' },
    { 'id': 8824, 'value': 'Instituto Tecnológico de Tizimín', 'label': 'Instituto Tecnológico de Tizimín' },
    { 'id': 8825, 'value': 'Instituto Tecnológico de Tláhuac', 'label': 'Instituto Tecnológico de Tláhuac' },
    { 'id': 8826, 'value': 'Instituto Tecnológico de Tláhuac II', 'label': 'Instituto Tecnológico de Tláhuac II' },
    { 'id': 8827, 'value': 'Instituto Tecnológico de Tláhuac III', 'label': 'Instituto Tecnológico de Tláhuac III' },
    { 'id': 8828, 'value': 'Instituto Tecnológico de Tlajomulco', 'label': 'Instituto Tecnológico de Tlajomulco' },
    { 'id': 8829, 'value': 'Instituto Tecnológico de Tlalnepantla', 'label': 'Instituto Tecnológico de Tlalnepantla' },
    { 'id': 8830, 'value': 'Instituto Tecnológico de Tlalpan', 'label': 'Instituto Tecnológico de Tlalpan' },
    { 'id': 8831, 'value': 'Instituto Tecnológico de Tlaxiaco', 'label': 'Instituto Tecnológico de Tlaxiaco' },
    { 'id': 8832, 'value': 'Instituto Tecnológico de Toluca', 'label': 'Instituto Tecnológico de Toluca' },
    { 'id': 8833, 'value': 'Instituto Tecnológico de Torreón', 'label': 'Instituto Tecnológico de Torreón' },
    { 'id': 8834, 'value': 'Instituto Tecnológico de Tuxtepec', 'label': 'Instituto Tecnológico de Tuxtepec' },
    { 'id': 8835, 'value': 'Instituto Tecnológico de Tuxtla Gutiérrez', 'label': 'Instituto Tecnológico de Tuxtla Gutiérrez' },
    { 'id': 8836, 'value': 'Instituto Tecnológico de Úrsulo Galván', 'label': 'Instituto Tecnológico de Úrsulo Galván' },
    { 'id': 8837, 'value': 'Instituto Tecnológico de Veracruz', 'label': 'Instituto Tecnológico de Veracruz' },
    { 'id': 8838, 'value': 'Instituto Tecnológico de Villahermosa', 'label': 'Instituto Tecnológico de Villahermosa' },
    { 'id': 8839, 'value': 'Instituto Tecnológico de Zacatecas', 'label': 'Instituto Tecnológico de Zacatecas' },
    { 'id': 8840, 'value': 'Instituto Tecnológico de Zacatepec', 'label': 'Instituto Tecnológico de Zacatepec' },
    { 'id': 8841, 'value': 'Instituto Tecnológico de Zitácuaro', 'label': 'Instituto Tecnológico de Zitácuaro' },
    { 'id': 8842, 'value': 'Instituto Tecnológico del Istmo', 'label': 'Instituto Tecnológico del Istmo' },
    { 'id': 8843, 'value': 'Instituto Tecnológico del Norte de Nayarit', 'label': 'Instituto Tecnológico del Norte de Nayarit' },
    { 'id': 8844, 'value': 'Instituto Tecnológico del Sur de Nayarit', 'label': 'Instituto Tecnológico del Sur de Nayarit' },
    { 'id': 8845, 'value': 'Instituto Tecnológico del Valle de Etla', 'label': 'Instituto Tecnológico del Valle de Etla' },
    { 'id': 8846, 'value': 'Instituto Tecnológico del Valle de Morelia', 'label': 'Instituto Tecnológico del Valle de Morelia' },
    { 'id': 8847, 'value': 'Instituto Tecnológico del Valle de Oaxaca', 'label': 'Instituto Tecnológico del Valle de Oaxaca' },
    { 'id': 8848, 'value': 'Instituto Tecnológico del Valle del Guadiana', 'label': 'Instituto Tecnológico del Valle del Guadiana' },
    { 'id': 8849, 'value': 'Instituto Tecnológico del Valle del Yaqui', 'label': 'Instituto Tecnológico del Valle del Yaqui' },
    { 'id': 8850, 'value': 'Instituto Tecnológico Latinoamericano', 'label': 'Instituto Tecnológico Latinoamericano' },
    { 'id': 3139, 'value': 'Instituto Tecnológico Metropolitano', 'label': 'Instituto Tecnológico Metropolitano' },
    { 'id': 3140, 'value': 'Instituto Tecnológico Pascual Bravo', 'label': 'Instituto Tecnológico Pascual Bravo' },
    { 'id': 8851, 'value': 'Instituto Tecnológico y de Estudios Superiores de Occidente A.C.', 'label': 'Instituto Tecnológico y de Estudios Superiores de Occidente A.C.' },
    { 'id': 876, 'value': 'Instituto Universitario Aeronáutico', 'label': 'Instituto Universitario Aeronáutico' },
    { 'id': 13968, 'value': 'Instituto Universitario Asociación Cristiana de Jóvenes', 'label': 'Instituto Universitario Asociación Cristiana de Jóvenes' },
    { 'id': 13969, 'value': 'Instituto Universitario Bios', 'label': 'Instituto Universitario Bios' },
    { 'id': 877, 'value': 'Instituto Universitario CEMIC', 'label': 'Instituto Universitario CEMIC' },
    { 'id': 13970, 'value': 'Instituto Universitario Centro de Docencia, Investigación e Información en Aprendizaje', 'label': 'Instituto Universitario Centro de Docencia, Investigación e Información en Aprendizaje' },
    { 'id': 13971, 'value': 'Instituto Universitario Centro de Estudio y Diagnóstico de las Disgnacias del Uruguay', 'label': 'Instituto Universitario Centro de Estudio y Diagnóstico de las Disgnacias del Uruguay' },
    { 'id': 13972, 'value': 'Instituto Universitario Centro Latinoamericano de Economía Humana', 'label': 'Instituto Universitario Centro Latinoamericano de Economía Humana' },
    { 'id': 10472, 'value': 'Instituto Universitário da Maia', 'label': 'Instituto Universitário da Maia' },
    { 'id': 878, 'value': 'Instituto Universitario de Ciencias Biomédicas de Córdoba', 'label': 'Instituto Universitario de Ciencias Biomédicas de Córdoba' },
    { 'id': 10473, 'value': 'Instituto Universitário de Ciências da Saúde', 'label': 'Instituto Universitário de Ciências da Saúde' },
    { 'id': 879, 'value': 'Instituto Universitario de Ciencias de la Salud', 'label': 'Instituto Universitario de Ciencias de la Salud' },
    { 'id': 10474, 'value': 'Instituto Universitário de Ciências Psicológicas, Sociais e da Vida', 'label': 'Instituto Universitário de Ciências Psicológicas, Sociais e da Vida' },
    { 'id': 3141, 'value': 'Instituto Universitario de La Paz', 'label': 'Instituto Universitario de La Paz' },
    { 'id': 10475, 'value': 'Instituto Universitário de Lisboa', 'label': 'Instituto Universitário de Lisboa' },
    { 'id': 13973, 'value': 'Instituto Universitario de Postgrado en Psicoanálisis', 'label': 'Instituto Universitario de Postgrado en Psicoanálisis' },
    { 'id': 880, 'value': 'Instituto Universitario de Salud Mental', 'label': 'Instituto Universitario de Salud Mental' },
    { 'id': 881, 'value': 'Instituto Universitario de Seguridad Marítima', 'label': 'Instituto Universitario de Seguridad Marítima' },
    { 'id': 882, 'value': 'Instituto Universitario del Hospital Italiano', 'label': 'Instituto Universitario del Hospital Italiano' },
    { 'id': 883, 'value': 'Instituto Universitario Escuela Argentina de Negocios', 'label': 'Instituto Universitario Escuela Argentina de Negocios' },
    { 'id': 884, 'value': 'Instituto Universitario ESEADE', 'label': 'Instituto Universitario ESEADE' },
    { 'id': 13974, 'value': 'Instituto Universitario Francisco de Asís', 'label': 'Instituto Universitario Francisco de Asís' },
    { 'id': 885, 'value': 'Instituto Universitario Italiano de Rosario', 'label': 'Instituto Universitario Italiano de Rosario' },
    { 'id': 886, 'value': 'Instituto Universitario Nacional de Derechos Humanos Madres de Plaza de Mayo', 'label': 'Instituto Universitario Nacional de Derechos Humanos Madres de Plaza de Mayo' },
    { 'id': 887, 'value': 'Instituto Universitario Patagónico de las Artes', 'label': 'Instituto Universitario Patagónico de las Artes' },
    { 'id': 888, 'value': 'Instituto Universitario River Plate', 'label': 'Instituto Universitario River Plate' },
    { 'id': 1382, 'value': 'Instituts Saint-Luc Bruxelles', 'label': 'Instituts Saint-Luc Bruxelles' },
    { 'id': 11588, 'value': 'Instituts Supérieurs des Etudes Technologiques', 'label': 'Instituts Supérieurs des Etudes Technologiques' },
    { 'id': 10521, 'value': 'Institutul de Administrare a Afacerilor din Bucuresti', 'label': 'Institutul de Administrare a Afacerilor din Bucuresti' },
    { 'id': 1383, 'value': 'Instituut voor Tropische Geneeskunde', 'label': 'Instituut voor Tropische Geneeskunde' },
    { 'id': 5886, 'value': 'Integral University', 'label': 'Integral University' },
    { 'id': 3361, 'value': 'Intercollege', 'label': 'Intercollege' },
    { 'id': 1161, 'value': 'Internacionalna poslovno-informaciona akademija', 'label': 'Internacionalna poslovno-informaciona akademija' },
    { 'id': 1162, 'value': 'Internacionalni univerzitet Travnik', 'label': 'Internacionalni univerzitet Travnik' },
    { 'id': 10599, 'value': 'Internacionalni Univerzitet u Novom Pazaru', 'label': 'Internacionalni Univerzitet u Novom Pazaru' },
    { 'id': 1163, 'value': 'Internacionalni univerzitet u Sarajevu', 'label': 'Internacionalni univerzitet u Sarajevu' },
    { 'id': 1164, 'value': 'Internacionalnog univerziteta u Goraždu', 'label': 'Internacionalnog univerziteta u Goraždu' },
    { 'id': 3362, 'value': 'InterNapa College', 'label': 'InterNapa College' },
    { 'id': 7853, 'value': 'International Academy of Management, Law, Finance and Business', 'label': 'International Academy of Management, Law, Finance and Business' },
    { 'id': 8477, 'value': 'International Balkan University', 'label': 'International Balkan University' },
    { 'id': 10683, 'value': 'International Banking Institute', 'label': 'International Banking Institute' },
    { 'id': 4641, 'value': 'International Black Sea University', 'label': 'International Black Sea University' },
    { 'id': 7227, 'value': 'International Budo University', 'label': 'International Budo University' },
    { 'id': 1165, 'value': 'International BURCH University', 'label': 'International BURCH University' },
    { 'id': 4903, 'value': 'International Business School', 'label': 'International Business School' },
    { 'id': 1431, 'value': 'International Business School, Botevgrad', 'label': 'International Business School, Botevgrad' },
    { 'id': 12172, 'value': 'International Business, Science and Technology University', 'label': 'International Business, Science and Technology University' },
    { 'id': 7228, 'value': 'International Christian University', 'label': 'International Christian University' },
    { 'id': 7229, 'value': 'International College for Postgraduate Buddhist Studies', 'label': 'International College for Postgraduate Buddhist Studies' },
    { 'id': 8160, 'value': 'International College of the Cayman Islands', 'label': 'International College of the Cayman Islands' },
    { 'id': 4744, 'value': 'International Hellenic University', 'label': 'International Hellenic University' },
    { 'id': 8192, 'value': 'International Humanitarian Technical University', 'label': 'International Humanitarian Technical University' },
    { 'id': 12009, 'value': 'International Humanitarian University', 'label': 'International Humanitarian University' },
    { 'id': 1831, 'value': 'International Humanities and Economics Institute', 'label': 'International Humanities and Economics Institute' },
    { 'id': 10684, 'value': 'International Independent Ecological-Political University', 'label': 'International Independent Ecological-Political University' },
    { 'id': 8421, 'value': 'International Institute for Higher Education in Morocco', 'label': 'International Institute for Higher Education in Morocco' },
    { 'id': 5887, 'value': 'International Institute for Population Sciences', 'label': 'International Institute for Population Sciences' },
    { 'id': 5888, 'value': 'International Institute of Information Technology Bangalore', 'label': 'International Institute of Information Technology Bangalore' },
    { 'id': 5889, 'value': 'International Institute of Information Technology, Bhubaneswar', 'label': 'International Institute of Information Technology, Bhubaneswar' },
    { 'id': 5890, 'value': 'International Institute of Information Technology, Hyderabad', 'label': 'International Institute of Information Technology, Hyderabad' },
    { 'id': 5891, 'value': 'International Institute of Information Technology, Naya Raipur', 'label': 'International Institute of Information Technology, Naya Raipur' },
    { 'id': 1259, 'value': 'International Islamic University, Chittagong', 'label': 'International Islamic University, Chittagong' },
    { 'id': 10101, 'value': 'International Islamic University, Islamabad', 'label': 'International Islamic University, Islamabad' },
    { 'id': 8193, 'value': 'International IT University', 'label': 'International IT University' },
    { 'id': 1486, 'value': 'International Leadership University, Burundi', 'label': 'International Leadership University, Burundi' },
    { 'id': 7791, 'value': 'International Leadership University, Kenya', 'label': 'International Leadership University, Kenya' },
    { 'id': 9628, 'value': 'International Maritime College Oman', 'label': 'International Maritime College Oman' },
    { 'id': 11952, 'value': 'International Medical and Technological University', 'label': 'International Medical and Technological University' },
    { 'id': 11535, 'value': 'International Oil and Gas University', 'label': 'International Oil and Gas University' },
    { 'id': 7230, 'value': 'International Pacific University', 'label': 'International Pacific University' },
    { 'id': 3666, 'value': 'International Psychoanalytic University', 'label': 'International Psychoanalytic University' },
    { 'id': 11589, 'value': 'International School of Business', 'label': 'International School of Business' },
    { 'id': 3667, 'value': 'International School of Management', 'label': 'International School of Management' },
    { 'id': 12010, 'value': 'International Science and Technology University', 'label': 'International Science and Technology University' },
    { 'id': 8478, 'value': 'International Slavic University G. R. Derzhavin', 'label': 'International Slavic University G. R. Derzhavin' },
    { 'id': 1260, 'value': 'International Standard University', 'label': 'International Standard University' },
    { 'id': 12011, 'value': 'International Technological University Mykolaiv Polytechnic', 'label': 'International Technological University Mykolaiv Polytechnic' },
    { 'id': 7905, 'value': 'International University', 'label': 'International University' },
    { 'id': 7938, 'value': 'International University for Graduate Studies', 'label': 'International University for Graduate Studies' },
    { 'id': 1832, 'value': 'International University MITSO', 'label': 'International University MITSO' },
    { 'id': 11067, 'value': 'International University of Africa', 'label': 'International University of Africa' },
    { 'id': 1261, 'value': 'International University of Business Agriculture and Technology', 'label': 'International University of Business Agriculture and Technology' },
    { 'id': 7854, 'value': 'International University of Central Asia', 'label': 'International University of Central Asia' },
    { 'id': 6560, 'value': 'International University of Chabahar', 'label': 'International University of Chabahar' },
    { 'id': 12173, 'value': 'International University of East Africa', 'label': 'International University of East Africa' },
    { 'id': 1487, 'value': 'International University of Equator, Burundi', 'label': 'International University of Equator, Burundi' },
    { 'id': 10685, 'value': 'International University of Fundamental Studies, St. Petersburg', 'label': 'International University of Fundamental Studies, St. Petersburg' },
    { 'id': 7231, 'value': 'International University of Health and Welfare', 'label': 'International University of Health and Welfare' },
    { 'id': 7855, 'value': 'International University of Innovative Technologies', 'label': 'International University of Innovative Technologies' },
    { 'id': 7232, 'value': 'International University of Japan', 'label': 'International University of Japan' },
    { 'id': 8047, 'value': 'International University of Korea', 'label': 'International University of Korea' },
    { 'id': 7856, 'value': 'International University of Kyrgyzstan', 'label': 'International University of Kyrgyzstan' },
    { 'id': 9241, 'value': 'International University of Malaya-Wales', 'label': 'International University of Malaya-Wales' },
    { 'id': 8446, 'value': 'International University of Monaco', 'label': 'International University of Monaco' },
    { 'id': 11346, 'value': 'International University of Science and Technology', 'label': 'International University of Science and Technology' },
    { 'id': 14201, 'value': 'International University of Technology Twintech', 'label': 'International University of Technology Twintech' },
    { 'id': 8608, 'value': 'International University of Ulaanbaatar', 'label': 'International University of Ulaanbaatar' },
    { 'id': 2093, 'value': 'International University, Bamenda', 'label': 'International University, Bamenda' },
    { 'id': 8307, 'value': 'Internationale Akademie für Philosophie', 'label': 'Internationale Akademie für Philosophie' },
    { 'id': 9242, 'value': 'INTI International University', 'label': 'INTI International University' },
    { 'id': 5892, 'value': 'Invertis University', 'label': 'Invertis University' },
    { 'id': 12831, 'value': 'Iona College', 'label': 'Iona College' },
    { 'id': 4745, 'value': 'Ionian University', 'label': 'Ionian University' },
    { 'id': 12832, 'value': 'Iowa State University of Science and Technology', 'label': 'Iowa State University of Science and Technology' },
    { 'id': 12833, 'value': 'Iowa Wesleyan University', 'label': 'Iowa Wesleyan University' },
    { 'id': 4390, 'value': 'IPAG Business School', 'label': 'IPAG Business School' },
    { 'id': 14429, 'value': 'IPE - Business School', 'label': 'IPE - Business School' },
    { 'id': 10102, 'value': 'Iqra National University', 'label': 'Iqra National University' },
    { 'id': 10103, 'value': 'Iqra University', 'label': 'Iqra University' },
    { 'id': 14202, 'value': 'Iqra\'a University for Science and Technology', 'label': 'Iqra\'a University for Science and Technology' },
    { 'id': 6561, 'value': 'Iran Informatic Institute', 'label': 'Iran Informatic Institute' },
    { 'id': 6562, 'value': 'Iran Polymer and Petrochemical Institute', 'label': 'Iran Polymer and Petrochemical Institute' },
    { 'id': 6563, 'value': 'Iran University of Industries and Mines', 'label': 'Iran University of Industries and Mines' },
    { 'id': 6564, 'value': 'Iran University of Science and Technology', 'label': 'Iran University of Science and Technology' },
    { 'id': 7046, 'value': 'Irbid National University', 'label': 'Irbid National University' },
    { 'id': 12834, 'value': 'Irell and Manella Graduate School of Biological Sciences', 'label': 'Irell and Manella Graduate School of Biological Sciences' },
    { 'id': 10686, 'value': 'Irkutsk National Research Technical University', 'label': 'Irkutsk National Research Technical University' },
    { 'id': 10687, 'value': 'Irkutsk State Medical University', 'label': 'Irkutsk State Medical University' },
    { 'id': 10688, 'value': 'Irkutsk State University', 'label': 'Irkutsk State University' },
    { 'id': 7233, 'value': 'Iryo Sosei University', 'label': 'Iryo Sosei University' },
    { 'id': 3290, 'value': 'ISA, Universidad de las Artes', 'label': 'ISA, Universidad de las Artes' },
    { 'id': 9896, 'value': 'Isabela State University', 'label': 'Isabela State University' },
    { 'id': 483, 'value': 'ISAE - Institut Supérieur de l\'Aéronautique et de l\'Espace', 'label': 'ISAE - Institut Supérieur de l\'Aéronautique et de l\'Espace' },
    { 'id': 9651, 'value': 'ISAE Universidad', 'label': 'ISAE Universidad' },
    { 'id': 286, 'value': 'ISART Digital', 'label': 'ISART Digital' },
    { 'id': 5893, 'value': 'ISBM University', 'label': 'ISBM University' },
    { 'id': 4391, 'value': 'ISC Paris Business School', 'label': 'ISC Paris Business School' },
    { 'id': 14333, 'value': 'ISDE Law Business School', 'label': 'ISDE Law Business School' },
    { 'id': 9539, 'value': 'Iselinge Hogeschool', 'label': 'Iselinge Hogeschool' },
    { 'id': 6565, 'value': 'Isfahan Institute of Higher Education', 'label': 'Isfahan Institute of Higher Education' },
    { 'id': 6566, 'value': 'Isfahan University of Medical Sciences', 'label': 'Isfahan University of Medical Sciences' },
    { 'id': 6567, 'value': 'Isfahan University of Technology', 'label': 'Isfahan University of Technology' },
    { 'id': 1262, 'value': 'Ishakha International University, Bangladesh', 'label': 'Ishakha International University, Bangladesh' },
    { 'id': 7234, 'value': 'Ishikawa Prefectural Nursing University', 'label': 'Ishikawa Prefectural Nursing University' },
    { 'id': 7235, 'value': 'Ishikawa Prefectural University', 'label': 'Ishikawa Prefectural University' },
    { 'id': 7236, 'value': 'Ishinomaki Senshu University', 'label': 'Ishinomaki Senshu University' },
    { 'id': 180, 'value': 'ISIA Urbino', 'label': 'ISIA Urbino' },
    { 'id': 11703, 'value': 'Isik Üniversitesi', 'label': 'Isik Üniversitesi' },
    { 'id': 11704, 'value': 'Iskenderun Teknik Üniversitesi', 'label': 'Iskenderun Teknik Üniversitesi' },
    { 'id': 10104, 'value': 'Islamia College Peshawar', 'label': 'Islamia College Peshawar' },
    { 'id': 1263, 'value': 'Islamic Arabic University', 'label': 'Islamic Arabic University' },
    { 'id': 717, 'value': 'Islamic Azad University Afghanistan', 'label': 'Islamic Azad University Afghanistan' },
    { 'id': 6568, 'value': 'Islamic Azad University Central Tehran Branch', 'label': 'Islamic Azad University Central Tehran Branch' },
    { 'id': 6569, 'value': 'Islamic Azad University Isfahan, Khorasgan Branch', 'label': 'Islamic Azad University Isfahan, Khorasgan Branch' },
    { 'id': 6570, 'value': 'Islamic Azad university Kashmar Branch', 'label': 'Islamic Azad university Kashmar Branch' },
    { 'id': 6571, 'value': 'Islamic Azad University of Abadeh', 'label': 'Islamic Azad University of Abadeh' },
    { 'id': 6572, 'value': 'Islamic Azad University of Arak', 'label': 'Islamic Azad University of Arak' },
    { 'id': 6573, 'value': 'Islamic Azad University of Bojnourd', 'label': 'Islamic Azad University of Bojnourd' },
    { 'id': 6574, 'value': 'Islamic Azad University of Fasa', 'label': 'Islamic Azad University of Fasa' },
    { 'id': 6575, 'value': 'Islamic Azad University of Golpayegan', 'label': 'Islamic Azad University of Golpayegan' },
    { 'id': 6576, 'value': 'Islamic Azad University of Ilam', 'label': 'Islamic Azad University of Ilam' },
    { 'id': 6577, 'value': 'Islamic Azad University of Jahrom', 'label': 'Islamic Azad University of Jahrom' },
    { 'id': 6578, 'value': 'Islamic Azad University of Lahijan', 'label': 'Islamic Azad University of Lahijan' },
    { 'id': 6579, 'value': 'Islamic Azad University of Mashhad', 'label': 'Islamic Azad University of Mashhad' },
    { 'id': 6580, 'value': 'Islamic Azad University of Medical Sciences, Qeshm', 'label': 'Islamic Azad University of Medical Sciences, Qeshm' },
    { 'id': 6581, 'value': 'Islamic Azad University of Roudehen', 'label': 'Islamic Azad University of Roudehen' },
    { 'id': 6582, 'value': 'Islamic Azad University of Urmia', 'label': 'Islamic Azad University of Urmia' },
    { 'id': 646, 'value': 'Islamic Azad University U.A.E. Branch', 'label': 'Islamic Azad University U.A.E. Branch' },
    { 'id': 6697, 'value': 'Islamic Azad University-Sarab Branch', 'label': 'Islamic Azad University-Sarab Branch' },
    { 'id': 6583, 'value': 'Islamic Azad University, Abadan', 'label': 'Islamic Azad University, Abadan' },
    { 'id': 6584, 'value': 'Islamic Azad University, Abarkouh', 'label': 'Islamic Azad University, Abarkouh' },
    { 'id': 6585, 'value': 'Islamic Azad University, Abhar', 'label': 'Islamic Azad University, Abhar' },
    { 'id': 6586, 'value': 'Islamic Azad University, Ahar', 'label': 'Islamic Azad University, Ahar' },
    { 'id': 6587, 'value': 'Islamic Azad University, Ahvaz', 'label': 'Islamic Azad University, Ahvaz' },
    { 'id': 6588, 'value': 'Islamic Azad University, Ajabshir', 'label': 'Islamic Azad University, Ajabshir' },
    { 'id': 6589, 'value': 'Islamic Azad University, Aligudarz', 'label': 'Islamic Azad University, Aligudarz' },
    { 'id': 6590, 'value': 'Islamic Azad University, Anar', 'label': 'Islamic Azad University, Anar' },
    { 'id': 6591, 'value': 'Islamic Azad University, Andimeshk', 'label': 'Islamic Azad University, Andimeshk' },
    { 'id': 6592, 'value': 'Islamic Azad University, Ardabil', 'label': 'Islamic Azad University, Ardabil' },
    { 'id': 6593, 'value': 'Islamic Azad University, Ardakan', 'label': 'Islamic Azad University, Ardakan' },
    { 'id': 6594, 'value': 'Islamic Azad University, Ardestan', 'label': 'Islamic Azad University, Ardestan' },
    { 'id': 6595, 'value': 'Islamic Azad University, Arsanjan', 'label': 'Islamic Azad University, Arsanjan' },
    { 'id': 6596, 'value': 'Islamic Azad University, Ashkezar', 'label': 'Islamic Azad University, Ashkezar' },
    { 'id': 6597, 'value': 'Islamic Azad University, Ashtian', 'label': 'Islamic Azad University, Ashtian' },
    { 'id': 6598, 'value': 'Islamic Azad University, Astara', 'label': 'Islamic Azad University, Astara' },
    { 'id': 6599, 'value': 'Islamic Azad University, Ayatollah Amoli', 'label': 'Islamic Azad University, Ayatollah Amoli' },
    { 'id': 6600, 'value': 'Islamic Azad University, Azadshahr', 'label': 'Islamic Azad University, Azadshahr' },
    { 'id': 6601, 'value': 'Islamic Azad University, Babol', 'label': 'Islamic Azad University, Babol' },
    { 'id': 6602, 'value': 'Islamic Azad University, Baft', 'label': 'Islamic Azad University, Baft' },
    { 'id': 6603, 'value': 'Islamic Azad University, Bandar Abbas', 'label': 'Islamic Azad University, Bandar Abbas' },
    { 'id': 6604, 'value': 'Islamic Azad University, Bandar Gaz', 'label': 'Islamic Azad University, Bandar Gaz' },
    { 'id': 6605, 'value': 'Islamic Azad University, Bandar-e Anzali', 'label': 'Islamic Azad University, Bandar-e Anzali' },
    { 'id': 6606, 'value': 'Islamic Azad University, Behbahan', 'label': 'Islamic Azad University, Behbahan' },
    { 'id': 6607, 'value': 'Islamic Azad University, Behshahr', 'label': 'Islamic Azad University, Behshahr' },
    { 'id': 6608, 'value': 'Islamic Azad University, Birjand', 'label': 'Islamic Azad University, Birjand' },
    { 'id': 6609, 'value': 'Islamic Azad University, Bonab', 'label': 'Islamic Azad University, Bonab' },
    { 'id': 6610, 'value': 'Islamic Azad University, Borujerd', 'label': 'Islamic Azad University, Borujerd' },
    { 'id': 6611, 'value': 'Islamic Azad University, Buin-Zahra', 'label': 'Islamic Azad University, Buin-Zahra' },
    { 'id': 6612, 'value': 'Islamic Azad University, Bushehr', 'label': 'Islamic Azad University, Bushehr' },
    { 'id': 6613, 'value': 'Islamic Azad University, Damavand', 'label': 'Islamic Azad University, Damavand' },
    { 'id': 6614, 'value': 'Islamic Azad University, Damghan', 'label': 'Islamic Azad University, Damghan' },
    { 'id': 6615, 'value': 'Islamic Azad University, Darab', 'label': 'Islamic Azad University, Darab' },
    { 'id': 6616, 'value': 'Islamic Azad University, Dehaghan', 'label': 'Islamic Azad University, Dehaghan' },
    { 'id': 6617, 'value': 'Islamic Azad University, Dehloran', 'label': 'Islamic Azad University, Dehloran' },
    { 'id': 6618, 'value': 'Islamic Azad University, Dezful', 'label': 'Islamic Azad University, Dezful' },
    { 'id': 6619, 'value': 'Islamic Azad University, East Tehran', 'label': 'Islamic Azad University, East Tehran' },
    { 'id': 6620, 'value': 'Islamic Azad University, Eghlid', 'label': 'Islamic Azad University, Eghlid' },
    { 'id': 6621, 'value': 'Islamic Azad University, Esfarayen', 'label': 'Islamic Azad University, Esfarayen' },
    { 'id': 6622, 'value': 'Islamic Azad University, Eslamshahr', 'label': 'Islamic Azad University, Eslamshahr' },
    { 'id': 6623, 'value': 'Islamic Azad University, Estahban', 'label': 'Islamic Azad University, Estahban' },
    { 'id': 6624, 'value': 'Islamic Azad University, Ferdows', 'label': 'Islamic Azad University, Ferdows' },
    { 'id': 6625, 'value': 'Islamic Azad University, Firoozkooh', 'label': 'Islamic Azad University, Firoozkooh' },
    { 'id': 6626, 'value': 'Islamic Azad University, Firuzabad', 'label': 'Islamic Azad University, Firuzabad' },
    { 'id': 6627, 'value': 'Islamic Azad University, Gachsaran', 'label': 'Islamic Azad University, Gachsaran' },
    { 'id': 6628, 'value': 'Islamic Azad University, Garmsar', 'label': 'Islamic Azad University, Garmsar' },
    { 'id': 6629, 'value': 'Islamic Azad University, Gonabad', 'label': 'Islamic Azad University, Gonabad' },
    { 'id': 6630, 'value': 'Islamic Azad University, Gorgan', 'label': 'Islamic Azad University, Gorgan' },
    { 'id': 6631, 'value': 'Islamic Azad University, Hamedan', 'label': 'Islamic Azad University, Hamedan' },
    { 'id': 6632, 'value': 'Islamic Azad University, Hashtrood', 'label': 'Islamic Azad University, Hashtrood' },
    { 'id': 6633, 'value': 'Islamic Azad University, Hidaj', 'label': 'Islamic Azad University, Hidaj' },
    { 'id': 6634, 'value': 'Islamic Azad University, Izeh', 'label': 'Islamic Azad University, Izeh' },
    { 'id': 6635, 'value': 'Islamic Azad University, Karaj Branch', 'label': 'Islamic Azad University, Karaj Branch' },
    { 'id': 6636, 'value': 'Islamic Azad University, Kashan', 'label': 'Islamic Azad University, Kashan' },
    { 'id': 6637, 'value': 'Islamic Azad University, Kazeroon', 'label': 'Islamic Azad University, Kazeroon' },
    { 'id': 6638, 'value': 'Islamic Azad University, Kerman', 'label': 'Islamic Azad University, Kerman' },
    { 'id': 6639, 'value': 'Islamic Azad University, Kermanshah', 'label': 'Islamic Azad University, Kermanshah' },
    { 'id': 6640, 'value': 'Islamic Azad University, Khomain', 'label': 'Islamic Azad University, Khomain' },
    { 'id': 6641, 'value': 'Islamic Azad University, Khomeinishahr', 'label': 'Islamic Azad University, Khomeinishahr' },
    { 'id': 6642, 'value': 'Islamic Azad University, Khoy', 'label': 'Islamic Azad University, Khoy' },
    { 'id': 6643, 'value': 'Islamic Azad University, Larestan', 'label': 'Islamic Azad University, Larestan' },
    { 'id': 6644, 'value': 'Islamic Azad University, Mahabad', 'label': 'Islamic Azad University, Mahabad' },
    { 'id': 6645, 'value': 'Islamic Azad University, Mahdishahr', 'label': 'Islamic Azad University, Mahdishahr' },
    { 'id': 6646, 'value': 'Islamic Azad University, Mahshahr', 'label': 'Islamic Azad University, Mahshahr' },
    { 'id': 6647, 'value': 'Islamic Azad University, Majlesi', 'label': 'Islamic Azad University, Majlesi' },
    { 'id': 6648, 'value': 'Islamic Azad University, Maragheh', 'label': 'Islamic Azad University, Maragheh' },
    { 'id': 6649, 'value': 'Islamic Azad University, Marvdasht', 'label': 'Islamic Azad University, Marvdasht' },
    { 'id': 6650, 'value': 'Islamic Azad University, Masjed Soleyman', 'label': 'Islamic Azad University, Masjed Soleyman' },
    { 'id': 6651, 'value': 'Islamic Azad University, Maybod', 'label': 'Islamic Azad University, Maybod' },
    { 'id': 6652, 'value': 'Islamic Azad University, Miyaneh', 'label': 'Islamic Azad University, Miyaneh' },
    { 'id': 6653, 'value': 'Islamic Azad University, Mobarakeh', 'label': 'Islamic Azad University, Mobarakeh' },
    { 'id': 6654, 'value': 'Islamic Azad University, Naein', 'label': 'Islamic Azad University, Naein' },
    { 'id': 6655, 'value': 'Islamic Azad University, Naragh', 'label': 'Islamic Azad University, Naragh' },
    { 'id': 6656, 'value': 'Islamic Azad University, Neyshabur', 'label': 'Islamic Azad University, Neyshabur' },
    { 'id': 6657, 'value': 'Islamic Azad University, Nur', 'label': 'Islamic Azad University, Nur' },
    { 'id': 6658, 'value': 'Islamic Azad University, Omidiyeh', 'label': 'Islamic Azad University, Omidiyeh' },
    { 'id': 6659, 'value': 'Islamic Azad University, Osku', 'label': 'Islamic Azad University, Osku' },
    { 'id': 6660, 'value': 'Islamic Azad University, Parand', 'label': 'Islamic Azad University, Parand' },
    { 'id': 6661, 'value': 'Islamic Azad University, Parsabad Moghan', 'label': 'Islamic Azad University, Parsabad Moghan' },
    { 'id': 6662, 'value': 'Islamic Azad University, Qaemshahr', 'label': 'Islamic Azad University, Qaemshahr' },
    { 'id': 6663, 'value': 'Islamic Azad University, Qazvin', 'label': 'Islamic Azad University, Qazvin' },
    { 'id': 6664, 'value': 'Islamic Azad University, Qom', 'label': 'Islamic Azad University, Qom' },
    { 'id': 6665, 'value': 'Islamic Azad University, Quchan', 'label': 'Islamic Azad University, Quchan' },
    { 'id': 6666, 'value': 'Islamic Azad University, Ramhormoz', 'label': 'Islamic Azad University, Ramhormoz' },
    { 'id': 6667, 'value': 'Islamic Azad University, Rasht', 'label': 'Islamic Azad University, Rasht' },
    { 'id': 6668, 'value': 'Islamic Azad University, Sabzevar', 'label': 'Islamic Azad University, Sabzevar' },
    { 'id': 6669, 'value': 'Islamic Azad University, Sanandaj Branch', 'label': 'Islamic Azad University, Sanandaj Branch' },
    { 'id': 6670, 'value': 'Islamic Azad University, Sari', 'label': 'Islamic Azad University, Sari' },
    { 'id': 6671, 'value': 'Islamic Azad University, Saveh', 'label': 'Islamic Azad University, Saveh' },
    { 'id': 6672, 'value': 'Islamic Azad University, Science and Research of Fars', 'label': 'Islamic Azad University, Science and Research of Fars' },
    { 'id': 6673, 'value': 'Islamic Azad University, Semnan', 'label': 'Islamic Azad University, Semnan' },
    { 'id': 6674, 'value': 'Islamic Azad University, Sepidan', 'label': 'Islamic Azad University, Sepidan' },
    { 'id': 6675, 'value': 'Islamic Azad University, Shabestar', 'label': 'Islamic Azad University, Shabestar' },
    { 'id': 6676, 'value': 'Islamic Azad University, Shahr Rey', 'label': 'Islamic Azad University, Shahr Rey' },
    { 'id': 6677, 'value': 'Islamic Azad University, Shahr-e-Qods', 'label': 'Islamic Azad University, Shahr-e-Qods' },
    { 'id': 6678, 'value': 'Islamic Azad University, Shahreza', 'label': 'Islamic Azad University, Shahreza' },
    { 'id': 6679, 'value': 'Islamic Azad University, Shahrood', 'label': 'Islamic Azad University, Shahrood' },
    { 'id': 6680, 'value': 'Islamic Azad University, Shiraz', 'label': 'Islamic Azad University, Shiraz' },
    { 'id': 6681, 'value': 'Islamic Azad University, Shirvan', 'label': 'Islamic Azad University, Shirvan' },
    { 'id': 6682, 'value': 'Islamic Azad University, Shoushtar', 'label': 'Islamic Azad University, Shoushtar' },
    { 'id': 6683, 'value': 'Islamic Azad University, Sirjan', 'label': 'Islamic Azad University, Sirjan' },
    { 'id': 6684, 'value': 'Islamic Azad University, South Tehran', 'label': 'Islamic Azad University, South Tehran' },
    { 'id': 6685, 'value': 'Islamic Azad University, Tabriz', 'label': 'Islamic Azad University, Tabriz' },
    { 'id': 6686, 'value': 'Islamic Azad University, Tafresh', 'label': 'Islamic Azad University, Tafresh' },
    { 'id': 6687, 'value': 'Islamic Azad University, Takestan', 'label': 'Islamic Azad University, Takestan' },
    { 'id': 6688, 'value': 'Islamic Azad University, Tehran Medical', 'label': 'Islamic Azad University, Tehran Medical' },
    { 'id': 6689, 'value': 'Islamic Azad University, Tonekabon', 'label': 'Islamic Azad University, Tonekabon' },
    { 'id': 6690, 'value': 'Islamic Azad University, Varamin', 'label': 'Islamic Azad University, Varamin' },
    { 'id': 6691, 'value': 'Islamic Azad University, West Tehran', 'label': 'Islamic Azad University, West Tehran' },
    { 'id': 6692, 'value': 'Islamic Azad University, Yasuj', 'label': 'Islamic Azad University, Yasuj' },
    { 'id': 6693, 'value': 'Islamic Azad University, Yazd', 'label': 'Islamic Azad University, Yazd' },
    { 'id': 6694, 'value': 'Islamic Azad University, Zahedan', 'label': 'Islamic Azad University, Zahedan' },
    { 'id': 6695, 'value': 'Islamic Azad University, Zanjan', 'label': 'Islamic Azad University, Zanjan' },
    { 'id': 6696, 'value': 'Islamic Azad University, Zarand', 'label': 'Islamic Azad University, Zarand' },
    { 'id': 11502, 'value': 'Islamic Institute of Tajikistan', 'label': 'Islamic Institute of Tajikistan' },
    { 'id': 1264, 'value': 'Islamic University', 'label': 'Islamic University' },
    { 'id': 4690, 'value': 'Islamic University College, Ghana', 'label': 'Islamic University College, Ghana' },
    { 'id': 12174, 'value': 'Islamic University in Uganda', 'label': 'Islamic University in Uganda' },
    { 'id': 8285, 'value': 'Islamic University of Lebanon', 'label': 'Islamic University of Lebanon' },
    { 'id': 11028, 'value': 'Islamic University of Madinah', 'label': 'Islamic University of Madinah' },
    { 'id': 5894, 'value': 'Islamic University of Science and Technology', 'label': 'Islamic University of Science and Technology' },
    { 'id': 1265, 'value': 'Islamic University of Technology', 'label': 'Islamic University of Technology' },
    { 'id': 9540, 'value': 'Islamitische Universiteit Rotterdam', 'label': 'Islamitische Universiteit Rotterdam' },
    { 'id': 8336, 'value': 'ISM Vadybos ir ekonomikos universitetas', 'label': 'ISM Vadybos ir ekonomikos universitetas' },
    { 'id': 7047, 'value': 'Isra University', 'label': 'Isra University' },
    { 'id': 10391, 'value': 'Israa University', 'label': 'Israa University' },
    { 'id': 3668, 'value': 'ISS International Business School of Service Management', 'label': 'ISS International Business School of Service Management' },
    { 'id': 14434, 'value': 'ISSAM', 'label': 'ISSAM' },
    { 'id': 505, 'value': 'ISSM Luigi Boccherini', 'label': 'ISSM Luigi Boccherini' },
    { 'id': 7857, 'value': 'Issyk-Kul State University', 'label': 'Issyk-Kul State University' },
    { 'id': 3669, 'value': 'IST-Hochschule für Management', 'label': 'IST-Hochschule für Management' },
    { 'id': 11705, 'value': 'Istanbul 29 Mayis Üniversitesi', 'label': 'Istanbul 29 Mayis Üniversitesi' },
    { 'id': 11706, 'value': 'Istanbul Arel Üniversitesi', 'label': 'Istanbul Arel Üniversitesi' },
    { 'id': 11707, 'value': 'Istanbul Aydin Üniversitesi', 'label': 'Istanbul Aydin Üniversitesi' },
    { 'id': 11708, 'value': 'Istanbul Ayvansaray Üniversitesi', 'label': 'Istanbul Ayvansaray Üniversitesi' },
    { 'id': 11709, 'value': 'Istanbul Bilgi Üniversitesi', 'label': 'Istanbul Bilgi Üniversitesi' },
    { 'id': 11710, 'value': 'Istanbul Esenyurt Üniversitesi', 'label': 'Istanbul Esenyurt Üniversitesi' },
    { 'id': 11711, 'value': 'Istanbul Gedik Üniversitesi', 'label': 'Istanbul Gedik Üniversitesi' },
    { 'id': 11712, 'value': 'Istanbul Gelisim Üniversitesi', 'label': 'Istanbul Gelisim Üniversitesi' },
    { 'id': 11713, 'value': 'Istanbul Kent Üniversitesi', 'label': 'Istanbul Kent Üniversitesi' },
    { 'id': 11714, 'value': 'Istanbul Kültür Üniversitesi', 'label': 'Istanbul Kültür Üniversitesi' },
    { 'id': 11715, 'value': 'Istanbul Medeniyet Üniversitesi', 'label': 'Istanbul Medeniyet Üniversitesi' },
    { 'id': 11716, 'value': 'Istanbul Medipol Üniversitesi', 'label': 'Istanbul Medipol Üniversitesi' },
    { 'id': 11717, 'value': 'Istanbul Rumeli Üniversitesi', 'label': 'Istanbul Rumeli Üniversitesi' },
    { 'id': 11718, 'value': 'Istanbul Sabahattin Zaim Üniversitesi', 'label': 'Istanbul Sabahattin Zaim Üniversitesi' },
    { 'id': 11719, 'value': 'Istanbul Sehir Üniversitesi', 'label': 'Istanbul Sehir Üniversitesi' },
    { 'id': 11720, 'value': 'Istanbul Teknik Üniversitesi', 'label': 'Istanbul Teknik Üniversitesi' },
    { 'id': 11721, 'value': 'Istanbul Ticaret Üniversitesi', 'label': 'Istanbul Ticaret Üniversitesi' },
    { 'id': 11722, 'value': 'Istanbul Üniversitesi', 'label': 'Istanbul Üniversitesi' },
    { 'id': 11723, 'value': 'Istanbul Yeni Yüzyil Üniversitesi', 'label': 'Istanbul Yeni Yüzyil Üniversitesi' },
    { 'id': 4822, 'value': 'Istarsko veleucilište', 'label': 'Istarsko veleucilište' },
    { 'id': 11724, 'value': 'Istinye Üniversitesi', 'label': 'Istinye Üniversitesi' },
    { 'id': 6976, 'value': 'Istituto d\'Arte Applicata e Design (IAAD)', 'label': 'Istituto d\'Arte Applicata e Design (IAAD)' },
    { 'id': 37, 'value': 'Istituto di Moda Burgo', 'label': 'Istituto di Moda Burgo' },
    { 'id': 6931, 'value': 'Istituto DUOFIN ART - Accademia delle Arti e Nuove Tecnologie - ROMA', 'label': 'Istituto DUOFIN ART - Accademia delle Arti e Nuove Tecnologie - ROMA' },
    { 'id': 6990, 'value': 'Istituto Europeo del Design (IED) - CAGLIARI', 'label': 'Istituto Europeo del Design (IED) - CAGLIARI' },
    { 'id': 7005, 'value': 'Istituto Europeo del Design (IED) - FIRENZE, sede decentrata IED MILANO', 'label': 'Istituto Europeo del Design (IED) - FIRENZE, sede decentrata IED MILANO' },
    { 'id': 6959, 'value': 'Istituto Europeo del Design (IED) - MILANO', 'label': 'Istituto Europeo del Design (IED) - MILANO' },
    { 'id': 6932, 'value': 'Istituto Europeo del Design (IED) - ROMA', 'label': 'Istituto Europeo del Design (IED) - ROMA' },
    { 'id': 6977, 'value': 'Istituto Europeo del Design (IED) - TORINO', 'label': 'Istituto Europeo del Design (IED) - TORINO' },
    { 'id': 6840, 'value': 'Istituto Italiano di Scienze Umane di Firenze', 'label': 'Istituto Italiano di Scienze Umane di Firenze' },
    { 'id': 199, 'value': 'Istituto Marangoni Milano', 'label': 'Istituto Marangoni Milano' },
    { 'id': 302, 'value': 'Istituto Marangoni UK', 'label': 'Istituto Marangoni UK' },
    { 'id': 231, 'value': 'Istituto Modartech', 'label': 'Istituto Modartech' },
    { 'id': 155, 'value': 'Istituto musicale CATANIA Vincenzo Bellini', 'label': 'Istituto musicale CATANIA Vincenzo Bellini' },
    { 'id': 6961, 'value': 'Istituto Mussida Music Publishing - MILANO', 'label': 'Istituto Mussida Music Publishing - MILANO' },
    { 'id': 6933, 'value': 'Istituto Pantheon Design & Technology - ROMA', 'label': 'Istituto Pantheon Design & Technology - ROMA' },
    { 'id': 14647, 'value': 'Istituto per l\'Arte e il Restauro - Palazzo Spinelli', 'label': 'Istituto per l\'Arte e il Restauro - Palazzo Spinelli' },
    { 'id': 6966, 'value': 'Istituto Poliarte - ANCONA', 'label': 'Istituto Poliarte - ANCONA' },
    { 'id': 14387, 'value': 'Istituto Secoli', 'label': 'Istituto Secoli' },
    { 'id': 6882, 'value': 'Istituto statale superiore di studi musicali e coreutici "Gaetano Braga" di TERAMO', 'label': 'Istituto statale superiore di studi musicali e coreutici "Gaetano Braga" di TERAMO' },
    { 'id': 7017, 'value': 'Istituto superiore di studi musicali di AOSTA della Valle d\'Aosta', 'label': 'Istituto superiore di studi musicali di AOSTA della Valle d\'Aosta' },
    { 'id': 6954, 'value': 'Istituto superiore di studi musicali di BERGAMO "Gaetano Donizetti"', 'label': 'Istituto superiore di studi musicali di BERGAMO "Gaetano Donizetti"' },
    { 'id': 6997, 'value': 'Istituto superiore di studi musicali di CALTANISSETTA "Vincenzo Bellini"', 'label': 'Istituto superiore di studi musicali di CALTANISSETTA "Vincenzo Bellini"' },
    { 'id': 6998, 'value': 'Istituto superiore di studi musicali di CATANIA "Vincenzo Bellini"', 'label': 'Istituto superiore di studi musicali di CATANIA "Vincenzo Bellini"' },
    { 'id': 6955, 'value': 'Istituto superiore di studi musicali di CREMONA "Claudio Monteverdi"', 'label': 'Istituto superiore di studi musicali di CREMONA "Claudio Monteverdi"' },
    { 'id': 6956, 'value': 'Istituto superiore di studi musicali di GALLARATE "Giacomo Puccini"', 'label': 'Istituto superiore di studi musicali di GALLARATE "Giacomo Puccini"' },
    { 'id': 7001, 'value': 'Istituto superiore di studi musicali di LUCCA "Luigi Boccherini"', 'label': 'Istituto superiore di studi musicali di LUCCA "Luigi Boccherini"' },
    { 'id': 6910, 'value': 'Istituto superiore di studi musicali di MODENA e CARPI "Orazio Vecchi - Antonio Tonelli"', 'label': 'Istituto superiore di studi musicali di MODENA e CARPI "Orazio Vecchi - Antonio Tonelli"' },
    { 'id': 6895, 'value': 'Istituto superiore di studi musicali di NOCERA TERINESE "P.I. Tchaikovsky"', 'label': 'Istituto superiore di studi musicali di NOCERA TERINESE "P.I. Tchaikovsky"' },
    { 'id': 6957, 'value': 'Istituto superiore di studi musicali di PAVIA "Franco Vittadini"', 'label': 'Istituto superiore di studi musicali di PAVIA "Franco Vittadini"' },
    { 'id': 6911, 'value': 'Istituto superiore di studi musicali di RAVENNA "Giuseppe Verdi"', 'label': 'Istituto superiore di studi musicali di RAVENNA "Giuseppe Verdi"' },
    { 'id': 6912, 'value': 'Istituto superiore di studi musicali di REGGIO EMILIA e CASTELNOVO NE\' MONTI "Achille Peri e Merulo"', 'label': 'Istituto superiore di studi musicali di REGGIO EMILIA e CASTELNOVO NE\' MONTI "Achille Peri e Merulo"' },
    { 'id': 6999, 'value': 'Istituto superiore di studi musicali di RIBERA "Arturo Toscanini"', 'label': 'Istituto superiore di studi musicali di RIBERA "Arturo Toscanini"' },
    { 'id': 6913, 'value': 'Istituto superiore di studi musicali di RIMINI "G. Lettimi"', 'label': 'Istituto superiore di studi musicali di RIMINI "G. Lettimi"' },
    { 'id': 7002, 'value': 'Istituto superiore di studi musicali di SIENA "Rinaldo Franci"', 'label': 'Istituto superiore di studi musicali di SIENA "Rinaldo Franci"' },
    { 'id': 6986, 'value': 'Istituto superiore di studi musicali di TARANTO "Giovanni Paisiello"', 'label': 'Istituto superiore di studi musicali di TARANTO "Giovanni Paisiello"' },
    { 'id': 14334, 'value': 'ISTITUTO SUPERIORE DI STUDI MUSICALI DI TARANTO "GIOVANNI PAISIELLO"', 'label': 'ISTITUTO SUPERIORE DI STUDI MUSICALI DI TARANTO "GIOVANNI PAISIELLO"' },
    { 'id': 7014, 'value': 'Istituto superiore di studi musicali di TERNI "Giulio Briccialdi"', 'label': 'Istituto superiore di studi musicali di TERNI "Giulio Briccialdi"' },
    { 'id': 470, 'value': 'Istituto Superiore di Studi Musicali P.Mascagni', 'label': 'Istituto Superiore di Studi Musicali P.Mascagni' },
    { 'id': 6914, 'value': 'Istituto Superiore per le Industrie Artistiche (ISIA) di FAENZA', 'label': 'Istituto Superiore per le Industrie Artistiche (ISIA) di FAENZA' },
    { 'id': 7003, 'value': 'Istituto Superiore per le Industrie Artistiche (ISIA) di FIRENZE', 'label': 'Istituto Superiore per le Industrie Artistiche (ISIA) di FIRENZE' },
    { 'id': 6975, 'value': 'Istituto superiore per le Industrie Artistiche (ISIA) di PESCARA', 'label': 'Istituto superiore per le Industrie Artistiche (ISIA) di PESCARA' },
    { 'id': 6927, 'value': 'Istituto Superiore per le Industrie Artistiche (ISIA) di ROMA', 'label': 'Istituto Superiore per le Industrie Artistiche (ISIA) di ROMA' },
    { 'id': 6965, 'value': 'Istituto Superiore per le Industrie Artistiche (ISIA) di URBINO', 'label': 'Istituto Superiore per le Industrie Artistiche (ISIA) di URBINO' },
    { 'id': 14350, 'value': 'Istituto Tecnico Superiore Apulia Digital Maker', 'label': 'Istituto Tecnico Superiore Apulia Digital Maker' },
    { 'id': 14368, 'value': 'ISTITUTO TECNICO SUPERIORE NUOVE TECNOLOGIE PER IL MADE IN ITALY - MALIGNANI', 'label': 'ISTITUTO TECNICO SUPERIORE NUOVE TECNOLOGIE PER IL MADE IN ITALY - MALIGNANI' },
    { 'id': 14339, 'value': 'Istituto Tecnico Superiore per la Filiera dei Trasporti e della Logistica Intermodale – ITS Lombardo Mobilità Sostenibile', 'label': 'Istituto Tecnico Superiore per la Filiera dei Trasporti e della Logistica Intermodale – ITS Lombardo Mobilità Sostenibile' },
    { 'id': 14351, 'value': 'Istituto Tecnico Superiore per le Nuove Tecnologie della Vita Alessandro Volta', 'label': 'Istituto Tecnico Superiore per le Nuove Tecnologie della Vita Alessandro Volta' },
    { 'id': 6828, 'value': 'Istituto universitario di studi superiori di Pavia', 'label': 'Istituto universitario di studi superiori di Pavia' },
    { 'id': 3833, 'value': 'IT-Universitetet i København', 'label': 'IT-Universitetet i København' },
    { 'id': 4217, 'value': 'Itä-Suomen yliopisto', 'label': 'Itä-Suomen yliopisto' },
    { 'id': 393, 'value': 'Italia Conti Academy', 'label': 'Italia Conti Academy' },
    { 'id': 4392, 'value': 'ITECH Institut Textile et Chimique de Lyon', 'label': 'ITECH Institut Textile et Chimique de Lyon' },
    { 'id': 12835, 'value': 'Ithaca College', 'label': 'Ithaca College' },
    { 'id': 5895, 'value': 'ITM University Gwalior', 'label': 'ITM University Gwalior' },
    { 'id': 5896, 'value': 'ITM University Raipur', 'label': 'ITM University Raipur' },
    { 'id': 5897, 'value': 'ITM Vocational University', 'label': 'ITM Vocational University' },
    { 'id': 10689, 'value': 'ITMO University', 'label': 'ITMO University' },
    { 'id': 233, 'value': 'ITS - Fondazione Mo.So.S.', 'label': 'ITS - Fondazione Mo.So.S.' },
    { 'id': 14323, 'value': 'ITS - TAM Tessile Abbigliamento Moda', 'label': 'ITS - TAM Tessile Abbigliamento Moda' },
    { 'id': 14622, 'value': 'ITS Aerospazio Sicilia', 'label': 'ITS Aerospazio Sicilia' },
    { 'id': 422, 'value': 'ITS Agroalimentare per il Piemonte', 'label': 'ITS Agroalimentare per il Piemonte' },
    { 'id': 419, 'value': 'ITS Biotecnologie Piemonte', 'label': 'ITS Biotecnologie Piemonte' },
    { 'id': 474, 'value': 'ITS Cosmo', 'label': 'ITS Cosmo' },
    { 'id': 601, 'value': 'ITS Incom', 'label': 'ITS Incom' },
    { 'id': 62, 'value': 'ITS La Spezia', 'label': 'ITS La Spezia' },
    { 'id': 333, 'value': 'ITS Maker', 'label': 'ITS Maker' },
    { 'id': 14318, 'value': 'ITS MARCO POLO - FONDAZIONE PER LA MOBILITA\'', 'label': 'ITS MARCO POLO - FONDAZIONE PER LA MOBILITA\'' },
    { 'id': 14388, 'value': 'ITS Mita | Made in Italy Tuscany Academy', 'label': 'ITS Mita | Made in Italy Tuscany Academy' },
    { 'id': 14347, 'value': 'ITS NUOVE TECNOLOGIE DELLA VITA - ROMA', 'label': 'ITS NUOVE TECNOLOGIE DELLA VITA - ROMA' },
    { 'id': 552, 'value': 'ITS Nuove Tecnologie della vita (Bergamo-Lainate)', 'label': 'ITS Nuove Tecnologie della vita (Bergamo-Lainate)' },
    { 'id': 14369, 'value': 'ITS Nuove Tecnologie della vita (Lainate)', 'label': 'ITS Nuove Tecnologie della vita (Lainate)' },
    { 'id': 14370, 'value': 'ITS Nuove Tecnologie della vita (Lainate)', 'label': 'ITS Nuove Tecnologie della vita (Lainate)' },
    { 'id': 14356, 'value': 'ITS per le nuove tecnologie per il Made in Italy - Viterbo', 'label': 'ITS per le nuove tecnologie per il Made in Italy - Viterbo' },
    { 'id': 540, 'value': 'ITS per le nuove tecnologie per il Made in Italy Machina Lonati', 'label': 'ITS per le nuove tecnologie per il Made in Italy Machina Lonati' },
    { 'id': 438, 'value': 'ITS Piemonte - tecnologie dell\'informazione e della comunicazione', 'label': 'ITS Piemonte - tecnologie dell\'informazione e della comunicazione' },
    { 'id': 424, 'value': 'ITS RED ACADEMY', 'label': 'ITS RED ACADEMY' },
    { 'id': 423, 'value': 'ITS Turismo Marche', 'label': 'ITS Turismo Marche' },
    { 'id': 453, 'value': 'ITS Turismo Piemonte', 'label': 'ITS Turismo Piemonte' },
    { 'id': 11347, 'value': 'Ittihad Private University', 'label': 'Ittihad Private University' },
    { 'id': 481, 'value': 'IUAD - Accademia della Moda', 'label': 'IUAD - Accademia della Moda' },
    { 'id': 3670, 'value': 'IUBH Duales Studium', 'label': 'IUBH Duales Studium' },
    { 'id': 428, 'value': 'IUBH Internationale Hochschule', 'label': 'IUBH Internationale Hochschule' },
    { 'id': 3671, 'value': 'IUBH School of Business and Management', 'label': 'IUBH School of Business and Management' },
    { 'id': 14, 'value': 'IULM (Libera Università di Lingue e Comunicazione)', 'label': 'IULM (Libera Università di Lingue e Comunicazione)' },
    { 'id': 229, 'value': 'IUSTO - Istituto Universitario Salesiano Torino', 'label': 'IUSTO - Istituto Universitario Salesiano Torino' },
    { 'id': 129, 'value': 'IUSVE - Istituto Universitario Salesiano Venezia', 'label': 'IUSVE - Istituto Universitario Salesiano Venezia' },
    { 'id': 12012, 'value': 'Ivan Franko National University of Lviv', 'label': 'Ivan Franko National University of Lviv' },
    { 'id': 4642, 'value': 'Ivane Javakhishvili Tbilisi State University', 'label': 'Ivane Javakhishvili Tbilisi State University' },
    { 'id': 12013, 'value': 'Ivano-Frankivsk National Medical University', 'label': 'Ivano-Frankivsk National Medical University' },
    { 'id': 12014, 'value': 'Ivano-Frankivsk National Technical University of Oil and Gas', 'label': 'Ivano-Frankivsk National Technical University of Oil and Gas' },
    { 'id': 10690, 'value': 'Ivanovo State Academy of Agriculture', 'label': 'Ivanovo State Academy of Agriculture' },
    { 'id': 10691, 'value': 'Ivanovo State Medical Academy', 'label': 'Ivanovo State Medical Academy' },
    { 'id': 10692, 'value': 'Ivanovo State Polytechnic University', 'label': 'Ivanovo State Polytechnic University' },
    { 'id': 10693, 'value': 'Ivanovo State Power University', 'label': 'Ivanovo State Power University' },
    { 'id': 10694, 'value': 'Ivanovo State University', 'label': 'Ivanovo State University' },
    { 'id': 10695, 'value': 'Ivanovo State University of Chemistry and Technology', 'label': 'Ivanovo State University of Chemistry and Technology' },
    { 'id': 7237, 'value': 'Iwate Medical University', 'label': 'Iwate Medical University' },
    { 'id': 7238, 'value': 'Iwate Prefectural University', 'label': 'Iwate Prefectural University' },
    { 'id': 7239, 'value': 'Iwate University', 'label': 'Iwate University' },
    { 'id': 10696, 'value': 'Izhevsk State Agricultural Academy', 'label': 'Izhevsk State Agricultural Academy' },
    { 'id': 10697, 'value': 'Izhevsk State Medical Academy', 'label': 'Izhevsk State Medical Academy' },
    { 'id': 12015, 'value': 'Izmail State University of Humanities', 'label': 'Izmail State University of Humanities' },
    { 'id': 11725, 'value': 'Izmir Demokrasi Üniversitesi', 'label': 'Izmir Demokrasi Üniversitesi' },
    { 'id': 11726, 'value': 'Izmir Ekonomi Üniversitesi', 'label': 'Izmir Ekonomi Üniversitesi' },
    { 'id': 11727, 'value': 'Izmir Kâtip Çelebi Üniversitesi', 'label': 'Izmir Kâtip Çelebi Üniversitesi' },
    { 'id': 11728, 'value': 'Izmir Yüksek Teknoloji Enstitüsü', 'label': 'Izmir Yüksek Teknoloji Enstitüsü' },
    { 'id': 5898, 'value': 'J.C. Bose University of Science and Technology', 'label': 'J.C. Bose University of Science and Technology' },
    { 'id': 11254, 'value': 'Jaamacada Badweynta Hindiya', 'label': 'Jaamacada Badweynta Hindiya' },
    { 'id': 11255, 'value': 'Jaamacada Bariga Afrika', 'label': 'Jaamacada Bariga Afrika' },
    { 'id': 11256, 'value': 'Jaamacada Burco', 'label': 'Jaamacada Burco' },
    { 'id': 11257, 'value': 'Jaamacada Eelo', 'label': 'Jaamacada Eelo' },
    { 'id': 11258, 'value': 'Jaamacada Gedo', 'label': 'Jaamacada Gedo' },
    { 'id': 11259, 'value': 'Jaamacada Golis', 'label': 'Jaamacada Golis' },
    { 'id': 11260, 'value': 'Jaamacada Hargeysa', 'label': 'Jaamacada Hargeysa' },
    { 'id': 11261, 'value': 'Jaamacada Hiiraan', 'label': 'Jaamacada Hiiraan' },
    { 'id': 11262, 'value': 'Jaamacada Jamhuuriya ee Culuumta iyo Tiknooloojiyada', 'label': 'Jaamacada Jamhuuriya ee Culuumta iyo Tiknooloojiyada' },
    { 'id': 11263, 'value': 'Jaamacada Jazeera', 'label': 'Jaamacada Jazeera' },
    { 'id': 11264, 'value': 'Jaamacada Kismaayo', 'label': 'Jaamacada Kismaayo' },
    { 'id': 11265, 'value': 'Jaamacada Koonfur Galbeed Soomaaliya', 'label': 'Jaamacada Koonfur Galbeed Soomaaliya' },
    { 'id': 11266, 'value': 'Jaamacada Nugaal', 'label': 'Jaamacada Nugaal' },
    { 'id': 11267, 'value': 'Jaamacada Plasma', 'label': 'Jaamacada Plasma' },
    { 'id': 11268, 'value': 'Jaamacada Ummada Soomaaliyeed', 'label': 'Jaamacada Ummada Soomaaliyeed' },
    { 'id': 11269, 'value': 'Jaamacadda Banaadir', 'label': 'Jaamacadda Banaadir' },
    { 'id': 11270, 'value': 'Jaamacadda Caalamiga ah ee Horseed', 'label': 'Jaamacadda Caalamiga ah ee Horseed' },
    { 'id': 11271, 'value': 'Jaamacadda Camuud', 'label': 'Jaamacadda Camuud' },
    { 'id': 11272, 'value': 'Jaamacadda Jobkey', 'label': 'Jaamacadda Jobkey' },
    { 'id': 11273, 'value': 'Jaamacadda Juba ee Cilmiga iyo Tiknolojiyada', 'label': 'Jaamacadda Juba ee Cilmiga iyo Tiknolojiyada' },
    { 'id': 11274, 'value': 'Jaamacadda Muqdisho', 'label': 'Jaamacadda Muqdisho' },
    { 'id': 11275, 'value': 'Jaamacadda Puntland State University', 'label': 'Jaamacadda Puntland State University' },
    { 'id': 11276, 'value': 'Jaamacadda Salaam', 'label': 'Jaamacadda Salaam' },
    { 'id': 11277, 'value': 'Jaamacadda Sayniska iyo Teknolojiyada Sanaag', 'label': 'Jaamacadda Sayniska iyo Teknolojiyada Sanaag' },
    { 'id': 11278, 'value': 'Jaamacadda SIMAD', 'label': 'Jaamacadda SIMAD' },
    { 'id': 11279, 'value': 'Jaamacadda Soomaaliya', 'label': 'Jaamacadda Soomaaliya' },
    { 'id': 11280, 'value': 'Jaamacadda Teknoolajiyadda Somaliland', 'label': 'Jaamacadda Teknoolajiyadda Somaliland' },
    { 'id': 11281, 'value': 'Jaamacadda Zamzam ee Culuumta iyo Tiknolojiyadda', 'label': 'Jaamacadda Zamzam ee Culuumta iyo Tiknolojiyadda' },
    { 'id': 6454, 'value': 'Jabir Ibn Hayyan Medical University', 'label': 'Jabir Ibn Hayyan Medical University' },
    { 'id': 12836, 'value': 'Jackson State University', 'label': 'Jackson State University' },
    { 'id': 12837, 'value': 'Jacksonville State University', 'label': 'Jacksonville State University' },
    { 'id': 12838, 'value': 'Jacksonville University', 'label': 'Jacksonville University' },
    { 'id': 3672, 'value': 'Jacobs University Bremen', 'label': 'Jacobs University Bremen' },
    { 'id': 7048, 'value': 'Jadara University', 'label': 'Jadara University' },
    { 'id': 5899, 'value': 'Jadavpur University', 'label': 'Jadavpur University' },
    { 'id': 3673, 'value': 'Jade Hochschule', 'label': 'Jade Hochschule' },
    { 'id': 5900, 'value': 'Jagadguru Ramanandacharya Rajasthan Sanskrit University', 'label': 'Jagadguru Ramanandacharya Rajasthan Sanskrit University' },
    { 'id': 5901, 'value': 'Jagadguru Rambhadracharya Handicapped University', 'label': 'Jagadguru Rambhadracharya Handicapped University' },
    { 'id': 5902, 'value': 'Jagan Nath University', 'label': 'Jagan Nath University' },
    { 'id': 1266, 'value': 'Jagannath University', 'label': 'Jagannath University' },
    { 'id': 5903, 'value': 'Jagran Lakecity University', 'label': 'Jagran Lakecity University' },
    { 'id': 718, 'value': 'Jahan E Noor Institute of Higher Education', 'label': 'Jahan E Noor Institute of Higher Education' },
    { 'id': 719, 'value': 'Jahan University', 'label': 'Jahan University' },
    { 'id': 1267, 'value': 'Jahangirnagar University', 'label': 'Jahangirnagar University' },
    { 'id': 13993, 'value': 'Jahon Iqtisodiyoti va Diplomatiya Universiteti', 'label': 'Jahon Iqtisodiyoti va Diplomatiya Universiteti' },
    { 'id': 6698, 'value': 'Jahrom university', 'label': 'Jahrom university' },
    { 'id': 6699, 'value': 'Jahrom University of Medical Sciences', 'label': 'Jahrom University of Medical Sciences' },
    { 'id': 5904, 'value': 'Jai Narain Vyas University', 'label': 'Jai Narain Vyas University' },
    { 'id': 5905, 'value': 'Jai Prakash Vishwavidyalaya', 'label': 'Jai Prakash Vishwavidyalaya' },
    { 'id': 5906, 'value': 'Jain University', 'label': 'Jain University' },
    { 'id': 5907, 'value': 'Jain Vishva Bharati Institute', 'label': 'Jain Vishva Bharati Institute' },
    { 'id': 5908, 'value': 'Jaipur National University', 'label': 'Jaipur National University' },
    { 'id': 7858, 'value': 'Jalal-Abad Oblast of Humanities and Education University', 'label': 'Jalal-Abad Oblast of Humanities and Education University' },
    { 'id': 7859, 'value': 'Jalalabad State University', 'label': 'Jalalabad State University' },
    { 'id': 1027, 'value': 'Jam Music Lab Private University', 'label': 'Jam Music Lab Private University' },
    { 'id': 1082, 'value': 'James Cook University', 'label': 'James Cook University' },
    { 'id': 12839, 'value': 'James Madison University', 'label': 'James Madison University' },
    { 'id': 12840, 'value': 'Jamestown Business College', 'label': 'Jamestown Business College' },
    { 'id': 6700, 'value': 'Jami Institute of Technology', 'label': 'Jami Institute of Technology' },
    { 'id': 5909, 'value': 'Jamia Hamdard', 'label': 'Jamia Hamdard' },
    { 'id': 5910, 'value': 'Jamia Millia Islamia', 'label': 'Jamia Millia Islamia' },
    { 'id': 3404, 'value': 'Janáckova akademie múzických umení v Brne', 'label': 'Janáckova akademie múzických umení v Brne' },
    { 'id': 5911, 'value': 'Jananayak Chandrashekhar University', 'label': 'Jananayak Chandrashekhar University' },
    { 'id': 5912, 'value': 'Janardan Rai Nagar Rajasthan Vidhyapeeth University', 'label': 'Janardan Rai Nagar Rajasthan Vidhyapeeth University' },
    { 'id': 7240, 'value': 'Japan Advanced Institute of Science and Technology', 'label': 'Japan Advanced Institute of Science and Technology' },
    { 'id': 7241, 'value': 'Japan College of Social Work', 'label': 'Japan College of Social Work' },
    { 'id': 7242, 'value': 'Japan Lutheran College', 'label': 'Japan Lutheran College' },
    { 'id': 7243, 'value': 'Japan University of Economics', 'label': 'Japan University of Economics' },
    { 'id': 7244, 'value': 'Japan Women\'s College of Physical Education', 'label': 'Japan Women\'s College of Physical Education' },
    { 'id': 7245, 'value': 'Japan Women\'s University', 'label': 'Japan Women\'s University' },
    { 'id': 7246, 'value': 'Japanese Red Cross Hokkaido College of Nursing', 'label': 'Japanese Red Cross Hokkaido College of Nursing' },
    { 'id': 7247, 'value': 'Japanese Red Cross Toyota College of Nursing', 'label': 'Japanese Red Cross Toyota College of Nursing' },
    { 'id': 7792, 'value': 'Jaramogi Oginga Odinga University of Science and Technology', 'label': 'Jaramogi Oginga Odinga University of Science and Technology' },
    { 'id': 12841, 'value': 'Jarvis Christian College', 'label': 'Jarvis Christian College' },
    { 'id': 1268, 'value': 'Jatiya Kabi Kazi Nazrul Islam University', 'label': 'Jatiya Kabi Kazi Nazrul Islam University' },
    { 'id': 5913, 'value': 'Jawaharlal Institute of Postgraduate Medical Education and Research', 'label': 'Jawaharlal Institute of Postgraduate Medical Education and Research' },
    { 'id': 5914, 'value': 'Jawaharlal Nehru Architecture and Fine Arts University', 'label': 'Jawaharlal Nehru Architecture and Fine Arts University' },
    { 'id': 5915, 'value': 'Jawaharlal Nehru Centre for Advanced Scientific Research', 'label': 'Jawaharlal Nehru Centre for Advanced Scientific Research' },
    { 'id': 5916, 'value': 'Jawaharlal Nehru Krishi Vishwavidyalaya', 'label': 'Jawaharlal Nehru Krishi Vishwavidyalaya' },
    { 'id': 5917, 'value': 'Jawaharlal Nehru Technological University', 'label': 'Jawaharlal Nehru Technological University' },
    { 'id': 5918, 'value': 'Jawaharlal Nehru Technological University, Anantapur', 'label': 'Jawaharlal Nehru Technological University, Anantapur' },
    { 'id': 5919, 'value': 'Jawaharlal Nehru Technological University, Kakinada', 'label': 'Jawaharlal Nehru Technological University, Kakinada' },
    { 'id': 5920, 'value': 'Jawaharlal Nehru University', 'label': 'Jawaharlal Nehru University' },
    { 'id': 720, 'value': 'Jawzjan University', 'label': 'Jawzjan University' },
    { 'id': 4691, 'value': 'Jayee University College', 'label': 'Jayee University College' },
    { 'id': 5921, 'value': 'Jayoti Vidyapeeth Women\'s University', 'label': 'Jayoti Vidyapeeth Women\'s University' },
    { 'id': 5922, 'value': 'Jaypee Institute of Information Technology', 'label': 'Jaypee Institute of Information Technology' },
    { 'id': 5923, 'value': 'Jaypee University Anoopshahr', 'label': 'Jaypee University Anoopshahr' },
    { 'id': 5924, 'value': 'Jaypee University of Engineering and Technology', 'label': 'Jaypee University of Engineering and Technology' },
    { 'id': 5925, 'value': 'Jaypee University of Information Technology', 'label': 'Jaypee University of Information Technology' },
    { 'id': 11029, 'value': 'Jazan University', 'label': 'Jazan University' },
    { 'id': 8375, 'value': 'Jazepa Vitola Latvijas muzikas akademija', 'label': 'Jazepa Vitola Latvijas muzikas akademija' },
    { 'id': 5926, 'value': 'JECRC University', 'label': 'JECRC University' },
    { 'id': 8048, 'value': 'Jeju International University', 'label': 'Jeju International University' },
    { 'id': 8049, 'value': 'Jeju National University', 'label': 'Jeju National University' },
    { 'id': 8050, 'value': 'Jeonju National University of Education', 'label': 'Jeonju National University of Education' },
    { 'id': 8051, 'value': 'Jeonju University', 'label': 'Jeonju University' },
    { 'id': 7049, 'value': 'Jerash Private University', 'label': 'Jerash Private University' },
    { 'id': 5541, 'value': 'Jerusalem College of Engineering', 'label': 'Jerusalem College of Engineering' },
    { 'id': 5542, 'value': 'Jerusalem College of Technology', 'label': 'Jerusalem College of Technology' },
    { 'id': 1269, 'value': 'Jessore University of Science and Technology', 'label': 'Jessore University of Science and Technology' },
    { 'id': 8052, 'value': 'Jesus University', 'label': 'Jesus University' },
    { 'id': 5927, 'value': 'Jharkhand Rai University', 'label': 'Jharkhand Rai University' },
    { 'id': 5928, 'value': 'Jharkhand Raksha Shakti University', 'label': 'Jharkhand Raksha Shakti University' },
    { 'id': 2499, 'value': 'Jiamusi University', 'label': 'Jiamusi University' },
    { 'id': 2500, 'value': 'Jianghan University', 'label': 'Jianghan University' },
    { 'id': 2501, 'value': 'Jiangnan University', 'label': 'Jiangnan University' },
    { 'id': 2502, 'value': 'Jiangsu Normal University', 'label': 'Jiangsu Normal University' },
    { 'id': 2503, 'value': 'Jiangsu Second Normal University', 'label': 'Jiangsu Second Normal University' },
    { 'id': 2504, 'value': 'Jiangsu Teachers University of Technology', 'label': 'Jiangsu Teachers University of Technology' },
    { 'id': 2505, 'value': 'Jiangsu University', 'label': 'Jiangsu University' },
    { 'id': 2506, 'value': 'Jiangsu University of Science and Technology', 'label': 'Jiangsu University of Science and Technology' },
    { 'id': 2507, 'value': 'Jiangsu University of Technology', 'label': 'Jiangsu University of Technology' },
    { 'id': 2508, 'value': 'Jiangxi Agricultural University', 'label': 'Jiangxi Agricultural University' },
    { 'id': 2509, 'value': 'Jiangxi Institute of Fashion Technology', 'label': 'Jiangxi Institute of Fashion Technology' },
    { 'id': 2510, 'value': 'Jiangxi Normal University', 'label': 'Jiangxi Normal University' },
    { 'id': 2511, 'value': 'Jiangxi Science and Technology Normal University', 'label': 'Jiangxi Science and Technology Normal University' },
    { 'id': 2512, 'value': 'Jiangxi University of Finance and Economics', 'label': 'Jiangxi University of Finance and Economics' },
    { 'id': 2513, 'value': 'Jiangxi University of Science and Technology', 'label': 'Jiangxi University of Science and Technology' },
    { 'id': 2514, 'value': 'Jiangxi University of Technology', 'label': 'Jiangxi University of Technology' },
    { 'id': 2515, 'value': 'Jiangxi University of Traditional Chinese Medicine', 'label': 'Jiangxi University of Traditional Chinese Medicine' },
    { 'id': 2516, 'value': 'Jiaxing University', 'label': 'Jiaxing University' },
    { 'id': 2517, 'value': 'Jiaying University', 'label': 'Jiaying University' },
    { 'id': 7248, 'value': 'Jichi Medical University', 'label': 'Jichi Medical University' },
    { 'id': 4190, 'value': 'Jigjiga University', 'label': 'Jigjiga University' },
    { 'id': 3405, 'value': 'Jihoceská univerzita v Ceských Budejovicích', 'label': 'Jihoceská univerzita v Ceských Budejovicích' },
    { 'id': 7249, 'value': 'Jikei University School of Medicine', 'label': 'Jikei University School of Medicine' },
    { 'id': 2518, 'value': 'Jilin Agricultural Science and Technology College', 'label': 'Jilin Agricultural Science and Technology College' },
    { 'id': 2519, 'value': 'Jilin Agricultural University', 'label': 'Jilin Agricultural University' },
    { 'id': 2520, 'value': 'Jilin Animation Institute', 'label': 'Jilin Animation Institute' },
    { 'id': 2521, 'value': 'Jilin Business and Technology College', 'label': 'Jilin Business and Technology College' },
    { 'id': 2522, 'value': 'Jilin College of the Arts', 'label': 'Jilin College of the Arts' },
    { 'id': 2523, 'value': 'Jilin Engineering Normal University', 'label': 'Jilin Engineering Normal University' },
    { 'id': 2524, 'value': 'Jilin Huaqiao University of Foreign Languages', 'label': 'Jilin Huaqiao University of Foreign Languages' },
    { 'id': 2525, 'value': 'Jilin Institute of Chemical Technology', 'label': 'Jilin Institute of Chemical Technology' },
    { 'id': 2526, 'value': 'Jilin Jianzhu University', 'label': 'Jilin Jianzhu University' },
    { 'id': 2527, 'value': 'Jilin Medical University', 'label': 'Jilin Medical University' },
    { 'id': 2528, 'value': 'Jilin Normal University', 'label': 'Jilin Normal University' },
    { 'id': 2529, 'value': 'Jilin Sport University', 'label': 'Jilin Sport University' },
    { 'id': 2530, 'value': 'Jilin University', 'label': 'Jilin University' },
    { 'id': 2531, 'value': 'Jilin University of Finance and Economics', 'label': 'Jilin University of Finance and Economics' },
    { 'id': 2532, 'value': 'Jimei University', 'label': 'Jimei University' },
    { 'id': 4191, 'value': 'Jimma University', 'label': 'Jimma University' },
    { 'id': 7250, 'value': 'Jin-ai University', 'label': 'Jin-ai University' },
    { 'id': 2533, 'value': 'Jinan University', 'label': 'Jinan University' },
    { 'id': 8286, 'value': 'Jinan University of Lebanon', 'label': 'Jinan University of Lebanon' },
    { 'id': 2534, 'value': 'Jingchu University of Technology', 'label': 'Jingchu University of Technology' },
    { 'id': 2535, 'value': 'Jingdezhen Ceramic Institute', 'label': 'Jingdezhen Ceramic Institute' },
    { 'id': 2536, 'value': 'Jingdezhen University', 'label': 'Jingdezhen University' },
    { 'id': 2537, 'value': 'Jinggangshan University', 'label': 'Jinggangshan University' },
    { 'id': 2538, 'value': 'Jining Medical University', 'label': 'Jining Medical University' },
    { 'id': 2539, 'value': 'Jining Normal University', 'label': 'Jining Normal University' },
    { 'id': 2540, 'value': 'Jining University', 'label': 'Jining University' },
    { 'id': 2541, 'value': 'Jinling Institute of Technology', 'label': 'Jinling Institute of Technology' },
    { 'id': 10105, 'value': 'Jinnah Sindh Medical University', 'label': 'Jinnah Sindh Medical University' },
    { 'id': 10106, 'value': 'Jinnah University for Women', 'label': 'Jinnah University for Women' },
    { 'id': 11846, 'value': 'Jinwen University of Science and Technology', 'label': 'Jinwen University of Science and Technology' },
    { 'id': 2542, 'value': 'Jinzhong University', 'label': 'Jinzhong University' },
    { 'id': 2543, 'value': 'Jinzhou Medical University', 'label': 'Jinzhou Medical University' },
    { 'id': 5929, 'value': 'JIS University', 'label': 'JIS University' },
    { 'id': 2544, 'value': 'Jishou University', 'label': 'Jishou University' },
    { 'id': 7251, 'value': 'Jissen Women\'s University', 'label': 'Jissen Women\'s University' },
    { 'id': 2545, 'value': 'Jiujiang University', 'label': 'Jiujiang University' },
    { 'id': 5930, 'value': 'Jiwaji University', 'label': 'Jiwaji University' },
    { 'id': 13994, 'value': 'Jizzax Davlat Pedagogika Instituti', 'label': 'Jizzax Davlat Pedagogika Instituti' },
    { 'id': 13995, 'value': 'Jizzax Politexnika Instituti', 'label': 'Jizzax Politexnika Instituti' },
    { 'id': 5931, 'value': 'JK Lakshmipat University', 'label': 'JK Lakshmipat University' },
    { 'id': 7252, 'value': 'Jobu University', 'label': 'Jobu University' },
    { 'id': 5932, 'value': 'Jodhpur National University', 'label': 'Jodhpur National University' },
    { 'id': 7253, 'value': 'Joetsu University of Education', 'label': 'Joetsu University of Education' },
    { 'id': 3674, 'value': 'Johannes Gutenberg-Universität Mainz', 'label': 'Johannes Gutenberg-Universität Mainz' },
    { 'id': 1028, 'value': 'Johannes Kepler Universität Linz', 'label': 'Johannes Kepler Universität Linz' },
    { 'id': 9897, 'value': 'John B. Lacson Foundation Maritime University', 'label': 'John B. Lacson Foundation Maritime University' },
    { 'id': 12842, 'value': 'John Brown University', 'label': 'John Brown University' },
    { 'id': 359, 'value': 'John Cabot University', 'label': 'John Cabot University' },
    { 'id': 12843, 'value': 'John Carroll University', 'label': 'John Carroll University' },
    { 'id': 14264, 'value': 'John David University', 'label': 'John David University' },
    { 'id': 12844, 'value': 'John F. Kennedy University', 'label': 'John F. Kennedy University' },
    { 'id': 11287, 'value': 'John Garang Memorial University of Science and Technology', 'label': 'John Garang Memorial University of Science and Technology' },
    { 'id': 12845, 'value': 'John Jay College of Criminal Justice', 'label': 'John Jay College of Criminal Justice' },
    { 'id': 12846, 'value': 'John Paul the Great Catholic University', 'label': 'John Paul the Great Catholic University' },
    { 'id': 488, 'value': 'Johns Hopkins University', 'label': 'Johns Hopkins University' },
    { 'id': 12847, 'value': 'Johnson and Wales University', 'label': 'Johnson and Wales University' },
    { 'id': 12848, 'value': 'Johnson C. Smith University', 'label': 'Johnson C. Smith University' },
    { 'id': 12849, 'value': 'Johnson University', 'label': 'Johnson University' },
    { 'id': 7793, 'value': 'Jomo Kenyatta University of Agriculture and Technology', 'label': 'Jomo Kenyatta University of Agriculture and Technology' },
    { 'id': 10998, 'value': 'Jomo Kenyatta University of Agriculture and Technology, Kigali Campus', 'label': 'Jomo Kenyatta University of Agriculture and Technology, Kigali Campus' },
    { 'id': 456, 'value': 'Jönköping University', 'label': 'Jönköping University' },
    { 'id': 8053, 'value': 'Joongang Sangha University', 'label': 'Joongang Sangha University' },
    { 'id': 8054, 'value': 'Joongbu University', 'label': 'Joongbu University' },
    { 'id': 7050, 'value': 'Jordan Academy of Music', 'label': 'Jordan Academy of Music' },
    { 'id': 7051, 'value': 'Jordan University of Science and Technology', 'label': 'Jordan University of Science and Technology' },
    { 'id': 7254, 'value': 'Josai International University', 'label': 'Josai International University' },
    { 'id': 7255, 'value': 'Josai University', 'label': 'Josai University' },
    { 'id': 9898, 'value': 'José Rizal University', 'label': 'José Rizal University' },
    { 'id': 9381, 'value': 'Joseph Ayo Babalola University', 'label': 'Joseph Ayo Babalola University' },
    { 'id': 7256, 'value': 'Joshibi University of Art and Design', 'label': 'Joshibi University of Art and Design' },
    { 'id': 5933, 'value': 'JS University', 'label': 'JS University' },
    { 'id': 5934, 'value': 'JSS Academy of Higher Education and Research', 'label': 'JSS Academy of Higher Education and Research' },
    { 'id': 5935, 'value': 'JSS Science and Technology University', 'label': 'JSS Science and Technology University' },
    { 'id': 12850, 'value': 'Judson College', 'label': 'Judson College' },
    { 'id': 12851, 'value': 'Judson University', 'label': 'Judson University' },
    { 'id': 3675, 'value': 'Julius-Maximilians-Universität Würzburg', 'label': 'Julius-Maximilians-Universität Würzburg' },
    { 'id': 647, 'value': 'Jumeira University', 'label': 'Jumeira University' },
    { 'id': 7257, 'value': 'Jumonji University', 'label': 'Jumonji University' },
    { 'id': 5936, 'value': 'Junagadh Agricultural University', 'label': 'Junagadh Agricultural University' },
    { 'id': 6701, 'value': 'Jundi-Shapur University of Technology', 'label': 'Jundi-Shapur University of Technology' },
    { 'id': 8055, 'value': 'Jungwon University', 'label': 'Jungwon University' },
    { 'id': 12852, 'value': 'Juniata College', 'label': 'Juniata College' },
    { 'id': 7258, 'value': 'Juntendo University', 'label': 'Juntendo University' },
    { 'id': 14265, 'value': 'Justo Mwale University', 'label': 'Justo Mwale University' },
    { 'id': 3676, 'value': 'Justus-Liebig-Universität Giessen', 'label': 'Justus-Liebig-Universität Giessen' },
    { 'id': 4218, 'value': 'Jyväskylän ammattikorkeakoulu', 'label': 'Jyväskylän ammattikorkeakoulu' },
    { 'id': 4219, 'value': 'Jyväskylän yliopisto', 'label': 'Jyväskylän yliopisto' },
    { 'id': 5937, 'value': 'K L University', 'label': 'K L University' },
    { 'id': 7860, 'value': 'K. Sh. Toktomamatova International University', 'label': 'K. Sh. Toktomamatova International University' },
    { 'id': 5938, 'value': 'K.K. University', 'label': 'K.K. University' },
    { 'id': 5939, 'value': 'K.R. Mangalam University', 'label': 'K.R. Mangalam University' },
    { 'id': 4692, 'value': 'KAAF University College', 'label': 'KAAF University College' },
    { 'id': 4220, 'value': 'Kaakkois-Suomen ammattikorkeakoulu', 'label': 'Kaakkois-Suomen ammattikorkeakoulu' },
    { 'id': 12175, 'value': 'Kabale University', 'label': 'Kabale University' },
    { 'id': 7794, 'value': 'Kabarak University', 'label': 'Kabarak University' },
    { 'id': 10698, 'value': 'Kabardino-Balkarian State Agricultural University', 'label': 'Kabardino-Balkarian State Agricultural University' },
    { 'id': 10699, 'value': 'Kabardino-Balkarian State University', 'label': 'Kabardino-Balkarian State University' },
    { 'id': 721, 'value': 'Kaboora Institute of Higher Education', 'label': 'Kaboora Institute of Higher Education' },
    { 'id': 722, 'value': 'Kabul Polytechnic University', 'label': 'Kabul Polytechnic University' },
    { 'id': 723, 'value': 'Kabul University', 'label': 'Kabul University' },
    { 'id': 724, 'value': 'Kabul University of Medical Sciences', 'label': 'Kabul University of Medical Sciences' },
    { 'id': 5940, 'value': 'Kadi Sarva Vishwavidyalaya', 'label': 'Kadi Sarva Vishwavidyalaya' },
    { 'id': 11729, 'value': 'Kadir Has Üniversitesi', 'label': 'Kadir Has Üniversitesi' },
    { 'id': 9382, 'value': 'Kaduna State University', 'label': 'Kaduna State University' },
    { 'id': 7259, 'value': 'Kaetsu University', 'label': 'Kaetsu University' },
    { 'id': 11730, 'value': 'Kafkas Üniversitesi', 'label': 'Kafkas Üniversitesi' },
    { 'id': 4063, 'value': 'Kafrelsheikh University', 'label': 'Kafrelsheikh University' },
    { 'id': 7795, 'value': 'KAG East University', 'label': 'KAG East University' },
    { 'id': 7260, 'value': 'Kagawa Nutrition University', 'label': 'Kagawa Nutrition University' },
    { 'id': 7261, 'value': 'Kagawa Prefectural College of Health Sciences', 'label': 'Kagawa Prefectural College of Health Sciences' },
    { 'id': 7262, 'value': 'Kagawa University', 'label': 'Kagawa University' },
    { 'id': 7263, 'value': 'Kagoshima Immaculate Heart University', 'label': 'Kagoshima Immaculate Heart University' },
    { 'id': 7264, 'value': 'Kagoshima University', 'label': 'Kagoshima University' },
    { 'id': 725, 'value': 'Kahkashan-e-Sharq Higher Education Institute', 'label': 'Kahkashan-e-Sharq Higher Education Institute' },
    { 'id': 11731, 'value': 'Kahramanmaras Sütçü Imam Üniversitesi', 'label': 'Kahramanmaras Sütçü Imam Üniversitesi' },
    { 'id': 7265, 'value': 'Kaichi International University', 'label': 'Kaichi International University' },
    { 'id': 2546, 'value': 'Kaili University', 'label': 'Kaili University' },
    { 'id': 11847, 'value': 'Kainan University', 'label': 'Kainan University' },
    { 'id': 8194, 'value': 'Kainar University', 'label': 'Kainar University' },
    { 'id': 8056, 'value': 'KAIST', 'label': 'KAIST' },
    { 'id': 4221, 'value': 'Kajaanin ammattikorkeakoulu', 'label': 'Kajaanin ammattikorkeakoulu' },
    { 'id': 5941, 'value': 'Kakatiya University', 'label': 'Kakatiya University' },
    { 'id': 2006, 'value': 'Kalaidos Fachhochschule', 'label': 'Kalaidos Fachhochschule' },
    { 'id': 12853, 'value': 'Kalamazoo College', 'label': 'Kalamazoo College' },
    { 'id': 5942, 'value': 'Kalasalingam University', 'label': 'Kalasalingam University' },
    { 'id': 10700, 'value': 'Kalashnikov Izhevsk State Technical University', 'label': 'Kalashnikov Izhevsk State Technical University' },
    { 'id': 11401, 'value': 'Kalasin University', 'label': 'Kalasin University' },
    { 'id': 8507, 'value': 'Kalay University', 'label': 'Kalay University' },
    { 'id': 5943, 'value': 'Kalinga University', 'label': 'Kalinga University' },
    { 'id': 10701, 'value': 'Kaliningrad State Technical University', 'label': 'Kaliningrad State Technical University' },
    { 'id': 10702, 'value': 'Kalmyk State University', 'label': 'Kalmyk State University' },
    { 'id': 10703, 'value': 'Kaluga State University', 'label': 'Kaluga State University' },
    { 'id': 7266, 'value': 'Kamakura Women\'s University', 'label': 'Kamakura Women\'s University' },
    { 'id': 10704, 'value': 'Kamchatka State Technical University', 'label': 'Kamchatka State Technical University' },
    { 'id': 10705, 'value': 'Kamchatka State University', 'label': 'Kamchatka State University' },
    { 'id': 5944, 'value': 'Kamdhenu University', 'label': 'Kamdhenu University' },
    { 'id': 5945, 'value': 'Kameshwar Singh Darbhanga Sanskrit University', 'label': 'Kameshwar Singh Darbhanga Sanskrit University' },
    { 'id': 13996, 'value': 'Kamoliddin Behzod Nomidagi Milliy Rassomlik Va Dizayn Instituti', 'label': 'Kamoliddin Behzod Nomidagi Milliy Rassomlik Va Dizayn Instituti' },
    { 'id': 12176, 'value': 'Kampala International University', 'label': 'Kampala International University' },
    { 'id': 12177, 'value': 'Kampala University', 'label': 'Kampala University' },
    { 'id': 11402, 'value': 'Kamphaeng Phet Rajabhat University', 'label': 'Kamphaeng Phet Rajabhat University' },
    { 'id': 7906, 'value': 'Kampong Cham National School of Agriculture', 'label': 'Kampong Cham National School of Agriculture' },
    { 'id': 12016, 'value': 'Kamyanets-Podilsky Ivan Ohienko National University', 'label': 'Kamyanets-Podilsky Ivan Ohienko National University' },
    { 'id': 7267, 'value': 'Kanagawa Dental University', 'label': 'Kanagawa Dental University' },
    { 'id': 7268, 'value': 'Kanagawa Institute of Technology', 'label': 'Kanagawa Institute of Technology' },
    { 'id': 7269, 'value': 'Kanagawa University', 'label': 'Kanagawa University' },
    { 'id': 7270, 'value': 'Kanagawa University of Human Services', 'label': 'Kanagawa University of Human Services' },
    { 'id': 7271, 'value': 'Kanazawa College of Art', 'label': 'Kanazawa College of Art' },
    { 'id': 7272, 'value': 'Kanazawa Gakuin University', 'label': 'Kanazawa Gakuin University' },
    { 'id': 7273, 'value': 'Kanazawa Institute of Technology', 'label': 'Kanazawa Institute of Technology' },
    { 'id': 7274, 'value': 'Kanazawa Medical University', 'label': 'Kanazawa Medical University' },
    { 'id': 7275, 'value': 'Kanazawa Seiryo University', 'label': 'Kanazawa Seiryo University' },
    { 'id': 7276, 'value': 'Kanazawa University', 'label': 'Kanazawa University' },
    { 'id': 11403, 'value': 'Kanchanaburi Rajabhat University', 'label': 'Kanchanaburi Rajabhat University' },
    { 'id': 7277, 'value': 'Kanda University of International Studies', 'label': 'Kanda University of International Studies' },
    { 'id': 726, 'value': 'Kandahar University', 'label': 'Kandahar University' },
    { 'id': 8057, 'value': 'Kangnam University', 'label': 'Kangnam University' },
    { 'id': 8058, 'value': 'Kangwon National University', 'label': 'Kangwon National University' },
    { 'id': 5946, 'value': 'Kannada University', 'label': 'Kannada University' },
    { 'id': 5947, 'value': 'Kannur University', 'label': 'Kannur University' },
    { 'id': 9383, 'value': 'Kano University of Science and Technology', 'label': 'Kano University of Science and Technology' },
    { 'id': 7278, 'value': 'Kansai Gaidai University', 'label': 'Kansai Gaidai University' },
    { 'id': 7279, 'value': 'Kansai Medical University', 'label': 'Kansai Medical University' },
    { 'id': 7280, 'value': 'Kansai University', 'label': 'Kansai University' },
    { 'id': 7281, 'value': 'Kansai University of Health Sciences', 'label': 'Kansai University of Health Sciences' },
    { 'id': 7282, 'value': 'Kansai University of International Studies', 'label': 'Kansai University of International Studies' },
    { 'id': 7283, 'value': 'Kansai University of Nursing and Health Sciences', 'label': 'Kansai University of Nursing and Health Sciences' },
    { 'id': 7284, 'value': 'Kansai University of Social Welfare', 'label': 'Kansai University of Social Welfare' },
    { 'id': 7285, 'value': 'Kansai University of Welfare Sciences', 'label': 'Kansai University of Welfare Sciences' },
    { 'id': 12854, 'value': 'Kansas City Art Institute', 'label': 'Kansas City Art Institute' },
    { 'id': 12855, 'value': 'Kansas City University of Medicine and Biosciences', 'label': 'Kansas City University of Medicine and Biosciences' },
    { 'id': 12856, 'value': 'Kansas State University', 'label': 'Kansas State University' },
    { 'id': 12857, 'value': 'Kansas Wesleyan University', 'label': 'Kansas Wesleyan University' },
    { 'id': 7286, 'value': 'Kanto Gakuen University', 'label': 'Kanto Gakuen University' },
    { 'id': 7287, 'value': 'Kanto Gakuin University', 'label': 'Kanto Gakuin University' },
    { 'id': 11848, 'value': 'Kao Yuan University', 'label': 'Kao Yuan University' },
    { 'id': 11849, 'value': 'Kaohsiung Medical University', 'label': 'Kaohsiung Medical University' },
    { 'id': 4904, 'value': 'Kaposvári Egyetem', 'label': 'Kaposvári Egyetem' },
    { 'id': 11732, 'value': 'Karabük Üniversitesi', 'label': 'Karabük Üniversitesi' },
    { 'id': 10107, 'value': 'Karachi Institute of Economics and Technology', 'label': 'Karachi Institute of Economics and Technology' },
    { 'id': 10108, 'value': 'Karachi School for Business and Leadership', 'label': 'Karachi School for Business and Leadership' },
    { 'id': 11733, 'value': 'Karadeniz Teknik Üniversitesi', 'label': 'Karadeniz Teknik Üniversitesi' },
    { 'id': 8195, 'value': 'Karaganda Medical University', 'label': 'Karaganda Medical University' },
    { 'id': 8196, 'value': 'Karaganda State Industrial University', 'label': 'Karaganda State Industrial University' },
    { 'id': 8197, 'value': 'Karaganda State Technical University', 'label': 'Karaganda State Technical University' },
    { 'id': 8198, 'value': 'Karaganda State University', 'label': 'Karaganda State University' },
    { 'id': 8199, 'value': 'Karaganda University Bolashak', 'label': 'Karaganda University Bolashak' },
    { 'id': 8200, 'value': 'Karaganda University of Economics', 'label': 'Karaganda University of Economics' },
    { 'id': 10109, 'value': 'Karakurum International University', 'label': 'Karakurum International University' },
    { 'id': 11734, 'value': 'Karamanoglu Mehmetbey Üniversitesi', 'label': 'Karamanoglu Mehmetbey Üniversitesi' },
    { 'id': 11068, 'value': 'Karary University', 'label': 'Karary University' },
    { 'id': 7796, 'value': 'Karatina University', 'label': 'Karatina University' },
    { 'id': 727, 'value': 'Kardan University', 'label': 'Kardan University' },
    { 'id': 1384, 'value': 'Karel de Grote-Hogeschool', 'label': 'Karel de Grote-Hogeschool' },
    { 'id': 4222, 'value': 'Karelia-ammattikorkeakoulu', 'label': 'Karelia-ammattikorkeakoulu' },
    { 'id': 1029, 'value': 'Karl Landsteiner Privatuniversität für Gesundheitswissenschaften', 'label': 'Karl Landsteiner Privatuniversität für Gesundheitswissenschaften' },
    { 'id': 1030, 'value': 'Karl-Franzens-Universität Graz', 'label': 'Karl-Franzens-Universität Graz' },
    { 'id': 3677, 'value': 'Karlshochschule International University', 'label': 'Karlshochschule International University' },
    { 'id': 3678, 'value': 'Karlsruher Institut für Technologie', 'label': 'Karlsruher Institut für Technologie' },
    { 'id': 11123, 'value': 'Karlstads universitet', 'label': 'Karlstads universitet' },
    { 'id': 5948, 'value': 'Karnatak University', 'label': 'Karnatak University' },
    { 'id': 5949, 'value': 'Karnataka Janapada Vishwavidyalaya', 'label': 'Karnataka Janapada Vishwavidyalaya' },
    { 'id': 5950, 'value': 'Karnataka Sanskrit University', 'label': 'Karnataka Sanskrit University' },
    { 'id': 5951, 'value': 'Karnataka State Law University', 'label': 'Karnataka State Law University' },
    { 'id': 5952, 'value': 'Karnataka State Rural Development and Panchayat Raj University', 'label': 'Karnataka State Rural Development and Panchayat Raj University' },
    { 'id': 5953, 'value': 'Karnataka State Women\'s University', 'label': 'Karnataka State Women\'s University' },
    { 'id': 5954, 'value': 'Karnataka Veterinary, Animal and Fisheries Sciences University', 'label': 'Karnataka Veterinary, Animal and Fisheries Sciences University' },
    { 'id': 5955, 'value': 'Karnavati University', 'label': 'Karnavati University' },
    { 'id': 4905, 'value': 'Károli Gáspár Református Egyetem', 'label': 'Károli Gáspár Református Egyetem' },
    { 'id': 11124, 'value': 'Karolinska Institutet', 'label': 'Karolinska Institutet' },
    { 'id': 5956, 'value': 'Karpagam Academy of Higher Education', 'label': 'Karpagam Academy of Higher Education' },
    { 'id': 5957, 'value': 'Karunya Institute of Technology and Sciences', 'label': 'Karunya Institute of Technology and Sciences' },
    { 'id': 728, 'value': 'Karwan University', 'label': 'Karwan University' },
    { 'id': 10110, 'value': 'KASB Institute of Technology', 'label': 'KASB Institute of Technology' },
    { 'id': 11404, 'value': 'Kasem Bundit University', 'label': 'Kasem Bundit University' },
    { 'id': 11405, 'value': 'Kasetsart University', 'label': 'Kasetsart University' },
    { 'id': 6702, 'value': 'Kashan University of Medical Sciences', 'label': 'Kashan University of Medical Sciences' },
    { 'id': 2547, 'value': 'Kashi University', 'label': 'Kashi University' },
    { 'id': 11735, 'value': 'Kastamonu Üniversitesi', 'label': 'Kastamonu Üniversitesi' },
    { 'id': 11953, 'value': 'Katavi University of Agriculture', 'label': 'Katavi University of Agriculture' },
    { 'id': 729, 'value': 'Kateb University', 'label': 'Kateb University' },
    { 'id': 9593, 'value': 'Kathmandu University', 'label': 'Kathmandu University' },
    { 'id': 9541, 'value': 'Katholieke PABO Zwolle', 'label': 'Katholieke PABO Zwolle' },
    { 'id': 1385, 'value': 'Katholieke Universiteit Leuven', 'label': 'Katholieke Universiteit Leuven' },
    { 'id': 3679, 'value': 'Katholische Hochschule Freiburg', 'label': 'Katholische Hochschule Freiburg' },
    { 'id': 3680, 'value': 'Katholische Hochschule für Sozialwesen Berlin', 'label': 'Katholische Hochschule für Sozialwesen Berlin' },
    { 'id': 3681, 'value': 'Katholische Hochschule Mainz', 'label': 'Katholische Hochschule Mainz' },
    { 'id': 3682, 'value': 'Katholische Hochschule Nordrhein-Westfalen', 'label': 'Katholische Hochschule Nordrhein-Westfalen' },
    { 'id': 1031, 'value': 'Katholische Privat-Universität Linz', 'label': 'Katholische Privat-Universität Linz' },
    { 'id': 3683, 'value': 'Katholische Stiftungsfachhochschule München', 'label': 'Katholische Stiftungsfachhochschule München' },
    { 'id': 3684, 'value': 'Katholische Universität Eichstätt-Ingolstadt', 'label': 'Katholische Universität Eichstätt-Ingolstadt' },
    { 'id': 11190, 'value': 'Katolická Univerzita v Ružomberku', 'label': 'Katolická Univerzita v Ružomberku' },
    { 'id': 10251, 'value': 'Katolicki Uniwersytet Lubelski Jana Pawla II', 'label': 'Katolicki Uniwersytet Lubelski Jana Pawla II' },
    { 'id': 8337, 'value': 'Kauno Kolegija', 'label': 'Kauno Kolegija' },
    { 'id': 8338, 'value': 'Kauno mišku ir aplinkos inžinerijos kolegija', 'label': 'Kauno mišku ir aplinkos inžinerijos kolegija' },
    { 'id': 8339, 'value': 'Kauno technikos kolegija', 'label': 'Kauno technikos kolegija' },
    { 'id': 8340, 'value': 'Kauno technologijos universitetas', 'label': 'Kauno technologijos universitetas' },
    { 'id': 5958, 'value': 'Kavi Kulguru Kalidas Sanskrit Vishwavidyalaya', 'label': 'Kavi Kulguru Kalidas Sanskrit Vishwavidyalaya' },
    { 'id': 7288, 'value': 'Kawamura Gakuen Women\'s University', 'label': 'Kawamura Gakuen Women\'s University' },
    { 'id': 7289, 'value': 'Kawasaki Medical School', 'label': 'Kawasaki Medical School' },
    { 'id': 7290, 'value': 'Kawasaki University of Medical Welfare', 'label': 'Kawasaki University of Medical Welfare' },
    { 'id': 730, 'value': 'Kawun Institute of Higher Education', 'label': 'Kawun Institute of Higher Education' },
    { 'id': 8059, 'value': 'Kaya University', 'label': 'Kaya University' },
    { 'id': 12178, 'value': 'Kayiwa International University', 'label': 'Kayiwa International University' },
    { 'id': 8201, 'value': 'Kazak Academy of Labour and Social Relations', 'label': 'Kazak Academy of Labour and Social Relations' },
    { 'id': 8202, 'value': 'Kazakh Ablai Khan University of International Relations and World Languages', 'label': 'Kazakh Ablai Khan University of International Relations and World Languages' },
    { 'id': 8203, 'value': 'Kazakh Academy of Sport and Tourism', 'label': 'Kazakh Academy of Sport and Tourism' },
    { 'id': 8204, 'value': 'Kazakh Academy of Transport and Communication', 'label': 'Kazakh Academy of Transport and Communication' },
    { 'id': 8205, 'value': 'Kazakh Humanitarian Law Innovative University', 'label': 'Kazakh Humanitarian Law Innovative University' },
    { 'id': 8206, 'value': 'Kazakh Leading Academy of Architecture and Civil Engineering', 'label': 'Kazakh Leading Academy of Architecture and Civil Engineering' },
    { 'id': 8207, 'value': 'Kazakh Medical University of Continuing Education', 'label': 'Kazakh Medical University of Continuing Education' },
    { 'id': 8208, 'value': 'Kazakh National Academy of Arts', 'label': 'Kazakh National Academy of Arts' },
    { 'id': 8209, 'value': 'Kazakh National Agricultural University', 'label': 'Kazakh National Agricultural University' },
    { 'id': 8210, 'value': 'Kazakh National Conservatory', 'label': 'Kazakh National Conservatory' },
    { 'id': 8211, 'value': 'Kazakh National Medical University', 'label': 'Kazakh National Medical University' },
    { 'id': 8212, 'value': 'Kazakh National Pedagogical University', 'label': 'Kazakh National Pedagogical University' },
    { 'id': 8213, 'value': 'Kazakh National University of Arts', 'label': 'Kazakh National University of Arts' },
    { 'id': 8214, 'value': 'Kazakh State Women\'s Teacher Training University', 'label': 'Kazakh State Women\'s Teacher Training University' },
    { 'id': 8215, 'value': 'Kazakh University of Economics, Finance and International Trade', 'label': 'Kazakh University of Economics, Finance and International Trade' },
    { 'id': 8216, 'value': 'Kazakh University of Railway Transport', 'label': 'Kazakh University of Railway Transport' },
    { 'id': 8217, 'value': 'Kazakh University of Technology and Business', 'label': 'Kazakh University of Technology and Business' },
    { 'id': 8218, 'value': 'Kazakh-American Free University', 'label': 'Kazakh-American Free University' },
    { 'id': 8219, 'value': 'Kazakh-American University', 'label': 'Kazakh-American University' },
    { 'id': 8220, 'value': 'Kazakh-British Technical University', 'label': 'Kazakh-British Technical University' },
    { 'id': 8221, 'value': 'Kazakh-Russian International University', 'label': 'Kazakh-Russian International University' },
    { 'id': 8222, 'value': 'Kazakhstan Engineering and Pedagogical University of Friendship of Peoples', 'label': 'Kazakhstan Engineering and Pedagogical University of Friendship of Peoples' },
    { 'id': 8223, 'value': 'Kazakhstan Engineering-Technological University', 'label': 'Kazakhstan Engineering-Technological University' },
    { 'id': 8224, 'value': 'Kazakhstan Innovative University', 'label': 'Kazakhstan Innovative University' },
    { 'id': 8225, 'value': 'Kazakhstan School of Public Health', 'label': 'Kazakhstan School of Public Health' },
    { 'id': 8226, 'value': 'Kazakhstan-Russian Medical University', 'label': 'Kazakhstan-Russian Medical University' },
    { 'id': 10706, 'value': 'Kazan Federal University', 'label': 'Kazan Federal University' },
    { 'id': 10707, 'value': 'Kazan National Research Technical University named after A.N. Tupolev - KAI', 'label': 'Kazan National Research Technical University named after A.N. Tupolev - KAI' },
    { 'id': 10708, 'value': 'Kazan State Agricultural University', 'label': 'Kazan State Agricultural University' },
    { 'id': 10709, 'value': 'Kazan State Conservatory', 'label': 'Kazan State Conservatory' },
    { 'id': 10710, 'value': 'Kazan State Medical University', 'label': 'Kazan State Medical University' },
    { 'id': 10711, 'value': 'Kazan State Power Engineering University', 'label': 'Kazan State Power Engineering University' },
    { 'id': 10712, 'value': 'Kazan State Technological University', 'label': 'Kazan State Technological University' },
    { 'id': 10713, 'value': 'Kazan State University of Architecture and Engineering', 'label': 'Kazan State University of Architecture and Engineering' },
    { 'id': 10714, 'value': 'Kazan State University of Culture and Arts', 'label': 'Kazan State University of Culture and Arts' },
    { 'id': 8227, 'value': 'KAZGUU University', 'label': 'KAZGUU University' },
    { 'id': 5959, 'value': 'Kazi Nazrul University', 'label': 'Kazi Nazrul University' },
    { 'id': 8341, 'value': 'Kazimiero Simonaviciaus universitetas', 'label': 'Kazimiero Simonaviciaus universitetas' },
    { 'id': 5960, 'value': 'Kaziranga University', 'label': 'Kaziranga University' },
    { 'id': 7797, 'value': 'KCA University', 'label': 'KCA University' },
    { 'id': 562, 'value': 'KEA - Copenhagen School of Design and Technology', 'label': 'KEA - Copenhagen School of Design and Technology' },
    { 'id': 12858, 'value': 'Kean University', 'label': 'Kean University' },
    { 'id': 9384, 'value': 'Kebbi State University of Science and Technology', 'label': 'Kebbi State University of Science and Technology' },
    { 'id': 12859, 'value': 'Keck Graduate Institute', 'label': 'Keck Graduate Institute' },
    { 'id': 4393, 'value': 'KEDGE Business School', 'label': 'KEDGE Business School' },
    { 'id': 4521, 'value': 'Keele University', 'label': 'Keele University' },
    { 'id': 12860, 'value': 'Keene State College', 'label': 'Keene State College' },
    { 'id': 7291, 'value': 'Keiai University', 'label': 'Keiai University' },
    { 'id': 8060, 'value': 'Keimyung University', 'label': 'Keimyung University' },
    { 'id': 7292, 'value': 'Keio University', 'label': 'Keio University' },
    { 'id': 7293, 'value': 'Keisen University', 'label': 'Keisen University' },
    { 'id': 12861, 'value': 'Keiser University', 'label': 'Keiser University' },
    { 'id': 9461, 'value': 'Keiser University Latin American Campus', 'label': 'Keiser University Latin American Campus' },
    { 'id': 7294, 'value': 'Keiwa College', 'label': 'Keiwa College' },
    { 'id': 10715, 'value': 'Kemerovo State Medical University', 'label': 'Kemerovo State Medical University' },
    { 'id': 10716, 'value': 'Kemerovo State University', 'label': 'Kemerovo State University' },
    { 'id': 10717, 'value': 'Kemerovo State University of Culture and the Arts', 'label': 'Kemerovo State University of Culture and the Arts' },
    { 'id': 12862, 'value': 'Kendall College', 'label': 'Kendall College' },
    { 'id': 12863, 'value': 'Kennesaw State University', 'label': 'Kennesaw State University' },
    { 'id': 14266, 'value': 'Kenneth Kaunda Metropolitan University', 'label': 'Kenneth Kaunda Metropolitan University' },
    { 'id': 12864, 'value': 'Kent State University', 'label': 'Kent State University' },
    { 'id': 12865, 'value': 'Kentucky Christian University', 'label': 'Kentucky Christian University' },
    { 'id': 12866, 'value': 'Kentucky State University', 'label': 'Kentucky State University' },
    { 'id': 12867, 'value': 'Kentucky Wesleyan College', 'label': 'Kentucky Wesleyan College' },
    { 'id': 7798, 'value': 'Kenya Highlands University', 'label': 'Kenya Highlands University' },
    { 'id': 7799, 'value': 'Kenya Methodist University', 'label': 'Kenya Methodist University' },
    { 'id': 7800, 'value': 'Kenyatta University', 'label': 'Kenyatta University' },
    { 'id': 12868, 'value': 'Kenyon College', 'label': 'Kenyon College' },
    { 'id': 5961, 'value': 'Kerala Agricultural University', 'label': 'Kerala Agricultural University' },
    { 'id': 5962, 'value': 'Kerala Kalamandalam', 'label': 'Kerala Kalamandalam' },
    { 'id': 5963, 'value': 'Kerala University of Fisheries and Ocean Studies', 'label': 'Kerala University of Fisheries and Ocean Studies' },
    { 'id': 5964, 'value': 'Kerala University of Health Sciences', 'label': 'Kerala University of Health Sciences' },
    { 'id': 5965, 'value': 'Kerala Veterinary and Animal Sciences University', 'label': 'Kerala Veterinary and Animal Sciences University' },
    { 'id': 12017, 'value': 'Kerch State Marine Technology University', 'label': 'Kerch State Marine Technology University' },
    { 'id': 6703, 'value': 'Kerman Medical University', 'label': 'Kerman Medical University' },
    { 'id': 6704, 'value': 'Kermanshah University of Medical Sciences', 'label': 'Kermanshah University of Medical Sciences' },
    { 'id': 6705, 'value': 'Kermanshah University of Technology', 'label': 'Kermanshah University of Technology' },
    { 'id': 3363, 'value': 'KES College', 'label': 'KES College' },
    { 'id': 4693, 'value': 'Kessben College', 'label': 'Kessben College' },
    { 'id': 12869, 'value': 'Kettering College', 'label': 'Kettering College' },
    { 'id': 384, 'value': 'Kettering University', 'label': 'Kettering University' },
    { 'id': 12870, 'value': 'Keuka College', 'label': 'Keuka College' },
    { 'id': 12871, 'value': 'Keystone College', 'label': 'Keystone College' },
    { 'id': 10718, 'value': 'Khabarovsk State University of Economics and Law', 'label': 'Khabarovsk State University of Economics and Law' },
    { 'id': 6706, 'value': 'Khaje Nasir Toosi University of Technology', 'label': 'Khaje Nasir Toosi University of Technology' },
    { 'id': 10719, 'value': 'Khakas State University', 'label': 'Khakas State University' },
    { 'id': 648, 'value': 'Khalifa University', 'label': 'Khalifa University' },
    { 'id': 5966, 'value': 'Khallikote University', 'label': 'Khallikote University' },
    { 'id': 731, 'value': 'Khana-e-Noor University', 'label': 'Khana-e-Noor University' },
    { 'id': 8609, 'value': 'Khangai Institute', 'label': 'Khangai Institute' },
    { 'id': 6707, 'value': 'Kharazmi University', 'label': 'Kharazmi University' },
    { 'id': 12018, 'value': 'Kharkiv Medical Academy of Postgraduate Education', 'label': 'Kharkiv Medical Academy of Postgraduate Education' },
    { 'id': 12019, 'value': 'Kharkiv National Agricultural University', 'label': 'Kharkiv National Agricultural University' },
    { 'id': 12020, 'value': 'Kharkiv National Automobile and Highway University', 'label': 'Kharkiv National Automobile and Highway University' },
    { 'id': 12021, 'value': 'Kharkiv National Medical University', 'label': 'Kharkiv National Medical University' },
    { 'id': 12022, 'value': 'Kharkiv National Pedagogical University', 'label': 'Kharkiv National Pedagogical University' },
    { 'id': 12023, 'value': 'Kharkiv National Technical University of Agriculture', 'label': 'Kharkiv National Technical University of Agriculture' },
    { 'id': 12024, 'value': 'Kharkiv National University of Arts', 'label': 'Kharkiv National University of Arts' },
    { 'id': 12025, 'value': 'Kharkiv National University of Civil Engineering and Architecture', 'label': 'Kharkiv National University of Civil Engineering and Architecture' },
    { 'id': 12026, 'value': 'Kharkiv National University of Internal Affairs', 'label': 'Kharkiv National University of Internal Affairs' },
    { 'id': 12027, 'value': 'Kharkiv National University of Radioelectronics', 'label': 'Kharkiv National University of Radioelectronics' },
    { 'id': 12028, 'value': 'Kharkiv Regional Institute of Public Administration', 'label': 'Kharkiv Regional Institute of Public Administration' },
    { 'id': 12029, 'value': 'Kharkiv State Academy of Culture', 'label': 'Kharkiv State Academy of Culture' },
    { 'id': 12030, 'value': 'Kharkiv State Academy of Design and Arts', 'label': 'Kharkiv State Academy of Design and Arts' },
    { 'id': 12031, 'value': 'Kharkiv State Academy of Physical Culture', 'label': 'Kharkiv State Academy of Physical Culture' },
    { 'id': 12032, 'value': 'Kharkiv State University of Food Technology and Trade', 'label': 'Kharkiv State University of Food Technology and Trade' },
    { 'id': 12033, 'value': 'Kharkiv State Zooveterinary Academy', 'label': 'Kharkiv State Zooveterinary Academy' },
    { 'id': 732, 'value': 'Khatam Al Nabieen University', 'label': 'Khatam Al Nabieen University' },
    { 'id': 6708, 'value': 'Khavaran Institute of Higher Education', 'label': 'Khavaran Institute of Higher Education' },
    { 'id': 649, 'value': 'Khawarizmi International College', 'label': 'Khawarizmi International College' },
    { 'id': 7907, 'value': 'Khemarak University', 'label': 'Khemarak University' },
    { 'id': 12034, 'value': 'Kherson National Technical University', 'label': 'Kherson National Technical University' },
    { 'id': 12035, 'value': 'Kherson State Agrarian University', 'label': 'Kherson State Agrarian University' },
    { 'id': 12036, 'value': 'Kherson State Maritime Academy', 'label': 'Kherson State Maritime Academy' },
    { 'id': 12037, 'value': 'Kherson State University', 'label': 'Kherson State University' },
    { 'id': 12038, 'value': 'Khmelnytskyi National University', 'label': 'Khmelnytskyi National University' },
    { 'id': 7908, 'value': 'Khmer University of Technology and Management', 'label': 'Khmer University of Technology and Management' },
    { 'id': 11406, 'value': 'Khon Kaen University', 'label': 'Khon Kaen University' },
    { 'id': 11503, 'value': 'Khorog State University', 'label': 'Khorog State University' },
    { 'id': 6709, 'value': 'Khorramshahr Marine Science and Technology University', 'label': 'Khorramshahr Marine Science and Technology University' },
    { 'id': 8610, 'value': 'Khovd University', 'label': 'Khovd University' },
    { 'id': 11504, 'value': 'Khujand State University', 'label': 'Khujand State University' },
    { 'id': 1270, 'value': 'Khulna Agricultural University', 'label': 'Khulna Agricultural University' },
    { 'id': 1271, 'value': 'Khulna University', 'label': 'Khulna University' },
    { 'id': 1272, 'value': 'Khulna University of Engineering and Technology', 'label': 'Khulna University of Engineering and Technology' },
    { 'id': 733, 'value': 'Khurasan University', 'label': 'Khurasan University' },
    { 'id': 734, 'value': 'Khurshid Institute of Higher Education', 'label': 'Khurshid Institute of Higher Education' },
    { 'id': 10111, 'value': 'Khushal Khan Khattak University', 'label': 'Khushal Khan Khattak University' },
    { 'id': 6710, 'value': 'Khuzestan Agricultural Sciences and Natural Resources University', 'label': 'Khuzestan Agricultural Sciences and Natural Resources University' },
    { 'id': 10112, 'value': 'Khwaja Fareed University of Engineering and Information Technology', 'label': 'Khwaja Fareed University of Engineering and Information Technology' },
    { 'id': 5967, 'value': 'Khwaja Moinuddin Chishti Urdu, Arabi-Farsi University', 'label': 'Khwaja Moinuddin Chishti Urdu, Arabi-Farsi University' },
    { 'id': 1273, 'value': 'Khwaja Yunus Ali University', 'label': 'Khwaja Yunus Ali University' },
    { 'id': 10113, 'value': 'Khyber Medical University', 'label': 'Khyber Medical University' },
    { 'id': 8656, 'value': 'Kiang Wu Nursing College of Macau', 'label': 'Kiang Wu Nursing College of Macau' },
    { 'id': 7801, 'value': 'Kibabii University', 'label': 'Kibabii University' },
    { 'id': 7295, 'value': 'Kibi International University', 'label': 'Kibi International University' },
    { 'id': 10999, 'value': 'Kibogora Polytechnic', 'label': 'Kibogora Polytechnic' },
    { 'id': 3364, 'value': 'Kibris Amerikan Üniversitesi', 'label': 'Kibris Amerikan Üniversitesi' },
    { 'id': 3365, 'value': 'Kibris Bati Üniversitesi', 'label': 'Kibris Bati Üniversitesi' },
    { 'id': 3366, 'value': 'Kibris Ilim Üniversitesi', 'label': 'Kibris Ilim Üniversitesi' },
    { 'id': 3367, 'value': 'Kibris Saglik ve Toplum Bilimleri Üniversitesi', 'label': 'Kibris Saglik ve Toplum Bilimleri Üniversitesi' },
    { 'id': 3368, 'value': 'Kibris Sosyal Bilimler Üniversitesi', 'label': 'Kibris Sosyal Bilimler Üniversitesi' },
    { 'id': 12039, 'value': 'Kiev College of Civil Engineering, Architecture and Design', 'label': 'Kiev College of Civil Engineering, Architecture and Design' },
    { 'id': 5968, 'value': 'KIIT University', 'label': 'KIIT University' },
    { 'id': 11736, 'value': 'Kilis 7 Aralik Üniversitesi', 'label': 'Kilis 7 Aralik Üniversitesi' },
    { 'id': 7949, 'value': 'Kim Chaek University of Technology', 'label': 'Kim Chaek University of Technology' },
    { 'id': 7950, 'value': 'Kim Chol Ju University of Education', 'label': 'Kim Chol Ju University of Education' },
    { 'id': 7951, 'value': 'Kim Hyong Jik University of Education', 'label': 'Kim Hyong Jik University of Education' },
    { 'id': 7952, 'value': 'Kim Il Sung University', 'label': 'Kim Il Sung University' },
    { 'id': 11000, 'value': 'KIM University', 'label': 'KIM University' },
    { 'id': 7953, 'value': 'Kim Won Gyun Pyongyang Conservatory', 'label': 'Kim Won Gyun Pyongyang Conservatory' },
    { 'id': 8228, 'value': 'KIMEP University', 'label': 'KIMEP University' },
    { 'id': 7296, 'value': 'Kindai University', 'label': 'Kindai University' },
    { 'id': 11030, 'value': 'King AbdulAziz University', 'label': 'King AbdulAziz University' },
    { 'id': 11031, 'value': 'King Abdullah University of Science and Technology', 'label': 'King Abdullah University of Science and Technology' },
    { 'id': 12179, 'value': 'King Ceasor University', 'label': 'King Ceasor University' },
    { 'id': 10114, 'value': 'King Edward Medical University', 'label': 'King Edward Medical University' },
    { 'id': 11032, 'value': 'King Fahd University of Petroleum and Minerals', 'label': 'King Fahd University of Petroleum and Minerals' },
    { 'id': 11033, 'value': 'King Faisal University', 'label': 'King Faisal University' },
    { 'id': 5969, 'value': 'King George\'s Medical University', 'label': 'King George\'s Medical University' },
    { 'id': 11034, 'value': 'King Khalid University', 'label': 'King Khalid University' },
    { 'id': 11407, 'value': 'King Mongkut\'s Institute of Technology Ladkrabang', 'label': 'King Mongkut\'s Institute of Technology Ladkrabang' },
    { 'id': 11408, 'value': 'King Mongkut\'s University of Technology North Bangkok', 'label': 'King Mongkut\'s University of Technology North Bangkok' },
    { 'id': 11409, 'value': 'King Mongkut\'s University of Technology Thonburi', 'label': 'King Mongkut\'s University of Technology Thonburi' },
    { 'id': 11035, 'value': 'King Saud bin Abdulaziz University for Health Sciences', 'label': 'King Saud bin Abdulaziz University for Health Sciences' },
    { 'id': 11036, 'value': 'King Saud University', 'label': 'King Saud University' },
    { 'id': 12872, 'value': 'King University', 'label': 'King University' },
    { 'id': 119, 'value': 'King\'s College London', 'label': 'King\'s College London' },
    { 'id': 1474, 'value': 'Kingdom University', 'label': 'Kingdom University' },
    { 'id': 9385, 'value': 'Kings University', 'label': 'Kings University' },
    { 'id': 4694, 'value': 'Kings University College', 'label': 'Kings University College' },
    { 'id': 4523, 'value': 'Kingston University', 'label': 'Kingston University' },
    { 'id': 1873, 'value': 'Kingswood University', 'label': 'Kingswood University' },
    { 'id': 7297, 'value': 'Kinjo Gakuin University', 'label': 'Kinjo Gakuin University' },
    { 'id': 7298, 'value': 'Kinjo University', 'label': 'Kinjo University' },
    { 'id': 7299, 'value': 'Kinki Health Welfare University', 'label': 'Kinki Health Welfare University' },
    { 'id': 10115, 'value': 'Kinnaird College for Women', 'label': 'Kinnaird College for Women' },
    { 'id': 7300, 'value': 'Kio University', 'label': 'Kio University' },
    { 'id': 3685, 'value': 'Kirchliche Hochschule Wuppertal/Bethel', 'label': 'Kirchliche Hochschule Wuppertal/Bethel' },
    { 'id': 1032, 'value': 'Kirchliche Pädagogische Hochschule Edith Stein', 'label': 'Kirchliche Pädagogische Hochschule Edith Stein' },
    { 'id': 1033, 'value': 'Kirchliche Pädagogische Hochschule Graz', 'label': 'Kirchliche Pädagogische Hochschule Graz' },
    { 'id': 1034, 'value': 'Kirchliche Pädagogische Hochschule Wien/Krems', 'label': 'Kirchliche Pädagogische Hochschule Wien/Krems' },
    { 'id': 7861, 'value': 'Kirgizistan-Türkiye Manas Üniversitesi', 'label': 'Kirgizistan-Türkiye Manas Üniversitesi' },
    { 'id': 11737, 'value': 'Kirikkale Üniversitesi', 'label': 'Kirikkale Üniversitesi' },
    { 'id': 7802, 'value': 'Kirinyaga University', 'label': 'Kirinyaga University' },
    { 'id': 7803, 'value': 'Kiriri Women\'s University of Science and Technology', 'label': 'Kiriri Women\'s University of Science and Technology' },
    { 'id': 11738, 'value': 'Kirklareli Üniversitesi', 'label': 'Kirklareli Üniversitesi' },
    { 'id': 10720, 'value': 'Kirov State Medical University', 'label': 'Kirov State Medical University' },
    { 'id': 12040, 'value': 'Kirovohrad National Technical University', 'label': 'Kirovohrad National Technical University' },
    { 'id': 7804, 'value': 'Kisii University', 'label': 'Kisii University' },
    { 'id': 7301, 'value': 'Kitami Institute of Technology', 'label': 'Kitami Institute of Technology' },
    { 'id': 7302, 'value': 'Kitasato University', 'label': 'Kitasato University' },
    { 'id': 8061, 'value': 'Kkottongnae University', 'label': 'Kkottongnae University' },
    { 'id': 8342, 'value': 'Klaipedos Universitetas', 'label': 'Klaipedos Universitetas' },
    { 'id': 8343, 'value': 'Klaipedos valstybine kolegija', 'label': 'Klaipedos valstybine kolegija' },
    { 'id': 5970, 'value': 'KLE Technological University', 'label': 'KLE Technological University' },
    { 'id': 5971, 'value': 'KLE University', 'label': 'KLE University' },
    { 'id': 14203, 'value': 'Knowledge and Modern Science University', 'label': 'Knowledge and Modern Science University' },
    { 'id': 6455, 'value': 'Knowledge University', 'label': 'Knowledge University' },
    { 'id': 12873, 'value': 'Knox College', 'label': 'Knox College' },
    { 'id': 4695, 'value': 'Knutsford University College', 'label': 'Knutsford University College' },
    { 'id': 7303, 'value': 'Kobe City College of Nursing', 'label': 'Kobe City College of Nursing' },
    { 'id': 7304, 'value': 'Kobe City University of Foreign Studies', 'label': 'Kobe City University of Foreign Studies' },
    { 'id': 7305, 'value': 'Kobe College', 'label': 'Kobe College' },
    { 'id': 7306, 'value': 'Kobe Design University', 'label': 'Kobe Design University' },
    { 'id': 7307, 'value': 'Kobe Gakuin University', 'label': 'Kobe Gakuin University' },
    { 'id': 7308, 'value': 'Kobe Institute of Computing', 'label': 'Kobe Institute of Computing' },
    { 'id': 7309, 'value': 'Kobe International University', 'label': 'Kobe International University' },
    { 'id': 7310, 'value': 'Kobe Kaisei College', 'label': 'Kobe Kaisei College' },
    { 'id': 7311, 'value': 'Kobe Pharmaceutical University', 'label': 'Kobe Pharmaceutical University' },
    { 'id': 7312, 'value': 'Kobe Shinwa Women\'s University', 'label': 'Kobe Shinwa Women\'s University' },
    { 'id': 7313, 'value': 'Kobe Shoin Women\'s University', 'label': 'Kobe Shoin Women\'s University' },
    { 'id': 7314, 'value': 'Kobe Tokiwa University', 'label': 'Kobe Tokiwa University' },
    { 'id': 7315, 'value': 'Kobe University', 'label': 'Kobe University' },
    { 'id': 7316, 'value': 'Kobe Women\'s University', 'label': 'Kobe Women\'s University' },
    { 'id': 7317, 'value': 'Kobe Yamate University', 'label': 'Kobe Yamate University' },
    { 'id': 3834, 'value': 'Københavns Erhvervsakademi', 'label': 'Københavns Erhvervsakademi' },
    { 'id': 3835, 'value': 'Københavns Universitet', 'label': 'Københavns Universitet' },
    { 'id': 11739, 'value': 'Koç Üniversitesi', 'label': 'Koç Üniversitesi' },
    { 'id': 11740, 'value': 'Kocaeli Üniversitesi', 'label': 'Kocaeli Üniversitesi' },
    { 'id': 7318, 'value': 'Kochi University', 'label': 'Kochi University' },
    { 'id': 7319, 'value': 'Kochi University of Technology', 'label': 'Kochi University of Technology' },
    { 'id': 4906, 'value': 'Kodolányi János Egyetem', 'label': 'Kodolányi János Egyetem' },
    { 'id': 4696, 'value': 'Koforidua Technical University', 'label': 'Koforidua Technical University' },
    { 'id': 7320, 'value': 'Kogakkan University', 'label': 'Kogakkan University' },
    { 'id': 7321, 'value': 'Kogakuin University', 'label': 'Kogakuin University' },
    { 'id': 9386, 'value': 'Kogi State University', 'label': 'Kogi State University' },
    { 'id': 10116, 'value': 'Kohat University of Science and Technology', 'label': 'Kohat University of Science and Technology' },
    { 'id': 8229, 'value': 'Kokshetau State University', 'label': 'Kokshetau State University' },
    { 'id': 8230, 'value': 'Kokshetau University named after A. Myrzakhmetov', 'label': 'Kokshetau University named after A. Myrzakhmetov' },
    { 'id': 7322, 'value': 'Kokugakuin University', 'label': 'Kokugakuin University' },
    { 'id': 7323, 'value': 'Kokushikan University', 'label': 'Kokushikan University' },
    { 'id': 9387, 'value': 'Kola Daisi University', 'label': 'Kola Daisi University' },
    { 'id': 779, 'value': 'Kolegji Universitar Bedër', 'label': 'Kolegji Universitar Bedër' },
    { 'id': 780, 'value': 'Kolegji Universitar Instituti Kanadez i Teknologjisë', 'label': 'Kolegji Universitar Instituti Kanadez i Teknologjisë' },
    { 'id': 781, 'value': 'Kolegji Universitar Logos', 'label': 'Kolegji Universitar Logos' },
    { 'id': 782, 'value': 'Kolegji Universitar Luarasi', 'label': 'Kolegji Universitar Luarasi' },
    { 'id': 783, 'value': 'Kolegji Universitar Pavarësia Vlorë', 'label': 'Kolegji Universitar Pavarësia Vlorë' },
    { 'id': 784, 'value': 'Kolegji Universitar Qiriazi', 'label': 'Kolegji Universitar Qiriazi' },
    { 'id': 785, 'value': 'Kolegji Universitar Wisdom', 'label': 'Kolegji Universitar Wisdom' },
    { 'id': 5972, 'value': 'Kolhan University', 'label': 'Kolhan University' },
    { 'id': 8344, 'value': 'Kolpingo kolegija', 'label': 'Kolpingo kolegija' },
    { 'id': 6456, 'value': 'Komar University of Science and Technology', 'label': 'Komar University of Science and Technology' },
    { 'id': 7324, 'value': 'Komazawa University', 'label': 'Komazawa University' },
    { 'id': 7325, 'value': 'Komazawa Women\'s University', 'label': 'Komazawa Women\'s University' },
    { 'id': 10721, 'value': 'Komsomolsk-on-Amur State Technical University', 'label': 'Komsomolsk-on-Amur State Technical University' },
    { 'id': 1166, 'value': 'Komunikoloski koledz Kapa Fi Banja Luka', 'label': 'Komunikoloski koledz Kapa Fi Banja Luka' },
    { 'id': 7326, 'value': 'Konan University', 'label': 'Konan University' },
    { 'id': 7327, 'value': 'Konan Women\'s University', 'label': 'Konan Women\'s University' },
    { 'id': 8062, 'value': 'Kongju National University', 'label': 'Kongju National University' },
    { 'id': 316, 'value': 'Koninklijk Conservatorium', 'label': 'Koninklijk Conservatorium' },
    { 'id': 9542, 'value': 'Koninklijke Academie van Beeldende Kunsten ', 'label': 'Koninklijke Academie van Beeldende Kunsten ' },
    { 'id': 8063, 'value': 'Konkuk University', 'label': 'Konkuk University' },
    { 'id': 1432, 'value': 'Konstantin Preslavsky University of Shumen', 'label': 'Konstantin Preslavsky University of Shumen' },
    { 'id': 11125, 'value': 'Konstfack', 'label': 'Konstfack' },
    { 'id': 11741, 'value': 'Konya Gida ve Tarim Üniversitesi', 'label': 'Konya Gida ve Tarim Üniversitesi' },
    { 'id': 8064, 'value': 'Konyang University', 'label': 'Konyang University' },
    { 'id': 8065, 'value': 'Kookmin University', 'label': 'Kookmin University' },
    { 'id': 8066, 'value': 'Korea Aerospace University', 'label': 'Korea Aerospace University' },
    { 'id': 8067, 'value': 'Korea Baptist Theological University', 'label': 'Korea Baptist Theological University' },
    { 'id': 8068, 'value': 'Korea Christian University', 'label': 'Korea Christian University' },
    { 'id': 8069, 'value': 'Korea Maritime and Ocean University', 'label': 'Korea Maritime and Ocean University' },
    { 'id': 8070, 'value': 'Korea National Sport University', 'label': 'Korea National Sport University' },
    { 'id': 8071, 'value': 'Korea National University of Education', 'label': 'Korea National University of Education' },
    { 'id': 8072, 'value': 'Korea National University of Transportation', 'label': 'Korea National University of Transportation' },
    { 'id': 8073, 'value': 'Korea Nazarene University', 'label': 'Korea Nazarene University' },
    { 'id': 8074, 'value': 'Korea Polytechnic University', 'label': 'Korea Polytechnic University' },
    { 'id': 8075, 'value': 'Korea University', 'label': 'Korea University' },
    { 'id': 8076, 'value': 'Korea University of Technology and Education', 'label': 'Korea University of Technology and Education' },
    { 'id': 7328, 'value': 'Korea University, Japan', 'label': 'Korea University, Japan' },
    { 'id': 8077, 'value': 'Korean Bible University', 'label': 'Korean Bible University' },
    { 'id': 8078, 'value': 'Korean National University of Arts', 'label': 'Korean National University of Arts' },
    { 'id': 4031, 'value': 'Kõrgem Kunstikool Pallas', 'label': 'Kõrgem Kunstikool Pallas' },
    { 'id': 7329, 'value': 'Koriyama Women\'s University', 'label': 'Koriyama Women\'s University' },
    { 'id': 7954, 'value': 'Koryo Songgyungwan University', 'label': 'Koryo Songgyungwan University' },
    { 'id': 6711, 'value': 'Kosar University of Bojnord', 'label': 'Kosar University of Bojnord' },
    { 'id': 7330, 'value': 'Koshien University', 'label': 'Koshien University' },
    { 'id': 8079, 'value': 'Kosin University', 'label': 'Kosin University' },
    { 'id': 8231, 'value': 'Kostanay Engineering-Pedagogical University', 'label': 'Kostanay Engineering-Pedagogical University' },
    { 'id': 8232, 'value': 'Kostanay Social-Technical University', 'label': 'Kostanay Social-Technical University' },
    { 'id': 8233, 'value': 'Kostanay State Pedagogical University', 'label': 'Kostanay State Pedagogical University' },
    { 'id': 8234, 'value': 'Kostanay State University', 'label': 'Kostanay State University' },
    { 'id': 10722, 'value': 'Kostroma State University', 'label': 'Kostroma State University' },
    { 'id': 6457, 'value': 'Koya University', 'label': 'Koya University' },
    { 'id': 7331, 'value': 'Koyasan University', 'label': 'Koyasan University' },
    { 'id': 4907, 'value': 'Közép-Európai Egyetem', 'label': 'Közép-Európai Egyetem' },
    { 'id': 460, 'value': 'Krakowska Akademia', 'label': 'Krakowska Akademia' },
    { 'id': 5973, 'value': 'Krantiguru Shyamji Krishna Verma Kachchh University', 'label': 'Krantiguru Shyamji Krishna Verma Kachchh University' },
    { 'id': 10723, 'value': 'Krasnoyarsk State Agricultural University', 'label': 'Krasnoyarsk State Agricultural University' },
    { 'id': 10724, 'value': 'Krasnoyarsk State Medical University', 'label': 'Krasnoyarsk State Medical University' },
    { 'id': 10725, 'value': 'Krasnoyarsk State Pedagogical University', 'label': 'Krasnoyarsk State Pedagogical University' },
    { 'id': 1433, 'value': 'Krastyo Sarafov National Academy for Theatre and Film Arts', 'label': 'Krastyo Sarafov National Academy for Theatre and Film Arts' },
    { 'id': 12041, 'value': 'Kremenchuk Mykhailo Ostrohradskyi National University', 'label': 'Kremenchuk Mykhailo Ostrohradskyi National University' },
    { 'id': 11410, 'value': 'Krirk University', 'label': 'Krirk University' },
    { 'id': 5974, 'value': 'Krishna Institute of Medical Sciences', 'label': 'Krishna Institute of Medical Sciences' },
    { 'id': 5975, 'value': 'Krishna University', 'label': 'Krishna University' },
    { 'id': 12042, 'value': 'KROK University', 'label': 'KROK University' },
    { 'id': 12043, 'value': 'Kryvyi Rih National University', 'label': 'Kryvyi Rih National University' },
    { 'id': 12044, 'value': 'Kryvyi Rih State Pedagogical University', 'label': 'Kryvyi Rih State Pedagogical University' },
    { 'id': 5976, 'value': 'KSGH Music and Performing Arts University', 'label': 'KSGH Music and Performing Arts University' },
    { 'id': 170, 'value': 'KTH Royal Institute of Technology in Stockholm', 'label': 'KTH Royal Institute of Technology in Stockholm' },
    { 'id': 11742, 'value': 'KTO Karatay Üniversitesi', 'label': 'KTO Karatay Üniversitesi' },
    { 'id': 413, 'value': 'KU Leuven', 'label': 'KU Leuven' },
    { 'id': 10726, 'value': 'Kuban State Agricultural University', 'label': 'Kuban State Agricultural University' },
    { 'id': 10727, 'value': 'Kuban State Medical University', 'label': 'Kuban State Medical University' },
    { 'id': 10728, 'value': 'Kuban State University', 'label': 'Kuban State University' },
    { 'id': 10729, 'value': 'Kuban State University of Physical Culture, Sport and Tourism', 'label': 'Kuban State University of Physical Culture, Sport and Tourism' },
    { 'id': 10730, 'value': 'Kuban State University of Technology', 'label': 'Kuban State University of Technology' },
    { 'id': 3686, 'value': 'Kühne Logistics University', 'label': 'Kühne Logistics University' },
    { 'id': 11505, 'value': 'Kulob State University', 'label': 'Kulob State University' },
    { 'id': 7332, 'value': 'Kumamoto Gakuen University', 'label': 'Kumamoto Gakuen University' },
    { 'id': 7333, 'value': 'Kumamoto Health Science University', 'label': 'Kumamoto Health Science University' },
    { 'id': 7334, 'value': 'Kumamoto University', 'label': 'Kumamoto University' },
    { 'id': 5977, 'value': 'Kumar Bhaskar Varma Sanskrit and Ancient Studies University', 'label': 'Kumar Bhaskar Varma Sanskrit and Ancient Studies University' },
    { 'id': 4697, 'value': 'Kumasi Technical University', 'label': 'Kumasi Technical University' },
    { 'id': 5978, 'value': 'Kumaun University', 'label': 'Kumaun University' },
    { 'id': 12180, 'value': 'Kumi University', 'label': 'Kumi University' },
    { 'id': 8080, 'value': 'Kumoh National University of Technology', 'label': 'Kumoh National University of Technology' },
    { 'id': 11850, 'value': 'Kun Shan University', 'label': 'Kun Shan University' },
    { 'id': 735, 'value': 'Kunduz University', 'label': 'Kunduz University' },
    { 'id': 11126, 'value': 'Kungliga Konsthögskolan', 'label': 'Kungliga Konsthögskolan' },
    { 'id': 11127, 'value': 'Kungliga Musikhögskolan i Stockholm', 'label': 'Kungliga Musikhögskolan i Stockholm' },
    { 'id': 11128, 'value': 'Kungliga Tekniska högskolan', 'label': 'Kungliga Tekniska högskolan' },
    { 'id': 7335, 'value': 'Kunitachi College of Music', 'label': 'Kunitachi College of Music' },
    { 'id': 2548, 'value': 'Kunming Medical University', 'label': 'Kunming Medical University' },
    { 'id': 2549, 'value': 'Kunming University', 'label': 'Kunming University' },
    { 'id': 2550, 'value': 'Kunming University of Science and Technology', 'label': 'Kunming University of Science and Technology' },
    { 'id': 8081, 'value': 'Kunsan National University', 'label': 'Kunsan National University' },
    { 'id': 3687, 'value': 'Kunstakademie Düsseldorf', 'label': 'Kunstakademie Düsseldorf' },
    { 'id': 3688, 'value': 'Kunstakademie Münster', 'label': 'Kunstakademie Münster' },
    { 'id': 3689, 'value': 'Kunsthochschule für Medien Köln', 'label': 'Kunsthochschule für Medien Köln' },
    { 'id': 9572, 'value': 'Kunsthøgskolen i Oslo', 'label': 'Kunsthøgskolen i Oslo' },
    { 'id': 7336, 'value': 'Kurashiki Sakuyo University', 'label': 'Kurashiki Sakuyo University' },
    { 'id': 7337, 'value': 'Kurashiki University of Science and the Arts', 'label': 'Kurashiki University of Science and the Arts' },
    { 'id': 6458, 'value': 'Kurdistan Institution for Strategic Studies and Scientific Research', 'label': 'Kurdistan Institution for Strategic Studies and Scientific Research' },
    { 'id': 6712, 'value': 'Kurdistan University of Medical Sciences', 'label': 'Kurdistan University of Medical Sciences' },
    { 'id': 10731, 'value': 'Kurgan State University', 'label': 'Kurgan State University' },
    { 'id': 10732, 'value': 'Kursk State Medical University', 'label': 'Kursk State Medical University' },
    { 'id': 10733, 'value': 'Kursk State University', 'label': 'Kursk State University' },
    { 'id': 5979, 'value': 'Kurukshetra University', 'label': 'Kurukshetra University' },
    { 'id': 7338, 'value': 'Kurume Institute of Technology', 'label': 'Kurume Institute of Technology' },
    { 'id': 7339, 'value': 'Kurume University', 'label': 'Kurume University' },
    { 'id': 5980, 'value': 'Kushabhau Thakre Patrakarita Avam Jansanchar University', 'label': 'Kushabhau Thakre Patrakarita Avam Jansanchar University' },
    { 'id': 7340, 'value': 'Kushiro Public University of Economics', 'label': 'Kushiro Public University of Economics' },
    { 'id': 11743, 'value': 'Kütahya Dumlupinar Üniversitesi', 'label': 'Kütahya Dumlupinar Üniversitesi' },
    { 'id': 4643, 'value': 'Kutaisi University', 'label': 'Kutaisi University' },
    { 'id': 12874, 'value': 'Kutztown University of Pennsylvania', 'label': 'Kutztown University of Pennsylvania' },
    { 'id': 5981, 'value': 'Kuvempu University', 'label': 'Kuvempu University' },
    { 'id': 8158, 'value': 'Kuwait University', 'label': 'Kuwait University' },
    { 'id': 12875, 'value': 'Kuyper College', 'label': 'Kuyper College' },
    { 'id': 10734, 'value': 'Kuzbass State Technical University', 'label': 'Kuzbass State Technical University' },
    { 'id': 14267, 'value': 'Kwame Nkrumah University', 'label': 'Kwame Nkrumah University' },
    { 'id': 4698, 'value': 'Kwame Nkrumah University of Science and Technology', 'label': 'Kwame Nkrumah University of Science and Technology' },
    { 'id': 8082, 'value': 'Kwangju Women\'s University', 'label': 'Kwangju Women\'s University' },
    { 'id': 8083, 'value': 'Kwangshin University', 'label': 'Kwangshin University' },
    { 'id': 8084, 'value': 'Kwangwoon University', 'label': 'Kwangwoon University' },
    { 'id': 7341, 'value': 'Kwansei Gakuin University', 'label': 'Kwansei Gakuin University' },
    { 'id': 1874, 'value': 'Kwantlen Polytechnic University', 'label': 'Kwantlen Polytechnic University' },
    { 'id': 9388, 'value': 'Kwara State University', 'label': 'Kwara State University' },
    { 'id': 9389, 'value': 'Kwararafa University, Wukari', 'label': 'Kwararafa University, Wukari' },
    { 'id': 7342, 'value': 'Kwassui Women\'s College', 'label': 'Kwassui Women\'s College' },
    { 'id': 8508, 'value': 'Kyaingtong University', 'label': 'Kyaingtong University' },
    { 'id': 12181, 'value': 'Kyambogo University', 'label': 'Kyambogo University' },
    { 'id': 8509, 'value': 'Kyaukse University', 'label': 'Kyaukse University' },
    { 'id': 12045, 'value': 'Kyiv National Economic University', 'label': 'Kyiv National Economic University' },
    { 'id': 12046, 'value': 'Kyiv National Linguistic University', 'label': 'Kyiv National Linguistic University' },
    { 'id': 12047, 'value': 'Kyiv National University of Construction and Architecture', 'label': 'Kyiv National University of Construction and Architecture' },
    { 'id': 12048, 'value': 'Kyiv National University of Culture and Arts', 'label': 'Kyiv National University of Culture and Arts' },
    { 'id': 12049, 'value': 'Kyiv National University of Technologies and Design', 'label': 'Kyiv National University of Technologies and Design' },
    { 'id': 12050, 'value': 'Kyiv National University of Theatre, Cinema and Television', 'label': 'Kyiv National University of Theatre, Cinema and Television' },
    { 'id': 12051, 'value': 'Kyiv National University of Trade and Economics', 'label': 'Kyiv National University of Trade and Economics' },
    { 'id': 12052, 'value': 'Kyiv Slavonic University', 'label': 'Kyiv Slavonic University' },
    { 'id': 7343, 'value': 'Kyoei University', 'label': 'Kyoei University' },
    { 'id': 8085, 'value': 'Kyonggi University', 'label': 'Kyonggi University' },
    { 'id': 7344, 'value': 'Kyorin University', 'label': 'Kyorin University' },
    { 'id': 7345, 'value': 'Kyoritsu Women\'s University', 'label': 'Kyoritsu Women\'s University' },
    { 'id': 7346, 'value': 'Kyoto Bunkyo University', 'label': 'Kyoto Bunkyo University' },
    { 'id': 7347, 'value': 'Kyoto City University of Arts', 'label': 'Kyoto City University of Arts' },
    { 'id': 7348, 'value': 'Kyoto College of Graduate Studies for Informatics', 'label': 'Kyoto College of Graduate Studies for Informatics' },
    { 'id': 7349, 'value': 'Kyoto College of Medical Science', 'label': 'Kyoto College of Medical Science' },
    { 'id': 7350, 'value': 'Kyoto Institute of Technology', 'label': 'Kyoto Institute of Technology' },
    { 'id': 7351, 'value': 'Kyoto Koka Women\'s University', 'label': 'Kyoto Koka Women\'s University' },
    { 'id': 7352, 'value': 'Kyoto Notre Dame University', 'label': 'Kyoto Notre Dame University' },
    { 'id': 7353, 'value': 'Kyoto Pharmaceutical University', 'label': 'Kyoto Pharmaceutical University' },
    { 'id': 7354, 'value': 'Kyoto Prefectural University', 'label': 'Kyoto Prefectural University' },
    { 'id': 7355, 'value': 'Kyoto Prefectural University of Medicine', 'label': 'Kyoto Prefectural University of Medicine' },
    { 'id': 7356, 'value': 'Kyoto Saga University of Arts', 'label': 'Kyoto Saga University of Arts' },
    { 'id': 7357, 'value': 'Kyoto Sangyo University', 'label': 'Kyoto Sangyo University' },
    { 'id': 7358, 'value': 'Kyoto Seika Daigaku', 'label': 'Kyoto Seika Daigaku' },
    { 'id': 7359, 'value': 'Kyoto Tachibana University', 'label': 'Kyoto Tachibana University' },
    { 'id': 7360, 'value': 'Kyoto University', 'label': 'Kyoto University' },
    { 'id': 7361, 'value': 'Kyoto University of Advanced Science', 'label': 'Kyoto University of Advanced Science' },
    { 'id': 7362, 'value': 'Kyoto University of Art and Design', 'label': 'Kyoto University of Art and Design' },
    { 'id': 7363, 'value': 'Kyoto University of Education', 'label': 'Kyoto University of Education' },
    { 'id': 7364, 'value': 'Kyoto University of Foreign Studies', 'label': 'Kyoto University of Foreign Studies' },
    { 'id': 7365, 'value': 'Kyoto Women\'s University', 'label': 'Kyoto Women\'s University' },
    { 'id': 7862, 'value': 'Kyrgyz Economic University', 'label': 'Kyrgyz Economic University' },
    { 'id': 7863, 'value': 'Kyrgyz National Agrarian University', 'label': 'Kyrgyz National Agrarian University' },
    { 'id': 7864, 'value': 'Kyrgyz National Conservatory', 'label': 'Kyrgyz National Conservatory' },
    { 'id': 7865, 'value': 'Kyrgyz National University', 'label': 'Kyrgyz National University' },
    { 'id': 7866, 'value': 'Kyrgyz Russian Slavic University', 'label': 'Kyrgyz Russian Slavic University' },
    { 'id': 7867, 'value': 'Kyrgyz State Academy of Law', 'label': 'Kyrgyz State Academy of Law' },
    { 'id': 7868, 'value': 'Kyrgyz State Medical Academy', 'label': 'Kyrgyz State Medical Academy' },
    { 'id': 7869, 'value': 'Kyrgyz State Technical University', 'label': 'Kyrgyz State Technical University' },
    { 'id': 7870, 'value': 'Kyrgyz State University after I. Arabayev', 'label': 'Kyrgyz State University after I. Arabayev' },
    { 'id': 7871, 'value': 'Kyrgyz State University of Construction, Transportation and Architecture', 'label': 'Kyrgyz State University of Construction, Transportation and Architecture' },
    { 'id': 7872, 'value': 'Kyrgyz State University of Culture and Arts', 'label': 'Kyrgyz State University of Culture and Arts' },
    { 'id': 7874, 'value': 'Kyrgyz-Uzbek University', 'label': 'Kyrgyz-Uzbek University' },
    { 'id': 7873, 'value': 'Kyrgyzskaya State Academy of Physical Culture and Sports', 'label': 'Kyrgyzskaya State Academy of Physical Culture and Sports' },
    { 'id': 8086, 'value': 'Kyung Hee University', 'label': 'Kyung Hee University' },
    { 'id': 8087, 'value': 'Kyungdong University', 'label': 'Kyungdong University' },
    { 'id': 8088, 'value': 'Kyungil University', 'label': 'Kyungil University' },
    { 'id': 8089, 'value': 'Kyungnam University', 'label': 'Kyungnam University' },
    { 'id': 8090, 'value': 'Kyungpook National University', 'label': 'Kyungpook National University' },
    { 'id': 8091, 'value': 'Kyungsung University', 'label': 'Kyungsung University' },
    { 'id': 8092, 'value': 'Kyungwoon University', 'label': 'Kyungwoon University' },
    { 'id': 7366, 'value': 'Kyushu Dental College', 'label': 'Kyushu Dental College' },
    { 'id': 7367, 'value': 'Kyushu Institute of Information Sciences', 'label': 'Kyushu Institute of Information Sciences' },
    { 'id': 7368, 'value': 'Kyushu Institute of Technology', 'label': 'Kyushu Institute of Technology' },
    { 'id': 7369, 'value': 'Kyushu International University', 'label': 'Kyushu International University' },
    { 'id': 7370, 'value': 'Kyushu Kyoritsu University', 'label': 'Kyushu Kyoritsu University' },
    { 'id': 7371, 'value': 'Kyushu Lutheran College', 'label': 'Kyushu Lutheran College' },
    { 'id': 7372, 'value': 'Kyushu Nutrition Welfare University', 'label': 'Kyushu Nutrition Welfare University' },
    { 'id': 7373, 'value': 'Kyushu Sangyo University', 'label': 'Kyushu Sangyo University' },
    { 'id': 7374, 'value': 'Kyushu University', 'label': 'Kyushu University' },
    { 'id': 7375, 'value': 'Kyushu University of Health and Welfare', 'label': 'Kyushu University of Health and Welfare' },
    { 'id': 7376, 'value': 'Kyushu University of Nursing and Social Welfare', 'label': 'Kyushu University of Nursing and Social Welfare' },
    { 'id': 7377, 'value': 'Kyushu Women\'s University', 'label': 'Kyushu Women\'s University' },
    { 'id': 8235, 'value': 'Kyzylorda State University', 'label': 'Kyzylorda State University' },
    { 'id': 8236, 'value': 'L.B. Goncharov Kazakh Road Academy', 'label': 'L.B. Goncharov Kazakh Road Academy' },
    { 'id': 8237, 'value': 'L.N. Gumilyov Eurasian National University', 'label': 'L.N. Gumilyov Eurasian National University' },
    { 'id': 4396, 'value': 'L\'École de design Nantes Atlantique', 'label': 'L\'École de design Nantes Atlantique' },
    { 'id': 9462, 'value': 'La American University', 'label': 'La American University' },
    { 'id': 9899, 'value': 'La Consolacion College', 'label': 'La Consolacion College' },
    { 'id': 9900, 'value': 'La Consolacion College Manila', 'label': 'La Consolacion College Manila' },
    { 'id': 9901, 'value': 'La Consolacion University Philippines', 'label': 'La Consolacion University Philippines' },
    { 'id': 12876, 'value': 'La Roche University', 'label': 'La Roche University' },
    { 'id': 4394, 'value': 'La Rochelle Université', 'label': 'La Rochelle Université' },
    { 'id': 12877, 'value': 'La Salle University', 'label': 'La Salle University' },
    { 'id': 9902, 'value': 'La Salle University, Ozamiz City', 'label': 'La Salle University, Ozamiz City' },
    { 'id': 12878, 'value': 'La Sierra University', 'label': 'La Sierra University' },
    { 'id': 1083, 'value': 'La Trobe University', 'label': 'La Trobe University' },
    { 'id': 4223, 'value': 'LAB University of Applied Sciences', 'label': 'LAB University of Applied Sciences' },
    { 'id': 509, 'value': 'LABA - Libera Accademia di Belle Arti FIRENZE', 'label': 'LABA - Libera Accademia di Belle Arti FIRENZE' },
    { 'id': 394, 'value': 'LABA Libera Accademia Delle Belle Arti di Brescia', 'label': 'LABA Libera Accademia Delle Belle Arti di Brescia' },
    { 'id': 14367, 'value': 'LABA TRENTINO - ROVERETO', 'label': 'LABA TRENTINO - ROVERETO' },
    { 'id': 12879, 'value': 'Labouré College', 'label': 'Labouré College' },
    { 'id': 14149, 'value': 'Lac Hong University', 'label': 'Lac Hong University' },
    { 'id': 9390, 'value': 'Ladoke Akintola University of Technology', 'label': 'Ladoke Akintola University of Technology' },
    { 'id': 12880, 'value': 'Lafayette College', 'label': 'Lafayette College' },
    { 'id': 736, 'value': 'Laghman University', 'label': 'Laghman University' },
    { 'id': 9391, 'value': 'Lagos State University', 'label': 'Lagos State University' },
    { 'id': 12881, 'value': 'LaGrange College', 'label': 'LaGrange College' },
    { 'id': 429, 'value': 'LaGuardia Community College', 'label': 'LaGuardia Community College' },
    { 'id': 12882, 'value': 'Laguna College of Art and Design', 'label': 'Laguna College of Art and Design' },
    { 'id': 9903, 'value': 'Laguna State Polytechnic University', 'label': 'Laguna State Polytechnic University' },
    { 'id': 10117, 'value': 'Lahore College for Women University', 'label': 'Lahore College for Women University' },
    { 'id': 10118, 'value': 'Lahore Garrison University', 'label': 'Lahore Garrison University' },
    { 'id': 10119, 'value': 'Lahore Leads University', 'label': 'Lahore Leads University' },
    { 'id': 10120, 'value': 'Lahore School of Economics', 'label': 'Lahore School of Economics' },
    { 'id': 10121, 'value': 'Lahore University of Management Sciences', 'label': 'Lahore University of Management Sciences' },
    { 'id': 7805, 'value': 'Laikipia University', 'label': 'Laikipia University' },
    { 'id': 12883, 'value': 'Lake Erie College', 'label': 'Lake Erie College' },
    { 'id': 12884, 'value': 'Lake Forest College', 'label': 'Lake Forest College' },
    { 'id': 12885, 'value': 'Lake Forest Graduate School of Management', 'label': 'Lake Forest Graduate School of Management' },
    { 'id': 8680, 'value': 'Lake Malawi Anglican University', 'label': 'Lake Malawi Anglican University' },
    { 'id': 12886, 'value': 'Lake Superior State University', 'label': 'Lake Superior State University' },
    { 'id': 12887, 'value': 'Lake Washington Institute of Technology', 'label': 'Lake Washington Institute of Technology' },
    { 'id': 1875, 'value': 'Lakehead University', 'label': 'Lakehead University' },
    { 'id': 12888, 'value': 'Lakeland College', 'label': 'Lakeland College' },
    { 'id': 12889, 'value': 'Lakeview College of Nursing', 'label': 'Lakeview College of Nursing' },
    { 'id': 5982, 'value': 'Lakshmibai National Institute of Physical Education', 'label': 'Lakshmibai National Institute of Physical Education' },
    { 'id': 5983, 'value': 'Lakulish Yoga University', 'label': 'Lakulish Yoga University' },
    { 'id': 5984, 'value': 'Lala Lajpat Rai University of Veterinary and Animal Sciences', 'label': 'Lala Lajpat Rai University of Veterinary and Animal Sciences' },
    { 'id': 5985, 'value': 'Lalit Narayan Mithila University', 'label': 'Lalit Narayan Mithila University' },
    { 'id': 12890, 'value': 'Lamar University', 'label': 'Lamar University' },
    { 'id': 11411, 'value': 'Lampang Rajabhat University', 'label': 'Lampang Rajabhat University' },
    { 'id': 11851, 'value': 'Lan Yang Institute of Technology', 'label': 'Lan Yang Institute of Technology' },
    { 'id': 12891, 'value': 'Lancaster Bible College', 'label': 'Lancaster Bible College' },
    { 'id': 4524, 'value': 'Lancaster University', 'label': 'Lancaster University' },
    { 'id': 4699, 'value': 'Lancaster University, Ghana', 'label': 'Lancaster University, Ghana' },
    { 'id': 12892, 'value': 'Lander University', 'label': 'Lander University' },
    { 'id': 12893, 'value': 'Landmark College', 'label': 'Landmark College' },
    { 'id': 9392, 'value': 'Landmark University', 'label': 'Landmark University' },
    { 'id': 12894, 'value': 'Lane College', 'label': 'Lane College' },
    { 'id': 2551, 'value': 'Langfang Teachers University', 'label': 'Langfang Teachers University' },
    { 'id': 12895, 'value': 'Langston University', 'label': 'Langston University' },
    { 'id': 8611, 'value': 'Language and Civilisation Institute', 'label': 'Language and Civilisation Institute' },
    { 'id': 2552, 'value': 'Lanzhou City University', 'label': 'Lanzhou City University' },
    { 'id': 2553, 'value': 'Lanzhou Institute of Technology', 'label': 'Lanzhou Institute of Technology' },
    { 'id': 2554, 'value': 'Lanzhou Jiaotong University', 'label': 'Lanzhou Jiaotong University' },
    { 'id': 2555, 'value': 'Lanzhou University', 'label': 'Lanzhou University' },
    { 'id': 2556, 'value': 'Lanzhou University of Arts and Science', 'label': 'Lanzhou University of Arts and Science' },
    { 'id': 2557, 'value': 'Lanzhou University of Finance and Economics', 'label': 'Lanzhou University of Finance and Economics' },
    { 'id': 2558, 'value': 'Lanzhou University of Technology', 'label': 'Lanzhou University of Technology' },
    { 'id': 4224, 'value': 'Lapin Ammattikorkeakoulu', 'label': 'Lapin Ammattikorkeakoulu' },
    { 'id': 4225, 'value': 'Lapin yliopisto', 'label': 'Lapin yliopisto' },
    { 'id': 4226, 'value': 'Lappeenrannan teknillinen yliopisto', 'label': 'Lappeenrannan teknillinen yliopisto' },
    { 'id': 3369, 'value': 'Larnaca College', 'label': 'Larnaca College' },
    { 'id': 11147, 'value': 'LASALLE College of the Arts', 'label': 'LASALLE College of the Arts' },
    { 'id': 10122, 'value': 'Lasbela University of Agriculture, Water and Marine Sciences', 'label': 'Lasbela University of Agriculture, Water and Marine Sciences' },
    { 'id': 12896, 'value': 'Lasell College', 'label': 'Lasell College' },
    { 'id': 8510, 'value': 'Lashio University', 'label': 'Lashio University' },
    { 'id': 8376, 'value': 'Latvijas Juras akademija', 'label': 'Latvijas Juras akademija' },
    { 'id': 8377, 'value': 'Latvijas Kristiga akademija', 'label': 'Latvijas Kristiga akademija' },
    { 'id': 8378, 'value': 'Latvijas Kulturas akademija', 'label': 'Latvijas Kulturas akademija' },
    { 'id': 8379, 'value': 'Latvijas Lauksaimniecibas universitate', 'label': 'Latvijas Lauksaimniecibas universitate' },
    { 'id': 8380, 'value': 'Latvijas Makslas akademija', 'label': 'Latvijas Makslas akademija' },
    { 'id': 8381, 'value': 'Latvijas Sporta pedagogijas akademija', 'label': 'Latvijas Sporta pedagogijas akademija' },
    { 'id': 8382, 'value': 'Latvijas Universitate', 'label': 'Latvijas Universitate' },
    { 'id': 1035, 'value': 'Lauder Business School', 'label': 'Lauder Business School' },
    { 'id': 4227, 'value': 'Laurea-ammattikorkeakoulu', 'label': 'Laurea-ammattikorkeakoulu' },
    { 'id': 1876, 'value': 'Laurentian University', 'label': 'Laurentian University' },
    { 'id': 12897, 'value': 'Lawrence Technological University', 'label': 'Lawrence Technological University' },
    { 'id': 12898, 'value': 'Lawrence University', 'label': 'Lawrence University' },
    { 'id': 8345, 'value': 'LCC tarptautinis universitetas', 'label': 'LCC tarptautinis universitetas' },
    { 'id': 14176, 'value': 'Le Iunivesite Aoao o Samoa', 'label': 'Le Iunivesite Aoao o Samoa' },
    { 'id': 4395, 'value': 'Le Mans Université', 'label': 'Le Mans Université' },
    { 'id': 12899, 'value': 'Le Moyne College', 'label': 'Le Moyne College' },
    { 'id': 9393, 'value': 'Lead City University', 'label': 'Lead City University' },
    { 'id': 3229, 'value': 'LEAD University', 'label': 'LEAD University' },
    { 'id': 11590, 'value': 'Leaders University', 'label': 'Leaders University' },
    { 'id': 1274, 'value': 'Leading University', 'label': 'Leading University' },
    { 'id': 8287, 'value': 'Lebanese American University', 'label': 'Lebanese American University' },
    { 'id': 6459, 'value': 'Lebanese French University', 'label': 'Lebanese French University' },
    { 'id': 8288, 'value': 'Lebanese German University', 'label': 'Lebanese German University' },
    { 'id': 8289, 'value': 'Lebanese International University', 'label': 'Lebanese International University' },
    { 'id': 14204, 'value': 'Lebanese International University Yemen', 'label': 'Lebanese International University Yemen' },
    { 'id': 12900, 'value': 'Lebanon Valley College', 'label': 'Lebanon Valley College' },
    { 'id': 7378, 'value': 'LEC Tokyo Legal Mind University', 'label': 'LEC Tokyo Legal Mind University' },
    { 'id': 3370, 'value': 'Ledra College', 'label': 'Ledra College' },
    { 'id': 12901, 'value': 'Lee University', 'label': 'Lee University' },
    { 'id': 11852, 'value': 'Lee-Ming Institute of Technology', 'label': 'Lee-Ming Institute of Technology' },
    { 'id': 4525, 'value': 'Leeds Arts University', 'label': 'Leeds Arts University' },
    { 'id': 565, 'value': 'Leeds Beckett University', 'label': 'Leeds Beckett University' },
    { 'id': 4526, 'value': 'Leeds College of Music', 'label': 'Leeds College of Music' },
    { 'id': 4527, 'value': 'Leeds Trinity University', 'label': 'Leeds Trinity University' },
    { 'id': 12902, 'value': 'Lees-McRae College', 'label': 'Lees-McRae College' },
    { 'id': 3371, 'value': 'Lefke Avrupa Üniversitesi', 'label': 'Lefke Avrupa Üniversitesi' },
    { 'id': 9394, 'value': 'Legacy University, Okija', 'label': 'Legacy University, Okija' },
    { 'id': 12903, 'value': 'Lehigh University', 'label': 'Lehigh University' },
    { 'id': 12904, 'value': 'Lehman College, CUNY', 'label': 'Lehman College, CUNY' },
    { 'id': 3690, 'value': 'Leibniz Universität Hannover', 'label': 'Leibniz Universität Hannover' },
    { 'id': 3691, 'value': 'Leibniz-Fachhochschule', 'label': 'Leibniz-Fachhochschule' },
    { 'id': 133, 'value': 'Leipzig Graduate School of Management', 'label': 'Leipzig Graduate School of Management' },
    { 'id': 12905, 'value': 'LeMoyne-Owen College', 'label': 'LeMoyne-Owen College' },
    { 'id': 10735, 'value': 'Leningrad State University', 'label': 'Leningrad State University' },
    { 'id': 1143, 'value': 'Lenkeran Dövlet Universiteti', 'label': 'Lenkeran Dövlet Universiteti' },
    { 'id': 12906, 'value': 'Lenoir-Rhyne University', 'label': 'Lenoir-Rhyne University' },
    { 'id': 1516, 'value': 'Les Cours Sonou', 'label': 'Les Cours Sonou' },
    { 'id': 14419, 'value': 'Les Roches Marbella Global Hospitality Education', 'label': 'Les Roches Marbella Global Hospitality Education' },
    { 'id': 2559, 'value': 'Leshan Normal University', 'label': 'Leshan Normal University' },
    { 'id': 12907, 'value': 'Lesley University', 'label': 'Lesley University' },
    { 'id': 8330, 'value': 'Lesotho College of Education', 'label': 'Lesotho College of Education' },
    { 'id': 12053, 'value': 'Lesya Ukrainka Eastern European National University', 'label': 'Lesya Ukrainka Eastern European National University' },
    { 'id': 12908, 'value': 'LeTourneau University', 'label': 'LeTourneau University' },
    { 'id': 5515, 'value': 'Letterkenny Institute of Technology', 'label': 'Letterkenny Institute of Technology' },
    { 'id': 3692, 'value': 'Leuphana Universität Lüneburg', 'label': 'Leuphana Universität Lüneburg' },
    { 'id': 12909, 'value': 'Lewis & Clark College', 'label': 'Lewis & Clark College' },
    { 'id': 12910, 'value': 'Lewis University', 'label': 'Lewis University' },
    { 'id': 12911, 'value': 'Lewis-Clark State College', 'label': 'Lewis-Clark State College' },
    { 'id': 9904, 'value': 'Leyte Normal University', 'label': 'Leyte Normal University' },
    { 'id': 2560, 'value': 'Liaocheng University', 'label': 'Liaocheng University' },
    { 'id': 2561, 'value': 'Liaoning Communication University', 'label': 'Liaoning Communication University' },
    { 'id': 2562, 'value': 'Liaoning Finance and Trade College', 'label': 'Liaoning Finance and Trade College' },
    { 'id': 2563, 'value': 'Liaoning Institute of Science and Engineering', 'label': 'Liaoning Institute of Science and Engineering' },
    { 'id': 2564, 'value': 'Liaoning Institute of Science and Technology', 'label': 'Liaoning Institute of Science and Technology' },
    { 'id': 2565, 'value': 'Liaoning Normal University', 'label': 'Liaoning Normal University' },
    { 'id': 2566, 'value': 'Liaoning Shihua University', 'label': 'Liaoning Shihua University' },
    { 'id': 2567, 'value': 'Liaoning Technical University', 'label': 'Liaoning Technical University' },
    { 'id': 2568, 'value': 'Liaoning University', 'label': 'Liaoning University' },
    { 'id': 2569, 'value': 'Liaoning University of International Business and Economics', 'label': 'Liaoning University of International Business and Economics' },
    { 'id': 2570, 'value': 'Liaoning University of Technology', 'label': 'Liaoning University of Technology' },
    { 'id': 2571, 'value': 'Liaoning University of Traditional Chinese Medicine', 'label': 'Liaoning University of Traditional Chinese Medicine' },
    { 'id': 10123, 'value': 'Liaquat University of Medical and Health Sciences', 'label': 'Liaquat University of Medical and Health Sciences' },
    { 'id': 6941, 'value': 'Libera Accademia di Belle Arti di Firenze', 'label': 'Libera Accademia di Belle Arti di Firenze' },
    { 'id': 6915, 'value': 'Libera Accademia di Belle Arti di Rimini', 'label': 'Libera Accademia di Belle Arti di Rimini' },
    { 'id': 273, 'value': 'Libera Università di Bolzano', 'label': 'Libera Università di Bolzano' },
    { 'id': 382, 'value': 'Libera Università Mediterranea Jean Monnet (LUM)', 'label': 'Libera Università Mediterranea Jean Monnet (LUM)' },
    { 'id': 6825, 'value': 'Libera Università Vita-Salute San Raffaele di Milano', 'label': 'Libera Università Vita-Salute San Raffaele di Milano' },
    { 'id': 4823, 'value': 'Libertas Medunarodno Sveucilište', 'label': 'Libertas Medunarodno Sveucilište' },
    { 'id': 12912, 'value': 'Liberty University', 'label': 'Liberty University' },
    { 'id': 4490, 'value': 'Libreville International Business School', 'label': 'Libreville International Business School' },
    { 'id': 8400, 'value': 'Libyan International Medical University', 'label': 'Libyan International Medical University' },
    { 'id': 9905, 'value': 'Liceo de Cagayan University', 'label': 'Liceo de Cagayan University' },
    { 'id': 8383, 'value': 'Liepajas Universitate', 'label': 'Liepajas Universitate' },
    { 'id': 8346, 'value': 'Lietuvos aukštoji jureivystes mokykla', 'label': 'Lietuvos aukštoji jureivystes mokykla' },
    { 'id': 8347, 'value': 'Lietuvos muzikos ir teatro akademija', 'label': 'Lietuvos muzikos ir teatro akademija' },
    { 'id': 8348, 'value': 'Lietuvos sporto universitetas', 'label': 'Lietuvos sporto universitetas' },
    { 'id': 8349, 'value': 'Lietuvos sveikatos mokslu universitetas', 'label': 'Lietuvos sveikatos mokslu universitetas' },
    { 'id': 8350, 'value': 'Lietuvos verslo kolegija', 'label': 'Lietuvos verslo kolegija' },
    { 'id': 12913, 'value': 'Life Pacific College', 'label': 'Life Pacific College' },
    { 'id': 12914, 'value': 'Life University', 'label': 'Life University' },
    { 'id': 7909, 'value': 'Life University, Cambodia', 'label': 'Life University, Cambodia' },
    { 'id': 8681, 'value': 'Lilongwe University of Agriculture and Natural Resources', 'label': 'Lilongwe University of Agriculture and Natural Resources' },
    { 'id': 12915, 'value': 'LIM College', 'label': 'LIM College' },
    { 'id': 5516, 'value': 'Limerick Institute of Technology', 'label': 'Limerick Institute of Technology' },
    { 'id': 12916, 'value': 'Limestone College', 'label': 'Limestone College' },
    { 'id': 11358, 'value': 'Limkokwing University of Creative Technology Swaziland', 'label': 'Limkokwing University of Creative Technology Swaziland' },
    { 'id': 1795, 'value': 'Limkokwing University of Creative Technology, Botswana', 'label': 'Limkokwing University of Creative Technology, Botswana' },
    { 'id': 7910, 'value': 'Limkokwing University of Creative Technology, Cambodia', 'label': 'Limkokwing University of Creative Technology, Cambodia' },
    { 'id': 4792, 'value': 'Lincoln American University', 'label': 'Lincoln American University' },
    { 'id': 12917, 'value': 'Lincoln Christian University', 'label': 'Lincoln Christian University' },
    { 'id': 12918, 'value': 'Lincoln College', 'label': 'Lincoln College' },
    { 'id': 12919, 'value': 'Lincoln Memorial University', 'label': 'Lincoln Memorial University' },
    { 'id': 12920, 'value': 'Lincoln University', 'label': 'Lincoln University' },
    { 'id': 12921, 'value': 'Lincoln University, California', 'label': 'Lincoln University, California' },
    { 'id': 12922, 'value': 'Lincoln University, Missouri', 'label': 'Lincoln University, Missouri' },
    { 'id': 9601, 'value': 'Lincoln University, New Zealand', 'label': 'Lincoln University, New Zealand' },
    { 'id': 12923, 'value': 'Lindenwood University', 'label': 'Lindenwood University' },
    { 'id': 12924, 'value': 'Lindsey Wilson College', 'label': 'Lindsey Wilson College' },
    { 'id': 12925, 'value': 'Linfield College', 'label': 'Linfield College' },
    { 'id': 11853, 'value': 'Ling Tung University', 'label': 'Ling Tung University' },
    { 'id': 5986, 'value': 'Lingaya\'s University', 'label': 'Lingaya\'s University' },
    { 'id': 2572, 'value': 'Lingnan Normal University', 'label': 'Lingnan Normal University' },
    { 'id': 4800, 'value': 'Lingnan University', 'label': 'Lingnan University' },
    { 'id': 10736, 'value': 'Linguistic University of Nizhny Novgorod', 'label': 'Linguistic University of Nizhny Novgorod' },
    { 'id': 36, 'value': 'Link Campus University di Roma', 'label': 'Link Campus University di Roma' },
    { 'id': 554, 'value': 'Linköping University', 'label': 'Linköping University' },
    { 'id': 11129, 'value': 'Linköpings Universitet', 'label': 'Linköpings Universitet' },
    { 'id': 11130, 'value': 'Linnéuniversitetet', 'label': 'Linnéuniversitetet' },
    { 'id': 2573, 'value': 'Linyi University', 'label': 'Linyi University' },
    { 'id': 9906, 'value': 'Lipa City Colleges', 'label': 'Lipa City Colleges' },
    { 'id': 10737, 'value': 'Lipetsk State Pedagogical University', 'label': 'Lipetsk State Pedagogical University' },
    { 'id': 10738, 'value': 'Lipetsk State Technical University', 'label': 'Lipetsk State Technical University' },
    { 'id': 12926, 'value': 'Lipscomb University', 'label': 'Lipscomb University' },
    { 'id': 12182, 'value': 'Lira University', 'label': 'Lira University' },
    { 'id': 14651, 'value': 'Lisbon School of Economics and Management - ISEG', 'label': 'Lisbon School of Economics and Management - ISEG' },
    { 'id': 2574, 'value': 'Lishui University', 'label': 'Lishui University' },
    { 'id': 6816, 'value': 'Listaháskóli Íslands', 'label': 'Listaháskóli Íslands' },
    { 'id': 4908, 'value': 'Liszt Ferenc Zenemuvészeti Egyetem', 'label': 'Liszt Ferenc Zenemuvészeti Egyetem' },
    { 'id': 2575, 'value': 'Liupanshui Normal University', 'label': 'Liupanshui Normal University' },
    { 'id': 4528, 'value': 'Liverpool Hope University', 'label': 'Liverpool Hope University' },
    { 'id': 4529, 'value': 'Liverpool Institute for Performing Arts', 'label': 'Liverpool Institute for Performing Arts' },
    { 'id': 4530, 'value': 'Liverpool John Moores University', 'label': 'Liverpool John Moores University' },
    { 'id': 12927, 'value': 'Livingstone College', 'label': 'Livingstone College' },
    { 'id': 12183, 'value': 'LivingStone International University', 'label': 'LivingStone International University' },
    { 'id': 14268, 'value': 'Livingstone International University of Tourism Excellence and Business Management', 'label': 'Livingstone International University of Tourism Excellence and Business Management' },
    { 'id': 5987, 'value': 'LNCT University', 'label': 'LNCT University' },
    { 'id': 10739, 'value': 'Lobachevsky State University of Nizhni Novgorod', 'label': 'Lobachevsky State University of Nizhni Novgorod' },
    { 'id': 12928, 'value': 'Lock Haven University', 'label': 'Lock Haven University' },
    { 'id': 11412, 'value': 'Loei Rajabhat University', 'label': 'Loei Rajabhat University' },
    { 'id': 12929, 'value': 'Logan University', 'label': 'Logan University' },
    { 'id': 8511, 'value': 'Loikaw University', 'label': 'Loikaw University' },
    { 'id': 12930, 'value': 'Loma Linda University', 'label': 'Loma Linda University' },
    { 'id': 14349, 'value': 'Lombardia - ITS Angelo Rizzoli per le Tecnologie dell\'Informazione e della Comunicazione', 'label': 'Lombardia - ITS Angelo Rizzoli per le Tecnologie dell\'Informazione e della Comunicazione' },
    { 'id': 166, 'value': 'London Business School', 'label': 'London Business School' },
    { 'id': 364, 'value': 'London Film School', 'label': 'London Film School' },
    { 'id': 254, 'value': 'London Metropolitan University', 'label': 'London Metropolitan University' },
    { 'id': 123, 'value': 'London School of Economics and Political Science (LSE)', 'label': 'London School of Economics and Political Science (LSE)' },
    { 'id': 4531, 'value': 'London School of Hygiene and Tropical Medicine, University of London', 'label': 'London School of Hygiene and Tropical Medicine, University of London' },
    { 'id': 4532, 'value': 'London South Bank University', 'label': 'London South Bank University' },
    { 'id': 162, 'value': 'London Studio Centre', 'label': 'London Studio Centre' },
    { 'id': 12931, 'value': 'Long Island University', 'label': 'Long Island University' },
    { 'id': 2576, 'value': 'Longdong University', 'label': 'Longdong University' },
    { 'id': 12932, 'value': 'Longwood University', 'label': 'Longwood University' },
    { 'id': 2577, 'value': 'Longyan University', 'label': 'Longyan University' },
    { 'id': 12933, 'value': 'Loras College', 'label': 'Loras College' },
    { 'id': 6713, 'value': 'Lorestan University', 'label': 'Lorestan University' },
    { 'id': 6714, 'value': 'Lorestan University of Medical Sciences', 'label': 'Lorestan University of Medical Sciences' },
    { 'id': 9907, 'value': 'Lorma Colleges', 'label': 'Lorma Colleges' },
    { 'id': 526, 'value': 'Loughborough University', 'label': 'Loughborough University' },
    { 'id': 12934, 'value': 'Louisiana College', 'label': 'Louisiana College' },
    { 'id': 12935, 'value': 'Louisiana State University', 'label': 'Louisiana State University' },
    { 'id': 12936, 'value': 'Louisiana State University Health Sciences Center', 'label': 'Louisiana State University Health Sciences Center' },
    { 'id': 12937, 'value': 'Louisiana State University Health Sciences Center-Shreveport', 'label': 'Louisiana State University Health Sciences Center-Shreveport' },
    { 'id': 12938, 'value': 'Louisiana State University in Shreveport', 'label': 'Louisiana State University in Shreveport' },
    { 'id': 12939, 'value': 'Louisiana State University of Alexandria', 'label': 'Louisiana State University of Alexandria' },
    { 'id': 12940, 'value': 'Louisiana Tech University', 'label': 'Louisiana Tech University' },
    { 'id': 9908, 'value': 'Lourdes College', 'label': 'Lourdes College' },
    { 'id': 12941, 'value': 'Lourdes University', 'label': 'Lourdes University' },
    { 'id': 5988, 'value': 'Lovely Professional University', 'label': 'Lovely Professional University' },
    { 'id': 9573, 'value': 'Lovisenberg diakonale høgskole', 'label': 'Lovisenberg diakonale høgskole' },
    { 'id': 12942, 'value': 'Loyola Marymount University', 'label': 'Loyola Marymount University' },
    { 'id': 12943, 'value': 'Loyola University Chicago', 'label': 'Loyola University Chicago' },
    { 'id': 12944, 'value': 'Loyola University Maryland', 'label': 'Loyola University Maryland' },
    { 'id': 12945, 'value': 'Loyola University New Orleans', 'label': 'Loyola University New Orleans' },
    { 'id': 12946, 'value': 'Lubbock Christian University', 'label': 'Lubbock Christian University' },
    { 'id': 1386, 'value': 'LUCA School of Arts', 'label': 'LUCA School of Arts' },
    { 'id': 2578, 'value': 'Ludong University', 'label': 'Ludong University' },
    { 'id': 3693, 'value': 'Ludwig-Maximilians-Universität München', 'label': 'Ludwig-Maximilians-Universität München' },
    { 'id': 12054, 'value': 'Lugansk National Agrarian University', 'label': 'Lugansk National Agrarian University' },
    { 'id': 12055, 'value': 'Lugansk State Medical University', 'label': 'Lugansk State Medical University' },
    { 'id': 12056, 'value': 'Lugansk State University of Internal Affairs', 'label': 'Lugansk State University of Internal Affairs' },
    { 'id': 12057, 'value': 'Luhansk Taras Shevchenko National University', 'label': 'Luhansk Taras Shevchenko National University' },
    { 'id': 6848, 'value': 'LUISS - Libera Università internazionale degli studi sociali Guido Carli di Roma', 'label': 'LUISS - Libera Università internazionale degli studi sociali Guido Carli di Roma' },
    { 'id': 7806, 'value': 'Lukenya University', 'label': 'Lukenya University' },
    { 'id': 11131, 'value': 'Luleå tekniska Universitet', 'label': 'Luleå tekniska Universitet' },
    { 'id': 2579, 'value': 'Luliang University', 'label': 'Luliang University' },
    { 'id': 9594, 'value': 'Lumbini Buddhist University', 'label': 'Lumbini Buddhist University' },
    { 'id': 59, 'value': 'LUMSA - Libera Università Maria SS.Assunta', 'label': 'LUMSA - Libera Università Maria SS.Assunta' },
    { 'id': 289, 'value': 'Lund University', 'label': 'Lund University' },
    { 'id': 11132, 'value': 'Lunds Universitet', 'label': 'Lunds Universitet' },
    { 'id': 14403, 'value': 'LUNEX University International University of Health, Exercise & Sports', 'label': 'LUNEX University International University of Health, Exercise & Sports' },
    { 'id': 11854, 'value': 'Lunghwa University of Science and Technology', 'label': 'Lunghwa University of Science and Technology' },
    { 'id': 2580, 'value': 'Luoyang Institute of Science and Technology', 'label': 'Luoyang Institute of Science and Technology' },
    { 'id': 2581, 'value': 'Luoyang Normal University', 'label': 'Luoyang Normal University' },
    { 'id': 14308, 'value': 'Lupane State University', 'label': 'Lupane State University' },
    { 'id': 14269, 'value': 'Lusaka Apex Medical University', 'label': 'Lusaka Apex Medical University' },
    { 'id': 12947, 'value': 'Luther College', 'label': 'Luther College' },
    { 'id': 1877, 'value': 'Luther College at the University of Regina', 'label': 'Luther College at the University of Regina' },
    { 'id': 8093, 'value': 'Luther University', 'label': 'Luther University' },
    { 'id': 3694, 'value': 'Lutherische Theologische Hochschule', 'label': 'Lutherische Theologische Hochschule' },
    { 'id': 12058, 'value': 'Lutsk National Technical University', 'label': 'Lutsk National Technical University' },
    { 'id': 2582, 'value': 'LuXun Academy of Fine Arts', 'label': 'LuXun Academy of Fine Arts' },
    { 'id': 12059, 'value': 'Lviv National Academy of Arts', 'label': 'Lviv National Academy of Arts' },
    { 'id': 12060, 'value': 'Lviv National Agrarian University', 'label': 'Lviv National Agrarian University' },
    { 'id': 12061, 'value': 'Lviv National Medical University', 'label': 'Lviv National Medical University' },
    { 'id': 12062, 'value': 'Lviv National Music Academy', 'label': 'Lviv National Music Academy' },
    { 'id': 12063, 'value': 'Lviv National University of Veterinary Medicine and Biotechnologies', 'label': 'Lviv National University of Veterinary Medicine and Biotechnologies' },
    { 'id': 12064, 'value': 'Lviv Polytechnic National University', 'label': 'Lviv Polytechnic National University' },
    { 'id': 12065, 'value': 'Lviv State University of Physical Culture', 'label': 'Lviv State University of Physical Culture' },
    { 'id': 12066, 'value': 'Lviv University of Trade and Economics', 'label': 'Lviv University of Trade and Economics' },
    { 'id': 9909, 'value': 'Lyceum of the Philippines University', 'label': 'Lyceum of the Philippines University' },
    { 'id': 9910, 'value': 'Lyceum-Northwestern University', 'label': 'Lyceum-Northwestern University' },
    { 'id': 12948, 'value': 'Lycoming College', 'label': 'Lycoming College' },
    { 'id': 12949, 'value': 'Lynn University', 'label': 'Lynn University' },
    { 'id': 12950, 'value': 'Lyon College', 'label': 'Lyon College' },
    { 'id': 3332, 'value': 'M_EIA Instituto Universitário de Arte, Tecnologia e Cultura', 'label': 'M_EIA Instituto Universitário de Arte, Tecnologia e Cultura' },
    { 'id': 10740, 'value': 'M. Akmullah Bashkir State Pedagogical University', 'label': 'M. Akmullah Bashkir State Pedagogical University' },
    { 'id': 5989, 'value': 'M.J.P. Rohilkhand University', 'label': 'M.J.P. Rohilkhand University' },
    { 'id': 5990, 'value': 'M.S. Ramaiah University of Applied Sciences', 'label': 'M.S. Ramaiah University of Applied Sciences' },
    { 'id': 1144, 'value': 'M.V. Lomonosov adina Moskva Dövlet Universitetinin Baki', 'label': 'M.V. Lomonosov adina Moskva Dövlet Universitetinin Baki' },
    { 'id': 7807, 'value': 'Maasai Mara University', 'label': 'Maasai Mara University' },
    { 'id': 8159, 'value': 'Maastricht School of Management Kuwait', 'label': 'Maastricht School of Management Kuwait' },
    { 'id': 111, 'value': 'Maastricht University', 'label': 'Maastricht University' },
    { 'id': 12951, 'value': 'Macalester College', 'label': 'Macalester College' },
    { 'id': 8657, 'value': 'Macau Institute of Management', 'label': 'Macau Institute of Management' },
    { 'id': 8658, 'value': 'Macau Millennium College', 'label': 'Macau Millennium College' },
    { 'id': 8659, 'value': 'Macau University of Science and Technology', 'label': 'Macau University of Science and Technology' },
    { 'id': 1878, 'value': 'MacEwan University', 'label': 'MacEwan University' },
    { 'id': 7808, 'value': 'Machakos University', 'label': 'Machakos University' },
    { 'id': 5543, 'value': 'Machon Lander', 'label': 'Machon Lander' },
    { 'id': 12952, 'value': 'MacMurray College', 'label': 'MacMurray College' },
    { 'id': 1084, 'value': 'Macquarie University', 'label': 'Macquarie University' },
    { 'id': 5991, 'value': 'Madan Mohan Malaviya University of Technology', 'label': 'Madan Mohan Malaviya University of Technology' },
    { 'id': 4192, 'value': 'Madda Walabu University', 'label': 'Madda Walabu University' },
    { 'id': 5992, 'value': 'Madhav University', 'label': 'Madhav University' },
    { 'id': 12953, 'value': 'Madonna University', 'label': 'Madonna University' },
    { 'id': 9395, 'value': 'Madonna University, Okija', 'label': 'Madonna University, Okija' },
    { 'id': 5993, 'value': 'Madurai Kamaraj University', 'label': 'Madurai Kamaraj University' },
    { 'id': 11413, 'value': 'Mae Fah Luang University', 'label': 'Mae Fah Luang University' },
    { 'id': 7379, 'value': 'Maebashi Institute of Technology', 'label': 'Maebashi Institute of Technology' },
    { 'id': 7380, 'value': 'Maebashi Kyoai Gakuen College', 'label': 'Maebashi Kyoai Gakuen College' },
    { 'id': 11414, 'value': 'Maejo University', 'label': 'Maejo University' },
    { 'id': 5994, 'value': 'Magadh University', 'label': 'Magadh University' },
    { 'id': 12954, 'value': 'Magdalen College of the Liberal Arts', 'label': 'Magdalen College of the Liberal Arts' },
    { 'id': 10741, 'value': 'Magnitogorsk State Conservatory', 'label': 'Magnitogorsk State Conservatory' },
    { 'id': 10742, 'value': 'Magnitogorsk State Technical University', 'label': 'Magnitogorsk State Technical University' },
    { 'id': 8512, 'value': 'Magway University', 'label': 'Magway University' },
    { 'id': 4909, 'value': 'Magyar Képzomuvészeti Egyetem', 'label': 'Magyar Képzomuvészeti Egyetem' },
    { 'id': 4910, 'value': 'Magyar Táncmuvészeti Egyetem', 'label': 'Magyar Táncmuvészeti Egyetem' },
    { 'id': 11415, 'value': 'Mahachulalongkornrajavidyalaya University', 'label': 'Mahachulalongkornrajavidyalaya University' },
    { 'id': 11416, 'value': 'Mahamakut Buddhist University', 'label': 'Mahamakut Buddhist University' },
    { 'id': 11417, 'value': 'Mahanakorn University of Technology', 'label': 'Mahanakorn University of Technology' },
    { 'id': 5995, 'value': 'Mahapurusha Srimanta Sankaradeva Viswavidyalaya', 'label': 'Mahapurusha Srimanta Sankaradeva Viswavidyalaya' },
    { 'id': 5996, 'value': 'Maharaja Agrasen University', 'label': 'Maharaja Agrasen University' },
    { 'id': 5997, 'value': 'Maharaja Bir Bikram University', 'label': 'Maharaja Bir Bikram University' },
    { 'id': 5998, 'value': 'Maharaja Chhatrasal Bundelkhand University', 'label': 'Maharaja Chhatrasal Bundelkhand University' },
    { 'id': 5999, 'value': 'Maharaja Ganga Singh University', 'label': 'Maharaja Ganga Singh University' },
    { 'id': 6000, 'value': 'Maharaja Krishnakumarsinhji Bhavnagar University', 'label': 'Maharaja Krishnakumarsinhji Bhavnagar University' },
    { 'id': 6001, 'value': 'Maharaja Ranjit Singh Punjab Technical University', 'label': 'Maharaja Ranjit Singh Punjab Technical University' },
    { 'id': 6002, 'value': 'Maharaja Surajmal Brij University', 'label': 'Maharaja Surajmal Brij University' },
    { 'id': 6003, 'value': 'Maharana Pratap University of Agriculture and Technology', 'label': 'Maharana Pratap University of Agriculture and Technology' },
    { 'id': 6004, 'value': 'Maharashtra Animal and Fishery Sciences University', 'label': 'Maharashtra Animal and Fishery Sciences University' },
    { 'id': 6005, 'value': 'Maharashtra National Law University Mumbai', 'label': 'Maharashtra National Law University Mumbai' },
    { 'id': 6006, 'value': 'Maharashtra National Law University, Aurangabad', 'label': 'Maharashtra National Law University, Aurangabad' },
    { 'id': 6007, 'value': 'Maharashtra National Law University, Nagpur', 'label': 'Maharashtra National Law University, Nagpur' },
    { 'id': 6008, 'value': 'Maharashtra University of Health Sciences', 'label': 'Maharashtra University of Health Sciences' },
    { 'id': 6009, 'value': 'Maharishi Arvind University, Jaipur', 'label': 'Maharishi Arvind University, Jaipur' },
    { 'id': 6010, 'value': 'Maharishi Dayanand University', 'label': 'Maharishi Dayanand University' },
    { 'id': 6011, 'value': 'Maharishi Mahesh Yogi Vedic Vishwavidyalaya', 'label': 'Maharishi Mahesh Yogi Vedic Vishwavidyalaya' },
    { 'id': 6012, 'value': 'Maharishi Markandeshwar University, Mullana', 'label': 'Maharishi Markandeshwar University, Mullana' },
    { 'id': 6013, 'value': 'Maharishi Markandeshwar University, Sadopur', 'label': 'Maharishi Markandeshwar University, Sadopur' },
    { 'id': 6014, 'value': 'Maharishi Markandeshwar University, Solan', 'label': 'Maharishi Markandeshwar University, Solan' },
    { 'id': 6015, 'value': 'Maharishi University', 'label': 'Maharishi University' },
    { 'id': 12955, 'value': 'Maharishi University of Management', 'label': 'Maharishi University of Management' },
    { 'id': 6016, 'value': 'Maharishi University of Management and Technology', 'label': 'Maharishi University of Management and Technology' },
    { 'id': 6017, 'value': 'Maharshi Dayanand Saraswati University', 'label': 'Maharshi Dayanand Saraswati University' },
    { 'id': 6018, 'value': 'Maharshi Panini Sanskrit Vishwavidyalaya', 'label': 'Maharshi Panini Sanskrit Vishwavidyalaya' },
    { 'id': 11418, 'value': 'Mahasarakham University', 'label': 'Mahasarakham University' },
    { 'id': 6019, 'value': 'Mahatma Gandhi Antarrashtriya Hindi Vishwavidyalaya', 'label': 'Mahatma Gandhi Antarrashtriya Hindi Vishwavidyalaya' },
    { 'id': 6020, 'value': 'Mahatma Gandhi Central University, Motihari', 'label': 'Mahatma Gandhi Central University, Motihari' },
    { 'id': 6021, 'value': 'Mahatma Gandhi Chitrakoot Gramoday Vishwavidyalaya', 'label': 'Mahatma Gandhi Chitrakoot Gramoday Vishwavidyalaya' },
    { 'id': 8672, 'value': 'Mahatma Gandhi Institute', 'label': 'Mahatma Gandhi Institute' },
    { 'id': 6022, 'value': 'Mahatma Gandhi Kashi Vidyapeeth', 'label': 'Mahatma Gandhi Kashi Vidyapeeth' },
    { 'id': 6023, 'value': 'Mahatma Gandhi University', 'label': 'Mahatma Gandhi University' },
    { 'id': 6024, 'value': 'Mahatma Gandhi University of Medical Sciences and Technology', 'label': 'Mahatma Gandhi University of Medical Sciences and Technology' },
    { 'id': 11001, 'value': 'Mahatma Gandhi University Rwanda', 'label': 'Mahatma Gandhi University Rwanda' },
    { 'id': 6025, 'value': 'Mahatma Gandhi University, Meghalaya', 'label': 'Mahatma Gandhi University, Meghalaya' },
    { 'id': 6026, 'value': 'Mahatma Gandhi University, Nalgonda', 'label': 'Mahatma Gandhi University, Nalgonda' },
    { 'id': 6027, 'value': 'Mahatma Jyoti Rao Phoole University', 'label': 'Mahatma Jyoti Rao Phoole University' },
    { 'id': 6028, 'value': 'Mahatma Phule Krishi Vidyapeeth', 'label': 'Mahatma Phule Krishi Vidyapeeth' },
    { 'id': 11419, 'value': 'Mahidol University', 'label': 'Mahidol University' },
    { 'id': 737, 'value': 'Maihan Institute of Higher Education', 'label': 'Maihan Institute of Higher Education' },
    { 'id': 10743, 'value': 'Maikop State Technological University', 'label': 'Maikop State Technological University' },
    { 'id': 12956, 'value': 'Maine College of Art', 'label': 'Maine College of Art' },
    { 'id': 12957, 'value': 'Maine Maritime Academy', 'label': 'Maine Maritime Academy' },
    { 'id': 738, 'value': 'Maiwand institute of Higher Education', 'label': 'Maiwand institute of Higher Education' },
    { 'id': 9629, 'value': 'Majan University College', 'label': 'Majan University College' },
    { 'id': 11037, 'value': 'Majmaah University', 'label': 'Majmaah University' },
    { 'id': 8290, 'value': 'Makassed University of Beirut', 'label': 'Makassed University of Beirut' },
    { 'id': 12184, 'value': 'Makerere University', 'label': 'Makerere University' },
    { 'id': 6029, 'value': 'Makhanlal Chaturvedi Rashtriya Patrakarita Vishwavidyalaya', 'label': 'Makhanlal Chaturvedi Rashtriya Patrakarita Vishwavidyalaya' },
    { 'id': 650, 'value': 'Maktoum Bin Hamdan Dental University College', 'label': 'Maktoum Bin Hamdan Dental University College' },
    { 'id': 11133, 'value': 'Mälardalens högskola', 'label': 'Mälardalens högskola' },
    { 'id': 6030, 'value': 'Malaviya National Institute of Technology, Jaipur', 'label': 'Malaviya National Institute of Technology, Jaipur' },
    { 'id': 8682, 'value': 'Malawi Adventist University', 'label': 'Malawi Adventist University' },
    { 'id': 8683, 'value': 'Malawi Assemblies of God University', 'label': 'Malawi Assemblies of God University' },
    { 'id': 8684, 'value': 'Malawi College of Accountancy', 'label': 'Malawi College of Accountancy' },
    { 'id': 8685, 'value': 'Malawi University of Science and Technology', 'label': 'Malawi University of Science and Technology' },
    { 'id': 6715, 'value': 'Malayer University', 'label': 'Malayer University' },
    { 'id': 9243, 'value': 'Malaysia University of Science and Technology', 'label': 'Malaysia University of Science and Technology' },
    { 'id': 6716, 'value': 'Malek-Ashtar University of Technology', 'label': 'Malek-Ashtar University of Technology' },
    { 'id': 11134, 'value': 'Malmö högskola', 'label': 'Malmö högskola' },
    { 'id': 12958, 'value': 'Malone University', 'label': 'Malone University' },
    { 'id': 8668, 'value': 'Malta College of Arts, Science and Technology', 'label': 'Malta College of Arts, Science and Technology' },
    { 'id': 11744, 'value': 'Maltepe Üniversitesi', 'label': 'Maltepe Üniversitesi' },
    { 'id': 6031, 'value': 'Malwanchal University', 'label': 'Malwanchal University' },
    { 'id': 9244, 'value': 'Management and Science University', 'label': 'Management and Science University' },
    { 'id': 7809, 'value': 'Management University of Africa', 'label': 'Management University of Africa' },
    { 'id': 11069, 'value': 'Managil University of Science and Technology', 'label': 'Managil University of Science and Technology' },
    { 'id': 11348, 'value': 'Manara University', 'label': 'Manara University' },
    { 'id': 1275, 'value': 'Manarat International University', 'label': 'Manarat International University' },
    { 'id': 6032, 'value': 'Manav Bharti University', 'label': 'Manav Bharti University' },
    { 'id': 6033, 'value': 'Manav Rachna International Institute of Research and Studies', 'label': 'Manav Rachna International Institute of Research and Studies' },
    { 'id': 6034, 'value': 'Manav Rachna University', 'label': 'Manav Rachna University' },
    { 'id': 4533, 'value': 'Manchester Metropolitan University', 'label': 'Manchester Metropolitan University' },
    { 'id': 12959, 'value': 'Manchester University', 'label': 'Manchester University' },
    { 'id': 8612, 'value': 'Mandakh University', 'label': 'Mandakh University' },
    { 'id': 8513, 'value': 'Mandalay Technological University', 'label': 'Mandalay Technological University' },
    { 'id': 8514, 'value': 'Mandalay University', 'label': 'Mandalay University' },
    { 'id': 6035, 'value': 'Mandsaur University', 'label': 'Mandsaur University' },
    { 'id': 6036, 'value': 'Mangalayatan University', 'label': 'Mangalayatan University' },
    { 'id': 6037, 'value': 'Mangalore University', 'label': 'Mangalore University' },
    { 'id': 14224, 'value': 'Mangosuthu University of Technology', 'label': 'Mangosuthu University of Technology' },
    { 'id': 12960, 'value': 'Manhattan Christian College', 'label': 'Manhattan Christian College' },
    { 'id': 12961, 'value': 'Manhattan College', 'label': 'Manhattan College' },
    { 'id': 515, 'value': 'Manhattan School of Music', 'label': 'Manhattan School of Music' },
    { 'id': 12962, 'value': 'Manhattanville College', 'label': 'Manhattanville College' },
    { 'id': 14309, 'value': 'Manicaland State University of Applied Sciences', 'label': 'Manicaland State University of Applied Sciences' },
    { 'id': 9911, 'value': 'Manila Central University', 'label': 'Manila Central University' },
    { 'id': 6038, 'value': 'Manipal Academy of Higher Education', 'label': 'Manipal Academy of Higher Education' },
    { 'id': 9245, 'value': 'Manipal International University', 'label': 'Manipal International University' },
    { 'id': 651, 'value': 'Manipal University, Dubai', 'label': 'Manipal University, Dubai' },
    { 'id': 6039, 'value': 'Manipur Technical University', 'label': 'Manipur Technical University' },
    { 'id': 6040, 'value': 'Manipur University', 'label': 'Manipur University' },
    { 'id': 6041, 'value': 'Manipur University of Culture', 'label': 'Manipur University of Culture' },
    { 'id': 11745, 'value': 'Manisa Celal Bayar Üniversitesi', 'label': 'Manisa Celal Bayar Üniversitesi' },
    { 'id': 6042, 'value': 'Manonmaniam Sundaranar University', 'label': 'Manonmaniam Sundaranar University' },
    { 'id': 14270, 'value': 'Mansfield University', 'label': 'Mansfield University' },
    { 'id': 12963, 'value': 'Mansfield University of Pennsylvania', 'label': 'Mansfield University of Pennsylvania' },
    { 'id': 4064, 'value': 'Mansoura University', 'label': 'Mansoura University' },
    { 'id': 9912, 'value': 'Manuel L. Quezon University', 'label': 'Manuel L. Quezon University' },
    { 'id': 9913, 'value': 'Manuel S. Enverga University Foundation', 'label': 'Manuel S. Enverga University Foundation' },
    { 'id': 9914, 'value': 'Mapúa University', 'label': 'Mapúa University' },
    { 'id': 12964, 'value': 'Maranatha Baptist University', 'label': 'Maranatha Baptist University' },
    { 'id': 4700, 'value': 'Maranatha University College', 'label': 'Maranatha University College' },
    { 'id': 11746, 'value': 'Mardin Artuklu Üniversitesi', 'label': 'Mardin Artuklu Üniversitesi' },
    { 'id': 10744, 'value': 'Mari State University', 'label': 'Mari State University' },
    { 'id': 12965, 'value': 'Maria College', 'label': 'Maria College' },
    { 'id': 12966, 'value': 'Marian University', 'label': 'Marian University' },
    { 'id': 12967, 'value': 'Marian University, Wisconsin', 'label': 'Marian University, Wisconsin' },
    { 'id': 9915, 'value': 'Mariano Marcos State University', 'label': 'Mariano Marcos State University' },
    { 'id': 12968, 'value': 'Marietta College', 'label': 'Marietta College' },
    { 'id': 8351, 'value': 'Marijampoles kolegija', 'label': 'Marijampoles kolegija' },
    { 'id': 9916, 'value': 'Marinduque State College', 'label': 'Marinduque State College' },
    { 'id': 5517, 'value': 'Marino Institute of Education', 'label': 'Marino Institute of Education' },
    { 'id': 12969, 'value': 'Marist College', 'label': 'Marist College' },
    { 'id': 10745, 'value': 'Maritime State University', 'label': 'Maritime State University' },
    { 'id': 12067, 'value': 'Mariupol State University', 'label': 'Mariupol State University' },
    { 'id': 12970, 'value': 'Marlboro College', 'label': 'Marlboro College' },
    { 'id': 11747, 'value': 'Marmara Üniversitesi', 'label': 'Marmara Üniversitesi' },
    { 'id': 9543, 'value': 'Marnix Academie', 'label': 'Marnix Academie' },
    { 'id': 14310, 'value': 'Marondera University of Agricultural Sciences and Technology', 'label': 'Marondera University of Agricultural Sciences and Technology' },
    { 'id': 12971, 'value': 'Marquette University', 'label': 'Marquette University' },
    { 'id': 12972, 'value': 'Mars Hill University', 'label': 'Mars Hill University' },
    { 'id': 12973, 'value': 'Marshall B. Ketchum University', 'label': 'Marshall B. Ketchum University' },
    { 'id': 12974, 'value': 'Marshall University', 'label': 'Marshall University' },
    { 'id': 4701, 'value': 'Marshalls University College', 'label': 'Marshalls University College' },
    { 'id': 6043, 'value': 'Martin Luther Christian University', 'label': 'Martin Luther Christian University' },
    { 'id': 12975, 'value': 'Martin Luther College', 'label': 'Martin Luther College' },
    { 'id': 12976, 'value': 'Martin Methodist College', 'label': 'Martin Methodist College' },
    { 'id': 12977, 'value': 'Martin University', 'label': 'Martin University' },
    { 'id': 3695, 'value': 'Martin-Luther-Universität Halle-Wittenberg', 'label': 'Martin-Luther-Universität Halle-Wittenberg' },
    { 'id': 6044, 'value': 'Marwadi University', 'label': 'Marwadi University' },
    { 'id': 12978, 'value': 'Mary Baldwin University', 'label': 'Mary Baldwin University' },
    { 'id': 739, 'value': 'Maryam University', 'label': 'Maryam University' },
    { 'id': 12979, 'value': 'Marygrove College', 'label': 'Marygrove College' },
    { 'id': 12980, 'value': 'Maryland Institute College of Art', 'label': 'Maryland Institute College of Art' },
    { 'id': 12981, 'value': 'Maryland University of Integrative Health', 'label': 'Maryland University of Integrative Health' },
    { 'id': 12982, 'value': 'Marymount California University', 'label': 'Marymount California University' },
    { 'id': 12983, 'value': 'Marymount Manhattan College', 'label': 'Marymount Manhattan College' },
    { 'id': 12984, 'value': 'Marymount University', 'label': 'Marymount University' },
    { 'id': 12985, 'value': 'Maryville College', 'label': 'Maryville College' },
    { 'id': 12986, 'value': 'Maryville University', 'label': 'Maryville University' },
    { 'id': 12987, 'value': 'Marywood University', 'label': 'Marywood University' },
    { 'id': 3406, 'value': 'Masarykova univerzita', 'label': 'Masarykova univerzita' },
    { 'id': 7810, 'value': 'Maseno University', 'label': 'Maseno University' },
    { 'id': 740, 'value': 'Mashal University', 'label': 'Mashal University' },
    { 'id': 6717, 'value': 'Mashhad University of Medical Sciences', 'label': 'Mashhad University of Medical Sciences' },
    { 'id': 11070, 'value': 'Mashreq University', 'label': 'Mashreq University' },
    { 'id': 7811, 'value': 'Masinde Muliro University of Science and Technology', 'label': 'Masinde Muliro University of Science and Technology' },
    { 'id': 12988, 'value': 'Massachusetts College of Art and Design', 'label': 'Massachusetts College of Art and Design' },
    { 'id': 12989, 'value': 'Massachusetts College of Liberal Arts', 'label': 'Massachusetts College of Liberal Arts' },
    { 'id': 12990, 'value': 'Massachusetts Institute of Technology', 'label': 'Massachusetts Institute of Technology' },
    { 'id': 12991, 'value': 'Massachusetts Maritime Academy', 'label': 'Massachusetts Maritime Academy' },
    { 'id': 12992, 'value': 'Massachusetts School of Law', 'label': 'Massachusetts School of Law' },
    { 'id': 4098, 'value': 'Massawa College of Marine Science and Technology', 'label': 'Massawa College of Marine Science and Technology' },
    { 'id': 9602, 'value': 'Massey University', 'label': 'Massey University' },
    { 'id': 8291, 'value': 'Matn University College of Technology', 'label': 'Matn University College of Technology' },
    { 'id': 4065, 'value': 'Matrouh University', 'label': 'Matrouh University' },
    { 'id': 6045, 'value': 'MATS University', 'label': 'MATS University' },
    { 'id': 7381, 'value': 'Matsumoto Dental University', 'label': 'Matsumoto Dental University' },
    { 'id': 7382, 'value': 'Matsumoto University', 'label': 'Matsumoto University' },
    { 'id': 7383, 'value': 'Matsuyama Shinonome College', 'label': 'Matsuyama Shinonome College' },
    { 'id': 7384, 'value': 'Matsuyama University', 'label': 'Matsuyama University' },
    { 'id': 8515, 'value': 'Maubin University', 'label': 'Maubin University' },
    { 'id': 6046, 'value': 'Maulana Abul Kalam Azad University of Technology, West Bengal', 'label': 'Maulana Abul Kalam Azad University of Technology, West Bengal' },
    { 'id': 6047, 'value': 'Maulana Azad National Institute of Technology', 'label': 'Maulana Azad National Institute of Technology' },
    { 'id': 6048, 'value': 'Maulana Azad National Urdu University', 'label': 'Maulana Azad National Urdu University' },
    { 'id': 6049, 'value': 'Maulana Azad University, Jodhpur', 'label': 'Maulana Azad University, Jodhpur' },
    { 'id': 6050, 'value': 'Maulana Mazharul Haque Arabic and Persian University', 'label': 'Maulana Mazharul Haque Arabic and Persian University' },
    { 'id': 8673, 'value': 'Mauritius Institute of Education', 'label': 'Mauritius Institute of Education' },
    { 'id': 8516, 'value': 'Mawlamyine University', 'label': 'Mawlamyine University' },
    { 'id': 1276, 'value': 'Mawlana Bhashani Science and Technology University', 'label': 'Mawlana Bhashani Science and Technology University' },
    { 'id': 741, 'value': 'Mawlana University', 'label': 'Mawlana University' },
    { 'id': 5544, 'value': 'Max Stern Academic College of Emek Yezreel', 'label': 'Max Stern Academic College of Emek Yezreel' },
    { 'id': 14205, 'value': 'May 22 College of Applied Medical Sciences', 'label': 'May 22 College of Applied Medical Sciences' },
    { 'id': 5518, 'value': 'Maynooth University', 'label': 'Maynooth University' },
    { 'id': 12993, 'value': 'Mayo Medical School', 'label': 'Mayo Medical School' },
    { 'id': 12994, 'value': 'Mayville State University', 'label': 'Mayville State University' },
    { 'id': 6718, 'value': 'Mazandaran University of Medical Sciences', 'label': 'Mazandaran University of Medical Sciences' },
    { 'id': 6719, 'value': 'Mazandaran University of Science and Technology', 'label': 'Mazandaran University of Science and Technology' },
    { 'id': 9630, 'value': 'Mazoon College', 'label': 'Mazoon College' },
    { 'id': 406, 'value': 'MBA global Asia (Hong Kong, New York e Londra)', 'label': 'MBA global Asia (Hong Kong, New York e Londra)' },
    { 'id': 12185, 'value': 'Mbarara University of Science and Technology', 'label': 'Mbarara University of Science and Technology' },
    { 'id': 11954, 'value': 'Mbeya University of Science and Technology', 'label': 'Mbeya University of Science and Technology' },
    { 'id': 12995, 'value': 'McDaniel College', 'label': 'McDaniel College' },
    { 'id': 604, 'value': 'McGill University', 'label': 'McGill University' },
    { 'id': 1036, 'value': 'MCI Management Center Innsbruck', 'label': 'MCI Management Center Innsbruck' },
    { 'id': 12996, 'value': 'McKendree University', 'label': 'McKendree University' },
    { 'id': 1879, 'value': 'McMaster University', 'label': 'McMaster University' },
    { 'id': 12997, 'value': 'McMurry University', 'label': 'McMurry University' },
    { 'id': 12998, 'value': 'McNeese State University', 'label': 'McNeese State University' },
    { 'id': 12999, 'value': 'McPherson College', 'label': 'McPherson College' },
    { 'id': 9396, 'value': 'Mcpherson University', 'label': 'Mcpherson University' },
    { 'id': 13000, 'value': 'MCPHS University', 'label': 'MCPHS University' },
    { 'id': 7911, 'value': 'Mean Chey University', 'label': 'Mean Chey University' },
    { 'id': 13001, 'value': 'Medaille College', 'label': 'Medaille College' },
    { 'id': 13002, 'value': 'Medgar Evers College', 'label': 'Medgar Evers College' },
    { 'id': 6051, 'value': 'Medi-Caps University', 'label': 'Medi-Caps University' },
    { 'id': 3696, 'value': 'Mediadesign Hochschule', 'label': 'Mediadesign Hochschule' },
    { 'id': 172, 'value': 'Mediazione Linguistica Perugia', 'label': 'Mediazione Linguistica Perugia' },
    { 'id': 13003, 'value': 'Medical College of Wisconsin', 'label': 'Medical College of Wisconsin' },
    { 'id': 3697, 'value': 'Medical School Hamburg', 'label': 'Medical School Hamburg' },
    { 'id': 1434, 'value': 'Medical University - Pleven', 'label': 'Medical University - Pleven' },
    { 'id': 1435, 'value': 'Medical University - Sofia', 'label': 'Medical University - Sofia' },
    { 'id': 6720, 'value': 'Medical University of Ilam', 'label': 'Medical University of Ilam' },
    { 'id': 335, 'value': 'Medical University of Plovdiv', 'label': 'Medical University of Plovdiv' },
    { 'id': 13004, 'value': 'Medical University of South Carolina', 'label': 'Medical University of South Carolina' },
    { 'id': 1436, 'value': 'Medical University-Varna', 'label': 'Medical University-Varna' },
    { 'id': 14372, 'value': 'MEDICINA "DUNAREA DE JOS" ENNA - FONDO PROSERPINA', 'label': 'MEDICINA "DUNAREA DE JOS" ENNA - FONDO PROSERPINA' },
    { 'id': 14373, 'value': 'MEDICINA "DUNAREA DE JOS" ENNA - FONDO PROSERPINA', 'label': 'MEDICINA "DUNAREA DE JOS" ENNA - FONDO PROSERPINA' },
    { 'id': 4824, 'value': 'Medimursko veleucilište u Cakovcu', 'label': 'Medimursko veleucilište u Cakovcu' },
    { 'id': 3372, 'value': 'Mediterranean Institute of Management', 'label': 'Mediterranean Institute of Management' },
    { 'id': 11591, 'value': 'Mediterranean School of Business', 'label': 'Mediterranean School of Business' },
    { 'id': 3698, 'value': 'Medizinische Hochschule Brandenburg Theodor Fontane', 'label': 'Medizinische Hochschule Brandenburg Theodor Fontane' },
    { 'id': 3699, 'value': 'Medizinische Hochschule Hannover', 'label': 'Medizinische Hochschule Hannover' },
    { 'id': 1037, 'value': 'Medizinische Universität Graz', 'label': 'Medizinische Universität Graz' },
    { 'id': 1038, 'value': 'Medizinische Universität Innsbruck', 'label': 'Medizinische Universität Innsbruck' },
    { 'id': 1039, 'value': 'Medizinische Universität Wien', 'label': 'Medizinische Universität Wien' },
    { 'id': 11172, 'value': 'Mednarodna fakulteta za družbene in poslovne študije', 'label': 'Mednarodna fakulteta za družbene in poslovne študije' },
    { 'id': 11173, 'value': 'Mednarodna podiplomska šola Jožefa Stefana', 'label': 'Mednarodna podiplomska šola Jožefa Stefana' },
    { 'id': 6052, 'value': 'Meenakshi Academy of Higher Education and Research', 'label': 'Meenakshi Academy of Higher Education and Research' },
    { 'id': 11748, 'value': 'Mef Üniversitesi', 'label': 'Mef Üniversitesi' },
    { 'id': 1796, 'value': 'Mega Size College', 'label': 'Mega Size College' },
    { 'id': 13005, 'value': 'Meharry Medical College', 'label': 'Meharry Medical College' },
    { 'id': 10124, 'value': 'Mehran University of Engineering and Technology', 'label': 'Mehran University of Engineering and Technology' },
    { 'id': 11855, 'value': 'Meiho University', 'label': 'Meiho University' },
    { 'id': 7385, 'value': 'Meiji Gakuin University', 'label': 'Meiji Gakuin University' },
    { 'id': 7386, 'value': 'Meiji Pharmaceutical University', 'label': 'Meiji Pharmaceutical University' },
    { 'id': 7387, 'value': 'Meiji University', 'label': 'Meiji University' },
    { 'id': 7388, 'value': 'Meiji University of Integrative Medicine', 'label': 'Meiji University of Integrative Medicine' },
    { 'id': 7389, 'value': 'Meijo University', 'label': 'Meijo University' },
    { 'id': 7390, 'value': 'Meikai University', 'label': 'Meikai University' },
    { 'id': 8517, 'value': 'Meiktila University', 'label': 'Meiktila University' },
    { 'id': 7391, 'value': 'Meio University', 'label': 'Meio University' },
    { 'id': 7392, 'value': 'Meisei University', 'label': 'Meisei University' },
    { 'id': 7393, 'value': 'Mejiro University', 'label': 'Mejiro University' },
    { 'id': 4193, 'value': 'Mekelle Institute of Technology', 'label': 'Mekelle Institute of Technology' },
    { 'id': 4194, 'value': 'Mekelle University', 'label': 'Mekelle University' },
    { 'id': 12068, 'value': 'Melitopol State Pedagogical University', 'label': 'Melitopol State Pedagogical University' },
    { 'id': 1880, 'value': 'Memorial University of Newfoundland', 'label': 'Memorial University of Newfoundland' },
    { 'id': 13006, 'value': 'Memphis College of Art', 'label': 'Memphis College of Art' },
    { 'id': 652, 'value': 'MENA College of Management', 'label': 'MENA College of Management' },
    { 'id': 10746, 'value': 'Mendeleev University of Chemical Technology of Russia', 'label': 'Mendeleev University of Chemical Technology of Russia' },
    { 'id': 3407, 'value': 'Mendelova univerzita v Brne', 'label': 'Mendelova univerzita v Brne' },
    { 'id': 13007, 'value': 'Menlo College', 'label': 'Menlo College' },
    { 'id': 4066, 'value': 'Menoufia University', 'label': 'Menoufia University' },
    { 'id': 13008, 'value': 'Mercer University', 'label': 'Mercer University' },
    { 'id': 13009, 'value': 'Mercy College', 'label': 'Mercy College' },
    { 'id': 13010, 'value': 'Mercy College of Health Sciences', 'label': 'Mercy College of Health Sciences' },
    { 'id': 13011, 'value': 'Mercy College of Ohio', 'label': 'Mercy College of Ohio' },
    { 'id': 13012, 'value': 'Mercyhurst University', 'label': 'Mercyhurst University' },
    { 'id': 13013, 'value': 'Meredith College', 'label': 'Meredith College' },
    { 'id': 11071, 'value': 'Merowe University of Technology', 'label': 'Merowe University of Technology' },
    { 'id': 13014, 'value': 'Merrimack College', 'label': 'Merrimack College' },
    { 'id': 11749, 'value': 'Mersin Üniversitesi', 'label': 'Mersin Üniversitesi' },
    { 'id': 7812, 'value': 'Meru University of Science and Technology', 'label': 'Meru University of Science and Technology' },
    { 'id': 3700, 'value': 'Merz Akademie', 'label': 'Merz Akademie' },
    { 'id': 3373, 'value': 'Mesoyios College', 'label': 'Mesoyios College' },
    { 'id': 13015, 'value': 'Messiah College', 'label': 'Messiah College' },
    { 'id': 251, 'value': 'MetFilm School', 'label': 'MetFilm School' },
    { 'id': 13016, 'value': 'Methodist College', 'label': 'Methodist College' },
    { 'id': 13017, 'value': 'Methodist University', 'label': 'Methodist University' },
    { 'id': 4702, 'value': 'Methodist University College', 'label': 'Methodist University College' },
    { 'id': 4228, 'value': 'Metropolia Ammattikorkeakoulu', 'label': 'Metropolia Ammattikorkeakoulu' },
    { 'id': 13018, 'value': 'Metropolitan College of New York', 'label': 'Metropolitan College of New York' },
    { 'id': 12186, 'value': 'Metropolitan International University', 'label': 'Metropolitan International University' },
    { 'id': 13019, 'value': 'Metropolitan State University', 'label': 'Metropolitan State University' },
    { 'id': 13020, 'value': 'Metropolitan State University of Denver', 'label': 'Metropolitan State University of Denver' },
    { 'id': 1277, 'value': 'Metropolitan University', 'label': 'Metropolitan University' },
    { 'id': 3408, 'value': 'Metropolitní univerzita v Praze', 'label': 'Metropolitní univerzita v Praze' },
    { 'id': 4195, 'value': 'Mettu University', 'label': 'Mettu University' },
    { 'id': 6053, 'value': 'Mewar University', 'label': 'Mewar University' },
    { 'id': 6721, 'value': 'Meybod University', 'label': 'Meybod University' },
    { 'id': 9917, 'value': 'Meycauayan College', 'label': 'Meycauayan College' },
    { 'id': 13021, 'value': 'MGH Institute of Health Professions', 'label': 'MGH Institute of Health Professions' },
    { 'id': 6054, 'value': 'MGM Institute of Health Sciences', 'label': 'MGM Institute of Health Sciences' },
    { 'id': 13022, 'value': 'Miami Dade College', 'label': 'Miami Dade College' },
    { 'id': 13023, 'value': 'Miami University', 'label': 'Miami University' },
    { 'id': 2583, 'value': 'Mianyang Normal University', 'label': 'Mianyang Normal University' },
    { 'id': 9397, 'value': 'Michael and Cecilia Ibru University', 'label': 'Michael and Cecilia Ibru University' },
    { 'id': 9398, 'value': 'Michael Okpara University of Agriculture', 'label': 'Michael Okpara University of Agriculture' },
    { 'id': 13024, 'value': 'Michigan School of Psychology', 'label': 'Michigan School of Psychology' },
    { 'id': 13025, 'value': 'Michigan State University', 'label': 'Michigan State University' },
    { 'id': 13026, 'value': 'Michigan Technological University', 'label': 'Michigan Technological University' },
    { 'id': 10747, 'value': 'Michurinsk State Agrarian University', 'label': 'Michurinsk State Agrarian University' },
    { 'id': 7028, 'value': 'Mico University College', 'label': 'Mico University College' },
    { 'id': 9595, 'value': 'Mid Western University', 'label': 'Mid Western University' },
    { 'id': 13027, 'value': 'Mid-America Christian University', 'label': 'Mid-America Christian University' },
    { 'id': 13029, 'value': 'Mid-Atlantic Christian University', 'label': 'Mid-Atlantic Christian University' },
    { 'id': 13028, 'value': 'MidAmerica Nazarene University', 'label': 'MidAmerica Nazarene University' },
    { 'id': 9631, 'value': 'Middle East College', 'label': 'Middle East College' },
    { 'id': 8292, 'value': 'Middle East University', 'label': 'Middle East University' },
    { 'id': 7052, 'value': 'Middle East University, Jordan', 'label': 'Middle East University, Jordan' },
    { 'id': 13030, 'value': 'Middle Georgia State University', 'label': 'Middle Georgia State University' },
    { 'id': 6460, 'value': 'Middle Technical University', 'label': 'Middle Technical University' },
    { 'id': 13031, 'value': 'Middle Tennessee School of Anesthesia', 'label': 'Middle Tennessee School of Anesthesia' },
    { 'id': 13032, 'value': 'Middle Tennessee State University', 'label': 'Middle Tennessee State University' },
    { 'id': 13033, 'value': 'Middlebury College', 'label': 'Middlebury College' },
    { 'id': 4534, 'value': 'Middlesex University', 'label': 'Middlesex University' },
    { 'id': 653, 'value': 'Middlesex University Dubai', 'label': 'Middlesex University Dubai' },
    { 'id': 8669, 'value': 'Middlesex University Malta', 'label': 'Middlesex University Malta' },
    { 'id': 13034, 'value': 'Midland College', 'label': 'Midland College' },
    { 'id': 13035, 'value': 'Midland University', 'label': 'Midland University' },
    { 'id': 14311, 'value': 'Midlands State University', 'label': 'Midlands State University' },
    { 'id': 13036, 'value': 'Midstate College', 'label': 'Midstate College' },
    { 'id': 13037, 'value': 'Midway University', 'label': 'Midway University' },
    { 'id': 13038, 'value': 'Midwestern State University', 'label': 'Midwestern State University' },
    { 'id': 13039, 'value': 'Midwestern University', 'label': 'Midwestern University' },
    { 'id': 7394, 'value': 'Mie Prefectural College of Nursing', 'label': 'Mie Prefectural College of Nursing' },
    { 'id': 7395, 'value': 'Mie University', 'label': 'Mie University' },
    { 'id': 10252, 'value': 'Miedzynarodowa Wyzsza Szkola Logistyki i Transportu we Wroclawiu', 'label': 'Miedzynarodowa Wyzsza Szkola Logistyki i Transportu we Wroclawiu' },
    { 'id': 14426, 'value': 'Milano Fashion Institute', 'label': 'Milano Fashion Institute' },
    { 'id': 84, 'value': 'Milano SAE Institute', 'label': 'Milano SAE Institute' },
    { 'id': 13040, 'value': 'Miles College', 'label': 'Miles College' },
    { 'id': 742, 'value': 'Mili Institute of Higher Education', 'label': 'Mili Institute of Higher Education' },
    { 'id': 8686, 'value': 'Millennium University', 'label': 'Millennium University' },
    { 'id': 13041, 'value': 'Millersville University of Pennsylvania', 'label': 'Millersville University of Pennsylvania' },
    { 'id': 1145, 'value': 'Milli Aviasiya Akademiyasi', 'label': 'Milli Aviasiya Akademiyasi' },
    { 'id': 13042, 'value': 'Milligan College', 'label': 'Milligan College' },
    { 'id': 13043, 'value': 'Millikin University', 'label': 'Millikin University' },
    { 'id': 13997, 'value': 'Milliy Raqs va Xoreografiya Oliy Maktabi', 'label': 'Milliy Raqs va Xoreografiya Oliy Maktabi' },
    { 'id': 13044, 'value': 'Mills College', 'label': 'Mills College' },
    { 'id': 13045, 'value': 'Millsaps College', 'label': 'Millsaps College' },
    { 'id': 4911, 'value': 'Milton Friedman Egyetem', 'label': 'Milton Friedman Egyetem' },
    { 'id': 11220, 'value': 'Milton Margai College of Education and Technology', 'label': 'Milton Margai College of Education and Technology' },
    { 'id': 13046, 'value': 'Milwaukee Institute of Art & Design', 'label': 'Milwaukee Institute of Art & Design' },
    { 'id': 13047, 'value': 'Milwaukee School of Engineering', 'label': 'Milwaukee School of Engineering' },
    { 'id': 11750, 'value': 'Mimar Sinan Güzel Sanatlar Üniversitesi', 'label': 'Mimar Sinan Güzel Sanatlar Üniversitesi' },
    { 'id': 7396, 'value': 'Mimasaka University', 'label': 'Mimasaka University' },
    { 'id': 7397, 'value': 'Minami Kyushu University', 'label': 'Minami Kyushu University' },
    { 'id': 9918, 'value': 'Mindanao State University', 'label': 'Mindanao State University' },
    { 'id': 9919, 'value': 'Mindanao State University - Iligan Institute of Technology', 'label': 'Mindanao State University - Iligan Institute of Technology' },
    { 'id': 4397, 'value': 'MINES ParisTech', 'label': 'MINES ParisTech' },
    { 'id': 11856, 'value': 'Ming Chi University of Technology', 'label': 'Ming Chi University of Technology' },
    { 'id': 11857, 'value': 'Ming Chuan University', 'label': 'Ming Chuan University' },
    { 'id': 11858, 'value': 'MingDao University', 'label': 'MingDao University' },
    { 'id': 1146, 'value': 'Mingeçevir Dövlet Universiteti', 'label': 'Mingeçevir Dövlet Universiteti' },
    { 'id': 11859, 'value': 'Minghsin University of Science and Technology', 'label': 'Minghsin University of Science and Technology' },
    { 'id': 10125, 'value': 'Minhaj University', 'label': 'Minhaj University' },
    { 'id': 4067, 'value': 'Minia University', 'label': 'Minia University' },
    { 'id': 11506, 'value': 'Mining-metallurgy Institute of Tajikistan', 'label': 'Mining-metallurgy Institute of Tajikistan' },
    { 'id': 2584, 'value': 'Minjiang University', 'label': 'Minjiang University' },
    { 'id': 2585, 'value': 'Minnan Normal University', 'label': 'Minnan Normal University' },
    { 'id': 2586, 'value': 'Minnan University of Science and Technology', 'label': 'Minnan University of Science and Technology' },
    { 'id': 13048, 'value': 'Minneapolis College of Art and Design', 'label': 'Minneapolis College of Art and Design' },
    { 'id': 13049, 'value': 'Minnesota State University Moorhead', 'label': 'Minnesota State University Moorhead' },
    { 'id': 13050, 'value': 'Minnesota State University, Mankato', 'label': 'Minnesota State University, Mankato' },
    { 'id': 7398, 'value': 'Minobusan University', 'label': 'Minobusan University' },
    { 'id': 13051, 'value': 'Minot State University', 'label': 'Minot State University' },
    { 'id': 1833, 'value': 'Minsk Innovation University', 'label': 'Minsk Innovation University' },
    { 'id': 1834, 'value': 'Minsk State Linguistic University', 'label': 'Minsk State Linguistic University' },
    { 'id': 2587, 'value': 'Minzu University of China', 'label': 'Minzu University of China' },
    { 'id': 9920, 'value': 'Miriam College', 'label': 'Miriam College' },
    { 'id': 10126, 'value': 'Mirpur University of Science and Technology', 'label': 'Mirpur University of Science and Technology' },
    { 'id': 9921, 'value': 'Misamis Oriental State College of Agriculture and Technology', 'label': 'Misamis Oriental State College of Agriculture and Technology' },
    { 'id': 9922, 'value': 'Misamis University', 'label': 'Misamis University' },
    { 'id': 13052, 'value': 'Misericordia University', 'label': 'Misericordia University' },
    { 'id': 4912, 'value': 'Miskolci Egyetem', 'label': 'Miskolci Egyetem' },
    { 'id': 4068, 'value': 'Misr International University', 'label': 'Misr International University' },
    { 'id': 4069, 'value': 'Misr University for Science and Technology', 'label': 'Misr University for Science and Technology' },
    { 'id': 13053, 'value': 'Mississippi College', 'label': 'Mississippi College' },
    { 'id': 13054, 'value': 'Mississippi State University', 'label': 'Mississippi State University' },
    { 'id': 13055, 'value': 'Mississippi University for Women', 'label': 'Mississippi University for Women' },
    { 'id': 13056, 'value': 'Mississippi Valley State University', 'label': 'Mississippi Valley State University' },
    { 'id': 13057, 'value': 'Missouri Baptist University', 'label': 'Missouri Baptist University' },
    { 'id': 13058, 'value': 'Missouri Southern State University', 'label': 'Missouri Southern State University' },
    { 'id': 340, 'value': 'Missouri State University', 'label': 'Missouri State University' },
    { 'id': 13059, 'value': 'Missouri University of Science and Technology', 'label': 'Missouri University of Science and Technology' },
    { 'id': 13060, 'value': 'Missouri Valley College', 'label': 'Missouri Valley College' },
    { 'id': 13061, 'value': 'Missouri Western State University', 'label': 'Missouri Western State University' },
    { 'id': 8401, 'value': 'Misurata University', 'label': 'Misurata University' },
    { 'id': 6055, 'value': 'MIT Art Design and Technology University', 'label': 'MIT Art Design and Technology University' },
    { 'id': 503, 'value': 'MIT Management - Sloan School', 'label': 'MIT Management - Sloan School' },
    { 'id': 11240, 'value': 'MIT University Dakar', 'label': 'MIT University Dakar' },
    { 'id': 8479, 'value': 'MIT Univerzitet', 'label': 'MIT Univerzitet' },
    { 'id': 13062, 'value': 'Mitchell College', 'label': 'Mitchell College' },
    { 'id': 13063, 'value': 'Mitchell Hamline School of Law', 'label': 'Mitchell Hamline School of Law' },
    { 'id': 11135, 'value': 'Mittuniversitetet', 'label': 'Mittuniversitetet' },
    { 'id': 12187, 'value': 'Mityana Agro-Vet Institute', 'label': 'Mityana Agro-Vet Institute' },
    { 'id': 7399, 'value': 'Miyagi Gakuin Women\'s University', 'label': 'Miyagi Gakuin Women\'s University' },
    { 'id': 7400, 'value': 'Miyagi University', 'label': 'Miyagi University' },
    { 'id': 7401, 'value': 'Miyagi University of Education', 'label': 'Miyagi University of Education' },
    { 'id': 7402, 'value': 'Miyazaki International College', 'label': 'Miyazaki International College' },
    { 'id': 7403, 'value': 'Miyazaki Municipal University', 'label': 'Miyazaki Municipal University' },
    { 'id': 7404, 'value': 'Miyazaki Prefectural Nursing University', 'label': 'Miyazaki Prefectural Nursing University' },
    { 'id': 7405, 'value': 'Miyazaki Sangyo-keiei University', 'label': 'Miyazaki Sangyo-keiei University' },
    { 'id': 4196, 'value': 'Mizan Tepi University', 'label': 'Mizan Tepi University' },
    { 'id': 6056, 'value': 'Mizoram University', 'label': 'Mizoram University' },
    { 'id': 3374, 'value': 'MKC City College', 'label': 'MKC City College' },
    { 'id': 825, 'value': 'Mkitar Gosh Armenian-Russian International University', 'label': 'Mkitar Gosh Armenian-Russian International University' },
    { 'id': 11174, 'value': 'MLC Fakulteta za management in pravo Ljubljana', 'label': 'MLC Fakulteta za management in pravo Ljubljana' },
    { 'id': 9632, 'value': 'Modern College of Business and Science', 'label': 'Modern College of Business and Science' },
    { 'id': 4070, 'value': 'Modern Sciences and Arts University', 'label': 'Modern Sciences and Arts University' },
    { 'id': 10392, 'value': 'Modern University College', 'label': 'Modern University College' },
    { 'id': 8293, 'value': 'Modern University for Business and Science', 'label': 'Modern University for Business and Science' },
    { 'id': 4071, 'value': 'Modern University for Technology and Information', 'label': 'Modern University for Technology and Information' },
    { 'id': 10748, 'value': 'Modern University for the Humanities', 'label': 'Modern University for the Humanities' },
    { 'id': 9399, 'value': 'Modibbo Adama University of Technology', 'label': 'Modibbo Adama University of Technology' },
    { 'id': 654, 'value': 'MODUL University Dubai', 'label': 'MODUL University Dubai' },
    { 'id': 1040, 'value': 'MODUL University Vienna', 'label': 'MODUL University Vienna' },
    { 'id': 6057, 'value': 'Mody University of Science and Technology', 'label': 'Mody University of Science and Technology' },
    { 'id': 6722, 'value': 'Mofid University', 'label': 'Mofid University' },
    { 'id': 1835, 'value': 'Mogilev State University', 'label': 'Mogilev State University' },
    { 'id': 1836, 'value': 'Mogilev State University of Food Technologies', 'label': 'Mogilev State University of Food Technologies' },
    { 'id': 6058, 'value': 'Mohammad Ali Jauhar University', 'label': 'Mohammad Ali Jauhar University' },
    { 'id': 10127, 'value': 'Mohammad Ali Jinnah University', 'label': 'Mohammad Ali Jinnah University' },
    { 'id': 655, 'value': 'Mohammed Bin Rashid School of Government', 'label': 'Mohammed Bin Rashid School of Government' },
    { 'id': 656, 'value': 'Mohammed Bin Rashid University of Medicine and Health Sciences', 'label': 'Mohammed Bin Rashid University of Medicine and Health Sciences' },
    { 'id': 657, 'value': 'Mohammed V University Abu Dhabi', 'label': 'Mohammed V University Abu Dhabi' },
    { 'id': 6059, 'value': 'Mohanlal Sukhadia University', 'label': 'Mohanlal Sukhadia University' },
    { 'id': 10128, 'value': 'Mohi-ud-Din Islamic University', 'label': 'Mohi-ud-Din Islamic University' },
    { 'id': 4913, 'value': 'Moholy-Nagy Muvészeti Egyetem', 'label': 'Moholy-Nagy Muvészeti Egyetem' },
    { 'id': 7813, 'value': 'Moi University', 'label': 'Moi University' },
    { 'id': 8094, 'value': 'Mokpo Catholic University', 'label': 'Mokpo Catholic University' },
    { 'id': 8095, 'value': 'Mokpo National Maritime University', 'label': 'Mokpo National Maritime University' },
    { 'id': 8096, 'value': 'Mokpo National University', 'label': 'Mokpo National University' },
    { 'id': 8097, 'value': 'Mokwon University', 'label': 'Mokwon University' },
    { 'id': 13064, 'value': 'Molloy College', 'label': 'Molloy College' },
    { 'id': 7406, 'value': 'Momoyama Gakuin University', 'label': 'Momoyama Gakuin University' },
    { 'id': 8613, 'value': 'Mon-Altius Institute', 'label': 'Mon-Altius Institute' },
    { 'id': 6060, 'value': 'Monad University', 'label': 'Monad University' },
    { 'id': 529, 'value': 'Monash University', 'label': 'Monash University' },
    { 'id': 4101, 'value': 'Mondragon Univertsitatea', 'label': 'Mondragon Univertsitatea' },
    { 'id': 8614, 'value': 'Mongol Institute', 'label': 'Mongol Institute' },
    { 'id': 8615, 'value': 'Mongolia International University', 'label': 'Mongolia International University' },
    { 'id': 8616, 'value': 'Mongolian Labour and Social Relations Institute', 'label': 'Mongolian Labour and Social Relations Institute' },
    { 'id': 8617, 'value': 'Mongolian National Institute of Physical Education', 'label': 'Mongolian National Institute of Physical Education' },
    { 'id': 8618, 'value': 'Mongolian National University', 'label': 'Mongolian National University' },
    { 'id': 8619, 'value': 'Mongolian National University of Arts and Culture', 'label': 'Mongolian National University of Arts and Culture' },
    { 'id': 8620, 'value': 'Mongolian National University of Medical Sciences', 'label': 'Mongolian National University of Medical Sciences' },
    { 'id': 8621, 'value': 'Mongolian State Conservatory', 'label': 'Mongolian State Conservatory' },
    { 'id': 8622, 'value': 'Mongolian State University of Education', 'label': 'Mongolian State University of Education' },
    { 'id': 8623, 'value': 'Mongolian University of Life Sciences', 'label': 'Mongolian University of Life Sciences' },
    { 'id': 8624, 'value': 'Mongolian University of Science and Technology', 'label': 'Mongolian University of Science and Technology' },
    { 'id': 13065, 'value': 'Monmouth College', 'label': 'Monmouth College' },
    { 'id': 13066, 'value': 'Monmouth University', 'label': 'Monmouth University' },
    { 'id': 13067, 'value': 'Monroe College', 'label': 'Monroe College' },
    { 'id': 13068, 'value': 'Montana State University', 'label': 'Montana State University' },
    { 'id': 13069, 'value': 'Montana State University Billings', 'label': 'Montana State University Billings' },
    { 'id': 13070, 'value': 'Montana State University-Northern', 'label': 'Montana State University-Northern' },
    { 'id': 13071, 'value': 'Montana Tech', 'label': 'Montana Tech' },
    { 'id': 1041, 'value': 'Montanuniversität Leoben', 'label': 'Montanuniversität Leoben' },
    { 'id': 13072, 'value': 'Montclair State University', 'label': 'Montclair State University' },
    { 'id': 387, 'value': 'Montpellier Business School', 'label': 'Montpellier Business School' },
    { 'id': 4398, 'value': 'Montpellier SupAgro', 'label': 'Montpellier SupAgro' },
    { 'id': 13073, 'value': 'Montreat College', 'label': 'Montreat College' },
    { 'id': 13074, 'value': 'Montserrat College of Art', 'label': 'Montserrat College of Art' },
    { 'id': 8518, 'value': 'Monywa University', 'label': 'Monywa University' },
    { 'id': 14525, 'value': 'Moodart Fashion School', 'label': 'Moodart Fashion School' },
    { 'id': 13075, 'value': 'Moody Bible Institute', 'label': 'Moody Bible Institute' },
    { 'id': 13076, 'value': 'Moore College of Art and Design', 'label': 'Moore College of Art and Design' },
    { 'id': 743, 'value': 'Moraa Female University', 'label': 'Moraa Female University' },
    { 'id': 13077, 'value': 'Moravian College', 'label': 'Moravian College' },
    { 'id': 3409, 'value': 'Moravská vysoká škola Olomouc', 'label': 'Moravská vysoká škola Olomouc' },
    { 'id': 13078, 'value': 'Morehead State University', 'label': 'Morehead State University' },
    { 'id': 13079, 'value': 'Morehouse College', 'label': 'Morehouse College' },
    { 'id': 13080, 'value': 'Morehouse School of Medicine', 'label': 'Morehouse School of Medicine' },
    { 'id': 13081, 'value': 'Morgan State University', 'label': 'Morgan State University' },
    { 'id': 7407, 'value': 'Morinomiya University of Medical Sciences', 'label': 'Morinomiya University of Medical Sciences' },
    { 'id': 7408, 'value': 'Morioka College', 'label': 'Morioka College' },
    { 'id': 13082, 'value': 'Morningside College', 'label': 'Morningside College' },
    { 'id': 13083, 'value': 'Morris College', 'label': 'Morris College' },
    { 'id': 13084, 'value': 'Morrisville State College', 'label': 'Morrisville State College' },
    { 'id': 14271, 'value': 'Mosa University', 'label': 'Mosa University' },
    { 'id': 10749, 'value': 'Moscow Automobile and Road Construction State Technical University', 'label': 'Moscow Automobile and Road Construction State Technical University' },
    { 'id': 10750, 'value': 'Moscow Aviation Institute', 'label': 'Moscow Aviation Institute' },
    { 'id': 10751, 'value': 'Moscow City Teachers\' Training University', 'label': 'Moscow City Teachers\' Training University' },
    { 'id': 10752, 'value': 'Moscow Institute of Physics and Technology', 'label': 'Moscow Institute of Physics and Technology' },
    { 'id': 10753, 'value': 'Moscow Institute of Public Administration and Law', 'label': 'Moscow Institute of Public Administration and Law' },
    { 'id': 10754, 'value': 'Moscow International Higher Business School', 'label': 'Moscow International Higher Business School' },
    { 'id': 10755, 'value': 'Moscow International University', 'label': 'Moscow International University' },
    { 'id': 10756, 'value': 'Moscow Medical Academy', 'label': 'Moscow Medical Academy' },
    { 'id': 10757, 'value': 'Moscow Metropolitan Governance University', 'label': 'Moscow Metropolitan Governance University' },
    { 'id': 10758, 'value': 'Moscow Polytech', 'label': 'Moscow Polytech' },
    { 'id': 10759, 'value': 'Moscow State Academy of Physical Culture', 'label': 'Moscow State Academy of Physical Culture' },
    { 'id': 10760, 'value': 'Moscow State Academy of Water Transport', 'label': 'Moscow State Academy of Water Transport' },
    { 'id': 10761, 'value': 'Moscow State Industrial Art University', 'label': 'Moscow State Industrial Art University' },
    { 'id': 10762, 'value': 'Moscow State Institute of Culture', 'label': 'Moscow State Institute of Culture' },
    { 'id': 10763, 'value': 'Moscow State Institute of International Relations', 'label': 'Moscow State Institute of International Relations' },
    { 'id': 10764, 'value': 'Moscow State Linguistic University', 'label': 'Moscow State Linguistic University' },
    { 'id': 10765, 'value': 'Moscow State Pedagogical University', 'label': 'Moscow State Pedagogical University' },
    { 'id': 10766, 'value': 'Moscow State Regional University', 'label': 'Moscow State Regional University' },
    { 'id': 10767, 'value': 'Moscow State Technical University of Civil Aviation', 'label': 'Moscow State Technical University of Civil Aviation' },
    { 'id': 10768, 'value': 'Moscow State Technological University "Stankin"', 'label': 'Moscow State Technological University "Stankin"' },
    { 'id': 10769, 'value': 'Moscow State University', 'label': 'Moscow State University' },
    { 'id': 11507, 'value': 'Moscow State University Lomonosov in Dushanbe', 'label': 'Moscow State University Lomonosov in Dushanbe' },
    { 'id': 10770, 'value': 'Moscow State University of Civil Engineering', 'label': 'Moscow State University of Civil Engineering' },
    { 'id': 10771, 'value': 'Moscow State University of Environmental Engineering', 'label': 'Moscow State University of Environmental Engineering' },
    { 'id': 10772, 'value': 'Moscow State University of Food Production', 'label': 'Moscow State University of Food Production' },
    { 'id': 10773, 'value': 'Moscow State University of Geodesy and Cartography', 'label': 'Moscow State University of Geodesy and Cartography' },
    { 'id': 10774, 'value': 'Moscow State University of Printing Arts', 'label': 'Moscow State University of Printing Arts' },
    { 'id': 10775, 'value': 'Moscow State University of Psychology and Education', 'label': 'Moscow State University of Psychology and Education' },
    { 'id': 10776, 'value': 'Moscow State University of Railway Engineering', 'label': 'Moscow State University of Railway Engineering' },
    { 'id': 10777, 'value': 'Moscow State University of Technology and Management', 'label': 'Moscow State University of Technology and Management' },
    { 'id': 10778, 'value': 'Moscow Tchaikovsky Conservatory', 'label': 'Moscow Tchaikovsky Conservatory' },
    { 'id': 10779, 'value': 'Moscow Technical University - MIREA', 'label': 'Moscow Technical University - MIREA' },
    { 'id': 10780, 'value': 'Moscow Technical University of Communications and Informatics', 'label': 'Moscow Technical University of Communications and Informatics' },
    { 'id': 10781, 'value': 'Moscow University for the Humanities', 'label': 'Moscow University for the Humanities' },
    { 'id': 10782, 'value': 'Moscow University Touro', 'label': 'Moscow University Touro' },
    { 'id': 11955, 'value': 'Moshi Co-operative University', 'label': 'Moshi Co-operative University' },
    { 'id': 9400, 'value': 'Moshood Abiola University of Science and Technology, Abeokuta', 'label': 'Moshood Abiola University of Science and Technology, Abeokuta' },
    { 'id': 6061, 'value': 'Mother Teresa Women\'s University', 'label': 'Mother Teresa Women\'s University' },
    { 'id': 6062, 'value': 'Motherhood University', 'label': 'Motherhood University' },
    { 'id': 6063, 'value': 'Motilal Nehru National Institute of Technology Allahabad', 'label': 'Motilal Nehru National Institute of Technology Allahabad' },
    { 'id': 1881, 'value': 'Mount Allison University', 'label': 'Mount Allison University' },
    { 'id': 13085, 'value': 'Mount Aloysius College', 'label': 'Mount Aloysius College' },
    { 'id': 13086, 'value': 'Mount Carmel College of Nursing', 'label': 'Mount Carmel College of Nursing' },
    { 'id': 13087, 'value': 'Mount Holyoke College', 'label': 'Mount Holyoke College' },
    { 'id': 7814, 'value': 'Mount Kenya University', 'label': 'Mount Kenya University' },
    { 'id': 11002, 'value': 'Mount Kenya University Rwanda', 'label': 'Mount Kenya University Rwanda' },
    { 'id': 13088, 'value': 'Mount Marty College', 'label': 'Mount Marty College' },
    { 'id': 13089, 'value': 'Mount Mary University', 'label': 'Mount Mary University' },
    { 'id': 13090, 'value': 'Mount Mercy University', 'label': 'Mount Mercy University' },
    { 'id': 11956, 'value': 'Mount Meru University', 'label': 'Mount Meru University' },
    { 'id': 1882, 'value': 'Mount Royal University', 'label': 'Mount Royal University' },
    { 'id': 13091, 'value': 'Mount Saint Mary College', 'label': 'Mount Saint Mary College' },
    { 'id': 13092, 'value': 'Mount Saint Mary\'s University', 'label': 'Mount Saint Mary\'s University' },
    { 'id': 1883, 'value': 'Mount Saint Vincent University', 'label': 'Mount Saint Vincent University' },
    { 'id': 13093, 'value': 'Mount St. Joseph University', 'label': 'Mount St. Joseph University' },
    { 'id': 13094, 'value': 'Mount St. Mary\'s University', 'label': 'Mount St. Mary\'s University' },
    { 'id': 13095, 'value': 'Mount Vernon Nazarene University', 'label': 'Mount Vernon Nazarene University' },
    { 'id': 9401, 'value': 'Mountain Top University', 'label': 'Mountain Top University' },
    { 'id': 9923, 'value': 'Mountain View College', 'label': 'Mountain View College' },
    { 'id': 12188, 'value': 'Mountains of the Moon University', 'label': 'Mountains of the Moon University' },
    { 'id': 4703, 'value': 'Mountcrest University College', 'label': 'Mountcrest University College' },
    { 'id': 2588, 'value': 'Moutai Institute', 'label': 'Moutai Institute' },
    { 'id': 1837, 'value': 'Mozyr State Pedagogical University', 'label': 'Mozyr State Pedagogical University' },
    { 'id': 14648, 'value': 'MPDA Aps', 'label': 'MPDA Aps' },
    { 'id': 3701, 'value': 'MSB Medical School Berlin', 'label': 'MSB Medical School Berlin' },
    { 'id': 1437, 'value': 'MT&M College', 'label': 'MT&M College' },
    { 'id': 11420, 'value': 'Muban Chombueng Rajabhat University', 'label': 'Muban Chombueng Rajabhat University' },
    { 'id': 2589, 'value': 'Mudanjiang Medical University', 'label': 'Mudanjiang Medical University' },
    { 'id': 2590, 'value': 'Mudanjiang Normal University', 'label': 'Mudanjiang Normal University' },
    { 'id': 11751, 'value': 'Mugla Sitki Koçman Üniversitesi', 'label': 'Mugla Sitki Koçman Üniversitesi' },
    { 'id': 10129, 'value': 'Muhammad Nawaz Shareef University of Agriculture, Multan', 'label': 'Muhammad Nawaz Shareef University of Agriculture, Multan' },
    { 'id': 10130, 'value': 'Muhammad Nawaz Sharif University of Engineering and Technology', 'label': 'Muhammad Nawaz Sharif University of Engineering and Technology' },
    { 'id': 11957, 'value': 'Muhimbili University of Health and Allied Sciences', 'label': 'Muhimbili University of Health and Allied Sciences' },
    { 'id': 13096, 'value': 'Muhlenberg College', 'label': 'Muhlenberg College' },
    { 'id': 12069, 'value': 'Mukachevo State University', 'label': 'Mukachevo State University' },
    { 'id': 7409, 'value': 'Mukogawa Women\'s University', 'label': 'Mukogawa Women\'s University' },
    { 'id': 14272, 'value': 'Mukuba University', 'label': 'Mukuba University' },
    { 'id': 9246, 'value': 'Multimedia University', 'label': 'Multimedia University' },
    { 'id': 7815, 'value': 'Multimedia University of Kenya', 'label': 'Multimedia University of Kenya' },
    { 'id': 13097, 'value': 'Multnomah University', 'label': 'Multnomah University' },
    { 'id': 14273, 'value': 'Mulungushi University', 'label': 'Mulungushi University' },
    { 'id': 12189, 'value': 'Muni University', 'label': 'Muni University' },
    { 'id': 3702, 'value': 'Munich Business School', 'label': 'Munich Business School' },
    { 'id': 11752, 'value': 'Munzur Üniversitesi', 'label': 'Munzur Üniversitesi' },
    { 'id': 7816, 'value': 'Murang\'a University of Technology', 'label': 'Murang\'a University of Technology' },
    { 'id': 1085, 'value': 'Murdoch University', 'label': 'Murdoch University' },
    { 'id': 658, 'value': 'Murdoch University Dubai', 'label': 'Murdoch University Dubai' },
    { 'id': 10783, 'value': 'Murmansk Arctic State University', 'label': 'Murmansk Arctic State University' },
    { 'id': 10784, 'value': 'Murmansk State Technical University', 'label': 'Murmansk State Technical University' },
    { 'id': 7410, 'value': 'Muroran Institute of Technology', 'label': 'Muroran Institute of Technology' },
    { 'id': 13098, 'value': 'Murray State University', 'label': 'Murray State University' },
    { 'id': 11753, 'value': 'Mus Alparslan Üniversitesi', 'label': 'Mus Alparslan Üniversitesi' },
    { 'id': 7411, 'value': 'Musashi University', 'label': 'Musashi University' },
    { 'id': 7412, 'value': 'Musashino Academia Musicae', 'label': 'Musashino Academia Musicae' },
    { 'id': 7413, 'value': 'Musashino Art University', 'label': 'Musashino Art University' },
    { 'id': 7414, 'value': 'Musashino Gakuin University', 'label': 'Musashino Gakuin University' },
    { 'id': 7415, 'value': 'Musashino University', 'label': 'Musashino University' },
    { 'id': 9633, 'value': 'Muscat College', 'label': 'Muscat College' },
    { 'id': 330, 'value': 'Musicians Institute', 'label': 'Musicians Institute' },
    { 'id': 1042, 'value': 'Musik und Kunst Privatuniversität der Stadt Wien', 'label': 'Musik und Kunst Privatuniversität der Stadt Wien' },
    { 'id': 3703, 'value': 'Musikhochschule Lübeck', 'label': 'Musikhochschule Lübeck' },
    { 'id': 13099, 'value': 'Muskingum University', 'label': 'Muskingum University' },
    { 'id': 11958, 'value': 'Muslim University of Morogoro', 'label': 'Muslim University of Morogoro' },
    { 'id': 10131, 'value': 'Muslim Youth University', 'label': 'Muslim Youth University' },
    { 'id': 7053, 'value': 'Mutah university', 'label': 'Mutah university' },
    { 'id': 12190, 'value': 'Muteesa I Royal University', 'label': 'Muteesa I Royal University' },
    { 'id': 3704, 'value': 'Muthesius Kunsthochschule', 'label': 'Muthesius Kunsthochschule' },
    { 'id': 6064, 'value': 'MVN University', 'label': 'MVN University' },
    { 'id': 11959, 'value': 'Mwalimu Julius K. Nyerere University of Agriculture and Technology', 'label': 'Mwalimu Julius K. Nyerere University of Agriculture and Technology' },
    { 'id': 11960, 'value': 'Mwenge Catholic University', 'label': 'Mwenge Catholic University' },
    { 'id': 8519, 'value': 'Myanmar Aerospace Engineering University', 'label': 'Myanmar Aerospace Engineering University' },
    { 'id': 8520, 'value': 'Myanmar Maritime University', 'label': 'Myanmar Maritime University' },
    { 'id': 8521, 'value': 'Myeik University', 'label': 'Myeik University' },
    { 'id': 8522, 'value': 'Myitkyina University', 'label': 'Myitkyina University' },
    { 'id': 12070, 'value': 'Mykolayiv National Agrarian University', 'label': 'Mykolayiv National Agrarian University' },
    { 'id': 12071, 'value': 'Mykolayiv National University', 'label': 'Mykolayiv National University' },
    { 'id': 8352, 'value': 'Mykolo Romerio Universitetas', 'label': 'Mykolo Romerio Universitetas' },
    { 'id': 8098, 'value': 'Myongji University', 'label': 'Myongji University' },
    { 'id': 11961, 'value': 'Mzumbe University', 'label': 'Mzumbe University' },
    { 'id': 8687, 'value': 'Mzuzu University', 'label': 'Mzuzu University' },
    { 'id': 1278, 'value': 'N.P.I. University of Bangladesh', 'label': 'N.P.I. University of Bangladesh' },
    { 'id': 10785, 'value': 'N.R.U. Moscow Power Engineering Institute', 'label': 'N.R.U. Moscow Power Engineering Institute' },
    { 'id': 11508, 'value': 'N.R.U. Moscow Power Engineering Institute in Dushanbe', 'label': 'N.R.U. Moscow Power Engineering Institute in Dushanbe' },
    { 'id': 75, 'value': 'NABA, Nuova Accademia di Belle Arti Milano', 'label': 'NABA, Nuova Accademia di Belle Arti Milano' },
    { 'id': 14321, 'value': 'NABA, Nuova Accademia di Belle Arti Roma', 'label': 'NABA, Nuova Accademia di Belle Arti Roma' },
    { 'id': 10253, 'value': 'Nadbuzanska Szkola Wyzsza w Siemiatyczach', 'label': 'Nadbuzanska Szkola Wyzsza w Siemiatyczach' },
    { 'id': 7416, 'value': 'Nagahama Institute of Bio-Science and Technology', 'label': 'Nagahama Institute of Bio-Science and Technology' },
    { 'id': 6065, 'value': 'Nagaland University', 'label': 'Nagaland University' },
    { 'id': 7417, 'value': 'Nagano College of Nursing', 'label': 'Nagano College of Nursing' },
    { 'id': 7418, 'value': 'Nagano University', 'label': 'Nagano University' },
    { 'id': 7419, 'value': 'Nagaoka Institute of Design', 'label': 'Nagaoka Institute of Design' },
    { 'id': 7420, 'value': 'Nagaoka University', 'label': 'Nagaoka University' },
    { 'id': 7421, 'value': 'Nagaoka University of Technology', 'label': 'Nagaoka University of Technology' },
    { 'id': 7422, 'value': 'Nagasaki Institute of Applied Science', 'label': 'Nagasaki Institute of Applied Science' },
    { 'id': 7423, 'value': 'Nagasaki International University', 'label': 'Nagasaki International University' },
    { 'id': 7424, 'value': 'Nagasaki Junshin Catholic University', 'label': 'Nagasaki Junshin Catholic University' },
    { 'id': 7425, 'value': 'Nagasaki University', 'label': 'Nagasaki University' },
    { 'id': 7426, 'value': 'Nagasaki University of Foreign Studies', 'label': 'Nagasaki University of Foreign Studies' },
    { 'id': 7427, 'value': 'Nagasaki Wesleyan University', 'label': 'Nagasaki Wesleyan University' },
    { 'id': 7428, 'value': 'Nagoya Bunri University', 'label': 'Nagoya Bunri University' },
    { 'id': 7429, 'value': 'Nagoya City University', 'label': 'Nagoya City University' },
    { 'id': 7430, 'value': 'Nagoya College of Music', 'label': 'Nagoya College of Music' },
    { 'id': 7431, 'value': 'Nagoya Gakuin University', 'label': 'Nagoya Gakuin University' },
    { 'id': 7432, 'value': 'Nagoya Institute of Technology', 'label': 'Nagoya Institute of Technology' },
    { 'id': 7433, 'value': 'Nagoya Keizai University', 'label': 'Nagoya Keizai University' },
    { 'id': 7434, 'value': 'Nagoya Sangyo University', 'label': 'Nagoya Sangyo University' },
    { 'id': 7435, 'value': 'Nagoya University', 'label': 'Nagoya University' },
    { 'id': 7436, 'value': 'Nagoya University of Arts', 'label': 'Nagoya University of Arts' },
    { 'id': 7437, 'value': 'Nagoya University of Arts and Sciences', 'label': 'Nagoya University of Arts and Sciences' },
    { 'id': 7438, 'value': 'Nagoya University of Commerce and Business Administration', 'label': 'Nagoya University of Commerce and Business Administration' },
    { 'id': 7439, 'value': 'Nagoya University of Foreign Studies', 'label': 'Nagoya University of Foreign Studies' },
    { 'id': 7440, 'value': 'Nagoya Women\'s University', 'label': 'Nagoya Women\'s University' },
    { 'id': 7441, 'value': 'Nagoya Zokei University of Art and Design', 'label': 'Nagoya Zokei University of Art and Design' },
    { 'id': 6723, 'value': 'Nahavand University', 'label': 'Nahavand University' },
    { 'id': 4072, 'value': 'Nahda University', 'label': 'Nahda University' },
    { 'id': 11038, 'value': 'Najran University', 'label': 'Najran University' },
    { 'id': 7442, 'value': 'Nakamura Gakuen University', 'label': 'Nakamura Gakuen University' },
    { 'id': 11421, 'value': 'Nakhon Pathom Rajabhat University', 'label': 'Nakhon Pathom Rajabhat University' },
    { 'id': 11422, 'value': 'Nakhon Phanom University', 'label': 'Nakhon Phanom University' },
    { 'id': 11423, 'value': 'Nakhon Ratchasima Rajabhat University', 'label': 'Nakhon Ratchasima Rajabhat University' },
    { 'id': 11424, 'value': 'Nakhon Sawan Rajabhat University', 'label': 'Nakhon Sawan Rajabhat University' },
    { 'id': 11425, 'value': 'Nakhon Si Thammarat Rajabhat University', 'label': 'Nakhon Si Thammarat Rajabhat University' },
    { 'id': 6066, 'value': 'Nalanda University', 'label': 'Nalanda University' },
    { 'id': 6067, 'value': 'NALSAR University of Law', 'label': 'NALSAR University of Law' },
    { 'id': 13998, 'value': 'Namangan Davlat Universiteti', 'label': 'Namangan Davlat Universiteti' },
    { 'id': 13999, 'value': 'Namangan Muhandislik-Texnologiya Instituti', 'label': 'Namangan Muhandislik-Texnologiya Instituti' },
    { 'id': 8099, 'value': 'Nambu University', 'label': 'Nambu University' },
    { 'id': 9294, 'value': 'Namibia University of Science and Technology', 'label': 'Namibia University of Science and Technology' },
    { 'id': 8100, 'value': 'Namseoul University', 'label': 'Namseoul University' },
    { 'id': 11860, 'value': 'Nan Jeon University of Science and Technology', 'label': 'Nan Jeon University of Science and Technology' },
    { 'id': 11861, 'value': 'Nan Kai University of Technology', 'label': 'Nan Kai University of Technology' },
    { 'id': 6068, 'value': 'Nanaji Deshmukh Veterinary Science University', 'label': 'Nanaji Deshmukh Veterinary Science University' },
    { 'id': 2591, 'value': 'Nanchang HangKong University', 'label': 'Nanchang HangKong University' },
    { 'id': 2592, 'value': 'Nanchang Institute of Science and Technology', 'label': 'Nanchang Institute of Science and Technology' },
    { 'id': 2593, 'value': 'Nanchang Institute of Technology', 'label': 'Nanchang Institute of Technology' },
    { 'id': 2594, 'value': 'Nanchang Normal University', 'label': 'Nanchang Normal University' },
    { 'id': 2595, 'value': 'Nanchang University', 'label': 'Nanchang University' },
    { 'id': 744, 'value': 'Nangarhar University', 'label': 'Nangarhar University' },
    { 'id': 11862, 'value': 'Nanhua University', 'label': 'Nanhua University' },
    { 'id': 2596, 'value': 'Nanjing Agricultural University', 'label': 'Nanjing Agricultural University' },
    { 'id': 2597, 'value': 'Nanjing Audit University', 'label': 'Nanjing Audit University' },
    { 'id': 2598, 'value': 'Nanjing Forestry University', 'label': 'Nanjing Forestry University' },
    { 'id': 2599, 'value': 'Nanjing Institute of Technology', 'label': 'Nanjing Institute of Technology' },
    { 'id': 2600, 'value': 'Nanjing Medical University', 'label': 'Nanjing Medical University' },
    { 'id': 2601, 'value': 'Nanjing Normal University', 'label': 'Nanjing Normal University' },
    { 'id': 2602, 'value': 'Nanjing Sport Institute', 'label': 'Nanjing Sport Institute' },
    { 'id': 2603, 'value': 'Nanjing Tech University', 'label': 'Nanjing Tech University' },
    { 'id': 2604, 'value': 'Nanjing University', 'label': 'Nanjing University' },
    { 'id': 2605, 'value': 'Nanjing University of Aeronautics and Astronautics', 'label': 'Nanjing University of Aeronautics and Astronautics' },
    { 'id': 2606, 'value': 'Nanjing University of Chinese Medicine', 'label': 'Nanjing University of Chinese Medicine' },
    { 'id': 2607, 'value': 'Nanjing University of Finance and Economics', 'label': 'Nanjing University of Finance and Economics' },
    { 'id': 2608, 'value': 'Nanjing University of Information Science and Technology', 'label': 'Nanjing University of Information Science and Technology' },
    { 'id': 2609, 'value': 'Nanjing University of Posts and Telecommunications', 'label': 'Nanjing University of Posts and Telecommunications' },
    { 'id': 2610, 'value': 'Nanjing University of Science and Technology', 'label': 'Nanjing University of Science and Technology' },
    { 'id': 2611, 'value': 'Nanjing University of the Arts', 'label': 'Nanjing University of the Arts' },
    { 'id': 2612, 'value': 'Nanjing Xiaozhuang University', 'label': 'Nanjing Xiaozhuang University' },
    { 'id': 2613, 'value': 'Nankai University', 'label': 'Nankai University' },
    { 'id': 2614, 'value': 'Nanning University', 'label': 'Nanning University' },
    { 'id': 2615, 'value': 'NanTong Institute of Technology', 'label': 'NanTong Institute of Technology' },
    { 'id': 2616, 'value': 'Nantong University', 'label': 'Nantong University' },
    { 'id': 11863, 'value': 'Nanya Institute of Technology', 'label': 'Nanya Institute of Technology' },
    { 'id': 11148, 'value': 'Nanyang Academy of Fine Arts', 'label': 'Nanyang Academy of Fine Arts' },
    { 'id': 2617, 'value': 'Nanyang Institute of Technology', 'label': 'Nanyang Institute of Technology' },
    { 'id': 2618, 'value': 'Nanyang Normal University', 'label': 'Nanyang Normal University' },
    { 'id': 11149, 'value': 'Nanyang Technological University', 'label': 'Nanyang Technological University' },
    { 'id': 7443, 'value': 'Nanzan University', 'label': 'Nanzan University' },
    { 'id': 7444, 'value': 'Nara Institute of Science and Technology', 'label': 'Nara Institute of Science and Technology' },
    { 'id': 7445, 'value': 'Nara Medical University', 'label': 'Nara Medical University' },
    { 'id': 7446, 'value': 'Nara Prefectual University', 'label': 'Nara Prefectual University' },
    { 'id': 7447, 'value': 'Nara University', 'label': 'Nara University' },
    { 'id': 7448, 'value': 'Nara University of Education', 'label': 'Nara University of Education' },
    { 'id': 7449, 'value': 'Nara Women\'s University', 'label': 'Nara Women\'s University' },
    { 'id': 7450, 'value': 'Naragakuen University', 'label': 'Naragakuen University' },
    { 'id': 6069, 'value': 'Narendra Dev University of Agriculture and Technology', 'label': 'Narendra Dev University of Agriculture and Technology' },
    { 'id': 11426, 'value': 'Naresuan University', 'label': 'Naresuan University' },
    { 'id': 13100, 'value': 'Naropa University', 'label': 'Naropa University' },
    { 'id': 6070, 'value': 'Narsee Monjee Institute of Management and Higher Studies', 'label': 'Narsee Monjee Institute of Management and Higher Studies' },
    { 'id': 7451, 'value': 'Naruto University of Education', 'label': 'Naruto University of Education' },
    { 'id': 8238, 'value': 'Narxoz University', 'label': 'Narxoz University' },
    { 'id': 7875, 'value': 'Naryn State University', 'label': 'Naryn State University' },
    { 'id': 9402, 'value': 'Nasarawa State University', 'label': 'Nasarawa State University' },
    { 'id': 11427, 'value': 'Nation University', 'label': 'Nation University' },
    { 'id': 1438, 'value': 'National Academy of Arts', 'label': 'National Academy of Arts' },
    { 'id': 7876, 'value': 'National Academy of Arts of the Kyrgyz Republic', 'label': 'National Academy of Arts of the Kyrgyz Republic' },
    { 'id': 826, 'value': 'National Academy of Fine Arts', 'label': 'National Academy of Fine Arts' },
    { 'id': 1439, 'value': 'National Academy of Music "Pancho Vladigerov"', 'label': 'National Academy of Music "Pancho Vladigerov"' },
    { 'id': 12072, 'value': 'National Aerospace University', 'label': 'National Aerospace University' },
    { 'id': 13101, 'value': 'National American University', 'label': 'National American University' },
    { 'id': 4746, 'value': 'National and Kapodistrian University of Athens', 'label': 'National and Kapodistrian University of Athens' },
    { 'id': 12073, 'value': 'National Aviation University', 'label': 'National Aviation University' },
    { 'id': 11864, 'value': 'National Central University', 'label': 'National Central University' },
    { 'id': 11865, 'value': 'National Changhua University of Education', 'label': 'National Changhua University of Education' },
    { 'id': 11866, 'value': 'National Cheng Kung University', 'label': 'National Cheng Kung University' },
    { 'id': 11867, 'value': 'National Chengchi University', 'label': 'National Chengchi University' },
    { 'id': 11868, 'value': 'National Chi Nan University', 'label': 'National Chi Nan University' },
    { 'id': 11869, 'value': 'National Chiao Tung University', 'label': 'National Chiao Tung University' },
    { 'id': 11870, 'value': 'National Chiayi University', 'label': 'National Chiayi University' },
    { 'id': 11871, 'value': 'National Chin-Yi University of Technology', 'label': 'National Chin-Yi University of Technology' },
    { 'id': 11872, 'value': 'National Chung Cheng University', 'label': 'National Chung Cheng University' },
    { 'id': 11873, 'value': 'National Chung Hsing University', 'label': 'National Chung Hsing University' },
    { 'id': 5519, 'value': 'National College of Art and Design', 'label': 'National College of Art and Design' },
    { 'id': 10132, 'value': 'National College of Arts', 'label': 'National College of Arts' },
    { 'id': 10133, 'value': 'National College of Business Administration and Economics', 'label': 'National College of Business Administration and Economics' },
    { 'id': 9924, 'value': 'National College of Business and Arts', 'label': 'National College of Business and Arts' },
    { 'id': 5520, 'value': 'National College of Ireland', 'label': 'National College of Ireland' },
    { 'id': 6071, 'value': 'National Dairy Research Institute', 'label': 'National Dairy Research Institute' },
    { 'id': 10134, 'value': 'National Defence University', 'label': 'National Defence University' },
    { 'id': 9925, 'value': 'National Defense College of the Philippines', 'label': 'National Defense College of the Philippines' },
    { 'id': 13102, 'value': 'National Defense University', 'label': 'National Defense University' },
    { 'id': 11874, 'value': 'National Dong Hwa University', 'label': 'National Dong Hwa University' },
    { 'id': 14150, 'value': 'National Economics University', 'label': 'National Economics University' },
    { 'id': 12074, 'value': 'National Forestry University of Ukraine', 'label': 'National Forestry University of Ukraine' },
    { 'id': 11875, 'value': 'National Formosa University', 'label': 'National Formosa University' },
    { 'id': 7453, 'value': 'National Graduate Institute for Policy Studies', 'label': 'National Graduate Institute for Policy Studies' },
    { 'id': 11876, 'value': 'National Ilan University', 'label': 'National Ilan University' },
    { 'id': 7912, 'value': 'National Institute of Business', 'label': 'National Institute of Business' },
    { 'id': 6072, 'value': 'National Institute of Design', 'label': 'National Institute of Design' },
    { 'id': 11428, 'value': 'National Institute of Development Administration', 'label': 'National Institute of Development Administration' },
    { 'id': 7913, 'value': 'National Institute of Education', 'label': 'National Institute of Education' },
    { 'id': 6073, 'value': 'National Institute of Educational Planning and Administration', 'label': 'National Institute of Educational Planning and Administration' },
    { 'id': 6074, 'value': 'National Institute of Fashion Technology', 'label': 'National Institute of Fashion Technology' },
    { 'id': 7454, 'value': 'National Institute of Fitness and Sports in Kanoya', 'label': 'National Institute of Fitness and Sports in Kanoya' },
    { 'id': 6075, 'value': 'National Institute of Food Technology Entrepreneurship and Management', 'label': 'National Institute of Food Technology Entrepreneurship and Management' },
    { 'id': 6076, 'value': 'National Institute of Mental Health and Neuro Sciences', 'label': 'National Institute of Mental Health and Neuro Sciences' },
    { 'id': 6077, 'value': 'National Institute of Pharmaceutical Education and Research, Ahmedabad', 'label': 'National Institute of Pharmaceutical Education and Research, Ahmedabad' },
    { 'id': 6078, 'value': 'National Institute of Pharmaceutical Education and Research, Guwahati', 'label': 'National Institute of Pharmaceutical Education and Research, Guwahati' },
    { 'id': 6079, 'value': 'National Institute of Pharmaceutical Education and Research, Hajipur', 'label': 'National Institute of Pharmaceutical Education and Research, Hajipur' },
    { 'id': 6080, 'value': 'National Institute of Pharmaceutical Education and Research, Hyderabad', 'label': 'National Institute of Pharmaceutical Education and Research, Hyderabad' },
    { 'id': 6081, 'value': 'National Institute of Pharmaceutical Education and Research, Kolkata', 'label': 'National Institute of Pharmaceutical Education and Research, Kolkata' },
    { 'id': 6082, 'value': 'National Institute of Pharmaceutical Education and Research, Mohali', 'label': 'National Institute of Pharmaceutical Education and Research, Mohali' },
    { 'id': 6083, 'value': 'National Institute of Pharmaceutical Education and Research, Rae Bareli', 'label': 'National Institute of Pharmaceutical Education and Research, Rae Bareli' },
    { 'id': 11536, 'value': 'National Institute of Sports and Tourism of Turkmenistan', 'label': 'National Institute of Sports and Tourism of Turkmenistan' },
    { 'id': 6084, 'value': 'National Institute of Technology, Agartala', 'label': 'National Institute of Technology, Agartala' },
    { 'id': 6085, 'value': 'National Institute of Technology, Arunachal Pradesh', 'label': 'National Institute of Technology, Arunachal Pradesh' },
    { 'id': 6086, 'value': 'National Institute of Technology, Calicut', 'label': 'National Institute of Technology, Calicut' },
    { 'id': 6087, 'value': 'National Institute of Technology, Delhi', 'label': 'National Institute of Technology, Delhi' },
    { 'id': 6088, 'value': 'National Institute of Technology, Durgapur', 'label': 'National Institute of Technology, Durgapur' },
    { 'id': 6089, 'value': 'National Institute of Technology, Goa', 'label': 'National Institute of Technology, Goa' },
    { 'id': 6090, 'value': 'National Institute of Technology, Hamirpur', 'label': 'National Institute of Technology, Hamirpur' },
    { 'id': 6091, 'value': 'National Institute of Technology, Jamshedpur', 'label': 'National Institute of Technology, Jamshedpur' },
    { 'id': 6092, 'value': 'National Institute of Technology, Karnataka', 'label': 'National Institute of Technology, Karnataka' },
    { 'id': 6093, 'value': 'National Institute of Technology, Kurukshetra', 'label': 'National Institute of Technology, Kurukshetra' },
    { 'id': 6094, 'value': 'National Institute of Technology, Manipur', 'label': 'National Institute of Technology, Manipur' },
    { 'id': 6095, 'value': 'National Institute of Technology, Meghalaya', 'label': 'National Institute of Technology, Meghalaya' },
    { 'id': 6096, 'value': 'National Institute of Technology, Mizoram', 'label': 'National Institute of Technology, Mizoram' },
    { 'id': 6097, 'value': 'National Institute of Technology, Nagaland', 'label': 'National Institute of Technology, Nagaland' },
    { 'id': 6098, 'value': 'National Institute of Technology, Patna', 'label': 'National Institute of Technology, Patna' },
    { 'id': 6099, 'value': 'National Institute of Technology, Puducherry', 'label': 'National Institute of Technology, Puducherry' },
    { 'id': 6100, 'value': 'National Institute of Technology, Raipur', 'label': 'National Institute of Technology, Raipur' },
    { 'id': 6101, 'value': 'National Institute of Technology, Rourkela', 'label': 'National Institute of Technology, Rourkela' },
    { 'id': 6102, 'value': 'National Institute of Technology, Sikkim', 'label': 'National Institute of Technology, Sikkim' },
    { 'id': 6103, 'value': 'National Institute of Technology, Silchar', 'label': 'National Institute of Technology, Silchar' },
    { 'id': 6104, 'value': 'National Institute of Technology, Srinagar', 'label': 'National Institute of Technology, Srinagar' },
    { 'id': 6105, 'value': 'National Institute of Technology, Tiruchirappalli', 'label': 'National Institute of Technology, Tiruchirappalli' },
    { 'id': 6106, 'value': 'National Institute of Technology, Uttarakhand', 'label': 'National Institute of Technology, Uttarakhand' },
    { 'id': 6107, 'value': 'National Institute of Technology, Warangal', 'label': 'National Institute of Technology, Warangal' },
    { 'id': 11877, 'value': 'National Kaohsiung Normal University', 'label': 'National Kaohsiung Normal University' },
    { 'id': 11878, 'value': 'National Kaohsiung University of Applied Sciences', 'label': 'National Kaohsiung University of Applied Sciences' },
    { 'id': 11879, 'value': 'National Kaohsiung University of Hospitality and Tourism', 'label': 'National Kaohsiung University of Hospitality and Tourism' },
    { 'id': 11880, 'value': 'National Kaohsiung University of Science and Technology', 'label': 'National Kaohsiung University of Science and Technology' },
    { 'id': 6108, 'value': 'National Law Institute University', 'label': 'National Law Institute University' },
    { 'id': 6109, 'value': 'National Law School of India University', 'label': 'National Law School of India University' },
    { 'id': 6110, 'value': 'National Law University and Judicial Academy', 'label': 'National Law University and Judicial Academy' },
    { 'id': 6111, 'value': 'National Law University, Delhi', 'label': 'National Law University, Delhi' },
    { 'id': 6112, 'value': 'National Law University, Jodhpur', 'label': 'National Law University, Jodhpur' },
    { 'id': 6113, 'value': 'National Law University, Orissa', 'label': 'National Law University, Orissa' },
    { 'id': 13103, 'value': 'National Louis University', 'label': 'National Louis University' },
    { 'id': 12075, 'value': 'National Metallurgical Academy of Ukraine', 'label': 'National Metallurgical Academy of Ukraine' },
    { 'id': 1440, 'value': 'National Military University "Vasil Levski"', 'label': 'National Military University "Vasil Levski"' },
    { 'id': 12076, 'value': 'National Mining University', 'label': 'National Mining University' },
    { 'id': 12077, 'value': 'National Pedagogical Dragomanov University', 'label': 'National Pedagogical Dragomanov University' },
    { 'id': 11881, 'value': 'National Penghu University of Science and Technology', 'label': 'National Penghu University of Science and Technology' },
    { 'id': 11882, 'value': 'National Pingtung University', 'label': 'National Pingtung University' },
    { 'id': 11883, 'value': 'National Pingtung University of Science and Technology', 'label': 'National Pingtung University of Science and Technology' },
    { 'id': 7914, 'value': 'National Polytechnic Institute of Cambodia', 'label': 'National Polytechnic Institute of Cambodia' },
    { 'id': 827, 'value': 'National Polytechnic University of Armenia', 'label': 'National Polytechnic University of Armenia' },
    { 'id': 11884, 'value': 'National Quemoy University', 'label': 'National Quemoy University' },
    { 'id': 10786, 'value': 'National Research Nuclear University MEPI', 'label': 'National Research Nuclear University MEPI' },
    { 'id': 11509, 'value': 'National Research Technological University "MISA" in Dushanbe', 'label': 'National Research Technological University "MISA" in Dushanbe' },
    { 'id': 336, 'value': 'National Research University - Higher School of Economics (HSE) Moscow', 'label': 'National Research University - Higher School of Economics (HSE) Moscow' },
    { 'id': 10787, 'value': 'National Research University Higher School of Economics', 'label': 'National Research University Higher School of Economics' },
    { 'id': 10788, 'value': 'National Research University of Electronic Technology', 'label': 'National Research University of Electronic Technology' },
    { 'id': 11072, 'value': 'National Ribat University', 'label': 'National Ribat University' },
    { 'id': 1441, 'value': 'National Sports Academy Vasil Levski', 'label': 'National Sports Academy Vasil Levski' },
    { 'id': 11885, 'value': 'National Sun Yat-Sen University', 'label': 'National Sun Yat-Sen University' },
    { 'id': 11886, 'value': 'National Taichung University of Education', 'label': 'National Taichung University of Education' },
    { 'id': 11887, 'value': 'National Taichung University of Science and Technology', 'label': 'National Taichung University of Science and Technology' },
    { 'id': 11888, 'value': 'National Taipei University', 'label': 'National Taipei University' },
    { 'id': 11889, 'value': 'National Taipei University of Business', 'label': 'National Taipei University of Business' },
    { 'id': 11890, 'value': 'National Taipei University of Education', 'label': 'National Taipei University of Education' },
    { 'id': 11891, 'value': 'National Taipei University of Nursing and Health Sciences', 'label': 'National Taipei University of Nursing and Health Sciences' },
    { 'id': 11892, 'value': 'National Taipei University of Technology', 'label': 'National Taipei University of Technology' },
    { 'id': 11893, 'value': 'National Taitung University', 'label': 'National Taitung University' },
    { 'id': 11894, 'value': 'National Taiwan College of Performing Arts', 'label': 'National Taiwan College of Performing Arts' },
    { 'id': 11895, 'value': 'National Taiwan Normal University', 'label': 'National Taiwan Normal University' },
    { 'id': 11896, 'value': 'National Taiwan Ocean University', 'label': 'National Taiwan Ocean University' },
    { 'id': 11897, 'value': 'National Taiwan Sport University', 'label': 'National Taiwan Sport University' },
    { 'id': 11898, 'value': 'National Taiwan University', 'label': 'National Taiwan University' },
    { 'id': 11899, 'value': 'National Taiwan University of Arts', 'label': 'National Taiwan University of Arts' },
    { 'id': 11900, 'value': 'National Taiwan University of Science and Technology', 'label': 'National Taiwan University of Science and Technology' },
    { 'id': 11901, 'value': 'National Taiwan University of Sport', 'label': 'National Taiwan University of Sport' },
    { 'id': 8625, 'value': 'National Technical University', 'label': 'National Technical University' },
    { 'id': 12078, 'value': 'National Technical University Kharkiv Polytechnic Institute', 'label': 'National Technical University Kharkiv Polytechnic Institute' },
    { 'id': 4747, 'value': 'National Technical University of Athens', 'label': 'National Technical University of Athens' },
    { 'id': 12079, 'value': 'National Technical University of Ukraine Kyiv Polytechnic Institute', 'label': 'National Technical University of Ukraine Kyiv Polytechnic Institute' },
    { 'id': 10135, 'value': 'National Textile University', 'label': 'National Textile University' },
    { 'id': 12080, 'value': 'National Transport University', 'label': 'National Transport University' },
    { 'id': 11902, 'value': 'National Tsing Hua University', 'label': 'National Tsing Hua University' },
    { 'id': 11903, 'value': 'National United University', 'label': 'National United University' },
    { 'id': 13104, 'value': 'National University', 'label': 'National University' },
    { 'id': 10353, 'value': 'National University College', 'label': 'National University College' },
    { 'id': 12081, 'value': 'National University Odesa Law Academy', 'label': 'National University Odesa Law Academy' },
    { 'id': 12082, 'value': 'National University Odessa Maritime Academy', 'label': 'National University Odessa Maritime Academy' },
    { 'id': 828, 'value': 'National University of Architecture and Construction of Armenia', 'label': 'National University of Architecture and Construction of Armenia' },
    { 'id': 8523, 'value': 'National University of Arts and Culture, Mandalay', 'label': 'National University of Arts and Culture, Mandalay' },
    { 'id': 8524, 'value': 'National University of Arts and Culture, Yangon', 'label': 'National University of Arts and Culture, Yangon' },
    { 'id': 12083, 'value': 'National University of Cherkasy', 'label': 'National University of Cherkasy' },
    { 'id': 14151, 'value': 'National University of Civil Engineering', 'label': 'National University of Civil Engineering' },
    { 'id': 10136, 'value': 'National University of Computer and Emerging Sciences', 'label': 'National University of Computer and Emerging Sciences' },
    { 'id': 2619, 'value': 'National University of Defense Technology', 'label': 'National University of Defense Technology' },
    { 'id': 8626, 'value': 'National University of Economics', 'label': 'National University of Economics' },
    { 'id': 12084, 'value': 'National University of Food Technologies', 'label': 'National University of Food Technologies' },
    { 'id': 13105, 'value': 'National University of Health Sciences', 'label': 'National University of Health Sciences' },
    { 'id': 5521, 'value': 'National University of Ireland, Galway', 'label': 'National University of Ireland, Galway' },
    { 'id': 5522, 'value': 'National University of Ireland, System', 'label': 'National University of Ireland, System' },
    { 'id': 11904, 'value': 'National University of Kaohsiung', 'label': 'National University of Kaohsiung' },
    { 'id': 12085, 'value': 'National University of Kyiv-Mohyla Academy', 'label': 'National University of Kyiv-Mohyla Academy' },
    { 'id': 8272, 'value': 'National University of Laos', 'label': 'National University of Laos' },
    { 'id': 8331, 'value': 'National University of Lesotho', 'label': 'National University of Lesotho' },
    { 'id': 12086, 'value': 'National University of Life and Environmental Sciences of Ukraine', 'label': 'National University of Life and Environmental Sciences of Ukraine' },
    { 'id': 7915, 'value': 'National University of Management', 'label': 'National University of Management' },
    { 'id': 10137, 'value': 'National University of Medical Sciences', 'label': 'National University of Medical Sciences' },
    { 'id': 10138, 'value': 'National University of Modern Languages', 'label': 'National University of Modern Languages' },
    { 'id': 8627, 'value': 'National University of Mongolia', 'label': 'National University of Mongolia' },
    { 'id': 12087, 'value': 'National University of Ostroh Academy', 'label': 'National University of Ostroh Academy' },
    { 'id': 12088, 'value': 'National University of Pharmacy', 'label': 'National University of Pharmacy' },
    { 'id': 12089, 'value': 'National University of Physical Education and Sports of Ukraine', 'label': 'National University of Physical Education and Sports of Ukraine' },
    { 'id': 14312, 'value': 'National University of Science and Technology', 'label': 'National University of Science and Technology' },
    { 'id': 10789, 'value': 'National University of Science and Technology "MISIS"', 'label': 'National University of Science and Technology "MISIS"' },
    { 'id': 10139, 'value': 'National University of Sciences and Technology', 'label': 'National University of Sciences and Technology' },
    { 'id': 11150, 'value': 'National University of Singapore', 'label': 'National University of Singapore' },
    { 'id': 12090, 'value': 'National University of State Tax Service of Ukraine', 'label': 'National University of State Tax Service of Ukraine' },
    { 'id': 6114, 'value': 'National University of Study and Research in Law', 'label': 'National University of Study and Research in Law' },
    { 'id': 11905, 'value': 'National University of Tainan', 'label': 'National University of Tainan' },
    { 'id': 10140, 'value': 'National University of Technology', 'label': 'National University of Technology' },
    { 'id': 12091, 'value': 'National University of Water and Environmental Engineering', 'label': 'National University of Water and Environmental Engineering' },
    { 'id': 12092, 'value': 'National University Yuri Kondratyuk Poltava Polytechnic', 'label': 'National University Yuri Kondratyuk Poltava Polytechnic' },
    { 'id': 11073, 'value': 'National University-Sudan', 'label': 'National University-Sudan' },
    { 'id': 1279, 'value': 'National University, Bangladesh', 'label': 'National University, Bangladesh' },
    { 'id': 9926, 'value': 'National University, Philippines', 'label': 'National University, Philippines' },
    { 'id': 14206, 'value': 'National University, Yemen', 'label': 'National University, Yemen' },
    { 'id': 11906, 'value': 'National Yang-Ming University', 'label': 'National Yang-Ming University' },
    { 'id': 11907, 'value': 'National Yunlin University of Science and Technology', 'label': 'National Yunlin University of Science and Technology' },
    { 'id': 6115, 'value': 'Nava Nalanda Mahavihara', 'label': 'Nava Nalanda Mahavihara' },
    { 'id': 13106, 'value': 'Naval Postgraduate School', 'label': 'Naval Postgraduate School' },
    { 'id': 11429, 'value': 'Navamindradhiraj University', 'label': 'Navamindradhiraj University' },
    { 'id': 14000, 'value': 'Navoiy Davlat Konchilik Instituti', 'label': 'Navoiy Davlat Konchilik Instituti' },
    { 'id': 14001, 'value': 'Navoiy Davlat Pedagogika Instituti', 'label': 'Navoiy Davlat Pedagogika Instituti' },
    { 'id': 6116, 'value': 'Navrachana University', 'label': 'Navrachana University' },
    { 'id': 6117, 'value': 'Navsari Agricultural University', 'label': 'Navsari Agricultural University' },
    { 'id': 6461, 'value': 'Nawroz University', 'label': 'Nawroz University' },
    { 'id': 1147, 'value': 'Naxcivan Dövlet Universiteti', 'label': 'Naxcivan Dövlet Universiteti' },
    { 'id': 1148, 'value': 'Naxçivan Müellimler Institutu', 'label': 'Naxçivan Müellimler Institutu' },
    { 'id': 1149, 'value': 'Naxcivan Universiteti', 'label': 'Naxcivan Universiteti' },
    { 'id': 10790, 'value': 'Nayanova University', 'label': 'Nayanova University' },
    { 'id': 745, 'value': 'Nayestan Institute of Higher Education', 'label': 'Nayestan Institute of Higher Education' },
    { 'id': 7455, 'value': 'Nayoro City University', 'label': 'Nayoro City University' },
    { 'id': 8239, 'value': 'Nazarbayev University', 'label': 'Nazarbayev University' },
    { 'id': 13107, 'value': 'Nazarene Bible College', 'label': 'Nazarene Bible College' },
    { 'id': 13108, 'value': 'Nazareth College', 'label': 'Nazareth College' },
    { 'id': 10141, 'value': 'Nazeer Hussain University', 'label': 'Nazeer Hussain University' },
    { 'id': 12191, 'value': 'Ndejje University', 'label': 'Ndejje University' },
    { 'id': 3375, 'value': 'Neapolis University Paphos', 'label': 'Neapolis University Paphos' },
    { 'id': 13109, 'value': 'Nebraska Christian College', 'label': 'Nebraska Christian College' },
    { 'id': 13110, 'value': 'Nebraska Methodist College', 'label': 'Nebraska Methodist College' },
    { 'id': 13111, 'value': 'Nebraska Wesleyan University', 'label': 'Nebraska Wesleyan University' },
    { 'id': 11754, 'value': 'Necmettin Erbakan Üniversitesi', 'label': 'Necmettin Erbakan Üniversitesi' },
    { 'id': 10142, 'value': 'NED University of Engineering and Technology', 'label': 'NED University of Engineering and Technology' },
    { 'id': 11074, 'value': 'Neelain University', 'label': 'Neelain University' },
    { 'id': 9927, 'value': 'Negros Oriental State University', 'label': 'Negros Oriental State University' },
    { 'id': 6118, 'value': 'Nehru Gram Bharati Vishwavidyalaya', 'label': 'Nehru Gram Bharati Vishwavidyalaya' },
    { 'id': 2620, 'value': 'Neijiang Normal University', 'label': 'Neijiang Normal University' },
    { 'id': 11962, 'value': 'Nelson Mandela African Institution of Science and Technology', 'label': 'Nelson Mandela African Institution of Science and Technology' },
    { 'id': 14225, 'value': 'Nelson Mandela University', 'label': 'Nelson Mandela University' },
    { 'id': 4914, 'value': 'Nemzeti Közszolgálati Egyetem', 'label': 'Nemzeti Közszolgálati Egyetem' },
    { 'id': 4399, 'value': 'NEOMA Business School', 'label': 'NEOMA Business School' },
    { 'id': 9596, 'value': 'Nepal Sanskrit University', 'label': 'Nepal Sanskrit University' },
    { 'id': 220, 'value': 'Nessuna risposta per 60 giorni', 'label': 'Nessuna risposta per 60 giorni' },
    { 'id': 5545, 'value': 'Netanya Academic College', 'label': 'Netanya Academic College' },
    { 'id': 4915, 'value': 'Neumann János Egyetem', 'label': 'Neumann János Egyetem' },
    { 'id': 13112, 'value': 'Neumann University', 'label': 'Neumann University' },
    { 'id': 13113, 'value': 'Neumont University', 'label': 'Neumont University' },
    { 'id': 2621, 'value': 'Neusoft Institute, Guangdong', 'label': 'Neusoft Institute, Guangdong' },
    { 'id': 13114, 'value': 'Nevada State College', 'label': 'Nevada State College' },
    { 'id': 11755, 'value': 'Nevsehir Haci Bektas Veli Üniversitesi', 'label': 'Nevsehir Haci Bektas Veli Üniversitesi' },
    { 'id': 10791, 'value': 'Nevsky Institute of Language and Culture', 'label': 'Nevsky Institute of Language and Culture' },
    { 'id': 1442, 'value': 'New Bulgarian University', 'label': 'New Bulgarian University' },
    { 'id': 13115, 'value': 'New College of Florida', 'label': 'New College of Florida' },
    { 'id': 1043, 'value': 'New Design University Privatuniversität', 'label': 'New Design University Privatuniversität' },
    { 'id': 10792, 'value': 'New Economic School', 'label': 'New Economic School' },
    { 'id': 13116, 'value': 'New England College', 'label': 'New England College' },
    { 'id': 13117, 'value': 'New England College of Business', 'label': 'New England College of Business' },
    { 'id': 13118, 'value': 'New England College of Optometry', 'label': 'New England College of Optometry' },
    { 'id': 13119, 'value': 'New England Conservatory', 'label': 'New England Conservatory' },
    { 'id': 13120, 'value': 'New England Institute of Technology', 'label': 'New England Institute of Technology' },
    { 'id': 13121, 'value': 'New England Law | Boston', 'label': 'New England Law | Boston' },
    { 'id': 1797, 'value': 'New Era College', 'label': 'New Era College' },
    { 'id': 9928, 'value': 'New Era University', 'label': 'New Era University' },
    { 'id': 4073, 'value': 'New Giza University', 'label': 'New Giza University' },
    { 'id': 13122, 'value': 'New Hope Christian College', 'label': 'New Hope Christian College' },
    { 'id': 13123, 'value': 'New Jersey City University', 'label': 'New Jersey City University' },
    { 'id': 13124, 'value': 'New Jersey Institute of Technology', 'label': 'New Jersey Institute of Technology' },
    { 'id': 8628, 'value': 'New Medicine Institute', 'label': 'New Medicine Institute' },
    { 'id': 13125, 'value': 'New Mexico Highlands University', 'label': 'New Mexico Highlands University' },
    { 'id': 13126, 'value': 'New Mexico Institute of Mining and Technology', 'label': 'New Mexico Institute of Mining and Technology' },
    { 'id': 13127, 'value': 'New Mexico State University', 'label': 'New Mexico State University' },
    { 'id': 4074, 'value': 'New Valley University', 'label': 'New Valley University' },
    { 'id': 4644, 'value': 'New Vision University', 'label': 'New Vision University' },
    { 'id': 13128, 'value': 'New York Academy of Art', 'label': 'New York Academy of Art' },
    { 'id': 13129, 'value': 'New York City College of Technology, CUNY', 'label': 'New York City College of Technology, CUNY' },
    { 'id': 13130, 'value': 'New York College of Podiatric Medicine', 'label': 'New York College of Podiatric Medicine' },
    { 'id': 252, 'value': 'New York Film Academy', 'label': 'New York Film Academy' },
    { 'id': 13131, 'value': 'New York Institute of Technology', 'label': 'New York Institute of Technology' },
    { 'id': 659, 'value': 'New York Institute of Technology Abu Dhabi', 'label': 'New York Institute of Technology Abu Dhabi' },
    { 'id': 13132, 'value': 'New York Law School', 'label': 'New York Law School' },
    { 'id': 13133, 'value': 'New York Medical College', 'label': 'New York Medical College' },
    { 'id': 13134, 'value': 'New York School of Interior Design', 'label': 'New York School of Interior Design' },
    { 'id': 205, 'value': 'New York University', 'label': 'New York University' },
    { 'id': 660, 'value': 'New York University Abu Dhabi', 'label': 'New York University Abu Dhabi' },
    { 'id': 362, 'value': 'New York University Shangai', 'label': 'New York University Shangai' },
    { 'id': 8480, 'value': 'New York University Skopje', 'label': 'New York University Skopje' },
    { 'id': 13135, 'value': 'Newberry College', 'label': 'Newberry College' },
    { 'id': 4535, 'value': 'Newcastle University', 'label': 'Newcastle University' },
    { 'id': 13136, 'value': 'Newman University', 'label': 'Newman University' },
    { 'id': 4536, 'value': 'Newman University, Birmingham', 'label': 'Newman University, Birmingham' },
    { 'id': 11136, 'value': 'Newmaninstitutet', 'label': 'Newmaninstitutet' },
    { 'id': 10143, 'value': 'Newports Institute of Communications and Economics', 'label': 'Newports Institute of Communications and Economics' },
    { 'id': 13137, 'value': 'NewSchool of Architecture and Design', 'label': 'NewSchool of Architecture and Design' },
    { 'id': 3410, 'value': 'Newton College', 'label': 'Newton College' },
    { 'id': 1167, 'value': 'Nezavisni univerzitet Banja Luka', 'label': 'Nezavisni univerzitet Banja Luka' },
    { 'id': 10144, 'value': 'NFC Institute of Engineering and Technology', 'label': 'NFC Institute of Engineering and Technology' },
    { 'id': 11359, 'value': 'Ngwane College', 'label': 'Ngwane College' },
    { 'id': 14152, 'value': 'Nha Trang University', 'label': 'Nha Trang University' },
    { 'id': 9544, 'value': 'NHL Stenden Hogeschool', 'label': 'NHL Stenden Hogeschool' },
    { 'id': 68, 'value': 'NHL Stenden University of Applied Sciences', 'label': 'NHL Stenden University of Applied Sciences' },
    { 'id': 13138, 'value': 'Niagara University', 'label': 'Niagara University' },
    { 'id': 13139, 'value': 'Nicholls State University', 'label': 'Nicholls State University' },
    { 'id': 13140, 'value': 'Nichols College', 'label': 'Nichols College' },
    { 'id': 1884, 'value': 'Nicola Valley Institute of Technology', 'label': 'Nicola Valley Institute of Technology' },
    { 'id': 11756, 'value': 'Nigde Ömer Halisdemir Üniversitesi', 'label': 'Nigde Ömer Halisdemir Üniversitesi' },
    { 'id': 9403, 'value': 'Niger Delta University', 'label': 'Niger Delta University' },
    { 'id': 9404, 'value': 'Nigerian Maritime University, Okerenkoko', 'label': 'Nigerian Maritime University, Okerenkoko' },
    { 'id': 7456, 'value': 'Nihon Bunka University', 'label': 'Nihon Bunka University' },
    { 'id': 7457, 'value': 'Nihon Fukushi University', 'label': 'Nihon Fukushi University' },
    { 'id': 7458, 'value': 'Nihon Institute of Medical Science', 'label': 'Nihon Institute of Medical Science' },
    { 'id': 7459, 'value': 'Nihon Pharmaceutical University', 'label': 'Nihon Pharmaceutical University' },
    { 'id': 7460, 'value': 'Nihon University', 'label': 'Nihon University' },
    { 'id': 7461, 'value': 'Niigata College of Nursing', 'label': 'Niigata College of Nursing' },
    { 'id': 7462, 'value': 'Niigata Institute of Technology', 'label': 'Niigata Institute of Technology' },
    { 'id': 7463, 'value': 'Niigata Sangyo University', 'label': 'Niigata Sangyo University' },
    { 'id': 7464, 'value': 'Niigata Seiryo University', 'label': 'Niigata Seiryo University' },
    { 'id': 7465, 'value': 'Niigata University', 'label': 'Niigata University' },
    { 'id': 7466, 'value': 'Niigata University of Health and Welfare', 'label': 'Niigata University of Health and Welfare' },
    { 'id': 7467, 'value': 'Niigata University of International and Information Studies', 'label': 'Niigata University of International and Information Studies' },
    { 'id': 7468, 'value': 'Niigata University of Management', 'label': 'Niigata University of Management' },
    { 'id': 7469, 'value': 'Niigata University of Pharmacy and Applied Life Sciences', 'label': 'Niigata University of Pharmacy and Applied Life Sciences' },
    { 'id': 6119, 'value': 'NIILM University', 'label': 'NIILM University' },
    { 'id': 6120, 'value': 'NIIT University', 'label': 'NIIT University' },
    { 'id': 1443, 'value': 'Nikola Vaptsarov Naval Academy', 'label': 'Nikola Vaptsarov Naval Academy' },
    { 'id': 6121, 'value': 'Nilamber-Pitamber University', 'label': 'Nilamber-Pitamber University' },
    { 'id': 11003, 'value': 'Nile Source Polytechnic of Applied Arts', 'label': 'Nile Source Polytechnic of Applied Arts' },
    { 'id': 4075, 'value': 'Nile University', 'label': 'Nile University' },
    { 'id': 9405, 'value': 'Nile University of Nigeria', 'label': 'Nile University of Nigeria' },
    { 'id': 11075, 'value': 'Nile Valley University', 'label': 'Nile Valley University' },
    { 'id': 6122, 'value': 'NIMS University', 'label': 'NIMS University' },
    { 'id': 6462, 'value': 'Ninevah University', 'label': 'Ninevah University' },
    { 'id': 2622, 'value': 'Ningbo University', 'label': 'Ningbo University' },
    { 'id': 2623, 'value': 'Ningbo University of Finance and Economics', 'label': 'Ningbo University of Finance and Economics' },
    { 'id': 2624, 'value': 'Ningbo University of Technology', 'label': 'Ningbo University of Technology' },
    { 'id': 2625, 'value': 'Ningde Normal University', 'label': 'Ningde Normal University' },
    { 'id': 2626, 'value': 'Ningxia Institute of Science and Technology', 'label': 'Ningxia Institute of Science and Technology' },
    { 'id': 2627, 'value': 'Ningxia Medical University', 'label': 'Ningxia Medical University' },
    { 'id': 2628, 'value': 'Ningxia Normal University', 'label': 'Ningxia Normal University' },
    { 'id': 2629, 'value': 'Ningxia University', 'label': 'Ningxia University' },
    { 'id': 1885, 'value': 'Nipissing University', 'label': 'Nipissing University' },
    { 'id': 7470, 'value': 'Nippon Bunri University', 'label': 'Nippon Bunri University' },
    { 'id': 7471, 'value': 'Nippon Institute of Technology', 'label': 'Nippon Institute of Technology' },
    { 'id': 7472, 'value': 'Nippon Medical School', 'label': 'Nippon Medical School' },
    { 'id': 7473, 'value': 'Nippon Sport Science University', 'label': 'Nippon Sport Science University' },
    { 'id': 7474, 'value': 'Nippon Veterinary and Life Science University', 'label': 'Nippon Veterinary and Life Science University' },
    { 'id': 6123, 'value': 'Nirma University', 'label': 'Nirma University' },
    { 'id': 11757, 'value': 'Nisantasi Üniversitesi', 'label': 'Nisantasi Üniversitesi' },
    { 'id': 7475, 'value': 'Nishikyushu University', 'label': 'Nishikyushu University' },
    { 'id': 7476, 'value': 'Nishinippon Institute of Technology', 'label': 'Nishinippon Institute of Technology' },
    { 'id': 7477, 'value': 'Nishogakusha University', 'label': 'Nishogakusha University' },
    { 'id': 6124, 'value': 'NITTE University', 'label': 'NITTE University' },
    { 'id': 6125, 'value': 'Nizam\'s Institute of Medical Sciences', 'label': 'Nizam\'s Institute of Medical Sciences' },
    { 'id': 10793, 'value': 'Nizhnevartovsk State University', 'label': 'Nizhnevartovsk State University' },
    { 'id': 10794, 'value': 'Nizhny Novgorod State Agricultural Academy', 'label': 'Nizhny Novgorod State Agricultural Academy' },
    { 'id': 10795, 'value': 'Nizhny Novgorod State Pedagogical University', 'label': 'Nizhny Novgorod State Pedagogical University' },
    { 'id': 10796, 'value': 'Nizhny Novgorod State Technical University', 'label': 'Nizhny Novgorod State Technical University' },
    { 'id': 10797, 'value': 'Nizhny Novgorod State University of Architecture and Civil Engineering', 'label': 'Nizhny Novgorod State University of Architecture and Civil Engineering' },
    { 'id': 12093, 'value': 'Nizhyn Gogol State University', 'label': 'Nizhyn Gogol State University' },
    { 'id': 9634, 'value': 'Nizwa College of Technology', 'label': 'Nizwa College of Technology' },
    { 'id': 11221, 'value': 'Njala University', 'label': 'Njala University' },
    { 'id': 8688, 'value': 'Nkhoma University', 'label': 'Nkhoma University' },
    { 'id': 12192, 'value': 'Nkumba University', 'label': 'Nkumba University' },
    { 'id': 9574, 'value': 'NLA Høgskolen', 'label': 'NLA Høgskolen' },
    { 'id': 9406, 'value': 'Nnamdi Azikiwe University', 'label': 'Nnamdi Azikiwe University' },
    { 'id': 1280, 'value': 'Noakhali Science and Technology University', 'label': 'Noakhali Science and Technology University' },
    { 'id': 6126, 'value': 'Noida International University', 'label': 'Noida International University' },
    { 'id': 746, 'value': 'Noman Sadat Institute of Higher Education', 'label': 'Noman Sadat Institute of Higher Education' },
    { 'id': 747, 'value': 'Noorin Institute of Higher Education', 'label': 'Noorin Institute of Higher Education' },
    { 'id': 3705, 'value': 'Nordakademie Hochschule der Wirtschaft', 'label': 'Nordakademie Hochschule der Wirtschaft' },
    { 'id': 13141, 'value': 'Norfolk State University', 'label': 'Norfolk State University' },
    { 'id': 9575, 'value': 'Norges Handelshøyskole', 'label': 'Norges Handelshøyskole' },
    { 'id': 9576, 'value': 'Norges idrettshøgskole', 'label': 'Norges idrettshøgskole' },
    { 'id': 9577, 'value': 'Norges miljø- og biovitenskapelige universitet', 'label': 'Norges miljø- og biovitenskapelige universitet' },
    { 'id': 9578, 'value': 'Norges musikkhøgskole', 'label': 'Norges musikkhøgskole' },
    { 'id': 9579, 'value': 'Norges teknisk-naturvitenskaplige universitet', 'label': 'Norges teknisk-naturvitenskaplige universitet' },
    { 'id': 7478, 'value': 'North Asia University', 'label': 'North Asia University' },
    { 'id': 11430, 'value': 'North Bangkok University', 'label': 'North Bangkok University' },
    { 'id': 1281, 'value': 'North Bengal International University', 'label': 'North Bengal International University' },
    { 'id': 13142, 'value': 'North Carolina Agricultural and Technical State University', 'label': 'North Carolina Agricultural and Technical State University' },
    { 'id': 13143, 'value': 'North Carolina Central University', 'label': 'North Carolina Central University' },
    { 'id': 13144, 'value': 'North Carolina State University', 'label': 'North Carolina State University' },
    { 'id': 13145, 'value': 'North Carolina Wesleyan College', 'label': 'North Carolina Wesleyan College' },
    { 'id': 10798, 'value': 'North Caucasian Institute of Mining and Metallurgy', 'label': 'North Caucasian Institute of Mining and Metallurgy' },
    { 'id': 10799, 'value': 'North Caucasus Federal University', 'label': 'North Caucasus Federal University' },
    { 'id': 13146, 'value': 'North Central College', 'label': 'North Central College' },
    { 'id': 13147, 'value': 'North Central University', 'label': 'North Central University' },
    { 'id': 11431, 'value': 'North Chiang Mai University', 'label': 'North Chiang Mai University' },
    { 'id': 2630, 'value': 'North China Electric Power University', 'label': 'North China Electric Power University' },
    { 'id': 2631, 'value': 'North China Institute of Aerospace Engineering', 'label': 'North China Institute of Aerospace Engineering' },
    { 'id': 2632, 'value': 'North China Institute of Science and Technology', 'label': 'North China Institute of Science and Technology' },
    { 'id': 2633, 'value': 'North China University of Science and Technology', 'label': 'North China University of Science and Technology' },
    { 'id': 2634, 'value': 'North China University of Technology', 'label': 'North China University of Technology' },
    { 'id': 2635, 'value': 'North China University of Water Resources and Electric Power', 'label': 'North China University of Water Resources and Electric Power' },
    { 'id': 13148, 'value': 'North Dakota State University', 'label': 'North Dakota State University' },
    { 'id': 6127, 'value': 'North East Frontier Technical University', 'label': 'North East Frontier Technical University' },
    { 'id': 1282, 'value': 'North East University Bangladesh', 'label': 'North East University Bangladesh' },
    { 'id': 6128, 'value': 'North Eastern Hill University', 'label': 'North Eastern Hill University' },
    { 'id': 6129, 'value': 'North Eastern Regional Institute of Science and Technology', 'label': 'North Eastern Regional Institute of Science and Technology' },
    { 'id': 11432, 'value': 'North Eastern University', 'label': 'North Eastern University' },
    { 'id': 13149, 'value': 'North Greenville University', 'label': 'North Greenville University' },
    { 'id': 8240, 'value': 'North Kazakhstan State University', 'label': 'North Kazakhstan State University' },
    { 'id': 6724, 'value': 'North Khorasan University of Medical Sciences', 'label': 'North Khorasan University of Medical Sciences' },
    { 'id': 6130, 'value': 'North Maharashtra University', 'label': 'North Maharashtra University' },
    { 'id': 6131, 'value': 'North Orissa University', 'label': 'North Orissa University' },
    { 'id': 10800, 'value': 'North Ossetian State University', 'label': 'North Ossetian State University' },
    { 'id': 13150, 'value': 'North Park University', 'label': 'North Park University' },
    { 'id': 2636, 'value': 'North Sichuan Medical College', 'label': 'North Sichuan Medical College' },
    { 'id': 1283, 'value': 'North South University', 'label': 'North South University' },
    { 'id': 10801, 'value': 'North State Medical University', 'label': 'North State Medical University' },
    { 'id': 2637, 'value': 'North University of China', 'label': 'North University of China' },
    { 'id': 2638, 'value': 'North University of Nationalities', 'label': 'North University of Nationalities' },
    { 'id': 1284, 'value': 'North Western University', 'label': 'North Western University' },
    { 'id': 10802, 'value': 'North-Eastern Federal University', 'label': 'North-Eastern Federal University' },
    { 'id': 10803, 'value': 'North-Eastern State University', 'label': 'North-Eastern State University' },
    { 'id': 14226, 'value': 'North-West University', 'label': 'North-West University' },
    { 'id': 10806, 'value': 'North-Western State Medical University', 'label': 'North-Western State Medical University' },
    { 'id': 2639, 'value': 'Northeast Agricultural University', 'label': 'Northeast Agricultural University' },
    { 'id': 2640, 'value': 'Northeast Electric Power University', 'label': 'Northeast Electric Power University' },
    { 'id': 2641, 'value': 'Northeast Forestry University', 'label': 'Northeast Forestry University' },
    { 'id': 2642, 'value': 'Northeast Normal University', 'label': 'Northeast Normal University' },
    { 'id': 13151, 'value': 'Northeast Ohio Medical University', 'label': 'Northeast Ohio Medical University' },
    { 'id': 2643, 'value': 'Northeast Petroleum University', 'label': 'Northeast Petroleum University' },
    { 'id': 13152, 'value': 'Northeastern Illinois University', 'label': 'Northeastern Illinois University' },
    { 'id': 13153, 'value': 'Northeastern State University', 'label': 'Northeastern State University' },
    { 'id': 13154, 'value': 'Northeastern University', 'label': 'Northeastern University' },
    { 'id': 2644, 'value': 'Northeastern University, China', 'label': 'Northeastern University, China' },
    { 'id': 1886, 'value': 'Northern Alberta Institute of Technology', 'label': 'Northern Alberta Institute of Technology' },
    { 'id': 10804, 'value': 'Northern Arctic Federal University', 'label': 'Northern Arctic Federal University' },
    { 'id': 13155, 'value': 'Northern Arizona University', 'label': 'Northern Arizona University' },
    { 'id': 11039, 'value': 'Northern Borders University', 'label': 'Northern Borders University' },
    { 'id': 7029, 'value': 'Northern Caribbean University', 'label': 'Northern Caribbean University' },
    { 'id': 13156, 'value': 'Northern Illinois University', 'label': 'Northern Illinois University' },
    { 'id': 13157, 'value': 'Northern Kentucky University', 'label': 'Northern Kentucky University' },
    { 'id': 9929, 'value': 'Northern Luzon Adventist College', 'label': 'Northern Luzon Adventist College' },
    { 'id': 13158, 'value': 'Northern Michigan University', 'label': 'Northern Michigan University' },
    { 'id': 13159, 'value': 'Northern New Mexico College', 'label': 'Northern New Mexico College' },
    { 'id': 10805, 'value': 'Northern Ossetian State Academy of Medicine', 'label': 'Northern Ossetian State Academy of Medicine' },
    { 'id': 11222, 'value': 'Northern Polytechnic', 'label': 'Northern Polytechnic' },
    { 'id': 4537, 'value': 'Northern School of Contemporary Dance', 'label': 'Northern School of Contemporary Dance' },
    { 'id': 13160, 'value': 'Northern State University', 'label': 'Northern State University' },
    { 'id': 6463, 'value': 'Northern Technical University', 'label': 'Northern Technical University' },
    { 'id': 10145, 'value': 'Northern University', 'label': 'Northern University' },
    { 'id': 1285, 'value': 'Northern University of Bangladesh', 'label': 'Northern University of Bangladesh' },
    { 'id': 1286, 'value': 'Northern University of Business and Technology, Khulna', 'label': 'Northern University of Business and Technology, Khulna' },
    { 'id': 13161, 'value': 'Northern Vermont University', 'label': 'Northern Vermont University' },
    { 'id': 13162, 'value': 'Northland College', 'label': 'Northland College' },
    { 'id': 13163, 'value': 'Northpoint Bible College', 'label': 'Northpoint Bible College' },
    { 'id': 14274, 'value': 'Northrise University', 'label': 'Northrise University' },
    { 'id': 4538, 'value': 'Northumbria University', 'label': 'Northumbria University' },
    { 'id': 2645, 'value': 'Northwest A&F University', 'label': 'Northwest A&F University' },
    { 'id': 13164, 'value': 'Northwest Christian University', 'label': 'Northwest Christian University' },
    { 'id': 13165, 'value': 'Northwest Florida State College', 'label': 'Northwest Florida State College' },
    { 'id': 13166, 'value': 'Northwest Missouri State University', 'label': 'Northwest Missouri State University' },
    { 'id': 13167, 'value': 'Northwest Nazarene University', 'label': 'Northwest Nazarene University' },
    { 'id': 2646, 'value': 'Northwest Normal University', 'label': 'Northwest Normal University' },
    { 'id': 13168, 'value': 'Northwest University', 'label': 'Northwest University' },
    { 'id': 2647, 'value': 'Northwest University for Nationalities', 'label': 'Northwest University for Nationalities' },
    { 'id': 2648, 'value': 'Northwest University of Politics and Law', 'label': 'Northwest University of Politics and Law' },
    { 'id': 2649, 'value': 'Northwest University, China', 'label': 'Northwest University, China' },
    { 'id': 14448, 'value': 'Northwest Vista College San Antonio TX - USA', 'label': 'Northwest Vista College San Antonio TX - USA' },
    { 'id': 13169, 'value': 'Northwestern College', 'label': 'Northwestern College' },
    { 'id': 13170, 'value': 'Northwestern Health Sciences University', 'label': 'Northwestern Health Sciences University' },
    { 'id': 13171, 'value': 'Northwestern Oklahoma State University', 'label': 'Northwestern Oklahoma State University' },
    { 'id': 2650, 'value': 'Northwestern Polytechnical University', 'label': 'Northwestern Polytechnical University' },
    { 'id': 13172, 'value': 'Northwestern State University of Louisiana', 'label': 'Northwestern State University of Louisiana' },
    { 'id': 506, 'value': 'Northwestern University', 'label': 'Northwestern University' },
    { 'id': 10511, 'value': 'Northwestern University in Qatar', 'label': 'Northwestern University in Qatar' },
    { 'id': 9930, 'value': 'Northwestern University, Philippines', 'label': 'Northwestern University, Philippines' },
    { 'id': 13173, 'value': 'Northwood University', 'label': 'Northwood University' },
    { 'id': 7916, 'value': 'Norton University', 'label': 'Norton University' },
    { 'id': 267, 'value': 'Norwegian University Life of Sciences', 'label': 'Norwegian University Life of Sciences' },
    { 'id': 13174, 'value': 'Norwich University', 'label': 'Norwich University' },
    { 'id': 4539, 'value': 'Norwich University of the Arts', 'label': 'Norwich University of the Arts' },
    { 'id': 13175, 'value': 'Notre Dame College', 'label': 'Notre Dame College' },
    { 'id': 13176, 'value': 'Notre Dame de Namur University', 'label': 'Notre Dame de Namur University' },
    { 'id': 9931, 'value': 'Notre Dame of Dadiangas University', 'label': 'Notre Dame of Dadiangas University' },
    { 'id': 9932, 'value': 'Notre Dame of Kidapawan College', 'label': 'Notre Dame of Kidapawan College' },
    { 'id': 9933, 'value': 'Notre Dame of Marbel University', 'label': 'Notre Dame of Marbel University' },
    { 'id': 13177, 'value': 'Notre Dame of Maryland University', 'label': 'Notre Dame of Maryland University' },
    { 'id': 7479, 'value': 'Notre Dame Seishin University', 'label': 'Notre Dame Seishin University' },
    { 'id': 8294, 'value': 'Notre Dame University', 'label': 'Notre Dame University' },
    { 'id': 1287, 'value': 'Notre Dame University Bangladesh', 'label': 'Notre Dame University Bangladesh' },
    { 'id': 418, 'value': 'Nottingham Trent University', 'label': 'Nottingham Trent University' },
    { 'id': 218, 'value': 'NOVA School of Business and Economics', 'label': 'NOVA School of Business and Economics' },
    { 'id': 13178, 'value': 'Nova Southeastern University', 'label': 'Nova Southeastern University' },
    { 'id': 9407, 'value': 'Novena University', 'label': 'Novena University' },
    { 'id': 10807, 'value': 'Novgorod State University', 'label': 'Novgorod State University' },
    { 'id': 10808, 'value': 'Novosibirsk State Agricultural University', 'label': 'Novosibirsk State Agricultural University' },
    { 'id': 10809, 'value': 'Novosibirsk State Architectural University', 'label': 'Novosibirsk State Architectural University' },
    { 'id': 10810, 'value': 'Novosibirsk State Medical University', 'label': 'Novosibirsk State Medical University' },
    { 'id': 10811, 'value': 'Novosibirsk State Pedagogical University', 'label': 'Novosibirsk State Pedagogical University' },
    { 'id': 10812, 'value': 'Novosibirsk State Technical University', 'label': 'Novosibirsk State Technical University' },
    { 'id': 10813, 'value': 'Novosibirsk State University', 'label': 'Novosibirsk State University' },
    { 'id': 10814, 'value': 'Novosibirsk State University of Economics and Management', 'label': 'Novosibirsk State University of Economics and Management' },
    { 'id': 12193, 'value': 'Nsaka University', 'label': 'Nsaka University' },
    { 'id': 1887, 'value': 'NSCAD University', 'label': 'NSCAD University' },
    { 'id': 3706, 'value': 'nta Hochschule Isny', 'label': 'nta Hochschule Isny' },
    { 'id': 9934, 'value': 'Nueva Ecija University of Science and Technology', 'label': 'Nueva Ecija University of Science and Technology' },
    { 'id': 9935, 'value': 'Nueva Vizcaya State University', 'label': 'Nueva Vizcaya State University' },
    { 'id': 11758, 'value': 'Nuh Naci Yazgan Üniversitesi', 'label': 'Nuh Naci Yazgan Üniversitesi' },
    { 'id': 14002, 'value': 'Nukus Davlat Pedagogika Instituti', 'label': 'Nukus Davlat Pedagogika Instituti' },
    { 'id': 10146, 'value': 'Nur International University', 'label': 'Nur International University' },
    { 'id': 353, 'value': 'NUS Business School (National University of Singapore)', 'label': 'NUS Business School (National University of Singapore)' },
    { 'id': 13179, 'value': 'Nyack College', 'label': 'Nyack College' },
    { 'id': 9545, 'value': 'Nyenrode Business Universiteit', 'label': 'Nyenrode Business Universiteit' },
    { 'id': 4916, 'value': 'Nyíregyházi Egyetem', 'label': 'Nyíregyházi Egyetem' },
    { 'id': 12094, 'value': 'O.M. Beketov National University of Urban Economy in Kharkiv', 'label': 'O.M. Beketov National University of Urban Economy in Kharkiv' },
    { 'id': 12095, 'value': 'O.O. Bogomolets National Medical University', 'label': 'O.O. Bogomolets National Medical University' },
    { 'id': 6132, 'value': 'O.P. Jindal Global University', 'label': 'O.P. Jindal Global University' },
    { 'id': 6133, 'value': 'O.P. Jindal University', 'label': 'O.P. Jindal University' },
    { 'id': 14003, 'value': 'O\'zbekiston Davlat Jahon Tillari Universiteti', 'label': 'O\'zbekiston Davlat Jahon Tillari Universiteti' },
    { 'id': 14004, 'value': 'O\'zbekiston davlat jismoniy tarbiya va sport universiteti', 'label': 'O\'zbekiston davlat jismoniy tarbiya va sport universiteti' },
    { 'id': 14005, 'value': 'O\'zbekiston Davlat Konservatoriyasi', 'label': 'O\'zbekiston Davlat Konservatoriyasi' },
    { 'id': 14006, 'value': 'O\'zbekiston Davlat San\'at va Madaniyat Instituti', 'label': 'O\'zbekiston Davlat San\'at va Madaniyat Instituti' },
    { 'id': 14007, 'value': 'O\'zbekiston Milliy Universiteti', 'label': 'O\'zbekiston Milliy Universiteti' },
    { 'id': 14008, 'value': 'O\'zbekiston Respublikasi Davlat soliq qo\'mitasi huzuridagi Malaka oshirish markazi', 'label': 'O\'zbekiston Respublikasi Davlat soliq qo\'mitasi huzuridagi Malaka oshirish markazi' },
    { 'id': 13180, 'value': 'Oak Hills Christian College', 'label': 'Oak Hills Christian College' },
    { 'id': 14275, 'value': 'OAK University', 'label': 'OAK University' },
    { 'id': 13181, 'value': 'Oakland City University', 'label': 'Oakland City University' },
    { 'id': 13182, 'value': 'Oakland University', 'label': 'Oakland University' },
    { 'id': 13183, 'value': 'Oakwood University', 'label': 'Oakwood University' },
    { 'id': 9408, 'value': 'Obafemi Awolowo University', 'label': 'Obafemi Awolowo University' },
    { 'id': 13184, 'value': 'Oberlin College', 'label': 'Oberlin College' },
    { 'id': 7480, 'value': 'Obihiro University of Agriculture and Veterinary Medicine', 'label': 'Obihiro University of Agriculture and Veterinary Medicine' },
    { 'id': 7481, 'value': 'Obirin Daigaku', 'label': 'Obirin Daigaku' },
    { 'id': 9409, 'value': 'Obong University', 'label': 'Obong University' },
    { 'id': 4917, 'value': 'Óbudai Egyetem', 'label': 'Óbudai Egyetem' },
    { 'id': 1888, 'value': 'OCAD University', 'label': 'OCAD University' },
    { 'id': 13185, 'value': 'Occidental College', 'label': 'Occidental College' },
    { 'id': 2651, 'value': 'Ocean University of China', 'label': 'Ocean University of China' },
    { 'id': 7482, 'value': 'Ochanomizu University', 'label': 'Ochanomizu University' },
    { 'id': 4076, 'value': 'October 6 University', 'label': 'October 6 University' },
    { 'id': 12096, 'value': 'Odessa National Academy of Food Technologies', 'label': 'Odessa National Academy of Food Technologies' },
    { 'id': 12097, 'value': 'Odessa National Academy of Music', 'label': 'Odessa National Academy of Music' },
    { 'id': 12098, 'value': 'Odessa National Academy of Telecommunications', 'label': 'Odessa National Academy of Telecommunications' },
    { 'id': 12099, 'value': 'Odessa National Economics University', 'label': 'Odessa National Economics University' },
    { 'id': 12100, 'value': 'Odessa National Maritime University', 'label': 'Odessa National Maritime University' },
    { 'id': 12101, 'value': 'Odessa National Medical University', 'label': 'Odessa National Medical University' },
    { 'id': 12102, 'value': 'Odessa National Polytechnic University', 'label': 'Odessa National Polytechnic University' },
    { 'id': 12103, 'value': 'Odessa National University', 'label': 'Odessa National University' },
    { 'id': 12104, 'value': 'Odessa State Academy of Civil Engineering and Architecture', 'label': 'Odessa State Academy of Civil Engineering and Architecture' },
    { 'id': 12105, 'value': 'Odessa State Agrarian University', 'label': 'Odessa State Agrarian University' },
    { 'id': 12106, 'value': 'Odessa State Environmental University', 'label': 'Odessa State Environmental University' },
    { 'id': 1387, 'value': 'Odisee', 'label': 'Odisee' },
    { 'id': 1150, 'value': 'Odlar Yurdu Universiteti', 'label': 'Odlar Yurdu Universiteti' },
    { 'id': 9410, 'value': 'Oduduwa University', 'label': 'Oduduwa University' },
    { 'id': 10815, 'value': 'Ogarev Mordovia State University', 'label': 'Ogarev Mordovia State University' },
    { 'id': 13186, 'value': 'Oglethorpe University', 'label': 'Oglethorpe University' },
    { 'id': 11537, 'value': 'Oguz Han Engineering and Technology University of Turkmenistan', 'label': 'Oguz Han Engineering and Technology University of Turkmenistan' },
    { 'id': 13187, 'value': 'Ohio Christian University', 'label': 'Ohio Christian University' },
    { 'id': 13188, 'value': 'Ohio Dominican University', 'label': 'Ohio Dominican University' },
    { 'id': 13189, 'value': 'Ohio Northern University', 'label': 'Ohio Northern University' },
    { 'id': 13190, 'value': 'Ohio University', 'label': 'Ohio University' },
    { 'id': 13191, 'value': 'Ohio University Southern', 'label': 'Ohio University Southern' },
    { 'id': 13192, 'value': 'Ohio University-Chillicothe', 'label': 'Ohio University-Chillicothe' },
    { 'id': 13193, 'value': 'Ohio Valley University', 'label': 'Ohio Valley University' },
    { 'id': 13194, 'value': 'Ohio Wesleyan University', 'label': 'Ohio Wesleyan University' },
    { 'id': 7483, 'value': 'Ohkagakuen University', 'label': 'Ohkagakuen University' },
    { 'id': 7484, 'value': 'Ohu University', 'label': 'Ohu University' },
    { 'id': 7485, 'value': 'Oita University', 'label': 'Oita University' },
    { 'id': 7486, 'value': 'Oita University of Nursing and Health Sciences', 'label': 'Oita University of Nursing and Health Sciences' },
    { 'id': 11759, 'value': 'Okan Üniversitesi', 'label': 'Okan Üniversitesi' },
    { 'id': 7487, 'value': 'Okayama Gakuin University', 'label': 'Okayama Gakuin University' },
    { 'id': 7488, 'value': 'Okayama Prefectural University', 'label': 'Okayama Prefectural University' },
    { 'id': 7489, 'value': 'Okayama Shoka University', 'label': 'Okayama Shoka University' },
    { 'id': 7490, 'value': 'Okayama University', 'label': 'Okayama University' },
    { 'id': 7491, 'value': 'Okayama University of Science', 'label': 'Okayama University of Science' },
    { 'id': 7492, 'value': 'Okinawa Christian University', 'label': 'Okinawa Christian University' },
    { 'id': 7493, 'value': 'Okinawa International University', 'label': 'Okinawa International University' },
    { 'id': 7494, 'value': 'Okinawa Prefectural College of Nursing', 'label': 'Okinawa Prefectural College of Nursing' },
    { 'id': 7495, 'value': 'Okinawa Prefectural University of Arts', 'label': 'Okinawa Prefectural University of Arts' },
    { 'id': 13195, 'value': 'Oklahoma Baptist University', 'label': 'Oklahoma Baptist University' },
    { 'id': 13196, 'value': 'Oklahoma Christian University', 'label': 'Oklahoma Christian University' },
    { 'id': 13197, 'value': 'Oklahoma City University', 'label': 'Oklahoma City University' },
    { 'id': 13198, 'value': 'Oklahoma Panhandle State University', 'label': 'Oklahoma Panhandle State University' },
    { 'id': 13199, 'value': 'Oklahoma State University', 'label': 'Oklahoma State University' },
    { 'id': 13200, 'value': 'Oklahoma Wesleyan University', 'label': 'Oklahoma Wesleyan University' },
    { 'id': 9411, 'value': 'Olabisi Onabanjo University', 'label': 'Olabisi Onabanjo University' },
    { 'id': 13201, 'value': 'Old Dominion University', 'label': 'Old Dominion University' },
    { 'id': 9936, 'value': 'Olivarez College', 'label': 'Olivarez College' },
    { 'id': 13202, 'value': 'Olivet College', 'label': 'Olivet College' },
    { 'id': 13203, 'value': 'Olivet Nazarene University', 'label': 'Olivet Nazarene University' },
    { 'id': 9635, 'value': 'Oman College of Management and Technology', 'label': 'Oman College of Management and Technology' },
    { 'id': 9636, 'value': 'Oman Dental College', 'label': 'Oman Dental College' },
    { 'id': 9637, 'value': 'Oman Medical College', 'label': 'Oman Medical College' },
    { 'id': 9638, 'value': 'Oman Tourism College', 'label': 'Oman Tourism College' },
    { 'id': 8402, 'value': 'Omar Al-Mukhtar University', 'label': 'Omar Al-Mukhtar University' },
    { 'id': 11076, 'value': 'Omdurman Ahlia University', 'label': 'Omdurman Ahlia University' },
    { 'id': 11077, 'value': 'Omdurman Islamic University', 'label': 'Omdurman Islamic University' },
    { 'id': 10816, 'value': 'Omsk State Agrarian University n.a. P. A. Stolypin', 'label': 'Omsk State Agrarian University n.a. P. A. Stolypin' },
    { 'id': 10817, 'value': 'Omsk State Medical Academy', 'label': 'Omsk State Medical Academy' },
    { 'id': 10818, 'value': 'Omsk State Pedagogical University', 'label': 'Omsk State Pedagogical University' },
    { 'id': 10819, 'value': 'Omsk State Technical University', 'label': 'Omsk State Technical University' },
    { 'id': 10820, 'value': 'Omsk State Transport University', 'label': 'Omsk State Transport University' },
    { 'id': 3376, 'value': 'Onbes Kasim Kibris Üniversitesi', 'label': 'Onbes Kasim Kibris Üniversitesi' },
    { 'id': 9412, 'value': 'Ondo State University of Science and Technology', 'label': 'Ondo State University of Science and Technology' },
    { 'id': 11760, 'value': 'Ondokuz Mayis Üniversitesi', 'label': 'Ondokuz Mayis Üniversitesi' },
    { 'id': 5546, 'value': 'Ono Academic College', 'label': 'Ono Academic College' },
    { 'id': 7496, 'value': 'Onomichi University', 'label': 'Onomichi University' },
    { 'id': 1889, 'value': 'Ontario Tech University', 'label': 'Ontario Tech University' },
    { 'id': 445, 'value': 'Opificio delle Pietre Dure - Fondazione CR Firenze', 'label': 'Opificio delle Pietre Dure - Fondazione CR Firenze' },
    { 'id': 6134, 'value': 'OPJS University', 'label': 'OPJS University' },
    { 'id': 13204, 'value': 'Oral Roberts University', 'label': 'Oral Roberts University' },
    { 'id': 2652, 'value': 'Ordos Institute of Technology', 'label': 'Ordos Institute of Technology' },
    { 'id': 11761, 'value': 'Ordu Üniversitesi', 'label': 'Ordu Üniversitesi' },
    { 'id': 11137, 'value': 'Örebro Universitet', 'label': 'Örebro Universitet' },
    { 'id': 13205, 'value': 'Oregon Health & Science University', 'label': 'Oregon Health & Science University' },
    { 'id': 13206, 'value': 'Oregon Institute of Technology', 'label': 'Oregon Institute of Technology' },
    { 'id': 13207, 'value': 'Oregon State University', 'label': 'Oregon State University' },
    { 'id': 10821, 'value': 'Orel State Agrarian University', 'label': 'Orel State Agrarian University' },
    { 'id': 10822, 'value': 'Orel State University', 'label': 'Orel State University' },
    { 'id': 10823, 'value': 'Orenburg State Agrarian University', 'label': 'Orenburg State Agrarian University' },
    { 'id': 10824, 'value': 'Orenburg State Medical University', 'label': 'Orenburg State Medical University' },
    { 'id': 10825, 'value': 'Orenburg State Pedagogical University', 'label': 'Orenburg State Pedagogical University' },
    { 'id': 10826, 'value': 'Orenburg State University', 'label': 'Orenburg State University' },
    { 'id': 11908, 'value': 'Oriental Institute of Technology', 'label': 'Oriental Institute of Technology' },
    { 'id': 6135, 'value': 'Oriental University', 'label': 'Oriental University' },
    { 'id': 6136, 'value': 'Orissa University of Agriculture and Technology', 'label': 'Orissa University of Agriculture and Technology' },
    { 'id': 8629, 'value': 'Orkhon University', 'label': 'Orkhon University' },
    { 'id': 4099, 'value': 'Orota School of Medicine and Dental Medicine', 'label': 'Orota School of Medicine and Dental Medicine' },
    { 'id': 5547, 'value': 'ORT Braude College', 'label': 'ORT Braude College' },
    { 'id': 11762, 'value': 'Orta Dogu Teknik Üniversitesi', 'label': 'Orta Dogu Teknik Üniversitesi' },
    { 'id': 3377, 'value': 'Orta Dogu Teknik Universitesi Kuzey Kibris Kampusu', 'label': 'Orta Dogu Teknik Universitesi Kuzey Kibris Kampusu' },
    { 'id': 748, 'value': 'Oruj Institute of Higher Education', 'label': 'Oruj Institute of Higher Education' },
    { 'id': 7497, 'value': 'Osaka Aoyama University', 'label': 'Osaka Aoyama University' },
    { 'id': 7498, 'value': 'Osaka City University', 'label': 'Osaka City University' },
    { 'id': 7499, 'value': 'Osaka College of Music', 'label': 'Osaka College of Music' },
    { 'id': 7500, 'value': 'Osaka Dental University', 'label': 'Osaka Dental University' },
    { 'id': 7501, 'value': 'Osaka Electro-Communication University', 'label': 'Osaka Electro-Communication University' },
    { 'id': 7502, 'value': 'Osaka Gakuin University', 'label': 'Osaka Gakuin University' },
    { 'id': 7503, 'value': 'Osaka Institute of Technology', 'label': 'Osaka Institute of Technology' },
    { 'id': 7504, 'value': 'Osaka International University', 'label': 'Osaka International University' },
    { 'id': 7505, 'value': 'Osaka Jogakuin College', 'label': 'Osaka Jogakuin College' },
    { 'id': 7506, 'value': 'Osaka Kawasaki Rehabilitation University', 'label': 'Osaka Kawasaki Rehabilitation University' },
    { 'id': 7507, 'value': 'Osaka Kyoiku University', 'label': 'Osaka Kyoiku University' },
    { 'id': 7508, 'value': 'Osaka Medical College', 'label': 'Osaka Medical College' },
    { 'id': 7509, 'value': 'Osaka Ohtani University', 'label': 'Osaka Ohtani University' },
    { 'id': 7510, 'value': 'Osaka Prefecture University', 'label': 'Osaka Prefecture University' },
    { 'id': 7511, 'value': 'Osaka Sangyo University', 'label': 'Osaka Sangyo University' },
    { 'id': 7512, 'value': 'Osaka Seikei University', 'label': 'Osaka Seikei University' },
    { 'id': 7513, 'value': 'Osaka Shoin Women\'s University', 'label': 'Osaka Shoin Women\'s University' },
    { 'id': 7514, 'value': 'Osaka University', 'label': 'Osaka University' },
    { 'id': 7515, 'value': 'Osaka University of Arts', 'label': 'Osaka University of Arts' },
    { 'id': 7516, 'value': 'Osaka University of Commerce', 'label': 'Osaka University of Commerce' },
    { 'id': 7517, 'value': 'Osaka University of Economics', 'label': 'Osaka University of Economics' },
    { 'id': 7518, 'value': 'Osaka University of Economics and Law', 'label': 'Osaka University of Economics and Law' },
    { 'id': 7519, 'value': 'Osaka University of Health and Sport Sciences', 'label': 'Osaka University of Health and Sport Sciences' },
    { 'id': 7520, 'value': 'Osaka University of Human Sciences', 'label': 'Osaka University of Human Sciences' },
    { 'id': 7521, 'value': 'Osaka University of Pharmaceutical Sciences', 'label': 'Osaka University of Pharmaceutical Sciences' },
    { 'id': 7522, 'value': 'Osaka University of Tourism', 'label': 'Osaka University of Tourism' },
    { 'id': 7877, 'value': 'Osh Agrarian Institute', 'label': 'Osh Agrarian Institute' },
    { 'id': 7878, 'value': 'Osh Humanity-Pedagogical Institute', 'label': 'Osh Humanity-Pedagogical Institute' },
    { 'id': 7879, 'value': 'Osh State Law Institute', 'label': 'Osh State Law Institute' },
    { 'id': 7880, 'value': 'Osh State University', 'label': 'Osh State University' },
    { 'id': 7881, 'value': 'Osh Technological University', 'label': 'Osh Technological University' },
    { 'id': 9580, 'value': 'OsloMet', 'label': 'OsloMet' },
    { 'id': 6137, 'value': 'Osmania University', 'label': 'Osmania University' },
    { 'id': 11763, 'value': 'Osmaniye Korkut Ata Üniversitesi', 'label': 'Osmaniye Korkut Ata Üniversitesi' },
    { 'id': 3707, 'value': 'Ostbayerische Technische Hochschule Amberg-Weiden', 'label': 'Ostbayerische Technische Hochschule Amberg-Weiden' },
    { 'id': 3708, 'value': 'Ostbayerische Technische Hochschule Regensburg', 'label': 'Ostbayerische Technische Hochschule Regensburg' },
    { 'id': 3709, 'value': 'Ostfalia Hochschule für angewandte Wissenschaften', 'label': 'Ostfalia Hochschule für angewandte Wissenschaften' },
    { 'id': 3411, 'value': 'Ostravská univerzita', 'label': 'Ostravská univerzita' },
    { 'id': 9413, 'value': 'Osun State University', 'label': 'Osun State University' },
    { 'id': 7523, 'value': 'Otani University', 'label': 'Otani University' },
    { 'id': 7524, 'value': 'Otaru University of Commerce', 'label': 'Otaru University of Commerce' },
    { 'id': 7525, 'value': 'Otemae University', 'label': 'Otemae University' },
    { 'id': 7526, 'value': 'Otemon Gakuin University', 'label': 'Otemon Gakuin University' },
    { 'id': 8630, 'value': 'Otgontenger University', 'label': 'Otgontenger University' },
    { 'id': 13208, 'value': 'Otis College of Art and Design', 'label': 'Otis College of Art and Design' },
    { 'id': 8631, 'value': 'Otoch Manramba University', 'label': 'Otoch Manramba University' },
    { 'id': 7527, 'value': 'Otsuma Women\'s University', 'label': 'Otsuma Women\'s University' },
    { 'id': 13209, 'value': 'Ottawa University', 'label': 'Ottawa University' },
    { 'id': 13210, 'value': 'Otterbein University', 'label': 'Otterbein University' },
    { 'id': 3710, 'value': 'Otto-Friedrich-Universität Bamberg', 'label': 'Otto-Friedrich-Universität Bamberg' },
    { 'id': 3711, 'value': 'Otto-von-Guericke-Universität Magdeburg', 'label': 'Otto-von-Guericke-Universität Magdeburg' },
    { 'id': 13211, 'value': 'Ouachita Baptist University', 'label': 'Ouachita Baptist University' },
    { 'id': 4229, 'value': 'Oulun Seudun ammattikorkeakoulu', 'label': 'Oulun Seudun ammattikorkeakoulu' },
    { 'id': 4230, 'value': 'Oulun yliopisto', 'label': 'Oulun yliopisto' },
    { 'id': 9937, 'value': 'Our Lady of Fatima University', 'label': 'Our Lady of Fatima University' },
    { 'id': 13212, 'value': 'Our Lady of the Lake University', 'label': 'Our Lady of the Lake University' },
    { 'id': 11909, 'value': 'Overseas Chinese University', 'label': 'Overseas Chinese University' },
    { 'id': 4540, 'value': 'Oxford Brookes University', 'label': 'Oxford Brookes University' },
    { 'id': 13213, 'value': 'Ozark Christian College', 'label': 'Ozark Christian College' },
    { 'id': 11764, 'value': 'Özyegin Üniversitesi', 'label': 'Özyegin Üniversitesi' },
    { 'id': 6138, 'value': 'P P Savani University', 'label': 'P P Savani University' },
    { 'id': 3378, 'value': 'P.A. College', 'label': 'P.A. College' },
    { 'id': 6139, 'value': 'P.K. University', 'label': 'P.K. University' },
    { 'id': 1288, 'value': 'Pabna Science and Technology University', 'label': 'Pabna Science and Technology University' },
    { 'id': 13214, 'value': 'Pace University', 'label': 'Pace University' },
    { 'id': 9810, 'value': 'Pacific Adventist University', 'label': 'Pacific Adventist University' },
    { 'id': 13215, 'value': 'Pacific Lutheran University', 'label': 'Pacific Lutheran University' },
    { 'id': 6140, 'value': 'Pacific Medical University', 'label': 'Pacific Medical University' },
    { 'id': 10827, 'value': 'Pacific National University', 'label': 'Pacific National University' },
    { 'id': 13216, 'value': 'Pacific Northwest College of Art', 'label': 'Pacific Northwest College of Art' },
    { 'id': 13217, 'value': 'Pacific Oaks College', 'label': 'Pacific Oaks College' },
    { 'id': 10828, 'value': 'Pacific State Medical University', 'label': 'Pacific State Medical University' },
    { 'id': 13218, 'value': 'Pacific Union College', 'label': 'Pacific Union College' },
    { 'id': 13219, 'value': 'Pacific University', 'label': 'Pacific University' },
    { 'id': 6141, 'value': 'Pacific University, India', 'label': 'Pacific University, India' },
    { 'id': 13220, 'value': 'Pacifica Graduate Institute', 'label': 'Pacifica Graduate Institute' },
    { 'id': 3712, 'value': 'Pädagogische Hochschule Freiburg', 'label': 'Pädagogische Hochschule Freiburg' },
    { 'id': 3713, 'value': 'Pädagogische Hochschule Heidelberg', 'label': 'Pädagogische Hochschule Heidelberg' },
    { 'id': 3714, 'value': 'Pädagogische Hochschule Karlsruhe', 'label': 'Pädagogische Hochschule Karlsruhe' },
    { 'id': 1044, 'value': 'Pädagogische Hochschule Kärnten', 'label': 'Pädagogische Hochschule Kärnten' },
    { 'id': 3715, 'value': 'Pädagogische Hochschule Ludwigsburg', 'label': 'Pädagogische Hochschule Ludwigsburg' },
    { 'id': 1045, 'value': 'Pädagogische Hochschule Niederösterreich', 'label': 'Pädagogische Hochschule Niederösterreich' },
    { 'id': 1046, 'value': 'Pädagogische Hochschule Oberösterreich', 'label': 'Pädagogische Hochschule Oberösterreich' },
    { 'id': 1047, 'value': 'Pädagogische Hochschule Salzburg', 'label': 'Pädagogische Hochschule Salzburg' },
    { 'id': 3716, 'value': 'Pädagogische Hochschule Schwäbisch Gmünd', 'label': 'Pädagogische Hochschule Schwäbisch Gmünd' },
    { 'id': 1048, 'value': 'Pädagogische Hochschule Steiermark', 'label': 'Pädagogische Hochschule Steiermark' },
    { 'id': 1049, 'value': 'Pädagogische Hochschule Tirol', 'label': 'Pädagogische Hochschule Tirol' },
    { 'id': 1050, 'value': 'Pädagogische Hochschule Vorarlberg', 'label': 'Pädagogische Hochschule Vorarlberg' },
    { 'id': 3717, 'value': 'Pädagogische Hochschule Weingarten', 'label': 'Pädagogische Hochschule Weingarten' },
    { 'id': 1051, 'value': 'Pädagogische Hochschule Wien', 'label': 'Pädagogische Hochschule Wien' },
    { 'id': 6142, 'value': 'Padmashree Dr. D.Y. Patil Vidyapith', 'label': 'Padmashree Dr. D.Y. Patil Vidyapith' },
    { 'id': 14276, 'value': 'Paglory University', 'label': 'Paglory University' },
    { 'id': 8101, 'value': 'Pai Chai University', 'label': 'Pai Chai University' },
    { 'id': 13221, 'value': 'Paine College', 'label': 'Paine College' },
    { 'id': 10147, 'value': 'Pakistan Institute of Development Economics', 'label': 'Pakistan Institute of Development Economics' },
    { 'id': 10148, 'value': 'Pakistan Institute of Engineering and Applied Sciences', 'label': 'Pakistan Institute of Engineering and Applied Sciences' },
    { 'id': 10149, 'value': 'Pakistan Institute of Fashion and Design', 'label': 'Pakistan Institute of Fashion and Design' },
    { 'id': 8525, 'value': 'Pakokku University', 'label': 'Pakokku University' },
    { 'id': 749, 'value': 'Paktia University', 'label': 'Paktia University' },
    { 'id': 6143, 'value': 'Palamuru University', 'label': 'Palamuru University' },
    { 'id': 9938, 'value': 'Palawan State University', 'label': 'Palawan State University' },
    { 'id': 10393, 'value': 'Palestine Ahliya University', 'label': 'Palestine Ahliya University' },
    { 'id': 10394, 'value': 'Palestine College of Nursing', 'label': 'Palestine College of Nursing' },
    { 'id': 10395, 'value': 'Palestine Polytechnic University', 'label': 'Palestine Polytechnic University' },
    { 'id': 10396, 'value': 'Palestine Technical College', 'label': 'Palestine Technical College' },
    { 'id': 10397, 'value': 'Palestine Technical College Arroub', 'label': 'Palestine Technical College Arroub' },
    { 'id': 10398, 'value': 'Palestine Technical University Kadoorie', 'label': 'Palestine Technical University Kadoorie' },
    { 'id': 13222, 'value': 'Palm Beach Atlantic University', 'label': 'Palm Beach Atlantic University' },
    { 'id': 13223, 'value': 'Palm Beach State College', 'label': 'Palm Beach State College' },
    { 'id': 13224, 'value': 'Palo Alto University', 'label': 'Palo Alto University' },
    { 'id': 3718, 'value': 'Palucca Hochschule für Tanz Dresden', 'label': 'Palucca Hochschule für Tanz Dresden' },
    { 'id': 9939, 'value': 'Pamantasan ng Lungsod ng Maynila', 'label': 'Pamantasan ng Lungsod ng Maynila' },
    { 'id': 750, 'value': 'Pamir Institute of Higher Education', 'label': 'Pamir Institute of Higher Education' },
    { 'id': 9414, 'value': 'PAMO University of Medical Sciences', 'label': 'PAMO University of Medical Sciences' },
    { 'id': 11765, 'value': 'Pamukkale Üniversitesi', 'label': 'Pamukkale Üniversitesi' },
    { 'id': 7817, 'value': 'Pan Africa Christian University', 'label': 'Pan Africa Christian University' },
    { 'id': 9415, 'value': 'Pan-Atlantic University', 'label': 'Pan-Atlantic University' },
    { 'id': 6144, 'value': 'Pandit Deendayal Petroleum University', 'label': 'Pandit Deendayal Petroleum University' },
    { 'id': 6145, 'value': 'Pandit Deendayal Upadhyaya Shekhawati University', 'label': 'Pandit Deendayal Upadhyaya Shekhawati University' },
    { 'id': 6146, 'value': 'Pandit Ravishankar Shukla University', 'label': 'Pandit Ravishankar Shukla University' },
    { 'id': 11191, 'value': 'Paneurópska vysoká škola', 'label': 'Paneurópska vysoká škola' },
    { 'id': 8353, 'value': 'Panevežio kolegija', 'label': 'Panevežio kolegija' },
    { 'id': 1168, 'value': 'Panevropski univerzitet Apeiron', 'label': 'Panevropski univerzitet Apeiron' },
    { 'id': 9940, 'value': 'Pangasinan State University', 'label': 'Pangasinan State University' },
    { 'id': 8526, 'value': 'Panglong University', 'label': 'Panglong University' },
    { 'id': 7917, 'value': 'Panha Chiet University', 'label': 'Panha Chiet University' },
    { 'id': 6147, 'value': 'Panjab University', 'label': 'Panjab University' },
    { 'id': 7918, 'value': 'Paññasastra University of Cambodia', 'label': 'Paññasastra University of Cambodia' },
    { 'id': 4918, 'value': 'Pannon Egyetem', 'label': 'Pannon Egyetem' },
    { 'id': 10254, 'value': 'Panstwowa Uczelnia Stanislawa Staszica w Pile', 'label': 'Panstwowa Uczelnia Stanislawa Staszica w Pile' },
    { 'id': 10255, 'value': 'Panstwowa Uczelnia Zawodowa im. Ignacego Moscickiego w Ciechanowie', 'label': 'Panstwowa Uczelnia Zawodowa im. Ignacego Moscickiego w Ciechanowie' },
    { 'id': 10256, 'value': 'Panstwowa Wyzsza Szkola Informatyki i Przedsiebiorczosci w Lomzy', 'label': 'Panstwowa Wyzsza Szkola Informatyki i Przedsiebiorczosci w Lomzy' },
    { 'id': 10257, 'value': 'Panstwowa Wyzsza Szkola Zawodowa w Chelmie', 'label': 'Panstwowa Wyzsza Szkola Zawodowa w Chelmie' },
    { 'id': 10258, 'value': 'Panstwowa Wyzsza Szkola Zawodowa w Walczu', 'label': 'Panstwowa Wyzsza Szkola Zawodowa w Walczu' },
    { 'id': 4748, 'value': 'Panteion University', 'label': 'Panteion University' },
    { 'id': 2653, 'value': 'Panzhihua University', 'label': 'Panzhihua University' },
    { 'id': 9811, 'value': 'Papua New Guinea University of Technology', 'label': 'Papua New Guinea University of Technology' },
    { 'id': 1052, 'value': 'Paracelsus Medizinische Privatuniversität', 'label': 'Paracelsus Medizinische Privatuniversität' },
    { 'id': 7919, 'value': 'Paragon International University', 'label': 'Paragon International University' },
    { 'id': 13225, 'value': 'Pardee RAND Graduate School', 'label': 'Pardee RAND Graduate School' },
    { 'id': 317, 'value': 'Paris 1 Panthéon - Sorbonne', 'label': 'Paris 1 Panthéon - Sorbonne' },
    { 'id': 14394, 'value': 'Paris Junior College', 'label': 'Paris Junior College' },
    { 'id': 13226, 'value': 'Park University', 'label': 'Park University' },
    { 'id': 13227, 'value': 'Parker University', 'label': 'Parker University' },
    { 'id': 9941, 'value': 'Partido State University', 'label': 'Partido State University' },
    { 'id': 6148, 'value': 'Parul University', 'label': 'Parul University' },
    { 'id': 751, 'value': 'Parwan University', 'label': 'Parwan University' },
    { 'id': 9942, 'value': 'Pasig Catholic College', 'label': 'Pasig Catholic College' },
    { 'id': 8527, 'value': 'Pathein University', 'label': 'Pathein University' },
    { 'id': 11433, 'value': 'Pathumthani University', 'label': 'Pathumthani University' },
    { 'id': 11434, 'value': 'Pathumwan Institute of Technology', 'label': 'Pathumwan Institute of Technology' },
    { 'id': 6149, 'value': 'Patna University', 'label': 'Patna University' },
    { 'id': 9943, 'value': 'PATTS College of Aeronautics', 'label': 'PATTS College of Aeronautics' },
    { 'id': 1289, 'value': 'Patuakhali Science and Technology University', 'label': 'Patuakhali Science and Technology University' },
    { 'id': 13228, 'value': 'Paul Quinn College', 'label': 'Paul Quinn College' },
    { 'id': 13229, 'value': 'Paul Smith\'s College', 'label': 'Paul Smith\'s College' },
    { 'id': 9416, 'value': 'Paul University', 'label': 'Paul University' },
    { 'id': 8241, 'value': 'Pavlodar State Pedagogical University', 'label': 'Pavlodar State Pedagogical University' },
    { 'id': 8242, 'value': 'Pavlodar State University', 'label': 'Pavlodar State University' },
    { 'id': 10829, 'value': 'Pavlov First Saint Petersburg State Medical University', 'label': 'Pavlov First Saint Petersburg State Medical University' },
    { 'id': 752, 'value': 'Payam Institute of Higher Education', 'label': 'Payam Institute of Higher Education' },
    { 'id': 11435, 'value': 'Payap University', 'label': 'Payap University' },
    { 'id': 4919, 'value': 'Pázmány Péter Katolikus Egyetem', 'label': 'Pázmány Péter Katolikus Egyetem' },
    { 'id': 6150, 'value': 'PDM University', 'label': 'PDM University' },
    { 'id': 6151, 'value': 'PDPM Indian Institute of Information Technology, Design and Manufacturing', 'label': 'PDPM Indian Institute of Information Technology, Design and Manufacturing' },
    { 'id': 6152, 'value': 'PEC University of Technology', 'label': 'PEC University of Technology' },
    { 'id': 4920, 'value': 'Pécsi Tudományegyetem', 'label': 'Pécsi Tudományegyetem' },
    { 'id': 11510, 'value': 'Pedagogical Institute of Panjakent', 'label': 'Pedagogical Institute of Panjakent' },
    { 'id': 829, 'value': 'Pedagogical Institute of Vardenis', 'label': 'Pedagogical Institute of Vardenis' },
    { 'id': 13230, 'value': 'Peirce College', 'label': 'Peirce College' },
    { 'id': 2654, 'value': 'Peking Union Medical College', 'label': 'Peking Union Medical College' },
    { 'id': 2655, 'value': 'Peking University', 'label': 'Peking University' },
    { 'id': 13231, 'value': 'Penn State College of Medicine', 'label': 'Penn State College of Medicine' },
    { 'id': 13232, 'value': 'Penn State University', 'label': 'Penn State University' },
    { 'id': 13233, 'value': 'Pennsylvania Academy of the Fine Arts', 'label': 'Pennsylvania Academy of the Fine Arts' },
    { 'id': 13234, 'value': 'Pennsylvania College of Art and Design', 'label': 'Pennsylvania College of Art and Design' },
    { 'id': 13235, 'value': 'Pennsylvania College of Health Sciences', 'label': 'Pennsylvania College of Health Sciences' },
    { 'id': 13236, 'value': 'Pennsylvania College of Technology', 'label': 'Pennsylvania College of Technology' },
    { 'id': 13237, 'value': 'Pensacola State College', 'label': 'Pensacola State College' },
    { 'id': 4704, 'value': 'Pentecost University College', 'label': 'Pentecost University College' },
    { 'id': 8689, 'value': 'Pentecostal Life University', 'label': 'Pentecostal Life University' },
    { 'id': 10830, 'value': 'Penza State Agricultural Academy', 'label': 'Penza State Agricultural Academy' },
    { 'id': 10831, 'value': 'Penza State Technological University', 'label': 'Penza State Technological University' },
    { 'id': 10832, 'value': 'Penza State University', 'label': 'Penza State University' },
    { 'id': 10833, 'value': 'Penza State University of Architecture and Construction', 'label': 'Penza State University of Architecture and Construction' },
    { 'id': 2656, 'value': 'People\'s Public Security University of China', 'label': 'People\'s Public Security University of China' },
    { 'id': 6153, 'value': 'People\'s University', 'label': 'People\'s University' },
    { 'id': 10150, 'value': 'Peoples University of Medical and Health Sciences for Women', 'label': 'Peoples University of Medical and Health Sciences for Women' },
    { 'id': 7882, 'value': 'Peoples\' Friendship University n.a. AS. Batyrov', 'label': 'Peoples\' Friendship University n.a. AS. Batyrov' },
    { 'id': 13238, 'value': 'Pepperdine University', 'label': 'Pepperdine University' },
    { 'id': 9247, 'value': 'Perdana University', 'label': 'Perdana University' },
    { 'id': 5548, 'value': 'Peres Academic Center', 'label': 'Peres Academic Center' },
    { 'id': 12107, 'value': 'Pereyaslav-Khmelnitsky State Pedagogical University', 'label': 'Pereyaslav-Khmelnitsky State Pedagogical University' },
    { 'id': 4705, 'value': 'Perez University College', 'label': 'Perez University College' },
    { 'id': 6154, 'value': 'Periyar Maniammai Institute of Science and Technology', 'label': 'Periyar Maniammai Institute of Science and Technology' },
    { 'id': 6155, 'value': 'Periyar University', 'label': 'Periyar University' },
    { 'id': 10834, 'value': 'Perm State Academy of Medicine', 'label': 'Perm State Academy of Medicine' },
    { 'id': 10835, 'value': 'Perm State Pedagogical University', 'label': 'Perm State Pedagogical University' },
    { 'id': 10836, 'value': 'Perm State Technical University', 'label': 'Perm State Technical University' },
    { 'id': 10837, 'value': 'Perm State University', 'label': 'Perm State University' },
    { 'id': 6725, 'value': 'Persian Gulf University', 'label': 'Persian Gulf University' },
    { 'id': 13239, 'value': 'Peru State College', 'label': 'Peru State College' },
    { 'id': 6156, 'value': 'PES University', 'label': 'PES University' },
    { 'id': 753, 'value': 'Peshgam Institute of Higher Education', 'label': 'Peshgam Institute of Higher Education' },
    { 'id': 10838, 'value': 'Peter the Great St.Petersburg Polytechnic University', 'label': 'Peter the Great St.Petersburg Polytechnic University' },
    { 'id': 4645, 'value': 'Petre Shotadze Tbilisi Medical Academy', 'label': 'Petre Shotadze Tbilisi Medical Academy' },
    { 'id': 6726, 'value': 'Petroleum University of Technology', 'label': 'Petroleum University of Technology' },
    { 'id': 10839, 'value': 'Petrozavodsk State Conservatory', 'label': 'Petrozavodsk State Conservatory' },
    { 'id': 10840, 'value': 'Petrozavodsk State University', 'label': 'Petrozavodsk State University' },
    { 'id': 13240, 'value': 'Pfeiffer University', 'label': 'Pfeiffer University' },
    { 'id': 4077, 'value': 'Pharos University in Alexandria', 'label': 'Pharos University in Alexandria' },
    { 'id': 11436, 'value': 'Phetchabun Rajabhat University', 'label': 'Phetchabun Rajabhat University' },
    { 'id': 11437, 'value': 'Phetchaburi Rajabhat University', 'label': 'Phetchaburi Rajabhat University' },
    { 'id': 7054, 'value': 'Philadelphia University', 'label': 'Philadelphia University' },
    { 'id': 13241, 'value': 'Philander Smith College', 'label': 'Philander Smith College' },
    { 'id': 9944, 'value': 'Philippine Christian University', 'label': 'Philippine Christian University' },
    { 'id': 9945, 'value': 'Philippine Normal University', 'label': 'Philippine Normal University' },
    { 'id': 9946, 'value': 'Philippine School of Business Administration', 'label': 'Philippine School of Business Administration' },
    { 'id': 3719, 'value': 'Philipps-Universität Marburg', 'label': 'Philipps-Universität Marburg' },
    { 'id': 13242, 'value': 'Phillips Graduate University', 'label': 'Phillips Graduate University' },
    { 'id': 3720, 'value': 'Philosophisch-Theologische Hochschule Münster', 'label': 'Philosophisch-Theologische Hochschule Münster' },
    { 'id': 3721, 'value': 'Philosophisch-Theologische Hochschule Sankt Georgen', 'label': 'Philosophisch-Theologische Hochschule Sankt Georgen' },
    { 'id': 3722, 'value': 'Philosophisch-Theologische Hochschule SVD St. Augustin', 'label': 'Philosophisch-Theologische Hochschule SVD St. Augustin' },
    { 'id': 3723, 'value': 'Philosophisch-Theologische Hochschule Vallendar', 'label': 'Philosophisch-Theologische Hochschule Vallendar' },
    { 'id': 11438, 'value': 'Phitsanulok University', 'label': 'Phitsanulok University' },
    { 'id': 7920, 'value': 'Phnom Penh Institute of Technology', 'label': 'Phnom Penh Institute of Technology' },
    { 'id': 7921, 'value': 'Phnom Penh International University', 'label': 'Phnom Penh International University' },
    { 'id': 11439, 'value': 'Phranakhon Rajabhat University', 'label': 'Phranakhon Rajabhat University' },
    { 'id': 11440, 'value': 'Phranakhon Si Ayutthaya Rajabhat University', 'label': 'Phranakhon Si Ayutthaya Rajabhat University' },
    { 'id': 11441, 'value': 'Phuket Rajabhat University', 'label': 'Phuket Rajabhat University' },
    { 'id': 14153, 'value': 'Phuong Dong University', 'label': 'Phuong Dong University' },
    { 'id': 11442, 'value': 'Pibulsongkram Rajabhat University', 'label': 'Pibulsongkram Rajabhat University' },
    { 'id': 13243, 'value': 'Piedmont College', 'label': 'Piedmont College' },
    { 'id': 13244, 'value': 'Piedmont International University', 'label': 'Piedmont International University' },
    { 'id': 1517, 'value': 'PIGIER Benin', 'label': 'PIGIER Benin' },
    { 'id': 13245, 'value': 'Pillar College', 'label': 'Pillar College' },
    { 'id': 10151, 'value': 'PIMSAT Institute of Higher Education', 'label': 'PIMSAT Institute of Higher Education' },
    { 'id': 13246, 'value': 'Pine Manor College', 'label': 'Pine Manor College' },
    { 'id': 2657, 'value': 'Pingdingshan University', 'label': 'Pingdingshan University' },
    { 'id': 2658, 'value': 'Pingxiang University', 'label': 'Pingxiang University' },
    { 'id': 7818, 'value': 'Pioneer International University', 'label': 'Pioneer International University' },
    { 'id': 10152, 'value': 'Pir Mehr Ali Shah Arid Agriculture University', 'label': 'Pir Mehr Ali Shah Arid Agriculture University' },
    { 'id': 11766, 'value': 'Piri Reis Üniversitesi', 'label': 'Piri Reis Üniversitesi' },
    { 'id': 13247, 'value': 'Pittsburg State University', 'label': 'Pittsburg State University' },
    { 'id': 13248, 'value': 'Pitzer College', 'label': 'Pitzer College' },
    { 'id': 6157, 'value': 'Plastindia International University', 'label': 'Plastindia International University' },
    { 'id': 9417, 'value': 'Plateau State University', 'label': 'Plateau State University' },
    { 'id': 7883, 'value': 'Plato University of Management and Design', 'label': 'Plato University of Management and Design' },
    { 'id': 13249, 'value': 'Plaza College', 'label': 'Plaza College' },
    { 'id': 10841, 'value': 'Plekhanov Russian University of Economics', 'label': 'Plekhanov Russian University of Economics' },
    { 'id': 4541, 'value': 'Plymouth College of Art', 'label': 'Plymouth College of Art' },
    { 'id': 13250, 'value': 'Plymouth State University', 'label': 'Plymouth State University' },
    { 'id': 9812, 'value': 'PNG University of Natural Resources and Environment', 'label': 'PNG University of Natural Resources and Environment' },
    { 'id': 8102, 'value': 'Pohang University of Science and Technology', 'label': 'Pohang University of Science and Technology' },
    { 'id': 13251, 'value': 'Point Loma Nazarene University', 'label': 'Point Loma Nazarene University' },
    { 'id': 13252, 'value': 'Point Park University', 'label': 'Point Park University' },
    { 'id': 13253, 'value': 'Point University', 'label': 'Point University' },
    { 'id': 9597, 'value': 'Pokhara University', 'label': 'Pokhara University' },
    { 'id': 1838, 'value': 'Polessky State University', 'label': 'Polessky State University' },
    { 'id': 297, 'value': 'PoliDesign - POLIMI', 'label': 'PoliDesign - POLIMI' },
    { 'id': 10259, 'value': 'Politechnika Bialostocka', 'label': 'Politechnika Bialostocka' },
    { 'id': 10260, 'value': 'Politechnika Czestochowska', 'label': 'Politechnika Czestochowska' },
    { 'id': 10261, 'value': 'Politechnika Gdanska', 'label': 'Politechnika Gdanska' },
    { 'id': 10262, 'value': 'Politechnika Koszalinska', 'label': 'Politechnika Koszalinska' },
    { 'id': 10263, 'value': 'Politechnika Krakowska im. Tadeusza Kosciuszki', 'label': 'Politechnika Krakowska im. Tadeusza Kosciuszki' },
    { 'id': 10264, 'value': 'Politechnika Lódzka', 'label': 'Politechnika Lódzka' },
    { 'id': 10265, 'value': 'Politechnika Lubelska', 'label': 'Politechnika Lubelska' },
    { 'id': 10266, 'value': 'Politechnika Opolska', 'label': 'Politechnika Opolska' },
    { 'id': 10267, 'value': 'Politechnika Poznanska', 'label': 'Politechnika Poznanska' },
    { 'id': 10268, 'value': 'Politechnika Rzeszowska im. Ignacego Lukasiewicza', 'label': 'Politechnika Rzeszowska im. Ignacego Lukasiewicza' },
    { 'id': 10269, 'value': 'Politechnika Slaska', 'label': 'Politechnika Slaska' },
    { 'id': 10270, 'value': 'Politechnika Swietokrzyska w Kielcach', 'label': 'Politechnika Swietokrzyska w Kielcach' },
    { 'id': 10271, 'value': 'Politechnika Warszawska', 'label': 'Politechnika Warszawska' },
    { 'id': 10272, 'value': 'Politechnika Wroclawska', 'label': 'Politechnika Wroclawska' },
    { 'id': 3143, 'value': 'Politécnico Colombiano Jaime Isaza Cadavid', 'label': 'Politécnico Colombiano Jaime Isaza Cadavid' },
    { 'id': 13975, 'value': 'Politécnico de Punta del Este', 'label': 'Politécnico de Punta del Este' },
    { 'id': 6871, 'value': 'Politecnico di Bari', 'label': 'Politecnico di Bari' },
    { 'id': 95, 'value': 'Politecnico di Milano', 'label': 'Politecnico di Milano' },
    { 'id': 18, 'value': 'Politecnico di Torino', 'label': 'Politecnico di Torino' },
    { 'id': 3144, 'value': 'Politécnico Grancolombiano', 'label': 'Politécnico Grancolombiano' },
    { 'id': 1528, 'value': 'Politeknik Brunei', 'label': 'Politeknik Brunei' },
    { 'id': 13254, 'value': 'Polk State College', 'label': 'Polk State College' },
    { 'id': 339, 'value': 'Polo Michelangelo', 'label': 'Polo Michelangelo' },
    { 'id': 1839, 'value': 'Polotsk State University', 'label': 'Polotsk State University' },
    { 'id': 10273, 'value': 'Polsko-Japonska Akademia Technik Komputerowych', 'label': 'Polsko-Japonska Akademia Technik Komputerowych' },
    { 'id': 12108, 'value': 'Poltava State Agrarian Academy', 'label': 'Poltava State Agrarian Academy' },
    { 'id': 12109, 'value': 'Poltava V. G. Korolenko National Pedagogical University', 'label': 'Poltava V. G. Korolenko National Pedagogical University' },
    { 'id': 11285, 'value': 'Polytechnic College Suriname', 'label': 'Polytechnic College Suriname' },
    { 'id': 9947, 'value': 'Polytechnic University of the Philippines', 'label': 'Polytechnic University of the Philippines' },
    { 'id': 10842, 'value': 'Polzunov Altai State Technical University', 'label': 'Polzunov Altai State Technical University' },
    { 'id': 13255, 'value': 'Pomona College', 'label': 'Pomona College' },
    { 'id': 10274, 'value': 'Pomorski Uniwersytet Medyczny w Szczecinie', 'label': 'Pomorski Uniwersytet Medyczny w Szczecinie' },
    { 'id': 10354, 'value': 'Ponce Health Sciences University', 'label': 'Ponce Health Sciences University' },
    { 'id': 6158, 'value': 'Pondicherry University', 'label': 'Pondicherry University' },
    { 'id': 6159, 'value': 'Ponnaiyan Ramajayam Institute of Science and Technology', 'label': 'Ponnaiyan Ramajayam Institute of Science and Technology' },
    { 'id': 13256, 'value': 'Pontifical Faculty of the Immaculate Conception', 'label': 'Pontifical Faculty of the Immaculate Conception' },
    { 'id': 14047, 'value': 'Pontificia Facoltà di Scienze dell\'Educazione Auxilium', 'label': 'Pontificia Facoltà di Scienze dell\'Educazione Auxilium' },
    { 'id': 889, 'value': 'Pontificia Universidad Católica Argentina', 'label': 'Pontificia Universidad Católica Argentina' },
    { 'id': 2032, 'value': 'Pontificia Universidad Católica de Chile', 'label': 'Pontificia Universidad Católica de Chile' },
    { 'id': 10355, 'value': 'Pontificia Universidad Catolica de Puerto Rico', 'label': 'Pontificia Universidad Catolica de Puerto Rico' },
    { 'id': 2033, 'value': 'Pontificia Universidad Católica de Valparaíso', 'label': 'Pontificia Universidad Católica de Valparaíso' },
    { 'id': 3971, 'value': 'Pontificia Universidad Católica del Ecuador', 'label': 'Pontificia Universidad Católica del Ecuador' },
    { 'id': 9671, 'value': 'Pontificia Universidad Católica del Perú', 'label': 'Pontificia Universidad Católica del Perú' },
    { 'id': 3856, 'value': 'Pontificia Universidad Católica Madre y Maestra', 'label': 'Pontificia Universidad Católica Madre y Maestra' },
    { 'id': 3145, 'value': 'Pontificia Universidad Javeriana', 'label': 'Pontificia Universidad Javeriana' },
    { 'id': 1584, 'value': 'Pontifícia Universidade Católica de Campinas', 'label': 'Pontifícia Universidade Católica de Campinas' },
    { 'id': 1585, 'value': 'Pontifícia Universidade Católica de Goiás', 'label': 'Pontifícia Universidade Católica de Goiás' },
    { 'id': 1586, 'value': 'Pontifícia Universidade Católica de Minas Gerais', 'label': 'Pontifícia Universidade Católica de Minas Gerais' },
    { 'id': 1587, 'value': 'Pontifícia Universidade Católica de São Paulo', 'label': 'Pontifícia Universidade Católica de São Paulo' },
    { 'id': 1588, 'value': 'Pontifícia Universidade Católica do Paraná', 'label': 'Pontifícia Universidade Católica do Paraná' },
    { 'id': 1589, 'value': 'Pontifícia Universidade Católica do Rio de Janeiro', 'label': 'Pontifícia Universidade Católica do Rio de Janeiro' },
    { 'id': 1590, 'value': 'Pontifícia Universidade Católica do Rio Grande do Sul', 'label': 'Pontifícia Universidade Católica do Rio Grande do Sul' },
    { 'id': 14048, 'value': 'Pontificia Università Antonianum', 'label': 'Pontificia Università Antonianum' },
    { 'id': 14049, 'value': 'Pontificia Università della Santa Croce', 'label': 'Pontificia Università della Santa Croce' },
    { 'id': 14050, 'value': 'Pontificia Università Gregoriana', 'label': 'Pontificia Università Gregoriana' },
    { 'id': 14051, 'value': 'Pontificia Università Lateranense', 'label': 'Pontificia Università Lateranense' },
    { 'id': 14052, 'value': 'Pontificia Università San Tommaso d\'Aquino', 'label': 'Pontificia Università San Tommaso d\'Aquino' },
    { 'id': 14053, 'value': 'Pontificia Università Urbaniana', 'label': 'Pontificia Università Urbaniana' },
    { 'id': 7528, 'value': 'Poole Gakuin University', 'label': 'Poole Gakuin University' },
    { 'id': 6160, 'value': 'Poornima University', 'label': 'Poornima University' },
    { 'id': 1290, 'value': 'Port City International University', 'label': 'Port City International University' },
    { 'id': 4078, 'value': 'Port Said University', 'label': 'Port Said University' },
    { 'id': 13257, 'value': 'Portland State University', 'label': 'Portland State University' },
    { 'id': 4825, 'value': 'Poslovno veleucilište Zagreb', 'label': 'Poslovno veleucilište Zagreb' },
    { 'id': 6161, 'value': 'Post Graduate Institute of Medical Education and Research', 'label': 'Post Graduate Institute of Medical Education and Research' },
    { 'id': 13258, 'value': 'Post University', 'label': 'Post University' },
    { 'id': 6162, 'value': 'Potti Sreeramulu Telugu University', 'label': 'Potti Sreeramulu Telugu University' },
    { 'id': 6163, 'value': 'Pragyan International University', 'label': 'Pragyan International University' },
    { 'id': 13259, 'value': 'Prairie View A&M University', 'label': 'Prairie View A&M University' },
    { 'id': 6164, 'value': 'Pratap University', 'label': 'Pratap University' },
    { 'id': 14412, 'value': 'Pratica annullata', 'label': 'Pratica annullata' },
    { 'id': 13260, 'value': 'Pratt Institute', 'label': 'Pratt Institute' },
    { 'id': 6165, 'value': 'Pravara Institute of Medical Sciences', 'label': 'Pravara Institute of Medical Sciences' },
    { 'id': 3412, 'value': 'Pražská vysoká škola psychosociálních studií', 'label': 'Pražská vysoká škola psychosociálních studií' },
    { 'id': 7922, 'value': 'Preah Sihanouk Raja Buddhist University', 'label': 'Preah Sihanouk Raja Buddhist University' },
    { 'id': 12110, 'value': 'Precarpathian National University', 'label': 'Precarpathian National University' },
    { 'id': 9418, 'value': 'Precious Cornerstone University', 'label': 'Precious Cornerstone University' },
    { 'id': 7529, 'value': 'Prefectural University of Hiroshima', 'label': 'Prefectural University of Hiroshima' },
    { 'id': 7530, 'value': 'Prefectural University of Kumamoto', 'label': 'Prefectural University of Kumamoto' },
    { 'id': 7923, 'value': 'Prek Leap National College of Agriculture', 'label': 'Prek Leap National College of Agriculture' },
    { 'id': 1291, 'value': 'Premier University', 'label': 'Premier University' },
    { 'id': 13261, 'value': 'Presbyterian College', 'label': 'Presbyterian College' },
    { 'id': 8103, 'value': 'Presbyterian University and Theological Seminary', 'label': 'Presbyterian University and Theological Seminary' },
    { 'id': 4706, 'value': 'Presbyterian University College', 'label': 'Presbyterian University College' },
    { 'id': 13262, 'value': 'Prescott College', 'label': 'Prescott College' },
    { 'id': 13263, 'value': 'Presentation College', 'label': 'Presentation College' },
    { 'id': 6166, 'value': 'Presidency University', 'label': 'Presidency University' },
    { 'id': 1292, 'value': 'Presidency University, Bangladesh', 'label': 'Presidency University, Bangladesh' },
    { 'id': 11192, 'value': 'Prešovská univerzita v Prešove', 'label': 'Prešovská univerzita v Prešove' },
    { 'id': 10153, 'value': 'Preston University', 'label': 'Preston University' },
    { 'id': 1293, 'value': 'Prime University', 'label': 'Prime University' },
    { 'id': 1294, 'value': 'Primeasia University', 'label': 'Primeasia University' },
    { 'id': 11040, 'value': 'Prince Mohammad Bin Fahd University', 'label': 'Prince Mohammad Bin Fahd University' },
    { 'id': 11443, 'value': 'Prince of Songkla University', 'label': 'Prince of Songkla University' },
    { 'id': 11041, 'value': 'Prince Sattam Bin Abdulaziz University', 'label': 'Prince Sattam Bin Abdulaziz University' },
    { 'id': 11042, 'value': 'Prince Sultan University', 'label': 'Prince Sultan University' },
    { 'id': 11444, 'value': 'Princess Galyani Vadhana Institute of Music', 'label': 'Princess Galyani Vadhana Institute of Music' },
    { 'id': 11043, 'value': 'Princess Nora bint Abdulrahman University', 'label': 'Princess Nora bint Abdulrahman University' },
    { 'id': 11445, 'value': 'Princess of Naradhiwas University', 'label': 'Princess of Naradhiwas University' },
    { 'id': 7055, 'value': 'Princess Sumaya University for Technology', 'label': 'Princess Sumaya University for Technology' },
    { 'id': 13264, 'value': 'Princeton University', 'label': 'Princeton University' },
    { 'id': 13265, 'value': 'Principia College', 'label': 'Principia College' },
    { 'id': 3724, 'value': 'Private Hochschule für Wirtschaft und Technik', 'label': 'Private Hochschule für Wirtschaft und Technik' },
    { 'id': 3725, 'value': 'Private Hochschule Göttingen', 'label': 'Private Hochschule Göttingen' },
    { 'id': 1053, 'value': 'Private Pädagogische Hochschule Burgenland', 'label': 'Private Pädagogische Hochschule Burgenland' },
    { 'id': 1054, 'value': 'Private Pädagogische Hochschule der Diözese Linz', 'label': 'Private Pädagogische Hochschule der Diözese Linz' },
    { 'id': 1055, 'value': 'Private Universität für Gesundheitswissenschaften, Medizinische Informatik und Technik', 'label': 'Private Universität für Gesundheitswissenschaften, Medizinische Informatik und Technik' },
    { 'id': 8308, 'value': 'Private Universität im fürstentum Liechtenstein', 'label': 'Private Universität im fürstentum Liechtenstein' },
    { 'id': 1056, 'value': 'Privatuniversität Schloss Seeburg', 'label': 'Privatuniversität Schloss Seeburg' },
    { 'id': 10843, 'value': 'Privolzhsky Research Medical University', 'label': 'Privolzhsky Research Medical University' },
    { 'id': 6167, 'value': 'Professor Jayashankar Telangana State Agricultural University', 'label': 'Professor Jayashankar Telangana State Agricultural University' },
    { 'id': 830, 'value': 'Progress University of Gyumri', 'label': 'Progress University of Gyumri' },
    { 'id': 11004, 'value': 'Protestant Institute of Arts and Social Sciences', 'label': 'Protestant Institute of Arts and Social Sciences' },
    { 'id': 3726, 'value': 'Provadis Hochschule', 'label': 'Provadis Hochschule' },
    { 'id': 13266, 'value': 'Providence Christian College', 'label': 'Providence Christian College' },
    { 'id': 13267, 'value': 'Providence College', 'label': 'Providence College' },
    { 'id': 11910, 'value': 'Providence University', 'label': 'Providence University' },
    { 'id': 12111, 'value': 'Pryazovskyi State Technical University', 'label': 'Pryazovskyi State Technical University' },
    { 'id': 12112, 'value': 'Prydniprovska State Academy of Civil Engineering and Architecture', 'label': 'Prydniprovska State Academy of Civil Engineering and Architecture' },
    { 'id': 10844, 'value': 'Pskov State Pedagogical University', 'label': 'Pskov State Pedagogical University' },
    { 'id': 185, 'value': 'PSP - Paris School of Business', 'label': 'PSP - Paris School of Business' },
    { 'id': 3727, 'value': 'Psychologische Hochschule Berlin', 'label': 'Psychologische Hochschule Berlin' },
    { 'id': 6168, 'value': 'Pt. Bhagwat Dayal Sharma University of Health Sciences', 'label': 'Pt. Bhagwat Dayal Sharma University of Health Sciences' },
    { 'id': 2659, 'value': 'Puer University', 'label': 'Puer University' },
    { 'id': 8104, 'value': 'Pukyong National University', 'label': 'Pukyong National University' },
    { 'id': 1295, 'value': 'Pundra University of Science and Technology', 'label': 'Pundra University of Science and Technology' },
    { 'id': 6169, 'value': 'Punjab Agricultural University', 'label': 'Punjab Agricultural University' },
    { 'id': 6170, 'value': 'Punjab Technical University', 'label': 'Punjab Technical University' },
    { 'id': 6171, 'value': 'Punjabi University Patiala', 'label': 'Punjabi University Patiala' },
    { 'id': 9598, 'value': 'Purbanchal University', 'label': 'Purbanchal University' },
    { 'id': 13268, 'value': 'Purchase College, State University of New York', 'label': 'Purchase College, State University of New York' },
    { 'id': 13269, 'value': 'Purdue University', 'label': 'Purdue University' },
    { 'id': 13270, 'value': 'Purdue University Fort Wayne', 'label': 'Purdue University Fort Wayne' },
    { 'id': 13271, 'value': 'Purdue University Global', 'label': 'Purdue University Global' },
    { 'id': 13272, 'value': 'Purdue University Northwest', 'label': 'Purdue University Northwest' },
    { 'id': 8105, 'value': 'Pusan National University', 'label': 'Pusan National University' },
    { 'id': 10845, 'value': 'Pushchino State Institute of Natural Science', 'label': 'Pushchino State Institute of Natural Science' },
    { 'id': 10846, 'value': 'Pushkin State Russian Language Institute', 'label': 'Pushkin State Russian Language Institute' },
    { 'id': 2660, 'value': 'Putian University', 'label': 'Putian University' },
    { 'id': 7819, 'value': 'Pwani University', 'label': 'Pwani University' },
    { 'id': 10847, 'value': 'Pyatigorsk State Pharmaceutical Academy', 'label': 'Pyatigorsk State Pharmaceutical Academy' },
    { 'id': 10848, 'value': 'Pyatigorsk State University', 'label': 'Pyatigorsk State University' },
    { 'id': 8528, 'value': 'Pyay Technological University', 'label': 'Pyay Technological University' },
    { 'id': 8106, 'value': 'Pyeongtaek University', 'label': 'Pyeongtaek University' },
    { 'id': 7955, 'value': 'Pyongyang Medical University', 'label': 'Pyongyang Medical University' },
    { 'id': 7956, 'value': 'Pyongyang University of Agriculture', 'label': 'Pyongyang University of Agriculture' },
    { 'id': 7957, 'value': 'Pyongyang University of Architecture and Building Materials', 'label': 'Pyongyang University of Architecture and Building Materials' },
    { 'id': 7958, 'value': 'Pyongyang University of Foreign Studies', 'label': 'Pyongyang University of Foreign Studies' },
    { 'id': 7959, 'value': 'Pyongyang University of Music and Dance', 'label': 'Pyongyang University of Music and Dance' },
    { 'id': 7960, 'value': 'Pyongyang University of Printing Engineering', 'label': 'Pyongyang University of Printing Engineering' },
    { 'id': 7961, 'value': 'Pyongyang University of Railways', 'label': 'Pyongyang University of Railways' },
    { 'id': 7962, 'value': 'Pyongyang University of Science and Technology', 'label': 'Pyongyang University of Science and Technology' },
    { 'id': 754, 'value': 'Qalam Institute of Higher Education', 'label': 'Qalam Institute of Higher Education' },
    { 'id': 14009, 'value': 'Qarshi Davlat Universiteti', 'label': 'Qarshi Davlat Universiteti' },
    { 'id': 14010, 'value': 'Qarshi Muhandislik Iqtisodiyot Instituti', 'label': 'Qarshi Muhandislik Iqtisodiyot Instituti' },
    { 'id': 10154, 'value': 'Qarshi University', 'label': 'Qarshi University' },
    { 'id': 11044, 'value': 'Qassim University', 'label': 'Qassim University' },
    { 'id': 11349, 'value': 'Qasyuon University for Science and Technology', 'label': 'Qasyuon University for Science and Technology' },
    { 'id': 10512, 'value': 'Qatar University', 'label': 'Qatar University' },
    { 'id': 6727, 'value': 'Qazvin University of Medical Sciences', 'label': 'Qazvin University of Medical Sciences' },
    { 'id': 1151, 'value': 'Qerb Kaspi Universiteti', 'label': 'Qerb Kaspi Universiteti' },
    { 'id': 2661, 'value': 'Qiannan Normal College for Nationalities', 'label': 'Qiannan Normal College for Nationalities' },
    { 'id': 2662, 'value': 'Qilu Institute of Technology', 'label': 'Qilu Institute of Technology' },
    { 'id': 2663, 'value': 'Qilu Medical University', 'label': 'Qilu Medical University' },
    { 'id': 2664, 'value': 'Qilu Normal University', 'label': 'Qilu Normal University' },
    { 'id': 2665, 'value': 'Qilu University of Technology', 'label': 'Qilu University of Technology' },
    { 'id': 2666, 'value': 'Qingdao Agricultural University', 'label': 'Qingdao Agricultural University' },
    { 'id': 2667, 'value': 'Qingdao Binhai University', 'label': 'Qingdao Binhai University' },
    { 'id': 2668, 'value': 'Qingdao Hengxing University of Science and Technology', 'label': 'Qingdao Hengxing University of Science and Technology' },
    { 'id': 2669, 'value': 'Qingdao Huanghai University', 'label': 'Qingdao Huanghai University' },
    { 'id': 2670, 'value': 'Qingdao University', 'label': 'Qingdao University' },
    { 'id': 2671, 'value': 'Qingdao University of Science and Technology', 'label': 'Qingdao University of Science and Technology' },
    { 'id': 2672, 'value': 'Qingdao University of Technology', 'label': 'Qingdao University of Technology' },
    { 'id': 2673, 'value': 'Qinghai Normal University', 'label': 'Qinghai Normal University' },
    { 'id': 2674, 'value': 'Qinghai University', 'label': 'Qinghai University' },
    { 'id': 2675, 'value': 'Qinghai University for Nationalities', 'label': 'Qinghai University for Nationalities' },
    { 'id': 2676, 'value': 'Qinzhou University', 'label': 'Qinzhou University' },
    { 'id': 2677, 'value': 'Qiongtai Normal University', 'label': 'Qiongtai Normal University' },
    { 'id': 2678, 'value': 'Qiqihar Institute of Engineering', 'label': 'Qiqihar Institute of Engineering' },
    { 'id': 2679, 'value': 'Qiqihar Medical University', 'label': 'Qiqihar Medical University' },
    { 'id': 2680, 'value': 'Qiqihar University', 'label': 'Qiqihar University' },
    { 'id': 14011, 'value': 'Qo\'qon Davlat Pedagogika Instituti', 'label': 'Qo\'qon Davlat Pedagogika Instituti' },
    { 'id': 6728, 'value': 'Qom University of Medical Sciences', 'label': 'Qom University of Medical Sciences' },
    { 'id': 6729, 'value': 'Qom University of Technology', 'label': 'Qom University of Technology' },
    { 'id': 14012, 'value': 'Qoraqalpoq Davlat Universiteti', 'label': 'Qoraqalpoq Davlat Universiteti' },
    { 'id': 3728, 'value': 'Quadriga Hochschule Berlin', 'label': 'Quadriga Hochschule Berlin' },
    { 'id': 10155, 'value': 'Quaid-e-Awam University of Engineering, Science and Technology', 'label': 'Quaid-e-Awam University of Engineering, Science and Technology' },
    { 'id': 10156, 'value': 'Quaid-i-Azam University', 'label': 'Quaid-i-Azam University' },
    { 'id': 9652, 'value': 'Quality Leadership University', 'label': 'Quality Leadership University' },
    { 'id': 6172, 'value': 'Quantum University', 'label': 'Quantum University' },
    { 'id': 2681, 'value': 'Quanzhou Institute of Information Engineering', 'label': 'Quanzhou Institute of Information Engineering' },
    { 'id': 2682, 'value': 'Quanzhou Normal University', 'label': 'Quanzhou Normal University' },
    { 'id': 39, 'value': 'Quasar Design University', 'label': 'Quasar Design University' },
    { 'id': 6935, 'value': 'Quasar progetto S.R.L. - ROMA', 'label': 'Quasar progetto S.R.L. - ROMA' },
    { 'id': 6730, 'value': 'Quchan University of Advanced Technology', 'label': 'Quchan University of Advanced Technology' },
    { 'id': 755, 'value': 'Quds Higher Education Institute', 'label': 'Quds Higher Education Institute' },
    { 'id': 14207, 'value': 'Queen Arwa University', 'label': 'Queen Arwa University' },
    { 'id': 4542, 'value': 'Queen Margaret University', 'label': 'Queen Margaret University' },
    { 'id': 187, 'value': 'Queen Mary University of London', 'label': 'Queen Mary University of London' },
    { 'id': 358, 'value': 'Queen\'s University', 'label': 'Queen\'s University' },
    { 'id': 4543, 'value': 'Queen\'s University Belfast', 'label': 'Queen\'s University Belfast' },
    { 'id': 13273, 'value': 'Queens College, City University of New York', 'label': 'Queens College, City University of New York' },
    { 'id': 1296, 'value': 'Queens University', 'label': 'Queens University' },
    { 'id': 13274, 'value': 'Queens University of Charlotte', 'label': 'Queens University of Charlotte' },
    { 'id': 560, 'value': 'Queensland University of Technology', 'label': 'Queensland University of Technology' },
    { 'id': 9248, 'value': 'Quest International University Perak', 'label': 'Quest International University Perak' },
    { 'id': 1890, 'value': 'Quest University Canada', 'label': 'Quest University Canada' },
    { 'id': 2683, 'value': 'Qufu Normal University', 'label': 'Qufu Normal University' },
    { 'id': 13275, 'value': 'Quincy University', 'label': 'Quincy University' },
    { 'id': 13276, 'value': 'Quinnipiac University', 'label': 'Quinnipiac University' },
    { 'id': 9948, 'value': 'Quirino State University', 'label': 'Quirino State University' },
    { 'id': 2684, 'value': 'Qujing Normal University', 'label': 'Qujing Normal University' },
    { 'id': 10157, 'value': 'Qurtaba University', 'label': 'Qurtaba University' },
    { 'id': 14154, 'value': 'Quy Nhon University', 'label': 'Quy Nhon University' },
    { 'id': 2685, 'value': 'Quzhou University', 'label': 'Quzhou University' },
    { 'id': 756, 'value': 'Rabia Balkhi Institute of Higher Education', 'label': 'Rabia Balkhi Institute of Higher Education' },
    { 'id': 6173, 'value': 'Rabindra Bharati University', 'label': 'Rabindra Bharati University' },
    { 'id': 1297, 'value': 'Rabindra Maitree University', 'label': 'Rabindra Maitree University' },
    { 'id': 1298, 'value': 'Rabindra University, Bangladesh', 'label': 'Rabindra University, Bangladesh' },
    { 'id': 6174, 'value': 'Rabindranath Tagore University', 'label': 'Rabindranath Tagore University' },
    { 'id': 9546, 'value': 'Radboud Universiteit', 'label': 'Radboud Universiteit' },
    { 'id': 476, 'value': 'Radboud University', 'label': 'Radboud University' },
    { 'id': 13277, 'value': 'Radford University', 'label': 'Radford University' },
    { 'id': 4707, 'value': 'Radford University College', 'label': 'Radford University College' },
    { 'id': 7820, 'value': 'RAF International University', 'label': 'RAF International University' },
    { 'id': 6175, 'value': 'Raffles University', 'label': 'Raffles University' },
    { 'id': 8295, 'value': 'Rafik Hariri University', 'label': 'Rafik Hariri University' },
    { 'id': 6731, 'value': 'Rafsanjan University of Medical Sciences', 'label': 'Rafsanjan University of Medical Sciences' },
    { 'id': 757, 'value': 'Rah-e-Saadat University', 'label': 'Rah-e-Saadat University' },
    { 'id': 758, 'value': 'Rahnaward Institute of Higher Education', 'label': 'Rahnaward Institute of Higher Education' },
    { 'id': 6176, 'value': 'Rai Technology University', 'label': 'Rai Technology University' },
    { 'id': 6177, 'value': 'Rai University', 'label': 'Rai University' },
    { 'id': 6178, 'value': 'Raiganj University', 'label': 'Raiganj University' },
    { 'id': 14427, 'value': 'Rail Academy', 'label': 'Rail Academy' },
    { 'id': 6179, 'value': 'Raj Rishi Bharthari Matsya University', 'label': 'Raj Rishi Bharthari Matsya University' },
    { 'id': 6180, 'value': 'Raja Mansingh Tomar Music and Arts University', 'label': 'Raja Mansingh Tomar Music and Arts University' },
    { 'id': 11446, 'value': 'Rajabhat Maha Sarakham University', 'label': 'Rajabhat Maha Sarakham University' },
    { 'id': 11447, 'value': 'Rajamangala University of Technology Isarn', 'label': 'Rajamangala University of Technology Isarn' },
    { 'id': 11448, 'value': 'Rajamangala University of Technology Krungthep', 'label': 'Rajamangala University of Technology Krungthep' },
    { 'id': 11449, 'value': 'Rajamangala University of Technology Lanna', 'label': 'Rajamangala University of Technology Lanna' },
    { 'id': 11450, 'value': 'Rajamangala University of Technology Phra Nakhon', 'label': 'Rajamangala University of Technology Phra Nakhon' },
    { 'id': 11451, 'value': 'Rajamangala University of Technology Rattanakosin', 'label': 'Rajamangala University of Technology Rattanakosin' },
    { 'id': 11452, 'value': 'Rajamangala University of Technology Srivijaya', 'label': 'Rajamangala University of Technology Srivijaya' },
    { 'id': 11453, 'value': 'Rajamangala University of Technology Suvarnabhumi', 'label': 'Rajamangala University of Technology Suvarnabhumi' },
    { 'id': 11454, 'value': 'Rajamangala University of Technology Tawan-ok', 'label': 'Rajamangala University of Technology Tawan-ok' },
    { 'id': 11455, 'value': 'Rajamangala University of Technology Thanyaburi', 'label': 'Rajamangala University of Technology Thanyaburi' },
    { 'id': 11456, 'value': 'Rajanagarindra Rajabhat University', 'label': 'Rajanagarindra Rajabhat University' },
    { 'id': 11457, 'value': 'Rajapruk University', 'label': 'Rajapruk University' },
    { 'id': 8313, 'value': 'Rajarata University of Sri Lanka', 'label': 'Rajarata University of Sri Lanka' },
    { 'id': 6181, 'value': 'Rajasthan Technical University Kota', 'label': 'Rajasthan Technical University Kota' },
    { 'id': 6182, 'value': 'Rajasthan University of Health Sciences', 'label': 'Rajasthan University of Health Sciences' },
    { 'id': 6183, 'value': 'Rajasthan University of Veterinary and Animal Sciences', 'label': 'Rajasthan University of Veterinary and Animal Sciences' },
    { 'id': 7963, 'value': 'Rajin University of Marine Transport', 'label': 'Rajin University of Marine Transport' },
    { 'id': 6184, 'value': 'Rajiv Gandhi Institute of Petroleum Technology', 'label': 'Rajiv Gandhi Institute of Petroleum Technology' },
    { 'id': 6185, 'value': 'Rajiv Gandhi National Aviation University', 'label': 'Rajiv Gandhi National Aviation University' },
    { 'id': 6186, 'value': 'Rajiv Gandhi National Institute of Youth Development', 'label': 'Rajiv Gandhi National Institute of Youth Development' },
    { 'id': 6187, 'value': 'Rajiv Gandhi National University of Law', 'label': 'Rajiv Gandhi National University of Law' },
    { 'id': 6188, 'value': 'Rajiv Gandhi Proudyogiki Vishwavidyalaya', 'label': 'Rajiv Gandhi Proudyogiki Vishwavidyalaya' },
    { 'id': 6189, 'value': 'Rajiv Gandhi University', 'label': 'Rajiv Gandhi University' },
    { 'id': 6190, 'value': 'Rajiv Gandhi University of Health Sciences', 'label': 'Rajiv Gandhi University of Health Sciences' },
    { 'id': 6191, 'value': 'Rajiv Gandhi University of Knowledge Technologies', 'label': 'Rajiv Gandhi University of Knowledge Technologies' },
    { 'id': 4793, 'value': 'Rajiv Gandhi University of Science and Technology', 'label': 'Rajiv Gandhi University of Science and Technology' },
    { 'id': 6192, 'value': 'Rajmata Vijayaraje Scindia Krishi Vishwavidyalaya', 'label': 'Rajmata Vijayaraje Scindia Krishi Vishwavidyalaya' },
    { 'id': 1299, 'value': 'Rajshahi Medical University', 'label': 'Rajshahi Medical University' },
    { 'id': 1300, 'value': 'Rajshahi Science and Technology University', 'label': 'Rajshahi Science and Technology University' },
    { 'id': 1301, 'value': 'Rajshahi University', 'label': 'Rajshahi University' },
    { 'id': 1302, 'value': 'Rajshahi University of Engineering and Technology', 'label': 'Rajshahi University of Engineering and Technology' },
    { 'id': 6193, 'value': 'Raksha Shakti University', 'label': 'Raksha Shakti University' },
    { 'id': 7531, 'value': 'Rakuno Gakuen University', 'label': 'Rakuno Gakuen University' },
    { 'id': 6194, 'value': 'Rama Devi Women\'s University', 'label': 'Rama Devi Women\'s University' },
    { 'id': 6195, 'value': 'Rama University', 'label': 'Rama University' },
    { 'id': 6196, 'value': 'Ramakrishna Mission Vivekananda University', 'label': 'Ramakrishna Mission Vivekananda University' },
    { 'id': 13278, 'value': 'Ramapo College of New Jersey', 'label': 'Ramapo College of New Jersey' },
    { 'id': 11458, 'value': 'Rambhai Barni Rajabhat University', 'label': 'Rambhai Barni Rajabhat University' },
    { 'id': 11459, 'value': 'Ramkhamhaeng University', 'label': 'Ramkhamhaeng University' },
    { 'id': 9949, 'value': 'Ramon Magsaysay Technological University', 'label': 'Ramon Magsaysay Technological University' },
    { 'id': 759, 'value': 'Rana University', 'label': 'Rana University' },
    { 'id': 1303, 'value': 'Ranada Prasad Shaha University', 'label': 'Ranada Prasad Shaha University' },
    { 'id': 6197, 'value': 'Ranchi University', 'label': 'Ranchi University' },
    { 'id': 13279, 'value': 'Randolph College', 'label': 'Randolph College' },
    { 'id': 13280, 'value': 'Randolph-Macon College', 'label': 'Randolph-Macon College' },
    { 'id': 1304, 'value': 'Rangamati Science and Technology University', 'label': 'Rangamati Science and Technology University' },
    { 'id': 11460, 'value': 'Rangsit University', 'label': 'Rangsit University' },
    { 'id': 6198, 'value': 'Rani Channamma University, Belagavi', 'label': 'Rani Channamma University, Belagavi' },
    { 'id': 6199, 'value': 'Rani Durgavati Vishwavidyalaya', 'label': 'Rani Durgavati Vishwavidyalaya' },
    { 'id': 6200, 'value': 'Rani Lakshmi Bai Central Agricultural University', 'label': 'Rani Lakshmi Bai Central Agricultural University' },
    { 'id': 661, 'value': 'Ras al-Khaimah Medical and Health Sciences University', 'label': 'Ras al-Khaimah Medical and Health Sciences University' },
    { 'id': 6201, 'value': 'Ras Bihari Bose Subharti University', 'label': 'Ras Bihari Bose Subharti University' },
    { 'id': 6202, 'value': 'Rashtrasant Tukadoji Maharaj Nagpur University', 'label': 'Rashtrasant Tukadoji Maharaj Nagpur University' },
    { 'id': 6203, 'value': 'Rashtriya Sanskrit Sansthan University', 'label': 'Rashtriya Sanskrit Sansthan University' },
    { 'id': 6204, 'value': 'Rashtriya Sanskrit Vidyapeetha', 'label': 'Rashtriya Sanskrit Vidyapeetha' },
    { 'id': 13281, 'value': 'Rasmussen College', 'label': 'Rasmussen College' },
    { 'id': 11461, 'value': 'Ratchathani University', 'label': 'Ratchathani University' },
    { 'id': 11462, 'value': 'Rattana Bundit University', 'label': 'Rattana Bundit University' },
    { 'id': 3379, 'value': 'Rauf Denktas Üniversitesi', 'label': 'Rauf Denktas Üniversitesi' },
    { 'id': 4544, 'value': 'Ravensbourne', 'label': 'Ravensbourne' },
    { 'id': 6205, 'value': 'Ravenshaw University', 'label': 'Ravenshaw University' },
    { 'id': 10158, 'value': 'Rawalpindi Medical University', 'label': 'Rawalpindi Medical University' },
    { 'id': 6206, 'value': 'Rayalaseema University', 'label': 'Rayalaseema University' },
    { 'id': 6207, 'value': 'Rayat-Bahra University', 'label': 'Rayat-Bahra University' },
    { 'id': 6732, 'value': 'Razi University', 'label': 'Razi University' },
    { 'id': 381, 'value': 'RCS Academy', 'label': 'RCS Academy' },
    { 'id': 11767, 'value': 'Recep Tayyip Erdogan Üniversitesi', 'label': 'Recep Tayyip Erdogan Üniversitesi' },
    { 'id': 11078, 'value': 'Red Sea University', 'label': 'Red Sea University' },
    { 'id': 1891, 'value': 'Redeemer University College', 'label': 'Redeemer University College' },
    { 'id': 9419, 'value': 'Redeemer\'s University', 'label': 'Redeemer\'s University' },
    { 'id': 13282, 'value': 'Reed College', 'label': 'Reed College' },
    { 'id': 14313, 'value': 'Reformed Church University', 'label': 'Reformed Church University' },
    { 'id': 13283, 'value': 'Regent University', 'label': 'Regent University' },
    { 'id': 4708, 'value': 'Regent University College of Science and Technology', 'label': 'Regent University College of Science and Technology' },
    { 'id': 367, 'value': 'Regent\'s University London', 'label': 'Regent\'s University London' },
    { 'id': 4709, 'value': 'Regional Maritime University', 'label': 'Regional Maritime University' },
    { 'id': 13284, 'value': 'Regis College', 'label': 'Regis College' },
    { 'id': 13285, 'value': 'Regis University', 'label': 'Regis University' },
    { 'id': 13286, 'value': 'Reinhardt University', 'label': 'Reinhardt University' },
    { 'id': 7532, 'value': 'Reitaku University', 'label': 'Reitaku University' },
    { 'id': 13287, 'value': 'Relay Graduate School of Education', 'label': 'Relay Graduate School of Education' },
    { 'id': 9420, 'value': 'Renaissance University', 'label': 'Renaissance University' },
    { 'id': 280, 'value': 'Renmin University of China', 'label': 'Renmin University of China' },
    { 'id': 4400, 'value': 'Rennes School of Business', 'label': 'Rennes School of Business' },
    { 'id': 13288, 'value': 'Rensselaer Polytechnic Institute', 'label': 'Rensselaer Polytechnic Institute' },
    { 'id': 9950, 'value': 'Republic Central Colleges', 'label': 'Republic Central Colleges' },
    { 'id': 13289, 'value': 'Research College of Nursing', 'label': 'Research College of Nursing' },
    { 'id': 13290, 'value': 'Resurrection University', 'label': 'Resurrection University' },
    { 'id': 6208, 'value': 'REVA University', 'label': 'REVA University' },
    { 'id': 8384, 'value': 'Rezeknes Tehnologiju Akademija', 'label': 'Rezeknes Tehnologiju Akademija' },
    { 'id': 3731, 'value': 'Rheinisch-Westfälische Technische Hochschule Aachen', 'label': 'Rheinisch-Westfälische Technische Hochschule Aachen' },
    { 'id': 3729, 'value': 'Rheinische Fachhochschule Köln', 'label': 'Rheinische Fachhochschule Köln' },
    { 'id': 3730, 'value': 'Rheinische Friedrich-Wilhelms-Universität Bonn', 'label': 'Rheinische Friedrich-Wilhelms-Universität Bonn' },
    { 'id': 9421, 'value': 'Rhema University', 'label': 'Rhema University' },
    { 'id': 13291, 'value': 'Rhode Island College', 'label': 'Rhode Island College' },
    { 'id': 13292, 'value': 'Rhode Island School of Design', 'label': 'Rhode Island School of Design' },
    { 'id': 13293, 'value': 'Rhodes College', 'label': 'Rhodes College' },
    { 'id': 14227, 'value': 'Rhodes University', 'label': 'Rhodes University' },
    { 'id': 7821, 'value': 'Riara University', 'label': 'Riara University' },
    { 'id': 13294, 'value': 'Rice University', 'label': 'Rice University' },
    { 'id': 4545, 'value': 'Richmond, The American International University in London', 'label': 'Richmond, The American International University in London' },
    { 'id': 13295, 'value': 'Richmont Graduate University', 'label': 'Richmont Graduate University' },
    { 'id': 13296, 'value': 'Rider University', 'label': 'Rider University' },
    { 'id': 4197, 'value': 'Rift Valley University', 'label': 'Rift Valley University' },
    { 'id': 179, 'value': 'Riga Stradiņš University', 'label': 'Riga Stradiņš University' },
    { 'id': 8385, 'value': 'Rigas Aeronavigacijas instituts', 'label': 'Rigas Aeronavigacijas instituts' },
    { 'id': 8386, 'value': 'Rigas Ekonomikas augstskola', 'label': 'Rigas Ekonomikas augstskola' },
    { 'id': 8387, 'value': 'Rigas Juridiska augstskola', 'label': 'Rigas Juridiska augstskola' },
    { 'id': 8388, 'value': 'Rigas Starptautiska ekonomikas un biznesa administracijas augstskola', 'label': 'Rigas Starptautiska ekonomikas un biznesa administracijas augstskola' },
    { 'id': 8389, 'value': 'Rigas Stradina Universitate', 'label': 'Rigas Stradina Universitate' },
    { 'id': 8390, 'value': 'Rigas Tehniska universitate', 'label': 'Rigas Tehniska universitate' },
    { 'id': 9547, 'value': 'Rijksuniversiteit Groningen', 'label': 'Rijksuniversiteit Groningen' },
    { 'id': 7533, 'value': 'Rikkyo University', 'label': 'Rikkyo University' },
    { 'id': 6209, 'value': 'RIMT University', 'label': 'RIMT University' },
    { 'id': 13297, 'value': 'Ringling College of Art and Design', 'label': 'Ringling College of Art and Design' },
    { 'id': 10159, 'value': 'Riphah International University', 'label': 'Riphah International University' },
    { 'id': 13298, 'value': 'Ripon College', 'label': 'Ripon College' },
    { 'id': 7534, 'value': 'Rissho University', 'label': 'Rissho University' },
    { 'id': 9422, 'value': 'Ritman University', 'label': 'Ritman University' },
    { 'id': 7535, 'value': 'Ritsumeikan Asia Pacific University', 'label': 'Ritsumeikan Asia Pacific University' },
    { 'id': 7536, 'value': 'Ritsumeikan University', 'label': 'Ritsumeikan University' },
    { 'id': 9423, 'value': 'Rivers State University', 'label': 'Rivers State University' },
    { 'id': 8690, 'value': 'Riverton University', 'label': 'Riverton University' },
    { 'id': 13299, 'value': 'Rivier University', 'label': 'Rivier University' },
    { 'id': 12113, 'value': 'Rivne State Humanitarian University', 'label': 'Rivne State Humanitarian University' },
    { 'id': 9951, 'value': 'Rizal Technological University', 'label': 'Rizal Technological University' },
    { 'id': 6210, 'value': 'RK University', 'label': 'RK University' },
    { 'id': 6211, 'value': 'RKDF University', 'label': 'RKDF University' },
    { 'id': 1086, 'value': 'RMIT University', 'label': 'RMIT University' },
    { 'id': 13300, 'value': 'Roanoke College', 'label': 'Roanoke College' },
    { 'id': 4546, 'value': 'Robert Gordon University', 'label': 'Robert Gordon University' },
    { 'id': 13301, 'value': 'Robert Morris University', 'label': 'Robert Morris University' },
    { 'id': 13302, 'value': 'Robert Morris University Illinois', 'label': 'Robert Morris University Illinois' },
    { 'id': 3732, 'value': 'Robert Schumann Hochschule Düsseldorf', 'label': 'Robert Schumann Hochschule Düsseldorf' },
    { 'id': 13303, 'value': 'Roberts Wesleyan College', 'label': 'Roberts Wesleyan College' },
    { 'id': 13304, 'value': 'Rochester Institute of Technology', 'label': 'Rochester Institute of Technology' },
    { 'id': 4826, 'value': 'Rochester Institute of Technology Croatia', 'label': 'Rochester Institute of Technology Croatia' },
    { 'id': 662, 'value': 'Rochester Institute of Technology, Dubai', 'label': 'Rochester Institute of Technology, Dubai' },
    { 'id': 13305, 'value': 'Rochester University', 'label': 'Rochester University' },
    { 'id': 296, 'value': 'Rockford University', 'label': 'Rockford University' },
    { 'id': 13306, 'value': 'Rockhurst University', 'label': 'Rockhurst University' },
    { 'id': 14277, 'value': 'Rockview University', 'label': 'Rockview University' },
    { 'id': 13307, 'value': 'Rocky Mountain College', 'label': 'Rocky Mountain College' },
    { 'id': 13308, 'value': 'Rocky Mountain College of Art and Design', 'label': 'Rocky Mountain College of Art and Design' },
    { 'id': 13309, 'value': 'Rocky Mountain University of Health Professions', 'label': 'Rocky Mountain University of Health Professions' },
    { 'id': 13310, 'value': 'Rocky Vista University', 'label': 'Rocky Vista University' },
    { 'id': 11138, 'value': 'Röda Korsets högskola', 'label': 'Röda Korsets högskola' },
    { 'id': 13311, 'value': 'Roger Williams University', 'label': 'Roger Williams University' },
    { 'id': 13312, 'value': 'Rogers State University', 'label': 'Rogers State University' },
    { 'id': 11463, 'value': 'Roi-et Rajabhat University', 'label': 'Roi-et Rajabhat University' },
    { 'id': 13313, 'value': 'Rollins College', 'label': 'Rollins College' },
    { 'id': 9952, 'value': 'Romblon State University', 'label': 'Romblon State University' },
    { 'id': 14395, 'value': 'Rome Business School', 'label': 'Rome Business School' },
    { 'id': 7822, 'value': 'Rongo University', 'label': 'Rongo University' },
    { 'id': 13314, 'value': 'Roosevelt University', 'label': 'Roosevelt University' },
    { 'id': 13315, 'value': 'Rosalind Franklin University of Medicine and Science', 'label': 'Rosalind Franklin University of Medicine and Science' },
    { 'id': 4547, 'value': 'Rose Bruford College', 'label': 'Rose Bruford College' },
    { 'id': 13316, 'value': 'Rose-Hulman Institute of Technology', 'label': 'Rose-Hulman Institute of Technology' },
    { 'id': 13317, 'value': 'Roseman University of Health Sciences', 'label': 'Roseman University of Health Sciences' },
    { 'id': 13318, 'value': 'Rosemont College', 'label': 'Rosemont College' },
    { 'id': 3836, 'value': 'Roskilde Universitet', 'label': 'Roskilde Universitet' },
    { 'id': 3840, 'value': 'Ross University School of Medicine', 'label': 'Ross University School of Medicine' },
    { 'id': 14013, 'value': 'Rossiya Davlat Neft va Gaz Universitetining Toshkent Shahridagi Filiali', 'label': 'Rossiya Davlat Neft va Gaz Universitetining Toshkent Shahridagi Filiali' },
    { 'id': 14014, 'value': 'Rossiya iqtisodiyot universitetining Toshkent shahridagi filiali', 'label': 'Rossiya iqtisodiyot universitetining Toshkent shahridagi filiali' },
    { 'id': 10849, 'value': 'Rostov State Medical University', 'label': 'Rostov State Medical University' },
    { 'id': 10850, 'value': 'Rostov State University of Economics', 'label': 'Rostov State University of Economics' },
    { 'id': 163, 'value': 'Rotterdam Business School', 'label': 'Rotterdam Business School' },
    { 'id': 249, 'value': 'Rowan College', 'label': 'Rowan College' },
    { 'id': 13319, 'value': 'Rowan University', 'label': 'Rowan University' },
    { 'id': 7924, 'value': 'Royal Academy of Cambodia', 'label': 'Royal Academy of Cambodia' },
    { 'id': 4548, 'value': 'Royal Academy of Music, University of London', 'label': 'Royal Academy of Music, University of London' },
    { 'id': 4549, 'value': 'Royal Agricultural University', 'label': 'Royal Agricultural University' },
    { 'id': 421, 'value': 'Royal College of Art', 'label': 'Royal College of Art' },
    { 'id': 258, 'value': 'Royal College of Music', 'label': 'Royal College of Music' },
    { 'id': 5523, 'value': 'Royal College of Surgeons in Ireland', 'label': 'Royal College of Surgeons in Ireland' },
    { 'id': 1475, 'value': 'Royal College of Surgeons in Ireland - Medical University of Bahrain', 'label': 'Royal College of Surgeons in Ireland - Medical University of Bahrain' },
    { 'id': 663, 'value': 'Royal College of Surgeons in Ireland-Dubai', 'label': 'Royal College of Surgeons in Ireland-Dubai' },
    { 'id': 4550, 'value': 'Royal Conservatoire of Scotland', 'label': 'Royal Conservatoire of Scotland' },
    { 'id': 4551, 'value': 'Royal Holloway, University of London', 'label': 'Royal Holloway, University of London' },
    { 'id': 5524, 'value': 'Royal Irish Academy of Music', 'label': 'Royal Irish Academy of Music' },
    { 'id': 1892, 'value': 'Royal Military College of Canada', 'label': 'Royal Military College of Canada' },
    { 'id': 4552, 'value': 'Royal Northern College of Music', 'label': 'Royal Northern College of Music' },
    { 'id': 1893, 'value': 'Royal Roads University', 'label': 'Royal Roads University' },
    { 'id': 1476, 'value': 'Royal University for Women', 'label': 'Royal University for Women' },
    { 'id': 7925, 'value': 'Royal University of Agriculture', 'label': 'Royal University of Agriculture' },
    { 'id': 1305, 'value': 'Royal University of Dhaka', 'label': 'Royal University of Dhaka' },
    { 'id': 7926, 'value': 'Royal University of Fine Arts', 'label': 'Royal University of Fine Arts' },
    { 'id': 7927, 'value': 'Royal University of Law and Economics', 'label': 'Royal University of Law and Economics' },
    { 'id': 7928, 'value': 'Royal University of Phnom Penh', 'label': 'Royal University of Phnom Penh' },
    { 'id': 4553, 'value': 'Royal Veterinary College University of London', 'label': 'Royal Veterinary College University of London' },
    { 'id': 760, 'value': 'Royan Institute of Higher Education', 'label': 'Royan Institute of Higher Education' },
    { 'id': 4827, 'value': 'RRiF Visoka škola za financijski menadžment', 'label': 'RRiF Visoka škola za financijski menadžment' },
    { 'id': 11963, 'value': 'Ruaha Catholic University', 'label': 'Ruaha Catholic University' },
    { 'id': 10851, 'value': 'RUDN University', 'label': 'RUDN University' },
    { 'id': 8243, 'value': 'Rudny Industrial Institute', 'label': 'Rudny Industrial Institute' },
    { 'id': 57, 'value': 'RUFA University of Fine Arts', 'label': 'RUFA University of Fine Arts' },
    { 'id': 3733, 'value': 'Ruhr-Universität Bochum', 'label': 'Ruhr-Universität Bochum' },
    { 'id': 11288, 'value': 'Rumbek University', 'label': 'Rumbek University' },
    { 'id': 5549, 'value': 'Ruppin Academic Center', 'label': 'Ruppin Academic Center' },
    { 'id': 3734, 'value': 'Ruprecht-Karls-Universität Heidelberg', 'label': 'Ruprecht-Karls-Universität Heidelberg' },
    { 'id': 14278, 'value': 'Rusangu University', 'label': 'Rusangu University' },
    { 'id': 13320, 'value': 'Rush University', 'label': 'Rush University' },
    { 'id': 11005, 'value': 'Rusizi International University', 'label': 'Rusizi International University' },
    { 'id': 10852, 'value': 'Russian National Research Medical University', 'label': 'Russian National Research Medical University' },
    { 'id': 10853, 'value': 'Russian New University', 'label': 'Russian New University' },
    { 'id': 10854, 'value': 'Russian Presidential Academy of National Economy and Public Administration', 'label': 'Russian Presidential Academy of National Economy and Public Administration' },
    { 'id': 10855, 'value': 'Russian State Agricultural University', 'label': 'Russian State Agricultural University' },
    { 'id': 10856, 'value': 'Russian State Geological Prospecting University', 'label': 'Russian State Geological Prospecting University' },
    { 'id': 10857, 'value': 'Russian State Hydrometeorological University', 'label': 'Russian State Hydrometeorological University' },
    { 'id': 10858, 'value': 'Russian State Institute of Performing Arts', 'label': 'Russian State Institute of Performing Arts' },
    { 'id': 10859, 'value': 'Russian State Social University', 'label': 'Russian State Social University' },
    { 'id': 10860, 'value': 'Russian State University A.N. Kosygin', 'label': 'Russian State University A.N. Kosygin' },
    { 'id': 10861, 'value': 'Russian State University for the Humanities', 'label': 'Russian State University for the Humanities' },
    { 'id': 10862, 'value': 'Russian State University of Oil and Gas', 'label': 'Russian State University of Oil and Gas' },
    { 'id': 10863, 'value': 'Russian State University of Physical Culture, Sport and Tourism', 'label': 'Russian State University of Physical Culture, Sport and Tourism' },
    { 'id': 10864, 'value': 'Russian State University of Tourism and Services', 'label': 'Russian State University of Tourism and Services' },
    { 'id': 831, 'value': 'Russian-Armenian University', 'label': 'Russian-Armenian University' },
    { 'id': 11511, 'value': 'Russian-Tajik Slavonic University', 'label': 'Russian-Tajik Slavonic University' },
    { 'id': 13321, 'value': 'Rust College', 'label': 'Rust College' },
    { 'id': 9639, 'value': 'Rustaq College of Education', 'label': 'Rustaq College of Education' },
    { 'id': 265, 'value': 'Rutgers School of Graduate Studies', 'label': 'Rutgers School of Graduate Studies' },
    { 'id': 13322, 'value': 'Rutgers, The State University of New Jersey', 'label': 'Rutgers, The State University of New Jersey' },
    { 'id': 10865, 'value': 'Ryazan State Agrotechnological University', 'label': 'Ryazan State Agrotechnological University' },
    { 'id': 10866, 'value': 'Ryazan State Medical University', 'label': 'Ryazan State Medical University' },
    { 'id': 10867, 'value': 'Ryazan State Radio Engineering University', 'label': 'Ryazan State Radio Engineering University' },
    { 'id': 10868, 'value': 'Ryazan State University', 'label': 'Ryazan State University' },
    { 'id': 10869, 'value': 'Rybinsk State Aviation Technical University', 'label': 'Rybinsk State Aviation Technical University' },
    { 'id': 1894, 'value': 'Ryerson University', 'label': 'Ryerson University' },
    { 'id': 7537, 'value': 'Ryotokuji University', 'label': 'Ryotokuji University' },
    { 'id': 7538, 'value': 'Ryukoku University', 'label': 'Ryukoku University' },
    { 'id': 7539, 'value': 'Ryutsu Keizai University', 'label': 'Ryutsu Keizai University' },
    { 'id': 14208, 'value': 'Saba University', 'label': 'Saba University' },
    { 'id': 11768, 'value': 'Sabanci Üniversitesi', 'label': 'Sabanci Üniversitesi' },
    { 'id': 8314, 'value': 'Sabaragamuwa University of Sri Lanka', 'label': 'Sabaragamuwa University of Sri Lanka' },
    { 'id': 6212, 'value': 'Sabarmati University', 'label': 'Sabarmati University' },
    { 'id': 761, 'value': 'Saber Institute of Higher Education', 'label': 'Saber Institute of Higher Education' },
    { 'id': 6733, 'value': 'Sabzevar University of Medical Sciences', 'label': 'Sabzevar University of Medical Sciences' },
    { 'id': 6734, 'value': 'Sabzevar University of New Technologies', 'label': 'Sabzevar University of New Technologies' },
    { 'id': 9953, 'value': 'Sacred Heart College', 'label': 'Sacred Heart College' },
    { 'id': 13323, 'value': 'Sacred Heart University', 'label': 'Sacred Heart University' },
    { 'id': 762, 'value': 'Sadat Institute of Higher Education', 'label': 'Sadat Institute of Higher Education' },
    { 'id': 6735, 'value': 'Sadjad University of Technology', 'label': 'Sadjad University of Technology' },
    { 'id': 6888, 'value': 'SAE Italia International Technology College', 'label': 'SAE Italia International Technology College' },
    { 'id': 7540, 'value': 'Saga University', 'label': 'Saga University' },
    { 'id': 8529, 'value': 'Sagaing University', 'label': 'Sagaing University' },
    { 'id': 7541, 'value': 'Sagami Women\'s University', 'label': 'Sagami Women\'s University' },
    { 'id': 6213, 'value': 'Sage University', 'label': 'Sage University' },
    { 'id': 13324, 'value': 'Saginaw Valley State University', 'label': 'Saginaw Valley State University' },
    { 'id': 11769, 'value': 'Saglik Bilimleri Üniversitesi', 'label': 'Saglik Bilimleri Üniversitesi' },
    { 'id': 6736, 'value': 'Sahand University of Technology', 'label': 'Sahand University of Technology' },
    { 'id': 8107, 'value': 'Sahmyook University', 'label': 'Sahmyook University' },
    { 'id': 6214, 'value': 'Sai Nath University', 'label': 'Sai Nath University' },
    { 'id': 6215, 'value': 'Sai Tirupati University', 'label': 'Sai Tirupati University' },
    { 'id': 14155, 'value': 'Saigon University', 'label': 'Saigon University' },
    { 'id': 13325, 'value': 'Saint Ambrose University', 'label': 'Saint Ambrose University' },
    { 'id': 4646, 'value': 'Saint Andrew the First-Called Georgian University', 'label': 'Saint Andrew the First-Called Georgian University' },
    { 'id': 13326, 'value': 'Saint Anselm College', 'label': 'Saint Anselm College' },
    { 'id': 13327, 'value': 'Saint Anthony College of Nursing', 'label': 'Saint Anthony College of Nursing' },
    { 'id': 13328, 'value': 'Saint Augustine College', 'label': 'Saint Augustine College' },
    { 'id': 473, 'value': 'Saint Camillus International University of Health (UniCamillus)', 'label': 'Saint Camillus International University of Health (UniCamillus)' },
    { 'id': 13329, 'value': 'Saint Francis Medical Center College of Nursing', 'label': 'Saint Francis Medical Center College of Nursing' },
    { 'id': 13330, 'value': 'Saint Francis University', 'label': 'Saint Francis University' },
    { 'id': 778, 'value': 'Saint James School of Medicine', 'label': 'Saint James School of Medicine' },
    { 'id': 11464, 'value': 'Saint John\'s University', 'label': 'Saint John\'s University' },
    { 'id': 13331, 'value': 'Saint Johns River State College', 'label': 'Saint Johns River State College' },
    { 'id': 9954, 'value': 'Saint Joseph Institute of Technology', 'label': 'Saint Joseph Institute of Technology' },
    { 'id': 13332, 'value': 'Saint Joseph\'s College of Maine', 'label': 'Saint Joseph\'s College of Maine' },
    { 'id': 13333, 'value': 'Saint Joseph\'s University', 'label': 'Saint Joseph\'s University' },
    { 'id': 9955, 'value': 'Saint Jude College', 'label': 'Saint Jude College' },
    { 'id': 13334, 'value': 'Saint Leo University', 'label': 'Saint Leo University' },
    { 'id': 305, 'value': 'Saint Louis College of Music', 'label': 'Saint Louis College of Music' },
    { 'id': 6936, 'value': 'Saint Louis Music Center - College of Music - ROMA', 'label': 'Saint Louis Music Center - College of Music - ROMA' },
    { 'id': 13335, 'value': 'Saint Louis University', 'label': 'Saint Louis University' },
    { 'id': 9956, 'value': 'Saint Louis University, Baguio City', 'label': 'Saint Louis University, Baguio City' },
    { 'id': 13336, 'value': 'Saint Luke\'s College of Health Sciences', 'label': 'Saint Luke\'s College of Health Sciences' },
    { 'id': 13337, 'value': 'Saint Martin\'s University', 'label': 'Saint Martin\'s University' },
    { 'id': 13338, 'value': 'Saint Mary-of-the-Woods College', 'label': 'Saint Mary-of-the-Woods College' },
    { 'id': 13339, 'value': 'Saint Mary\'s College', 'label': 'Saint Mary\'s College' },
    { 'id': 13340, 'value': 'Saint Mary\'s College of California', 'label': 'Saint Mary\'s College of California' },
    { 'id': 1895, 'value': 'Saint Mary\'s University', 'label': 'Saint Mary\'s University' },
    { 'id': 9957, 'value': 'Saint Mary\'s University of Bayombong', 'label': 'Saint Mary\'s University of Bayombong' },
    { 'id': 13341, 'value': 'Saint Mary\'s University of Minnesota', 'label': 'Saint Mary\'s University of Minnesota' },
    { 'id': 13342, 'value': 'Saint Michael\'s College', 'label': 'Saint Michael\'s College' },
    { 'id': 9958, 'value': 'Saint Michael\'s College of Laguna', 'label': 'Saint Michael\'s College of Laguna' },
    { 'id': 5525, 'value': 'Saint Patrick\'s College, Maynooth', 'label': 'Saint Patrick\'s College, Maynooth' },
    { 'id': 9959, 'value': 'Saint Pedro Poveda College', 'label': 'Saint Pedro Poveda College' },
    { 'id': 13343, 'value': 'Saint Peter\'s University', 'label': 'Saint Peter\'s University' },
    { 'id': 10870, 'value': 'Saint Petersburg State Electrotechnical University', 'label': 'Saint Petersburg State Electrotechnical University' },
    { 'id': 10871, 'value': 'Saint Petersburg State Institute of Technology', 'label': 'Saint Petersburg State Institute of Technology' },
    { 'id': 10872, 'value': 'Saint Petersburg State Pediatric Medical Academy', 'label': 'Saint Petersburg State Pediatric Medical Academy' },
    { 'id': 10873, 'value': 'Saint Tikhon\'s Orthodox University', 'label': 'Saint Tikhon\'s Orthodox University' },
    { 'id': 13344, 'value': 'Saint Vincent College', 'label': 'Saint Vincent College' },
    { 'id': 13345, 'value': 'Saint Xavier University', 'label': 'Saint Xavier University' },
    { 'id': 10874, 'value': 'Saint-Petersburg Mining University', 'label': 'Saint-Petersburg Mining University' },
    { 'id': 10875, 'value': 'Saint-Petersburg State Chemical Pharmaceutical University', 'label': 'Saint-Petersburg State Chemical Pharmaceutical University' },
    { 'id': 10876, 'value': 'Saint-Petersburg State Institute of Film and Television', 'label': 'Saint-Petersburg State Institute of Film and Television' },
    { 'id': 1896, 'value': 'SAIT Polytechnic', 'label': 'SAIT Polytechnic' },
    { 'id': 7542, 'value': 'Saitama Gakuen University', 'label': 'Saitama Gakuen University' },
    { 'id': 7543, 'value': 'Saitama Institute of Technology', 'label': 'Saitama Institute of Technology' },
    { 'id': 7544, 'value': 'Saitama Medical School', 'label': 'Saitama Medical School' },
    { 'id': 7545, 'value': 'Saitama Prefectural University', 'label': 'Saitama Prefectural University' },
    { 'id': 7546, 'value': 'Saitama University', 'label': 'Saitama University' },
    { 'id': 11770, 'value': 'Sakarya Üniversitesi', 'label': 'Sakarya Üniversitesi' },
    { 'id': 8244, 'value': 'Saken Seifullin Kazakh Agrotechnical University', 'label': 'Saken Seifullin Kazakh Agrotechnical University' },
    { 'id': 10877, 'value': 'Sakhalin State University', 'label': 'Sakhalin State University' },
    { 'id': 11465, 'value': 'Sakon Nakhon Rajabhat University', 'label': 'Sakon Nakhon Rajabhat University' },
    { 'id': 7547, 'value': 'Sakushin Gakuin University', 'label': 'Sakushin Gakuin University' },
    { 'id': 6464, 'value': 'Salahaddin University-Erbil', 'label': 'Salahaddin University-Erbil' },
    { 'id': 9640, 'value': 'Salalah College of Technology', 'label': 'Salalah College of Technology' },
    { 'id': 763, 'value': 'Salam University', 'label': 'Salam University' },
    { 'id': 13346, 'value': 'Salem College', 'label': 'Salem College' },
    { 'id': 13347, 'value': 'Salem International University', 'label': 'Salem International University' },
    { 'id': 13348, 'value': 'Salem State University', 'label': 'Salem State University' },
    { 'id': 9424, 'value': 'Salem University', 'label': 'Salem University' },
    { 'id': 13349, 'value': 'Salisbury University', 'label': 'Salisbury University' },
    { 'id': 6737, 'value': 'Salman Farsi University of Kazerun', 'label': 'Salman Farsi University of Kazerun' },
    { 'id': 13350, 'value': 'Salus University', 'label': 'Salus University' },
    { 'id': 13351, 'value': 'Salve Regina University', 'label': 'Salve Regina University' },
    { 'id': 6216, 'value': 'Sam Higginbottom Institute of Agriculture, Technology and Sciences', 'label': 'Sam Higginbottom Institute of Agriculture, Technology and Sciences' },
    { 'id': 13352, 'value': 'Sam Houston State University', 'label': 'Sam Houston State University' },
    { 'id': 9960, 'value': 'Samar State University', 'label': 'Samar State University' },
    { 'id': 10878, 'value': 'Samara Academy of Humanities', 'label': 'Samara Academy of Humanities' },
    { 'id': 10879, 'value': 'Samara National Research University', 'label': 'Samara National Research University' },
    { 'id': 10880, 'value': 'Samara State Academy of Social Sciences and Humanities', 'label': 'Samara State Academy of Social Sciences and Humanities' },
    { 'id': 10881, 'value': 'Samara State Economic University', 'label': 'Samara State Economic University' },
    { 'id': 10882, 'value': 'Samara State Medical University', 'label': 'Samara State Medical University' },
    { 'id': 10883, 'value': 'Samara State Technical University', 'label': 'Samara State Technical University' },
    { 'id': 10884, 'value': 'Samara State University', 'label': 'Samara State University' },
    { 'id': 14015, 'value': 'Samarkand Davlat Universiteti', 'label': 'Samarkand Davlat Universiteti' },
    { 'id': 14016, 'value': 'Samarqand Davlat Arxitektura-Qurilish Instituti', 'label': 'Samarqand Davlat Arxitektura-Qurilish Instituti' },
    { 'id': 14017, 'value': 'Samarqand Davlat Chet Tillar Instituti', 'label': 'Samarqand Davlat Chet Tillar Instituti' },
    { 'id': 14018, 'value': 'Samarqand Davlat Tibbiyot Instituti', 'label': 'Samarqand Davlat Tibbiyot Instituti' },
    { 'id': 14019, 'value': 'Samarqand Iqtisodiyot va Servis Instituti', 'label': 'Samarqand Iqtisodiyot va Servis Instituti' },
    { 'id': 14020, 'value': 'Samarqand Qishloq xo\'jalik Instituti', 'label': 'Samarqand Qishloq xo\'jalik Instituti' },
    { 'id': 6217, 'value': 'Sambalpur University', 'label': 'Sambalpur University' },
    { 'id': 13353, 'value': 'Samford University', 'label': 'Samford University' },
    { 'id': 9581, 'value': 'Sámi allskuvla', 'label': 'Sámi allskuvla' },
    { 'id': 5550, 'value': 'Sami Shamoon College of Engineering', 'label': 'Sami Shamoon College of Engineering' },
    { 'id': 6218, 'value': 'Sampurnanand Sanskrit Vishvavidyalaya', 'label': 'Sampurnanand Sanskrit Vishvavidyalaya' },
    { 'id': 4647, 'value': 'Samtskhe-Javakheti State University', 'label': 'Samtskhe-Javakheti State University' },
    { 'id': 13354, 'value': 'Samuel Merritt University', 'label': 'Samuel Merritt University' },
    { 'id': 9961, 'value': 'San Beda University', 'label': 'San Beda University' },
    { 'id': 13355, 'value': 'San Diego Christian College', 'label': 'San Diego Christian College' },
    { 'id': 13356, 'value': 'San Diego State University', 'label': 'San Diego State University' },
    { 'id': 13357, 'value': 'San Francisco Art Institute', 'label': 'San Francisco Art Institute' },
    { 'id': 13358, 'value': 'San Francisco Conservatory of Music', 'label': 'San Francisco Conservatory of Music' },
    { 'id': 13359, 'value': 'San Francisco State University', 'label': 'San Francisco State University' },
    { 'id': 8632, 'value': 'San Insitute', 'label': 'San Insitute' },
    { 'id': 13360, 'value': 'San Joaquin College of Law', 'label': 'San Joaquin College of Law' },
    { 'id': 13361, 'value': 'San José State University', 'label': 'San José State University' },
    { 'id': 10356, 'value': 'San Juan Bautista School of Medicine', 'label': 'San Juan Bautista School of Medicine' },
    { 'id': 9962, 'value': 'San Sebastian College-Recoletos de Cavite', 'label': 'San Sebastian College-Recoletos de Cavite' },
    { 'id': 14209, 'value': 'Sana\'a University', 'label': 'Sana\'a University' },
    { 'id': 6219, 'value': 'Sanchi University of Buddhist-Indic Studies', 'label': 'Sanchi University of Buddhist-Indic Studies' },
    { 'id': 2686, 'value': 'Sanda University', 'label': 'Sanda University' },
    { 'id': 6220, 'value': 'Sandip University', 'label': 'Sandip University' },
    { 'id': 6221, 'value': 'Sandip University, Sijoul', 'label': 'Sandip University, Sijoul' },
    { 'id': 6222, 'value': 'Sangai International University', 'label': 'Sangai International University' },
    { 'id': 6223, 'value': 'Sangam University', 'label': 'Sangam University' },
    { 'id': 8108, 'value': 'Sangji University', 'label': 'Sangji University' },
    { 'id': 8109, 'value': 'Sangmyung University', 'label': 'Sangmyung University' },
    { 'id': 7548, 'value': 'Saniku Gakuin College', 'label': 'Saniku Gakuin College' },
    { 'id': 6224, 'value': 'Sanjay Gandhi Post Graduate Institute of Medical Sciences', 'label': 'Sanjay Gandhi Post Graduate Institute of Medical Sciences' },
    { 'id': 6225, 'value': 'Sanjay Ghodawat University', 'label': 'Sanjay Ghodawat University' },
    { 'id': 2687, 'value': 'Sanjiang University', 'label': 'Sanjiang University' },
    { 'id': 6226, 'value': 'Sankalchand Patel University', 'label': 'Sankalchand Patel University' },
    { 'id': 11771, 'value': 'Sanko Üniversitesi', 'label': 'Sanko Üniversitesi' },
    { 'id': 2688, 'value': 'Sanming University', 'label': 'Sanming University' },
    { 'id': 7549, 'value': 'Sanno University', 'label': 'Sanno University' },
    { 'id': 2689, 'value': 'SanQuan Medical College', 'label': 'SanQuan Medical College' },
    { 'id': 6227, 'value': 'Sanskriti University', 'label': 'Sanskriti University' },
    { 'id': 6228, 'value': 'Sant Baba Bhag Singh University', 'label': 'Sant Baba Bhag Singh University' },
    { 'id': 6229, 'value': 'Sant Gadge Baba Amravati University', 'label': 'Sant Gadge Baba Amravati University' },
    { 'id': 6230, 'value': 'Sant Longowal Institute of Engineering and Technology', 'label': 'Sant Longowal Institute of Engineering and Technology' },
    { 'id': 14391, 'value': 'Santa Barbara City College', 'label': 'Santa Barbara City College' },
    { 'id': 13362, 'value': 'Santa Clara University', 'label': 'Santa Clara University' },
    { 'id': 13363, 'value': 'Santa Fe College', 'label': 'Santa Fe College' },
    { 'id': 9963, 'value': 'Santa Isabel College', 'label': 'Santa Isabel College' },
    { 'id': 343, 'value': 'Santa Monica College', 'label': 'Santa Monica College' },
    { 'id': 6231, 'value': 'Santosh University', 'label': 'Santosh University' },
    { 'id': 2690, 'value': 'Sanya University', 'label': 'Sanya University' },
    { 'id': 7550, 'value': 'Sanyo Gakuen University', 'label': 'Sanyo Gakuen University' },
    { 'id': 7551, 'value': 'Sanyo-onoda City University', 'label': 'Sanyo-onoda City University' },
    { 'id': 5551, 'value': 'Sapir College', 'label': 'Sapir College' },
    { 'id': 7552, 'value': 'Sapporo City University', 'label': 'Sapporo City University' },
    { 'id': 7553, 'value': 'Sapporo Gakuin University', 'label': 'Sapporo Gakuin University' },
    { 'id': 7554, 'value': 'Sapporo International University', 'label': 'Sapporo International University' },
    { 'id': 7555, 'value': 'Sapporo Medical University', 'label': 'Sapporo Medical University' },
    { 'id': 7556, 'value': 'Sapporo Otani University', 'label': 'Sapporo Otani University' },
    { 'id': 7557, 'value': 'Sapporo University', 'label': 'Sapporo University' },
    { 'id': 13364, 'value': 'Sarah Lawrence College', 'label': 'Sarah Lawrence College' },
    { 'id': 1169, 'value': 'Sarajevo School of Science and Technology', 'label': 'Sarajevo School of Science and Technology' },
    { 'id': 6232, 'value': 'Sarala Birla University', 'label': 'Sarala Birla University' },
    { 'id': 10885, 'value': 'Saratov State Academy of Law', 'label': 'Saratov State Academy of Law' },
    { 'id': 10886, 'value': 'Saratov State Agrarian University', 'label': 'Saratov State Agrarian University' },
    { 'id': 10887, 'value': 'Saratov State Medical University', 'label': 'Saratov State Medical University' },
    { 'id': 10888, 'value': 'Saratov State Technical University', 'label': 'Saratov State Technical University' },
    { 'id': 10889, 'value': 'Saratov State University', 'label': 'Saratov State University' },
    { 'id': 10160, 'value': 'Sardar Bahadur Khan Women\'s University', 'label': 'Sardar Bahadur Khan Women\'s University' },
    { 'id': 6233, 'value': 'Sardar Patel University', 'label': 'Sardar Patel University' },
    { 'id': 6234, 'value': 'Sardar Patel University of Police, Security and Criminal Justice', 'label': 'Sardar Patel University of Police, Security and Criminal Justice' },
    { 'id': 6235, 'value': 'Sardar Vallabhbhai National Institute of Technology, Surat', 'label': 'Sardar Vallabhbhai National Institute of Technology, Surat' },
    { 'id': 6236, 'value': 'Sardar Vallabhbhai Patel University of Agriculture and Technology', 'label': 'Sardar Vallabhbhai Patel University of Agriculture and Technology' },
    { 'id': 6237, 'value': 'Sardarkrushinagar Dantiwada Agricultural University', 'label': 'Sardarkrushinagar Dantiwada Agricultural University' },
    { 'id': 6238, 'value': 'Sarguja University', 'label': 'Sarguja University' },
    { 'id': 10161, 'value': 'Sarhad University of Science and Information Technology', 'label': 'Sarhad University of Science and Information Technology' },
    { 'id': 6738, 'value': 'Sari Agricultural Sciences and Natural Resources University', 'label': 'Sari Agricultural Sciences and Natural Resources University' },
    { 'id': 7964, 'value': 'Sariwon Pharmaceutical College of Koryo', 'label': 'Sariwon Pharmaceutical College of Koryo' },
    { 'id': 6239, 'value': 'Sarvepalli Radhakrishnan University', 'label': 'Sarvepalli Radhakrishnan University' },
    { 'id': 6240, 'value': 'SASTRA University', 'label': 'SASTRA University' },
    { 'id': 4231, 'value': 'Satakunnan ammattikorkeakoulu', 'label': 'Satakunnan ammattikorkeakoulu' },
    { 'id': 6241, 'value': 'Satavahana University', 'label': 'Satavahana University' },
    { 'id': 8245, 'value': 'Satbayev University', 'label': 'Satbayev University' },
    { 'id': 6242, 'value': 'Sathyabama Institute of Science and Technology', 'label': 'Sathyabama Institute of Science and Technology' },
    { 'id': 6243, 'value': 'Saurashtra University', 'label': 'Saurashtra University' },
    { 'id': 13365, 'value': 'Savannah College of Art and Design', 'label': 'Savannah College of Art and Design' },
    { 'id': 13366, 'value': 'Savannah State University', 'label': 'Savannah State University' },
    { 'id': 6244, 'value': 'Saveetha University', 'label': 'Saveetha University' },
    { 'id': 6245, 'value': 'Savitribai Phule Pune University', 'label': 'Savitribai Phule Pune University' },
    { 'id': 4232, 'value': 'Savonia-ammattikorkeakoulu', 'label': 'Savonia-ammattikorkeakoulu' },
    { 'id': 13367, 'value': 'Saybrook University', 'label': 'Saybrook University' },
    { 'id': 6739, 'value': 'Sayyed Jamaleddin Asadabadi University', 'label': 'Sayyed Jamaleddin Asadabadi University' },
    { 'id': 256, 'value': 'SCAD (Savannah College of Art and Design)', 'label': 'SCAD (Savannah College of Art and Design)' },
    { 'id': 5552, 'value': 'Schechter Institute of Jewish Studies', 'label': 'Schechter Institute of Jewish Studies' },
    { 'id': 13368, 'value': 'Schiller International University', 'label': 'Schiller International University' },
    { 'id': 4554, 'value': 'School of Advanced Study, University of London', 'label': 'School of Advanced Study, University of London' },
    { 'id': 8633, 'value': 'School of Agroecology and Business', 'label': 'School of Agroecology and Business' },
    { 'id': 477, 'value': 'School of Finance e Management Frankfurt', 'label': 'School of Finance e Management Frankfurt' },
    { 'id': 6740, 'value': 'School of International Relations', 'label': 'School of International Relations' },
    { 'id': 6246, 'value': 'School of Planning and Architecture, Bhopal', 'label': 'School of Planning and Architecture, Bhopal' },
    { 'id': 6247, 'value': 'School of Planning and Architecture, Delhi', 'label': 'School of Planning and Architecture, Delhi' },
    { 'id': 6248, 'value': 'School of Planning and Architecture, Vijayawada', 'label': 'School of Planning and Architecture, Vijayawada' },
    { 'id': 345, 'value': 'School of The Art Institute of Chicago', 'label': 'School of The Art Institute of Chicago' },
    { 'id': 13369, 'value': 'School of Visual Arts', 'label': 'School of Visual Arts' },
    { 'id': 13370, 'value': 'Schreiner University', 'label': 'Schreiner University' },
    { 'id': 328, 'value': 'Schule für Eurythmische Art und Kunst Berlin', 'label': 'Schule für Eurythmische Art und Kunst Berlin' },
    { 'id': 52, 'value': 'Sciences Po', 'label': 'Sciences Po' },
    { 'id': 9641, 'value': 'Scientific College of Design', 'label': 'Scientific College of Design' },
    { 'id': 10522, 'value': 'Scoala Nationala de Studii Politice si Administrative', 'label': 'Scoala Nationala de Studii Politice si Administrative' },
    { 'id': 4555, 'value': 'Scotland\'s Rural College', 'label': 'Scotland\'s Rural College' },
    { 'id': 7823, 'value': 'Scott Christian University', 'label': 'Scott Christian University' },
    { 'id': 13371, 'value': 'Scripps College', 'label': 'Scripps College' },
    { 'id': 14365, 'value': 'Scuola del teatro musicale (STM)', 'label': 'Scuola del teatro musicale (STM)' },
    { 'id': 14382, 'value': 'Scuola di Alta Cucina Tessieri', 'label': 'Scuola di Alta Cucina Tessieri' },
    { 'id': 7006, 'value': 'Scuola di musica di Fiesole', 'label': 'Scuola di musica di Fiesole' },
    { 'id': 6839, 'value': 'Scuola IMT Alti Studi di Lucca', 'label': 'Scuola IMT Alti Studi di Lucca' },
    { 'id': 6834, 'value': 'Scuola Internazionale Superiore di Studi Avanzati di Trieste', 'label': 'Scuola Internazionale Superiore di Studi Avanzati di Trieste' },
    { 'id': 6843, 'value': 'Scuola Normale Superiore di Pisa', 'label': 'Scuola Normale Superiore di Pisa' },
    { 'id': 472, 'value': 'Scuola Superiore di Studi Universitari e Perfezionamento "S. Anna" di Pisa', 'label': 'Scuola Superiore di Studi Universitari e Perfezionamento "S. Anna" di Pisa' },
    { 'id': 14320, 'value': 'Scuola Superiore per Mediatori Linguistici - Pisa', 'label': 'Scuola Superiore per Mediatori Linguistici - Pisa' },
    { 'id': 2007, 'value': 'Scuola universitaria professionale della Svizzera italiana', 'label': 'Scuola universitaria professionale della Svizzera italiana' },
    { 'id': 6249, 'value': 'Seacom Skills University', 'label': 'Seacom Skills University' },
    { 'id': 13372, 'value': 'Seattle Central College', 'label': 'Seattle Central College' },
    { 'id': 13373, 'value': 'Seattle Pacific University', 'label': 'Seattle Pacific University' },
    { 'id': 464, 'value': 'Seattle University', 'label': 'Seattle University' },
    { 'id': 11964, 'value': 'Sebastian Kolowa Memorial University', 'label': 'Sebastian Kolowa Memorial University' },
    { 'id': 8403, 'value': 'Sebha University', 'label': 'Sebha University' },
    { 'id': 14228, 'value': 'Sefako Makgatho Health Sciences University', 'label': 'Sefako Makgatho Health Sciences University' },
    { 'id': 8110, 'value': 'Sehan University', 'label': 'Sehan University' },
    { 'id': 7558, 'value': 'Seian University of Art and Design', 'label': 'Seian University of Art and Design' },
    { 'id': 7559, 'value': 'Seigakuin University', 'label': 'Seigakuin University' },
    { 'id': 7560, 'value': 'Seijo University', 'label': 'Seijo University' },
    { 'id': 7561, 'value': 'Seijoh University', 'label': 'Seijoh University' },
    { 'id': 7562, 'value': 'Seikei University', 'label': 'Seikei University' },
    { 'id': 4233, 'value': 'Seinäjoen ammattikorkeakoulu', 'label': 'Seinäjoen ammattikorkeakoulu' },
    { 'id': 7563, 'value': 'Seinan Gakuin University', 'label': 'Seinan Gakuin University' },
    { 'id': 7564, 'value': 'Seinan Jo Gakuin University', 'label': 'Seinan Jo Gakuin University' },
    { 'id': 7565, 'value': 'Seirei Christopher University', 'label': 'Seirei Christopher University' },
    { 'id': 7566, 'value': 'Seisa Dohto University', 'label': 'Seisa Dohto University' },
    { 'id': 7567, 'value': 'Seisen Jogakuin College', 'label': 'Seisen Jogakuin College' },
    { 'id': 7568, 'value': 'Seisen University', 'label': 'Seisen University' },
    { 'id': 7569, 'value': 'Seitoku University', 'label': 'Seitoku University' },
    { 'id': 7570, 'value': 'Seiwa University', 'label': 'Seiwa University' },
    { 'id': 8111, 'value': 'Sejong University', 'label': 'Sejong University' },
    { 'id': 11772, 'value': 'Selçuk Üniversitesi', 'label': 'Selçuk Üniversitesi' },
    { 'id': 14383, 'value': 'SELKIRK COLLEGE', 'label': 'SELKIRK COLLEGE' },
    { 'id': 4198, 'value': 'Semera University', 'label': 'Semera University' },
    { 'id': 8246, 'value': 'Semey Medical University', 'label': 'Semey Medical University' },
    { 'id': 13374, 'value': 'Seminole State College of Florida', 'label': 'Seminole State College of Florida' },
    { 'id': 8247, 'value': 'Semipalatinsk State University', 'label': 'Semipalatinsk State University' },
    { 'id': 4921, 'value': 'Semmelweis Egyetem', 'label': 'Semmelweis Egyetem' },
    { 'id': 298, 'value': 'Semmelweis University', 'label': 'Semmelweis University' },
    { 'id': 6741, 'value': 'Semnan University', 'label': 'Semnan University' },
    { 'id': 6742, 'value': 'Semnan University of Medical Sciences', 'label': 'Semnan University of Medical Sciences' },
    { 'id': 8112, 'value': 'Semyung University', 'label': 'Semyung University' },
    { 'id': 7571, 'value': 'Sendai Shirayuri Women\'s College', 'label': 'Sendai Shirayuri Women\'s College' },
    { 'id': 7572, 'value': 'Sendai University', 'label': 'Sendai University' },
    { 'id': 14392, 'value': 'Seneca College', 'label': 'Seneca College' },
    { 'id': 7573, 'value': 'Senri Kinran University', 'label': 'Senri Kinran University' },
    { 'id': 7574, 'value': 'Senshu University', 'label': 'Senshu University' },
    { 'id': 7575, 'value': 'Senzoku Gakuen College of Music', 'label': 'Senzoku Gakuen College of Music' },
    { 'id': 8113, 'value': 'Seokyeong University', 'label': 'Seokyeong University' },
    { 'id': 8114, 'value': 'Seonam University', 'label': 'Seonam University' },
    { 'id': 8115, 'value': 'Seoul Christian University', 'label': 'Seoul Christian University' },
    { 'id': 8116, 'value': 'Seoul Jangsin University', 'label': 'Seoul Jangsin University' },
    { 'id': 8117, 'value': 'Seoul National University', 'label': 'Seoul National University' },
    { 'id': 8118, 'value': 'Seoul National University of Education', 'label': 'Seoul National University of Education' },
    { 'id': 8119, 'value': 'Seoul National University of Science and Technology', 'label': 'Seoul National University of Science and Technology' },
    { 'id': 8120, 'value': 'Seoul Theological University', 'label': 'Seoul Theological University' },
    { 'id': 8121, 'value': 'Seoul Women\'s University', 'label': 'Seoul Women\'s University' },
    { 'id': 8122, 'value': 'Seowon University', 'label': 'Seowon University' },
    { 'id': 10890, 'value': 'Sergei Witte University of Moscow', 'label': 'Sergei Witte University of Moscow' },
    { 'id': 8634, 'value': 'Seruuleg Institute', 'label': 'Seruuleg Institute' },
    { 'id': 14385, 'value': 'Setanta College.', 'label': 'Setanta College.' },
    { 'id': 8635, 'value': 'Setguulch Institute', 'label': 'Setguulch Institute' },
    { 'id': 13375, 'value': 'Seton Hall University', 'label': 'Seton Hall University' },
    { 'id': 13376, 'value': 'Seton Hill University', 'label': 'Seton Hill University' },
    { 'id': 7576, 'value': 'Setsunan University', 'label': 'Setsunan University' },
    { 'id': 12114, 'value': 'Sevastopol State University', 'label': 'Sevastopol State University' },
    { 'id': 13377, 'value': 'Sewanee: The University of the South', 'label': 'Sewanee: The University of the South' },
    { 'id': 191, 'value': 'SFU - Sigmund Freud University', 'label': 'SFU - Sigmund Freud University' },
    { 'id': 5553, 'value': 'Sha\'arei Mishpat College', 'label': 'Sha\'arei Mishpat College' },
    { 'id': 2691, 'value': 'Shaanxi Fashion Engineering University', 'label': 'Shaanxi Fashion Engineering University' },
    { 'id': 2692, 'value': 'Shaanxi Institute of International Trade and Commerce', 'label': 'Shaanxi Institute of International Trade and Commerce' },
    { 'id': 2693, 'value': 'Shaanxi Normal University', 'label': 'Shaanxi Normal University' },
    { 'id': 2694, 'value': 'Shaanxi University of Chinese Medicine', 'label': 'Shaanxi University of Chinese Medicine' },
    { 'id': 2695, 'value': 'Shaanxi University of Science and Technology', 'label': 'Shaanxi University of Science and Technology' },
    { 'id': 2696, 'value': 'Shaanxi University of Technology', 'label': 'Shaanxi University of Technology' },
    { 'id': 2697, 'value': 'Shaanxi Xueqian Normal University', 'label': 'Shaanxi Xueqian Normal University' },
    { 'id': 10162, 'value': 'Shah Abdul Latif University', 'label': 'Shah Abdul Latif University' },
    { 'id': 6743, 'value': 'Shahed University', 'label': 'Shahed University' },
    { 'id': 10163, 'value': 'Shaheed Benazir Bhutto City University', 'label': 'Shaheed Benazir Bhutto City University' },
    { 'id': 10164, 'value': 'Shaheed Benazir Bhutto University', 'label': 'Shaheed Benazir Bhutto University' },
    { 'id': 10165, 'value': 'Shaheed Benazir Bhutto University of Veterinary and Animal Sciences', 'label': 'Shaheed Benazir Bhutto University of Veterinary and Animal Sciences' },
    { 'id': 10166, 'value': 'Shaheed Benazir Bhutto University, Shaheed Benazirabad', 'label': 'Shaheed Benazir Bhutto University, Shaheed Benazirabad' },
    { 'id': 10167, 'value': 'Shaheed Benazir Bhutto Women University', 'label': 'Shaheed Benazir Bhutto Women University' },
    { 'id': 10168, 'value': 'Shaheed Mohtarma Benazir Bhutto Medical University', 'label': 'Shaheed Mohtarma Benazir Bhutto Medical University' },
    { 'id': 764, 'value': 'Shaheed Rabani Education University', 'label': 'Shaheed Rabani Education University' },
    { 'id': 664, 'value': 'Shaheed Zulfikar Ali Bhutto Institute of Science and Technology Dubai', 'label': 'Shaheed Zulfikar Ali Bhutto Institute of Science and Technology Dubai' },
    { 'id': 10169, 'value': 'Shaheed Zulfiqar Ali Bhutto Institute of Science and Technology', 'label': 'Shaheed Zulfiqar Ali Bhutto Institute of Science and Technology' },
    { 'id': 10170, 'value': 'Shaheed Zulfiqar Ali Bhutto Medical University', 'label': 'Shaheed Zulfiqar Ali Bhutto Medical University' },
    { 'id': 10171, 'value': 'Shaheed Zulfiqar Ali Bhutto University of Law', 'label': 'Shaheed Zulfiqar Ali Bhutto University of Law' },
    { 'id': 6744, 'value': 'Shahid Bahonar University of Kerman', 'label': 'Shahid Bahonar University of Kerman' },
    { 'id': 6745, 'value': 'Shahid Beheshti University', 'label': 'Shahid Beheshti University' },
    { 'id': 6746, 'value': 'Shahid Beheshti University of Medical Sciences', 'label': 'Shahid Beheshti University of Medical Sciences' },
    { 'id': 6747, 'value': 'Shahid Chamran University of Ahvaz', 'label': 'Shahid Chamran University of Ahvaz' },
    { 'id': 6748, 'value': 'Shahid Rajaee Teacher Training University', 'label': 'Shahid Rajaee Teacher Training University' },
    { 'id': 6749, 'value': 'Shahid Sadoughi University of Medical Sciences and Health Services', 'label': 'Shahid Sadoughi University of Medical Sciences and Health Services' },
    { 'id': 6750, 'value': 'Shahid Sattari University of Aeronautical Engineering', 'label': 'Shahid Sattari University of Aeronautical Engineering' },
    { 'id': 1306, 'value': 'Shahjalal University of Science and Technology', 'label': 'Shahjalal University of Science and Technology' },
    { 'id': 6751, 'value': 'Shahrekord University', 'label': 'Shahrekord University' },
    { 'id': 6752, 'value': 'Shahrekord University of Medical Sciences', 'label': 'Shahrekord University of Medical Sciences' },
    { 'id': 6753, 'value': 'Shahrood University of Technology', 'label': 'Shahrood University of Technology' },
    { 'id': 6754, 'value': 'Shahroud University of Medical Sciences', 'label': 'Shahroud University of Medical Sciences' },
    { 'id': 765, 'value': 'Shaikh Zayed University, Khost', 'label': 'Shaikh Zayed University, Khost' },
    { 'id': 2698, 'value': 'Shandong Agricultural University', 'label': 'Shandong Agricultural University' },
    { 'id': 2699, 'value': 'Shandong Agriculture and Engineering University', 'label': 'Shandong Agriculture and Engineering University' },
    { 'id': 2700, 'value': 'Shandong Huayu University of Technology', 'label': 'Shandong Huayu University of Technology' },
    { 'id': 2701, 'value': 'Shandong Jianzhu University', 'label': 'Shandong Jianzhu University' },
    { 'id': 2702, 'value': 'Shandong Jiaotong University', 'label': 'Shandong Jiaotong University' },
    { 'id': 2703, 'value': 'Shandong Management University', 'label': 'Shandong Management University' },
    { 'id': 2704, 'value': 'Shandong Normal University', 'label': 'Shandong Normal University' },
    { 'id': 2705, 'value': 'Shandong Sport University', 'label': 'Shandong Sport University' },
    { 'id': 2706, 'value': 'Shandong Technology and Business University', 'label': 'Shandong Technology and Business University' },
    { 'id': 2707, 'value': 'Shandong University', 'label': 'Shandong University' },
    { 'id': 2708, 'value': 'Shandong University of Art and Design', 'label': 'Shandong University of Art and Design' },
    { 'id': 2709, 'value': 'Shandong University of Arts', 'label': 'Shandong University of Arts' },
    { 'id': 2710, 'value': 'Shandong University of Finance and Economics', 'label': 'Shandong University of Finance and Economics' },
    { 'id': 2711, 'value': 'Shandong University of Political Science and Law', 'label': 'Shandong University of Political Science and Law' },
    { 'id': 2712, 'value': 'Shandong University of Science and Technology', 'label': 'Shandong University of Science and Technology' },
    { 'id': 2713, 'value': 'Shandong University of Technology', 'label': 'Shandong University of Technology' },
    { 'id': 2714, 'value': 'Shandong University of Traditional Chinese Medicine', 'label': 'Shandong University of Traditional Chinese Medicine' },
    { 'id': 2715, 'value': 'Shandong Women\'s University', 'label': 'Shandong Women\'s University' },
    { 'id': 2716, 'value': 'Shandong Xiandai University', 'label': 'Shandong Xiandai University' },
    { 'id': 2717, 'value': 'Shandong Xiehe University', 'label': 'Shandong Xiehe University' },
    { 'id': 2718, 'value': 'Shandong Yingcai University', 'label': 'Shandong Yingcai University' },
    { 'id': 2719, 'value': 'Shandong Youth University of Political Science', 'label': 'Shandong Youth University of Political Science' },
    { 'id': 2720, 'value': 'Shanghai Business School', 'label': 'Shanghai Business School' },
    { 'id': 2721, 'value': 'Shanghai Conservatory of Music', 'label': 'Shanghai Conservatory of Music' },
    { 'id': 2722, 'value': 'Shanghai Dianji University', 'label': 'Shanghai Dianji University' },
    { 'id': 2723, 'value': 'Shanghai Institute of Technology', 'label': 'Shanghai Institute of Technology' },
    { 'id': 2724, 'value': 'Shanghai Institute of Visual Art', 'label': 'Shanghai Institute of Visual Art' },
    { 'id': 2725, 'value': 'Shanghai International Studies University', 'label': 'Shanghai International Studies University' },
    { 'id': 2726, 'value': 'Shanghai Jianqiao University', 'label': 'Shanghai Jianqiao University' },
    { 'id': 275, 'value': 'Shanghai Jiao Tong University', 'label': 'Shanghai Jiao Tong University' },
    { 'id': 2727, 'value': 'Shanghai Lixin University of Accounting and Finance', 'label': 'Shanghai Lixin University of Accounting and Finance' },
    { 'id': 2728, 'value': 'Shanghai Maritime University', 'label': 'Shanghai Maritime University' },
    { 'id': 2729, 'value': 'Shanghai Normal University', 'label': 'Shanghai Normal University' },
    { 'id': 2730, 'value': 'Shanghai Ocean University', 'label': 'Shanghai Ocean University' },
    { 'id': 2731, 'value': 'Shanghai Second Polytechnic University', 'label': 'Shanghai Second Polytechnic University' },
    { 'id': 2732, 'value': 'Shanghai Tech University', 'label': 'Shanghai Tech University' },
    { 'id': 2733, 'value': 'Shanghai Theatre Academy', 'label': 'Shanghai Theatre Academy' },
    { 'id': 2734, 'value': 'Shanghai University', 'label': 'Shanghai University' },
    { 'id': 2735, 'value': 'Shanghai University of Electric Power', 'label': 'Shanghai University of Electric Power' },
    { 'id': 2736, 'value': 'Shanghai University of Engineering Science', 'label': 'Shanghai University of Engineering Science' },
    { 'id': 2737, 'value': 'Shanghai University of Finance and Economics', 'label': 'Shanghai University of Finance and Economics' },
    { 'id': 2738, 'value': 'Shanghai University of International Business and Economics', 'label': 'Shanghai University of International Business and Economics' },
    { 'id': 2739, 'value': 'Shanghai University of Medicine and Health Sciences', 'label': 'Shanghai University of Medicine and Health Sciences' },
    { 'id': 2740, 'value': 'Shanghai University of Political Science and Law', 'label': 'Shanghai University of Political Science and Law' },
    { 'id': 2741, 'value': 'Shanghai University of Sport', 'label': 'Shanghai University of Sport' },
    { 'id': 2742, 'value': 'Shanghai University of Traditional Chinese Medicine', 'label': 'Shanghai University of Traditional Chinese Medicine' },
    { 'id': 2743, 'value': 'Shanghai Xingwei College', 'label': 'Shanghai Xingwei College' },
    { 'id': 2744, 'value': 'Shangluo University', 'label': 'Shangluo University' },
    { 'id': 2745, 'value': 'Shangqiu Institute of Technology', 'label': 'Shangqiu Institute of Technology' },
    { 'id': 2746, 'value': 'Shangqiu Normal University', 'label': 'Shangqiu Normal University' },
    { 'id': 2747, 'value': 'Shangqiu University', 'label': 'Shangqiu University' },
    { 'id': 2748, 'value': 'Shangrao Normal University', 'label': 'Shangrao Normal University' },
    { 'id': 1307, 'value': 'Shanto Mariam University of Creative Technology', 'label': 'Shanto Mariam University of Creative Technology' },
    { 'id': 2749, 'value': 'Shantou University', 'label': 'Shantou University' },
    { 'id': 2750, 'value': 'Shanxi Agricultural University', 'label': 'Shanxi Agricultural University' },
    { 'id': 2751, 'value': 'Shanxi College of Applied Science and Technology', 'label': 'Shanxi College of Applied Science and Technology' },
    { 'id': 2752, 'value': 'Shanxi Datong University', 'label': 'Shanxi Datong University' },
    { 'id': 2753, 'value': 'Shanxi Institute of Energy', 'label': 'Shanxi Institute of Energy' },
    { 'id': 2754, 'value': 'Shanxi Institute of Engineering and Technology', 'label': 'Shanxi Institute of Engineering and Technology' },
    { 'id': 2755, 'value': 'Shanxi Medical University', 'label': 'Shanxi Medical University' },
    { 'id': 2756, 'value': 'Shanxi Normal University', 'label': 'Shanxi Normal University' },
    { 'id': 2757, 'value': 'Shanxi Technology and Business College', 'label': 'Shanxi Technology and Business College' },
    { 'id': 2758, 'value': 'Shanxi University', 'label': 'Shanxi University' },
    { 'id': 2759, 'value': 'Shanxi University of Finance and Economics', 'label': 'Shanxi University of Finance and Economics' },
    { 'id': 2760, 'value': 'Shanxi University of Traditional Chinese Medicine', 'label': 'Shanxi University of Traditional Chinese Medicine' },
    { 'id': 2761, 'value': 'Shaoguan University', 'label': 'Shaoguan University' },
    { 'id': 2762, 'value': 'Shaoxing University', 'label': 'Shaoxing University' },
    { 'id': 2763, 'value': 'Shaoyang University', 'label': 'Shaoyang University' },
    { 'id': 11045, 'value': 'Shaqra University', 'label': 'Shaqra University' },
    { 'id': 6250, 'value': 'Sharda University', 'label': 'Sharda University' },
    { 'id': 6755, 'value': 'Sharif University of Technology', 'label': 'Sharif University of Technology' },
    { 'id': 6251, 'value': 'Sharnbasva University', 'label': 'Sharnbasva University' },
    { 'id': 766, 'value': 'Sharq Institute of Higher Education', 'label': 'Sharq Institute of Higher Education' },
    { 'id': 13378, 'value': 'Shaw University', 'label': 'Shaw University' },
    { 'id': 13379, 'value': 'Shawnee State University', 'label': 'Shawnee State University' },
    { 'id': 285, 'value': 'Sheffield Hallam University', 'label': 'Sheffield Hallam University' },
    { 'id': 1308, 'value': 'Sheikh Fazilatunnesa Mujib University', 'label': 'Sheikh Fazilatunnesa Mujib University' },
    { 'id': 1309, 'value': 'Sheikh Hasina University', 'label': 'Sheikh Hasina University' },
    { 'id': 6756, 'value': 'Sheikhbahaee University', 'label': 'Sheikhbahaee University' },
    { 'id': 13380, 'value': 'Shenandoah University', 'label': 'Shenandoah University' },
    { 'id': 5554, 'value': 'Shenkar College of Engineering and Design', 'label': 'Shenkar College of Engineering and Design' },
    { 'id': 2764, 'value': 'Shenyang Aerospace University', 'label': 'Shenyang Aerospace University' },
    { 'id': 2765, 'value': 'Shenyang Agricultural University', 'label': 'Shenyang Agricultural University' },
    { 'id': 2766, 'value': 'Shenyang City University', 'label': 'Shenyang City University' },
    { 'id': 2767, 'value': 'Shenyang Conservatory of Music', 'label': 'Shenyang Conservatory of Music' },
    { 'id': 2768, 'value': 'Shenyang Institute of Engineering', 'label': 'Shenyang Institute of Engineering' },
    { 'id': 2769, 'value': 'Shenyang Institute of Science and Technology', 'label': 'Shenyang Institute of Science and Technology' },
    { 'id': 2770, 'value': 'Shenyang Institute of Technology', 'label': 'Shenyang Institute of Technology' },
    { 'id': 2771, 'value': 'Shenyang Jianzhu University', 'label': 'Shenyang Jianzhu University' },
    { 'id': 2772, 'value': 'Shenyang Ligong University', 'label': 'Shenyang Ligong University' },
    { 'id': 2773, 'value': 'Shenyang Medical College', 'label': 'Shenyang Medical College' },
    { 'id': 2774, 'value': 'Shenyang Normal University', 'label': 'Shenyang Normal University' },
    { 'id': 2775, 'value': 'Shenyang Pharmaceutical University', 'label': 'Shenyang Pharmaceutical University' },
    { 'id': 2776, 'value': 'Shenyang Sport University', 'label': 'Shenyang Sport University' },
    { 'id': 2777, 'value': 'Shenyang University', 'label': 'Shenyang University' },
    { 'id': 2778, 'value': 'Shenyang University of Chemical Technology', 'label': 'Shenyang University of Chemical Technology' },
    { 'id': 2779, 'value': 'Shenyang University of Technology', 'label': 'Shenyang University of Technology' },
    { 'id': 2780, 'value': 'Shenyang Urban Construction University', 'label': 'Shenyang Urban Construction University' },
    { 'id': 2781, 'value': 'Shenzhen MSU-BIT University', 'label': 'Shenzhen MSU-BIT University' },
    { 'id': 2782, 'value': 'Shenzhen University', 'label': 'Shenzhen University' },
    { 'id': 13381, 'value': 'Shepherd University', 'label': 'Shepherd University' },
    { 'id': 1310, 'value': 'Sher-e-Bangla Agricultural University', 'label': 'Sher-e-Bangla Agricultural University' },
    { 'id': 6252, 'value': 'Sher-e-Kashmir University of Agricultural Sciences and Technology of Jammu', 'label': 'Sher-e-Kashmir University of Agricultural Sciences and Technology of Jammu' },
    { 'id': 6253, 'value': 'Sher-e-Kashmir University of Agricultural Sciences and Technology of Kashmir', 'label': 'Sher-e-Kashmir University of Agricultural Sciences and Technology of Kashmir' },
    { 'id': 6254, 'value': 'Sher-i-Kashmir Institute of Medical Sciences', 'label': 'Sher-i-Kashmir Institute of Medical Sciences' },
    { 'id': 7577, 'value': 'Shibaura Institute of Technology', 'label': 'Shibaura Institute of Technology' },
    { 'id': 767, 'value': 'Shifa Institute of Higher Education', 'label': 'Shifa Institute of Higher Education' },
    { 'id': 10172, 'value': 'Shifa Tameer-e-Millat University', 'label': 'Shifa Tameer-e-Millat University' },
    { 'id': 7578, 'value': 'Shiga University', 'label': 'Shiga University' },
    { 'id': 7579, 'value': 'Shiga University of Medical Science', 'label': 'Shiga University of Medical Science' },
    { 'id': 7580, 'value': 'Shigakkan University', 'label': 'Shigakkan University' },
    { 'id': 7581, 'value': 'Shigakukan University', 'label': 'Shigakukan University' },
    { 'id': 11911, 'value': 'Shih Chien University', 'label': 'Shih Chien University' },
    { 'id': 11912, 'value': 'Shih Hsin University', 'label': 'Shih Hsin University' },
    { 'id': 2783, 'value': 'Shihezi University', 'label': 'Shihezi University' },
    { 'id': 8636, 'value': 'Shihihutug Law Institute', 'label': 'Shihihutug Law Institute' },
    { 'id': 2784, 'value': 'Shijiazhuang Tiedao University', 'label': 'Shijiazhuang Tiedao University' },
    { 'id': 2785, 'value': 'Shijiazhuang University', 'label': 'Shijiazhuang University' },
    { 'id': 7582, 'value': 'Shijonawate Gakuen University', 'label': 'Shijonawate Gakuen University' },
    { 'id': 7583, 'value': 'Shikoku Gakuin University', 'label': 'Shikoku Gakuin University' },
    { 'id': 7584, 'value': 'Shikoku University', 'label': 'Shikoku University' },
    { 'id': 7585, 'value': 'Shimane University', 'label': 'Shimane University' },
    { 'id': 7586, 'value': 'Shimonoseki City University', 'label': 'Shimonoseki City University' },
    { 'id': 9642, 'value': 'Shinas College of Technology', 'label': 'Shinas College of Technology' },
    { 'id': 11466, 'value': 'Shinawatra University', 'label': 'Shinawatra University' },
    { 'id': 8637, 'value': 'Shine Irgenshil Institute', 'label': 'Shine Irgenshil Institute' },
    { 'id': 8123, 'value': 'Shingyeong University', 'label': 'Shingyeong University' },
    { 'id': 7587, 'value': 'Shinshu University', 'label': 'Shinshu University' },
    { 'id': 13382, 'value': 'Shippensburg University of Pennsylvania', 'label': 'Shippensburg University of Pennsylvania' },
    { 'id': 832, 'value': 'Shirak State University', 'label': 'Shirak State University' },
    { 'id': 7588, 'value': 'Shiraume Gakuen University', 'label': 'Shiraume Gakuen University' },
    { 'id': 7589, 'value': 'Shirayuri College', 'label': 'Shirayuri College' },
    { 'id': 6757, 'value': 'Shiraz Art University', 'label': 'Shiraz Art University' },
    { 'id': 6758, 'value': 'Shiraz University', 'label': 'Shiraz University' },
    { 'id': 6759, 'value': 'Shiraz University of Applied Science and Technology', 'label': 'Shiraz University of Applied Science and Technology' },
    { 'id': 6760, 'value': 'Shiraz University of Medical Sciences', 'label': 'Shiraz University of Medical Sciences' },
    { 'id': 6761, 'value': 'Shiraz University of Technology', 'label': 'Shiraz University of Technology' },
    { 'id': 7590, 'value': 'Shiseikan University', 'label': 'Shiseikan University' },
    { 'id': 7591, 'value': 'Shitennoji University', 'label': 'Shitennoji University' },
    { 'id': 6255, 'value': 'Shiv Nadar University', 'label': 'Shiv Nadar University' },
    { 'id': 6256, 'value': 'Shivaji University', 'label': 'Shivaji University' },
    { 'id': 7592, 'value': 'Shizuoka Eiwa Gakuin University', 'label': 'Shizuoka Eiwa Gakuin University' },
    { 'id': 7593, 'value': 'Shizuoka Institute of Science and Technology', 'label': 'Shizuoka Institute of Science and Technology' },
    { 'id': 7594, 'value': 'Shizuoka Sangyo University', 'label': 'Shizuoka Sangyo University' },
    { 'id': 7595, 'value': 'Shizuoka University', 'label': 'Shizuoka University' },
    { 'id': 7596, 'value': 'Shizuoka University of Art and Culture', 'label': 'Shizuoka University of Art and Culture' },
    { 'id': 7597, 'value': 'Shizuoka University of Welfare', 'label': 'Shizuoka University of Welfare' },
    { 'id': 6257, 'value': 'Shobhit University', 'label': 'Shobhit University' },
    { 'id': 7598, 'value': 'Shobi University', 'label': 'Shobi University' },
    { 'id': 6762, 'value': 'Shohadaye Hoveizeh University of Technology', 'label': 'Shohadaye Hoveizeh University of Technology' },
    { 'id': 7599, 'value': 'Shoin University', 'label': 'Shoin University' },
    { 'id': 7600, 'value': 'Shokei College', 'label': 'Shokei College' },
    { 'id': 7601, 'value': 'Shokei Gakuin University', 'label': 'Shokei Gakuin University' },
    { 'id': 6763, 'value': 'Shomal University', 'label': 'Shomal University' },
    { 'id': 7602, 'value': 'Shonan Institute of Technology', 'label': 'Shonan Institute of Technology' },
    { 'id': 6258, 'value': 'Shoolini University of Biotechnology and Management Sciences', 'label': 'Shoolini University of Biotechnology and Management Sciences' },
    { 'id': 13383, 'value': 'Shorter University', 'label': 'Shorter University' },
    { 'id': 4648, 'value': 'Shota Meskhia State Teaching University', 'label': 'Shota Meskhia State Teaching University' },
    { 'id': 4649, 'value': 'Shota Rustaveli Theatre and Film Georgian State University', 'label': 'Shota Rustaveli Theatre and Film Georgian State University' },
    { 'id': 2786, 'value': 'Shougang Institute of Technology', 'label': 'Shougang Institute of Technology' },
    { 'id': 7603, 'value': 'Showa Pharmaceutical University', 'label': 'Showa Pharmaceutical University' },
    { 'id': 7604, 'value': 'Showa University', 'label': 'Showa University' },
    { 'id': 7605, 'value': 'Showa University of Music', 'label': 'Showa University of Music' },
    { 'id': 7606, 'value': 'Showa Women\'s University', 'label': 'Showa Women\'s University' },
    { 'id': 6259, 'value': 'Shree Guru Gobind Singh Tricentenary University', 'label': 'Shree Guru Gobind Singh Tricentenary University' },
    { 'id': 6260, 'value': 'Shree Somnath Sanskrit University', 'label': 'Shree Somnath Sanskrit University' },
    { 'id': 6261, 'value': 'Shreemati Nathibai Damodar Thackersey Women\'s University', 'label': 'Shreemati Nathibai Damodar Thackersey Women\'s University' },
    { 'id': 6262, 'value': 'Shri Govind Guru University', 'label': 'Shri Govind Guru University' },
    { 'id': 6263, 'value': 'Shri Guru Ram Rai Education Mission', 'label': 'Shri Guru Ram Rai Education Mission' },
    { 'id': 6264, 'value': 'Shri Guru Ram Rai University', 'label': 'Shri Guru Ram Rai University' },
    { 'id': 6265, 'value': 'Shri Jagannath Sanskrit Vishvavidyalaya', 'label': 'Shri Jagannath Sanskrit Vishvavidyalaya' },
    { 'id': 6266, 'value': 'Shri Jagdishprasad Jhabrmal Tibrewala University', 'label': 'Shri Jagdishprasad Jhabrmal Tibrewala University' },
    { 'id': 6267, 'value': 'Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha', 'label': 'Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha' },
    { 'id': 6268, 'value': 'Shri Mata Vaishno Devi University', 'label': 'Shri Mata Vaishno Devi University' },
    { 'id': 6269, 'value': 'Shri Ramswaroop Memorial University', 'label': 'Shri Ramswaroop Memorial University' },
    { 'id': 6270, 'value': 'Shri Vaishnav Vidyapeeth Vishwavidyalaya', 'label': 'Shri Vaishnav Vidyapeeth Vishwavidyalaya' },
    { 'id': 6271, 'value': 'Shri Venkateshwara University', 'label': 'Shri Venkateshwara University' },
    { 'id': 6272, 'value': 'Shri Vishwakarma Skill University', 'label': 'Shri Vishwakarma Skill University' },
    { 'id': 6273, 'value': 'Shridhar University', 'label': 'Shridhar University' },
    { 'id': 11913, 'value': 'Shu-Te University', 'label': 'Shu-Te University' },
    { 'id': 7607, 'value': 'Shuchiin University', 'label': 'Shuchiin University' },
    { 'id': 7608, 'value': 'Shujitsu University', 'label': 'Shujitsu University' },
    { 'id': 7609, 'value': 'Shukutoku University', 'label': 'Shukutoku University' },
    { 'id': 7610, 'value': 'Shumei University', 'label': 'Shumei University' },
    { 'id': 12115, 'value': 'Shupyk National Medical Academy of Postgraduate Education', 'label': 'Shupyk National Medical Academy of Postgraduate Education' },
    { 'id': 8530, 'value': 'Shwebo University', 'label': 'Shwebo University' },
    { 'id': 8248, 'value': 'Shymkent University', 'label': 'Shymkent University' },
    { 'id': 11467, 'value': 'Siam University', 'label': 'Siam University' },
    { 'id': 8354, 'value': 'Šiauliu Universitetas', 'label': 'Šiauliu Universitetas' },
    { 'id': 8355, 'value': 'Šiauliu valstybine kolegija', 'label': 'Šiauliu valstybine kolegija' },
    { 'id': 8356, 'value': 'Šiaures Lietuvos kolegija', 'label': 'Šiaures Lietuvos kolegija' },
    { 'id': 10891, 'value': 'Siberian Federal University', 'label': 'Siberian Federal University' },
    { 'id': 10892, 'value': 'Siberian State Aerospace University', 'label': 'Siberian State Aerospace University' },
    { 'id': 10893, 'value': 'Siberian State Industrial University', 'label': 'Siberian State Industrial University' },
    { 'id': 10894, 'value': 'Siberian State Medical University', 'label': 'Siberian State Medical University' },
    { 'id': 10895, 'value': 'Siberian State Transport University', 'label': 'Siberian State Transport University' },
    { 'id': 10896, 'value': 'Siberian State University of Physical Culture and Sports', 'label': 'Siberian State University of Physical Culture and Sports' },
    { 'id': 10897, 'value': 'Siberian State University of Telecommunications and Informatics', 'label': 'Siberian State University of Telecommunications and Informatics' },
    { 'id': 2787, 'value': 'Sichuan Agricultural University', 'label': 'Sichuan Agricultural University' },
    { 'id': 2788, 'value': 'Sichuan Conservatory of Music', 'label': 'Sichuan Conservatory of Music' },
    { 'id': 2789, 'value': 'Sichuan Film and Television University', 'label': 'Sichuan Film and Television University' },
    { 'id': 2790, 'value': 'Sichuan Fine Arts Institute', 'label': 'Sichuan Fine Arts Institute' },
    { 'id': 2791, 'value': 'Sichuan Institute of Industrial Technology', 'label': 'Sichuan Institute of Industrial Technology' },
    { 'id': 2792, 'value': 'Sichuan International Studies University', 'label': 'Sichuan International Studies University' },
    { 'id': 2793, 'value': 'Sichuan Normal University', 'label': 'Sichuan Normal University' },
    { 'id': 2794, 'value': 'Sichuan Tourism University', 'label': 'Sichuan Tourism University' },
    { 'id': 2795, 'value': 'Sichuan University', 'label': 'Sichuan University' },
    { 'id': 2796, 'value': 'Sichuan University for Nationalities', 'label': 'Sichuan University for Nationalities' },
    { 'id': 2797, 'value': 'Sichuan University of Arts and Science', 'label': 'Sichuan University of Arts and Science' },
    { 'id': 2798, 'value': 'Sichuan University of Culture and Arts', 'label': 'Sichuan University of Culture and Arts' },
    { 'id': 2799, 'value': 'Sichuan University of Media and Communications', 'label': 'Sichuan University of Media and Communications' },
    { 'id': 2800, 'value': 'Sichuan University of Science and Engineering', 'label': 'Sichuan University of Science and Engineering' },
    { 'id': 14319, 'value': 'SID - Scuola Italiana Design', 'label': 'SID - Scuola Italiana Design' },
    { 'id': 6274, 'value': 'Siddharth University', 'label': 'Siddharth University' },
    { 'id': 14398, 'value': 'Side Academy', 'label': 'Side Academy' },
    { 'id': 6275, 'value': 'Sidho Kanho Birsha University', 'label': 'Sidho Kanho Birsha University' },
    { 'id': 6276, 'value': 'Sido Kanhu Murmu University', 'label': 'Sido Kanhu Murmu University' },
    { 'id': 7611, 'value': 'Siebold University of Nagasaki', 'label': 'Siebold University of Nagasaki' },
    { 'id': 13384, 'value': 'Siena College', 'label': 'Siena College' },
    { 'id': 9964, 'value': 'Siena College of Taytay', 'label': 'Siena College of Taytay' },
    { 'id': 13385, 'value': 'Siena Heights University', 'label': 'Siena Heights University' },
    { 'id': 7007, 'value': 'Siena Jazz - Accademia nazionale del Jazz', 'label': 'Siena Jazz - Accademia nazionale del Jazz' },
    { 'id': 13386, 'value': 'Sierra Nevada University', 'label': 'Sierra Nevada University' },
    { 'id': 1057, 'value': 'Sigmund Freud Privatuniversität Wien', 'label': 'Sigmund Freud Privatuniversität Wien' },
    { 'id': 11773, 'value': 'Siirt Üniversitesi', 'label': 'Siirt Üniversitesi' },
    { 'id': 6277, 'value': 'Sikkim Manipal University', 'label': 'Sikkim Manipal University' },
    { 'id': 6278, 'value': 'Sikkim University', 'label': 'Sikkim University' },
    { 'id': 6279, 'value': 'Siksha O Anusandhan University', 'label': 'Siksha O Anusandhan University' },
    { 'id': 8249, 'value': 'SILKWAY International University', 'label': 'SILKWAY International University' },
    { 'id': 8124, 'value': 'Silla University', 'label': 'Silla University' },
    { 'id': 9965, 'value': 'Silliman University', 'label': 'Silliman University' },
    { 'id': 11468, 'value': 'Silpakorn University', 'label': 'Silpakorn University' },
    { 'id': 13387, 'value': 'Simmons University', 'label': 'Simmons University' },
    { 'id': 201, 'value': 'Simon Fraser University', 'label': 'Simon Fraser University' },
    { 'id': 12116, 'value': 'Simon Kuznets Kharkiv National University of Economics', 'label': 'Simon Kuznets Kharkiv National University of Economics' },
    { 'id': 13388, 'value': 'Simpson College', 'label': 'Simpson College' },
    { 'id': 13389, 'value': 'Simpson University', 'label': 'Simpson University' },
    { 'id': 4079, 'value': 'Sinai University', 'label': 'Sinai University' },
    { 'id': 10173, 'value': 'Sindh Agriculture University', 'label': 'Sindh Agriculture University' },
    { 'id': 10174, 'value': 'Sindh Madresatul Islam University', 'label': 'Sindh Madresatul Islam University' },
    { 'id': 11151, 'value': 'Singapore Institute of Technology', 'label': 'Singapore Institute of Technology' },
    { 'id': 11152, 'value': 'Singapore Management University', 'label': 'Singapore Management University' },
    { 'id': 11153, 'value': 'Singapore University of Social Sciences', 'label': 'Singapore University of Social Sciences' },
    { 'id': 11154, 'value': 'Singapore University of Technology and Design', 'label': 'Singapore University of Technology and Design' },
    { 'id': 6280, 'value': 'Singhania University', 'label': 'Singhania University' },
    { 'id': 11079, 'value': 'Sinnar University', 'label': 'Sinnar University' },
    { 'id': 11774, 'value': 'Sinop Üniversitesi', 'label': 'Sinop Üniversitesi' },
    { 'id': 6281, 'value': 'Sir Padampat Singhania University', 'label': 'Sir Padampat Singhania University' },
    { 'id': 10175, 'value': 'Sir Syed University of Engineering and Technology', 'label': 'Sir Syed University of Engineering and Technology' },
    { 'id': 6764, 'value': 'Sirjan University of Technology', 'label': 'Sirjan University of Technology' },
    { 'id': 11775, 'value': 'Sirnak Üniversitesi', 'label': 'Sirnak Üniversitesi' },
    { 'id': 8404, 'value': 'Sirte University', 'label': 'Sirte University' },
    { 'id': 11469, 'value': 'Sisaket Rajabhat University', 'label': 'Sisaket Rajabhat University' },
    { 'id': 4032, 'value': 'Sisekaitseakadeemia', 'label': 'Sisekaitseakadeemia' },
    { 'id': 13390, 'value': 'SIT Graduate Institute', 'label': 'SIT Graduate Institute' },
    { 'id': 8531, 'value': 'Sittwe University', 'label': 'Sittwe University' },
    { 'id': 11776, 'value': 'Sivas Cumhuriyet Üniversitesi', 'label': 'Sivas Cumhuriyet Üniversitesi' },
    { 'id': 183, 'value': 'SKEMA Business School', 'label': 'SKEMA Business School' },
    { 'id': 13391, 'value': 'Skidmore College', 'label': 'Skidmore College' },
    { 'id': 3413, 'value': 'Škoda Auto Vysoká škola', 'label': 'Škoda Auto Vysoká škola' },
    { 'id': 665, 'value': 'Skyline University College', 'label': 'Skyline University College' },
    { 'id': 9425, 'value': 'Skyline University Nigeria', 'label': 'Skyline University Nigeria' },
    { 'id': 8691, 'value': 'Skyway University', 'label': 'Skyway University' },
    { 'id': 10275, 'value': 'Slaski Uniwersytet Medyczny w Katowicach', 'label': 'Slaski Uniwersytet Medyczny w Katowicach' },
    { 'id': 3414, 'value': 'Slezská univerzita v Opave', 'label': 'Slezská univerzita v Opave' },
    { 'id': 13392, 'value': 'Slippery Rock University of Pennsylvania', 'label': 'Slippery Rock University of Pennsylvania' },
    { 'id': 1170, 'value': 'Slobomir P Univerzitet', 'label': 'Slobomir P Univerzitet' },
    { 'id': 11193, 'value': 'Slovenská Polnohospodárska Univerzita v Nitre', 'label': 'Slovenská Polnohospodárska Univerzita v Nitre' },
    { 'id': 11194, 'value': 'Slovenská technická univerzita v Bratislave', 'label': 'Slovenská technická univerzita v Bratislave' },
    { 'id': 11195, 'value': 'Slovenská zdravotnícka univerzita v Bratislave', 'label': 'Slovenská zdravotnícka univerzita v Bratislave' },
    { 'id': 13393, 'value': 'Smith College', 'label': 'Smith College' },
    { 'id': 10898, 'value': 'Smolensk State University', 'label': 'Smolensk State University' },
    { 'id': 13394, 'value': 'Snow College', 'label': 'Snow College' },
    { 'id': 7612, 'value': 'Soai University', 'label': 'Soai University' },
    { 'id': 194, 'value': 'SOAS University of London', 'label': 'SOAS University of London' },
    { 'id': 4556, 'value': 'SOAS, University of London', 'label': 'SOAS, University of London' },
    { 'id': 8391, 'value': 'Sociala darba un socialas pedagogijas augstskola Attistiba', 'label': 'Sociala darba un socialas pedagogijas augstskola Attistiba' },
    { 'id': 8357, 'value': 'Socialiniu mokslu kolegija', 'label': 'Socialiniu mokslu kolegija' },
    { 'id': 11139, 'value': 'Södertörns högskola', 'label': 'Södertörns högskola' },
    { 'id': 13395, 'value': 'Sofia University', 'label': 'Sofia University' },
    { 'id': 1444, 'value': 'Sofia University St. Kliment Ohridski', 'label': 'Sofia University St. Kliment Ohridski' },
    { 'id': 8125, 'value': 'Sogang University', 'label': 'Sogang University' },
    { 'id': 4080, 'value': 'Sohag university', 'label': 'Sohag university' },
    { 'id': 9643, 'value': 'Sohar University', 'label': 'Sohar University' },
    { 'id': 7613, 'value': 'Sojo University', 'label': 'Sojo University' },
    { 'id': 7614, 'value': 'Soka University', 'label': 'Soka University' },
    { 'id': 13396, 'value': 'Soka University of America', 'label': 'Soka University of America' },
    { 'id': 4650, 'value': 'Sokhumi State University', 'label': 'Sokhumi State University' },
    { 'id': 11965, 'value': 'Sokoine University of Agriculture', 'label': 'Sokoine University of Agriculture' },
    { 'id': 9426, 'value': 'Sokoto State University', 'label': 'Sokoto State University' },
    { 'id': 14229, 'value': 'Sol Plaatje University', 'label': 'Sol Plaatje University' },
    { 'id': 4557, 'value': 'Solent University', 'label': 'Solent University' },
    { 'id': 11055, 'value': 'Solomon Islands National University', 'label': 'Solomon Islands National University' },
    { 'id': 14314, 'value': 'Solusi University', 'label': 'Solusi University' },
    { 'id': 1311, 'value': 'Sonargaon University', 'label': 'Sonargaon University' },
    { 'id': 11470, 'value': 'Songkhla Rajabhat University', 'label': 'Songkhla Rajabhat University' },
    { 'id': 7615, 'value': 'Sonoda Women\'s University', 'label': 'Sonoda Women\'s University' },
    { 'id': 13397, 'value': 'Sonoma State University', 'label': 'Sonoma State University' },
    { 'id': 2801, 'value': 'Soochow University', 'label': 'Soochow University' },
    { 'id': 11914, 'value': 'Soochow University, Taiwan', 'label': 'Soochow University, Taiwan' },
    { 'id': 8126, 'value': 'Sookmyung Women\'s University', 'label': 'Sookmyung Women\'s University' },
    { 'id': 8127, 'value': 'Soonchunhyang University', 'label': 'Soonchunhyang University' },
    { 'id': 8128, 'value': 'Soongsil University', 'label': 'Soongsil University' },
    { 'id': 7616, 'value': 'Sophia University', 'label': 'Sophia University' },
    { 'id': 11140, 'value': 'Sophiahemmet Högskola', 'label': 'Sophiahemmet Högskola' },
    { 'id': 4922, 'value': 'Soproni Egyetem', 'label': 'Soproni Egyetem' },
    { 'id': 6465, 'value': 'Soran University', 'label': 'Soran University' },
    { 'id': 4401, 'value': 'Sorbonne Université', 'label': 'Sorbonne Université' },
    { 'id': 12194, 'value': 'Soroti University', 'label': 'Soroti University' },
    { 'id': 9966, 'value': 'Sorsogon State College', 'label': 'Sorsogon State College' },
    { 'id': 14353, 'value': 'Sotheby’s Institute Art London', 'label': 'Sotheby’s Institute Art London' },
    { 'id': 3415, 'value': 'Soukromá vysoká škola ekonomická Znojmo', 'label': 'Soukromá vysoká škola ekonomická Znojmo' },
    { 'id': 6282, 'value': 'South Asian University', 'label': 'South Asian University' },
    { 'id': 13398, 'value': 'South Carolina State University', 'label': 'South Carolina State University' },
    { 'id': 4402, 'value': 'South Champagne Business School', 'label': 'South Champagne Business School' },
    { 'id': 2802, 'value': 'South China Agricultural University', 'label': 'South China Agricultural University' },
    { 'id': 2803, 'value': 'South China Normal University', 'label': 'South China Normal University' },
    { 'id': 2804, 'value': 'South China University of Technology', 'label': 'South China University of Technology' },
    { 'id': 13399, 'value': 'South College', 'label': 'South College' },
    { 'id': 13400, 'value': 'South Dakota School of Mines and Technology', 'label': 'South Dakota School of Mines and Technology' },
    { 'id': 13401, 'value': 'South Dakota State University', 'label': 'South Dakota State University' },
    { 'id': 8481, 'value': 'South East European University', 'label': 'South East European University' },
    { 'id': 7824, 'value': 'South Eastern Kenya University', 'label': 'South Eastern Kenya University' },
    { 'id': 8315, 'value': 'South Eastern University of Sri Lanka', 'label': 'South Eastern University of Sri Lanka' },
    { 'id': 13402, 'value': 'South Georgia State College', 'label': 'South Georgia State College' },
    { 'id': 8250, 'value': 'South Kazakhstan Medical Academy', 'label': 'South Kazakhstan Medical Academy' },
    { 'id': 8251, 'value': 'South Kazakhstan State Pedagogical Institute', 'label': 'South Kazakhstan State Pedagogical Institute' },
    { 'id': 8252, 'value': 'South Kazakhstan State University', 'label': 'South Kazakhstan State University' },
    { 'id': 13403, 'value': 'South Texas College', 'label': 'South Texas College' },
    { 'id': 13404, 'value': 'South Texas College of Law', 'label': 'South Texas College of Law' },
    { 'id': 12117, 'value': 'South Ukrainian National Pedagogical University', 'label': 'South Ukrainian National Pedagogical University' },
    { 'id': 13405, 'value': 'South University', 'label': 'South University' },
    { 'id': 2805, 'value': 'South University of Science and Technology', 'label': 'South University of Science and Technology' },
    { 'id': 10899, 'value': 'South Ural State University', 'label': 'South Ural State University' },
    { 'id': 4081, 'value': 'South Valley University', 'label': 'South Valley University' },
    { 'id': 2806, 'value': 'South-Central University for Nationalities', 'label': 'South-Central University for Nationalities' },
    { 'id': 11471, 'value': 'South-East Asia University', 'label': 'South-East Asia University' },
    { 'id': 8253, 'value': 'South-Kazakhstan Humanitarian Institute', 'label': 'South-Kazakhstan Humanitarian Institute' },
    { 'id': 10901, 'value': 'South-Russian State Technical University', 'label': 'South-Russian State Technical University' },
    { 'id': 1445, 'value': 'South-West University "Neofit Rilski"', 'label': 'South-West University "Neofit Rilski"' },
    { 'id': 10902, 'value': 'South-Western State University', 'label': 'South-Western State University' },
    { 'id': 13406, 'value': 'Southeast Missouri State University', 'label': 'Southeast Missouri State University' },
    { 'id': 2807, 'value': 'Southeast University', 'label': 'Southeast University' },
    { 'id': 1312, 'value': 'Southeast University, Bangladesh', 'label': 'Southeast University, Bangladesh' },
    { 'id': 13407, 'value': 'Southeastern Baptist College', 'label': 'Southeastern Baptist College' },
    { 'id': 13408, 'value': 'Southeastern Louisiana University', 'label': 'Southeastern Louisiana University' },
    { 'id': 13409, 'value': 'Southeastern Oklahoma State University', 'label': 'Southeastern Oklahoma State University' },
    { 'id': 13410, 'value': 'Southeastern University', 'label': 'Southeastern University' },
    { 'id': 14279, 'value': 'Southern Acacia University', 'label': 'Southern Acacia University' },
    { 'id': 13411, 'value': 'Southern Adventist University', 'label': 'Southern Adventist University' },
    { 'id': 11360, 'value': 'Southern African Nazarene University', 'label': 'Southern African Nazarene University' },
    { 'id': 13412, 'value': 'Southern Arkansas University', 'label': 'Southern Arkansas University' },
    { 'id': 13413, 'value': 'Southern California Institute of Architecture', 'label': 'Southern California Institute of Architecture' },
    { 'id': 489, 'value': 'Southern California Institute of Architecture (SCI-Arc)', 'label': 'Southern California Institute of Architecture (SCI-Arc)' },
    { 'id': 13414, 'value': 'Southern College of Optometry', 'label': 'Southern College of Optometry' },
    { 'id': 13415, 'value': 'Southern Connecticut State University', 'label': 'Southern Connecticut State University' },
    { 'id': 1087, 'value': 'Southern Cross University', 'label': 'Southern Cross University' },
    { 'id': 10900, 'value': 'Southern Federal University', 'label': 'Southern Federal University' },
    { 'id': 13416, 'value': 'Southern Illinois University Carbondale', 'label': 'Southern Illinois University Carbondale' },
    { 'id': 13417, 'value': 'Southern Illinois University Edwardsville', 'label': 'Southern Illinois University Edwardsville' },
    { 'id': 9967, 'value': 'Southern Leyte State University', 'label': 'Southern Leyte State University' },
    { 'id': 9968, 'value': 'Southern Luzon State University', 'label': 'Southern Luzon State University' },
    { 'id': 2808, 'value': 'Southern Medical University', 'label': 'Southern Medical University' },
    { 'id': 13418, 'value': 'Southern Methodist University', 'label': 'Southern Methodist University' },
    { 'id': 13419, 'value': 'Southern Nazarene University', 'label': 'Southern Nazarene University' },
    { 'id': 13420, 'value': 'Southern New Hampshire University', 'label': 'Southern New Hampshire University' },
    { 'id': 13421, 'value': 'Southern Oregon University', 'label': 'Southern Oregon University' },
    { 'id': 11915, 'value': 'Southern Taiwan University of Science and Technology', 'label': 'Southern Taiwan University of Science and Technology' },
    { 'id': 6466, 'value': 'Southern Technical University', 'label': 'Southern Technical University' },
    { 'id': 14280, 'value': 'Southern University', 'label': 'Southern University' },
    { 'id': 13422, 'value': 'Southern University and A&M College', 'label': 'Southern University and A&M College' },
    { 'id': 13423, 'value': 'Southern University at New Orleans', 'label': 'Southern University at New Orleans' },
    { 'id': 1313, 'value': 'Southern University Bangladesh', 'label': 'Southern University Bangladesh' },
    { 'id': 13424, 'value': 'Southern University Law Center', 'label': 'Southern University Law Center' },
    { 'id': 306, 'value': 'SOUTHERN UTAH UNIVERSITY', 'label': 'SOUTHERN UTAH UNIVERSITY' },
    { 'id': 13425, 'value': 'Southern Vermont College', 'label': 'Southern Vermont College' },
    { 'id': 13426, 'value': 'Southern Virginia University', 'label': 'Southern Virginia University' },
    { 'id': 13427, 'value': 'Southern Wesleyan University', 'label': 'Southern Wesleyan University' },
    { 'id': 13428, 'value': 'Southwest Baptist University', 'label': 'Southwest Baptist University' },
    { 'id': 2809, 'value': 'Southwest Forestry University', 'label': 'Southwest Forestry University' },
    { 'id': 2810, 'value': 'Southwest Jiaotong University', 'label': 'Southwest Jiaotong University' },
    { 'id': 2811, 'value': 'Southwest Medical University', 'label': 'Southwest Medical University' },
    { 'id': 13429, 'value': 'Southwest Minnesota State University', 'label': 'Southwest Minnesota State University' },
    { 'id': 2812, 'value': 'Southwest Petroleum University', 'label': 'Southwest Petroleum University' },
    { 'id': 2813, 'value': 'Southwest University', 'label': 'Southwest University' },
    { 'id': 2814, 'value': 'Southwest University for Nationalities', 'label': 'Southwest University for Nationalities' },
    { 'id': 2815, 'value': 'Southwest University of Political Science and Law', 'label': 'Southwest University of Political Science and Law' },
    { 'id': 2816, 'value': 'Southwest University of Science and Technology', 'label': 'Southwest University of Science and Technology' },
    { 'id': 13430, 'value': 'Southwest University of Visual Arts', 'label': 'Southwest University of Visual Arts' },
    { 'id': 13431, 'value': 'Southwestern Adventist University', 'label': 'Southwestern Adventist University' },
    { 'id': 13432, 'value': 'Southwestern Assemblies of God University', 'label': 'Southwestern Assemblies of God University' },
    { 'id': 13433, 'value': 'Southwestern Christian University', 'label': 'Southwestern Christian University' },
    { 'id': 13434, 'value': 'Southwestern College', 'label': 'Southwestern College' },
    { 'id': 13435, 'value': 'Southwestern College, Santa Fe', 'label': 'Southwestern College, Santa Fe' },
    { 'id': 13436, 'value': 'Southwestern Law School', 'label': 'Southwestern Law School' },
    { 'id': 13437, 'value': 'Southwestern Oklahoma State University', 'label': 'Southwestern Oklahoma State University' },
    { 'id': 13438, 'value': 'Southwestern University', 'label': 'Southwestern University' },
    { 'id': 2817, 'value': 'Southwestern University of Finance and Economics', 'label': 'Southwestern University of Finance and Economics' },
    { 'id': 9969, 'value': 'Southwestern University PHINMA', 'label': 'Southwestern University PHINMA' },
    { 'id': 9427, 'value': 'Southwestern University, Nigeria', 'label': 'Southwestern University, Nigeria' },
    { 'id': 8638, 'value': 'Soyol-Erdem College', 'label': 'Soyol-Erdem College' },
    { 'id': 13439, 'value': 'Spalding University', 'label': 'Spalding University' },
    { 'id': 14378, 'value': 'SPD Scuola Politecnica di design', 'label': 'SPD Scuola Politecnica di design' },
    { 'id': 13440, 'value': 'Spelman College', 'label': 'Spelman College' },
    { 'id': 6283, 'value': 'Spicer Adventist University', 'label': 'Spicer Adventist University' },
    { 'id': 4710, 'value': 'Spiritan University College', 'label': 'Spiritan University College' },
    { 'id': 9428, 'value': 'Spiritan University, Nneochi', 'label': 'Spiritan University, Nneochi' },
    { 'id': 13441, 'value': 'Spring Arbor University', 'label': 'Spring Arbor University' },
    { 'id': 13442, 'value': 'Spring Hill College', 'label': 'Spring Hill College' },
    { 'id': 13443, 'value': 'Springfield College', 'label': 'Springfield College' },
    { 'id': 6284, 'value': 'Sree Chitra Thirunal Institute of Medical Sciences and Technology', 'label': 'Sree Chitra Thirunal Institute of Medical Sciences and Technology' },
    { 'id': 6285, 'value': 'Sree Sankaracharya University of Sanskrit', 'label': 'Sree Sankaracharya University of Sanskrit' },
    { 'id': 3735, 'value': 'SRH Fachhochschule für Gesundheit Gera', 'label': 'SRH Fachhochschule für Gesundheit Gera' },
    { 'id': 3736, 'value': 'SRH Fernhochschule', 'label': 'SRH Fernhochschule' },
    { 'id': 3737, 'value': 'SRH Hochschule Berlin', 'label': 'SRH Hochschule Berlin' },
    { 'id': 3738, 'value': 'SRH Hochschule der populären Künste', 'label': 'SRH Hochschule der populären Künste' },
    { 'id': 3739, 'value': 'SRH Hochschule Hamm', 'label': 'SRH Hochschule Hamm' },
    { 'id': 3740, 'value': 'SRH Hochschule Heidelberg', 'label': 'SRH Hochschule Heidelberg' },
    { 'id': 6286, 'value': 'Sri Balaji Vidyapeeth', 'label': 'Sri Balaji Vidyapeeth' },
    { 'id': 6287, 'value': 'Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya', 'label': 'Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya' },
    { 'id': 6288, 'value': 'Sri Dev Suman Uttarakhand University', 'label': 'Sri Dev Suman Uttarakhand University' },
    { 'id': 6289, 'value': 'Sri Devaraj Urs Academy of Higher Education and Research', 'label': 'Sri Devaraj Urs Academy of Higher Education and Research' },
    { 'id': 6290, 'value': 'Sri Guru Granth Sahib World University', 'label': 'Sri Guru Granth Sahib World University' },
    { 'id': 6291, 'value': 'Sri Guru Ram Das University of Health Sciences', 'label': 'Sri Guru Ram Das University of Health Sciences' },
    { 'id': 6292, 'value': 'Sri Konda Laxman Telangana State Horticultural University', 'label': 'Sri Konda Laxman Telangana State Horticultural University' },
    { 'id': 6293, 'value': 'Sri Krishnadevaraya University', 'label': 'Sri Krishnadevaraya University' },
    { 'id': 6294, 'value': 'Sri Padmavati Mahila Visvavidyalayam', 'label': 'Sri Padmavati Mahila Visvavidyalayam' },
    { 'id': 6295, 'value': 'Sri Ramachandra Institute of Higher Education and Research', 'label': 'Sri Ramachandra Institute of Higher Education and Research' },
    { 'id': 6296, 'value': 'Sri Sai University', 'label': 'Sri Sai University' },
    { 'id': 6297, 'value': 'Sri Sathya Sai Institute of Higher Learning', 'label': 'Sri Sathya Sai Institute of Higher Learning' },
    { 'id': 6298, 'value': 'Sri Satya Sai University of Technology and Medical Sciences', 'label': 'Sri Satya Sai University of Technology and Medical Sciences' },
    { 'id': 6299, 'value': 'Sri Siddhartha Academy of Higher Education', 'label': 'Sri Siddhartha Academy of Higher Education' },
    { 'id': 6300, 'value': 'Sri Sri University', 'label': 'Sri Sri University' },
    { 'id': 6301, 'value': 'Sri Venkateswara Institute of Medical Sciences', 'label': 'Sri Venkateswara Institute of Medical Sciences' },
    { 'id': 6302, 'value': 'Sri Venkateswara University', 'label': 'Sri Venkateswara University' },
    { 'id': 6303, 'value': 'Sri Venkateswara Vedic University', 'label': 'Sri Venkateswara Vedic University' },
    { 'id': 6304, 'value': 'Sri Venkateswara Veterinary University', 'label': 'Sri Venkateswara Veterinary University' },
    { 'id': 6305, 'value': 'Srimanta Sankaradeva University of Health Sciences', 'label': 'Srimanta Sankaradeva University of Health Sciences' },
    { 'id': 11472, 'value': 'Srinakharinwirot University', 'label': 'Srinakharinwirot University' },
    { 'id': 6306, 'value': 'Srinivas University', 'label': 'Srinivas University' },
    { 'id': 11473, 'value': 'Sripatum University', 'label': 'Sripatum University' },
    { 'id': 6307, 'value': 'SRM Institute of Science and Technology', 'label': 'SRM Institute of Science and Technology' },
    { 'id': 6308, 'value': 'SRM University Haryana', 'label': 'SRM University Haryana' },
    { 'id': 6309, 'value': 'SRM University, Sikkim', 'label': 'SRM University, Sikkim' },
    { 'id': 368, 'value': 'SSML "Vittoria" -  Scuola Superiore Mediatori Linguistici "Vittoria"', 'label': 'SSML "Vittoria" -  Scuola Superiore Mediatori Linguistici "Vittoria"' },
    { 'id': 545, 'value': 'SSML Armando Curcio', 'label': 'SSML Armando Curcio' },
    { 'id': 532, 'value': 'SSML Carlo Bo - Firenze', 'label': 'SSML Carlo Bo - Firenze' },
    { 'id': 570, 'value': 'SSML Carlo Bo - Milano', 'label': 'SSML Carlo Bo - Milano' },
    { 'id': 14415, 'value': 'SSML Carlo Bo - Roma', 'label': 'SSML Carlo Bo - Roma' },
    { 'id': 14331, 'value': 'SSML Carlo Bo –  Bologna', 'label': 'SSML Carlo Bo –  Bologna' },
    { 'id': 522, 'value': 'SSML Centro Masterly', 'label': 'SSML Centro Masterly' },
    { 'id': 14358, 'value': 'SSML di Vicenza – FUSP', 'label': 'SSML di Vicenza – FUSP' },
    { 'id': 216, 'value': 'SSML Gregorio VII', 'label': 'SSML Gregorio VII' },
    { 'id': 518, 'value': 'SSML Padova', 'label': 'SSML Padova' },
    { 'id': 583, 'value': 'SSML San Domenico', 'label': 'SSML San Domenico' },
    { 'id': 533, 'value': 'SSML Unicollege Firenze', 'label': 'SSML Unicollege Firenze' },
    { 'id': 610, 'value': 'SSML“P. M. Loria” della Società Umanitaria', 'label': 'SSML“P. M. Loria” della Società Umanitaria' },
    { 'id': 13444, 'value': 'St Andrews University', 'label': 'St Andrews University' },
    { 'id': 7617, 'value': 'St Catherine University', 'label': 'St Catherine University' },
    { 'id': 4558, 'value': 'St George\'s, University of London', 'label': 'St George\'s, University of London' },
    { 'id': 13445, 'value': 'St John\'s College', 'label': 'St John\'s College' },
    { 'id': 11916, 'value': 'St John\'s University', 'label': 'St John\'s University' },
    { 'id': 11966, 'value': 'St John\'s University of Tanzania', 'label': 'St John\'s University of Tanzania' },
    { 'id': 12195, 'value': 'St Lawrence University', 'label': 'St Lawrence University' },
    { 'id': 11289, 'value': 'St Mary\'s College', 'label': 'St Mary\'s College' },
    { 'id': 1897, 'value': 'St Mary\'s University', 'label': 'St Mary\'s University' },
    { 'id': 4559, 'value': 'St Mary\'s University, Twickenham', 'label': 'St Mary\'s University, Twickenham' },
    { 'id': 13446, 'value': 'St Petersburg College', 'label': 'St Petersburg College' },
    { 'id': 13447, 'value': 'St Thomas University', 'label': 'St Thomas University' },
    { 'id': 11967, 'value': 'St. Augustine University of Tanzania', 'label': 'St. Augustine University of Tanzania' },
    { 'id': 13448, 'value': 'St. Augustine\'s University', 'label': 'St. Augustine\'s University' },
    { 'id': 13449, 'value': 'St. Bonaventure University', 'label': 'St. Bonaventure University' },
    { 'id': 13450, 'value': 'St. Catherine University', 'label': 'St. Catherine University' },
    { 'id': 13451, 'value': 'St. Cloud State University', 'label': 'St. Cloud State University' },
    { 'id': 1446, 'value': 'St. Cyril and St. Methodius University of Veliko Tarnovo', 'label': 'St. Cyril and St. Methodius University of Veliko Tarnovo' },
    { 'id': 13452, 'value': 'St. Edward\'s University', 'label': 'St. Edward\'s University' },
    { 'id': 13453, 'value': 'St. Francis College', 'label': 'St. Francis College' },
    { 'id': 1898, 'value': 'St. Francis Xavier University', 'label': 'St. Francis Xavier University' },
    { 'id': 4614, 'value': 'St. George\'s University', 'label': 'St. George\'s University' },
    { 'id': 13454, 'value': 'St. John Fisher College', 'label': 'St. John Fisher College' },
    { 'id': 8692, 'value': 'St. John the Baptist University', 'label': 'St. John the Baptist University' },
    { 'id': 13455, 'value': 'St. John\'s University', 'label': 'St. John\'s University' },
    { 'id': 512, 'value': 'St. John’s University - Rome', 'label': 'St. John’s University - Rome' },
    { 'id': 6310, 'value': 'St. Joseph University', 'label': 'St. Joseph University' },
    { 'id': 11968, 'value': 'St. Joseph University in Tanzania', 'label': 'St. Joseph University in Tanzania' },
    { 'id': 13456, 'value': 'St. Joseph\'s College', 'label': 'St. Joseph\'s College' },
    { 'id': 9970, 'value': 'St. Joseph\'s College of Quezon City', 'label': 'St. Joseph\'s College of Quezon City' },
    { 'id': 4651, 'value': 'St. King Tamar University of Patriarchate of Georgia', 'label': 'St. King Tamar University of Patriarchate of Georgia' },
    { 'id': 13457, 'value': 'St. Lawrence University', 'label': 'St. Lawrence University' },
    { 'id': 13458, 'value': 'St. Louis Christian College', 'label': 'St. Louis Christian College' },
    { 'id': 13459, 'value': 'St. Louis College of Pharmacy', 'label': 'St. Louis College of Pharmacy' },
    { 'id': 9971, 'value': 'St. Louis College Valenzuela', 'label': 'St. Louis College Valenzuela' },
    { 'id': 9972, 'value': 'St. Luke\'s College of Medicine - WHQM', 'label': 'St. Luke\'s College of Medicine - WHQM' },
    { 'id': 7618, 'value': 'St. Luke\'s International University', 'label': 'St. Luke\'s International University' },
    { 'id': 7619, 'value': 'St. Marianna University School of Medicine', 'label': 'St. Marianna University School of Medicine' },
    { 'id': 9973, 'value': 'St. Mary\'s College', 'label': 'St. Mary\'s College' },
    { 'id': 13460, 'value': 'St. Mary\'s College of Maryland', 'label': 'St. Mary\'s College of Maryland' },
    { 'id': 13461, 'value': 'St. Mary\'s University', 'label': 'St. Mary\'s University' },
    { 'id': 8161, 'value': 'St. Matthew\'s University', 'label': 'St. Matthew\'s University' },
    { 'id': 13462, 'value': 'St. Norbert College', 'label': 'St. Norbert College' },
    { 'id': 13463, 'value': 'St. Olaf College', 'label': 'St. Olaf College' },
    { 'id': 9974, 'value': 'St. Paul University Dumaguete', 'label': 'St. Paul University Dumaguete' },
    { 'id': 9975, 'value': 'St. Paul University Iloilo', 'label': 'St. Paul University Iloilo' },
    { 'id': 9976, 'value': 'St. Paul University Manila', 'label': 'St. Paul University Manila' },
    { 'id': 9977, 'value': 'St. Paul University Philippines', 'label': 'St. Paul University Philippines' },
    { 'id': 9978, 'value': 'St. Paul University Quezon City', 'label': 'St. Paul University Quezon City' },
    { 'id': 9979, 'value': 'St. Paul University Surigao', 'label': 'St. Paul University Surigao' },
    { 'id': 7825, 'value': 'St. Paul\'s University', 'label': 'St. Paul\'s University' },
    { 'id': 6311, 'value': 'St. Peter\'s University', 'label': 'St. Peter\'s University' },
    { 'id': 10903, 'value': 'St. Petersburg State Agrarian University', 'label': 'St. Petersburg State Agrarian University' },
    { 'id': 10904, 'value': 'St. Petersburg State Conservatory', 'label': 'St. Petersburg State Conservatory' },
    { 'id': 10905, 'value': 'St. Petersburg State Marine Technical University', 'label': 'St. Petersburg State Marine Technical University' },
    { 'id': 10906, 'value': 'St. Petersburg State University', 'label': 'St. Petersburg State University' },
    { 'id': 10907, 'value': 'St. Petersburg State University for Culture and Arts', 'label': 'St. Petersburg State University for Culture and Arts' },
    { 'id': 10908, 'value': 'St. Petersburg State University of Aerospace Instrumentation', 'label': 'St. Petersburg State University of Aerospace Instrumentation' },
    { 'id': 10909, 'value': 'St. Petersburg State University of Architecture and Civil Engineering', 'label': 'St. Petersburg State University of Architecture and Civil Engineering' },
    { 'id': 10910, 'value': 'St. Petersburg State University of Civil Aviation', 'label': 'St. Petersburg State University of Civil Aviation' },
    { 'id': 10911, 'value': 'St. Petersburg State University of Economics', 'label': 'St. Petersburg State University of Economics' },
    { 'id': 10912, 'value': 'St. Petersburg State University of Industrial Technologies and Design', 'label': 'St. Petersburg State University of Industrial Technologies and Design' },
    { 'id': 10913, 'value': 'St. Petersburg State University of Physical Education', 'label': 'St. Petersburg State University of Physical Education' },
    { 'id': 10914, 'value': 'St. Petersburg University of Management Technologies and Economics', 'label': 'St. Petersburg University of Management Technologies and Economics' },
    { 'id': 9980, 'value': 'St. Scholastica\'s College', 'label': 'St. Scholastica\'s College' },
    { 'id': 1899, 'value': 'St. Stephen\'s University', 'label': 'St. Stephen\'s University' },
    { 'id': 833, 'value': 'St. Theresa Medical University of Yerevan', 'label': 'St. Theresa Medical University of Yerevan' },
    { 'id': 13464, 'value': 'St. Thomas Aquinas College', 'label': 'St. Thomas Aquinas College' },
    { 'id': 1900, 'value': 'St. Thomas More College', 'label': 'St. Thomas More College' },
    { 'id': 1901, 'value': 'St. Thomas University', 'label': 'St. Thomas University' },
    { 'id': 6312, 'value': 'St. Xavier\'s University', 'label': 'St. Xavier\'s University' },
    { 'id': 3741, 'value': 'Staatliche Akademie der Bildenden Künste Karlsruhe', 'label': 'Staatliche Akademie der Bildenden Künste Karlsruhe' },
    { 'id': 3742, 'value': 'Staatliche Akademie der Bildenden Künste Stuttgart', 'label': 'Staatliche Akademie der Bildenden Künste Stuttgart' },
    { 'id': 3743, 'value': 'Staatliche Hochschule für Gestaltung Karlsruhe', 'label': 'Staatliche Hochschule für Gestaltung Karlsruhe' },
    { 'id': 3744, 'value': 'Staatliche Hochschule für Musik Trossingen', 'label': 'Staatliche Hochschule für Musik Trossingen' },
    { 'id': 3745, 'value': 'Staatliche Hochschule für Musik und Darstellende Kunst Mannheim', 'label': 'Staatliche Hochschule für Musik und Darstellende Kunst Mannheim' },
    { 'id': 3746, 'value': 'Staatliche Hochschule für Musik und Darstellende Kunst Stuttgart', 'label': 'Staatliche Hochschule für Musik und Darstellende Kunst Stuttgart' },
    { 'id': 12196, 'value': 'Stafford University Uganda', 'label': 'Stafford University Uganda' },
    { 'id': 4560, 'value': 'Staffordshire University', 'label': 'Staffordshire University' },
    { 'id': 11474, 'value': 'Stamford International University', 'label': 'Stamford International University' },
    { 'id': 1314, 'value': 'Stamford University Bangladesh', 'label': 'Stamford University Bangladesh' },
    { 'id': 519, 'value': 'Stanford University', 'label': 'Stanford University' },
    { 'id': 6313, 'value': 'Starex University', 'label': 'Starex University' },
    { 'id': 10276, 'value': 'Staropolska Szkola Wyzsza w Kielcach', 'label': 'Staropolska Szkola Wyzsza w Kielcach' },
    { 'id': 8392, 'value': 'Starptautiska praktiskas psihologijas augstskola', 'label': 'Starptautiska praktiskas psihologijas augstskola' },
    { 'id': 834, 'value': 'State Academy of Fine Arts of Armenia', 'label': 'State Academy of Fine Arts of Armenia' },
    { 'id': 11538, 'value': 'State Academy of Fine Arts of Turkmenistan', 'label': 'State Academy of Fine Arts of Turkmenistan' },
    { 'id': 12118, 'value': 'State Agrarian and Engineering University in Podilia', 'label': 'State Agrarian and Engineering University in Podilia' },
    { 'id': 13465, 'value': 'State College of Florida-Manatee-Sarasota', 'label': 'State College of Florida-Manatee-Sarasota' },
    { 'id': 11512, 'value': 'State Financial and Economic University of Tajikistan', 'label': 'State Financial and Economic University of Tajikistan' },
    { 'id': 1315, 'value': 'State University of Bangladesh', 'label': 'State University of Bangladesh' },
    { 'id': 12119, 'value': 'State University of Infrastructure and Technology', 'label': 'State University of Infrastructure and Technology' },
    { 'id': 10915, 'value': 'State University of Land Management', 'label': 'State University of Land Management' },
    { 'id': 116, 'value': 'State University of Music and Performing Arts Stuttgart', 'label': 'State University of Music and Performing Arts Stuttgart' },
    { 'id': 13466, 'value': 'State University of New York at Fredonia', 'label': 'State University of New York at Fredonia' },
    { 'id': 13467, 'value': 'State University of New York at New Paltz', 'label': 'State University of New York at New Paltz' },
    { 'id': 13468, 'value': 'State University of New York at Oswego', 'label': 'State University of New York at Oswego' },
    { 'id': 13469, 'value': 'State University of New York College at Plattsburgh', 'label': 'State University of New York College at Plattsburgh' },
    { 'id': 6314, 'value': 'State University of Performing and Visual Arts', 'label': 'State University of Performing and Visual Arts' },
    { 'id': 12120, 'value': 'State University of Telecommunications', 'label': 'State University of Telecommunications' },
    { 'id': 10916, 'value': 'Stavropol State Agrarian University', 'label': 'Stavropol State Agrarian University' },
    { 'id': 10917, 'value': 'Stavropol State Medical University', 'label': 'Stavropol State Medical University' },
    { 'id': 3747, 'value': 'Steinbeis-Hochschule', 'label': 'Steinbeis-Hochschule' },
    { 'id': 13470, 'value': 'Stephen F. Austin State University', 'label': 'Stephen F. Austin State University' },
    { 'id': 13471, 'value': 'Stephens College', 'label': 'Stephens College' },
    { 'id': 13472, 'value': 'Sterling College', 'label': 'Sterling College' },
    { 'id': 13473, 'value': 'Sterling College, Vermont', 'label': 'Sterling College, Vermont' },
    { 'id': 13474, 'value': 'Stetson University', 'label': 'Stetson University' },
    { 'id': 13475, 'value': 'Stevens Institute of Technology', 'label': 'Stevens Institute of Technology' },
    { 'id': 13476, 'value': 'Stevenson University', 'label': 'Stevenson University' },
    { 'id': 9981, 'value': 'STI West Negros University', 'label': 'STI West Negros University' },
    { 'id': 13477, 'value': 'Stillman College', 'label': 'Stillman College' },
    { 'id': 14402, 'value': 'Stockholm School of Economics', 'label': 'Stockholm School of Economics' },
    { 'id': 14423, 'value': 'Stockholm University', 'label': 'Stockholm University' },
    { 'id': 14386, 'value': 'Stockholms Filmskola', 'label': 'Stockholms Filmskola' },
    { 'id': 11141, 'value': 'Stockholms konstnärliga högskola', 'label': 'Stockholms konstnärliga högskola' },
    { 'id': 11142, 'value': 'Stockholms universitet', 'label': 'Stockholms universitet' },
    { 'id': 13478, 'value': 'Stockton University', 'label': 'Stockton University' },
    { 'id': 13479, 'value': 'Stonehill College', 'label': 'Stonehill College' },
    { 'id': 13480, 'value': 'Stony Brook University', 'label': 'Stony Brook University' },
    { 'id': 4403, 'value': 'Strate Ecole de Design', 'label': 'Strate Ecole de Design' },
    { 'id': 7826, 'value': 'Strathmore University', 'label': 'Strathmore University' },
    { 'id': 13481, 'value': 'Strayer University', 'label': 'Strayer University' },
    { 'id': 11196, 'value': 'Stredoeurópska vysoká škola v Skalici', 'label': 'Stredoeurópska vysoká škola v Skalici' },
    { 'id': 6315, 'value': 'Suamandeep Vidyapeeth', 'label': 'Suamandeep Vidyapeeth' },
    { 'id': 11475, 'value': 'Suan Dusit University', 'label': 'Suan Dusit University' },
    { 'id': 11476, 'value': 'Suan Sunandha Rajabhat University', 'label': 'Suan Sunandha Rajabhat University' },
    { 'id': 11080, 'value': 'Sudan Academy of Sciences', 'label': 'Sudan Academy of Sciences' },
    { 'id': 11081, 'value': 'Sudan International University', 'label': 'Sudan International University' },
    { 'id': 11082, 'value': 'Sudan Technological University', 'label': 'Sudan Technological University' },
    { 'id': 11083, 'value': 'Sudan University of Science and Technology', 'label': 'Sudan University of Science and Technology' },
    { 'id': 4082, 'value': 'Suez Canal University', 'label': 'Suez Canal University' },
    { 'id': 4083, 'value': 'Suez University', 'label': 'Suez University' },
    { 'id': 13482, 'value': 'Suffolk University', 'label': 'Suffolk University' },
    { 'id': 7620, 'value': 'Sugino Fashion College', 'label': 'Sugino Fashion College' },
    { 'id': 7621, 'value': 'Sugiyama Jogakuen University', 'label': 'Sugiyama Jogakuen University' },
    { 'id': 2818, 'value': 'Suihua University', 'label': 'Suihua University' },
    { 'id': 1840, 'value': 'Sukhoi State Technical University of Gomel', 'label': 'Sukhoi State Technical University of Gomel' },
    { 'id': 10176, 'value': 'Sukkur Institute of Business Administration', 'label': 'Sukkur Institute of Business Administration' },
    { 'id': 13483, 'value': 'Sul Ross State University', 'label': 'Sul Ross State University' },
    { 'id': 6467, 'value': 'Sulaimani Polytechnic University', 'label': 'Sulaimani Polytechnic University' },
    { 'id': 9429, 'value': 'Sule Lamido University', 'label': 'Sule Lamido University' },
    { 'id': 11777, 'value': 'Süleyman Demirel Üniversitesi', 'label': 'Süleyman Demirel Üniversitesi' },
    { 'id': 8254, 'value': 'Suleyman Demirel University', 'label': 'Suleyman Demirel University' },
    { 'id': 4652, 'value': 'Sulkhan-Saba Orbeliani Teaching University', 'label': 'Sulkhan-Saba Orbeliani Teaching University' },
    { 'id': 13484, 'value': 'Sullivan University', 'label': 'Sullivan University' },
    { 'id': 9644, 'value': 'Sultan Qaboos University', 'label': 'Sultan Qaboos University' },
    { 'id': 9430, 'value': 'Summit University Offa', 'label': 'Summit University Offa' },
    { 'id': 1152, 'value': 'Sumqayit Dövlet Universiteti', 'label': 'Sumqayit Dövlet Universiteti' },
    { 'id': 12121, 'value': 'Sumy National Agrarian University', 'label': 'Sumy National Agrarian University' },
    { 'id': 12122, 'value': 'Sumy State Pedagogical University', 'label': 'Sumy State Pedagogical University' },
    { 'id': 12123, 'value': 'Sumy State University', 'label': 'Sumy State University' },
    { 'id': 8129, 'value': 'Sun Moon University', 'label': 'Sun Moon University' },
    { 'id': 2819, 'value': 'Sun Yat-Sen University', 'label': 'Sun Yat-Sen University' },
    { 'id': 8130, 'value': 'Sunchon National University', 'label': 'Sunchon National University' },
    { 'id': 8131, 'value': 'Sungkonghoe University', 'label': 'Sungkonghoe University' },
    { 'id': 8132, 'value': 'Sungkyul University', 'label': 'Sungkyul University' },
    { 'id': 320, 'value': 'Sungkyunkwan University', 'label': 'Sungkyunkwan University' },
    { 'id': 8133, 'value': 'Sungshin University', 'label': 'Sungshin University' },
    { 'id': 14281, 'value': 'Sunningdale University', 'label': 'Sunningdale University' },
    { 'id': 6316, 'value': 'Sunrise University', 'label': 'Sunrise University' },
    { 'id': 9249, 'value': 'Sunway University', 'label': 'Sunway University' },
    { 'id': 13485, 'value': 'SUNY Canton', 'label': 'SUNY Canton' },
    { 'id': 13486, 'value': 'SUNY Cobleskill', 'label': 'SUNY Cobleskill' },
    { 'id': 13487, 'value': 'SUNY College at Old Westbury', 'label': 'SUNY College at Old Westbury' },
    { 'id': 13488, 'value': 'SUNY College at Oneonta', 'label': 'SUNY College at Oneonta' },
    { 'id': 13489, 'value': 'SUNY College of Environmental Science and Forestry', 'label': 'SUNY College of Environmental Science and Forestry' },
    { 'id': 13490, 'value': 'SUNY College of Optometry', 'label': 'SUNY College of Optometry' },
    { 'id': 13491, 'value': 'SUNY Cortland', 'label': 'SUNY Cortland' },
    { 'id': 13492, 'value': 'SUNY Delhi', 'label': 'SUNY Delhi' },
    { 'id': 13493, 'value': 'SUNY Downstate Medical Center', 'label': 'SUNY Downstate Medical Center' },
    { 'id': 13494, 'value': 'SUNY Empire State College', 'label': 'SUNY Empire State College' },
    { 'id': 13495, 'value': 'SUNY Geneseo', 'label': 'SUNY Geneseo' },
    { 'id': 13496, 'value': 'SUNY Maritime College', 'label': 'SUNY Maritime College' },
    { 'id': 13497, 'value': 'SUNY Polytechnic Institute', 'label': 'SUNY Polytechnic Institute' },
    { 'id': 13498, 'value': 'SUNY Upstate Medical University', 'label': 'SUNY Upstate Medical University' },
    { 'id': 4711, 'value': 'Sunyani Technical University', 'label': 'Sunyani Technical University' },
    { 'id': 14282, 'value': 'Supershine University', 'label': 'Supershine University' },
    { 'id': 4404, 'value': 'Supméca - Institut Supérieur de Mécanique', 'label': 'Supméca - Institut Supérieur de Mécanique' },
    { 'id': 2820, 'value': 'Suqian College', 'label': 'Suqian College' },
    { 'id': 9645, 'value': 'Sur University College', 'label': 'Sur University College' },
    { 'id': 11477, 'value': 'Suranaree University of Technology', 'label': 'Suranaree University of Technology' },
    { 'id': 11478, 'value': 'Suratthani Rajabhat University', 'label': 'Suratthani Rajabhat University' },
    { 'id': 6317, 'value': 'Suresh Gyan Vihar University', 'label': 'Suresh Gyan Vihar University' },
    { 'id': 10918, 'value': 'Surgut State University', 'label': 'Surgut State University' },
    { 'id': 9982, 'value': 'Surigao del Sur State University', 'label': 'Surigao del Sur State University' },
    { 'id': 11479, 'value': 'Surindra Rajabhat University', 'label': 'Surindra Rajabhat University' },
    { 'id': 7622, 'value': 'Surugadai University', 'label': 'Surugadai University' },
    { 'id': 13499, 'value': 'Susquehanna University', 'label': 'Susquehanna University' },
    { 'id': 8639, 'value': 'Sutai Institute', 'label': 'Sutai Institute' },
    { 'id': 7623, 'value': 'Suwa University of Science', 'label': 'Suwa University of Science' },
    { 'id': 8134, 'value': 'Suwon Catholic University', 'label': 'Suwon Catholic University' },
    { 'id': 2821, 'value': 'Suzhou University', 'label': 'Suzhou University' },
    { 'id': 2822, 'value': 'Suzhou University of Science and Technology', 'label': 'Suzhou University of Science and Technology' },
    { 'id': 7624, 'value': 'Suzuka International University', 'label': 'Suzuka International University' },
    { 'id': 7625, 'value': 'Suzuka University of Medical Science', 'label': 'Suzuka University of Medical Science' },
    { 'id': 7929, 'value': 'Svay Rieng University', 'label': 'Svay Rieng University' },
    { 'id': 11143, 'value': 'Sveriges lantbruksuniversitet', 'label': 'Sveriges lantbruksuniversitet' },
    { 'id': 1171, 'value': 'Sveucilište Hercegovina', 'label': 'Sveucilište Hercegovina' },
    { 'id': 4828, 'value': 'Sveucilište Josipa Jurja Strossmayera u Osijeku', 'label': 'Sveucilište Josipa Jurja Strossmayera u Osijeku' },
    { 'id': 4829, 'value': 'Sveucilište Jurja Dobrile u Puli', 'label': 'Sveucilište Jurja Dobrile u Puli' },
    { 'id': 4830, 'value': 'Sveucilište Sjever', 'label': 'Sveucilište Sjever' },
    { 'id': 4831, 'value': 'Sveucilište u Dubrovniku', 'label': 'Sveucilište u Dubrovniku' },
    { 'id': 1172, 'value': 'Sveucilište u Mostaru', 'label': 'Sveucilište u Mostaru' },
    { 'id': 4832, 'value': 'Sveucilište u Rijeci', 'label': 'Sveucilište u Rijeci' },
    { 'id': 4833, 'value': 'Sveucilište u Splitu', 'label': 'Sveucilište u Splitu' },
    { 'id': 4834, 'value': 'Sveucilište u Zadru', 'label': 'Sveucilište u Zadru' },
    { 'id': 4835, 'value': 'Sveucilište u Zagrebu', 'label': 'Sveucilište u Zagrebu' },
    { 'id': 1173, 'value': 'Sveucilište/Univerzitet ITC-Interlogos centar', 'label': 'Sveucilište/Univerzitet ITC-Interlogos centar' },
    { 'id': 1174, 'value': 'Sveucilište/Univerzitet VITEZ', 'label': 'Sveucilište/Univerzitet VITEZ' },
    { 'id': 6318, 'value': 'Swami Keshwanand Rajasthan Agricultural University', 'label': 'Swami Keshwanand Rajasthan Agricultural University' },
    { 'id': 6319, 'value': 'Swami Rama Himalayan University', 'label': 'Swami Rama Himalayan University' },
    { 'id': 6320, 'value': 'Swami Ramanand Teerth Marathwada University', 'label': 'Swami Ramanand Teerth Marathwada University' },
    { 'id': 6321, 'value': 'Swami Vivekanand Subharti University', 'label': 'Swami Vivekanand Subharti University' },
    { 'id': 6322, 'value': 'Swami Vivekanand University', 'label': 'Swami Vivekanand University' },
    { 'id': 6323, 'value': 'Swami Vivekananda Yoga Anusandhana Samsthana', 'label': 'Swami Vivekananda Yoga Anusandhana Samsthana' },
    { 'id': 432, 'value': 'Swansea University', 'label': 'Swansea University' },
    { 'id': 6324, 'value': 'Swarnim Gujarat Sports University', 'label': 'Swarnim Gujarat Sports University' },
    { 'id': 6325, 'value': 'Swarnim Startup and Innovation University', 'label': 'Swarnim Startup and Innovation University' },
    { 'id': 13500, 'value': 'Swarthmore College', 'label': 'Swarthmore College' },
    { 'id': 11361, 'value': 'Swaziland College of Technology', 'label': 'Swaziland College of Technology' },
    { 'id': 13501, 'value': 'Sweet Briar College', 'label': 'Sweet Briar College' },
    { 'id': 1088, 'value': 'Swinburne University of Technology', 'label': 'Swinburne University of Technology' },
    { 'id': 531, 'value': 'Swiss Education Group - Hotellerie', 'label': 'Swiss Education Group - Hotellerie' },
    { 'id': 263, 'value': 'Swiss School of Management', 'label': 'Swiss School of Management' },
    { 'id': 3837, 'value': 'Syddansk Musikkonservatorium', 'label': 'Syddansk Musikkonservatorium' },
    { 'id': 3838, 'value': 'Syddansk Universitet', 'label': 'Syddansk Universitet' },
    { 'id': 768, 'value': 'Syed Jamaluddin Afghan Institute of Higher Education', 'label': 'Syed Jamaluddin Afghan Institute of Higher Education' },
    { 'id': 10919, 'value': 'Syktyvkar State University', 'label': 'Syktyvkar State University' },
    { 'id': 1316, 'value': 'Sylhet Agricultural University', 'label': 'Sylhet Agricultural University' },
    { 'id': 1317, 'value': 'Sylhet International University', 'label': 'Sylhet International University' },
    { 'id': 1318, 'value': 'Sylhet Medical University', 'label': 'Sylhet Medical University' },
    { 'id': 14283, 'value': 'Sylva University', 'label': 'Sylva University' },
    { 'id': 6326, 'value': 'Symbiosis International University', 'label': 'Symbiosis International University' },
    { 'id': 6327, 'value': 'Symbiosis University of Applied Sciences', 'label': 'Symbiosis University of Applied Sciences' },
    { 'id': 10920, 'value': 'Synergy University', 'label': 'Synergy University' },
    { 'id': 13502, 'value': 'Syracuse University', 'label': 'Syracuse University' },
    { 'id': 8255, 'value': 'Syrdariya University', 'label': 'Syrdariya University' },
    { 'id': 11350, 'value': 'Syrian Private University', 'label': 'Syrian Private University' },
    { 'id': 835, 'value': 'Syunik Institute', 'label': 'Syunik Institute' },
    { 'id': 4923, 'value': 'Széchényi István Egyetem', 'label': 'Széchényi István Egyetem' },
    { 'id': 4924, 'value': 'Szegedi Tudományegyetem', 'label': 'Szegedi Tudományegyetem' },
    { 'id': 4925, 'value': 'Szent István Egyetem', 'label': 'Szent István Egyetem' },
    { 'id': 4926, 'value': 'Színház- és Filmmuvészeti Egyetem', 'label': 'Színház- és Filmmuvészeti Egyetem' },
    { 'id': 10277, 'value': 'Szkola Filmowa w Lodzi', 'label': 'Szkola Filmowa w Lodzi' },
    { 'id': 10278, 'value': 'Szkola Glówna Gospodarstwa Wiejskiego', 'label': 'Szkola Glówna Gospodarstwa Wiejskiego' },
    { 'id': 10279, 'value': 'Szkola Glówna Handlowa w Warszawie', 'label': 'Szkola Glówna Handlowa w Warszawie' },
    { 'id': 10280, 'value': 'Szkola Wyzsza im. Pawla Wlodkowica w Plocku', 'label': 'Szkola Wyzsza im. Pawla Wlodkowica w Plocku' },
    { 'id': 11917, 'value': 'Ta Hwa University of Science and Technology', 'label': 'Ta Hwa University of Science and Technology' },
    { 'id': 769, 'value': 'Tabesh University', 'label': 'Tabesh University' },
    { 'id': 13503, 'value': 'Tabor College', 'label': 'Tabor College' },
    { 'id': 6765, 'value': 'Tabriz Islamic Art University', 'label': 'Tabriz Islamic Art University' },
    { 'id': 6766, 'value': 'Tabriz University of Medical Sciences', 'label': 'Tabriz University of Medical Sciences' },
    { 'id': 7056, 'value': 'Tafila Technical University', 'label': 'Tafila Technical University' },
    { 'id': 6767, 'value': 'Tafresh University', 'label': 'Tafresh University' },
    { 'id': 9431, 'value': 'Tai Solarin University of Education', 'label': 'Tai Solarin University of Education' },
    { 'id': 11046, 'value': 'Taibah University', 'label': 'Taibah University' },
    { 'id': 4234, 'value': 'Taideyliopisto', 'label': 'Taideyliopisto' },
    { 'id': 11047, 'value': 'Taif University', 'label': 'Taif University' },
    { 'id': 2823, 'value': 'Taihu University of Wuxi', 'label': 'Taihu University of Wuxi' },
    { 'id': 11918, 'value': 'Tainan National University of the Arts', 'label': 'Tainan National University of the Arts' },
    { 'id': 11919, 'value': 'Tainan University of Technology', 'label': 'Tainan University of Technology' },
    { 'id': 11920, 'value': 'Taipei City University of Science and Technology', 'label': 'Taipei City University of Science and Technology' },
    { 'id': 11921, 'value': 'Taipei Medical University', 'label': 'Taipei Medical University' },
    { 'id': 11922, 'value': 'Taipei National University of the Arts', 'label': 'Taipei National University of the Arts' },
    { 'id': 11923, 'value': 'Taipei University of Maritime Technology', 'label': 'Taipei University of Maritime Technology' },
    { 'id': 7626, 'value': 'Taisei Gakuin University', 'label': 'Taisei Gakuin University' },
    { 'id': 2824, 'value': 'Taishan Medical University', 'label': 'Taishan Medical University' },
    { 'id': 2825, 'value': 'Taishan University', 'label': 'Taishan University' },
    { 'id': 7627, 'value': 'Taisho University', 'label': 'Taisho University' },
    { 'id': 7827, 'value': 'Taita Taveta University', 'label': 'Taita Taveta University' },
    { 'id': 11924, 'value': 'Taiwan Hospitality and Tourism University', 'label': 'Taiwan Hospitality and Tourism University' },
    { 'id': 11925, 'value': 'Taiwan Shoufu University', 'label': 'Taiwan Shoufu University' },
    { 'id': 2826, 'value': 'Taiyuan Institute of Technology', 'label': 'Taiyuan Institute of Technology' },
    { 'id': 2827, 'value': 'Taiyuan Normal University', 'label': 'Taiyuan Normal University' },
    { 'id': 2828, 'value': 'Taiyuan University', 'label': 'Taiyuan University' },
    { 'id': 2829, 'value': 'Taiyuan University of Science and Technology', 'label': 'Taiyuan University of Science and Technology' },
    { 'id': 2830, 'value': 'Taiyuan University of Technology', 'label': 'Taiyuan University of Technology' },
    { 'id': 14210, 'value': 'Taiz University', 'label': 'Taiz University' },
    { 'id': 2831, 'value': 'Taizhou University', 'label': 'Taizhou University' },
    { 'id': 770, 'value': 'Taj Institute of Higher Education', 'label': 'Taj Institute of Higher Education' },
    { 'id': 11926, 'value': 'Tajen University', 'label': 'Tajen University' },
    { 'id': 11513, 'value': 'Tajik Agrarian University', 'label': 'Tajik Agrarian University' },
    { 'id': 11514, 'value': 'Tajik Institute of Entrepreneurship and Service', 'label': 'Tajik Institute of Entrepreneurship and Service' },
    { 'id': 11515, 'value': 'Tajik Institute of Languages', 'label': 'Tajik Institute of Languages' },
    { 'id': 11516, 'value': 'Tajik Institute of Physical Education', 'label': 'Tajik Institute of Physical Education' },
    { 'id': 11517, 'value': 'Tajik National Conservatory', 'label': 'Tajik National Conservatory' },
    { 'id': 11518, 'value': 'Tajik National University', 'label': 'Tajik National University' },
    { 'id': 11519, 'value': 'Tajik State Medical University', 'label': 'Tajik State Medical University' },
    { 'id': 11520, 'value': 'Tajik State Pedagogical University', 'label': 'Tajik State Pedagogical University' },
    { 'id': 11521, 'value': 'Tajik State University of Commerce', 'label': 'Tajik State University of Commerce' },
    { 'id': 11522, 'value': 'Tajik Technical University', 'label': 'Tajik Technical University' },
    { 'id': 11523, 'value': 'Tajikistan State Institute of Art', 'label': 'Tajikistan State Institute of Art' },
    { 'id': 11524, 'value': 'Tajikistan State University of Law, Business and Politics', 'label': 'Tajikistan State University of Law, Business and Politics' },
    { 'id': 7628, 'value': 'Takachiho University', 'label': 'Takachiho University' },
    { 'id': 7629, 'value': 'Takamatsu University', 'label': 'Takamatsu University' },
    { 'id': 7630, 'value': 'Takaoka University of Law', 'label': 'Takaoka University of Law' },
    { 'id': 7631, 'value': 'Takarazuka University of Art and Design', 'label': 'Takarazuka University of Art and Design' },
    { 'id': 7632, 'value': 'Takasaki City University of Economics', 'label': 'Takasaki City University of Economics' },
    { 'id': 7633, 'value': 'Takasaki University of Commerce', 'label': 'Takasaki University of Commerce' },
    { 'id': 7634, 'value': 'Takasaki University of Health and Welfare', 'label': 'Takasaki University of Health and Welfare' },
    { 'id': 771, 'value': 'Takhar University', 'label': 'Takhar University' },
    { 'id': 11927, 'value': 'Takming University of Science and Technology', 'label': 'Takming University of Science and Technology' },
    { 'id': 4712, 'value': 'Takoradi Technical University', 'label': 'Takoradi Technical University' },
    { 'id': 7635, 'value': 'Takushoku University', 'label': 'Takushoku University' },
    { 'id': 1477, 'value': 'Talal Abu-Ghazaleh University College of Business', 'label': 'Talal Abu-Ghazaleh University College of Business' },
    { 'id': 7884, 'value': 'Talas State University', 'label': 'Talas State University' },
    { 'id': 13504, 'value': 'Talladega College', 'label': 'Talladega College' },
    { 'id': 4033, 'value': 'Tallinna Tehnikakõrgkool', 'label': 'Tallinna Tehnikakõrgkool' },
    { 'id': 4034, 'value': 'Tallinna Tehnikaülikool', 'label': 'Tallinna Tehnikaülikool' },
    { 'id': 4035, 'value': 'Tallinna Tervishoiu Kõrgkool', 'label': 'Tallinna Tervishoiu Kõrgkool' },
    { 'id': 4036, 'value': 'Tallinna Ülikool', 'label': 'Tallinna Ülikool' },
    { 'id': 7636, 'value': 'Tama Art University', 'label': 'Tama Art University' },
    { 'id': 7637, 'value': 'Tama University', 'label': 'Tama University' },
    { 'id': 7638, 'value': 'Tamagawa University', 'label': 'Tamagawa University' },
    { 'id': 4713, 'value': 'Tamale Technical University', 'label': 'Tamale Technical University' },
    { 'id': 10921, 'value': 'Tambov State Musical-Pedagogical Institute named after S.V. Rachmaninov', 'label': 'Tambov State Musical-Pedagogical Institute named after S.V. Rachmaninov' },
    { 'id': 10922, 'value': 'Tambov State Technical University', 'label': 'Tambov State Technical University' },
    { 'id': 10923, 'value': 'Tambov State University', 'label': 'Tambov State University' },
    { 'id': 6328, 'value': 'Tamil Nadu Agricultural University', 'label': 'Tamil Nadu Agricultural University' },
    { 'id': 6329, 'value': 'Tamil Nadu Dr Ambedkar Law University', 'label': 'Tamil Nadu Dr Ambedkar Law University' },
    { 'id': 6330, 'value': 'Tamil Nadu Dr. J. Jayalalithaa Fisheries University', 'label': 'Tamil Nadu Dr. J. Jayalalithaa Fisheries University' },
    { 'id': 6331, 'value': 'Tamil Nadu Dr. M.G.R.Medical University', 'label': 'Tamil Nadu Dr. M.G.R.Medical University' },
    { 'id': 6332, 'value': 'Tamil Nadu Music and Fine Arts University', 'label': 'Tamil Nadu Music and Fine Arts University' },
    { 'id': 6333, 'value': 'Tamil Nadu National Law University', 'label': 'Tamil Nadu National Law University' },
    { 'id': 6334, 'value': 'Tamil Nadu Physical Education and Sports University', 'label': 'Tamil Nadu Physical Education and Sports University' },
    { 'id': 6335, 'value': 'Tamil Nadu Teacher Education University', 'label': 'Tamil Nadu Teacher Education University' },
    { 'id': 6336, 'value': 'Tamil Nadu Veterinary and Animal Sciences University', 'label': 'Tamil Nadu Veterinary and Animal Sciences University' },
    { 'id': 6337, 'value': 'Tamil University', 'label': 'Tamil University' },
    { 'id': 11928, 'value': 'Tamkang University', 'label': 'Tamkang University' },
    { 'id': 4235, 'value': 'Tampereen yliopisto', 'label': 'Tampereen yliopisto' },
    { 'id': 2832, 'value': 'Tangshan College', 'label': 'Tangshan College' },
    { 'id': 2833, 'value': 'Tangshan Normal University', 'label': 'Tangshan Normal University' },
    { 'id': 9432, 'value': 'Tansian University', 'label': 'Tansian University' },
    { 'id': 4084, 'value': 'Tanta University', 'label': 'Tanta University' },
    { 'id': 6338, 'value': 'Tantia University', 'label': 'Tantia University' },
    { 'id': 11480, 'value': 'Tapee University', 'label': 'Tapee University' },
    { 'id': 9433, 'value': 'Taraba State University', 'label': 'Taraba State University' },
    { 'id': 12124, 'value': 'Taras Shevchenko National University of Kyiv', 'label': 'Taras Shevchenko National University of Kyiv' },
    { 'id': 8256, 'value': 'Taraz Innovative-Humanitarian University', 'label': 'Taraz Innovative-Humanitarian University' },
    { 'id': 8257, 'value': 'Taraz State University', 'label': 'Taraz State University' },
    { 'id': 6768, 'value': 'Tarbiat Modares University', 'label': 'Tarbiat Modares University' },
    { 'id': 2834, 'value': 'Tarim University', 'label': 'Tarim University' },
    { 'id': 9983, 'value': 'Tarlac Agricultural University', 'label': 'Tarlac Agricultural University' },
    { 'id': 9984, 'value': 'Tarlac State University', 'label': 'Tarlac State University' },
    { 'id': 13505, 'value': 'Tarleton State University', 'label': 'Tarleton State University' },
    { 'id': 8358, 'value': 'Tarptautine teises ir verslo aukštoji mokykla', 'label': 'Tarptautine teises ir verslo aukštoji mokykla' },
    { 'id': 4037, 'value': 'Tartu Tervishoiu Kõrgkool', 'label': 'Tartu Tervishoiu Kõrgkool' },
    { 'id': 4038, 'value': 'Tartu Ülikool', 'label': 'Tartu Ülikool' },
    { 'id': 6339, 'value': 'Tata Institute of Fundamental Research', 'label': 'Tata Institute of Fundamental Research' },
    { 'id': 6340, 'value': 'Tata Institute of Social Sciences', 'label': 'Tata Institute of Social Sciences' },
    { 'id': 10924, 'value': 'Tatar State University of Humanities and Education', 'label': 'Tatar State University of Humanities and Education' },
    { 'id': 11929, 'value': 'Tatung Institute of Commerce and Technology', 'label': 'Tatung Institute of Commerce and Technology' },
    { 'id': 11930, 'value': 'Tatung University', 'label': 'Tatung University' },
    { 'id': 8532, 'value': 'Taunggyi University', 'label': 'Taunggyi University' },
    { 'id': 8533, 'value': 'Taungoo University', 'label': 'Taungoo University' },
    { 'id': 12125, 'value': 'Tavria State Agrotechnological University', 'label': 'Tavria State Agrotechnological University' },
    { 'id': 14156, 'value': 'Tay Bac University', 'label': 'Tay Bac University' },
    { 'id': 13506, 'value': 'Taylor University', 'label': 'Taylor University' },
    { 'id': 9250, 'value': 'Taylor\'s University', 'label': 'Taylor\'s University' },
    { 'id': 4653, 'value': 'Tbel Abuserisdze Teaching University of Georgian Patriarchate', 'label': 'Tbel Abuserisdze Teaching University of Georgian Patriarchate' },
    { 'id': 4654, 'value': 'Tbilisi David Agmashenebeli Teaching University', 'label': 'Tbilisi David Agmashenebeli Teaching University' },
    { 'id': 4655, 'value': 'Tbilisi Humanitarian Teaching University', 'label': 'Tbilisi Humanitarian Teaching University' },
    { 'id': 4656, 'value': 'Tbilisi Open Teaching University', 'label': 'Tbilisi Open Teaching University' },
    { 'id': 4657, 'value': 'Tbilisi State Academy of Arts', 'label': 'Tbilisi State Academy of Arts' },
    { 'id': 4658, 'value': 'Tbilisi State Medical University', 'label': 'Tbilisi State Medical University' },
    { 'id': 10925, 'value': 'Tchaikovsky State Institute of Physical Culture', 'label': 'Tchaikovsky State Institute of Physical Culture' },
    { 'id': 4659, 'value': 'Teaching University Geomedi', 'label': 'Teaching University Geomedi' },
    { 'id': 12197, 'value': 'Team University', 'label': 'Team University' },
    { 'id': 6341, 'value': 'TeamLease Skills University', 'label': 'TeamLease Skills University' },
    { 'id': 6769, 'value': 'Technical and Vocational University', 'label': 'Technical and Vocational University' },
    { 'id': 1447, 'value': 'Technical University - Varna', 'label': 'Technical University - Varna' },
    { 'id': 4749, 'value': 'Technical University of Crete', 'label': 'Technical University of Crete' },
    { 'id': 573, 'value': 'Technical University of Denmark - DTU', 'label': 'Technical University of Denmark - DTU' },
    { 'id': 1448, 'value': 'Technical University of Gabrovo', 'label': 'Technical University of Gabrovo' },
    { 'id': 7828, 'value': 'Technical University of Kenya', 'label': 'Technical University of Kenya' },
    { 'id': 7829, 'value': 'Technical University of Mombasa', 'label': 'Technical University of Mombasa' },
    { 'id': 1449, 'value': 'Technical University of Sofia', 'label': 'Technical University of Sofia' },
    { 'id': 11197, 'value': 'Technická univerzita v Košiciach', 'label': 'Technická univerzita v Košiciach' },
    { 'id': 3416, 'value': 'Technická Univerzita v Liberci', 'label': 'Technická Univerzita v Liberci' },
    { 'id': 11198, 'value': 'Technická univerzita vo Zvolene', 'label': 'Technická univerzita vo Zvolene' },
    { 'id': 5555, 'value': 'Technion - Israel Institute of Technology', 'label': 'Technion - Israel Institute of Technology' },
    { 'id': 3748, 'value': 'Technische Hochschule Brandenburg', 'label': 'Technische Hochschule Brandenburg' },
    { 'id': 593, 'value': 'Technische Hochschule Deggendorf', 'label': 'Technische Hochschule Deggendorf' },
    { 'id': 3749, 'value': 'Technische Hochschule Georg Agricola', 'label': 'Technische Hochschule Georg Agricola' },
    { 'id': 3750, 'value': 'Technische Hochschule Ingolstadt', 'label': 'Technische Hochschule Ingolstadt' },
    { 'id': 3751, 'value': 'Technische Hochschule Köln', 'label': 'Technische Hochschule Köln' },
    { 'id': 3752, 'value': 'Technische Hochschule Mittelhessen', 'label': 'Technische Hochschule Mittelhessen' },
    { 'id': 3753, 'value': 'Technische Hochschule Nürnberg Georg Simon Ohm', 'label': 'Technische Hochschule Nürnberg Georg Simon Ohm' },
    { 'id': 3754, 'value': 'Technische Hochschule Ostwestfalen-Lippe', 'label': 'Technische Hochschule Ostwestfalen-Lippe' },
    { 'id': 3755, 'value': 'Technische Hochschule Wildau', 'label': 'Technische Hochschule Wildau' },
    { 'id': 3756, 'value': 'Technische Universität Bergakademie Freiberg', 'label': 'Technische Universität Bergakademie Freiberg' },
    { 'id': 3757, 'value': 'Technische Universität Berlin', 'label': 'Technische Universität Berlin' },
    { 'id': 3758, 'value': 'Technische Universität Braunschweig', 'label': 'Technische Universität Braunschweig' },
    { 'id': 3759, 'value': 'Technische Universität Chemnitz', 'label': 'Technische Universität Chemnitz' },
    { 'id': 3760, 'value': 'Technische Universität Clausthal', 'label': 'Technische Universität Clausthal' },
    { 'id': 3761, 'value': 'Technische Universität Darmstadt', 'label': 'Technische Universität Darmstadt' },
    { 'id': 3762, 'value': 'Technische Universität Dortmund', 'label': 'Technische Universität Dortmund' },
    { 'id': 3763, 'value': 'Technische Universität Dresden', 'label': 'Technische Universität Dresden' },
    { 'id': 1058, 'value': 'Technische Universität Graz', 'label': 'Technische Universität Graz' },
    { 'id': 3764, 'value': 'Technische Universität Hamburg', 'label': 'Technische Universität Hamburg' },
    { 'id': 3765, 'value': 'Technische Universität Ilmenau', 'label': 'Technische Universität Ilmenau' },
    { 'id': 3766, 'value': 'Technische Universität Kaiserslautern', 'label': 'Technische Universität Kaiserslautern' },
    { 'id': 3767, 'value': 'Technische Universität München', 'label': 'Technische Universität München' },
    { 'id': 1059, 'value': 'Technische Universität Wien', 'label': 'Technische Universität Wien' },
    { 'id': 9548, 'value': 'Technische Universiteit Delft', 'label': 'Technische Universiteit Delft' },
    { 'id': 9549, 'value': 'Technische Universiteit Eindhoven', 'label': 'Technische Universiteit Eindhoven' },
    { 'id': 3768, 'value': 'Technischen Hochschule Bingen', 'label': 'Technischen Hochschule Bingen' },
    { 'id': 6342, 'value': 'Techno India University', 'label': 'Techno India University' },
    { 'id': 4750, 'value': 'Technological Education Institute of Central Macedonia', 'label': 'Technological Education Institute of Central Macedonia' },
    { 'id': 4751, 'value': 'Technological Education Institute of Ionian Islands', 'label': 'Technological Education Institute of Ionian Islands' },
    { 'id': 4752, 'value': 'Technological Education Institute of Western Macedonia', 'label': 'Technological Education Institute of Western Macedonia' },
    { 'id': 4753, 'value': 'Technological Educational Institute of Athens', 'label': 'Technological Educational Institute of Athens' },
    { 'id': 4754, 'value': 'Technological Educational Institute of Central Greece', 'label': 'Technological Educational Institute of Central Greece' },
    { 'id': 4755, 'value': 'Technological Educational Institute of Crete', 'label': 'Technological Educational Institute of Crete' },
    { 'id': 4756, 'value': 'Technological Educational Institute of Eastern Macedonia and Thrace', 'label': 'Technological Educational Institute of Eastern Macedonia and Thrace' },
    { 'id': 4757, 'value': 'Technological Educational Institute of Epirus', 'label': 'Technological Educational Institute of Epirus' },
    { 'id': 4758, 'value': 'Technological Educational Institute of Peloponnese', 'label': 'Technological Educational Institute of Peloponnese' },
    { 'id': 4759, 'value': 'Technological Educational Institute of Piraeus', 'label': 'Technological Educational Institute of Piraeus' },
    { 'id': 4760, 'value': 'Technological Educational Institute of Western Greece', 'label': 'Technological Educational Institute of Western Greece' },
    { 'id': 9985, 'value': 'Technological Institute of the Philippines', 'label': 'Technological Institute of the Philippines' },
    { 'id': 11084, 'value': 'Technological University', 'label': 'Technological University' },
    { 'id': 5526, 'value': 'Technological University Dublin', 'label': 'Technological University Dublin' },
    { 'id': 11525, 'value': 'Technological University of Tajikistan', 'label': 'Technological University of Tajikistan' },
    { 'id': 9986, 'value': 'Technological University of the Philippines', 'label': 'Technological University of the Philippines' },
    { 'id': 8534, 'value': 'Technological University, Bhamos', 'label': 'Technological University, Bhamos' },
    { 'id': 8535, 'value': 'Technological University, Dawei', 'label': 'Technological University, Dawei' },
    { 'id': 8536, 'value': 'Technological University, Hinthada', 'label': 'Technological University, Hinthada' },
    { 'id': 8537, 'value': 'Technological University, Hmawbi', 'label': 'Technological University, Hmawbi' },
    { 'id': 8538, 'value': 'Technological University, Hpaan', 'label': 'Technological University, Hpaan' },
    { 'id': 8539, 'value': 'Technological University, Kalay', 'label': 'Technological University, Kalay' },
    { 'id': 8540, 'value': 'Technological University, Kyaing Tong', 'label': 'Technological University, Kyaing Tong' },
    { 'id': 8541, 'value': 'Technological University, Kyaukse', 'label': 'Technological University, Kyaukse' },
    { 'id': 8542, 'value': 'Technological University, Lashio', 'label': 'Technological University, Lashio' },
    { 'id': 8543, 'value': 'Technological University, Loikaw', 'label': 'Technological University, Loikaw' },
    { 'id': 8544, 'value': 'Technological University, Magway', 'label': 'Technological University, Magway' },
    { 'id': 8545, 'value': 'Technological University, Mandalay', 'label': 'Technological University, Mandalay' },
    { 'id': 8546, 'value': 'Technological University, Maubin', 'label': 'Technological University, Maubin' },
    { 'id': 8547, 'value': 'Technological University, Mawlamyine', 'label': 'Technological University, Mawlamyine' },
    { 'id': 8548, 'value': 'Technological University, Meikhtila', 'label': 'Technological University, Meikhtila' },
    { 'id': 8549, 'value': 'Technological University, Monywa', 'label': 'Technological University, Monywa' },
    { 'id': 8550, 'value': 'Technological University, Myeik', 'label': 'Technological University, Myeik' },
    { 'id': 8551, 'value': 'Technological University, Myitkyina', 'label': 'Technological University, Myitkyina' },
    { 'id': 8552, 'value': 'Technological University, Pakokku', 'label': 'Technological University, Pakokku' },
    { 'id': 8553, 'value': 'Technological University, Pathein', 'label': 'Technological University, Pathein' },
    { 'id': 8554, 'value': 'Technological University, Pinlon', 'label': 'Technological University, Pinlon' },
    { 'id': 8555, 'value': 'Technological University, Sagaing', 'label': 'Technological University, Sagaing' },
    { 'id': 8556, 'value': 'Technological University, Sittwe', 'label': 'Technological University, Sittwe' },
    { 'id': 8557, 'value': 'Technological University, Taunggyi', 'label': 'Technological University, Taunggyi' },
    { 'id': 8558, 'value': 'Technological University, Taungoo', 'label': 'Technological University, Taungoo' },
    { 'id': 8559, 'value': 'Technological University, Yamethin', 'label': 'Technological University, Yamethin' },
    { 'id': 3146, 'value': 'Tecnológico de Antioquia', 'label': 'Tecnológico de Antioquia' },
    { 'id': 8852, 'value': 'Tecnológico de Monterrey', 'label': 'Tecnológico de Monterrey' },
    { 'id': 11778, 'value': 'Ted Üniversitesi', 'label': 'Ted Üniversitesi' },
    { 'id': 6343, 'value': 'Teerthanker Mahaveer University', 'label': 'Teerthanker Mahaveer University' },
    { 'id': 4561, 'value': 'Teesside University', 'label': 'Teesside University' },
    { 'id': 4836, 'value': 'Tehnicko veleucilište u Zagrebu', 'label': 'Tehnicko veleucilište u Zagrebu' },
    { 'id': 6770, 'value': 'Tehran University of Medical Sciences', 'label': 'Tehran University of Medical Sciences' },
    { 'id': 7639, 'value': 'Teikyo Heisei University', 'label': 'Teikyo Heisei University' },
    { 'id': 7640, 'value': 'Teikyo University', 'label': 'Teikyo University' },
    { 'id': 7641, 'value': 'Teikyo University of Science and Technology', 'label': 'Teikyo University of Science and Technology' },
    { 'id': 11779, 'value': 'Tekirdag Namik Kemal Üniversitesi', 'label': 'Tekirdag Namik Kemal Üniversitesi' },
    { 'id': 5556, 'value': 'Tel Aviv University', 'label': 'Tel Aviv University' },
    { 'id': 5557, 'value': 'Tel-Hai Academic College', 'label': 'Tel-Hai Academic College' },
    { 'id': 6344, 'value': 'Telangana University', 'label': 'Telangana University' },
    { 'id': 4405, 'value': 'Télécom Paris', 'label': 'Télécom Paris' },
    { 'id': 4406, 'value': 'Télécom SudParis', 'label': 'Télécom SudParis' },
    { 'id': 13507, 'value': 'Temple University', 'label': 'Temple University' },
    { 'id': 8640, 'value': 'Tenger Institute', 'label': 'Tenger Institute' },
    { 'id': 13508, 'value': 'Tennessee State University', 'label': 'Tennessee State University' },
    { 'id': 13509, 'value': 'Tennessee Tech University', 'label': 'Tennessee Tech University' },
    { 'id': 13510, 'value': 'Tennessee Wesleyan University', 'label': 'Tennessee Wesleyan University' },
    { 'id': 7642, 'value': 'Tenri University', 'label': 'Tenri University' },
    { 'id': 7643, 'value': 'Tenshi College', 'label': 'Tenshi College' },
    { 'id': 11969, 'value': 'Teofilo Kisanji University', 'label': 'Teofilo Kisanji University' },
    { 'id': 6345, 'value': 'TERI School of Advanced Studies', 'label': 'TERI School of Advanced Studies' },
    { 'id': 14021, 'value': 'Termiz Davlat Universiteti', 'label': 'Termiz Davlat Universiteti' },
    { 'id': 12126, 'value': 'Ternopil National Economic University', 'label': 'Ternopil National Economic University' },
    { 'id': 12127, 'value': 'Ternopil National Technical University', 'label': 'Ternopil National Technical University' },
    { 'id': 12128, 'value': 'Ternopil State Medical University', 'label': 'Ternopil State Medical University' },
    { 'id': 12129, 'value': 'Ternopil Volodymyr Hnatiuk National Pedagogical University', 'label': 'Ternopil Volodymyr Hnatiuk National Pedagogical University' },
    { 'id': 13511, 'value': 'Texas A&M International University', 'label': 'Texas A&M International University' },
    { 'id': 13512, 'value': 'Texas A&M University', 'label': 'Texas A&M University' },
    { 'id': 13513, 'value': 'Texas A&M University at Galveston', 'label': 'Texas A&M University at Galveston' },
    { 'id': 10513, 'value': 'Texas A&M University at Qatar', 'label': 'Texas A&M University at Qatar' },
    { 'id': 13514, 'value': 'Texas A&M University-Commerce', 'label': 'Texas A&M University-Commerce' },
    { 'id': 13515, 'value': 'Texas A&M University-Corpus Christi', 'label': 'Texas A&M University-Corpus Christi' },
    { 'id': 13516, 'value': 'Texas A&M University-Kingsville', 'label': 'Texas A&M University-Kingsville' },
    { 'id': 13517, 'value': 'Texas A&M University-Texarkana', 'label': 'Texas A&M University-Texarkana' },
    { 'id': 13518, 'value': 'Texas Christian University', 'label': 'Texas Christian University' },
    { 'id': 13519, 'value': 'Texas College', 'label': 'Texas College' },
    { 'id': 13520, 'value': 'Texas Lutheran University', 'label': 'Texas Lutheran University' },
    { 'id': 13521, 'value': 'Texas Southern University', 'label': 'Texas Southern University' },
    { 'id': 13522, 'value': 'Texas State University', 'label': 'Texas State University' },
    { 'id': 498, 'value': 'Texas Tech University', 'label': 'Texas Tech University' },
    { 'id': 13523, 'value': 'Texas Tech University Health Sciences Center', 'label': 'Texas Tech University Health Sciences Center' },
    { 'id': 13524, 'value': 'Texas Wesleyan University', 'label': 'Texas Wesleyan University' },
    { 'id': 13525, 'value': 'Texas Woman\'s University', 'label': 'Texas Woman\'s University' },
    { 'id': 14284, 'value': 'Texila American University Zambia', 'label': 'Texila American University Zambia' },
    { 'id': 4794, 'value': 'Texila American University, Guyana', 'label': 'Texila American University, Guyana' },
    { 'id': 10177, 'value': 'Textile Institute of Pakistan', 'label': 'Textile Institute of Pakistan' },
    { 'id': 6346, 'value': 'Tezpur University', 'label': 'Tezpur University' },
    { 'id': 7644, 'value': 'Tezukayama Gakuin University', 'label': 'Tezukayama Gakuin University' },
    { 'id': 7645, 'value': 'Tezukayama University', 'label': 'Tezukayama University' },
    { 'id': 14157, 'value': 'Thai Binh University of Medicine and Pharmacy', 'label': 'Thai Binh University of Medicine and Pharmacy' },
    { 'id': 14158, 'value': 'Thai Nguyen University', 'label': 'Thai Nguyen University' },
    { 'id': 11481, 'value': 'Thaksin University', 'label': 'Thaksin University' },
    { 'id': 14211, 'value': 'Thamar University', 'label': 'Thamar University' },
    { 'id': 11482, 'value': 'Thammasat University', 'label': 'Thammasat University' },
    { 'id': 8560, 'value': 'Thanlyin Technological University', 'label': 'Thanlyin Technological University' },
    { 'id': 6347, 'value': 'Thapar Institute of Engineering and Technology', 'label': 'Thapar Institute of Engineering and Technology' },
    { 'id': 5558, 'value': 'The Academic College of Tel-Aviv-Yaffo', 'label': 'The Academic College of Tel-Aviv-Yaffo' },
    { 'id': 8641, 'value': 'The Academy of Management', 'label': 'The Academy of Management' },
    { 'id': 272, 'value': 'The Ailey School', 'label': 'The Ailey School' },
    { 'id': 13526, 'value': 'The American College of Financial Services', 'label': 'The American College of Financial Services' },
    { 'id': 4761, 'value': 'The American College of Greece', 'label': 'The American College of Greece' },
    { 'id': 4762, 'value': 'The American College of Thessaloniki', 'label': 'The American College of Thessaloniki' },
    { 'id': 4085, 'value': 'The American University in Cairo', 'label': 'The American University in Cairo' },
    { 'id': 772, 'value': 'The American University of Afghanistan', 'label': 'The American University of Afghanistan' },
    { 'id': 6468, 'value': 'The American University of Iraq, Sulaimani', 'label': 'The American University of Iraq, Sulaimani' },
    { 'id': 6469, 'value': 'The American University of Kurdistan', 'label': 'The American University of Kurdistan' },
    { 'id': 4407, 'value': 'The American University of Paris', 'label': 'The American University of Paris' },
    { 'id': 4086, 'value': 'The Arab Academy for Management, Banking and Financial Sciences', 'label': 'The Arab Academy for Management, Banking and Financial Sciences' },
    { 'id': 13527, 'value': 'The Baptist College of Florida', 'label': 'The Baptist College of Florida' },
    { 'id': 10926, 'value': 'The Bonch-Bruevich St. Petersburg State University of Telecommunications', 'label': 'The Bonch-Bruevich St. Petersburg State University of Telecommunications' },
    { 'id': 666, 'value': 'The British University in Dubai', 'label': 'The British University in Dubai' },
    { 'id': 4087, 'value': 'The British University in Egypt', 'label': 'The British University in Egypt' },
    { 'id': 13528, 'value': 'The Catholic University of America', 'label': 'The Catholic University of America' },
    { 'id': 7830, 'value': 'The Catholic University of Eastern Africa', 'label': 'The Catholic University of Eastern Africa' },
    { 'id': 8135, 'value': 'The Catholic University of Korea', 'label': 'The Catholic University of Korea' },
    { 'id': 8693, 'value': 'The Catholic University of Malawi', 'label': 'The Catholic University of Malawi' },
    { 'id': 11290, 'value': 'The Catholic University of South Sudan', 'label': 'The Catholic University of South Sudan' },
    { 'id': 5559, 'value': 'The Center for Academic Studies', 'label': 'The Center for Academic Studies' },
    { 'id': 2835, 'value': 'The Central Academy of Drama', 'label': 'The Central Academy of Drama' },
    { 'id': 13529, 'value': 'The Chicago School of Professional Psychology', 'label': 'The Chicago School of Professional Psychology' },
    { 'id': 4801, 'value': 'The Chinese University of Hong Kong', 'label': 'The Chinese University of Hong Kong' },
    { 'id': 2836, 'value': 'The Chinese University of Hong Kong, Shenzhen', 'label': 'The Chinese University of Hong Kong, Shenzhen' },
    { 'id': 13530, 'value': 'The Citadel, The Military College of South Carolina', 'label': 'The Citadel, The Military College of South Carolina' },
    { 'id': 13531, 'value': 'The City College of New York', 'label': 'The City College of New York' },
    { 'id': 7831, 'value': 'The Co-operative University of Kenya', 'label': 'The Co-operative University of Kenya' },
    { 'id': 13532, 'value': 'The College at Brockport', 'label': 'The College at Brockport' },
    { 'id': 667, 'value': 'The College of Fashion and Design', 'label': 'The College of Fashion and Design' },
    { 'id': 13533, 'value': 'The College of Idaho', 'label': 'The College of Idaho' },
    { 'id': 5560, 'value': 'The College of Management - Academic Studies', 'label': 'The College of Management - Academic Studies' },
    { 'id': 13534, 'value': 'The College of New Jersey', 'label': 'The College of New Jersey' },
    { 'id': 13535, 'value': 'The College of Saint Rose', 'label': 'The College of Saint Rose' },
    { 'id': 13536, 'value': 'The College of St. Scholastica', 'label': 'The College of St. Scholastica' },
    { 'id': 13537, 'value': 'The College of Westchester', 'label': 'The College of Westchester' },
    { 'id': 13538, 'value': 'The College of Wooster', 'label': 'The College of Wooster' },
    { 'id': 13539, 'value': 'The Cooper Union for the Advancement of Science and Art', 'label': 'The Cooper Union for the Advancement of Science and Art' },
    { 'id': 14285, 'value': 'The Copperbelt University', 'label': 'The Copperbelt University' },
    { 'id': 365, 'value': 'The courtauld institute of art', 'label': 'The courtauld institute of art' },
    { 'id': 4562, 'value': 'The Courtauld Institute of Art, University of London', 'label': 'The Courtauld Institute of Art, University of London' },
    { 'id': 3380, 'value': 'The CTL Eurocollege', 'label': 'The CTL Eurocollege' },
    { 'id': 13540, 'value': 'The Culinary Institute of America', 'label': 'The Culinary Institute of America' },
    { 'id': 3381, 'value': 'The Cyprus Institute', 'label': 'The Cyprus Institute' },
    { 'id': 3382, 'value': 'The Cyprus Institute of Marketing', 'label': 'The Cyprus Institute of Marketing' },
    { 'id': 7832, 'value': 'The East African University', 'label': 'The East African University' },
    { 'id': 14286, 'value': 'The Eastern University', 'label': 'The Eastern University' },
    { 'id': 11483, 'value': 'The Eastern University of Management and Technology', 'label': 'The Eastern University of Management and Technology' },
    { 'id': 4802, 'value': 'The Education University of Hong Kong', 'label': 'The Education University of Hong Kong' },
    { 'id': 668, 'value': 'The Emirates Academy of Hospitality Management', 'label': 'The Emirates Academy of Hospitality Management' },
    { 'id': 6348, 'value': 'The English and Foreign Languages University', 'label': 'The English and Foreign Languages University' },
    { 'id': 8670, 'value': 'The European Graduate School', 'label': 'The European Graduate School' },
    { 'id': 13541, 'value': 'The Evergreen State College', 'label': 'The Evergreen State College' },
    { 'id': 11484, 'value': 'The Far Eastern University', 'label': 'The Far Eastern University' },
    { 'id': 11085, 'value': 'The Future University', 'label': 'The Future University' },
    { 'id': 536, 'value': 'The George Washington University', 'label': 'The George Washington University' },
    { 'id': 4088, 'value': 'The German University in Cairo', 'label': 'The German University in Cairo' },
    { 'id': 10178, 'value': 'The Government Sadiq College Women University, Bahawalpur', 'label': 'The Government Sadiq College Women University, Bahawalpur' },
    { 'id': 13542, 'value': 'The Graduate Center, CUNY', 'label': 'The Graduate Center, CUNY' },
    { 'id': 7646, 'value': 'The Graduate University for Advanced Studies', 'label': 'The Graduate University for Advanced Studies' },
    { 'id': 14649, 'value': 'The Hague University of Applied Sciences', 'label': 'The Hague University of Applied Sciences' },
    { 'id': 7057, 'value': 'The Hashemite University', 'label': 'The Hashemite University' },
    { 'id': 547, 'value': 'The Hebrew University of Jerusalem', 'label': 'The Hebrew University of Jerusalem' },
    { 'id': 4803, 'value': 'The Hong Kong Polytechnic University', 'label': 'The Hong Kong Polytechnic University' },
    { 'id': 4804, 'value': 'The Hong Kong University of Science and Technology', 'label': 'The Hong Kong University of Science and Technology' },
    { 'id': 6349, 'value': 'The IIS University', 'label': 'The IIS University' },
    { 'id': 8642, 'value': 'The Ikh Zasag University', 'label': 'The Ikh Zasag University' },
    { 'id': 6350, 'value': 'The Indian Law Institute', 'label': 'The Indian Law Institute' },
    { 'id': 8643, 'value': 'The Institute of International Studies', 'label': 'The Institute of International Studies' },
    { 'id': 10179, 'value': 'The Institute of Management Sciences', 'label': 'The Institute of Management Sciences' },
    { 'id': 13543, 'value': 'The Institute of World Politics', 'label': 'The Institute of World Politics' },
    { 'id': 5561, 'value': 'The Interdisciplinary Center', 'label': 'The Interdisciplinary Center' },
    { 'id': 7647, 'value': 'The International University of Kagoshima', 'label': 'The International University of Kagoshima' },
    { 'id': 9295, 'value': 'The International University of Management', 'label': 'The International University of Management' },
    { 'id': 1319, 'value': 'The International University of Scholars', 'label': 'The International University of Scholars' },
    { 'id': 10180, 'value': 'The Islamia University of Bahawalpur', 'label': 'The Islamia University of Bahawalpur' },
    { 'id': 10399, 'value': 'The Islamic University of Gaza', 'label': 'The Islamic University of Gaza' },
    { 'id': 7648, 'value': 'The Japanese Red Cross College of Nursing', 'label': 'The Japanese Red Cross College of Nursing' },
    { 'id': 7649, 'value': 'The Japanese Red Cross Hiroshima College of Nursing', 'label': 'The Japanese Red Cross Hiroshima College of Nursing' },
    { 'id': 7650, 'value': 'The Japanese Red Cross Kyushu International College of Nursing', 'label': 'The Japanese Red Cross Kyushu International College of Nursing' },
    { 'id': 5562, 'value': 'The Jerusalem Academy of Music and Dance', 'label': 'The Jerusalem Academy of Music and Dance' },
    { 'id': 13544, 'value': 'The Juilliard School', 'label': 'The Juilliard School' },
    { 'id': 13546, 'value': 'The King\'s University', 'label': 'The King\'s University' },
    { 'id': 1902, 'value': 'The King’s University', 'label': 'The King’s University' },
    { 'id': 3383, 'value': 'The Limassol College', 'label': 'The Limassol College' },
    { 'id': 6351, 'value': 'The LNM Institute of Information Technology', 'label': 'The LNM Institute of Information Technology' },
    { 'id': 14381, 'value': 'The London Institute of Banking & Finance', 'label': 'The London Institute of Banking & Finance' },
    { 'id': 4563, 'value': 'The London School of Economics and Political Science', 'label': 'The London School of Economics and Political Science' },
    { 'id': 6352, 'value': 'The Maharaja Sayajirao University of Baroda', 'label': 'The Maharaja Sayajirao University of Baroda' },
    { 'id': 8676, 'value': 'The Maldives National University', 'label': 'The Maldives National University' },
    { 'id': 13547, 'value': 'The Master\'s University', 'label': 'The Master\'s University' },
    { 'id': 1320, 'value': 'The Millenium University', 'label': 'The Millenium University' },
    { 'id': 2837, 'value': 'The National Academy of Chinese Theatre Arts', 'label': 'The National Academy of Chinese Theatre Arts' },
    { 'id': 9987, 'value': 'The National Teachers College', 'label': 'The National Teachers College' },
    { 'id': 6353, 'value': 'The National University of Advanced Legal Studies', 'label': 'The National University of Advanced Legal Studies' },
    { 'id': 6354, 'value': 'The Neotia University', 'label': 'The Neotia University' },
    { 'id': 13548, 'value': 'The New School', 'label': 'The New School' },
    { 'id': 571, 'value': 'The New School For Design - Parsons', 'label': 'The New School For Design - Parsons' },
    { 'id': 7651, 'value': 'The Nippon Dental University', 'label': 'The Nippon Dental University' },
    { 'id': 6355, 'value': 'The Northcap University', 'label': 'The Northcap University' },
    { 'id': 13549, 'value': 'The Ohio State University', 'label': 'The Ohio State University' },
    { 'id': 4805, 'value': 'The Open University of Hong Kong', 'label': 'The Open University of Hong Kong' },
    { 'id': 1321, 'value': 'The People\'s University of Bangladesh', 'label': 'The People\'s University of Bangladesh' },
    { 'id': 9988, 'value': 'The Philippine Women\'s University', 'label': 'The Philippine Women\'s University' },
    { 'id': 3384, 'value': 'The Philips College', 'label': 'The Philips College' },
    { 'id': 7833, 'value': 'The Presbyterian University of East Africa', 'label': 'The Presbyterian University of East Africa' },
    { 'id': 8258, 'value': 'The Regional Social and Innovation University', 'label': 'The Regional Social and Innovation University' },
    { 'id': 13550, 'value': 'The Rockefeller University', 'label': 'The Rockefeller University' },
    { 'id': 10927, 'value': 'The Rostov State Conservatoire', 'label': 'The Rostov State Conservatoire' },
    { 'id': 4564, 'value': 'The Royal Central School of Speech and Drama', 'label': 'The Royal Central School of Speech and Drama' },
    { 'id': 87, 'value': 'The Royal Conservatoire of Scotland', 'label': 'The Royal Conservatoire of Scotland' },
    { 'id': 13551, 'value': 'The Sage Colleges', 'label': 'The Sage Colleges' },
    { 'id': 6356, 'value': 'The Sanskrit College and University', 'label': 'The Sanskrit College and University' },
    { 'id': 13552, 'value': 'The School of Architecture at Taliesin', 'label': 'The School of Architecture at Taliesin' },
    { 'id': 10928, 'value': 'The State University of Management', 'label': 'The State University of Management' },
    { 'id': 13553, 'value': 'The State University of New York at Potsdam', 'label': 'The State University of New York at Potsdam' },
    { 'id': 11970, 'value': 'The State University of Zanzibar', 'label': 'The State University of Zanzibar' },
    { 'id': 10181, 'value': 'The Superior College', 'label': 'The Superior College' },
    { 'id': 9434, 'value': 'The Technical University', 'label': 'The Technical University' },
    { 'id': 14287, 'value': 'The United Church of Zambia University', 'label': 'The United Church of Zambia University' },
    { 'id': 1089, 'value': 'The University of Adelaide', 'label': 'The University of Adelaide' },
    { 'id': 10182, 'value': 'The University of Agriculture, Peshawar', 'label': 'The University of Agriculture, Peshawar' },
    { 'id': 13554, 'value': 'The University of Alabama', 'label': 'The University of Alabama' },
    { 'id': 13555, 'value': 'The University of Alabama in Huntsville', 'label': 'The University of Alabama in Huntsville' },
    { 'id': 546, 'value': 'The University of Arizona', 'label': 'The University of Arizona' },
    { 'id': 11971, 'value': 'The University of Arusha', 'label': 'The University of Arusha' },
    { 'id': 14288, 'value': 'The University of Barotseland', 'label': 'The University of Barotseland' },
    { 'id': 1903, 'value': 'The University of British Columbia', 'label': 'The University of British Columbia' },
    { 'id': 4565, 'value': 'The University of Buckingham', 'label': 'The University of Buckingham' },
    { 'id': 7930, 'value': 'The University of Cambodia', 'label': 'The University of Cambodia' },
    { 'id': 11485, 'value': 'The University of Central Thailand', 'label': 'The University of Central Thailand' },
    { 'id': 513, 'value': 'The University of Chicago  - Booth School of Business', 'label': 'The University of Chicago  - Booth School of Business' },
    { 'id': 14159, 'value': 'The University of Da nang', 'label': 'The University of Da nang' },
    { 'id': 11972, 'value': 'The University of Dodoma', 'label': 'The University of Dodoma' },
    { 'id': 6470, 'value': 'The University of Duhok', 'label': 'The University of Duhok' },
    { 'id': 4566, 'value': 'The University of Edinburgh', 'label': 'The University of Edinburgh' },
    { 'id': 7652, 'value': 'The University of Electro-Communications', 'label': 'The University of Electro-Communications' },
    { 'id': 10183, 'value': 'The University of Faisalabad', 'label': 'The University of Faisalabad' },
    { 'id': 4243, 'value': 'The University of Fiji', 'label': 'The University of Fiji' },
    { 'id': 13556, 'value': 'The University of Findlay', 'label': 'The University of Findlay' },
    { 'id': 7653, 'value': 'The University of Fukuchiyama', 'label': 'The University of Fukuchiyama' },
    { 'id': 4660, 'value': 'The University of Georgia', 'label': 'The University of Georgia' },
    { 'id': 6771, 'value': 'The University of Guilan', 'label': 'The University of Guilan' },
    { 'id': 4806, 'value': 'The University of Hong Kong', 'label': 'The University of Hong Kong' },
    { 'id': 4567, 'value': 'The University of Hull', 'label': 'The University of Hull' },
    { 'id': 482, 'value': 'The University of Iowa', 'label': 'The University of Iowa' },
    { 'id': 7654, 'value': 'The University of Kitakyushu', 'label': 'The University of Kitakyushu' },
    { 'id': 10184, 'value': 'The University of Lahore', 'label': 'The University of Lahore' },
    { 'id': 479, 'value': 'The University of Law', 'label': 'The University of Law' },
    { 'id': 13557, 'value': 'The University of Maine', 'label': 'The University of Maine' },
    { 'id': 4568, 'value': 'The University of Manchester', 'label': 'The University of Manchester' },
    { 'id': 9989, 'value': 'The University of Manila', 'label': 'The University of Manila' },
    { 'id': 572, 'value': 'The University of Manitoba', 'label': 'The University of Manitoba' },
    { 'id': 14160, 'value': 'The University of Medicine and Pharmacy at Ho Chi Minh City', 'label': 'The University of Medicine and Pharmacy at Ho Chi Minh City' },
    { 'id': 1090, 'value': 'The University of Melbourne', 'label': 'The University of Melbourne' },
    { 'id': 13558, 'value': 'The University of Memphis', 'label': 'The University of Memphis' },
    { 'id': 13559, 'value': 'The University of Montana', 'label': 'The University of Montana' },
    { 'id': 13560, 'value': 'The University of Montana Western', 'label': 'The University of Montana Western' },
    { 'id': 6471, 'value': 'The University of Mustansiriyah', 'label': 'The University of Mustansiriyah' },
    { 'id': 1091, 'value': 'The University of New South Wales', 'label': 'The University of New South Wales' },
    { 'id': 1092, 'value': 'The University of Newcastle', 'label': 'The University of Newcastle' },
    { 'id': 9646, 'value': 'The University of Nizwa', 'label': 'The University of Nizwa' },
    { 'id': 4569, 'value': 'The University of Northampton', 'label': 'The University of Northampton' },
    { 'id': 1093, 'value': 'The University of Notre Dame Australia', 'label': 'The University of Notre Dame Australia' },
    { 'id': 4570, 'value': 'The University of Nottingham', 'label': 'The University of Nottingham' },
    { 'id': 2838, 'value': 'The University of Nottingham Ningbo, China', 'label': 'The University of Nottingham Ningbo, China' },
    { 'id': 7655, 'value': 'The University of Okinawa', 'label': 'The University of Okinawa' },
    { 'id': 13561, 'value': 'The University of Oklahoma', 'label': 'The University of Oklahoma' },
    { 'id': 13562, 'value': 'The University of Oklahoma Health Sciences Center', 'label': 'The University of Oklahoma Health Sciences Center' },
    { 'id': 10185, 'value': 'The University of Poonch', 'label': 'The University of Poonch' },
    { 'id': 1094, 'value': 'The University of Queensland', 'label': 'The University of Queensland' },
    { 'id': 4571, 'value': 'The University of Sheffield', 'label': 'The University of Sheffield' },
    { 'id': 7656, 'value': 'The University of Shiga Prefecture', 'label': 'The University of Shiga Prefecture' },
    { 'id': 7657, 'value': 'The University of Shimane', 'label': 'The University of Shimane' },
    { 'id': 8136, 'value': 'The University of Suwon', 'label': 'The University of Suwon' },
    { 'id': 1095, 'value': 'The University of Sydney', 'label': 'The University of Sydney' },
    { 'id': 13563, 'value': 'The University of Tampa', 'label': 'The University of Tampa' },
    { 'id': 13564, 'value': 'The University of Tennessee at Chattanooga', 'label': 'The University of Tennessee at Chattanooga' },
    { 'id': 13565, 'value': 'The University of Tennessee at Martin', 'label': 'The University of Tennessee at Martin' },
    { 'id': 13566, 'value': 'The University of Tennessee Health Science Center', 'label': 'The University of Tennessee Health Science Center' },
    { 'id': 13567, 'value': 'The University of Tennessee, Knoxville', 'label': 'The University of Tennessee, Knoxville' },
    { 'id': 13568, 'value': 'The University of Texas at Arlington', 'label': 'The University of Texas at Arlington' },
    { 'id': 13569, 'value': 'The University of Texas at Austin', 'label': 'The University of Texas at Austin' },
    { 'id': 13570, 'value': 'The University of Texas at Dallas', 'label': 'The University of Texas at Dallas' },
    { 'id': 13571, 'value': 'The University of Texas at El Paso', 'label': 'The University of Texas at El Paso' },
    { 'id': 13572, 'value': 'The University of Texas at San Antonio', 'label': 'The University of Texas at San Antonio' },
    { 'id': 13573, 'value': 'The University of Texas at Tyler', 'label': 'The University of Texas at Tyler' },
    { 'id': 13574, 'value': 'The University of Texas Health Science Center at Houston', 'label': 'The University of Texas Health Science Center at Houston' },
    { 'id': 13575, 'value': 'The University of Texas Health Science Center at San Antonio', 'label': 'The University of Texas Health Science Center at San Antonio' },
    { 'id': 13576, 'value': 'The University of Texas Health Science Center at Tyler', 'label': 'The University of Texas Health Science Center at Tyler' },
    { 'id': 13577, 'value': 'The University of Texas MD Anderson Cancer Center', 'label': 'The University of Texas MD Anderson Cancer Center' },
    { 'id': 13578, 'value': 'The University of Texas Medical Branch at Galveston', 'label': 'The University of Texas Medical Branch at Galveston' },
    { 'id': 13579, 'value': 'The University of Texas of the Permian Basin', 'label': 'The University of Texas of the Permian Basin' },
    { 'id': 13580, 'value': 'The University of the Arts', 'label': 'The University of the Arts' },
    { 'id': 4244, 'value': 'The University of the South Pacific', 'label': 'The University of the South Pacific' },
    { 'id': 271, 'value': 'The University of the South Sewanee', 'label': 'The University of the South Sewanee' },
    { 'id': 1202, 'value': 'The University of the West Indies, Cave Hill Campus', 'label': 'The University of the West Indies, Cave Hill Campus' },
    { 'id': 7030, 'value': 'The University of the West Indies, Mona', 'label': 'The University of the West Indies, Mona' },
    { 'id': 11800, 'value': 'The University of the West Indies, St. Augustine', 'label': 'The University of the West Indies, St. Augustine' },
    { 'id': 7658, 'value': 'The University of Tokushima', 'label': 'The University of Tokushima' },
    { 'id': 7659, 'value': 'The University of Tokyo', 'label': 'The University of Tokyo' },
    { 'id': 11801, 'value': 'The University of Trinidad and Tobago', 'label': 'The University of Trinidad and Tobago' },
    { 'id': 13581, 'value': 'The University of Tulsa', 'label': 'The University of Tulsa' },
    { 'id': 13582, 'value': 'The University of Utah', 'label': 'The University of Utah' },
    { 'id': 13583, 'value': 'The University of Virginia\'s College at Wise', 'label': 'The University of Virginia\'s College at Wise' },
    { 'id': 4572, 'value': 'The University of Warwick', 'label': 'The University of Warwick' },
    { 'id': 13584, 'value': 'The University of West Alabama', 'label': 'The University of West Alabama' },
    { 'id': 13585, 'value': 'The University of West Los Angeles', 'label': 'The University of West Los Angeles' },
    { 'id': 1096, 'value': 'The University of Western Australia', 'label': 'The University of Western Australia' },
    { 'id': 4573, 'value': 'The University of Winchester', 'label': 'The University of Winchester' },
    { 'id': 1904, 'value': 'The University of Winnipeg', 'label': 'The University of Winnipeg' },
    { 'id': 4574, 'value': 'The University of York', 'label': 'The University of York' },
    { 'id': 6357, 'value': 'The West Bengal National University of Juridical Sciences', 'label': 'The West Bengal National University of Juridical Sciences' },
    { 'id': 10186, 'value': 'The Women University, Multan', 'label': 'The Women University, Multan' },
    { 'id': 7058, 'value': 'The World Islamic Sciences and Education University', 'label': 'The World Islamic Sciences and Education University' },
    { 'id': 13586, 'value': 'The Wright Institute', 'label': 'The Wright Institute' },
    { 'id': 14289, 'value': 'The Zambia Catholic University', 'label': 'The Zambia Catholic University' },
    { 'id': 3769, 'value': 'Theologische Hochschule Friedensau', 'label': 'Theologische Hochschule Friedensau' },
    { 'id': 3770, 'value': 'Theologische Hochschule Reutlingen', 'label': 'Theologische Hochschule Reutlingen' },
    { 'id': 11486, 'value': 'Thepsatri Rajabhat University', 'label': 'Thepsatri Rajabhat University' },
    { 'id': 13587, 'value': 'Thiel College', 'label': 'Thiel College' },
    { 'id': 6358, 'value': 'Thiruvalluvar University', 'label': 'Thiruvalluvar University' },
    { 'id': 13588, 'value': 'Thomas Aquinas College', 'label': 'Thomas Aquinas College' },
    { 'id': 13589, 'value': 'Thomas College', 'label': 'Thomas College' },
    { 'id': 13590, 'value': 'Thomas Edison State University', 'label': 'Thomas Edison State University' },
    { 'id': 13591, 'value': 'Thomas Jefferson School of Law', 'label': 'Thomas Jefferson School of Law' },
    { 'id': 372, 'value': 'Thomas Jefferson University', 'label': 'Thomas Jefferson University' },
    { 'id': 13592, 'value': 'Thomas More College of Liberal Arts', 'label': 'Thomas More College of Liberal Arts' },
    { 'id': 1388, 'value': 'Thomas More Hogeschool', 'label': 'Thomas More Hogeschool' },
    { 'id': 13593, 'value': 'Thomas More University', 'label': 'Thomas More University' },
    { 'id': 13594, 'value': 'Thomas University', 'label': 'Thomas University' },
    { 'id': 1905, 'value': 'Thompson Rivers University', 'label': 'Thompson Rivers University' },
    { 'id': 11487, 'value': 'Thonburi University', 'label': 'Thonburi University' },
    { 'id': 6359, 'value': 'Thunchath Ezhuthachan Malayalam University', 'label': 'Thunchath Ezhuthachan Malayalam University' },
    { 'id': 14161, 'value': 'Thuongmai University', 'label': 'Thuongmai University' },
    { 'id': 14162, 'value': 'Thuyloi University', 'label': 'Thuyloi University' },
    { 'id': 2839, 'value': 'Tianjin Academy of Fine Arts', 'label': 'Tianjin Academy of Fine Arts' },
    { 'id': 2840, 'value': 'Tianjin Agricultural University', 'label': 'Tianjin Agricultural University' },
    { 'id': 2841, 'value': 'Tianjin Chengjian University', 'label': 'Tianjin Chengjian University' },
    { 'id': 2842, 'value': 'Tianjin Conservatory of Music', 'label': 'Tianjin Conservatory of Music' },
    { 'id': 2843, 'value': 'Tianjin Foreign Studies University', 'label': 'Tianjin Foreign Studies University' },
    { 'id': 2844, 'value': 'Tianjin Medical University', 'label': 'Tianjin Medical University' },
    { 'id': 2845, 'value': 'Tianjin Normal University', 'label': 'Tianjin Normal University' },
    { 'id': 2846, 'value': 'Tianjin Polytechnic University', 'label': 'Tianjin Polytechnic University' },
    { 'id': 2847, 'value': 'Tianjin Tianshi College', 'label': 'Tianjin Tianshi College' },
    { 'id': 2848, 'value': 'Tianjin University', 'label': 'Tianjin University' },
    { 'id': 2849, 'value': 'Tianjin University of Commerce', 'label': 'Tianjin University of Commerce' },
    { 'id': 2850, 'value': 'Tianjin University of Finance and Economics', 'label': 'Tianjin University of Finance and Economics' },
    { 'id': 2851, 'value': 'Tianjin University of Science and Technology', 'label': 'Tianjin University of Science and Technology' },
    { 'id': 2852, 'value': 'Tianjin University of Sport', 'label': 'Tianjin University of Sport' },
    { 'id': 2853, 'value': 'Tianjin University of Technology', 'label': 'Tianjin University of Technology' },
    { 'id': 2854, 'value': 'Tianjin University of Technology and Education', 'label': 'Tianjin University of Technology and Education' },
    { 'id': 2855, 'value': 'Tianjin University of Traditional Chinese Medicine', 'label': 'Tianjin University of Traditional Chinese Medicine' },
    { 'id': 2856, 'value': 'Tianjin Zhongde University of Applied Sciences', 'label': 'Tianjin Zhongde University of Applied Sciences' },
    { 'id': 2857, 'value': 'Tianshui Normal University', 'label': 'Tianshui Normal University' },
    { 'id': 9550, 'value': 'TIAS School for Business and Society', 'label': 'TIAS School for Business and Society' },
    { 'id': 2858, 'value': 'Tibet University', 'label': 'Tibet University' },
    { 'id': 2859, 'value': 'Tibetan Traditional Medical College', 'label': 'Tibetan Traditional Medical College' },
    { 'id': 3771, 'value': 'Tierärztliche Hochschule Hannover', 'label': 'Tierärztliche Hochschule Hannover' },
    { 'id': 13595, 'value': 'Tiffin University', 'label': 'Tiffin University' },
    { 'id': 6360, 'value': 'Tilak Maharashtra Vidyapeeth', 'label': 'Tilak Maharashtra Vidyapeeth' },
    { 'id': 521, 'value': 'Tilburg University', 'label': 'Tilburg University' },
    { 'id': 6361, 'value': 'Tilka Manjhi Bhagalpur University', 'label': 'Tilka Manjhi Bhagalpur University' },
    { 'id': 11592, 'value': 'TIME Université', 'label': 'TIME Université' },
    { 'id': 1322, 'value': 'Times University of Bangladesh', 'label': 'Times University of Bangladesh' },
    { 'id': 786, 'value': 'Tirana Business University College', 'label': 'Tirana Business University College' },
    { 'id': 6472, 'value': 'Tishk International University', 'label': 'Tishk International University' },
    { 'id': 11351, 'value': 'Tishreen University', 'label': 'Tishreen University' },
    { 'id': 11780, 'value': 'TOBB Ekonomi ve Teknoloji Üniversitesi', 'label': 'TOBB Ekonomi ve Teknoloji Üniversitesi' },
    { 'id': 13596, 'value': 'Toccoa Falls College', 'label': 'Toccoa Falls College' },
    { 'id': 10929, 'value': 'Togliatti State University', 'label': 'Togliatti State University' },
    { 'id': 7660, 'value': 'Toho College of Music', 'label': 'Toho College of Music' },
    { 'id': 7661, 'value': 'Toho Gakuen School of Music', 'label': 'Toho Gakuen School of Music' },
    { 'id': 7662, 'value': 'Toho University', 'label': 'Toho University' },
    { 'id': 7663, 'value': 'Tohoku Bunka Gakuen University', 'label': 'Tohoku Bunka Gakuen University' },
    { 'id': 7664, 'value': 'Tohoku Fukushi University', 'label': 'Tohoku Fukushi University' },
    { 'id': 7665, 'value': 'Tohoku Gakuin University', 'label': 'Tohoku Gakuin University' },
    { 'id': 7666, 'value': 'Tohoku Institute of Technology', 'label': 'Tohoku Institute of Technology' },
    { 'id': 7667, 'value': 'Tohoku Pharmaceutical University', 'label': 'Tohoku Pharmaceutical University' },
    { 'id': 7668, 'value': 'Tohoku Seikatsu Bunka College', 'label': 'Tohoku Seikatsu Bunka College' },
    { 'id': 7669, 'value': 'Tohoku University', 'label': 'Tohoku University' },
    { 'id': 7670, 'value': 'Tohoku University of Art and Design', 'label': 'Tohoku University of Art and Design' },
    { 'id': 7671, 'value': 'Tohoku University of Community Service and Science', 'label': 'Tohoku University of Community Service and Science' },
    { 'id': 7672, 'value': 'Tohoku Women\'s College', 'label': 'Tohoku Women\'s College' },
    { 'id': 7673, 'value': 'Tohto College of Health Sciences', 'label': 'Tohto College of Health Sciences' },
    { 'id': 7674, 'value': 'Toin University of Yokohama', 'label': 'Toin University of Yokohama' },
    { 'id': 7675, 'value': 'Tokai Gakuen University', 'label': 'Tokai Gakuen University' },
    { 'id': 7676, 'value': 'Tokai Gakuin University', 'label': 'Tokai Gakuin University' },
    { 'id': 7677, 'value': 'Tokai University', 'label': 'Tokai University' },
    { 'id': 11781, 'value': 'Tokat Gaziosmanpasa Üniversitesi', 'label': 'Tokat Gaziosmanpasa Üniversitesi' },
    { 'id': 7678, 'value': 'Tokiwa University', 'label': 'Tokiwa University' },
    { 'id': 7679, 'value': 'Tokiwakai Gakuen University', 'label': 'Tokiwakai Gakuen University' },
    { 'id': 11931, 'value': 'Toko University', 'label': 'Toko University' },
    { 'id': 7680, 'value': 'Tokoha University', 'label': 'Tokoha University' },
    { 'id': 7681, 'value': 'Tokushima Bunri University', 'label': 'Tokushima Bunri University' },
    { 'id': 7682, 'value': 'Tokuyama University', 'label': 'Tokuyama University' },
    { 'id': 7683, 'value': 'Tokyo Christian University', 'label': 'Tokyo Christian University' },
    { 'id': 7684, 'value': 'Tokyo City University', 'label': 'Tokyo City University' },
    { 'id': 7685, 'value': 'Tokyo College of Music', 'label': 'Tokyo College of Music' },
    { 'id': 7686, 'value': 'Tokyo Denki University', 'label': 'Tokyo Denki University' },
    { 'id': 7687, 'value': 'Tokyo Dental College', 'label': 'Tokyo Dental College' },
    { 'id': 7688, 'value': 'Tokyo Fuji University', 'label': 'Tokyo Fuji University' },
    { 'id': 7689, 'value': 'Tokyo Future University', 'label': 'Tokyo Future University' },
    { 'id': 7690, 'value': 'Tokyo Gakugei University', 'label': 'Tokyo Gakugei University' },
    { 'id': 7691, 'value': 'Tokyo Health Care University', 'label': 'Tokyo Health Care University' },
    { 'id': 7692, 'value': 'Tokyo Institute of Technology', 'label': 'Tokyo Institute of Technology' },
    { 'id': 7693, 'value': 'Tokyo International University', 'label': 'Tokyo International University' },
    { 'id': 7694, 'value': 'Tokyo Jogakkan College', 'label': 'Tokyo Jogakkan College' },
    { 'id': 7695, 'value': 'Tokyo Junshin University', 'label': 'Tokyo Junshin University' },
    { 'id': 7696, 'value': 'Tokyo Kasei University', 'label': 'Tokyo Kasei University' },
    { 'id': 7697, 'value': 'Tokyo Kasei-Gakuin University', 'label': 'Tokyo Kasei-Gakuin University' },
    { 'id': 7698, 'value': 'Tokyo Keizai University', 'label': 'Tokyo Keizai University' },
    { 'id': 7699, 'value': 'Tokyo Medical and Dental University', 'label': 'Tokyo Medical and Dental University' },
    { 'id': 7700, 'value': 'Tokyo Medical University', 'label': 'Tokyo Medical University' },
    { 'id': 7701, 'value': 'Tokyo Metropolitan University', 'label': 'Tokyo Metropolitan University' },
    { 'id': 7702, 'value': 'Tokyo Polytechnic University', 'label': 'Tokyo Polytechnic University' },
    { 'id': 7703, 'value': 'Tokyo Seiei College', 'label': 'Tokyo Seiei College' },
    { 'id': 7704, 'value': 'Tokyo Seitoku University', 'label': 'Tokyo Seitoku University' },
    { 'id': 7705, 'value': 'Tokyo Union Theological Seminary', 'label': 'Tokyo Union Theological Seminary' },
    { 'id': 7706, 'value': 'Tokyo University of Agriculture', 'label': 'Tokyo University of Agriculture' },
    { 'id': 7707, 'value': 'Tokyo University of Agriculture and Technology', 'label': 'Tokyo University of Agriculture and Technology' },
    { 'id': 7708, 'value': 'Tokyo University of Foreign Studies', 'label': 'Tokyo University of Foreign Studies' },
    { 'id': 7709, 'value': 'Tokyo University of Information Sciences', 'label': 'Tokyo University of Information Sciences' },
    { 'id': 7710, 'value': 'Tokyo University of Marine Science and Technology', 'label': 'Tokyo University of Marine Science and Technology' },
    { 'id': 7711, 'value': 'Tokyo University of Pharmacy and Life Science', 'label': 'Tokyo University of Pharmacy and Life Science' },
    { 'id': 7712, 'value': 'Tokyo University of Science', 'label': 'Tokyo University of Science' },
    { 'id': 7713, 'value': 'Tokyo University of Social Welfare', 'label': 'Tokyo University of Social Welfare' },
    { 'id': 7714, 'value': 'Tokyo University of Technology', 'label': 'Tokyo University of Technology' },
    { 'id': 7715, 'value': 'Tokyo University of the Arts', 'label': 'Tokyo University of the Arts' },
    { 'id': 7716, 'value': 'Tokyo Woman\'s Christian University', 'label': 'Tokyo Woman\'s Christian University' },
    { 'id': 7717, 'value': 'Tokyo Women\'s College of Physical Education', 'label': 'Tokyo Women\'s College of Physical Education' },
    { 'id': 7718, 'value': 'Tokyo Women\'s Medical University', 'label': 'Tokyo Women\'s Medical University' },
    { 'id': 7719, 'value': 'Tokyo Zokei University', 'label': 'Tokyo Zokei University' },
    { 'id': 773, 'value': 'Tolo-e-Aftab Institute of Higher Education', 'label': 'Tolo-e-Aftab Institute of Higher Education' },
    { 'id': 7720, 'value': 'Tomakomai Komazawa University', 'label': 'Tomakomai Komazawa University' },
    { 'id': 4927, 'value': 'Tomori Pál Foiskola', 'label': 'Tomori Pál Foiskola' },
    { 'id': 10930, 'value': 'Tomsk Polytechnic University', 'label': 'Tomsk Polytechnic University' },
    { 'id': 10931, 'value': 'Tomsk State Pedagogical University', 'label': 'Tomsk State Pedagogical University' },
    { 'id': 10932, 'value': 'Tomsk State University', 'label': 'Tomsk State University' },
    { 'id': 10933, 'value': 'Tomsk State University of Architecture and Building', 'label': 'Tomsk State University of Architecture and Building' },
    { 'id': 10934, 'value': 'Tomsk State University of Control Systems and Radioelectronics', 'label': 'Tomsk State University of Control Systems and Radioelectronics' },
    { 'id': 2860, 'value': 'Tonghua Normal University', 'label': 'Tonghua Normal University' },
    { 'id': 2861, 'value': 'Tongji University', 'label': 'Tongji University' },
    { 'id': 2862, 'value': 'Tongling University', 'label': 'Tongling University' },
    { 'id': 8137, 'value': 'Tongmyung University', 'label': 'Tongmyung University' },
    { 'id': 2863, 'value': 'Tongren University', 'label': 'Tongren University' },
    { 'id': 11782, 'value': 'Toros Üniversitesi', 'label': 'Toros Üniversitesi' },
    { 'id': 1097, 'value': 'Torrens University Australia', 'label': 'Torrens University Australia' },
    { 'id': 14022, 'value': 'Toshkent Arxitektura Qurilish Instituti', 'label': 'Toshkent Arxitektura Qurilish Instituti' },
    { 'id': 14023, 'value': 'Toshkent Avtomobil Yo\'llarini loyihalash, Qurish va Ekspluatatsiyasi Instituti', 'label': 'Toshkent Avtomobil Yo\'llarini loyihalash, Qurish va Ekspluatatsiyasi Instituti' },
    { 'id': 14024, 'value': 'Toshkent Axborot Texnologiyalari Universiteti', 'label': 'Toshkent Axborot Texnologiyalari Universiteti' },
    { 'id': 14025, 'value': 'Toshkent Davlat Iqtisodiyot Universiteti', 'label': 'Toshkent Davlat Iqtisodiyot Universiteti' },
    { 'id': 14026, 'value': 'Toshkent Davlat O\'zbek Tili va Adabiyoti Universiteti', 'label': 'Toshkent Davlat O\'zbek Tili va Adabiyoti Universiteti' },
    { 'id': 14027, 'value': 'Toshkent Davlat Pedagogika Universiteti', 'label': 'Toshkent Davlat Pedagogika Universiteti' },
    { 'id': 14028, 'value': 'Toshkent Davlat Sharqshunoslik Instituti', 'label': 'Toshkent Davlat Sharqshunoslik Instituti' },
    { 'id': 14029, 'value': 'Toshkent Davlat Stomatologiya Instituti', 'label': 'Toshkent Davlat Stomatologiya Instituti' },
    { 'id': 14030, 'value': 'Toshkent Davlat Texnika Universiteti', 'label': 'Toshkent Davlat Texnika Universiteti' },
    { 'id': 14031, 'value': 'Toshkent Davlat Yuridik Universiteti', 'label': 'Toshkent Davlat Yuridik Universiteti' },
    { 'id': 14032, 'value': 'Toshkent Farmatsevtika Instituti', 'label': 'Toshkent Farmatsevtika Instituti' },
    { 'id': 14033, 'value': 'Toshkent irrigatsiya va qishloq xo\'jaligini mexanizatsiyalash muhandislari instituti', 'label': 'Toshkent irrigatsiya va qishloq xo\'jaligini mexanizatsiyalash muhandislari instituti' },
    { 'id': 14034, 'value': 'Toshkent Islom Universiteti', 'label': 'Toshkent Islom Universiteti' },
    { 'id': 14035, 'value': 'Toshkent Kimyo-Texnologiya Instituti', 'label': 'Toshkent Kimyo-Texnologiya Instituti' },
    { 'id': 14036, 'value': 'Toshkent Moliya Instituti', 'label': 'Toshkent Moliya Instituti' },
    { 'id': 14037, 'value': 'Toshkent Pediatriya Tibbiyot Instituti', 'label': 'Toshkent Pediatriya Tibbiyot Instituti' },
    { 'id': 14038, 'value': 'Toshkent Shahridagi Inha Universiteti', 'label': 'Toshkent Shahridagi Inha Universiteti' },
    { 'id': 14039, 'value': 'Toshkent Shahridagi Singapur Menejmentni Rivojlantirish Instituti', 'label': 'Toshkent Shahridagi Singapur Menejmentni Rivojlantirish Instituti' },
    { 'id': 14040, 'value': 'Toshkent Shahridagi Turin Politexnika Universiteti', 'label': 'Toshkent Shahridagi Turin Politexnika Universiteti' },
    { 'id': 14041, 'value': 'Toshkent Shahridagi Xalqaro Vestminster Universiteti', 'label': 'Toshkent Shahridagi Xalqaro Vestminster Universiteti' },
    { 'id': 14042, 'value': 'Toshkent Temir yo\'l Muhandislari Instituti', 'label': 'Toshkent Temir yo\'l Muhandislari Instituti' },
    { 'id': 14043, 'value': 'Toshkent Tibbiyot Akademiyasi', 'label': 'Toshkent Tibbiyot Akademiyasi' },
    { 'id': 14044, 'value': 'Toshkent To\'qimachilik va Yengil Sanoat Instituti', 'label': 'Toshkent To\'qimachilik va Yengil Sanoat Instituti' },
    { 'id': 14045, 'value': 'Toshkentagi M. V. Lomonosov nomidagi Moskva Davlat Universiteti', 'label': 'Toshkentagi M. V. Lomonosov nomidagi Moskva Davlat Universiteti' },
    { 'id': 7721, 'value': 'Tottori University', 'label': 'Tottori University' },
    { 'id': 7722, 'value': 'Tottori University of Environmental Studies', 'label': 'Tottori University of Environmental Studies' },
    { 'id': 13597, 'value': 'Tougaloo College', 'label': 'Tougaloo College' },
    { 'id': 4408, 'value': 'Toulouse Business School', 'label': 'Toulouse Business School' },
    { 'id': 188, 'value': 'Toulouse School of Economics', 'label': 'Toulouse School of Economics' },
    { 'id': 13598, 'value': 'Touro College', 'label': 'Touro College' },
    { 'id': 3772, 'value': 'Touro College Berlin', 'label': 'Touro College Berlin' },
    { 'id': 13599, 'value': 'Touro University California', 'label': 'Touro University California' },
    { 'id': 13600, 'value': 'Towson University', 'label': 'Towson University' },
    { 'id': 7723, 'value': 'Toyama Prefectural University', 'label': 'Toyama Prefectural University' },
    { 'id': 7724, 'value': 'Toyama University of International Studies', 'label': 'Toyama University of International Studies' },
    { 'id': 7725, 'value': 'Toyo Eiwa University', 'label': 'Toyo Eiwa University' },
    { 'id': 7726, 'value': 'Toyo Gakuen University', 'label': 'Toyo Gakuen University' },
    { 'id': 7727, 'value': 'Toyo University', 'label': 'Toyo University' },
    { 'id': 7728, 'value': 'Toyohashi Sozo University', 'label': 'Toyohashi Sozo University' },
    { 'id': 7729, 'value': 'Toyohashi University of Technology', 'label': 'Toyohashi University of Technology' },
    { 'id': 7730, 'value': 'Toyota Technological Institute', 'label': 'Toyota Technological Institute' },
    { 'id': 14360, 'value': 'Training Srl', 'label': 'Training Srl' },
    { 'id': 1450, 'value': 'Trakia University', 'label': 'Trakia University' },
    { 'id': 11783, 'value': 'Trakya Üniversitesi', 'label': 'Trakya Üniversitesi' },
    { 'id': 14290, 'value': 'Trans-Africa Christian University', 'label': 'Trans-Africa Christian University' },
    { 'id': 10935, 'value': 'Transbaikal State University', 'label': 'Transbaikal State University' },
    { 'id': 6362, 'value': 'TransDisciplinary University', 'label': 'TransDisciplinary University' },
    { 'id': 1389, 'value': 'transnationale Universiteit Limburg', 'label': 'transnationale Universiteit Limburg' },
    { 'id': 8393, 'value': 'Transporta un sakaru instituts', 'label': 'Transporta un sakaru instituts' },
    { 'id': 8644, 'value': 'Transportation Institute', 'label': 'Transportation Institute' },
    { 'id': 11932, 'value': 'TransWorld University', 'label': 'TransWorld University' },
    { 'id': 13601, 'value': 'Transylvania University', 'label': 'Transylvania University' },
    { 'id': 11199, 'value': 'Trencianska Univerzita Alexandra Dubceka v Trencíne', 'label': 'Trencianska Univerzita Alexandra Dubceka v Trencíne' },
    { 'id': 1906, 'value': 'Trent University', 'label': 'Trent University' },
    { 'id': 6896, 'value': 'Trentino Art Academy', 'label': 'Trentino Art Academy' },
    { 'id': 13602, 'value': 'Trevecca Nazarene University', 'label': 'Trevecca Nazarene University' },
    { 'id': 13612, 'value': 'Tri-State Bible College', 'label': 'Tri-State Bible College' },
    { 'id': 9599, 'value': 'Tribhuvan University', 'label': 'Tribhuvan University' },
    { 'id': 13603, 'value': 'Trine University', 'label': 'Trine University' },
    { 'id': 11802, 'value': 'Trinidad & Tobago Hospitality and Tourism Institute', 'label': 'Trinidad & Tobago Hospitality and Tourism Institute' },
    { 'id': 13604, 'value': 'Trinity Bible College', 'label': 'Trinity Bible College' },
    { 'id': 13605, 'value': 'Trinity Christian College', 'label': 'Trinity Christian College' },
    { 'id': 13606, 'value': 'Trinity College', 'label': 'Trinity College' },
    { 'id': 492, 'value': 'Trinity College Dublin', 'label': 'Trinity College Dublin' },
    { 'id': 5527, 'value': 'Trinity College Dublin, University of Dublin', 'label': 'Trinity College Dublin, University of Dublin' },
    { 'id': 13607, 'value': 'Trinity College of Florida', 'label': 'Trinity College of Florida' },
    { 'id': 13608, 'value': 'Trinity College of Nursing & Health Sciences', 'label': 'Trinity College of Nursing & Health Sciences' },
    { 'id': 13609, 'value': 'Trinity International University', 'label': 'Trinity International University' },
    { 'id': 4575, 'value': 'Trinity Laban Conservatoire of Music and Dance', 'label': 'Trinity Laban Conservatoire of Music and Dance' },
    { 'id': 13610, 'value': 'Trinity University', 'label': 'Trinity University' },
    { 'id': 9990, 'value': 'Trinity University of Asia', 'label': 'Trinity University of Asia' },
    { 'id': 14291, 'value': 'Trinity University, Zambia', 'label': 'Trinity University, Zambia' },
    { 'id': 13611, 'value': 'Trinity Washington University', 'label': 'Trinity Washington University' },
    { 'id': 1907, 'value': 'Trinity Western University', 'label': 'Trinity Western University' },
    { 'id': 6363, 'value': 'Tripura University', 'label': 'Tripura University' },
    { 'id': 11200, 'value': 'Trnavská univerzita v Trnave', 'label': 'Trnavská univerzita v Trnave' },
    { 'id': 13613, 'value': 'Trocaire College', 'label': 'Trocaire College' },
    { 'id': 13614, 'value': 'Troy University', 'label': 'Troy University' },
    { 'id': 13615, 'value': 'Truett McConnell University', 'label': 'Truett McConnell University' },
    { 'id': 8162, 'value': 'Truman Bodden Law School', 'label': 'Truman Bodden Law School' },
    { 'id': 13616, 'value': 'Truman State University', 'label': 'Truman State University' },
    { 'id': 8645, 'value': 'Tsetsee Goun Institute of Management', 'label': 'Tsetsee Goun Institute of Management' },
    { 'id': 14230, 'value': 'Tshwane University of Technology', 'label': 'Tshwane University of Technology' },
    { 'id': 425, 'value': 'Tsinghua University', 'label': 'Tsinghua University' },
    { 'id': 7731, 'value': 'Tsuda University', 'label': 'Tsuda University' },
    { 'id': 7732, 'value': 'Tsukuba Gakuin University', 'label': 'Tsukuba Gakuin University' },
    { 'id': 7733, 'value': 'Tsukuba International University', 'label': 'Tsukuba International University' },
    { 'id': 7734, 'value': 'Tsukuba University of Technology', 'label': 'Tsukuba University of Technology' },
    { 'id': 7735, 'value': 'Tsuru University', 'label': 'Tsuru University' },
    { 'id': 7736, 'value': 'Tsurumi University', 'label': 'Tsurumi University' },
    { 'id': 313, 'value': 'TU Delft', 'label': 'TU Delft' },
    { 'id': 13617, 'value': 'Tufts University', 'label': 'Tufts University' },
    { 'id': 10936, 'value': 'Tula State Pedagogical University', 'label': 'Tula State Pedagogical University' },
    { 'id': 10937, 'value': 'Tula State University', 'label': 'Tula State University' },
    { 'id': 13618, 'value': 'Tulane University', 'label': 'Tulane University' },
    { 'id': 98, 'value': 'TUM - Technische Universität München', 'label': 'TUM - Technische Universität München' },
    { 'id': 11973, 'value': 'Tumaini University Makumira', 'label': 'Tumaini University Makumira' },
    { 'id': 6364, 'value': 'Tumkur University', 'label': 'Tumkur University' },
    { 'id': 11933, 'value': 'Tung Fang Design of University', 'label': 'Tung Fang Design of University' },
    { 'id': 11934, 'value': 'Tunghai University', 'label': 'Tunghai University' },
    { 'id': 11935, 'value': 'Tungnan University', 'label': 'Tungnan University' },
    { 'id': 11593, 'value': 'Tunisia Tech University', 'label': 'Tunisia Tech University' },
    { 'id': 8259, 'value': 'Turan University', 'label': 'Turan University' },
    { 'id': 8260, 'value': 'Turan-Astana University', 'label': 'Turan-Astana University' },
    { 'id': 11784, 'value': 'Türk Hava Kurumu Üniversitesi', 'label': 'Türk Hava Kurumu Üniversitesi' },
    { 'id': 11785, 'value': 'Türk-Alman Üniversitesi', 'label': 'Türk-Alman Üniversitesi' },
    { 'id': 774, 'value': 'Turkestan Institute of Higher Education', 'label': 'Turkestan Institute of Higher Education' },
    { 'id': 11539, 'value': 'Turkmen Agricultural University', 'label': 'Turkmen Agricultural University' },
    { 'id': 11540, 'value': 'Turkmen National Conservatory', 'label': 'Turkmen National Conservatory' },
    { 'id': 11541, 'value': 'Turkmen National Institute of World Languages', 'label': 'Turkmen National Institute of World Languages' },
    { 'id': 11542, 'value': 'Turkmen State Institute of Architecture and Construction', 'label': 'Turkmen State Institute of Architecture and Construction' },
    { 'id': 11543, 'value': 'Turkmen State Institute of Culture', 'label': 'Turkmen State Institute of Culture' },
    { 'id': 11544, 'value': 'Turkmen State Institute of Economics and Management', 'label': 'Turkmen State Institute of Economics and Management' },
    { 'id': 11545, 'value': 'Turkmen State Institute of Finance', 'label': 'Turkmen State Institute of Finance' },
    { 'id': 11546, 'value': 'Turkmen State Institute of Transport and Communications', 'label': 'Turkmen State Institute of Transport and Communications' },
    { 'id': 11547, 'value': 'Turkmen State Medical University', 'label': 'Turkmen State Medical University' },
    { 'id': 11548, 'value': 'Turkmen State Pedagogical Institute', 'label': 'Turkmen State Pedagogical Institute' },
    { 'id': 11549, 'value': 'Turkmen State Power Engineering Institute', 'label': 'Turkmen State Power Engineering Institute' },
    { 'id': 11550, 'value': 'Turkmen State University', 'label': 'Turkmen State University' },
    { 'id': 4236, 'value': 'Turun ammattikorkeakoulu', 'label': 'Turun ammattikorkeakoulu' },
    { 'id': 4237, 'value': 'Turun yliopisto', 'label': 'Turun yliopisto' },
    { 'id': 13619, 'value': 'Tusculum College', 'label': 'Tusculum College' },
    { 'id': 8646, 'value': 'Tushee Institute', 'label': 'Tushee Institute' },
    { 'id': 13620, 'value': 'Tuskegee University', 'label': 'Tuskegee University' },
    { 'id': 10938, 'value': 'Tver State Medical University', 'label': 'Tver State Medical University' },
    { 'id': 10939, 'value': 'Tver State Technical University', 'label': 'Tver State Technical University' },
    { 'id': 10940, 'value': 'Tver State University', 'label': 'Tver State University' },
    { 'id': 14292, 'value': 'Twin Palm Leadership University', 'label': 'Twin Palm Leadership University' },
    { 'id': 10941, 'value': 'Tyumen Industrial University', 'label': 'Tyumen Industrial University' },
    { 'id': 10942, 'value': 'Tyumen State Agricultural Academy', 'label': 'Tyumen State Agricultural Academy' },
    { 'id': 10943, 'value': 'Tyva State University', 'label': 'Tyva State University' },
    { 'id': 11936, 'value': 'Tzu Chi University', 'label': 'Tzu Chi University' },
    { 'id': 11937, 'value': 'Tzu Chi University of Science and Technology', 'label': 'Tzu Chi University of Science and Technology' },
    { 'id': 6365, 'value': 'U.P. Pandit Deen Dayal Upadhyaya Pashu Chikitsa Vigyan Vishwavidyalaya Evam Go-Anusandhan Sansthan', 'label': 'U.P. Pandit Deen Dayal Upadhyaya Pashu Chikitsa Vigyan Vishwavidyalaya Evam Go-Anusandhan Sansthan' },
    { 'id': 8138, 'value': 'U1 University', 'label': 'U1 University' },
    { 'id': 356, 'value': 'UAX - UNIVERSIDAD ALFONSO X EL SABIO', 'label': 'UAX - UNIVERSIDAD ALFONSO X EL SABIO' },
    { 'id': 7737, 'value': 'Ube Frontier University', 'label': 'Ube Frontier University' },
    { 'id': 11488, 'value': 'Ubon Ratchathani Rajabhat University', 'label': 'Ubon Ratchathani Rajabhat University' },
    { 'id': 11489, 'value': 'Ubon Ratchathani University', 'label': 'Ubon Ratchathani University' },
    { 'id': 1390, 'value': 'UC Leuven-Limburg', 'label': 'UC Leuven-Limburg' },
    { 'id': 171, 'value': 'UCD Michael Smurfit Graduate Business School', 'label': 'UCD Michael Smurfit Graduate Business School' },
    { 'id': 135, 'value': 'UCLA', 'label': 'UCLA' },
    { 'id': 9251, 'value': 'UCSI University', 'label': 'UCSI University' },
    { 'id': 10281, 'value': 'Uczelnia Jana Wyzykowskiego', 'label': 'Uczelnia Jana Wyzykowskiego' },
    { 'id': 10282, 'value': 'Uczelnia Lazarskiego', 'label': 'Uczelnia Lazarskiego' },
    { 'id': 10283, 'value': 'Uczelnia Medyczna im. Marii Sklodowskiej-Curie w Warszawie', 'label': 'Uczelnia Medyczna im. Marii Sklodowskiej-Curie w Warszawie' },
    { 'id': 10284, 'value': 'Uczelnia Techniczno-Handlowa im. Heleny Chodkowskiej', 'label': 'Uczelnia Techniczno-Handlowa im. Heleny Chodkowskiej' },
    { 'id': 10944, 'value': 'Udmurt State University', 'label': 'Udmurt State University' },
    { 'id': 11490, 'value': 'Udon Thani Rajabhat University', 'label': 'Udon Thani Rajabhat University' },
    { 'id': 7738, 'value': 'Ueno Gakuen University', 'label': 'Ueno Gakuen University' },
    { 'id': 10945, 'value': 'Ufa State Aviation Technical University', 'label': 'Ufa State Aviation Technical University' },
    { 'id': 10946, 'value': 'Ufa State Petroleum Technological University', 'label': 'Ufa State Petroleum Technological University' },
    { 'id': 11786, 'value': 'Ufuk Üniversitesi', 'label': 'Ufuk Üniversitesi' },
    { 'id': 12198, 'value': 'Uganda Christian University', 'label': 'Uganda Christian University' },
    { 'id': 12199, 'value': 'Uganda Martyrs University', 'label': 'Uganda Martyrs University' },
    { 'id': 12200, 'value': 'Uganda Pentecostal University', 'label': 'Uganda Pentecostal University' },
    { 'id': 12201, 'value': 'Uganda Technology and Management University', 'label': 'Uganda Technology and Management University' },
    { 'id': 8853, 'value': 'UGMEX', 'label': 'UGMEX' },
    { 'id': 10947, 'value': 'Ugra State University', 'label': 'Ugra State University' },
    { 'id': 8139, 'value': 'Uiduk University', 'label': 'Uiduk University' },
    { 'id': 9582, 'value': 'UiT Norges arktiske universitet', 'label': 'UiT Norges arktiske universitet' },
    { 'id': 6366, 'value': 'Uka Tarsadia University', 'label': 'Uka Tarsadia University' },
    { 'id': 10948, 'value': 'Ukhta State Technical University', 'label': 'Ukhta State Technical University' },
    { 'id': 12130, 'value': 'Ukrainian Academy of Printing', 'label': 'Ukrainian Academy of Printing' },
    { 'id': 12131, 'value': 'Ukrainian Engineering and Pedagogical Academy', 'label': 'Ukrainian Engineering and Pedagogical Academy' },
    { 'id': 12132, 'value': 'Ukrainian Medical Stomatological Academy', 'label': 'Ukrainian Medical Stomatological Academy' },
    { 'id': 12133, 'value': 'Ukrainian State University of Chemical Technology', 'label': 'Ukrainian State University of Chemical Technology' },
    { 'id': 12134, 'value': 'Ukrainian State University of Railway Transport', 'label': 'Ukrainian State University of Railway Transport' },
    { 'id': 8647, 'value': 'Ulaanbaatar State University', 'label': 'Ulaanbaatar State University' },
    { 'id': 8648, 'value': 'Ulaanbaatar-Erdem University', 'label': 'Ulaanbaatar-Erdem University' },
    { 'id': 8140, 'value': 'Ulsan National Institute of Science and Technology', 'label': 'Ulsan National Institute of Science and Technology' },
    { 'id': 4576, 'value': 'Ulster University', 'label': 'Ulster University' },
    { 'id': 11787, 'value': 'Uludag Üniversitesi', 'label': 'Uludag Üniversitesi' },
    { 'id': 3385, 'value': 'Uluslararasi Final Üniversitesi', 'label': 'Uluslararasi Final Üniversitesi' },
    { 'id': 3386, 'value': 'Uluslararasi Kibris Üniversitesi', 'label': 'Uluslararasi Kibris Üniversitesi' },
    { 'id': 8482, 'value': 'Uluslararasi Vizyon Üniversitesi', 'label': 'Uluslararasi Vizyon Üniversitesi' },
    { 'id': 10949, 'value': 'Ulyanovsk State Pedagogical University', 'label': 'Ulyanovsk State Pedagogical University' },
    { 'id': 10950, 'value': 'Ulyanovsk State Technical University', 'label': 'Ulyanovsk State Technical University' },
    { 'id': 10951, 'value': 'Ulyanovsk State University', 'label': 'Ulyanovsk State University' },
    { 'id': 12135, 'value': 'Uman National University of Horticulture', 'label': 'Uman National University of Horticulture' },
    { 'id': 12136, 'value': 'Uman State Pedagogical University', 'label': 'Uman State Pedagogical University' },
    { 'id': 9435, 'value': 'Umaru Musa Yar\'Adua University', 'label': 'Umaru Musa Yar\'Adua University' },
    { 'id': 11144, 'value': 'Umeå universitet', 'label': 'Umeå universitet' },
    { 'id': 669, 'value': 'Umm Al Quwain University', 'label': 'Umm Al Quwain University' },
    { 'id': 11048, 'value': 'Umm Al-Qura University', 'label': 'Umm Al-Qura University' },
    { 'id': 7834, 'value': 'Umma University', 'label': 'Umma University' },
    { 'id': 8694, 'value': 'UNICAF University, Malawi', 'label': 'UNICAF University, Malawi' },
    { 'id': 3417, 'value': 'Unicorn College', 'label': 'Unicorn College' },
    { 'id': 3147, 'value': 'Unidad Central del Valle del Cauca', 'label': 'Unidad Central del Valle del Cauca' },
    { 'id': 13621, 'value': 'Uniformed Services University of the Health Sciences', 'label': 'Uniformed Services University of the Health Sciences' },
    { 'id': 530, 'value': 'UNIL | Université de Lausanne', 'label': 'UNIL | Université de Lausanne' },
    { 'id': 4409, 'value': 'UniLaSalle', 'label': 'UniLaSalle' },
    { 'id': 9991, 'value': 'Union Christian College', 'label': 'Union Christian College' },
    { 'id': 13622, 'value': 'Union College', 'label': 'Union College' },
    { 'id': 13623, 'value': 'Union College, Kentucky', 'label': 'Union College, Kentucky' },
    { 'id': 13624, 'value': 'Union College, Nebraska', 'label': 'Union College, Nebraska' },
    { 'id': 13625, 'value': 'Union Institute & University', 'label': 'Union Institute & University' },
    { 'id': 13626, 'value': 'Union University', 'label': 'Union University' },
    { 'id': 14212, 'value': 'Union University of Science and Technology', 'label': 'Union University of Science and Technology' },
    { 'id': 9252, 'value': 'UNITAR International University', 'label': 'UNITAR International University' },
    { 'id': 11974, 'value': 'United African University of Tanzania', 'label': 'United African University of Tanzania' },
    { 'id': 670, 'value': 'United Arab Emirates University', 'label': 'United Arab Emirates University' },
    { 'id': 139, 'value': 'United Campus of Malta', 'label': 'United Campus of Malta' },
    { 'id': 2864, 'value': 'United International College', 'label': 'United International College' },
    { 'id': 1323, 'value': 'United International University', 'label': 'United International University' },
    { 'id': 8328, 'value': 'United Methodist University', 'label': 'United Methodist University' },
    { 'id': 13627, 'value': 'United States Air Force Academy', 'label': 'United States Air Force Academy' },
    { 'id': 13628, 'value': 'United States Coast Guard Academy', 'label': 'United States Coast Guard Academy' },
    { 'id': 7835, 'value': 'United States International University Africa', 'label': 'United States International University Africa' },
    { 'id': 13629, 'value': 'United States Merchant Marine Academy', 'label': 'United States Merchant Marine Academy' },
    { 'id': 13630, 'value': 'United States Military Academy', 'label': 'United States Military Academy' },
    { 'id': 13631, 'value': 'United States Naval Academy', 'label': 'United States Naval Academy' },
    { 'id': 13632, 'value': 'United States Sports Academy', 'label': 'United States Sports Academy' },
    { 'id': 13633, 'value': 'United States University', 'label': 'United States University' },
    { 'id': 411, 'value': 'UNITEL - Università telematica San Raffaele di Roma', 'label': 'UNITEL - Università telematica San Raffaele di Roma' },
    { 'id': 13634, 'value': 'Unity College', 'label': 'Unity College' },
    { 'id': 4199, 'value': 'Unity University', 'label': 'Unity University' },
    { 'id': 442, 'value': 'Univeristy of Basel', 'label': 'Univeristy of Basel' },
    { 'id': 4102, 'value': 'Universidad Abat Oliba CEU', 'label': 'Universidad Abat Oliba CEU' },
    { 'id': 890, 'value': 'Universidad Abierta Interamericana', 'label': 'Universidad Abierta Interamericana' },
    { 'id': 2034, 'value': 'Universidad Academia de Humanismo Cristiano', 'label': 'Universidad Academia de Humanismo Cristiano' },
    { 'id': 2035, 'value': 'Universidad Adolfo Ibañez', 'label': 'Universidad Adolfo Ibañez' },
    { 'id': 1533, 'value': 'Universidad Adventista de Bolivia', 'label': 'Universidad Adventista de Bolivia' },
    { 'id': 3230, 'value': 'Universidad Adventista de Centro América', 'label': 'Universidad Adventista de Centro América' },
    { 'id': 2036, 'value': 'Universidad Adventista de Chile', 'label': 'Universidad Adventista de Chile' },
    { 'id': 10357, 'value': 'Universidad Adventista de las Antillas', 'label': 'Universidad Adventista de las Antillas' },
    { 'id': 9463, 'value': 'Universidad Adventista de Nicaragua', 'label': 'Universidad Adventista de Nicaragua' },
    { 'id': 891, 'value': 'Universidad Adventista del Plata', 'label': 'Universidad Adventista del Plata' },
    { 'id': 3857, 'value': 'Universidad Adventista Dominicana', 'label': 'Universidad Adventista Dominicana' },
    { 'id': 3291, 'value': 'Universidad Agraria de La Habana Fructuoso Rodríguez Pérez', 'label': 'Universidad Agraria de La Habana Fructuoso Rodríguez Pérez' },
    { 'id': 3972, 'value': 'Universidad Agraria del Ecuador', 'label': 'Universidad Agraria del Ecuador' },
    { 'id': 3858, 'value': 'Universidad Agroforestal Fernando Arturo de Meriño', 'label': 'Universidad Agroforestal Fernando Arturo de Meriño' },
    { 'id': 9672, 'value': 'Universidad Alas Peruanas', 'label': 'Universidad Alas Peruanas' },
    { 'id': 11306, 'value': 'Universidad Albert Einstein', 'label': 'Universidad Albert Einstein' },
    { 'id': 8854, 'value': 'Universidad Albert Einstein, Mexico', 'label': 'Universidad Albert Einstein, Mexico' },
    { 'id': 2037, 'value': 'Universidad Alberto Hurtado', 'label': 'Universidad Alberto Hurtado' },
    { 'id': 14054, 'value': 'Universidad Alejandro de Humboldt', 'label': 'Universidad Alejandro de Humboldt' },
    { 'id': 4103, 'value': 'Universidad Alfonso X el Sabio', 'label': 'Universidad Alfonso X el Sabio' },
    { 'id': 14055, 'value': 'Universidad Alonso de Ojeda', 'label': 'Universidad Alonso de Ojeda' },
    { 'id': 8855, 'value': 'Universidad Alva Edison', 'label': 'Universidad Alva Edison' },
    { 'id': 8856, 'value': 'Universidad Alzate de Ozumba', 'label': 'Universidad Alzate de Ozumba' },
    { 'id': 1534, 'value': 'Universidad Amazónica de Pando', 'label': 'Universidad Amazónica de Pando' },
    { 'id': 9464, 'value': 'Universidad American College', 'label': 'Universidad American College' },
    { 'id': 10496, 'value': 'Universidad Americana', 'label': 'Universidad Americana' },
    { 'id': 8857, 'value': 'Universidad Americana de Acapulco', 'label': 'Universidad Americana de Acapulco' },
    { 'id': 3231, 'value': 'Universidad Americana, Costa Rica', 'label': 'Universidad Americana, Costa Rica' },
    { 'id': 9465, 'value': 'Universidad Americana, Nicaragua', 'label': 'Universidad Americana, Nicaragua' },
    { 'id': 9653, 'value': 'Universidad Americana, Panama', 'label': 'Universidad Americana, Panama' },
    { 'id': 10358, 'value': 'Universidad Ana G. Méndez, Recinto de Carolina', 'label': 'Universidad Ana G. Méndez, Recinto de Carolina' },
    { 'id': 10359, 'value': 'Universidad Ana G. Méndez, Recinto de Cupey', 'label': 'Universidad Ana G. Méndez, Recinto de Cupey' },
    { 'id': 10360, 'value': 'Universidad Ana G. Méndez, Recinto de Gurabo', 'label': 'Universidad Ana G. Méndez, Recinto de Gurabo' },
    { 'id': 8858, 'value': 'Universidad Anáhuac Cancún', 'label': 'Universidad Anáhuac Cancún' },
    { 'id': 8859, 'value': 'Universidad Anáhuac de Oaxaca', 'label': 'Universidad Anáhuac de Oaxaca' },
    { 'id': 8860, 'value': 'Universidad Anáhuac de Xalapa', 'label': 'Universidad Anáhuac de Xalapa' },
    { 'id': 8861, 'value': 'Universidad Anáhuac Mayab', 'label': 'Universidad Anáhuac Mayab' },
    { 'id': 8862, 'value': 'Universidad Anáhuac México Norte', 'label': 'Universidad Anáhuac México Norte' },
    { 'id': 8863, 'value': 'Universidad Anáhuac México Sur', 'label': 'Universidad Anáhuac México Sur' },
    { 'id': 8864, 'value': 'Universidad Anáhuac Puebla', 'label': 'Universidad Anáhuac Puebla' },
    { 'id': 8865, 'value': 'Universidad Anáhuac Querétaro', 'label': 'Universidad Anáhuac Querétaro' },
    { 'id': 9673, 'value': 'Universidad Andina del Cusco', 'label': 'Universidad Andina del Cusco' },
    { 'id': 9674, 'value': 'Universidad Andina Néstor Cáceres Velásquez', 'label': 'Universidad Andina Néstor Cáceres Velásquez' },
    { 'id': 1535, 'value': 'Universidad Andina Simón Bolivar', 'label': 'Universidad Andina Simón Bolivar' },
    { 'id': 3973, 'value': 'Universidad Andina Simón Bolívar, Ecuador', 'label': 'Universidad Andina Simón Bolívar, Ecuador' },
    { 'id': 2038, 'value': 'Universidad Andrés Bello', 'label': 'Universidad Andrés Bello' },
    { 'id': 4104, 'value': 'Universidad Antonio de Nebrija', 'label': 'Universidad Antonio de Nebrija' },
    { 'id': 3148, 'value': 'Universidad Antonio Nariño', 'label': 'Universidad Antonio Nariño' },
    { 'id': 9675, 'value': 'Universidad Antonio Ruiz de Montoya', 'label': 'Universidad Antonio Ruiz de Montoya' },
    { 'id': 9466, 'value': 'Universidad Anunciata', 'label': 'Universidad Anunciata' },
    { 'id': 3859, 'value': 'Universidad APEC', 'label': 'Universidad APEC' },
    { 'id': 892, 'value': 'Universidad Argentina de la Empresa', 'label': 'Universidad Argentina de la Empresa' },
    { 'id': 893, 'value': 'Universidad Argentina John F. Kennedy', 'label': 'Universidad Argentina John F. Kennedy' },
    { 'id': 14056, 'value': 'Universidad Arturo Michelena', 'label': 'Universidad Arturo Michelena' },
    { 'id': 2039, 'value': 'Universidad Arturo Prat', 'label': 'Universidad Arturo Prat' },
    { 'id': 9676, 'value': 'Universidad Arzobispo Loayza', 'label': 'Universidad Arzobispo Loayza' },
    { 'id': 8866, 'value': 'Universidad Ateneo de Monterrey', 'label': 'Universidad Ateneo de Monterrey' },
    { 'id': 894, 'value': 'Universidad Atlántida Argentina', 'label': 'Universidad Atlántida Argentina' },
    { 'id': 895, 'value': 'Universidad Austral', 'label': 'Universidad Austral' },
    { 'id': 2040, 'value': 'Universidad Austral de Chile', 'label': 'Universidad Austral de Chile' },
    { 'id': 8867, 'value': 'Universidad Autónoma Agraria Antonio Narro', 'label': 'Universidad Autónoma Agraria Antonio Narro' },
    { 'id': 8868, 'value': 'Universidad Autónoma Benito Juárez de Oaxaca', 'label': 'Universidad Autónoma Benito Juárez de Oaxaca' },
    { 'id': 8869, 'value': 'Universidad Autónoma de Aguascalientes', 'label': 'Universidad Autónoma de Aguascalientes' },
    { 'id': 10497, 'value': 'Universidad Autónoma de Asunción', 'label': 'Universidad Autónoma de Asunción' },
    { 'id': 8870, 'value': 'Universidad Autónoma de Baja California', 'label': 'Universidad Autónoma de Baja California' },
    { 'id': 8871, 'value': 'Universidad Autónoma de Baja California Sur', 'label': 'Universidad Autónoma de Baja California Sur' },
    { 'id': 3149, 'value': 'Universidad Autónoma de Bucaramanga', 'label': 'Universidad Autónoma de Bucaramanga' },
    { 'id': 8872, 'value': 'Universidad Autónoma de Campeche', 'label': 'Universidad Autónoma de Campeche' },
    { 'id': 3232, 'value': 'Universidad Autónoma de Centro América', 'label': 'Universidad Autónoma de Centro América' },
    { 'id': 8873, 'value': 'Universidad Autónoma de Chapingo', 'label': 'Universidad Autónoma de Chapingo' },
    { 'id': 8874, 'value': 'Universidad Autónoma de Chiapas', 'label': 'Universidad Autónoma de Chiapas' },
    { 'id': 8875, 'value': 'Universidad Autónoma de Chihuahua', 'label': 'Universidad Autónoma de Chihuahua' },
    { 'id': 2041, 'value': 'Universidad Autónoma de Chile', 'label': 'Universidad Autónoma de Chile' },
    { 'id': 9467, 'value': 'Universidad Autonoma de Chinandega', 'label': 'Universidad Autonoma de Chinandega' },
    { 'id': 9654, 'value': 'Universidad Autonoma de Chiriqui', 'label': 'Universidad Autonoma de Chiriqui' },
    { 'id': 8876, 'value': 'Universidad Autónoma de Ciudad Juárez', 'label': 'Universidad Autónoma de Ciudad Juárez' },
    { 'id': 8877, 'value': 'Universidad Autónoma de Coahuila', 'label': 'Universidad Autónoma de Coahuila' },
    { 'id': 3150, 'value': 'Universidad Autónoma de Colombia', 'label': 'Universidad Autónoma de Colombia' },
    { 'id': 8878, 'value': 'Universidad Autónoma de Durango', 'label': 'Universidad Autónoma de Durango' },
    { 'id': 896, 'value': 'Universidad Autónoma de Entre Ríos', 'label': 'Universidad Autónoma de Entre Ríos' },
    { 'id': 8879, 'value': 'Universidad Autónoma de Fresnillo', 'label': 'Universidad Autónoma de Fresnillo' },
    { 'id': 8880, 'value': 'Universidad Autónoma de Guadalajara', 'label': 'Universidad Autónoma de Guadalajara' },
    { 'id': 8881, 'value': 'Universidad Autónoma de Guerrero', 'label': 'Universidad Autónoma de Guerrero' },
    { 'id': 9677, 'value': 'Universidad Autónoma de Ica', 'label': 'Universidad Autónoma de Ica' },
    { 'id': 8882, 'value': 'Universidad Autónoma de la Ciudad de México', 'label': 'Universidad Autónoma de la Ciudad de México' },
    { 'id': 8883, 'value': 'Universidad Autónoma de la Laguna', 'label': 'Universidad Autónoma de la Laguna' },
    { 'id': 4105, 'value': 'Universidad Autónoma de Madrid', 'label': 'Universidad Autónoma de Madrid' },
    { 'id': 3151, 'value': 'Universidad Autónoma de Manizales', 'label': 'Universidad Autónoma de Manizales' },
    { 'id': 3233, 'value': 'Universidad Autónoma de Monterrey', 'label': 'Universidad Autónoma de Monterrey' },
    { 'id': 8884, 'value': 'Universidad Autónoma de Nayarit', 'label': 'Universidad Autónoma de Nayarit' },
    { 'id': 8885, 'value': 'Universidad Autónoma de Nuevo León', 'label': 'Universidad Autónoma de Nuevo León' },
    { 'id': 3152, 'value': 'Universidad Autónoma de Occidente', 'label': 'Universidad Autónoma de Occidente' },
    { 'id': 8886, 'value': 'Universidad Autónoma de Piedras Negras', 'label': 'Universidad Autónoma de Piedras Negras' },
    { 'id': 8887, 'value': 'Universidad Autónoma de Querétaro', 'label': 'Universidad Autónoma de Querétaro' },
    { 'id': 8888, 'value': 'Universidad Autónoma de San Luis Potosí', 'label': 'Universidad Autónoma de San Luis Potosí' },
    { 'id': 11307, 'value': 'Universidad Autónoma de Santa Ana', 'label': 'Universidad Autónoma de Santa Ana' },
    { 'id': 3860, 'value': 'Universidad Autónoma de Santo Domingo', 'label': 'Universidad Autónoma de Santo Domingo' },
    { 'id': 8889, 'value': 'Universidad Autónoma de Sinaloa', 'label': 'Universidad Autónoma de Sinaloa' },
    { 'id': 8890, 'value': 'Universidad Autónoma de Tamaulipas', 'label': 'Universidad Autónoma de Tamaulipas' },
    { 'id': 8891, 'value': 'Universidad Autónoma de Tlaxcala', 'label': 'Universidad Autónoma de Tlaxcala' },
    { 'id': 8892, 'value': 'Universidad Autónoma de Yucatán', 'label': 'Universidad Autónoma de Yucatán' },
    { 'id': 8893, 'value': 'Universidad Autónoma de Zacatecas', 'label': 'Universidad Autónoma de Zacatecas' },
    { 'id': 1536, 'value': 'Universidad Autónoma del Beni', 'label': 'Universidad Autónoma del Beni' },
    { 'id': 3153, 'value': 'Universidad Autónoma del Caribe', 'label': 'Universidad Autónoma del Caribe' },
    { 'id': 8894, 'value': 'Universidad Autónoma del Carmen', 'label': 'Universidad Autónoma del Carmen' },
    { 'id': 8895, 'value': 'Universidad Autónoma del Estado de Hidalgo', 'label': 'Universidad Autónoma del Estado de Hidalgo' },
    { 'id': 8896, 'value': 'Universidad Autónoma del Estado de México', 'label': 'Universidad Autónoma del Estado de México' },
    { 'id': 8897, 'value': 'Universidad Autónoma del Estado de Morelos', 'label': 'Universidad Autónoma del Estado de Morelos' },
    { 'id': 8898, 'value': 'Universidad Autónoma del Noreste', 'label': 'Universidad Autónoma del Noreste' },
    { 'id': 10498, 'value': 'Universidad Autónoma del Paraguay', 'label': 'Universidad Autónoma del Paraguay' },
    { 'id': 9678, 'value': 'Universidad Autónoma del Perú', 'label': 'Universidad Autónoma del Perú' },
    { 'id': 1537, 'value': 'Universidad Autónoma Gabriel René Moreno', 'label': 'Universidad Autónoma Gabriel René Moreno' },
    { 'id': 8899, 'value': 'Universidad Autónoma Intercultural de Sinaloa', 'label': 'Universidad Autónoma Intercultural de Sinaloa' },
    { 'id': 1538, 'value': 'Universidad Autónoma Juan Misael Saracho', 'label': 'Universidad Autónoma Juan Misael Saracho' },
    { 'id': 3154, 'value': 'Universidad Autónoma Latinoamericana', 'label': 'Universidad Autónoma Latinoamericana' },
    { 'id': 8900, 'value': 'Universidad Autónoma Metropolitana', 'label': 'Universidad Autónoma Metropolitana' },
    { 'id': 9679, 'value': 'Universidad Autónoma Municipal de Los Olivos', 'label': 'Universidad Autónoma Municipal de Los Olivos' },
    { 'id': 9680, 'value': 'Universidad Autónoma San Francisco', 'label': 'Universidad Autónoma San Francisco' },
    { 'id': 1539, 'value': 'Universidad Autónoma Tomás Frías', 'label': 'Universidad Autónoma Tomás Frías' },
    { 'id': 8901, 'value': 'Universidad Benito Juarez', 'label': 'Universidad Benito Juarez' },
    { 'id': 2042, 'value': 'Universidad Bernardo O\'Higgins', 'label': 'Universidad Bernardo O\'Higgins' },
    { 'id': 1540, 'value': 'Universidad Bethesda', 'label': 'Universidad Bethesda' },
    { 'id': 3234, 'value': 'Universidad Bíblica Latinoamericana', 'label': 'Universidad Bíblica Latinoamericana' },
    { 'id': 14057, 'value': 'Universidad Bicentenaria de Aragua', 'label': 'Universidad Bicentenaria de Aragua' },
    { 'id': 897, 'value': 'Universidad Blas Pascal', 'label': 'Universidad Blas Pascal' },
    { 'id': 2043, 'value': 'Universidad Bolivariana', 'label': 'Universidad Bolivariana' },
    { 'id': 14058, 'value': 'Universidad Bolivariana de Trabajadores Jesús Rivero', 'label': 'Universidad Bolivariana de Trabajadores Jesús Rivero' },
    { 'id': 14059, 'value': 'Universidad Bolivariana de Venezuela', 'label': 'Universidad Bolivariana de Venezuela' },
    { 'id': 1541, 'value': 'Universidad Boliviana de Informática', 'label': 'Universidad Boliviana de Informática' },
    { 'id': 3235, 'value': 'Universidad Braulio Carrillo', 'label': 'Universidad Braulio Carrillo' },
    { 'id': 898, 'value': 'Universidad CAECE', 'label': 'Universidad CAECE' },
    { 'id': 4106, 'value': 'Universidad Camilo José Cela', 'label': 'Universidad Camilo José Cela' },
    { 'id': 14060, 'value': 'Universidad Campesina de Venezuela Argimiro Gabaldón', 'label': 'Universidad Campesina de Venezuela Argimiro Gabaldón' },
    { 'id': 11308, 'value': 'Universidad Capitán General Gerardo Barrios', 'label': 'Universidad Capitán General Gerardo Barrios' },
    { 'id': 535, 'value': 'Universidad Carlos III de Madrid', 'label': 'Universidad Carlos III de Madrid' },
    { 'id': 3974, 'value': 'Universidad Casa Grande', 'label': 'Universidad Casa Grande' },
    { 'id': 3236, 'value': 'Universidad Castro Carazo', 'label': 'Universidad Castro Carazo' },
    { 'id': 9468, 'value': 'Universidad Católica Agropecuaria del Trópico Seco', 'label': 'Universidad Católica Agropecuaria del Trópico Seco' },
    { 'id': 14061, 'value': 'Universidad Católica Andres Bello', 'label': 'Universidad Católica Andres Bello' },
    { 'id': 1542, 'value': 'Universidad Católica Boliviana', 'label': 'Universidad Católica Boliviana' },
    { 'id': 14062, 'value': 'Universidad Católica Cecilio Acosta', 'label': 'Universidad Católica Cecilio Acosta' },
    { 'id': 4107, 'value': 'Universidad Católica de Ávila', 'label': 'Universidad Católica de Ávila' },
    { 'id': 3155, 'value': 'Universidad Católica de Colombia', 'label': 'Universidad Católica de Colombia' },
    { 'id': 899, 'value': 'Universidad Católica de Córdoba', 'label': 'Universidad Católica de Córdoba' },
    { 'id': 3237, 'value': 'Universidad Católica de Costa Rica', 'label': 'Universidad Católica de Costa Rica' },
    { 'id': 3975, 'value': 'Universidad Catolica de Cuenca', 'label': 'Universidad Catolica de Cuenca' },
    { 'id': 8902, 'value': 'Universidad Catolica de Culiacan A.C.', 'label': 'Universidad Catolica de Culiacan A.C.' },
    { 'id': 900, 'value': 'Universidad Católica de Cuyo', 'label': 'Universidad Católica de Cuyo' },
    { 'id': 11309, 'value': 'Universidad Católica de El Salvador', 'label': 'Universidad Católica de El Salvador' },
    { 'id': 4809, 'value': 'Universidad Catolica de Honduras', 'label': 'Universidad Catolica de Honduras' },
    { 'id': 901, 'value': 'Universidad Católica de La Plata', 'label': 'Universidad Católica de La Plata' },
    { 'id': 2044, 'value': 'Universidad Católica de la Santísima Concepción', 'label': 'Universidad Católica de la Santísima Concepción' },
    { 'id': 902, 'value': 'Universidad Católica de las Misiones', 'label': 'Universidad Católica de las Misiones' },
    { 'id': 3156, 'value': 'Universidad Católica de Manizales', 'label': 'Universidad Católica de Manizales' },
    { 'id': 235, 'value': 'Universidad Catolica de Murcia - UCAM', 'label': 'Universidad Catolica de Murcia - UCAM' },
    { 'id': 3157, 'value': 'Universidad Católica de Oriente', 'label': 'Universidad Católica de Oriente' },
    { 'id': 3158, 'value': 'Universidad Católica de Pereira', 'label': 'Universidad Católica de Pereira' },
    { 'id': 903, 'value': 'Universidad Católica de Salta', 'label': 'Universidad Católica de Salta' },
    { 'id': 904, 'value': 'Universidad Católica de Santa Fe', 'label': 'Universidad Católica de Santa Fe' },
    { 'id': 9681, 'value': 'Universidad Católica de Santa María', 'label': 'Universidad Católica de Santa María' },
    { 'id': 3976, 'value': 'Universidad Católica de Santiago de Guayaquil', 'label': 'Universidad Católica de Santiago de Guayaquil' },
    { 'id': 905, 'value': 'Universidad Católica de Santiago del Estero', 'label': 'Universidad Católica de Santiago del Estero' },
    { 'id': 2045, 'value': 'Universidad Católica de Temuco', 'label': 'Universidad Católica de Temuco' },
    { 'id': 9682, 'value': 'Universidad Católica de Trujillo Benedicto XVI', 'label': 'Universidad Católica de Trujillo Benedicto XVI' },
    { 'id': 4108, 'value': 'Universidad Católica de Valencia San Vicente Màrtir', 'label': 'Universidad Católica de Valencia San Vicente Màrtir' },
    { 'id': 3861, 'value': 'Universidad Católica del Cibao', 'label': 'Universidad Católica del Cibao' },
    { 'id': 3862, 'value': 'Universidad Católica del Este', 'label': 'Universidad Católica del Este' },
    { 'id': 2046, 'value': 'Universidad Católica del Maule', 'label': 'Universidad Católica del Maule' },
    { 'id': 2047, 'value': 'Universidad Católica del Norte', 'label': 'Universidad Católica del Norte' },
    { 'id': 14063, 'value': 'Universidad Católica del Táchira', 'label': 'Universidad Católica del Táchira' },
    { 'id': 13976, 'value': 'Universidad Católica del Uruguay', 'label': 'Universidad Católica del Uruguay' },
    { 'id': 9469, 'value': 'Universidad Católica Inmaculada Concepción de la Arquidiócesis de Managua', 'label': 'Universidad Católica Inmaculada Concepción de la Arquidiócesis de Managua' },
    { 'id': 9470, 'value': 'Universidad Católica Juan Pablo II', 'label': 'Universidad Católica Juan Pablo II' },
    { 'id': 9683, 'value': 'Universidad Católica Los Ángeles de Chimbote', 'label': 'Universidad Católica Los Ángeles de Chimbote' },
    { 'id': 3159, 'value': 'Universidad Católica Luis Amigó', 'label': 'Universidad Católica Luis Amigó' },
    { 'id': 3863, 'value': 'Universidad Católica Nordestana', 'label': 'Universidad Católica Nordestana' },
    { 'id': 10499, 'value': 'Universidad Católica Nuestra Señora de la Asunción', 'label': 'Universidad Católica Nuestra Señora de la Asunción' },
    { 'id': 9471, 'value': 'Universidad Catolica Redemptoris Mater', 'label': 'Universidad Catolica Redemptoris Mater' },
    { 'id': 9684, 'value': 'Universidad Católica San Pablo', 'label': 'Universidad Católica San Pablo' },
    { 'id': 9655, 'value': 'Universidad Católica Santa María La Antigua', 'label': 'Universidad Católica Santa María La Antigua' },
    { 'id': 14064, 'value': 'Universidad Católica Santa Rosa', 'label': 'Universidad Católica Santa Rosa' },
    { 'id': 3864, 'value': 'Universidad Católica Santo Domingo', 'label': 'Universidad Católica Santo Domingo' },
    { 'id': 9685, 'value': 'Universidad Católica Santo Toribio de Mogrovejo', 'label': 'Universidad Católica Santo Toribio de Mogrovejo' },
    { 'id': 9686, 'value': 'Universidad Católica Sedes Sapientiae', 'label': 'Universidad Católica Sedes Sapientiae' },
    { 'id': 2048, 'value': 'Universidad Católica Silva Henriquez', 'label': 'Universidad Católica Silva Henriquez' },
    { 'id': 3865, 'value': 'Universidad Católica Tecnológica de Barahona', 'label': 'Universidad Católica Tecnológica de Barahona' },
    { 'id': 3238, 'value': 'Universidad Cenfotec', 'label': 'Universidad Cenfotec' },
    { 'id': 3160, 'value': 'Universidad Central', 'label': 'Universidad Central' },
    { 'id': 10361, 'value': 'Universidad Central de Bayamón', 'label': 'Universidad Central de Bayamón' },
    { 'id': 2049, 'value': 'Universidad Central de Chile', 'label': 'Universidad Central de Chile' },
    { 'id': 9472, 'value': 'Universidad Central de Nicaragua', 'label': 'Universidad Central de Nicaragua' },
    { 'id': 14065, 'value': 'Universidad Central de Venezuela', 'label': 'Universidad Central de Venezuela' },
    { 'id': 10362, 'value': 'Universidad Central del Caribe', 'label': 'Universidad Central del Caribe' },
    { 'id': 3977, 'value': 'Universidad Central del Ecuador', 'label': 'Universidad Central del Ecuador' },
    { 'id': 3866, 'value': 'Universidad Central del Este', 'label': 'Universidad Central del Este' },
    { 'id': 3292, 'value': 'Universidad Central Marta Abreu de Las Villas', 'label': 'Universidad Central Marta Abreu de Las Villas' },
    { 'id': 1543, 'value': 'Universidad Central, Bolivia', 'label': 'Universidad Central, Bolivia' },
    { 'id': 3239, 'value': 'Universidad Central, Costa Rica', 'label': 'Universidad Central, Costa Rica' },
    { 'id': 14066, 'value': 'Universidad Centro Occidental Lisandro Alvarado', 'label': 'Universidad Centro Occidental Lisandro Alvarado' },
    { 'id': 9473, 'value': 'Universidad Centroamericana', 'label': 'Universidad Centroamericana' },
    { 'id': 9474, 'value': 'Universidad Centroamericana de Ciencias Empresariales', 'label': 'Universidad Centroamericana de Ciencias Empresariales' },
    { 'id': 3240, 'value': 'Universidad Centroamericana de Ciencias Sociales', 'label': 'Universidad Centroamericana de Ciencias Sociales' },
    { 'id': 11310, 'value': 'Universidad Centroamericana José Simeón Cañas', 'label': 'Universidad Centroamericana José Simeón Cañas' },
    { 'id': 3161, 'value': 'Universidad CES', 'label': 'Universidad CES' },
    { 'id': 9687, 'value': 'Universidad César Vallejo', 'label': 'Universidad César Vallejo' },
    { 'id': 4110, 'value': 'Universidad CEU Cardenal Herrera', 'label': 'Universidad CEU Cardenal Herrera' },
    { 'id': 4111, 'value': 'Universidad CEU San Pablo', 'label': 'Universidad CEU San Pablo' },
    { 'id': 906, 'value': 'Universidad Champagnat', 'label': 'Universidad Champagnat' },
    { 'id': 8903, 'value': 'Universidad Chapultepéc A.C.', 'label': 'Universidad Chapultepéc A.C.' },
    { 'id': 9688, 'value': 'Universidad Ciencias de la Salud', 'label': 'Universidad Ciencias de la Salud' },
    { 'id': 9689, 'value': 'Universidad Científica del Perú', 'label': 'Universidad Científica del Perú' },
    { 'id': 9690, 'value': 'Universidad Cientifica del Sur', 'label': 'Universidad Cientifica del Sur' },
    { 'id': 8904, 'value': 'Universidad Cientifica Latino Americana de Hidalgo', 'label': 'Universidad Cientifica Latino Americana de Hidalgo' },
    { 'id': 3162, 'value': 'Universidad Colegio Mayor de Cundinamarca', 'label': 'Universidad Colegio Mayor de Cundinamarca' },
    { 'id': 10500, 'value': 'Universidad Columbia del Paraguay', 'label': 'Universidad Columbia del Paraguay' },
    { 'id': 4112, 'value': 'Universidad Complutense de Madrid', 'label': 'Universidad Complutense de Madrid' },
    { 'id': 9691, 'value': 'Universidad Continental', 'label': 'Universidad Continental' },
    { 'id': 3241, 'value': 'Universidad Continental de las Ciencias y las Artes', 'label': 'Universidad Continental de las Ciencias y las Artes' },
    { 'id': 8905, 'value': 'Universidad Continental Justo Sierra', 'label': 'Universidad Continental Justo Sierra' },
    { 'id': 3163, 'value': 'Universidad Cooperativa de Colombia', 'label': 'Universidad Cooperativa de Colombia' },
    { 'id': 3242, 'value': 'Universidad Creativa', 'label': 'Universidad Creativa' },
    { 'id': 9475, 'value': 'Universidad Cristiana Autónoma de Nicaragua', 'label': 'Universidad Cristiana Autónoma de Nicaragua' },
    { 'id': 1544, 'value': 'Universidad Cristiana de Bolivia', 'label': 'Universidad Cristiana de Bolivia' },
    { 'id': 11311, 'value': 'Universidad Cristiana de las Asambleas de Dios', 'label': 'Universidad Cristiana de las Asambleas de Dios' },
    { 'id': 9656, 'value': 'Universidad Cristiana de Panamá', 'label': 'Universidad Cristiana de Panamá' },
    { 'id': 3243, 'value': 'Universidad Cristiana del Sur', 'label': 'Universidad Cristiana del Sur' },
    { 'id': 4810, 'value': 'Universidad Cristiana Evangélica Nuevo Milenio', 'label': 'Universidad Cristiana Evangélica Nuevo Milenio' },
    { 'id': 8906, 'value': 'Universidad Cristóbal Colón', 'label': 'Universidad Cristóbal Colón' },
    { 'id': 8907, 'value': 'Universidad Cuauhnáhuac', 'label': 'Universidad Cuauhnáhuac' },
    { 'id': 8908, 'value': 'Universidad Cuauhtémoc', 'label': 'Universidad Cuauhtémoc' },
    { 'id': 4772, 'value': 'Universidad Da Vinci de Guatemala', 'label': 'Universidad Da Vinci de Guatemala' },
    { 'id': 2050, 'value': 'Universidad de Aconcagua', 'label': 'Universidad de Aconcagua' },
    { 'id': 9476, 'value': 'Universidad de Administración, Comercio y Aduana', 'label': 'Universidad de Administración, Comercio y Aduana' },
    { 'id': 4113, 'value': 'Universidad de Alcalá', 'label': 'Universidad de Alcalá' },
    { 'id': 4114, 'value': 'Universidad de Alicante', 'label': 'Universidad de Alicante' },
    { 'id': 4115, 'value': 'Universidad de Almería', 'label': 'Universidad de Almería' },
    { 'id': 3164, 'value': 'Universidad de América', 'label': 'Universidad de América' },
    { 'id': 3165, 'value': 'Universidad de Antioquía', 'label': 'Universidad de Antioquía' },
    { 'id': 2051, 'value': 'Universidad de Antofagasta', 'label': 'Universidad de Antofagasta' },
    { 'id': 1545, 'value': 'Universidad de Aquino Bolivia', 'label': 'Universidad de Aquino Bolivia' },
    { 'id': 3293, 'value': 'Universidad de Artemisa', 'label': 'Universidad de Artemisa' },
    { 'id': 2052, 'value': 'Universidad de Artes, Ciencias y Comunicación', 'label': 'Universidad de Artes, Ciencias y Comunicación' },
    { 'id': 2053, 'value': 'Universidad de Atacama', 'label': 'Universidad de Atacama' },
    { 'id': 9692, 'value': 'Universidad de Ayacucho Federico Froebel', 'label': 'Universidad de Ayacucho Federico Froebel' },
    { 'id': 2054, 'value': 'Universidad de Aysén', 'label': 'Universidad de Aysén' },
    { 'id': 907, 'value': 'Universidad de Belgrano', 'label': 'Universidad de Belgrano' },
    { 'id': 3166, 'value': 'Universidad de Bogotá Jorge Tadeo Lozano', 'label': 'Universidad de Bogotá Jorge Tadeo Lozano' },
    { 'id': 3167, 'value': 'Universidad de Boyacá', 'label': 'Universidad de Boyacá' },
    { 'id': 908, 'value': 'Universidad de Buenos Aires', 'label': 'Universidad de Buenos Aires' },
    { 'id': 4116, 'value': 'Universidad de Burgos', 'label': 'Universidad de Burgos' },
    { 'id': 4117, 'value': 'Universidad de Cádiz', 'label': 'Universidad de Cádiz' },
    { 'id': 3168, 'value': 'Universidad de Caldas', 'label': 'Universidad de Caldas' },
    { 'id': 3294, 'value': 'Universidad de Camagüey Ignacio Agramonte Loynaz', 'label': 'Universidad de Camagüey Ignacio Agramonte Loynaz' },
    { 'id': 4118, 'value': 'Universidad de Cantabria', 'label': 'Universidad de Cantabria' },
    { 'id': 14067, 'value': 'Universidad de Carabobo', 'label': 'Universidad de Carabobo' },
    { 'id': 3169, 'value': 'Universidad de Cartagena', 'label': 'Universidad de Cartagena' },
    { 'id': 9657, 'value': 'Universidad de Cartago de Panama', 'label': 'Universidad de Cartago de Panama' },
    { 'id': 3244, 'value': 'Universidad de Cartago Florencio del Castillo', 'label': 'Universidad de Cartago Florencio del Castillo' },
    { 'id': 4119, 'value': 'Universidad de Castilla-La Mancha', 'label': 'Universidad de Castilla-La Mancha' },
    { 'id': 8909, 'value': 'Universidad de Celaya', 'label': 'Universidad de Celaya' },
    { 'id': 2055, 'value': 'Universidad de Chile', 'label': 'Universidad de Chile' },
    { 'id': 3295, 'value': 'Universidad de Ciego de Ávila Máximo Gómez Báez', 'label': 'Universidad de Ciego de Ávila Máximo Gómez Báez' },
    { 'id': 3170, 'value': 'Universidad de Ciencias Aplicadas y Ambientales', 'label': 'Universidad de Ciencias Aplicadas y Ambientales' },
    { 'id': 9477, 'value': 'Universidad de Ciencias Comerciales', 'label': 'Universidad de Ciencias Comerciales' },
    { 'id': 3296, 'value': 'Universidad de Ciencias de la Cultura Física y el Deporte Manuel Fajardo', 'label': 'Universidad de Ciencias de la Cultura Física y el Deporte Manuel Fajardo' },
    { 'id': 14068, 'value': 'Universidad de Ciencias de la Salud', 'label': 'Universidad de Ciencias de la Salud' },
    { 'id': 9478, 'value': 'Universidad de Ciencias de la Salud y Energías Renovables', 'label': 'Universidad de Ciencias de la Salud y Energías Renovables' },
    { 'id': 3245, 'value': 'Universidad de Ciencias Empresariales', 'label': 'Universidad de Ciencias Empresariales' },
    { 'id': 909, 'value': 'Universidad de Ciencias Empresariales y Sociales', 'label': 'Universidad de Ciencias Empresariales y Sociales' },
    { 'id': 3297, 'value': 'Universidad de Ciencias Médicas de Camagüey', 'label': 'Universidad de Ciencias Médicas de Camagüey' },
    { 'id': 3298, 'value': 'Universidad de Ciencias Médicas de Ciego de Ávila', 'label': 'Universidad de Ciencias Médicas de Ciego de Ávila' },
    { 'id': 3299, 'value': 'Universidad de Ciencias Médicas de Cienfuegos', 'label': 'Universidad de Ciencias Médicas de Cienfuegos' },
    { 'id': 3300, 'value': 'Universidad de Ciencias Médicas de Granma', 'label': 'Universidad de Ciencias Médicas de Granma' },
    { 'id': 3301, 'value': 'Universidad de Ciencias Médicas de Guantánamo', 'label': 'Universidad de Ciencias Médicas de Guantánamo' },
    { 'id': 3302, 'value': 'Universidad de Ciencias Médicas de Holguín', 'label': 'Universidad de Ciencias Médicas de Holguín' },
    { 'id': 3303, 'value': 'Universidad de Ciencias Médicas de La Habana', 'label': 'Universidad de Ciencias Médicas de La Habana' },
    { 'id': 3304, 'value': 'Universidad de Ciencias Médicas de Las Tunas', 'label': 'Universidad de Ciencias Médicas de Las Tunas' },
    { 'id': 3305, 'value': 'Universidad de Ciencias Médicas de Matanzas', 'label': 'Universidad de Ciencias Médicas de Matanzas' },
    { 'id': 3306, 'value': 'Universidad de Ciencias Médicas de Pinar del Río', 'label': 'Universidad de Ciencias Médicas de Pinar del Río' },
    { 'id': 3307, 'value': 'Universidad de Ciencias Médicas de Sancti Spíritus', 'label': 'Universidad de Ciencias Médicas de Sancti Spíritus' },
    { 'id': 3308, 'value': 'Universidad de Ciencias Médicas de Santiago de Cuba', 'label': 'Universidad de Ciencias Médicas de Santiago de Cuba' },
    { 'id': 3309, 'value': 'Universidad de Ciencias Médicas de Villa Clara', 'label': 'Universidad de Ciencias Médicas de Villa Clara' },
    { 'id': 3246, 'value': 'Universidad de Ciencias Médicas, Costa Rica', 'label': 'Universidad de Ciencias Médicas, Costa Rica' },
    { 'id': 9479, 'value': 'Universidad de Ciencias Médicas, Nicaragua', 'label': 'Universidad de Ciencias Médicas, Nicaragua' },
    { 'id': 3310, 'value': 'Universidad de Ciencias Pedagógicas Enrique José Varona', 'label': 'Universidad de Ciencias Pedagógicas Enrique José Varona' },
    { 'id': 3311, 'value': 'Universidad de Ciencias Pedagógicas Félix Varela Morales', 'label': 'Universidad de Ciencias Pedagógicas Félix Varela Morales' },
    { 'id': 3312, 'value': 'Universidad de Ciencias Pedagógicas Frank País García', 'label': 'Universidad de Ciencias Pedagógicas Frank País García' },
    { 'id': 3313, 'value': 'Universidad de Ciencias Pedagógicas Héctor Alfredo Pineda Zaldívar', 'label': 'Universidad de Ciencias Pedagógicas Héctor Alfredo Pineda Zaldívar' },
    { 'id': 3314, 'value': 'Universidad de Ciencias Pedagógicas José de la Luz y Caballero', 'label': 'Universidad de Ciencias Pedagógicas José de la Luz y Caballero' },
    { 'id': 3315, 'value': 'Universidad de Ciencias Pedagógicas Pepito Tey', 'label': 'Universidad de Ciencias Pedagógicas Pepito Tey' },
    { 'id': 9693, 'value': 'Universidad de Ciencias y Artes de América Latina', 'label': 'Universidad de Ciencias y Artes de América Latina' },
    { 'id': 8910, 'value': 'Universidad de Ciencias y Artes de Chiapas', 'label': 'Universidad de Ciencias y Artes de Chiapas' },
    { 'id': 9694, 'value': 'Universidad de Ciencias y Humanidades', 'label': 'Universidad de Ciencias y Humanidades' },
    { 'id': 3316, 'value': 'Universidad de Cienfuegos Carlos Rafael Rodríguez', 'label': 'Universidad de Cienfuegos Carlos Rafael Rodríguez' },
    { 'id': 8911, 'value': 'Universidad de Colima', 'label': 'Universidad de Colima' },
    { 'id': 8912, 'value': 'Universidad de Comunicación Avanzada', 'label': 'Universidad de Comunicación Avanzada' },
    { 'id': 2056, 'value': 'Universidad de Concepción', 'label': 'Universidad de Concepción' },
    { 'id': 910, 'value': 'Universidad de Concepción del Uruguay', 'label': 'Universidad de Concepción del Uruguay' },
    { 'id': 911, 'value': 'Universidad de Congreso', 'label': 'Universidad de Congreso' },
    { 'id': 4120, 'value': 'Universidad de Córdoba', 'label': 'Universidad de Córdoba' },
    { 'id': 3171, 'value': 'Universidad de Córdoba, Colombia', 'label': 'Universidad de Córdoba, Colombia' },
    { 'id': 3247, 'value': 'Universidad de Costa Rica', 'label': 'Universidad de Costa Rica' },
    { 'id': 8913, 'value': 'Universidad de Cuautitlán Izcalli', 'label': 'Universidad de Cuautitlán Izcalli' },
    { 'id': 3978, 'value': 'Universidad de Cuenca', 'label': 'Universidad de Cuenca' },
    { 'id': 3172, 'value': 'Universidad de Cundinamarca', 'label': 'Universidad de Cundinamarca' },
    { 'id': 4121, 'value': 'Universidad de Deusto', 'label': 'Universidad de Deusto' },
    { 'id': 8914, 'value': 'Universidad de Ecatepec', 'label': 'Universidad de Ecatepec' },
    { 'id': 371, 'value': 'Universidad de el Pais Vasco', 'label': 'Universidad de el Pais Vasco' },
    { 'id': 11312, 'value': 'Universidad de El Salvador', 'label': 'Universidad de El Salvador' },
    { 'id': 3979, 'value': 'Universidad de Especialidades del Espíritu Santo', 'label': 'Universidad de Especialidades del Espíritu Santo' },
    { 'id': 3980, 'value': 'Universidad de Especialidades Turísticas', 'label': 'Universidad de Especialidades Turísticas' },
    { 'id': 8915, 'value': 'Universidad de Estudios Avanzados', 'label': 'Universidad de Estudios Avanzados' },
    { 'id': 4122, 'value': 'Universidad de Extremadura', 'label': 'Universidad de Extremadura' },
    { 'id': 14069, 'value': 'Universidad de Falcón', 'label': 'Universidad de Falcón' },
    { 'id': 912, 'value': 'Universidad de Flores', 'label': 'Universidad de Flores' },
    { 'id': 341, 'value': 'Universidad de Granada', 'label': 'Universidad de Granada' },
    { 'id': 3317, 'value': 'Universidad de Granma', 'label': 'Universidad de Granma' },
    { 'id': 8916, 'value': 'Universidad de Guadalajara', 'label': 'Universidad de Guadalajara' },
    { 'id': 8917, 'value': 'Universidad de Guanajuato', 'label': 'Universidad de Guanajuato' },
    { 'id': 3318, 'value': 'Universidad de Guantánamo', 'label': 'Universidad de Guantánamo' },
    { 'id': 3981, 'value': 'Universidad de Guayaquil', 'label': 'Universidad de Guayaquil' },
    { 'id': 8918, 'value': 'Universidad de Hermosillo A.C.', 'label': 'Universidad de Hermosillo A.C.' },
    { 'id': 3319, 'value': 'Universidad de Holguín', 'label': 'Universidad de Holguín' },
    { 'id': 9695, 'value': 'Universidad de Huánuco', 'label': 'Universidad de Huánuco' },
    { 'id': 4123, 'value': 'Universidad de Huelva', 'label': 'Universidad de Huelva' },
    { 'id': 3173, 'value': 'Universidad de Ibagué', 'label': 'Universidad de Ibagué' },
    { 'id': 3248, 'value': 'Universidad de Iberoamérica', 'label': 'Universidad de Iberoamérica' },
    { 'id': 9696, 'value': 'Universidad de Ingeniería y Tecnología', 'label': 'Universidad de Ingeniería y Tecnología' },
    { 'id': 4124, 'value': 'Universidad de Jaén', 'label': 'Universidad de Jaén' },
    { 'id': 3174, 'value': 'Universidad de la Amazonia', 'label': 'Universidad de la Amazonia' },
    { 'id': 1546, 'value': 'Universidad de la Amazonía Boliviana', 'label': 'Universidad de la Amazonía Boliviana' },
    { 'id': 8919, 'value': 'Universidad de la Cañada', 'label': 'Universidad de la Cañada' },
    { 'id': 8920, 'value': 'Universidad de la Ciénega del Estado de Michoacán de Ocampo', 'label': 'Universidad de la Ciénega del Estado de Michoacán de Ocampo' },
    { 'id': 8921, 'value': 'Universidad de la Comunicación S.C.', 'label': 'Universidad de la Comunicación S.C.' },
    { 'id': 1547, 'value': 'Universidad de la Cordillera', 'label': 'Universidad de la Cordillera' },
    { 'id': 913, 'value': 'Universidad de la Cuenca del Plata', 'label': 'Universidad de la Cuenca del Plata' },
    { 'id': 13977, 'value': 'Universidad de la Empresa', 'label': 'Universidad de la Empresa' },
    { 'id': 2057, 'value': 'Universidad de La Frontera', 'label': 'Universidad de La Frontera' },
    { 'id': 3175, 'value': 'Universidad de La Guajira', 'label': 'Universidad de La Guajira' },
    { 'id': 3320, 'value': 'Universidad de La Habana', 'label': 'Universidad de La Habana' },
    { 'id': 3321, 'value': 'Universidad de la Isla de la Juventud Jesús Montané Oropesa', 'label': 'Universidad de la Isla de la Juventud Jesús Montané Oropesa' },
    { 'id': 4125, 'value': 'Universidad de La Laguna', 'label': 'Universidad de La Laguna' },
    { 'id': 914, 'value': 'Universidad de la Marina Mercante', 'label': 'Universidad de la Marina Mercante' },
    { 'id': 13978, 'value': 'Universidad de la República', 'label': 'Universidad de la República' },
    { 'id': 4126, 'value': 'Universidad de La Rioja', 'label': 'Universidad de La Rioja' },
    { 'id': 3176, 'value': 'Universidad de La Sabana', 'label': 'Universidad de La Sabana' },
    { 'id': 3177, 'value': 'Universidad de La Salle', 'label': 'Universidad de La Salle' },
    { 'id': 8922, 'value': 'Universidad de la Salle Bajío A.C.', 'label': 'Universidad de la Salle Bajío A.C.' },
    { 'id': 3249, 'value': 'Universidad de la Salle Costa Rica', 'label': 'Universidad de la Salle Costa Rica' },
    { 'id': 2058, 'value': 'Universidad de La Serena', 'label': 'Universidad de La Serena' },
    { 'id': 8923, 'value': 'Universidad de la Sierra', 'label': 'Universidad de la Sierra' },
    { 'id': 8924, 'value': 'Universidad de la Sierra A.C.', 'label': 'Universidad de la Sierra A.C.' },
    { 'id': 8925, 'value': 'Universidad de la Sierra Juárez', 'label': 'Universidad de la Sierra Juárez' },
    { 'id': 8926, 'value': 'Universidad de la Sierra Sur', 'label': 'Universidad de la Sierra Sur' },
    { 'id': 9697, 'value': 'Universidad de Lambayeque', 'label': 'Universidad de Lambayeque' },
    { 'id': 2059, 'value': 'Universidad de Las Américas', 'label': 'Universidad de Las Américas' },
    { 'id': 8927, 'value': 'Universidad de las Américas A.C.', 'label': 'Universidad de las Américas A.C.' },
    { 'id': 8928, 'value': 'Universidad de las Américas Puebla', 'label': 'Universidad de las Américas Puebla' },
    { 'id': 9480, 'value': 'Universidad de las Américas, Nicaragua', 'label': 'Universidad de las Américas, Nicaragua' },
    { 'id': 3982, 'value': 'Universidad de las Artes', 'label': 'Universidad de las Artes' },
    { 'id': 8929, 'value': 'Universidad de las Californias Internacional S.C.', 'label': 'Universidad de las Californias Internacional S.C.' },
    { 'id': 8930, 'value': 'Universidad de las Ciencias de la Comunicación de Puebla S.C.', 'label': 'Universidad de las Ciencias de la Comunicación de Puebla S.C.' },
    { 'id': 3322, 'value': 'Universidad de las Ciencias Informáticas', 'label': 'Universidad de las Ciencias Informáticas' },
    { 'id': 3250, 'value': 'Universidad de las Ciencias y el Arte de Costa Rica', 'label': 'Universidad de las Ciencias y el Arte de Costa Rica' },
    { 'id': 4127, 'value': 'Universidad de Las Palmas de Gran Canaria', 'label': 'Universidad de Las Palmas de Gran Canaria' },
    { 'id': 9481, 'value': 'Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense', 'label': 'Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense' },
    { 'id': 3323, 'value': 'Universidad de Las Tunas', 'label': 'Universidad de Las Tunas' },
    { 'id': 4128, 'value': 'Universidad de León', 'label': 'Universidad de León' },
    { 'id': 8931, 'value': 'Universidad de León, Mexico', 'label': 'Universidad de León, Mexico' },
    { 'id': 4129, 'value': 'Universidad de les Illes Balears', 'label': 'Universidad de les Illes Balears' },
    { 'id': 9698, 'value': 'Universidad de Lima', 'label': 'Universidad de Lima' },
    { 'id': 8932, 'value': 'Universidad de Londres A.C.', 'label': 'Universidad de Londres A.C.' },
    { 'id': 8933, 'value': 'Universidad de Los Altos de Chiapas S.C.', 'label': 'Universidad de Los Altos de Chiapas S.C.' },
    { 'id': 14070, 'value': 'Universidad de Los Andes', 'label': 'Universidad de Los Andes' },
    { 'id': 1548, 'value': 'Universidad de los Andes, Bolivia', 'label': 'Universidad de los Andes, Bolivia' },
    { 'id': 2060, 'value': 'Universidad de los Andes, Chile', 'label': 'Universidad de los Andes, Chile' },
    { 'id': 3178, 'value': 'Universidad de los Andes, Colombia', 'label': 'Universidad de los Andes, Colombia' },
    { 'id': 3983, 'value': 'Universidad de Los Hemisferios', 'label': 'Universidad de Los Hemisferios' },
    { 'id': 2061, 'value': 'Universidad de los Lagos', 'label': 'Universidad de los Lagos' },
    { 'id': 3179, 'value': 'Universidad de los Llanos', 'label': 'Universidad de los Llanos' },
    { 'id': 2062, 'value': 'Universidad de Magallanes', 'label': 'Universidad de Magallanes' },
    { 'id': 4130, 'value': 'Universidad de Málaga', 'label': 'Universidad de Málaga' },
    { 'id': 9482, 'value': 'Universidad de Managua', 'label': 'Universidad de Managua' },
    { 'id': 3180, 'value': 'Universidad de Manizales', 'label': 'Universidad de Manizales' },
    { 'id': 14071, 'value': 'Universidad de Margarita', 'label': 'Universidad de Margarita' },
    { 'id': 3324, 'value': 'Universidad de Matanzas', 'label': 'Universidad de Matanzas' },
    { 'id': 3181, 'value': 'Universidad de Medellín', 'label': 'Universidad de Medellín' },
    { 'id': 915, 'value': 'Universidad de Mendoza', 'label': 'Universidad de Mendoza' },
    { 'id': 3325, 'value': 'Universidad de Moa Dr Antonio Núñez Jiménez', 'label': 'Universidad de Moa Dr Antonio Núñez Jiménez' },
    { 'id': 8934, 'value': 'Universidad de Montemorelos A.C.', 'label': 'Universidad de Montemorelos A.C.' },
    { 'id': 8935, 'value': 'Universidad de Monterrey', 'label': 'Universidad de Monterrey' },
    { 'id': 13979, 'value': 'Universidad de Montevideo', 'label': 'Universidad de Montevideo' },
    { 'id': 8936, 'value': 'Universidad de Morelia A.C.', 'label': 'Universidad de Morelia A.C.' },
    { 'id': 916, 'value': 'Universidad de Morón', 'label': 'Universidad de Morón' },
    { 'id': 3182, 'value': 'Universidad de Nariño', 'label': 'Universidad de Nariño' },
    { 'id': 4132, 'value': 'Universidad de Navarra', 'label': 'Universidad de Navarra' },
    { 'id': 8937, 'value': 'Universidad de Negocios ISEC', 'label': 'Universidad de Negocios ISEC' },
    { 'id': 8938, 'value': 'Universidad de Norteamerica', 'label': 'Universidad de Norteamerica' },
    { 'id': 2063, 'value': 'Universidad de O\'Higgins', 'label': 'Universidad de O\'Higgins' },
    { 'id': 9483, 'value': 'Universidad de Occidente UDO', 'label': 'Universidad de Occidente UDO' },
    { 'id': 4773, 'value': 'Universidad de Occidente, Guatemala', 'label': 'Universidad de Occidente, Guatemala' },
    { 'id': 3326, 'value': 'Universidad de Oriente', 'label': 'Universidad de Oriente' },
    { 'id': 11313, 'value': 'Universidad de Oriente, El Salvador', 'label': 'Universidad de Oriente, El Salvador' },
    { 'id': 8939, 'value': 'Universidad de Oriente, Mexico', 'label': 'Universidad de Oriente, Mexico' },
    { 'id': 14072, 'value': 'Universidad de Oriente, Venezuela', 'label': 'Universidad de Oriente, Venezuela' },
    { 'id': 3984, 'value': 'Universidad de Otavalo', 'label': 'Universidad de Otavalo' },
    { 'id': 4133, 'value': 'Universidad de Oviedo', 'label': 'Universidad de Oviedo' },
    { 'id': 917, 'value': 'Universidad de Palermo', 'label': 'Universidad de Palermo' },
    { 'id': 3183, 'value': 'Universidad de Pamplona', 'label': 'Universidad de Pamplona' },
    { 'id': 9658, 'value': 'Universidad de Panamá', 'label': 'Universidad de Panamá' },
    { 'id': 3327, 'value': 'Universidad de Pinar del Río Hermanos Saíz Montes de Oca', 'label': 'Universidad de Pinar del Río Hermanos Saíz Montes de Oca' },
    { 'id': 9699, 'value': 'Universidad de Piura', 'label': 'Universidad de Piura' },
    { 'id': 2064, 'value': 'Universidad de Playa Ancha de Ciencias de la Educacion', 'label': 'Universidad de Playa Ancha de Ciencias de la Educacion' },
    { 'id': 10363, 'value': 'Universidad de Puerto Rico', 'label': 'Universidad de Puerto Rico' },
    { 'id': 10364, 'value': 'Universidad de Puerto Rico Bayamón', 'label': 'Universidad de Puerto Rico Bayamón' },
    { 'id': 10365, 'value': 'Universidad de Puerto Rico Carolina', 'label': 'Universidad de Puerto Rico Carolina' },
    { 'id': 10366, 'value': 'Universidad de Puerto Rico en Aguadilla', 'label': 'Universidad de Puerto Rico en Aguadilla' },
    { 'id': 10367, 'value': 'Universidad de Puerto Rico en Arecibo', 'label': 'Universidad de Puerto Rico en Arecibo' },
    { 'id': 10368, 'value': 'Universidad de Puerto Rico en Ponce', 'label': 'Universidad de Puerto Rico en Ponce' },
    { 'id': 10369, 'value': 'Universidad de Puerto Rico en Utuado', 'label': 'Universidad de Puerto Rico en Utuado' },
    { 'id': 10370, 'value': 'Universidad de Puerto Rico Humacao', 'label': 'Universidad de Puerto Rico Humacao' },
    { 'id': 10371, 'value': 'Universidad de Puerto Rico, Recinto de Ciencias Médicas', 'label': 'Universidad de Puerto Rico, Recinto de Ciencias Médicas' },
    { 'id': 10372, 'value': 'Universidad de Puerto Rico, Recinto de Mayagüez', 'label': 'Universidad de Puerto Rico, Recinto de Mayagüez' },
    { 'id': 10373, 'value': 'Universidad de Puerto Rico, Recinto de Río Piedras', 'label': 'Universidad de Puerto Rico, Recinto de Río Piedras' },
    { 'id': 8940, 'value': 'Universidad de Quintana Roo', 'label': 'Universidad de Quintana Roo' },
    { 'id': 9484, 'value': 'Universidad de Rubén Darío', 'label': 'Universidad de Rubén Darío' },
    { 'id': 8941, 'value': 'Universidad de Sahagun', 'label': 'Universidad de Sahagun' },
    { 'id': 4134, 'value': 'Universidad de Salamanca', 'label': 'Universidad de Salamanca' },
    { 'id': 918, 'value': 'Universidad de San Andrés', 'label': 'Universidad de San Andrés' },
    { 'id': 3184, 'value': 'Universidad de San Buenaventura', 'label': 'Universidad de San Buenaventura' },
    { 'id': 4774, 'value': 'Universidad de San Carlos de Guatemala', 'label': 'Universidad de San Carlos de Guatemala' },
    { 'id': 919, 'value': 'Universidad de San Isidro', 'label': 'Universidad de San Isidro' },
    { 'id': 3251, 'value': 'Universidad de San José', 'label': 'Universidad de San José' },
    { 'id': 9700, 'value': 'Universidad de San Martín de Porres', 'label': 'Universidad de San Martín de Porres' },
    { 'id': 8942, 'value': 'Universidad de San Miguel A.C.', 'label': 'Universidad de San Miguel A.C.' },
    { 'id': 920, 'value': 'Universidad de San Pablo-T', 'label': 'Universidad de San Pablo-T' },
    { 'id': 4811, 'value': 'Universidad de San Pedro Sula', 'label': 'Universidad de San Pedro Sula' },
    { 'id': 3328, 'value': 'Universidad de Sancti Spíritus José Martí Pérez', 'label': 'Universidad de Sancti Spíritus José Martí Pérez' },
    { 'id': 3185, 'value': 'Universidad de Santander', 'label': 'Universidad de Santander' },
    { 'id': 2065, 'value': 'Universidad de Santiago de Chile', 'label': 'Universidad de Santiago de Chile' },
    { 'id': 4135, 'value': 'Universidad de Santiago de Compostela', 'label': 'Universidad de Santiago de Compostela' },
    { 'id': 4136, 'value': 'Universidad de Sevilla', 'label': 'Universidad de Sevilla' },
    { 'id': 8943, 'value': 'Universidad de Sonora', 'label': 'Universidad de Sonora' },
    { 'id': 11314, 'value': 'Universidad de Sonsonate', 'label': 'Universidad de Sonsonate' },
    { 'id': 8944, 'value': 'Universidad de Sotavento A.C.', 'label': 'Universidad de Sotavento A.C.' },
    { 'id': 9992, 'value': 'Universidad de Sta. Isabel', 'label': 'Universidad de Sta. Isabel' },
    { 'id': 3186, 'value': 'Universidad de Sucre', 'label': 'Universidad de Sucre' },
    { 'id': 2066, 'value': 'Universidad de Talca', 'label': 'Universidad de Talca' },
    { 'id': 2067, 'value': 'Universidad de Tarapacá', 'label': 'Universidad de Tarapacá' },
    { 'id': 9485, 'value': 'Universidad de Tecnología y Comercio', 'label': 'Universidad de Tecnología y Comercio' },
    { 'id': 8945, 'value': 'Universidad de Tijuana', 'label': 'Universidad de Tijuana' },
    { 'id': 4137, 'value': 'Universidad de Valladolid', 'label': 'Universidad de Valladolid' },
    { 'id': 2068, 'value': 'Universidad de Valparaíso', 'label': 'Universidad de Valparaíso' },
    { 'id': 2069, 'value': 'Universidad de Viña del Mar', 'label': 'Universidad de Viña del Mar' },
    { 'id': 8946, 'value': 'Universidad de Xalapa A.C.', 'label': 'Universidad de Xalapa A.C.' },
    { 'id': 9993, 'value': 'Universidad de Zamboanga', 'label': 'Universidad de Zamboanga' },
    { 'id': 4138, 'value': 'Universidad de Zaragoza', 'label': 'Universidad de Zaragoza' },
    { 'id': 921, 'value': 'Universidad del Aconcagua', 'label': 'Universidad del Aconcagua' },
    { 'id': 8947, 'value': 'Universidad del Alica', 'label': 'Universidad del Alica' },
    { 'id': 8948, 'value': 'Universidad del Altiplano', 'label': 'Universidad del Altiplano' },
    { 'id': 9659, 'value': 'Universidad del Arte Ganexa', 'label': 'Universidad del Arte Ganexa' },
    { 'id': 3187, 'value': 'Universidad del Atlántico', 'label': 'Universidad del Atlántico' },
    { 'id': 4139, 'value': 'Universidad del Atlántico Medio', 'label': 'Universidad del Atlántico Medio' },
    { 'id': 3985, 'value': 'Universidad del Azuay', 'label': 'Universidad del Azuay' },
    { 'id': 2070, 'value': 'Universidad del Bío-Bío', 'label': 'Universidad del Bío-Bío' },
    { 'id': 3867, 'value': 'Universidad del Caribe', 'label': 'Universidad del Caribe' },
    { 'id': 8949, 'value': 'Universidad del Caribe, Mexico', 'label': 'Universidad del Caribe, Mexico' },
    { 'id': 3188, 'value': 'Universidad del Cauca', 'label': 'Universidad del Cauca' },
    { 'id': 922, 'value': 'Universidad del CEMA', 'label': 'Universidad del CEMA' },
    { 'id': 8950, 'value': 'Universidad del Centro de México', 'label': 'Universidad del Centro de México' },
    { 'id': 8951, 'value': 'Universidad del Centro del Bajío', 'label': 'Universidad del Centro del Bajío' },
    { 'id': 923, 'value': 'Universidad del Centro Educativo Latinoamericano', 'label': 'Universidad del Centro Educativo Latinoamericano' },
    { 'id': 924, 'value': 'Universidad del Cine', 'label': 'Universidad del Cine' },
    { 'id': 8952, 'value': 'Universidad del Claustro de Sor Juana', 'label': 'Universidad del Claustro de Sor Juana' },
    { 'id': 10501, 'value': 'Universidad del Cono Sur de las Américas', 'label': 'Universidad del Cono Sur de las Américas' },
    { 'id': 2071, 'value': 'Universidad del Desarrollo', 'label': 'Universidad del Desarrollo' },
    { 'id': 8953, 'value': 'Universidad del Desarrollo Empresarial y Pedagógico', 'label': 'Universidad del Desarrollo Empresarial y Pedagógico' },
    { 'id': 8954, 'value': 'Universidad del Distrito Federal S.C.', 'label': 'Universidad del Distrito Federal S.C.' },
    { 'id': 925, 'value': 'Universidad del Este', 'label': 'Universidad del Este' },
    { 'id': 8955, 'value': 'Universidad del Golfo', 'label': 'Universidad del Golfo' },
    { 'id': 8956, 'value': 'Universidad del Golfo de México Norte', 'label': 'Universidad del Golfo de México Norte' },
    { 'id': 926, 'value': 'Universidad del Gran Rosario', 'label': 'Universidad del Gran Rosario' },
    { 'id': 9660, 'value': 'Universidad del Istmo', 'label': 'Universidad del Istmo' },
    { 'id': 4775, 'value': 'Universidad del Istmo, Guatemala', 'label': 'Universidad del Istmo, Guatemala' },
    { 'id': 8957, 'value': 'Universidad del Istmo, Oaxaca', 'label': 'Universidad del Istmo, Oaxaca' },
    { 'id': 3189, 'value': 'Universidad del Magdalena', 'label': 'Universidad del Magdalena' },
    { 'id': 8958, 'value': 'Universidad del Mar', 'label': 'Universidad del Mar' },
    { 'id': 927, 'value': 'Universidad del Museo Social Argentino', 'label': 'Universidad del Museo Social Argentino' },
    { 'id': 8959, 'value': 'Universidad del Noreste A.C.', 'label': 'Universidad del Noreste A.C.' },
    { 'id': 3190, 'value': 'Universidad del Norte', 'label': 'Universidad del Norte' },
    { 'id': 8960, 'value': 'Universidad del Norte A.C.', 'label': 'Universidad del Norte A.C.' },
    { 'id': 9486, 'value': 'Universidad del Norte de Nicaragua', 'label': 'Universidad del Norte de Nicaragua' },
    { 'id': 928, 'value': 'Universidad del Norte Santo Tomás de Aquino', 'label': 'Universidad del Norte Santo Tomás de Aquino' },
    { 'id': 10502, 'value': 'Universidad del Norte, Paraguay', 'label': 'Universidad del Norte, Paraguay' },
    { 'id': 9701, 'value': 'Universidad del Pacifico', 'label': 'Universidad del Pacifico' },
    { 'id': 8961, 'value': 'Universidad del Pacífico de Chiapas', 'label': 'Universidad del Pacífico de Chiapas' },
    { 'id': 2072, 'value': 'Universidad del Pacífico, Chile', 'label': 'Universidad del Pacífico, Chile' },
    { 'id': 3191, 'value': 'Universidad del Pacifico, Colombia', 'label': 'Universidad del Pacifico, Colombia' },
    { 'id': 3986, 'value': 'Universidad del Pacifico, Ecuador', 'label': 'Universidad del Pacifico, Ecuador' },
    { 'id': 4140, 'value': 'Universidad del País Vasco', 'label': 'Universidad del País Vasco' },
    { 'id': 8962, 'value': 'Universidad del Papaloapan', 'label': 'Universidad del Papaloapan' },
    { 'id': 8963, 'value': 'Universidad del Pedregal', 'label': 'Universidad del Pedregal' },
    { 'id': 3192, 'value': 'Universidad del Quindío', 'label': 'Universidad del Quindío' },
    { 'id': 3193, 'value': 'Universidad del Rosario', 'label': 'Universidad del Rosario' },
    { 'id': 10374, 'value': 'Universidad del Sagrado Corazon', 'label': 'Universidad del Sagrado Corazon' },
    { 'id': 929, 'value': 'Universidad del Salvador', 'label': 'Universidad del Salvador' },
    { 'id': 3194, 'value': 'Universidad del Sinú', 'label': 'Universidad del Sinú' },
    { 'id': 8964, 'value': 'Universidad del Tepeyac', 'label': 'Universidad del Tepeyac' },
    { 'id': 3195, 'value': 'Universidad del Tolima', 'label': 'Universidad del Tolima' },
    { 'id': 3252, 'value': 'Universidad del Turismo', 'label': 'Universidad del Turismo' },
    { 'id': 3196, 'value': 'Universidad del Valle', 'label': 'Universidad del Valle' },
    { 'id': 8965, 'value': 'Universidad del Valle de Atemajac A.C.', 'label': 'Universidad del Valle de Atemajac A.C.' },
    { 'id': 8966, 'value': 'Universidad del Valle de Cuernavaca', 'label': 'Universidad del Valle de Cuernavaca' },
    { 'id': 8967, 'value': 'Universidad del Valle de Guadiana', 'label': 'Universidad del Valle de Guadiana' },
    { 'id': 4776, 'value': 'Universidad del Valle de Guatemala', 'label': 'Universidad del Valle de Guatemala' },
    { 'id': 8968, 'value': 'Universidad del Valle de Matatipac S.C.', 'label': 'Universidad del Valle de Matatipac S.C.' },
    { 'id': 8969, 'value': 'Universidad del Valle de México A.C.', 'label': 'Universidad del Valle de México A.C.' },
    { 'id': 8970, 'value': 'Universidad del Valle de Orizaba', 'label': 'Universidad del Valle de Orizaba' },
    { 'id': 8971, 'value': 'Universidad del Valle de Puebla S.C.', 'label': 'Universidad del Valle de Puebla S.C.' },
    { 'id': 8972, 'value': 'Universidad del Valle de Tlaxcala', 'label': 'Universidad del Valle de Tlaxcala' },
    { 'id': 8973, 'value': 'Universidad del Valle de Toluca', 'label': 'Universidad del Valle de Toluca' },
    { 'id': 9487, 'value': 'Universidad del Valle, Nicaragua', 'label': 'Universidad del Valle, Nicaragua' },
    { 'id': 14073, 'value': 'Universidad del Zulia', 'label': 'Universidad del Zulia' },
    { 'id': 14074, 'value': 'Universidad Deportiva del Sur', 'label': 'Universidad Deportiva del Sur' },
    { 'id': 2073, 'value': 'Universidad Diego Portales', 'label': 'Universidad Diego Portales' },
    { 'id': 3197, 'value': 'Universidad Distrital Francisco José de Caldas', 'label': 'Universidad Distrital Francisco José de Caldas' },
    { 'id': 3868, 'value': 'Universidad Dominicana O&M', 'label': 'Universidad Dominicana O&M' },
    { 'id': 3869, 'value': 'Universidad Domínico-Americana', 'label': 'Universidad Domínico-Americana' },
    { 'id': 11315, 'value': 'Universidad Don Bosco', 'label': 'Universidad Don Bosco' },
    { 'id': 8974, 'value': 'Universidad Don Vasco A.C.', 'label': 'Universidad Don Vasco A.C.' },
    { 'id': 8975, 'value': 'Universidad Dorados', 'label': 'Universidad Dorados' },
    { 'id': 11316, 'value': 'Universidad Dr. Andrés Bello', 'label': 'Universidad Dr. Andrés Bello' },
    { 'id': 14075, 'value': 'Universidad Dr. José Gregorio Hernández', 'label': 'Universidad Dr. José Gregorio Hernández' },
    { 'id': 11317, 'value': 'Universidad Dr. Jose Matias Delgado', 'label': 'Universidad Dr. Jose Matias Delgado' },
    { 'id': 14076, 'value': 'Universidad Dr. Rafael Belloso Chacín', 'label': 'Universidad Dr. Rafael Belloso Chacín' },
    { 'id': 3198, 'value': 'Universidad EAFIT', 'label': 'Universidad EAFIT' },
    { 'id': 3199, 'value': 'Universidad EAN', 'label': 'Universidad EAN' },
    { 'id': 3253, 'value': 'Universidad EARTH', 'label': 'Universidad EARTH' },
    { 'id': 3200, 'value': 'Universidad ECCI', 'label': 'Universidad ECCI' },
    { 'id': 3987, 'value': 'Universidad Ecotec', 'label': 'Universidad Ecotec' },
    { 'id': 3201, 'value': 'Universidad El Bosque', 'label': 'Universidad El Bosque' },
    { 'id': 3254, 'value': 'Universidad Empresarial de Costa Rica', 'label': 'Universidad Empresarial de Costa Rica' },
    { 'id': 930, 'value': 'Universidad Empresarial Siglo 21', 'label': 'Universidad Empresarial Siglo 21' },
    { 'id': 9702, 'value': 'Universidad ESAN', 'label': 'Universidad ESAN' },
    { 'id': 3255, 'value': 'Universidad Escuela Libre de Derecho', 'label': 'Universidad Escuela Libre de Derecho' },
    { 'id': 8976, 'value': 'Universidad España', 'label': 'Universidad España' },
    { 'id': 9661, 'value': 'Universidad Especializada de Las Americas', 'label': 'Universidad Especializada de Las Americas' },
    { 'id': 9662, 'value': 'Universidad Especializada del Contador Público de Panamá', 'label': 'Universidad Especializada del Contador Público de Panamá' },
    { 'id': 3988, 'value': 'Universidad Estatal Amazonica', 'label': 'Universidad Estatal Amazonica' },
    { 'id': 3989, 'value': 'Universidad Estatal de Bolívar', 'label': 'Universidad Estatal de Bolívar' },
    { 'id': 3990, 'value': 'Universidad Estatal de Milagro', 'label': 'Universidad Estatal de Milagro' },
    { 'id': 8977, 'value': 'Universidad Estatal de Sonora', 'label': 'Universidad Estatal de Sonora' },
    { 'id': 3991, 'value': 'Universidad Estatal del Sur de Manabi', 'label': 'Universidad Estatal del Sur de Manabi' },
    { 'id': 8978, 'value': 'Universidad Estatal del Valle de Ecatepec', 'label': 'Universidad Estatal del Valle de Ecatepec' },
    { 'id': 3992, 'value': 'Universidad Estatal Península de Santa Elena', 'label': 'Universidad Estatal Península de Santa Elena' },
    { 'id': 8979, 'value': 'Universidad ETAC', 'label': 'Universidad ETAC' },
    { 'id': 3870, 'value': 'Universidad Eugenio María de Hostos', 'label': 'Universidad Eugenio María de Hostos' },
    { 'id': 268, 'value': 'Universidad Europea', 'label': 'Universidad Europea' },
    { 'id': 4141, 'value': 'Universidad Europea de Madrid', 'label': 'Universidad Europea de Madrid' },
    { 'id': 4142, 'value': 'Universidad Europea del Atlántico', 'label': 'Universidad Europea del Atlántico' },
    { 'id': 4143, 'value': 'Universidad Europea Miguel de Cervantes', 'label': 'Universidad Europea Miguel de Cervantes' },
    { 'id': 1549, 'value': 'Universidad Evangélica Boliviana', 'label': 'Universidad Evangélica Boliviana' },
    { 'id': 11318, 'value': 'Universidad Evangélica de El Salvador', 'label': 'Universidad Evangélica de El Salvador' },
    { 'id': 3256, 'value': 'Universidad Evangélica de las Américas', 'label': 'Universidad Evangélica de las Américas' },
    { 'id': 9488, 'value': 'Universidad Evangélica Nicaragüense Martin Luther King', 'label': 'Universidad Evangélica Nicaragüense Martin Luther King' },
    { 'id': 3871, 'value': 'Universidad Experimental Felix Adam', 'label': 'Universidad Experimental Felix Adam' },
    { 'id': 3202, 'value': 'Universidad Externado de Colombia', 'label': 'Universidad Externado de Colombia' },
    { 'id': 931, 'value': 'Universidad FASTA', 'label': 'Universidad FASTA' },
    { 'id': 932, 'value': 'Universidad Favaloro', 'label': 'Universidad Favaloro' },
    { 'id': 3257, 'value': 'Universidad Federada San Judas Tadeo', 'label': 'Universidad Federada San Judas Tadeo' },
    { 'id': 3872, 'value': 'Universidad Federico Henriquez y Carvajal', 'label': 'Universidad Federico Henriquez y Carvajal' },
    { 'id': 9703, 'value': 'Universidad Femenina del Sagrado Corazón', 'label': 'Universidad Femenina del Sagrado Corazón' },
    { 'id': 14077, 'value': 'Universidad Fermín Toro', 'label': 'Universidad Fermín Toro' },
    { 'id': 374, 'value': 'Universidad Fernando Pessoa', 'label': 'Universidad Fernando Pessoa' },
    { 'id': 4144, 'value': 'Universidad Fernando Pessoa Canarias', 'label': 'Universidad Fernando Pessoa Canarias' },
    { 'id': 3258, 'value': 'Universidad Fidélitas', 'label': 'Universidad Fidélitas' },
    { 'id': 2074, 'value': 'Universidad Finis Terrae', 'label': 'Universidad Finis Terrae' },
    { 'id': 3203, 'value': 'Universidad Francisco de Paula Santander', 'label': 'Universidad Francisco de Paula Santander' },
    { 'id': 4145, 'value': 'Universidad Francisco de Vitoria', 'label': 'Universidad Francisco de Vitoria' },
    { 'id': 11319, 'value': 'Universidad Francisco Gavidia', 'label': 'Universidad Francisco Gavidia' },
    { 'id': 4777, 'value': 'Universidad Francisco Marroquín', 'label': 'Universidad Francisco Marroquín' },
    { 'id': 8980, 'value': 'Universidad Franco-Mexicana S.C.', 'label': 'Universidad Franco-Mexicana S.C.' },
    { 'id': 8981, 'value': 'Universidad Fray Luca Paccioli', 'label': 'Universidad Fray Luca Paccioli' },
    { 'id': 3259, 'value': 'Universidad Fundepos Alma Mater', 'label': 'Universidad Fundepos Alma Mater' },
    { 'id': 2075, 'value': 'Universidad Gabriela Mistral', 'label': 'Universidad Gabriela Mistral' },
    { 'id': 4778, 'value': 'Universidad Galileo', 'label': 'Universidad Galileo' },
    { 'id': 933, 'value': 'Universidad Gastón Dachary', 'label': 'Universidad Gastón Dachary' },
    { 'id': 9704, 'value': 'Universidad Global del Cusco', 'label': 'Universidad Global del Cusco' },
    { 'id': 8982, 'value': 'Universidad Hebraica', 'label': 'Universidad Hebraica' },
    { 'id': 8983, 'value': 'Universidad Hipócrates', 'label': 'Universidad Hipócrates' },
    { 'id': 8984, 'value': 'Universidad Hispana', 'label': 'Universidad Hispana' },
    { 'id': 3260, 'value': 'Universidad Hispanoamericana', 'label': 'Universidad Hispanoamericana' },
    { 'id': 8985, 'value': 'Universidad Hispanoamericana Justo Sierra', 'label': 'Universidad Hispanoamericana Justo Sierra' },
    { 'id': 9489, 'value': 'Universidad Hispanoamericana, Nicaragua', 'label': 'Universidad Hispanoamericana, Nicaragua' },
    { 'id': 3873, 'value': 'Universidad Iberoamericana', 'label': 'Universidad Iberoamericana' },
    { 'id': 8986, 'value': 'Universidad Iberoamericana A.C.', 'label': 'Universidad Iberoamericana A.C.' },
    { 'id': 9490, 'value': 'Universidad Iberoamericana de Ciencia y Tecnologia', 'label': 'Universidad Iberoamericana de Ciencia y Tecnologia' },
    { 'id': 2076, 'value': 'Universidad Iberoamericana de Ciencias y Tecnología', 'label': 'Universidad Iberoamericana de Ciencias y Tecnología' },
    { 'id': 3993, 'value': 'Universidad Iberoamericana del Ecuador', 'label': 'Universidad Iberoamericana del Ecuador' },
    { 'id': 8987, 'value': 'Universidad ICEL', 'label': 'Universidad ICEL' },
    { 'id': 3204, 'value': 'Universidad ICESI', 'label': 'Universidad ICESI' },
    { 'id': 9705, 'value': 'Universidad Inca Garcilaso de la Vega', 'label': 'Universidad Inca Garcilaso de la Vega' },
    { 'id': 3205, 'value': 'Universidad INCCA de Colombia', 'label': 'Universidad INCCA de Colombia' },
    { 'id': 3874, 'value': 'Universidad INCE', 'label': 'Universidad INCE' },
    { 'id': 3261, 'value': 'Universidad Independiente de Costa Rica', 'label': 'Universidad Independiente de Costa Rica' },
    { 'id': 3206, 'value': 'Universidad Industrial de Santander', 'label': 'Universidad Industrial de Santander' },
    { 'id': 8988, 'value': 'Universidad Insurgentes S.C.', 'label': 'Universidad Insurgentes S.C.' },
    { 'id': 8989, 'value': 'Universidad Interamericana A.C.', 'label': 'Universidad Interamericana A.C.' },
    { 'id': 9663, 'value': 'Universidad Interamericana de Panamá', 'label': 'Universidad Interamericana de Panamá' },
    { 'id': 10375, 'value': 'Universidad Interamericana de Puerto Rico', 'label': 'Universidad Interamericana de Puerto Rico' },
    { 'id': 8990, 'value': 'Universidad Interamericana del Norte y Tecnológico Sierra Madre', 'label': 'Universidad Interamericana del Norte y Tecnológico Sierra Madre' },
    { 'id': 8991, 'value': 'Universidad Interamericana para el Desarrollo', 'label': 'Universidad Interamericana para el Desarrollo' },
    { 'id': 9706, 'value': 'Universidad Interamericana para el Desarrollo, Peru', 'label': 'Universidad Interamericana para el Desarrollo, Peru' },
    { 'id': 8992, 'value': 'Universidad Intercontinental', 'label': 'Universidad Intercontinental' },
    { 'id': 8993, 'value': 'Universidad Internacional', 'label': 'Universidad Internacional' },
    { 'id': 9491, 'value': 'Universidad Internacional Antonio de Valdivieso', 'label': 'Universidad Internacional Antonio de Valdivieso' },
    { 'id': 4146, 'value': 'Universidad Internacional de Andalucía', 'label': 'Universidad Internacional de Andalucía' },
    { 'id': 9492, 'value': 'Universidad Internacional de Integración de América Latina', 'label': 'Universidad Internacional de Integración de América Latina' },
    { 'id': 8994, 'value': 'Universidad Internacional de La Paz', 'label': 'Universidad Internacional de La Paz' },
    { 'id': 3262, 'value': 'Universidad Internacional de las Américas', 'label': 'Universidad Internacional de las Américas' },
    { 'id': 3994, 'value': 'Universidad Internacional del Ecuador', 'label': 'Universidad Internacional del Ecuador' },
    { 'id': 4147, 'value': 'Universidad Internacional Menéndez Pelayo', 'label': 'Universidad Internacional Menéndez Pelayo' },
    { 'id': 9493, 'value': 'Universidad Internacional para el Desarrollo Sostenible', 'label': 'Universidad Internacional para el Desarrollo Sostenible' },
    { 'id': 3263, 'value': 'Universidad Internacional San Isidro Labrador', 'label': 'Universidad Internacional San Isidro Labrador' },
    { 'id': 3995, 'value': 'Universidad Internacional SEK', 'label': 'Universidad Internacional SEK' },
    { 'id': 8995, 'value': 'Universidad Internacional, Ciudad de Mexico', 'label': 'Universidad Internacional, Ciudad de Mexico' },
    { 'id': 4779, 'value': 'Universidad InterNaciones', 'label': 'Universidad InterNaciones' },
    { 'id': 8996, 'value': 'Universidad Interserrana del Estado de Puebla Ahuacatlán', 'label': 'Universidad Interserrana del Estado de Puebla Ahuacatlán' },
    { 'id': 8997, 'value': 'Universidad Interserrana del Estado de Puebla-Chilchotla', 'label': 'Universidad Interserrana del Estado de Puebla-Chilchotla' },
    { 'id': 3264, 'value': 'Universidad Invenio', 'label': 'Universidad Invenio' },
    { 'id': 3875, 'value': 'Universidad ISA', 'label': 'Universidad ISA' },
    { 'id': 3265, 'value': 'Universidad Isaac Newton', 'label': 'Universidad Isaac Newton' },
    { 'id': 934, 'value': 'Universidad ISALUD', 'label': 'Universidad ISALUD' },
    { 'id': 8998, 'value': 'Universidad Isidro Fabela de Toluca S.C.', 'label': 'Universidad Isidro Fabela de Toluca S.C.' },
    { 'id': 3996, 'value': 'Universidad Israel', 'label': 'Universidad Israel' },
    { 'id': 9707, 'value': 'Universidad Jaime Bausate y Meza', 'label': 'Universidad Jaime Bausate y Meza' },
    { 'id': 9494, 'value': 'Universidad Jean Jacques Rousseau', 'label': 'Universidad Jean Jacques Rousseau' },
    { 'id': 14078, 'value': 'Universidad José Antonio Páez', 'label': 'Universidad José Antonio Páez' },
    { 'id': 9708, 'value': 'Universidad José Carlos Mariátegui', 'label': 'Universidad José Carlos Mariátegui' },
    { 'id': 4812, 'value': 'Universidad José Cecilio del Valle', 'label': 'Universidad José Cecilio del Valle' },
    { 'id': 14079, 'value': 'Universidad José María Vargas', 'label': 'Universidad José María Vargas' },
    { 'id': 8999, 'value': 'Universidad José Vasconcelos de Oaxaca S.C.', 'label': 'Universidad José Vasconcelos de Oaxaca S.C.' },
    { 'id': 935, 'value': 'Universidad Juan Agustín Maza', 'label': 'Universidad Juan Agustín Maza' },
    { 'id': 3266, 'value': 'Universidad Juan Pablo II', 'label': 'Universidad Juan Pablo II' },
    { 'id': 9000, 'value': 'Universidad Juárez Autónoma de Tabasco', 'label': 'Universidad Juárez Autónoma de Tabasco' },
    { 'id': 9001, 'value': 'Universidad Juárez del Estado de Durango', 'label': 'Universidad Juárez del Estado de Durango' },
    { 'id': 9002, 'value': 'Universidad Justo Sierra A.C.', 'label': 'Universidad Justo Sierra A.C.' },
    { 'id': 9003, 'value': 'Universidad Kino A.C.', 'label': 'Universidad Kino A.C.' },
    { 'id': 9004, 'value': 'Universidad La Concordia', 'label': 'Universidad La Concordia' },
    { 'id': 3207, 'value': 'Universidad La Gran Colombia', 'label': 'Universidad La Gran Colombia' },
    { 'id': 2077, 'value': 'Universidad La República', 'label': 'Universidad La República' },
    { 'id': 9005, 'value': 'Universidad La Salle', 'label': 'Universidad La Salle' },
    { 'id': 9006, 'value': 'Universidad La Salle Benavente A.C.', 'label': 'Universidad La Salle Benavente A.C.' },
    { 'id': 9007, 'value': 'Universidad La Salle Cancún A.C.', 'label': 'Universidad La Salle Cancún A.C.' },
    { 'id': 9008, 'value': 'Universidad La Salle Chihuahua A.C.', 'label': 'Universidad La Salle Chihuahua A.C.' },
    { 'id': 9009, 'value': 'Universidad La Salle Cuernavaca', 'label': 'Universidad La Salle Cuernavaca' },
    { 'id': 9010, 'value': 'Universidad La Salle Laguna', 'label': 'Universidad La Salle Laguna' },
    { 'id': 9011, 'value': 'Universidad La Salle Morelia A.C.', 'label': 'Universidad La Salle Morelia A.C.' },
    { 'id': 9012, 'value': 'Universidad La Salle Noroeste A.C.', 'label': 'Universidad La Salle Noroeste A.C.' },
    { 'id': 9013, 'value': 'Universidad La Salle Pachuca A.C.', 'label': 'Universidad La Salle Pachuca A.C.' },
    { 'id': 9014, 'value': 'Universidad La Salle Saltillo', 'label': 'Universidad La Salle Saltillo' },
    { 'id': 9015, 'value': 'Universidad La Salle Victoria', 'label': 'Universidad La Salle Victoria' },
    { 'id': 1550, 'value': 'Universidad La Salle, Bolivia', 'label': 'Universidad La Salle, Bolivia' },
    { 'id': 9709, 'value': 'Universidad La Salle, Peru', 'label': 'Universidad La Salle, Peru' },
    { 'id': 3997, 'value': 'Universidad Laica Eloy Alfaro de Manabi', 'label': 'Universidad Laica Eloy Alfaro de Manabi' },
    { 'id': 3998, 'value': 'Universidad Laica Vicente Rocafuerte de Guayaquil', 'label': 'Universidad Laica Vicente Rocafuerte de Guayaquil' },
    { 'id': 9016, 'value': 'Universidad Lasallista Benavente S.C.', 'label': 'Universidad Lasallista Benavente S.C.' },
    { 'id': 9017, 'value': 'Universidad Latina', 'label': 'Universidad Latina' },
    { 'id': 9018, 'value': 'Universidad Latina de America', 'label': 'Universidad Latina de America' },
    { 'id': 3267, 'value': 'Universidad Latina de Costa Rica', 'label': 'Universidad Latina de Costa Rica' },
    { 'id': 9019, 'value': 'Universidad Latina de México S.C.', 'label': 'Universidad Latina de México S.C.' },
    { 'id': 9664, 'value': 'Universidad Latina de Panamá', 'label': 'Universidad Latina de Panamá' },
    { 'id': 9665, 'value': 'Universidad Latinamericana de Comercio Exterior', 'label': 'Universidad Latinamericana de Comercio Exterior' },
    { 'id': 1551, 'value': 'Universidad Latinoamericana', 'label': 'Universidad Latinoamericana' },
    { 'id': 9710, 'value': 'Universidad Latinoamericana CIMA', 'label': 'Universidad Latinoamericana CIMA' },
    { 'id': 3268, 'value': 'Universidad Latinoamericana de Ciencia y Tecnología', 'label': 'Universidad Latinoamericana de Ciencia y Tecnología' },
    { 'id': 9020, 'value': 'Universidad Latinoamericana S.C.', 'label': 'Universidad Latinoamericana S.C.' },
    { 'id': 14080, 'value': 'Universidad Latinoamericana y del Caribe', 'label': 'Universidad Latinoamericana y del Caribe' },
    { 'id': 9711, 'value': 'Universidad Le Cordon Bleu', 'label': 'Universidad Le Cordon Bleu' },
    { 'id': 3208, 'value': 'Universidad Libre', 'label': 'Universidad Libre' },
    { 'id': 3269, 'value': 'Universidad Libre de Costa Rica', 'label': 'Universidad Libre de Costa Rica' },
    { 'id': 9021, 'value': 'Universidad Linda Vista', 'label': 'Universidad Linda Vista' },
    { 'id': 2078, 'value': 'Universidad Los Leones', 'label': 'Universidad Los Leones' },
    { 'id': 1552, 'value': 'Universidad Loyola', 'label': 'Universidad Loyola' },
    { 'id': 4148, 'value': 'Universidad Loyola Andalucía', 'label': 'Universidad Loyola Andalucía' },
    { 'id': 9022, 'value': 'Universidad Loyola de America', 'label': 'Universidad Loyola de America' },
    { 'id': 9023, 'value': 'Universidad Loyola del Pacífico A.C.', 'label': 'Universidad Loyola del Pacífico A.C.' },
    { 'id': 9024, 'value': 'Universidad Lucerna S.C.', 'label': 'Universidad Lucerna S.C.' },
    { 'id': 11320, 'value': 'Universidad Luterana Salvadoreña', 'label': 'Universidad Luterana Salvadoreña' },
    { 'id': 9025, 'value': 'Universidad Madero', 'label': 'Universidad Madero' },
    { 'id': 3270, 'value': 'Universidad Magister', 'label': 'Universidad Magister' },
    { 'id': 936, 'value': 'Universidad Maimónides', 'label': 'Universidad Maimónides' },
    { 'id': 3209, 'value': 'Universidad Manuela Beltrán', 'label': 'Universidad Manuela Beltrán' },
    { 'id': 9712, 'value': 'Universidad Marcelino Champagnat', 'label': 'Universidad Marcelino Champagnat' },
    { 'id': 9713, 'value': 'Universidad María Auxiliadora', 'label': 'Universidad María Auxiliadora' },
    { 'id': 3210, 'value': 'Universidad Mariana', 'label': 'Universidad Mariana' },
    { 'id': 4780, 'value': 'Universidad Mariano Gálvez de Guatemala', 'label': 'Universidad Mariano Gálvez de Guatemala' },
    { 'id': 9026, 'value': 'Universidad Marista A.C.', 'label': 'Universidad Marista A.C.' },
    { 'id': 9027, 'value': 'Universidad Marista de Guadalajara', 'label': 'Universidad Marista de Guadalajara' },
    { 'id': 9714, 'value': 'Universidad Marítima del Perú', 'label': 'Universidad Marítima del Perú' },
    { 'id': 9666, 'value': 'Universidad Marítima Internacional de Panamá', 'label': 'Universidad Marítima Internacional de Panamá' },
    { 'id': 9495, 'value': 'Universidad Martín Lutero', 'label': 'Universidad Martín Lutero' },
    { 'id': 9028, 'value': 'Universidad Maya', 'label': 'Universidad Maya' },
    { 'id': 2079, 'value': 'Universidad Mayor', 'label': 'Universidad Mayor' },
    { 'id': 1553, 'value': 'Universidad Mayor de San Andrés', 'label': 'Universidad Mayor de San Andrés' },
    { 'id': 1554, 'value': 'Universidad Mayor de San Simón', 'label': 'Universidad Mayor de San Simón' },
    { 'id': 9029, 'value': 'Universidad Mesoamericana', 'label': 'Universidad Mesoamericana' },
    { 'id': 9030, 'value': 'Universidad Mesoamericana de San Agustín A.C.', 'label': 'Universidad Mesoamericana de San Agustín A.C.' },
    { 'id': 9031, 'value': 'Universidad Mesoamericana Puebla', 'label': 'Universidad Mesoamericana Puebla' },
    { 'id': 4781, 'value': 'Universidad Mesoamericana, Guatemala', 'label': 'Universidad Mesoamericana, Guatemala' },
    { 'id': 3271, 'value': 'Universidad Metodista de Costa Rica', 'label': 'Universidad Metodista de Costa Rica' },
    { 'id': 14081, 'value': 'Universidad Metropolitana', 'label': 'Universidad Metropolitana' },
    { 'id': 2080, 'value': 'Universidad Metropolitana de Ciencias de la Educación', 'label': 'Universidad Metropolitana de Ciencias de la Educación' },
    { 'id': 9667, 'value': 'Universidad Metropolitana de Educacion, Ciencia y Tecnologia', 'label': 'Universidad Metropolitana de Educacion, Ciencia y Tecnologia' },
    { 'id': 4813, 'value': 'Universidad Metropolitana de Honduras', 'label': 'Universidad Metropolitana de Honduras' },
    { 'id': 9032, 'value': 'Universidad Metropolitana de Monterrey', 'label': 'Universidad Metropolitana de Monterrey' },
    { 'id': 937, 'value': 'Universidad Metropolitana para la Educación y el Trabajo', 'label': 'Universidad Metropolitana para la Educación y el Trabajo' },
    { 'id': 3211, 'value': 'Universidad Metropolitana, Colombia', 'label': 'Universidad Metropolitana, Colombia' },
    { 'id': 3999, 'value': 'Universidad Metropolitana, Ecuador', 'label': 'Universidad Metropolitana, Ecuador' },
    { 'id': 9496, 'value': 'Universidad Metropolitana, Nicaragua', 'label': 'Universidad Metropolitana, Nicaragua' },
    { 'id': 9033, 'value': 'Universidad Mexicana S.C.', 'label': 'Universidad Mexicana S.C.' },
    { 'id': 9034, 'value': 'Universidad México Americana del Norte A.C.', 'label': 'Universidad México Americana del Norte A.C.' },
    { 'id': 9035, 'value': 'Universidad Mexiquense', 'label': 'Universidad Mexiquense' },
    { 'id': 9036, 'value': 'Universidad Mexiquense del Bicentenario', 'label': 'Universidad Mexiquense del Bicentenario' },
    { 'id': 9037, 'value': 'Universidad Michoacana de San Nicolás de Hidalgo', 'label': 'Universidad Michoacana de San Nicolás de Hidalgo' },
    { 'id': 2081, 'value': 'Universidad Miguel de Cervantes', 'label': 'Universidad Miguel de Cervantes' },
    { 'id': 4149, 'value': 'Universidad Miguel Hernández de Elche', 'label': 'Universidad Miguel Hernández de Elche' },
    { 'id': 3212, 'value': 'Universidad Militar Nueva Granada', 'label': 'Universidad Militar Nueva Granada' },
    { 'id': 9038, 'value': 'Universidad Modelo', 'label': 'Universidad Modelo' },
    { 'id': 11321, 'value': 'Universidad Monseñor Oscar Arnulfo Romero', 'label': 'Universidad Monseñor Oscar Arnulfo Romero' },
    { 'id': 14082, 'value': 'Universidad Monteávila', 'label': 'Universidad Monteávila' },
    { 'id': 9039, 'value': 'Universidad Motolinia del Pedregal A.C.', 'label': 'Universidad Motolinia del Pedregal A.C.' },
    { 'id': 9040, 'value': 'Universidad Multicultural CUDEC', 'label': 'Universidad Multicultural CUDEC' },
    { 'id': 9041, 'value': 'Universidad Mundial', 'label': 'Universidad Mundial' },
    { 'id': 9042, 'value': 'Universidad Mundo Maya', 'label': 'Universidad Mundo Maya' },
    { 'id': 3272, 'value': 'Universidad Nacional', 'label': 'Universidad Nacional' },
    { 'id': 9497, 'value': 'Universidad Nacional Agraria', 'label': 'Universidad Nacional Agraria' },
    { 'id': 9715, 'value': 'Universidad Nacional Agraria de la Selva', 'label': 'Universidad Nacional Agraria de la Selva' },
    { 'id': 9716, 'value': 'Universidad Nacional Agraria La Molina', 'label': 'Universidad Nacional Agraria La Molina' },
    { 'id': 9717, 'value': 'Universidad Nacional Amazónica de Madre de Dios', 'label': 'Universidad Nacional Amazónica de Madre de Dios' },
    { 'id': 938, 'value': 'Universidad Nacional Arturo Jauretche', 'label': 'Universidad Nacional Arturo Jauretche' },
    { 'id': 9718, 'value': 'Universidad Nacional Autónoma Altoandina de Tarma', 'label': 'Universidad Nacional Autónoma Altoandina de Tarma' },
    { 'id': 9719, 'value': 'Universidad Nacional Autónoma de Alto Amazonas', 'label': 'Universidad Nacional Autónoma de Alto Amazonas' },
    { 'id': 9720, 'value': 'Universidad Nacional Autónoma de Chota', 'label': 'Universidad Nacional Autónoma de Chota' },
    { 'id': 4814, 'value': 'Universidad Nacional Autónoma de Honduras', 'label': 'Universidad Nacional Autónoma de Honduras' },
    { 'id': 9721, 'value': 'Universidad Nacional Autónoma de Huanta', 'label': 'Universidad Nacional Autónoma de Huanta' },
    { 'id': 9043, 'value': 'Universidad Nacional Autónoma de México', 'label': 'Universidad Nacional Autónoma de México' },
    { 'id': 9498, 'value': 'Universidad Nacional Autónoma de Nicaragua, León', 'label': 'Universidad Nacional Autónoma de Nicaragua, León' },
    { 'id': 9499, 'value': 'Universidad Nacional Autónoma de Nicaragua, Managua', 'label': 'Universidad Nacional Autónoma de Nicaragua, Managua' },
    { 'id': 9722, 'value': 'Universidad Nacional Autónoma de Tayacaja', 'label': 'Universidad Nacional Autónoma de Tayacaja' },
    { 'id': 9723, 'value': 'Universidad Nacional Ciro Alegría', 'label': 'Universidad Nacional Ciro Alegría' },
    { 'id': 9724, 'value': 'Universidad Nacional Daniel Alcides Carrion', 'label': 'Universidad Nacional Daniel Alcides Carrion' },
    { 'id': 10503, 'value': 'Universidad Nacional de Asunción', 'label': 'Universidad Nacional de Asunción' },
    { 'id': 939, 'value': 'Universidad Nacional de Avellaneda', 'label': 'Universidad Nacional de Avellaneda' },
    { 'id': 9725, 'value': 'Universidad Nacional de Barranca', 'label': 'Universidad Nacional de Barranca' },
    { 'id': 9726, 'value': 'Universidad Nacional de Cajamarca', 'label': 'Universidad Nacional de Cajamarca' },
    { 'id': 9727, 'value': 'Universidad Nacional de Cañete', 'label': 'Universidad Nacional de Cañete' },
    { 'id': 940, 'value': 'Universidad Nacional de Catamarca', 'label': 'Universidad Nacional de Catamarca' },
    { 'id': 941, 'value': 'Universidad Nacional de Chilecito', 'label': 'Universidad Nacional de Chilecito' },
    { 'id': 4000, 'value': 'Universidad Nacional de Chimborazo', 'label': 'Universidad Nacional de Chimborazo' },
    { 'id': 3213, 'value': 'Universidad Nacional de Colombia', 'label': 'Universidad Nacional de Colombia' },
    { 'id': 942, 'value': 'Universidad Nacional de Córdoba', 'label': 'Universidad Nacional de Córdoba' },
    { 'id': 943, 'value': 'Universidad Nacional de Cuyo', 'label': 'Universidad Nacional de Cuyo' },
    { 'id': 4001, 'value': 'Universidad Nacional de Educación', 'label': 'Universidad Nacional de Educación' },
    { 'id': 9728, 'value': 'Universidad Nacional de Educación Enrique Guzmán y Valle', 'label': 'Universidad Nacional de Educación Enrique Guzmán y Valle' },
    { 'id': 944, 'value': 'Universidad Nacional de Entre Ríos', 'label': 'Universidad Nacional de Entre Ríos' },
    { 'id': 945, 'value': 'Universidad Nacional de Formosa', 'label': 'Universidad Nacional de Formosa' },
    { 'id': 9729, 'value': 'Universidad Nacional de Frontera', 'label': 'Universidad Nacional de Frontera' },
    { 'id': 946, 'value': 'Universidad Nacional de General Sarmiento', 'label': 'Universidad Nacional de General Sarmiento' },
    { 'id': 4735, 'value': 'Universidad Nacional de Guinea Ecuatorial', 'label': 'Universidad Nacional de Guinea Ecuatorial' },
    { 'id': 9730, 'value': 'Universidad Nacional de Huancavelica', 'label': 'Universidad Nacional de Huancavelica' },
    { 'id': 947, 'value': 'Universidad Nacional de Hurlingham', 'label': 'Universidad Nacional de Hurlingham' },
    { 'id': 9500, 'value': 'Universidad Nacional de Ingenieria', 'label': 'Universidad Nacional de Ingenieria' },
    { 'id': 9731, 'value': 'Universidad Nacional de Jaén', 'label': 'Universidad Nacional de Jaén' },
    { 'id': 948, 'value': 'Universidad Nacional de José C. Paz', 'label': 'Universidad Nacional de José C. Paz' },
    { 'id': 949, 'value': 'Universidad Nacional de Jujuy', 'label': 'Universidad Nacional de Jujuy' },
    { 'id': 9732, 'value': 'Universidad Nacional de Juliaca', 'label': 'Universidad Nacional de Juliaca' },
    { 'id': 9733, 'value': 'Universidad Nacional de la Amazonía Peruana', 'label': 'Universidad Nacional de la Amazonía Peruana' },
    { 'id': 950, 'value': 'Universidad Nacional de La Matanza', 'label': 'Universidad Nacional de La Matanza' },
    { 'id': 951, 'value': 'Universidad Nacional de La Pampa', 'label': 'Universidad Nacional de La Pampa' },
    { 'id': 952, 'value': 'Universidad Nacional de la Patagonia Austral', 'label': 'Universidad Nacional de la Patagonia Austral' },
    { 'id': 953, 'value': 'Universidad Nacional de la Patagonia San Juan Bosco', 'label': 'Universidad Nacional de la Patagonia San Juan Bosco' },
    { 'id': 954, 'value': 'Universidad Nacional de La Plata', 'label': 'Universidad Nacional de La Plata' },
    { 'id': 955, 'value': 'Universidad Nacional de La Rioja', 'label': 'Universidad Nacional de La Rioja' },
    { 'id': 956, 'value': 'Universidad Nacional de Lanús', 'label': 'Universidad Nacional de Lanús' },
    { 'id': 957, 'value': 'Universidad Nacional de las Artes', 'label': 'Universidad Nacional de las Artes' },
    { 'id': 4002, 'value': 'Universidad Nacional de Loja', 'label': 'Universidad Nacional de Loja' },
    { 'id': 958, 'value': 'Universidad Nacional de Lomas de Zamora', 'label': 'Universidad Nacional de Lomas de Zamora' },
    { 'id': 959, 'value': 'Universidad Nacional de los Comechingones', 'label': 'Universidad Nacional de los Comechingones' },
    { 'id': 960, 'value': 'Universidad Nacional de Luján', 'label': 'Universidad Nacional de Luján' },
    { 'id': 961, 'value': 'Universidad Nacional de Mar del Plata', 'label': 'Universidad Nacional de Mar del Plata' },
    { 'id': 962, 'value': 'Universidad Nacional de Misiones', 'label': 'Universidad Nacional de Misiones' },
    { 'id': 9734, 'value': 'Universidad Nacional de Moquegua', 'label': 'Universidad Nacional de Moquegua' },
    { 'id': 963, 'value': 'Universidad Nacional de Moreno', 'label': 'Universidad Nacional de Moreno' },
    { 'id': 9735, 'value': 'Universidad Nacional de Piura', 'label': 'Universidad Nacional de Piura' },
    { 'id': 964, 'value': 'Universidad Nacional de Quilmes', 'label': 'Universidad Nacional de Quilmes' },
    { 'id': 965, 'value': 'Universidad Nacional de Rafaela', 'label': 'Universidad Nacional de Rafaela' },
    { 'id': 966, 'value': 'Universidad Nacional de Río Cuarto', 'label': 'Universidad Nacional de Río Cuarto' },
    { 'id': 967, 'value': 'Universidad Nacional de Río Negro', 'label': 'Universidad Nacional de Río Negro' },
    { 'id': 968, 'value': 'Universidad Nacional de Rosario', 'label': 'Universidad Nacional de Rosario' },
    { 'id': 969, 'value': 'Universidad Nacional de Salta', 'label': 'Universidad Nacional de Salta' },
    { 'id': 9736, 'value': 'Universidad Nacional de San Agustín de Arequipa', 'label': 'Universidad Nacional de San Agustín de Arequipa' },
    { 'id': 9737, 'value': 'Universidad Nacional de San Antonio Abad del Cusco', 'label': 'Universidad Nacional de San Antonio Abad del Cusco' },
    { 'id': 970, 'value': 'Universidad Nacional de San Antonio de Areco', 'label': 'Universidad Nacional de San Antonio de Areco' },
    { 'id': 9738, 'value': 'Universidad Nacional de San Cristóbal de Huamanga', 'label': 'Universidad Nacional de San Cristóbal de Huamanga' },
    { 'id': 971, 'value': 'Universidad Nacional de San Juan', 'label': 'Universidad Nacional de San Juan' },
    { 'id': 972, 'value': 'Universidad Nacional de San Luis', 'label': 'Universidad Nacional de San Luis' },
    { 'id': 9739, 'value': 'Universidad Nacional de San Martín', 'label': 'Universidad Nacional de San Martín' },
    { 'id': 973, 'value': 'Universidad Nacional de San Martín, Argentina', 'label': 'Universidad Nacional de San Martín, Argentina' },
    { 'id': 974, 'value': 'Universidad Nacional de Santiago del Estero', 'label': 'Universidad Nacional de Santiago del Estero' },
    { 'id': 1555, 'value': 'Universidad Nacional de Siglo XX', 'label': 'Universidad Nacional de Siglo XX' },
    { 'id': 975, 'value': 'Universidad Nacional de Tierra del Fuego, Antártida e Islas del Atlántico Sur', 'label': 'Universidad Nacional de Tierra del Fuego, Antártida e Islas del Atlántico Sur' },
    { 'id': 976, 'value': 'Universidad Nacional de Tres de Febrero', 'label': 'Universidad Nacional de Tres de Febrero' },
    { 'id': 9740, 'value': 'Universidad Nacional de Trujillo', 'label': 'Universidad Nacional de Trujillo' },
    { 'id': 977, 'value': 'Universidad Nacional de Tucumán', 'label': 'Universidad Nacional de Tucumán' },
    { 'id': 9741, 'value': 'Universidad Nacional de Tumbes', 'label': 'Universidad Nacional de Tumbes' },
    { 'id': 9742, 'value': 'Universidad Nacional de Ucayali', 'label': 'Universidad Nacional de Ucayali' },
    { 'id': 978, 'value': 'Universidad Nacional de Villa María', 'label': 'Universidad Nacional de Villa María' },
    { 'id': 979, 'value': 'Universidad Nacional de Villa Mercedes', 'label': 'Universidad Nacional de Villa Mercedes' },
    { 'id': 9743, 'value': 'Universidad Nacional del Altiplano', 'label': 'Universidad Nacional del Altiplano' },
    { 'id': 980, 'value': 'Universidad Nacional del Alto Uruguay', 'label': 'Universidad Nacional del Alto Uruguay' },
    { 'id': 9744, 'value': 'Universidad Nacional del Callao', 'label': 'Universidad Nacional del Callao' },
    { 'id': 981, 'value': 'Universidad Nacional del Centro de la Provincia de Buenos Aires', 'label': 'Universidad Nacional del Centro de la Provincia de Buenos Aires' },
    { 'id': 9745, 'value': 'Universidad Nacional del Centro del Perú', 'label': 'Universidad Nacional del Centro del Perú' },
    { 'id': 982, 'value': 'Universidad Nacional del Chaco Austral', 'label': 'Universidad Nacional del Chaco Austral' },
    { 'id': 983, 'value': 'Universidad Nacional del Comahue', 'label': 'Universidad Nacional del Comahue' },
    { 'id': 10504, 'value': 'Universidad Nacional del Este', 'label': 'Universidad Nacional del Este' },
    { 'id': 984, 'value': 'Universidad Nacional del Litoral', 'label': 'Universidad Nacional del Litoral' },
    { 'id': 985, 'value': 'Universidad Nacional del Nordeste', 'label': 'Universidad Nacional del Nordeste' },
    { 'id': 986, 'value': 'Universidad Nacional del Noroeste de la Provincia de Buenos Aires', 'label': 'Universidad Nacional del Noroeste de la Provincia de Buenos Aires' },
    { 'id': 987, 'value': 'Universidad Nacional del Oeste', 'label': 'Universidad Nacional del Oeste' },
    { 'id': 1556, 'value': 'Universidad Nacional del Oriente', 'label': 'Universidad Nacional del Oriente' },
    { 'id': 9746, 'value': 'Universidad Nacional del Santa', 'label': 'Universidad Nacional del Santa' },
    { 'id': 988, 'value': 'Universidad Nacional del Sur', 'label': 'Universidad Nacional del Sur' },
    { 'id': 1557, 'value': 'Universidad Nacional Ecológica', 'label': 'Universidad Nacional Ecológica' },
    { 'id': 3876, 'value': 'Universidad Nacional Evangélica', 'label': 'Universidad Nacional Evangélica' },
    { 'id': 14083, 'value': 'Universidad Nacional Experimental de Guayana', 'label': 'Universidad Nacional Experimental de Guayana' },
    { 'id': 14084, 'value': 'Universidad Nacional Experimental de la Seguridad', 'label': 'Universidad Nacional Experimental de la Seguridad' },
    { 'id': 14085, 'value': 'Universidad Nacional Experimental de las Artes', 'label': 'Universidad Nacional Experimental de las Artes' },
    { 'id': 14086, 'value': 'Universidad Nacional Experimental de los Llanos Centrales', 'label': 'Universidad Nacional Experimental de los Llanos Centrales' },
    { 'id': 14087, 'value': 'Universidad Nacional Experimental de los Llanos Occidentales', 'label': 'Universidad Nacional Experimental de los Llanos Occidentales' },
    { 'id': 14088, 'value': 'Universidad Nacional Experimental del Táchira', 'label': 'Universidad Nacional Experimental del Táchira' },
    { 'id': 14089, 'value': 'Universidad Nacional Experimental del Yaracuy', 'label': 'Universidad Nacional Experimental del Yaracuy' },
    { 'id': 14090, 'value': 'Universidad Nacional Experimental Francisco de Miranda', 'label': 'Universidad Nacional Experimental Francisco de Miranda' },
    { 'id': 14091, 'value': 'Universidad Nacional Experimental Indígena del Tauca', 'label': 'Universidad Nacional Experimental Indígena del Tauca' },
    { 'id': 14092, 'value': 'Universidad Nacional Experimental Marítima del Caribe', 'label': 'Universidad Nacional Experimental Marítima del Caribe' },
    { 'id': 14093, 'value': 'Universidad Nacional Experimental Politécnica Antonio José de Sucre', 'label': 'Universidad Nacional Experimental Politécnica Antonio José de Sucre' },
    { 'id': 14094, 'value': 'Universidad Nacional Experimental Rafael Maria Baralt', 'label': 'Universidad Nacional Experimental Rafael Maria Baralt' },
    { 'id': 14095, 'value': 'Universidad Nacional Experimental Simón Rodriguez', 'label': 'Universidad Nacional Experimental Simón Rodriguez' },
    { 'id': 14096, 'value': 'Universidad Nacional Experimental Sur del Lago Jesús Maria Semprum', 'label': 'Universidad Nacional Experimental Sur del Lago Jesús Maria Semprum' },
    { 'id': 9747, 'value': 'Universidad Nacional Federico Villarreal', 'label': 'Universidad Nacional Federico Villarreal' },
    { 'id': 989, 'value': 'Universidad Nacional Guillermo Brown', 'label': 'Universidad Nacional Guillermo Brown' },
    { 'id': 9748, 'value': 'Universidad Nacional Hermilio Valdizan', 'label': 'Universidad Nacional Hermilio Valdizan' },
    { 'id': 9749, 'value': 'Universidad Nacional Intercultural de Bagua', 'label': 'Universidad Nacional Intercultural de Bagua' },
    { 'id': 9750, 'value': 'Universidad Nacional Intercultural de la Amazonía', 'label': 'Universidad Nacional Intercultural de la Amazonía' },
    { 'id': 9751, 'value': 'Universidad Nacional Intercultural de la Selva Central', 'label': 'Universidad Nacional Intercultural de la Selva Central' },
    { 'id': 9752, 'value': 'Universidad Nacional Intercultural de Quillabamba', 'label': 'Universidad Nacional Intercultural de Quillabamba' },
    { 'id': 9753, 'value': 'Universidad Nacional Jorge Basadre Grohmann', 'label': 'Universidad Nacional Jorge Basadre Grohmann' },
    { 'id': 9754, 'value': 'Universidad Nacional José Faustino Sánchez Carrión', 'label': 'Universidad Nacional José Faustino Sánchez Carrión' },
    { 'id': 9755, 'value': 'Universidad Nacional José María Arguedas', 'label': 'Universidad Nacional José María Arguedas' },
    { 'id': 9756, 'value': 'Universidad Nacional Mayor de San Marcos', 'label': 'Universidad Nacional Mayor de San Marcos' },
    { 'id': 9757, 'value': 'Universidad Nacional Micaela Bastidas de Apurímac', 'label': 'Universidad Nacional Micaela Bastidas de Apurímac' },
    { 'id': 3877, 'value': 'Universidad Nacional Pedro Henríquez Ureña', 'label': 'Universidad Nacional Pedro Henríquez Ureña' },
    { 'id': 9758, 'value': 'Universidad Nacional Pedro Ruíz Gallo', 'label': 'Universidad Nacional Pedro Ruíz Gallo' },
    { 'id': 990, 'value': 'Universidad Nacional Raúl Scalabrini Ortiz', 'label': 'Universidad Nacional Raúl Scalabrini Ortiz' },
    { 'id': 9759, 'value': 'Universidad Nacional San Luis Gonzaga', 'label': 'Universidad Nacional San Luis Gonzaga' },
    { 'id': 9760, 'value': 'Universidad Nacional Santiago Antúnez de Mayolo', 'label': 'Universidad Nacional Santiago Antúnez de Mayolo' },
    { 'id': 3878, 'value': 'Universidad Nacional Tecnológica', 'label': 'Universidad Nacional Tecnológica' },
    { 'id': 9761, 'value': 'Universidad Nacional Tecnológica de Lima Sur', 'label': 'Universidad Nacional Tecnológica de Lima Sur' },
    { 'id': 9762, 'value': 'Universidad Nacional Tecnológica de San Juan de Lurigancho', 'label': 'Universidad Nacional Tecnológica de San Juan de Lurigancho' },
    { 'id': 9763, 'value': 'Universidad Nacional Toribio Rodríguez de Mendoza', 'label': 'Universidad Nacional Toribio Rodríguez de Mendoza' },
    { 'id': 3273, 'value': 'Universidad Neotropical', 'label': 'Universidad Neotropical' },
    { 'id': 9501, 'value': 'Universidad Nicaragüense de Ciencia y Tecnología', 'label': 'Universidad Nicaragüense de Ciencia y Tecnología' },
    { 'id': 9502, 'value': 'Universidad Nicaraguense de Estudios Humanísticos', 'label': 'Universidad Nicaraguense de Estudios Humanísticos' },
    { 'id': 14097, 'value': 'Universidad Nororiental privada Gran Mariscal de Ayacucho', 'label': 'Universidad Nororiental privada Gran Mariscal de Ayacucho' },
    { 'id': 991, 'value': 'Universidad Notarial Argentina', 'label': 'Universidad Notarial Argentina' },
    { 'id': 1558, 'value': 'Universidad Nuestra Señora de La Paz', 'label': 'Universidad Nuestra Señora de La Paz' },
    { 'id': 14098, 'value': 'Universidad Nueva Esparta', 'label': 'Universidad Nueva Esparta' },
    { 'id': 11322, 'value': 'Universidad Nueva San Salvador', 'label': 'Universidad Nueva San Salvador' },
    { 'id': 1559, 'value': 'Universidad Nur', 'label': 'Universidad Nur' },
    { 'id': 9044, 'value': 'Universidad Obrera de México', 'label': 'Universidad Obrera de México' },
    { 'id': 3879, 'value': 'Universidad Odontológica Dominicana', 'label': 'Universidad Odontológica Dominicana' },
    { 'id': 9045, 'value': 'Universidad Olmeca A.C.', 'label': 'Universidad Olmeca A.C.' },
    { 'id': 13980, 'value': 'Universidad ORT Uruguay', 'label': 'Universidad ORT Uruguay' },
    { 'id': 4150, 'value': 'Universidad Pablo de Olavide', 'label': 'Universidad Pablo de Olavide' },
    { 'id': 9046, 'value': 'Universidad Paccioli de Córdoba', 'label': 'Universidad Paccioli de Córdoba' },
    { 'id': 9047, 'value': 'Universidad Panamericana', 'label': 'Universidad Panamericana' },
    { 'id': 14099, 'value': 'Universidad Panamericana del Puerto', 'label': 'Universidad Panamericana del Puerto' },
    { 'id': 3274, 'value': 'Universidad Panamericana, Costa Rica', 'label': 'Universidad Panamericana, Costa Rica' },
    { 'id': 11323, 'value': 'Universidad Panamericana, El Salvador', 'label': 'Universidad Panamericana, El Salvador' },
    { 'id': 4782, 'value': 'Universidad Panamericana, Guatemala', 'label': 'Universidad Panamericana, Guatemala' },
    { 'id': 1560, 'value': 'Universidad para el Desarrollo y la Innovación', 'label': 'Universidad para el Desarrollo y la Innovación' },
    { 'id': 3275, 'value': 'Universidad para la Cooperación Internacional', 'label': 'Universidad para la Cooperación Internacional' },
    { 'id': 1561, 'value': 'Universidad para la Investigación Estratégica en Bolivia', 'label': 'Universidad para la Investigación Estratégica en Bolivia' },
    { 'id': 9048, 'value': 'Universidad Partenón Cozumel A.C.', 'label': 'Universidad Partenón Cozumel A.C.' },
    { 'id': 9764, 'value': 'Universidad Particular de Chiclayo', 'label': 'Universidad Particular de Chiclayo' },
    { 'id': 4003, 'value': 'Universidad Particular San Gregorio de Portoviejo', 'label': 'Universidad Particular San Gregorio de Portoviejo' },
    { 'id': 9503, 'value': 'Universidad Paulo Freire', 'label': 'Universidad Paulo Freire' },
    { 'id': 9049, 'value': 'Universidad Pedagógica de Durango', 'label': 'Universidad Pedagógica de Durango' },
    { 'id': 11324, 'value': 'Universidad Pedagógica de El Salvador', 'label': 'Universidad Pedagógica de El Salvador' },
    { 'id': 14100, 'value': 'Universidad Pedagógica Experimental Libertador', 'label': 'Universidad Pedagógica Experimental Libertador' },
    { 'id': 992, 'value': 'Universidad Pedagógica Nacional', 'label': 'Universidad Pedagógica Nacional' },
    { 'id': 4815, 'value': 'Universidad Pedagógica Nacional Francisco Morazán', 'label': 'Universidad Pedagógica Nacional Francisco Morazán' },
    { 'id': 9050, 'value': 'Universidad Pedagógica Nacional, Mexico', 'label': 'Universidad Pedagógica Nacional, Mexico' },
    { 'id': 9051, 'value': 'Universidad Pedagógica Veracruzana', 'label': 'Universidad Pedagógica Veracruzana' },
    { 'id': 3214, 'value': 'Universidad Pedagógica y Tecnológica de Colombia', 'label': 'Universidad Pedagógica y Tecnológica de Colombia' },
    { 'id': 9052, 'value': 'Universidad Pedro de Gante', 'label': 'Universidad Pedro de Gante' },
    { 'id': 2082, 'value': 'Universidad Pedro de Valdivia', 'label': 'Universidad Pedro de Valdivia' },
    { 'id': 9765, 'value': 'Universidad Peruana Austral del Cusco', 'label': 'Universidad Peruana Austral del Cusco' },
    { 'id': 9766, 'value': 'Universidad Peruana Cayetano Heredia', 'label': 'Universidad Peruana Cayetano Heredia' },
    { 'id': 9767, 'value': 'Universidad Peruana de Arte Orval', 'label': 'Universidad Peruana de Arte Orval' },
    { 'id': 9768, 'value': 'Universidad Peruana de Ciencias Aplicadas', 'label': 'Universidad Peruana de Ciencias Aplicadas' },
    { 'id': 9769, 'value': 'Universidad Peruana de Ciencias e Informatica', 'label': 'Universidad Peruana de Ciencias e Informatica' },
    { 'id': 9770, 'value': 'Universidad Peruana de Integración Global', 'label': 'Universidad Peruana de Integración Global' },
    { 'id': 9771, 'value': 'Universidad Peruana de Investigación y Negocios', 'label': 'Universidad Peruana de Investigación y Negocios' },
    { 'id': 9772, 'value': 'Universidad Peruana de las Américas', 'label': 'Universidad Peruana de las Américas' },
    { 'id': 9773, 'value': 'Universidad Peruana del Centro', 'label': 'Universidad Peruana del Centro' },
    { 'id': 9774, 'value': 'Universidad Peruana del Oriente', 'label': 'Universidad Peruana del Oriente' },
    { 'id': 9775, 'value': 'Universidad Peruana Los Andes', 'label': 'Universidad Peruana Los Andes' },
    { 'id': 9776, 'value': 'Universidad Peruana Simón Bolivar', 'label': 'Universidad Peruana Simón Bolivar' },
    { 'id': 9777, 'value': 'Universidad Peruana Unión', 'label': 'Universidad Peruana Unión' },
    { 'id': 3215, 'value': 'Universidad Piloto de Colombia', 'label': 'Universidad Piloto de Colombia' },
    { 'id': 9778, 'value': 'Universidad Politécnica Amazónica', 'label': 'Universidad Politécnica Amazónica' },
    { 'id': 9053, 'value': 'Universidad Politécnica Cuencamé', 'label': 'Universidad Politécnica Cuencamé' },
    { 'id': 9054, 'value': 'Universidad Politécnica de Aguascalientes', 'label': 'Universidad Politécnica de Aguascalientes' },
    { 'id': 9055, 'value': 'Universidad Politécnica de Altamira', 'label': 'Universidad Politécnica de Altamira' },
    { 'id': 9056, 'value': 'Universidad Politécnica de Amozoc', 'label': 'Universidad Politécnica de Amozoc' },
    { 'id': 9057, 'value': 'Universidad Politécnica de Apodaca', 'label': 'Universidad Politécnica de Apodaca' },
    { 'id': 9058, 'value': 'Universidad Politécnica de Bacalar', 'label': 'Universidad Politécnica de Bacalar' },
    { 'id': 9059, 'value': 'Universidad Politécnica de Baja California', 'label': 'Universidad Politécnica de Baja California' },
    { 'id': 4151, 'value': 'Universidad Politécnica de Cartagena', 'label': 'Universidad Politécnica de Cartagena' },
    { 'id': 9060, 'value': 'Universidad Politécnica de Chiapas', 'label': 'Universidad Politécnica de Chiapas' },
    { 'id': 9061, 'value': 'Universidad Politécnica de Chihuahua', 'label': 'Universidad Politécnica de Chihuahua' },
    { 'id': 9062, 'value': 'Universidad Politécnica de Durango', 'label': 'Universidad Politécnica de Durango' },
    { 'id': 11325, 'value': 'Universidad Politécnica de El Salvador', 'label': 'Universidad Politécnica de El Salvador' },
    { 'id': 9063, 'value': 'Universidad Politécnica de Francisco I. Madero', 'label': 'Universidad Politécnica de Francisco I. Madero' },
    { 'id': 9064, 'value': 'Universidad Politécnica de Gómez Palacio', 'label': 'Universidad Politécnica de Gómez Palacio' },
    { 'id': 9065, 'value': 'Universidad Politécnica de Guanajuato', 'label': 'Universidad Politécnica de Guanajuato' },
    { 'id': 9066, 'value': 'Universidad Politécnica de Huatusco', 'label': 'Universidad Politécnica de Huatusco' },
    { 'id': 9067, 'value': 'Universidad Politécnica de Huejutla', 'label': 'Universidad Politécnica de Huejutla' },
    { 'id': 4816, 'value': 'Universidad Politécnica de Ingeniería', 'label': 'Universidad Politécnica de Ingeniería' },
    { 'id': 9068, 'value': 'Universidad Politécnica de la Región Ribereña', 'label': 'Universidad Politécnica de la Región Ribereña' },
    { 'id': 9069, 'value': 'Universidad Politécnica de la Zona Metropolitana de Guadalajara', 'label': 'Universidad Politécnica de la Zona Metropolitana de Guadalajara' },
    { 'id': 9070, 'value': 'Universidad Politécnica de Lázaro Cárdenas', 'label': 'Universidad Politécnica de Lázaro Cárdenas' },
    { 'id': 4152, 'value': 'Universidad Politécnica de Madrid', 'label': 'Universidad Politécnica de Madrid' },
    { 'id': 9504, 'value': 'Universidad Politecnica de Nicaragua', 'label': 'Universidad Politecnica de Nicaragua' },
    { 'id': 9071, 'value': 'Universidad Politécnica de Pachuca', 'label': 'Universidad Politécnica de Pachuca' },
    { 'id': 9072, 'value': 'Universidad Politécnica de Pénjamo', 'label': 'Universidad Politécnica de Pénjamo' },
    { 'id': 9073, 'value': 'Universidad Politécnica de Piedras Negras', 'label': 'Universidad Politécnica de Piedras Negras' },
    { 'id': 9074, 'value': 'Universidad Politécnica de Puebla', 'label': 'Universidad Politécnica de Puebla' },
    { 'id': 10376, 'value': 'Universidad Politecnica de Puerto Rico', 'label': 'Universidad Politecnica de Puerto Rico' },
    { 'id': 9075, 'value': 'Universidad Politécnica de Querétaro', 'label': 'Universidad Politécnica de Querétaro' },
    { 'id': 9076, 'value': 'Universidad Politécnica de Quintana Roo', 'label': 'Universidad Politécnica de Quintana Roo' },
    { 'id': 9077, 'value': 'Universidad Politécnica de Ramos Arizpe', 'label': 'Universidad Politécnica de Ramos Arizpe' },
    { 'id': 9078, 'value': 'Universidad Politécnica de San Luís Potosí', 'label': 'Universidad Politécnica de San Luís Potosí' },
    { 'id': 9079, 'value': 'Universidad Politécnica de Santa Rosa Jáuregui', 'label': 'Universidad Politécnica de Santa Rosa Jáuregui' },
    { 'id': 9080, 'value': 'Universidad Politécnica de Sinaloa', 'label': 'Universidad Politécnica de Sinaloa' },
    { 'id': 9081, 'value': 'Universidad Politécnica de Tapachula', 'label': 'Universidad Politécnica de Tapachula' },
    { 'id': 9082, 'value': 'Universidad Politécnica de Tecámac', 'label': 'Universidad Politécnica de Tecámac' },
    { 'id': 9083, 'value': 'Universidad Politécnica de Texcoco', 'label': 'Universidad Politécnica de Texcoco' },
    { 'id': 9084, 'value': 'Universidad Politécnica de Tlaxcala', 'label': 'Universidad Politécnica de Tlaxcala' },
    { 'id': 9085, 'value': 'Universidad Politécnica de Tlaxcala Región Poniente', 'label': 'Universidad Politécnica de Tlaxcala Región Poniente' },
    { 'id': 9086, 'value': 'Universidad Politécnica de Tulancingo', 'label': 'Universidad Politécnica de Tulancingo' },
    { 'id': 9087, 'value': 'Universidad Politécnica de Uruapan', 'label': 'Universidad Politécnica de Uruapan' },
    { 'id': 4153, 'value': 'Universidad Politécnica de València', 'label': 'Universidad Politécnica de València' },
    { 'id': 9088, 'value': 'Universidad Politécnica de Victoria', 'label': 'Universidad Politécnica de Victoria' },
    { 'id': 9089, 'value': 'Universidad Politécnica de Zacatecas', 'label': 'Universidad Politécnica de Zacatecas' },
    { 'id': 9090, 'value': 'Universidad Politécnica del Bicentenario', 'label': 'Universidad Politécnica del Bicentenario' },
    { 'id': 9091, 'value': 'Universidad Politécnica del Centro', 'label': 'Universidad Politécnica del Centro' },
    { 'id': 9092, 'value': 'Universidad Politécnica del Estado de Guerrero', 'label': 'Universidad Politécnica del Estado de Guerrero' },
    { 'id': 9093, 'value': 'Universidad Politécnica del Estado de Morelos', 'label': 'Universidad Politécnica del Estado de Morelos' },
    { 'id': 9094, 'value': 'Universidad Politécnica del Golfo de México', 'label': 'Universidad Politécnica del Golfo de México' },
    { 'id': 9095, 'value': 'Universidad Politécnica del Mar y la Sierra', 'label': 'Universidad Politécnica del Mar y la Sierra' },
    { 'id': 9096, 'value': 'Universidad Politécnica del Sur de Zacatecas', 'label': 'Universidad Politécnica del Sur de Zacatecas' },
    { 'id': 9097, 'value': 'Universidad Politécnica del Valle de Évora', 'label': 'Universidad Politécnica del Valle de Évora' },
    { 'id': 9098, 'value': 'Universidad Politécnica del Valle de México', 'label': 'Universidad Politécnica del Valle de México' },
    { 'id': 9099, 'value': 'Universidad Politécnica del Valle de Toluca', 'label': 'Universidad Politécnica del Valle de Toluca' },
    { 'id': 4004, 'value': 'Universidad Politecnica Estatal del Carchi', 'label': 'Universidad Politecnica Estatal del Carchi' },
    { 'id': 9100, 'value': 'Universidad Politecnica Hispano Mexicana', 'label': 'Universidad Politecnica Hispano Mexicana' },
    { 'id': 3276, 'value': 'Universidad Politécnica Internacional', 'label': 'Universidad Politécnica Internacional' },
    { 'id': 14101, 'value': 'Universidad Politécnica Jose Felix Ribas', 'label': 'Universidad Politécnica Jose Felix Ribas' },
    { 'id': 9101, 'value': 'Universidad Politécnica Juventino Rosas', 'label': 'Universidad Politécnica Juventino Rosas' },
    { 'id': 9102, 'value': 'Universidad Politécnica Mesoamericana', 'label': 'Universidad Politécnica Mesoamericana' },
    { 'id': 9103, 'value': 'Universidad Politécnica Metropolitana de Hidalgo', 'label': 'Universidad Politécnica Metropolitana de Hidalgo' },
    { 'id': 9104, 'value': 'Universidad Politécnica Metropolitana de Puebla', 'label': 'Universidad Politécnica Metropolitana de Puebla' },
    { 'id': 4005, 'value': 'Universidad Politécnica Salesiana', 'label': 'Universidad Politécnica Salesiana' },
    { 'id': 3216, 'value': 'Universidad Pontificia Bolivariana', 'label': 'Universidad Pontificia Bolivariana' },
    { 'id': 4154, 'value': 'Universidad Pontificia Comillas', 'label': 'Universidad Pontificia Comillas' },
    { 'id': 9105, 'value': 'Universidad Pontificia de Mexico', 'label': 'Universidad Pontificia de Mexico' },
    { 'id': 4155, 'value': 'Universidad Pontificia de Salamanca', 'label': 'Universidad Pontificia de Salamanca' },
    { 'id': 9106, 'value': 'Universidad Popular Autonóma del Estado de Puebla', 'label': 'Universidad Popular Autonóma del Estado de Puebla' },
    { 'id': 9107, 'value': 'Universidad Popular de la Chontalpa', 'label': 'Universidad Popular de la Chontalpa' },
    { 'id': 9505, 'value': 'Universidad Popular de Nicaragua', 'label': 'Universidad Popular de Nicaragua' },
    { 'id': 3217, 'value': 'Universidad Popular del Cesar', 'label': 'Universidad Popular del Cesar' },
    { 'id': 1562, 'value': 'Universidad Privada Abierta Latinoamericana', 'label': 'Universidad Privada Abierta Latinoamericana' },
    { 'id': 9779, 'value': 'Universidad Privada Antenor Orrego', 'label': 'Universidad Privada Antenor Orrego' },
    { 'id': 9780, 'value': 'Universidad Privada Antonio Guillermo Urrelo', 'label': 'Universidad Privada Antonio Guillermo Urrelo' },
    { 'id': 9781, 'value': 'Universidad Privada Autónoma del Sur', 'label': 'Universidad Privada Autónoma del Sur' },
    { 'id': 1563, 'value': 'Universidad Privada Boliviana', 'label': 'Universidad Privada Boliviana' },
    { 'id': 1564, 'value': 'Universidad Privada Cumbre', 'label': 'Universidad Privada Cumbre' },
    { 'id': 1565, 'value': 'Universidad Privada de Ciencias Administrativas y Tecnologicas', 'label': 'Universidad Privada de Ciencias Administrativas y Tecnologicas' },
    { 'id': 9782, 'value': 'Universidad Privada de Huancayo Franklin Roosevelt', 'label': 'Universidad Privada de Huancayo Franklin Roosevelt' },
    { 'id': 9783, 'value': 'Universidad Privada de Ica', 'label': 'Universidad Privada de Ica' },
    { 'id': 9108, 'value': 'Universidad Privada de Irapuato', 'label': 'Universidad Privada de Irapuato' },
    { 'id': 9784, 'value': 'Universidad Privada de la Selva Peruana', 'label': 'Universidad Privada de la Selva Peruana' },
    { 'id': 1566, 'value': 'Universidad Privada de Oruro', 'label': 'Universidad Privada de Oruro' },
    { 'id': 9785, 'value': 'Universidad Privada de Pucallpa', 'label': 'Universidad Privada de Pucallpa' },
    { 'id': 1567, 'value': 'Universidad Privada de Santa Cruz de la Sierra', 'label': 'Universidad Privada de Santa Cruz de la Sierra' },
    { 'id': 9786, 'value': 'Universidad Privada de Tacna', 'label': 'Universidad Privada de Tacna' },
    { 'id': 9787, 'value': 'Universidad Privada de Trujillo', 'label': 'Universidad Privada de Trujillo' },
    { 'id': 1568, 'value': 'Universidad Privada del Chaco', 'label': 'Universidad Privada del Chaco' },
    { 'id': 9109, 'value': 'Universidad Privada del Estado de México', 'label': 'Universidad Privada del Estado de México' },
    { 'id': 9110, 'value': 'Universidad Privada del Estado de Morelos S.C.', 'label': 'Universidad Privada del Estado de Morelos S.C.' },
    { 'id': 9788, 'value': 'Universidad Privada del Norte', 'label': 'Universidad Privada del Norte' },
    { 'id': 1569, 'value': 'Universidad Privada del Valle', 'label': 'Universidad Privada del Valle' },
    { 'id': 1570, 'value': 'Universidad Privada Domingo Savio', 'label': 'Universidad Privada Domingo Savio' },
    { 'id': 1571, 'value': 'Universidad Privada Franz Tamayo', 'label': 'Universidad Privada Franz Tamayo' },
    { 'id': 9789, 'value': 'Universidad Privada Juan Mejía Baca', 'label': 'Universidad Privada Juan Mejía Baca' },
    { 'id': 9790, 'value': 'Universidad Privada Juan Pablo II', 'label': 'Universidad Privada Juan Pablo II' },
    { 'id': 9791, 'value': 'Universidad Privada Leonardo Da Vinci', 'label': 'Universidad Privada Leonardo Da Vinci' },
    { 'id': 9792, 'value': 'Universidad Privada Líder Peruana', 'label': 'Universidad Privada Líder Peruana' },
    { 'id': 9793, 'value': 'Universidad Privada Norbert Wiener', 'label': 'Universidad Privada Norbert Wiener' },
    { 'id': 9794, 'value': 'Universidad Privada Peruano Alemana', 'label': 'Universidad Privada Peruano Alemana' },
    { 'id': 9795, 'value': 'Universidad Privada San Carlos', 'label': 'Universidad Privada San Carlos' },
    { 'id': 9796, 'value': 'Universidad Privada San Juan Bautista', 'label': 'Universidad Privada San Juan Bautista' },
    { 'id': 9797, 'value': 'Universidad Privada TELESUP', 'label': 'Universidad Privada TELESUP' },
    { 'id': 993, 'value': 'Universidad Provincial de Córdoba', 'label': 'Universidad Provincial de Córdoba' },
    { 'id': 994, 'value': 'Universidad Provincial de Ezeiza', 'label': 'Universidad Provincial de Ezeiza' },
    { 'id': 995, 'value': 'Universidad Provincial del Sudoeste', 'label': 'Universidad Provincial del Sudoeste' },
    { 'id': 3880, 'value': 'Universidad Psicología Industrial Dominicana', 'label': 'Universidad Psicología Industrial Dominicana' },
    { 'id': 1572, 'value': 'Universidad Pública de El Alto', 'label': 'Universidad Pública de El Alto' },
    { 'id': 4156, 'value': 'Universidad Pública de Navarra', 'label': 'Universidad Pública de Navarra' },
    { 'id': 9111, 'value': 'Universidad Quetzalcóatl', 'label': 'Universidad Quetzalcóatl' },
    { 'id': 4783, 'value': 'Universidad Rafael Landívar', 'label': 'Universidad Rafael Landívar' },
    { 'id': 14102, 'value': 'Universidad Rafael Urdaneta', 'label': 'Universidad Rafael Urdaneta' },
    { 'id': 1573, 'value': 'Universidad Real', 'label': 'Universidad Real' },
    { 'id': 9112, 'value': 'Universidad Realística de México', 'label': 'Universidad Realística de México' },
    { 'id': 9113, 'value': 'Universidad Regiomontana', 'label': 'Universidad Regiomontana' },
    { 'id': 4006, 'value': 'Universidad Regional Amazónica IKIAM', 'label': 'Universidad Regional Amazónica IKIAM' },
    { 'id': 4007, 'value': 'Universidad Regional Autonoma de los Andes', 'label': 'Universidad Regional Autonoma de los Andes' },
    { 'id': 4784, 'value': 'Universidad Regional de Guatemala', 'label': 'Universidad Regional de Guatemala' },
    { 'id': 9114, 'value': 'Universidad Regional del Norte', 'label': 'Universidad Regional del Norte' },
    { 'id': 9115, 'value': 'Universidad Regional del Sureste A.C.', 'label': 'Universidad Regional del Sureste A.C.' },
    { 'id': 4157, 'value': 'Universidad Rey Juan Carlos', 'label': 'Universidad Rey Juan Carlos' },
    { 'id': 9798, 'value': 'Universidad Ricardo Palma', 'label': 'Universidad Ricardo Palma' },
    { 'id': 4785, 'value': 'Universidad Rural de Guatemala', 'label': 'Universidad Rural de Guatemala' },
    { 'id': 996, 'value': 'Universidad Salesiana', 'label': 'Universidad Salesiana' },
    { 'id': 1574, 'value': 'Universidad Salesiana de Bolivia', 'label': 'Universidad Salesiana de Bolivia' },
    { 'id': 11326, 'value': 'Universidad Salvadoreña Alberto Masferrer', 'label': 'Universidad Salvadoreña Alberto Masferrer' },
    { 'id': 9799, 'value': 'Universidad San Andrés', 'label': 'Universidad San Andrés' },
    { 'id': 1575, 'value': 'Universidad San Francisco de Asís', 'label': 'Universidad San Francisco de Asís' },
    { 'id': 4008, 'value': 'Universidad San Francisco de Quito', 'label': 'Universidad San Francisco de Quito' },
    { 'id': 1576, 'value': 'Universidad San Francisco Xavier', 'label': 'Universidad San Francisco Xavier' },
    { 'id': 9800, 'value': 'Universidad San Ignacio de Loyola', 'label': 'Universidad San Ignacio de Loyola' },
    { 'id': 4158, 'value': 'Universidad San Jorge', 'label': 'Universidad San Jorge' },
    { 'id': 3277, 'value': 'Universidad San Juan de la Cruz', 'label': 'Universidad San Juan de la Cruz' },
    { 'id': 3278, 'value': 'Universidad San Marcos', 'label': 'Universidad San Marcos' },
    { 'id': 9116, 'value': 'Universidad San Marcos S.C.', 'label': 'Universidad San Marcos S.C.' },
    { 'id': 4786, 'value': 'Universidad San Pablo de Guatemala', 'label': 'Universidad San Pablo de Guatemala' },
    { 'id': 9801, 'value': 'Universidad San Pedro', 'label': 'Universidad San Pedro' },
    { 'id': 2083, 'value': 'Universidad San Sebastián', 'label': 'Universidad San Sebastián' },
    { 'id': 9117, 'value': 'Universidad Santa Fe A.C.', 'label': 'Universidad Santa Fe A.C.' },
    { 'id': 3279, 'value': 'Universidad Santa Lucía', 'label': 'Universidad Santa Lucía' },
    { 'id': 14103, 'value': 'Universidad Santa María', 'label': 'Universidad Santa María' },
    { 'id': 4009, 'value': 'Universidad Santa María, Campus Guayaquil', 'label': 'Universidad Santa María, Campus Guayaquil' },
    { 'id': 3280, 'value': 'Universidad Santa Paula', 'label': 'Universidad Santa Paula' },
    { 'id': 9668, 'value': 'Universidad Santander', 'label': 'Universidad Santander' },
    { 'id': 3218, 'value': 'Universidad Santiago de Cali', 'label': 'Universidad Santiago de Cali' },
    { 'id': 9802, 'value': 'Universidad Santo Domingo de Guzmán', 'label': 'Universidad Santo Domingo de Guzmán' },
    { 'id': 3219, 'value': 'Universidad Santo Tomás', 'label': 'Universidad Santo Tomás' },
    { 'id': 9803, 'value': 'Universidad Santo Tomás de Aquino', 'label': 'Universidad Santo Tomás de Aquino' },
    { 'id': 9506, 'value': 'Universidad Santo Tomas de Oriente y Mediodía', 'label': 'Universidad Santo Tomas de Oriente y Mediodía' },
    { 'id': 2084, 'value': 'Universidad Santo Tomás, Chile', 'label': 'Universidad Santo Tomás, Chile' },
    { 'id': 2085, 'value': 'Universidad SEK', 'label': 'Universidad SEK' },
    { 'id': 9804, 'value': 'Universidad Señor de Sipán', 'label': 'Universidad Señor de Sipán' },
    { 'id': 3220, 'value': 'Universidad Sergio Arboleda', 'label': 'Universidad Sergio Arboleda' },
    { 'id': 9805, 'value': 'Universidad Sergio Bernales', 'label': 'Universidad Sergio Bernales' },
    { 'id': 9118, 'value': 'Universidad Simón Bolivar', 'label': 'Universidad Simón Bolivar' },
    { 'id': 3221, 'value': 'Universidad Simón Bolívar, Colombia', 'label': 'Universidad Simón Bolívar, Colombia' },
    { 'id': 1577, 'value': 'Universidad Simón I. Patiño', 'label': 'Universidad Simón I. Patiño' },
    { 'id': 9806, 'value': 'Universidad SISE', 'label': 'Universidad SISE' },
    { 'id': 3222, 'value': 'Universidad Surcolombiana', 'label': 'Universidad Surcolombiana' },
    { 'id': 9119, 'value': 'Universidad Tangamanga S.C.', 'label': 'Universidad Tangamanga S.C.' },
    { 'id': 9120, 'value': 'Universidad TecMilenio', 'label': 'Universidad TecMilenio' },
    { 'id': 4010, 'value': 'Universidad Técnica de Ambato', 'label': 'Universidad Técnica de Ambato' },
    { 'id': 4011, 'value': 'Universidad Técnica de Babahoyo', 'label': 'Universidad Técnica de Babahoyo' },
    { 'id': 9507, 'value': 'Universidad Técnica de Comercio', 'label': 'Universidad Técnica de Comercio' },
    { 'id': 4012, 'value': 'Universidad Técnica de Cotopaxi', 'label': 'Universidad Técnica de Cotopaxi' },
    { 'id': 4013, 'value': 'Universidad Tecnica de Machala', 'label': 'Universidad Tecnica de Machala' },
    { 'id': 4014, 'value': 'Universidad Técnica de Manabí', 'label': 'Universidad Técnica de Manabí' },
    { 'id': 1578, 'value': 'Universidad Técnica de Oruro', 'label': 'Universidad Técnica de Oruro' },
    { 'id': 4015, 'value': 'Universidad Técnica del Norte', 'label': 'Universidad Técnica del Norte' },
    { 'id': 4016, 'value': 'Universidad Técnica Estatal de Quevedo', 'label': 'Universidad Técnica Estatal de Quevedo' },
    { 'id': 2086, 'value': 'Universidad Técnica Federico Santa María', 'label': 'Universidad Técnica Federico Santa María' },
    { 'id': 11327, 'value': 'Universidad Técnica Latinoamericana', 'label': 'Universidad Técnica Latinoamericana' },
    { 'id': 4017, 'value': 'Universidad Tecnica Luis Vargas Torres', 'label': 'Universidad Tecnica Luis Vargas Torres' },
    { 'id': 3281, 'value': 'Universidad Técnica Nacional', 'label': 'Universidad Técnica Nacional' },
    { 'id': 4018, 'value': 'Universidad Técnica Particular de Loja', 'label': 'Universidad Técnica Particular de Loja' },
    { 'id': 1579, 'value': 'Universidad Técnica Privada Cosmos', 'label': 'Universidad Técnica Privada Cosmos' },
    { 'id': 13981, 'value': 'Universidad Tecnológica', 'label': 'Universidad Tecnológica' },
    { 'id': 9121, 'value': 'Universidad Tecnológica Americana', 'label': 'Universidad Tecnológica Americana' },
    { 'id': 1580, 'value': 'Universidad Tecnológica Boliviana', 'label': 'Universidad Tecnológica Boliviana' },
    { 'id': 4817, 'value': 'Universidad Tecnológica Centroamericana', 'label': 'Universidad Tecnológica Centroamericana' },
    { 'id': 3282, 'value': 'Universidad Tecnológica Costarricense', 'label': 'Universidad Tecnológica Costarricense' },
    { 'id': 9122, 'value': 'Universidad Tecnológica de Acapulco', 'label': 'Universidad Tecnológica de Acapulco' },
    { 'id': 9123, 'value': 'Universidad Tecnológica de Aguascalientes', 'label': 'Universidad Tecnológica de Aguascalientes' },
    { 'id': 9124, 'value': 'Universidad Tecnológica de Altamira', 'label': 'Universidad Tecnológica de Altamira' },
    { 'id': 9125, 'value': 'Universidad Tecnológica de Bahía de Banderas', 'label': 'Universidad Tecnológica de Bahía de Banderas' },
    { 'id': 3223, 'value': 'Universidad Tecnológica de Bolívar', 'label': 'Universidad Tecnológica de Bolívar' },
    { 'id': 9126, 'value': 'Universidad Tecnológica de Cadereyta', 'label': 'Universidad Tecnológica de Cadereyta' },
    { 'id': 9127, 'value': 'Universidad Tecnológica de Calvillo', 'label': 'Universidad Tecnológica de Calvillo' },
    { 'id': 9128, 'value': 'Universidad Tecnológica de Camargo', 'label': 'Universidad Tecnológica de Camargo' },
    { 'id': 9129, 'value': 'Universidad Tecnológica de Campeche', 'label': 'Universidad Tecnológica de Campeche' },
    { 'id': 9130, 'value': 'Universidad Tecnológica de Cancún', 'label': 'Universidad Tecnológica de Cancún' },
    { 'id': 9131, 'value': 'Universidad Tecnológica de Candelaria', 'label': 'Universidad Tecnológica de Candelaria' },
    { 'id': 9132, 'value': 'Universidad Tecnológica de Chetumal', 'label': 'Universidad Tecnológica de Chetumal' },
    { 'id': 9133, 'value': 'Universidad Tecnológica de Chihuahua', 'label': 'Universidad Tecnológica de Chihuahua' },
    { 'id': 9134, 'value': 'Universidad Tecnológica de Chihuhahua Sur', 'label': 'Universidad Tecnológica de Chihuhahua Sur' },
    { 'id': 2087, 'value': 'Universidad Tecnológica de Chile INACAP', 'label': 'Universidad Tecnológica de Chile INACAP' },
    { 'id': 9135, 'value': 'Universidad Tecnológica de Ciudad Juárez', 'label': 'Universidad Tecnológica de Ciudad Juárez' },
    { 'id': 9136, 'value': 'Universidad Tecnológica de Coahuila', 'label': 'Universidad Tecnológica de Coahuila' },
    { 'id': 9137, 'value': 'Universidad Tecnológica de Corregidora', 'label': 'Universidad Tecnológica de Corregidora' },
    { 'id': 9138, 'value': 'Universidad Tecnológica de Culiacán', 'label': 'Universidad Tecnológica de Culiacán' },
    { 'id': 9139, 'value': 'Universidad Tecnológica de Durango', 'label': 'Universidad Tecnológica de Durango' },
    { 'id': 11328, 'value': 'Universidad Tecnológica de El Salvador', 'label': 'Universidad Tecnológica de El Salvador' },
    { 'id': 9140, 'value': 'Universidad Tecnológica de Escuinapa', 'label': 'Universidad Tecnológica de Escuinapa' },
    { 'id': 9141, 'value': 'Universidad Tecnológica de Etchojoa', 'label': 'Universidad Tecnológica de Etchojoa' },
    { 'id': 9142, 'value': 'Universidad Tecnológica de Guaymas', 'label': 'Universidad Tecnológica de Guaymas' },
    { 'id': 9143, 'value': 'Universidad Tecnológica de Gutiérrez Zamora', 'label': 'Universidad Tecnológica de Gutiérrez Zamora' },
    { 'id': 9144, 'value': 'Universidad Tecnológica de Hermosillo, Sonora', 'label': 'Universidad Tecnológica de Hermosillo, Sonora' },
    { 'id': 4818, 'value': 'Universidad Tecnológica de Honduras', 'label': 'Universidad Tecnológica de Honduras' },
    { 'id': 9145, 'value': 'Universidad Tecnológica de Huejotzingo', 'label': 'Universidad Tecnológica de Huejotzingo' },
    { 'id': 9146, 'value': 'Universidad Tecnológica de Izúcar de Matamoros', 'label': 'Universidad Tecnológica de Izúcar de Matamoros' },
    { 'id': 9147, 'value': 'Universidad Tecnológica de Jalisco', 'label': 'Universidad Tecnológica de Jalisco' },
    { 'id': 9148, 'value': 'Universidad Tecnológica de la Babícora', 'label': 'Universidad Tecnológica de la Babícora' },
    { 'id': 9149, 'value': 'Universidad Tecnológica de la Costa', 'label': 'Universidad Tecnológica de la Costa' },
    { 'id': 9150, 'value': 'Universidad Tecnológica de la Costa Grande de Guerrero', 'label': 'Universidad Tecnológica de la Costa Grande de Guerrero' },
    { 'id': 3329, 'value': 'Universidad Tecnológica de la Habana José Antonio Echeverría', 'label': 'Universidad Tecnológica de la Habana José Antonio Echeverría' },
    { 'id': 9151, 'value': 'Universidad Tecnológica de La Huasteca Hidalguense', 'label': 'Universidad Tecnológica de La Huasteca Hidalguense' },
    { 'id': 9152, 'value': 'Universidad Tecnológica de La Laguna Durango', 'label': 'Universidad Tecnológica de La Laguna Durango' },
    { 'id': 9153, 'value': 'Universidad Tecnológica de la Mixteca', 'label': 'Universidad Tecnológica de la Mixteca' },
    { 'id': 9154, 'value': 'Universidad Tecnológica de La Paz', 'label': 'Universidad Tecnológica de La Paz' },
    { 'id': 9155, 'value': 'Universidad Tecnológica de la Región Carbonífera', 'label': 'Universidad Tecnológica de la Región Carbonífera' },
    { 'id': 9156, 'value': 'Universidad Tecnológica de la Región Centro de Coahuila', 'label': 'Universidad Tecnológica de la Región Centro de Coahuila' },
    { 'id': 9157, 'value': 'Universidad Tecnológica de la Región Norte de Guerrero', 'label': 'Universidad Tecnológica de la Región Norte de Guerrero' },
    { 'id': 9158, 'value': 'Universidad Tecnológica de La Riviera Maya', 'label': 'Universidad Tecnológica de La Riviera Maya' },
    { 'id': 9159, 'value': 'Universidad Tecnológica de la Selva', 'label': 'Universidad Tecnológica de la Selva' },
    { 'id': 9160, 'value': 'Universidad Tecnológica de la Sierra', 'label': 'Universidad Tecnológica de la Sierra' },
    { 'id': 9161, 'value': 'Universidad Tecnológica de la Sierra Hidalguense', 'label': 'Universidad Tecnológica de la Sierra Hidalguense' },
    { 'id': 9162, 'value': 'Universidad Tecnológica de la Sierra Sur de Oaxaca', 'label': 'Universidad Tecnológica de la Sierra Sur de Oaxaca' },
    { 'id': 9163, 'value': 'Universidad Tecnológica de la Tarahumara', 'label': 'Universidad Tecnológica de la Tarahumara' },
    { 'id': 9164, 'value': 'Universidad Tecnológica de la Tierra Caliente', 'label': 'Universidad Tecnológica de la Tierra Caliente' },
    { 'id': 9165, 'value': 'Universidad Tecnológica de la Zona Metropolitana de Guadalajara', 'label': 'Universidad Tecnológica de la Zona Metropolitana de Guadalajara' },
    { 'id': 9166, 'value': 'Universidad Tecnológica de la Zona Metropolitana del Valle de México', 'label': 'Universidad Tecnológica de la Zona Metropolitana del Valle de México' },
    { 'id': 9167, 'value': 'Universidad Tecnológica de León', 'label': 'Universidad Tecnológica de León' },
    { 'id': 9168, 'value': 'Universidad Tecnológica de los Valles Centrales de Oaxaca', 'label': 'Universidad Tecnológica de los Valles Centrales de Oaxaca' },
    { 'id': 9169, 'value': 'Universidad Tecnológica de Manzanillo', 'label': 'Universidad Tecnológica de Manzanillo' },
    { 'id': 9170, 'value': 'Universidad Tecnológica de Matamoros', 'label': 'Universidad Tecnológica de Matamoros' },
    { 'id': 9171, 'value': 'Universidad Tecnológica de México', 'label': 'Universidad Tecnológica de México' },
    { 'id': 9172, 'value': 'Universidad Tecnológica de Morelia', 'label': 'Universidad Tecnológica de Morelia' },
    { 'id': 9173, 'value': 'Universidad Tecnológica de Nayarit', 'label': 'Universidad Tecnológica de Nayarit' },
    { 'id': 9174, 'value': 'Universidad Tecnológica de Nezahualcóyotl', 'label': 'Universidad Tecnológica de Nezahualcóyotl' },
    { 'id': 9175, 'value': 'Universidad Tecnológica de Nogales, Sonora', 'label': 'Universidad Tecnológica de Nogales, Sonora' },
    { 'id': 9176, 'value': 'Universidad Tecnológica de Nuevo Laredo', 'label': 'Universidad Tecnológica de Nuevo Laredo' },
    { 'id': 9177, 'value': 'Universidad Tecnológica de Oriental', 'label': 'Universidad Tecnológica de Oriental' },
    { 'id': 9669, 'value': 'Universidad Tecnológica de Panamá', 'label': 'Universidad Tecnológica de Panamá' },
    { 'id': 9178, 'value': 'Universidad Tecnológica de Paquimé', 'label': 'Universidad Tecnológica de Paquimé' },
    { 'id': 9179, 'value': 'Universidad Tecnológica de Parral', 'label': 'Universidad Tecnológica de Parral' },
    { 'id': 3224, 'value': 'Universidad Tecnológica de Pereira', 'label': 'Universidad Tecnológica de Pereira' },
    { 'id': 9180, 'value': 'Universidad Tecnológica de Poanas', 'label': 'Universidad Tecnológica de Poanas' },
    { 'id': 9181, 'value': 'Universidad Tecnológica de Puebla', 'label': 'Universidad Tecnológica de Puebla' },
    { 'id': 9182, 'value': 'Universidad Tecnológica de Puerto Peñasco', 'label': 'Universidad Tecnológica de Puerto Peñasco' },
    { 'id': 9183, 'value': 'Universidad Tecnológica de Querétaro', 'label': 'Universidad Tecnológica de Querétaro' },
    { 'id': 9184, 'value': 'Universidad Tecnológica de Rodeo', 'label': 'Universidad Tecnológica de Rodeo' },
    { 'id': 9185, 'value': 'Universidad Tecnológica de Salamanca', 'label': 'Universidad Tecnológica de Salamanca' },
    { 'id': 9186, 'value': 'Universidad Tecnológica de Saltillo', 'label': 'Universidad Tecnológica de Saltillo' },
    { 'id': 9187, 'value': 'Universidad Tecnológica de San Juan del Río', 'label': 'Universidad Tecnológica de San Juan del Río' },
    { 'id': 9188, 'value': 'Universidad Tecnológica de San Luis Potosí', 'label': 'Universidad Tecnológica de San Luis Potosí' },
    { 'id': 9189, 'value': 'Universidad Tecnológica de San Luis Río Colorado', 'label': 'Universidad Tecnológica de San Luis Río Colorado' },
    { 'id': 9190, 'value': 'Universidad Tecnológica de San Miguel de Allende', 'label': 'Universidad Tecnológica de San Miguel de Allende' },
    { 'id': 9191, 'value': 'Universidad Tecnológica de Santa Catarina', 'label': 'Universidad Tecnológica de Santa Catarina' },
    { 'id': 3881, 'value': 'Universidad Tecnológica de Santiago', 'label': 'Universidad Tecnológica de Santiago' },
    { 'id': 9192, 'value': 'Universidad Tecnológica de Tabasco', 'label': 'Universidad Tecnológica de Tabasco' },
    { 'id': 9193, 'value': 'Universidad Tecnológica de Tamaulipas Norte', 'label': 'Universidad Tecnológica de Tamaulipas Norte' },
    { 'id': 9194, 'value': 'Universidad Tecnológica de Tecámac', 'label': 'Universidad Tecnológica de Tecámac' },
    { 'id': 9195, 'value': 'Universidad Tecnológica de Tecamachalco', 'label': 'Universidad Tecnológica de Tecamachalco' },
    { 'id': 9196, 'value': 'Universidad Tecnológica de Tehuacán', 'label': 'Universidad Tecnológica de Tehuacán' },
    { 'id': 9197, 'value': 'Universidad Tecnológica de Tijuana', 'label': 'Universidad Tecnológica de Tijuana' },
    { 'id': 9198, 'value': 'Universidad Tecnológica de Tlaxcala', 'label': 'Universidad Tecnológica de Tlaxcala' },
    { 'id': 9199, 'value': 'Universidad Tecnológica de Torreón', 'label': 'Universidad Tecnológica de Torreón' },
    { 'id': 9201, 'value': 'Universidad Tecnológica de Tula-Tepeji', 'label': 'Universidad Tecnológica de Tula-Tepeji' },
    { 'id': 9200, 'value': 'Universidad Tecnológica de Tulancingo', 'label': 'Universidad Tecnológica de Tulancingo' },
    { 'id': 9202, 'value': 'Universidad Tecnológica de Xicotepec de Juárez', 'label': 'Universidad Tecnológica de Xicotepec de Juárez' },
    { 'id': 9203, 'value': 'Universidad Tecnológica de Zinacantepec', 'label': 'Universidad Tecnológica de Zinacantepec' },
    { 'id': 14104, 'value': 'Universidad Tecnológica del Centro', 'label': 'Universidad Tecnológica del Centro' },
    { 'id': 9204, 'value': 'Universidad Tecnológica del Centro de Mexico S.C.', 'label': 'Universidad Tecnológica del Centro de Mexico S.C.' },
    { 'id': 9205, 'value': 'Universidad Tecnológica del Centro de Veracruz', 'label': 'Universidad Tecnológica del Centro de Veracruz' },
    { 'id': 9206, 'value': 'Universidad Tecnológica del Centro, Mexico', 'label': 'Universidad Tecnológica del Centro, Mexico' },
    { 'id': 3225, 'value': 'Universidad Tecnológica del Choco', 'label': 'Universidad Tecnológica del Choco' },
    { 'id': 3882, 'value': 'Universidad Tecnológica del Cibao Oriental', 'label': 'Universidad Tecnológica del Cibao Oriental' },
    { 'id': 9207, 'value': 'Universidad Tecnológica del Estado de Zacatecas', 'label': 'Universidad Tecnológica del Estado de Zacatecas' },
    { 'id': 9208, 'value': 'Universidad Tecnológica del Mar de Tamaulipas Bicentenario', 'label': 'Universidad Tecnológica del Mar de Tamaulipas Bicentenario' },
    { 'id': 9209, 'value': 'Universidad Tecnológica del Mar del Estado de Guerrero', 'label': 'Universidad Tecnológica del Mar del Estado de Guerrero' },
    { 'id': 9210, 'value': 'Universidad Tecnológica del Mayab', 'label': 'Universidad Tecnológica del Mayab' },
    { 'id': 9211, 'value': 'Universidad Tecnológica del Norte de Aguascalientes', 'label': 'Universidad Tecnológica del Norte de Aguascalientes' },
    { 'id': 9212, 'value': 'Universidad Tecnológica del Norte de Coahuila', 'label': 'Universidad Tecnológica del Norte de Coahuila' },
    { 'id': 9213, 'value': 'Universidad Tecnológica del Norte de Guanajuato', 'label': 'Universidad Tecnológica del Norte de Guanajuato' },
    { 'id': 9807, 'value': 'Universidad Tecnológica del Peru', 'label': 'Universidad Tecnológica del Peru' },
    { 'id': 9214, 'value': 'Universidad Tecnológica del Poniente', 'label': 'Universidad Tecnológica del Poniente' },
    { 'id': 3883, 'value': 'Universidad Tecnológica del Sur', 'label': 'Universidad Tecnológica del Sur' },
    { 'id': 9215, 'value': 'Universidad Tecnológica del Sur de Sonora', 'label': 'Universidad Tecnológica del Sur de Sonora' },
    { 'id': 9216, 'value': 'Universidad Tecnológica del Sur del Estado de México', 'label': 'Universidad Tecnológica del Sur del Estado de México' },
    { 'id': 9217, 'value': 'Universidad Tecnológica del Sur del Estado de Morelos', 'label': 'Universidad Tecnológica del Sur del Estado de Morelos' },
    { 'id': 9218, 'value': 'Universidad Tecnológica del Sureste de Veracruz', 'label': 'Universidad Tecnológica del Sureste de Veracruz' },
    { 'id': 9219, 'value': 'Universidad Tecnológica del Suroeste de Guanajuato', 'label': 'Universidad Tecnológica del Suroeste de Guanajuato' },
    { 'id': 9220, 'value': 'Universidad Tecnológica del Usumacinta', 'label': 'Universidad Tecnológica del Usumacinta' },
    { 'id': 9221, 'value': 'Universidad Tecnológica del Valle de Toluca', 'label': 'Universidad Tecnológica del Valle de Toluca' },
    { 'id': 9222, 'value': 'Universidad Tecnológica del Valle del Mezquital', 'label': 'Universidad Tecnológica del Valle del Mezquital' },
    { 'id': 9223, 'value': 'Universidad Tecnológica El Retoño', 'label': 'Universidad Tecnológica El Retoño' },
    { 'id': 9224, 'value': 'Universidad Tecnológica Emiliano Zapata del Estado de Morelos', 'label': 'Universidad Tecnológica Emiliano Zapata del Estado de Morelos' },
    { 'id': 4019, 'value': 'Universidad Tecnológica Empresarial de Guayaquil', 'label': 'Universidad Tecnológica Empresarial de Guayaquil' },
    { 'id': 4020, 'value': 'Universidad Tecnológica Equinoccial', 'label': 'Universidad Tecnológica Equinoccial' },
    { 'id': 9225, 'value': 'Universidad Tecnológica Fidel Velázquez', 'label': 'Universidad Tecnológica Fidel Velázquez' },
    { 'id': 9226, 'value': 'Universidad Tecnológica General Mariano Escobedo', 'label': 'Universidad Tecnológica General Mariano Escobedo' },
    { 'id': 4021, 'value': 'Universidad Tecnológica Indoamérica', 'label': 'Universidad Tecnológica Indoamérica' },
    { 'id': 9508, 'value': 'Universidad Tecnológica La Salle', 'label': 'Universidad Tecnológica La Salle' },
    { 'id': 9227, 'value': 'Universidad Tecnológica Linares', 'label': 'Universidad Tecnológica Linares' },
    { 'id': 2088, 'value': 'Universidad Tecnológica Metropolitana', 'label': 'Universidad Tecnológica Metropolitana' },
    { 'id': 9228, 'value': 'Universidad Tecnológica Metropolitana, Mexico', 'label': 'Universidad Tecnológica Metropolitana, Mexico' },
    { 'id': 9229, 'value': 'Universidad Tecnológica Minera de Zimapán', 'label': 'Universidad Tecnológica Minera de Zimapán' },
    { 'id': 997, 'value': 'Universidad Tecnológica Nacional', 'label': 'Universidad Tecnológica Nacional' },
    { 'id': 9509, 'value': 'Universidad Tecnológica Nicaragüense', 'label': 'Universidad Tecnológica Nicaragüense' },
    { 'id': 9670, 'value': 'Universidad Tecnológica Oteima', 'label': 'Universidad Tecnológica Oteima' },
    { 'id': 1581, 'value': 'Universidad Tecnológica Privada de Santa Cruz', 'label': 'Universidad Tecnológica Privada de Santa Cruz' },
    { 'id': 9230, 'value': 'Universidad Tecnológica Regional del Sur', 'label': 'Universidad Tecnológica Regional del Sur' },
    { 'id': 9510, 'value': 'Universidad Thomas More', 'label': 'Universidad Thomas More' },
    { 'id': 998, 'value': 'Universidad Torcuato di Tella', 'label': 'Universidad Torcuato di Tella' },
    { 'id': 2089, 'value': 'Universidad UCINF', 'label': 'Universidad UCINF' },
    { 'id': 1582, 'value': 'Universidad Unidad', 'label': 'Universidad Unidad' },
    { 'id': 1583, 'value': 'Universidad Unión Bolivariana', 'label': 'Universidad Unión Bolivariana' },
    { 'id': 9231, 'value': 'Universidad Valle del Grijalva S.C.', 'label': 'Universidad Valle del Grijalva S.C.' },
    { 'id': 14105, 'value': 'Universidad Valle del Momboy', 'label': 'Universidad Valle del Momboy' },
    { 'id': 9232, 'value': 'Universidad Vasco de Quiroga A.C.', 'label': 'Universidad Vasco de Quiroga A.C.' },
    { 'id': 14106, 'value': 'Universidad Venezolana de los Hidrocarburos', 'label': 'Universidad Venezolana de los Hidrocarburos' },
    { 'id': 9233, 'value': 'Universidad Veracruzana', 'label': 'Universidad Veracruzana' },
    { 'id': 3283, 'value': 'Universidad Veritas', 'label': 'Universidad Veritas' },
    { 'id': 9234, 'value': 'Universidad Villasunción', 'label': 'Universidad Villasunción' },
    { 'id': 9235, 'value': 'Universidad Westhill', 'label': 'Universidad Westhill' },
    { 'id': 9236, 'value': 'Universidad Xicotepetl A.C.', 'label': 'Universidad Xicotepetl A.C.' },
    { 'id': 14107, 'value': 'Universidad Yacambú', 'label': 'Universidad Yacambú' },
    { 'id': 4022, 'value': 'Universidad Yachay Tech', 'label': 'Universidad Yachay Tech' },
    { 'id': 9237, 'value': 'Universidad YMCA', 'label': 'Universidad YMCA' },
    { 'id': 854, 'value': 'Universidade 11 de Novembro', 'label': 'Universidade 11 de Novembro' },
    { 'id': 855, 'value': 'Universidade Agostinho Neto', 'label': 'Universidade Agostinho Neto' },
    { 'id': 1591, 'value': 'Universidade Alto Vale do Rio do Peixe', 'label': 'Universidade Alto Vale do Rio do Peixe' },
    { 'id': 1592, 'value': 'Universidade Anhanguera de São Paulo', 'label': 'Universidade Anhanguera de São Paulo' },
    { 'id': 1593, 'value': 'Universidade Anhanguera-Uniderp', 'label': 'Universidade Anhanguera-Uniderp' },
    { 'id': 1594, 'value': 'Universidade Anhembi Morumbi', 'label': 'Universidade Anhembi Morumbi' },
    { 'id': 10476, 'value': 'Universidade Atlântica', 'label': 'Universidade Atlântica' },
    { 'id': 10477, 'value': 'Universidade Autónoma de Lisboa Luís de Camões', 'label': 'Universidade Autónoma de Lisboa Luís de Camões' },
    { 'id': 1595, 'value': 'Universidade Brasil', 'label': 'Universidade Brasil' },
    { 'id': 1596, 'value': 'Universidade Braz Cubas', 'label': 'Universidade Braz Cubas' },
    { 'id': 1597, 'value': 'Universidade Cândido Mendes', 'label': 'Universidade Cândido Mendes' },
    { 'id': 1598, 'value': 'Universidade Castelo Branco', 'label': 'Universidade Castelo Branco' },
    { 'id': 856, 'value': 'Universidade Católica de Angola', 'label': 'Universidade Católica de Angola' },
    { 'id': 1599, 'value': 'Universidade Católica de Brasília', 'label': 'Universidade Católica de Brasília' },
    { 'id': 9283, 'value': 'Universidade Católica de Moçambique', 'label': 'Universidade Católica de Moçambique' },
    { 'id': 1600, 'value': 'Universidade Católica de Pelotas', 'label': 'Universidade Católica de Pelotas' },
    { 'id': 1601, 'value': 'Universidade Católica de Pernambuco', 'label': 'Universidade Católica de Pernambuco' },
    { 'id': 1602, 'value': 'Universidade Católica de Petrópolis', 'label': 'Universidade Católica de Petrópolis' },
    { 'id': 1603, 'value': 'Universidade Católica de Santos', 'label': 'Universidade Católica de Santos' },
    { 'id': 1604, 'value': 'Universidade Católica do Salvador', 'label': 'Universidade Católica do Salvador' },
    { 'id': 1605, 'value': 'Universidade Católica Dom Bosco', 'label': 'Universidade Católica Dom Bosco' },
    { 'id': 584, 'value': 'Universidade Católica Portuguesa', 'label': 'Universidade Católica Portuguesa' },
    { 'id': 1606, 'value': 'Universidade Ceuma', 'label': 'Universidade Ceuma' },
    { 'id': 1607, 'value': 'Universidade Cidade de São Paulo', 'label': 'Universidade Cidade de São Paulo' },
    { 'id': 1608, 'value': 'Universidade Comunitária da Região de Chapecó', 'label': 'Universidade Comunitária da Região de Chapecó' },
    { 'id': 1609, 'value': 'Universidade Cruzeiro do Sul', 'label': 'Universidade Cruzeiro do Sul' },
    { 'id': 857, 'value': 'Universidade Cuíto Cuanavale', 'label': 'Universidade Cuíto Cuanavale' },
    { 'id': 1610, 'value': 'Universidade da Amazônia', 'label': 'Universidade da Amazônia' },
    { 'id': 10478, 'value': 'Universidade da Beira Interior', 'label': 'Universidade da Beira Interior' },
    { 'id': 4159, 'value': 'Universidade da Coruña', 'label': 'Universidade da Coruña' },
    { 'id': 1611, 'value': 'Universidade da Integração Internacional da Lusofonia Afro-Brasileira', 'label': 'Universidade da Integração Internacional da Lusofonia Afro-Brasileira' },
    { 'id': 10479, 'value': 'Universidade da Madeira', 'label': 'Universidade da Madeira' },
    { 'id': 11530, 'value': 'Universidade da Paz', 'label': 'Universidade da Paz' },
    { 'id': 1612, 'value': 'Universidade da Região da Campanha', 'label': 'Universidade da Região da Campanha' },
    { 'id': 1613, 'value': 'Universidade da Região de Joinville', 'label': 'Universidade da Região de Joinville' },
    { 'id': 1614, 'value': 'Universidade de Araraquara', 'label': 'Universidade de Araraquara' },
    { 'id': 10480, 'value': 'Universidade de Aveiro', 'label': 'Universidade de Aveiro' },
    { 'id': 858, 'value': 'Universidade de Belas', 'label': 'Universidade de Belas' },
    { 'id': 1615, 'value': 'Universidade de Brasília', 'label': 'Universidade de Brasília' },
    { 'id': 3333, 'value': 'Universidade de Cabo Verde', 'label': 'Universidade de Cabo Verde' },
    { 'id': 1616, 'value': 'Universidade de Caxias do Sul', 'label': 'Universidade de Caxias do Sul' },
    { 'id': 10481, 'value': 'Universidade de Coimbra', 'label': 'Universidade de Coimbra' },
    { 'id': 1617, 'value': 'Universidade de Cruz Alta', 'label': 'Universidade de Cruz Alta' },
    { 'id': 1618, 'value': 'Universidade de Cuiabá', 'label': 'Universidade de Cuiabá' },
    { 'id': 11531, 'value': 'Universidade de Díli', 'label': 'Universidade de Díli' },
    { 'id': 10482, 'value': 'Universidade de Évora', 'label': 'Universidade de Évora' },
    { 'id': 1619, 'value': 'Universidade de Fortaleza', 'label': 'Universidade de Fortaleza' },
    { 'id': 1620, 'value': 'Universidade de Franca', 'label': 'Universidade de Franca' },
    { 'id': 1621, 'value': 'Universidade de Gurupi', 'label': 'Universidade de Gurupi' },
    { 'id': 1622, 'value': 'Universidade de Itaúna', 'label': 'Universidade de Itaúna' },
    { 'id': 10483, 'value': 'Universidade de Lisboa', 'label': 'Universidade de Lisboa' },
    { 'id': 8660, 'value': 'Universidade de Macau', 'label': 'Universidade de Macau' },
    { 'id': 1623, 'value': 'Universidade de Marília', 'label': 'Universidade de Marília' },
    { 'id': 1624, 'value': 'Universidade de Mogi das Cruzes', 'label': 'Universidade de Mogi das Cruzes' },
    { 'id': 1625, 'value': 'Universidade de Passo Fundo', 'label': 'Universidade de Passo Fundo' },
    { 'id': 1626, 'value': 'Universidade de Pernambuco', 'label': 'Universidade de Pernambuco' },
    { 'id': 1627, 'value': 'Universidade de Ribeirão Preto', 'label': 'Universidade de Ribeirão Preto' },
    { 'id': 1628, 'value': 'Universidade de Rio Verde', 'label': 'Universidade de Rio Verde' },
    { 'id': 1629, 'value': 'Universidade de Santa Cruz do Sul', 'label': 'Universidade de Santa Cruz do Sul' },
    { 'id': 3334, 'value': 'Universidade de Santiago', 'label': 'Universidade de Santiago' },
    { 'id': 1630, 'value': 'Universidade de São Paulo', 'label': 'Universidade de São Paulo' },
    { 'id': 1631, 'value': 'Universidade de Sorocaba', 'label': 'Universidade de Sorocaba' },
    { 'id': 1632, 'value': 'Universidade de Taubaté', 'label': 'Universidade de Taubaté' },
    { 'id': 10484, 'value': 'Universidade de Trás-os-Montes e Alto Douro', 'label': 'Universidade de Trás-os-Montes e Alto Douro' },
    { 'id': 1633, 'value': 'Universidade de Uberaba', 'label': 'Universidade de Uberaba' },
    { 'id': 1634, 'value': 'Universidade de Vassouras', 'label': 'Universidade de Vassouras' },
    { 'id': 4160, 'value': 'Universidade de Vigo', 'label': 'Universidade de Vigo' },
    { 'id': 10485, 'value': 'Universidade do Algarve', 'label': 'Universidade do Algarve' },
    { 'id': 1635, 'value': 'Universidade do Contestado', 'label': 'Universidade do Contestado' },
    { 'id': 1636, 'value': 'Universidade do Estado da Bahia', 'label': 'Universidade do Estado da Bahia' },
    { 'id': 1637, 'value': 'Universidade do Estado de Mato Grosso', 'label': 'Universidade do Estado de Mato Grosso' },
    { 'id': 1638, 'value': 'Universidade do Estado de Minas Gerais', 'label': 'Universidade do Estado de Minas Gerais' },
    { 'id': 1639, 'value': 'Universidade do Estado de Santa Catarina', 'label': 'Universidade do Estado de Santa Catarina' },
    { 'id': 1640, 'value': 'Universidade do Estado do Amapá', 'label': 'Universidade do Estado do Amapá' },
    { 'id': 1641, 'value': 'Universidade do Estado do Amazonas', 'label': 'Universidade do Estado do Amazonas' },
    { 'id': 1642, 'value': 'Universidade do Estado do Pará', 'label': 'Universidade do Estado do Pará' },
    { 'id': 1643, 'value': 'Universidade do Estado do Rio de Janeiro', 'label': 'Universidade do Estado do Rio de Janeiro' },
    { 'id': 1644, 'value': 'Universidade do Estado do Rio Grande do Norte', 'label': 'Universidade do Estado do Rio Grande do Norte' },
    { 'id': 1645, 'value': 'Universidade do Extremo Sul Catarinense', 'label': 'Universidade do Extremo Sul Catarinense' },
    { 'id': 1646, 'value': 'Universidade do Grande Rio', 'label': 'Universidade do Grande Rio' },
    { 'id': 3335, 'value': 'Universidade do Mindelo', 'label': 'Universidade do Mindelo' },
    { 'id': 10486, 'value': 'Universidade do Minho', 'label': 'Universidade do Minho' },
    { 'id': 1647, 'value': 'Universidade do Oeste de Santa Catarina', 'label': 'Universidade do Oeste de Santa Catarina' },
    { 'id': 1648, 'value': 'Universidade do Oeste Paulista', 'label': 'Universidade do Oeste Paulista' },
    { 'id': 1649, 'value': 'Universidade do Planalto Catarinense', 'label': 'Universidade do Planalto Catarinense' },
    { 'id': 10487, 'value': 'Universidade do Porto', 'label': 'Universidade do Porto' },
    { 'id': 1650, 'value': 'Universidade do Sagrado Coração', 'label': 'Universidade do Sagrado Coração' },
    { 'id': 1651, 'value': 'Universidade do Sul de Santa Catarina', 'label': 'Universidade do Sul de Santa Catarina' },
    { 'id': 1652, 'value': 'Universidade do Vale do Itajaí', 'label': 'Universidade do Vale do Itajaí' },
    { 'id': 1653, 'value': 'Universidade do Vale do Paraíba', 'label': 'Universidade do Vale do Paraíba' },
    { 'id': 1654, 'value': 'Universidade do Vale do Rio dos Sinos', 'label': 'Universidade do Vale do Rio dos Sinos' },
    { 'id': 1655, 'value': 'Universidade do Vale do Sapucaí', 'label': 'Universidade do Vale do Sapucaí' },
    { 'id': 1656, 'value': 'Universidade do Vale do Taquari', 'label': 'Universidade do Vale do Taquari' },
    { 'id': 10488, 'value': 'Universidade dos Açores', 'label': 'Universidade dos Açores' },
    { 'id': 9284, 'value': 'Universidade Eduardo Mondlane', 'label': 'Universidade Eduardo Mondlane' },
    { 'id': 1657, 'value': 'Universidade Estácio de Sá', 'label': 'Universidade Estácio de Sá' },
    { 'id': 1658, 'value': 'Universidade Estadual da Paraíba', 'label': 'Universidade Estadual da Paraíba' },
    { 'id': 1659, 'value': 'Universidade Estadual da Região Tocantina do Maranhão', 'label': 'Universidade Estadual da Região Tocantina do Maranhão' },
    { 'id': 1660, 'value': 'Universidade Estadual de Alagoas', 'label': 'Universidade Estadual de Alagoas' },
    { 'id': 1661, 'value': 'Universidade Estadual de Campinas', 'label': 'Universidade Estadual de Campinas' },
    { 'id': 1662, 'value': 'Universidade Estadual de Ciências da Saúde de Alagoas', 'label': 'Universidade Estadual de Ciências da Saúde de Alagoas' },
    { 'id': 1663, 'value': 'Universidade Estadual de Feira de Santana', 'label': 'Universidade Estadual de Feira de Santana' },
    { 'id': 1664, 'value': 'Universidade Estadual de Goiás', 'label': 'Universidade Estadual de Goiás' },
    { 'id': 1665, 'value': 'Universidade Estadual de Londrina', 'label': 'Universidade Estadual de Londrina' },
    { 'id': 1666, 'value': 'Universidade Estadual de Maringá', 'label': 'Universidade Estadual de Maringá' },
    { 'id': 1667, 'value': 'Universidade Estadual de Mato Grosso do Sul', 'label': 'Universidade Estadual de Mato Grosso do Sul' },
    { 'id': 1668, 'value': 'Universidade Estadual de Montes Claros', 'label': 'Universidade Estadual de Montes Claros' },
    { 'id': 1669, 'value': 'Universidade Estadual de Ponta Grossa', 'label': 'Universidade Estadual de Ponta Grossa' },
    { 'id': 1670, 'value': 'Universidade Estadual de Roraima', 'label': 'Universidade Estadual de Roraima' },
    { 'id': 1671, 'value': 'Universidade Estadual de Santa Cruz', 'label': 'Universidade Estadual de Santa Cruz' },
    { 'id': 1672, 'value': 'Universidade Estadual do Ceará', 'label': 'Universidade Estadual do Ceará' },
    { 'id': 1673, 'value': 'Universidade Estadual do Centro-Oeste', 'label': 'Universidade Estadual do Centro-Oeste' },
    { 'id': 1674, 'value': 'Universidade Estadual do Maranhão', 'label': 'Universidade Estadual do Maranhão' },
    { 'id': 1675, 'value': 'Universidade Estadual do Norte do Paraná', 'label': 'Universidade Estadual do Norte do Paraná' },
    { 'id': 1676, 'value': 'Universidade Estadual do Norte Fluminense', 'label': 'Universidade Estadual do Norte Fluminense' },
    { 'id': 1677, 'value': 'Universidade Estadual do Oeste do Paraná', 'label': 'Universidade Estadual do Oeste do Paraná' },
    { 'id': 1678, 'value': 'Universidade Estadual do Paraná', 'label': 'Universidade Estadual do Paraná' },
    { 'id': 1679, 'value': 'Universidade Estadual do Piauí', 'label': 'Universidade Estadual do Piauí' },
    { 'id': 1680, 'value': 'Universidade Estadual do Rio Grande do Sul', 'label': 'Universidade Estadual do Rio Grande do Sul' },
    { 'id': 1681, 'value': 'Universidade Estadual do Sudoeste da Bahia', 'label': 'Universidade Estadual do Sudoeste da Bahia' },
    { 'id': 1682, 'value': 'Universidade Estadual do Tocantins', 'label': 'Universidade Estadual do Tocantins' },
    { 'id': 1683, 'value': 'Universidade Estadual Paulista', 'label': 'Universidade Estadual Paulista' },
    { 'id': 1684, 'value': 'Universidade Estadual Vale do Acaraú', 'label': 'Universidade Estadual Vale do Acaraú' },
    { 'id': 10489, 'value': 'Universidade Europeia', 'label': 'Universidade Europeia' },
    { 'id': 1685, 'value': 'Universidade Federal da Bahia', 'label': 'Universidade Federal da Bahia' },
    { 'id': 1686, 'value': 'Universidade Federal da Fronteira Sul', 'label': 'Universidade Federal da Fronteira Sul' },
    { 'id': 1687, 'value': 'Universidade Federal da Grande Dourados', 'label': 'Universidade Federal da Grande Dourados' },
    { 'id': 1688, 'value': 'Universidade Federal da Integração Latino-Americana', 'label': 'Universidade Federal da Integração Latino-Americana' },
    { 'id': 1689, 'value': 'Universidade Federal da Paraíba', 'label': 'Universidade Federal da Paraíba' },
    { 'id': 1690, 'value': 'Universidade Federal de Alagoas', 'label': 'Universidade Federal de Alagoas' },
    { 'id': 1691, 'value': 'Universidade Federal de Alfenas', 'label': 'Universidade Federal de Alfenas' },
    { 'id': 1692, 'value': 'Universidade Federal de Campina Grande', 'label': 'Universidade Federal de Campina Grande' },
    { 'id': 1693, 'value': 'Universidade Federal de Ciências da Saúde de Porto Alegre', 'label': 'Universidade Federal de Ciências da Saúde de Porto Alegre' },
    { 'id': 1694, 'value': 'Universidade Federal de Goiás', 'label': 'Universidade Federal de Goiás' },
    { 'id': 1695, 'value': 'Universidade Federal de Itajubá', 'label': 'Universidade Federal de Itajubá' },
    { 'id': 1696, 'value': 'Universidade Federal de Juiz de Fora', 'label': 'Universidade Federal de Juiz de Fora' },
    { 'id': 1697, 'value': 'Universidade Federal de Lavras', 'label': 'Universidade Federal de Lavras' },
    { 'id': 1698, 'value': 'Universidade Federal de Mato Grosso', 'label': 'Universidade Federal de Mato Grosso' },
    { 'id': 1699, 'value': 'Universidade Federal de Mato Grosso do Sul', 'label': 'Universidade Federal de Mato Grosso do Sul' },
    { 'id': 1700, 'value': 'Universidade Federal de Minas Gerais', 'label': 'Universidade Federal de Minas Gerais' },
    { 'id': 1701, 'value': 'Universidade Federal de Ouro Preto', 'label': 'Universidade Federal de Ouro Preto' },
    { 'id': 1702, 'value': 'Universidade Federal de Pelotas', 'label': 'Universidade Federal de Pelotas' },
    { 'id': 1703, 'value': 'Universidade Federal de Pernambuco', 'label': 'Universidade Federal de Pernambuco' },
    { 'id': 1704, 'value': 'Universidade Federal de Rondônia', 'label': 'Universidade Federal de Rondônia' },
    { 'id': 1705, 'value': 'Universidade Federal de Roraima', 'label': 'Universidade Federal de Roraima' },
    { 'id': 1706, 'value': 'Universidade Federal de Santa Catarina', 'label': 'Universidade Federal de Santa Catarina' },
    { 'id': 1707, 'value': 'Universidade Federal de Santa Maria', 'label': 'Universidade Federal de Santa Maria' },
    { 'id': 1708, 'value': 'Universidade Federal de São Carlos', 'label': 'Universidade Federal de São Carlos' },
    { 'id': 1709, 'value': 'Universidade Federal de São João Del Rei', 'label': 'Universidade Federal de São João Del Rei' },
    { 'id': 1710, 'value': 'Universidade Federal de São Paulo', 'label': 'Universidade Federal de São Paulo' },
    { 'id': 1711, 'value': 'Universidade Federal de Sergipe', 'label': 'Universidade Federal de Sergipe' },
    { 'id': 1712, 'value': 'Universidade Federal de Uberlândia', 'label': 'Universidade Federal de Uberlândia' },
    { 'id': 1713, 'value': 'Universidade Federal de Viçosa', 'label': 'Universidade Federal de Viçosa' },
    { 'id': 1714, 'value': 'Universidade Federal do ABC', 'label': 'Universidade Federal do ABC' },
    { 'id': 1715, 'value': 'Universidade Federal do Acre', 'label': 'Universidade Federal do Acre' },
    { 'id': 1716, 'value': 'Universidade Federal do Amapá', 'label': 'Universidade Federal do Amapá' },
    { 'id': 1717, 'value': 'Universidade Federal do Amazonas', 'label': 'Universidade Federal do Amazonas' },
    { 'id': 1718, 'value': 'Universidade Federal do Cariri', 'label': 'Universidade Federal do Cariri' },
    { 'id': 1719, 'value': 'Universidade Federal do Ceará', 'label': 'Universidade Federal do Ceará' },
    { 'id': 1720, 'value': 'Universidade Federal do Espírito Santo', 'label': 'Universidade Federal do Espírito Santo' },
    { 'id': 1721, 'value': 'Universidade Federal do Estado do Rio de Janeiro', 'label': 'Universidade Federal do Estado do Rio de Janeiro' },
    { 'id': 1722, 'value': 'Universidade Federal do Maranhão', 'label': 'Universidade Federal do Maranhão' },
    { 'id': 1723, 'value': 'Universidade Federal do Oeste da Bahia', 'label': 'Universidade Federal do Oeste da Bahia' },
    { 'id': 1724, 'value': 'Universidade Federal do Oeste do Pará', 'label': 'Universidade Federal do Oeste do Pará' },
    { 'id': 1725, 'value': 'Universidade Federal do Pampa', 'label': 'Universidade Federal do Pampa' },
    { 'id': 1726, 'value': 'Universidade Federal do Pará', 'label': 'Universidade Federal do Pará' },
    { 'id': 1727, 'value': 'Universidade Federal do Paraná', 'label': 'Universidade Federal do Paraná' },
    { 'id': 1728, 'value': 'Universidade Federal do Piauí', 'label': 'Universidade Federal do Piauí' },
    { 'id': 1729, 'value': 'Universidade Federal do Recôncavo da Bahia', 'label': 'Universidade Federal do Recôncavo da Bahia' },
    { 'id': 1730, 'value': 'Universidade Federal do Rio de Janeiro', 'label': 'Universidade Federal do Rio de Janeiro' },
    { 'id': 1731, 'value': 'Universidade Federal do Rio Grande', 'label': 'Universidade Federal do Rio Grande' },
    { 'id': 1732, 'value': 'Universidade Federal do Rio Grande do Norte', 'label': 'Universidade Federal do Rio Grande do Norte' },
    { 'id': 1733, 'value': 'Universidade Federal do Rio Grande do Sul', 'label': 'Universidade Federal do Rio Grande do Sul' },
    { 'id': 1734, 'value': 'Universidade Federal do Sul da Bahia', 'label': 'Universidade Federal do Sul da Bahia' },
    { 'id': 1735, 'value': 'Universidade Federal do Sul e Sudeste do Pará', 'label': 'Universidade Federal do Sul e Sudeste do Pará' },
    { 'id': 1736, 'value': 'Universidade Federal do Tocantins', 'label': 'Universidade Federal do Tocantins' },
    { 'id': 1737, 'value': 'Universidade Federal do Triângulo Mineiro', 'label': 'Universidade Federal do Triângulo Mineiro' },
    { 'id': 1738, 'value': 'Universidade Federal do Vale do São Francisco', 'label': 'Universidade Federal do Vale do São Francisco' },
    { 'id': 1739, 'value': 'Universidade Federal dos Vales do Jequitinhonha e Mucuri', 'label': 'Universidade Federal dos Vales do Jequitinhonha e Mucuri' },
    { 'id': 1740, 'value': 'Universidade Federal Fluminense', 'label': 'Universidade Federal Fluminense' },
    { 'id': 1741, 'value': 'Universidade Federal Rural da Amazônia', 'label': 'Universidade Federal Rural da Amazônia' },
    { 'id': 1742, 'value': 'Universidade Federal Rural de Pernambuco', 'label': 'Universidade Federal Rural de Pernambuco' },
    { 'id': 1743, 'value': 'Universidade Federal Rural do Rio de Janeiro', 'label': 'Universidade Federal Rural do Rio de Janeiro' },
    { 'id': 1744, 'value': 'Universidade Federal Rural do Semi-Árido', 'label': 'Universidade Federal Rural do Semi-Árido' },
    { 'id': 1745, 'value': 'Universidade Feevale', 'label': 'Universidade Feevale' },
    { 'id': 10490, 'value': 'Universidade Fernando Pessoa', 'label': 'Universidade Fernando Pessoa' },
    { 'id': 1746, 'value': 'Universidade Franciscana', 'label': 'Universidade Franciscana' },
    { 'id': 1747, 'value': 'Universidade FUMEC', 'label': 'Universidade FUMEC' },
    { 'id': 859, 'value': 'Universidade Gregório Semedo', 'label': 'Universidade Gregório Semedo' },
    { 'id': 1748, 'value': 'Universidade Ibirapuera', 'label': 'Universidade Ibirapuera' },
    { 'id': 1749, 'value': 'Universidade Iguaçu', 'label': 'Universidade Iguaçu' },
    { 'id': 860, 'value': 'Universidade Independente de Angola', 'label': 'Universidade Independente de Angola' },
    { 'id': 3336, 'value': 'Universidade Intercontinental de Cabo Verde', 'label': 'Universidade Intercontinental de Cabo Verde' },
    { 'id': 861, 'value': 'Universidade Jean Piaget de Angola', 'label': 'Universidade Jean Piaget de Angola' },
    { 'id': 3337, 'value': 'Universidade Jean Piaget de Cabo Verde', 'label': 'Universidade Jean Piaget de Cabo Verde' },
    { 'id': 9285, 'value': 'Universidade Jean Piaget de Moçambique', 'label': 'Universidade Jean Piaget de Moçambique' },
    { 'id': 1750, 'value': 'Universidade José do Rosário Vellano', 'label': 'Universidade José do Rosário Vellano' },
    { 'id': 862, 'value': 'Universidade José Eduardo dos Santos', 'label': 'Universidade José Eduardo dos Santos' },
    { 'id': 863, 'value': 'Universidade Katyavala Bwila', 'label': 'Universidade Katyavala Bwila' },
    { 'id': 864, 'value': 'Universidade Kimpa Vita', 'label': 'Universidade Kimpa Vita' },
    { 'id': 1751, 'value': 'Universidade La Salle', 'label': 'Universidade La Salle' },
    { 'id': 865, 'value': 'Universidade Lueji A\'Nkonde', 'label': 'Universidade Lueji A\'Nkonde' },
    { 'id': 9286, 'value': 'Universidade Lúrio', 'label': 'Universidade Lúrio' },
    { 'id': 10491, 'value': 'Universidade Lusíada', 'label': 'Universidade Lusíada' },
    { 'id': 866, 'value': 'Universidade Lusíada de Angola', 'label': 'Universidade Lusíada de Angola' },
    { 'id': 3338, 'value': 'Universidade Lusófona de Cabo Verde', 'label': 'Universidade Lusófona de Cabo Verde' },
    { 'id': 10492, 'value': 'Universidade Lusófona de Humanidades e Technologias', 'label': 'Universidade Lusófona de Humanidades e Technologias' },
    { 'id': 10493, 'value': 'Universidade Lusófona do Porto', 'label': 'Universidade Lusófona do Porto' },
    { 'id': 1752, 'value': 'Universidade Luterana do Brasil', 'label': 'Universidade Luterana do Brasil' },
    { 'id': 867, 'value': 'Universidade Mandume Ya Ndemufayo', 'label': 'Universidade Mandume Ya Ndemufayo' },
    { 'id': 868, 'value': 'Universidade Metodista de Angola', 'label': 'Universidade Metodista de Angola' },
    { 'id': 1753, 'value': 'Universidade Metodista de Piracicaba', 'label': 'Universidade Metodista de Piracicaba' },
    { 'id': 1754, 'value': 'Universidade Metodista de São Paulo', 'label': 'Universidade Metodista de São Paulo' },
    { 'id': 1755, 'value': 'Universidade Metropolitana de Santos', 'label': 'Universidade Metropolitana de Santos' },
    { 'id': 1756, 'value': 'Universidade Municipal de São Caetano do Sul', 'label': 'Universidade Municipal de São Caetano do Sul' },
    { 'id': 9287, 'value': 'Universidade Mussa Bin Bique', 'label': 'Universidade Mussa Bin Bique' },
    { 'id': 11532, 'value': 'Universidade Nacional Timor Lorosa\'e', 'label': 'Universidade Nacional Timor Lorosa\'e' },
    { 'id': 1757, 'value': 'Universidade Nilton Lins', 'label': 'Universidade Nilton Lins' },
    { 'id': 1758, 'value': 'Universidade Norte do Paraná', 'label': 'Universidade Norte do Paraná' },
    { 'id': 10494, 'value': 'Universidade Nova de Lisboa', 'label': 'Universidade Nova de Lisboa' },
    { 'id': 1759, 'value': 'Universidade Nove de Julho', 'label': 'Universidade Nove de Julho' },
    { 'id': 11533, 'value': 'Universidade Oriental Timor Lorosa\'e', 'label': 'Universidade Oriental Timor Lorosa\'e' },
    { 'id': 869, 'value': 'Universidade Óscar Ribas', 'label': 'Universidade Óscar Ribas' },
    { 'id': 1760, 'value': 'Universidade Paranaense', 'label': 'Universidade Paranaense' },
    { 'id': 1761, 'value': 'Universidade Paulista', 'label': 'Universidade Paulista' },
    { 'id': 9288, 'value': 'Universidade Pedagógica', 'label': 'Universidade Pedagógica' },
    { 'id': 9289, 'value': 'Universidade Pedagógica Sagrada Família', 'label': 'Universidade Pedagógica Sagrada Família' },
    { 'id': 9290, 'value': 'Universidade Politécnica', 'label': 'Universidade Politécnica' },
    { 'id': 10495, 'value': 'Universidade Portucalense Infante D. Henrique', 'label': 'Universidade Portucalense Infante D. Henrique' },
    { 'id': 1762, 'value': 'Universidade Positivo', 'label': 'Universidade Positivo' },
    { 'id': 1763, 'value': 'Universidade Potiguar', 'label': 'Universidade Potiguar' },
    { 'id': 1764, 'value': 'Universidade Presbiteriana Mackenzie', 'label': 'Universidade Presbiteriana Mackenzie' },
    { 'id': 1765, 'value': 'Universidade Presidente Antônio Carlos', 'label': 'Universidade Presidente Antônio Carlos' },
    { 'id': 870, 'value': 'Universidade Privada de Angola', 'label': 'Universidade Privada de Angola' },
    { 'id': 1766, 'value': 'Universidade Regional de Blumenau', 'label': 'Universidade Regional de Blumenau' },
    { 'id': 1767, 'value': 'Universidade Regional do Cariri', 'label': 'Universidade Regional do Cariri' },
    { 'id': 1768, 'value': 'Universidade Regional do Noroeste do Estado do Rio Grande do Sul', 'label': 'Universidade Regional do Noroeste do Estado do Rio Grande do Sul' },
    { 'id': 1769, 'value': 'Universidade Regional Integrada do Alto Uruguai e das Missões', 'label': 'Universidade Regional Integrada do Alto Uruguai e das Missões' },
    { 'id': 1770, 'value': 'Universidade Salgado de Oliveira', 'label': 'Universidade Salgado de Oliveira' },
    { 'id': 1771, 'value': 'Universidade Salvador', 'label': 'Universidade Salvador' },
    { 'id': 1772, 'value': 'Universidade Santa Cecília', 'label': 'Universidade Santa Cecília' },
    { 'id': 1773, 'value': 'Universidade Santa Úrsula', 'label': 'Universidade Santa Úrsula' },
    { 'id': 1774, 'value': 'Universidade Santo Amaro', 'label': 'Universidade Santo Amaro' },
    { 'id': 1775, 'value': 'Universidade São Francisco', 'label': 'Universidade São Francisco' },
    { 'id': 1776, 'value': 'Universidade São Judas Tadeu', 'label': 'Universidade São Judas Tadeu' },
    { 'id': 9291, 'value': 'Universidade São Tomás de Moçambique', 'label': 'Universidade São Tomás de Moçambique' },
    { 'id': 871, 'value': 'Universidade Técnica de Angola', 'label': 'Universidade Técnica de Angola' },
    { 'id': 9292, 'value': 'Universidade Técnica de Moçambique', 'label': 'Universidade Técnica de Moçambique' },
    { 'id': 1777, 'value': 'Universidade Tecnológica Federal do Paraná', 'label': 'Universidade Tecnológica Federal do Paraná' },
    { 'id': 1778, 'value': 'Universidade Tiradentes', 'label': 'Universidade Tiradentes' },
    { 'id': 1779, 'value': 'Universidade Tuiuti do Paraná', 'label': 'Universidade Tuiuti do Paraná' },
    { 'id': 1780, 'value': 'Universidade Universus Veritas Guarulhos', 'label': 'Universidade Universus Veritas Guarulhos' },
    { 'id': 1781, 'value': 'Universidade Vale do Rio Doce', 'label': 'Universidade Vale do Rio Doce' },
    { 'id': 1782, 'value': 'Universidade Vale do Rio Verde', 'label': 'Universidade Vale do Rio Verde' },
    { 'id': 1783, 'value': 'Universidade Veiga de Almeida', 'label': 'Universidade Veiga de Almeida' },
    { 'id': 1784, 'value': 'Universidade Vila Velha', 'label': 'Universidade Vila Velha' },
    { 'id': 9293, 'value': 'Universidade Zambeze', 'label': 'Universidade Zambeze' },
    { 'id': 58, 'value': 'Università "Carlo Cattaneo" (LIUC)', 'label': 'Università "Carlo Cattaneo" (LIUC)' },
    { 'id': 14332, 'value': 'Università Bocconi Milano', 'label': 'Università Bocconi Milano' },
    { 'id': 600, 'value': 'Università Campus Bio-medico di Roma', 'label': 'Università Campus Bio-medico di Roma' },
    { 'id': 14336, 'value': 'Università Cattolica del "Sacro Cuore"', 'label': 'Università Cattolica del "Sacro Cuore"' },
    { 'id': 23, 'value': 'Università degli studi "Carlo Bo" di Urbino', 'label': 'Università degli studi "Carlo Bo" di Urbino' },
    { 'id': 90, 'value': 'Università degli Studi "Magna Graecia" di Catanzaro - Magna Grecia', 'label': 'Università degli Studi "Magna Graecia" di Catanzaro - Magna Grecia' },
    { 'id': 6831, 'value': 'Università degli studi Ca\' Foscari di Venezia', 'label': 'Università degli studi Ca\' Foscari di Venezia' },
    { 'id': 198, 'value': 'Università degli Studi del Molise', 'label': 'Università degli Studi del Molise' },
    { 'id': 138, 'value': 'Università degli studi del Piemonte Orientale "Amedeo Avogadro"', 'label': 'Università degli studi del Piemonte Orientale "Amedeo Avogadro"' },
    { 'id': 6859, 'value': 'Università degli Studi del Sannio', 'label': 'Università degli Studi del Sannio' },
    { 'id': 108, 'value': 'Università degli Studi dell\'Aquila', 'label': 'Università degli Studi dell\'Aquila' },
    { 'id': 148, 'value': 'Università degli Studi dell\'Insubria', 'label': 'Università degli Studi dell\'Insubria' },
    { 'id': 478, 'value': 'Università degli Studi della Basilicata', 'label': 'Università degli Studi della Basilicata' },
    { 'id': 91, 'value': 'Università degli Studi della Campania - Luigi Vanvitelli (Napoli II)', 'label': 'Università degli Studi della Campania - Luigi Vanvitelli (Napoli II)' },
    { 'id': 420, 'value': 'Università degli Studi della Repubblica di San Marino', 'label': 'Università degli Studi della Repubblica di San Marino' },
    { 'id': 46, 'value': 'Università degli Studi della Tuscia', 'label': 'Università degli Studi della Tuscia' },
    { 'id': 72, 'value': 'Università degli Studi di Bari Aldo Moro', 'label': 'Università degli Studi di Bari Aldo Moro' },
    { 'id': 79, 'value': 'Università degli Studi di Bergamo', 'label': 'Università degli Studi di Bergamo' },
    { 'id': 24, 'value': 'Università degli Studi di Bologna', 'label': 'Università degli Studi di Bologna' },
    { 'id': 212, 'value': 'Università degli Studi di Brescia', 'label': 'Università degli Studi di Brescia' },
    { 'id': 124, 'value': 'Università degli Studi di Cagliari', 'label': 'Università degli Studi di Cagliari' },
    { 'id': 60, 'value': 'Università degli Studi di Camerino', 'label': 'Università degli Studi di Camerino' },
    { 'id': 322, 'value': 'Università degli Studi di Cassino e del Lazio Meridionale', 'label': 'Università degli Studi di Cassino e del Lazio Meridionale' },
    { 'id': 81, 'value': 'Università degli Studi di Catania', 'label': 'Università degli Studi di Catania' },
    { 'id': 27, 'value': 'Università degli Studi di Enna Kore', 'label': 'Università degli Studi di Enna Kore' },
    { 'id': 223, 'value': 'Università degli Studi di Ferrara', 'label': 'Università degli Studi di Ferrara' },
    { 'id': 28, 'value': 'Università degli studi di Firenze', 'label': 'Università degli studi di Firenze' },
    { 'id': 96, 'value': 'Università degli Studi di Foggia', 'label': 'Università degli Studi di Foggia' },
    { 'id': 109, 'value': 'Università degli Studi di Genova', 'label': 'Università degli Studi di Genova' },
    { 'id': 234, 'value': 'Università degli Studi di Macerata', 'label': 'Università degli Studi di Macerata' },
    { 'id': 128, 'value': 'Università degli Studi di Messina', 'label': 'Università degli Studi di Messina' },
    { 'id': 157, 'value': 'Università degli Studi di Milano', 'label': 'Università degli Studi di Milano' },
    { 'id': 61, 'value': 'Università degli studi di Modena e Reggio Emilia', 'label': 'Università degli studi di Modena e Reggio Emilia' },
    { 'id': 82, 'value': 'Università degli Studi di Napoli - Parthenope', 'label': 'Università degli Studi di Napoli - Parthenope' },
    { 'id': 35, 'value': 'Università degli Studi di Napoli Federico II', 'label': 'Università degli Studi di Napoli Federico II' },
    { 'id': 6833, 'value': 'Università degli Studi di Padova', 'label': 'Università degli Studi di Padova' },
    { 'id': 69, 'value': 'Università degli Studi di Palermo', 'label': 'Università degli Studi di Palermo' },
    { 'id': 74, 'value': 'Università degli Studi di Parma', 'label': 'Università degli Studi di Parma' },
    { 'id': 175, 'value': 'Università degli studi di Pavia', 'label': 'Università degli studi di Pavia' },
    { 'id': 20, 'value': 'Università degli Studi di Perugia', 'label': 'Università degli Studi di Perugia' },
    { 'id': 146, 'value': 'Università degli Studi di Pisa', 'label': 'Università degli Studi di Pisa' },
    { 'id': 85, 'value': 'Università degli studi di Roma "Foro Italico"', 'label': 'Università degli studi di Roma "Foro Italico"' },
    { 'id': 13, 'value': 'Università degli Studi di Roma La Sapienza', 'label': 'Università degli Studi di Roma La Sapienza' },
    { 'id': 151, 'value': 'Università degli Studi di Roma Tor Vergata', 'label': 'Università degli Studi di Roma Tor Vergata' },
    { 'id': 38, 'value': 'Università degli Studi di Salerno', 'label': 'Università degli Studi di Salerno' },
    { 'id': 115, 'value': 'Università degli Studi di Sassari', 'label': 'Università degli Studi di Sassari' },
    { 'id': 137, 'value': 'Università degli Studi di Siena', 'label': 'Università degli Studi di Siena' },
    { 'id': 228, 'value': 'Università degli Studi di Teramo', 'label': 'Università degli Studi di Teramo' },
    { 'id': 244, 'value': 'Università degli Studi di Torino', 'label': 'Università degli Studi di Torino' },
    { 'id': 70, 'value': 'Università degli Studi di Trento', 'label': 'Università degli Studi di Trento' },
    { 'id': 55, 'value': 'Università Degli Studi di Trieste', 'label': 'Università Degli Studi di Trieste' },
    { 'id': 131, 'value': 'Università degli Studi di Udine', 'label': 'Università degli Studi di Udine' },
    { 'id': 6830, 'value': 'Università degli Studi di Verona', 'label': 'Università degli Studi di Verona' },
    { 'id': 114, 'value': 'Università degli studi Gabriele D\'Annunzio di Chieti e Pescara', 'label': 'Università degli studi Gabriele D\'Annunzio di Chieti e Pescara' },
    { 'id': 351, 'value': 'Università degli Studi Internazionali di Roma - UNINT', 'label': 'Università degli Studi Internazionali di Roma - UNINT' },
    { 'id': 12, 'value': 'Università degli Studi L\'Orientale di Napoli', 'label': 'Università degli Studi L\'Orientale di Napoli' },
    { 'id': 414, 'value': 'Università degli studi Mediterranea di Reggio Calabria', 'label': 'Università degli studi Mediterranea di Reggio Calabria' },
    { 'id': 77, 'value': 'Università degli Studi Roma Tre', 'label': 'Università degli Studi Roma Tre' },
    { 'id': 110, 'value': 'Università degli Studi Suor Orsola Benincasa di Napoli', 'label': 'Università degli Studi Suor Orsola Benincasa di Napoli' },
    { 'id': 14327, 'value': 'Università dei Sapori', 'label': 'Università dei Sapori' },
    { 'id': 80, 'value': 'Università del Salento', 'label': 'Università del Salento' },
    { 'id': 6874, 'value': 'Università della Calabria', 'label': 'Università della Calabria' },
    { 'id': 164, 'value': 'Università della Svizzera italiana', 'label': 'Università della Svizzera italiana' },
    { 'id': 407, 'value': 'Università della Valle D\'Aosta', 'label': 'Università della Valle D\'Aosta' },
    { 'id': 239, 'value': 'Università di Amsterdam - Law Faculty', 'label': 'Università di Amsterdam - Law Faculty' },
    { 'id': 134, 'value': 'Università di Leiden', 'label': 'Università di Leiden' },
    { 'id': 6818, 'value': 'Università di Scienze Gastronomiche - Pollenzo', 'label': 'Università di Scienze Gastronomiche - Pollenzo' },
    { 'id': 264, 'value': 'Università Europea di Roma', 'label': 'Università Europea di Roma' },
    { 'id': 6832, 'value': 'Università Iuav di Venezia', 'label': 'Università Iuav di Venezia' },
    { 'id': 176, 'value': 'Università Nostra Signora del Buon Consiglio', 'label': 'Università Nostra Signora del Buon Consiglio' },
    { 'id': 6877, 'value': 'Università per stranieri "Dante Alighieri" di Reggio Calabria', 'label': 'Università per stranieri "Dante Alighieri" di Reggio Calabria' },
    { 'id': 541, 'value': 'Università per Stranieri di Perugia', 'label': 'Università per Stranieri di Perugia' },
    { 'id': 25, 'value': 'Università per Stranieri di Siena', 'label': 'Università per Stranieri di Siena' },
    { 'id': 6837, 'value': 'Università Politecnica delle Marche - Ancona (UNIVPM)', 'label': 'Università Politecnica delle Marche - Ancona (UNIVPM)' },
    { 'id': 63, 'value': 'Università telematica "e-Campus" di Novedrate (eCampus)', 'label': 'Università telematica "e-Campus" di Novedrate (eCampus)' },
    { 'id': 6860, 'value': 'Università telematica "Giustino Fortunato" di Benevento', 'label': 'Università telematica "Giustino Fortunato" di Benevento' },
    { 'id': 458, 'value': 'Università telematica "Italian University line" di Firenze (IUL)', 'label': 'Università telematica "Italian University line" di Firenze (IUL)' },
    { 'id': 208, 'value': 'Universita telematica "Pegaso" di Napoli', 'label': 'Universita telematica "Pegaso" di Napoli' },
    { 'id': 6851, 'value': 'Università telematica Guglielmo Marconi di Roma', 'label': 'Università telematica Guglielmo Marconi di Roma' },
    { 'id': 132, 'value': 'Università telematica internazionale UNINETTUNO di Roma', 'label': 'Università telematica internazionale UNINETTUNO di Roma' },
    { 'id': 447, 'value': 'Università Telematica Mercatorum di Roma', 'label': 'Università Telematica Mercatorum di Roma' },
    { 'id': 169, 'value': 'Università telematica Niccolò Cusano di Roma', 'label': 'Università telematica Niccolò Cusano di Roma' },
    { 'id': 6869, 'value': 'Università telematica non statale "Leonardo da Vinci" di Torrevecchia Teatina (CH)', 'label': 'Università telematica non statale "Leonardo da Vinci" di Torrevecchia Teatina (CH)' },
    { 'id': 389, 'value': 'Università telematica Unitelma Sapienza di Roma', 'label': 'Università telematica Unitelma Sapienza di Roma' },
    { 'id': 167, 'value': 'Università Vest Vasile Goldis', 'label': 'Università Vest Vasile Goldis' },
    { 'id': 326, 'value': 'Università Vest Vasile Goldis Arad', 'label': 'Università Vest Vasile Goldis Arad' },
    { 'id': 11225, 'value': 'Universita\' degli Studi di San Marino', 'label': 'Universita\' degli Studi di San Marino' },
    { 'id': 450, 'value': 'Universitad do Porto', 'label': 'Universitad do Porto' },
    { 'id': 3226, 'value': 'Universitaria Agustiniana', 'label': 'Universitaria Agustiniana' },
    { 'id': 4939, 'value': 'Universitas 17 Agustus 1945', 'label': 'Universitas 17 Agustus 1945' },
    { 'id': 4940, 'value': 'Universitas 17 Agustus 1945 Banyuwangi', 'label': 'Universitas 17 Agustus 1945 Banyuwangi' },
    { 'id': 4941, 'value': 'Universitas 17 Agustus 1945 Cirebon', 'label': 'Universitas 17 Agustus 1945 Cirebon' },
    { 'id': 4942, 'value': 'Universitas 17 Agustus 1945 Samarinda', 'label': 'Universitas 17 Agustus 1945 Samarinda' },
    { 'id': 4943, 'value': 'Universitas 17 Agustus 1945 Semarang', 'label': 'Universitas 17 Agustus 1945 Semarang' },
    { 'id': 4944, 'value': 'Universitas 17 Agustus 1945 Surabaya', 'label': 'Universitas 17 Agustus 1945 Surabaya' },
    { 'id': 4945, 'value': 'Universitas 45 Mataram', 'label': 'Universitas 45 Mataram' },
    { 'id': 4946, 'value': 'Universitas 45 Surabaya', 'label': 'Universitas 45 Surabaya' },
    { 'id': 4947, 'value': 'Universitas Abdurachman Saleh', 'label': 'Universitas Abdurachman Saleh' },
    { 'id': 4948, 'value': 'Universitas Abdurrab', 'label': 'Universitas Abdurrab' },
    { 'id': 4949, 'value': 'Universitas Abulyatama', 'label': 'Universitas Abulyatama' },
    { 'id': 4950, 'value': 'Universitas Achmad Yani', 'label': 'Universitas Achmad Yani' },
    { 'id': 4951, 'value': 'Universitas Advent Indonesia', 'label': 'Universitas Advent Indonesia' },
    { 'id': 4952, 'value': 'Universitas Ahmad Dahlan', 'label': 'Universitas Ahmad Dahlan' },
    { 'id': 4953, 'value': 'Universitas Airlangga', 'label': 'Universitas Airlangga' },
    { 'id': 4954, 'value': 'Universitas Aisyiyah Yogyakarta', 'label': 'Universitas Aisyiyah Yogyakarta' },
    { 'id': 4955, 'value': 'Universitas AKI', 'label': 'Universitas AKI' },
    { 'id': 4956, 'value': 'Universitas Al Asyariah Mandar', 'label': 'Universitas Al Asyariah Mandar' },
    { 'id': 4957, 'value': 'Universitas Al Azhar Indonesia', 'label': 'Universitas Al Azhar Indonesia' },
    { 'id': 4958, 'value': 'Universitas Al Ghifari', 'label': 'Universitas Al Ghifari' },
    { 'id': 4959, 'value': 'Universitas Al Muslim', 'label': 'Universitas Al Muslim' },
    { 'id': 4960, 'value': 'Universitas Al-Azhar', 'label': 'Universitas Al-Azhar' },
    { 'id': 4964, 'value': 'Universitas Al-washliyah Labuhan Batu', 'label': 'Universitas Al-washliyah Labuhan Batu' },
    { 'id': 4961, 'value': 'Universitas Alkhairaat', 'label': 'Universitas Alkhairaat' },
    { 'id': 4962, 'value': 'Universitas Alma Ata', 'label': 'Universitas Alma Ata' },
    { 'id': 4963, 'value': 'Universitas Alwashliyah', 'label': 'Universitas Alwashliyah' },
    { 'id': 4965, 'value': 'Universitas AMIKOM Yogyakarta', 'label': 'Universitas AMIKOM Yogyakarta' },
    { 'id': 4966, 'value': 'Universitas Amir Hamzah', 'label': 'Universitas Amir Hamzah' },
    { 'id': 4967, 'value': 'Universitas Andalas', 'label': 'Universitas Andalas' },
    { 'id': 4968, 'value': 'Universitas Andi Djemma', 'label': 'Universitas Andi Djemma' },
    { 'id': 4969, 'value': 'Universitas Antakusuma', 'label': 'Universitas Antakusuma' },
    { 'id': 4970, 'value': 'Universitas Aryasatya Deo Muri', 'label': 'Universitas Aryasatya Deo Muri' },
    { 'id': 4971, 'value': 'Universitas Asahan', 'label': 'Universitas Asahan' },
    { 'id': 4972, 'value': 'Universitas Atma Jaya Makassar', 'label': 'Universitas Atma Jaya Makassar' },
    { 'id': 4973, 'value': 'Universitas Atma Jaya Yogyakarta', 'label': 'Universitas Atma Jaya Yogyakarta' },
    { 'id': 4974, 'value': 'Universitas Azzahra', 'label': 'Universitas Azzahra' },
    { 'id': 4975, 'value': 'Universitas Baiturrahmah', 'label': 'Universitas Baiturrahmah' },
    { 'id': 4976, 'value': 'Universitas Bakrie', 'label': 'Universitas Bakrie' },
    { 'id': 4977, 'value': 'Universitas Bakti Indonesia', 'label': 'Universitas Bakti Indonesia' },
    { 'id': 4978, 'value': 'Universitas Bale Bandung', 'label': 'Universitas Bale Bandung' },
    { 'id': 4979, 'value': 'Universitas Balikpapan', 'label': 'Universitas Balikpapan' },
    { 'id': 4980, 'value': 'Universitas Bandar Lampung', 'label': 'Universitas Bandar Lampung' },
    { 'id': 4981, 'value': 'Universitas Bandung Raya', 'label': 'Universitas Bandung Raya' },
    { 'id': 4982, 'value': 'Universitas Bangka Belitung', 'label': 'Universitas Bangka Belitung' },
    { 'id': 4983, 'value': 'Universitas Banten Jaya', 'label': 'Universitas Banten Jaya' },
    { 'id': 4984, 'value': 'Universitas Batam', 'label': 'Universitas Batam' },
    { 'id': 4985, 'value': 'Universitas Batanghari', 'label': 'Universitas Batanghari' },
    { 'id': 4986, 'value': 'Universitas Baturaja', 'label': 'Universitas Baturaja' },
    { 'id': 4987, 'value': 'Universitas Bengkulu', 'label': 'Universitas Bengkulu' },
    { 'id': 4988, 'value': 'Universitas Bhayangkara Jakarta Raya', 'label': 'Universitas Bhayangkara Jakarta Raya' },
    { 'id': 4989, 'value': 'Universitas Bhayangkara Surabaya', 'label': 'Universitas Bhayangkara Surabaya' },
    { 'id': 4990, 'value': 'Universitas Billfath', 'label': 'Universitas Billfath' },
    { 'id': 4991, 'value': 'Universitas Bina Darma', 'label': 'Universitas Bina Darma' },
    { 'id': 4992, 'value': 'Universitas Bina Nusantara', 'label': 'Universitas Bina Nusantara' },
    { 'id': 4993, 'value': 'Universitas Bina Sarana Informatika', 'label': 'Universitas Bina Sarana Informatika' },
    { 'id': 4994, 'value': 'Universitas Bojonegoro', 'label': 'Universitas Bojonegoro' },
    { 'id': 4995, 'value': 'Universitas Bondowoso', 'label': 'Universitas Bondowoso' },
    { 'id': 4996, 'value': 'Universitas Borneo Tarakan', 'label': 'Universitas Borneo Tarakan' },
    { 'id': 4997, 'value': 'Universitas Borobudur', 'label': 'Universitas Borobudur' },
    { 'id': 4998, 'value': 'Universitas Bosowa', 'label': 'Universitas Bosowa' },
    { 'id': 4999, 'value': 'Universitas Boyolali', 'label': 'Universitas Boyolali' },
    { 'id': 5000, 'value': 'Universitas Brawijaya', 'label': 'Universitas Brawijaya' },
    { 'id': 5001, 'value': 'Universitas Buana Perjuangan Karawang', 'label': 'Universitas Buana Perjuangan Karawang' },
    { 'id': 5002, 'value': 'Universitas Buddhi Dharma', 'label': 'Universitas Buddhi Dharma' },
    { 'id': 5003, 'value': 'Universitas Budi Luhur', 'label': 'Universitas Budi Luhur' },
    { 'id': 5004, 'value': 'Universitas Bumi Hijrah Maluku Utara', 'label': 'Universitas Bumi Hijrah Maluku Utara' },
    { 'id': 5005, 'value': 'Universitas Bunda Mulia', 'label': 'Universitas Bunda Mulia' },
    { 'id': 5006, 'value': 'Universitas Bung Hatta', 'label': 'Universitas Bung Hatta' },
    { 'id': 5007, 'value': 'Universitas Bung Karno', 'label': 'Universitas Bung Karno' },
    { 'id': 5008, 'value': 'Universitas Cenderawasih', 'label': 'Universitas Cenderawasih' },
    { 'id': 5009, 'value': 'Universitas Ciputra', 'label': 'Universitas Ciputra' },
    { 'id': 5010, 'value': 'Universitas Cokroaminoto Makassar', 'label': 'Universitas Cokroaminoto Makassar' },
    { 'id': 5011, 'value': 'Universitas Cokroaminoto Palopo', 'label': 'Universitas Cokroaminoto Palopo' },
    { 'id': 5012, 'value': 'Universitas Cokroaminoto Yogyakarta', 'label': 'Universitas Cokroaminoto Yogyakarta' },
    { 'id': 5013, 'value': 'Universitas Cordova', 'label': 'Universitas Cordova' },
    { 'id': 5014, 'value': 'Universitas Darma Agung', 'label': 'Universitas Darma Agung' },
    { 'id': 5015, 'value': 'Universitas Darma Persada', 'label': 'Universitas Darma Persada' },
    { 'id': 5016, 'value': 'Universitas Darul \'ulum', 'label': 'Universitas Darul \'ulum' },
    { 'id': 5017, 'value': 'Universitas Darul Ulum Islamic Centre Sudirman', 'label': 'Universitas Darul Ulum Islamic Centre Sudirman' },
    { 'id': 5018, 'value': 'Universitas Darussalam Ambon', 'label': 'Universitas Darussalam Ambon' },
    { 'id': 5019, 'value': 'Universitas Darussalam Gontor', 'label': 'Universitas Darussalam Gontor' },
    { 'id': 5020, 'value': 'Universitas Darwan Ali', 'label': 'Universitas Darwan Ali' },
    { 'id': 5021, 'value': 'Universitas Dayanu Ikhsanuddin', 'label': 'Universitas Dayanu Ikhsanuddin' },
    { 'id': 5022, 'value': 'Universitas Dehasen Bengkulu', 'label': 'Universitas Dehasen Bengkulu' },
    { 'id': 5023, 'value': 'Universitas Dharma Andalas', 'label': 'Universitas Dharma Andalas' },
    { 'id': 5024, 'value': 'Universitas Dharmas Indonesia', 'label': 'Universitas Dharmas Indonesia' },
    { 'id': 5025, 'value': 'Universitas Dharmawangsa', 'label': 'Universitas Dharmawangsa' },
    { 'id': 5026, 'value': 'Universitas Dhyana Pura', 'label': 'Universitas Dhyana Pura' },
    { 'id': 5027, 'value': 'Universitas Dian Nusantara', 'label': 'Universitas Dian Nusantara' },
    { 'id': 5028, 'value': 'Universitas Dian Nuswantoro', 'label': 'Universitas Dian Nuswantoro' },
    { 'id': 5029, 'value': 'Universitas Diponegoro', 'label': 'Universitas Diponegoro' },
    { 'id': 5030, 'value': 'Universitas Dirgantara Marsekal Suryadarma', 'label': 'Universitas Dirgantara Marsekal Suryadarma' },
    { 'id': 5031, 'value': 'Universitas Djuanda', 'label': 'Universitas Djuanda' },
    { 'id': 5032, 'value': 'Universitas Doktor Nugroho Magetan', 'label': 'Universitas Doktor Nugroho Magetan' },
    { 'id': 5033, 'value': 'Universitas Dr. Soetomo', 'label': 'Universitas Dr. Soetomo' },
    { 'id': 5034, 'value': 'Universitas Dumoga Kotamobagu', 'label': 'Universitas Dumoga Kotamobagu' },
    { 'id': 5035, 'value': 'Universitas Dwijendra', 'label': 'Universitas Dwijendra' },
    { 'id': 5036, 'value': 'Universitas Efarina', 'label': 'Universitas Efarina' },
    { 'id': 5037, 'value': 'Universitas Ekasakti', 'label': 'Universitas Ekasakti' },
    { 'id': 5038, 'value': 'Universitas Esa Unggul', 'label': 'Universitas Esa Unggul' },
    { 'id': 5039, 'value': 'Universitas Fajar', 'label': 'Universitas Fajar' },
    { 'id': 5040, 'value': 'Universitas Flores', 'label': 'Universitas Flores' },
    { 'id': 5041, 'value': 'Universitas Gadjah Mada', 'label': 'Universitas Gadjah Mada' },
    { 'id': 5042, 'value': 'Universitas Gajah Putih', 'label': 'Universitas Gajah Putih' },
    { 'id': 5043, 'value': 'Universitas Gajayana Malang', 'label': 'Universitas Gajayana Malang' },
    { 'id': 5044, 'value': 'Universitas Galuh Ciamis', 'label': 'Universitas Galuh Ciamis' },
    { 'id': 5045, 'value': 'Universitas Garut', 'label': 'Universitas Garut' },
    { 'id': 5046, 'value': 'Universitas Gorontalo', 'label': 'Universitas Gorontalo' },
    { 'id': 5047, 'value': 'Universitas Graha Nusantara Padangsidimpuan', 'label': 'Universitas Graha Nusantara Padangsidimpuan' },
    { 'id': 5048, 'value': 'Universitas Gresik', 'label': 'Universitas Gresik' },
    { 'id': 5049, 'value': 'Universitas Gunadarma', 'label': 'Universitas Gunadarma' },
    { 'id': 5050, 'value': 'Universitas Gunung Kidul', 'label': 'Universitas Gunung Kidul' },
    { 'id': 5051, 'value': 'Universitas Gunung Leuser Aceh', 'label': 'Universitas Gunung Leuser Aceh' },
    { 'id': 5052, 'value': 'Universitas Gunung Rinjani', 'label': 'Universitas Gunung Rinjani' },
    { 'id': 5053, 'value': 'Universitas Halmahera', 'label': 'Universitas Halmahera' },
    { 'id': 5054, 'value': 'Universitas Halu Oleo', 'label': 'Universitas Halu Oleo' },
    { 'id': 5055, 'value': 'Universitas Hamzanwadi', 'label': 'Universitas Hamzanwadi' },
    { 'id': 5056, 'value': 'Universitas Hang Tuah', 'label': 'Universitas Hang Tuah' },
    { 'id': 5057, 'value': 'Universitas Hasanuddin', 'label': 'Universitas Hasanuddin' },
    { 'id': 5058, 'value': 'Universitas Hasyim Asy\'ari', 'label': 'Universitas Hasyim Asy\'ari' },
    { 'id': 5059, 'value': 'Universitas Hein Namotemo', 'label': 'Universitas Hein Namotemo' },
    { 'id': 5060, 'value': 'Universitas Hindu Indonesia', 'label': 'Universitas Hindu Indonesia' },
    { 'id': 5061, 'value': 'Universitas HKBP Nommensen', 'label': 'Universitas HKBP Nommensen' },
    { 'id': 5062, 'value': 'Universitas IBA', 'label': 'Universitas IBA' },
    { 'id': 5063, 'value': 'Universitas Ibn Khaldun', 'label': 'Universitas Ibn Khaldun' },
    { 'id': 5064, 'value': 'Universitas Ibnu Chaldun', 'label': 'Universitas Ibnu Chaldun' },
    { 'id': 5065, 'value': 'Universitas Ichsan Gorontalo', 'label': 'Universitas Ichsan Gorontalo' },
    { 'id': 5066, 'value': 'Universitas Indo Global Mandiri', 'label': 'Universitas Indo Global Mandiri' },
    { 'id': 5067, 'value': 'Universitas Indonesia', 'label': 'Universitas Indonesia' },
    { 'id': 5068, 'value': 'Universitas Indonesia Timur', 'label': 'Universitas Indonesia Timur' },
    { 'id': 5069, 'value': 'Universitas Indraprasta PGRI', 'label': 'Universitas Indraprasta PGRI' },
    { 'id': 5070, 'value': 'Universitas Informatika Dan Bisnis Indonesia', 'label': 'Universitas Informatika Dan Bisnis Indonesia' },
    { 'id': 5071, 'value': 'Universitas Internasional Batam', 'label': 'Universitas Internasional Batam' },
    { 'id': 5072, 'value': 'Universitas Internasional Semen Indonesia', 'label': 'Universitas Internasional Semen Indonesia' },
    { 'id': 5073, 'value': 'Universitas Iqra Buru', 'label': 'Universitas Iqra Buru' },
    { 'id': 5074, 'value': 'Universitas Iskandarmuda', 'label': 'Universitas Iskandarmuda' },
    { 'id': 5075, 'value': 'Universitas Islam 45 Bekasi', 'label': 'Universitas Islam 45 Bekasi' },
    { 'id': 5076, 'value': 'Universitas Islam Al-azhar Mataram', 'label': 'Universitas Islam Al-azhar Mataram' },
    { 'id': 5077, 'value': 'Universitas Islam Al-Ihya Kuningan', 'label': 'Universitas Islam Al-Ihya Kuningan' },
    { 'id': 5078, 'value': 'Universitas Islam As-Syafiiyah', 'label': 'Universitas Islam As-Syafiiyah' },
    { 'id': 5079, 'value': 'Universitas Islam Attahiriyah', 'label': 'Universitas Islam Attahiriyah' },
    { 'id': 5080, 'value': 'Universitas Islam Balitar', 'label': 'Universitas Islam Balitar' },
    { 'id': 5081, 'value': 'Universitas Islam Bandung', 'label': 'Universitas Islam Bandung' },
    { 'id': 5082, 'value': 'Universitas Islam Batik', 'label': 'Universitas Islam Batik' },
    { 'id': 5083, 'value': 'Universitas Islam Darul Ulum Lamongan', 'label': 'Universitas Islam Darul Ulum Lamongan' },
    { 'id': 5084, 'value': 'Universitas Islam Indonesia', 'label': 'Universitas Islam Indonesia' },
    { 'id': 5085, 'value': 'Universitas Islam Indragiri', 'label': 'Universitas Islam Indragiri' },
    { 'id': 5086, 'value': 'Universitas Islam Jakarta', 'label': 'Universitas Islam Jakarta' },
    { 'id': 5087, 'value': 'Universitas Islam Jember', 'label': 'Universitas Islam Jember' },
    { 'id': 5088, 'value': 'Universitas Islam Kadiri', 'label': 'Universitas Islam Kadiri' },
    { 'id': 5089, 'value': 'Universitas Islam Kalimantan Muhammad Arsyad Al Banjari', 'label': 'Universitas Islam Kalimantan Muhammad Arsyad Al Banjari' },
    { 'id': 5090, 'value': 'Universitas Islam Kuantan Singingi', 'label': 'Universitas Islam Kuantan Singingi' },
    { 'id': 5091, 'value': 'Universitas Islam Labuhan Batu', 'label': 'Universitas Islam Labuhan Batu' },
    { 'id': 5092, 'value': 'Universitas Islam Lamongan', 'label': 'Universitas Islam Lamongan' },
    { 'id': 5093, 'value': 'Universitas Islam Madura Pamekasan', 'label': 'Universitas Islam Madura Pamekasan' },
    { 'id': 5094, 'value': 'Universitas Islam Majapahit', 'label': 'Universitas Islam Majapahit' },
    { 'id': 5095, 'value': 'Universitas Islam Makassar', 'label': 'Universitas Islam Makassar' },
    { 'id': 5096, 'value': 'Universitas Islam Malang', 'label': 'Universitas Islam Malang' },
    { 'id': 5097, 'value': 'Universitas Islam Nahdlatul Ulama', 'label': 'Universitas Islam Nahdlatul Ulama' },
    { 'id': 5098, 'value': 'Universitas Islam Negeri Alauddin Makassar', 'label': 'Universitas Islam Negeri Alauddin Makassar' },
    { 'id': 5099, 'value': 'Universitas Islam Negeri Antasari', 'label': 'Universitas Islam Negeri Antasari' },
    { 'id': 5100, 'value': 'Universitas Islam Negeri Ar-Raniry', 'label': 'Universitas Islam Negeri Ar-Raniry' },
    { 'id': 5101, 'value': 'Universitas Islam Negeri Imam Bonjol', 'label': 'Universitas Islam Negeri Imam Bonjol' },
    { 'id': 5102, 'value': 'Universitas Islam Negeri Mataram', 'label': 'Universitas Islam Negeri Mataram' },
    { 'id': 5103, 'value': 'Universitas Islam Negeri Maulana Malik Ibrahim Malang', 'label': 'Universitas Islam Negeri Maulana Malik Ibrahim Malang' },
    { 'id': 5104, 'value': 'Universitas Islam Negeri Raden Fatah', 'label': 'Universitas Islam Negeri Raden Fatah' },
    { 'id': 5105, 'value': 'Universitas Islam Negeri Raden Intan Lampung', 'label': 'Universitas Islam Negeri Raden Intan Lampung' },
    { 'id': 5106, 'value': 'Universitas Islam Negeri Sultan Maulana Hasanuddin Banten', 'label': 'Universitas Islam Negeri Sultan Maulana Hasanuddin Banten' },
    { 'id': 5107, 'value': 'Universitas Islam Negeri Sulthan Syarif Kasim Riau', 'label': 'Universitas Islam Negeri Sulthan Syarif Kasim Riau' },
    { 'id': 5108, 'value': 'Universitas Islam Negeri Sulthan Thaha Saifuddin Jambi', 'label': 'Universitas Islam Negeri Sulthan Thaha Saifuddin Jambi' },
    { 'id': 5109, 'value': 'Universitas Islam Negeri Sumatera Utara', 'label': 'Universitas Islam Negeri Sumatera Utara' },
    { 'id': 5110, 'value': 'Universitas Islam Negeri Sunan Ampel Surabaya', 'label': 'Universitas Islam Negeri Sunan Ampel Surabaya' },
    { 'id': 5111, 'value': 'Universitas Islam Negeri Sunan Gunung Djati', 'label': 'Universitas Islam Negeri Sunan Gunung Djati' },
    { 'id': 5112, 'value': 'Universitas Islam Negeri Sunan Kalijaga Yogyakarta', 'label': 'Universitas Islam Negeri Sunan Kalijaga Yogyakarta' },
    { 'id': 5113, 'value': 'Universitas Islam Negeri Syarif Hidayatullah Jakarta', 'label': 'Universitas Islam Negeri Syarif Hidayatullah Jakarta' },
    { 'id': 5114, 'value': 'Universitas Islam Negeri Walisongo', 'label': 'Universitas Islam Negeri Walisongo' },
    { 'id': 5115, 'value': 'Universitas Islam Nusantara', 'label': 'Universitas Islam Nusantara' },
    { 'id': 5116, 'value': 'Universitas Islam Ogan Komering Ilir Kayuagung', 'label': 'Universitas Islam Ogan Komering Ilir Kayuagung' },
    { 'id': 5117, 'value': 'Universitas Islam Raden Rahmat', 'label': 'Universitas Islam Raden Rahmat' },
    { 'id': 5118, 'value': 'Universitas Islam Riau', 'label': 'Universitas Islam Riau' },
    { 'id': 5119, 'value': 'Universitas Islam Sultan Agung', 'label': 'Universitas Islam Sultan Agung' },
    { 'id': 5120, 'value': 'Universitas Islam Sumatera Barat', 'label': 'Universitas Islam Sumatera Barat' },
    { 'id': 5121, 'value': 'Universitas Islam Sumatera Utara', 'label': 'Universitas Islam Sumatera Utara' },
    { 'id': 5122, 'value': 'Universitas Islam Syekh-Yusuf', 'label': 'Universitas Islam Syekh-Yusuf' },
    { 'id': 5123, 'value': 'Universitas Jabal Ghafur', 'label': 'Universitas Jabal Ghafur' },
    { 'id': 5124, 'value': 'Universitas Jakarta', 'label': 'Universitas Jakarta' },
    { 'id': 5125, 'value': 'Universitas Jambi', 'label': 'Universitas Jambi' },
    { 'id': 5126, 'value': 'Universitas Janabadra', 'label': 'Universitas Janabadra' },
    { 'id': 5127, 'value': 'Universitas Jayabaya', 'label': 'Universitas Jayabaya' },
    { 'id': 5128, 'value': 'Universitas Jember', 'label': 'Universitas Jember' },
    { 'id': 5129, 'value': 'Universitas Jenderal Achmad Yani', 'label': 'Universitas Jenderal Achmad Yani' },
    { 'id': 5130, 'value': 'Universitas Jenderal Soedirman', 'label': 'Universitas Jenderal Soedirman' },
    { 'id': 5131, 'value': 'Universitas Kader Bangsa', 'label': 'Universitas Kader Bangsa' },
    { 'id': 5132, 'value': 'Universitas Kadiri', 'label': 'Universitas Kadiri' },
    { 'id': 5133, 'value': 'Universitas Kahuripan Kediri', 'label': 'Universitas Kahuripan Kediri' },
    { 'id': 5134, 'value': 'Universitas Kaltara', 'label': 'Universitas Kaltara' },
    { 'id': 5135, 'value': 'Universitas Kanjuruhan Malang', 'label': 'Universitas Kanjuruhan Malang' },
    { 'id': 5136, 'value': 'Universitas Kapuas Sintang', 'label': 'Universitas Kapuas Sintang' },
    { 'id': 5137, 'value': 'Universitas Karimun', 'label': 'Universitas Karimun' },
    { 'id': 5138, 'value': 'Universitas Karo', 'label': 'Universitas Karo' },
    { 'id': 5139, 'value': 'Universitas Kartini', 'label': 'Universitas Kartini' },
    { 'id': 5140, 'value': 'Universitas Karya Dharma Makassar', 'label': 'Universitas Karya Dharma Makassar' },
    { 'id': 5141, 'value': 'Universitas Karyadarma Kupang', 'label': 'Universitas Karyadarma Kupang' },
    { 'id': 5142, 'value': 'Universitas Katolik Darma Cendika', 'label': 'Universitas Katolik Darma Cendika' },
    { 'id': 5143, 'value': 'Universitas Katolik De La Salle', 'label': 'Universitas Katolik De La Salle' },
    { 'id': 5144, 'value': 'Universitas Katolik Indonesia Atma Jaya', 'label': 'Universitas Katolik Indonesia Atma Jaya' },
    { 'id': 5145, 'value': 'Universitas Katolik Musi Charitas', 'label': 'Universitas Katolik Musi Charitas' },
    { 'id': 5146, 'value': 'Universitas Katolik Parahyangan', 'label': 'Universitas Katolik Parahyangan' },
    { 'id': 5147, 'value': 'Universitas Katolik Santo Thomas', 'label': 'Universitas Katolik Santo Thomas' },
    { 'id': 5148, 'value': 'Universitas Katolik Soegijapranata', 'label': 'Universitas Katolik Soegijapranata' },
    { 'id': 5149, 'value': 'Universitas Katolik Widya Karya', 'label': 'Universitas Katolik Widya Karya' },
    { 'id': 5150, 'value': 'Universitas Katolik Widya Mandala Madiun', 'label': 'Universitas Katolik Widya Mandala Madiun' },
    { 'id': 5151, 'value': 'Universitas Katolik Widya Mandala Surabaya', 'label': 'Universitas Katolik Widya Mandala Surabaya' },
    { 'id': 5152, 'value': 'Universitas Katolik Widya Mandira', 'label': 'Universitas Katolik Widya Mandira' },
    { 'id': 5153, 'value': 'Universitas Kebangsaan', 'label': 'Universitas Kebangsaan' },
    { 'id': 5154, 'value': 'Universitas Kejuangan 45 Jakarta', 'label': 'Universitas Kejuangan 45 Jakarta' },
    { 'id': 5155, 'value': 'Universitas KH. A. Wahab Hasbullah', 'label': 'Universitas KH. A. Wahab Hasbullah' },
    { 'id': 5156, 'value': 'Universitas Khairun', 'label': 'Universitas Khairun' },
    { 'id': 5157, 'value': 'Universitas Klabat', 'label': 'Universitas Klabat' },
    { 'id': 5158, 'value': 'Universitas Komputer Indonesia', 'label': 'Universitas Komputer Indonesia' },
    { 'id': 5159, 'value': 'Universitas Krisnadwipayana', 'label': 'Universitas Krisnadwipayana' },
    { 'id': 5160, 'value': 'Universitas Kristen Artha Wacana', 'label': 'Universitas Kristen Artha Wacana' },
    { 'id': 5161, 'value': 'Universitas Kristen Duta Wacana', 'label': 'Universitas Kristen Duta Wacana' },
    { 'id': 5162, 'value': 'Universitas Kristen Immanuel', 'label': 'Universitas Kristen Immanuel' },
    { 'id': 5163, 'value': 'Universitas Kristen Indonesia', 'label': 'Universitas Kristen Indonesia' },
    { 'id': 5164, 'value': 'Universitas Kristen Indonesia Maluku', 'label': 'Universitas Kristen Indonesia Maluku' },
    { 'id': 5165, 'value': 'Universitas Kristen Indonesia Paulus', 'label': 'Universitas Kristen Indonesia Paulus' },
    { 'id': 5166, 'value': 'Universitas Kristen Indonesia Tomohon', 'label': 'Universitas Kristen Indonesia Tomohon' },
    { 'id': 5167, 'value': 'Universitas Kristen Indonesia Toraja', 'label': 'Universitas Kristen Indonesia Toraja' },
    { 'id': 5168, 'value': 'Universitas Kristen Krida Wacana', 'label': 'Universitas Kristen Krida Wacana' },
    { 'id': 5169, 'value': 'Universitas Kristen Maranatha', 'label': 'Universitas Kristen Maranatha' },
    { 'id': 5170, 'value': 'Universitas Kristen Palangka Raya', 'label': 'Universitas Kristen Palangka Raya' },
    { 'id': 5171, 'value': 'Universitas Kristen Papua', 'label': 'Universitas Kristen Papua' },
    { 'id': 5172, 'value': 'Universitas Kristen Petra', 'label': 'Universitas Kristen Petra' },
    { 'id': 5173, 'value': 'Universitas Kristen Satya Wacana', 'label': 'Universitas Kristen Satya Wacana' },
    { 'id': 5174, 'value': 'Universitas Kristen Surakarta', 'label': 'Universitas Kristen Surakarta' },
    { 'id': 5175, 'value': 'Universitas Kristen Tentena', 'label': 'Universitas Kristen Tentena' },
    { 'id': 5176, 'value': 'Universitas Kristen Wira Wacana Sumba', 'label': 'Universitas Kristen Wira Wacana Sumba' },
    { 'id': 5177, 'value': 'Universitas Kuningan', 'label': 'Universitas Kuningan' },
    { 'id': 5178, 'value': 'Universitas Kutai Kartanegara', 'label': 'Universitas Kutai Kartanegara' },
    { 'id': 5179, 'value': 'Universitas Lakidende Unaaha', 'label': 'Universitas Lakidende Unaaha' },
    { 'id': 5180, 'value': 'Universitas Lambung Mangkurat', 'label': 'Universitas Lambung Mangkurat' },
    { 'id': 5181, 'value': 'Universitas Lampung', 'label': 'Universitas Lampung' },
    { 'id': 5182, 'value': 'Universitas Lancang Kuning', 'label': 'Universitas Lancang Kuning' },
    { 'id': 5183, 'value': 'Universitas Langlangbuana', 'label': 'Universitas Langlangbuana' },
    { 'id': 5184, 'value': 'Universitas Lintas Internasional Indonesia', 'label': 'Universitas Lintas Internasional Indonesia' },
    { 'id': 5185, 'value': 'Universitas Lumajang', 'label': 'Universitas Lumajang' },
    { 'id': 5186, 'value': 'Universitas Ma Chung', 'label': 'Universitas Ma Chung' },
    { 'id': 5188, 'value': 'Universitas Ma\'arif Nahdlatul Ulama Kebumen', 'label': 'Universitas Ma\'arif Nahdlatul Ulama Kebumen' },
    { 'id': 5187, 'value': 'Universitas Maarif Hasyim Latif', 'label': 'Universitas Maarif Hasyim Latif' },
    { 'id': 5189, 'value': 'Universitas Madako Toli-toli', 'label': 'Universitas Madako Toli-toli' },
    { 'id': 5190, 'value': 'Universitas Madura', 'label': 'Universitas Madura' },
    { 'id': 5191, 'value': 'Universitas Mahaputra Muhammad Yamin', 'label': 'Universitas Mahaputra Muhammad Yamin' },
    { 'id': 5192, 'value': 'Universitas Mahasaraswati Denpasar', 'label': 'Universitas Mahasaraswati Denpasar' },
    { 'id': 5193, 'value': 'Universitas Mahasaraswati Mataram', 'label': 'Universitas Mahasaraswati Mataram' },
    { 'id': 5194, 'value': 'Universitas Mahendradatta', 'label': 'Universitas Mahendradatta' },
    { 'id': 5195, 'value': 'Universitas Majalengka', 'label': 'Universitas Majalengka' },
    { 'id': 5196, 'value': 'Universitas Malahayati', 'label': 'Universitas Malahayati' },
    { 'id': 5197, 'value': 'Universitas Malikussaleh', 'label': 'Universitas Malikussaleh' },
    { 'id': 5198, 'value': 'Universitas Maritim Raja Ali Haji', 'label': 'Universitas Maritim Raja Ali Haji' },
    { 'id': 5199, 'value': 'Universitas Matana', 'label': 'Universitas Matana' },
    { 'id': 5200, 'value': 'Universitas Mataram', 'label': 'Universitas Mataram' },
    { 'id': 5201, 'value': 'Universitas Mathla\'ul Anwar', 'label': 'Universitas Mathla\'ul Anwar' },
    { 'id': 5202, 'value': 'Universitas Mayjen Sungkono', 'label': 'Universitas Mayjen Sungkono' },
    { 'id': 5203, 'value': 'Universitas Medan Area', 'label': 'Universitas Medan Area' },
    { 'id': 5204, 'value': 'Universitas Megou Pak Tulang Bawang', 'label': 'Universitas Megou Pak Tulang Bawang' },
    { 'id': 5205, 'value': 'Universitas Mercu Buana', 'label': 'Universitas Mercu Buana' },
    { 'id': 5206, 'value': 'Universitas Mercu Buana Yogyakarta', 'label': 'Universitas Mercu Buana Yogyakarta' },
    { 'id': 5207, 'value': 'Universitas Merdeka Madiun', 'label': 'Universitas Merdeka Madiun' },
    { 'id': 5208, 'value': 'Universitas Merdeka Malang', 'label': 'Universitas Merdeka Malang' },
    { 'id': 5209, 'value': 'Universitas Merdeka Pasuruan', 'label': 'Universitas Merdeka Pasuruan' },
    { 'id': 5210, 'value': 'Universitas Merdeka Ponorogo', 'label': 'Universitas Merdeka Ponorogo' },
    { 'id': 5211, 'value': 'Universitas Merdeka Surabaya', 'label': 'Universitas Merdeka Surabaya' },
    { 'id': 5212, 'value': 'Universitas Methodist Indonesia', 'label': 'Universitas Methodist Indonesia' },
    { 'id': 5213, 'value': 'Universitas Mochammad Sroedji', 'label': 'Universitas Mochammad Sroedji' },
    { 'id': 5214, 'value': 'Universitas Mohammad Husni Thamrin', 'label': 'Universitas Mohammad Husni Thamrin' },
    { 'id': 5215, 'value': 'Universitas Mohammad Natsir Bukittinggi', 'label': 'Universitas Mohammad Natsir Bukittinggi' },
    { 'id': 5216, 'value': 'Universitas Mpu Tantular', 'label': 'Universitas Mpu Tantular' },
    { 'id': 5217, 'value': 'Universitas Muara Bungo', 'label': 'Universitas Muara Bungo' },
    { 'id': 5218, 'value': 'Universitas Muhadi Setiabudi', 'label': 'Universitas Muhadi Setiabudi' },
    { 'id': 5219, 'value': 'Universitas Muhammadiyah Aceh', 'label': 'Universitas Muhammadiyah Aceh' },
    { 'id': 5220, 'value': 'Universitas Muhammadiyah Bandung', 'label': 'Universitas Muhammadiyah Bandung' },
    { 'id': 5221, 'value': 'Universitas Muhammadiyah Banjarmasin', 'label': 'Universitas Muhammadiyah Banjarmasin' },
    { 'id': 5222, 'value': 'Universitas Muhammadiyah Bengkulu', 'label': 'Universitas Muhammadiyah Bengkulu' },
    { 'id': 5223, 'value': 'Universitas Muhammadiyah Buton', 'label': 'Universitas Muhammadiyah Buton' },
    { 'id': 5224, 'value': 'Universitas Muhammadiyah Cirebon', 'label': 'Universitas Muhammadiyah Cirebon' },
    { 'id': 5225, 'value': 'Universitas Muhammadiyah Gorontalo', 'label': 'Universitas Muhammadiyah Gorontalo' },
    { 'id': 5226, 'value': 'Universitas Muhammadiyah Gresik', 'label': 'Universitas Muhammadiyah Gresik' },
    { 'id': 5227, 'value': 'Universitas Muhammadiyah Jakarta', 'label': 'Universitas Muhammadiyah Jakarta' },
    { 'id': 5228, 'value': 'Universitas Muhammadiyah Jember', 'label': 'Universitas Muhammadiyah Jember' },
    { 'id': 5229, 'value': 'Universitas Muhammadiyah Kendari', 'label': 'Universitas Muhammadiyah Kendari' },
    { 'id': 5230, 'value': 'Universitas Muhammadiyah Kudus', 'label': 'Universitas Muhammadiyah Kudus' },
    { 'id': 5231, 'value': 'Universitas Muhammadiyah Kupang', 'label': 'Universitas Muhammadiyah Kupang' },
    { 'id': 5232, 'value': 'Universitas Muhammadiyah Lampung', 'label': 'Universitas Muhammadiyah Lampung' },
    { 'id': 5233, 'value': 'Universitas Muhammadiyah Luwuk Banggai', 'label': 'Universitas Muhammadiyah Luwuk Banggai' },
    { 'id': 5234, 'value': 'Universitas Muhammadiyah Magelang', 'label': 'Universitas Muhammadiyah Magelang' },
    { 'id': 5235, 'value': 'Universitas Muhammadiyah Makassar', 'label': 'Universitas Muhammadiyah Makassar' },
    { 'id': 5236, 'value': 'Universitas Muhammadiyah Malang', 'label': 'Universitas Muhammadiyah Malang' },
    { 'id': 5237, 'value': 'Universitas Muhammadiyah Maluku Utara', 'label': 'Universitas Muhammadiyah Maluku Utara' },
    { 'id': 5238, 'value': 'Universitas Muhammadiyah Mataram', 'label': 'Universitas Muhammadiyah Mataram' },
    { 'id': 5239, 'value': 'Universitas Muhammadiyah Metro', 'label': 'Universitas Muhammadiyah Metro' },
    { 'id': 5240, 'value': 'Universitas Muhammadiyah Palangkaraya', 'label': 'Universitas Muhammadiyah Palangkaraya' },
    { 'id': 5241, 'value': 'Universitas Muhammadiyah Palembang', 'label': 'Universitas Muhammadiyah Palembang' },
    { 'id': 5242, 'value': 'Universitas Muhammadiyah Palu', 'label': 'Universitas Muhammadiyah Palu' },
    { 'id': 5243, 'value': 'Universitas Muhammadiyah Parepare', 'label': 'Universitas Muhammadiyah Parepare' },
    { 'id': 5244, 'value': 'Universitas Muhammadiyah Ponorogo', 'label': 'Universitas Muhammadiyah Ponorogo' },
    { 'id': 5245, 'value': 'Universitas Muhammadiyah Pontianak', 'label': 'Universitas Muhammadiyah Pontianak' },
    { 'id': 5246, 'value': 'Universitas Muhammadiyah Prof. Dr. Hamka', 'label': 'Universitas Muhammadiyah Prof. Dr. Hamka' },
    { 'id': 5247, 'value': 'Universitas Muhammadiyah Purwokerto', 'label': 'Universitas Muhammadiyah Purwokerto' },
    { 'id': 5248, 'value': 'Universitas Muhammadiyah Purworejo', 'label': 'Universitas Muhammadiyah Purworejo' },
    { 'id': 5249, 'value': 'Universitas Muhammadiyah Riau', 'label': 'Universitas Muhammadiyah Riau' },
    { 'id': 5250, 'value': 'Universitas Muhammadiyah Semarang', 'label': 'Universitas Muhammadiyah Semarang' },
    { 'id': 5251, 'value': 'Universitas Muhammadiyah Sidoarjo', 'label': 'Universitas Muhammadiyah Sidoarjo' },
    { 'id': 5252, 'value': 'Universitas Muhammadiyah Sorong', 'label': 'Universitas Muhammadiyah Sorong' },
    { 'id': 5253, 'value': 'Universitas Muhammadiyah Sukabumi', 'label': 'Universitas Muhammadiyah Sukabumi' },
    { 'id': 5254, 'value': 'Universitas Muhammadiyah Sumatera Barat', 'label': 'Universitas Muhammadiyah Sumatera Barat' },
    { 'id': 5255, 'value': 'Universitas Muhammadiyah Sumatera Utara', 'label': 'Universitas Muhammadiyah Sumatera Utara' },
    { 'id': 5256, 'value': 'Universitas Muhammadiyah Surabaya', 'label': 'Universitas Muhammadiyah Surabaya' },
    { 'id': 5257, 'value': 'Universitas Muhammadiyah Surakarta', 'label': 'Universitas Muhammadiyah Surakarta' },
    { 'id': 5258, 'value': 'Universitas Muhammadiyah Tangerang', 'label': 'Universitas Muhammadiyah Tangerang' },
    { 'id': 5259, 'value': 'Universitas Muhammadiyah Tapanuli Selatan', 'label': 'Universitas Muhammadiyah Tapanuli Selatan' },
    { 'id': 5260, 'value': 'Universitas Muhammadiyah Tasikmalaya', 'label': 'Universitas Muhammadiyah Tasikmalaya' },
    { 'id': 5261, 'value': 'Universitas Muhammadiyah Yogyakarta', 'label': 'Universitas Muhammadiyah Yogyakarta' },
    { 'id': 5262, 'value': 'Universitas Mulawarman', 'label': 'Universitas Mulawarman' },
    { 'id': 5263, 'value': 'Universitas Multimedia Nusantara', 'label': 'Universitas Multimedia Nusantara' },
    { 'id': 5264, 'value': 'Universitas Muria Kudus', 'label': 'Universitas Muria Kudus' },
    { 'id': 5265, 'value': 'Universitas Musamus Merauke', 'label': 'Universitas Musamus Merauke' },
    { 'id': 5266, 'value': 'Universitas Musi Rawas', 'label': 'Universitas Musi Rawas' },
    { 'id': 5267, 'value': 'Universitas Muslim Indonesia', 'label': 'Universitas Muslim Indonesia' },
    { 'id': 5268, 'value': 'Universitas Muslim Nusantara Al-Washliyah', 'label': 'Universitas Muslim Nusantara Al-Washliyah' },
    { 'id': 5269, 'value': 'Universitas Nahdlatul Ulama Al Ghazali', 'label': 'Universitas Nahdlatul Ulama Al Ghazali' },
    { 'id': 5270, 'value': 'Universitas Nahdlatul Ulama Blitar', 'label': 'Universitas Nahdlatul Ulama Blitar' },
    { 'id': 5271, 'value': 'Universitas Nahdlatul Ulama Cirebon', 'label': 'Universitas Nahdlatul Ulama Cirebon' },
    { 'id': 5272, 'value': 'Universitas Nahdlatul Ulama Indonesia', 'label': 'Universitas Nahdlatul Ulama Indonesia' },
    { 'id': 5273, 'value': 'Universitas Nahdlatul Ulama Kalimantan Barat', 'label': 'Universitas Nahdlatul Ulama Kalimantan Barat' },
    { 'id': 5274, 'value': 'Universitas Nahdlatul Ulama Kalimantan Selatan', 'label': 'Universitas Nahdlatul Ulama Kalimantan Selatan' },
    { 'id': 5275, 'value': 'Universitas Nahdlatul Ulama Kalimantan Timur', 'label': 'Universitas Nahdlatul Ulama Kalimantan Timur' },
    { 'id': 5276, 'value': 'Universitas Nahdlatul Ulama Lampung', 'label': 'Universitas Nahdlatul Ulama Lampung' },
    { 'id': 5277, 'value': 'Universitas Nahdlatul Ulama Nusa Tenggara Barat', 'label': 'Universitas Nahdlatul Ulama Nusa Tenggara Barat' },
    { 'id': 5278, 'value': 'Universitas Nahdlatul Ulama Purwokerto', 'label': 'Universitas Nahdlatul Ulama Purwokerto' },
    { 'id': 5279, 'value': 'Universitas Nahdlatul Ulama Sidoarjo', 'label': 'Universitas Nahdlatul Ulama Sidoarjo' },
    { 'id': 5280, 'value': 'Universitas Nahdlatul Ulama Sulawesi Tenggara', 'label': 'Universitas Nahdlatul Ulama Sulawesi Tenggara' },
    { 'id': 5281, 'value': 'Universitas Nahdlatul Ulama Sumatera Barat', 'label': 'Universitas Nahdlatul Ulama Sumatera Barat' },
    { 'id': 5282, 'value': 'Universitas Nahdlatul Ulama Sumatera Utara', 'label': 'Universitas Nahdlatul Ulama Sumatera Utara' },
    { 'id': 5283, 'value': 'Universitas Nahdlatul Ulama Sunan Giri', 'label': 'Universitas Nahdlatul Ulama Sunan Giri' },
    { 'id': 5284, 'value': 'Universitas Nahdlatul Ulama Surabaya', 'label': 'Universitas Nahdlatul Ulama Surabaya' },
    { 'id': 5285, 'value': 'Universitas Nahdlatul Ulama Surakarta', 'label': 'Universitas Nahdlatul Ulama Surakarta' },
    { 'id': 5286, 'value': 'Universitas Nahdlatul Ulama Yogyakarta', 'label': 'Universitas Nahdlatul Ulama Yogyakarta' },
    { 'id': 5287, 'value': 'Universitas Nahdlatul Wathan', 'label': 'Universitas Nahdlatul Wathan' },
    { 'id': 5288, 'value': 'Universitas Nani Bili Nusantara', 'label': 'Universitas Nani Bili Nusantara' },
    { 'id': 5289, 'value': 'Universitas Narotama', 'label': 'Universitas Narotama' },
    { 'id': 5290, 'value': 'Universitas Nasional', 'label': 'Universitas Nasional' },
    { 'id': 5291, 'value': 'Universitas Nasional Pasim', 'label': 'Universitas Nasional Pasim' },
    { 'id': 5292, 'value': 'Universitas Negeri Gorontalo', 'label': 'Universitas Negeri Gorontalo' },
    { 'id': 5293, 'value': 'Universitas Negeri Jakarta', 'label': 'Universitas Negeri Jakarta' },
    { 'id': 5294, 'value': 'Universitas Negeri Makassar', 'label': 'Universitas Negeri Makassar' },
    { 'id': 5295, 'value': 'Universitas Negeri Malang', 'label': 'Universitas Negeri Malang' },
    { 'id': 5296, 'value': 'Universitas Negeri Manado', 'label': 'Universitas Negeri Manado' },
    { 'id': 5297, 'value': 'Universitas Negeri Medan', 'label': 'Universitas Negeri Medan' },
    { 'id': 5298, 'value': 'Universitas Negeri Padang', 'label': 'Universitas Negeri Padang' },
    { 'id': 5299, 'value': 'Universitas Negeri Semarang', 'label': 'Universitas Negeri Semarang' },
    { 'id': 5300, 'value': 'Universitas Negeri Surabaya', 'label': 'Universitas Negeri Surabaya' },
    { 'id': 5301, 'value': 'Universitas Negeri Yogyakarta', 'label': 'Universitas Negeri Yogyakarta' },
    { 'id': 5302, 'value': 'Universitas Ngudi Waluyo', 'label': 'Universitas Ngudi Waluyo' },
    { 'id': 5303, 'value': 'Universitas Ngurah Rai', 'label': 'Universitas Ngurah Rai' },
    { 'id': 5304, 'value': 'Universitas Nuku', 'label': 'Universitas Nuku' },
    { 'id': 5305, 'value': 'Universitas Nurtanio', 'label': 'Universitas Nurtanio' },
    { 'id': 5306, 'value': 'Universitas Nurul Jadid', 'label': 'Universitas Nurul Jadid' },
    { 'id': 5307, 'value': 'Universitas Nusa Bangsa', 'label': 'Universitas Nusa Bangsa' },
    { 'id': 5308, 'value': 'Universitas Nusa Cendana', 'label': 'Universitas Nusa Cendana' },
    { 'id': 5309, 'value': 'Universitas Nusa Lontar Rote', 'label': 'Universitas Nusa Lontar Rote' },
    { 'id': 5310, 'value': 'Universitas Nusa Nipa', 'label': 'Universitas Nusa Nipa' },
    { 'id': 5311, 'value': 'Universitas Nusa Putra', 'label': 'Universitas Nusa Putra' },
    { 'id': 5312, 'value': 'Universitas Nusa Tenggara Barat', 'label': 'Universitas Nusa Tenggara Barat' },
    { 'id': 5313, 'value': 'Universitas Nusantara Manado', 'label': 'Universitas Nusantara Manado' },
    { 'id': 5314, 'value': 'Universitas Nusantara PGRI Kediric', 'label': 'Universitas Nusantara PGRI Kediric' },
    { 'id': 5315, 'value': 'Universitas Ottow Geissler', 'label': 'Universitas Ottow Geissler' },
    { 'id': 5316, 'value': 'Universitas Padjadjaran', 'label': 'Universitas Padjadjaran' },
    { 'id': 5317, 'value': 'Universitas Pahlawan Tuanku Tambusai', 'label': 'Universitas Pahlawan Tuanku Tambusai' },
    { 'id': 5318, 'value': 'Universitas Pakuan', 'label': 'Universitas Pakuan' },
    { 'id': 5319, 'value': 'Universitas Palangka Raya', 'label': 'Universitas Palangka Raya' },
    { 'id': 5320, 'value': 'Universitas Palembang', 'label': 'Universitas Palembang' },
    { 'id': 5321, 'value': 'Universitas Pamulang', 'label': 'Universitas Pamulang' },
    { 'id': 5322, 'value': 'Universitas Panca Bhakti', 'label': 'Universitas Panca Bhakti' },
    { 'id': 5323, 'value': 'Universitas Panca Marga', 'label': 'Universitas Panca Marga' },
    { 'id': 5324, 'value': 'Universitas Pancasakti', 'label': 'Universitas Pancasakti' },
    { 'id': 5325, 'value': 'Universitas Pancasakti Makassar', 'label': 'Universitas Pancasakti Makassar' },
    { 'id': 5326, 'value': 'Universitas Pancasila', 'label': 'Universitas Pancasila' },
    { 'id': 5327, 'value': 'Universitas Pandanaran', 'label': 'Universitas Pandanaran' },
    { 'id': 5328, 'value': 'Universitas Panji Sakti', 'label': 'Universitas Panji Sakti' },
    { 'id': 5329, 'value': 'Universitas Papua', 'label': 'Universitas Papua' },
    { 'id': 5330, 'value': 'Universitas Paramadina', 'label': 'Universitas Paramadina' },
    { 'id': 5331, 'value': 'Universitas Pasifik Morotai', 'label': 'Universitas Pasifik Morotai' },
    { 'id': 5332, 'value': 'Universitas Pasir Pengaraian', 'label': 'Universitas Pasir Pengaraian' },
    { 'id': 5333, 'value': 'Universitas Pasundan', 'label': 'Universitas Pasundan' },
    { 'id': 5334, 'value': 'Universitas Patria Artha', 'label': 'Universitas Patria Artha' },
    { 'id': 5335, 'value': 'Universitas Pattimura', 'label': 'Universitas Pattimura' },
    { 'id': 5336, 'value': 'Universitas Pawyatan Daha', 'label': 'Universitas Pawyatan Daha' },
    { 'id': 5337, 'value': 'Universitas Pejuang Republik Indonesia', 'label': 'Universitas Pejuang Republik Indonesia' },
    { 'id': 5338, 'value': 'Universitas Pekalongan', 'label': 'Universitas Pekalongan' },
    { 'id': 5339, 'value': 'Universitas Pelita Harapan', 'label': 'Universitas Pelita Harapan' },
    { 'id': 5340, 'value': 'Universitas Pembangunan Indonesia', 'label': 'Universitas Pembangunan Indonesia' },
    { 'id': 5341, 'value': 'Universitas Pembangunan Jaya', 'label': 'Universitas Pembangunan Jaya' },
    { 'id': 5342, 'value': 'Universitas Pembangunan Nasional Veteran Jakarta', 'label': 'Universitas Pembangunan Nasional Veteran Jakarta' },
    { 'id': 5343, 'value': 'Universitas Pembangunan Nasional Veteran Jawa Timur', 'label': 'Universitas Pembangunan Nasional Veteran Jawa Timur' },
    { 'id': 5344, 'value': 'Universitas Pembangunan Nasional Veteran Yogyakarta', 'label': 'Universitas Pembangunan Nasional Veteran Yogyakarta' },
    { 'id': 5345, 'value': 'Universitas Pembangunan Panca Budi', 'label': 'Universitas Pembangunan Panca Budi' },
    { 'id': 5346, 'value': 'Universitas Pembinaan Masyarakat Indonesia', 'label': 'Universitas Pembinaan Masyarakat Indonesia' },
    { 'id': 5347, 'value': 'Universitas Pendidikan Ganesha', 'label': 'Universitas Pendidikan Ganesha' },
    { 'id': 5348, 'value': 'Universitas Pendidikan Indonesia', 'label': 'Universitas Pendidikan Indonesia' },
    { 'id': 5349, 'value': 'Universitas Pendidikan Nasional', 'label': 'Universitas Pendidikan Nasional' },
    { 'id': 5350, 'value': 'Universitas Pepabri Makassar', 'label': 'Universitas Pepabri Makassar' },
    { 'id': 5351, 'value': 'Universitas Peradaban Bumiayu', 'label': 'Universitas Peradaban Bumiayu' },
    { 'id': 5352, 'value': 'Universitas Perjuangan Tasikmalaya', 'label': 'Universitas Perjuangan Tasikmalaya' },
    { 'id': 5353, 'value': 'Universitas Persada Indonesia YAI', 'label': 'Universitas Persada Indonesia YAI' },
    { 'id': 5354, 'value': 'Universitas Persatuan Guru 1945 NTT', 'label': 'Universitas Persatuan Guru 1945 NTT' },
    { 'id': 5355, 'value': 'Universitas Pertamina', 'label': 'Universitas Pertamina' },
    { 'id': 5356, 'value': 'Universitas Pesantren Tinggi Darul \'Ulum', 'label': 'Universitas Pesantren Tinggi Darul \'Ulum' },
    { 'id': 5357, 'value': 'Universitas PGRI Adi Buana', 'label': 'Universitas PGRI Adi Buana' },
    { 'id': 5358, 'value': 'Universitas PGRI Banyuwangi', 'label': 'Universitas PGRI Banyuwangi' },
    { 'id': 5359, 'value': 'Universitas PGRI Madiun', 'label': 'Universitas PGRI Madiun' },
    { 'id': 5360, 'value': 'Universitas PGRI Palangkaraya', 'label': 'Universitas PGRI Palangkaraya' },
    { 'id': 5361, 'value': 'Universitas PGRI Palembang', 'label': 'Universitas PGRI Palembang' },
    { 'id': 5362, 'value': 'Universitas PGRI Ronggolawe', 'label': 'Universitas PGRI Ronggolawe' },
    { 'id': 5363, 'value': 'Universitas PGRI Semarang', 'label': 'Universitas PGRI Semarang' },
    { 'id': 5364, 'value': 'Universitas PGRI Yogyakarta', 'label': 'Universitas PGRI Yogyakarta' },
    { 'id': 5365, 'value': 'Universitas Podomoro', 'label': 'Universitas Podomoro' },
    { 'id': 5366, 'value': 'Universitas Potensi Utama', 'label': 'Universitas Potensi Utama' },
    { 'id': 5367, 'value': 'Universitas Pramita Indonesia', 'label': 'Universitas Pramita Indonesia' },
    { 'id': 5368, 'value': 'Universitas Prasetiya Mulya', 'label': 'Universitas Prasetiya Mulya' },
    { 'id': 5369, 'value': 'Universitas Presiden', 'label': 'Universitas Presiden' },
    { 'id': 5370, 'value': 'Universitas Prima Indonesia', 'label': 'Universitas Prima Indonesia' },
    { 'id': 5371, 'value': 'Universitas Prisma', 'label': 'Universitas Prisma' },
    { 'id': 5372, 'value': 'Universitas Prof. DR. Hazairin SH.', 'label': 'Universitas Prof. DR. Hazairin SH.' },
    { 'id': 5373, 'value': 'Universitas Prof. Dr. Moestopo', 'label': 'Universitas Prof. Dr. Moestopo' },
    { 'id': 5374, 'value': 'Universitas Proklamasi 45', 'label': 'Universitas Proklamasi 45' },
    { 'id': 5375, 'value': 'Universitas Putera Batam', 'label': 'Universitas Putera Batam' },
    { 'id': 5376, 'value': 'Universitas Putra Indonesia', 'label': 'Universitas Putra Indonesia' },
    { 'id': 5377, 'value': 'Universitas Putra Indonesia YPTK Padang', 'label': 'Universitas Putra Indonesia YPTK Padang' },
    { 'id': 5378, 'value': 'Universitas Quality', 'label': 'Universitas Quality' },
    { 'id': 5379, 'value': 'Universitas Ratu Samban', 'label': 'Universitas Ratu Samban' },
    { 'id': 5380, 'value': 'Universitas Respati Indonesia', 'label': 'Universitas Respati Indonesia' },
    { 'id': 5381, 'value': 'Universitas Respati Yogyakarta', 'label': 'Universitas Respati Yogyakarta' },
    { 'id': 5382, 'value': 'Universitas Riau', 'label': 'Universitas Riau' },
    { 'id': 5383, 'value': 'Universitas Riau Kepulauan', 'label': 'Universitas Riau Kepulauan' },
    { 'id': 5384, 'value': 'Universitas Sahid', 'label': 'Universitas Sahid' },
    { 'id': 5385, 'value': 'Universitas Sahid Surakarta', 'label': 'Universitas Sahid Surakarta' },
    { 'id': 5386, 'value': 'Universitas Sains Al-Qur\'an', 'label': 'Universitas Sains Al-Qur\'an' },
    { 'id': 5387, 'value': 'Universitas Sains Cut Nyak Dhien', 'label': 'Universitas Sains Cut Nyak Dhien' },
    { 'id': 5388, 'value': 'Universitas Sains dan Teknologi Jayapura', 'label': 'Universitas Sains dan Teknologi Jayapura' },
    { 'id': 5389, 'value': 'Universitas Sam Ratulangi', 'label': 'Universitas Sam Ratulangi' },
    { 'id': 5390, 'value': 'Universitas Samawa', 'label': 'Universitas Samawa' },
    { 'id': 5391, 'value': 'Universitas Sampoerna', 'label': 'Universitas Sampoerna' },
    { 'id': 5392, 'value': 'Universitas Samudra Langsa', 'label': 'Universitas Samudra Langsa' },
    { 'id': 5393, 'value': 'Universitas San Pedro', 'label': 'Universitas San Pedro' },
    { 'id': 5394, 'value': 'Universitas Sanata Dharma', 'label': 'Universitas Sanata Dharma' },
    { 'id': 5395, 'value': 'Universitas Sang Bumi Ruwa Jurai', 'label': 'Universitas Sang Bumi Ruwa Jurai' },
    { 'id': 5396, 'value': 'Universitas Sangga Buana', 'label': 'Universitas Sangga Buana' },
    { 'id': 5397, 'value': 'Universitas Sari Mutiara Indonesia', 'label': 'Universitas Sari Mutiara Indonesia' },
    { 'id': 5398, 'value': 'Universitas Sari Putra Tomohon', 'label': 'Universitas Sari Putra Tomohon' },
    { 'id': 5399, 'value': 'Universitas Sarjanawiyata Tamansiswa', 'label': 'Universitas Sarjanawiyata Tamansiswa' },
    { 'id': 5400, 'value': 'Universitas Satya Negara Indonesia', 'label': 'Universitas Satya Negara Indonesia' },
    { 'id': 5401, 'value': 'Universitas Satya Wiyata Mandala', 'label': 'Universitas Satya Wiyata Mandala' },
    { 'id': 5402, 'value': 'Universitas Satyagama', 'label': 'Universitas Satyagama' },
    { 'id': 5403, 'value': 'Universitas Sawerigading Makassar', 'label': 'Universitas Sawerigading Makassar' },
    { 'id': 5404, 'value': 'Universitas Sebelas Maret', 'label': 'Universitas Sebelas Maret' },
    { 'id': 5405, 'value': 'Universitas Selamat Sri', 'label': 'Universitas Selamat Sri' },
    { 'id': 5406, 'value': 'Universitas Semarang', 'label': 'Universitas Semarang' },
    { 'id': 5407, 'value': 'Universitas Sembilanbelas November Kolaka', 'label': 'Universitas Sembilanbelas November Kolaka' },
    { 'id': 5408, 'value': 'Universitas Serambi Mekkah', 'label': 'Universitas Serambi Mekkah' },
    { 'id': 5409, 'value': 'Universitas Serang Raya', 'label': 'Universitas Serang Raya' },
    { 'id': 5410, 'value': 'Universitas Setia Budi Mandiri', 'label': 'Universitas Setia Budi Mandiri' },
    { 'id': 5411, 'value': 'Universitas Setia Budi Surakarta', 'label': 'Universitas Setia Budi Surakarta' },
    { 'id': 5412, 'value': 'Universitas Siliwangi', 'label': 'Universitas Siliwangi' },
    { 'id': 5413, 'value': 'Universitas Simalungun', 'label': 'Universitas Simalungun' },
    { 'id': 5414, 'value': 'Universitas Singaperbangsa Karawang', 'label': 'Universitas Singaperbangsa Karawang' },
    { 'id': 5415, 'value': 'Universitas Sintuwu Maroso', 'label': 'Universitas Sintuwu Maroso' },
    { 'id': 5416, 'value': 'Universitas Sisingamangaraja XII', 'label': 'Universitas Sisingamangaraja XII' },
    { 'id': 5417, 'value': 'Universitas Sisingamangaraja XII Tapanuli Utara', 'label': 'Universitas Sisingamangaraja XII Tapanuli Utara' },
    { 'id': 5418, 'value': 'Universitas Sjakhyakirti', 'label': 'Universitas Sjakhyakirti' },
    { 'id': 5419, 'value': 'Universitas Slamet Riyadi', 'label': 'Universitas Slamet Riyadi' },
    { 'id': 5420, 'value': 'Universitas Soerjo', 'label': 'Universitas Soerjo' },
    { 'id': 5421, 'value': 'Universitas Sriwijaya', 'label': 'Universitas Sriwijaya' },
    { 'id': 5422, 'value': 'Universitas Stikubank', 'label': 'Universitas Stikubank' },
    { 'id': 5423, 'value': 'Universitas Subang', 'label': 'Universitas Subang' },
    { 'id': 5424, 'value': 'Universitas Sulawesi Barat', 'label': 'Universitas Sulawesi Barat' },
    { 'id': 5425, 'value': 'Universitas Sulawesi Tenggara', 'label': 'Universitas Sulawesi Tenggara' },
    { 'id': 5426, 'value': 'Universitas Sultan Ageng Tirtayasa', 'label': 'Universitas Sultan Ageng Tirtayasa' },
    { 'id': 5427, 'value': 'Universitas Sultan Fatah', 'label': 'Universitas Sultan Fatah' },
    { 'id': 5428, 'value': 'Universitas Sumatera Utara', 'label': 'Universitas Sumatera Utara' },
    { 'id': 5429, 'value': 'Universitas Sunan Bonang', 'label': 'Universitas Sunan Bonang' },
    { 'id': 5430, 'value': 'Universitas Sunan Giri', 'label': 'Universitas Sunan Giri' },
    { 'id': 5431, 'value': 'Universitas Surabaya', 'label': 'Universitas Surabaya' },
    { 'id': 5432, 'value': 'Universitas Surakarta', 'label': 'Universitas Surakarta' },
    { 'id': 5433, 'value': 'Universitas Surapati', 'label': 'Universitas Surapati' },
    { 'id': 5434, 'value': 'Universitas Surya', 'label': 'Universitas Surya' },
    { 'id': 5435, 'value': 'Universitas Suryakancana', 'label': 'Universitas Suryakancana' },
    { 'id': 5436, 'value': 'Universitas Sutomo', 'label': 'Universitas Sutomo' },
    { 'id': 5437, 'value': 'Universitas Swadaya Gunung Jati', 'label': 'Universitas Swadaya Gunung Jati' },
    { 'id': 5438, 'value': 'Universitas Swiss German', 'label': 'Universitas Swiss German' },
    { 'id': 5439, 'value': 'Universitas Syiah Kuala', 'label': 'Universitas Syiah Kuala' },
    { 'id': 5440, 'value': 'Universitas Tabanan', 'label': 'Universitas Tabanan' },
    { 'id': 5441, 'value': 'Universitas Tadulako', 'label': 'Universitas Tadulako' },
    { 'id': 5442, 'value': 'Universitas Tama Jagakarsa', 'label': 'Universitas Tama Jagakarsa' },
    { 'id': 5443, 'value': 'Universitas Tamansiswa', 'label': 'Universitas Tamansiswa' },
    { 'id': 5444, 'value': 'Universitas Tamansiswa Palembang', 'label': 'Universitas Tamansiswa Palembang' },
    { 'id': 5445, 'value': 'Universitas Tanjungpura', 'label': 'Universitas Tanjungpura' },
    { 'id': 5446, 'value': 'Universitas Tanri Abeng', 'label': 'Universitas Tanri Abeng' },
    { 'id': 5447, 'value': 'Universitas Tarumanagara', 'label': 'Universitas Tarumanagara' },
    { 'id': 5448, 'value': 'Universitas Teknologi Indonesia', 'label': 'Universitas Teknologi Indonesia' },
    { 'id': 5449, 'value': 'Universitas Teknologi Nusantara', 'label': 'Universitas Teknologi Nusantara' },
    { 'id': 5450, 'value': 'Universitas Teknologi Sulawesi', 'label': 'Universitas Teknologi Sulawesi' },
    { 'id': 5451, 'value': 'Universitas Teknologi Sulawesi Utara', 'label': 'Universitas Teknologi Sulawesi Utara' },
    { 'id': 5452, 'value': 'Universitas Teknologi Sumbawa', 'label': 'Universitas Teknologi Sumbawa' },
    { 'id': 5453, 'value': 'Universitas Teknologi Surabaya', 'label': 'Universitas Teknologi Surabaya' },
    { 'id': 5454, 'value': 'Universitas Teknologi Yogyakarta', 'label': 'Universitas Teknologi Yogyakarta' },
    { 'id': 5455, 'value': 'Universitas Telkom', 'label': 'Universitas Telkom' },
    { 'id': 5456, 'value': 'Universitas Teuku Umar', 'label': 'Universitas Teuku Umar' },
    { 'id': 5457, 'value': 'Universitas Tidar Magelang', 'label': 'Universitas Tidar Magelang' },
    { 'id': 5458, 'value': 'Universitas Timbul Nusantara', 'label': 'Universitas Timbul Nusantara' },
    { 'id': 5459, 'value': 'Universitas Timika', 'label': 'Universitas Timika' },
    { 'id': 5460, 'value': 'Universitas Timor', 'label': 'Universitas Timor' },
    { 'id': 5461, 'value': 'Universitas Tjut Nyak Dhien', 'label': 'Universitas Tjut Nyak Dhien' },
    { 'id': 5462, 'value': 'Universitas Tomakaka', 'label': 'Universitas Tomakaka' },
    { 'id': 5463, 'value': 'Universitas Tompotika', 'label': 'Universitas Tompotika' },
    { 'id': 5464, 'value': 'Universitas Tribhuwana Tungga Dewi', 'label': 'Universitas Tribhuwana Tungga Dewi' },
    { 'id': 5465, 'value': 'Universitas Tribuana Kalabahi', 'label': 'Universitas Tribuana Kalabahi' },
    { 'id': 5466, 'value': 'Universitas Tridharma Balikpapan', 'label': 'Universitas Tridharma Balikpapan' },
    { 'id': 5467, 'value': 'Universitas Tridinanti Palembang', 'label': 'Universitas Tridinanti Palembang' },
    { 'id': 5468, 'value': 'Universitas Trilogi', 'label': 'Universitas Trilogi' },
    { 'id': 5469, 'value': 'Universitas Trisakti', 'label': 'Universitas Trisakti' },
    { 'id': 5470, 'value': 'Universitas Trunajaya', 'label': 'Universitas Trunajaya' },
    { 'id': 5471, 'value': 'Universitas Trunojoyo Madura', 'label': 'Universitas Trunojoyo Madura' },
    { 'id': 5472, 'value': 'Universitas Tulang Bawang', 'label': 'Universitas Tulang Bawang' },
    { 'id': 5473, 'value': 'Universitas Tulungagung', 'label': 'Universitas Tulungagung' },
    { 'id': 5474, 'value': 'Universitas Tunas Pembangunan', 'label': 'Universitas Tunas Pembangunan' },
    { 'id': 5475, 'value': 'Universitas Ubudiyah Indonesia', 'label': 'Universitas Ubudiyah Indonesia' },
    { 'id': 5476, 'value': 'Universitas Udayana', 'label': 'Universitas Udayana' },
    { 'id': 5477, 'value': 'Universitas Universal', 'label': 'Universitas Universal' },
    { 'id': 5478, 'value': 'Universitas Veteran Bangun Nusantara', 'label': 'Universitas Veteran Bangun Nusantara' },
    { 'id': 5479, 'value': 'Universitas Victory Sorong', 'label': 'Universitas Victory Sorong' },
    { 'id': 5480, 'value': 'Universitas Wahid Hasyim', 'label': 'Universitas Wahid Hasyim' },
    { 'id': 5481, 'value': 'Universitas Wahidiyah', 'label': 'Universitas Wahidiyah' },
    { 'id': 5482, 'value': 'Universitas Wanita Internasional', 'label': 'Universitas Wanita Internasional' },
    { 'id': 5483, 'value': 'Universitas Warmadewa', 'label': 'Universitas Warmadewa' },
    { 'id': 5484, 'value': 'Universitas Widya Dharma', 'label': 'Universitas Widya Dharma' },
    { 'id': 5485, 'value': 'Universitas Widya Gama', 'label': 'Universitas Widya Gama' },
    { 'id': 5486, 'value': 'Universitas Widya Gama Mahakam Samarinda', 'label': 'Universitas Widya Gama Mahakam Samarinda' },
    { 'id': 5487, 'value': 'Universitas Widya Kartika', 'label': 'Universitas Widya Kartika' },
    { 'id': 5488, 'value': 'Universitas Widya Mataram', 'label': 'Universitas Widya Mataram' },
    { 'id': 5489, 'value': 'Universitas Widyatama', 'label': 'Universitas Widyatama' },
    { 'id': 5490, 'value': 'Universitas Wijaya Kusuma Purwokerto', 'label': 'Universitas Wijaya Kusuma Purwokerto' },
    { 'id': 5491, 'value': 'Universitas Wijaya Kusuma Surabaya', 'label': 'Universitas Wijaya Kusuma Surabaya' },
    { 'id': 5492, 'value': 'Universitas Wijaya Putra', 'label': 'Universitas Wijaya Putra' },
    { 'id': 5493, 'value': 'Universitas Winaya Mukti', 'label': 'Universitas Winaya Mukti' },
    { 'id': 5494, 'value': 'Universitas Wiralodra', 'label': 'Universitas Wiralodra' },
    { 'id': 5495, 'value': 'Universitas Wiraraja', 'label': 'Universitas Wiraraja' },
    { 'id': 5496, 'value': 'Universitas Wiraswasta Indonesia', 'label': 'Universitas Wiraswasta Indonesia' },
    { 'id': 5497, 'value': 'Universitas Wisnuwardhana', 'label': 'Universitas Wisnuwardhana' },
    { 'id': 5498, 'value': 'Universitas WR Supratman', 'label': 'Universitas WR Supratman' },
    { 'id': 5499, 'value': 'Universitas Yapis Papua', 'label': 'Universitas Yapis Papua' },
    { 'id': 5500, 'value': 'Universitas YARSI', 'label': 'Universitas YARSI' },
    { 'id': 5501, 'value': 'Universitas Yos Soedarso', 'label': 'Universitas Yos Soedarso' },
    { 'id': 5502, 'value': 'Universitas Yudharta Pasuruan', 'label': 'Universitas Yudharta Pasuruan' },
    { 'id': 3773, 'value': 'Universität Augsburg', 'label': 'Universität Augsburg' },
    { 'id': 4161, 'value': 'Universitat Autónoma de Barcelona', 'label': 'Universitat Autónoma de Barcelona' },
    { 'id': 2008, 'value': 'Universität Basel', 'label': 'Universität Basel' },
    { 'id': 3774, 'value': 'Universität Bayreuth', 'label': 'Universität Bayreuth' },
    { 'id': 2009, 'value': 'Universität Bern', 'label': 'Universität Bern' },
    { 'id': 3775, 'value': 'Universität Bielefeld', 'label': 'Universität Bielefeld' },
    { 'id': 3776, 'value': 'Universität Bremen', 'label': 'Universität Bremen' },
    { 'id': 614, 'value': 'Universitat d\'Andorra', 'label': 'Universitat d\'Andorra' },
    { 'id': 4162, 'value': 'Universitat de Barcelona', 'label': 'Universitat de Barcelona' },
    { 'id': 4163, 'value': 'Universitat de Girona', 'label': 'Universitat de Girona' },
    { 'id': 4164, 'value': 'Universitat de Lleida', 'label': 'Universitat de Lleida' },
    { 'id': 4165, 'value': 'Universitat de València', 'label': 'Universitat de València' },
    { 'id': 4166, 'value': 'Universitat de Vic - Universitat Central de Catalunya', 'label': 'Universitat de Vic - Universitat Central de Catalunya' },
    { 'id': 3777, 'value': 'Universität der Bundeswehr München', 'label': 'Universität der Bundeswehr München' },
    { 'id': 3778, 'value': 'Universität der Künste Berlin', 'label': 'Universität der Künste Berlin' },
    { 'id': 3779, 'value': 'Universität des Saarlandes', 'label': 'Universität des Saarlandes' },
    { 'id': 3780, 'value': 'Universität Duisburg-Essen', 'label': 'Universität Duisburg-Essen' },
    { 'id': 3781, 'value': 'Universität Erfurt', 'label': 'Universität Erfurt' },
    { 'id': 1060, 'value': 'Universität für angewandte Kunst Wien', 'label': 'Universität für angewandte Kunst Wien' },
    { 'id': 1061, 'value': 'Universität für Bodenkultur Wien', 'label': 'Universität für Bodenkultur Wien' },
    { 'id': 1062, 'value': 'Universität für Künstlerische und Industrielle Gestaltung Linz', 'label': 'Universität für Künstlerische und Industrielle Gestaltung Linz' },
    { 'id': 1063, 'value': 'Universität für Musik und darstellende Kunst Graz', 'label': 'Universität für Musik und darstellende Kunst Graz' },
    { 'id': 1064, 'value': 'Universität für Musik und darstellende Kunst Wien', 'label': 'Universität für Musik und darstellende Kunst Wien' },
    { 'id': 3782, 'value': 'Universität Hamburg', 'label': 'Universität Hamburg' },
    { 'id': 3783, 'value': 'Universität Hildesheim', 'label': 'Universität Hildesheim' },
    { 'id': 3784, 'value': 'Universität Hohenheim', 'label': 'Universität Hohenheim' },
    { 'id': 346, 'value': 'Universität Innsbruck', 'label': 'Universität Innsbruck' },
    { 'id': 4167, 'value': 'Universitat Internacional de Catalunya', 'label': 'Universitat Internacional de Catalunya' },
    { 'id': 490, 'value': 'Universitat International de Catalunya', 'label': 'Universitat International de Catalunya' },
    { 'id': 4168, 'value': 'Universitat Jaume I', 'label': 'Universitat Jaume I' },
    { 'id': 3785, 'value': 'Universität Kassel', 'label': 'Universität Kassel' },
    { 'id': 3786, 'value': 'Universität Koblenz-Landau', 'label': 'Universität Koblenz-Landau' },
    { 'id': 3787, 'value': 'Universität Konstanz', 'label': 'Universität Konstanz' },
    { 'id': 3788, 'value': 'Universität Leipzig', 'label': 'Universität Leipzig' },
    { 'id': 8309, 'value': 'Universität Liechtenstein', 'label': 'Universität Liechtenstein' },
    { 'id': 2010, 'value': 'Universität Luzern', 'label': 'Universität Luzern' },
    { 'id': 3789, 'value': 'Universität Mannheim', 'label': 'Universität Mannheim' },
    { 'id': 1065, 'value': 'Universität Mozarteum Salzburg', 'label': 'Universität Mozarteum Salzburg' },
    { 'id': 3790, 'value': 'Universität Osnabrück', 'label': 'Universität Osnabrück' },
    { 'id': 3791, 'value': 'Universität Paderborn', 'label': 'Universität Paderborn' },
    { 'id': 3792, 'value': 'Universität Passau', 'label': 'Universität Passau' },
    { 'id': 4169, 'value': 'Universitat Politècnica de Catalunya', 'label': 'Universitat Politècnica de Catalunya' },
    { 'id': 4170, 'value': 'Universitat Pompeu Fabra', 'label': 'Universitat Pompeu Fabra' },
    { 'id': 152, 'value': 'Universitat Pompeu Fabra Barcelona', 'label': 'Universitat Pompeu Fabra Barcelona' },
    { 'id': 3793, 'value': 'Universität Potsdam', 'label': 'Universität Potsdam' },
    { 'id': 4171, 'value': 'Universitat Ramon Llull', 'label': 'Universitat Ramon Llull' },
    { 'id': 3794, 'value': 'Universität Regensburg', 'label': 'Universität Regensburg' },
    { 'id': 3795, 'value': 'Universität Rostock', 'label': 'Universität Rostock' },
    { 'id': 4172, 'value': 'Universitat Rovira I Virgili', 'label': 'Universitat Rovira I Virgili' },
    { 'id': 1066, 'value': 'Universität Salzburg', 'label': 'Universität Salzburg' },
    { 'id': 3796, 'value': 'Universität Siegen', 'label': 'Universität Siegen' },
    { 'id': 2011, 'value': 'Universität St.Gallen', 'label': 'Universität St.Gallen' },
    { 'id': 3797, 'value': 'Universität Stuttgart', 'label': 'Universität Stuttgart' },
    { 'id': 3798, 'value': 'Universität Trier', 'label': 'Universität Trier' },
    { 'id': 3799, 'value': 'Universität Ulm', 'label': 'Universität Ulm' },
    { 'id': 3800, 'value': 'Universität Vechta', 'label': 'Universität Vechta' },
    { 'id': 1067, 'value': 'Universität Wien', 'label': 'Universität Wien' },
    { 'id': 3801, 'value': 'Universität Witten/Herdecke', 'label': 'Universität Witten/Herdecke' },
    { 'id': 3802, 'value': 'Universität zu Köln', 'label': 'Universität zu Köln' },
    { 'id': 3803, 'value': 'Universität zu Lübeck', 'label': 'Universität zu Lübeck' },
    { 'id': 2012, 'value': 'Universität Zürich', 'label': 'Universität Zürich' },
    { 'id': 8449, 'value': 'Universitatea "Perspectiva - INT"', 'label': 'Universitatea "Perspectiva - INT"' },
    { 'id': 10523, 'value': 'Universitatea 1 Decembrie 1918 din Alba Iulia', 'label': 'Universitatea 1 Decembrie 1918 din Alba Iulia' },
    { 'id': 8450, 'value': 'Universitatea Academiei de Stiinte a Moldovei', 'label': 'Universitatea Academiei de Stiinte a Moldovei' },
    { 'id': 10524, 'value': 'Universitatea Agora din Oradea', 'label': 'Universitatea Agora din Oradea' },
    { 'id': 8451, 'value': 'Universitatea Agrara de Stat din Moldova', 'label': 'Universitatea Agrara de Stat din Moldova' },
    { 'id': 10525, 'value': 'Universitatea Alexandru Ioan Cuza din Iasi', 'label': 'Universitatea Alexandru Ioan Cuza din Iasi' },
    { 'id': 10526, 'value': 'Universitatea Alma Mater Sibiu', 'label': 'Universitatea Alma Mater Sibiu' },
    { 'id': 10527, 'value': 'Universitatea Andrei Saguna', 'label': 'Universitatea Andrei Saguna' },
    { 'id': 10528, 'value': 'Universitatea Apollonia din Iasi', 'label': 'Universitatea Apollonia din Iasi' },
    { 'id': 10529, 'value': 'Universitatea Artifex din Bucuresti', 'label': 'Universitatea Artifex din Bucuresti' },
    { 'id': 10530, 'value': 'Universitatea Athenaeum din Bucuresti', 'label': 'Universitatea Athenaeum din Bucuresti' },
    { 'id': 10531, 'value': 'Universitatea Aurel Vlaicu din Arad', 'label': 'Universitatea Aurel Vlaicu din Arad' },
    { 'id': 10532, 'value': 'Universitatea Avram Iancu din Cluj-Napoca', 'label': 'Universitatea Avram Iancu din Cluj-Napoca' },
    { 'id': 10533, 'value': 'Universitatea Babes-Bolyai', 'label': 'Universitatea Babes-Bolyai' },
    { 'id': 10534, 'value': 'Universitatea Bioterra din Bucuresti', 'label': 'Universitatea Bioterra din Bucuresti' },
    { 'id': 10535, 'value': 'Universitatea Bogdan Voda din Cluj-Napoca', 'label': 'Universitatea Bogdan Voda din Cluj-Napoca' },
    { 'id': 10536, 'value': 'Universitatea Constantin Brâncoveanu', 'label': 'Universitatea Constantin Brâncoveanu' },
    { 'id': 10537, 'value': 'Universitatea Constantin Brâncusi', 'label': 'Universitatea Constantin Brâncusi' },
    { 'id': 8452, 'value': 'Universitatea Cooperatist-Comerciala din Moldova', 'label': 'Universitatea Cooperatist-Comerciala din Moldova' },
    { 'id': 10538, 'value': 'Universitatea Crestina Dimitrie Cantemir', 'label': 'Universitatea Crestina Dimitrie Cantemir' },
    { 'id': 10539, 'value': 'Universitatea Crestina Partium', 'label': 'Universitatea Crestina Partium' },
    { 'id': 10540, 'value': 'Universitatea Danubius Galati', 'label': 'Universitatea Danubius Galati' },
    { 'id': 10541, 'value': 'Universitatea de Arhitectura si Urbanism Ion Mincu din Bucuresti', 'label': 'Universitatea de Arhitectura si Urbanism Ion Mincu din Bucuresti' },
    { 'id': 10542, 'value': 'Universitatea de Arta si Design din Cluj-Napoca', 'label': 'Universitatea de Arta si Design din Cluj-Napoca' },
    { 'id': 10543, 'value': 'Universitatea de Arte din Târgu Mures', 'label': 'Universitatea de Arte din Târgu Mures' },
    { 'id': 10544, 'value': 'Universitatea de Arte George Enescu Iasi', 'label': 'Universitatea de Arte George Enescu Iasi' },
    { 'id': 496, 'value': 'Universitatea de Medicina si Farmacie "Victor Babes" Timisoara', 'label': 'Universitatea de Medicina si Farmacie "Victor Babes" Timisoara' },
    { 'id': 592, 'value': 'Universitatea de Medicină și Farmacie „Iuliu Hațieganu” din Cluj-Napoca', 'label': 'Universitatea de Medicină și Farmacie „Iuliu Hațieganu” din Cluj-Napoca' },
    { 'id': 10545, 'value': 'Universitatea de Medicina si Farmacie Carol Davila din Bucuresti', 'label': 'Universitatea de Medicina si Farmacie Carol Davila din Bucuresti' },
    { 'id': 10546, 'value': 'Universitatea de Medicina si Farmacie din Craiova', 'label': 'Universitatea de Medicina si Farmacie din Craiova' },
    { 'id': 10547, 'value': 'Universitatea de Medicina si Farmacie Gr. T. Popa', 'label': 'Universitatea de Medicina si Farmacie Gr. T. Popa' },
    { 'id': 10548, 'value': 'Universitatea de Medicina si Farmacie Iuliu Hatieganu', 'label': 'Universitatea de Medicina si Farmacie Iuliu Hatieganu' },
    { 'id': 10549, 'value': 'Universitatea de Medicina si Farmacie Victor Babes din Timisoara', 'label': 'Universitatea de Medicina si Farmacie Victor Babes din Timisoara' },
    { 'id': 10550, 'value': 'Universitatea de Medicina, Farmacie, stiinte si Tehnologie din Târgu Mures', 'label': 'Universitatea de Medicina, Farmacie, stiinte si Tehnologie din Târgu Mures' },
    { 'id': 8453, 'value': 'Universitatea de Stat A. Russo', 'label': 'Universitatea de Stat A. Russo' },
    { 'id': 8454, 'value': 'Universitatea de Stat Bogdan Petriceicu Hasdeu', 'label': 'Universitatea de Stat Bogdan Petriceicu Hasdeu' },
    { 'id': 8455, 'value': 'Universitatea de Stat de Educatie Fizica si Sport', 'label': 'Universitatea de Stat de Educatie Fizica si Sport' },
    { 'id': 8456, 'value': 'Universitatea de Stat de Medicina si Farmacie', 'label': 'Universitatea de Stat de Medicina si Farmacie' },
    { 'id': 8457, 'value': 'Universitatea de Stat din Comrat', 'label': 'Universitatea de Stat din Comrat' },
    { 'id': 8458, 'value': 'Universitatea de Stat din Moldova', 'label': 'Universitatea de Stat din Moldova' },
    { 'id': 8459, 'value': 'Universitatea de Stat din Taraclia', 'label': 'Universitatea de Stat din Taraclia' },
    { 'id': 8460, 'value': 'Universitatea de Stat din Tiraspol', 'label': 'Universitatea de Stat din Tiraspol' },
    { 'id': 10551, 'value': 'Universitatea de stiinte Agricole si Medicina Veterinara a Banatului Timisoara', 'label': 'Universitatea de stiinte Agricole si Medicina Veterinara a Banatului Timisoara' },
    { 'id': 10552, 'value': 'Universitatea de stiinte Agricole si Medicina Veterinara din Cluj-Napoca', 'label': 'Universitatea de stiinte Agricole si Medicina Veterinara din Cluj-Napoca' },
    { 'id': 10553, 'value': 'Universitatea de stiinte Agricole si Medicina Veterinara din Iasi', 'label': 'Universitatea de stiinte Agricole si Medicina Veterinara din Iasi' },
    { 'id': 10554, 'value': 'Universitatea de stiinte Agronomice si Medicina Veterinara din Bucuresti', 'label': 'Universitatea de stiinte Agronomice si Medicina Veterinara din Bucuresti' },
    { 'id': 8461, 'value': 'Universitatea de Studii Aplicate din Moldova', 'label': 'Universitatea de Studii Aplicate din Moldova' },
    { 'id': 8462, 'value': 'Universitatea de Studii Europene din Moldova', 'label': 'Universitatea de Studii Europene din Moldova' },
    { 'id': 8463, 'value': 'Universitatea de Studii Politice si Economice Europene', 'label': 'Universitatea de Studii Politice si Economice Europene' },
    { 'id': 10555, 'value': 'Universitatea de Vest din Timisoara', 'label': 'Universitatea de Vest din Timisoara' },
    { 'id': 10556, 'value': 'Universitatea de Vest Vasile Goldis din Arad', 'label': 'Universitatea de Vest Vasile Goldis din Arad' },
    { 'id': 10557, 'value': 'Universitatea Dimitrie Cantemir', 'label': 'Universitatea Dimitrie Cantemir' },
    { 'id': 10558, 'value': 'Universitatea din Bucuresti', 'label': 'Universitatea din Bucuresti' },
    { 'id': 10559, 'value': 'Universitatea din Craiova', 'label': 'Universitatea din Craiova' },
    { 'id': 10560, 'value': 'Universitatea din Oradea', 'label': 'Universitatea din Oradea' },
    { 'id': 10561, 'value': 'Universitatea din Petrosani', 'label': 'Universitatea din Petrosani' },
    { 'id': 10562, 'value': 'Universitatea din Pitesti', 'label': 'Universitatea din Pitesti' },
    { 'id': 10563, 'value': 'Universitatea Dunarea de Jos din Galati', 'label': 'Universitatea Dunarea de Jos din Galati' },
    { 'id': 10564, 'value': 'Universitatea Ecologica din Bucuresti', 'label': 'Universitatea Ecologica din Bucuresti' },
    { 'id': 10565, 'value': 'Universitatea Eftimie Murgu din Resita', 'label': 'Universitatea Eftimie Murgu din Resita' },
    { 'id': 10566, 'value': 'Universitatea Emanuel din Oradea', 'label': 'Universitatea Emanuel din Oradea' },
    { 'id': 10567, 'value': 'Universitatea Europeana "Dragan" din Lugoj', 'label': 'Universitatea Europeana "Dragan" din Lugoj' },
    { 'id': 10568, 'value': 'Universitatea George Bacovia Bacau', 'label': 'Universitatea George Bacovia Bacau' },
    { 'id': 10569, 'value': 'Universitatea HYPERION', 'label': 'Universitatea HYPERION' },
    { 'id': 8464, 'value': 'Universitatea Libera Internationala din Moldova', 'label': 'Universitatea Libera Internationala din Moldova' },
    { 'id': 10570, 'value': 'Universitatea Lucian Blaga din Sibiu', 'label': 'Universitatea Lucian Blaga din Sibiu' },
    { 'id': 10571, 'value': 'Universitatea Maritima din Constanta', 'label': 'Universitatea Maritima din Constanta' },
    { 'id': 10572, 'value': 'Universitatea Mihail Kogalniceanu', 'label': 'Universitatea Mihail Kogalniceanu' },
    { 'id': 10573, 'value': 'Universitatea Nationala de Arta Teatrala si Cinematografica Ion Luca Caragiale', 'label': 'Universitatea Nationala de Arta Teatrala si Cinematografica Ion Luca Caragiale' },
    { 'id': 10574, 'value': 'Universitatea Nationala de Arte din Bucuresti', 'label': 'Universitatea Nationala de Arte din Bucuresti' },
    { 'id': 10575, 'value': 'Universitatea Nationala de Educatie Fizica si Sport', 'label': 'Universitatea Nationala de Educatie Fizica si Sport' },
    { 'id': 10576, 'value': 'Universitatea Nationala de Muzica din Bucuresti', 'label': 'Universitatea Nationala de Muzica din Bucuresti' },
    { 'id': 10577, 'value': 'Universitatea Nicolae Titulescu din Bucuresti', 'label': 'Universitatea Nicolae Titulescu din Bucuresti' },
    { 'id': 10578, 'value': 'Universitatea Ovidius Constanta', 'label': 'Universitatea Ovidius Constanta' },
    { 'id': 8465, 'value': 'Universitatea Pedagogica de Stat Ion Creanga', 'label': 'Universitatea Pedagogica de Stat Ion Creanga' },
    { 'id': 10579, 'value': 'Universitatea Petre Andrei din Iasi', 'label': 'Universitatea Petre Andrei din Iasi' },
    { 'id': 10580, 'value': 'Universitatea Petrol-Gaze din Ploiesti', 'label': 'Universitatea Petrol-Gaze din Ploiesti' },
    { 'id': 10581, 'value': 'Universitatea Politehnica din Bucuresti', 'label': 'Universitatea Politehnica din Bucuresti' },
    { 'id': 10582, 'value': 'Universitatea Politehnica din Timisoara', 'label': 'Universitatea Politehnica din Timisoara' },
    { 'id': 10583, 'value': 'Universitatea Româna de stiinte si Arte Gheorghe Cristea', 'label': 'Universitatea Româna de stiinte si Arte Gheorghe Cristea' },
    { 'id': 10584, 'value': 'Universitatea Româno-Americana', 'label': 'Universitatea Româno-Americana' },
    { 'id': 10585, 'value': 'Universitatea Româno-Germana din Sibiu', 'label': 'Universitatea Româno-Germana din Sibiu' },
    { 'id': 10586, 'value': 'Universitatea Sapientia', 'label': 'Universitatea Sapientia' },
    { 'id': 8466, 'value': 'Universitatea Slavona', 'label': 'Universitatea Slavona' },
    { 'id': 10587, 'value': 'Universitatea Spiru Haret', 'label': 'Universitatea Spiru Haret' },
    { 'id': 10588, 'value': 'Universitatea Stefan cel Mare Suceava', 'label': 'Universitatea Stefan cel Mare Suceava' },
    { 'id': 10589, 'value': 'Universitatea Technica din Cluj-Napoca', 'label': 'Universitatea Technica din Cluj-Napoca' },
    { 'id': 8467, 'value': 'Universitatea Tehnica a Moldovei', 'label': 'Universitatea Tehnica a Moldovei' },
    { 'id': 10590, 'value': 'Universitatea Tehnica de Constructii Bucuresti ', 'label': 'Universitatea Tehnica de Constructii Bucuresti ' },
    { 'id': 10591, 'value': 'Universitatea Tehnica Gheorghe Asachi din Iasi', 'label': 'Universitatea Tehnica Gheorghe Asachi din Iasi' },
    { 'id': 10592, 'value': 'Universitatea Tibiscus din Timisoara', 'label': 'Universitatea Tibiscus din Timisoara' },
    { 'id': 161, 'value': 'Universitatea Titu Maiorescu', 'label': 'Universitatea Titu Maiorescu' },
    { 'id': 10593, 'value': 'Universitatea Transilvania din Brasov', 'label': 'Universitatea Transilvania din Brasov' },
    { 'id': 10594, 'value': 'Universitatea Valahia din Târgoviste', 'label': 'Universitatea Valahia din Târgoviste' },
    { 'id': 10595, 'value': 'Universitatea Vasile Alecsandri din Bacau', 'label': 'Universitatea Vasile Alecsandri din Bacau' },
    { 'id': 3916, 'value': 'Université 20 Août 1955 de Skikda', 'label': 'Université 20 Août 1955 de Skikda' },
    { 'id': 3917, 'value': 'Université 8 Mai 1945 Guelma', 'label': 'Université 8 Mai 1945 Guelma' },
    { 'id': 3918, 'value': 'Université Abbès Laghrour Khenchela', 'label': 'Université Abbès Laghrour Khenchela' },
    { 'id': 3919, 'value': 'Université Abdelhamid Ibn Badis de Mostaganem', 'label': 'Université Abdelhamid Ibn Badis de Mostaganem' },
    { 'id': 3920, 'value': 'Université Abdelhamid Mehri de Constantine 2', 'label': 'Université Abdelhamid Mehri de Constantine 2' },
    { 'id': 8422, 'value': 'Université Abdelmalek Essadi', 'label': 'Université Abdelmalek Essadi' },
    { 'id': 3921, 'value': 'Université Abderrahmane Mira de Béjaia', 'label': 'Université Abderrahmane Mira de Béjaia' },
    { 'id': 9298, 'value': 'Université Abdou Moumouni', 'label': 'Université Abdou Moumouni' },
    { 'id': 3922, 'value': 'Université Abou Bekr Belkaid de Tlemcen', 'label': 'Université Abou Bekr Belkaid de Tlemcen' },
    { 'id': 3923, 'value': 'Université Abou el Kacem Saâdallah d\'Alger 2', 'label': 'Université Abou el Kacem Saâdallah d\'Alger 2' },
    { 'id': 11364, 'value': 'Université Adam Barka d\'Abéché', 'label': 'Université Adam Barka d\'Abéché' },
    { 'id': 2094, 'value': 'Université Adventiste Cosendai', 'label': 'Université Adventiste Cosendai' },
    { 'id': 4864, 'value': 'Université Adventiste d\'Haïti', 'label': 'Université Adventiste d\'Haïti' },
    { 'id': 1518, 'value': 'Université Africaine de Technologie et de Management', 'label': 'Université Africaine de Technologie et de Management' },
    { 'id': 4491, 'value': 'Université Africaine des Sciences', 'label': 'Université Africaine des Sciences' },
    { 'id': 3924, 'value': 'Université Ahmed Ben Bella d\'Oran 1', 'label': 'Université Ahmed Ben Bella d\'Oran 1' },
    { 'id': 3925, 'value': 'Université Ahmed Draia d\'Adrar', 'label': 'Université Ahmed Draia d\'Adrar' },
    { 'id': 3926, 'value': 'Université Akli Mohand Oulhadj de Bouira', 'label': 'Université Akli Mohand Oulhadj de Bouira' },
    { 'id': 8423, 'value': 'Université Al Akhawayn', 'label': 'Université Al Akhawayn' },
    { 'id': 8424, 'value': 'Université Al Quaraouiyine', 'label': 'Université Al Quaraouiyine' },
    { 'id': 8296, 'value': 'Université Al-Kafaat', 'label': 'Université Al-Kafaat' },
    { 'id': 2026, 'value': 'Université Alassane Ouattara', 'label': 'Université Alassane Ouattara' },
    { 'id': 11241, 'value': 'Université Alioune Diop de Bambey', 'label': 'Université Alioune Diop de Bambey' },
    { 'id': 11242, 'value': 'Université Amadou Mahtar Mbow', 'label': 'Université Amadou Mahtar Mbow' },
    { 'id': 3927, 'value': 'Université Amar Telidji de Laghouat', 'label': 'Université Amar Telidji de Laghouat' },
    { 'id': 4865, 'value': 'Université Américaine des Sciences Modernes d\'Haïti', 'label': 'Université Américaine des Sciences Modernes d\'Haïti' },
    { 'id': 8297, 'value': 'Université Antonine', 'label': 'Université Antonine' },
    { 'id': 11594, 'value': 'Université Arabe des Sciences', 'label': 'Université Arabe des Sciences' },
    { 'id': 11243, 'value': 'Université Assane SECK de Ziguinchor', 'label': 'Université Assane SECK de Ziguinchor' },
    { 'id': 1408, 'value': 'Université Aube Nouvelle', 'label': 'Université Aube Nouvelle' },
    { 'id': 4866, 'value': 'Université Autonome de Port-au-Prince', 'label': 'Université Autonome de Port-au-Prince' },
    { 'id': 3928, 'value': 'Université Badji Mokhtar de Annaba', 'label': 'Université Badji Mokhtar de Annaba' },
    { 'id': 3929, 'value': 'Université Benyoucef Benkhedda d\'Alger 1', 'label': 'Université Benyoucef Benkhedda d\'Alger 1' },
    { 'id': 325, 'value': 'Université Bordeaux Montaigne', 'label': 'Université Bordeaux Montaigne' },
    { 'id': 3930, 'value': 'Université Brahim Soltane Chaibout d\'Alger 3', 'label': 'Université Brahim Soltane Chaibout d\'Alger 3' },
    { 'id': 4410, 'value': 'Université Bretagne Sud', 'label': 'Université Bretagne Sud' },
    { 'id': 8425, 'value': 'Université Cadi Ayyad', 'label': 'Université Cadi Ayyad' },
    { 'id': 4867, 'value': 'Université Caraibe', 'label': 'Université Caraibe' },
    { 'id': 1942, 'value': 'Université Catholique de Bukavu', 'label': 'Université Catholique de Bukavu' },
    { 'id': 11006, 'value': 'Université Catholique de Kabgayi', 'label': 'Université Catholique de Kabgayi' },
    { 'id': 1519, 'value': 'Université Catholique de l\'Afrique de l\'Ouest, Benin', 'label': 'Université Catholique de l\'Afrique de l\'Ouest, Benin' },
    { 'id': 1409, 'value': 'Université Catholique de l\'Afrique de l\'Ouest, Burkina Faso', 'label': 'Université Catholique de l\'Afrique de l\'Ouest, Burkina Faso' },
    { 'id': 4412, 'value': 'Université Catholique de l\'Ouest', 'label': 'Université Catholique de l\'Ouest' },
    { 'id': 11372, 'value': 'Université Catholique de l’Afrique de l’Ouest - Unité Universitaire du Togo', 'label': 'Université Catholique de l’Afrique de l’Ouest - Unité Universitaire du Togo' },
    { 'id': 4411, 'value': 'Université Catholique de Lille', 'label': 'Université Catholique de Lille' },
    { 'id': 1391, 'value': 'Université Catholique de Louvain', 'label': 'Université Catholique de Louvain' },
    { 'id': 4413, 'value': 'Université Catholique de Lyon', 'label': 'Université Catholique de Lyon' },
    { 'id': 1943, 'value': 'Université Catholique du Congo', 'label': 'Université Catholique du Congo' },
    { 'id': 1944, 'value': 'Université Catholique du Graben', 'label': 'Université Catholique du Graben' },
    { 'id': 11595, 'value': 'Université Centrale', 'label': 'Université Centrale' },
    { 'id': 1945, 'value': 'Université Centrale de Kinshasa', 'label': 'Université Centrale de Kinshasa' },
    { 'id': 3931, 'value': 'Université Chadli Bendjedid d\'El Tarf', 'label': 'Université Chadli Bendjedid d\'El Tarf' },
    { 'id': 11244, 'value': 'Université Cheikh Ahmadou Bamba', 'label': 'Université Cheikh Ahmadou Bamba' },
    { 'id': 11245, 'value': 'Université Cheikh Anta Diop', 'label': 'Université Cheikh Anta Diop' },
    { 'id': 8426, 'value': 'Université Chouaib Doukkali', 'label': 'Université Chouaib Doukkali' },
    { 'id': 1946, 'value': 'Université Chrétienne Cardinal Malula', 'label': 'Université Chrétienne Cardinal Malula' },
    { 'id': 1488, 'value': 'Université Chrétienne de Bujumbura', 'label': 'Université Chrétienne de Bujumbura' },
    { 'id': 1947, 'value': 'Université Chrétienne de Kinshasa', 'label': 'Université Chrétienne de Kinshasa' },
    { 'id': 4868, 'value': 'Université Chrétienne du Nord d\'Haïti', 'label': 'Université Chrétienne du Nord d\'Haïti' },
    { 'id': 4414, 'value': 'Université Claude Bernard Lyon 1', 'label': 'Université Claude Bernard Lyon 1' },
    { 'id': 4415, 'value': 'Université Clermont Auvergne', 'label': 'Université Clermont Auvergne' },
    { 'id': 566, 'value': 'Université Cote d\'Azur', 'label': 'Université Cote d\'Azur' },
    { 'id': 1520, 'value': 'Université d\'Abomey-Calavi', 'label': 'Université d\'Abomey-Calavi' },
    { 'id': 4416, 'value': 'Université d\'Angers', 'label': 'Université d\'Angers' },
    { 'id': 8469, 'value': 'Université d\'Antananarivo', 'label': 'Université d\'Antananarivo' },
    { 'id': 8470, 'value': 'Université d\'Antsiranana', 'label': 'Université d\'Antsiranana' },
    { 'id': 4417, 'value': 'Université d\'Artois', 'label': 'Université d\'Artois' },
    { 'id': 4872, 'value': 'Université d\'Etat d\'Haiti', 'label': 'Université d\'Etat d\'Haiti' },
    { 'id': 4446, 'value': 'Université d\'Évry-Val d\'Essonne', 'label': 'Université d\'Évry-Val d\'Essonne' },
    { 'id': 1972, 'value': 'Université d\'Ikela', 'label': 'Université d\'Ikela' },
    { 'id': 4447, 'value': 'Université d\'Orléans', 'label': 'Université d\'Orléans' },
    { 'id': 11246, 'value': 'Université Dakar Bourguiba', 'label': 'Université Dakar Bourguiba' },
    { 'id': 2095, 'value': 'Université de Bamenda', 'label': 'Université de Bamenda' },
    { 'id': 1948, 'value': 'Université de Bandundu', 'label': 'Université de Bandundu' },
    { 'id': 1997, 'value': 'Université de Bangui', 'label': 'Université de Bangui' },
    { 'id': 3932, 'value': 'Université de Batna 2', 'label': 'Université de Batna 2' },
    { 'id': 4418, 'value': 'Université de Bordeaux', 'label': 'Université de Bordeaux' },
    { 'id': 4419, 'value': 'Université de Bourgogne', 'label': 'Université de Bourgogne' },
    { 'id': 4420, 'value': 'Université de Bretagne Occidentale', 'label': 'Université de Bretagne Occidentale' },
    { 'id': 2096, 'value': 'Université de Buéa', 'label': 'Université de Buéa' },
    { 'id': 1949, 'value': 'Université de Bunia', 'label': 'Université de Bunia' },
    { 'id': 4421, 'value': 'Université de Caen Normandie', 'label': 'Université de Caen Normandie' },
    { 'id': 11596, 'value': 'Université de Carthage', 'label': 'Université de Carthage' },
    { 'id': 4422, 'value': 'Université de Corse Pasquale Paoli', 'label': 'Université de Corse Pasquale Paoli' },
    { 'id': 1410, 'value': 'Université de Dédougou', 'label': 'Université de Dédougou' },
    { 'id': 3813, 'value': 'Université de Djibouti', 'label': 'Université de Djibouti' },
    { 'id': 11365, 'value': 'Université de Doba', 'label': 'Université de Doba' },
    { 'id': 2097, 'value': 'Université de Douala', 'label': 'Université de Douala' },
    { 'id': 2098, 'value': 'Université de Dschang', 'label': 'Université de Dschang' },
    { 'id': 8471, 'value': 'Université de Fianarantsoa', 'label': 'Université de Fianarantsoa' },
    { 'id': 4869, 'value': 'Université de Fondwa', 'label': 'Université de Fondwa' },
    { 'id': 4423, 'value': 'Université de Franche-Comté', 'label': 'Université de Franche-Comté' },
    { 'id': 312, 'value': 'Université de Fribourg', 'label': 'Université de Fribourg' },
    { 'id': 11597, 'value': 'Université de Gabès', 'label': 'Université de Gabès' },
    { 'id': 11598, 'value': 'Université de Gafsa', 'label': 'Université de Gafsa' },
    { 'id': 1950, 'value': 'Université de Gbadolite', 'label': 'Université de Gbadolite' },
    { 'id': 2013, 'value': 'Université de Genève', 'label': 'Université de Genève' },
    { 'id': 102, 'value': 'Université De Genève - Law Faculty', 'label': 'Université De Genève - Law Faculty' },
    { 'id': 3933, 'value': 'Université de Ghardaia', 'label': 'Université de Ghardaia' },
    { 'id': 1951, 'value': 'Université de Goma', 'label': 'Université de Goma' },
    { 'id': 4662, 'value': 'Université de Guyane', 'label': 'Université de Guyane' },
    { 'id': 4424, 'value': 'Université de Haute-Alsace', 'label': 'Université de Haute-Alsace' },
    { 'id': 1908, 'value': 'Université de Hearst', 'label': 'Université de Hearst' },
    { 'id': 11599, 'value': 'Université de Jendouba', 'label': 'Université de Jendouba' },
    { 'id': 1952, 'value': 'Université de Kabinda', 'label': 'Université de Kabinda' },
    { 'id': 11600, 'value': 'Université de Kairouan', 'label': 'Université de Kairouan' },
    { 'id': 1953, 'value': 'Université de Kalemie', 'label': 'Université de Kalemie' },
    { 'id': 1954, 'value': 'Université de Kamina', 'label': 'Université de Kamina' },
    { 'id': 1955, 'value': 'Université de Kananga', 'label': 'Université de Kananga' },
    { 'id': 11373, 'value': 'Université de Kara', 'label': 'Université de Kara' },
    { 'id': 1956, 'value': 'Université de Kikwit', 'label': 'Université de Kikwit' },
    { 'id': 1957, 'value': 'Université de Kindu', 'label': 'Université de Kindu' },
    { 'id': 1958, 'value': 'Université de Kinshasa', 'label': 'Université de Kinshasa' },
    { 'id': 1959, 'value': 'Université de Kisangani', 'label': 'Université de Kisangani' },
    { 'id': 1960, 'value': 'Université de Kolwezi', 'label': 'Université de Kolwezi' },
    { 'id': 1965, 'value': 'Université de l\'Uélé', 'label': 'Université de l\'Uélé' },
    { 'id': 11247, 'value': 'Université de l’Atlantique', 'label': 'Université de l’Atlantique' },
    { 'id': 11601, 'value': 'Université de la Manouba', 'label': 'Université de la Manouba' },
    { 'id': 4870, 'value': 'Université de la Nouvelle Grand\'Anse', 'label': 'Université de la Nouvelle Grand\'Anse' },
    { 'id': 9297, 'value': 'Université de la Nouvelle-Calédonie', 'label': 'Université de la Nouvelle-Calédonie' },
    { 'id': 9808, 'value': 'Université de la Polynésie Française', 'label': 'Université de la Polynésie Française' },
    { 'id': 10518, 'value': 'Université de la Reunion', 'label': 'Université de la Reunion' },
    { 'id': 2014, 'value': 'Université de Lausanne', 'label': 'Université de Lausanne' },
    { 'id': 1392, 'value': 'Université de Liège', 'label': 'Université de Liège' },
    { 'id': 1961, 'value': 'Université de Likasi', 'label': 'Université de Likasi' },
    { 'id': 4425, 'value': 'Université de Lille', 'label': 'Université de Lille' },
    { 'id': 4426, 'value': 'Université de Limoges', 'label': 'Université de Limoges' },
    { 'id': 1962, 'value': 'Université de Lisala', 'label': 'Université de Lisala' },
    { 'id': 1963, 'value': 'Université de Lodja', 'label': 'Université de Lodja' },
    { 'id': 11374, 'value': 'Université de Lomé', 'label': 'Université de Lomé' },
    { 'id': 4427, 'value': 'Université de Lorraine', 'label': 'Université de Lorraine' },
    { 'id': 1964, 'value': 'Université de Lubumbashi', 'label': 'Université de Lubumbashi' },
    { 'id': 8472, 'value': 'Université de Mahajanga', 'label': 'Université de Mahajanga' },
    { 'id': 1966, 'value': 'Université de Malemba Nkulu', 'label': 'Université de Malemba Nkulu' },
    { 'id': 2027, 'value': 'Université de Man', 'label': 'Université de Man' },
    { 'id': 2099, 'value': 'Université de Maroua', 'label': 'Université de Maroua' },
    { 'id': 1967, 'value': 'Université de Mbandaka', 'label': 'Université de Mbandaka' },
    { 'id': 1968, 'value': 'Université de Mbuji Mayi', 'label': 'Université de Mbuji Mayi' },
    { 'id': 11602, 'value': 'Université de Monastir', 'label': 'Université de Monastir' },
    { 'id': 1909, 'value': 'Université de Moncton', 'label': 'Université de Moncton' },
    { 'id': 1393, 'value': 'Université de Mons', 'label': 'Université de Mons' },
    { 'id': 4428, 'value': 'Université de Montpellier', 'label': 'Université de Montpellier' },
    { 'id': 363, 'value': 'Université de Montréal', 'label': 'Université de Montréal' },
    { 'id': 11366, 'value': 'Université de Moundou', 'label': 'Université de Moundou' },
    { 'id': 1969, 'value': 'Université de Moyen Lualaba', 'label': 'Université de Moyen Lualaba' },
    { 'id': 1489, 'value': 'Université de Mwaro', 'label': 'Université de Mwaro' },
    { 'id': 1970, 'value': 'Université de Mwene-Ditu', 'label': 'Université de Mwene-Ditu' },
    { 'id': 11367, 'value': 'Université de N\'Djaména', 'label': 'Université de N\'Djaména' },
    { 'id': 1394, 'value': 'Université de Namur', 'label': 'Université de Namur' },
    { 'id': 4429, 'value': 'Université de Nantes', 'label': 'Université de Nantes' },
    { 'id': 2015, 'value': 'Université de Neuchâtel', 'label': 'Université de Neuchâtel' },
    { 'id': 2100, 'value': 'Université de Ngaoundéré', 'label': 'Université de Ngaoundéré' },
    { 'id': 1490, 'value': 'Université de Ngozi', 'label': 'Université de Ngozi' },
    { 'id': 4430, 'value': 'Université de Nîmes', 'label': 'Université de Nîmes' },
    { 'id': 1411, 'value': 'Université de Ouagadougou', 'label': 'Université de Ouagadougou' },
    { 'id': 1521, 'value': 'Université de Parakou', 'label': 'Université de Parakou' },
    { 'id': 4431, 'value': 'Université de Paris', 'label': 'Université de Paris' },
    { 'id': 4432, 'value': 'Université de Pau et des Pays de l\'Adour', 'label': 'Université de Pau et des Pays de l\'Adour' },
    { 'id': 4433, 'value': 'Université de Perpignan Via Domitia', 'label': 'Université de Perpignan Via Domitia' },
    { 'id': 4434, 'value': 'Université de Picardie Jules Verne', 'label': 'Université de Picardie Jules Verne' },
    { 'id': 4435, 'value': 'Université de Poitiers', 'label': 'Université de Poitiers' },
    { 'id': 4871, 'value': 'Université de Port-au-Prince', 'label': 'Université de Port-au-Prince' },
    { 'id': 4436, 'value': 'Université de Reims Champagne-Ardenne', 'label': 'Université de Reims Champagne-Ardenne' },
    { 'id': 4437, 'value': 'Université de Rennes 1', 'label': 'Université de Rennes 1' },
    { 'id': 4438, 'value': 'Université de Rouen Normandie', 'label': 'Université de Rouen Normandie' },
    { 'id': 1910, 'value': 'Université de Saint-Boniface', 'label': 'Université de Saint-Boniface' },
    { 'id': 11368, 'value': 'Université de Sarh', 'label': 'Université de Sarh' },
    { 'id': 8494, 'value': 'Université de Ségou', 'label': 'Université de Ségou' },
    { 'id': 11603, 'value': 'Université de Sfax', 'label': 'Université de Sfax' },
    { 'id': 1911, 'value': 'Université de Sherbrooke', 'label': 'Université de Sherbrooke' },
    { 'id': 11604, 'value': 'Université de Sousse', 'label': 'Université de Sousse' },
    { 'id': 4439, 'value': 'Université de Strasbourg', 'label': 'Université de Strasbourg' },
    { 'id': 4440, 'value': 'Université de Technologie de Belfort-Montbéliard', 'label': 'Université de Technologie de Belfort-Montbéliard' },
    { 'id': 4441, 'value': 'Université de Technologie de Compiègne', 'label': 'Université de Technologie de Compiègne' },
    { 'id': 4442, 'value': 'Université de Technologie de Troyes', 'label': 'Université de Technologie de Troyes' },
    { 'id': 11248, 'value': 'Université de Thiès', 'label': 'Université de Thiès' },
    { 'id': 8473, 'value': 'Université de Toamasina', 'label': 'Université de Toamasina' },
    { 'id': 8474, 'value': 'Université de Toliara', 'label': 'Université de Toliara' },
    { 'id': 4443, 'value': 'Université de Toulon', 'label': 'Université de Toulon' },
    { 'id': 4444, 'value': 'Université de Tours', 'label': 'Université de Tours' },
    { 'id': 11605, 'value': 'Université de Tunis', 'label': 'Université de Tunis' },
    { 'id': 11606, 'value': 'Université de Tunis El Manar', 'label': 'Université de Tunis El Manar' },
    { 'id': 4445, 'value': 'Université de Versailles Saint-Quentin-en-Yvelines', 'label': 'Université de Versailles Saint-Quentin-en-Yvelines' },
    { 'id': 2101, 'value': 'Université de Yaoundé I', 'label': 'Université de Yaoundé I' },
    { 'id': 2102, 'value': 'Université de Yaoundé II', 'label': 'Université de Yaoundé II' },
    { 'id': 4734, 'value': 'Université des Antilles', 'label': 'Université des Antilles' },
    { 'id': 1491, 'value': 'Université des Collines', 'label': 'Université des Collines' },
    { 'id': 1492, 'value': 'Université des Grands Lacs', 'label': 'Université des Grands Lacs' },
    { 'id': 8495, 'value': 'Université des lettres et des sciences humaines de Bamako', 'label': 'Université des lettres et des sciences humaines de Bamako' },
    { 'id': 2103, 'value': 'Université des Montagnes', 'label': 'Université des Montagnes' },
    { 'id': 4493, 'value': 'Université des Sciences d\'Informatique Appliquée', 'label': 'Université des Sciences d\'Informatique Appliquée' },
    { 'id': 7931, 'value': 'Université des Sciences de la Santé', 'label': 'Université des Sciences de la Santé' },
    { 'id': 4492, 'value': 'Université des Sciences de la Santé, Gabon', 'label': 'Université des Sciences de la Santé, Gabon' },
    { 'id': 3934, 'value': 'Université des Sciences et de la Technologie d\'Oran Mohamed Boudiaf', 'label': 'Université des Sciences et de la Technologie d\'Oran Mohamed Boudiaf' },
    { 'id': 3935, 'value': 'Université des Sciences et de la Technologie Houari Boumediène', 'label': 'Université des Sciences et de la Technologie Houari Boumediène' },
    { 'id': 11369, 'value': 'Université des Sciences et de Technologie d\'Ati', 'label': 'Université des Sciences et de Technologie d\'Ati' },
    { 'id': 4494, 'value': 'Université des Sciences et Techniques de Masuku', 'label': 'Université des Sciences et Techniques de Masuku' },
    { 'id': 1971, 'value': 'Université des Sciences et Technologies de Lodja', 'label': 'Université des Sciences et Technologies de Lodja' },
    { 'id': 11375, 'value': 'Université des Sciences et Technologies du Togo', 'label': 'Université des Sciences et Technologies du Togo' },
    { 'id': 3936, 'value': 'Université des Sciences Islamiques Emir Abdelkader', 'label': 'Université des Sciences Islamiques Emir Abdelkader' },
    { 'id': 8496, 'value': 'Université des sciences juridiques et politiques de Bamako', 'label': 'Université des sciences juridiques et politiques de Bamako' },
    { 'id': 8497, 'value': 'Université des sciences sociales et de gestion de Bamako', 'label': 'Université des sciences sociales et de gestion de Bamako' },
    { 'id': 8664, 'value': 'Université des Sciences, de Technologie et de Médecine', 'label': 'Université des Sciences, de Technologie et de Médecine' },
    { 'id': 8498, 'value': 'Université des sciences, des techniques et des technologies de Bamako', 'label': 'Université des sciences, des techniques et des technologies de Bamako' },
    { 'id': 3937, 'value': 'Université Djilali Bounaama de Khemis Miliana', 'label': 'Université Djilali Bounaama de Khemis Miliana' },
    { 'id': 3938, 'value': 'Université Djillali Liabès de Sidi-Bel-Abbès', 'label': 'Université Djillali Liabès de Sidi-Bel-Abbès' },
    { 'id': 1493, 'value': 'Université du Burundi', 'label': 'Université du Burundi' },
    { 'id': 1973, 'value': 'Université du Cinquantenaire de Lwiro', 'label': 'Université du Cinquantenaire de Lwiro' },
    { 'id': 1412, 'value': 'Université du Faso', 'label': 'Université du Faso' },
    { 'id': 4448, 'value': 'Université du Havre', 'label': 'Université du Havre' },
    { 'id': 1974, 'value': 'Université du Kwango', 'label': 'Université du Kwango' },
    { 'id': 1494, 'value': 'Université du Lac Tanganyika', 'label': 'Université du Lac Tanganyika' },
    { 'id': 4449, 'value': 'Université du Littoral Côte d\'Opale', 'label': 'Université du Littoral Côte d\'Opale' },
    { 'id': 544, 'value': 'UNIVERSITE DU LUXEMBOURG', 'label': 'UNIVERSITE DU LUXEMBOURG' },
    { 'id': 1912, 'value': 'Université du Québec', 'label': 'Université du Québec' },
    { 'id': 1913, 'value': 'Université du Québec à Chicoutimi', 'label': 'Université du Québec à Chicoutimi' },
    { 'id': 1914, 'value': 'Université du Québec à Montréal', 'label': 'Université du Québec à Montréal' },
    { 'id': 1915, 'value': 'Université du Québec à Rimouski', 'label': 'Université du Québec à Rimouski' },
    { 'id': 1916, 'value': 'Université du Québec à Trois-Rivières', 'label': 'Université du Québec à Trois-Rivières' },
    { 'id': 1917, 'value': 'Université du Québec en Abitibi-Témiscamingue', 'label': 'Université du Québec en Abitibi-Témiscamingue' },
    { 'id': 1918, 'value': 'Université du Québec en Outaouais', 'label': 'Université du Québec en Outaouais' },
    { 'id': 11249, 'value': 'Université du Sahel', 'label': 'Université du Sahel' },
    { 'id': 8298, 'value': 'Université du Tripoli', 'label': 'Université du Tripoli' },
    { 'id': 3939, 'value': 'Université Echahid Hamma Lakhdar d\'El Oued', 'label': 'Université Echahid Hamma Lakhdar d\'El Oued' },
    { 'id': 11370, 'value': 'Université Emi Koussi', 'label': 'Université Emi Koussi' },
    { 'id': 4873, 'value': 'Université Épiscopale d\'Haiti', 'label': 'Université Épiscopale d\'Haiti' },
    { 'id': 1495, 'value': 'Université Espoir d\'Afrique', 'label': 'Université Espoir d\'Afrique' },
    { 'id': 11250, 'value': 'Université Euro-Afrique', 'label': 'Université Euro-Afrique' },
    { 'id': 8427, 'value': 'Université Euro-Méditerranéenne de Fès', 'label': 'Université Euro-Méditerranéenne de Fès' },
    { 'id': 11607, 'value': 'Université Européenne de Tunis', 'label': 'Université Européenne de Tunis' },
    { 'id': 1975, 'value': 'Université Evangélique en Afrique', 'label': 'Université Evangélique en Afrique' },
    { 'id': 11608, 'value': 'Université Ez-Zitouna', 'label': 'Université Ez-Zitouna' },
    { 'id': 2028, 'value': 'Université Félix Houphouët-Boigny', 'label': 'Université Félix Houphouët-Boigny' },
    { 'id': 3940, 'value': 'Université Ferhat Abbas Sétif 1', 'label': 'Université Ferhat Abbas Sétif 1' },
    { 'id': 4089, 'value': 'Université Française d\'Égypte', 'label': 'Université Française d\'Égypte' },
    { 'id': 836, 'value': 'Université Française en Arménie', 'label': 'Université Française en Arménie' },
    { 'id': 4495, 'value': 'Université Franco-Gabonaise Saint-Exupéry', 'label': 'Université Franco-Gabonaise Saint-Exupéry' },
    { 'id': 4874, 'value': 'Université Franco-Haïtienne du Cap-Haïtien', 'label': 'Université Franco-Haïtienne du Cap-Haïtien' },
    { 'id': 3941, 'value': 'Université Frères Mentouri de Constantine 1', 'label': 'Université Frères Mentouri de Constantine 1' },
    { 'id': 4875, 'value': 'Université G.O.C.', 'label': 'Université G.O.C.' },
    { 'id': 11251, 'value': 'Université Gaston Berger', 'label': 'Université Gaston Berger' },
    { 'id': 4450, 'value': 'Université Grenoble Alpes', 'label': 'Université Grenoble Alpes' },
    { 'id': 3942, 'value': 'Université Hadj Lakhder de Batna 1', 'label': 'Université Hadj Lakhder de Batna 1' },
    { 'id': 8428, 'value': 'Université Hassan 1er', 'label': 'Université Hassan 1er' },
    { 'id': 8429, 'value': 'Université Hassan II de Casablanca', 'label': 'Université Hassan II de Casablanca' },
    { 'id': 3943, 'value': 'Université Hassiba Ben Bouali de Chlef', 'label': 'Université Hassiba Ben Bouali de Chlef' },
    { 'id': 11609, 'value': 'Université Ibn Khaldoun', 'label': 'Université Ibn Khaldoun' },
    { 'id': 3944, 'value': 'Université Ibn Khaldoun de Tiaret', 'label': 'Université Ibn Khaldoun de Tiaret' },
    { 'id': 8430, 'value': 'Université Ibn Tofail', 'label': 'Université Ibn Tofail' },
    { 'id': 8431, 'value': 'Université Ibn Zohr', 'label': 'Université Ibn Zohr' },
    { 'id': 8432, 'value': 'Université Internationale Abulcasis des Sciences de la Santé', 'label': 'Université Internationale Abulcasis des Sciences de la Santé' },
    { 'id': 8433, 'value': 'Université Internationale d\'Agadir', 'label': 'Université Internationale d\'Agadir' },
    { 'id': 8434, 'value': 'Université Internationale de Casablanca', 'label': 'Université Internationale de Casablanca' },
    { 'id': 8435, 'value': 'Université Internationale de Rabat', 'label': 'Université Internationale de Rabat' },
    { 'id': 11610, 'value': 'Université Internationale de Tunis', 'label': 'Université Internationale de Tunis' },
    { 'id': 4876, 'value': 'Université INUKA', 'label': 'Université INUKA' },
    { 'id': 1522, 'value': 'Université IRGIB Africa', 'label': 'Université IRGIB Africa' },
    { 'id': 2029, 'value': 'Université Jean Lorougnon Guédé', 'label': 'Université Jean Lorougnon Guédé' },
    { 'id': 4451, 'value': 'Université Jean Monnet', 'label': 'Université Jean Monnet' },
    { 'id': 4452, 'value': 'Université Jean Moulin Lyon 3', 'label': 'Université Jean Moulin Lyon 3' },
    { 'id': 1976, 'value': 'Université Joseph Kasa-Vubu', 'label': 'Université Joseph Kasa-Vubu' },
    { 'id': 4877, 'value': 'Université Joseph Lafortune', 'label': 'Université Joseph Lafortune' },
    { 'id': 3945, 'value': 'Université Kasdi Merbah de Ouargla', 'label': 'Université Kasdi Merbah de Ouargla' },
    { 'id': 11252, 'value': 'Université Kocc Barma Saint-Louis', 'label': 'Université Kocc Barma Saint-Louis' },
    { 'id': 4733, 'value': 'Université Kofi Annan de Guinée', 'label': 'Université Kofi Annan de Guinée' },
    { 'id': 1977, 'value': 'Université Kongo', 'label': 'Université Kongo' },
    { 'id': 8299, 'value': 'Université la Sagesse', 'label': 'Université la Sagesse' },
    { 'id': 3946, 'value': 'Université Larbi Ben Mhidi de Oum El Bouaghi', 'label': 'Université Larbi Ben Mhidi de Oum El Bouaghi' },
    { 'id': 3947, 'value': 'Université Larbi Tebessi de Tébessa', 'label': 'Université Larbi Tebessi de Tébessa' },
    { 'id': 1919, 'value': 'Université Laval', 'label': 'Université Laval' },
    { 'id': 8300, 'value': 'Université Libanaise', 'label': 'Université Libanaise' },
    { 'id': 8665, 'value': 'Université Libanaise Internationale en Mauritanie', 'label': 'Université Libanaise Internationale en Mauritanie' },
    { 'id': 8301, 'value': 'Université Libano-Canadienne', 'label': 'Université Libano-Canadienne' },
    { 'id': 8302, 'value': 'Université Libano-Française de Technologie et de Sciences Appliquées', 'label': 'Université Libano-Française de Technologie et de Sciences Appliquées' },
    { 'id': 434, 'value': 'Universitè Libre de Bruxelles', 'label': 'Universitè Libre de Bruxelles' },
    { 'id': 11007, 'value': 'Université Libre de Kigali', 'label': 'Université Libre de Kigali' },
    { 'id': 1978, 'value': 'Université Libre de Kinshasa', 'label': 'Université Libre de Kinshasa' },
    { 'id': 11611, 'value': 'Université Libre de Tunis', 'label': 'Université Libre de Tunis' },
    { 'id': 1979, 'value': 'Université Libre des Pays des Grands Lacs', 'label': 'Université Libre des Pays des Grands Lacs' },
    { 'id': 1413, 'value': 'Université Libre du Burkina', 'label': 'Université Libre du Burkina' },
    { 'id': 3948, 'value': 'Université Lounici Ali de Blida 2', 'label': 'Université Lounici Ali de Blida 2' },
    { 'id': 1496, 'value': 'Université Lumière de Bujumbura', 'label': 'Université Lumière de Bujumbura' },
    { 'id': 4453, 'value': 'Université Lumière Lyon 2', 'label': 'Université Lumière Lyon 2' },
    { 'id': 4878, 'value': 'Université Lumière MEBSH', 'label': 'Université Lumière MEBSH' },
    { 'id': 3949, 'value': 'Université M\'hamed Bouguerra de Boumerdès', 'label': 'Université M\'hamed Bouguerra de Boumerdès' },
    { 'id': 11612, 'value': 'Université Mahmoud El Materi', 'label': 'Université Mahmoud El Materi' },
    { 'id': 8499, 'value': 'Université Mandé Bukari', 'label': 'Université Mandé Bukari' },
    { 'id': 1998, 'value': 'Université Marien Ngouabi', 'label': 'Université Marien Ngouabi' },
    { 'id': 1497, 'value': 'Université Martin Luther King', 'label': 'Université Martin Luther King' },
    { 'id': 11613, 'value': 'Université Méditerranéenne', 'label': 'Université Méditerranéenne' },
    { 'id': 1980, 'value': 'Université Méthodiste au Katanga', 'label': 'Université Méthodiste au Katanga' },
    { 'id': 3950, 'value': 'Université Mohamed Ben Ahmed d\'Oran 2', 'label': 'Université Mohamed Ben Ahmed d\'Oran 2' },
    { 'id': 3951, 'value': 'Université Mohamed Boudiaf de M\'sila', 'label': 'Université Mohamed Boudiaf de M\'sila' },
    { 'id': 3952, 'value': 'Université Mohamed El Bachir El Ibrahimi de Bordj Bou Arréridj', 'label': 'Université Mohamed El Bachir El Ibrahimi de Bordj Bou Arréridj' },
    { 'id': 3953, 'value': 'Université Mohamed Khider de Biskra', 'label': 'Université Mohamed Khider de Biskra' },
    { 'id': 3954, 'value': 'Université Mohamed Lamine Debaghine de Sétif 2', 'label': 'Université Mohamed Lamine Debaghine de Sétif 2' },
    { 'id': 3955, 'value': 'Université Mohamed Seddik Ben Yahia de Jijel', 'label': 'Université Mohamed Seddik Ben Yahia de Jijel' },
    { 'id': 3956, 'value': 'Université Mohamed-Chérif Messaadia de Souk Ahras', 'label': 'Université Mohamed-Chérif Messaadia de Souk Ahras' },
    { 'id': 8436, 'value': 'Université Mohammed Premier', 'label': 'Université Mohammed Premier' },
    { 'id': 8437, 'value': 'Université Mohammed V', 'label': 'Université Mohammed V' },
    { 'id': 8438, 'value': 'Université Mohammed VI des Sciences de la Santé', 'label': 'Université Mohammed VI des Sciences de la Santé' },
    { 'id': 8439, 'value': 'Université Mohammed VI Polytechnique', 'label': 'Université Mohammed VI Polytechnique' },
    { 'id': 11614, 'value': 'Université Montplaisir Tunis', 'label': 'Université Montplaisir Tunis' },
    { 'id': 8440, 'value': 'Université Moulay Ismail', 'label': 'Université Moulay Ismail' },
    { 'id': 3957, 'value': 'Université Mouloud Maameri de Tizi Ouzou', 'label': 'Université Mouloud Maameri de Tizi Ouzou' },
    { 'id': 8441, 'value': 'Université Mundiapolis', 'label': 'Université Mundiapolis' },
    { 'id': 3958, 'value': 'Université Mustapha Stambouli de Mascara', 'label': 'Université Mustapha Stambouli de Mascara' },
    { 'id': 2030, 'value': 'Université Nangui Abrogoua', 'label': 'Université Nangui Abrogoua' },
    { 'id': 1523, 'value': 'Université Nationale d\'Agriculture', 'label': 'Université Nationale d\'Agriculture' },
    { 'id': 1524, 'value': 'Université Nationale des Sciences, Technologies, Ingénierie et Mathématiques', 'label': 'Université Nationale des Sciences, Technologies, Ingénierie et Mathématiques' },
    { 'id': 4454, 'value': 'Université Nice Sophia Antipolis', 'label': 'Université Nice Sophia Antipolis' },
    { 'id': 4879, 'value': 'Université Nobel d\'Haiti', 'label': 'Université Nobel d\'Haiti' },
    { 'id': 1414, 'value': 'Université Norbert Zongo', 'label': 'Université Norbert Zongo' },
    { 'id': 4880, 'value': 'Université Notre Dame d\'Haïti', 'label': 'Université Notre Dame d\'Haïti' },
    { 'id': 1981, 'value': 'Université Notre Dame de Lomami', 'label': 'Université Notre Dame de Lomami' },
    { 'id': 1982, 'value': 'Université Notre Dame de Tshumbe', 'label': 'Université Notre Dame de Tshumbe' },
    { 'id': 1983, 'value': 'Université Notre Dame du Kasayi', 'label': 'Université Notre Dame du Kasayi' },
    { 'id': 1984, 'value': 'Université Officielle de Bukavu', 'label': 'Université Officielle de Bukavu' },
    { 'id': 1985, 'value': 'Université Officielle de Mbujimayi', 'label': 'Université Officielle de Mbujimayi' },
    { 'id': 1986, 'value': 'Université Officielle de Mweka', 'label': 'Université Officielle de Mweka' },
    { 'id': 1987, 'value': 'Université Officielle de Ruwenzori', 'label': 'Université Officielle de Ruwenzori' },
    { 'id': 1988, 'value': 'Université Officielle de Semuliki', 'label': 'Université Officielle de Semuliki' },
    { 'id': 4496, 'value': 'Université Omar Bongo', 'label': 'Université Omar Bongo' },
    { 'id': 1415, 'value': 'Université Ouaga 3S', 'label': 'Université Ouaga 3S' },
    { 'id': 1416, 'value': 'Université Ouaga II', 'label': 'Université Ouaga II' },
    { 'id': 4455, 'value': 'Université Paris 1 Panthéon-Sorbonne', 'label': 'Université Paris 1 Panthéon-Sorbonne' },
    { 'id': 4456, 'value': 'Université Paris 13', 'label': 'Université Paris 13' },
    { 'id': 4457, 'value': 'Université Paris 2 Panthéon-Assas', 'label': 'Université Paris 2 Panthéon-Assas' },
    { 'id': 334, 'value': 'Université Paris 8', 'label': 'Université Paris 8' },
    { 'id': 4458, 'value': 'Université Paris 8 Vincennes-Saint-Denis', 'label': 'Université Paris 8 Vincennes-Saint-Denis' },
    { 'id': 4459, 'value': 'Université Paris Nanterre', 'label': 'Université Paris Nanterre' },
    { 'id': 4460, 'value': 'Université Paris-Dauphine', 'label': 'Université Paris-Dauphine' },
    { 'id': 11615, 'value': 'Université Paris-Dauphine Tunis', 'label': 'Université Paris-Dauphine Tunis' },
    { 'id': 4461, 'value': 'Université Paris-Est Créteil Val de Marne', 'label': 'Université Paris-Est Créteil Val de Marne' },
    { 'id': 4462, 'value': 'Université Paris-Est Marne-la-Vallée', 'label': 'Université Paris-Est Marne-la-Vallée' },
    { 'id': 4463, 'value': 'Université Paris-Saclay', 'label': 'Université Paris-Saclay' },
    { 'id': 671, 'value': 'Université Paris-Sorbonne Abou Dhabi', 'label': 'Université Paris-Sorbonne Abou Dhabi' },
    { 'id': 1989, 'value': 'Université Patrice Emery Lumumba de Wembo-Nyama', 'label': 'Université Patrice Emery Lumumba de Wembo-Nyama' },
    { 'id': 4464, 'value': 'Université Paul Valéry Montpellier 3', 'label': 'Université Paul Valéry Montpellier 3' },
    { 'id': 417, 'value': 'Université Paul Valery Montpellier III', 'label': 'Université Paul Valery Montpellier III' },
    { 'id': 1990, 'value': 'Université Pédagogique de Kananga', 'label': 'Université Pédagogique de Kananga' },
    { 'id': 1991, 'value': 'Université Pedagogique Nationale', 'label': 'Université Pedagogique Nationale' },
    { 'id': 2031, 'value': 'Université Péléforo-Gbon-Coulibaly', 'label': 'Université Péléforo-Gbon-Coulibaly' },
    { 'id': 1417, 'value': 'Université Polytechnique de Bobo-Dioulasso', 'label': 'Université Polytechnique de Bobo-Dioulasso' },
    { 'id': 1498, 'value': 'Université Polytechnique de Gitega', 'label': 'Université Polytechnique de Gitega' },
    { 'id': 4465, 'value': 'Université Polytechnique Hauts-de-France', 'label': 'Université Polytechnique Hauts-de-France' },
    { 'id': 1499, 'value': 'Université Polytechnique Intégrée', 'label': 'Université Polytechnique Intégrée' },
    { 'id': 1525, 'value': 'Université Polytechnique Internationale Obiang Nguema Mbasogo', 'label': 'Université Polytechnique Internationale Obiang Nguema Mbasogo' },
    { 'id': 8442, 'value': 'Université Privée de Fès', 'label': 'Université Privée de Fès' },
    { 'id': 8443, 'value': 'Université Privée de Marrakech', 'label': 'Université Privée de Marrakech' },
    { 'id': 1418, 'value': 'Université Privée de Ouagadougou', 'label': 'Université Privée de Ouagadougou' },
    { 'id': 11616, 'value': 'Université Privée de Sousse', 'label': 'Université Privée de Sousse' },
    { 'id': 11617, 'value': 'Université Privée des Etudes Scientifiques et Technologiques de Mégrine', 'label': 'Université Privée des Etudes Scientifiques et Technologiques de Mégrine' },
    { 'id': 11618, 'value': 'Université Privée du Sud', 'label': 'Université Privée du Sud' },
    { 'id': 2104, 'value': 'Université Protestante d\'Afrique Centrale', 'label': 'Université Protestante d\'Afrique Centrale' },
    { 'id': 1526, 'value': 'Université Protestante de l\'Afrique de l\'Ouest', 'label': 'Université Protestante de l\'Afrique de l\'Ouest' },
    { 'id': 1992, 'value': 'Université Protestante de Lubumbashi', 'label': 'Université Protestante de Lubumbashi' },
    { 'id': 1993, 'value': 'Université Protestante du Congo', 'label': 'Université Protestante du Congo' },
    { 'id': 4881, 'value': 'Université Publique de l\'Artibonite aux Gonaïves', 'label': 'Université Publique de l\'Artibonite aux Gonaïves' },
    { 'id': 4882, 'value': 'Université Publique du Nord au Cap-haïtien', 'label': 'Université Publique du Nord au Cap-haïtien' },
    { 'id': 4883, 'value': 'Université Publique du Sud au Cayes', 'label': 'Université Publique du Sud au Cayes' },
    { 'id': 4884, 'value': 'Université Queensland', 'label': 'Université Queensland' },
    { 'id': 4885, 'value': 'Université Quisqueya', 'label': 'Université Quisqueya' },
    { 'id': 4466, 'value': 'Université Rennes 2', 'label': 'Université Rennes 2' },
    { 'id': 11371, 'value': 'Université Roi Fayçal', 'label': 'Université Roi Fayçal' },
    { 'id': 4886, 'value': 'Université Roi Henri Christophe', 'label': 'Université Roi Henri Christophe' },
    { 'id': 4887, 'value': 'Université Royale d\'Haïti', 'label': 'Université Royale d\'Haïti' },
    { 'id': 3959, 'value': 'Université Saad Dahlab de Blida 1', 'label': 'Université Saad Dahlab de Blida 1' },
    { 'id': 1500, 'value': 'Université Sagesse d’Afrique', 'label': 'Université Sagesse d’Afrique' },
    { 'id': 1419, 'value': 'Université Saint Thomas d\'Aquin', 'label': 'Université Saint Thomas d\'Aquin' },
    { 'id': 8304, 'value': 'Université Saint-Esprit de Kaslik', 'label': 'Université Saint-Esprit de Kaslik' },
    { 'id': 8305, 'value': 'Université Saint-Joseph de Beyrouth', 'label': 'Université Saint-Joseph de Beyrouth' },
    { 'id': 672, 'value': 'Université Saint-Joseph de Dubai', 'label': 'Université Saint-Joseph de Dubai' },
    { 'id': 1395, 'value': 'Université Saint-Louis - Bruxelles', 'label': 'Université Saint-Louis - Bruxelles' },
    { 'id': 8303, 'value': 'Université Sainte Famille', 'label': 'Université Sainte Famille' },
    { 'id': 1920, 'value': 'Université Sainte-Anne', 'label': 'Université Sainte-Anne' },
    { 'id': 3960, 'value': 'Université Salah Boubnider de Constantine 3', 'label': 'Université Salah Boubnider de Constantine 3' },
    { 'id': 4467, 'value': 'Université Savoie Mont Blanc', 'label': 'Université Savoie Mont Blanc' },
    { 'id': 11619, 'value': 'Université SESAME', 'label': 'Université SESAME' },
    { 'id': 1994, 'value': 'Université Shalom de Bunia', 'label': 'Université Shalom de Bunia' },
    { 'id': 8444, 'value': 'Université Sidi Mohamed Ben Abdellah', 'label': 'Université Sidi Mohamed Ben Abdellah' },
    { 'id': 1995, 'value': 'Université Simon Kimbangu', 'label': 'Université Simon Kimbangu' },
    { 'id': 11253, 'value': 'Université Sine-Saloum El Hadji Ibrahima Niasse', 'label': 'Université Sine-Saloum El Hadji Ibrahima Niasse' },
    { 'id': 4468, 'value': 'Université Sorbonne Nouvelle - Paris 3', 'label': 'Université Sorbonne Nouvelle - Paris 3' },
    { 'id': 8445, 'value': 'Université Sultan Moulay Slimane', 'label': 'Université Sultan Moulay Slimane' },
    { 'id': 3961, 'value': 'Université Tahar Moulay de Saida', 'label': 'Université Tahar Moulay de Saida' },
    { 'id': 3962, 'value': 'Université Tahri Mohammed de Béchar', 'label': 'Université Tahri Mohammed de Béchar' },
    { 'id': 11620, 'value': 'Université Technologique Privée de Gabès', 'label': 'Université Technologique Privée de Gabès' },
    { 'id': 4469, 'value': 'Université Toulouse - Jean Jaurès', 'label': 'Université Toulouse - Jean Jaurès' },
    { 'id': 4470, 'value': 'Université Toulouse 1 Capitole', 'label': 'Université Toulouse 1 Capitole' },
    { 'id': 4471, 'value': 'Université Toulouse III - Paul Sabatier', 'label': 'Université Toulouse III - Paul Sabatier' },
    { 'id': 11621, 'value': 'Université Tunis Carthage', 'label': 'Université Tunis Carthage' },
    { 'id': 1996, 'value': 'Université William Booth', 'label': 'Université William Booth' },
    { 'id': 3963, 'value': 'Université Yahia Fares de Médéa', 'label': 'Université Yahia Fares de Médéa' },
    { 'id': 3964, 'value': 'Université Ziane Achour de Djelfa', 'label': 'Université Ziane Achour de Djelfa' },
    { 'id': 1396, 'value': 'Universiteit Antwerpen', 'label': 'Universiteit Antwerpen' },
    { 'id': 1397, 'value': 'Universiteit Gent', 'label': 'Universiteit Gent' },
    { 'id': 1398, 'value': 'Universiteit Hasselt', 'label': 'Universiteit Hasselt' },
    { 'id': 9551, 'value': 'Universiteit Leiden', 'label': 'Universiteit Leiden' },
    { 'id': 9552, 'value': 'Universiteit Maastricht', 'label': 'Universiteit Maastricht' },
    { 'id': 14231, 'value': 'Universiteit Stellenbosch', 'label': 'Universiteit Stellenbosch' },
    { 'id': 9553, 'value': 'Universiteit Twente', 'label': 'Universiteit Twente' },
    { 'id': 9554, 'value': 'Universiteit Utrecht', 'label': 'Universiteit Utrecht' },
    { 'id': 9555, 'value': 'Universiteit van Amsterdam', 'label': 'Universiteit van Amsterdam' },
    { 'id': 3339, 'value': 'Universiteit van Curaçao', 'label': 'Universiteit van Curaçao' },
    { 'id': 9556, 'value': 'Universiteit van Tilburg', 'label': 'Universiteit van Tilburg' },
    { 'id': 9557, 'value': 'Universiteit voor Humanistiek', 'label': 'Universiteit voor Humanistiek' },
    { 'id': 9583, 'value': 'Universitetet i Agder', 'label': 'Universitetet i Agder' },
    { 'id': 9584, 'value': 'Universitetet i Bergen', 'label': 'Universitetet i Bergen' },
    { 'id': 9585, 'value': 'Universitetet i Nordland', 'label': 'Universitetet i Nordland' },
    { 'id': 9586, 'value': 'Universitetet i Oslo', 'label': 'Universitetet i Oslo' },
    { 'id': 9587, 'value': 'Universitetet i Sørøst-Norge', 'label': 'Universitetet i Sørøst-Norge' },
    { 'id': 9588, 'value': 'Universitetet i Stavanger', 'label': 'Universitetet i Stavanger' },
    { 'id': 787, 'value': 'Universiteti Aldent', 'label': 'Universiteti Aldent' },
    { 'id': 788, 'value': 'Universiteti Aleksandër Moisiu, Durrës', 'label': 'Universiteti Aleksandër Moisiu, Durrës' },
    { 'id': 789, 'value': 'Universiteti Bujqësor i Tiranës', 'label': 'Universiteti Bujqësor i Tiranës' },
    { 'id': 790, 'value': 'Universiteti Epoka', 'label': 'Universiteti Epoka' },
    { 'id': 791, 'value': 'Universiteti Eqerem Çabej, Gjirokastër', 'label': 'Universiteti Eqerem Çabej, Gjirokastër' },
    { 'id': 792, 'value': 'Universiteti Europian i Tiranës', 'label': 'Universiteti Europian i Tiranës' },
    { 'id': 793, 'value': 'Universiteti Fan S. Noli, Korçë', 'label': 'Universiteti Fan S. Noli, Korçë' },
    { 'id': 10600, 'value': 'Universiteti Haxhi Zeka', 'label': 'Universiteti Haxhi Zeka' },
    { 'id': 794, 'value': 'Universiteti i Arteve', 'label': 'Universiteti i Arteve' },
    { 'id': 795, 'value': 'Universiteti i Elbasanit Aleksander Xhuvani', 'label': 'Universiteti i Elbasanit Aleksander Xhuvani' },
    { 'id': 10601, 'value': 'Universiteti i Gjakovës Fehmi Agani', 'label': 'Universiteti i Gjakovës Fehmi Agani' },
    { 'id': 10602, 'value': 'Universiteti i Mitrovicës Isa Boletini', 'label': 'Universiteti i Mitrovicës Isa Boletini' },
    { 'id': 796, 'value': 'Universiteti i Mjekësisë, Tiranë', 'label': 'Universiteti i Mjekësisë, Tiranë' },
    { 'id': 797, 'value': 'Universiteti i New York-ut në Tiranë', 'label': 'Universiteti i New York-ut në Tiranë' },
    { 'id': 10603, 'value': 'Universiteti i Prishtinës', 'label': 'Universiteti i Prishtinës' },
    { 'id': 10604, 'value': 'Universiteti i Prizrenit Ukshin Hoti', 'label': 'Universiteti i Prizrenit Ukshin Hoti' },
    { 'id': 10605, 'value': 'Universiteti i Shkencave të Aplikuara në Ferizaj', 'label': 'Universiteti i Shkencave të Aplikuara në Ferizaj' },
    { 'id': 798, 'value': 'Universiteti i Shkodrës Luigj Gurakuqi', 'label': 'Universiteti i Shkodrës Luigj Gurakuqi' },
    { 'id': 799, 'value': 'Universiteti i Sporteve të Tiranës', 'label': 'Universiteti i Sporteve të Tiranës' },
    { 'id': 8483, 'value': 'Universiteti i Tetovës', 'label': 'Universiteti i Tetovës' },
    { 'id': 800, 'value': 'Universiteti i Tiranës', 'label': 'Universiteti i Tiranës' },
    { 'id': 801, 'value': 'Universiteti Ismail Qemali, Vlorë', 'label': 'Universiteti Ismail Qemali, Vlorë' },
    { 'id': 10606, 'value': 'Universiteti Kadri Zeka Gjilan', 'label': 'Universiteti Kadri Zeka Gjilan' },
    { 'id': 802, 'value': 'Universiteti Katolik Zoja e Këshillit Të Mirë', 'label': 'Universiteti Katolik Zoja e Këshillit Të Mirë' },
    { 'id': 803, 'value': 'Universiteti Marin Barleti', 'label': 'Universiteti Marin Barleti' },
    { 'id': 804, 'value': 'Universiteti Mesdhetar i Shqiperise', 'label': 'Universiteti Mesdhetar i Shqiperise' },
    { 'id': 805, 'value': 'Universiteti Metropolitan Tirana', 'label': 'Universiteti Metropolitan Tirana' },
    { 'id': 8484, 'value': 'Universiteti Nderkombetar i Struges', 'label': 'Universiteti Nderkombetar i Struges' },
    { 'id': 806, 'value': 'Universiteti Polis', 'label': 'Universiteti Polis' },
    { 'id': 807, 'value': 'Universiteti Politeknik i Tiranës', 'label': 'Universiteti Politeknik i Tiranës' },
    { 'id': 808, 'value': 'Universiteti Privat Albanian University', 'label': 'Universiteti Privat Albanian University' },
    { 'id': 9589, 'value': 'Universitetssenteret på Svalbard', 'label': 'Universitetssenteret på Svalbard' },
    { 'id': 9253, 'value': 'Universiti AIMST', 'label': 'Universiti AIMST' },
    { 'id': 9254, 'value': 'Universiti Antarabangsa AlBukhary', 'label': 'Universiti Antarabangsa AlBukhary' },
    { 'id': 1529, 'value': 'Universiti Brunei Darussalam', 'label': 'Universiti Brunei Darussalam' },
    { 'id': 9255, 'value': 'Universiti Islam Antarabangsa Malaysia', 'label': 'Universiti Islam Antarabangsa Malaysia' },
    { 'id': 1530, 'value': 'Universiti Islam Sultan Sharif Ali', 'label': 'Universiti Islam Sultan Sharif Ali' },
    { 'id': 9256, 'value': 'Universiti Kebangsaan Malaysia', 'label': 'Universiti Kebangsaan Malaysia' },
    { 'id': 9257, 'value': 'Universiti Kuala Lumpur', 'label': 'Universiti Kuala Lumpur' },
    { 'id': 9258, 'value': 'Universiti Malaya', 'label': 'Universiti Malaya' },
    { 'id': 9259, 'value': 'Universiti Malaysia Kelantan', 'label': 'Universiti Malaysia Kelantan' },
    { 'id': 9260, 'value': 'Universiti Malaysia Pahang', 'label': 'Universiti Malaysia Pahang' },
    { 'id': 9261, 'value': 'Universiti Malaysia Perlis', 'label': 'Universiti Malaysia Perlis' },
    { 'id': 9262, 'value': 'Universiti Malaysia Sabah', 'label': 'Universiti Malaysia Sabah' },
    { 'id': 9263, 'value': 'Universiti Malaysia Sarawak', 'label': 'Universiti Malaysia Sarawak' },
    { 'id': 9264, 'value': 'Universiti Malaysia Terengganu', 'label': 'Universiti Malaysia Terengganu' },
    { 'id': 9265, 'value': 'Universiti Pendidikan Sultan Idris', 'label': 'Universiti Pendidikan Sultan Idris' },
    { 'id': 9266, 'value': 'Universiti Pertahanan Nasional Malaysia', 'label': 'Universiti Pertahanan Nasional Malaysia' },
    { 'id': 9267, 'value': 'Universiti Perubatan Antarabangsa', 'label': 'Universiti Perubatan Antarabangsa' },
    { 'id': 9268, 'value': 'Universiti Putra Malaysia', 'label': 'Universiti Putra Malaysia' },
    { 'id': 9269, 'value': 'Universiti Sains Islam Malaysia', 'label': 'Universiti Sains Islam Malaysia' },
    { 'id': 9270, 'value': 'Universiti Sains Malaysia', 'label': 'Universiti Sains Malaysia' },
    { 'id': 9271, 'value': 'Universiti Selangor', 'label': 'Universiti Selangor' },
    { 'id': 9272, 'value': 'Universiti Sultan Zainal Abidin', 'label': 'Universiti Sultan Zainal Abidin' },
    { 'id': 9273, 'value': 'Universiti Teknikal Malaysia Melaka', 'label': 'Universiti Teknikal Malaysia Melaka' },
    { 'id': 1531, 'value': 'Universiti Teknologi Brunei', 'label': 'Universiti Teknologi Brunei' },
    { 'id': 9274, 'value': 'Universiti Teknologi Kreatif Limkokwing', 'label': 'Universiti Teknologi Kreatif Limkokwing' },
    { 'id': 9275, 'value': 'Universiti Teknologi Malaysia', 'label': 'Universiti Teknologi Malaysia' },
    { 'id': 9276, 'value': 'Universiti Teknologi MARA', 'label': 'Universiti Teknologi MARA' },
    { 'id': 9277, 'value': 'Universiti Teknologi Petronas', 'label': 'Universiti Teknologi Petronas' },
    { 'id': 9278, 'value': 'Universiti Tenaga Nasional', 'label': 'Universiti Tenaga Nasional' },
    { 'id': 9279, 'value': 'Universiti Tun Abdul Razak', 'label': 'Universiti Tun Abdul Razak' },
    { 'id': 9280, 'value': 'Universiti Tun Hussein Onn Malaysia', 'label': 'Universiti Tun Hussein Onn Malaysia' },
    { 'id': 9281, 'value': 'Universiti Tunku Abdul Rahman', 'label': 'Universiti Tunku Abdul Rahman' },
    { 'id': 9282, 'value': 'Universiti Utara Malaysia', 'label': 'Universiti Utara Malaysia' },
    { 'id': 8261, 'value': 'University "Miras"', 'label': 'University "Miras"' },
    { 'id': 8485, 'value': 'University American College Skopje', 'label': 'University American College Skopje' },
    { 'id': 13635, 'value': 'University at Albany, State University of New York', 'label': 'University at Albany, State University of New York' },
    { 'id': 13636, 'value': 'University at Buffalo, State University of New York', 'label': 'University at Buffalo, State University of New York' },
    { 'id': 8262, 'value': 'University Bolashak', 'label': 'University Bolashak' },
    { 'id': 510, 'value': 'University Canada West', 'label': 'University Canada West' },
    { 'id': 4577, 'value': 'University College Birmingham', 'label': 'University College Birmingham' },
    { 'id': 5528, 'value': 'University College Cork', 'label': 'University College Cork' },
    { 'id': 227, 'value': 'University College Dublin', 'label': 'University College Dublin' },
    { 'id': 3839, 'value': 'University College Lillebaelt', 'label': 'University College Lillebaelt' },
    { 'id': 193, 'value': 'University College London', 'label': 'University College London' },
    { 'id': 4714, 'value': 'University College of Agriculture and Environmental Studies', 'label': 'University College of Agriculture and Environmental Studies' },
    { 'id': 10400, 'value': 'University College of Applied Sciences', 'label': 'University College of Applied Sciences' },
    { 'id': 1478, 'value': 'University College of Bahrain', 'label': 'University College of Bahrain' },
    { 'id': 4715, 'value': 'University College of Management Studies', 'label': 'University College of Management Studies' },
    { 'id': 673, 'value': 'University College of Mother and Family Sciences', 'label': 'University College of Mother and Family Sciences' },
    { 'id': 10401, 'value': 'University College of Science and Technology', 'label': 'University College of Science and Technology' },
    { 'id': 8163, 'value': 'University College of the Cayman Islands', 'label': 'University College of the Cayman Islands' },
    { 'id': 4716, 'value': 'University for Development Studies', 'label': 'University for Development Studies' },
    { 'id': 8486, 'value': 'University for Information Science and Technology St. Paul the Apostle', 'label': 'University for Information Science and Technology St. Paul the Apostle' },
    { 'id': 283, 'value': 'University for Peace', 'label': 'University for Peace' },
    { 'id': 4578, 'value': 'University for the Creative Arts', 'label': 'University for the Creative Arts' },
    { 'id': 184, 'value': 'University of Aberdeen', 'label': 'University of Aberdeen' },
    { 'id': 9436, 'value': 'University of Abuja', 'label': 'University of Abuja' },
    { 'id': 14213, 'value': 'University of Aden', 'label': 'University of Aden' },
    { 'id': 13637, 'value': 'University of Advancing Technology', 'label': 'University of Advancing Technology' },
    { 'id': 9437, 'value': 'University of Africa', 'label': 'University of Africa' },
    { 'id': 1451, 'value': 'University of Agribusiness and Rural Development', 'label': 'University of Agribusiness and Rural Development' },
    { 'id': 6367, 'value': 'University of Agricultural and Horticultural Sciences, Shivamogga', 'label': 'University of Agricultural and Horticultural Sciences, Shivamogga' },
    { 'id': 6368, 'value': 'University of Agricultural Sciences, Bangalore', 'label': 'University of Agricultural Sciences, Bangalore' },
    { 'id': 6369, 'value': 'University of Agricultural Sciences, Dharwad', 'label': 'University of Agricultural Sciences, Dharwad' },
    { 'id': 6370, 'value': 'University of Agricultural Sciences, Raichur', 'label': 'University of Agricultural Sciences, Raichur' },
    { 'id': 10187, 'value': 'University of Agriculture, Faisalabad', 'label': 'University of Agriculture, Faisalabad' },
    { 'id': 9438, 'value': 'University of Agriculture, Makurdi', 'label': 'University of Agriculture, Makurdi' },
    { 'id': 7739, 'value': 'University of Aizu', 'label': 'University of Aizu' },
    { 'id': 13638, 'value': 'University of Akron', 'label': 'University of Akron' },
    { 'id': 6473, 'value': 'University of Al Hamdaniya', 'label': 'University of Al Hamdaniya' },
    { 'id': 6474, 'value': 'University of Al-Qadisiyah', 'label': 'University of Al-Qadisiyah' },
    { 'id': 13639, 'value': 'University of Alabama at Birmingham', 'label': 'University of Alabama at Birmingham' },
    { 'id': 13640, 'value': 'University of Alaska Anchorage', 'label': 'University of Alaska Anchorage' },
    { 'id': 13641, 'value': 'University of Alaska Fairbanks', 'label': 'University of Alaska Fairbanks' },
    { 'id': 13642, 'value': 'University of Alaska Southeast', 'label': 'University of Alaska Southeast' },
    { 'id': 1921, 'value': 'University of Alberta', 'label': 'University of Alberta' },
    { 'id': 11086, 'value': 'University of Albutana', 'label': 'University of Albutana' },
    { 'id': 11352, 'value': 'University of Aleppo', 'label': 'University of Aleppo' },
    { 'id': 6371, 'value': 'University of Allahabad', 'label': 'University of Allahabad' },
    { 'id': 290, 'value': 'University of Amsterdam', 'label': 'University of Amsterdam' },
    { 'id': 6475, 'value': 'University of Anbar', 'label': 'University of Anbar' },
    { 'id': 9994, 'value': 'University of Antique', 'label': 'University of Antique' },
    { 'id': 149, 'value': 'University of Antwerp - Anversa', 'label': 'University of Antwerp - Anversa' },
    { 'id': 6772, 'value': 'University of Applied Science and Technology', 'label': 'University of Applied Science and Technology' },
    { 'id': 3804, 'value': 'University of Applied Sciences Europe', 'label': 'University of Applied Sciences Europe' },
    { 'id': 1452, 'value': 'University of Architecture, Civil Engineering and Geodesy', 'label': 'University of Architecture, Civil Engineering and Geodesy' },
    { 'id': 13643, 'value': 'University of Arkansas', 'label': 'University of Arkansas' },
    { 'id': 13644, 'value': 'University of Arkansas - Fort Smith', 'label': 'University of Arkansas - Fort Smith' },
    { 'id': 13645, 'value': 'University of Arkansas at Little Rock', 'label': 'University of Arkansas at Little Rock' },
    { 'id': 13646, 'value': 'University of Arkansas at Monticello', 'label': 'University of Arkansas at Monticello' },
    { 'id': 13647, 'value': 'University of Arkansas at Pine Bluff', 'label': 'University of Arkansas at Pine Bluff' },
    { 'id': 13648, 'value': 'University of Arkansas for Medical Sciences', 'label': 'University of Arkansas for Medical Sciences' },
    { 'id': 6773, 'value': 'University of Art', 'label': 'University of Art' },
    { 'id': 209, 'value': 'University of Arts HELSINKI - Uniarts', 'label': 'University of Arts HELSINKI - Uniarts' },
    { 'id': 1110, 'value': 'University of Aruba', 'label': 'University of Aruba' },
    { 'id': 9995, 'value': 'University of Asia and the Pacific', 'label': 'University of Asia and the Pacific' },
    { 'id': 1324, 'value': 'University of Asia Pacific', 'label': 'University of Asia Pacific' },
    { 'id': 9603, 'value': 'University of Auckland', 'label': 'University of Auckland' },
    { 'id': 106, 'value': 'University of Augsburg', 'label': 'University of Augsburg' },
    { 'id': 10188, 'value': 'University of Azad Jammu and Kashmir', 'label': 'University of Azad Jammu and Kashmir' },
    { 'id': 6476, 'value': 'University of Babylon', 'label': 'University of Babylon' },
    { 'id': 11975, 'value': 'University of Bagamoyo', 'label': 'University of Bagamoyo' },
    { 'id': 6477, 'value': 'University of Baghdad', 'label': 'University of Baghdad' },
    { 'id': 9996, 'value': 'University of Baguio', 'label': 'University of Baguio' },
    { 'id': 11291, 'value': 'University of Bahr El-Ghazal', 'label': 'University of Bahr El-Ghazal' },
    { 'id': 1479, 'value': 'University of Bahrain', 'label': 'University of Bahrain' },
    { 'id': 11087, 'value': 'University of Bahri', 'label': 'University of Bahri' },
    { 'id': 11088, 'value': 'University of Bakhtalruda', 'label': 'University of Bakhtalruda' },
    { 'id': 8306, 'value': 'University of Balamand', 'label': 'University of Balamand' },
    { 'id': 10189, 'value': 'University of Balochistan', 'label': 'University of Balochistan' },
    { 'id': 13649, 'value': 'University of Baltimore', 'label': 'University of Baltimore' },
    { 'id': 10190, 'value': 'University of Baltistan, Skardu', 'label': 'University of Baltistan, Skardu' },
    { 'id': 1325, 'value': 'University of Barisal', 'label': 'University of Barisal' },
    { 'id': 6478, 'value': 'University of Basrah', 'label': 'University of Basrah' },
    { 'id': 9997, 'value': 'University of Batangas', 'label': 'University of Batangas' },
    { 'id': 386, 'value': 'University of Bath', 'label': 'University of Bath' },
    { 'id': 7932, 'value': 'University of Battambang', 'label': 'University of Battambang' },
    { 'id': 4579, 'value': 'University of Bedfordshire', 'label': 'University of Bedfordshire' },
    { 'id': 1848, 'value': 'University of Belize', 'label': 'University of Belize' },
    { 'id': 8405, 'value': 'University of Benghazi', 'label': 'University of Benghazi' },
    { 'id': 9439, 'value': 'University of Benin', 'label': 'University of Benin' },
    { 'id': 379, 'value': 'University of Bern', 'label': 'University of Bern' },
    { 'id': 6774, 'value': 'University of Birjand', 'label': 'University of Birjand' },
    { 'id': 332, 'value': 'University of Birmingham', 'label': 'University of Birmingham' },
    { 'id': 11049, 'value': 'University of Bisha', 'label': 'University of Bisha' },
    { 'id': 8695, 'value': 'University of Blantyre Synod', 'label': 'University of Blantyre Synod' },
    { 'id': 11089, 'value': 'University of Blue Nile', 'label': 'University of Blue Nile' },
    { 'id': 9998, 'value': 'University of Bohol', 'label': 'University of Bohol' },
    { 'id': 6775, 'value': 'University of Bojnord', 'label': 'University of Bojnord' },
    { 'id': 4580, 'value': 'University of Bolton', 'label': 'University of Bolton' },
    { 'id': 6776, 'value': 'University of Bonab', 'label': 'University of Bonab' },
    { 'id': 1798, 'value': 'University of Botswana', 'label': 'University of Botswana' },
    { 'id': 274, 'value': 'University of Bradford', 'label': 'University of Bradford' },
    { 'id': 13650, 'value': 'University of Bridgeport', 'label': 'University of Bridgeport' },
    { 'id': 4581, 'value': 'University of Brighton', 'label': 'University of Brighton' },
    { 'id': 4582, 'value': 'University of Bristol', 'label': 'University of Bristol' },
    { 'id': 9647, 'value': 'University of Buraimi', 'label': 'University of Buraimi' },
    { 'id': 6372, 'value': 'University of Burdwan', 'label': 'University of Burdwan' },
    { 'id': 11050, 'value': 'University of Business and Technology', 'label': 'University of Business and Technology' },
    { 'id': 9999, 'value': 'University of Cagayan Valley', 'label': 'University of Cagayan Valley' },
    { 'id': 9440, 'value': 'University of Calabar', 'label': 'University of Calabar' },
    { 'id': 6373, 'value': 'University of Calcutta', 'label': 'University of Calcutta' },
    { 'id': 1922, 'value': 'University of Calgary', 'label': 'University of Calgary' },
    { 'id': 10515, 'value': 'University of Calgary in Qatar', 'label': 'University of Calgary in Qatar' },
    { 'id': 6374, 'value': 'University of Calicut', 'label': 'University of Calicut' },
    { 'id': 202, 'value': 'University of California', 'label': 'University of California' },
    { 'id': 13651, 'value': 'University of California, Berkeley', 'label': 'University of California, Berkeley' },
    { 'id': 13652, 'value': 'University of California, Davis', 'label': 'University of California, Davis' },
    { 'id': 13653, 'value': 'University of California, Hastings College of the Law', 'label': 'University of California, Hastings College of the Law' },
    { 'id': 13654, 'value': 'University of California, Irvine', 'label': 'University of California, Irvine' },
    { 'id': 13655, 'value': 'University of California, Los Angeles', 'label': 'University of California, Los Angeles' },
    { 'id': 13656, 'value': 'University of California, Merced', 'label': 'University of California, Merced' },
    { 'id': 13657, 'value': 'University of California, Riverside', 'label': 'University of California, Riverside' },
    { 'id': 13658, 'value': 'University of California, San Diego', 'label': 'University of California, San Diego' },
    { 'id': 13659, 'value': 'University of California, San Francisco', 'label': 'University of California, San Francisco' },
    { 'id': 13660, 'value': 'University of California, Santa Barbara', 'label': 'University of California, Santa Barbara' },
    { 'id': 13661, 'value': 'University of California, Santa Cruz', 'label': 'University of California, Santa Cruz' },
    { 'id': 4583, 'value': 'University of Cambridge', 'label': 'University of Cambridge' },
    { 'id': 1098, 'value': 'University of Canberra', 'label': 'University of Canberra' },
    { 'id': 9604, 'value': 'University of Canterbury', 'label': 'University of Canterbury' },
    { 'id': 4717, 'value': 'University of Cape Coast', 'label': 'University of Cape Coast' },
    { 'id': 14232, 'value': 'University of Cape Town', 'label': 'University of Cape Town' },
    { 'id': 10000, 'value': 'University of Cebu', 'label': 'University of Cebu' },
    { 'id': 13662, 'value': 'University of Central Arkansas', 'label': 'University of Central Arkansas' },
    { 'id': 7885, 'value': 'University of Central Asia', 'label': 'University of Central Asia' },
    { 'id': 13663, 'value': 'University of Central Florida', 'label': 'University of Central Florida' },
    { 'id': 4584, 'value': 'University of Central Lancashire', 'label': 'University of Central Lancashire' },
    { 'id': 3387, 'value': 'University of Central Lancashire, Cyprus', 'label': 'University of Central Lancashire, Cyprus' },
    { 'id': 13664, 'value': 'University of Central Missouri', 'label': 'University of Central Missouri' },
    { 'id': 13665, 'value': 'University of Central Oklahoma', 'label': 'University of Central Oklahoma' },
    { 'id': 10191, 'value': 'University of Central Punjab', 'label': 'University of Central Punjab' },
    { 'id': 13666, 'value': 'University of Charleston', 'label': 'University of Charleston' },
    { 'id': 1453, 'value': 'University of Chemical Technology and Metallurgy', 'label': 'University of Chemical Technology and Metallurgy' },
    { 'id': 4585, 'value': 'University of Chester', 'label': 'University of Chester' },
    { 'id': 13667, 'value': 'University of Chicago', 'label': 'University of Chicago' },
    { 'id': 520, 'value': 'University of Chicago Law School', 'label': 'University of Chicago Law School' },
    { 'id': 4586, 'value': 'University of Chichester', 'label': 'University of Chichester' },
    { 'id': 1326, 'value': 'University of Chittagong', 'label': 'University of Chittagong' },
    { 'id': 13668, 'value': 'University of Cincinnati', 'label': 'University of Cincinnati' },
    { 'id': 14214, 'value': 'University of Civilization', 'label': 'University of Civilization' },
    { 'id': 14451, 'value': 'University of Cologne', 'label': 'University of Cologne' },
    { 'id': 8316, 'value': 'University of Colombo', 'label': 'University of Colombo' },
    { 'id': 13669, 'value': 'University of Colorado Boulder', 'label': 'University of Colorado Boulder' },
    { 'id': 13670, 'value': 'University of Colorado Colorado Springs', 'label': 'University of Colorado Colorado Springs' },
    { 'id': 13671, 'value': 'University of Colorado Denver', 'label': 'University of Colorado Denver' },
    { 'id': 8561, 'value': 'University of Community Health, Magway', 'label': 'University of Community Health, Magway' },
    { 'id': 8562, 'value': 'University of Computer Studies, Mandalay', 'label': 'University of Computer Studies, Mandalay' },
    { 'id': 8563, 'value': 'University of Computer Studies, Yangon', 'label': 'University of Computer Studies, Yangon' },
    { 'id': 13672, 'value': 'University of Connecticut', 'label': 'University of Connecticut' },
    { 'id': 551, 'value': 'University of Copenhagen', 'label': 'University of Copenhagen' },
    { 'id': 1327, 'value': 'University of Creative Technology, Chittagong', 'label': 'University of Creative Technology, Chittagong' },
    { 'id': 4763, 'value': 'University of Crete', 'label': 'University of Crete' },
    { 'id': 4587, 'value': 'University of Cumbria', 'label': 'University of Cumbria' },
    { 'id': 12137, 'value': 'University of Customs and Finance', 'label': 'University of Customs and Finance' },
    { 'id': 3388, 'value': 'University of Cyprus', 'label': 'University of Cyprus' },
    { 'id': 13673, 'value': 'University of Dallas', 'label': 'University of Dallas' },
    { 'id': 11976, 'value': 'University of Dar es Salaam', 'label': 'University of Dar es Salaam' },
    { 'id': 13674, 'value': 'University of Dayton', 'label': 'University of Dayton' },
    { 'id': 13675, 'value': 'University of Delaware', 'label': 'University of Delaware' },
    { 'id': 6375, 'value': 'University of Delhi', 'label': 'University of Delhi' },
    { 'id': 8564, 'value': 'University of Dental Medicine, Mandalay', 'label': 'University of Dental Medicine, Mandalay' },
    { 'id': 8565, 'value': 'University of Dental Medicine, Yangon', 'label': 'University of Dental Medicine, Yangon' },
    { 'id': 76, 'value': 'University of Denver', 'label': 'University of Denver' },
    { 'id': 4588, 'value': 'University of Derby', 'label': 'University of Derby' },
    { 'id': 13676, 'value': 'University of Detroit Mercy', 'label': 'University of Detroit Mercy' },
    { 'id': 1328, 'value': 'University of Development Alternative', 'label': 'University of Development Alternative' },
    { 'id': 1329, 'value': 'University of Dhaka', 'label': 'University of Dhaka' },
    { 'id': 6479, 'value': 'University of Diyala', 'label': 'University of Diyala' },
    { 'id': 11090, 'value': 'University of Dongola', 'label': 'University of Dongola' },
    { 'id': 674, 'value': 'University of Dubai', 'label': 'University of Dubai' },
    { 'id': 13677, 'value': 'University of Dubuque', 'label': 'University of Dubuque' },
    { 'id': 4589, 'value': 'University of Dundee', 'label': 'University of Dundee' },
    { 'id': 14293, 'value': 'University of East and Southern Africa', 'label': 'University of East and Southern Africa' },
    { 'id': 4590, 'value': 'University of East Anglia', 'label': 'University of East Anglia' },
    { 'id': 7740, 'value': 'University of East Asia', 'label': 'University of East Asia' },
    { 'id': 11091, 'value': 'University of East Kordofan', 'label': 'University of East Kordofan' },
    { 'id': 4591, 'value': 'University of East London', 'label': 'University of East London' },
    { 'id': 7836, 'value': 'University of Eastern Africa, Baraton', 'label': 'University of Eastern Africa, Baraton' },
    { 'id': 10001, 'value': 'University of Eastern Philippines', 'label': 'University of Eastern Philippines' },
    { 'id': 1454, 'value': 'University of Economics - Varna', 'label': 'University of Economics - Varna' },
    { 'id': 14163, 'value': 'University of Economics and Law', 'label': 'University of Economics and Law' },
    { 'id': 14164, 'value': 'University of Economics Ho Chi Minh City', 'label': 'University of Economics Ho Chi Minh City' },
    { 'id': 837, 'value': 'University of Economy and Law after Avetik Mkrtchyan', 'label': 'University of Economy and Law after Avetik Mkrtchyan' },
    { 'id': 288, 'value': 'University of Edimburgh', 'label': 'University of Edimburgh' },
    { 'id': 10192, 'value': 'University of Education', 'label': 'University of Education' },
    { 'id': 4718, 'value': 'University of Education, Winneba', 'label': 'University of Education, Winneba' },
    { 'id': 11092, 'value': 'University of El Geneina', 'label': 'University of El Geneina' },
    { 'id': 11093, 'value': 'University of El Imam El Mahdi', 'label': 'University of El Imam El Mahdi' },
    { 'id': 7837, 'value': 'University of Eldoret', 'label': 'University of Eldoret' },
    { 'id': 2865, 'value': 'University of Electronic Science and Technology of China', 'label': 'University of Electronic Science and Technology of China' },
    { 'id': 7838, 'value': 'University of Embu', 'label': 'University of Embu' },
    { 'id': 4719, 'value': 'University of Energy and Natural Resources', 'label': 'University of Energy and Natural Resources' },
    { 'id': 6376, 'value': 'University of Engineering and Management, Kolkata', 'label': 'University of Engineering and Management, Kolkata' },
    { 'id': 10193, 'value': 'University of Engineering and Technology, Lahore', 'label': 'University of Engineering and Technology, Lahore' },
    { 'id': 10194, 'value': 'University of Engineering and Technology, Peshawar', 'label': 'University of Engineering and Technology, Peshawar' },
    { 'id': 10195, 'value': 'University of Engineering and Technology, Taxila', 'label': 'University of Engineering and Technology, Taxila' },
    { 'id': 299, 'value': 'University of Essex', 'label': 'University of Essex' },
    { 'id': 13678, 'value': 'University of Evansville', 'label': 'University of Evansville' },
    { 'id': 443, 'value': 'University of Exeter', 'label': 'University of Exeter' },
    { 'id': 6480, 'value': 'University of Fallujah', 'label': 'University of Fallujah' },
    { 'id': 8649, 'value': 'University of Finance and Economics', 'label': 'University of Finance and Economics' },
    { 'id': 1455, 'value': 'University of Finance, Business and Entrepreneurship', 'label': 'University of Finance, Business and Entrepreneurship' },
    { 'id': 13679, 'value': 'University of Florida', 'label': 'University of Florida' },
    { 'id': 1456, 'value': 'University of Food Technologies', 'label': 'University of Food Technologies' },
    { 'id': 8263, 'value': 'University of Foreign Languages and Business Career', 'label': 'University of Foreign Languages and Business Career' },
    { 'id': 8566, 'value': 'University of Foreign Languages, Mandalay', 'label': 'University of Foreign Languages, Mandalay' },
    { 'id': 8567, 'value': 'University of Foreign Languages, Yangon', 'label': 'University of Foreign Languages, Yangon' },
    { 'id': 1457, 'value': 'University of Forestry', 'label': 'University of Forestry' },
    { 'id': 8568, 'value': 'University of Forestry and Environmental Science, Yezin', 'label': 'University of Forestry and Environmental Science, Yezin' },
    { 'id': 14233, 'value': 'University of Fort Hare', 'label': 'University of Fort Hare' },
    { 'id': 675, 'value': 'University of Fujairah', 'label': 'University of Fujairah' },
    { 'id': 7741, 'value': 'University of Fukui', 'label': 'University of Fukui' },
    { 'id': 11094, 'value': 'University of Gadarif', 'label': 'University of Gadarif' },
    { 'id': 11095, 'value': 'University of Garden City', 'label': 'University of Garden City' },
    { 'id': 6481, 'value': 'University of Garmian', 'label': 'University of Garmian' },
    { 'id': 6777, 'value': 'University of Garmsar', 'label': 'University of Garmsar' },
    { 'id': 13680, 'value': 'University of Georgia', 'label': 'University of Georgia' },
    { 'id': 11096, 'value': 'University of Gezira', 'label': 'University of Gezira' },
    { 'id': 4720, 'value': 'University of Ghana', 'label': 'University of Ghana' },
    { 'id': 8406, 'value': 'University of Gharyan', 'label': 'University of Gharyan' },
    { 'id': 11008, 'value': 'University of Gitwe', 'label': 'University of Gitwe' },
    { 'id': 279, 'value': 'University of Glasgow', 'label': 'University of Glasgow' },
    { 'id': 11009, 'value': 'University of Global Health Equity', 'label': 'University of Global Health Equity' },
    { 'id': 1330, 'value': 'University of Global Village', 'label': 'University of Global Village' },
    { 'id': 4592, 'value': 'University of Gloucestershire', 'label': 'University of Gloucestershire' },
    { 'id': 6778, 'value': 'University of Gonabad', 'label': 'University of Gonabad' },
    { 'id': 4200, 'value': 'University of Gondar', 'label': 'University of Gondar' },
    { 'id': 9813, 'value': 'University of Goroka', 'label': 'University of Goroka' },
    { 'id': 6377, 'value': 'University of Gour Banga', 'label': 'University of Gour Banga' },
    { 'id': 311, 'value': 'University of Greenwich', 'label': 'University of Greenwich' },
    { 'id': 127, 'value': 'University of Groningen', 'label': 'University of Groningen' },
    { 'id': 4787, 'value': 'University of Guam', 'label': 'University of Guam' },
    { 'id': 230, 'value': 'University of Guelph', 'label': 'University of Guelph' },
    { 'id': 10196, 'value': 'University of Gujrat', 'label': 'University of Gujrat' },
    { 'id': 4795, 'value': 'University of Guyana', 'label': 'University of Guyana' },
    { 'id': 11052, 'value': 'University of Ha\'il', 'label': 'University of Ha\'il' },
    { 'id': 11051, 'value': 'University of Hafr Al Batin', 'label': 'University of Hafr Al Batin' },
    { 'id': 14165, 'value': 'University of Hai Duong', 'label': 'University of Hai Duong' },
    { 'id': 5563, 'value': 'University of Haifa', 'label': 'University of Haifa' },
    { 'id': 6482, 'value': 'University of Halabja', 'label': 'University of Halabja' },
    { 'id': 11353, 'value': 'University of Hama', 'label': 'University of Hama' },
    { 'id': 10197, 'value': 'University of Haripur', 'label': 'University of Haripur' },
    { 'id': 13681, 'value': 'University of Hartford', 'label': 'University of Hartford' },
    { 'id': 13682, 'value': 'University of Hawaii at Hilo', 'label': 'University of Hawaii at Hilo' },
    { 'id': 13683, 'value': 'University of Hawaii at Manoa', 'label': 'University of Hawaii at Manoa' },
    { 'id': 13684, 'value': 'University of Hawaii-West Oahu', 'label': 'University of Hawaii-West Oahu' },
    { 'id': 4721, 'value': 'University of Health and Allied Sciences', 'label': 'University of Health and Allied Sciences' },
    { 'id': 777, 'value': 'University of Health Sciences Antigua', 'label': 'University of Health Sciences Antigua' },
    { 'id': 10198, 'value': 'University of Health Sciences, Lahore', 'label': 'University of Health Sciences, Lahore' },
    { 'id': 14399, 'value': 'University of Helsinki', 'label': 'University of Helsinki' },
    { 'id': 4593, 'value': 'University of Hertfordshire', 'label': 'University of Hertfordshire' },
    { 'id': 13685, 'value': 'University of Holy Cross', 'label': 'University of Holy Cross' },
    { 'id': 6378, 'value': 'University of Horticultural Sciences, Bagalkot', 'label': 'University of Horticultural Sciences, Bagalkot' },
    { 'id': 13686, 'value': 'University of Houston', 'label': 'University of Houston' },
    { 'id': 13687, 'value': 'University of Houston-Clear Lake', 'label': 'University of Houston-Clear Lake' },
    { 'id': 13688, 'value': 'University of Houston-Downtown', 'label': 'University of Houston-Downtown' },
    { 'id': 13689, 'value': 'University of Houston-Victoria', 'label': 'University of Houston-Victoria' },
    { 'id': 4594, 'value': 'University of Huddersfield', 'label': 'University of Huddersfield' },
    { 'id': 7742, 'value': 'University of Human Arts and Sciences', 'label': 'University of Human Arts and Sciences' },
    { 'id': 6483, 'value': 'University of Human Development', 'label': 'University of Human Development' },
    { 'id': 7743, 'value': 'University of Human Environments', 'label': 'University of Human Environments' },
    { 'id': 10952, 'value': 'University of Humanities', 'label': 'University of Humanities' },
    { 'id': 6379, 'value': 'University of Hyderabad', 'label': 'University of Hyderabad' },
    { 'id': 7744, 'value': 'University of Hyogo', 'label': 'University of Hyogo' },
    { 'id': 9441, 'value': 'University of Ibadan', 'label': 'University of Ibadan' },
    { 'id': 13690, 'value': 'University of Idaho', 'label': 'University of Idaho' },
    { 'id': 165, 'value': 'University of Illinois at Chicago', 'label': 'University of Illinois at Chicago' },
    { 'id': 13691, 'value': 'University of Illinois at Springfield', 'label': 'University of Illinois at Springfield' },
    { 'id': 13692, 'value': 'University of Illinois at Urbana-Champaign', 'label': 'University of Illinois at Urbana-Champaign' },
    { 'id': 10002, 'value': 'University of Iloilo - PHINMA', 'label': 'University of Iloilo - PHINMA' },
    { 'id': 9442, 'value': 'University of Ilorin', 'label': 'University of Ilorin' },
    { 'id': 8141, 'value': 'University of Incheon', 'label': 'University of Incheon' },
    { 'id': 13693, 'value': 'University of Indianapolis', 'label': 'University of Indianapolis' },
    { 'id': 6484, 'value': 'University of Information Technology and Communications', 'label': 'University of Information Technology and Communications' },
    { 'id': 1331, 'value': 'University of Information Technology and Sciences', 'label': 'University of Information Technology and Sciences' },
    { 'id': 8264, 'value': 'University of Innovation and Technology of Western Kazakhstan', 'label': 'University of Innovation and Technology of Western Kazakhstan' },
    { 'id': 8265, 'value': 'University of International Business', 'label': 'University of International Business' },
    { 'id': 2866, 'value': 'University of International Business and Economics', 'label': 'University of International Business and Economics' },
    { 'id': 838, 'value': 'University of International Economic Relations', 'label': 'University of International Economic Relations' },
    { 'id': 2867, 'value': 'University of International Relations', 'label': 'University of International Relations' },
    { 'id': 4764, 'value': 'University of Ioannina', 'label': 'University of Ioannina' },
    { 'id': 13694, 'value': 'University of Iowa', 'label': 'University of Iowa' },
    { 'id': 11977, 'value': 'University of Iringa', 'label': 'University of Iringa' },
    { 'id': 6779, 'value': 'University of Isfahan', 'label': 'University of Isfahan' },
    { 'id': 14215, 'value': 'University of Islamic Sciences Al Hudaydah', 'label': 'University of Islamic Sciences Al Hudaydah' },
    { 'id': 8317, 'value': 'University of Jaffna', 'label': 'University of Jaffna' },
    { 'id': 13695, 'value': 'University of Jamestown', 'label': 'University of Jamestown' },
    { 'id': 6380, 'value': 'University of Jammu', 'label': 'University of Jammu' },
    { 'id': 11053, 'value': 'University of Jeddah', 'label': 'University of Jeddah' },
    { 'id': 2868, 'value': 'University of Jinan', 'label': 'University of Jinan' },
    { 'id': 6780, 'value': 'University of Jiroft', 'label': 'University of Jiroft' },
    { 'id': 14234, 'value': 'University of Johannesburg', 'label': 'University of Johannesburg' },
    { 'id': 7059, 'value': 'University of Jordan', 'label': 'University of Jordan' },
    { 'id': 9443, 'value': 'University of Jos', 'label': 'University of Jos' },
    { 'id': 11292, 'value': 'University of Juba', 'label': 'University of Juba' },
    { 'id': 7839, 'value': 'University of Kabianga', 'label': 'University of Kabianga' },
    { 'id': 11354, 'value': 'University of Kalamoon', 'label': 'University of Kalamoon' },
    { 'id': 6381, 'value': 'University of Kalyani', 'label': 'University of Kalyani' },
    { 'id': 11938, 'value': 'University of Kang Ning', 'label': 'University of Kang Ning' },
    { 'id': 13696, 'value': 'University of Kansas', 'label': 'University of Kansas' },
    { 'id': 10199, 'value': 'University of Karachi', 'label': 'University of Karachi' },
    { 'id': 6781, 'value': 'University of Kashan', 'label': 'University of Kashan' },
    { 'id': 6382, 'value': 'University of Kashmir', 'label': 'University of Kashmir' },
    { 'id': 11097, 'value': 'University of Kassala', 'label': 'University of Kassala' },
    { 'id': 8318, 'value': 'University of Kelaniya', 'label': 'University of Kelaniya' },
    { 'id': 344, 'value': 'UNIVERSITY OF KENT', 'label': 'UNIVERSITY OF KENT' },
    { 'id': 13697, 'value': 'University of Kentucky', 'label': 'University of Kentucky' },
    { 'id': 6383, 'value': 'University of Kerala', 'label': 'University of Kerala' },
    { 'id': 6485, 'value': 'University of Kerbala', 'label': 'University of Kerbala' },
    { 'id': 11098, 'value': 'University of Khartoum', 'label': 'University of Khartoum' },
    { 'id': 11010, 'value': 'University of Kibungo', 'label': 'University of Kibungo' },
    { 'id': 11011, 'value': 'University of Kigali', 'label': 'University of Kigali' },
    { 'id': 6486, 'value': 'University of Kirkuk', 'label': 'University of Kirkuk' },
    { 'id': 7745, 'value': 'University of Kochi', 'label': 'University of Kochi' },
    { 'id': 11099, 'value': 'University of Kordofan', 'label': 'University of Kordofan' },
    { 'id': 6384, 'value': 'University of Kota', 'label': 'University of Kota' },
    { 'id': 10200, 'value': 'University of Kotli Azad Jammu and Kashmir', 'label': 'University of Kotli Azad Jammu and Kashmir' },
    { 'id': 6487, 'value': 'University of Kufa', 'label': 'University of Kufa' },
    { 'id': 6782, 'value': 'University of Kurdistan', 'label': 'University of Kurdistan' },
    { 'id': 6488, 'value': 'University of Kurdistan Hewlêr', 'label': 'University of Kurdistan Hewlêr' },
    { 'id': 14235, 'value': 'University of KwaZulu-Natal', 'label': 'University of KwaZulu-Natal' },
    { 'id': 10003, 'value': 'University of La Salette', 'label': 'University of La Salette' },
    { 'id': 13698, 'value': 'University of La Verne', 'label': 'University of La Verne' },
    { 'id': 9444, 'value': 'University of Lagos', 'label': 'University of Lagos' },
    { 'id': 11012, 'value': 'University of Lay Adventists of Kigali', 'label': 'University of Lay Adventists of Kigali' },
    { 'id': 300, 'value': 'University of Leeds', 'label': 'University of Leeds' },
    { 'id': 347, 'value': 'University of Leicester', 'label': 'University of Leicester' },
    { 'id': 1924, 'value': 'University of Lethbridge', 'label': 'University of Lethbridge' },
    { 'id': 1332, 'value': 'University of Liberal Arts Bangladesh', 'label': 'University of Liberal Arts Bangladesh' },
    { 'id': 8329, 'value': 'University of Liberia', 'label': 'University of Liberia' },
    { 'id': 1458, 'value': 'University of Library Studies and Information Technologies', 'label': 'University of Library Studies and Information Technologies' },
    { 'id': 8696, 'value': 'University of Lilongwe', 'label': 'University of Lilongwe' },
    { 'id': 5529, 'value': 'University of Limerick', 'label': 'University of Limerick' },
    { 'id': 14236, 'value': 'University of Limpopo', 'label': 'University of Limpopo' },
    { 'id': 4595, 'value': 'University of Lincoln', 'label': 'University of Lincoln' },
    { 'id': 4596, 'value': 'University of Liverpool', 'label': 'University of Liverpool' },
    { 'id': 524, 'value': 'University of Liverpool,', 'label': 'University of Liverpool,' },
    { 'id': 8697, 'value': 'University of Livingstonia', 'label': 'University of Livingstonia' },
    { 'id': 107, 'value': 'University of London', 'label': 'University of London' },
    { 'id': 10201, 'value': 'University of Loralai', 'label': 'University of Loralai' },
    { 'id': 13699, 'value': 'University of Louisiana at Lafayette', 'label': 'University of Louisiana at Lafayette' },
    { 'id': 13700, 'value': 'University of Louisiana at Monroe', 'label': 'University of Louisiana at Monroe' },
    { 'id': 13701, 'value': 'University of Louisville', 'label': 'University of Louisville' },
    { 'id': 6385, 'value': 'University of Lucknow', 'label': 'University of Lucknow' },
    { 'id': 14294, 'value': 'University of Lusaka', 'label': 'University of Lusaka' },
    { 'id': 10004, 'value': 'University of Luzon', 'label': 'University of Luzon' },
    { 'id': 13702, 'value': 'University of Lynchburg', 'label': 'University of Lynchburg' },
    { 'id': 4765, 'value': 'University of Macedonia', 'label': 'University of Macedonia' },
    { 'id': 6386, 'value': 'University of Madras', 'label': 'University of Madras' },
    { 'id': 9445, 'value': 'University of Maiduguri', 'label': 'University of Maiduguri' },
    { 'id': 13703, 'value': 'University of Maine at Augusta', 'label': 'University of Maine at Augusta' },
    { 'id': 13704, 'value': 'University of Maine at Farmington', 'label': 'University of Maine at Farmington' },
    { 'id': 13705, 'value': 'University of Maine at Fort Kent', 'label': 'University of Maine at Fort Kent' },
    { 'id': 13706, 'value': 'University of Maine at Machias', 'label': 'University of Maine at Machias' },
    { 'id': 13707, 'value': 'University of Maine at Presque Isle', 'label': 'University of Maine at Presque Isle' },
    { 'id': 11223, 'value': 'University of Makeni', 'label': 'University of Makeni' },
    { 'id': 10202, 'value': 'University of Malakand', 'label': 'University of Malakand' },
    { 'id': 8698, 'value': 'University of Malawi', 'label': 'University of Malawi' },
    { 'id': 8671, 'value': 'University of Malta', 'label': 'University of Malta' },
    { 'id': 7933, 'value': 'University of Management and Economics', 'label': 'University of Management and Economics' },
    { 'id': 10203, 'value': 'University of Management and Technology', 'label': 'University of Management and Technology' },
    { 'id': 426, 'value': 'University of Manchester', 'label': 'University of Manchester' },
    { 'id': 1925, 'value': 'University of Manitoba', 'label': 'University of Manitoba' },
    { 'id': 416, 'value': 'University of Mannheim', 'label': 'University of Mannheim' },
    { 'id': 6783, 'value': 'University of Maragheh', 'label': 'University of Maragheh' },
    { 'id': 7746, 'value': 'University of Marketing and Distribution Sciences', 'label': 'University of Marketing and Distribution Sciences' },
    { 'id': 13708, 'value': 'University of Mary', 'label': 'University of Mary' },
    { 'id': 13709, 'value': 'University of Mary Hardin-Baylor', 'label': 'University of Mary Hardin-Baylor' },
    { 'id': 13710, 'value': 'University of Mary Washington', 'label': 'University of Mary Washington' },
    { 'id': 13711, 'value': 'University of Maryland', 'label': 'University of Maryland' },
    { 'id': 13712, 'value': 'University of Maryland Eastern Shore', 'label': 'University of Maryland Eastern Shore' },
    { 'id': 13713, 'value': 'University of Maryland, Baltimore', 'label': 'University of Maryland, Baltimore' },
    { 'id': 13714, 'value': 'University of Maryland, Baltimore County', 'label': 'University of Maryland, Baltimore County' },
    { 'id': 13715, 'value': 'University of Massachusetts Amherst', 'label': 'University of Massachusetts Amherst' },
    { 'id': 13716, 'value': 'University of Massachusetts Boston', 'label': 'University of Massachusetts Boston' },
    { 'id': 13717, 'value': 'University of Massachusetts Dartmouth', 'label': 'University of Massachusetts Dartmouth' },
    { 'id': 13718, 'value': 'University of Massachusetts Lowell', 'label': 'University of Massachusetts Lowell' },
    { 'id': 13719, 'value': 'University of Massachusetts Medical School', 'label': 'University of Massachusetts Medical School' },
    { 'id': 8674, 'value': 'University of Mauritius', 'label': 'University of Mauritius' },
    { 'id': 6784, 'value': 'University of Mazandaran', 'label': 'University of Mazandaran' },
    { 'id': 9446, 'value': 'University of Medical Sciences', 'label': 'University of Medical Sciences' },
    { 'id': 11100, 'value': 'University of Medical Sciences and Technology', 'label': 'University of Medical Sciences and Technology' },
    { 'id': 8569, 'value': 'University of Medical Technology, Mandalay', 'label': 'University of Medical Technology, Mandalay' },
    { 'id': 8570, 'value': 'University of Medical Technology, Yangon', 'label': 'University of Medical Technology, Yangon' },
    { 'id': 8571, 'value': 'University of Medicine 1, Yangon', 'label': 'University of Medicine 1, Yangon' },
    { 'id': 8572, 'value': 'University of Medicine 2, Yangon', 'label': 'University of Medicine 2, Yangon' },
    { 'id': 8573, 'value': 'University of Medicine, Magway', 'label': 'University of Medicine, Magway' },
    { 'id': 8574, 'value': 'University of Medicine, Mandalay', 'label': 'University of Medicine, Mandalay' },
    { 'id': 142, 'value': 'University of Miami', 'label': 'University of Miami' },
    { 'id': 13720, 'value': 'University of Michigan', 'label': 'University of Michigan' },
    { 'id': 13721, 'value': 'University of Michigan-Dearborn', 'label': 'University of Michigan-Dearborn' },
    { 'id': 13722, 'value': 'University of Michigan-Flint', 'label': 'University of Michigan-Flint' },
    { 'id': 10005, 'value': 'University of Mindanao', 'label': 'University of Mindanao' },
    { 'id': 4722, 'value': 'University of Mines and Technology', 'label': 'University of Mines and Technology' },
    { 'id': 248, 'value': 'University of Minho', 'label': 'University of Minho' },
    { 'id': 1459, 'value': 'University of Mining and Geology St Ivan Rilski', 'label': 'University of Mining and Geology St Ivan Rilski' },
    { 'id': 13723, 'value': 'University of Minnesota', 'label': 'University of Minnesota' },
    { 'id': 6489, 'value': 'University of Misan', 'label': 'University of Misan' },
    { 'id': 13724, 'value': 'University of Mississippi', 'label': 'University of Mississippi' },
    { 'id': 13725, 'value': 'University of Mississippi Medical Center', 'label': 'University of Mississippi Medical Center' },
    { 'id': 13726, 'value': 'University of Missouri', 'label': 'University of Missouri' },
    { 'id': 13727, 'value': 'University of Missouri-Kansas City', 'label': 'University of Missouri-Kansas City' },
    { 'id': 13728, 'value': 'University of Missouri-St. Louis', 'label': 'University of Missouri-St. Louis' },
    { 'id': 7747, 'value': 'University of Miyazaki', 'label': 'University of Miyazaki' },
    { 'id': 9447, 'value': 'University of Mkar', 'label': 'University of Mkar' },
    { 'id': 13729, 'value': 'University of Mobile', 'label': 'University of Mobile' },
    { 'id': 14216, 'value': 'University of Modern Sciences', 'label': 'University of Modern Sciences' },
    { 'id': 6785, 'value': 'University of Mohaghegh Ardabili', 'label': 'University of Mohaghegh Ardabili' },
    { 'id': 314, 'value': 'University of Monaco', 'label': 'University of Monaco' },
    { 'id': 13730, 'value': 'University of Montevallo', 'label': 'University of Montevallo' },
    { 'id': 8319, 'value': 'University of Moratuwa', 'label': 'University of Moratuwa' },
    { 'id': 6490, 'value': 'University of Mosul', 'label': 'University of Mosul' },
    { 'id': 13731, 'value': 'University of Mount Olive', 'label': 'University of Mount Olive' },
    { 'id': 13732, 'value': 'University of Mount Union', 'label': 'University of Mount Union' },
    { 'id': 14237, 'value': 'University of Mpumalanga', 'label': 'University of Mpumalanga' },
    { 'id': 6387, 'value': 'University of Mumbai', 'label': 'University of Mumbai' },
    { 'id': 6388, 'value': 'University of Mysore', 'label': 'University of Mysore' },
    { 'id': 7840, 'value': 'University of Nairobi', 'label': 'University of Nairobi' },
    { 'id': 9296, 'value': 'University of Namibia', 'label': 'University of Namibia' },
    { 'id': 1460, 'value': 'University of National and World Economy', 'label': 'University of National and World Economy' },
    { 'id': 232, 'value': 'University of Navarra', 'label': 'University of Navarra' },
    { 'id': 13733, 'value': 'University of Nebraska at Kearney', 'label': 'University of Nebraska at Kearney' },
    { 'id': 13734, 'value': 'University of Nebraska at Omaha', 'label': 'University of Nebraska at Omaha' },
    { 'id': 534, 'value': 'University of Nebraska Kearney', 'label': 'University of Nebraska Kearney' },
    { 'id': 13735, 'value': 'University of Nebraska Medical Center', 'label': 'University of Nebraska Medical Center' },
    { 'id': 13736, 'value': 'University of Nebraska-Lincoln', 'label': 'University of Nebraska-Lincoln' },
    { 'id': 10006, 'value': 'University of Negros Occidental - Recoletos', 'label': 'University of Negros Occidental - Recoletos' },
    { 'id': 13737, 'value': 'University of Nevada, Las Vegas', 'label': 'University of Nevada, Las Vegas' },
    { 'id': 13738, 'value': 'University of Nevada, Reno', 'label': 'University of Nevada, Reno' },
    { 'id': 1926, 'value': 'University of New Brunswick', 'label': 'University of New Brunswick' },
    { 'id': 13739, 'value': 'University of New England', 'label': 'University of New England' },
    { 'id': 1099, 'value': 'University of New England, Australia', 'label': 'University of New England, Australia' },
    { 'id': 13740, 'value': 'University of New Hampshire', 'label': 'University of New Hampshire' },
    { 'id': 178, 'value': 'University of New Haven', 'label': 'University of New Haven' },
    { 'id': 13741, 'value': 'University of New Mexico', 'label': 'University of New Mexico' },
    { 'id': 13742, 'value': 'University of New Orleans', 'label': 'University of New Orleans' },
    { 'id': 3418, 'value': 'University of New York in Prague', 'label': 'University of New York in Prague' },
    { 'id': 6786, 'value': 'University of Neyshabur', 'label': 'University of Neyshabur' },
    { 'id': 3389, 'value': 'University of Nicosia', 'label': 'University of Nicosia' },
    { 'id': 9448, 'value': 'University of Nigeria', 'label': 'University of Nigeria' },
    { 'id': 13743, 'value': 'University of North Alabama', 'label': 'University of North Alabama' },
    { 'id': 6389, 'value': 'University of North Bengal', 'label': 'University of North Bengal' },
    { 'id': 13744, 'value': 'University of North Carolina at Asheville', 'label': 'University of North Carolina at Asheville' },
    { 'id': 13745, 'value': 'University of North Carolina at Chapel Hill', 'label': 'University of North Carolina at Chapel Hill' },
    { 'id': 13746, 'value': 'University of North Carolina at Charlotte', 'label': 'University of North Carolina at Charlotte' },
    { 'id': 13747, 'value': 'University of North Carolina at Greensboro', 'label': 'University of North Carolina at Greensboro' },
    { 'id': 13748, 'value': 'University of North Carolina at Pembroke', 'label': 'University of North Carolina at Pembroke' },
    { 'id': 13749, 'value': 'University of North Carolina School of the Arts', 'label': 'University of North Carolina School of the Arts' },
    { 'id': 13750, 'value': 'University of North Carolina Wilmington', 'label': 'University of North Carolina Wilmington' },
    { 'id': 13751, 'value': 'University of North Dakota', 'label': 'University of North Dakota' },
    { 'id': 13752, 'value': 'University of North Florida', 'label': 'University of North Florida' },
    { 'id': 13753, 'value': 'University of North Georgia', 'label': 'University of North Georgia' },
    { 'id': 13754, 'value': 'University of North Texas', 'label': 'University of North Texas' },
    { 'id': 13755, 'value': 'University of North Texas Health Science Center', 'label': 'University of North Texas Health Science Center' },
    { 'id': 11293, 'value': 'University of Northern Bahr El-Ghazal', 'label': 'University of Northern Bahr El-Ghazal' },
    { 'id': 1927, 'value': 'University of Northern British Columbia', 'label': 'University of Northern British Columbia' },
    { 'id': 13756, 'value': 'University of Northern Colorado', 'label': 'University of Northern Colorado' },
    { 'id': 13757, 'value': 'University of Northern Iowa', 'label': 'University of Northern Iowa' },
    { 'id': 10007, 'value': 'University of Northern Philippines', 'label': 'University of Northern Philippines' },
    { 'id': 13758, 'value': 'University of Northwestern - St. Paul', 'label': 'University of Northwestern - St. Paul' },
    { 'id': 13759, 'value': 'University of Northwestern Ohio', 'label': 'University of Northwestern Ohio' },
    { 'id': 13760, 'value': 'University of Notre Dame', 'label': 'University of Notre Dame' },
    { 'id': 369, 'value': 'University of Notthingam', 'label': 'University of Notthingam' },
    { 'id': 10008, 'value': 'University of Nueva Caceres', 'label': 'University of Nueva Caceres' },
    { 'id': 8575, 'value': 'University of Nursing, Mandalay', 'label': 'University of Nursing, Mandalay' },
    { 'id': 8576, 'value': 'University of Nursing, Yangon', 'label': 'University of Nursing, Yangon' },
    { 'id': 11101, 'value': 'University of Nyala', 'label': 'University of Nyala' },
    { 'id': 7748, 'value': 'University of Occupational and Environmental Health, Japan', 'label': 'University of Occupational and Environmental Health, Japan' },
    { 'id': 13761, 'value': 'University of Oregon', 'label': 'University of Oregon' },
    { 'id': 9605, 'value': 'University of Otago', 'label': 'University of Otago' },
    { 'id': 1928, 'value': 'University of Ottawa', 'label': 'University of Ottawa' },
    { 'id': 192, 'value': 'University of Oxford', 'label': 'University of Oxford' },
    { 'id': 10402, 'value': 'University of Palestine', 'label': 'University of Palestine' },
    { 'id': 10009, 'value': 'University of Pangasinan', 'label': 'University of Pangasinan' },
    { 'id': 9814, 'value': 'University of Papua New Guinea', 'label': 'University of Papua New Guinea' },
    { 'id': 6390, 'value': 'University of Patanjali', 'label': 'University of Patanjali' },
    { 'id': 4766, 'value': 'University of Patras', 'label': 'University of Patras' },
    { 'id': 4767, 'value': 'University of Peloponnese', 'label': 'University of Peloponnese' },
    { 'id': 13762, 'value': 'University of Pennsylvania', 'label': 'University of Pennsylvania' },
    { 'id': 8320, 'value': 'University of Peradeniya', 'label': 'University of Peradeniya' },
    { 'id': 10010, 'value': 'University of Perpetual Help System DALTA', 'label': 'University of Perpetual Help System DALTA' },
    { 'id': 10011, 'value': 'University of Perpetual Help System Jonelta', 'label': 'University of Perpetual Help System Jonelta' },
    { 'id': 10204, 'value': 'University of Peshawar', 'label': 'University of Peshawar' },
    { 'id': 7060, 'value': 'University of Petra', 'label': 'University of Petra' },
    { 'id': 6391, 'value': 'University of Petroleum and Energy Studies', 'label': 'University of Petroleum and Energy Studies' },
    { 'id': 8650, 'value': 'University of Pharmaceutical Science', 'label': 'University of Pharmaceutical Science' },
    { 'id': 8577, 'value': 'University of Pharmacy, Mandalay', 'label': 'University of Pharmacy, Mandalay' },
    { 'id': 8578, 'value': 'University of Pharmacy, Yangon', 'label': 'University of Pharmacy, Yangon' },
    { 'id': 11491, 'value': 'University of Phayao', 'label': 'University of Phayao' },
    { 'id': 13763, 'value': 'University of Pikeville', 'label': 'University of Pikeville' },
    { 'id': 4768, 'value': 'University of Piraeus', 'label': 'University of Piraeus' },
    { 'id': 13764, 'value': 'University of Pittsburgh', 'label': 'University of Pittsburgh' },
    { 'id': 1461, 'value': 'University of Plovdiv Paisii Hilendarski', 'label': 'University of Plovdiv Paisii Hilendarski' },
    { 'id': 189, 'value': 'University of Plymouth', 'label': 'University of Plymouth' },
    { 'id': 9449, 'value': 'University of Port Harcourt', 'label': 'University of Port Harcourt' },
    { 'id': 13765, 'value': 'University of Portland', 'label': 'University of Portland' },
    { 'id': 4597, 'value': 'University of Portsmouth', 'label': 'University of Portsmouth' },
    { 'id': 14238, 'value': 'University of Pretoria', 'label': 'University of Pretoria' },
    { 'id': 1929, 'value': 'University of Prince Edward Island', 'label': 'University of Prince Edward Island' },
    { 'id': 4723, 'value': 'University of Professional Studies, Accra', 'label': 'University of Professional Studies, Accra' },
    { 'id': 13766, 'value': 'University of Providence', 'label': 'University of Providence' },
    { 'id': 8579, 'value': 'University of Public Health, Yangon', 'label': 'University of Public Health, Yangon' },
    { 'id': 13767, 'value': 'University of Puget Sound', 'label': 'University of Puget Sound' },
    { 'id': 7934, 'value': 'University of Puthisastra', 'label': 'University of Puthisastra' },
    { 'id': 8580, 'value': 'University of Pyay', 'label': 'University of Pyay' },
    { 'id': 6787, 'value': 'University of Qom', 'label': 'University of Qom' },
    { 'id': 6788, 'value': 'University of Quran and Hadith', 'label': 'University of Quran and Hadith' },
    { 'id': 6392, 'value': 'University of Rajasthan', 'label': 'University of Rajasthan' },
    { 'id': 6491, 'value': 'University of Raparin', 'label': 'University of Raparin' },
    { 'id': 64, 'value': 'University of Reading', 'label': 'University of Reading' },
    { 'id': 13768, 'value': 'University of Redlands', 'label': 'University of Redlands' },
    { 'id': 1930, 'value': 'University of Regina', 'label': 'University of Regina' },
    { 'id': 13769, 'value': 'University of Rhode Island', 'label': 'University of Rhode Island' },
    { 'id': 13770, 'value': 'University of Richmond', 'label': 'University of Richmond' },
    { 'id': 13771, 'value': 'University of Rio Grande', 'label': 'University of Rio Grande' },
    { 'id': 10012, 'value': 'University of Rizal System', 'label': 'University of Rizal System' },
    { 'id': 13772, 'value': 'University of Rochester', 'label': 'University of Rochester' },
    { 'id': 4598, 'value': 'University of Roehampton', 'label': 'University of Roehampton' },
    { 'id': 8321, 'value': 'University of Ruhuna', 'label': 'University of Ruhuna' },
    { 'id': 11013, 'value': 'University of Rwanda', 'label': 'University of Rwanda' },
    { 'id': 4090, 'value': 'University of Sadat City', 'label': 'University of Sadat City' },
    { 'id': 214, 'value': 'University of saint andrews', 'label': 'University of saint andrews' },
    { 'id': 13773, 'value': 'University of Saint Francis', 'label': 'University of Saint Francis' },
    { 'id': 213, 'value': 'University of Saint Gallen', 'label': 'University of Saint Gallen' },
    { 'id': 13774, 'value': 'University of Saint Joseph', 'label': 'University of Saint Joseph' },
    { 'id': 8661, 'value': 'University of Saint Joseph, Macau', 'label': 'University of Saint Joseph, Macau' },
    { 'id': 10013, 'value': 'University of Saint Louis', 'label': 'University of Saint Louis' },
    { 'id': 13775, 'value': 'University of Saint Mary', 'label': 'University of Saint Mary' },
    { 'id': 4599, 'value': 'University of Salford', 'label': 'University of Salford' },
    { 'id': 6492, 'value': 'University of Samarra', 'label': 'University of Samarra' },
    { 'id': 10014, 'value': 'University of San Agustin', 'label': 'University of San Agustin' },
    { 'id': 10015, 'value': 'University of San Carlos', 'label': 'University of San Carlos' },
    { 'id': 13776, 'value': 'University of San Diego', 'label': 'University of San Diego' },
    { 'id': 250, 'value': 'University of San Francisco', 'label': 'University of San Francisco' },
    { 'id': 10016, 'value': 'University of San Jose-Recoletos', 'label': 'University of San Jose-Recoletos' },
    { 'id': 10017, 'value': 'University of Santo Tomas', 'label': 'University of Santo Tomas' },
    { 'id': 10205, 'value': 'University of Sargodha', 'label': 'University of Sargodha' },
    { 'id': 1931, 'value': 'University of Saskatchewan', 'label': 'University of Saskatchewan' },
    { 'id': 6789, 'value': 'University of Science and Arts', 'label': 'University of Science and Arts' },
    { 'id': 13777, 'value': 'University of Science and Arts of Oklahoma', 'label': 'University of Science and Arts of Oklahoma' },
    { 'id': 11102, 'value': 'University of Science and Technology', 'label': 'University of Science and Technology' },
    { 'id': 4091, 'value': 'University of Science and Technology at Zewail City', 'label': 'University of Science and Technology at Zewail City' },
    { 'id': 2869, 'value': 'University of Science and Technology Beijing', 'label': 'University of Science and Technology Beijing' },
    { 'id': 1333, 'value': 'University of Science and Technology Chittagong', 'label': 'University of Science and Technology Chittagong' },
    { 'id': 8142, 'value': 'University of Science and Technology Korea', 'label': 'University of Science and Technology Korea' },
    { 'id': 2870, 'value': 'University of Science and Technology Liaoning', 'label': 'University of Science and Technology Liaoning' },
    { 'id': 2871, 'value': 'University of Science and Technology of China', 'label': 'University of Science and Technology of China' },
    { 'id': 6790, 'value': 'University of Science and Technology of Mazandaran', 'label': 'University of Science and Technology of Mazandaran' },
    { 'id': 10018, 'value': 'University of Science and Technology of Southern Philippines', 'label': 'University of Science and Technology of Southern Philippines' },
    { 'id': 10206, 'value': 'University of Science and Technology, Bannu', 'label': 'University of Science and Technology, Bannu' },
    { 'id': 6393, 'value': 'University of Science and Technology, Meghalaya', 'label': 'University of Science and Technology, Meghalaya' },
    { 'id': 315, 'value': 'University of Sciences - Philadelphia', 'label': 'University of Sciences - Philadelphia' },
    { 'id': 13778, 'value': 'University of Scranton', 'label': 'University of Scranton' },
    { 'id': 1462, 'value': 'University of Security and Economics', 'label': 'University of Security and Economics' },
    { 'id': 8143, 'value': 'University of Seoul', 'label': 'University of Seoul' },
    { 'id': 11056, 'value': 'University of Seychelles', 'label': 'University of Seychelles' },
    { 'id': 6791, 'value': 'University of Shahreza', 'label': 'University of Shahreza' },
    { 'id': 2872, 'value': 'University of Shanghai for Science and Technology', 'label': 'University of Shanghai for Science and Technology' },
    { 'id': 676, 'value': 'University of Sharjah', 'label': 'University of Sharjah' },
    { 'id': 11103, 'value': 'University of Shendi', 'label': 'University of Shendi' },
    { 'id': 7749, 'value': 'University of Shizuoka', 'label': 'University of Shizuoka' },
    { 'id': 11224, 'value': 'University of Sierra Leone', 'label': 'University of Sierra Leone' },
    { 'id': 10207, 'value': 'University of Sindh', 'label': 'University of Sindh' },
    { 'id': 13779, 'value': 'University of Sioux Falls', 'label': 'University of Sioux Falls' },
    { 'id': 6792, 'value': 'University of Sistan and Baluchestan', 'label': 'University of Sistan and Baluchestan' },
    { 'id': 6793, 'value': 'University of Social Welfare and Rehabilitation Sciences', 'label': 'University of Social Welfare and Rehabilitation Sciences' },
    { 'id': 6394, 'value': 'University of Solapur', 'label': 'University of Solapur' },
    { 'id': 13780, 'value': 'University of South Alabama', 'label': 'University of South Alabama' },
    { 'id': 10208, 'value': 'University of South Asia', 'label': 'University of South Asia' },
    { 'id': 1334, 'value': 'University of South Asia, Bangladesh', 'label': 'University of South Asia, Bangladesh' },
    { 'id': 1100, 'value': 'University of South Australia', 'label': 'University of South Australia' },
    { 'id': 13781, 'value': 'University of South Carolina', 'label': 'University of South Carolina' },
    { 'id': 13782, 'value': 'University of South Carolina-Aiken', 'label': 'University of South Carolina-Aiken' },
    { 'id': 13783, 'value': 'University of South Carolina-Beaufort', 'label': 'University of South Carolina-Beaufort' },
    { 'id': 13784, 'value': 'University of South Carolina-Upstate', 'label': 'University of South Carolina-Upstate' },
    { 'id': 2873, 'value': 'University of South China', 'label': 'University of South China' },
    { 'id': 13785, 'value': 'University of South Dakota', 'label': 'University of South Dakota' },
    { 'id': 13786, 'value': 'University of South Florida', 'label': 'University of South Florida' },
    { 'id': 4600, 'value': 'University of South Wales', 'label': 'University of South Wales' },
    { 'id': 337, 'value': 'University of southampton', 'label': 'University of southampton' },
    { 'id': 7935, 'value': 'University of Southeast Asia', 'label': 'University of Southeast Asia' },
    { 'id': 10019, 'value': 'University of Southeastern Philippines', 'label': 'University of Southeastern Philippines' },
    { 'id': 13787, 'value': 'University of Southern California', 'label': 'University of Southern California' },
    { 'id': 14393, 'value': 'University of Southern Denmark, SDU', 'label': 'University of Southern Denmark, SDU' },
    { 'id': 13788, 'value': 'University of Southern Indiana', 'label': 'University of Southern Indiana' },
    { 'id': 13789, 'value': 'University of Southern Maine', 'label': 'University of Southern Maine' },
    { 'id': 10020, 'value': 'University of Southern Mindanao', 'label': 'University of Southern Mindanao' },
    { 'id': 13790, 'value': 'University of Southern Mississippi', 'label': 'University of Southern Mississippi' },
    { 'id': 10021, 'value': 'University of Southern Philippines Foundation', 'label': 'University of Southern Philippines Foundation' },
    { 'id': 1101, 'value': 'University of Southern Queensland', 'label': 'University of Southern Queensland' },
    { 'id': 8322, 'value': 'University of Sri Jayewardenepura', 'label': 'University of Sri Jayewardenepura' },
    { 'id': 4601, 'value': 'University of St Andrews', 'label': 'University of St Andrews' },
    { 'id': 4602, 'value': 'University of St Mark and St John', 'label': 'University of St Mark and St John' },
    { 'id': 13791, 'value': 'University of St Thomas', 'label': 'University of St Thomas' },
    { 'id': 13792, 'value': 'University of St. Augustine for Health Sciences', 'label': 'University of St. Augustine for Health Sciences' },
    { 'id': 13793, 'value': 'University of St. Francis', 'label': 'University of St. Francis' },
    { 'id': 10022, 'value': 'University of St. La Salle', 'label': 'University of St. La Salle' },
    { 'id': 13794, 'value': 'University of St. Thomas', 'label': 'University of St. Thomas' },
    { 'id': 461, 'value': 'University of Stirling', 'label': 'University of Stirling' },
    { 'id': 403, 'value': 'University of Strathclyde', 'label': 'University of Strathclyde' },
    { 'id': 1463, 'value': 'University of Structural Engineering and Architecture "Lyuben Karavelov"', 'label': 'University of Structural Engineering and Architecture "Lyuben Karavelov"' },
    { 'id': 6493, 'value': 'University of Sulaimani', 'label': 'University of Sulaimani' },
    { 'id': 6494, 'value': 'University of Sumer', 'label': 'University of Sumer' },
    { 'id': 4603, 'value': 'University of Sunderland', 'label': 'University of Sunderland' },
    { 'id': 329, 'value': 'University of Surrey', 'label': 'University of Surrey' },
    { 'id': 266, 'value': 'University of Sussex', 'label': 'University of Sussex' },
    { 'id': 10209, 'value': 'University of Swabi', 'label': 'University of Swabi' },
    { 'id': 10210, 'value': 'University of Swat', 'label': 'University of Swat' },
    { 'id': 11362, 'value': 'University of Swaziland', 'label': 'University of Swaziland' },
    { 'id': 6794, 'value': 'University of Tabriz', 'label': 'University of Tabriz' },
    { 'id': 11054, 'value': 'University of Tabuk', 'label': 'University of Tabuk' },
    { 'id': 11939, 'value': 'University of Taipei', 'label': 'University of Taipei' },
    { 'id': 11355, 'value': 'University of Tartous', 'label': 'University of Tartous' },
    { 'id': 1102, 'value': 'University of Tasmania', 'label': 'University of Tasmania' },
    { 'id': 6395, 'value': 'University of Technology', 'label': 'University of Technology' },
    { 'id': 14166, 'value': 'University of Technology & Management', 'label': 'University of Technology & Management' },
    { 'id': 11014, 'value': 'University of Technology and Arts of Byumba', 'label': 'University of Technology and Arts of Byumba' },
    { 'id': 6396, 'value': 'University of Technology and Management', 'label': 'University of Technology and Management' },
    { 'id': 1103, 'value': 'University of Technology Sydney', 'label': 'University of Technology Sydney' },
    { 'id': 7031, 'value': 'University of Technology, Jamaica', 'label': 'University of Technology, Jamaica' },
    { 'id': 8675, 'value': 'University of Technology, Mauritius', 'label': 'University of Technology, Mauritius' },
    { 'id': 8581, 'value': 'University of Technology, Yadanabon Cyber City', 'label': 'University of Technology, Yadanabon Cyber City' },
    { 'id': 6795, 'value': 'University of Tehran', 'label': 'University of Tehran' },
    { 'id': 6495, 'value': 'University of Telafer', 'label': 'University of Telafer' },
    { 'id': 13795, 'value': 'University of Texas Rio Grande Valley', 'label': 'University of Texas Rio Grande Valley' },
    { 'id': 13796, 'value': 'University of Texas Southwestern Medical Center', 'label': 'University of Texas Southwestern Medical Center' },
    { 'id': 4769, 'value': 'University of the Aegean', 'label': 'University of the Aegean' },
    { 'id': 323, 'value': 'University of the Arts London', 'label': 'University of the Arts London' },
    { 'id': 10023, 'value': 'University of the Assumption', 'label': 'University of the Assumption' },
    { 'id': 1785, 'value': 'University of The Bahamas', 'label': 'University of The Bahamas' },
    { 'id': 2874, 'value': 'University of the Chinese Academy of Sciences', 'label': 'University of the Chinese Academy of Sciences' },
    { 'id': 7032, 'value': 'University of the Commonwealth Caribbean', 'label': 'University of the Commonwealth Caribbean' },
    { 'id': 10024, 'value': 'University of the Cordilleras', 'label': 'University of the Cordilleras' },
    { 'id': 13797, 'value': 'University of the Cumberlands', 'label': 'University of the Cumberlands' },
    { 'id': 13798, 'value': 'University of the District of Columbia', 'label': 'University of the District of Columbia' },
    { 'id': 10025, 'value': 'University of the East', 'label': 'University of the East' },
    { 'id': 10026, 'value': 'University of the East Ramon Magsaysay', 'label': 'University of the East Ramon Magsaysay' },
    { 'id': 1932, 'value': 'University of the Fraser Valley', 'label': 'University of the Fraser Valley' },
    { 'id': 14239, 'value': 'University of the Free State', 'label': 'University of the Free State' },
    { 'id': 4732, 'value': 'University of the Gambia', 'label': 'University of the Gambia' },
    { 'id': 4604, 'value': 'University of the Highlands and Islands', 'label': 'University of the Highlands and Islands' },
    { 'id': 11104, 'value': 'University of the Holy Quran and Islamic Sciences', 'label': 'University of the Holy Quran and Islamic Sciences' },
    { 'id': 8651, 'value': 'University of the Humanities', 'label': 'University of the Humanities' },
    { 'id': 10027, 'value': 'University of the Immaculate Conception', 'label': 'University of the Immaculate Conception' },
    { 'id': 13799, 'value': 'University of the Incarnate Word', 'label': 'University of the Incarnate Word' },
    { 'id': 13800, 'value': 'University of the Ozarks', 'label': 'University of the Ozarks' },
    { 'id': 13801, 'value': 'University of the Pacific', 'label': 'University of the Pacific' },
    { 'id': 10028, 'value': 'University of the Philippines Baguio', 'label': 'University of the Philippines Baguio' },
    { 'id': 10029, 'value': 'University of the Philippines Diliman', 'label': 'University of the Philippines Diliman' },
    { 'id': 10030, 'value': 'University of the Philippines in the Visayas', 'label': 'University of the Philippines in the Visayas' },
    { 'id': 10031, 'value': 'University of the Philippines Los Baños', 'label': 'University of the Philippines Los Baños' },
    { 'id': 10032, 'value': 'University of the Philippines Manila', 'label': 'University of the Philippines Manila' },
    { 'id': 10033, 'value': 'University of the Philippines Mindanao', 'label': 'University of the Philippines Mindanao' },
    { 'id': 10034, 'value': 'University of the Philippines System', 'label': 'University of the Philippines System' },
    { 'id': 13802, 'value': 'University of the Potomac', 'label': 'University of the Potomac' },
    { 'id': 10211, 'value': 'University of the Punjab', 'label': 'University of the Punjab' },
    { 'id': 7750, 'value': 'University of the Ryukyus', 'label': 'University of the Ryukyus' },
    { 'id': 7751, 'value': 'University of the Sacred Heart', 'label': 'University of the Sacred Heart' },
    { 'id': 12202, 'value': 'University of the Sacred Heart Gulu', 'label': 'University of the Sacred Heart Gulu' },
    { 'id': 13803, 'value': 'University of the Sciences in Philadelphia', 'label': 'University of the Sciences in Philadelphia' },
    { 'id': 11803, 'value': 'University of the Southern Caribbean', 'label': 'University of the Southern Caribbean' },
    { 'id': 13804, 'value': 'University of the Southwest', 'label': 'University of the Southwest' },
    { 'id': 1104, 'value': 'University of the Sunshine Coast', 'label': 'University of the Sunshine Coast' },
    { 'id': 11492, 'value': 'University of the Thai Chamber of Commerce', 'label': 'University of the Thai Chamber of Commerce' },
    { 'id': 14108, 'value': 'University of the Virgin Islands', 'label': 'University of the Virgin Islands' },
    { 'id': 10035, 'value': 'University of the Visayas', 'label': 'University of the Visayas' },
    { 'id': 8323, 'value': 'University of the Visual and Performing Arts', 'label': 'University of the Visual and Performing Arts' },
    { 'id': 13805, 'value': 'University of the West', 'label': 'University of the West' },
    { 'id': 4605, 'value': 'University of the West of England', 'label': 'University of the West of England' },
    { 'id': 579, 'value': 'University of the West of England - Bristol', 'label': 'University of the West of England - Bristol' },
    { 'id': 4606, 'value': 'University of the West of Scotland', 'label': 'University of the West of Scotland' },
    { 'id': 14240, 'value': 'University of the Western Cape', 'label': 'University of the Western Cape' },
    { 'id': 14241, 'value': 'University of the Witwatersrand', 'label': 'University of the Witwatersrand' },
    { 'id': 4770, 'value': 'University of Thessaly', 'label': 'University of Thessaly' },
    { 'id': 6496, 'value': 'University of Thi-Qar', 'label': 'University of Thi-Qar' },
    { 'id': 6497, 'value': 'University of Tikrit', 'label': 'University of Tikrit' },
    { 'id': 13806, 'value': 'University of Toledo', 'label': 'University of Toledo' },
    { 'id': 6796, 'value': 'University of Torbat-e-Heydarieh', 'label': 'University of Torbat-e-Heydarieh' },
    { 'id': 11294, 'value': 'University of Torit', 'label': 'University of Torit' },
    { 'id': 1933, 'value': 'University of Toronto', 'label': 'University of Toronto' },
    { 'id': 8487, 'value': 'University of Tourism and Management in Skopje', 'label': 'University of Tourism and Management in Skopje' },
    { 'id': 11015, 'value': 'University of Tourism Technology and Business Studies', 'label': 'University of Tourism Technology and Business Studies' },
    { 'id': 7752, 'value': 'University of Toyama', 'label': 'University of Toyama' },
    { 'id': 839, 'value': 'University of Traditional Medicine', 'label': 'University of Traditional Medicine' },
    { 'id': 14167, 'value': 'University of Transport and Communications', 'label': 'University of Transport and Communications' },
    { 'id': 1464, 'value': 'University of Transport Todor Kableshkov', 'label': 'University of Transport Todor Kableshkov' },
    { 'id': 8407, 'value': 'University of Tripoli', 'label': 'University of Tripoli' },
    { 'id': 7753, 'value': 'University of Tsukuba', 'label': 'University of Tsukuba' },
    { 'id': 10212, 'value': 'University of Turbat', 'label': 'University of Turbat' },
    { 'id': 10953, 'value': 'University of Tyumen', 'label': 'University of Tyumen' },
    { 'id': 8144, 'value': 'University of Ulsan', 'label': 'University of Ulsan' },
    { 'id': 1420, 'value': 'University of United Popular Nations', 'label': 'University of United Popular Nations' },
    { 'id': 9450, 'value': 'University of Uyo', 'label': 'University of Uyo' },
    { 'id': 13807, 'value': 'University of Valley Forge', 'label': 'University of Valley Forge' },
    { 'id': 14242, 'value': 'University of Venda', 'label': 'University of Venda' },
    { 'id': 13808, 'value': 'University of Vermont', 'label': 'University of Vermont' },
    { 'id': 10213, 'value': 'University of Veterinary and Animal Sciences', 'label': 'University of Veterinary and Animal Sciences' },
    { 'id': 8582, 'value': 'University of Veterinary Science, Yezin', 'label': 'University of Veterinary Science, Yezin' },
    { 'id': 1934, 'value': 'University of Victoria', 'label': 'University of Victoria' },
    { 'id': 13809, 'value': 'University of Virginia', 'label': 'University of Virginia' },
    { 'id': 10214, 'value': 'University of Wah', 'label': 'University of Wah' },
    { 'id': 9606, 'value': 'University of Waikato', 'label': 'University of Waikato' },
    { 'id': 4607, 'value': 'University of Wales', 'label': 'University of Wales' },
    { 'id': 4608, 'value': 'University of Wales Trinity Saint David', 'label': 'University of Wales Trinity Saint David' },
    { 'id': 181, 'value': 'University of Warwick', 'label': 'University of Warwick' },
    { 'id': 13810, 'value': 'University of Washington', 'label': 'University of Washington' },
    { 'id': 6498, 'value': 'University of Wasit', 'label': 'University of Wasit' },
    { 'id': 1935, 'value': 'University of Waterloo', 'label': 'University of Waterloo' },
    { 'id': 13811, 'value': 'University of West Florida', 'label': 'University of West Florida' },
    { 'id': 13812, 'value': 'University of West Georgia', 'label': 'University of West Georgia' },
    { 'id': 4609, 'value': 'University of West London', 'label': 'University of West London' },
    { 'id': 8583, 'value': 'University of West Yangon', 'label': 'University of West Yangon' },
    { 'id': 11295, 'value': 'University of Western Equatoria', 'label': 'University of Western Equatoria' },
    { 'id': 4771, 'value': 'University of Western Macedonia', 'label': 'University of Western Macedonia' },
    { 'id': 13813, 'value': 'University of Western States', 'label': 'University of Western States' },
    { 'id': 294, 'value': 'University of Westminster', 'label': 'University of Westminster' },
    { 'id': 1936, 'value': 'University of Windsor', 'label': 'University of Windsor' },
    { 'id': 13814, 'value': 'University of Wisconsin-Eau Claire', 'label': 'University of Wisconsin-Eau Claire' },
    { 'id': 13815, 'value': 'University of Wisconsin-Green Bay', 'label': 'University of Wisconsin-Green Bay' },
    { 'id': 13816, 'value': 'University of Wisconsin-La Crosse', 'label': 'University of Wisconsin-La Crosse' },
    { 'id': 13817, 'value': 'University of Wisconsin-Madison', 'label': 'University of Wisconsin-Madison' },
    { 'id': 13818, 'value': 'University of Wisconsin-Milwaukee', 'label': 'University of Wisconsin-Milwaukee' },
    { 'id': 13819, 'value': 'University of Wisconsin-Oshkosh', 'label': 'University of Wisconsin-Oshkosh' },
    { 'id': 13820, 'value': 'University of Wisconsin-Parkside', 'label': 'University of Wisconsin-Parkside' },
    { 'id': 13821, 'value': 'University of Wisconsin-Platteville', 'label': 'University of Wisconsin-Platteville' },
    { 'id': 13822, 'value': 'University of Wisconsin-River Falls', 'label': 'University of Wisconsin-River Falls' },
    { 'id': 13823, 'value': 'University of Wisconsin-Stevens Point', 'label': 'University of Wisconsin-Stevens Point' },
    { 'id': 13824, 'value': 'University of Wisconsin-Stout', 'label': 'University of Wisconsin-Stout' },
    { 'id': 605, 'value': 'University of Wisconsin-Superior', 'label': 'University of Wisconsin-Superior' },
    { 'id': 13825, 'value': 'University of Wisconsin-Whitewater', 'label': 'University of Wisconsin-Whitewater' },
    { 'id': 1105, 'value': 'University of Wollongong', 'label': 'University of Wollongong' },
    { 'id': 677, 'value': 'University of Wollongong in Dubai', 'label': 'University of Wollongong in Dubai' },
    { 'id': 4610, 'value': 'University of Wolverhampton', 'label': 'University of Wolverhampton' },
    { 'id': 4611, 'value': 'University of Worcester', 'label': 'University of Worcester' },
    { 'id': 13826, 'value': 'University of Wyoming', 'label': 'University of Wyoming' },
    { 'id': 7754, 'value': 'University of Yamanashi', 'label': 'University of Yamanashi' },
    { 'id': 331, 'value': 'University of York', 'label': 'University of York' },
    { 'id': 6797, 'value': 'University of Zabol', 'label': 'University of Zabol' },
    { 'id': 6499, 'value': 'University of Zakho', 'label': 'University of Zakho' },
    { 'id': 11105, 'value': 'University of Zalingei', 'label': 'University of Zalingei' },
    { 'id': 14295, 'value': 'University of Zambia', 'label': 'University of Zambia' },
    { 'id': 8408, 'value': 'University of Zawiya', 'label': 'University of Zawiya' },
    { 'id': 14315, 'value': 'University of Zimbabwe', 'label': 'University of Zimbabwe' },
    { 'id': 14243, 'value': 'University of Zululand', 'label': 'University of Zululand' },
    { 'id': 543, 'value': 'University of Zurich', 'label': 'University of Zurich' },
    { 'id': 1465, 'value': 'University Prof. Dr. Assen Zlatarov', 'label': 'University Prof. Dr. Assen Zlatarov' },
    { 'id': 11175, 'value': 'Univerza na Primorskem', 'label': 'Univerza na Primorskem' },
    { 'id': 11176, 'value': 'Univerza v Ljubljani', 'label': 'Univerza v Ljubljani' },
    { 'id': 11177, 'value': 'Univerza v Mariboru', 'label': 'Univerza v Mariboru' },
    { 'id': 11178, 'value': 'Univerza v Novem mestu', 'label': 'Univerza v Novem mestu' },
    { 'id': 11179, 'value': 'Univerza v Novi Gorici', 'label': 'Univerza v Novi Gorici' },
    { 'id': 3419, 'value': 'Univerzita Hradec Králové', 'label': 'Univerzita Hradec Králové' },
    { 'id': 11201, 'value': 'Univerzita J. Selyeho', 'label': 'Univerzita J. Selyeho' },
    { 'id': 3420, 'value': 'Univerzita Jana Amose Komenského Praha', 'label': 'Univerzita Jana Amose Komenského Praha' },
    { 'id': 3421, 'value': 'Univerzita Jana Evangelisty Purkyne v Ústí nad Labem', 'label': 'Univerzita Jana Evangelisty Purkyne v Ústí nad Labem' },
    { 'id': 3422, 'value': 'Univerzita Karlova', 'label': 'Univerzita Karlova' },
    { 'id': 11202, 'value': 'Univerzita Komenského v Bratislave', 'label': 'Univerzita Komenského v Bratislave' },
    { 'id': 11203, 'value': 'Univerzita Konštantína Filozofa v Nitre', 'label': 'Univerzita Konštantína Filozofa v Nitre' },
    { 'id': 11204, 'value': 'Univerzita Mateja Bela v Banskej Bystrici', 'label': 'Univerzita Mateja Bela v Banskej Bystrici' },
    { 'id': 3423, 'value': 'Univerzita Palackého v Olomouci', 'label': 'Univerzita Palackého v Olomouci' },
    { 'id': 3424, 'value': 'Univerzita Pardubice', 'label': 'Univerzita Pardubice' },
    { 'id': 11205, 'value': 'Univerzita Pavla Jozefa Šafárika v Košiciach', 'label': 'Univerzita Pavla Jozefa Šafárika v Košiciach' },
    { 'id': 11206, 'value': 'Univerzita sv. Cyrila a Metoda v Trnave', 'label': 'Univerzita sv. Cyrila a Metoda v Trnave' },
    { 'id': 3425, 'value': 'Univerzita Tomáše Bati ve Zlíne', 'label': 'Univerzita Tomáše Bati ve Zlíne' },
    { 'id': 11207, 'value': 'Univerzita Veterinárskeho Lekárstva a Farmácie v Košiciach', 'label': 'Univerzita Veterinárskeho Lekárstva a Farmácie v Košiciach' },
    { 'id': 1175, 'value': 'Univerzitet Bijeljina', 'label': 'Univerzitet Bijeljina' },
    { 'id': 8468, 'value': 'Univerzitet Crne Gore', 'label': 'Univerzitet Crne Gore' },
    { 'id': 1176, 'value': 'Univerzitet Džemal Bijedic u Mostaru', 'label': 'Univerzitet Džemal Bijedic u Mostaru' },
    { 'id': 10607, 'value': 'Univerzitet Educons', 'label': 'Univerzitet Educons' },
    { 'id': 10608, 'value': 'Univerzitet Megatrend', 'label': 'Univerzitet Megatrend' },
    { 'id': 10609, 'value': 'Univerzitet Metropolitan', 'label': 'Univerzitet Metropolitan' },
    { 'id': 1177, 'value': 'Univerzitet modernih znanosti-CKM Mostar', 'label': 'Univerzitet modernih znanosti-CKM Mostar' },
    { 'id': 10610, 'value': 'Univerzitet Privredna akademija', 'label': 'Univerzitet Privredna akademija' },
    { 'id': 1178, 'value': 'Univerzitet Sinergija Bijeljina', 'label': 'Univerzitet Sinergija Bijeljina' },
    { 'id': 10611, 'value': 'Univerzitet Singidunum', 'label': 'Univerzitet Singidunum' },
    { 'id': 8488, 'value': 'Univerzitet Sv. Kiril i Metódij vo Skopje', 'label': 'Univerzitet Sv. Kiril i Metódij vo Skopje' },
    { 'id': 8489, 'value': 'Univerzitet Sv. Kliment Ohridski vo Bitola', 'label': 'Univerzitet Sv. Kliment Ohridski vo Bitola' },
    { 'id': 1179, 'value': 'Univerzitet u Banjoj Luci', 'label': 'Univerzitet u Banjoj Luci' },
    { 'id': 10612, 'value': 'Univerzitet u Beogradu', 'label': 'Univerzitet u Beogradu' },
    { 'id': 1180, 'value': 'Univerzitet u Bihacu', 'label': 'Univerzitet u Bihacu' },
    { 'id': 1181, 'value': 'Univerzitet u Istocnom Sarajevu', 'label': 'Univerzitet u Istocnom Sarajevu' },
    { 'id': 10613, 'value': 'Univerzitet u Kragujevcu', 'label': 'Univerzitet u Kragujevcu' },
    { 'id': 10614, 'value': 'Univerzitet u Nišu', 'label': 'Univerzitet u Nišu' },
    { 'id': 10615, 'value': 'Univerzitet u Novom Sadu', 'label': 'Univerzitet u Novom Sadu' },
    { 'id': 1182, 'value': 'Univerzitet u Sarajevu', 'label': 'Univerzitet u Sarajevu' },
    { 'id': 1183, 'value': 'Univerzitet u Travniku', 'label': 'Univerzitet u Travniku' },
    { 'id': 1184, 'value': 'Univerzitet u Tuzli', 'label': 'Univerzitet u Tuzli' },
    { 'id': 1185, 'value': 'Univerzitet u Zenici', 'label': 'Univerzitet u Zenici' },
    { 'id': 10616, 'value': 'Univerzitet umetnosti u Beogradu', 'label': 'Univerzitet umetnosti u Beogradu' },
    { 'id': 10617, 'value': 'Univerzitet Union', 'label': 'Univerzitet Union' },
    { 'id': 1186, 'value': 'Univerzitet za poslovne studije', 'label': 'Univerzitet za poslovne studije' },
    { 'id': 1187, 'value': 'Univerzitet za poslovni inženjering i menadžment', 'label': 'Univerzitet za poslovni inženjering i menadžment' },
    { 'id': 10285, 'value': 'Uniwersytet Artystyczny w Poznaniu', 'label': 'Uniwersytet Artystyczny w Poznaniu' },
    { 'id': 10286, 'value': 'Uniwersytet Ekonomiczny w Katowicach', 'label': 'Uniwersytet Ekonomiczny w Katowicach' },
    { 'id': 10287, 'value': 'Uniwersytet Ekonomiczny w Krakowie', 'label': 'Uniwersytet Ekonomiczny w Krakowie' },
    { 'id': 10288, 'value': 'Uniwersytet Ekonomiczny w Poznaniu', 'label': 'Uniwersytet Ekonomiczny w Poznaniu' },
    { 'id': 10289, 'value': 'Uniwersytet Ekonomiczny we Wroclawiu', 'label': 'Uniwersytet Ekonomiczny we Wroclawiu' },
    { 'id': 10290, 'value': 'Uniwersytet Gdanski', 'label': 'Uniwersytet Gdanski' },
    { 'id': 10291, 'value': 'Uniwersytet Humanistyczno-Przyrodniczy im. Jana Dlugosza w Czestochowie', 'label': 'Uniwersytet Humanistyczno-Przyrodniczy im. Jana Dlugosza w Czestochowie' },
    { 'id': 10292, 'value': 'Uniwersytet im. Adama Mickiewicza w Poznaniu', 'label': 'Uniwersytet im. Adama Mickiewicza w Poznaniu' },
    { 'id': 10293, 'value': 'Uniwersytet Jagiellonski', 'label': 'Uniwersytet Jagiellonski' },
    { 'id': 10294, 'value': 'Uniwersytet Jana Kochanowskiego w Kielcach', 'label': 'Uniwersytet Jana Kochanowskiego w Kielcach' },
    { 'id': 10295, 'value': 'Uniwersytet Kardynala Stefana Wyszynskiego w Warszawie', 'label': 'Uniwersytet Kardynala Stefana Wyszynskiego w Warszawie' },
    { 'id': 10296, 'value': 'Uniwersytet Kazimierza Wielkiego w Bydgoszczy', 'label': 'Uniwersytet Kazimierza Wielkiego w Bydgoszczy' },
    { 'id': 10297, 'value': 'Uniwersytet Lódzki', 'label': 'Uniwersytet Lódzki' },
    { 'id': 10298, 'value': 'Uniwersytet Marii Curie-Sklodowskiej w Lublinie', 'label': 'Uniwersytet Marii Curie-Sklodowskiej w Lublinie' },
    { 'id': 10299, 'value': 'Uniwersytet Medyczny im. Karola Marcinkowskiego w Poznaniu', 'label': 'Uniwersytet Medyczny im. Karola Marcinkowskiego w Poznaniu' },
    { 'id': 10300, 'value': 'Uniwersytet Medyczny im. Piastów Slaskich', 'label': 'Uniwersytet Medyczny im. Piastów Slaskich' },
    { 'id': 10301, 'value': 'Uniwersytet Medyczny w Bialymstoku', 'label': 'Uniwersytet Medyczny w Bialymstoku' },
    { 'id': 10302, 'value': 'Uniwersytet Medyczny w Lodzi', 'label': 'Uniwersytet Medyczny w Lodzi' },
    { 'id': 10303, 'value': 'Uniwersytet Medyczny w Lublinie', 'label': 'Uniwersytet Medyczny w Lublinie' },
    { 'id': 10304, 'value': 'Uniwersytet Mikolaja Kopernika w Toruniu', 'label': 'Uniwersytet Mikolaja Kopernika w Toruniu' },
    { 'id': 10305, 'value': 'Uniwersytet Morski w Gdyn', 'label': 'Uniwersytet Morski w Gdyn' },
    { 'id': 10306, 'value': 'Uniwersytet Muzyczny Fryderyka Chopina', 'label': 'Uniwersytet Muzyczny Fryderyka Chopina' },
    { 'id': 10307, 'value': 'Uniwersytet Opolski', 'label': 'Uniwersytet Opolski' },
    { 'id': 10308, 'value': 'Uniwersytet Pedagogiczny im. Komisji Edukacji Narodowej w Krakowie', 'label': 'Uniwersytet Pedagogiczny im. Komisji Edukacji Narodowej w Krakowie' },
    { 'id': 10309, 'value': 'Uniwersytet Przyrodniczo-Humanistyczny w Siedlcach', 'label': 'Uniwersytet Przyrodniczo-Humanistyczny w Siedlcach' },
    { 'id': 10310, 'value': 'Uniwersytet Przyrodniczy w Lublinie', 'label': 'Uniwersytet Przyrodniczy w Lublinie' },
    { 'id': 10311, 'value': 'Uniwersytet Przyrodniczy w Poznaniu', 'label': 'Uniwersytet Przyrodniczy w Poznaniu' },
    { 'id': 10312, 'value': 'Uniwersytet Przyrodniczy we Wroclawiu', 'label': 'Uniwersytet Przyrodniczy we Wroclawiu' },
    { 'id': 10313, 'value': 'Uniwersytet Rolniczy w Krakowie', 'label': 'Uniwersytet Rolniczy w Krakowie' },
    { 'id': 10314, 'value': 'Uniwersytet Rzeszowski', 'label': 'Uniwersytet Rzeszowski' },
    { 'id': 10315, 'value': 'Uniwersytet Slaski w Katowicach', 'label': 'Uniwersytet Slaski w Katowicach' },
    { 'id': 10316, 'value': 'Uniwersytet SWPS', 'label': 'Uniwersytet SWPS' },
    { 'id': 10317, 'value': 'Uniwersytet Szczecinski', 'label': 'Uniwersytet Szczecinski' },
    { 'id': 10318, 'value': 'Uniwersytet Technologiczno-Humanistyczny im. Kazimierza Pulaskiego w Radomiu', 'label': 'Uniwersytet Technologiczno-Humanistyczny im. Kazimierza Pulaskiego w Radomiu' },
    { 'id': 10319, 'value': 'Uniwersytet Technologiczno-Przyrodniczy w Bydgoszczy', 'label': 'Uniwersytet Technologiczno-Przyrodniczy w Bydgoszczy' },
    { 'id': 10320, 'value': 'Uniwersytet w Bialymstoku', 'label': 'Uniwersytet w Bialymstoku' },
    { 'id': 10321, 'value': 'Uniwersytet Warminsko-Mazurski w Olsztynie', 'label': 'Uniwersytet Warminsko-Mazurski w Olsztynie' },
    { 'id': 10322, 'value': 'Uniwersytet Warszawski', 'label': 'Uniwersytet Warszawski' },
    { 'id': 10323, 'value': 'Uniwersytet Wroclawski', 'label': 'Uniwersytet Wroclawski' },
    { 'id': 10324, 'value': 'Uniwersytet Zielonogórski', 'label': 'Uniwersytet Zielonogórski' },
    { 'id': 14612, 'value': 'Unsw', 'label': 'Unsw' },
    { 'id': 195, 'value': 'UPEC - Université Paris-Est Créteil', 'label': 'UPEC - Université Paris-Est Créteil' },
    { 'id': 13827, 'value': 'Upper Iowa University', 'label': 'Upper Iowa University' },
    { 'id': 11296, 'value': 'Upper Nile University', 'label': 'Upper Nile University' },
    { 'id': 11145, 'value': 'Uppsala Universitet', 'label': 'Uppsala Universitet' },
    { 'id': 10954, 'value': 'Ural Federal University', 'label': 'Ural Federal University' },
    { 'id': 10955, 'value': 'Ural State Conservatory', 'label': 'Ural State Conservatory' },
    { 'id': 10956, 'value': 'Ural State Forestry University', 'label': 'Ural State Forestry University' },
    { 'id': 10957, 'value': 'Ural State Law Academy', 'label': 'Ural State Law Academy' },
    { 'id': 10958, 'value': 'Ural State Mining University', 'label': 'Ural State Mining University' },
    { 'id': 10959, 'value': 'Ural State Pedagogical University', 'label': 'Ural State Pedagogical University' },
    { 'id': 10960, 'value': 'Ural State University of Economics', 'label': 'Ural State University of Economics' },
    { 'id': 10961, 'value': 'Ural State University of Physical Culture', 'label': 'Ural State University of Physical Culture' },
    { 'id': 10962, 'value': 'Ural State University of Railway Transport', 'label': 'Ural State University of Railway Transport' },
    { 'id': 840, 'value': 'Urartu University of Practical Psychology and Sociology', 'label': 'Urartu University of Practical Psychology and Sociology' },
    { 'id': 7755, 'value': 'Urawa University', 'label': 'Urawa University' },
    { 'id': 13828, 'value': 'Urbana University', 'label': 'Urbana University' },
    { 'id': 14046, 'value': 'Urganch Davlat Universiteti', 'label': 'Urganch Davlat Universiteti' },
    { 'id': 6798, 'value': 'Urmia University', 'label': 'Urmia University' },
    { 'id': 6799, 'value': 'Urmia University of Medical Sciences', 'label': 'Urmia University of Medical Sciences' },
    { 'id': 6800, 'value': 'Urmia University of Technology', 'label': 'Urmia University of Technology' },
    { 'id': 13829, 'value': 'Ursinus College', 'label': 'Ursinus College' },
    { 'id': 13830, 'value': 'Ursuline College', 'label': 'Ursuline College' },
    { 'id': 11788, 'value': 'Usak Üniversitesi', 'label': 'Usak Üniversitesi' },
    { 'id': 6397, 'value': 'Usha Martin University', 'label': 'Usha Martin University' },
    { 'id': 11789, 'value': 'Üsküdar Üniversitesi', 'label': 'Üsküdar Üniversitesi' },
    { 'id': 9451, 'value': 'Usmanu Danfodio University', 'label': 'Usmanu Danfodio University' },
    { 'id': 8266, 'value': 'USTAZ University', 'label': 'USTAZ University' },
    { 'id': 13831, 'value': 'Utah State University', 'label': 'Utah State University' },
    { 'id': 13832, 'value': 'Utah Valley University', 'label': 'Utah Valley University' },
    { 'id': 8359, 'value': 'Utenos kolegija', 'label': 'Utenos kolegija' },
    { 'id': 13833, 'value': 'Utica College', 'label': 'Utica College' },
    { 'id': 6398, 'value': 'Utkal University', 'label': 'Utkal University' },
    { 'id': 6399, 'value': 'Utkal University of Culture', 'label': 'Utkal University of Culture' },
    { 'id': 240, 'value': 'Utrecht University', 'label': 'Utrecht University' },
    { 'id': 7756, 'value': 'Utsunomiya Kyowa University', 'label': 'Utsunomiya Kyowa University' },
    { 'id': 7757, 'value': 'Utsunomiya University', 'label': 'Utsunomiya University' },
    { 'id': 6400, 'value': 'Uttar Banga Krishi Viswavidyalaya', 'label': 'Uttar Banga Krishi Viswavidyalaya' },
    { 'id': 6401, 'value': 'Uttar Pradesh University of Medical Sciences', 'label': 'Uttar Pradesh University of Medical Sciences' },
    { 'id': 1335, 'value': 'Uttara University', 'label': 'Uttara University' },
    { 'id': 11493, 'value': 'Uttaradit Rajabhat University', 'label': 'Uttaradit Rajabhat University' },
    { 'id': 6402, 'value': 'Uttarakhand Aawasiya Vishwavidyalaya, Almora', 'label': 'Uttarakhand Aawasiya Vishwavidyalaya, Almora' },
    { 'id': 6403, 'value': 'Uttarakhand Ayurved University', 'label': 'Uttarakhand Ayurved University' },
    { 'id': 6404, 'value': 'Uttarakhand Technical University', 'label': 'Uttarakhand Technical University' },
    { 'id': 6405, 'value': 'Uttarakhand University of Horticulture and Forestry', 'label': 'Uttarakhand University of Horticulture and Forestry' },
    { 'id': 6406, 'value': 'Uttaranchal Sanskrit University', 'label': 'Uttaranchal Sanskrit University' },
    { 'id': 6407, 'value': 'Uttaranchal University', 'label': 'Uttaranchal University' },
    { 'id': 8324, 'value': 'Uva Wellassa University', 'label': 'Uva Wellassa University' },
    { 'id': 12138, 'value': 'Uzhhorod National University', 'label': 'Uzhhorod National University' },
    { 'id': 12139, 'value': 'V. N. Karazin Kharkiv National University', 'label': 'V. N. Karazin Kharkiv National University' },
    { 'id': 4661, 'value': 'V. Sarajishvili Tbilisi State Conservatoire', 'label': 'V. Sarajishvili Tbilisi State Conservatoire' },
    { 'id': 12140, 'value': 'V.I. Vernadsky Crimean Federal University', 'label': 'V.I. Vernadsky Crimean Federal University' },
    { 'id': 12141, 'value': 'V.I. Vernadsky Taurida National University', 'label': 'V.I. Vernadsky Taurida National University' },
    { 'id': 14244, 'value': 'Vaal University of Technology', 'label': 'Vaal University of Technology' },
    { 'id': 4238, 'value': 'Vaasan ammattikorkeakoulu', 'label': 'Vaasan ammattikorkeakoulu' },
    { 'id': 4239, 'value': 'Vaasan yliopisto', 'label': 'Vaasan yliopisto' },
    { 'id': 11494, 'value': 'Valaya Alongkorn Rajabhat University', 'label': 'Valaya Alongkorn Rajabhat University' },
    { 'id': 13834, 'value': 'Valdosta State University', 'label': 'Valdosta State University' },
    { 'id': 13835, 'value': 'Valencia College', 'label': 'Valencia College' },
    { 'id': 6801, 'value': 'ValiAsr University of Rafsanjan', 'label': 'ValiAsr University of Rafsanjan' },
    { 'id': 13836, 'value': 'Valley City State University', 'label': 'Valley City State University' },
    { 'id': 12203, 'value': 'Valley University of Science and Technology', 'label': 'Valley University of Science and Technology' },
    { 'id': 4724, 'value': 'Valley View University', 'label': 'Valley View University' },
    { 'id': 13837, 'value': 'Valparaiso University', 'label': 'Valparaiso University' },
    { 'id': 9558, 'value': 'Van Hall Larenstein University of Applied Sciences', 'label': 'Van Hall Larenstein University of Applied Sciences' },
    { 'id': 14168, 'value': 'Van Lang University', 'label': 'Van Lang University' },
    { 'id': 11790, 'value': 'Van Yüzüncü Yil Üniversitesi', 'label': 'Van Yüzüncü Yil Üniversitesi' },
    { 'id': 841, 'value': 'Vanadzor State University', 'label': 'Vanadzor State University' },
    { 'id': 287, 'value': 'Vancouver Academy of Dramatic Arts', 'label': 'Vancouver Academy of Dramatic Arts' },
    { 'id': 190, 'value': 'Vancouver Film School', 'label': 'Vancouver Film School' },
    { 'id': 1937, 'value': 'Vancouver Island University', 'label': 'Vancouver Island University' },
    { 'id': 7936, 'value': 'Vanda Institute', 'label': 'Vanda Institute' },
    { 'id': 13838, 'value': 'Vanderbilt University', 'label': 'Vanderbilt University' },
    { 'id': 13839, 'value': 'VanderCook College of Music', 'label': 'VanderCook College of Music' },
    { 'id': 13840, 'value': 'Vanguard University of Southern California', 'label': 'Vanguard University of Southern California' },
    { 'id': 11940, 'value': 'Vanung University', 'label': 'Vanung University' },
    { 'id': 1336, 'value': 'Varendra University', 'label': 'Varendra University' },
    { 'id': 1466, 'value': 'Varna Free University "Chernorizets Hrabar"', 'label': 'Varna Free University "Chernorizets Hrabar"' },
    { 'id': 1467, 'value': 'Varna University of Management', 'label': 'Varna University of Management' },
    { 'id': 6408, 'value': 'Vasantrao Naik Marathwada Krishi Vidyapeeth', 'label': 'Vasantrao Naik Marathwada Krishi Vidyapeeth' },
    { 'id': 13841, 'value': 'Vassar College', 'label': 'Vassar College' },
    { 'id': 12142, 'value': 'Vasyl\' Stus Donetsk National University', 'label': 'Vasyl\' Stus Donetsk National University' },
    { 'id': 373, 'value': 'Vatel Business School', 'label': 'Vatel Business School' },
    { 'id': 11016, 'value': 'Vatel School Rwanda', 'label': 'Vatel School Rwanda' },
    { 'id': 13842, 'value': 'Vaughn College of Aeronautics and Technology', 'label': 'Vaughn College of Aeronautics and Technology' },
    { 'id': 6409, 'value': 'Veer Bahadur Singh Purvanchal University', 'label': 'Veer Bahadur Singh Purvanchal University' },
    { 'id': 6410, 'value': 'Veer Kunwar Singh University', 'label': 'Veer Kunwar Singh University' },
    { 'id': 6411, 'value': 'Veer Narmad South Gujarat University', 'label': 'Veer Narmad South Gujarat University' },
    { 'id': 6412, 'value': 'Veer Surendra Sai University of Technology', 'label': 'Veer Surendra Sai University of Technology' },
    { 'id': 6413, 'value': 'Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology', 'label': 'Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology' },
    { 'id': 6802, 'value': 'Velayat University', 'label': 'Velayat University' },
    { 'id': 4837, 'value': 'Veleucilište Baltazar, Zaprešic', 'label': 'Veleucilište Baltazar, Zaprešic' },
    { 'id': 4838, 'value': 'Veleucilište Hrvatsko Zagorje Krapina', 'label': 'Veleucilište Hrvatsko Zagorje Krapina' },
    { 'id': 4839, 'value': 'Veleucilište Lavoslav Ružicka u Vukovaru', 'label': 'Veleucilište Lavoslav Ružicka u Vukovaru' },
    { 'id': 4840, 'value': 'Veleucilište Marko Marulic u Kninu', 'label': 'Veleucilište Marko Marulic u Kninu' },
    { 'id': 4841, 'value': 'Veleucilište Nikola Tesla u Gospicu', 'label': 'Veleucilište Nikola Tesla u Gospicu' },
    { 'id': 4842, 'value': 'Veleucilište u Bjelovaru', 'label': 'Veleucilište u Bjelovaru' },
    { 'id': 4843, 'value': 'Veleucilište u Karlovcu', 'label': 'Veleucilište u Karlovcu' },
    { 'id': 4844, 'value': 'Veleucilište u Požegi', 'label': 'Veleucilište u Požegi' },
    { 'id': 4845, 'value': 'Veleucilište u Rijeci', 'label': 'Veleucilište u Rijeci' },
    { 'id': 4846, 'value': 'Veleucilište u Šibeniku', 'label': 'Veleucilište u Šibeniku' },
    { 'id': 4847, 'value': 'Veleucilište u Slavonskom Brodu', 'label': 'Veleucilište u Slavonskom Brodu' },
    { 'id': 4848, 'value': 'Veleucilište Velika Gorica', 'label': 'Veleucilište Velika Gorica' },
    { 'id': 4849, 'value': 'Veleucilište VERN', 'label': 'Veleucilište VERN' },
    { 'id': 6414, 'value': 'Vels University', 'label': 'Vels University' },
    { 'id': 8394, 'value': 'Ventspils Augstskola', 'label': 'Ventspils Augstskola' },
    { 'id': 9452, 'value': 'Veritas University', 'label': 'Veritas University' },
    { 'id': 13843, 'value': 'Vermont College of Fine Arts', 'label': 'Vermont College of Fine Arts' },
    { 'id': 13844, 'value': 'Vermont Law School', 'label': 'Vermont Law School' },
    { 'id': 13845, 'value': 'Vermont Technical College', 'label': 'Vermont Technical College' },
    { 'id': 3142, 'value': 'versity', 'label': 'versity' },
    { 'id': 1399, 'value': 'Vesalius College', 'label': 'Vesalius College' },
    { 'id': 4472, 'value': 'VetAgro Sup', 'label': 'VetAgro Sup' },
    { 'id': 1068, 'value': 'Veterinärmedizinische Universität Wien', 'label': 'Veterinärmedizinische Universität Wien' },
    { 'id': 3426, 'value': 'Veterinární a farmaceutická univerzita Brno', 'label': 'Veterinární a farmaceutická univerzita Brno' },
    { 'id': 291, 'value': 'VIA University College’s School of Business, Technology and Creative Industries', 'label': 'VIA University College’s School of Business, Technology and Creative Industries' },
    { 'id': 14296, 'value': 'Victoria Falls University of Technology', 'label': 'Victoria Falls University of Technology' },
    { 'id': 1106, 'value': 'Victoria University', 'label': 'Victoria University' },
    { 'id': 1337, 'value': 'Victoria University of Bangladesh', 'label': 'Victoria University of Bangladesh' },
    { 'id': 9607, 'value': 'Victoria University of Wellington', 'label': 'Victoria University of Wellington' },
    { 'id': 12204, 'value': 'Victoria University, Kampala', 'label': 'Victoria University, Kampala' },
    { 'id': 9590, 'value': 'VID vitenskapelige høgskole', 'label': 'VID vitenskapelige høgskole' },
    { 'id': 6415, 'value': 'Vidyasagar University', 'label': 'Vidyasagar University' },
    { 'id': 8395, 'value': 'Vidzemes Augstskola', 'label': 'Vidzemes Augstskola' },
    { 'id': 282, 'value': 'Vienna University - WU', 'label': 'Vienna University - WU' },
    { 'id': 168, 'value': 'Vienna University of Economics and Business', 'label': 'Vienna University of Economics and Business' },
    { 'id': 14169, 'value': 'Vietnam Maritime University', 'label': 'Vietnam Maritime University' },
    { 'id': 14170, 'value': 'Vietnam National University of Agriculture', 'label': 'Vietnam National University of Agriculture' },
    { 'id': 14171, 'value': 'Vietnam National University, Hanoi', 'label': 'Vietnam National University, Hanoi' },
    { 'id': 14172, 'value': 'Vietnam National University, Ho Chi Minh City', 'label': 'Vietnam National University, Ho Chi Minh City' },
    { 'id': 6416, 'value': 'Vignan University', 'label': 'Vignan University' },
    { 'id': 6417, 'value': 'Vijayanagara Sri Krishnadevaraya University', 'label': 'Vijayanagara Sri Krishnadevaraya University' },
    { 'id': 6418, 'value': 'Vikram University', 'label': 'Vikram University' },
    { 'id': 6419, 'value': 'Vikrama Simhapuri University', 'label': 'Vikrama Simhapuri University' },
    { 'id': 13846, 'value': 'Villa Maria College', 'label': 'Villa Maria College' },
    { 'id': 13847, 'value': 'Villanova University', 'label': 'Villanova University' },
    { 'id': 8360, 'value': 'Vilniaus Dailes Akademija', 'label': 'Vilniaus Dailes Akademija' },
    { 'id': 8361, 'value': 'Vilniaus dizaino kolegija', 'label': 'Vilniaus dizaino kolegija' },
    { 'id': 8362, 'value': 'Vilniaus Gedimino Technikos Universitetas', 'label': 'Vilniaus Gedimino Technikos Universitetas' },
    { 'id': 8363, 'value': 'Vilniaus kolegija', 'label': 'Vilniaus kolegija' },
    { 'id': 8364, 'value': 'Vilniaus kooperacijos kolegija', 'label': 'Vilniaus kooperacijos kolegija' },
    { 'id': 8365, 'value': 'Vilniaus technologiju ir dizaino kolegija', 'label': 'Vilniaus technologiju ir dizaino kolegija' },
    { 'id': 8366, 'value': 'Vilniaus Universitetas', 'label': 'Vilniaus Universitetas' },
    { 'id': 8367, 'value': 'Vilniaus verslo kolegija', 'label': 'Vilniaus verslo kolegija' },
    { 'id': 14397, 'value': 'Vilnius University', 'label': 'Vilnius University' },
    { 'id': 6420, 'value': 'Vinayaka Missions Sikkim University', 'label': 'Vinayaka Missions Sikkim University' },
    { 'id': 6421, 'value': 'Vinayaka Missions University', 'label': 'Vinayaka Missions University' },
    { 'id': 13848, 'value': 'Vincennes University', 'label': 'Vincennes University' },
    { 'id': 14173, 'value': 'Vinh University', 'label': 'Vinh University' },
    { 'id': 12143, 'value': 'Vinnitsa National Medical University', 'label': 'Vinnitsa National Medical University' },
    { 'id': 12144, 'value': 'Vinnitsa State Pedagogical University', 'label': 'Vinnitsa State Pedagogical University' },
    { 'id': 12145, 'value': 'Vinnytsia National Agrarian University', 'label': 'Vinnytsia National Agrarian University' },
    { 'id': 12146, 'value': 'Vinnytsia National Technical University', 'label': 'Vinnytsia National Technical University' },
    { 'id': 6422, 'value': 'Vinoba Bhave University', 'label': 'Vinoba Bhave University' },
    { 'id': 10036, 'value': 'Virgen Milagrosa University Foundation', 'label': 'Virgen Milagrosa University Foundation' },
    { 'id': 13849, 'value': 'Virginia Commonwealth University', 'label': 'Virginia Commonwealth University' },
    { 'id': 10516, 'value': 'Virginia Commonwealth University School of the Arts in Qatar', 'label': 'Virginia Commonwealth University School of the Arts in Qatar' },
    { 'id': 13850, 'value': 'Virginia Military Institute', 'label': 'Virginia Military Institute' },
    { 'id': 13851, 'value': 'Virginia Polytechnic Institute and State University', 'label': 'Virginia Polytechnic Institute and State University' },
    { 'id': 13852, 'value': 'Virginia State University', 'label': 'Virginia State University' },
    { 'id': 13853, 'value': 'Virginia Union University', 'label': 'Virginia Union University' },
    { 'id': 13854, 'value': 'Virginia Wesleyan University', 'label': 'Virginia Wesleyan University' },
    { 'id': 10037, 'value': 'Visayas State University', 'label': 'Visayas State University' },
    { 'id': 6423, 'value': 'Vishwakarma University', 'label': 'Vishwakarma University' },
    { 'id': 1188, 'value': 'Visoka medicinska škola Prijedor', 'label': 'Visoka medicinska škola Prijedor' },
    { 'id': 1189, 'value': 'Visoka medunarodna škola Cazin', 'label': 'Visoka medunarodna škola Cazin' },
    { 'id': 4850, 'value': 'Visoka poslovna škola PAR', 'label': 'Visoka poslovna škola PAR' },
    { 'id': 1190, 'value': 'Visoka poslovno tehnicka škola Doboj', 'label': 'Visoka poslovno tehnicka škola Doboj' },
    { 'id': 1191, 'value': 'Visoka škola Logos centar', 'label': 'Visoka škola Logos centar' },
    { 'id': 4851, 'value': 'Visoka škola medunarodnih odnosa i diplomacije Dag Hammarskjöld', 'label': 'Visoka škola medunarodnih odnosa i diplomacije Dag Hammarskjöld' },
    { 'id': 1192, 'value': 'Visoka škola Primus', 'label': 'Visoka škola Primus' },
    { 'id': 1193, 'value': 'Visoka škola za ekonomiju i informatiku Prijedor', 'label': 'Visoka škola za ekonomiju i informatiku Prijedor' },
    { 'id': 4852, 'value': 'Visoka škola za ekonomiju, poduzetništvo i upravljanje Nikola Šubic Zrinski', 'label': 'Visoka škola za ekonomiju, poduzetništvo i upravljanje Nikola Šubic Zrinski' },
    { 'id': 1194, 'value': 'Visoka škola za finansije i racunovodstvo', 'label': 'Visoka škola za finansije i racunovodstvo' },
    { 'id': 4853, 'value': 'Visoka škola za informacijske tehnologije', 'label': 'Visoka škola za informacijske tehnologije' },
    { 'id': 1195, 'value': 'Visoka škola za informacione tehnologije, ekonomiju i preduzetništvo', 'label': 'Visoka škola za informacione tehnologije, ekonomiju i preduzetništvo' },
    { 'id': 4854, 'value': 'Visoka škola za inspekcijski i kadrovski menadžment', 'label': 'Visoka škola za inspekcijski i kadrovski menadžment' },
    { 'id': 4855, 'value': 'Visoka škola za menadžment i dizajn Aspira', 'label': 'Visoka škola za menadžment i dizajn Aspira' },
    { 'id': 4856, 'value': 'Visoka škola za menadžment u turizmu i informatici u Virovitici', 'label': 'Visoka škola za menadžment u turizmu i informatici u Virovitici' },
    { 'id': 1196, 'value': 'Visoka škola za primijenjene i pravne nauke Prometej', 'label': 'Visoka škola za primijenjene i pravne nauke Prometej' },
    { 'id': 4857, 'value': 'Visoka škola za sigurnost', 'label': 'Visoka škola za sigurnost' },
    { 'id': 1197, 'value': 'Visoka škola za turizam i hotelijerstvo Trebinje', 'label': 'Visoka škola za turizam i hotelijerstvo Trebinje' },
    { 'id': 1198, 'value': 'Visoka škola za turizam i menadžment Konjic', 'label': 'Visoka škola za turizam i menadžment Konjic' },
    { 'id': 1199, 'value': 'Visoka škola za uslužni biznis', 'label': 'Visoka škola za uslužni biznis' },
    { 'id': 11180, 'value': 'Visoka šola Ravne na Koroškem', 'label': 'Visoka šola Ravne na Koroškem' },
    { 'id': 11181, 'value': 'Visoka šola za hotelirstvo in turizem Bled', 'label': 'Visoka šola za hotelirstvo in turizem Bled' },
    { 'id': 11182, 'value': 'Visoka šola za proizvodno inženirstvo', 'label': 'Visoka šola za proizvodno inženirstvo' },
    { 'id': 11183, 'value': 'Visoka šola za racunovodstvo in finance', 'label': 'Visoka šola za racunovodstvo in finance' },
    { 'id': 11184, 'value': 'Visoka šola za varstvo okolja', 'label': 'Visoka šola za varstvo okolja' },
    { 'id': 4858, 'value': 'Visoko gospodarsko ucilište u Križevcima', 'label': 'Visoko gospodarsko ucilište u Križevcima' },
    { 'id': 4859, 'value': 'Visoko ucilište Algebra', 'label': 'Visoko ucilište Algebra' },
    { 'id': 4860, 'value': 'Visoko ucilište EFFECTUS', 'label': 'Visoko ucilište EFFECTUS' },
    { 'id': 11185, 'value': 'Visokošolski zavod Fizioterapevtika', 'label': 'Visokošolski zavod Fizioterapevtika' },
    { 'id': 6424, 'value': 'Visva-Bharati University', 'label': 'Visva-Bharati University' },
    { 'id': 6425, 'value': 'Visvesvaraya National Institute of Technology', 'label': 'Visvesvaraya National Institute of Technology' },
    { 'id': 6426, 'value': 'Visvesvaraya Technological University', 'label': 'Visvesvaraya Technological University' },
    { 'id': 6427, 'value': 'VIT Bhopal University', 'label': 'VIT Bhopal University' },
    { 'id': 6428, 'value': 'VIT University', 'label': 'VIT University' },
    { 'id': 1841, 'value': 'Vitebsk State Academy of Veterinary Medicine', 'label': 'Vitebsk State Academy of Veterinary Medicine' },
    { 'id': 1842, 'value': 'Vitebsk State Medical University', 'label': 'Vitebsk State Medical University' },
    { 'id': 1843, 'value': 'Vitebsk State Technological University', 'label': 'Vitebsk State Technological University' },
    { 'id': 1844, 'value': 'Vitebsk State University', 'label': 'Vitebsk State University' },
    { 'id': 13855, 'value': 'Viterbo University', 'label': 'Viterbo University' },
    { 'id': 6429, 'value': 'Vivekananda Global University', 'label': 'Vivekananda Global University' },
    { 'id': 1400, 'value': 'VIVES', 'label': 'VIVES' },
    { 'id': 10963, 'value': 'Vladikavkaz Institute of Economics, Management and Law', 'label': 'Vladikavkaz Institute of Economics, Management and Law' },
    { 'id': 10964, 'value': 'Vladimir State University for the Humanities', 'label': 'Vladimir State University for the Humanities' },
    { 'id': 10965, 'value': 'Vladivostok State University of Economics and Service', 'label': 'Vladivostok State University of Economics and Service' },
    { 'id': 1401, 'value': 'Vlerick Business School', 'label': 'Vlerick Business School' },
    { 'id': 14174, 'value': 'VNU University of Science', 'label': 'VNU University of Science' },
    { 'id': 14175, 'value': 'VNUHCM - University of Science', 'label': 'VNUHCM - University of Science' },
    { 'id': 10966, 'value': 'Volga Region State Academy of Physical Culture, Sport and Tourism', 'label': 'Volga Region State Academy of Physical Culture, Sport and Tourism' },
    { 'id': 10967, 'value': 'Volga State University of Technology', 'label': 'Volga State University of Technology' },
    { 'id': 10968, 'value': 'Volga State University of Telecommunications and Informatics', 'label': 'Volga State University of Telecommunications and Informatics' },
    { 'id': 10969, 'value': 'Volgograd State Medical Academy', 'label': 'Volgograd State Medical Academy' },
    { 'id': 10970, 'value': 'Volgograd State Socio-Pedagogical University', 'label': 'Volgograd State Socio-Pedagogical University' },
    { 'id': 10971, 'value': 'Volgograd State Technical University', 'label': 'Volgograd State Technical University' },
    { 'id': 10972, 'value': 'Volgograd State University', 'label': 'Volgograd State University' },
    { 'id': 10973, 'value': 'Volgograd State University of Architecture and Civil Engineering', 'label': 'Volgograd State University of Architecture and Civil Engineering' },
    { 'id': 12147, 'value': 'Volodymyr Vynnychenko Central Ukrainian State Pedagogical University', 'label': 'Volodymyr Vynnychenko Central Ukrainian State Pedagogical University' },
    { 'id': 10974, 'value': 'Vologda State Dairy Farming Academy', 'label': 'Vologda State Dairy Farming Academy' },
    { 'id': 10975, 'value': 'Vologda State University', 'label': 'Vologda State University' },
    { 'id': 11495, 'value': 'Vongchavalitkul University', 'label': 'Vongchavalitkul University' },
    { 'id': 13856, 'value': 'Voorhees College', 'label': 'Voorhees College' },
    { 'id': 10976, 'value': 'Voronezh State Agricultural University', 'label': 'Voronezh State Agricultural University' },
    { 'id': 10977, 'value': 'Voronezh State Medical Academy', 'label': 'Voronezh State Medical Academy' },
    { 'id': 10978, 'value': 'Voronezh State Pedagogical University', 'label': 'Voronezh State Pedagogical University' },
    { 'id': 10979, 'value': 'Voronezh State Technical University', 'label': 'Voronezh State Technical University' },
    { 'id': 10980, 'value': 'Voronezh State University', 'label': 'Voronezh State University' },
    { 'id': 10981, 'value': 'Voronezh State University of Engineering Technology', 'label': 'Voronezh State University of Engineering Technology' },
    { 'id': 99, 'value': 'VRIJE UNIVERSITEIT AMSTERDAM', 'label': 'VRIJE UNIVERSITEIT AMSTERDAM' },
    { 'id': 1402, 'value': 'Vrije Universiteit Brussel', 'label': 'Vrije Universiteit Brussel' },
    { 'id': 3427, 'value': 'VŠB - Technická univerzita Ostrava', 'label': 'VŠB - Technická univerzita Ostrava' },
    { 'id': 3805, 'value': 'VWA-Hochschule für berufsbegleitendes Studium', 'label': 'VWA-Hochschule für berufsbegleitendes Studium' },
    { 'id': 10982, 'value': 'Vyatka State Humanities University', 'label': 'Vyatka State Humanities University' },
    { 'id': 10983, 'value': 'Vyatka State University', 'label': 'Vyatka State University' },
    { 'id': 3428, 'value': 'Vysoká škola Ambis', 'label': 'Vysoká škola Ambis' },
    { 'id': 3429, 'value': 'Vysoká škola aplikované psychologie', 'label': 'Vysoká škola aplikované psychologie' },
    { 'id': 11208, 'value': 'Vysoká Škola Bezpecnostného Manažérstva v Košiciach', 'label': 'Vysoká Škola Bezpecnostného Manažérstva v Košiciach' },
    { 'id': 3430, 'value': 'Vysoká škola chemicko-technologická v Praze', 'label': 'Vysoká škola chemicko-technologická v Praze' },
    { 'id': 11209, 'value': 'Vysoká škola Danubius', 'label': 'Vysoká škola Danubius' },
    { 'id': 11210, 'value': 'Vysoká škola DTI', 'label': 'Vysoká škola DTI' },
    { 'id': 3431, 'value': 'Vysoká škola ekonomická v Praze', 'label': 'Vysoká škola ekonomická v Praze' },
    { 'id': 3432, 'value': 'Vysoká škola ekonomie a managementu', 'label': 'Vysoká škola ekonomie a managementu' },
    { 'id': 11211, 'value': 'Vysoká škola ekonómie a manažmentu verejnej správy v Bratislave', 'label': 'Vysoká škola ekonómie a manažmentu verejnej správy v Bratislave' },
    { 'id': 3433, 'value': 'Vysoká škola evropských a regionálních studií', 'label': 'Vysoká škola evropských a regionálních studií' },
    { 'id': 3434, 'value': 'Vysoká škola financní a správní', 'label': 'Vysoká škola financní a správní' },
    { 'id': 3435, 'value': 'Vysoká škola hotelová v Praze', 'label': 'Vysoká škola hotelová v Praze' },
    { 'id': 3436, 'value': 'Vysoká škola kreativní komunikace', 'label': 'Vysoká škola kreativní komunikace' },
    { 'id': 3437, 'value': 'Vysoká škola logistiky', 'label': 'Vysoká škola logistiky' },
    { 'id': 11212, 'value': 'Vysoká škola manažmentu, City University of Seattle', 'label': 'Vysoká škola manažmentu, City University of Seattle' },
    { 'id': 11213, 'value': 'Vysoká škola medzinárodného podnikania ISM Slovakia v Prešove', 'label': 'Vysoká škola medzinárodného podnikania ISM Slovakia v Prešove' },
    { 'id': 3438, 'value': 'Vysoká škola mezinárodních a verejných vztahu Praha', 'label': 'Vysoká škola mezinárodních a verejných vztahu Praha' },
    { 'id': 11214, 'value': 'Vysoká škola múzických umení v Bratislave', 'label': 'Vysoká škola múzických umení v Bratislave' },
    { 'id': 3439, 'value': 'Vysoká škola obchodní a hotelová', 'label': 'Vysoká škola obchodní a hotelová' },
    { 'id': 3440, 'value': 'Vysoká škola obchodní v Praze', 'label': 'Vysoká škola obchodní v Praze' },
    { 'id': 3441, 'value': 'Vysoká škola podnikání a práva', 'label': 'Vysoká škola podnikání a práva' },
    { 'id': 3442, 'value': 'Vysoká škola polytechnická Jihlava', 'label': 'Vysoká škola polytechnická Jihlava' },
    { 'id': 3443, 'value': 'Vysoká škola PRIGO', 'label': 'Vysoká škola PRIGO' },
    { 'id': 3444, 'value': 'Vysoká škola technická a ekonomická v Ceských Budejovicích', 'label': 'Vysoká škola technická a ekonomická v Ceských Budejovicích' },
    { 'id': 3445, 'value': 'Vysoká škola telesné výchovy a sportu Palestra', 'label': 'Vysoká škola telesné výchovy a sportu Palestra' },
    { 'id': 3446, 'value': 'Vysoká škola umeleckoprumyslová v Praze', 'label': 'Vysoká škola umeleckoprumyslová v Praze' },
    { 'id': 11215, 'value': 'Vysoká škola výtvarných umení v Bratislave', 'label': 'Vysoká škola výtvarných umení v Bratislave' },
    { 'id': 3447, 'value': 'Vysoká škola zdravotnická', 'label': 'Vysoká škola zdravotnická' },
    { 'id': 11216, 'value': 'Vysoká Škola Zdravotníctva a Sociálnej Práce sv. Alžbety v Bratislave', 'label': 'Vysoká Škola Zdravotníctva a Sociálnej Práce sv. Alžbety v Bratislave' },
    { 'id': 3448, 'value': 'Vysoké ucení technické v Brne', 'label': 'Vysoké ucení technické v Brne' },
    { 'id': 8368, 'value': 'Vytauto Didžiojo universitetas', 'label': 'Vytauto Didžiojo universitetas' },
    { 'id': 4725, 'value': 'Wa Polytechnic', 'label': 'Wa Polytechnic' },
    { 'id': 13857, 'value': 'Wabash College', 'label': 'Wabash College' },
    { 'id': 4201, 'value': 'Wachamo University', 'label': 'Wachamo University' },
    { 'id': 11106, 'value': 'Wad Medani Ahlia University', 'label': 'Wad Medani Ahlia University' },
    { 'id': 13858, 'value': 'Wade College', 'label': 'Wade College' },
    { 'id': 11356, 'value': 'Wadi International University', 'label': 'Wadi International University' },
    { 'id': 9559, 'value': 'Wageningen Universiteit', 'label': 'Wageningen Universiteit' },
    { 'id': 237, 'value': 'Wageningen University', 'label': 'Wageningen University' },
    { 'id': 13859, 'value': 'Wagner College', 'label': 'Wagner College' },
    { 'id': 10403, 'value': 'Wajdi University College of Technology', 'label': 'Wajdi University College of Technology' },
    { 'id': 7758, 'value': 'Wakayama Medical University', 'label': 'Wakayama Medical University' },
    { 'id': 7759, 'value': 'Wakayama University', 'label': 'Wakayama University' },
    { 'id': 13860, 'value': 'Wake Forest University', 'label': 'Wake Forest University' },
    { 'id': 7760, 'value': 'Wakkanai Hokusei Gakuen College', 'label': 'Wakkanai Hokusei Gakuen College' },
    { 'id': 7761, 'value': 'Wako University', 'label': 'Wako University' },
    { 'id': 11496, 'value': 'Walailak University', 'label': 'Walailak University' },
    { 'id': 13861, 'value': 'Waldorf University', 'label': 'Waldorf University' },
    { 'id': 9648, 'value': 'Waljat Colleges of Applied Sciences', 'label': 'Waljat Colleges of Applied Sciences' },
    { 'id': 13862, 'value': 'Walla Walla University', 'label': 'Walla Walla University' },
    { 'id': 13863, 'value': 'Walsh College', 'label': 'Walsh College' },
    { 'id': 13864, 'value': 'Walsh University', 'label': 'Walsh University' },
    { 'id': 14245, 'value': 'Walter Sisulu University', 'label': 'Walter Sisulu University' },
    { 'id': 2875, 'value': 'Wannan Medical College', 'label': 'Wannan Medical College' },
    { 'id': 13865, 'value': 'Warner Pacific College', 'label': 'Warner Pacific College' },
    { 'id': 13866, 'value': 'Warner University', 'label': 'Warner University' },
    { 'id': 13867, 'value': 'Warren Wilson College', 'label': 'Warren Wilson College' },
    { 'id': 10325, 'value': 'Warszawski Uniwersytet Medyczny', 'label': 'Warszawski Uniwersytet Medyczny' },
    { 'id': 13868, 'value': 'Wartburg College', 'label': 'Wartburg College' },
    { 'id': 388, 'value': 'Warwick Business School', 'label': 'Warwick Business School' },
    { 'id': 7762, 'value': 'Waseda University', 'label': 'Waseda University' },
    { 'id': 13869, 'value': 'Washburn University', 'label': 'Washburn University' },
    { 'id': 13870, 'value': 'Washington & Jefferson College', 'label': 'Washington & Jefferson College' },
    { 'id': 13871, 'value': 'Washington Adventist University', 'label': 'Washington Adventist University' },
    { 'id': 13872, 'value': 'Washington and Lee University', 'label': 'Washington and Lee University' },
    { 'id': 13873, 'value': 'Washington College', 'label': 'Washington College' },
    { 'id': 13874, 'value': 'Washington State University', 'label': 'Washington State University' },
    { 'id': 13875, 'value': 'Washington University in St. Louis', 'label': 'Washington University in St. Louis' },
    { 'id': 5530, 'value': 'Waterford Institute of Technology', 'label': 'Waterford Institute of Technology' },
    { 'id': 13876, 'value': 'Watkins College of Art, Design & Film', 'label': 'Watkins College of Art, Design & Film' },
    { 'id': 8325, 'value': 'Wayamba University of Sri Lanka', 'label': 'Wayamba University of Sri Lanka' },
    { 'id': 13877, 'value': 'Wayland Baptist University', 'label': 'Wayland Baptist University' },
    { 'id': 13878, 'value': 'Wayne State College', 'label': 'Wayne State College' },
    { 'id': 13879, 'value': 'Wayne State University', 'label': 'Wayne State University' },
    { 'id': 13880, 'value': 'Waynesburg University', 'label': 'Waynesburg University' },
    { 'id': 7763, 'value': 'Wayo Women\'s University', 'label': 'Wayo Women\'s University' },
    { 'id': 13881, 'value': 'Webb Institute', 'label': 'Webb Institute' },
    { 'id': 13882, 'value': 'Webber International University', 'label': 'Webber International University' },
    { 'id': 13883, 'value': 'Weber State University', 'label': 'Weber State University' },
    { 'id': 13884, 'value': 'Webster University', 'label': 'Webster University' },
    { 'id': 2016, 'value': 'Webster University Geneva', 'label': 'Webster University Geneva' },
    { 'id': 4726, 'value': 'Webster University Ghana', 'label': 'Webster University Ghana' },
    { 'id': 11497, 'value': 'Webster University Thailand', 'label': 'Webster University Thailand' },
    { 'id': 1069, 'value': 'Webster Vienna Private University', 'label': 'Webster Vienna Private University' },
    { 'id': 2876, 'value': 'Weifang Medical University', 'label': 'Weifang Medical University' },
    { 'id': 2877, 'value': 'Weifang University', 'label': 'Weifang University' },
    { 'id': 2878, 'value': 'Weifang University of Science and Technology', 'label': 'Weifang University of Science and Technology' },
    { 'id': 10517, 'value': 'Weill Cornell Medicine - Qatar', 'label': 'Weill Cornell Medicine - Qatar' },
    { 'id': 2879, 'value': 'Weinan Normal University', 'label': 'Weinan Normal University' },
    { 'id': 3806, 'value': 'Weissensee Kunsthochschule Berlin', 'label': 'Weissensee Kunsthochschule Berlin' },
    { 'id': 5564, 'value': 'Weizmann Institute of Science', 'label': 'Weizmann Institute of Science' },
    { 'id': 4928, 'value': 'Wekerle Sándor Üzleti Foiskola', 'label': 'Wekerle Sándor Üzleti Foiskola' },
    { 'id': 13885, 'value': 'Welch College', 'label': 'Welch College' },
    { 'id': 13886, 'value': 'Wellesley College', 'label': 'Wellesley College' },
    { 'id': 13887, 'value': 'Wells College', 'label': 'Wells College' },
    { 'id': 9453, 'value': 'Wellspring University', 'label': 'Wellspring University' },
    { 'id': 2880, 'value': 'Wenhua University', 'label': 'Wenhua University' },
    { 'id': 2881, 'value': 'Wenshan University', 'label': 'Wenshan University' },
    { 'id': 13888, 'value': 'Wentworth Institute of Technology', 'label': 'Wentworth Institute of Technology' },
    { 'id': 11941, 'value': 'Wenzao Ursuline University of Languages', 'label': 'Wenzao Ursuline University of Languages' },
    { 'id': 2882, 'value': 'Wenzhou Business College', 'label': 'Wenzhou Business College' },
    { 'id': 2883, 'value': 'Wenzhou Medical University', 'label': 'Wenzhou Medical University' },
    { 'id': 2884, 'value': 'Wenzhou University', 'label': 'Wenzhou University' },
    { 'id': 2885, 'value': 'Wenzhou-Kean University', 'label': 'Wenzhou-Kean University' },
    { 'id': 13889, 'value': 'Wesley College', 'label': 'Wesley College' },
    { 'id': 4929, 'value': 'Wesley János Lelkészképzo Foiskola', 'label': 'Wesley János Lelkészképzo Foiskola' },
    { 'id': 9454, 'value': 'Wesley University of Science and Technology', 'label': 'Wesley University of Science and Technology' },
    { 'id': 13890, 'value': 'Wesleyan College', 'label': 'Wesleyan College' },
    { 'id': 13891, 'value': 'Wesleyan University', 'label': 'Wesleyan University' },
    { 'id': 10038, 'value': 'Wesleyan University-Philippines', 'label': 'Wesleyan University-Philippines' },
    { 'id': 2886, 'value': 'West Anhui University', 'label': 'West Anhui University' },
    { 'id': 6430, 'value': 'West Bengal State University', 'label': 'West Bengal State University' },
    { 'id': 6431, 'value': 'West Bengal University of Animal and Fishery Sciences', 'label': 'West Bengal University of Animal and Fishery Sciences' },
    { 'id': 6432, 'value': 'West Bengal University of Health Sciences', 'label': 'West Bengal University of Health Sciences' },
    { 'id': 6433, 'value': 'West Bengal University of Teachers\' Training, Education Planning and Administration', 'label': 'West Bengal University of Teachers\' Training, Education Planning and Administration' },
    { 'id': 13892, 'value': 'West Chester University of Pennsylvania', 'label': 'West Chester University of Pennsylvania' },
    { 'id': 13893, 'value': 'West Coast University-Los Angeles', 'label': 'West Coast University-Los Angeles' },
    { 'id': 4727, 'value': 'West End University College', 'label': 'West End University College' },
    { 'id': 8267, 'value': 'West Kazakhstan Agro-Technical University', 'label': 'West Kazakhstan Agro-Technical University' },
    { 'id': 8268, 'value': 'West Kazakhstan State Medical University', 'label': 'West Kazakhstan State Medical University' },
    { 'id': 8269, 'value': 'West Kazakhstan State University', 'label': 'West Kazakhstan State University' },
    { 'id': 11107, 'value': 'West Kordofan University', 'label': 'West Kordofan University' },
    { 'id': 13894, 'value': 'West Liberty University', 'label': 'West Liberty University' },
    { 'id': 13895, 'value': 'West Texas A&M University', 'label': 'West Texas A&M University' },
    { 'id': 13896, 'value': 'West Virginia School of Osteopathic Medicine', 'label': 'West Virginia School of Osteopathic Medicine' },
    { 'id': 13897, 'value': 'West Virginia State University', 'label': 'West Virginia State University' },
    { 'id': 13898, 'value': 'West Virginia University', 'label': 'West Virginia University' },
    { 'id': 13899, 'value': 'West Virginia University at Parkersburg', 'label': 'West Virginia University at Parkersburg' },
    { 'id': 13900, 'value': 'West Virginia University Institute of Technology', 'label': 'West Virginia University Institute of Technology' },
    { 'id': 13901, 'value': 'West Virginia Wesleyan College', 'label': 'West Virginia Wesleyan College' },
    { 'id': 10039, 'value': 'West Visayas State University', 'label': 'West Visayas State University' },
    { 'id': 8584, 'value': 'West Yangon Technological University', 'label': 'West Yangon Technological University' },
    { 'id': 2887, 'value': 'West Yunnan University', 'label': 'West Yunnan University' },
    { 'id': 2888, 'value': 'West Yunnan University of Applied Sciences', 'label': 'West Yunnan University of Applied Sciences' },
    { 'id': 13902, 'value': 'Western Carolina University', 'label': 'Western Carolina University' },
    { 'id': 13903, 'value': 'Western Colorado University', 'label': 'Western Colorado University' },
    { 'id': 13904, 'value': 'Western Connecticut State University', 'label': 'Western Connecticut State University' },
    { 'id': 9455, 'value': 'Western Delta University', 'label': 'Western Delta University' },
    { 'id': 13905, 'value': 'Western Illinois University', 'label': 'Western Illinois University' },
    { 'id': 10040, 'value': 'Western Institute of Technology', 'label': 'Western Institute of Technology' },
    { 'id': 13906, 'value': 'Western Kentucky University', 'label': 'Western Kentucky University' },
    { 'id': 13907, 'value': 'Western Michigan University', 'label': 'Western Michigan University' },
    { 'id': 10041, 'value': 'Western Mindanao State University', 'label': 'Western Mindanao State University' },
    { 'id': 13908, 'value': 'Western Nevada College', 'label': 'Western Nevada College' },
    { 'id': 13909, 'value': 'Western New England University', 'label': 'Western New England University' },
    { 'id': 13910, 'value': 'Western New Mexico University', 'label': 'Western New Mexico University' },
    { 'id': 13911, 'value': 'Western Oregon University', 'label': 'Western Oregon University' },
    { 'id': 10042, 'value': 'Western Philippines University', 'label': 'Western Philippines University' },
    { 'id': 13912, 'value': 'Western State College of Law', 'label': 'Western State College of Law' },
    { 'id': 1107, 'value': 'Western Sydney University', 'label': 'Western Sydney University' },
    { 'id': 1938, 'value': 'Western University', 'label': 'Western University' },
    { 'id': 13913, 'value': 'Western University of Health Sciences', 'label': 'Western University of Health Sciences' },
    { 'id': 7937, 'value': 'Western University, Cambodia', 'label': 'Western University, Cambodia' },
    { 'id': 13914, 'value': 'Western Washington University', 'label': 'Western Washington University' },
    { 'id': 3807, 'value': 'Westfälische Hochschule Gelsenkirchen Bocholt Recklinghausen', 'label': 'Westfälische Hochschule Gelsenkirchen Bocholt Recklinghausen' },
    { 'id': 3808, 'value': 'Westfälische Wilhelms-Universität Münster', 'label': 'Westfälische Wilhelms-Universität Münster' },
    { 'id': 13915, 'value': 'Westfield State University', 'label': 'Westfield State University' },
    { 'id': 13916, 'value': 'Westminster College', 'label': 'Westminster College' },
    { 'id': 13917, 'value': 'Westminster College, Missouri', 'label': 'Westminster College, Missouri' },
    { 'id': 13918, 'value': 'Westminster College, Pennsylvania', 'label': 'Westminster College, Pennsylvania' },
    { 'id': 13919, 'value': 'Westmont College', 'label': 'Westmont College' },
    { 'id': 3809, 'value': 'Westsächsische Hochschule Zwickau', 'label': 'Westsächsische Hochschule Zwickau' },
    { 'id': 511, 'value': 'Wharton School of the University of Pennsylvania', 'label': 'Wharton School of the University of Pennsylvania' },
    { 'id': 13920, 'value': 'Wheaton College', 'label': 'Wheaton College' },
    { 'id': 13921, 'value': 'Wheaton College, Massachusetts', 'label': 'Wheaton College, Massachusetts' },
    { 'id': 13922, 'value': 'Wheeling University', 'label': 'Wheeling University' },
    { 'id': 11108, 'value': 'White Nile University', 'label': 'White Nile University' },
    { 'id': 13923, 'value': 'Whitman College', 'label': 'Whitman College' },
    { 'id': 13924, 'value': 'Whittier College', 'label': 'Whittier College' },
    { 'id': 13925, 'value': 'Whitworth University', 'label': 'Whitworth University' },
    { 'id': 3810, 'value': 'WHU - Otto Beisheim School of Management', 'label': 'WHU - Otto Beisheim School of Management' },
    { 'id': 13926, 'value': 'Wichita State University', 'label': 'Wichita State University' },
    { 'id': 13927, 'value': 'Widener University', 'label': 'Widener University' },
    { 'id': 13928, 'value': 'Wilberforce University', 'label': 'Wilberforce University' },
    { 'id': 13929, 'value': 'Wiley College', 'label': 'Wiley College' },
    { 'id': 1939, 'value': 'Wilfrid Laurier University', 'label': 'Wilfrid Laurier University' },
    { 'id': 3811, 'value': 'Wilhelm Löhe Hochschule', 'label': 'Wilhelm Löhe Hochschule' },
    { 'id': 13930, 'value': 'Wilkes University', 'label': 'Wilkes University' },
    { 'id': 13931, 'value': 'Willamette University', 'label': 'Willamette University' },
    { 'id': 408, 'value': 'Willem de Kooning Academy', 'label': 'Willem de Kooning Academy' },
    { 'id': 13932, 'value': 'William Carey University', 'label': 'William Carey University' },
    { 'id': 6434, 'value': 'William Carey University, Meghalaya', 'label': 'William Carey University, Meghalaya' },
    { 'id': 13933, 'value': 'William James College', 'label': 'William James College' },
    { 'id': 13934, 'value': 'William Jessup University', 'label': 'William Jessup University' },
    { 'id': 13935, 'value': 'William Jewell College', 'label': 'William Jewell College' },
    { 'id': 13936, 'value': 'William Paterson University', 'label': 'William Paterson University' },
    { 'id': 13937, 'value': 'William Peace University', 'label': 'William Peace University' },
    { 'id': 13938, 'value': 'William Penn University', 'label': 'William Penn University' },
    { 'id': 13939, 'value': 'William Woods University', 'label': 'William Woods University' },
    { 'id': 13940, 'value': 'Williams Baptist University', 'label': 'Williams Baptist University' },
    { 'id': 13941, 'value': 'Williams College', 'label': 'Williams College' },
    { 'id': 13942, 'value': 'Wilmington College', 'label': 'Wilmington College' },
    { 'id': 13943, 'value': 'Wilmington University', 'label': 'Wilmington University' },
    { 'id': 159, 'value': 'Wilson College', 'label': 'Wilson College' },
    { 'id': 7939, 'value': 'Windsor University School of Medicine', 'label': 'Windsor University School of Medicine' },
    { 'id': 13944, 'value': 'Wingate University', 'label': 'Wingate University' },
    { 'id': 465, 'value': 'Winnipeg University', 'label': 'Winnipeg University' },
    { 'id': 13945, 'value': 'Winona State University', 'label': 'Winona State University' },
    { 'id': 13946, 'value': 'Winston-Salem State University', 'label': 'Winston-Salem State University' },
    { 'id': 13947, 'value': 'Winthrop University', 'label': 'Winthrop University' },
    { 'id': 1070, 'value': 'Wirtschaftsuniversität Wien', 'label': 'Wirtschaftsuniversität Wien' },
    { 'id': 4728, 'value': 'Wisconsin International University College', 'label': 'Wisconsin International University College' },
    { 'id': 13948, 'value': 'Wisconsin Lutheran College', 'label': 'Wisconsin Lutheran College' },
    { 'id': 13949, 'value': 'Wisconsin School of Professional Psychology', 'label': 'Wisconsin School of Professional Psychology' },
    { 'id': 13950, 'value': 'Wittenberg University', 'label': 'Wittenberg University' },
    { 'id': 9560, 'value': 'Wittenborg University of Applied Sciences', 'label': 'Wittenborg University of Applied Sciences' },
    { 'id': 13951, 'value': 'Wofford College', 'label': 'Wofford College' },
    { 'id': 10326, 'value': 'Wojskowa Akademia Techniczna', 'label': 'Wojskowa Akademia Techniczna' },
    { 'id': 4202, 'value': 'Wolaita Sodo University', 'label': 'Wolaita Sodo University' },
    { 'id': 4203, 'value': 'Woldia University', 'label': 'Woldia University' },
    { 'id': 4204, 'value': 'Wolkite University', 'label': 'Wolkite University' },
    { 'id': 4205, 'value': 'Wollega University', 'label': 'Wollega University' },
    { 'id': 4206, 'value': 'Wollo University', 'label': 'Wollo University' },
    { 'id': 10215, 'value': 'Women University of Azad Jammu and Kashmir Bagh', 'label': 'Women University of Azad Jammu and Kashmir Bagh' },
    { 'id': 10216, 'value': 'Women University Swabi', 'label': 'Women University Swabi' },
    { 'id': 14316, 'value': 'Women\'s University in Africa', 'label': 'Women\'s University in Africa' },
    { 'id': 13952, 'value': 'WON Institute of Graduate Studies', 'label': 'WON Institute of Graduate Studies' },
    { 'id': 8145, 'value': 'Wonkwang University', 'label': 'Wonkwang University' },
    { 'id': 7965, 'value': 'Wonsan Agricultural University', 'label': 'Wonsan Agricultural University' },
    { 'id': 13953, 'value': 'Woodbury University', 'label': 'Woodbury University' },
    { 'id': 8146, 'value': 'Woosong University', 'label': 'Woosong University' },
    { 'id': 8147, 'value': 'Woosuk University', 'label': 'Woosuk University' },
    { 'id': 13954, 'value': 'Worcester Polytechnic Institute', 'label': 'Worcester Polytechnic Institute' },
    { 'id': 13955, 'value': 'Worcester State University', 'label': 'Worcester State University' },
    { 'id': 11146, 'value': 'World Maritime University', 'label': 'World Maritime University' },
    { 'id': 1338, 'value': 'World University of Bangladesh', 'label': 'World University of Bangladesh' },
    { 'id': 4612, 'value': 'Wrexham Glyndwr University', 'label': 'Wrexham Glyndwr University' },
    { 'id': 13956, 'value': 'Wright State University', 'label': 'Wright State University' },
    { 'id': 2889, 'value': 'Wuchang Institute of Technology', 'label': 'Wuchang Institute of Technology' },
    { 'id': 2890, 'value': 'Wuchang Shouyi University', 'label': 'Wuchang Shouyi University' },
    { 'id': 2891, 'value': 'Wuchang University of Technology', 'label': 'Wuchang University of Technology' },
    { 'id': 11942, 'value': 'WuFeng University', 'label': 'WuFeng University' },
    { 'id': 2892, 'value': 'Wuhan Business University', 'label': 'Wuhan Business University' },
    { 'id': 2893, 'value': 'Wuhan College', 'label': 'Wuhan College' },
    { 'id': 2894, 'value': 'Wuhan College of Media and Communications', 'label': 'Wuhan College of Media and Communications' },
    { 'id': 2895, 'value': 'Wuhan Conservatory of Music', 'label': 'Wuhan Conservatory of Music' },
    { 'id': 2896, 'value': 'Wuhan Donghu University', 'label': 'Wuhan Donghu University' },
    { 'id': 2897, 'value': 'Wuhan Huaxia University of Technology', 'label': 'Wuhan Huaxia University of Technology' },
    { 'id': 2898, 'value': 'Wuhan Institute of Bioengineering', 'label': 'Wuhan Institute of Bioengineering' },
    { 'id': 2899, 'value': 'Wuhan Institute of Design and Sciences', 'label': 'Wuhan Institute of Design and Sciences' },
    { 'id': 2900, 'value': 'Wuhan Institute of Technology', 'label': 'Wuhan Institute of Technology' },
    { 'id': 2901, 'value': 'Wuhan Polytechnic University', 'label': 'Wuhan Polytechnic University' },
    { 'id': 2902, 'value': 'Wuhan Qingchuan University', 'label': 'Wuhan Qingchuan University' },
    { 'id': 2903, 'value': 'Wuhan Sports University', 'label': 'Wuhan Sports University' },
    { 'id': 2904, 'value': 'Wuhan Technology and Business University', 'label': 'Wuhan Technology and Business University' },
    { 'id': 2905, 'value': 'Wuhan Textile University', 'label': 'Wuhan Textile University' },
    { 'id': 2906, 'value': 'Wuhan University', 'label': 'Wuhan University' },
    { 'id': 2907, 'value': 'Wuhan University of Engineering Science', 'label': 'Wuhan University of Engineering Science' },
    { 'id': 2908, 'value': 'Wuhan University of Science and Technology', 'label': 'Wuhan University of Science and Technology' },
    { 'id': 2909, 'value': 'Wuhan University of Technology', 'label': 'Wuhan University of Technology' },
    { 'id': 2910, 'value': 'Wuyi University', 'label': 'Wuyi University' },
    { 'id': 2911, 'value': 'Wuyi University, Guangdong', 'label': 'Wuyi University, Guangdong' },
    { 'id': 2912, 'value': 'Wuzhou University', 'label': 'Wuzhou University' },
    { 'id': 10327, 'value': 'Wyzsza Szkola Biznesu - National-Louis University', 'label': 'Wyzsza Szkola Biznesu - National-Louis University' },
    { 'id': 10328, 'value': 'Wyzsza Szkola Ekonomii i Informatyki w Krakowie', 'label': 'Wyzsza Szkola Ekonomii i Informatyki w Krakowie' },
    { 'id': 10329, 'value': 'Wyzsza Szkola Ekonomii, Prawa i Nauk Medycznych', 'label': 'Wyzsza Szkola Ekonomii, Prawa i Nauk Medycznych' },
    { 'id': 10330, 'value': 'Wyzsza Szkola Finansów i Zarzadzania w Bialymstoku', 'label': 'Wyzsza Szkola Finansów i Zarzadzania w Bialymstoku' },
    { 'id': 10331, 'value': 'Wyzsza Szkola Handlu i Finansów Miedzynarodowych im. Fryderyka Skarbka', 'label': 'Wyzsza Szkola Handlu i Finansów Miedzynarodowych im. Fryderyka Skarbka' },
    { 'id': 10332, 'value': 'Wyzsza Szkola Informatyki i Umiejetnosci w Lodzi', 'label': 'Wyzsza Szkola Informatyki i Umiejetnosci w Lodzi' },
    { 'id': 10333, 'value': 'Wyzsza Szkola Informatyki i Zarzadzania im. Prof. Tadeusza Kotarbinskiego', 'label': 'Wyzsza Szkola Informatyki i Zarzadzania im. Prof. Tadeusza Kotarbinskiego' },
    { 'id': 10334, 'value': 'Wyzsza Szkola Informatyki Stosowanej i Zarzadzania', 'label': 'Wyzsza Szkola Informatyki Stosowanej i Zarzadzania' },
    { 'id': 10335, 'value': 'Wyzsza Szkola Lingwistyczna w Czestochowie', 'label': 'Wyzsza Szkola Lingwistyczna w Czestochowie' },
    { 'id': 10336, 'value': 'Wyzsza Szkola Pedagogiczna im. Janusza Korczaka w Warszawie', 'label': 'Wyzsza Szkola Pedagogiczna im. Janusza Korczaka w Warszawie' },
    { 'id': 10337, 'value': 'Wyzsza Szkola Spoleczno-Ekonomiczna w Gdansku', 'label': 'Wyzsza Szkola Spoleczno-Ekonomiczna w Gdansku' },
    { 'id': 10338, 'value': 'Wyzsza Szkola Zarzadzania i Administracji w Zamosciu', 'label': 'Wyzsza Szkola Zarzadzania i Administracji w Zamosciu' },
    { 'id': 10339, 'value': 'Wyzsza Szkola Zarzadzania i Bankowosci w Krakowie', 'label': 'Wyzsza Szkola Zarzadzania i Bankowosci w Krakowie' },
    { 'id': 10340, 'value': 'Wyzsza Szkola Zarzadzania w Gdansku', 'label': 'Wyzsza Szkola Zarzadzania w Gdansku' },
    { 'id': 10341, 'value': 'Wyzsze Szkoly Bankowe', 'label': 'Wyzsze Szkoly Bankowe' },
    { 'id': 13957, 'value': 'Xavier University', 'label': 'Xavier University' },
    { 'id': 6435, 'value': 'Xavier University Bhubaneswar', 'label': 'Xavier University Bhubaneswar' },
    { 'id': 13958, 'value': 'Xavier University of Louisiana', 'label': 'Xavier University of Louisiana' },
    { 'id': 1111, 'value': 'Xavier University School of Medicine', 'label': 'Xavier University School of Medicine' },
    { 'id': 10043, 'value': 'Xavier University-Ateneo de Cagayan', 'label': 'Xavier University-Ateneo de Cagayan' },
    { 'id': 1153, 'value': 'Xezer Universiteti', 'label': 'Xezer Universiteti' },
    { 'id': 2917, 'value': 'Xi\'an Academy of Fine Arts', 'label': 'Xi\'an Academy of Fine Arts' },
    { 'id': 2918, 'value': 'Xi\'an Aeronautical University', 'label': 'Xi\'an Aeronautical University' },
    { 'id': 2919, 'value': 'Xi\'an Conservatory of Music', 'label': 'Xi\'an Conservatory of Music' },
    { 'id': 2920, 'value': 'Xi\'an Eurasia University', 'label': 'Xi\'an Eurasia University' },
    { 'id': 2921, 'value': 'Xi\'an Fanyi University', 'label': 'Xi\'an Fanyi University' },
    { 'id': 2922, 'value': 'Xi\'an International Studies University', 'label': 'Xi\'an International Studies University' },
    { 'id': 2923, 'value': 'Xi\'an International University', 'label': 'Xi\'an International University' },
    { 'id': 2924, 'value': 'Xi\'an Jiaotong University', 'label': 'Xi\'an Jiaotong University' },
    { 'id': 2925, 'value': 'Xi\'an Jiaotong-Liverpool University', 'label': 'Xi\'an Jiaotong-Liverpool University' },
    { 'id': 2926, 'value': 'Xi\'an Medical University', 'label': 'Xi\'an Medical University' },
    { 'id': 2927, 'value': 'Xi\'an Peihua University', 'label': 'Xi\'an Peihua University' },
    { 'id': 2928, 'value': 'Xi\'an Physical Education University', 'label': 'Xi\'an Physical Education University' },
    { 'id': 2929, 'value': 'Xi\'an Polytechnic University', 'label': 'Xi\'an Polytechnic University' },
    { 'id': 2930, 'value': 'Xi\'an Shiyou University', 'label': 'Xi\'an Shiyou University' },
    { 'id': 2931, 'value': 'Xi\'an Siyuan University', 'label': 'Xi\'an Siyuan University' },
    { 'id': 2932, 'value': 'Xi\'an Technological University', 'label': 'Xi\'an Technological University' },
    { 'id': 2933, 'value': 'Xi\'an Traffic Engineering Institute', 'label': 'Xi\'an Traffic Engineering Institute' },
    { 'id': 2934, 'value': 'Xi\'an University', 'label': 'Xi\'an University' },
    { 'id': 2935, 'value': 'Xi\'an University of Architecture and Technology', 'label': 'Xi\'an University of Architecture and Technology' },
    { 'id': 2936, 'value': 'Xi\'an University of Finance and Economics', 'label': 'Xi\'an University of Finance and Economics' },
    { 'id': 2937, 'value': 'Xi\'an University of Posts and Telecommunications', 'label': 'Xi\'an University of Posts and Telecommunications' },
    { 'id': 2938, 'value': 'Xi\'an University of Science and Technology', 'label': 'Xi\'an University of Science and Technology' },
    { 'id': 2939, 'value': 'Xi\'an University of Technology', 'label': 'Xi\'an University of Technology' },
    { 'id': 2913, 'value': 'Xiamen Huaxia University', 'label': 'Xiamen Huaxia University' },
    { 'id': 2914, 'value': 'Xiamen Institude of Technology', 'label': 'Xiamen Institude of Technology' },
    { 'id': 2915, 'value': 'Xiamen University', 'label': 'Xiamen University' },
    { 'id': 2916, 'value': 'Xiamen University of Technology', 'label': 'Xiamen University of Technology' },
    { 'id': 2940, 'value': 'Xiangnan University', 'label': 'Xiangnan University' },
    { 'id': 2941, 'value': 'XiangTan University', 'label': 'XiangTan University' },
    { 'id': 2942, 'value': 'Xianyang Normal University', 'label': 'Xianyang Normal University' },
    { 'id': 2943, 'value': 'Xichang College', 'label': 'Xichang College' },
    { 'id': 2944, 'value': 'Xidian University', 'label': 'Xidian University' },
    { 'id': 2945, 'value': 'Xihua University', 'label': 'Xihua University' },
    { 'id': 2946, 'value': 'Xijing University', 'label': 'Xijing University' },
    { 'id': 2947, 'value': 'Xinghai Conservatory of Music', 'label': 'Xinghai Conservatory of Music' },
    { 'id': 2948, 'value': 'Xingtai University', 'label': 'Xingtai University' },
    { 'id': 2949, 'value': 'Xingyi Normal University for Nationalities', 'label': 'Xingyi Normal University for Nationalities' },
    { 'id': 2950, 'value': 'Xinjiang Agricultural University', 'label': 'Xinjiang Agricultural University' },
    { 'id': 2951, 'value': 'Xinjiang Arts University', 'label': 'Xinjiang Arts University' },
    { 'id': 2952, 'value': 'Xinjiang Institute of Engineering', 'label': 'Xinjiang Institute of Engineering' },
    { 'id': 2953, 'value': 'Xinjiang Medical University', 'label': 'Xinjiang Medical University' },
    { 'id': 2954, 'value': 'Xinjiang Normal University', 'label': 'Xinjiang Normal University' },
    { 'id': 2955, 'value': 'Xinjiang University', 'label': 'Xinjiang University' },
    { 'id': 2956, 'value': 'Xinjiang University of Finance and Economics', 'label': 'Xinjiang University of Finance and Economics' },
    { 'id': 2957, 'value': 'Xinxiang Medical University', 'label': 'Xinxiang Medical University' },
    { 'id': 2958, 'value': 'Xinxiang University', 'label': 'Xinxiang University' },
    { 'id': 2959, 'value': 'Xinyang Agriculture and Forestry University', 'label': 'Xinyang Agriculture and Forestry University' },
    { 'id': 2960, 'value': 'Xinyang Normal University', 'label': 'Xinyang Normal University' },
    { 'id': 2961, 'value': 'Xinyang University', 'label': 'Xinyang University' },
    { 'id': 2962, 'value': 'Xinyu University', 'label': 'Xinyu University' },
    { 'id': 2963, 'value': 'Xinzhou Teachers University', 'label': 'Xinzhou Teachers University' },
    { 'id': 2964, 'value': 'Xizang Minzu University', 'label': 'Xizang Minzu University' },
    { 'id': 2965, 'value': 'Xuchang University', 'label': 'Xuchang University' },
    { 'id': 2966, 'value': 'Xuzhou Institute of Technology', 'label': 'Xuzhou Institute of Technology' },
    { 'id': 2967, 'value': 'Xuzhou Medical University', 'label': 'Xuzhou Medical University' },
    { 'id': 8585, 'value': 'Yadanabon University', 'label': 'Yadanabon University' },
    { 'id': 3390, 'value': 'Yakin Dogu Üniversitesi', 'label': 'Yakin Dogu Üniversitesi' },
    { 'id': 10984, 'value': 'Yakutsk State Academy of Agriculture', 'label': 'Yakutsk State Academy of Agriculture' },
    { 'id': 11498, 'value': 'Yala Rajabhat University', 'label': 'Yala Rajabhat University' },
    { 'id': 13959, 'value': 'Yale University', 'label': 'Yale University' },
    { 'id': 11791, 'value': 'Yalova Üniversitesi', 'label': 'Yalova Üniversitesi' },
    { 'id': 7764, 'value': 'Yamagata Prefectural University of Health Sciences', 'label': 'Yamagata Prefectural University of Health Sciences' },
    { 'id': 7765, 'value': 'Yamagata University', 'label': 'Yamagata University' },
    { 'id': 7766, 'value': 'Yamaguchi Gakugei College', 'label': 'Yamaguchi Gakugei College' },
    { 'id': 7767, 'value': 'Yamaguchi Prefectural University', 'label': 'Yamaguchi Prefectural University' },
    { 'id': 7768, 'value': 'Yamaguchi University', 'label': 'Yamaguchi University' },
    { 'id': 7769, 'value': 'Yamanashi Eiwa College', 'label': 'Yamanashi Eiwa College' },
    { 'id': 7770, 'value': 'Yamanashi Gakuin University', 'label': 'Yamanashi Gakuin University' },
    { 'id': 7771, 'value': 'Yamanashi Prefectural University', 'label': 'Yamanashi Prefectural University' },
    { 'id': 2968, 'value': 'Yan\'an University', 'label': 'Yan\'an University' },
    { 'id': 2969, 'value': 'Yanbian University', 'label': 'Yanbian University' },
    { 'id': 2970, 'value': 'Yanbian University of Science and Technology', 'label': 'Yanbian University of Science and Technology' },
    { 'id': 2971, 'value': 'Yancheng Institute of Technology', 'label': 'Yancheng Institute of Technology' },
    { 'id': 2972, 'value': 'Yancheng Teachers University', 'label': 'Yancheng Teachers University' },
    { 'id': 2973, 'value': 'Yang-En University', 'label': 'Yang-En University' },
    { 'id': 8586, 'value': 'Yangon Technological University', 'label': 'Yangon Technological University' },
    { 'id': 8587, 'value': 'Yangon University', 'label': 'Yangon University' },
    { 'id': 2974, 'value': 'Yangtze Normal University', 'label': 'Yangtze Normal University' },
    { 'id': 2975, 'value': 'Yangtze University', 'label': 'Yangtze University' },
    { 'id': 2976, 'value': 'Yangzhou University', 'label': 'Yangzhou University' },
    { 'id': 2977, 'value': 'Yanjing Institute of Technology', 'label': 'Yanjing Institute of Technology' },
    { 'id': 1845, 'value': 'Yanka Kupala State University of Grodno', 'label': 'Yanka Kupala State University of Grodno' },
    { 'id': 2978, 'value': 'Yanshan University', 'label': 'Yanshan University' },
    { 'id': 2979, 'value': 'Yantai Nanshan University', 'label': 'Yantai Nanshan University' },
    { 'id': 2980, 'value': 'Yantai University', 'label': 'Yantai University' },
    { 'id': 11357, 'value': 'Yarmouk Private University', 'label': 'Yarmouk Private University' },
    { 'id': 7061, 'value': 'Yarmouk University', 'label': 'Yarmouk University' },
    { 'id': 12148, 'value': 'Yaroslav Mudryi National Law University', 'label': 'Yaroslav Mudryi National Law University' },
    { 'id': 10985, 'value': 'Yaroslavl State Academy of Agriculture', 'label': 'Yaroslavl State Academy of Agriculture' },
    { 'id': 10986, 'value': 'Yaroslavl State Medical University', 'label': 'Yaroslavl State Medical University' },
    { 'id': 10987, 'value': 'Yaroslavl State Pedagogical University', 'label': 'Yaroslavl State Pedagogical University' },
    { 'id': 10988, 'value': 'Yaroslavl State Technical University', 'label': 'Yaroslavl State Technical University' },
    { 'id': 10989, 'value': 'Yaroslavl State University', 'label': 'Yaroslavl State University' },
    { 'id': 11792, 'value': 'Yasar Üniversitesi', 'label': 'Yasar Üniversitesi' },
    { 'id': 7772, 'value': 'Yashima Gakuen University', 'label': 'Yashima Gakuen University' },
    { 'id': 7773, 'value': 'Yasuda Women\'s University', 'label': 'Yasuda Women\'s University' },
    { 'id': 6803, 'value': 'Yasuj University', 'label': 'Yasuj University' },
    { 'id': 6804, 'value': 'Yasuj University of Medical Sciences', 'label': 'Yasuj University of Medical Sciences' },
    { 'id': 6805, 'value': 'Yazd University', 'label': 'Yazd University' },
    { 'id': 6436, 'value': 'YBN University', 'label': 'YBN University' },
    { 'id': 11793, 'value': 'Yeditepe Üniversitesi', 'label': 'Yeditepe Üniversitesi' },
    { 'id': 11297, 'value': 'Yei Agricultural and Mechanical University', 'label': 'Yei Agricultural and Mechanical University' },
    { 'id': 10990, 'value': 'Yelets State University', 'label': 'Yelets State University' },
    { 'id': 14217, 'value': 'Yemen and the Gulf University of Science and Technology', 'label': 'Yemen and the Gulf University of Science and Technology' },
    { 'id': 14218, 'value': 'Yemeni Jordanian University', 'label': 'Yemeni Jordanian University' },
    { 'id': 14219, 'value': 'Yemeni University', 'label': 'Yemeni University' },
    { 'id': 14220, 'value': 'Yemenia University', 'label': 'Yemenia University' },
    { 'id': 6437, 'value': 'Yenepoya University', 'label': 'Yenepoya University' },
    { 'id': 842, 'value': 'Yerevan Agricultural University', 'label': 'Yerevan Agricultural University' },
    { 'id': 843, 'value': 'Yerevan Brusov State University of Languages and Social Sciences', 'label': 'Yerevan Brusov State University of Languages and Social Sciences' },
    { 'id': 844, 'value': 'Yerevan Gladzor University', 'label': 'Yerevan Gladzor University' },
    { 'id': 845, 'value': 'Yerevan Komitas State Conservatory', 'label': 'Yerevan Komitas State Conservatory' },
    { 'id': 846, 'value': 'Yerevan Medical Institute after Mehrabyan', 'label': 'Yerevan Medical Institute after Mehrabyan' },
    { 'id': 847, 'value': 'Yerevan Mesrop Mashtots University', 'label': 'Yerevan Mesrop Mashtots University' },
    { 'id': 848, 'value': 'Yerevan Northern University', 'label': 'Yerevan Northern University' },
    { 'id': 849, 'value': 'Yerevan State Institute of Theatre and Cinema', 'label': 'Yerevan State Institute of Theatre and Cinema' },
    { 'id': 850, 'value': 'Yerevan State Medical University', 'label': 'Yerevan State Medical University' },
    { 'id': 851, 'value': 'Yerevan State University', 'label': 'Yerevan State University' },
    { 'id': 852, 'value': 'Yerevan University after Movses Khorenatsi', 'label': 'Yerevan University after Movses Khorenatsi' },
    { 'id': 853, 'value': 'Yerevan University of Management', 'label': 'Yerevan University of Management' },
    { 'id': 13960, 'value': 'Yeshiva University', 'label': 'Yeshiva University' },
    { 'id': 8148, 'value': 'Yeungnam University', 'label': 'Yeungnam University' },
    { 'id': 8149, 'value': 'Yewon Arts University', 'label': 'Yewon Arts University' },
    { 'id': 8588, 'value': 'Yezin Agricultural University', 'label': 'Yezin Agricultural University' },
    { 'id': 2981, 'value': 'Yibin University', 'label': 'Yibin University' },
    { 'id': 2982, 'value': 'Yichun University', 'label': 'Yichun University' },
    { 'id': 11794, 'value': 'Yildiz Teknik Üniversitesi', 'label': 'Yildiz Teknik Üniversitesi' },
    { 'id': 2983, 'value': 'Yili Normal University', 'label': 'Yili Normal University' },
    { 'id': 2984, 'value': 'Yinchuan Energy Institute', 'label': 'Yinchuan Energy Institute' },
    { 'id': 2985, 'value': 'Yingkou Institute of Technology', 'label': 'Yingkou Institute of Technology' },
    { 'id': 9456, 'value': 'Yobe State University', 'label': 'Yobe State University' },
    { 'id': 6438, 'value': 'Yogi Vemana University', 'label': 'Yogi Vemana University' },
    { 'id': 7774, 'value': 'Yokkaichi Nursing and Medical Care University', 'label': 'Yokkaichi Nursing and Medical Care University' },
    { 'id': 7775, 'value': 'Yokkaichi University', 'label': 'Yokkaichi University' },
    { 'id': 7776, 'value': 'Yokohama City University', 'label': 'Yokohama City University' },
    { 'id': 7777, 'value': 'Yokohama College of Commerce', 'label': 'Yokohama College of Commerce' },
    { 'id': 7778, 'value': 'Yokohama College of Pharmacy', 'label': 'Yokohama College of Pharmacy' },
    { 'id': 7779, 'value': 'Yokohama National University', 'label': 'Yokohama National University' },
    { 'id': 8150, 'value': 'Yong-In University', 'label': 'Yong-In University' },
    { 'id': 8151, 'value': 'Yonsei University', 'label': 'Yonsei University' },
    { 'id': 13961, 'value': 'York College', 'label': 'York College' },
    { 'id': 13962, 'value': 'York College of Pennsylvania', 'label': 'York College of Pennsylvania' },
    { 'id': 13963, 'value': 'York College, City University of New York', 'label': 'York College, City University of New York' },
    { 'id': 4613, 'value': 'York St John University', 'label': 'York St John University' },
    { 'id': 1940, 'value': 'York University', 'label': 'York University' },
    { 'id': 1941, 'value': 'Yorkville University', 'label': 'Yorkville University' },
    { 'id': 2986, 'value': 'Youjiang Medical University for Nationalities', 'label': 'Youjiang Medical University for Nationalities' },
    { 'id': 13964, 'value': 'Young Harris College', 'label': 'Young Harris College' },
    { 'id': 8152, 'value': 'Youngnam Theological University and Seminary', 'label': 'Youngnam Theological University and Seminary' },
    { 'id': 8153, 'value': 'Youngsan University', 'label': 'Youngsan University' },
    { 'id': 8154, 'value': 'Youngsan University of Son Studies', 'label': 'Youngsan University of Son Studies' },
    { 'id': 13965, 'value': 'Youngstown State University', 'label': 'Youngstown State University' },
    { 'id': 11795, 'value': 'Yozgat Bozok Üniversitesi', 'label': 'Yozgat Bozok Üniversitesi' },
    { 'id': 4240, 'value': 'Yrkeshögskolan Arcada', 'label': 'Yrkeshögskolan Arcada' },
    { 'id': 4241, 'value': 'Yrkeshögskolan Novia', 'label': 'Yrkeshögskolan Novia' },
    { 'id': 11943, 'value': 'Yu Da University of Science and Technology', 'label': 'Yu Da University of Science and Technology' },
    { 'id': 11944, 'value': 'Yuan Ze University', 'label': 'Yuan Ze University' },
    { 'id': 11945, 'value': 'Yuanpei University', 'label': 'Yuanpei University' },
    { 'id': 11796, 'value': 'Yüksek Ihtisas Üniversitesi', 'label': 'Yüksek Ihtisas Üniversitesi' },
    { 'id': 2987, 'value': 'Yulin Normal University', 'label': 'Yulin Normal University' },
    { 'id': 2988, 'value': 'Yulin University', 'label': 'Yulin University' },
    { 'id': 2989, 'value': 'Yuncheng University', 'label': 'Yuncheng University' },
    { 'id': 11946, 'value': 'Yung Ta Institute of Technology and Commerce', 'label': 'Yung Ta Institute of Technology and Commerce' },
    { 'id': 2990, 'value': 'Yunnan Agricultural University', 'label': 'Yunnan Agricultural University' },
    { 'id': 2991, 'value': 'Yunnan Arts University', 'label': 'Yunnan Arts University' },
    { 'id': 2992, 'value': 'Yunnan College of Business Management', 'label': 'Yunnan College of Business Management' },
    { 'id': 2993, 'value': 'Yunnan Minzu University', 'label': 'Yunnan Minzu University' },
    { 'id': 2994, 'value': 'Yunnan Normal University', 'label': 'Yunnan Normal University' },
    { 'id': 2995, 'value': 'Yunnan Technology and Business University', 'label': 'Yunnan Technology and Business University' },
    { 'id': 2996, 'value': 'Yunnan University', 'label': 'Yunnan University' },
    { 'id': 2997, 'value': 'Yunnan University of Finance and Economics', 'label': 'Yunnan University of Finance and Economics' },
    { 'id': 2998, 'value': 'Yunnan University of Traditional Chinese Medicine', 'label': 'Yunnan University of Traditional Chinese Medicine' },
    { 'id': 12149, 'value': 'Yuriy Fedkovych Chernivtsi National University', 'label': 'Yuriy Fedkovych Chernivtsi National University' },
    { 'id': 9457, 'value': 'Yusuf Maitama Sule University Kano', 'label': 'Yusuf Maitama Sule University Kano' },
    { 'id': 2999, 'value': 'Yuxi Normal University', 'label': 'Yuxi Normal University' },
    { 'id': 3000, 'value': 'Yuzhang Normal University', 'label': 'Yuzhang Normal University' },
    { 'id': 1339, 'value': 'Z.H. Sikder University of Science and Technology', 'label': 'Z.H. Sikder University of Science and Technology' },
    { 'id': 6806, 'value': 'Zabol University of Medical Sciences', 'label': 'Zabol University of Medical Sciences' },
    { 'id': 10342, 'value': 'Zachodniopomorska Szkola Biznesu w Szczecinie', 'label': 'Zachodniopomorska Szkola Biznesu w Szczecinie' },
    { 'id': 10343, 'value': 'Zachodniopomorski Uniwersytet Technologiczny w Szczecinie', 'label': 'Zachodniopomorski Uniwersytet Technologiczny w Szczecinie' },
    { 'id': 4092, 'value': 'Zagazig University', 'label': 'Zagazig University' },
    { 'id': 4861, 'value': 'Zagrebacka škola ekonomije i managementa', 'label': 'Zagrebacka škola ekonomije i managementa' },
    { 'id': 6807, 'value': 'Zahedan University of Medical Sciences', 'label': 'Zahedan University of Medical Sciences' },
    { 'id': 14297, 'value': 'Zambian Christian University', 'label': 'Zambian Christian University' },
    { 'id': 14298, 'value': 'Zambian Royal Medical University', 'label': 'Zambian Royal Medical University' },
    { 'id': 10044, 'value': 'Zamboanga State College of Marine Sciences and Technology', 'label': 'Zamboanga State College of Marine Sciences and Technology' },
    { 'id': 9458, 'value': 'Zamfara State University', 'label': 'Zamfara State University' },
    { 'id': 6808, 'value': 'Zanjan University', 'label': 'Zanjan University' },
    { 'id': 6809, 'value': 'Zanjan University of Medical Sciences', 'label': 'Zanjan University of Medical Sciences' },
    { 'id': 11978, 'value': 'Zanzibar University', 'label': 'Zanzibar University' },
    { 'id': 3001, 'value': 'Zaozhuang University', 'label': 'Zaozhuang University' },
    { 'id': 3449, 'value': 'Západoceská Univerzita v Plzni', 'label': 'Západoceská Univerzita v Plzni' },
    { 'id': 12150, 'value': 'Zaporizhia Medical Academy of Postgraduate Education', 'label': 'Zaporizhia Medical Academy of Postgraduate Education' },
    { 'id': 12151, 'value': 'Zaporizhia State Engineering Academy', 'label': 'Zaporizhia State Engineering Academy' },
    { 'id': 12152, 'value': 'Zaporizhia State Medical University', 'label': 'Zaporizhia State Medical University' },
    { 'id': 12153, 'value': 'Zaporizhzhya National Technical University', 'label': 'Zaporizhzhya National Technical University' },
    { 'id': 12154, 'value': 'Zaporizhzhya National University', 'label': 'Zaporizhzhya National University' },
    { 'id': 7062, 'value': 'Zarqa University', 'label': 'Zarqa University' },
    { 'id': 8652, 'value': 'Zasagt Khan Institute', 'label': 'Zasagt Khan Institute' },
    { 'id': 775, 'value': 'Zawul Institute of Higher Education', 'label': 'Zawul Institute of Higher Education' },
    { 'id': 678, 'value': 'Zayed University', 'label': 'Zayed University' },
    { 'id': 14299, 'value': 'ZCAS University', 'label': 'ZCAS University' },
    { 'id': 4862, 'value': 'Zdravstveno veleucilište Zagreb', 'label': 'Zdravstveno veleucilište Zagreb' },
    { 'id': 4729, 'value': 'Zenith University College', 'label': 'Zenith University College' },
    { 'id': 3812, 'value': 'Zeppelin University', 'label': 'Zeppelin University' },
    { 'id': 7841, 'value': 'Zetech University', 'label': 'Zetech University' },
    { 'id': 3002, 'value': 'Zhangjiakou University', 'label': 'Zhangjiakou University' },
    { 'id': 3003, 'value': 'Zhaoqing University', 'label': 'Zhaoqing University' },
    { 'id': 3004, 'value': 'Zhaotong University', 'label': 'Zhaotong University' },
    { 'id': 3005, 'value': 'Zhejiang Chinese Medical University', 'label': 'Zhejiang Chinese Medical University' },
    { 'id': 3006, 'value': 'Zhejiang Conservatory of Music', 'label': 'Zhejiang Conservatory of Music' },
    { 'id': 3007, 'value': 'Zhejiang Forestry University', 'label': 'Zhejiang Forestry University' },
    { 'id': 3008, 'value': 'Zhejiang Gongshang University', 'label': 'Zhejiang Gongshang University' },
    { 'id': 3009, 'value': 'Zhejiang International Studies University', 'label': 'Zhejiang International Studies University' },
    { 'id': 3010, 'value': 'Zhejiang Normal University', 'label': 'Zhejiang Normal University' },
    { 'id': 3011, 'value': 'Zhejiang Ocean University', 'label': 'Zhejiang Ocean University' },
    { 'id': 3012, 'value': 'Zhejiang Sci-Tech University', 'label': 'Zhejiang Sci-Tech University' },
    { 'id': 3013, 'value': 'Zhejiang Shuren University', 'label': 'Zhejiang Shuren University' },
    { 'id': 3014, 'value': 'Zhejiang University', 'label': 'Zhejiang University' },
    { 'id': 3015, 'value': 'Zhejiang University of Finance and Economics', 'label': 'Zhejiang University of Finance and Economics' },
    { 'id': 3016, 'value': 'Zhejiang University of Science and Technology', 'label': 'Zhejiang University of Science and Technology' },
    { 'id': 3017, 'value': 'Zhejiang University of Technology', 'label': 'Zhejiang University of Technology' },
    { 'id': 3018, 'value': 'Zhejiang University of Water Resources and Electric Power', 'label': 'Zhejiang University of Water Resources and Electric Power' },
    { 'id': 3019, 'value': 'Zhejiang Wanli University', 'label': 'Zhejiang Wanli University' },
    { 'id': 3020, 'value': 'Zhejiang Yuexiu University of Foreign Languages', 'label': 'Zhejiang Yuexiu University of Foreign Languages' },
    { 'id': 3021, 'value': 'Zhengzhou Chenggong University of Finance and Economics', 'label': 'Zhengzhou Chenggong University of Finance and Economics' },
    { 'id': 3022, 'value': 'Zhengzhou Institute of Finance and Economics', 'label': 'Zhengzhou Institute of Finance and Economics' },
    { 'id': 3023, 'value': 'Zhengzhou Institute of Technology', 'label': 'Zhengzhou Institute of Technology' },
    { 'id': 3024, 'value': 'Zhengzhou Normal University', 'label': 'Zhengzhou Normal University' },
    { 'id': 3025, 'value': 'Zhengzhou Shengda University of Economics, Business and Management', 'label': 'Zhengzhou Shengda University of Economics, Business and Management' },
    { 'id': 3026, 'value': 'Zhengzhou Technology and Business University', 'label': 'Zhengzhou Technology and Business University' },
    { 'id': 3027, 'value': 'Zhengzhou University', 'label': 'Zhengzhou University' },
    { 'id': 3028, 'value': 'Zhengzhou University of Aeronautics', 'label': 'Zhengzhou University of Aeronautics' },
    { 'id': 3029, 'value': 'Zhengzhou University of Industrial Technology', 'label': 'Zhengzhou University of Industrial Technology' },
    { 'id': 3030, 'value': 'Zhengzhou University of Light Industry', 'label': 'Zhengzhou University of Light Industry' },
    { 'id': 3031, 'value': 'Zhengzhou University of Science and Technology', 'label': 'Zhengzhou University of Science and Technology' },
    { 'id': 8270, 'value': 'Zhetysu State University', 'label': 'Zhetysu State University' },
    { 'id': 8271, 'value': 'Zhezkazgan University', 'label': 'Zhezkazgan University' },
    { 'id': 3032, 'value': 'Zhongkai University of Agriculture and Engineering', 'label': 'Zhongkai University of Agriculture and Engineering' },
    { 'id': 3033, 'value': 'Zhongnan University of Economics and Law', 'label': 'Zhongnan University of Economics and Law' },
    { 'id': 3034, 'value': 'Zhongyuan University of Technology', 'label': 'Zhongyuan University of Technology' },
    { 'id': 3035, 'value': 'Zhoukou Normal University', 'label': 'Zhoukou Normal University' },
    { 'id': 12155, 'value': 'Zhytomyr Ivan Franko State University', 'label': 'Zhytomyr Ivan Franko State University' },
    { 'id': 12156, 'value': 'Zhytomyr National Agroecological University', 'label': 'Zhytomyr National Agroecological University' },
    { 'id': 12157, 'value': 'Zhytomyr State Technological University', 'label': 'Zhytomyr State Technological University' },
    { 'id': 10217, 'value': 'Ziauddin University', 'label': 'Ziauddin University' },
    { 'id': 11217, 'value': 'Žilinská univerzita v Žiline', 'label': 'Žilinská univerzita v Žiline' },
    { 'id': 14317, 'value': 'Zimbabwe Ezekiel Guti University', 'label': 'Zimbabwe Ezekiel Guti University' },
    { 'id': 1340, 'value': 'ZNRF University of Management Sciences', 'label': 'ZNRF University of Management Sciences' },
    { 'id': 8653, 'value': 'Zokhiomj Institute', 'label': 'Zokhiomj Institute' },
    { 'id': 3036, 'value': 'Zunyi Medical University', 'label': 'Zunyi Medical University' },
    { 'id': 3037, 'value': 'Zunyi Normal College', 'label': 'Zunyi Normal College' },
    { 'id': 2017, 'value': 'Zürcher Fachhochschule', 'label': 'Zürcher Fachhochschule' },
    { 'id': 9561, 'value': 'Zuyd Hogeschool', 'label': 'Zuyd Hogeschool' },
]

export const courses = [
    { 'school_id': 244, 'value': 'Antropologia Culturale ed Etnologia', 'label': 'Antropologia Culturale ed Etnologia' },
    { 'school_id': 24, 'value': 'Antropologia Culturale ed Etnologia', 'label': 'Antropologia Culturale ed Etnologia' },
    { 'school_id': 6831, 'value': 'Antropologia Culturale, Etnologia, Etnolinguistica', 'label': 'Antropologia Culturale, Etnologia, Etnolinguistica' },
    { 'school_id': 137, 'value': 'Antropologia e Linguaggi dell\'Immagine', 'label': 'Antropologia e Linguaggi dell\'Immagine' },
    { 'school_id': 61, 'value': 'Antropologia e Storia del Mondo Contemporaneo', 'label': 'Antropologia e Storia del Mondo Contemporaneo' },
    { 'school_id': 13, 'value': 'Discipline Etno-Antropologiche', 'label': 'Discipline Etno-Antropologiche' },
    { 'school_id': 6831, 'value': 'Environmental Humanities', 'label': 'Environmental Humanities' },
    { 'school_id': 478, 'value': 'Scienze Antropologiche e Geografiche per i Patrimoni Culturali e la Valorizzazione dei Territori (Sage)', 'label': 'Scienze Antropologiche e Geografiche per i Patrimoni Culturali e la Valorizzazione dei Territori (Sage)' },
    { 'school_id': 66, 'value': 'Scienze Antropologiche ed Etnologiche', 'label': 'Scienze Antropologiche ed Etnologiche' },
    { 'school_id': 20, 'value': 'Scienze Socioantropologiche per l\'Integrazione e la Sicurezza Sociale', 'label': 'Scienze Socioantropologiche per l\'Integrazione e la Sicurezza Sociale' },
    { 'school_id': 157, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 28, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 146, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 137, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 13, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 77, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 72, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 80, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 6874, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 69, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 81, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 115, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 389, 'value': 'Archeologia Classica', 'label': 'Archeologia Classica' },
    { 'school_id': 38, 'value': 'Archeologia e Culture Antiche', 'label': 'Archeologia e Culture Antiche' },
    { 'school_id': 24, 'value': 'Archeologia e Culture del Mondo Antico', 'label': 'Archeologia e Culture del Mondo Antico' },
    { 'school_id': 131, 'value': 'Archeologia e Culture dell\'Antichita\'', 'label': 'Archeologia e Culture dell\'Antichita\'' },
    { 'school_id': 244, 'value': 'Archeologia e Storia Antica', 'label': 'Archeologia e Storia Antica' },
    { 'school_id': 14336, 'value': 'Archeologia e Storia dell\'Arte', 'label': 'Archeologia e Storia dell\'Arte' },
    { 'school_id': 20, 'value': 'Archeologia e Storia dell\'Arte', 'label': 'Archeologia e Storia dell\'Arte' },
    { 'school_id': 35, 'value': 'Archeologia e Storia dell\'Arte', 'label': 'Archeologia e Storia dell\'Arte' },
    { 'school_id': 110, 'value': 'Archeologia e Storia dell\'Arte', 'label': 'Archeologia e Storia dell\'Arte' },
    { 'school_id': 91, 'value': 'Archeologia e Storia dell\'Arte', 'label': 'Archeologia e Storia dell\'Arte' },
    { 'school_id': 124, 'value': 'Archeologia e Storia dell\'Arte', 'label': 'Archeologia e Storia dell\'Arte' },
    { 'school_id': 46, 'value': 'Archeologia e Storia dell\'Arte. Tutela e Valorizzazione', 'label': 'Archeologia e Storia dell\'Arte. Tutela e Valorizzazione' },
    { 'school_id': 151, 'value': 'Archeologia, Filologia, Letterature e Storia dell\'Antichità', 'label': 'Archeologia, Filologia, Letterature e Storia dell\'Antichità' },
    { 'school_id': 12, 'value': 'Archeologia: Oriente e Occidente', 'label': 'Archeologia: Oriente e Occidente' },
    { 'school_id': 478, 'value': 'Asa: Archeologia e Storia dell\'Arte', 'label': 'Asa: Archeologia e Storia dell\'Arte' },
    { 'school_id': 114, 'value': 'Beni Archeologici e Storico-Artistici', 'label': 'Beni Archeologici e Storico-Artistici' },
    { 'school_id': 24, 'value': 'Beni Archeologici, Artistici e del Paesaggio: Storia, Tutela e Valorizzazione', 'label': 'Beni Archeologici, Artistici e del Paesaggio: Storia, Tutela e Valorizzazione' },
    { 'school_id': 146, 'value': 'Orientalistica: Egitto, Vicino e Medio Oriente', 'label': 'Orientalistica: Egitto, Vicino e Medio Oriente' },
    { 'school_id': 223, 'value': 'Quaternario, Preistoria e Archeologia', 'label': 'Quaternario, Preistoria e Archeologia' },
    { 'school_id': 6833, 'value': 'Scienze Archeologiche', 'label': 'Scienze Archeologiche' },
    { 'school_id': 109, 'value': 'Scienze dell\'Antichità: Archeologia, Filologia e Letterature, Storia', 'label': 'Scienze dell\'Antichità: Archeologia, Filologia e Letterature, Storia' },
    { 'school_id': 6831, 'value': 'Scienze dell\'Antichità: Letterature, Storia e Archeologia', 'label': 'Scienze dell\'Antichità: Letterature, Storia e Archeologia' },
    { 'school_id': 175, 'value': 'The Ancient Mediterranean World. History, Archaeology And Art', 'label': 'The Ancient Mediterranean World. History, Archaeology And Art' },
    { 'school_id': 128, 'value': 'Tradizione Classica e Archeologia del Mediterraneo', 'label': 'Tradizione Classica e Archeologia del Mediterraneo' },
    { 'school_id': 28, 'value': 'Architettura del Paesaggio', 'label': 'Architettura del Paesaggio' },
    { 'school_id': 13, 'value': 'Architettura del Paesaggio', 'label': 'Architettura del Paesaggio' },
    { 'school_id': 69, 'value': 'Architettura del Paesaggio', 'label': 'Architettura del Paesaggio' },
    { 'school_id': 95, 'value': 'Landscape Architecture. Land Landscape Heritage', 'label': 'Landscape Architecture. Land Landscape Heritage' },
    { 'school_id': 109, 'value': 'Progettazione delle Aree Verdi e del Paesaggio', 'label': 'Progettazione delle Aree Verdi e del Paesaggio' },
    { 'school_id': 95, 'value': 'Architectural Design And History - Progettazione Architettonica e Storia', 'label': 'Architectural Design And History - Progettazione Architettonica e Storia' },
    { 'school_id': 6832, 'value': 'Architecture', 'label': 'Architecture' },
    { 'school_id': 109, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 6832, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 131, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 60, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 28, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 115, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 124, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 95, 'value': 'Architettura - Ambiente Costruito - Interni', 'label': 'Architettura - Ambiente Costruito - Interni' },
    { 'school_id': 77, 'value': 'Architettura - Progettazione Architettonica', 'label': 'Architettura - Progettazione Architettonica' },
    { 'school_id': 91, 'value': 'Architettura - Progettazione degli Interni e per l\'Autonomia', 'label': 'Architettura - Progettazione degli Interni e per l\'Autonomia' },
    { 'school_id': 77, 'value': 'Architettura - Progettazione Urbana', 'label': 'Architettura - Progettazione Urbana' },
    { 'school_id': 77, 'value': 'Architettura - Restauro', 'label': 'Architettura - Restauro' },
    { 'school_id': 414, 'value': 'Architettura - Restauro', 'label': 'Architettura - Restauro' },
    { 'school_id': 13, 'value': 'Architettura - Rigenerazione Urbana', 'label': 'Architettura - Rigenerazione Urbana' },
    { 'school_id': 35, 'value': 'Architettura (Progettazione Architettonica)', 'label': 'Architettura (Progettazione Architettonica)' },
    { 'school_id': 13, 'value': 'Architettura (Restauro)', 'label': 'Architettura (Restauro)' },
    { 'school_id': 18, 'value': 'Architettura Costruzione Città', 'label': 'Architettura Costruzione Città' },
    { 'school_id': 74, 'value': 'Architettura e Citta\' Sostenibili', 'label': 'Architettura e Citta\' Sostenibili' },
    { 'school_id': 95, 'value': 'Architettura e Disegno Urbano', 'label': 'Architettura e Disegno Urbano' },
    { 'school_id': 18, 'value': 'Architettura per il Progetto Sostenibile', 'label': 'Architettura per il Progetto Sostenibile' },
    { 'school_id': 18, 'value': 'Architettura per il Restauro e Valorizzazione del Patrimonio', 'label': 'Architettura per il Restauro e Valorizzazione del Patrimonio' },
    { 'school_id': 95, 'value': 'Architettura-Architettura delle Costruzioni', 'label': 'Architettura-Architettura delle Costruzioni' },
    { 'school_id': 109, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 24, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 95, 'value': 'Sustainable Architecture And Landscape Design - Architettura Sostenibile e Progetto del Paesaggio', 'label': 'Sustainable Architecture And Landscape Design - Architettura Sostenibile e Progetto del Paesaggio' },
    { 'school_id': 55, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 24, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 223, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 13, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 35, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 91, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 114, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 6871, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 478, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 414, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 69, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 27, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 81, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 35, 'value': 'Corso di Laurea Magistrale in Ingegneria Edile-Architettura', 'label': 'Corso di Laurea Magistrale in Ingegneria Edile-Architettura' },
    { 'school_id': 95, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 212, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 175, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 70, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 6833, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 6837, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 146, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 20, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 13, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 151, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 38, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 108, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 6874, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 81, 'value': 'Ingegneria Edile-Architettura', 'label': 'Ingegneria Edile-Architettura' },
    { 'school_id': 13, 'value': 'Archivistica e Biblioteconomia', 'label': 'Archivistica e Biblioteconomia' },
    { 'school_id': 38, 'value': 'Gestione e Valorizzazione degli Archivi e delle Biblioteche', 'label': 'Gestione e Valorizzazione degli Archivi e delle Biblioteche' },
    { 'school_id': 6831, 'value': 'Scienze Archivistiche e Biblioteconomiche', 'label': 'Scienze Archivistiche e Biblioteconomiche' },
    { 'school_id': 28, 'value': 'Scienze Archivistiche e Biblioteconomiche', 'label': 'Scienze Archivistiche e Biblioteconomiche' },
    { 'school_id': 24, 'value': 'Scienze del Libro e del Documento', 'label': 'Scienze del Libro e del Documento' },
    { 'school_id': 244, 'value': 'Scienze del Libro, del Documento, del Patrimonio Culturale', 'label': 'Scienze del Libro, del Documento, del Patrimonio Culturale' },
    { 'school_id': 72, 'value': 'Scienze Storiche e della Documentazione Storica', 'label': 'Scienze Storiche e della Documentazione Storica' },
    { 'school_id': 6833, 'value': 'Archeologia', 'label': 'Archeologia' },
    { 'school_id': 77, 'value': 'Archeologia e Storia dell\'Arte', 'label': 'Archeologia e Storia dell\'Arte' },
    { 'school_id': 35, 'value': 'Archeologia, Storia delle Arti e Scienze del Patrimonio Culturale', 'label': 'Archeologia, Storia delle Arti e Scienze del Patrimonio Culturale' },
    { 'school_id': 74, 'value': 'Beni Artistici e dello Spettacolo', 'label': 'Beni Artistici e dello Spettacolo' },
    { 'school_id': 244, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 70, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 6830, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 131, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 24, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 20, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 114, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 80, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 81, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 151, 'value': 'Beni Culturali (Archeologici, Artistici, Musicali e dello Spettacolo)', 'label': 'Beni Culturali (Archeologici, Artistici, Musicali e dello Spettacolo)' },
    { 'school_id': 124, 'value': 'Beni Culturali e Spettacolo', 'label': 'Beni Culturali e Spettacolo' },
    { 'school_id': 234, 'value': 'Beni Culturali e Turismo', 'label': 'Beni Culturali e Turismo' },
    { 'school_id': 6869, 'value': 'Beni Culturali per il Turismo Sociale e l\'Impresa', 'label': 'Beni Culturali per il Turismo Sociale e l\'Impresa' },
    { 'school_id': 69, 'value': 'Beni Culturali: Conoscenza, Gestione, Valorizzazione', 'label': 'Beni Culturali: Conoscenza, Gestione, Valorizzazione' },
    { 'school_id': 12, 'value': 'Civiltà Antiche e Archeologia: Oriente e Occidente', 'label': 'Civiltà Antiche e Archeologia: Oriente e Occidente' },
    { 'school_id': 109, 'value': 'Conservazione dei Beni Culturali', 'label': 'Conservazione dei Beni Culturali' },
    { 'school_id': 91, 'value': 'Conservazione dei Beni Culturali', 'label': 'Conservazione dei Beni Culturali' },
    { 'school_id': 6831, 'value': 'Conservazione e Gestione dei Beni e delle Attività Culturali', 'label': 'Conservazione e Gestione dei Beni e delle Attività Culturali' },
    { 'school_id': 132, 'value': 'Conservazione e Valorizzazione dei Beni Culturali', 'label': 'Conservazione e Valorizzazione dei Beni Culturali' },
    { 'school_id': 198, 'value': 'Lettere e Beni Culturali', 'label': 'Lettere e Beni Culturali' },
    { 'school_id': 6874, 'value': 'Lettere e Beni Culturali', 'label': 'Lettere e Beni Culturali' },
    { 'school_id': 175, 'value': 'Musicologia', 'label': 'Musicologia' },
    { 'school_id': 478, 'value': 'Operatore dei Beni Culturali', 'label': 'Operatore dei Beni Culturali' },
    { 'school_id': 96, 'value': 'Patrimonio e Turismo Culturale', 'label': 'Patrimonio e Turismo Culturale' },
    { 'school_id': 13, 'value': 'Scienze Archeologiche', 'label': 'Scienze Archeologiche' },
    { 'school_id': 157, 'value': 'Scienze dei Beni Culturali', 'label': 'Scienze dei Beni Culturali' },
    { 'school_id': 14336, 'value': 'Scienze dei Beni Culturali', 'label': 'Scienze dei Beni Culturali' },
    { 'school_id': 146, 'value': 'Scienze dei Beni Culturali', 'label': 'Scienze dei Beni Culturali' },
    { 'school_id': 46, 'value': 'Scienze dei Beni Culturali', 'label': 'Scienze dei Beni Culturali' },
    { 'school_id': 38, 'value': 'Scienze dei Beni Culturali', 'label': 'Scienze dei Beni Culturali' },
    { 'school_id': 72, 'value': 'Scienze dei Beni Culturali', 'label': 'Scienze dei Beni Culturali' },
    { 'school_id': 115, 'value': 'Scienze dei Beni Culturali', 'label': 'Scienze dei Beni Culturali' },
    { 'school_id': 110, 'value': 'Scienze dei Beni Culturali. Turismo, Arte, Archeologia', 'label': 'Scienze dei Beni Culturali. Turismo, Arte, Archeologia' },
    { 'school_id': 175, 'value': 'Scienze Letterarie e dei Beni Culturali', 'label': 'Scienze Letterarie e dei Beni Culturali' },
    { 'school_id': 137, 'value': 'Scienze Storiche e del Patrimonio Culturale', 'label': 'Scienze Storiche e del Patrimonio Culturale' },
    { 'school_id': 28, 'value': 'Storia e Tutela dei Beni Archeologici, Artistici, Archivistici e Librari', 'label': 'Storia e Tutela dei Beni Archeologici, Artistici, Archivistici e Librari' },
    { 'school_id': 6833, 'value': 'Storia e Tutela dei Beni Artistici e Musicali', 'label': 'Storia e Tutela dei Beni Artistici e Musicali' },
    { 'school_id': 13, 'value': 'Studi Storico-Artistici', 'label': 'Studi Storico-Artistici' },
    { 'school_id': 124, 'value': 'Bio-Ecologia Marina', 'label': 'Bio-Ecologia Marina' },
    { 'school_id': 77, 'value': 'Biodiversità e Gestione degli Ecosistemi', 'label': 'Biodiversità e Gestione degli Ecosistemi' },
    { 'school_id': 24, 'value': 'Biodiversità ed Evoluzione', 'label': 'Biodiversità ed Evoluzione' },
    { 'school_id': 157, 'value': 'Biodiversità ed Evoluzione Biologica', 'label': 'Biodiversità ed Evoluzione Biologica' },
    { 'school_id': 69, 'value': 'Biodiversita\' e Biologia Ambientale', 'label': 'Biodiversita\' e Biologia Ambientale' },
    { 'school_id': 151, 'value': 'Bioinformatica', 'label': 'Bioinformatica' },
    { 'school_id': 24, 'value': 'Bioinformatics', 'label': 'Bioinformatics' },
    { 'school_id': 138, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 66, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 137, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 20, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 6859, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 91, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 38, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 198, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 6874, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 115, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 72, 'value': 'Biologia Ambientale', 'label': 'Biologia Ambientale' },
    { 'school_id': 81, 'value': 'Biologia Ambientale', 'label': 'Biologia Ambientale' },
    { 'school_id': 108, 'value': 'Biologia Ambientale e Gestione degli Ecosistemi', 'label': 'Biologia Ambientale e Gestione degli Ecosistemi' },
    { 'school_id': 146, 'value': 'Biologia Applicata alla Biomedicina', 'label': 'Biologia Applicata alla Biomedicina' },
    { 'school_id': 157, 'value': 'Biologia Applicata alla Ricerca Biomedica', 'label': 'Biologia Applicata alla Ricerca Biomedica' },
    { 'school_id': 157, 'value': 'Biologia Applicata alle Scienze della Nutrizione', 'label': 'Biologia Applicata alle Scienze della Nutrizione' },
    { 'school_id': 109, 'value': 'Biologia Applicata e Sperimentale', 'label': 'Biologia Applicata e Sperimentale' },
    { 'school_id': 46, 'value': 'Biologia Cellulare e Molecolare', 'label': 'Biologia Cellulare e Molecolare' },
    { 'school_id': 72, 'value': 'Biologia Cellulare e Molecolare', 'label': 'Biologia Cellulare e Molecolare' },
    { 'school_id': 124, 'value': 'Biologia Cellulare e Molecolare', 'label': 'Biologia Cellulare e Molecolare' },
    { 'school_id': 151, 'value': 'Biologia Cellulare e Molecolare e Scienze Biomediche', 'label': 'Biologia Cellulare e Molecolare e Scienze Biomediche' },
    { 'school_id': 244, 'value': 'Biologia dell\'Ambiente', 'label': 'Biologia dell\'Ambiente' },
    { 'school_id': 28, 'value': 'Biologia dell\'Ambiente e del Comportamento', 'label': 'Biologia dell\'Ambiente e del Comportamento' },
    { 'school_id': 24, 'value': 'Biologia della Salute', 'label': 'Biologia della Salute' },
    { 'school_id': 128, 'value': 'Biologia della Salute delle Tecnologie Applicate e della Nutrizione', 'label': 'Biologia della Salute delle Tecnologie Applicate e della Nutrizione' },
    { 'school_id': 108, 'value': 'Biologia della Salute e della Nutrizione', 'label': 'Biologia della Salute e della Nutrizione' },
    { 'school_id': 35, 'value': 'Biologia delle Produzioni e degli Ambienti Acquatici', 'label': 'Biologia delle Produzioni e degli Ambienti Acquatici' },
    { 'school_id': 13, 'value': 'Biologia e Tecnologie Cellulari', 'label': 'Biologia e Tecnologie Cellulari' },
    { 'school_id': 35, 'value': 'Biologia ed Ecologia dell\' Ambiente Marino ed Uso Sostenibile delle Sue Risorse', 'label': 'Biologia ed Ecologia dell\' Ambiente Marino ed Uso Sostenibile delle Sue Risorse' },
    { 'school_id': 128, 'value': 'Biologia ed Ecologia dell\'Ambiente Marino Costiero', 'label': 'Biologia ed Ecologia dell\'Ambiente Marino Costiero' },
    { 'school_id': 109, 'value': 'Biologia ed Ecologia Marina', 'label': 'Biologia ed Ecologia Marina' },
    { 'school_id': 46, 'value': 'Biologia ed Ecologia Marina', 'label': 'Biologia ed Ecologia Marina' },
    { 'school_id': 6833, 'value': 'Biologia Evoluzionistica', 'label': 'Biologia Evoluzionistica' },
    { 'school_id': 151, 'value': 'Biologia Evoluzionistica, Ecologia e Antropologia Applicata', 'label': 'Biologia Evoluzionistica, Ecologia e Antropologia Applicata' },
    { 'school_id': 6833, 'value': 'Biologia Marina', 'label': 'Biologia Marina' },
    { 'school_id': 24, 'value': 'Biologia Marina', 'label': 'Biologia Marina' },
    { 'school_id': 6837, 'value': 'Biologia Marina', 'label': 'Biologia Marina' },
    { 'school_id': 146, 'value': 'Biologia Marina', 'label': 'Biologia Marina' },
    { 'school_id': 69, 'value': 'Biologia Marina', 'label': 'Biologia Marina' },
    { 'school_id': 6837, 'value': 'Biologia Molecolare e Applicata', 'label': 'Biologia Molecolare e Applicata' },
    { 'school_id': 28, 'value': 'Biologia Molecolare e Applicata', 'label': 'Biologia Molecolare e Applicata' },
    { 'school_id': 24, 'value': 'Biologia Molecolare e Cellulare', 'label': 'Biologia Molecolare e Cellulare' },
    { 'school_id': 146, 'value': 'Biologia Molecolare e Cellulare', 'label': 'Biologia Molecolare e Cellulare' },
    { 'school_id': 169, 'value': 'Biologia Molecolare e Cellulare Applicata', 'label': 'Biologia Molecolare e Cellulare Applicata' },
    { 'school_id': 69, 'value': 'Biologia Molecolare e della Salute', 'label': 'Biologia Molecolare e della Salute' },
    { 'school_id': 23, 'value': 'Biologia Molecolare, Sanitaria e della Nutrizione', 'label': 'Biologia Molecolare, Sanitaria e della Nutrizione' },
    { 'school_id': 77, 'value': 'Biologia per la Ricerca Molecolare, Cellulare e Fisiopatologica', 'label': 'Biologia per la Ricerca Molecolare, Cellulare e Fisiopatologica' },
    { 'school_id': 82, 'value': 'Biologia per la Sostenibilita\'', 'label': 'Biologia per la Sostenibilita\'' },
    { 'school_id': 6833, 'value': 'Biologia Sanitaria', 'label': 'Biologia Sanitaria' },
    { 'school_id': 137, 'value': 'Biologia Sanitaria', 'label': 'Biologia Sanitaria' },
    { 'school_id': 81, 'value': 'Biologia Sanitaria e Cellulare-Molecolare', 'label': 'Biologia Sanitaria e Cellulare-Molecolare' },
    { 'school_id': 61, 'value': 'Biologia Sperimentale e Applicata', 'label': 'Biologia Sperimentale e Applicata' },
    { 'school_id': 175, 'value': 'Biologia Sperimentale ed Applicata', 'label': 'Biologia Sperimentale ed Applicata' },
    { 'school_id': 80, 'value': 'Biologia Sperimentale ed Applicata', 'label': 'Biologia Sperimentale ed Applicata' },
    { 'school_id': 60, 'value': 'Biological Sciences', 'label': 'Biological Sciences' },
    { 'school_id': 148, 'value': 'Biomedical Sciences', 'label': 'Biomedical Sciences' },
    { 'school_id': 244, 'value': 'Biotecnologie Vegetali', 'label': 'Biotecnologie Vegetali' },
    { 'school_id': 244, 'value': 'Cellular And Molecular Biology-Biologia Cellulare e Molecolare', 'label': 'Cellular And Molecular Biology-Biologia Cellulare e Molecolare' },
    { 'school_id': 80, 'value': 'Coastal And Marine Biology And Ecology (Biologia ed Ecologia Costiera e Marina)', 'label': 'Coastal And Marine Biology And Ecology (Biologia ed Ecologia Costiera e Marina)' },
    { 'school_id': 146, 'value': 'Conservazione ed Evoluzione', 'label': 'Conservazione ed Evoluzione' },
    { 'school_id': 35, 'value': 'Corso di Laurea Magistrale in Biologia', 'label': 'Corso di Laurea Magistrale in Biologia' },
    { 'school_id': 13, 'value': 'Ecobiologia', 'label': 'Ecobiologia' },
    { 'school_id': 55, 'value': 'Ecologia dei Cambiamenti Globali', 'label': 'Ecologia dei Cambiamenti Globali' },
    { 'school_id': 74, 'value': 'Ecologia ed Etologia per la Conservazione della Natura', 'label': 'Ecologia ed Etologia per la Conservazione della Natura' },
    { 'school_id': 138, 'value': 'Food Health And Environment', 'label': 'Food Health And Environment' },
    { 'school_id': 13, 'value': 'Genetica e Biologia Molecolare', 'label': 'Genetica e Biologia Molecolare' },
    { 'school_id': 55, 'value': 'Genomica Funzionale', 'label': 'Genomica Funzionale' },
    { 'school_id': 6833, 'value': 'Molecular Biology', 'label': 'Molecular Biology' },
    { 'school_id': 175, 'value': 'Molecular Biology And Genetics', 'label': 'Molecular Biology And Genetics' },
    { 'school_id': 157, 'value': 'Molecular Biology Of The Cell - Biologia Molecolare della Cellula', 'label': 'Molecular Biology Of The Cell - Biologia Molecolare della Cellula' },
    { 'school_id': 175, 'value': 'Neurobiologia', 'label': 'Neurobiologia' },
    { 'school_id': 13, 'value': 'Neurobiologia', 'label': 'Neurobiologia' },
    { 'school_id': 124, 'value': 'Neuropsicobiologia', 'label': 'Neuropsicobiologia' },
    { 'school_id': 146, 'value': 'Neuroscience', 'label': 'Neuroscience' },
    { 'school_id': 69, 'value': 'Neurosciences', 'label': 'Neurosciences' },
    { 'school_id': 55, 'value': 'Neuroscienze', 'label': 'Neuroscienze' },
    { 'school_id': 157, 'value': 'Plant Science - Biologia Vegetale', 'label': 'Plant Science - Biologia Vegetale' },
    { 'school_id': 35, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 74, 'value': 'Scienze Biomediche Traslazionali', 'label': 'Scienze Biomediche Traslazionali' },
    { 'school_id': 223, 'value': 'Scienze Biomolecolari e dell\'Evoluzione', 'label': 'Scienze Biomolecolari e dell\'Evoluzione' },
    { 'school_id': 74, 'value': 'Scienze Biomolecolari, Genomiche e Cellulari', 'label': 'Scienze Biomolecolari, Genomiche e Cellulari' },
    { 'school_id': 72, 'value': 'Scienze Biosanitarie', 'label': 'Scienze Biosanitarie' },
    { 'school_id': 13, 'value': 'Bioinformatics', 'label': 'Bioinformatics' },
    { 'school_id': 60, 'value': 'Bioscienze e Biotecnologia - Biosciences And Biotechnology', 'label': 'Bioscienze e Biotecnologia - Biosciences And Biotechnology' },
    { 'school_id': 157, 'value': 'Biotecnologia', 'label': 'Biotecnologia' },
    { 'school_id': 244, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 138, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 109, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 148, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 66, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 212, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 175, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 6830, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 6833, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 131, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 74, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 61, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 24, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 223, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 23, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 28, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 146, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 137, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 20, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 46, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 13, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 151, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 411, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 6859, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 91, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 108, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 228, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 80, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 478, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 90, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 69, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 128, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 81, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 115, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 124, 'value': 'Biotecnologie', 'label': 'Biotecnologie' },
    { 'school_id': 13, 'value': 'Biotecnologie Agro-Industriali', 'label': 'Biotecnologie Agro-Industriali' },
    { 'school_id': 72, 'value': 'Biotecnologie Industriali e Agro-Alimentari', 'label': 'Biotecnologie Industriali e Agro-Alimentari' },
    { 'school_id': 157, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 223, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 72, 'value': 'Biotecnologie Mediche e Farmaceutiche', 'label': 'Biotecnologie Mediche e Farmaceutiche' },
    { 'school_id': 35, 'value': 'Biotecnologie per la Salute', 'label': 'Biotecnologie per la Salute' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Biotecnologie Biomolecolari e Industriali', 'label': 'Corso di Laurea in Biotecnologie Biomolecolari e Industriali' },
    { 'school_id': 24, 'value': 'Genomics', 'label': 'Genomics' },
    { 'school_id': 6825, 'value': 'Ricerca Biotecnologica in Medicina', 'label': 'Ricerca Biotecnologica in Medicina' },
    { 'school_id': 55, 'value': 'Scienze e Tecnologie Biologiche', 'label': 'Scienze e Tecnologie Biologiche' },
    { 'school_id': 6874, 'value': 'Scienze e Tecnologie Biologiche', 'label': 'Scienze e Tecnologie Biologiche' },
    { 'school_id': 70, 'value': 'Scienze e Tecnologie Biomolecolari', 'label': 'Scienze e Tecnologie Biomolecolari' },
    { 'school_id': 96, 'value': 'Scienze e Tecnologie Biomolecolari', 'label': 'Scienze e Tecnologie Biomolecolari' },
    { 'school_id': 146, 'value': 'Biosicurezza e Qualità degli Alimenti', 'label': 'Biosicurezza e Qualità degli Alimenti' },
    { 'school_id': 157, 'value': 'Biotechnology For The Bioeconomy - Biotecnologia per la Bioeconomia', 'label': 'Biotechnology For The Bioeconomy - Biotecnologia per la Bioeconomia' },
    { 'school_id': 81, 'value': 'Biotecnologie Agrarie', 'label': 'Biotecnologie Agrarie' },
    { 'school_id': 20, 'value': 'Biotecnologie Agrarie e Ambientali', 'label': 'Biotecnologie Agrarie e Ambientali' },
    { 'school_id': 6830, 'value': 'Biotecnologie Agro-Alimentari', 'label': 'Biotecnologie Agro-Alimentari' },
    { 'school_id': 35, 'value': 'Biotecnologie Agro-Ambientali e Alimentari', 'label': 'Biotecnologie Agro-Ambientali e Alimentari' },
    { 'school_id': 131, 'value': 'Biotecnologie Molecolari', 'label': 'Biotecnologie Molecolari' },
    { 'school_id': 28, 'value': 'Biotecnologie per la Gestione Ambientale e l\'Agricoltura Sostenibile', 'label': 'Biotecnologie per la Gestione Ambientale e l\'Agricoltura Sostenibile' },
    { 'school_id': 72, 'value': 'Biotecnologie per la Qualità e la Sicurezza dell\'Alimentazione', 'label': 'Biotecnologie per la Qualità e la Sicurezza dell\'Alimentazione' },
    { 'school_id': 46, 'value': 'Biotecnologie per la Sicurezza e la Qualita\' Agro-Alimentare', 'label': 'Biotecnologie per la Sicurezza e la Qualita\' Agro-Alimentare' },
    { 'school_id': 146, 'value': 'Biotecnologie Vegetali e Microbiche', 'label': 'Biotecnologie Vegetali e Microbiche' },
    { 'school_id': 157, 'value': 'Bioinformatics For Computational Genomics', 'label': 'Bioinformatics For Computational Genomics' },
    { 'school_id': 70, 'value': 'Biologia Quantitativa e Computazionale', 'label': 'Biologia Quantitativa e Computazionale' },
    { 'school_id': 151, 'value': 'Biotechnology - Biotecnologie', 'label': 'Biotechnology - Biotecnologie' },
    { 'school_id': 175, 'value': 'Biotecnologie Avanzate', 'label': 'Biotecnologie Avanzate' },
    { 'school_id': 13, 'value': 'Biotecnologie Genomiche, Industriali ed Ambientali', 'label': 'Biotecnologie Genomiche, Industriali ed Ambientali' },
    { 'school_id': 74, 'value': 'Biotecnologie Genomiche, Molecolari e Industriali', 'label': 'Biotecnologie Genomiche, Molecolari e Industriali' },
    { 'school_id': 244, 'value': 'Biotecnologie Industriali', 'label': 'Biotecnologie Industriali' },
    { 'school_id': 66, 'value': 'Biotecnologie Industriali', 'label': 'Biotecnologie Industriali' },
    { 'school_id': 6833, 'value': 'Biotecnologie Industriali', 'label': 'Biotecnologie Industriali' },
    { 'school_id': 61, 'value': 'Biotecnologie Industriali', 'label': 'Biotecnologie Industriali' },
    { 'school_id': 72, 'value': 'Biotecnologie Industriali ed Ambientali', 'label': 'Biotecnologie Industriali ed Ambientali' },
    { 'school_id': 46, 'value': 'Biotecnologie Industriali per la Salute e il Benessere', 'label': 'Biotecnologie Industriali per la Salute e il Benessere' },
    { 'school_id': 28, 'value': 'Biotecnologie Molecolari', 'label': 'Biotecnologie Molecolari' },
    { 'school_id': 146, 'value': 'Biotecnologie Molecolari', 'label': 'Biotecnologie Molecolari' },
    { 'school_id': 148, 'value': 'Biotecnologie Molecolari e Industriali', 'label': 'Biotecnologie Molecolari e Industriali' },
    { 'school_id': 24, 'value': 'Biotecnologie Molecolari e Industriali', 'label': 'Biotecnologie Molecolari e Industriali' },
    { 'school_id': 20, 'value': 'Biotecnologie Molecolari e Industriali', 'label': 'Biotecnologie Molecolari e Industriali' },
    { 'school_id': 35, 'value': 'Biotecnologie Molecolari e Industriali', 'label': 'Biotecnologie Molecolari e Industriali' },
    { 'school_id': 223, 'value': 'Biotecnologie per l\'Ambiente e la Salute (Bas)', 'label': 'Biotecnologie per l\'Ambiente e la Salute (Bas)' },
    { 'school_id': 6831, 'value': 'Biotecnologie per l\'Ambiente e Lo Sviluppo Sostenibile', 'label': 'Biotecnologie per l\'Ambiente e Lo Sviluppo Sostenibile' },
    { 'school_id': 69, 'value': 'Biotecnologie per l\'Industria e per la Ricerca Scientifica', 'label': 'Biotecnologie per l\'Industria e per la Ricerca Scientifica' },
    { 'school_id': 6830, 'value': 'Biotecnologie per le Biorisorse e Lo Sviluppo Ecosostenibile', 'label': 'Biotecnologie per le Biorisorse e Lo Sviluppo Ecosostenibile' },
    { 'school_id': 157, 'value': 'Molecular Biotechnology And Bioinformatics - Biotecnologie Molecolari e Bioinformatica', 'label': 'Molecular Biotechnology And Bioinformatics - Biotecnologie Molecolari e Bioinformatica' },
    { 'school_id': 157, 'value': 'Quantitative Biology', 'label': 'Quantitative Biology' },
    { 'school_id': 137, 'value': 'Sustainable Industrial Pharmaceutical Biotechnology', 'label': 'Sustainable Industrial Pharmaceutical Biotechnology' },
    { 'school_id': 157, 'value': 'Biomedical Omics', 'label': 'Biomedical Omics' },
    { 'school_id': 137, 'value': 'Biotechnologies Of Human Reproduction', 'label': 'Biotechnologies Of Human Reproduction' },
    { 'school_id': 6825, 'value': 'Biotechnology And Medical Biology', 'label': 'Biotechnology And Medical Biology' },
    { 'school_id': 24, 'value': 'Biotecnologie Animali', 'label': 'Biotecnologie Animali' },
    { 'school_id': 228, 'value': 'Biotecnologie Avanzate', 'label': 'Biotecnologie Avanzate' },
    { 'school_id': 70, 'value': 'Biotecnologie Cellulari e Molecolari', 'label': 'Biotecnologie Cellulari e Molecolari' },
    { 'school_id': 157, 'value': 'Biotecnologie del Farmaco', 'label': 'Biotecnologie del Farmaco' },
    { 'school_id': 228, 'value': 'Biotecnologie della Riproduzione - Reproductive Biotechnologies', 'label': 'Biotecnologie della Riproduzione - Reproductive Biotechnologies' },
    { 'school_id': 138, 'value': 'Biotecnologie Farmaceutiche', 'label': 'Biotecnologie Farmaceutiche' },
    { 'school_id': 20, 'value': 'Biotecnologie Farmaceutiche', 'label': 'Biotecnologie Farmaceutiche' },
    { 'school_id': 13, 'value': 'Biotecnologie Farmaceutiche', 'label': 'Biotecnologie Farmaceutiche' },
    { 'school_id': 6859, 'value': 'Biotecnologie Genetiche e Molecolari', 'label': 'Biotecnologie Genetiche e Molecolari' },
    { 'school_id': 244, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 66, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 212, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 55, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 61, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 13, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 151, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 35, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 108, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 128, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 81, 'value': 'Biotecnologie Mediche', 'label': 'Biotecnologie Mediche' },
    { 'school_id': 175, 'value': 'Biotecnologie Mediche e Farmaceutiche', 'label': 'Biotecnologie Mediche e Farmaceutiche' },
    { 'school_id': 28, 'value': 'Biotecnologie Mediche e Farmaceutiche', 'label': 'Biotecnologie Mediche e Farmaceutiche' },
    { 'school_id': 72, 'value': 'Biotecnologie Mediche e Medicina Molecolare', 'label': 'Biotecnologie Mediche e Medicina Molecolare' },
    { 'school_id': 69, 'value': 'Biotecnologie Mediche e Medicina Molecolare', 'label': 'Biotecnologie Mediche e Medicina Molecolare' },
    { 'school_id': 80, 'value': 'Biotecnologie Mediche e Nanobiotecnologie', 'label': 'Biotecnologie Mediche e Nanobiotecnologie' },
    { 'school_id': 74, 'value': 'Biotecnologie Mediche, Veterinarie e Farmaceutiche', 'label': 'Biotecnologie Mediche, Veterinarie e Farmaceutiche' },
    { 'school_id': 90, 'value': 'Biotecnologie Mediche, Veterinarie e Farmaceutiche', 'label': 'Biotecnologie Mediche, Veterinarie e Farmaceutiche' },
    { 'school_id': 108, 'value': 'Biotecnologie Molecolari e Cellulari', 'label': 'Biotecnologie Molecolari e Cellulari' },
    { 'school_id': 6833, 'value': 'Biotecnologie per l\'Alimentazione', 'label': 'Biotecnologie per l\'Alimentazione' },
    { 'school_id': 478, 'value': 'Biotecnologie per la Diagnostica Medica, Farmaceutica e Veterinaria', 'label': 'Biotecnologie per la Diagnostica Medica, Farmaceutica e Veterinaria' },
    { 'school_id': 14336, 'value': 'Biotecnologie per la Medicina Personalizzata', 'label': 'Biotecnologie per la Medicina Personalizzata' },
    { 'school_id': 6874, 'value': 'Biotecnologie per la Salute', 'label': 'Biotecnologie per la Salute' },
    { 'school_id': 115, 'value': 'Biotecnologie Sanitarie Mediche e Veterinarie', 'label': 'Biotecnologie Sanitarie Mediche e Veterinarie' },
    { 'school_id': 13, 'value': 'Comunicazione Scientifica Biomedica', 'label': 'Comunicazione Scientifica Biomedica' },
    { 'school_id': 35, 'value': 'Corso di Laurea Magistrale in Biotecnologie del Farmaco', 'label': 'Corso di Laurea Magistrale in Biotecnologie del Farmaco' },
    { 'school_id': 137, 'value': 'Genetic Counsellors', 'label': 'Genetic Counsellors' },
    { 'school_id': 6833, 'value': 'Medical Biotechnologies', 'label': 'Medical Biotechnologies' },
    { 'school_id': 137, 'value': 'Medical Biotechnologies - Biotecnologie Mediche', 'label': 'Medical Biotechnologies - Biotecnologie Mediche' },
    { 'school_id': 138, 'value': 'Medical Biotechnology', 'label': 'Medical Biotechnology' },
    { 'school_id': 24, 'value': 'Medical Biotechnology', 'label': 'Medical Biotechnology' },
    { 'school_id': 157, 'value': 'Medical Biotechnology And Molecular Medicine - Biotecnologie Mediche e Medicina Molecolare', 'label': 'Medical Biotechnology And Molecular Medicine - Biotecnologie Mediche e Medicina Molecolare' },
    { 'school_id': 109, 'value': 'Medical-Pharmaceutical Biotechnology', 'label': 'Medical-Pharmaceutical Biotechnology' },
    { 'school_id': 6830, 'value': 'Molecular And Medical Biotechnology', 'label': 'Molecular And Medical Biotechnology' },
    { 'school_id': 91, 'value': 'Molecular Biotechnology', 'label': 'Molecular Biotechnology' },
    { 'school_id': 244, 'value': 'Molecular Biotechnology - Biotecnologie Molecolari', 'label': 'Molecular Biotechnology - Biotecnologie Molecolari' },
    { 'school_id': 6833, 'value': 'Pharmaceutical Biotechnologies - Biotecnologie Farmaceutiche', 'label': 'Pharmaceutical Biotechnologies - Biotecnologie Farmaceutiche' },
    { 'school_id': 24, 'value': 'Pharmaceutical Biotechnology', 'label': 'Pharmaceutical Biotechnology' },
    { 'school_id': 157, 'value': 'Safety Assessment Of Xenobiotics And Biotechnological Products - Valutazione della Sicurezza di Xenobiotici e Prodotti Biotecnologici', 'label': 'Safety Assessment Of Xenobiotics And Biotechnological Products - Valutazione della Sicurezza di Xenobiotici e Prodotti Biotecnologici' },
    { 'school_id': 20, 'value': 'Scienze Biotecnologiche Mediche, Veterinarie e Forensi', 'label': 'Scienze Biotecnologiche Mediche, Veterinarie e Forensi' },
    { 'school_id': 157, 'value': 'Scienze Biotecnologiche Veterinarie', 'label': 'Scienze Biotecnologiche Veterinarie' },
    { 'school_id': 96, 'value': 'Scienze Biotecnologiche, degli Alimenti e della Nutrizione Umana', 'label': 'Scienze Biotecnologiche, degli Alimenti e della Nutrizione Umana' },
    { 'school_id': 244, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 175, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 24, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 23, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 46, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 151, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 110, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 72, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 6874, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 69, 'value': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)', 'label': 'Conservazione e Restauro dei Beni Culturali (Abilitante Ai Sensi del D.Lgs N.42/2004)' },
    { 'school_id': 24, 'value': 'Advanced Design', 'label': 'Advanced Design' },
    { 'school_id': 28, 'value': 'Design', 'label': 'Design' },
    { 'school_id': 95, 'value': 'Design & Engineering - Progetto e Ingegnerizzazione del Prodotto Industriale', 'label': 'Design & Engineering - Progetto e Ingegnerizzazione del Prodotto Industriale' },
    { 'school_id': 109, 'value': 'Design del Prodotto e dell\'Evento', 'label': 'Design del Prodotto e dell\'Evento' },
    { 'school_id': 6832, 'value': 'Design del Prodotto e della Comunicazione Visiva', 'label': 'Design del Prodotto e della Comunicazione Visiva' },
    { 'school_id': 95, 'value': 'Design della Comunicazione', 'label': 'Design della Comunicazione' },
    { 'school_id': 69, 'value': 'Design e Cultura del Territorio', 'label': 'Design e Cultura del Territorio' },
    { 'school_id': 95, 'value': 'Design For The Fashion System - Design per il Sistema Moda', 'label': 'Design For The Fashion System - Design per il Sistema Moda' },
    { 'school_id': 109, 'value': 'Design Navale e Nautico', 'label': 'Design Navale e Nautico' },
    { 'school_id': 35, 'value': 'Design per l\'Ambiente Costruito', 'label': 'Design per l\'Ambiente Costruito' },
    { 'school_id': 91, 'value': 'Design per l\'Innovazione', 'label': 'Design per l\'Innovazione' },
    { 'school_id': 60, 'value': 'Design per l\'Innovazione Digitale', 'label': 'Design per l\'Innovazione Digitale' },
    { 'school_id': 28, 'value': 'Design Sistema Moda', 'label': 'Design Sistema Moda' },
    { 'school_id': 18, 'value': 'Design Sistemico', 'label': 'Design Sistemico' },
    { 'school_id': 13, 'value': 'Design, Comunicazione Visiva e Multimediale', 'label': 'Design, Comunicazione Visiva e Multimediale' },
    { 'school_id': 95, 'value': 'Digital And Interaction Design', 'label': 'Digital And Interaction Design' },
    { 'school_id': 6871, 'value': 'Disegno Industriale', 'label': 'Disegno Industriale' },
    { 'school_id': 273, 'value': 'Eco-Social Design', 'label': 'Eco-Social Design' },
    { 'school_id': 223, 'value': 'Innovation Design', 'label': 'Innovation Design' },
    { 'school_id': 95, 'value': 'Integrated Product Design', 'label': 'Integrated Product Design' },
    { 'school_id': 95, 'value': 'Interior And Spatial Design', 'label': 'Interior And Spatial Design' },
    { 'school_id': 20, 'value': 'Planet Life Design', 'label': 'Planet Life Design' },
    { 'school_id': 13, 'value': 'Product And Service Design', 'label': 'Product And Service Design' },
    { 'school_id': 95, 'value': 'Product Service System Design - Design del Sistema Prodotto Servizio', 'label': 'Product Service System Design - Design del Sistema Prodotto Servizio' },
    { 'school_id': 28, 'value': 'Diagnostica e Materiali per la Conservazione e il Restauro', 'label': 'Diagnostica e Materiali per la Conservazione e il Restauro' },
    { 'school_id': 6831, 'value': 'Scienze e Tecnologie per i Beni Culturali', 'label': 'Scienze e Tecnologie per i Beni Culturali' },
    { 'school_id': 157, 'value': 'Scienze e Tecnologie per Lo Studio e la Conservazione dei Beni Culturali e dei Supporti della Informazione', 'label': 'Scienze e Tecnologie per Lo Studio e la Conservazione dei Beni Culturali e dei Supporti della Informazione' },
    { 'school_id': 60, 'value': 'Tecnologie Innovative per i Beni Culturali', 'label': 'Tecnologie Innovative per i Beni Culturali' },
    { 'school_id': 13, 'value': 'Tecnologie per la Conservazione e il Restauro dei Beni Culturali', 'label': 'Tecnologie per la Conservazione e il Restauro dei Beni Culturali' },
    { 'school_id': 13, 'value': 'Arti e Scienze dello Spettacolo', 'label': 'Arti e Scienze dello Spettacolo' },
    { 'school_id': 14, 'value': 'Arti, Spettacolo, Eventi Culturali', 'label': 'Arti, Spettacolo, Eventi Culturali' },
    { 'school_id': 6874, 'value': 'Comunicazione e Dams', 'label': 'Comunicazione e Dams' },
    { 'school_id': 24, 'value': 'Culture e Pratiche della Moda', 'label': 'Culture e Pratiche della Moda' },
    { 'school_id': 131, 'value': 'Dams - Discipline dell\'Audiovisivo, dei Media e dello Spettacolo', 'label': 'Dams - Discipline dell\'Audiovisivo, dei Media e dello Spettacolo' },
    { 'school_id': 24, 'value': 'Dams - Discipline delle Arti, della Musica e dello Spettacolo', 'label': 'Dams - Discipline delle Arti, della Musica e dello Spettacolo' },
    { 'school_id': 36, 'value': 'Dams - Produzione Audiovisiva e Teatrale', 'label': 'Dams - Produzione Audiovisiva e Teatrale' },
    { 'school_id': 244, 'value': 'Dams (Discipline delle Arti, della Musica e dello Spettacolo)', 'label': 'Dams (Discipline delle Arti, della Musica e dello Spettacolo)' },
    { 'school_id': 77, 'value': 'Dams (Discipline delle Arti, della Musica e dello Spettacolo)', 'label': 'Dams (Discipline delle Arti, della Musica e dello Spettacolo)' },
    { 'school_id': 63, 'value': 'Design e Discipline della Moda', 'label': 'Design e Discipline della Moda' },
    { 'school_id': 38, 'value': 'Discipline delle Arti Visive, della Musica e dello Spettacolo', 'label': 'Discipline delle Arti Visive, della Musica e dello Spettacolo' },
    { 'school_id': 14336, 'value': 'Discipline delle Arti, dei Media e dello Spettacolo', 'label': 'Discipline delle Arti, dei Media e dello Spettacolo' },
    { 'school_id': 6833, 'value': 'Discipline delle Arti, della Musica e dello Spettacolo', 'label': 'Discipline delle Arti, della Musica e dello Spettacolo' },
    { 'school_id': 28, 'value': 'Discipline delle Arti, della Musica e dello Spettacolo', 'label': 'Discipline delle Arti, della Musica e dello Spettacolo' },
    { 'school_id': 228, 'value': 'Discipline delle Arti, della Musica e dello Spettacolo', 'label': 'Discipline delle Arti, della Musica e dello Spettacolo' },
    { 'school_id': 69, 'value': 'Discipline delle Arti, della Musica e dello Spettacolo', 'label': 'Discipline delle Arti, della Musica e dello Spettacolo' },
    { 'school_id': 128, 'value': 'Discipline delle Arti, della Musica e dello Spettacolo', 'label': 'Discipline delle Arti, della Musica e dello Spettacolo' },
    { 'school_id': 80, 'value': 'Discipline delle Arti, della Musica e dello Spettacolo (Dams)', 'label': 'Discipline delle Arti, della Musica e dello Spettacolo (Dams)' },
    { 'school_id': 146, 'value': 'Discipline dello Spettacolo e della Comunicazione', 'label': 'Discipline dello Spettacolo e della Comunicazione' },
    { 'school_id': 14, 'value': 'Moda e Industrie Creative', 'label': 'Moda e Industrie Creative' },
    { 'school_id': 28, 'value': 'Progettazione e Gestione di Eventi e Imprese dell\'Arte e dello Spettacolo', 'label': 'Progettazione e Gestione di Eventi e Imprese dell\'Arte e dello Spettacolo' },
    { 'school_id': 13, 'value': 'Scienze della Moda e del Costume', 'label': 'Scienze della Moda e del Costume' },
    { 'school_id': 447, 'value': 'Scienze e Tecnologie delle Arti, dello Spettacolo e del Cinema', 'label': 'Scienze e Tecnologie delle Arti, dello Spettacolo e del Cinema' },
    { 'school_id': 20, 'value': 'Design', 'label': 'Design' },
    { 'school_id': 13, 'value': 'Design', 'label': 'Design' },
    { 'school_id': 114, 'value': 'Design', 'label': 'Design' },
    { 'school_id': 414, 'value': 'Design', 'label': 'Design' },
    { 'school_id': 95, 'value': 'Design degli Interni', 'label': 'Design degli Interni' },
    { 'school_id': 447, 'value': 'Design del Prodotto e della Moda', 'label': 'Design del Prodotto e della Moda' },
    { 'school_id': 109, 'value': 'Design del Prodotto e della Nautica', 'label': 'Design del Prodotto e della Nautica' },
    { 'school_id': 95, 'value': 'Design del Prodotto Industriale', 'label': 'Design del Prodotto Industriale' },
    { 'school_id': 24, 'value': 'Design del Prodotto Industriale', 'label': 'Design del Prodotto Industriale' },
    { 'school_id': 223, 'value': 'Design del Prodotto Industriale', 'label': 'Design del Prodotto Industriale' },
    { 'school_id': 95, 'value': 'Design della Moda', 'label': 'Design della Moda' },
    { 'school_id': 6832, 'value': 'Design della Moda e Arti Multimediali', 'label': 'Design della Moda e Arti Multimediali' },
    { 'school_id': 273, 'value': 'Design e Arti', 'label': 'Design e Arti' },
    { 'school_id': 18, 'value': 'Design e Comunicazione', 'label': 'Design e Comunicazione' },
    { 'school_id': 91, 'value': 'Design e Comunicazione', 'label': 'Design e Comunicazione' },
    { 'school_id': 35, 'value': 'Design per la Comunita\'', 'label': 'Design per la Comunita\'' },
    { 'school_id': 91, 'value': 'Design per la Moda', 'label': 'Design per la Moda' },
    { 'school_id': 28, 'value': 'Disegno Industriale', 'label': 'Disegno Industriale' },
    { 'school_id': 69, 'value': 'Disegno Industriale', 'label': 'Disegno Industriale' },
    { 'school_id': 60, 'value': 'Disegno Industriale e Ambientale', 'label': 'Disegno Industriale e Ambientale' },
    { 'school_id': 6832, 'value': 'Disegno Industriale e Multimedia', 'label': 'Disegno Industriale e Multimedia' },
    { 'school_id': 146, 'value': 'Ingegneria per il Design Industriale', 'label': 'Ingegneria per il Design Industriale' },
    { 'school_id': 411, 'value': 'Moda e Design Industriale', 'label': 'Moda e Design Industriale' },
    { 'school_id': 244, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 138, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 109, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 157, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 175, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 6833, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 55, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 74, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 61, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 24, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 223, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 23, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 60, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 28, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 146, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 137, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 20, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 13, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 38, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 114, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 72, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 6874, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 69, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 128, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 81, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 115, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 124, 'value': 'Chimica e Tecnologia Farmaceutiche', 'label': 'Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 35, 'value': 'Corso di Laurea Magistrale in Chimica e Tecnologia Farmaceutiche', 'label': 'Corso di Laurea Magistrale in Chimica e Tecnologia Farmaceutiche' },
    { 'school_id': 244, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 138, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 109, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 157, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 14336, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 212, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 175, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 6833, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 55, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 74, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 61, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 24, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 223, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 23, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 60, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 28, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 146, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 137, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 20, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 13, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 35, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 91, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 38, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 114, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 72, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 478, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 6874, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 90, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 69, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 128, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 81, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 115, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 124, 'value': 'Farmacia', 'label': 'Farmacia' },
    { 'school_id': 24, 'value': 'Pharmacy', 'label': 'Pharmacy' },
    { 'school_id': 151, 'value': 'Pharmacy', 'label': 'Pharmacy' },
    { 'school_id': 128, 'value': 'Civiltà Letteraria dell\'Italia Medievale e Moderna', 'label': 'Civiltà Letteraria dell\'Italia Medievale e Moderna' },
    { 'school_id': 25, 'value': 'Competenze Testuali per l\'Editoria, l\'Insegnamento e la Promozione Turistica', 'label': 'Competenze Testuali per l\'Editoria, l\'Insegnamento e la Promozione Turistica' },
    { 'school_id': 223, 'value': 'Culture e Tradizioni del Medio Evo e del Rinascimento', 'label': 'Culture e Tradizioni del Medio Evo e del Rinascimento' },
    { 'school_id': 244, 'value': 'Culture Moderne Comparate', 'label': 'Culture Moderne Comparate' },
    { 'school_id': 79, 'value': 'Culture Moderne Comparate', 'label': 'Culture Moderne Comparate' },
    { 'school_id': 234, 'value': 'Filologia Classica e Moderna', 'label': 'Filologia Classica e Moderna' },
    { 'school_id': 91, 'value': 'Filologia Classica e Moderna', 'label': 'Filologia Classica e Moderna' },
    { 'school_id': 478, 'value': 'Filologia Classica e Moderna', 'label': 'Filologia Classica e Moderna' },
    { 'school_id': 70, 'value': 'Filologia e Critica Letteraria', 'label': 'Filologia e Critica Letteraria' },
    { 'school_id': 6831, 'value': 'Filologia e Letteratura Italiana', 'label': 'Filologia e Letteratura Italiana' },
    { 'school_id': 6851, 'value': 'Filologia e Letterature Moderne', 'label': 'Filologia e Letterature Moderne' },
    { 'school_id': 14336, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 175, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 6833, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 28, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 46, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 13, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 35, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 38, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 72, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 6874, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 81, 'value': 'Filologia Moderna', 'label': 'Filologia Moderna' },
    { 'school_id': 6833, 'value': 'Filologia Moderna - Francesistica e Italianistica', 'label': 'Filologia Moderna - Francesistica e Italianistica' },
    { 'school_id': 138, 'value': 'Filologia Moderna, Classica e Comparata', 'label': 'Filologia Moderna, Classica e Comparata' },
    { 'school_id': 96, 'value': 'Filologia, Letterature e Storia', 'label': 'Filologia, Letterature e Storia' },
    { 'school_id': 114, 'value': 'Filologia, Linguistica e Tradizioni Letterarie', 'label': 'Filologia, Linguistica e Tradizioni Letterarie' },
    { 'school_id': 124, 'value': 'Filologie e Letterature Classiche e Moderne', 'label': 'Filologie e Letterature Classiche e Moderne' },
    { 'school_id': 131, 'value': 'Italianistica', 'label': 'Italianistica' },
    { 'school_id': 146, 'value': 'Italianistica', 'label': 'Italianistica' },
    { 'school_id': 77, 'value': 'Italianistica', 'label': 'Italianistica' },
    { 'school_id': 69, 'value': 'Italianistica', 'label': 'Italianistica' },
    { 'school_id': 20, 'value': 'Italianistica e Storia Europea', 'label': 'Italianistica e Storia Europea' },
    { 'school_id': 24, 'value': 'Italianistica, Culture Letterarie Europee, Scienze Linguistiche', 'label': 'Italianistica, Culture Letterarie Europee, Scienze Linguistiche' },
    { 'school_id': 541, 'value': 'Italiano per l\'Insegnamento a Stranieri', 'label': 'Italiano per l\'Insegnamento a Stranieri' },
    { 'school_id': 198, 'value': 'Letteratura e Storia dell\'Arte', 'label': 'Letteratura e Storia dell\'Arte' },
    { 'school_id': 151, 'value': 'Letteratura Italiana, Filologia Moderna e Linguistica', 'label': 'Letteratura Italiana, Filologia Moderna e Linguistica' },
    { 'school_id': 244, 'value': 'Letteratura, Filologia e Linguistica Italiana', 'label': 'Letteratura, Filologia e Linguistica Italiana' },
    { 'school_id': 63, 'value': 'Letteratura, Lingua e Cultura Italiana', 'label': 'Letteratura, Lingua e Cultura Italiana' },
    { 'school_id': 109, 'value': 'Letterature Moderne e Spettacolo', 'label': 'Letterature Moderne e Spettacolo' },
    { 'school_id': 322, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 74, 'value': 'Lettere Classiche e Moderne', 'label': 'Lettere Classiche e Moderne' },
    { 'school_id': 23, 'value': 'Lettere Classiche e Moderne', 'label': 'Lettere Classiche e Moderne' },
    { 'school_id': 157, 'value': 'Lettere Moderne', 'label': 'Lettere Moderne' },
    { 'school_id': 137, 'value': 'Lettere Moderne', 'label': 'Lettere Moderne' },
    { 'school_id': 80, 'value': 'Lettere Moderne', 'label': 'Lettere Moderne' },
    { 'school_id': 115, 'value': 'Lettere, Filologia Moderna e Industria Culturale', 'label': 'Lettere, Filologia Moderna e Industria Culturale' },
    { 'school_id': 12, 'value': 'Lingua e Cultura Italiana per Stranieri', 'label': 'Lingua e Cultura Italiana per Stranieri' },
    { 'school_id': 38, 'value': 'Linguistica e Didattica dell\'Italiano Nel Contesto Internazionale', 'label': 'Linguistica e Didattica dell\'Italiano Nel Contesto Internazionale' },
    { 'school_id': 169, 'value': 'Scienze Umanistiche', 'label': 'Scienze Umanistiche' },
    { 'school_id': 108, 'value': 'Studi Letterari e Culturali', 'label': 'Studi Letterari e Culturali' },
    { 'school_id': 6830, 'value': 'Tradizione e Interpretazione dei Testi Letterari', 'label': 'Tradizione e Interpretazione dei Testi Letterari' },
    { 'school_id': 175, 'value': 'Antichità Classiche e Orientali', 'label': 'Antichità Classiche e Orientali' },
    { 'school_id': 20, 'value': 'Civiltà Classiche', 'label': 'Civiltà Classiche' },
    { 'school_id': 81, 'value': 'Filologia Classica', 'label': 'Filologia Classica' },
    { 'school_id': 146, 'value': 'Filologia e Storia dell\'Antichita\'', 'label': 'Filologia e Storia dell\'Antichita\'' },
    { 'school_id': 28, 'value': 'Filologia, Letteratura e Storia dell\'Antichità', 'label': 'Filologia, Letteratura e Storia dell\'Antichità' },
    { 'school_id': 24, 'value': 'Filologia, Letteratura e Tradizione Classica', 'label': 'Filologia, Letteratura e Tradizione Classica' },
    { 'school_id': 35, 'value': 'Filologia, Letterature e Civilta\' del Mondo Antico', 'label': 'Filologia, Letterature e Civilta\' del Mondo Antico' },
    { 'school_id': 13, 'value': 'Filologia, Letterature e Storia del Mondo Antico', 'label': 'Filologia, Letterature e Storia del Mondo Antico' },
    { 'school_id': 244, 'value': 'Filologia, Letterature e Storia dell\'Antichità', 'label': 'Filologia, Letterature e Storia dell\'Antichità' },
    { 'school_id': 157, 'value': 'Filologia, Letterature e Storia dell\'Antichità', 'label': 'Filologia, Letterature e Storia dell\'Antichità' },
    { 'school_id': 77, 'value': 'Filologia, Letterature e Storia dell\'Antichità', 'label': 'Filologia, Letterature e Storia dell\'Antichità' },
    { 'school_id': 38, 'value': 'Filologia, Letterature e Storia dell\'Antichità', 'label': 'Filologia, Letterature e Storia dell\'Antichità' },
    { 'school_id': 72, 'value': 'Filologia, Letterature e Storia dell\'Antichita\'', 'label': 'Filologia, Letterature e Storia dell\'Antichita\'' },
    { 'school_id': 137, 'value': 'Lettere Classiche', 'label': 'Lettere Classiche' },
    { 'school_id': 80, 'value': 'Lettere Classiche', 'label': 'Lettere Classiche' },
    { 'school_id': 6833, 'value': 'Lettere Classiche e Storia Antica', 'label': 'Lettere Classiche e Storia Antica' },
    { 'school_id': 14336, 'value': 'Scienze dell\'Antichità', 'label': 'Scienze dell\'Antichità' },
    { 'school_id': 6874, 'value': 'Scienze dell\'Antichità', 'label': 'Scienze dell\'Antichità' },
    { 'school_id': 69, 'value': 'Scienze dell\'Antichità', 'label': 'Scienze dell\'Antichità' },
    { 'school_id': 55, 'value': 'Discipline Storiche e Filosofiche', 'label': 'Discipline Storiche e Filosofiche' },
    { 'school_id': 244, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 109, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 157, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 14336, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 6825, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 79, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 175, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 70, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 6830, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 6831, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 6833, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 24, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 234, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 28, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 146, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 13, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 151, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 77, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 35, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 38, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 72, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 80, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 128, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 81, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 124, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 138, 'value': 'Filosofia e Comunicazione', 'label': 'Filosofia e Comunicazione' },
    { 'school_id': 114, 'value': 'Filosofia e Scienze dell\'Educazione', 'label': 'Filosofia e Scienze dell\'Educazione' },
    { 'school_id': 20, 'value': 'Filosofia e Scienze e Tecniche Psicologiche', 'label': 'Filosofia e Scienze e Tecniche Psicologiche' },
    { 'school_id': 6874, 'value': 'Filosofia e Storia', 'label': 'Filosofia e Storia' },
    { 'school_id': 108, 'value': 'Filosofia e Teoria dei Processi Comunicativi', 'label': 'Filosofia e Teoria dei Processi Comunicativi' },
    { 'school_id': 6831, 'value': 'Philosophy, International And Economic Studies', 'label': 'Philosophy, International And Economic Studies' },
    { 'school_id': 223, 'value': 'Scienze Filosofiche e dell\'Educazione', 'label': 'Scienze Filosofiche e dell\'Educazione' },
    { 'school_id': 74, 'value': 'Studi Filosofici', 'label': 'Studi Filosofici' },
    { 'school_id': 69, 'value': 'Studi Filosofici e Storici', 'label': 'Studi Filosofici e Storici' },
    { 'school_id': 61, 'value': 'Analisi, Consulenza e Gestione Finanziaria', 'label': 'Analisi, Consulenza e Gestione Finanziaria' },
    { 'school_id': 6830, 'value': 'Banca e Finanza', 'label': 'Banca e Finanza' },
    { 'school_id': 66, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 80, 'value': 'Economia Finanza e Assicurazioni', 'label': 'Economia Finanza e Assicurazioni' },
    { 'school_id': 137, 'value': 'Finance - Finanza', 'label': 'Finance - Finanza' },
    { 'school_id': 151, 'value': 'Finance And Banking - Finanza e Banca', 'label': 'Finance And Banking - Finanza e Banca' },
    { 'school_id': 157, 'value': 'Finance And Economics - Finanza e Economia', 'label': 'Finance And Economics - Finanza e Economia' },
    { 'school_id': 6874, 'value': 'Finance And Insurance', 'label': 'Finance And Insurance' },
    { 'school_id': 28, 'value': 'Finance And Risk Management - Finanza e Gestione del Rischio', 'label': 'Finance And Risk Management - Finanza e Gestione del Rischio' },
    { 'school_id': 70, 'value': 'Finanza', 'label': 'Finanza' },
    { 'school_id': 35, 'value': 'Finanza', 'label': 'Finanza' },
    { 'school_id': 14332, 'value': 'Finanza - Finance', 'label': 'Finanza - Finance' },
    { 'school_id': 13, 'value': 'Finanza e Assicurazioni', 'label': 'Finanza e Assicurazioni' },
    { 'school_id': 77, 'value': 'Finanza e Impresa', 'label': 'Finanza e Impresa' },
    { 'school_id': 20, 'value': 'Finanza e Metodi Quantitativi per l\'Economia', 'label': 'Finanza e Metodi Quantitativi per l\'Economia' },
    { 'school_id': 24, 'value': 'Finanza, Intermediari e Mercati', 'label': 'Finanza, Intermediari e Mercati' },
    { 'school_id': 24, 'value': 'Quantitative Finance', 'label': 'Quantitative Finance' },
    { 'school_id': 6837, 'value': 'Scienze Economiche e Finanziarie', 'label': 'Scienze Economiche e Finanziarie' },
    { 'school_id': 6833, 'value': 'Astrophysics And Cosmology', 'label': 'Astrophysics And Cosmology' },
    { 'school_id': 108, 'value': 'Atmospheric Science And Technology', 'label': 'Atmospheric Science And Technology' },
    { 'school_id': 244, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 109, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 148, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 157, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 14336, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 66, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 55, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 74, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 146, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 20, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 13, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 151, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 77, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 35, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 38, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 108, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 80, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 6874, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 69, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 124, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 60, 'value': 'Fisica - Physics', 'label': 'Fisica - Physics' },
    { 'school_id': 244, 'value': 'Fisica dei Sistemi Complessi', 'label': 'Fisica dei Sistemi Complessi' },
    { 'school_id': 24, 'value': 'Fisica del Sistema Terra', 'label': 'Fisica del Sistema Terra' },
    { 'school_id': 70, 'value': 'Physics', 'label': 'Physics' },
    { 'school_id': 6833, 'value': 'Physics', 'label': 'Physics' },
    { 'school_id': 24, 'value': 'Physics', 'label': 'Physics' },
    { 'school_id': 223, 'value': 'Physics', 'label': 'Physics' },
    { 'school_id': 91, 'value': 'Physics', 'label': 'Physics' },
    { 'school_id': 72, 'value': 'Physics', 'label': 'Physics' },
    { 'school_id': 128, 'value': 'Physics', 'label': 'Physics' },
    { 'school_id': 81, 'value': 'Physics', 'label': 'Physics' },
    { 'school_id': 61, 'value': 'Physics - Fisica', 'label': 'Physics - Fisica' },
    { 'school_id': 6833, 'value': 'Physics Of Data', 'label': 'Physics Of Data' },
    { 'school_id': 175, 'value': 'Scienze Fisiche', 'label': 'Scienze Fisiche' },
    { 'school_id': 28, 'value': 'Scienze Fisiche e Astrofisiche', 'label': 'Scienze Fisiche e Astrofisiche' },
    { 'school_id': 115, 'value': 'Progettazione, Gestione e Promozione Turistica di Itinerari della Cultura e dell\'Ambiente', 'label': 'Progettazione, Gestione e Promozione Turistica di Itinerari della Cultura e dell\'Ambiente' },
    { 'school_id': 13, 'value': 'Scienze Geografiche per l\'Ambiente e la Salute', 'label': 'Scienze Geografiche per l\'Ambiente e la Salute' },
    { 'school_id': 157, 'value': 'Scienze Umane dell\'Ambiente, del Territorio e del Paesaggio', 'label': 'Scienze Umane dell\'Ambiente, del Territorio e del Paesaggio' },
    { 'school_id': 24, 'value': 'Artificial Intelligence', 'label': 'Artificial Intelligence' },
    { 'school_id': 131, 'value': 'Artificial Intelligence & Cybersecurity', 'label': 'Artificial Intelligence & Cybersecurity' },
    { 'school_id': 273, 'value': 'Computational Data Science', 'label': 'Computational Data Science' },
    { 'school_id': 109, 'value': 'Computer Science', 'label': 'Computer Science' },
    { 'school_id': 72, 'value': 'Computer Science', 'label': 'Computer Science' },
    { 'school_id': 6874, 'value': 'Computer Science', 'label': 'Computer Science' },
    { 'school_id': 13, 'value': 'Computer Science - Informatica', 'label': 'Computer Science - Informatica' },
    { 'school_id': 131, 'value': 'Comunicazione Multimediale e Tecnologie dell\'Informazione', 'label': 'Comunicazione Multimediale e Tecnologie dell\'Informazione' },
    { 'school_id': 146, 'value': 'Data Science And Business Informatics', 'label': 'Data Science And Business Informatics' },
    { 'school_id': 128, 'value': 'Engineering And Computer Science', 'label': 'Engineering And Computer Science' },
    { 'school_id': 244, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 138, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 148, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 157, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 66, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 70, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 6833, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 131, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 61, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 24, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 28, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 146, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 20, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 151, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 35, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 38, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 108, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 69, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 81, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 124, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 6831, 'value': 'Informatica - Computer Science', 'label': 'Informatica - Computer Science' },
    { 'school_id': 60, 'value': 'Informatica - Computer Science', 'label': 'Informatica - Computer Science' },
    { 'school_id': 23, 'value': 'Informatica Applicata', 'label': 'Informatica Applicata' },
    { 'school_id': 82, 'value': 'Informatica Applicata (Machine Learning e Big Data)', 'label': 'Informatica Applicata (Machine Learning e Big Data)' },
    { 'school_id': 146, 'value': 'Informatica e Networking', 'label': 'Informatica e Networking' },
    { 'school_id': 273, 'value': 'Ingegneria del Software per i Sistemi Informativi', 'label': 'Ingegneria del Software per i Sistemi Informativi' },
    { 'school_id': 6830, 'value': 'Ingegneria e Scienze Informatiche', 'label': 'Ingegneria e Scienze Informatiche' },
    { 'school_id': 24, 'value': 'Ingegneria e Scienze Informatiche', 'label': 'Ingegneria e Scienze Informatiche' },
    { 'school_id': 6830, 'value': 'Medical Bioinformatics', 'label': 'Medical Bioinformatics' },
    { 'school_id': 74, 'value': 'Scienze Informatiche', 'label': 'Scienze Informatiche' },
    { 'school_id': 169, 'value': 'Comunicazione Digitale', 'label': 'Comunicazione Digitale' },
    { 'school_id': 234, 'value': 'Comunicazione e Culture Digitali', 'label': 'Comunicazione e Culture Digitali' },
    { 'school_id': 24, 'value': 'Comunicazione Giornalistica, Pubblica e d\'Impresa', 'label': 'Comunicazione Giornalistica, Pubblica e d\'Impresa' },
    { 'school_id': 14336, 'value': 'Comunicazione per l\'Impresa, i Media e le Organizzazioni Complesse', 'label': 'Comunicazione per l\'Impresa, i Media e le Organizzazioni Complesse' },
    { 'school_id': 79, 'value': 'Comunicazione, Informazione, Editoria', 'label': 'Comunicazione, Informazione, Editoria' },
    { 'school_id': 6830, 'value': 'Editoria e Giornalismo', 'label': 'Editoria e Giornalismo' },
    { 'school_id': 13, 'value': 'Editoria e Scrittura', 'label': 'Editoria e Scrittura' },
    { 'school_id': 14336, 'value': 'Gestione di Contenuti Digitali per i Media, le Imprese e i Patrimoni Culturali', 'label': 'Gestione di Contenuti Digitali per i Media, le Imprese e i Patrimoni Culturali' },
    { 'school_id': 74, 'value': 'Giornalismo, Cultura Editoriale e Comunicazione Multimediale', 'label': 'Giornalismo, Cultura Editoriale e Comunicazione Multimediale' },
    { 'school_id': 109, 'value': 'Informazione ed Editoria', 'label': 'Informazione ed Editoria' },
    { 'school_id': 24, 'value': 'Informazione, Culture e Organizzazione dei Media', 'label': 'Informazione, Culture e Organizzazione dei Media' },
    { 'school_id': 77, 'value': 'Informazione, Editoria, Giornalismo', 'label': 'Informazione, Editoria, Giornalismo' },
    { 'school_id': 13, 'value': 'Media, Comunicazione Digitale e Giornalismo', 'label': 'Media, Comunicazione Digitale e Giornalismo' },
    { 'school_id': 128, 'value': 'Metodi e Linguaggi del Giornalismo', 'label': 'Metodi e Linguaggi del Giornalismo' },
    { 'school_id': 151, 'value': 'Scienze dell\'Informazione, della Comunicazione e dell\'Editoria', 'label': 'Scienze dell\'Informazione, della Comunicazione e dell\'Editoria' },
    { 'school_id': 95, 'value': 'Aeronautical Engineering - Ingegneria Aeronautica', 'label': 'Aeronautical Engineering - Ingegneria Aeronautica' },
    { 'school_id': 24, 'value': 'Aerospace Engineering', 'label': 'Aerospace Engineering' },
    { 'school_id': 80, 'value': 'Aerospace Engineering - Ingegneria Aerospaziale', 'label': 'Aerospace Engineering - Ingegneria Aerospaziale' },
    { 'school_id': 13, 'value': 'Ingegneria Aeronautica', 'label': 'Ingegneria Aeronautica' },
    { 'school_id': 77, 'value': 'Ingegneria Aeronautica', 'label': 'Ingegneria Aeronautica' },
    { 'school_id': 18, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 6833, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 146, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 35, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 91, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 69, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 13, 'value': 'Ingegneria Spaziale e Astronautica', 'label': 'Ingegneria Spaziale e Astronautica' },
    { 'school_id': 95, 'value': 'Space Engineering - Ingegneria Spaziale', 'label': 'Space Engineering - Ingegneria Spaziale' },
    { 'school_id': 109, 'value': 'Bioengineering', 'label': 'Bioengineering' },
    { 'school_id': 175, 'value': 'Bioingegneria', 'label': 'Bioingegneria' },
    { 'school_id': 6833, 'value': 'Bioingegneria', 'label': 'Bioingegneria' },
    { 'school_id': 35, 'value': 'Bioingegneria Industriale', 'label': 'Bioingegneria Industriale' },
    { 'school_id': 24, 'value': 'Biomedical Engineering', 'label': 'Biomedical Engineering' },
    { 'school_id': 6837, 'value': 'Biomedical Engineering', 'label': 'Biomedical Engineering' },
    { 'school_id': 77, 'value': 'Biomedical Engineering', 'label': 'Biomedical Engineering' },
    { 'school_id': 95, 'value': 'Biomedical Engineering - Ingegneria Biomedica', 'label': 'Biomedical Engineering - Ingegneria Biomedica' },
    { 'school_id': 146, 'value': 'Bionics Engineering', 'label': 'Bionics Engineering' },
    { 'school_id': 18, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 28, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 146, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 13, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 600, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 35, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 90, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 69, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 55, 'value': 'Ingegneria Clinica', 'label': 'Ingegneria Clinica' },
    { 'school_id': 6871, 'value': 'Ingegneria dei Sistemi Medicali', 'label': 'Ingegneria dei Sistemi Medicali' },
    { 'school_id': 151, 'value': 'Ingegneria Medica', 'label': 'Ingegneria Medica' },
    { 'school_id': 95, 'value': 'Chemical Engineering - Ingegneria Chimica', 'label': 'Chemical Engineering - Ingegneria Chimica' },
    { 'school_id': 81, 'value': 'Chemical Engineering For Industrial Sustainability (Ingegneria Chimica per la Sostenibilita\' Industriale)', 'label': 'Chemical Engineering For Industrial Sustainability (Ingegneria Chimica per la Sostenibilita\' Industriale)' },
    { 'school_id': 95, 'value': 'Food Engineering', 'label': 'Food Engineering' },
    { 'school_id': 38, 'value': 'Ingegneria Alimentare', 'label': 'Ingegneria Alimentare' },
    { 'school_id': 146, 'value': 'Ingegneria Chimica', 'label': 'Ingegneria Chimica' },
    { 'school_id': 13, 'value': 'Ingegneria Chimica', 'label': 'Ingegneria Chimica' },
    { 'school_id': 35, 'value': 'Ingegneria Chimica', 'label': 'Ingegneria Chimica' },
    { 'school_id': 38, 'value': 'Ingegneria Chimica', 'label': 'Ingegneria Chimica' },
    { 'school_id': 108, 'value': 'Ingegneria Chimica', 'label': 'Ingegneria Chimica' },
    { 'school_id': 6874, 'value': 'Ingegneria Chimica', 'label': 'Ingegneria Chimica' },
    { 'school_id': 69, 'value': 'Ingegneria Chimica', 'label': 'Ingegneria Chimica' },
    { 'school_id': 124, 'value': 'Ingegneria Chimica e dei Processi Biotecnologici', 'label': 'Ingegneria Chimica e dei Processi Biotecnologici' },
    { 'school_id': 6833, 'value': 'Ingegneria Chimica e dei Processi Industriali', 'label': 'Ingegneria Chimica e dei Processi Industriali' },
    { 'school_id': 18, 'value': 'Ingegneria Chimica e dei Processi Sostenibili', 'label': 'Ingegneria Chimica e dei Processi Sostenibili' },
    { 'school_id': 109, 'value': 'Ingegneria Chimica e di Processo', 'label': 'Ingegneria Chimica e di Processo' },
    { 'school_id': 24, 'value': 'Ingegneria Chimica e di Processo', 'label': 'Ingegneria Chimica e di Processo' },
    { 'school_id': 600, 'value': 'Ingegneria Chimica per Lo Sviluppo Sostenibile', 'label': 'Ingegneria Chimica per Lo Sviluppo Sostenibile' },
    { 'school_id': 55, 'value': 'Ingegneria di Processo e dei Materiali', 'label': 'Ingegneria di Processo e dei Materiali' },
    { 'school_id': 70, 'value': 'Materials And Production Engineering - Ingegneria dei Materiali e della Produzione', 'label': 'Materials And Production Engineering - Ingegneria dei Materiali e della Produzione' },
    { 'school_id': 24, 'value': 'Offshore Engineering', 'label': 'Offshore Engineering' },
    { 'school_id': 322, 'value': 'Civil And Environmental Engineering', 'label': 'Civil And Environmental Engineering' },
    { 'school_id': 24, 'value': 'Civil Engineering', 'label': 'Civil Engineering' },
    { 'school_id': 175, 'value': 'Civil Engineering For Mitigation Of Risk From Natural Hazards', 'label': 'Civil Engineering For Mitigation Of Risk From Natural Hazards' },
    { 'school_id': 95, 'value': 'Civil Engineering For Risk Mitigation', 'label': 'Civil Engineering For Risk Mitigation' },
    { 'school_id': 18, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 109, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 63, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 212, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 175, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 70, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 6833, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 131, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 55, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 74, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 24, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 223, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 6837, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 28, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 20, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 13, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 151, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 6851, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 132, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 169, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 6859, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 91, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 38, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 108, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 198, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 6871, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 80, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 478, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 6874, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 414, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 69, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 128, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 27, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 124, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 81, 'value': 'Ingegneria Civile delle Acque e dei Trasporti', 'label': 'Ingegneria Civile delle Acque e dei Trasporti' },
    { 'school_id': 61, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 82, 'value': 'Ingegneria Civile e per la Tutela dell\'Ambiente Costiero', 'label': 'Ingegneria Civile e per la Tutela dell\'Ambiente Costiero' },
    { 'school_id': 77, 'value': 'Ingegneria Civile per la Protezione dai Rischi Naturali', 'label': 'Ingegneria Civile per la Protezione dai Rischi Naturali' },
    { 'school_id': 81, 'value': 'Ingegneria Civile Strutturale e Geotecnica', 'label': 'Ingegneria Civile Strutturale e Geotecnica' },
    { 'school_id': 95, 'value': 'Ingegneria Civile-Civil Engineering', 'label': 'Ingegneria Civile-Civil Engineering' },
    { 'school_id': 322, 'value': 'Ingegneria Civile, per l\'Ambiente e il Territorio', 'label': 'Ingegneria Civile, per l\'Ambiente e il Territorio' },
    { 'school_id': 35, 'value': 'Ingegneria dei Sistemi Idraulici e di Trasporto', 'label': 'Ingegneria dei Sistemi Idraulici e di Trasporto' },
    { 'school_id': 146, 'value': 'Ingegneria delle Infrastrutture Civili e dell\'Ambiente', 'label': 'Ingegneria delle Infrastrutture Civili e dell\'Ambiente' },
    { 'school_id': 77, 'value': 'Ingegneria delle Infrastrutture Viarie e Trasporti', 'label': 'Ingegneria delle Infrastrutture Viarie e Trasporti' },
    { 'school_id': 146, 'value': 'Ingegneria Strutturale e Edile', 'label': 'Ingegneria Strutturale e Edile' },
    { 'school_id': 35, 'value': 'Ingegneria Strutturale e Geotecnica', 'label': 'Ingegneria Strutturale e Geotecnica' },
    { 'school_id': 77, 'value': 'Sustainable Coastal And Ocean Engineering', 'label': 'Sustainable Coastal And Ocean Engineering' },
    { 'school_id': 13, 'value': 'Transport Systems Engineering', 'label': 'Transport Systems Engineering' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Ingegneria Gestionale dei Progetti e delle Infrastrutture', 'label': 'Corso di Laurea in Ingegneria Gestionale dei Progetti e delle Infrastrutture' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Ingegneria per l\'Ambiente e il Territorio', 'label': 'Corso di Laurea in Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 6871, 'value': 'Costruzioni e Gestione Ambientale e Territoriale', 'label': 'Costruzioni e Gestione Ambientale e Territoriale' },
    { 'school_id': 61, 'value': 'Costruzioni e Gestione del Territorio', 'label': 'Costruzioni e Gestione del Territorio' },
    { 'school_id': 74, 'value': 'Costruzioni, Infrastrutture e Territorio', 'label': 'Costruzioni, Infrastrutture e Territorio' },
    { 'school_id': 69, 'value': 'Ingegneria Ambientale', 'label': 'Ingegneria Ambientale' },
    { 'school_id': 6874, 'value': 'Ingegneria Ambientale e Chimica', 'label': 'Ingegneria Ambientale e Chimica' },
    { 'school_id': 13, 'value': 'Ingegneria Ambientale e Industriale', 'label': 'Ingegneria Ambientale e Industriale' },
    { 'school_id': 95, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 77, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 35, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 208, 'value': 'Ingegneria Civile', 'label': 'Ingegneria Civile' },
    { 'school_id': 91, 'value': 'Ingegneria Civile - Edile - Ambientale', 'label': 'Ingegneria Civile - Edile - Ambientale' },
    { 'school_id': 146, 'value': 'Ingegneria Civile Ambientale e Edile', 'label': 'Ingegneria Civile Ambientale e Edile' },
    { 'school_id': 109, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 63, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 175, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 131, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 55, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 74, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 223, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 6837, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 20, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 151, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 132, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 322, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 108, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 6871, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 478, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 27, 'value': 'Ingegneria Civile e Ambientale', 'label': 'Ingegneria Civile e Ambientale' },
    { 'school_id': 82, 'value': 'Ingegneria Civile e Ambientale per la Mitigazione dei Rischi', 'label': 'Ingegneria Civile e Ambientale per la Mitigazione dei Rischi' },
    { 'school_id': 414, 'value': 'Ingegneria Civile e Ambientale per Lo Sviluppo Sostenibile', 'label': 'Ingegneria Civile e Ambientale per Lo Sviluppo Sostenibile' },
    { 'school_id': 38, 'value': 'Ingegneria Civile per l\'Ambiente ed il Territorio', 'label': 'Ingegneria Civile per l\'Ambiente ed il Territorio' },
    { 'school_id': 95, 'value': 'Ingegneria Civile per la Mitigazione del Rischio', 'label': 'Ingegneria Civile per la Mitigazione del Rischio' },
    { 'school_id': 81, 'value': 'Ingegneria Civile, Ambientale e Gestionale', 'label': 'Ingegneria Civile, Ambientale e Gestionale' },
    { 'school_id': 28, 'value': 'Ingegneria Civile, Edile e Ambientale', 'label': 'Ingegneria Civile, Edile e Ambientale' },
    { 'school_id': 18, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 212, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 70, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 6833, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 24, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 13, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 124, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 95, 'value': 'Ingegneria per l\'Ambiente ed il Territorio', 'label': 'Ingegneria per l\'Ambiente ed il Territorio' },
    { 'school_id': 148, 'value': 'Ingegneria per la Sicurezza del Lavoro e dell\'Ambiente', 'label': 'Ingegneria per la Sicurezza del Lavoro e dell\'Ambiente' },
    { 'school_id': 108, 'value': 'Tecniche della Protezione Civile e Sicurezza del Territorio', 'label': 'Tecniche della Protezione Civile e Sicurezza del Territorio' },
    { 'school_id': 91, 'value': 'Tecniche per l\'Edilizia, il Territorio e l\'Ambiente', 'label': 'Tecniche per l\'Edilizia, il Territorio e l\'Ambiente' },
    { 'school_id': 35, 'value': 'Tecnologie Digitali per le Costruzioni', 'label': 'Tecnologie Digitali per le Costruzioni' },
    { 'school_id': 95, 'value': 'Building And Architectural Engineering', 'label': 'Building And Architectural Engineering' },
    { 'school_id': 109, 'value': 'Engineering For Building Retrofitting', 'label': 'Engineering For Building Retrofitting' },
    { 'school_id': 13, 'value': 'Environmental And Sustainable Building Engineering', 'label': 'Environmental And Sustainable Building Engineering' },
    { 'school_id': 13, 'value': 'Gestione del Progetto e della Costruzione dei Sistemi Edilizi', 'label': 'Gestione del Progetto e della Costruzione dei Sistemi Edilizi' },
    { 'school_id': 24, 'value': 'Ingegneria dei Processi e dei Sistemi Edilizi', 'label': 'Ingegneria dei Processi e dei Sistemi Edilizi' },
    { 'school_id': 95, 'value': 'Ingegneria dei Sistemi Edilizi', 'label': 'Ingegneria dei Sistemi Edilizi' },
    { 'school_id': 6871, 'value': 'Ingegneria dei Sistemi Edilizi', 'label': 'Ingegneria dei Sistemi Edilizi' },
    { 'school_id': 69, 'value': 'Ingegneria dei Sistemi Edilizi', 'label': 'Ingegneria dei Sistemi Edilizi' },
    { 'school_id': 114, 'value': 'Ingegneria delle Costruzioni', 'label': 'Ingegneria delle Costruzioni' },
    { 'school_id': 79, 'value': 'Ingegneria delle Costruzioni Edili', 'label': 'Ingegneria delle Costruzioni Edili' },
    { 'school_id': 151, 'value': 'Ingegneria e Tecniche del Costruire', 'label': 'Ingegneria e Tecniche del Costruire' },
    { 'school_id': 18, 'value': 'Ingegneria Edile', 'label': 'Ingegneria Edile' },
    { 'school_id': 6837, 'value': 'Ingegneria Edile', 'label': 'Ingegneria Edile' },
    { 'school_id': 28, 'value': 'Ingegneria Edile', 'label': 'Ingegneria Edile' },
    { 'school_id': 35, 'value': 'Ingegneria Edile', 'label': 'Ingegneria Edile' },
    { 'school_id': 95, 'value': 'Management Of Built Environment - Gestione del Costruito', 'label': 'Management Of Built Environment - Gestione del Costruito' },
    { 'school_id': 95, 'value': 'Automation And Control Engineering - Ingegneria dell\'Automazione', 'label': 'Automation And Control Engineering - Ingegneria dell\'Automazione' },
    { 'school_id': 24, 'value': 'Automation Engineering', 'label': 'Automation Engineering' },
    { 'school_id': 81, 'value': 'Automation Engineering And Control Of Complex Systems', 'label': 'Automation Engineering And Control Of Complex Systems' },
    { 'school_id': 13, 'value': 'Control Engineering', 'label': 'Control Engineering' },
    { 'school_id': 6833, 'value': 'Control Systems Engineering', 'label': 'Control Systems Engineering' },
    { 'school_id': 175, 'value': 'Industrial Automation Engineering', 'label': 'Industrial Automation Engineering' },
    { 'school_id': 151, 'value': 'Ingegneria dell\'Automazione', 'label': 'Ingegneria dell\'Automazione' },
    { 'school_id': 6871, 'value': 'Ingegneria dell\'Automazione', 'label': 'Ingegneria dell\'Automazione' },
    { 'school_id': 6874, 'value': 'Ingegneria dell\'Automazione', 'label': 'Ingegneria dell\'Automazione' },
    { 'school_id': 35, 'value': 'Ingegneria dell\'Automazione e Robotica', 'label': 'Ingegneria dell\'Automazione e Robotica' },
    { 'school_id': 212, 'value': 'Ingegneria dell\'Automazione Industriale', 'label': 'Ingegneria dell\'Automazione Industriale' },
    { 'school_id': 28, 'value': 'Ingegneria Elettrica e dell\'Automazione', 'label': 'Ingegneria Elettrica e dell\'Automazione' },
    { 'school_id': 6833, 'value': 'Ingegneria Meccatronica', 'label': 'Ingegneria Meccatronica' },
    { 'school_id': 146, 'value': 'Ingegneria Robotica e dell\'Automazione', 'label': 'Ingegneria Robotica e dell\'Automazione' },
    { 'school_id': 18, 'value': 'Mechatronic Engineering (Ingegneria Meccatronica)', 'label': 'Mechatronic Engineering (Ingegneria Meccatronica)' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Ingegneria dell\'Automazione', 'label': 'Corso di Laurea in Ingegneria dell\'Automazione' },
    { 'school_id': 18, 'value': 'Electronic And Communications Engineering (Ingegneria Elettronica e delle Comunicazioni)', 'label': 'Electronic And Communications Engineering (Ingegneria Elettronica e delle Comunicazioni)' },
    { 'school_id': 109, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 95, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 6833, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 24, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 6837, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 124, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 69, 'value': 'Ingegneria Cibernetica', 'label': 'Ingegneria Cibernetica' },
    { 'school_id': 6871, 'value': 'Ingegneria dei Sistemi Aerospaziali', 'label': 'Ingegneria dei Sistemi Aerospaziali' },
    { 'school_id': 74, 'value': 'Ingegneria dei Sistemi Informativi', 'label': 'Ingegneria dei Sistemi Informativi' },
    { 'school_id': 18, 'value': 'Ingegneria del Cinema e dei Mezzi di Comunicazione', 'label': 'Ingegneria del Cinema e dei Mezzi di Comunicazione' },
    { 'school_id': 95, 'value': 'Ingegneria dell\'Automazione', 'label': 'Ingegneria dell\'Automazione' },
    { 'school_id': 24, 'value': 'Ingegneria dell\'Automazione', 'label': 'Ingegneria dell\'Automazione' },
    { 'school_id': 6833, 'value': 'Ingegneria dell\'Informazione', 'label': 'Ingegneria dell\'Informazione' },
    { 'school_id': 13, 'value': 'Ingegneria dell\'Informazione', 'label': 'Ingegneria dell\'Informazione' },
    { 'school_id': 108, 'value': 'Ingegneria dell\'Informazione', 'label': 'Ingegneria dell\'Informazione' },
    { 'school_id': 80, 'value': 'Ingegneria dell\'Informazione', 'label': 'Ingegneria dell\'Informazione' },
    { 'school_id': 414, 'value': 'Ingegneria dell\'Informazione', 'label': 'Ingegneria dell\'Informazione' },
    { 'school_id': 69, 'value': 'Ingegneria dell\'Innovazione per le Imprese Digitali', 'label': 'Ingegneria dell\'Innovazione per le Imprese Digitali' },
    { 'school_id': 13, 'value': 'Ingegneria delle Comunicazioni', 'label': 'Ingegneria delle Comunicazioni' },
    { 'school_id': 212, 'value': 'Ingegneria delle Tecnologie per l\'Impresa Digitale', 'label': 'Ingegneria delle Tecnologie per l\'Impresa Digitale' },
    { 'school_id': 146, 'value': 'Ingegneria delle Telecomunicazioni', 'label': 'Ingegneria delle Telecomunicazioni' },
    { 'school_id': 35, 'value': 'Ingegneria delle Telecomunicazioni e dei Media Digitali', 'label': 'Ingegneria delle Telecomunicazioni e dei Media Digitali' },
    { 'school_id': 151, 'value': 'Ingegneria di Internet', 'label': 'Ingegneria di Internet' },
    { 'school_id': 124, 'value': 'Ingegneria Elettrica, Elettronica e Informatica', 'label': 'Ingegneria Elettrica, Elettronica e Informatica' },
    { 'school_id': 18, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 95, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 6833, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 131, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 61, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 6837, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 146, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 13, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 151, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 77, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 35, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 38, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 6874, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 69, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 81, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 212, 'value': 'Ingegneria Elettronica e delle Telecomunicazioni', 'label': 'Ingegneria Elettronica e delle Telecomunicazioni' },
    { 'school_id': 28, 'value': 'Ingegneria Elettronica e delle Telecomunicazioni', 'label': 'Ingegneria Elettronica e delle Telecomunicazioni' },
    { 'school_id': 6871, 'value': 'Ingegneria Elettronica e delle Telecomunicazioni', 'label': 'Ingegneria Elettronica e delle Telecomunicazioni' },
    { 'school_id': 175, 'value': 'Ingegneria Elettronica e Informatica', 'label': 'Ingegneria Elettronica e Informatica' },
    { 'school_id': 55, 'value': 'Ingegneria Elettronica e Informatica', 'label': 'Ingegneria Elettronica e Informatica' },
    { 'school_id': 223, 'value': 'Ingegneria Elettronica e Informatica', 'label': 'Ingegneria Elettronica e Informatica' },
    { 'school_id': 169, 'value': 'Ingegneria Elettronica e Informatica', 'label': 'Ingegneria Elettronica e Informatica' },
    { 'school_id': 91, 'value': 'Ingegneria Elettronica e Informatica', 'label': 'Ingegneria Elettronica e Informatica' },
    { 'school_id': 128, 'value': 'Ingegneria Elettronica e Informatica', 'label': 'Ingegneria Elettronica e Informatica' },
    { 'school_id': 109, 'value': 'Ingegneria Elettronica e Tecnologie dell\'Informazione', 'label': 'Ingegneria Elettronica e Tecnologie dell\'Informazione' },
    { 'school_id': 24, 'value': 'Ingegneria Elettronica e Telecomunicazioni', 'label': 'Ingegneria Elettronica e Telecomunicazioni' },
    { 'school_id': 6859, 'value': 'Ingegneria Elettronica per l\'Automazione e le Telecomunicazioni', 'label': 'Ingegneria Elettronica per l\'Automazione e le Telecomunicazioni' },
    { 'school_id': 24, 'value': 'Ingegneria Elettronica per l\'Energia e l\'Informazione', 'label': 'Ingegneria Elettronica per l\'Energia e l\'Informazione' },
    { 'school_id': 18, 'value': 'Ingegneria Fisica', 'label': 'Ingegneria Fisica' },
    { 'school_id': 95, 'value': 'Ingegneria Fisica', 'label': 'Ingegneria Fisica' },
    { 'school_id': 6831, 'value': 'Ingegneria Fisica', 'label': 'Ingegneria Fisica' },
    { 'school_id': 18, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 95, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 131, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 61, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 6837, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 137, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 13, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 6874, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 18, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 109, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 95, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 79, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 212, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 6833, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 61, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 24, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 28, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 146, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 151, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 77, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 6851, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 132, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 447, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 6859, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 35, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 38, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 6874, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 69, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 27, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 81, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 115, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 13, 'value': 'Ingegneria Informatica e Automatica', 'label': 'Ingegneria Informatica e Automatica' },
    { 'school_id': 90, 'value': 'Ingegneria Informatica e Biomedica', 'label': 'Ingegneria Informatica e Biomedica' },
    { 'school_id': 63, 'value': 'Ingegneria Informatica e dell\'Automazione', 'label': 'Ingegneria Informatica e dell\'Automazione' },
    { 'school_id': 6837, 'value': 'Ingegneria Informatica e dell\'Automazione', 'label': 'Ingegneria Informatica e dell\'Automazione' },
    { 'school_id': 6871, 'value': 'Ingegneria Informatica e dell\'Automazione', 'label': 'Ingegneria Informatica e dell\'Automazione' },
    { 'school_id': 137, 'value': 'Ingegneria Informatica e dell\'Informazione', 'label': 'Ingegneria Informatica e dell\'Informazione' },
    { 'school_id': 322, 'value': 'Ingegneria Informatica e delle Telecomunicazioni', 'label': 'Ingegneria Informatica e delle Telecomunicazioni' },
    { 'school_id': 20, 'value': 'Ingegneria Informatica ed Elettronica', 'label': 'Ingegneria Informatica ed Elettronica' },
    { 'school_id': 82, 'value': 'Ingegneria Informatica, Biomedica e delle Telecomunicazioni', 'label': 'Ingegneria Informatica, Biomedica e delle Telecomunicazioni' },
    { 'school_id': 70, 'value': 'Ingegneria Informatica, delle Comunicazioni ed Elettronica', 'label': 'Ingegneria Informatica, delle Comunicazioni ed Elettronica' },
    { 'school_id': 74, 'value': 'Ingegneria Informatica, Elettronica e delle Telecomunicazioni', 'label': 'Ingegneria Informatica, Elettronica e delle Telecomunicazioni' },
    { 'school_id': 95, 'value': 'Ingegneria Matematica', 'label': 'Ingegneria Matematica' },
    { 'school_id': 61, 'value': 'Ingegneria Meccatronica', 'label': 'Ingegneria Meccatronica' },
    { 'school_id': 24, 'value': 'Ingegneria Meccatronica', 'label': 'Ingegneria Meccatronica' },
    { 'school_id': 35, 'value': 'Ingegneria Meccatronica', 'label': 'Ingegneria Meccatronica' },
    { 'school_id': 109, 'value': 'Engineering For Natural Risk Management', 'label': 'Engineering For Natural Risk Management' },
    { 'school_id': 95, 'value': 'Ingegneria della Prevenzione e della Sicurezza nell\'Industria di Processo', 'label': 'Ingegneria della Prevenzione e della Sicurezza nell\'Industria di Processo' },
    { 'school_id': 208, 'value': 'Ingegneria della Sicurezza', 'label': 'Ingegneria della Sicurezza' },
    { 'school_id': 6833, 'value': 'Ingegneria della Sicurezza Civile e Industriale', 'label': 'Ingegneria della Sicurezza Civile e Industriale' },
    { 'school_id': 13, 'value': 'Ingegneria della Sicurezza e Protezione Civile', 'label': 'Ingegneria della Sicurezza e Protezione Civile' },
    { 'school_id': 20, 'value': 'Ingegneria della Sicurezza per il Territorio e il Costruito', 'label': 'Ingegneria della Sicurezza per il Territorio e il Costruito' },
    { 'school_id': 95, 'value': 'Mobility Engineering', 'label': 'Mobility Engineering' },
    { 'school_id': 109, 'value': 'Safety Engineering For Transport, Logistics, And Production - Ingegneria della Sicurezza per i Trasporti, la Logistica e i Sistemi di Produzione', 'label': 'Safety Engineering For Transport, Logistics, And Production - Ingegneria della Sicurezza per i Trasporti, la Logistica e i Sistemi di Produzione' },
    { 'school_id': 74, 'value': 'Communication Engineering - Ingegneria delle Telecomunicazioni', 'label': 'Communication Engineering - Ingegneria delle Telecomunicazioni' },
    { 'school_id': 212, 'value': 'Communication Technologies And Multimedia - Tecnologie delle Comunicazioni e Multimedia', 'label': 'Communication Technologies And Multimedia - Tecnologie delle Comunicazioni e Multimedia' },
    { 'school_id': 18, 'value': 'Communications And Computer Networks Engineering (Ingegneria Telematica e delle Comunicazioni)', 'label': 'Communications And Computer Networks Engineering (Ingegneria Telematica e delle Comunicazioni)' },
    { 'school_id': 137, 'value': 'Electronics And Communications Engineering - Ingegneria Elettronica e delle Telecomunicazioni', 'label': 'Electronics And Communications Engineering - Ingegneria Elettronica e delle Telecomunicazioni' },
    { 'school_id': 151, 'value': 'Ict And Internet Engineering - Ingegneria di Internet e delle Tecnologie per l\'Informazione e la Comunicazione', 'label': 'Ict And Internet Engineering - Ingegneria di Internet e delle Tecnologie per l\'Informazione e la Comunicazione' },
    { 'school_id': 6833, 'value': 'Ict For Internet And Multimedia - Ingegneria per le Comunicazioni Multimediali e Internet', 'label': 'Ict For Internet And Multimedia - Ingegneria per le Comunicazioni Multimediali e Internet' },
    { 'school_id': 18, 'value': 'Ict For Smart Societies (Ict per la Società del Futuro)', 'label': 'Ict For Smart Societies (Ict per la Società del Futuro)' },
    { 'school_id': 70, 'value': 'Ingegneria dell\'Informazione e delle Comunicazioni', 'label': 'Ingegneria dell\'Informazione e delle Comunicazioni' },
    { 'school_id': 82, 'value': 'Ingegneria della Sicurezza dei Dati e delle Comunicazioni', 'label': 'Ingegneria della Sicurezza dei Dati e delle Comunicazioni' },
    { 'school_id': 77, 'value': 'Ingegneria delle Tecnologie della Comunicazione e dell\'Informazione', 'label': 'Ingegneria delle Tecnologie della Comunicazione e dell\'Informazione' },
    { 'school_id': 124, 'value': 'Ingegneria delle Tecnologie per Internet', 'label': 'Ingegneria delle Tecnologie per Internet' },
    { 'school_id': 28, 'value': 'Ingegneria delle Telecomunicazioni', 'label': 'Ingegneria delle Telecomunicazioni' },
    { 'school_id': 108, 'value': 'Ingegneria delle Telecomunicazioni', 'label': 'Ingegneria delle Telecomunicazioni' },
    { 'school_id': 6871, 'value': 'Ingegneria delle Telecomunicazioni', 'label': 'Ingegneria delle Telecomunicazioni' },
    { 'school_id': 6874, 'value': 'Ingegneria delle Telecomunicazioni', 'label': 'Ingegneria delle Telecomunicazioni' },
    { 'school_id': 81, 'value': 'Ingegneria delle Telecomunicazioni', 'label': 'Ingegneria delle Telecomunicazioni' },
    { 'school_id': 80, 'value': 'Ingegneria delle Telecomunicazioni e delle Tecnologie Elettroniche', 'label': 'Ingegneria delle Telecomunicazioni e delle Tecnologie Elettroniche' },
    { 'school_id': 414, 'value': 'Ingegneria Informatica e dei Sistemi per le Telecomunicazioni', 'label': 'Ingegneria Informatica e dei Sistemi per le Telecomunicazioni' },
    { 'school_id': 109, 'value': 'Internet And Multimedia Engineering- Ingegneria dell\'Internet e della Multimedialita\'', 'label': 'Internet And Multimedia Engineering- Ingegneria dell\'Internet e della Multimedialita\'' },
    { 'school_id': 95, 'value': 'Telecommunication Engineering - Ingegneria delle Telecomunicazioni', 'label': 'Telecommunication Engineering - Ingegneria delle Telecomunicazioni' },
    { 'school_id': 24, 'value': 'Telecommunications Engineering', 'label': 'Telecommunications Engineering' },
    { 'school_id': 322, 'value': 'Telecommunications Engineering - Ingegneria delle Telecomunicazioni', 'label': 'Telecommunications Engineering - Ingegneria delle Telecomunicazioni' },
    { 'school_id': 24, 'value': 'Electric Vehicle Engineering', 'label': 'Electric Vehicle Engineering' },
    { 'school_id': 81, 'value': 'Electrical Engineering', 'label': 'Electrical Engineering' },
    { 'school_id': 95, 'value': 'Electrical Engineering - Ingegneria Elettrica', 'label': 'Electrical Engineering - Ingegneria Elettrica' },
    { 'school_id': 6833, 'value': 'Ingegneria dell\'Energia Elettrica', 'label': 'Ingegneria dell\'Energia Elettrica' },
    { 'school_id': 24, 'value': 'Ingegneria dell\'Energia Elettrica', 'label': 'Ingegneria dell\'Energia Elettrica' },
    { 'school_id': 55, 'value': 'Ingegneria dell\'Energia Elettrica e dei Sistemi', 'label': 'Ingegneria dell\'Energia Elettrica e dei Sistemi' },
    { 'school_id': 18, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 109, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 175, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 146, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 322, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 35, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 108, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 6871, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 69, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 124, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 13, 'value': 'Ingegneria Elettrotecnica', 'label': 'Ingegneria Elettrotecnica' },
    { 'school_id': 13, 'value': 'Sustainable Transportation And Electrical Power Systems', 'label': 'Sustainable Transportation And Electrical Power Systems' },
    { 'school_id': 24, 'value': 'Advanced Automotive Electronic Engineering', 'label': 'Advanced Automotive Electronic Engineering' },
    { 'school_id': 175, 'value': 'Electronic Engineering', 'label': 'Electronic Engineering' },
    { 'school_id': 81, 'value': 'Electronic Engineering', 'label': 'Electronic Engineering' },
    { 'school_id': 61, 'value': 'Electronics Engineering', 'label': 'Electronics Engineering' },
    { 'school_id': 69, 'value': 'Electronics Engineering', 'label': 'Electronics Engineering' },
    { 'school_id': 95, 'value': 'Electronics Engineering - Ingegneria Elettronica', 'label': 'Electronics Engineering - Ingegneria Elettronica' },
    { 'school_id': 109, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 212, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 74, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 24, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 28, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 169, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 91, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 108, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 6871, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 414, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 124, 'value': 'Ingegneria Elettronica', 'label': 'Ingegneria Elettronica' },
    { 'school_id': 24, 'value': 'Ingegneria Elettronica e Telecomunicazioni per l\'Energia', 'label': 'Ingegneria Elettronica e Telecomunicazioni per l\'Energia' },
    { 'school_id': 223, 'value': 'Ingegneria Elettronica per l\'Ict', 'label': 'Ingegneria Elettronica per l\'Ict' },
    { 'school_id': 128, 'value': 'Ingegneria Elettronica per l\'Industria', 'label': 'Ingegneria Elettronica per l\'Industria' },
    { 'school_id': 77, 'value': 'Ingegneria Elettronica per l\'Industria e l\'Innovazione', 'label': 'Ingegneria Elettronica per l\'Industria e l\'Innovazione' },
    { 'school_id': 20, 'value': 'Ingegneria Elettronica per l\'Internet-Of-Things', 'label': 'Ingegneria Elettronica per l\'Internet-Of-Things' },
    { 'school_id': 151, 'value': 'Mechatronics Engineering', 'label': 'Mechatronics Engineering' },
    { 'school_id': 18, 'value': 'Nanotechnologies For Icts (Nanotecnologie per le Ict)', 'label': 'Nanotechnologies For Icts (Nanotecnologie per le Ict)' },
    { 'school_id': 109, 'value': 'Energy Engineering - Ingegneria Energetica', 'label': 'Energy Engineering - Ingegneria Energetica' },
    { 'school_id': 95, 'value': 'Energy Engineering - Ingegneria Energetica', 'label': 'Energy Engineering - Ingegneria Energetica' },
    { 'school_id': 273, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 6833, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 24, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 28, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 146, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 13, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 151, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 6859, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 6874, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 124, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 18, 'value': 'Ingegneria Energetica e Nucleare', 'label': 'Ingegneria Energetica e Nucleare' },
    { 'school_id': 6851, 'value': 'Ingegneria Energetica e Nucleare', 'label': 'Ingegneria Energetica e Nucleare' },
    { 'school_id': 69, 'value': 'Ingegneria Energetica e Nucleare', 'label': 'Ingegneria Energetica e Nucleare' },
    { 'school_id': 146, 'value': 'Ingegneria Nucleare', 'label': 'Ingegneria Nucleare' },
    { 'school_id': 91, 'value': 'Ingegneria per l\'Energia e l\'Ambiente', 'label': 'Ingegneria per l\'Energia e l\'Ambiente' },
    { 'school_id': 414, 'value': 'Ingegneria per la Gestione Sostenibile dell\'Ambiente e dell\'Energia', 'label': 'Ingegneria per la Gestione Sostenibile dell\'Ambiente e dell\'Energia' },
    { 'school_id': 95, 'value': 'Nuclear Engineering - Ingegneria Nucleare', 'label': 'Nuclear Engineering - Ingegneria Nucleare' },
    { 'school_id': 79, 'value': 'Engineering And Management For Health', 'label': 'Engineering And Management For Health' },
    { 'school_id': 137, 'value': 'Engineering Management', 'label': 'Engineering Management' },
    { 'school_id': 109, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 58, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 79, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 212, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 6833, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 74, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 24, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 28, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 146, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 151, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 132, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 447, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 169, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 322, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 35, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 82, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 91, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 38, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 108, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 6871, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 69, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 81, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 79, 'value': 'Management Engineering', 'label': 'Management Engineering' },
    { 'school_id': 95, 'value': 'Management Engineering - Ingegneria Gestionale', 'label': 'Management Engineering - Ingegneria Gestionale' },
    { 'school_id': 80, 'value': 'Management Engineering - Ingegneria Gestionale', 'label': 'Management Engineering - Ingegneria Gestionale' },
    { 'school_id': 55, 'value': 'Production Engineering And Management - Ingegneria Gestionale per la Produzione', 'label': 'Production Engineering And Management - Ingegneria Gestionale per la Produzione' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Ingegneria Aerospaziale', 'label': 'Corso di Laurea in Ingegneria Aerospaziale' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Ingegneria Chimica', 'label': 'Corso di Laurea in Ingegneria Chimica' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Ingegneria Elettrica', 'label': 'Corso di Laurea in Ingegneria Elettrica' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Ingegneria Meccanica', 'label': 'Corso di Laurea in Ingegneria Meccanica' },
    { 'school_id': 151, 'value': 'Engineering Sciences', 'label': 'Engineering Sciences' },
    { 'school_id': 115, 'value': 'Gestione Energetica e Sicurezza', 'label': 'Gestione Energetica e Sicurezza' },
    { 'school_id': 95, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 24, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 13, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 27, 'value': 'Ingegneria Aerospaziale', 'label': 'Ingegneria Aerospaziale' },
    { 'school_id': 91, 'value': 'Ingegneria Aerospaziale, Meccanica, Energetica', 'label': 'Ingegneria Aerospaziale, Meccanica, Energetica' },
    { 'school_id': 6874, 'value': 'Ingegneria Alimentare', 'label': 'Ingegneria Alimentare' },
    { 'school_id': 114, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 80, 'value': 'Ingegneria Biomedica', 'label': 'Ingegneria Biomedica' },
    { 'school_id': 95, 'value': 'Ingegneria Chimica', 'label': 'Ingegneria Chimica' },
    { 'school_id': 124, 'value': 'Ingegneria Chimica', 'label': 'Ingegneria Chimica' },
    { 'school_id': 18, 'value': 'Ingegneria Chimica e Alimentare', 'label': 'Ingegneria Chimica e Alimentare' },
    { 'school_id': 24, 'value': 'Ingegneria Chimica e Biochimica', 'label': 'Ingegneria Chimica e Biochimica' },
    { 'school_id': 69, 'value': 'Ingegneria Chimica e Biochimica', 'label': 'Ingegneria Chimica e Biochimica' },
    { 'school_id': 6833, 'value': 'Ingegneria Chimica e dei Materiali', 'label': 'Ingegneria Chimica e dei Materiali' },
    { 'school_id': 13, 'value': 'Ingegneria Clinica', 'label': 'Ingegneria Clinica' },
    { 'school_id': 18, 'value': 'Ingegneria dei Materiali', 'label': 'Ingegneria dei Materiali' },
    { 'school_id': 95, 'value': 'Ingegneria dei Materiali e delle Nanotecnologie', 'label': 'Ingegneria dei Materiali e delle Nanotecnologie' },
    { 'school_id': 96, 'value': 'Ingegneria dei Sistemi Logistici per l\' Agro-Alimentare', 'label': 'Ingegneria dei Sistemi Logistici per l\' Agro-Alimentare' },
    { 'school_id': 273, 'value': 'Ingegneria del Legno', 'label': 'Ingegneria del Legno' },
    { 'school_id': 61, 'value': 'Ingegneria del Veicolo', 'label': 'Ingegneria del Veicolo' },
    { 'school_id': 18, 'value': 'Ingegneria dell\'Autoveicolo', 'label': 'Ingegneria dell\'Autoveicolo' },
    { 'school_id': 6833, 'value': 'Ingegneria dell\'Energia', 'label': 'Ingegneria dell\'Energia' },
    { 'school_id': 146, 'value': 'Ingegneria dell\'Energia', 'label': 'Ingegneria dell\'Energia' },
    { 'school_id': 69, 'value': 'Ingegneria dell\'Energia e delle Fonti Rinnovabili', 'label': 'Ingegneria dell\'Energia e delle Fonti Rinnovabili' },
    { 'school_id': 6833, 'value': 'Ingegneria dell\'Innovazione del Prodotto', 'label': 'Ingegneria dell\'Innovazione del Prodotto' },
    { 'school_id': 18, 'value': 'Ingegneria della Produzione Industriale', 'label': 'Ingegneria della Produzione Industriale' },
    { 'school_id': 95, 'value': 'Ingegneria della Produzione Industriale', 'label': 'Ingegneria della Produzione Industriale' },
    { 'school_id': 69, 'value': 'Ingegneria della Sicurezza', 'label': 'Ingegneria della Sicurezza' },
    { 'school_id': 77, 'value': 'Ingegneria delle Tecnologie per il Mare', 'label': 'Ingegneria delle Tecnologie per il Mare' },
    { 'school_id': 79, 'value': 'Ingegneria delle Tecnologie per la Salute', 'label': 'Ingegneria delle Tecnologie per la Salute' },
    { 'school_id': 95, 'value': 'Ingegneria Elettrica', 'label': 'Ingegneria Elettrica' },
    { 'school_id': 69, 'value': 'Ingegneria Elettrica per la E-Mobility', 'label': 'Ingegneria Elettrica per la E-Mobility' },
    { 'school_id': 18, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 95, 'value': 'Ingegneria Energetica', 'label': 'Ingegneria Energetica' },
    { 'school_id': 382, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 128, 'value': 'Ingegneria Gestionale', 'label': 'Ingegneria Gestionale' },
    { 'school_id': 35, 'value': 'Ingegneria Gestionale della Logistica e della Produzione', 'label': 'Ingegneria Gestionale della Logistica e della Produzione' },
    { 'school_id': 63, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 175, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 70, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 55, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 20, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 46, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 600, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 6851, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 169, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 322, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 108, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 80, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 414, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 128, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 81, 'value': 'Ingegneria Industriale', 'label': 'Ingegneria Industriale' },
    { 'school_id': 273, 'value': 'Ingegneria Industriale Meccanica', 'label': 'Ingegneria Industriale Meccanica' },
    { 'school_id': 131, 'value': 'Ingegneria Industriale per la Sostenibilita\' Ambientale', 'label': 'Ingegneria Industriale per la Sostenibilita\' Ambientale' },
    { 'school_id': 18, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 109, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 95, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 79, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 6833, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 131, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 74, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 61, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 24, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 223, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 6837, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 28, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 146, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 20, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 13, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 151, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 77, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 38, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 6871, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 478, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 6874, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 69, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 124, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 109, 'value': 'Ingegneria Meccanica - Energia e Produzione', 'label': 'Ingegneria Meccanica - Energia e Produzione' },
    { 'school_id': 212, 'value': 'Ingegneria Meccanica e dei Materiali', 'label': 'Ingegneria Meccanica e dei Materiali' },
    { 'school_id': 198, 'value': 'Ingegneria Medica', 'label': 'Ingegneria Medica' },
    { 'school_id': 109, 'value': 'Ingegneria Nautica', 'label': 'Ingegneria Nautica' },
    { 'school_id': 109, 'value': 'Ingegneria Navale', 'label': 'Ingegneria Navale' },
    { 'school_id': 55, 'value': 'Ingegneria Navale', 'label': 'Ingegneria Navale' },
    { 'school_id': 35, 'value': 'Ingegneria Navale', 'label': 'Ingegneria Navale' },
    { 'school_id': 61, 'value': 'Ingegneria per l\'Industria Intelligente', 'label': 'Ingegneria per l\'Industria Intelligente' },
    { 'school_id': 35, 'value': 'Scienza e Ingegneria dei Materiali', 'label': 'Scienza e Ingegneria dei Materiali' },
    { 'school_id': 6837, 'value': 'Sistemi Industriali e dell\'Informazione', 'label': 'Sistemi Industriali e dell\'Informazione' },
    { 'school_id': 18, 'value': 'Tecnologie per l\'Industria Manifatturiera', 'label': 'Tecnologie per l\'Industria Manifatturiera' },
    { 'school_id': 137, 'value': 'Artificial Intelligence And Automation Engineering', 'label': 'Artificial Intelligence And Automation Engineering' },
    { 'school_id': 146, 'value': 'Artificial Intelligence And Data Engineering', 'label': 'Artificial Intelligence And Data Engineering' },
    { 'school_id': 13, 'value': 'Artificial Intelligence And Robotics', 'label': 'Artificial Intelligence And Robotics' },
    { 'school_id': 70, 'value': 'Artificial Intelligence Systems', 'label': 'Artificial Intelligence Systems' },
    { 'school_id': 109, 'value': 'Computer Engineering', 'label': 'Computer Engineering' },
    { 'school_id': 175, 'value': 'Computer Engineering', 'label': 'Computer Engineering' },
    { 'school_id': 6833, 'value': 'Computer Engineering', 'label': 'Computer Engineering' },
    { 'school_id': 146, 'value': 'Computer Engineering', 'label': 'Computer Engineering' },
    { 'school_id': 6830, 'value': 'Computer Engineering For Robotics And Smart Industry', 'label': 'Computer Engineering For Robotics And Smart Industry' },
    { 'school_id': 124, 'value': 'Computer Engineering, Cybersecurity And Artificial Intelligence', 'label': 'Computer Engineering, Cybersecurity And Artificial Intelligence' },
    { 'school_id': 95, 'value': 'Computer Science And Engineering - Ingegneria Informatica', 'label': 'Computer Science And Engineering - Ingegneria Informatica' },
    { 'school_id': 18, 'value': 'Data Science e Ingegneria', 'label': 'Data Science e Ingegneria' },
    { 'school_id': 38, 'value': 'Digital Health And Bioinformatic Engineering', 'label': 'Digital Health And Bioinformatic Engineering' },
    { 'school_id': 13, 'value': 'Engineering in Computer Science', 'label': 'Engineering in Computer Science' },
    { 'school_id': 95, 'value': 'Geoinformatics Engineering - Ingegneria Geoinformatica', 'label': 'Geoinformatics Engineering - Ingegneria Geoinformatica' },
    { 'school_id': 600, 'value': 'Ingegneria dei Sistemi Intelligenti', 'label': 'Ingegneria dei Sistemi Intelligenti' },
    { 'school_id': 77, 'value': 'Ingegneria Gestionale e dell\'Automazione', 'label': 'Ingegneria Gestionale e dell\'Automazione' },
    { 'school_id': 74, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 169, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 322, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 91, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 6871, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 80, 'value': 'Ingegneria Informatica', 'label': 'Ingegneria Informatica' },
    { 'school_id': 108, 'value': 'Ingegneria Informatica e Automatica', 'label': 'Ingegneria Informatica e Automatica' },
    { 'school_id': 223, 'value': 'Ingegneria Informatica e dell’Automazione', 'label': 'Ingegneria Informatica e dell’Automazione' },
    { 'school_id': 478, 'value': 'Ingegneria Informatica e delle Tecnologie dell\'Informazione', 'label': 'Ingegneria Informatica e delle Tecnologie dell\'Informazione' },
    { 'school_id': 20, 'value': 'Ingegneria Informatica e Robotica', 'label': 'Ingegneria Informatica e Robotica' },
    { 'school_id': 6874, 'value': 'Ingegneria Informatica per l\'Internet delle Cose', 'label': 'Ingegneria Informatica per l\'Internet delle Cose' },
    { 'school_id': 95, 'value': 'Music And Acoustic Engineering', 'label': 'Music And Acoustic Engineering' },
    { 'school_id': 109, 'value': 'Robotics Engineering', 'label': 'Robotics Engineering' },
    { 'school_id': 61, 'value': 'Advanced Automotive Engineering', 'label': 'Advanced Automotive Engineering' },
    { 'school_id': 18, 'value': 'Automotive Engineering (Ingegneria dell\'Autoveicolo)', 'label': 'Automotive Engineering (Ingegneria dell\'Autoveicolo)' },
    { 'school_id': 35, 'value': 'Autonomous Vehicle Engineering', 'label': 'Autonomous Vehicle Engineering' },
    { 'school_id': 74, 'value': 'Ingegneria degli Impianti e delle Macchine dell\'Industria Alimentare', 'label': 'Ingegneria degli Impianti e delle Macchine dell\'Industria Alimentare' },
    { 'school_id': 146, 'value': 'Ingegneria dei Veicoli', 'label': 'Ingegneria dei Veicoli' },
    { 'school_id': 18, 'value': 'Ingegneria della Produzione Industriale e dell\'Innovazione Tecnologica', 'label': 'Ingegneria della Produzione Industriale e dell\'Innovazione Tecnologica' },
    { 'school_id': 212, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 55, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 46, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 169, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 322, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 91, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 108, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 80, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 128, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 81, 'value': 'Ingegneria Meccanica', 'label': 'Ingegneria Meccanica' },
    { 'school_id': 109, 'value': 'Ingegneria Meccanica - Energia e Aeronautica', 'label': 'Ingegneria Meccanica - Energia e Aeronautica' },
    { 'school_id': 109, 'value': 'Ingegneria Meccanica - Progettazione e Produzione', 'label': 'Ingegneria Meccanica - Progettazione e Produzione' },
    { 'school_id': 35, 'value': 'Ingegneria Meccanica per l\'Energia e l\'Ambiente', 'label': 'Ingegneria Meccanica per l\'Energia e l\'Ambiente' },
    { 'school_id': 35, 'value': 'Ingegneria Meccanica per la Progettazione e la Produzione', 'label': 'Ingegneria Meccanica per la Progettazione e la Produzione' },
    { 'school_id': 77, 'value': 'Ingegneria Meccanica per le Risorse Marine', 'label': 'Ingegneria Meccanica per le Risorse Marine' },
    { 'school_id': 70, 'value': 'Ingegneria Meccatronica', 'label': 'Ingegneria Meccatronica' },
    { 'school_id': 212, 'value': 'Ingegneria per l\'Innovazione dei Materiali e del Prodotto', 'label': 'Ingegneria per l\'Innovazione dei Materiali e del Prodotto' },
    { 'school_id': 322, 'value': 'Mechanical Engineering', 'label': 'Mechanical Engineering' },
    { 'school_id': 6871, 'value': 'Mechanical Engineering', 'label': 'Mechanical Engineering' },
    { 'school_id': 95, 'value': 'Mechanical Engineering - Ingegneria Meccanica', 'label': 'Mechanical Engineering - Ingegneria Meccanica' },
    { 'school_id': 146, 'value': 'Tecnologia e Produzione della Carta e del Cartone', 'label': 'Tecnologia e Produzione della Carta e del Cartone' },
    { 'school_id': 109, 'value': 'Yacht Design', 'label': 'Yacht Design' },
    { 'school_id': 212, 'value': 'Civil And Environmental Engineering - Ingegneria Civile e Ambientale', 'label': 'Civil And Environmental Engineering - Ingegneria Civile e Ambientale' },
    { 'school_id': 109, 'value': 'Environmental Engineering', 'label': 'Environmental Engineering' },
    { 'school_id': 6833, 'value': 'Environmental Engineering', 'label': 'Environmental Engineering' },
    { 'school_id': 6837, 'value': 'Environmental Engineering', 'label': 'Environmental Engineering' },
    { 'school_id': 28, 'value': 'Geoengineering', 'label': 'Geoengineering' },
    { 'school_id': 148, 'value': 'Ingegneria Ambientale e per la Sostenibilita\' degli Ambienti di Lavoro', 'label': 'Ingegneria Ambientale e per la Sostenibilita\' degli Ambienti di Lavoro' },
    { 'school_id': 13, 'value': 'Ingegneria dell\'Ambiente per Lo Sviluppo Sostenibile', 'label': 'Ingegneria dell\'Ambiente per Lo Sviluppo Sostenibile' },
    { 'school_id': 69, 'value': 'Ingegneria e Tecnologie Innovative per l\'Ambiente', 'label': 'Ingegneria e Tecnologie Innovative per l\'Ambiente' },
    { 'school_id': 175, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 74, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 20, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 151, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 35, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 38, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 6871, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 478, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 6874, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 414, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 81, 'value': 'Ingegneria per l\'Ambiente e il Territorio', 'label': 'Ingegneria per l\'Ambiente e il Territorio' },
    { 'school_id': 95, 'value': 'Ingegneria per l\'Ambiente e il Territorio - Environmental And Land Planning Engineering', 'label': 'Ingegneria per l\'Ambiente e il Territorio - Environmental And Land Planning Engineering' },
    { 'school_id': 108, 'value': 'Ingegneria per l\'Ambiente ed il Territorio', 'label': 'Ingegneria per l\'Ambiente ed il Territorio' },
    { 'school_id': 131, 'value': 'Ingegneria per l\'Ambiente, il Territorio e la Protezione Civile', 'label': 'Ingegneria per l\'Ambiente, il Territorio e la Protezione Civile' },
    { 'school_id': 28, 'value': 'Ingegneria per la Tutela dell\'Ambiente e del Territorio', 'label': 'Ingegneria per la Tutela dell\'Ambiente e del Territorio' },
    { 'school_id': 18, 'value': 'Petroleum And Mining Engineering (Ingegneria del Petrolio e Mineraria)', 'label': 'Petroleum And Mining Engineering (Ingegneria del Petrolio e Mineraria)' },
    { 'school_id': 13, 'value': 'Classics', 'label': 'Classics' },
    { 'school_id': 244, 'value': 'Culture e Letterature del Mondo Moderno', 'label': 'Culture e Letterature del Mondo Moderno' },
    { 'school_id': 541, 'value': 'Digital Humanities per l\'Italiano', 'label': 'Digital Humanities per l\'Italiano' },
    { 'school_id': 146, 'value': 'Informatica Umanistica', 'label': 'Informatica Umanistica' },
    { 'school_id': 13, 'value': 'Letteratura Musica Spettacolo', 'label': 'Letteratura Musica Spettacolo' },
    { 'school_id': 63, 'value': 'Letteratura, Arte, Musica e Spettacolo', 'label': 'Letteratura, Arte, Musica e Spettacolo' },
    { 'school_id': 244, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 138, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 109, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 157, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 14336, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 79, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 175, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 6830, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 6831, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 6833, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 131, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 74, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 24, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 234, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 28, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 146, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 20, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 151, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 77, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 6851, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 91, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 38, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 108, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 114, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 96, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 72, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 80, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 69, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 128, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 27, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 81, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 115, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 124, 'value': 'Lettere', 'label': 'Lettere' },
    { 'school_id': 55, 'value': 'Lettere Antiche e Moderne, Arti, Comunicazione', 'label': 'Lettere Antiche e Moderne, Arti, Comunicazione' },
    { 'school_id': 13, 'value': 'Lettere Classiche', 'label': 'Lettere Classiche' },
    { 'school_id': 35, 'value': 'Lettere Classiche', 'label': 'Lettere Classiche' },
    { 'school_id': 13, 'value': 'Lettere Moderne', 'label': 'Lettere Moderne' },
    { 'school_id': 35, 'value': 'Lettere Moderne', 'label': 'Lettere Moderne' },
    { 'school_id': 223, 'value': 'Lettere, Arti e Archeologia', 'label': 'Lettere, Arti e Archeologia' },
    { 'school_id': 208, 'value': 'Lettere, Sapere Umanistico e Formazione', 'label': 'Lettere, Sapere Umanistico e Formazione' },
    { 'school_id': 25, 'value': 'Lingua e Cultura Italiana per l\'Insegnamento Agli Stranieri e per la Scuola', 'label': 'Lingua e Cultura Italiana per l\'Insegnamento Agli Stranieri e per la Scuola' },
    { 'school_id': 146, 'value': 'Lingua e Cultura Italiana per Stranieri', 'label': 'Lingua e Cultura Italiana per Stranieri' },
    { 'school_id': 46, 'value': 'Scienze Umanistiche', 'label': 'Scienze Umanistiche' },
    { 'school_id': 23, 'value': 'Scienze Umanistiche. Discipline Letterarie, Artistiche e Filosofiche.', 'label': 'Scienze Umanistiche. Discipline Letterarie, Artistiche e Filosofiche.' },
    { 'school_id': 137, 'value': 'Studi Letterari e Filosofici', 'label': 'Studi Letterari e Filosofici' },
    { 'school_id': 70, 'value': 'Studi Storici e Filologico-Letterari', 'label': 'Studi Storici e Filologico-Letterari' },
    { 'school_id': 169, 'value': 'Studi Umanistici', 'label': 'Studi Umanistici' },
    { 'school_id': 478, 'value': 'Studi Umanistici', 'label': 'Studi Umanistici' },
    { 'school_id': 74, 'value': 'Civilta\' e Lingue Straniere Moderne', 'label': 'Civilta\' e Lingue Straniere Moderne' },
    { 'school_id': 13, 'value': 'Lingue e Civiltà Orientali', 'label': 'Lingue e Civiltà Orientali' },
    { 'school_id': 12, 'value': 'Lingue e Culture Comparate', 'label': 'Lingue e Culture Comparate' },
    { 'school_id': 63, 'value': 'Lingue e Culture Europee e del Resto del Mondo', 'label': 'Lingue e Culture Europee e del Resto del Mondo' },
    { 'school_id': 81, 'value': 'Lingue e Culture Europee Euroamericane ed Orientali', 'label': 'Lingue e Culture Europee Euroamericane ed Orientali' },
    { 'school_id': 109, 'value': 'Lingue e Culture Moderne', 'label': 'Lingue e Culture Moderne' },
    { 'school_id': 175, 'value': 'Lingue e Culture Moderne', 'label': 'Lingue e Culture Moderne' },
    { 'school_id': 23, 'value': 'Lingue e Culture Moderne', 'label': 'Lingue e Culture Moderne' },
    { 'school_id': 46, 'value': 'Lingue e Culture Moderne', 'label': 'Lingue e Culture Moderne' },
    { 'school_id': 110, 'value': 'Lingue e Culture Moderne', 'label': 'Lingue e Culture Moderne' },
    { 'school_id': 6874, 'value': 'Lingue e Culture Moderne', 'label': 'Lingue e Culture Moderne' },
    { 'school_id': 27, 'value': 'Lingue e Culture Moderne', 'label': 'Lingue e Culture Moderne' },
    { 'school_id': 12, 'value': 'Lingue e Culture Orientali e Africane', 'label': 'Lingue e Culture Orientali e Africane' },
    { 'school_id': 124, 'value': 'Lingue e Culture per la Mediazione Linguistica', 'label': 'Lingue e Culture per la Mediazione Linguistica' },
    { 'school_id': 20, 'value': 'Lingue e Culture Straniere', 'label': 'Lingue e Culture Straniere' },
    { 'school_id': 38, 'value': 'Lingue e Culture Straniere', 'label': 'Lingue e Culture Straniere' },
    { 'school_id': 96, 'value': 'Lingue e Culture Straniere', 'label': 'Lingue e Culture Straniere' },
    { 'school_id': 234, 'value': 'Lingue e Culture Straniere Occidentali e Orientali', 'label': 'Lingue e Culture Straniere Occidentali e Orientali' },
    { 'school_id': 69, 'value': 'Lingue e Letterature - Studi Interculturali', 'label': 'Lingue e Letterature - Studi Interculturali' },
    { 'school_id': 244, 'value': 'Lingue e Letterature Moderne', 'label': 'Lingue e Letterature Moderne' },
    { 'school_id': 223, 'value': 'Lingue e Letterature Moderne', 'label': 'Lingue e Letterature Moderne' },
    { 'school_id': 151, 'value': 'Lingue e Letterature Moderne', 'label': 'Lingue e Letterature Moderne' },
    { 'school_id': 322, 'value': 'Lingue e Letterature Moderne', 'label': 'Lingue e Letterature Moderne' },
    { 'school_id': 6830, 'value': 'Lingue e Letterature per l\'Editoria e i Media Digitali', 'label': 'Lingue e Letterature per l\'Editoria e i Media Digitali' },
    { 'school_id': 77, 'value': 'Lingue e Letterature per la Comunicazione Interculturale', 'label': 'Lingue e Letterature per la Comunicazione Interculturale' },
    { 'school_id': 157, 'value': 'Lingue e Letterature Straniere', 'label': 'Lingue e Letterature Straniere' },
    { 'school_id': 6830, 'value': 'Lingue e Letterature Straniere', 'label': 'Lingue e Letterature Straniere' },
    { 'school_id': 131, 'value': 'Lingue e Letterature Straniere', 'label': 'Lingue e Letterature Straniere' },
    { 'school_id': 55, 'value': 'Lingue e Letterature Straniere', 'label': 'Lingue e Letterature Straniere' },
    { 'school_id': 24, 'value': 'Lingue e Letterature Straniere', 'label': 'Lingue e Letterature Straniere' },
    { 'school_id': 146, 'value': 'Lingue e Letterature Straniere', 'label': 'Lingue e Letterature Straniere' },
    { 'school_id': 114, 'value': 'Lingue e Letterature Straniere', 'label': 'Lingue e Letterature Straniere' },
    { 'school_id': 79, 'value': 'Lingue e Letterature Straniere Moderne', 'label': 'Lingue e Letterature Straniere Moderne' },
    { 'school_id': 70, 'value': 'Lingue Moderne', 'label': 'Lingue Moderne' },
    { 'school_id': 151, 'value': 'Lingue nella Società dell\'Informazione', 'label': 'Lingue nella Società dell\'Informazione' },
    { 'school_id': 137, 'value': 'Lingue per la Comunicazione Interculturale e d\'Impresa', 'label': 'Lingue per la Comunicazione Interculturale e d\'Impresa' },
    { 'school_id': 138, 'value': 'Lingue Straniere Moderne', 'label': 'Lingue Straniere Moderne' },
    { 'school_id': 6831, 'value': 'Lingue, Civiltà e Scienze del Linguaggio', 'label': 'Lingue, Civiltà e Scienze del Linguaggio' },
    { 'school_id': 72, 'value': 'Lingue, Culture e Letterature Moderne', 'label': 'Lingue, Culture e Letterature Moderne' },
    { 'school_id': 35, 'value': 'Lingue, Culture e Letterature Moderne Europee', 'label': 'Lingue, Culture e Letterature Moderne Europee' },
    { 'school_id': 80, 'value': 'Lingue, Culture e Letterature Straniere', 'label': 'Lingue, Culture e Letterature Straniere' },
    { 'school_id': 6831, 'value': 'Lingue, Culture e Società dell\'Asia e dell\'Africa Mediterranea', 'label': 'Lingue, Culture e Società dell\'Asia e dell\'Africa Mediterranea' },
    { 'school_id': 13, 'value': 'Lingue, Culture, Letterature, Traduzione', 'label': 'Lingue, Culture, Letterature, Traduzione' },
    { 'school_id': 12, 'value': 'Lingue, Letterature e Culture dell\'Europa e delle Americhe', 'label': 'Lingue, Letterature e Culture dell\'Europa e delle Americhe' },
    { 'school_id': 6833, 'value': 'Lingue, Letterature e Mediazione Culturale', 'label': 'Lingue, Letterature e Mediazione Culturale' },
    { 'school_id': 28, 'value': 'Lingue, Letterature e Studi Interculturali', 'label': 'Lingue, Letterature e Studi Interculturali' },
    { 'school_id': 128, 'value': 'Lingue, Letterature Straniere e Tecniche della Mediazione Linguistica', 'label': 'Lingue, Letterature Straniere e Tecniche della Mediazione Linguistica' },
    { 'school_id': 24, 'value': 'Lingue, Mercati e Culture dell\'Asia e dell\'Africa Mediterranea', 'label': 'Lingue, Mercati e Culture dell\'Asia e dell\'Africa Mediterranea' },
    { 'school_id': 6831, 'value': 'Lingue e Civiltà dell\'Asia e dell\'Africa Mediterranea', 'label': 'Lingue e Civiltà dell\'Asia e dell\'Africa Mediterranea' },
    { 'school_id': 28, 'value': 'Lingue e Civiltà dell\'Oriente Antico e Moderno', 'label': 'Lingue e Civiltà dell\'Oriente Antico e Moderno' },
    { 'school_id': 12, 'value': 'Lingue e Culture dell\'Asia e dell\'Africa', 'label': 'Lingue e Culture dell\'Asia e dell\'Africa' },
    { 'school_id': 6830, 'value': 'Comparative European And Non-European Languages And Literatures', 'label': 'Comparative European And Non-European Languages And Literatures' },
    { 'school_id': 13, 'value': 'English And Anglo-American Studies', 'label': 'English And Anglo-American Studies' },
    { 'school_id': 79, 'value': 'Intercultural Studies in Languages And Literatures', 'label': 'Intercultural Studies in Languages And Literatures' },
    { 'school_id': 12, 'value': 'Letterature e Culture Comparate', 'label': 'Letterature e Culture Comparate' },
    { 'school_id': 70, 'value': 'Letterature Euroamericane, Traduzione e Critica Letteraria', 'label': 'Letterature Euroamericane, Traduzione e Critica Letteraria' },
    { 'school_id': 175, 'value': 'Letterature Europee e Americane', 'label': 'Letterature Europee e Americane' },
    { 'school_id': 24, 'value': 'Letterature Moderne, Comparate e Postcoloniali', 'label': 'Letterature Moderne, Comparate e Postcoloniali' },
    { 'school_id': 46, 'value': 'Lingue e Culture per la Comunicazione Internazionale', 'label': 'Lingue e Culture per la Comunicazione Internazionale' },
    { 'school_id': 407, 'value': 'Lingue e Culture per la Promozione delle Aree Montane', 'label': 'Lingue e Culture per la Promozione delle Aree Montane' },
    { 'school_id': 351, 'value': 'Lingue e Didattica Innovativa', 'label': 'Lingue e Didattica Innovativa' },
    { 'school_id': 81, 'value': 'Lingue e Letterature Comparate', 'label': 'Lingue e Letterature Comparate' },
    { 'school_id': 6833, 'value': 'Lingue e Letterature Europee e Americane', 'label': 'Lingue e Letterature Europee e Americane' },
    { 'school_id': 28, 'value': 'Lingue e Letterature Europee e Americane', 'label': 'Lingue e Letterature Europee e Americane' },
    { 'school_id': 151, 'value': 'Lingue e Letterature Europee e Americane', 'label': 'Lingue e Letterature Europee e Americane' },
    { 'school_id': 12, 'value': 'Lingue e Letterature Europee e Americane', 'label': 'Lingue e Letterature Europee e Americane' },
    { 'school_id': 157, 'value': 'Lingue e Letterature Europee ed Extraeuropee', 'label': 'Lingue e Letterature Europee ed Extraeuropee' },
    { 'school_id': 131, 'value': 'Lingue e Letterature Europee ed Extraeuropee', 'label': 'Lingue e Letterature Europee ed Extraeuropee' },
    { 'school_id': 6831, 'value': 'Lingue e Letterature Europee, Americane e Postcoloniali', 'label': 'Lingue e Letterature Europee, Americane e Postcoloniali' },
    { 'school_id': 38, 'value': 'Lingue e Letterature Moderne', 'label': 'Lingue e Letterature Moderne' },
    { 'school_id': 72, 'value': 'Lingue e Letterature Moderne', 'label': 'Lingue e Letterature Moderne' },
    { 'school_id': 6874, 'value': 'Lingue e Letterature Moderne', 'label': 'Lingue e Letterature Moderne' },
    { 'school_id': 63, 'value': 'Lingue e Letterature Moderne e Traduzione Interculturale', 'label': 'Lingue e Letterature Moderne e Traduzione Interculturale' },
    { 'school_id': 35, 'value': 'Lingue e Letterature Moderne Europee', 'label': 'Lingue e Letterature Moderne Europee' },
    { 'school_id': 124, 'value': 'Lingue e Letterature Moderne Europee e Americane', 'label': 'Lingue e Letterature Moderne Europee e Americane' },
    { 'school_id': 109, 'value': 'Lingue e Letterature Moderne per i Servizi Culturali', 'label': 'Lingue e Letterature Moderne per i Servizi Culturali' },
    { 'school_id': 322, 'value': 'Lingue e Letterature Moderne per i Servizi Interculturali, la Mediazione Linguistica e la Cooperazione Internazionale', 'label': 'Lingue e Letterature Moderne per i Servizi Interculturali, la Mediazione Linguistica e la Cooperazione Internazionale' },
    { 'school_id': 77, 'value': 'Lingue e Letterature per la Didattica e la Traduzione', 'label': 'Lingue e Letterature per la Didattica e la Traduzione' },
    { 'school_id': 223, 'value': 'Lingue e Letterature Straniere', 'label': 'Lingue e Letterature Straniere' },
    { 'school_id': 69, 'value': 'Lingue e Letterature: Interculturalità e Didattica', 'label': 'Lingue e Letterature: Interculturalità e Didattica' },
    { 'school_id': 23, 'value': 'Lingue Moderne e Interculturalita\'', 'label': 'Lingue Moderne e Interculturalita\'' },
    { 'school_id': 80, 'value': 'Lingue Moderne, Letterature e Traduzione', 'label': 'Lingue Moderne, Letterature e Traduzione' },
    { 'school_id': 128, 'value': 'Lingue Moderne: Letterature e Traduzione', 'label': 'Lingue Moderne: Letterature e Traduzione' },
    { 'school_id': 234, 'value': 'Lingue, Culture e Traduzione Letteraria', 'label': 'Lingue, Culture e Traduzione Letteraria' },
    { 'school_id': 61, 'value': 'Lingue, Culture, Comunicazione', 'label': 'Lingue, Culture, Comunicazione' },
    { 'school_id': 138, 'value': 'Lingue, Culture, Turismo', 'label': 'Lingue, Culture, Turismo' },
    { 'school_id': 20, 'value': 'Lingue, Letterature Comparate e Traduzione Interculturale', 'label': 'Lingue, Letterature Comparate e Traduzione Interculturale' },
    { 'school_id': 114, 'value': 'Lingue, Letterature e Culture Moderne', 'label': 'Lingue, Letterature e Culture Moderne' },
    { 'school_id': 14336, 'value': 'Lingue, Letterature e Culture Straniere', 'label': 'Lingue, Letterature e Culture Straniere' },
    { 'school_id': 146, 'value': 'Lingue, Letterature e Filologie Euro - Americane', 'label': 'Lingue, Letterature e Filologie Euro - Americane' },
    { 'school_id': 55, 'value': 'Lingue, Letterature Straniere e Turismo Culturale', 'label': 'Lingue, Letterature Straniere e Turismo Culturale' },
    { 'school_id': 13, 'value': 'Scienze Linguistiche, Letterarie e della Traduzione', 'label': 'Scienze Linguistiche, Letterarie e della Traduzione' },
    { 'school_id': 69, 'value': 'Transnational German Studies', 'label': 'Transnational German Studies' },
    { 'school_id': 244, 'value': 'Comunicazione Internazionale per il Turismo', 'label': 'Comunicazione Internazionale per il Turismo' },
    { 'school_id': 6831, 'value': 'Language And Management To China', 'label': 'Language And Management To China' },
    { 'school_id': 24, 'value': 'Language, Society And Communication', 'label': 'Language, Society And Communication' },
    { 'school_id': 61, 'value': 'Languages For Communication in International Enterprises And Organizations - Lingue per la Comunicazione nell\'Impresa e nelle Organizzazioni Internazionali', 'label': 'Languages For Communication in International Enterprises And Organizations - Lingue per la Comunicazione nell\'Impresa e nelle Organizzazioni Internazionali' },
    { 'school_id': 244, 'value': 'Lingue dell\'Asia e dell\'Africa per la Comunicazione e la Cooperazione Internazionale', 'label': 'Lingue dell\'Asia e dell\'Africa per la Comunicazione e la Cooperazione Internazionale' },
    { 'school_id': 12, 'value': 'Lingue e Comunicazione Interculturale in Area Euromediterranea', 'label': 'Lingue e Comunicazione Interculturale in Area Euromediterranea' },
    { 'school_id': 157, 'value': 'Lingue e Culture per la Comunicazione e la Cooperazione Internazionale', 'label': 'Lingue e Culture per la Comunicazione e la Cooperazione Internazionale' },
    { 'school_id': 108, 'value': 'Lingue e Culture per la Comunicazione e la Cooperazione Internazionale', 'label': 'Lingue e Culture per la Comunicazione e la Cooperazione Internazionale' },
    { 'school_id': 115, 'value': 'Lingue e Letterature Straniere per la Mediazione Culturale e la Valorizzazione del Territorio', 'label': 'Lingue e Letterature Straniere per la Mediazione Culturale e la Valorizzazione del Territorio' },
    { 'school_id': 69, 'value': 'Lingue Moderne e Traduzione per le Relazioni Internazionali', 'label': 'Lingue Moderne e Traduzione per le Relazioni Internazionali' },
    { 'school_id': 148, 'value': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale', 'label': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale' },
    { 'school_id': 79, 'value': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale', 'label': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale' },
    { 'school_id': 6833, 'value': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale', 'label': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale' },
    { 'school_id': 234, 'value': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale', 'label': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale' },
    { 'school_id': 110, 'value': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale', 'label': 'Lingue Moderne per la Comunicazione e la Cooperazione Internazionale' },
    { 'school_id': 77, 'value': 'Lingue Moderne per la Comunicazione Internazionale', 'label': 'Lingue Moderne per la Comunicazione Internazionale' },
    { 'school_id': 6851, 'value': 'Lingue Moderne per la Comunicazione Internazionale', 'label': 'Lingue Moderne per la Comunicazione Internazionale' },
    { 'school_id': 27, 'value': 'Lingue per la Comunicazione Interculturale', 'label': 'Lingue per la Comunicazione Interculturale' },
    { 'school_id': 6830, 'value': 'Lingue per la Comunicazione Turistica e Commerciale', 'label': 'Lingue per la Comunicazione Turistica e Commerciale' },
    { 'school_id': 81, 'value': 'Lingue per la Cooperazione Internazionale', 'label': 'Lingue per la Cooperazione Internazionale' },
    { 'school_id': 244, 'value': 'Lingue per Lo Sviluppo Turistico del Territorio', 'label': 'Lingue per Lo Sviluppo Turistico del Territorio' },
    { 'school_id': 114, 'value': 'Lingue Straniere per l\'Impresa e la Cooperazione Internazionale', 'label': 'Lingue Straniere per l\'Impresa e la Cooperazione Internazionale' },
    { 'school_id': 244, 'value': 'Lingue Straniere per la Comunicazione Internazionale', 'label': 'Lingue Straniere per la Comunicazione Internazionale' },
    { 'school_id': 6831, 'value': 'Lingue, Economie e Istituzioni dell\'Asia e dell\'Africa Mediterranea', 'label': 'Lingue, Economie e Istituzioni dell\'Asia e dell\'Africa Mediterranea' },
    { 'school_id': 14336, 'value': 'Scienze Linguistiche', 'label': 'Scienze Linguistiche' },
    { 'school_id': 77, 'value': 'Didattica dell\'Italiano Come Lingua Seconda (Dil2)', 'label': 'Didattica dell\'Italiano Come Lingua Seconda (Dil2)' },
    { 'school_id': 137, 'value': 'Language And Mind: Linguistics And Cognitive Studies - Mente e Linguaggio: Linguistica e Studi Cognitivi', 'label': 'Language And Mind: Linguistics And Cognitive Studies - Mente e Linguaggio: Linguistica e Studi Cognitivi' },
    { 'school_id': 151, 'value': 'Lingua e Cultura Italiana a Stranieri per l\'Accoglienza e l\'Internazionalizzazione', 'label': 'Lingua e Cultura Italiana a Stranieri per l\'Accoglienza e l\'Internazionalizzazione' },
    { 'school_id': 128, 'value': 'Lingua e Cultura Italiana per Stranieri', 'label': 'Lingua e Cultura Italiana per Stranieri' },
    { 'school_id': 24, 'value': 'Lingua e Cultura Italiane per Stranieri', 'label': 'Lingua e Cultura Italiane per Stranieri' },
    { 'school_id': 6833, 'value': 'Linguistica', 'label': 'Linguistica' },
    { 'school_id': 13, 'value': 'Linguistica', 'label': 'Linguistica' },
    { 'school_id': 273, 'value': 'Linguistica Applicata', 'label': 'Linguistica Applicata' },
    { 'school_id': 146, 'value': 'Linguistica e Traduzione', 'label': 'Linguistica e Traduzione' },
    { 'school_id': 208, 'value': 'Linguistica Moderna', 'label': 'Linguistica Moderna' },
    { 'school_id': 175, 'value': 'Linguistica Teorica, Applicata e delle Lingue Moderne', 'label': 'Linguistica Teorica, Applicata e delle Lingue Moderne' },
    { 'school_id': 6830, 'value': 'Linguistics', 'label': 'Linguistics' },
    { 'school_id': 6831, 'value': 'Scienze del Linguaggio', 'label': 'Scienze del Linguaggio' },
    { 'school_id': 244, 'value': 'Scienze Linguistiche', 'label': 'Scienze Linguistiche' },
    { 'school_id': 25, 'value': 'Scienze Linguistiche e Comunicazione Interculturale', 'label': 'Scienze Linguistiche e Comunicazione Interculturale' },
    { 'school_id': 81, 'value': 'Scienze Linguistiche per l\'Intercultura e la Formazione', 'label': 'Scienze Linguistiche per l\'Intercultura e la Formazione' },
    { 'school_id': 244, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 138, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 109, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 148, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 63, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 157, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 14332, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 14336, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 66, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 79, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 212, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 175, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 70, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 6830, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 6833, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 131, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 55, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 74, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 61, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 24, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 223, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 23, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 234, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 60, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 28, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 458, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 146, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 137, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 20, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 46, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 13, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 151, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 59, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 6848, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 77, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 6851, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 389, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 264, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 169, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 36, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 322, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 6859, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 6860, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 35, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 82, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 110, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 91, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 208, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 38, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 228, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 6869, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 198, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 96, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 72, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 382, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 80, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 6874, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 90, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 414, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 69, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 128, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 27, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 81, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 115, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 124, 'value': 'Giurisprudenza', 'label': 'Giurisprudenza' },
    { 'school_id': 28, 'value': 'Giurisprudenza Italiana e Tedesca', 'label': 'Giurisprudenza Italiana e Tedesca' },
    { 'school_id': 137, 'value': 'Applied Mathematics - Matematica Applicata', 'label': 'Applied Mathematics - Matematica Applicata' },
    { 'school_id': 35, 'value': 'Corso di Laurea Magistrale in Matematica', 'label': 'Corso di Laurea Magistrale in Matematica' },
    { 'school_id': 244, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 109, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 148, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 157, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 14336, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 66, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 175, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 70, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 6833, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 131, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 55, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 74, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 61, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 24, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 223, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 28, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 146, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 20, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 13, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 77, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 91, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 38, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 108, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 72, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 80, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 478, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 6874, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 69, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 128, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 81, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 124, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 13, 'value': 'Matematica Applicata', 'label': 'Matematica Applicata' },
    { 'school_id': 60, 'value': 'Matematica e Applicazioni - Mathematics And Applications', 'label': 'Matematica e Applicazioni - Mathematics And Applications' },
    { 'school_id': 151, 'value': 'Matematica Pura e Applicata', 'label': 'Matematica Pura e Applicata' },
    { 'school_id': 6830, 'value': 'Mathematics', 'label': 'Mathematics' },
    { 'school_id': 77, 'value': 'Scienze Computazionali', 'label': 'Scienze Computazionali' },
    { 'school_id': 244, 'value': 'Stochastics And Data Science', 'label': 'Stochastics And Data Science' },
    { 'school_id': 55, 'value': 'Comunicazione Interlinguistica Applicata', 'label': 'Comunicazione Interlinguistica Applicata' },
    { 'school_id': 55, 'value': 'Comunicazione Interlinguistica Applicata alle Professioni Giuridiche', 'label': 'Comunicazione Interlinguistica Applicata alle Professioni Giuridiche' },
    { 'school_id': 234, 'value': 'Discipline della Mediazione Linguistica', 'label': 'Discipline della Mediazione Linguistica' },
    { 'school_id': 14, 'value': 'Interpretariato e Comunicazione', 'label': 'Interpretariato e Comunicazione' },
    { 'school_id': 407, 'value': 'Lingue e Comunicazione per l\'Impresa e il Turismo', 'label': 'Lingue e Comunicazione per l\'Impresa e il Turismo' },
    { 'school_id': 244, 'value': 'Lingue e Culture dell\'Asia e dell\'Africa', 'label': 'Lingue e Culture dell\'Asia e dell\'Africa' },
    { 'school_id': 61, 'value': 'Lingue e Culture Europee', 'label': 'Lingue e Culture Europee' },
    { 'school_id': 6830, 'value': 'Lingue e Culture per il Turismo e il Commercio Internazionale', 'label': 'Lingue e Culture per il Turismo e il Commercio Internazionale' },
    { 'school_id': 72, 'value': 'Lingue e Culture per il Turismo e la Mediazione Internazionale', 'label': 'Lingue e Culture per il Turismo e la Mediazione Internazionale' },
    { 'school_id': 77, 'value': 'Lingue e Mediazione Linguistico-Culturale', 'label': 'Lingue e Mediazione Linguistico-Culturale' },
    { 'school_id': 447, 'value': 'Lingue e Mercati', 'label': 'Lingue e Mercati' },
    { 'school_id': 351, 'value': 'Lingue per l\'Interpretariato e la Traduzione', 'label': 'Lingue per l\'Interpretariato e la Traduzione' },
    { 'school_id': 131, 'value': 'Mediazione Culturale', 'label': 'Mediazione Culturale' },
    { 'school_id': 114, 'value': 'Mediazione Linguistica e Comunicazione Interculturale', 'label': 'Mediazione Linguistica e Comunicazione Interculturale' },
    { 'school_id': 6831, 'value': 'Mediazione Linguistica e Culturale', 'label': 'Mediazione Linguistica e Culturale' },
    { 'school_id': 25, 'value': 'Mediazione Linguistica e Culturale', 'label': 'Mediazione Linguistica e Culturale' },
    { 'school_id': 59, 'value': 'Mediazione Linguistica e Culturale', 'label': 'Mediazione Linguistica e Culturale' },
    { 'school_id': 12, 'value': 'Mediazione Linguistica e Culturale', 'label': 'Mediazione Linguistica e Culturale' },
    { 'school_id': 108, 'value': 'Mediazione Linguistica e Culturale', 'label': 'Mediazione Linguistica e Culturale' },
    { 'school_id': 115, 'value': 'Mediazione Linguistica e Culturale', 'label': 'Mediazione Linguistica e Culturale' },
    { 'school_id': 157, 'value': 'Mediazione Linguistica e Culturale Applicata all\'Ambito Economico, Giuridico e Sociale', 'label': 'Mediazione Linguistica e Culturale Applicata all\'Ambito Economico, Giuridico e Sociale' },
    { 'school_id': 13, 'value': 'Mediazione Linguistica e Interculturale', 'label': 'Mediazione Linguistica e Interculturale' },
    { 'school_id': 81, 'value': 'Mediazione Linguistica e Interculturale', 'label': 'Mediazione Linguistica e Interculturale' },
    { 'school_id': 24, 'value': 'Mediazione Linguistica Interculturale', 'label': 'Mediazione Linguistica Interculturale' },
    { 'school_id': 80, 'value': 'Scienza e Tecnica della Mediazione Linguistica', 'label': 'Scienza e Tecnica della Mediazione Linguistica' },
    { 'school_id': 148, 'value': 'Scienze della Mediazione Interlinguistica e Interculturale', 'label': 'Scienze della Mediazione Interlinguistica e Interculturale' },
    { 'school_id': 244, 'value': 'Scienze della Mediazione Linguistica', 'label': 'Scienze della Mediazione Linguistica' },
    { 'school_id': 14336, 'value': 'Scienze Linguistiche per le Relazioni Internazionali', 'label': 'Scienze Linguistiche per le Relazioni Internazionali' },
    { 'school_id': 109, 'value': 'Teorie e Tecniche della Mediazione Interlinguistica', 'label': 'Teorie e Tecniche della Mediazione Interlinguistica' },
    { 'school_id': 244, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 138, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 109, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 148, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 157, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 14336, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 6825, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 66, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 6827, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 212, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 175, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 70, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 6830, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 6833, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 131, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 55, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 74, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 61, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 24, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 223, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 6837, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 28, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 146, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 137, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 20, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 13, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 151, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 600, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 473, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 35, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 91, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 38, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 108, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 114, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 198, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 96, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 72, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 90, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 69, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 128, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 27, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 81, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 115, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 124, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
    { 'school_id': 13, 'value': 'Medicina e Chirurgia \'A\'', 'label': 'Medicina e Chirurgia \'A\'' },
    { 'school_id': 13, 'value': 'Medicina e Chirurgia \'B\'', 'label': 'Medicina e Chirurgia \'B\'' },
    { 'school_id': 13, 'value': 'Medicina e Chirurgia \'C\'', 'label': 'Medicina e Chirurgia \'C\'' },
    { 'school_id': 13, 'value': 'Medicina e Chirurgia \'D\'', 'label': 'Medicina e Chirurgia \'D\'' },
    { 'school_id': 13, 'value': 'Medicina e Chirurgia \'E\'', 'label': 'Medicina e Chirurgia \'E\'' },
    { 'school_id': 13, 'value': 'Medicina e Chirurgia Ht', 'label': 'Medicina e Chirurgia Ht' },
    { 'school_id': 244, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 14336, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 6825, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 66, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 175, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 6833, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 24, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 13, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 151, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 600, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 128, 'value': 'Medicine And Surgery', 'label': 'Medicine And Surgery' },
    { 'school_id': 244, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 157, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 6833, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 74, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 24, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 60, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 146, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 35, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 228, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 72, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 128, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 115, 'value': 'Medicina Veterinaria', 'label': 'Medicina Veterinaria' },
    { 'school_id': 20, 'value': 'Veterinary Medicine', 'label': 'Veterinary Medicine' },
    { 'school_id': 6831, 'value': 'Digital And Public Humanities', 'label': 'Digital And Public Humanities' },
    { 'school_id': 80, 'value': 'Digital Humanities', 'label': 'Digital Humanities' },
    { 'school_id': 24, 'value': 'Digital Humanities And Digital Knowledge', 'label': 'Digital Humanities And Digital Knowledge' },
    { 'school_id': 6874, 'value': 'Gestione e Conservazione dei Documenti Digitali', 'label': 'Gestione e Conservazione dei Documenti Digitali' },
    { 'school_id': 81, 'value': 'Scienze del Testo per le Professioni Digitali', 'label': 'Scienze del Testo per le Professioni Digitali' },
    { 'school_id': 55, 'value': 'Data Science And Scientific Computing', 'label': 'Data Science And Scientific Computing' },
    { 'school_id': 95, 'value': 'Engineering Physics - Ingegneria Fisica', 'label': 'Engineering Physics - Ingegneria Fisica' },
    { 'school_id': 18, 'value': 'Ingegneria Matematica', 'label': 'Ingegneria Matematica' },
    { 'school_id': 35, 'value': 'Ingegneria Matematica', 'label': 'Ingegneria Matematica' },
    { 'school_id': 108, 'value': 'Ingegneria Matematica', 'label': 'Ingegneria Matematica' },
    { 'school_id': 6833, 'value': 'Mathematical Engineering', 'label': 'Mathematical Engineering' },
    { 'school_id': 95, 'value': 'Mathematical Engineering - Ingegneria Matematica', 'label': 'Mathematical Engineering - Ingegneria Matematica' },
    { 'school_id': 108, 'value': 'Modellistica Matematica', 'label': 'Modellistica Matematica' },
    { 'school_id': 18, 'value': 'Physics Of Complex Systems', 'label': 'Physics Of Complex Systems' },
    { 'school_id': 24, 'value': 'Discipline della Musica e del Teatro', 'label': 'Discipline della Musica e del Teatro' },
    { 'school_id': 151, 'value': 'Musica e Spettacolo', 'label': 'Musica e Spettacolo' },
    { 'school_id': 273, 'value': 'Musicologia', 'label': 'Musicologia' },
    { 'school_id': 13, 'value': 'Musicologia', 'label': 'Musicologia' },
    { 'school_id': 69, 'value': 'Musicologia e Scienze dello Spettacolo', 'label': 'Musicologia e Scienze dello Spettacolo' },
    { 'school_id': 157, 'value': 'Scienze della Musica e dello Spettacolo', 'label': 'Scienze della Musica e dello Spettacolo' },
    { 'school_id': 35, 'value': 'Corso di Laurea Magistrale in Odontoiatria e Protesi Dentaria', 'label': 'Corso di Laurea Magistrale in Odontoiatria e Protesi Dentaria' },
    { 'school_id': 137, 'value': 'Dentistry And Dental Prosthodontics', 'label': 'Dentistry And Dental Prosthodontics' },
    { 'school_id': 244, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 109, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 148, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 157, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 14336, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 6825, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 66, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 212, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 175, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 6830, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 6833, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 55, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 74, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 61, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 24, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 223, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 6837, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 28, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 146, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 20, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 13, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 151, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 473, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 91, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 38, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 108, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 114, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 96, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 72, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 90, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 69, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 128, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 81, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 115, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 124, 'value': 'Odontoiatria e Protesi Dentaria', 'label': 'Odontoiatria e Protesi Dentaria' },
    { 'school_id': 24, 'value': 'Management delle Attività Motorie e Sportive', 'label': 'Management delle Attività Motorie e Sportive' },
    { 'school_id': 85, 'value': 'Management dello Sport', 'label': 'Management dello Sport' },
    { 'school_id': 208, 'value': 'Management dello Sport e delle Attività Motorie', 'label': 'Management dello Sport e delle Attività Motorie' },
    { 'school_id': 69, 'value': 'Management dello Sport e delle Attività Motorie', 'label': 'Management dello Sport e delle Attività Motorie' },
    { 'school_id': 82, 'value': 'Scienze e Management dello Sport e delle Attività Motorie', 'label': 'Scienze e Management dello Sport e delle Attività Motorie' },
    { 'school_id': 24, 'value': 'Wellness Culture: Sport, Health And Tourism', 'label': 'Wellness Culture: Sport, Health And Tourism' },
    { 'school_id': 79, 'value': 'Geourbanistica. Analisi e Pianificazione Territoriale, Urbana, Ambientale e Valorizzazione del Paesaggio', 'label': 'Geourbanistica. Analisi e Pianificazione Territoriale, Urbana, Ambientale e Valorizzazione del Paesaggio' },
    { 'school_id': 6832, 'value': 'Pianificazione e Politiche per la Città, il Territorio e l\'Ambiente', 'label': 'Pianificazione e Politiche per la Città, il Territorio e l\'Ambiente' },
    { 'school_id': 115, 'value': 'Pianificazione e Politiche per la Città, l\'Ambiente e il Paesaggio', 'label': 'Pianificazione e Politiche per la Città, l\'Ambiente e il Paesaggio' },
    { 'school_id': 28, 'value': 'Pianificazione e Progettazione della Città e del Territorio', 'label': 'Pianificazione e Progettazione della Città e del Territorio' },
    { 'school_id': 69, 'value': 'Pianificazione Territoriale, Urbanistica e Ambientale', 'label': 'Pianificazione Territoriale, Urbanistica e Ambientale' },
    { 'school_id': 18, 'value': 'Pianificazione Territoriale, Urbanistica e Paesaggistico-Ambientale', 'label': 'Pianificazione Territoriale, Urbanistica e Paesaggistico-Ambientale' },
    { 'school_id': 35, 'value': 'Pianificazione Territoriale, Urbanistica e Paesaggistico-Ambientale', 'label': 'Pianificazione Territoriale, Urbanistica e Paesaggistico-Ambientale' },
    { 'school_id': 95, 'value': 'Urban Planning And Policy Design - Pianificazione Urbana e Politiche Territoriali', 'label': 'Urban Planning And Policy Design - Pianificazione Urbana e Politiche Territoriali' },
    { 'school_id': 157, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 212, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 6833, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 6837, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 28, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 13, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 114, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 72, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 90, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 69, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 124, 'value': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)', 'label': 'Assistenza Sanitaria (Abilitante alla Professione Sanitaria di Assistente Sanitario)' },
    { 'school_id': 244, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 109, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 157, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 14336, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 212, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 175, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 6830, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 6833, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 131, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 74, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 24, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 6837, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 28, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 146, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 137, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 20, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 13, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 151, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 35, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 91, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 108, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 114, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 198, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 72, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 69, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 128, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 124, 'value': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)', 'label': 'Tecniche della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro (Abilitante alla Professione Sanitaria di Tecnico della Prevenzione nell\'Ambiente e nei Luoghi di Lavoro)' },
    { 'school_id': 244, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 109, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 148, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 157, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 212, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 6833, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 131, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 24, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 223, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 6837, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 28, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 151, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 72, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 124, 'value': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)', 'label': 'Educazione Professionale (Abilitante alla Professione Sanitaria di Educatore Professionale)' },
    { 'school_id': 244, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 138, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 109, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 148, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 157, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 14336, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 6825, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 66, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 6827, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 212, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 175, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 6830, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 6833, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 131, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 55, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 74, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 61, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 24, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 223, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 6837, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 28, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 146, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 137, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 20, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 13, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 151, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 600, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 473, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 35, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 91, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 38, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 108, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 114, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 96, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 72, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 90, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 69, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 128, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 81, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 124, 'value': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)', 'label': 'Fisioterapia (Abilitante alla Professione Sanitaria di Fisioterapista)' },
    { 'school_id': 244, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 109, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 157, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 14336, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 175, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 6830, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 6833, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 55, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 74, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 61, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 24, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 223, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 6837, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 28, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 146, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 137, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 20, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 13, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 151, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 35, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 91, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 108, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 114, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 72, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 90, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 69, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 128, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 81, 'value': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)', 'label': 'Logopedia (Abilitante alla Professione Sanitaria di Logopedista)' },
    { 'school_id': 244, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 109, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 157, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 6833, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 74, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 223, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 137, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 13, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 151, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 35, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 91, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 108, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 114, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 72, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 90, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 69, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 128, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 81, 'value': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)', 'label': 'Ortottica ed Assistenza Oftalmologica (Abilitante alla Professione Sanitaria di Ortottista ed Assistente di Oftalmologia)' },
    { 'school_id': 109, 'value': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)', 'label': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)' },
    { 'school_id': 157, 'value': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)', 'label': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)' },
    { 'school_id': 24, 'value': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)', 'label': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)' },
    { 'school_id': 146, 'value': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)', 'label': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)' },
    { 'school_id': 13, 'value': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)', 'label': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)' },
    { 'school_id': 151, 'value': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)', 'label': 'Podologia (Abilitante alla Professione Sanitaria di Podologo)' },
    { 'school_id': 244, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 109, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 157, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 212, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 175, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 6830, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 6833, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 55, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 61, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 223, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 146, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 13, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 151, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 91, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 108, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 72, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 69, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 128, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 81, 'value': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)', 'label': 'Tecnica della Riabilitazione Psichiatrica (Abilitante alla Professione Sanitaria di Tecnico della Riabilitazione Psichiatrica)' },
    { 'school_id': 244, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 109, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 157, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 66, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 175, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 6833, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 146, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 13, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 151, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 91, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 108, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 128, 'value': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)', 'label': 'Terapia della Neuro e Psicomotricità dell\'Età Evolutiva (Abilitante alla Professione Sanitaria di Terapista della Neuro e Psicomotricità dell\'Età Evolutiva)' },
    { 'school_id': 157, 'value': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)', 'label': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)' },
    { 'school_id': 14336, 'value': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)', 'label': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)' },
    { 'school_id': 175, 'value': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)', 'label': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)' },
    { 'school_id': 6833, 'value': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)', 'label': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)' },
    { 'school_id': 61, 'value': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)', 'label': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)' },
    { 'school_id': 13, 'value': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)', 'label': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)' },
    { 'school_id': 114, 'value': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)', 'label': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)' },
    { 'school_id': 81, 'value': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)', 'label': 'Terapia Occupazionale (Abilitante alla Professione Sanitaria di Terapista Occupazionale)' },
    { 'school_id': 244, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 109, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 157, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 14336, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 212, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 175, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 6833, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 61, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 24, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 223, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 6837, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 28, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 146, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 137, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 13, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 151, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 35, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 114, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 96, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 72, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 90, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 69, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 81, 'value': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)', 'label': 'Dietistica (Abilitante alla Professione Sanitaria di Dietista)' },
    { 'school_id': 244, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 138, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 109, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 148, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 157, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 14336, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 6825, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 66, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 212, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 175, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 6830, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 6833, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 55, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 61, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 24, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 223, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 6837, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 146, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 137, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 13, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 151, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 35, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 91, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 108, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 114, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 72, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 69, 'value': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)', 'label': 'Igiene Dentale (Abilitante alla Professione Sanitaria di Igienista Dentale)' },
    { 'school_id': 244, 'value': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)', 'label': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)' },
    { 'school_id': 157, 'value': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)', 'label': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)' },
    { 'school_id': 6833, 'value': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)', 'label': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)' },
    { 'school_id': 13, 'value': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)', 'label': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)' },
    { 'school_id': 35, 'value': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)', 'label': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)' },
    { 'school_id': 72, 'value': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)', 'label': 'Tecniche Audiometriche (Abilitante alla Professione Sanitaria di Audiometrista)' },
    { 'school_id': 244, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 157, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 14336, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 6833, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 74, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 146, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 13, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 151, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 35, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 72, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 69, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 128, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 81, 'value': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)', 'label': 'Tecniche Audioprotesiche (Abilitante alla Professione Sanitaria di Audioprotesista)' },
    { 'school_id': 109, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 148, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 157, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 175, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 6830, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 61, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 137, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 13, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 151, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 35, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 114, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 72, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 90, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 81, 'value': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)', 'label': 'Tecniche di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare (Abilitante alla Professione Sanitaria di Tecnico di Fisiopatologia Cardiocircolatoria e Perfusione Cardiovascolare)' },
    { 'school_id': 244, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 138, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 109, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 148, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 157, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 66, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 212, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 175, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 6830, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 6833, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 55, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 74, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 61, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 24, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 223, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 6837, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 28, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 146, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 137, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 20, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 13, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 151, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 473, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 35, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 91, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 108, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 114, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 96, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 72, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 90, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 69, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 128, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 81, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 115, 'value': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)', 'label': 'Tecniche di Laboratorio Biomedico (Abilitante alla Professione Sanitaria di Tecnico di Laboratorio Biomedico)' },
    { 'school_id': 244, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 157, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 175, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 6833, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 24, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 28, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 13, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 151, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 35, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 72, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 90, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 128, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 124, 'value': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)', 'label': 'Tecniche di Neurofisiopatologia (Abilitante alla Professione Sanitaria di Tecnico di Neurofisiopatologia)' },
    { 'school_id': 244, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 138, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 109, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 148, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 157, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 14336, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 66, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 212, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 175, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 6830, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 6833, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 55, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 74, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 61, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 24, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 223, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 6837, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 28, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 146, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 137, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 20, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 13, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 151, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 600, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 473, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 35, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 91, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 38, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 108, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 114, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 96, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 72, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 69, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 128, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 81, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 115, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 124, 'value': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)', 'label': 'Tecniche di Radiologia Medica, per Immagini e Radioterapia (Abilitante alla Professione Sanitaria di Tecnico di Radiologia Medica)' },
    { 'school_id': 109, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 157, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 14336, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 175, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 6830, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 24, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 28, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 13, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 151, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 35, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 72, 'value': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)', 'label': 'Tecniche Ortopediche (Abilitante alla Professione Sanitaria di Tecnico Ortopedico)' },
    { 'school_id': 244, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 138, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 109, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 148, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 157, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 14336, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 6825, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 66, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 6827, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 212, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 175, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 6830, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 6833, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 131, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 55, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 74, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 61, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 24, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 223, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 6837, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 28, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 146, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 137, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 20, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 13, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 151, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 600, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 473, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 35, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 91, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 38, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 108, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 114, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 198, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 96, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 72, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 90, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 69, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 128, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 81, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 115, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 124, 'value': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)', 'label': 'Infermieristica (Abilitante alla Professione Sanitaria di Infermiere)' },
    { 'school_id': 244, 'value': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)', 'label': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)' },
    { 'school_id': 109, 'value': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)', 'label': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)' },
    { 'school_id': 157, 'value': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)', 'label': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)' },
    { 'school_id': 13, 'value': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)', 'label': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)' },
    { 'school_id': 151, 'value': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)', 'label': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)' },
    { 'school_id': 35, 'value': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)', 'label': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)' },
    { 'school_id': 91, 'value': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)', 'label': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)' },
    { 'school_id': 128, 'value': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)', 'label': 'Infermieristica Pediatrica (Abilitante alla Professione Sanitaria di Infermiere Pediatrico)' },
    { 'school_id': 13, 'value': 'Nursing', 'label': 'Nursing' },
    { 'school_id': 91, 'value': 'Nursing', 'label': 'Nursing' },
    { 'school_id': 69, 'value': 'Nursing', 'label': 'Nursing' },
    { 'school_id': 244, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 109, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 148, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 157, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 14336, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 6825, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 66, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 212, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 175, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 6830, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 6833, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 131, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 74, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 61, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 24, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 223, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 6837, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 28, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 146, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 137, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 20, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 13, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 151, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 473, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 35, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 91, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 38, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 108, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 114, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 72, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 69, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 128, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 81, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 115, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 124, 'value': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)', 'label': 'Ostetricia (Abilitante alla Professione Sanitaria di Ostetrica/o)' },
    { 'school_id': 28, 'value': 'Design Of Sustainable Tourism Systems – Progettazione dei Sistemi Turistici', 'label': 'Design Of Sustainable Tourism Systems – Progettazione dei Sistemi Turistici' },
    { 'school_id': 131, 'value': 'Gestione del Turismo Culturale e degli Eventi', 'label': 'Gestione del Turismo Culturale e degli Eventi' },
    { 'school_id': 80, 'value': 'Gestione delle Attivita\' Turistiche e Culturali', 'label': 'Gestione delle Attivita\' Turistiche e Culturali' },
    { 'school_id': 234, 'value': 'International Tourism And Destination Management / Turismo Internazionale e Gestione dei Sistemi Turistici', 'label': 'International Tourism And Destination Management / Turismo Internazionale e Gestione dei Sistemi Turistici' },
    { 'school_id': 198, 'value': 'Management del Turismo e dei Beni Culturali', 'label': 'Management del Turismo e dei Beni Culturali' },
    { 'school_id': 14, 'value': 'Management dell\'Ospitalità e del Turismo', 'label': 'Management dell\'Ospitalità e del Turismo' },
    { 'school_id': 70, 'value': 'Mediazione Linguistica, Turismo e Culture', 'label': 'Mediazione Linguistica, Turismo e Culture' },
    { 'school_id': 79, 'value': 'Planning And Management Of Tourism Systems', 'label': 'Planning And Management Of Tourism Systems' },
    { 'school_id': 151, 'value': 'Progettazione e Gestione dei Sistemi Turistici', 'label': 'Progettazione e Gestione dei Sistemi Turistici' },
    { 'school_id': 146, 'value': 'Progettazione e Gestione dei Sistemi Turistici Mediterranei', 'label': 'Progettazione e Gestione dei Sistemi Turistici Mediterranei' },
    { 'school_id': 72, 'value': 'Progettazione e Management dei Sistemi Turistici e Culturali', 'label': 'Progettazione e Management dei Sistemi Turistici e Culturali' },
    { 'school_id': 6831, 'value': 'Sviluppo Interculturale dei Sistemi Turistici', 'label': 'Sviluppo Interculturale dei Sistemi Turistici' },
    { 'school_id': 151, 'value': 'Tourism Strategy, Cultural Heritage And Made in Italy', 'label': 'Tourism Strategy, Cultural Heritage And Made in Italy' },
    { 'school_id': 69, 'value': 'Tourism Systems And Hospitality Management', 'label': 'Tourism Systems And Hospitality Management' },
    { 'school_id': 128, 'value': 'Turismo e Spettacolo', 'label': 'Turismo e Spettacolo' },
    { 'school_id': 66, 'value': 'Turismo, Territorio e Sviluppo Locale', 'label': 'Turismo, Territorio e Sviluppo Locale' },
    { 'school_id': 6874, 'value': 'Valorizzazione dei Sistemi Turistico Culturali', 'label': 'Valorizzazione dei Sistemi Turistico Culturali' },
    { 'school_id': 80, 'value': 'Consulenza Pedagogica e Progettazione dei Processi Formativi', 'label': 'Consulenza Pedagogica e Progettazione dei Processi Formativi' },
    { 'school_id': 14336, 'value': 'Consulenza Pedagogica per la Disabilità e la Marginalità', 'label': 'Consulenza Pedagogica per la Disabilità e la Marginalità' },
    { 'school_id': 77, 'value': 'Coordinatore dei Servizi Educativi e dei Servizi Sociali', 'label': 'Coordinatore dei Servizi Educativi e dei Servizi Sociali' },
    { 'school_id': 28, 'value': 'Dirigenza Scolastica e Pedagogia Clinica', 'label': 'Dirigenza Scolastica e Pedagogia Clinica' },
    { 'school_id': 6833, 'value': 'Management dei Servizi Educativi e Formazione Continua', 'label': 'Management dei Servizi Educativi e Formazione Continua' },
    { 'school_id': 82, 'value': 'Progettazione dei Servizi Educativi, Formativi, Media Education e Tecnologie per l\'Inclusione nei Contesti Formali e Non Formali', 'label': 'Progettazione dei Servizi Educativi, Formativi, Media Education e Tecnologie per l\'Inclusione nei Contesti Formali e Non Formali' },
    { 'school_id': 74, 'value': 'Progettazione e Coordinamento dei Servizi Educativi', 'label': 'Progettazione e Coordinamento dei Servizi Educativi' },
    { 'school_id': 59, 'value': 'Progettazione e Gestione dei Servizi Socio-Educativi e Formativi', 'label': 'Progettazione e Gestione dei Servizi Socio-Educativi e Formativi' },
    { 'school_id': 24, 'value': 'Progettazione e Gestione dell\'Intervento Educativo Nel Disagio Sociale', 'label': 'Progettazione e Gestione dell\'Intervento Educativo Nel Disagio Sociale' },
    { 'school_id': 14336, 'value': 'Progettazione Pedagogica e Formazione delle Risorse Umane', 'label': 'Progettazione Pedagogica e Formazione delle Risorse Umane' },
    { 'school_id': 14336, 'value': 'Progettazione Pedagogica nei Servizi per Minori', 'label': 'Progettazione Pedagogica nei Servizi per Minori' },
    { 'school_id': 244, 'value': 'Programmazione e Gestione dei Servizi Educativi e Formativi', 'label': 'Programmazione e Gestione dei Servizi Educativi e Formativi' },
    { 'school_id': 124, 'value': 'Scienze Pedagogiche e dei Servizi Educativi', 'label': 'Scienze Pedagogiche e dei Servizi Educativi' },
    { 'school_id': 96, 'value': 'Scienze Pedagogiche e della Progettazione Educativa', 'label': 'Scienze Pedagogiche e della Progettazione Educativa' },
    { 'school_id': 66, 'value': 'Applied Experimental Psychological Sciences', 'label': 'Applied Experimental Psychological Sciences' },
    { 'school_id': 13, 'value': 'Clinical Psychosexology', 'label': 'Clinical Psychosexology' },
    { 'school_id': 13, 'value': 'Cognitive Neuroscience', 'label': 'Cognitive Neuroscience' },
    { 'school_id': 6833, 'value': 'Cognitive Neuroscience And Clinical Neuropsychology', 'label': 'Cognitive Neuroscience And Clinical Neuropsychology' },
    { 'school_id': 13, 'value': 'Neuroscienze Cognitive e Riabilitazione Psicologica', 'label': 'Neuroscienze Cognitive e Riabilitazione Psicologica' },
    { 'school_id': 6833, 'value': 'Neuroscienze e Riabilitazione Neuropsicologica', 'label': 'Neuroscienze e Riabilitazione Neuropsicologica' },
    { 'school_id': 24, 'value': 'Neuroscienze e Riabilitazione Neuropsicologica', 'label': 'Neuroscienze e Riabilitazione Neuropsicologica' },
    { 'school_id': 132, 'value': 'Processi Cognitivi e Tecnologie', 'label': 'Processi Cognitivi e Tecnologie' },
    { 'school_id': 74, 'value': 'Psicobiologia e Neuroscienze Cognitive', 'label': 'Psicobiologia e Neuroscienze Cognitive' },
    { 'school_id': 109, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 63, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 6825, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 175, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 70, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 55, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 59, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 6851, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 264, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 169, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 35, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 114, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 72, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 81, 'value': 'Psicologia', 'label': 'Psicologia' },
    { 'school_id': 91, 'value': 'Psicologia Applicata', 'label': 'Psicologia Applicata' },
    { 'school_id': 13, 'value': 'Psicologia Applicata Ai Contesti della Salute e del Lavoro', 'label': 'Psicologia Applicata Ai Contesti della Salute e del Lavoro' },
    { 'school_id': 108, 'value': 'Psicologia Applicata, Clinica e della Salute', 'label': 'Psicologia Applicata, Clinica e della Salute' },
    { 'school_id': 244, 'value': 'Psicologia Clinica', 'label': 'Psicologia Clinica' },
    { 'school_id': 79, 'value': 'Psicologia Clinica', 'label': 'Psicologia Clinica' },
    { 'school_id': 6833, 'value': 'Psicologia Clinica', 'label': 'Psicologia Clinica' },
    { 'school_id': 24, 'value': 'Psicologia Clinica', 'label': 'Psicologia Clinica' },
    { 'school_id': 23, 'value': 'Psicologia Clinica', 'label': 'Psicologia Clinica' },
    { 'school_id': 13, 'value': 'Psicologia Clinica', 'label': 'Psicologia Clinica' },
    { 'school_id': 91, 'value': 'Psicologia Clinica', 'label': 'Psicologia Clinica' },
    { 'school_id': 69, 'value': 'Psicologia Clinica', 'label': 'Psicologia Clinica' },
    { 'school_id': 27, 'value': 'Psicologia Clinica', 'label': 'Psicologia Clinica' },
    { 'school_id': 6833, 'value': 'Psicologia Clinica dello Sviluppo', 'label': 'Psicologia Clinica dello Sviluppo' },
    { 'school_id': 146, 'value': 'Psicologia Clinica e della Salute', 'label': 'Psicologia Clinica e della Salute' },
    { 'school_id': 114, 'value': 'Psicologia Clinica e della Salute', 'label': 'Psicologia Clinica e della Salute' },
    { 'school_id': 28, 'value': 'Psicologia Clinica e della Salute e Neuropsicologia', 'label': 'Psicologia Clinica e della Salute e Neuropsicologia' },
    { 'school_id': 128, 'value': 'Psicologia Clinica e della Salute Nel Ciclo di Vita', 'label': 'Psicologia Clinica e della Salute Nel Ciclo di Vita' },
    { 'school_id': 124, 'value': 'Psicologia Clinica e di Comunita\'', 'label': 'Psicologia Clinica e di Comunita\'' },
    { 'school_id': 66, 'value': 'Psicologia Clinica e Neuropsicologia Nel Ciclo di Vita', 'label': 'Psicologia Clinica e Neuropsicologia Nel Ciclo di Vita' },
    { 'school_id': 14336, 'value': 'Psicologia Clinica e Promozione della Salute: Persona, Relazioni Familiari e di Comunità', 'label': 'Psicologia Clinica e Promozione della Salute: Persona, Relazioni Familiari e di Comunità' },
    { 'school_id': 6833, 'value': 'Psicologia Clinico-Dinamica', 'label': 'Psicologia Clinico-Dinamica' },
    { 'school_id': 6833, 'value': 'Psicologia Cognitiva Applicata', 'label': 'Psicologia Cognitiva Applicata' },
    { 'school_id': 24, 'value': 'Psicologia Cognitiva Applicata', 'label': 'Psicologia Cognitiva Applicata' },
    { 'school_id': 90, 'value': 'Psicologia Cognitiva e Neuroscienze', 'label': 'Psicologia Cognitiva e Neuroscienze' },
    { 'school_id': 6825, 'value': 'Psicologia Cognitiva nella Comunicazione della Salute', 'label': 'Psicologia Cognitiva nella Comunicazione della Salute' },
    { 'school_id': 6860, 'value': 'Psicologia Comportamentale e Cognitiva Applicata', 'label': 'Psicologia Comportamentale e Cognitiva Applicata' },
    { 'school_id': 244, 'value': 'Psicologia Criminologica e Forense', 'label': 'Psicologia Criminologica e Forense' },
    { 'school_id': 14336, 'value': 'Psicologia degli Interventi Clinici nei Contesti Sociali', 'label': 'Psicologia degli Interventi Clinici nei Contesti Sociali' },
    { 'school_id': 91, 'value': 'Psicologia dei Processi Cognitivi', 'label': 'Psicologia dei Processi Cognitivi' },
    { 'school_id': 69, 'value': 'Psicologia del Ciclo di Vita', 'label': 'Psicologia del Ciclo di Vita' },
    { 'school_id': 28, 'value': 'Psicologia del Ciclo di Vita e dei Contesti', 'label': 'Psicologia del Ciclo di Vita e dei Contesti' },
    { 'school_id': 59, 'value': 'Psicologia del Lavoro e del Benessere Organizzativo', 'label': 'Psicologia del Lavoro e del Benessere Organizzativo' },
    { 'school_id': 244, 'value': 'Psicologia del Lavoro e dell\'Organizzazione', 'label': 'Psicologia del Lavoro e dell\'Organizzazione' },
    { 'school_id': 447, 'value': 'Psicologia del Lavoro e delle Organizzazioni', 'label': 'Psicologia del Lavoro e delle Organizzazioni' },
    { 'school_id': 74, 'value': 'Psicologia dell\'Intervento Clinico e Sociale', 'label': 'Psicologia dell\'Intervento Clinico e Sociale' },
    { 'school_id': 80, 'value': 'Psicologia dell\'Intervento nei Contesti Relazionali e Sociali', 'label': 'Psicologia dell\'Intervento nei Contesti Relazionali e Sociali' },
    { 'school_id': 13, 'value': 'Psicologia della Comunicazione e del Marketing', 'label': 'Psicologia della Comunicazione e del Marketing' },
    { 'school_id': 14336, 'value': 'Psicologia dello Sviluppo e dei Processi di Tutela', 'label': 'Psicologia dello Sviluppo e dei Processi di Tutela' },
    { 'school_id': 66, 'value': 'Psicologia dello Sviluppo e dei Processi Educativi', 'label': 'Psicologia dello Sviluppo e dei Processi Educativi' },
    { 'school_id': 6833, 'value': 'Psicologia dello Sviluppo e dell\'Educazione', 'label': 'Psicologia dello Sviluppo e dell\'Educazione' },
    { 'school_id': 13, 'value': 'Psicologia dello Sviluppo Tipico e Atipico', 'label': 'Psicologia dello Sviluppo Tipico e Atipico' },
    { 'school_id': 6833, 'value': 'Psicologia di Comunita\', della Promozione del Benessere e del Cambiamento Sociale', 'label': 'Psicologia di Comunita\', della Promozione del Benessere e del Cambiamento Sociale' },
    { 'school_id': 128, 'value': 'Psicologia e Neuroscienze Cognitive', 'label': 'Psicologia e Neuroscienze Cognitive' },
    { 'school_id': 13, 'value': 'Psicologia Giuridica, Forense e Criminologica', 'label': 'Psicologia Giuridica, Forense e Criminologica' },
    { 'school_id': 14336, 'value': 'Psicologia per il Benessere: Empowerment, Riabilitazione e Tecnologia Positiva', 'label': 'Psicologia per il Benessere: Empowerment, Riabilitazione e Tecnologia Positiva' },
    { 'school_id': 6830, 'value': 'Psicologia per la Formazione', 'label': 'Psicologia per la Formazione' },
    { 'school_id': 14336, 'value': 'Psicologia per le Organizzazioni: Risorse Umane, Marketing e Comunicazione', 'label': 'Psicologia per le Organizzazioni: Risorse Umane, Marketing e Comunicazione' },
    { 'school_id': 24, 'value': 'Psicologia Scolastica e di Comunità', 'label': 'Psicologia Scolastica e di Comunità' },
    { 'school_id': 6833, 'value': 'Psicologia Sociale, del Lavoro e della Comunicazione', 'label': 'Psicologia Sociale, del Lavoro e della Comunicazione' },
    { 'school_id': 69, 'value': 'Psicologia Sociale, del Lavoro e delle Organizzazioni', 'label': 'Psicologia Sociale, del Lavoro e delle Organizzazioni' },
    { 'school_id': 66, 'value': 'Psicologia Sociale, Economica e delle Decisioni', 'label': 'Psicologia Sociale, Economica e delle Decisioni' },
    { 'school_id': 110, 'value': 'Psicologia: Risorse Umane, Ergonomia Cognitiva, Neuroscienze Cognitive', 'label': 'Psicologia: Risorse Umane, Ergonomia Cognitiva, Neuroscienze Cognitive' },
    { 'school_id': 13, 'value': 'Psicopatologia Dinamica dello Sviluppo', 'label': 'Psicopatologia Dinamica dello Sviluppo' },
    { 'school_id': 24, 'value': 'Psychology Of Wellbeing And Social Inclusivity', 'label': 'Psychology Of Wellbeing And Social Inclusivity' },
    { 'school_id': 175, 'value': 'Psychology, Neuroscience And Human Sciences', 'label': 'Psychology, Neuroscience And Human Sciences' },
    { 'school_id': 244, 'value': 'Scienze del Corpo e della Mente', 'label': 'Scienze del Corpo e della Mente' },
    { 'school_id': 20, 'value': 'Valutazione del Funzionamento Individuale in Psicologia Clinica e della Salute', 'label': 'Valutazione del Funzionamento Individuale in Psicologia Clinica e della Salute' },
    { 'school_id': 24, 'value': 'Work, Organizational And Personnel Psychology', 'label': 'Work, Organizational And Personnel Psychology' },
    { 'school_id': 55, 'value': 'Diplomazia e Cooperazione Internazionale', 'label': 'Diplomazia e Cooperazione Internazionale' },
    { 'school_id': 70, 'value': 'European And International Studies', 'label': 'European And International Studies' },
    { 'school_id': 234, 'value': 'Global Politics And International Relations / Politiche Globali e Relazioni Internazionali', 'label': 'Global Politics And International Relations / Politiche Globali e Relazioni Internazionali' },
    { 'school_id': 38, 'value': 'Global Studies And Eu', 'label': 'Global Studies And Eu' },
    { 'school_id': 6833, 'value': 'Human Rights And Multi-Level Governance', 'label': 'Human Rights And Multi-Level Governance' },
    { 'school_id': 24, 'value': 'Interdisciplinary Research And Studies On Eastern Europe', 'label': 'Interdisciplinary Research And Studies On Eastern Europe' },
    { 'school_id': 24, 'value': 'International Relations', 'label': 'International Relations' },
    { 'school_id': 6848, 'value': 'International Relations', 'label': 'International Relations' },
    { 'school_id': 69, 'value': 'International Relations / Relazioni Internazionali', 'label': 'International Relations / Relazioni Internazionali' },
    { 'school_id': 70, 'value': 'International Security Studies', 'label': 'International Security Studies' },
    { 'school_id': 81, 'value': 'Internazionalizzazione delle Relazioni Commerciali', 'label': 'Internazionalizzazione delle Relazioni Commerciali' },
    { 'school_id': 351, 'value': 'Investigazione, Criminalita\' e Sicurezza Internazionale', 'label': 'Investigazione, Criminalita\' e Sicurezza Internazionale' },
    { 'school_id': 14336, 'value': 'Politiche Europee ed Internazionali', 'label': 'Politiche Europee ed Internazionali' },
    { 'school_id': 12, 'value': 'Relazioni e Istituzioni dell\'Asia e dell\'Africa', 'label': 'Relazioni e Istituzioni dell\'Asia e dell\'Africa' },
    { 'school_id': 91, 'value': 'Relazioni e Organizzazioni Internazionali', 'label': 'Relazioni e Organizzazioni Internazionali' },
    { 'school_id': 109, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 157, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 20, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 13, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 59, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 77, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 169, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 35, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 12, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 128, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 124, 'value': 'Relazioni Internazionali', 'label': 'Relazioni Internazionali' },
    { 'school_id': 6831, 'value': 'Relazioni Internazionali Comparate', 'label': 'Relazioni Internazionali Comparate' },
    { 'school_id': 541, 'value': 'Relazioni Internazionali e Cooperazione allo Sviluppo', 'label': 'Relazioni Internazionali e Cooperazione allo Sviluppo' },
    { 'school_id': 6833, 'value': 'Relazioni Internazionali e Diplomazia', 'label': 'Relazioni Internazionali e Diplomazia' },
    { 'school_id': 28, 'value': 'Relazioni Internazionali e Studi Europei', 'label': 'Relazioni Internazionali e Studi Europei' },
    { 'school_id': 72, 'value': 'Relazioni Internazionali e Studi Europei', 'label': 'Relazioni Internazionali e Studi Europei' },
    { 'school_id': 35, 'value': 'Relazioni Internazionali ed Analisi di Scenario', 'label': 'Relazioni Internazionali ed Analisi di Scenario' },
    { 'school_id': 74, 'value': 'Relazioni Internazionali ed Europee', 'label': 'Relazioni Internazionali ed Europee' },
    { 'school_id': 447, 'value': 'Relazioni Internazionali per Lo Sviluppo Economico', 'label': 'Relazioni Internazionali per Lo Sviluppo Economico' },
    { 'school_id': 244, 'value': 'Scienze Internazionali', 'label': 'Scienze Internazionali' },
    { 'school_id': 137, 'value': 'Scienze Internazionali', 'label': 'Scienze Internazionali' },
    { 'school_id': 24, 'value': 'Scienze Internazionali e Diplomatiche', 'label': 'Scienze Internazionali e Diplomatiche' },
    { 'school_id': 70, 'value': 'Security, Intelligence And Strategic Studies', 'label': 'Security, Intelligence And Strategic Studies' },
    { 'school_id': 175, 'value': 'Studi dell\'Africa e dell\'Asia', 'label': 'Studi dell\'Africa e dell\'Asia' },
    { 'school_id': 146, 'value': 'Studi Internazionali', 'label': 'Studi Internazionali' },
    { 'school_id': 77, 'value': 'Studi Internazionali', 'label': 'Studi Internazionali' },
    { 'school_id': 36, 'value': 'Studi Strategici e Scienze Diplomatiche', 'label': 'Studi Strategici e Scienze Diplomatiche' },
    { 'school_id': 175, 'value': 'Sviluppo Economico e Relazioni Internazionali', 'label': 'Sviluppo Economico e Relazioni Internazionali' },
    { 'school_id': 6833, 'value': 'Ingegneria dei Materiali', 'label': 'Ingegneria dei Materiali' },
    { 'school_id': 61, 'value': 'Ingegneria dei Materiali', 'label': 'Ingegneria dei Materiali' },
    { 'school_id': 35, 'value': 'Ingegneria dei Materiali', 'label': 'Ingegneria dei Materiali' },
    { 'school_id': 13, 'value': 'Ingegneria delle Nanotecnologie', 'label': 'Ingegneria delle Nanotecnologie' },
    { 'school_id': 146, 'value': 'Materials And Nanotechnology', 'label': 'Materials And Nanotechnology' },
    { 'school_id': 95, 'value': 'Materials Engineering And Nanotechnology - Ingegneria dei Materiali e delle Nanotecnologie', 'label': 'Materials Engineering And Nanotechnology - Ingegneria dei Materiali e delle Nanotecnologie' },
    { 'school_id': 80, 'value': 'Materials Engineering And Nanotechnology - Ingegneria dei Materiali e Nanotecnologie', 'label': 'Materials Engineering And Nanotechnology - Ingegneria dei Materiali e Nanotecnologie' },
    { 'school_id': 66, 'value': 'Materials Science', 'label': 'Materials Science' },
    { 'school_id': 6831, 'value': 'Science And Technology Of Bio And Nanomaterials', 'label': 'Science And Technology Of Bio And Nanomaterials' },
    { 'school_id': 244, 'value': 'Scienza dei Materiali', 'label': 'Scienza dei Materiali' },
    { 'school_id': 6833, 'value': 'Scienza dei Materiali', 'label': 'Scienza dei Materiali' },
    { 'school_id': 109, 'value': 'Scienza e Ingegneria dei Materiali', 'label': 'Scienza e Ingegneria dei Materiali' },
    { 'school_id': 6874, 'value': 'Scienza e Ingegneria dei Materiali Innovativi e Funzionali', 'label': 'Scienza e Ingegneria dei Materiali Innovativi e Funzionali' },
    { 'school_id': 151, 'value': 'Scienza e Tecnologia dei Materiali', 'label': 'Scienza e Tecnologia dei Materiali' },
    { 'school_id': 72, 'value': 'Scienza e Tecnologia dei Materiali', 'label': 'Scienza e Tecnologia dei Materiali' },
    { 'school_id': 6833, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 74, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 35, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 124, 'value': 'Biologia', 'label': 'Biologia' },
    { 'school_id': 60, 'value': 'Biologia della Nutrizione', 'label': 'Biologia della Nutrizione' },
    { 'school_id': 169, 'value': 'Biologia Generale e Applicata', 'label': 'Biologia Generale e Applicata' },
    { 'school_id': 6833, 'value': 'Biologia Molecolare', 'label': 'Biologia Molecolare' },
    { 'school_id': 244, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 138, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 109, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 148, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 63, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 157, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 66, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 175, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 61, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 24, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 223, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 23, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 6837, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 28, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 146, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 137, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 20, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 46, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 13, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 151, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 77, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 6859, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 82, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 91, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 38, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 108, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 198, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 72, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 80, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 69, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 128, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 81, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 115, 'value': 'Scienze Biologiche', 'label': 'Scienze Biologiche' },
    { 'school_id': 46, 'value': 'Scienze Biologiche Ambientali', 'label': 'Scienze Biologiche Ambientali' },
    { 'school_id': 600, 'value': 'Scienze dell\'Alimentazione e della Nutrizione Umana', 'label': 'Scienze dell\'Alimentazione e della Nutrizione Umana' },
    { 'school_id': 24, 'value': 'Advanced Cosmetic Sciences', 'label': 'Advanced Cosmetic Sciences' },
    { 'school_id': 28, 'value': 'Advanced Molecular Sciences', 'label': 'Advanced Molecular Sciences' },
    { 'school_id': 24, 'value': 'Chemical Innovation And Regulation', 'label': 'Chemical Innovation And Regulation' },
    { 'school_id': 137, 'value': 'Chemistry-Chimica', 'label': 'Chemistry-Chimica' },
    { 'school_id': 244, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 148, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 175, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 6833, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 55, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 74, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 24, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 146, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 13, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 151, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 38, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 6874, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 69, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 128, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 13, 'value': 'Chimica Analitica', 'label': 'Chimica Analitica' },
    { 'school_id': 244, 'value': 'Chimica Clinica, Forense e dello Sport', 'label': 'Chimica Clinica, Forense e dello Sport' },
    { 'school_id': 244, 'value': 'Chimica dell\'Ambiente', 'label': 'Chimica dell\'Ambiente' },
    { 'school_id': 60, 'value': 'Chimica e Metodologie Chimiche Avanzate - Chemistry And Advanced Chemical Methodologies', 'label': 'Chimica e Metodologie Chimiche Avanzate - Chemistry And Advanced Chemical Methodologies' },
    { 'school_id': 6831, 'value': 'Chimica e Tecnologie Sostenibili', 'label': 'Chimica e Tecnologie Sostenibili' },
    { 'school_id': 24, 'value': 'Photochemistry And Molecular Materials', 'label': 'Photochemistry And Molecular Materials' },
    { 'school_id': 138, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 109, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 157, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 61, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 223, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 28, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 20, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 35, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 108, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 72, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 478, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 81, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 115, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 124, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 66, 'value': 'Scienze e Tecnologie Chimiche', 'label': 'Scienze e Tecnologie Chimiche' },
    { 'school_id': 70, 'value': 'Cognitive Science - Scienze Cognitive', 'label': 'Cognitive Science - Scienze Cognitive' },
    { 'school_id': 70, 'value': 'Human-Computer Interaction - Interazione Persona-Macchina', 'label': 'Human-Computer Interaction - Interazione Persona-Macchina' },
    { 'school_id': 157, 'value': 'Scienze Cognitive e Processi Decisionali', 'label': 'Scienze Cognitive e Processi Decisionali' },
    { 'school_id': 128, 'value': 'Scienze Cognitive e Teorie della Comunicazione', 'label': 'Scienze Cognitive e Teorie della Comunicazione' },
    { 'school_id': 6833, 'value': 'Consulente del Lavoro', 'label': 'Consulente del Lavoro' },
    { 'school_id': 24, 'value': 'Consulente del Lavoro e delle Relazioni Aziendali', 'label': 'Consulente del Lavoro e delle Relazioni Aziendali' },
    { 'school_id': 212, 'value': 'Consulente del Lavoro e Giurista d\'Impresa', 'label': 'Consulente del Lavoro e Giurista d\'Impresa' },
    { 'school_id': 72, 'value': 'Consulente del Lavoro e Operatore di Impresa', 'label': 'Consulente del Lavoro e Operatore di Impresa' },
    { 'school_id': 128, 'value': 'Consulente del Lavoro e Scienze dei Servizi Giuridici', 'label': 'Consulente del Lavoro e Scienze dei Servizi Giuridici' },
    { 'school_id': 96, 'value': 'Consulente del Lavoro ed Esperto di Relazioni Industriali', 'label': 'Consulente del Lavoro ed Esperto di Relazioni Industriali' },
    { 'school_id': 69, 'value': 'Consulente Giuridico D’Impresa', 'label': 'Consulente Giuridico D’Impresa' },
    { 'school_id': 244, 'value': 'Diritto Agroalimentare', 'label': 'Diritto Agroalimentare' },
    { 'school_id': 6833, 'value': 'Diritto dell\'Economia', 'label': 'Diritto dell\'Economia' },
    { 'school_id': 132, 'value': 'Diritto dell\'Impresa, del Lavoro e delle Nuove Tecnologie', 'label': 'Diritto dell\'Impresa, del Lavoro e delle Nuove Tecnologie' },
    { 'school_id': 146, 'value': 'Diritto dell\'Impresa, del Lavoro e delle Pubbliche Amministrazioni', 'label': 'Diritto dell\'Impresa, del Lavoro e delle Pubbliche Amministrazioni' },
    { 'school_id': 13, 'value': 'Diritto e Amministrazione Pubblica', 'label': 'Diritto e Amministrazione Pubblica' },
    { 'school_id': 80, 'value': 'Diritto e Management dello Sport', 'label': 'Diritto e Management dello Sport' },
    { 'school_id': 6833, 'value': 'Diritto e Tecnologia', 'label': 'Diritto e Tecnologia' },
    { 'school_id': 109, 'value': 'Diritto ed Economia delle Imprese', 'label': 'Diritto ed Economia delle Imprese' },
    { 'school_id': 6860, 'value': 'Diritto ed Economia delle Imprese', 'label': 'Diritto ed Economia delle Imprese' },
    { 'school_id': 382, 'value': 'Diritto ed Economia per l\'Impresa e la Cooperazione Internazionale', 'label': 'Diritto ed Economia per l\'Impresa e la Cooperazione Internazionale' },
    { 'school_id': 79, 'value': 'Diritto per l\'Impresa Nazionale e Internazionale', 'label': 'Diritto per l\'Impresa Nazionale e Internazionale' },
    { 'school_id': 244, 'value': 'Diritto per le Imprese e le Istituzioni', 'label': 'Diritto per le Imprese e le Istituzioni' },
    { 'school_id': 131, 'value': 'Diritto per le Imprese e le Istituzioni', 'label': 'Diritto per le Imprese e le Istituzioni' },
    { 'school_id': 38, 'value': 'Giurista d\'Impresa e delle Nuove Tecnologie', 'label': 'Giurista d\'Impresa e delle Nuove Tecnologie' },
    { 'school_id': 24, 'value': 'Giurista per le Imprese e per la Pubblica Amministrazione', 'label': 'Giurista per le Imprese e per la Pubblica Amministrazione' },
    { 'school_id': 244, 'value': 'Global Law And Transnational Legal Studies', 'label': 'Global Law And Transnational Legal Studies' },
    { 'school_id': 223, 'value': 'Operatore dei Servizi Giuridici', 'label': 'Operatore dei Servizi Giuridici' },
    { 'school_id': 6860, 'value': 'Operatore Giuridico d\'Impresa', 'label': 'Operatore Giuridico d\'Impresa' },
    { 'school_id': 108, 'value': 'Operatore Giuridico d\'Impresa', 'label': 'Operatore Giuridico d\'Impresa' },
    { 'school_id': 157, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 66, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 175, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 6830, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 28, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 20, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 77, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 6851, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 35, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 91, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 72, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 115, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 124, 'value': 'Scienze dei Servizi Giuridici', 'label': 'Scienze dei Servizi Giuridici' },
    { 'school_id': 447, 'value': 'Scienze Giuridiche', 'label': 'Scienze Giuridiche' },
    { 'school_id': 234, 'value': 'Scienze Giuridiche Applicate', 'label': 'Scienze Giuridiche Applicate' },
    { 'school_id': 61, 'value': 'Scienze Giuridiche dell\'Impresa e della Pubblica Amministrazione', 'label': 'Scienze Giuridiche dell\'Impresa e della Pubblica Amministrazione' },
    { 'school_id': 175, 'value': 'Scienze Giuridiche della Prevenzione e della Sicurezza', 'label': 'Scienze Giuridiche della Prevenzione e della Sicurezza' },
    { 'school_id': 28, 'value': 'Scienze Giuridiche della Sicurezza', 'label': 'Scienze Giuridiche della Sicurezza' },
    { 'school_id': 72, 'value': 'Scienze Giuridiche per l\'Immigrazione, i Diritti Umani e l\'Interculturalità', 'label': 'Scienze Giuridiche per l\'Immigrazione, i Diritti Umani e l\'Interculturalità' },
    { 'school_id': 23, 'value': 'Scienze Giuridiche per la Consulenza del Lavoro e la Sicurezza Pubblica e Privata', 'label': 'Scienze Giuridiche per la Consulenza del Lavoro e la Sicurezza Pubblica e Privata' },
    { 'school_id': 96, 'value': 'Scienze Investigative', 'label': 'Scienze Investigative' },
    { 'school_id': 63, 'value': 'Servizi Giuridici', 'label': 'Servizi Giuridici' },
    { 'school_id': 14336, 'value': 'Servizi Giuridici', 'label': 'Servizi Giuridici' },
    { 'school_id': 137, 'value': 'Servizi Giuridici', 'label': 'Servizi Giuridici' },
    { 'school_id': 228, 'value': 'Servizi Giuridici', 'label': 'Servizi Giuridici' },
    { 'school_id': 114, 'value': 'Servizi Giuridici per l\'Impresa', 'label': 'Servizi Giuridici per l\'Impresa' },
    { 'school_id': 77, 'value': 'Servizi Giuridici per la Sicurezza Territoriale e Informatica', 'label': 'Servizi Giuridici per la Sicurezza Territoriale e Informatica' },
    { 'school_id': 322, 'value': 'Servizi Giuridici per Lavoro, Pubblica Amministrazione, Sport e Terzo Settore', 'label': 'Servizi Giuridici per Lavoro, Pubblica Amministrazione, Sport e Terzo Settore' },
    { 'school_id': 109, 'value': 'Servizi Legali all\'Impresa e alla Pubblica Amministrazione', 'label': 'Servizi Legali all\'Impresa e alla Pubblica Amministrazione' },
    { 'school_id': 70, 'value': 'Studi Giuridici Comparati, Europei e Internazionali', 'label': 'Studi Giuridici Comparati, Europei e Internazionali' },
    { 'school_id': 38, 'value': 'Economia, Valorizzazione, Gestione del Patrimonio Turistico', 'label': 'Economia, Valorizzazione, Gestione del Patrimonio Turistico' },
    { 'school_id': 244, 'value': 'Lingue e Culture per il Turismo', 'label': 'Lingue e Culture per il Turismo' },
    { 'school_id': 115, 'value': 'Lingue, Culture e Tecniche per il Turismo', 'label': 'Lingue, Culture e Tecniche per il Turismo' },
    { 'school_id': 541, 'value': 'Made in Italy, Cibo e Ospitalità', 'label': 'Made in Italy, Cibo e Ospitalità' },
    { 'school_id': 223, 'value': 'Manager degli Itinerari Culturali', 'label': 'Manager degli Itinerari Culturali' },
    { 'school_id': 80, 'value': 'Manager del Turismo', 'label': 'Manager del Turismo' },
    { 'school_id': 6833, 'value': 'Progettazione e Gestione del Turismo Culturale', 'label': 'Progettazione e Gestione del Turismo Culturale' },
    { 'school_id': 138, 'value': 'Promozione e Gestione del Turismo', 'label': 'Promozione e Gestione del Turismo' },
    { 'school_id': 148, 'value': 'Scienze del Turismo', 'label': 'Scienze del Turismo' },
    { 'school_id': 146, 'value': 'Scienze del Turismo', 'label': 'Scienze del Turismo' },
    { 'school_id': 13, 'value': 'Scienze del Turismo', 'label': 'Scienze del Turismo' },
    { 'school_id': 151, 'value': 'Scienze del Turismo', 'label': 'Scienze del Turismo' },
    { 'school_id': 447, 'value': 'Scienze del Turismo', 'label': 'Scienze del Turismo' },
    { 'school_id': 91, 'value': 'Scienze del Turismo', 'label': 'Scienze del Turismo' },
    { 'school_id': 69, 'value': 'Scienze del Turismo', 'label': 'Scienze del Turismo' },
    { 'school_id': 81, 'value': 'Scienze del Turismo', 'label': 'Scienze del Turismo' },
    { 'school_id': 35, 'value': 'Scienze del Turismo Ad Indirizzo Manageriale', 'label': 'Scienze del Turismo Ad Indirizzo Manageriale' },
    { 'school_id': 66, 'value': 'Scienze del Turismo e Comunità Locale', 'label': 'Scienze del Turismo e Comunità Locale' },
    { 'school_id': 128, 'value': 'Scienze del Turismo, della Cultura e dell\'Impresa', 'label': 'Scienze del Turismo, della Cultura e dell\'Impresa' },
    { 'school_id': 109, 'value': 'Scienze del Turismo: Impresa, Cultura e Territorio', 'label': 'Scienze del Turismo: Impresa, Cultura e Territorio' },
    { 'school_id': 131, 'value': 'Scienze e Tecniche del Turismo Culturale', 'label': 'Scienze e Tecniche del Turismo Culturale' },
    { 'school_id': 208, 'value': 'Scienze Turistiche', 'label': 'Scienze Turistiche' },
    { 'school_id': 198, 'value': 'Scienze Turistiche', 'label': 'Scienze Turistiche' },
    { 'school_id': 6874, 'value': 'Scienze Turistiche', 'label': 'Scienze Turistiche' },
    { 'school_id': 14336, 'value': 'Scienze Turistiche e Valorizzazione del Territorio', 'label': 'Scienze Turistiche e Valorizzazione del Territorio' },
    { 'school_id': 264, 'value': 'Turismo e Valorizzazione del Territorio', 'label': 'Turismo e Valorizzazione del Territorio' },
    { 'school_id': 151, 'value': 'Turismo Enogastronomico', 'label': 'Turismo Enogastronomico' },
    { 'school_id': 14, 'value': 'Turismo, Management e Cultura', 'label': 'Turismo, Management e Cultura' },
    { 'school_id': 244, 'value': 'Consulenza del Lavoro e Gestione delle Risorse Umane', 'label': 'Consulenza del Lavoro e Gestione delle Risorse Umane' },
    { 'school_id': 151, 'value': 'Global Governance', 'label': 'Global Governance' },
    { 'school_id': 157, 'value': 'Management delle Organizzazioni e del Lavoro', 'label': 'Management delle Organizzazioni e del Lavoro' },
    { 'school_id': 157, 'value': 'Management Pubblico e della Sanità', 'label': 'Management Pubblico e della Sanità' },
    { 'school_id': 90, 'value': 'Organizzazioni delle Amministrazioni Pubbliche e Private', 'label': 'Organizzazioni delle Amministrazioni Pubbliche e Private' },
    { 'school_id': 411, 'value': 'Scienza dell\'Amministrazione e dell\'Organizzazione', 'label': 'Scienza dell\'Amministrazione e dell\'Organizzazione' },
    { 'school_id': 244, 'value': 'Scienze dell\'Amministrazione', 'label': 'Scienze dell\'Amministrazione' },
    { 'school_id': 6874, 'value': 'Scienze dell\'Amministrazione', 'label': 'Scienze dell\'Amministrazione' },
    { 'school_id': 13, 'value': 'Scienze dell\'Amministrazione e dell\'Organizzazione', 'label': 'Scienze dell\'Amministrazione e dell\'Organizzazione' },
    { 'school_id': 35, 'value': 'Scienze dell\'Amministrazione e dell\'Organizzazione', 'label': 'Scienze dell\'Amministrazione e dell\'Organizzazione' },
    { 'school_id': 38, 'value': 'Scienze dell\'Amministrazione e dell\'Organizzazione', 'label': 'Scienze dell\'Amministrazione e dell\'Organizzazione' },
    { 'school_id': 81, 'value': 'Scienze dell\'Amministrazione e dell\'Organizzazione', 'label': 'Scienze dell\'Amministrazione e dell\'Organizzazione' },
    { 'school_id': 124, 'value': 'Scienze dell\'Amministrazione e dell\'Organizzazione', 'label': 'Scienze dell\'Amministrazione e dell\'Organizzazione' },
    { 'school_id': 109, 'value': 'Scienze dell\'Amministrazione e della Politica', 'label': 'Scienze dell\'Amministrazione e della Politica' },
    { 'school_id': 389, 'value': 'Scienze dell\'Amministrazione e della Sicurezza', 'label': 'Scienze dell\'Amministrazione e della Sicurezza' },
    { 'school_id': 82, 'value': 'Scienze dell\'Amministrazione, dell\'Organizzazione e Consulenza del Lavoro', 'label': 'Scienze dell\'Amministrazione, dell\'Organizzazione e Consulenza del Lavoro' },
    { 'school_id': 69, 'value': 'Scienze dell\'Amministrazione, dell\'Organizzazione e Consulenza del Lavoro', 'label': 'Scienze dell\'Amministrazione, dell\'Organizzazione e Consulenza del Lavoro' },
    { 'school_id': 128, 'value': 'Scienze dell\'Informazione: Comunicazione Pubblica e Tecniche Giornalistiche', 'label': 'Scienze dell\'Informazione: Comunicazione Pubblica e Tecniche Giornalistiche' },
    { 'school_id': 66, 'value': 'Scienze dell\'Organizzazione', 'label': 'Scienze dell\'Organizzazione' },
    { 'school_id': 36, 'value': 'Scienze della Politica e dei Rapporti Internazionali', 'label': 'Scienze della Politica e dei Rapporti Internazionali' },
    { 'school_id': 198, 'value': 'Scienze della Politica e della Amministrazione', 'label': 'Scienze della Politica e della Amministrazione' },
    { 'school_id': 91, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 55, 'value': 'Scienze Politiche e dell\'Amministrazione', 'label': 'Scienze Politiche e dell\'Amministrazione' },
    { 'school_id': 128, 'value': 'Scienze Politiche, Amministrazione e Servizi', 'label': 'Scienze Politiche, Amministrazione e Servizi' },
    { 'school_id': 72, 'value': 'Scienze Politiche, Economiche e Amministrative', 'label': 'Scienze Politiche, Economiche e Amministrative' },
    { 'school_id': 138, 'value': 'Scienze Politiche, Economiche, Sociali e dell\'Amministrazione', 'label': 'Scienze Politiche, Economiche, Sociali e dell\'Amministrazione' },
    { 'school_id': 18, 'value': 'Architettura', 'label': 'Architettura' },
    { 'school_id': 74, 'value': 'Architettura Rigenerazione Sostenibilita\'', 'label': 'Architettura Rigenerazione Sostenibilita\'' },
    { 'school_id': 24, 'value': 'Architettura-Ingegneria', 'label': 'Architettura-Ingegneria' },
    { 'school_id': 95, 'value': 'Progettazione dell’Architettura', 'label': 'Progettazione dell’Architettura' },
    { 'school_id': 109, 'value': 'Scienze dell\'Architettura', 'label': 'Scienze dell\'Architettura' },
    { 'school_id': 131, 'value': 'Scienze dell\'Architettura', 'label': 'Scienze dell\'Architettura' },
    { 'school_id': 60, 'value': 'Scienze dell\'Architettura', 'label': 'Scienze dell\'Architettura' },
    { 'school_id': 28, 'value': 'Scienze dell\'Architettura', 'label': 'Scienze dell\'Architettura' },
    { 'school_id': 13, 'value': 'Scienze dell\'Architettura', 'label': 'Scienze dell\'Architettura' },
    { 'school_id': 77, 'value': 'Scienze dell\'Architettura', 'label': 'Scienze dell\'Architettura' },
    { 'school_id': 35, 'value': 'Scienze dell\'Architettura', 'label': 'Scienze dell\'Architettura' },
    { 'school_id': 414, 'value': 'Scienze dell\'Architettura', 'label': 'Scienze dell\'Architettura' },
    { 'school_id': 124, 'value': 'Scienze dell\'Architettura', 'label': 'Scienze dell\'Architettura' },
    { 'school_id': 115, 'value': 'Scienze dell\'Architettura e del Progetto', 'label': 'Scienze dell\'Architettura e del Progetto' },
    { 'school_id': 13, 'value': 'Analisi Economica delle Istituzioni Internazionali', 'label': 'Analisi Economica delle Istituzioni Internazionali' },
    { 'school_id': 96, 'value': 'Banca, Finanza e Mercati', 'label': 'Banca, Finanza e Mercati' },
    { 'school_id': 70, 'value': 'Behavioural And Applied Economics', 'label': 'Behavioural And Applied Economics' },
    { 'school_id': 36, 'value': 'Consulenza del Lavoro e Sistemi di Workfare', 'label': 'Consulenza del Lavoro e Sistemi di Workfare' },
    { 'school_id': 6837, 'value': 'Data Science per l\'Economia e le Imprese', 'label': 'Data Science per l\'Economia e le Imprese' },
    { 'school_id': 14336, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 38, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 414, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 115, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 151, 'value': 'Economia dei Mercati e degli Intermediari Finanziari', 'label': 'Economia dei Mercati e degli Intermediari Finanziari' },
    { 'school_id': 55, 'value': 'Economia dei Settori Produttivi e dei Mercati Internazionali', 'label': 'Economia dei Settori Produttivi e dei Mercati Internazionali' },
    { 'school_id': 77, 'value': 'Economia dell\'Ambiente e dello Sviluppo', 'label': 'Economia dell\'Ambiente e dello Sviluppo' },
    { 'school_id': 244, 'value': 'Economia dell\'Ambiente, della Cultura e del Territorio', 'label': 'Economia dell\'Ambiente, della Cultura e del Territorio' },
    { 'school_id': 114, 'value': 'Economia e Business Analytics', 'label': 'Economia e Business Analytics' },
    { 'school_id': 35, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 114, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 6874, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 24, 'value': 'Economia e Diritto', 'label': 'Economia e Diritto' },
    { 'school_id': 6831, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 6848, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 35, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 109, 'value': 'Economia e Istituzioni Finanziarie', 'label': 'Economia e Istituzioni Finanziarie' },
    { 'school_id': 244, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 478, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 81, 'value': 'Economia e Management del Territorio e del Turismo', 'label': 'Economia e Management del Territorio e del Turismo' },
    { 'school_id': 264, 'value': 'Economia e Management dell\'Innovazione', 'label': 'Economia e Management dell\'Innovazione' },
    { 'school_id': 24, 'value': 'Economia e Politica Economica', 'label': 'Economia e Politica Economica' },
    { 'school_id': 407, 'value': 'Economia e Politiche del Territorio e dell\'Impresa', 'label': 'Economia e Politiche del Territorio e dell\'Impresa' },
    { 'school_id': 72, 'value': 'Economia e Strategie per i Mercati Internazionali', 'label': 'Economia e Strategie per i Mercati Internazionali' },
    { 'school_id': 38, 'value': 'Economia Governo e Amministrazione', 'label': 'Economia Governo e Amministrazione' },
    { 'school_id': 137, 'value': 'Economia per l\'Ambiente e la Sostenibilita\'', 'label': 'Economia per l\'Ambiente e la Sostenibilita\'' },
    { 'school_id': 13, 'value': 'Economia Politica', 'label': 'Economia Politica' },
    { 'school_id': 212, 'value': 'Economia Sociale e Imprese Cooperative', 'label': 'Economia Sociale e Imprese Cooperative' },
    { 'school_id': 72, 'value': 'Economia, Finanza e Impresa', 'label': 'Economia, Finanza e Impresa' },
    { 'school_id': 91, 'value': 'Economia, Finanza e Mercati', 'label': 'Economia, Finanza e Mercati' },
    { 'school_id': 124, 'value': 'Economia, Finanza e Politiche Pubbliche', 'label': 'Economia, Finanza e Politiche Pubbliche' },
    { 'school_id': 138, 'value': 'Economia, Management e Istituzioni', 'label': 'Economia, Management e Istituzioni' },
    { 'school_id': 14332, 'value': 'Economic And Social Sciences', 'label': 'Economic And Social Sciences' },
    { 'school_id': 244, 'value': 'Economics', 'label': 'Economics' },
    { 'school_id': 24, 'value': 'Economics', 'label': 'Economics' },
    { 'school_id': 146, 'value': 'Economics', 'label': 'Economics' },
    { 'school_id': 137, 'value': 'Economics', 'label': 'Economics' },
    { 'school_id': 151, 'value': 'Economics - Economia', 'label': 'Economics - Economia' },
    { 'school_id': 131, 'value': 'Economics - Scienze Economiche', 'label': 'Economics - Scienze Economiche' },
    { 'school_id': 114, 'value': 'Economics And Behavioral Sciences', 'label': 'Economics And Behavioral Sciences' },
    { 'school_id': 79, 'value': 'Economics And Data Analysis', 'label': 'Economics And Data Analysis' },
    { 'school_id': 6830, 'value': 'Economics And Data Analysis', 'label': 'Economics And Data Analysis' },
    { 'school_id': 28, 'value': 'Economics And Development- Economia Politica e Sviluppo Economico', 'label': 'Economics And Development- Economia Politica e Sviluppo Economico' },
    { 'school_id': 322, 'value': 'Economics And Entrepreneurship', 'label': 'Economics And Entrepreneurship' },
    { 'school_id': 6833, 'value': 'Economics And Finance', 'label': 'Economics And Finance' },
    { 'school_id': 14332, 'value': 'Economics And Management Of Innovation And Technology', 'label': 'Economics And Management Of Innovation And Technology' },
    { 'school_id': 157, 'value': 'Economics And Political Science - Economia e Scienza Politica', 'label': 'Economics And Political Science - Economia e Scienza Politica' },
    { 'school_id': 61, 'value': 'Economics And Public Policy', 'label': 'Economics And Public Policy' },
    { 'school_id': 175, 'value': 'Economics, Finance And International Integration - Economia, Finanza e Integrazione Internazionale', 'label': 'Economics, Finance And International Integration - Economia, Finanza e Integrazione Internazionale' },
    { 'school_id': 223, 'value': 'Economics, Management And Policies For Global Challenges', 'label': 'Economics, Management And Policies For Global Challenges' },
    { 'school_id': 6831, 'value': 'Global Development And Entrepreneurship', 'label': 'Global Development And Entrepreneurship' },
    { 'school_id': 322, 'value': 'Global Economy And Business', 'label': 'Global Economy And Business' },
    { 'school_id': 13, 'value': 'Health Economics', 'label': 'Health Economics' },
    { 'school_id': 24, 'value': 'Health Economics And Management', 'label': 'Health Economics And Management' },
    { 'school_id': 66, 'value': 'International Economics - Economia Internazionale', 'label': 'International Economics - Economia Internazionale' },
    { 'school_id': 6830, 'value': 'International Economics And Business', 'label': 'International Economics And Business' },
    { 'school_id': 6837, 'value': 'International Economics And Commerce', 'label': 'International Economics And Commerce' },
    { 'school_id': 24, 'value': 'International Politics And Economics', 'label': 'International Politics And Economics' },
    { 'school_id': 114, 'value': 'Management, Finanza e Sviluppo', 'label': 'Management, Finanza e Sviluppo' },
    { 'school_id': 77, 'value': 'Mercato del Lavoro, Relazioni Industriali e Sistemi di Welfare', 'label': 'Mercato del Lavoro, Relazioni Industriali e Sistemi di Welfare' },
    { 'school_id': 212, 'value': 'Moneta, Finanza e Risk Management', 'label': 'Moneta, Finanza e Risk Management' },
    { 'school_id': 24, 'value': 'Resource Economics And Sustainable Development', 'label': 'Resource Economics And Sustainable Development' },
    { 'school_id': 63, 'value': 'Scienze dell\'Economia', 'label': 'Scienze dell\'Economia' },
    { 'school_id': 28, 'value': 'Scienze dell\'Economia', 'label': 'Scienze dell\'Economia' },
    { 'school_id': 6851, 'value': 'Scienze dell\'Economia', 'label': 'Scienze dell\'Economia' },
    { 'school_id': 77, 'value': 'Scienze Economiche', 'label': 'Scienze Economiche' },
    { 'school_id': 169, 'value': 'Scienze Economiche', 'label': 'Scienze Economiche' },
    { 'school_id': 208, 'value': 'Scienze Economiche', 'label': 'Scienze Economiche' },
    { 'school_id': 69, 'value': 'Scienze Economiche e Finanziarie', 'label': 'Scienze Economiche e Finanziarie' },
    { 'school_id': 128, 'value': 'Scienze Economiche e Finanziarie', 'label': 'Scienze Economiche e Finanziarie' },
    { 'school_id': 82, 'value': 'Scienze Economiche Finanziarie e Internazionali', 'label': 'Scienze Economiche Finanziarie e Internazionali' },
    { 'school_id': 24, 'value': 'Tourism Economics And Management', 'label': 'Tourism Economics And Management' },
    { 'school_id': 244, 'value': 'Amministrazione Aziendale', 'label': 'Amministrazione Aziendale' },
    { 'school_id': 70, 'value': 'Amministrazione Aziendale e Diritto', 'label': 'Amministrazione Aziendale e Diritto' },
    { 'school_id': 175, 'value': 'Amministrazione, Controllo e Finanza Aziendale', 'label': 'Amministrazione, Controllo e Finanza Aziendale' },
    { 'school_id': 212, 'value': 'Banca e Finanza', 'label': 'Banca e Finanza' },
    { 'school_id': 131, 'value': 'Banca e Finanza', 'label': 'Banca e Finanza' },
    { 'school_id': 146, 'value': 'Banca, Finanza e Mercati Finanziari', 'label': 'Banca, Finanza e Mercati Finanziari' },
    { 'school_id': 14332, 'value': 'Business (World Bachelor in Business)', 'label': 'Business (World Bachelor in Business)' },
    { 'school_id': 244, 'value': 'Business & Management', 'label': 'Business & Management' },
    { 'school_id': 151, 'value': 'Business Administration & Economics', 'label': 'Business Administration & Economics' },
    { 'school_id': 24, 'value': 'Business And Economics', 'label': 'Business And Economics' },
    { 'school_id': 6831, 'value': 'Digital Management', 'label': 'Digital Management' },
    { 'school_id': 61, 'value': 'Digital Marketing', 'label': 'Digital Marketing' },
    { 'school_id': 6833, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 223, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 228, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 244, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 138, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 109, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 58, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 14336, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 79, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 6831, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 131, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 24, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 6837, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 28, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 146, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 20, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 46, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 322, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 6859, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 35, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 82, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 91, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 208, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 38, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 114, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 198, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 96, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 72, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 80, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 478, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 6874, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 90, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 128, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 81, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 110, 'value': 'Economia Aziendale e Green Economy', 'label': 'Economia Aziendale e Green Economy' },
    { 'school_id': 14332, 'value': 'Economia Aziendale e Management', 'label': 'Economia Aziendale e Management' },
    { 'school_id': 6830, 'value': 'Economia Aziendale e Management', 'label': 'Economia Aziendale e Management' },
    { 'school_id': 61, 'value': 'Economia Aziendale e Management', 'label': 'Economia Aziendale e Management' },
    { 'school_id': 6869, 'value': 'Economia Aziendale e Management', 'label': 'Economia Aziendale e Management' },
    { 'school_id': 169, 'value': 'Economia Aziendale e Management,', 'label': 'Economia Aziendale e Management,' },
    { 'school_id': 6859, 'value': 'Economia Bancaria e Finanziaria', 'label': 'Economia Bancaria e Finanziaria' },
    { 'school_id': 14336, 'value': 'Economia dei Mercati e degli Intermediari Finanziari', 'label': 'Economia dei Mercati e degli Intermediari Finanziari' },
    { 'school_id': 20, 'value': 'Economia del Turismo', 'label': 'Economia del Turismo' },
    { 'school_id': 24, 'value': 'Economia dell\'Impresa', 'label': 'Economia dell\'Impresa' },
    { 'school_id': 109, 'value': 'Economia delle Aziende Marittime, della Logistica e dei Trasporti', 'label': 'Economia delle Aziende Marittime, della Logistica e dei Trasporti' },
    { 'school_id': 66, 'value': 'Economia delle Banche, delle Assicurazioni e degli Intermediari Finanziari', 'label': 'Economia delle Banche, delle Assicurazioni e degli Intermediari Finanziari' },
    { 'school_id': 35, 'value': 'Economia delle Imprese Finanziarie', 'label': 'Economia delle Imprese Finanziarie' },
    { 'school_id': 69, 'value': 'Economia e Amministrazione Aziendale', 'label': 'Economia e Amministrazione Aziendale' },
    { 'school_id': 72, 'value': 'Economia e Amministrazione delle Aziende', 'label': 'Economia e Amministrazione delle Aziende' },
    { 'school_id': 66, 'value': 'Economia e Amministrazione delle Imprese', 'label': 'Economia e Amministrazione delle Imprese' },
    { 'school_id': 108, 'value': 'Economia e Amministrazione delle Imprese', 'label': 'Economia e Amministrazione delle Imprese' },
    { 'school_id': 24, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 137, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 14332, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 14336, 'value': 'Economia e Gestione Aziendale', 'label': 'Economia e Gestione Aziendale' },
    { 'school_id': 212, 'value': 'Economia e Gestione Aziendale', 'label': 'Economia e Gestione Aziendale' },
    { 'school_id': 55, 'value': 'Economia e Gestione Aziendale', 'label': 'Economia e Gestione Aziendale' },
    { 'school_id': 59, 'value': 'Economia e Gestione Aziendale', 'label': 'Economia e Gestione Aziendale' },
    { 'school_id': 77, 'value': 'Economia e Gestione Aziendale', 'label': 'Economia e Gestione Aziendale' },
    { 'school_id': 264, 'value': 'Economia e Gestione Aziendale', 'label': 'Economia e Gestione Aziendale' },
    { 'school_id': 124, 'value': 'Economia e Gestione Aziendale', 'label': 'Economia e Gestione Aziendale' },
    { 'school_id': 14336, 'value': 'Economia e Gestione dei Beni Culturali e dello Spettacolo', 'label': 'Economia e Gestione dei Beni Culturali e dello Spettacolo' },
    { 'school_id': 14336, 'value': 'Economia e Gestione dei Servizi', 'label': 'Economia e Gestione dei Servizi' },
    { 'school_id': 132, 'value': 'Economia e Gestione delle Imprese', 'label': 'Economia e Gestione delle Imprese' },
    { 'school_id': 6830, 'value': 'Economia e Innovazione Aziendale', 'label': 'Economia e Innovazione Aziendale' },
    { 'school_id': 14336, 'value': 'Economia e Legislazione d\'Impresa', 'label': 'Economia e Legislazione d\'Impresa' },
    { 'school_id': 146, 'value': 'Economia e Legislazione dei Sistemi Logistici', 'label': 'Economia e Legislazione dei Sistemi Logistici' },
    { 'school_id': 157, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 273, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 70, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 74, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 23, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 151, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 6848, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 38, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 114, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 27, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 115, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 351, 'value': 'Economia e Management d\'Impresa', 'label': 'Economia e Management d\'Impresa' },
    { 'school_id': 115, 'value': 'Economia e Management del Turismo', 'label': 'Economia e Management del Turismo' },
    { 'school_id': 14332, 'value': 'Economia e Management per Arte, Cultura e Comunicazione - Economics And Management For Arts, Culture And Communication', 'label': 'Economia e Management per Arte, Cultura e Comunicazione - Economics And Management For Arts, Culture And Communication' },
    { 'school_id': 61, 'value': 'Economia e Marketing Internazionale', 'label': 'Economia e Marketing Internazionale' },
    { 'school_id': 382, 'value': 'Economia ed Organizzazione Aziendale', 'label': 'Economia ed Organizzazione Aziendale' },
    { 'school_id': 234, 'value': 'Economia: Banche, Aziende e Mercati', 'label': 'Economia: Banche, Aziende e Mercati' },
    { 'school_id': 14336, 'value': 'Economics And Management', 'label': 'Economics And Management' },
    { 'school_id': 70, 'value': 'Gestione Aziendale', 'label': 'Gestione Aziendale' },
    { 'school_id': 447, 'value': 'Gestione di Impresa', 'label': 'Gestione di Impresa' },
    { 'school_id': 35, 'value': 'Hospitality Management', 'label': 'Hospitality Management' },
    { 'school_id': 36, 'value': 'International Business Administration - Economia Aziendale Internazionale', 'label': 'International Business Administration - Economia Aziendale Internazionale' },
    { 'school_id': 14332, 'value': 'International Economics And Management', 'label': 'International Economics And Management' },
    { 'school_id': 175, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 6848, 'value': 'Management And Computer Science', 'label': 'Management And Computer Science' },
    { 'school_id': 128, 'value': 'Management d\'Impresa', 'label': 'Management d\'Impresa' },
    { 'school_id': 273, 'value': 'Management del Turismo, dello Sport e degli Eventi', 'label': 'Management del Turismo, dello Sport e degli Eventi' },
    { 'school_id': 244, 'value': 'Management dell\'Informazione e della Comunicazione Aziendale', 'label': 'Management dell\'Informazione e della Comunicazione Aziendale' },
    { 'school_id': 82, 'value': 'Management delle Imprese Internazionali', 'label': 'Management delle Imprese Internazionali' },
    { 'school_id': 82, 'value': 'Management delle Imprese Turistiche', 'label': 'Management delle Imprese Turistiche' },
    { 'school_id': 80, 'value': 'Management Digitale', 'label': 'Management Digitale' },
    { 'school_id': 13, 'value': 'Management e Diritto d\'Impresa', 'label': 'Management e Diritto d\'Impresa' },
    { 'school_id': 24, 'value': 'Management e Marketing', 'label': 'Management e Marketing' },
    { 'school_id': 146, 'value': 'Management For Business And Economics', 'label': 'Management For Business And Economics' },
    { 'school_id': 14336, 'value': 'Management per la Sostenibilità', 'label': 'Management per la Sostenibilità' },
    { 'school_id': 72, 'value': 'Marketing e Comunicazione d\'Azienda', 'label': 'Marketing e Comunicazione d\'Azienda' },
    { 'school_id': 61, 'value': 'Marketing e Organizzazione d\'Impresa', 'label': 'Marketing e Organizzazione d\'Impresa' },
    { 'school_id': 66, 'value': 'Marketing, Comunicazione Aziendale e Mercati Globali', 'label': 'Marketing, Comunicazione Aziendale e Mercati Globali' },
    { 'school_id': 13, 'value': 'Scienze Aziendali', 'label': 'Scienze Aziendali' },
    { 'school_id': 389, 'value': 'Scienze dell\'Economia Aziendale', 'label': 'Scienze dell\'Economia Aziendale' },
    { 'school_id': 407, 'value': 'Scienze dell\'Economia e della Gestione Aziendale', 'label': 'Scienze dell\'Economia e della Gestione Aziendale' },
    { 'school_id': 74, 'value': 'Sistema Alimentare: Sostenibilita\', Management e Tecnologie', 'label': 'Sistema Alimentare: Sostenibilita\', Management e Tecnologie' },
    { 'school_id': 110, 'value': 'Consulenza Pedagogica', 'label': 'Consulenza Pedagogica' },
    { 'school_id': 66, 'value': 'Formazione e Sviluppo delle Risorse Umane', 'label': 'Formazione e Sviluppo delle Risorse Umane' },
    { 'school_id': 458, 'value': 'Innovazione Educativa e Apprendimento Permanente nella Formazione degli Adulti in Contesti Nazionali e Internazionali', 'label': 'Innovazione Educativa e Apprendimento Permanente nella Formazione degli Adulti in Contesti Nazionali e Internazionali' },
    { 'school_id': 28, 'value': 'Scienze dell\'Educazione degli Adulti, della Formazione Continua e Scienze Pedagogiche', 'label': 'Scienze dell\'Educazione degli Adulti, della Formazione Continua e Scienze Pedagogiche' },
    { 'school_id': 24, 'value': 'Scienze dell\'Educazione Permanente e della Formazione Continua', 'label': 'Scienze dell\'Educazione Permanente e della Formazione Continua' },
    { 'school_id': 38, 'value': 'Scienze dell\'Educazione Permanente e della Formazione Continua', 'label': 'Scienze dell\'Educazione Permanente e della Formazione Continua' },
    { 'school_id': 77, 'value': 'Scienze Pedagogiche e Scienze dell\'Educazione degli Adulti e della Formazione Continua', 'label': 'Scienze Pedagogiche e Scienze dell\'Educazione degli Adulti e della Formazione Continua' },
    { 'school_id': 273, 'value': 'Corso di Laurea per Educatore Sociale', 'label': 'Corso di Laurea per Educatore Sociale' },
    { 'school_id': 61, 'value': 'Digital Education', 'label': 'Digital Education' },
    { 'school_id': 77, 'value': 'Educatore di Nido e dei Servizi per l\'Infanzia', 'label': 'Educatore di Nido e dei Servizi per l\'Infanzia' },
    { 'school_id': 24, 'value': 'Educatore nei Servizi per l\'Infanzia', 'label': 'Educatore nei Servizi per l\'Infanzia' },
    { 'school_id': 24, 'value': 'Educatore Sociale e Culturale', 'label': 'Educatore Sociale e Culturale' },
    { 'school_id': 80, 'value': 'Educazione Sociale e Tecniche dell\'Intervento Educativo', 'label': 'Educazione Sociale e Tecniche dell\'Intervento Educativo' },
    { 'school_id': 244, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 66, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 79, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 6830, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 55, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 23, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 20, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 59, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 77, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 6860, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 110, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 38, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 6874, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 69, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 115, 'value': 'Scienze dell\'Educazione', 'label': 'Scienze dell\'Educazione' },
    { 'school_id': 74, 'value': 'Scienze dell\'Educazione e dei Processi Formativi', 'label': 'Scienze dell\'Educazione e dei Processi Formativi' },
    { 'school_id': 109, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 63, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 14336, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 6833, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 234, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 28, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 137, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 13, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 151, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 6851, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 169, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 322, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 208, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 6869, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 96, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 72, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 478, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 414, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 81, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 124, 'value': 'Scienze dell\'Educazione e della Formazione', 'label': 'Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 77, 'value': 'Scienze dell\'Educazione per Educatori e Formatori', 'label': 'Scienze dell\'Educazione per Educatori e Formatori' },
    { 'school_id': 61, 'value': 'Scienze dell\'Educazione per il Nido e le Professioni Socio-Pedagogiche', 'label': 'Scienze dell\'Educazione per il Nido e le Professioni Socio-Pedagogiche' },
    { 'school_id': 108, 'value': 'Scienze della Formazione e del Servizio Sociale', 'label': 'Scienze della Formazione e del Servizio Sociale' },
    { 'school_id': 128, 'value': 'Scienze della Formazione e della Comunicazione', 'label': 'Scienze della Formazione e della Comunicazione' },
    { 'school_id': 458, 'value': 'Scienze e Tecniche dell\'Educazione e dei Servizi per l\'Infanzia', 'label': 'Scienze e Tecniche dell\'Educazione e dei Servizi per l\'Infanzia' },
    { 'school_id': 24, 'value': 'Astrofisica e Cosmologia', 'label': 'Astrofisica e Cosmologia' },
    { 'school_id': 66, 'value': 'Astrofisica e Fisica dello Spazio', 'label': 'Astrofisica e Fisica dello Spazio' },
    { 'school_id': 13, 'value': 'Astronomia e Astrofisica', 'label': 'Astronomia e Astrofisica' },
    { 'school_id': 6833, 'value': 'Comunicazione', 'label': 'Comunicazione' },
    { 'school_id': 14, 'value': 'Comunicazione d\'Impresa e Relazioni Pubbliche', 'label': 'Comunicazione d\'Impresa e Relazioni Pubbliche' },
    { 'school_id': 169, 'value': 'Comunicazione Digitale e Social Media', 'label': 'Comunicazione Digitale e Social Media' },
    { 'school_id': 74, 'value': 'Comunicazione e Media Contemporanei per le Industrie Creative', 'label': 'Comunicazione e Media Contemporanei per le Industrie Creative' },
    { 'school_id': 447, 'value': 'Comunicazione e Multimedialita\'', 'label': 'Comunicazione e Multimedialita\'' },
    { 'school_id': 157, 'value': 'Comunicazione e Società', 'label': 'Comunicazione e Società' },
    { 'school_id': 14336, 'value': 'Comunicazione e Società', 'label': 'Comunicazione e Società' },
    { 'school_id': 458, 'value': 'Comunicazione Innovativa, Multimediale e Digitale', 'label': 'Comunicazione Innovativa, Multimediale e Digitale' },
    { 'school_id': 244, 'value': 'Comunicazione Interculturale', 'label': 'Comunicazione Interculturale' },
    { 'school_id': 66, 'value': 'Comunicazione Interculturale', 'label': 'Comunicazione Interculturale' },
    { 'school_id': 541, 'value': 'Comunicazione Internazionale e Pubblicitaria', 'label': 'Comunicazione Internazionale e Pubblicitaria' },
    { 'school_id': 13, 'value': 'Comunicazione Pubblica e d\'Impresa', 'label': 'Comunicazione Pubblica e d\'Impresa' },
    { 'school_id': 115, 'value': 'Comunicazione Pubblica e Professioni dell\'Informazione', 'label': 'Comunicazione Pubblica e Professioni dell\'Informazione' },
    { 'school_id': 175, 'value': 'Comunicazione, Innovazione, Multimedialità', 'label': 'Comunicazione, Innovazione, Multimedialità' },
    { 'school_id': 14, 'value': 'Comunicazione, Media e Pubblicità', 'label': 'Comunicazione, Media e Pubblicità' },
    { 'school_id': 46, 'value': 'Comunicazione, Tecnologie e Culture Digitali', 'label': 'Comunicazione, Tecnologie e Culture Digitali' },
    { 'school_id': 13, 'value': 'Comunicazione, Tecnologie e Culture Digitali', 'label': 'Comunicazione, Tecnologie e Culture Digitali' },
    { 'school_id': 23, 'value': 'Informazione, Media, Pubblicità', 'label': 'Informazione, Media, Pubblicità' },
    { 'school_id': 36, 'value': 'Innovative Technologies For Digital Communication', 'label': 'Innovative Technologies For Digital Communication' },
    { 'school_id': 244, 'value': 'Innovazione Sociale, Comunicazione, Nuove Tecnologie', 'label': 'Innovazione Sociale, Comunicazione, Nuove Tecnologie' },
    { 'school_id': 70, 'value': 'Interfacce e Tecnologie della Comunicazione', 'label': 'Interfacce e Tecnologie della Comunicazione' },
    { 'school_id': 14336, 'value': 'Linguaggi dei Media', 'label': 'Linguaggi dei Media' },
    { 'school_id': 124, 'value': 'Lingue e Comunicazione', 'label': 'Lingue e Comunicazione' },
    { 'school_id': 131, 'value': 'Relazioni Pubbliche', 'label': 'Relazioni Pubbliche' },
    { 'school_id': 244, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 109, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 148, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 63, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 79, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 6830, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 61, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 24, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 234, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 137, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 20, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 151, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 77, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 132, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 110, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 38, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 228, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 198, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 72, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 80, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 124, 'value': 'Scienze della Comunicazione', 'label': 'Scienze della Comunicazione' },
    { 'school_id': 273, 'value': 'Scienze della Comunicazione e Cultura', 'label': 'Scienze della Comunicazione e Cultura' },
    { 'school_id': 69, 'value': 'Scienze della Comunicazione per i Media e le Istituzioni', 'label': 'Scienze della Comunicazione per i Media e le Istituzioni' },
    { 'school_id': 69, 'value': 'Scienze della Comunicazione per le Culture e le Arti', 'label': 'Scienze della Comunicazione per le Culture e le Arti' },
    { 'school_id': 59, 'value': 'Scienze della Comunicazione, Marketing e Digital Media', 'label': 'Scienze della Comunicazione, Marketing e Digital Media' },
    { 'school_id': 81, 'value': 'Scienze e Lingue per la Comunicazione', 'label': 'Scienze e Lingue per la Comunicazione' },
    { 'school_id': 223, 'value': 'Scienze e Tecnologie della Comunicazione', 'label': 'Scienze e Tecnologie della Comunicazione' },
    { 'school_id': 131, 'value': 'Scienze e Tecnologie Multimediali', 'label': 'Scienze e Tecnologie Multimediali' },
    { 'school_id': 66, 'value': 'Scienze Psicosociali della Comunicazione', 'label': 'Scienze Psicosociali della Comunicazione' },
    { 'school_id': 157, 'value': 'Scienze Umanistiche per la Comunicazione', 'label': 'Scienze Umanistiche per la Comunicazione' },
    { 'school_id': 28, 'value': 'Scienze Umanistiche per la Comunicazione', 'label': 'Scienze Umanistiche per la Comunicazione' },
    { 'school_id': 146, 'value': 'Comunicazione d\'Impresa e Politica delle Risorse Umane', 'label': 'Comunicazione d\'Impresa e Politica delle Risorse Umane' },
    { 'school_id': 175, 'value': 'Comunicazione Digitale', 'label': 'Comunicazione Digitale' },
    { 'school_id': 23, 'value': 'Comunicazione e Pubblicità per le Organizzazioni', 'label': 'Comunicazione e Pubblicità per le Organizzazioni' },
    { 'school_id': 131, 'value': 'Comunicazione Integrata per le Imprese e le Organizzazioni', 'label': 'Comunicazione Integrata per le Imprese e le Organizzazioni' },
    { 'school_id': 228, 'value': 'Comunicazione per la Gestione delle Organizzazioni', 'label': 'Comunicazione per la Gestione delle Organizzazioni' },
    { 'school_id': 157, 'value': 'Comunicazione Pubblica e d\'Impresa', 'label': 'Comunicazione Pubblica e d\'Impresa' },
    { 'school_id': 110, 'value': 'Comunicazione Pubblica e d\'Impresa', 'label': 'Comunicazione Pubblica e d\'Impresa' },
    { 'school_id': 244, 'value': 'Comunicazione Pubblica e Politica', 'label': 'Comunicazione Pubblica e Politica' },
    { 'school_id': 69, 'value': 'Comunicazione Pubblica, d\'Impresa e Pubblicità', 'label': 'Comunicazione Pubblica, d\'Impresa e Pubblicità' },
    { 'school_id': 20, 'value': 'Comunicazione Pubblica, Digitale e d\'Impresa', 'label': 'Comunicazione Pubblica, Digitale e d\'Impresa' },
    { 'school_id': 80, 'value': 'Comunicazione Pubblica, Economica e Istituzionale', 'label': 'Comunicazione Pubblica, Economica e Istituzionale' },
    { 'school_id': 14, 'value': 'Comunicazione Strategica', 'label': 'Comunicazione Strategica' },
    { 'school_id': 244, 'value': 'Comunicazione, Ict e Media', 'label': 'Comunicazione, Ict e Media' },
    { 'school_id': 13, 'value': 'Comunicazione, Valutazione e Ricerca Sociale per le Organizzazioni', 'label': 'Comunicazione, Valutazione e Ricerca Sociale per le Organizzazioni' },
    { 'school_id': 38, 'value': 'Corporate Communication e Media', 'label': 'Corporate Communication e Media' },
    { 'school_id': 59, 'value': 'Marketing & Digital Communication', 'label': 'Marketing & Digital Communication' },
    { 'school_id': 14, 'value': 'Marketing, Consumi e Comunicazione', 'label': 'Marketing, Consumi e Comunicazione' },
    { 'school_id': 13, 'value': 'Organizzazione e Marketing per la Comunicazione d\'Impresa', 'label': 'Organizzazione e Marketing per la Comunicazione d\'Impresa' },
    { 'school_id': 61, 'value': 'Pubblicita\', Comunicazione Digitale e Creativita\' d\'Impresa', 'label': 'Pubblicita\', Comunicazione Digitale e Creativita\' d\'Impresa' },
    { 'school_id': 72, 'value': 'Scienze della Comunicazione Pubblica, Sociale e d\'Impresa', 'label': 'Scienze della Comunicazione Pubblica, Sociale e d\'Impresa' },
    { 'school_id': 28, 'value': 'Strategie della Comunicazione Pubblica e Politica', 'label': 'Strategie della Comunicazione Pubblica e Politica' },
    { 'school_id': 36, 'value': 'Tecnologie e Linguaggi della Comunicazione', 'label': 'Tecnologie e Linguaggi della Comunicazione' },
    { 'school_id': 109, 'value': 'Engineering Technology For Strategy (And Security)', 'label': 'Engineering Technology For Strategy (And Security)' },
    { 'school_id': 35, 'value': 'Gestione dei Sistemi Aerospaziali per la Difesa', 'label': 'Gestione dei Sistemi Aerospaziali per la Difesa' },
    { 'school_id': 6874, 'value': 'Intelligence e Analisi del Rischio', 'label': 'Intelligence e Analisi del Rischio' },
    { 'school_id': 146, 'value': 'Scienze del Governo e dell\'Amministrazione del Mare', 'label': 'Scienze del Governo e dell\'Amministrazione del Mare' },
    { 'school_id': 36, 'value': 'Scienze della Difesa e della Sicurezza', 'label': 'Scienze della Difesa e della Sicurezza' },
    { 'school_id': 146, 'value': 'Scienze Marittime e Navali', 'label': 'Scienze Marittime e Navali' },
    { 'school_id': 244, 'value': 'Scienze Strategiche', 'label': 'Scienze Strategiche' },
    { 'school_id': 61, 'value': 'Scienze Strategiche', 'label': 'Scienze Strategiche' },
    { 'school_id': 244, 'value': 'Scienze Strategiche e della Sicurezza', 'label': 'Scienze Strategiche e della Sicurezza' },
    { 'school_id': 27, 'value': 'Scienze Strategiche e della Sicurezza', 'label': 'Scienze Strategiche e della Sicurezza' },
    { 'school_id': 244, 'value': 'Scienze Strategiche e Militari', 'label': 'Scienze Strategiche e Militari' },
    { 'school_id': 72, 'value': 'Scienze Strategiche Marittimo-Portuali', 'label': 'Scienze Strategiche Marittimo-Portuali' },
    { 'school_id': 115, 'value': 'Sicurezza e Cooperazione Internazionale', 'label': 'Sicurezza e Cooperazione Internazionale' },
    { 'school_id': 244, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 407, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 109, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 14336, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 66, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 79, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 273, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 6830, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 6833, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 131, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 61, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 24, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 23, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 234, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 28, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 146, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 20, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 13, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 59, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 77, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 264, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 110, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 38, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 108, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 198, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 72, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 80, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 478, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 6874, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 414, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 69, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 27, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 124, 'value': 'Scienze della Formazione Primaria', 'label': 'Scienze della Formazione Primaria' },
    { 'school_id': 6874, 'value': 'Biodiversità e Sistemi Naturali', 'label': 'Biodiversità e Sistemi Naturali' },
    { 'school_id': 157, 'value': 'Biogeoscienze: Analisi degli Ecosistemi e Comunicazione delle Scienze', 'label': 'Biogeoscienze: Analisi degli Ecosistemi e Comunicazione delle Scienze' },
    { 'school_id': 109, 'value': 'Conservazione e Gestione della Natura', 'label': 'Conservazione e Gestione della Natura' },
    { 'school_id': 124, 'value': 'Conservazione e Gestione della Natura e dell\'Ambiente', 'label': 'Conservazione e Gestione della Natura e dell\'Ambiente' },
    { 'school_id': 61, 'value': 'Didattica e Comunicazione delle Scienze', 'label': 'Didattica e Comunicazione delle Scienze' },
    { 'school_id': 24, 'value': 'Didattica e Comunicazione delle Scienze Naturali', 'label': 'Didattica e Comunicazione delle Scienze Naturali' },
    { 'school_id': 244, 'value': 'Evoluzione del Comportamento Animale e dell\'Uomo', 'label': 'Evoluzione del Comportamento Animale e dell\'Uomo' },
    { 'school_id': 244, 'value': 'Scienze dei Sistemi Naturali', 'label': 'Scienze dei Sistemi Naturali' },
    { 'school_id': 175, 'value': 'Scienze della Natura', 'label': 'Scienze della Natura' },
    { 'school_id': 6833, 'value': 'Scienze della Natura', 'label': 'Scienze della Natura' },
    { 'school_id': 13, 'value': 'Scienze della Natura', 'label': 'Scienze della Natura' },
    { 'school_id': 69, 'value': 'Scienze della Natura', 'label': 'Scienze della Natura' },
    { 'school_id': 72, 'value': 'Scienze della Natura e dell\'Ambiente', 'label': 'Scienze della Natura e dell\'Ambiente' },
    { 'school_id': 28, 'value': 'Scienze della Natura e dell\'Uomo', 'label': 'Scienze della Natura e dell\'Uomo' },
    { 'school_id': 24, 'value': 'Scienze e Gestione della Natura', 'label': 'Scienze e Gestione della Natura' },
    { 'school_id': 20, 'value': 'Scienze e Tecnologie Naturalistiche e Ambientali', 'label': 'Scienze e Tecnologie Naturalistiche e Ambientali' },
    { 'school_id': 35, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 157, 'value': 'Alimentazione e Nutrizione Umana', 'label': 'Alimentazione e Nutrizione Umana' },
    { 'school_id': 6874, 'value': 'Scienza della Nutrizione', 'label': 'Scienza della Nutrizione' },
    { 'school_id': 124, 'value': 'Scienze degli Alimenti e della Nutrizione', 'label': 'Scienze degli Alimenti e della Nutrizione' },
    { 'school_id': 244, 'value': 'Scienze degli Alimenti e della Nutrizione Umana', 'label': 'Scienze degli Alimenti e della Nutrizione Umana' },
    { 'school_id': 91, 'value': 'Scienze degli Alimenti e della Nutrizione Umana', 'label': 'Scienze degli Alimenti e della Nutrizione Umana' },
    { 'school_id': 28, 'value': 'Scienze dell\'Alimentazione', 'label': 'Scienze dell\'Alimentazione' },
    { 'school_id': 69, 'value': 'Scienze dell\'Alimentazione e della Nutrizione Umana', 'label': 'Scienze dell\'Alimentazione e della Nutrizione Umana' },
    { 'school_id': 128, 'value': 'Scienze dell\'Alimentazione e Nutrizione Umana', 'label': 'Scienze dell\'Alimentazione e Nutrizione Umana' },
    { 'school_id': 114, 'value': 'Scienze dell\'Alimentazione e Salute', 'label': 'Scienze dell\'Alimentazione e Salute' },
    { 'school_id': 115, 'value': 'Scienze dell\'Alimentazione, Salute e Benessere dell\'Uomo', 'label': 'Scienze dell\'Alimentazione, Salute e Benessere dell\'Uomo' },
    { 'school_id': 20, 'value': 'Scienze della Alimentazione e della Nutrizione Umana', 'label': 'Scienze della Alimentazione e della Nutrizione Umana' },
    { 'school_id': 72, 'value': 'Scienze della Nutrizione per la Salute Umana', 'label': 'Scienze della Nutrizione per la Salute Umana' },
    { 'school_id': 63, 'value': 'Scienze della Nutrizione Umana', 'label': 'Scienze della Nutrizione Umana' },
    { 'school_id': 74, 'value': 'Scienze della Nutrizione Umana', 'label': 'Scienze della Nutrizione Umana' },
    { 'school_id': 146, 'value': 'Scienze della Nutrizione Umana', 'label': 'Scienze della Nutrizione Umana' },
    { 'school_id': 151, 'value': 'Scienze della Nutrizione Umana', 'label': 'Scienze della Nutrizione Umana' },
    { 'school_id': 411, 'value': 'Scienze della Nutrizione Umana', 'label': 'Scienze della Nutrizione Umana' },
    { 'school_id': 35, 'value': 'Scienze della Nutrizione Umana', 'label': 'Scienze della Nutrizione Umana' },
    { 'school_id': 478, 'value': 'Paesaggio, Ambiente e Verde Urbano', 'label': 'Paesaggio, Ambiente e Verde Urbano' },
    { 'school_id': 28, 'value': 'Pianificazione della Citta\', del Territorio e del Paesaggio', 'label': 'Pianificazione della Citta\', del Territorio e del Paesaggio' },
    { 'school_id': 46, 'value': 'Pianificazione e Progettazione del Paesaggio e dell\'Ambiente', 'label': 'Pianificazione e Progettazione del Paesaggio e dell\'Ambiente' },
    { 'school_id': 81, 'value': 'Pianificazione e Sostenibilità Ambientale del Territorio e del Paesaggio', 'label': 'Pianificazione e Sostenibilità Ambientale del Territorio e del Paesaggio' },
    { 'school_id': 6833, 'value': 'Riassetto del Territorio e Tutela del Paesaggio', 'label': 'Riassetto del Territorio e Tutela del Paesaggio' },
    { 'school_id': 114, 'value': 'Scienze dell\'Habitat Sostenibile', 'label': 'Scienze dell\'Habitat Sostenibile' },
    { 'school_id': 23, 'value': 'Scienze Geologiche e Pianificazione Territoriale', 'label': 'Scienze Geologiche e Pianificazione Territoriale' },
    { 'school_id': 35, 'value': 'Sviluppo Sostenibile e Reti Territoriali', 'label': 'Sviluppo Sostenibile e Reti Territoriali' },
    { 'school_id': 6832, 'value': 'Urbanistica e Pianificazione del Territorio', 'label': 'Urbanistica e Pianificazione del Territorio' },
    { 'school_id': 69, 'value': 'Urbanistica e Scienze della Citta\'', 'label': 'Urbanistica e Scienze della Citta\'' },
    { 'school_id': 95, 'value': 'Urbanistica: Città Ambiente Paesaggio', 'label': 'Urbanistica: Città Ambiente Paesaggio' },
    { 'school_id': 115, 'value': 'Urbanistica. Pianificazione della Città, del Territorio, dell\'Ambiente e del Paesaggio.', 'label': 'Urbanistica. Pianificazione della Città, del Territorio, dell\'Ambiente e del Paesaggio.' },
    { 'school_id': 70, 'value': 'Global And Local Studies', 'label': 'Global And Local Studies' },
    { 'school_id': 81, 'value': 'Global Politics And Euro-Mediterranean Relations', 'label': 'Global Politics And Euro-Mediterranean Relations' },
    { 'school_id': 157, 'value': 'Global Politics And Society', 'label': 'Global Politics And Society' },
    { 'school_id': 6830, 'value': 'Governance dell\'Emergenza', 'label': 'Governance dell\'Emergenza' },
    { 'school_id': 6848, 'value': 'Governo, Amministrazione e Politica', 'label': 'Governo, Amministrazione e Politica' },
    { 'school_id': 24, 'value': 'Mass Media e Politica', 'label': 'Mass Media e Politica' },
    { 'school_id': 6848, 'value': 'Policies And Governance in Europe', 'label': 'Policies And Governance in Europe' },
    { 'school_id': 23, 'value': 'Politica Societa\' Economia Internazionali', 'label': 'Politica Societa\' Economia Internazionali' },
    { 'school_id': 20, 'value': 'Politica, Amministrazione, Territorio', 'label': 'Politica, Amministrazione, Territorio' },
    { 'school_id': 6825, 'value': 'Politica, Filosofia e Questioni Pubbliche', 'label': 'Politica, Filosofia e Questioni Pubbliche' },
    { 'school_id': 28, 'value': 'Politica, Istituzioni e Mercato', 'label': 'Politica, Istituzioni e Mercato' },
    { 'school_id': 234, 'value': 'Politiche Europee e Relazioni Euromediterranee', 'label': 'Politiche Europee e Relazioni Euromediterranee' },
    { 'school_id': 38, 'value': 'Politiche Territoriali e Cooperazione Internazionale', 'label': 'Politiche Territoriali e Cooperazione Internazionale' },
    { 'school_id': 124, 'value': 'Politiche, Società e Territorio', 'label': 'Politiche, Società e Territorio' },
    { 'school_id': 14332, 'value': 'Politics And Policy Analysis', 'label': 'Politics And Policy Analysis' },
    { 'school_id': 157, 'value': 'Politics, Philosophy And Public Affairs', 'label': 'Politics, Philosophy And Public Affairs' },
    { 'school_id': 244, 'value': 'Scienze del Governo', 'label': 'Scienze del Governo' },
    { 'school_id': 55, 'value': 'Scienze del Governo e Politiche Pubbliche', 'label': 'Scienze del Governo e Politiche Pubbliche' },
    { 'school_id': 13, 'value': 'Scienze della Politica', 'label': 'Scienze della Politica' },
    { 'school_id': 91, 'value': 'Scienze della Politica', 'label': 'Scienze della Politica' },
    { 'school_id': 46, 'value': 'Scienze della Politica, della Sicurezza Internazionale e della Comunicazione Pubblica', 'label': 'Scienze della Politica, della Sicurezza Internazionale e della Comunicazione Pubblica' },
    { 'school_id': 6851, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 198, 'value': 'Scienze Politiche e delle Istituzioni Europee', 'label': 'Scienze Politiche e delle Istituzioni Europee' },
    { 'school_id': 157, 'value': 'Scienze Politiche e di Governo', 'label': 'Scienze Politiche e di Governo' },
    { 'school_id': 115, 'value': 'Scienze Politiche e Giuridiche per L Amministrazione', 'label': 'Scienze Politiche e Giuridiche per L Amministrazione' },
    { 'school_id': 6874, 'value': 'Scienze Politiche e Istituzioni Comparate', 'label': 'Scienze Politiche e Istituzioni Comparate' },
    { 'school_id': 228, 'value': 'Scienze Politiche Internazionali', 'label': 'Scienze Politiche Internazionali' },
    { 'school_id': 80, 'value': 'Studi Geopolitici e Internazionali', 'label': 'Studi Geopolitici e Internazionali' },
    { 'school_id': 175, 'value': 'World Politics And International Relations (Politica Nel Mondo e Relazioni Internazionali)', 'label': 'World Politics And International Relations (Politica Nel Mondo e Relazioni Internazionali)' },
    { 'school_id': 85, 'value': 'Corso di Laurea in Scienze Motorie e Sportive', 'label': 'Corso di Laurea in Scienze Motorie e Sportive' },
    { 'school_id': 244, 'value': 'Scienze delle Attività Motorie e Sportive', 'label': 'Scienze delle Attività Motorie e Sportive' },
    { 'school_id': 6830, 'value': 'Scienze delle Attività Motorie e Sportive', 'label': 'Scienze delle Attività Motorie e Sportive' },
    { 'school_id': 24, 'value': 'Scienze delle Attività Motorie e Sportive', 'label': 'Scienze delle Attività Motorie e Sportive' },
    { 'school_id': 114, 'value': 'Scienze delle Attività Motorie e Sportive', 'label': 'Scienze delle Attività Motorie e Sportive' },
    { 'school_id': 72, 'value': 'Scienze delle Attività Motorie e Sportive', 'label': 'Scienze delle Attività Motorie e Sportive' },
    { 'school_id': 69, 'value': 'Scienze delle Attività Motorie e Sportive', 'label': 'Scienze delle Attività Motorie e Sportive' },
    { 'school_id': 38, 'value': 'Scienze delle Attività Motorie, Sportive e dell\'Educazione Psicomotoria', 'label': 'Scienze delle Attività Motorie, Sportive e dell\'Educazione Psicomotoria' },
    { 'school_id': 63, 'value': 'Scienze delle Attivita\' Motorie e Sportive', 'label': 'Scienze delle Attivita\' Motorie e Sportive' },
    { 'school_id': 96, 'value': 'Scienze delle Attivita\' Motorie e Sportive', 'label': 'Scienze delle Attivita\' Motorie e Sportive' },
    { 'school_id': 27, 'value': 'Scienze delle Attivita\' Motorie e Sportive', 'label': 'Scienze delle Attivita\' Motorie e Sportive' },
    { 'school_id': 124, 'value': 'Scienze delle Attivita\' Motorie e Sportive', 'label': 'Scienze delle Attivita\' Motorie e Sportive' },
    { 'school_id': 148, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 212, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 175, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 6833, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 131, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 223, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 146, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 151, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 169, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 411, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 322, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 82, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 208, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 81, 'value': 'Scienze Motorie', 'label': 'Scienze Motorie' },
    { 'school_id': 14336, 'value': 'Scienze Motorie e dello Sport', 'label': 'Scienze Motorie e dello Sport' },
    { 'school_id': 80, 'value': 'Scienze Motorie e dello Sport', 'label': 'Scienze Motorie e dello Sport' },
    { 'school_id': 79, 'value': 'Scienze Motorie e Sportive', 'label': 'Scienze Motorie e Sportive' },
    { 'school_id': 20, 'value': 'Scienze Motorie e Sportive', 'label': 'Scienze Motorie e Sportive' },
    { 'school_id': 108, 'value': 'Scienze Motorie e Sportive', 'label': 'Scienze Motorie e Sportive' },
    { 'school_id': 198, 'value': 'Scienze Motorie e Sportive', 'label': 'Scienze Motorie e Sportive' },
    { 'school_id': 90, 'value': 'Scienze Motorie e Sportive', 'label': 'Scienze Motorie e Sportive' },
    { 'school_id': 458, 'value': 'Scienze Motorie, Pratica e Gestione delle Attività Sportive', 'label': 'Scienze Motorie, Pratica e Gestione delle Attività Sportive' },
    { 'school_id': 109, 'value': 'Scienze Motorie, Sport e Salute', 'label': 'Scienze Motorie, Sport e Salute' },
    { 'school_id': 157, 'value': 'Scienze Motorie, Sport e Salute', 'label': 'Scienze Motorie, Sport e Salute' },
    { 'school_id': 74, 'value': 'Scienze Motorie, Sport e Salute', 'label': 'Scienze Motorie, Sport e Salute' },
    { 'school_id': 28, 'value': 'Scienze Motorie, Sport e Salute', 'label': 'Scienze Motorie, Sport e Salute' },
    { 'school_id': 128, 'value': 'Scienze Motorie, Sport e Salute', 'label': 'Scienze Motorie, Sport e Salute' },
    { 'school_id': 115, 'value': 'Scienze Motorie, Sportive e Benessere dell\'Uomo', 'label': 'Scienze Motorie, Sportive e Benessere dell\'Uomo' },
    { 'school_id': 23, 'value': 'Scienze Motorie, Sportive e della Salute', 'label': 'Scienze Motorie, Sportive e della Salute' },
    { 'school_id': 244, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 157, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 6833, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 24, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 28, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 13, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 151, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 35, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 108, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 198, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 72, 'value': 'Scienze delle Professioni Sanitarie della Prevenzione', 'label': 'Scienze delle Professioni Sanitarie della Prevenzione' },
    { 'school_id': 157, 'value': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali', 'label': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali' },
    { 'school_id': 6837, 'value': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali', 'label': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali' },
    { 'school_id': 13, 'value': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali', 'label': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali' },
    { 'school_id': 35, 'value': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali', 'label': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali' },
    { 'school_id': 108, 'value': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali', 'label': 'Scienze delle Professioni Sanitarie Tecniche Assistenziali' },
    { 'school_id': 244, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 157, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 6833, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 223, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 6837, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 28, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 13, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 151, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 35, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 108, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 72, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 128, 'value': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche', 'label': 'Scienze delle Professioni Sanitarie Tecniche Diagnostiche' },
    { 'school_id': 109, 'value': 'Amministrazione e Politiche Pubbliche', 'label': 'Amministrazione e Politiche Pubbliche' },
    { 'school_id': 157, 'value': 'Amministrazioni e Politiche Pubbliche', 'label': 'Amministrazioni e Politiche Pubbliche' },
    { 'school_id': 131, 'value': 'Diritto per l\'Innovazione di Imprese e Pubbliche Amministrazioni', 'label': 'Diritto per l\'Innovazione di Imprese e Pubbliche Amministrazioni' },
    { 'school_id': 61, 'value': 'Economia e Diritto per le Imprese e le Pubbliche Amministrazioni', 'label': 'Economia e Diritto per le Imprese e le Pubbliche Amministrazioni' },
    { 'school_id': 6831, 'value': 'Governance delle Organizzazioni Pubbliche', 'label': 'Governance delle Organizzazioni Pubbliche' },
    { 'school_id': 175, 'value': 'Governo e Politiche Pubbliche', 'label': 'Governo e Politiche Pubbliche' },
    { 'school_id': 81, 'value': 'Management della Pubblica Amministrazione', 'label': 'Management della Pubblica Amministrazione' },
    { 'school_id': 389, 'value': 'Management delle Organizzazioni Pubbliche e Sanitarie', 'label': 'Management delle Organizzazioni Pubbliche e Sanitarie' },
    { 'school_id': 66, 'value': 'Management e Design dei Servizi', 'label': 'Management e Design dei Servizi' },
    { 'school_id': 82, 'value': 'Management Pubblico', 'label': 'Management Pubblico' },
    { 'school_id': 24, 'value': 'Politica, Amministrazione e Organizzazione', 'label': 'Politica, Amministrazione e Organizzazione' },
    { 'school_id': 14336, 'value': 'Politiche Pubbliche', 'label': 'Politiche Pubbliche' },
    { 'school_id': 273, 'value': 'Politiche Pubbliche e Amministrazione', 'label': 'Politiche Pubbliche e Amministrazione' },
    { 'school_id': 244, 'value': 'Scienze Amministrative e Giuridiche delle Organizzazioni Pubbliche e Private', 'label': 'Scienze Amministrative e Giuridiche delle Organizzazioni Pubbliche e Private' },
    { 'school_id': 6833, 'value': 'Scienze del Governo e Politiche Pubbliche', 'label': 'Scienze del Governo e Politiche Pubbliche' },
    { 'school_id': 35, 'value': 'Scienze della Pubblica Amministrazione', 'label': 'Scienze della Pubblica Amministrazione' },
    { 'school_id': 137, 'value': 'Scienze delle Amministrazioni', 'label': 'Scienze delle Amministrazioni' },
    { 'school_id': 228, 'value': 'Scienze delle Amministrazioni', 'label': 'Scienze delle Amministrazioni' },
    { 'school_id': 72, 'value': 'Scienze delle Amministrazioni', 'label': 'Scienze delle Amministrazioni' },
    { 'school_id': 69, 'value': 'Scienze delle Amministrazioni e delle Organizzazioni Complesse', 'label': 'Scienze delle Amministrazioni e delle Organizzazioni Complesse' },
    { 'school_id': 90, 'value': 'Scienze delle Amministrazioni e delle Organizzazioni Complesse (Saoc)', 'label': 'Scienze delle Amministrazioni e delle Organizzazioni Complesse (Saoc)' },
    { 'school_id': 13, 'value': 'Scienze delle Amministrazioni e delle Politiche Pubbliche', 'label': 'Scienze delle Amministrazioni e delle Politiche Pubbliche' },
    { 'school_id': 146, 'value': 'Scienze delle Pubbliche Amministrazioni', 'label': 'Scienze delle Pubbliche Amministrazioni' },
    { 'school_id': 77, 'value': 'Scienze delle Pubbliche Amministrazioni', 'label': 'Scienze delle Pubbliche Amministrazioni' },
    { 'school_id': 6874, 'value': 'Scienze delle Pubbliche Amministrazioni', 'label': 'Scienze delle Pubbliche Amministrazioni' },
    { 'school_id': 128, 'value': 'Scienze delle Pubbliche Amministrazioni', 'label': 'Scienze delle Pubbliche Amministrazioni' },
    { 'school_id': 124, 'value': 'Scienze delle Pubbliche Amministrazioni', 'label': 'Scienze delle Pubbliche Amministrazioni' },
    { 'school_id': 91, 'value': 'Scienze e Tecniche delle Amministrazioni Pubbliche', 'label': 'Scienze e Tecniche delle Amministrazioni Pubbliche' },
    { 'school_id': 13, 'value': 'Culture e Religioni', 'label': 'Culture e Religioni' },
    { 'school_id': 77, 'value': 'Religioni, Culture, Storia', 'label': 'Religioni, Culture, Storia' },
    { 'school_id': 244, 'value': 'Scienze delle Religioni', 'label': 'Scienze delle Religioni' },
    { 'school_id': 6833, 'value': 'Scienze delle Religioni', 'label': 'Scienze delle Religioni' },
    { 'school_id': 6832, 'value': 'Arti Visive e Moda', 'label': 'Arti Visive e Moda' },
    { 'school_id': 244, 'value': 'Cam - Cinema, Arti della Scena, Musica e Media', 'label': 'Cam - Cinema, Arti della Scena, Musica e Media' },
    { 'school_id': 24, 'value': 'Cinema, Televisione e Produzione Multimediale', 'label': 'Cinema, Televisione e Produzione Multimediale' },
    { 'school_id': 77, 'value': 'Cinema, Televisione e Produzione Multimediale', 'label': 'Cinema, Televisione e Produzione Multimediale' },
    { 'school_id': 81, 'value': 'Comunicazione della Cultura e dello Spettacolo', 'label': 'Comunicazione della Cultura e dello Spettacolo' },
    { 'school_id': 77, 'value': 'Dams Teatro, Musica, Danza', 'label': 'Dams Teatro, Musica, Danza' },
    { 'school_id': 6874, 'value': 'Dams. Cinema, Fotografia, Performance', 'label': 'Dams. Cinema, Fotografia, Performance' },
    { 'school_id': 35, 'value': 'Discipline della Musica e dello Spettacolo. Storia e Teoria', 'label': 'Discipline della Musica e dello Spettacolo. Storia e Teoria' },
    { 'school_id': 24, 'value': 'Fashion Studies', 'label': 'Fashion Studies' },
    { 'school_id': 13, 'value': 'Fashion Studies', 'label': 'Fashion Studies' },
    { 'school_id': 228, 'value': 'Media, Arti, Culture', 'label': 'Media, Arti, Culture' },
    { 'school_id': 80, 'value': 'Progettazione e Gestione dei Sistemi Produttivi Audiovisivi e Performativi', 'label': 'Progettazione e Gestione dei Sistemi Produttivi Audiovisivi e Performativi' },
    { 'school_id': 131, 'value': 'Scienze del Patrimonio Audiovisivo e dell\'Educazione Ai Media', 'label': 'Scienze del Patrimonio Audiovisivo e dell\'Educazione Ai Media' },
    { 'school_id': 124, 'value': 'Scienze della Produzione Multimediale', 'label': 'Scienze della Produzione Multimediale' },
    { 'school_id': 28, 'value': 'Scienze dello Spettacolo', 'label': 'Scienze dello Spettacolo' },
    { 'school_id': 72, 'value': 'Scienze dello Spettacolo', 'label': 'Scienze dello Spettacolo' },
    { 'school_id': 110, 'value': 'Scienze dello Spettacolo e dei Media. Linguaggi, Interpretazione e Visioni del Reale', 'label': 'Scienze dello Spettacolo e dei Media. Linguaggi, Interpretazione e Visioni del Reale' },
    { 'school_id': 38, 'value': 'Scienze dello Spettacolo e della Produzione Multimediale', 'label': 'Scienze dello Spettacolo e della Produzione Multimediale' },
    { 'school_id': 6833, 'value': 'Scienze dello Spettacolo e Produzione Multimediale', 'label': 'Scienze dello Spettacolo e Produzione Multimediale' },
    { 'school_id': 13, 'value': 'Scritture e Produzioni dello Spettacolo e dei Media (Cinema, Teatro, Danza)', 'label': 'Scritture e Produzioni dello Spettacolo e dei Media (Cinema, Teatro, Danza)' },
    { 'school_id': 175, 'value': 'Scritture e Progetti per le Arti Visive e Performative', 'label': 'Scritture e Progetti per le Arti Visive e Performative' },
    { 'school_id': 146, 'value': 'Storia e Forme delle Arti Visive, dello Spettacolo e dei Nuovi Media', 'label': 'Storia e Forme delle Arti Visive, dello Spettacolo e dei Nuovi Media' },
    { 'school_id': 6832, 'value': 'Teatro e Arti Performative', 'label': 'Teatro e Arti Performative' },
    { 'school_id': 14, 'value': 'Televisione, Cinema e New Media', 'label': 'Televisione, Cinema e New Media' },
    { 'school_id': 69, 'value': 'Architettura e Progetto Nel Costruito', 'label': 'Architettura e Progetto Nel Costruito' },
    { 'school_id': 13, 'value': 'Gestione del Processo Edilizio - Project Management', 'label': 'Gestione del Processo Edilizio - Project Management' },
    { 'school_id': 151, 'value': 'Ingegneria dell\'Edilizia', 'label': 'Ingegneria dell\'Edilizia' },
    { 'school_id': 79, 'value': 'Ingegneria delle Tecnologie per l\'Edilizia', 'label': 'Ingegneria delle Tecnologie per l\'Edilizia' },
    { 'school_id': 24, 'value': 'Ingegneria Edile', 'label': 'Ingegneria Edile' },
    { 'school_id': 6871, 'value': 'Ingegneria Edile', 'label': 'Ingegneria Edile' },
    { 'school_id': 95, 'value': 'Ingegneria Edile e delle Costruzioni', 'label': 'Ingegneria Edile e delle Costruzioni' },
    { 'school_id': 69, 'value': 'Ingegneria Edile, Innovazione e Recupero del Costruito', 'label': 'Ingegneria Edile, Innovazione e Recupero del Costruito' },
    { 'school_id': 91, 'value': 'Scienze e Tecniche dell\'Edilizia', 'label': 'Scienze e Tecniche dell\'Edilizia' },
    { 'school_id': 13, 'value': 'Sustainable Building Engineering', 'label': 'Sustainable Building Engineering' },
    { 'school_id': 212, 'value': 'Tecniche dell\'Edilizia', 'label': 'Tecniche dell\'Edilizia' },
    { 'school_id': 131, 'value': 'Tecniche dell\'Edilizia e del Territorio', 'label': 'Tecniche dell\'Edilizia e del Territorio' },
    { 'school_id': 6837, 'value': 'Tecniche della Costruzione e Gestione del Territorio', 'label': 'Tecniche della Costruzione e Gestione del Territorio' },
    { 'school_id': 6833, 'value': 'Tecniche e Gestione dell\'Edilizia e del Territorio', 'label': 'Tecniche e Gestione dell\'Edilizia e del Territorio' },
    { 'school_id': 13, 'value': 'Tecniche per l\'Edilizia e il Territorio per la Professione del Geometra', 'label': 'Tecniche per l\'Edilizia e il Territorio per la Professione del Geometra' },
    { 'school_id': 478, 'value': 'Tecniche per l\'Edilizia e la Gestione del Territorio', 'label': 'Tecniche per l\'Edilizia e la Gestione del Territorio' },
    { 'school_id': 85, 'value': 'Attività Fisica e Salute', 'label': 'Attività Fisica e Salute' },
    { 'school_id': 85, 'value': 'Attività Motorie Preventive e Adattate', 'label': 'Attività Motorie Preventive e Adattate' },
    { 'school_id': 124, 'value': 'Attivita\' Motorie Preventive e Adattate', 'label': 'Attivita\' Motorie Preventive e Adattate' },
    { 'school_id': 157, 'value': 'Scienza dell\'Attività Fisica per il Benessere', 'label': 'Scienza dell\'Attività Fisica per il Benessere' },
    { 'school_id': 244, 'value': 'Scienze dell\'Educazione Motoria e delle Attività Adattate', 'label': 'Scienze dell\'Educazione Motoria e delle Attività Adattate' },
    { 'school_id': 63, 'value': 'Scienze dell\'Esercizio Fisico per il Benessere e la Salute', 'label': 'Scienze dell\'Esercizio Fisico per il Benessere e la Salute' },
    { 'school_id': 109, 'value': 'Scienze e Tecniche dell\'Attività Motoria Preventiva e Adattata', 'label': 'Scienze e Tecniche dell\'Attività Motoria Preventiva e Adattata' },
    { 'school_id': 6833, 'value': 'Scienze e Tecniche dell\'Attività Motoria Preventiva e Adattata', 'label': 'Scienze e Tecniche dell\'Attività Motoria Preventiva e Adattata' },
    { 'school_id': 24, 'value': 'Scienze e Tecniche dell\'Attività Motoria Preventiva e Adattata', 'label': 'Scienze e Tecniche dell\'Attività Motoria Preventiva e Adattata' },
    { 'school_id': 223, 'value': 'Scienze e Tecniche dell\'Attività Motoria Preventiva e Adattata', 'label': 'Scienze e Tecniche dell\'Attività Motoria Preventiva e Adattata' },
    { 'school_id': 14336, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 212, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 175, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 151, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 411, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 114, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 198, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 128, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 81, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 69, 'value': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate e delle Attività Sportive', 'label': 'Scienze e Tecniche delle Attività Motorie Preventive e Adattate e delle Attività Sportive' },
    { 'school_id': 74, 'value': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate' },
    { 'school_id': 146, 'value': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate' },
    { 'school_id': 322, 'value': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate' },
    { 'school_id': 96, 'value': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate' },
    { 'school_id': 27, 'value': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche delle Attivita\' Motorie Preventive e Adattate' },
    { 'school_id': 28, 'value': 'Scienze e Tecniche dello Sport e delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche dello Sport e delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 20, 'value': 'Scienze e Tecniche dello Sport e delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche dello Sport e delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 90, 'value': 'Scienze e Tecniche dello Sport e delle Attività Motorie Preventive e Adattate', 'label': 'Scienze e Tecniche dello Sport e delle Attività Motorie Preventive e Adattate' },
    { 'school_id': 23, 'value': 'Scienze Motorie per la Prevenzione e la Salute', 'label': 'Scienze Motorie per la Prevenzione e la Salute' },
    { 'school_id': 82, 'value': 'Scienze Motorie per la Prevenzione ed il Benessere', 'label': 'Scienze Motorie per la Prevenzione ed il Benessere' },
    { 'school_id': 108, 'value': 'Scienze Motorie Preventive e Adattative', 'label': 'Scienze Motorie Preventive e Adattative' },
    { 'school_id': 6830, 'value': 'Scienze Motorie Preventive ed Adattate', 'label': 'Scienze Motorie Preventive ed Adattate' },
    { 'school_id': 151, 'value': 'Physical Activity And Health Promotion', 'label': 'Physical Activity And Health Promotion' },
    { 'school_id': 131, 'value': 'Scienza dello Sport', 'label': 'Scienza dello Sport' },
    { 'school_id': 85, 'value': 'Scienza e Tecnica dello Sport', 'label': 'Scienza e Tecnica dello Sport' },
    { 'school_id': 169, 'value': 'Scienza e Tecnica dello Sport', 'label': 'Scienza e Tecnica dello Sport' },
    { 'school_id': 108, 'value': 'Scienza e Tecnica dello Sport', 'label': 'Scienza e Tecnica dello Sport' },
    { 'school_id': 157, 'value': 'Scienza, Tecnica e Didattica dello Sport', 'label': 'Scienza, Tecnica e Didattica dello Sport' },
    { 'school_id': 38, 'value': 'Scienze della Valutazione Motorio-Sportiva e Tecniche di Analisi e Progettazione dello Sport per Disabili', 'label': 'Scienze della Valutazione Motorio-Sportiva e Tecniche di Analisi e Progettazione dello Sport per Disabili' },
    { 'school_id': 23, 'value': 'Scienze dello Sport', 'label': 'Scienze dello Sport' },
    { 'school_id': 6830, 'value': 'Scienze dello Sport e della Prestazione Fisica', 'label': 'Scienze dello Sport e della Prestazione Fisica' },
    { 'school_id': 244, 'value': 'Scienze e Tecniche Avanzate dello Sport', 'label': 'Scienze e Tecniche Avanzate dello Sport' },
    { 'school_id': 24, 'value': 'Scienze e Tecniche dell\'Attività Sportiva', 'label': 'Scienze e Tecniche dell\'Attività Sportiva' },
    { 'school_id': 109, 'value': 'Scienze e Tecniche dello Sport', 'label': 'Scienze e Tecniche dello Sport' },
    { 'school_id': 175, 'value': 'Scienze e Tecniche dello Sport', 'label': 'Scienze e Tecniche dello Sport' },
    { 'school_id': 151, 'value': 'Scienze e Tecniche dello Sport', 'label': 'Scienze e Tecniche dello Sport' },
    { 'school_id': 72, 'value': 'Scienze e Tecniche dello Sport', 'label': 'Scienze e Tecniche dello Sport' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Scienze e Tecniche Psicologiche', 'label': 'Corso di Laurea in Scienze e Tecniche Psicologiche' },
    { 'school_id': 132, 'value': 'Discipline Psicosociali', 'label': 'Discipline Psicosociali' },
    { 'school_id': 13, 'value': 'Psicologia e Processi Sociali', 'label': 'Psicologia e Processi Sociali' },
    { 'school_id': 13, 'value': 'Psicologia e Salute', 'label': 'Psicologia e Salute' },
    { 'school_id': 6833, 'value': 'Psychological Science', 'label': 'Psychological Science' },
    { 'school_id': 80, 'value': 'Scienza e Tecniche Psicologiche', 'label': 'Scienza e Tecniche Psicologiche' },
    { 'school_id': 146, 'value': 'Scienze e Tecniche di Psicologia Clinica e della Salute', 'label': 'Scienze e Tecniche di Psicologia Clinica e della Salute' },
    { 'school_id': 70, 'value': 'Scienze e Tecniche di Psicologia Cognitiva', 'label': 'Scienze e Tecniche di Psicologia Cognitiva' },
    { 'school_id': 110, 'value': 'Scienze e Tecniche di Psicologia Cognitiva', 'label': 'Scienze e Tecniche di Psicologia Cognitiva' },
    { 'school_id': 90, 'value': 'Scienze e Tecniche di Psicologia Cognitiva', 'label': 'Scienze e Tecniche di Psicologia Cognitiva' },
    { 'school_id': 244, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 407, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 109, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 63, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 14336, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 6825, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 66, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 175, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 6833, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 55, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 61, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 24, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 23, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 28, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 13, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 59, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 6851, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 264, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 447, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 169, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 6860, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 91, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 114, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 72, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 6877, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 69, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 128, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 27, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 81, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 124, 'value': 'Scienze e Tecniche Psicologiche', 'label': 'Scienze e Tecniche Psicologiche' },
    { 'school_id': 128, 'value': 'Scienze e Tecniche Psicologiche Cliniche e Preventive', 'label': 'Scienze e Tecniche Psicologiche Cliniche e Preventive' },
    { 'school_id': 115, 'value': 'Scienze e Tecniche Psicologiche dei Processi Cognitivi', 'label': 'Scienze e Tecniche Psicologiche dei Processi Cognitivi' },
    { 'school_id': 79, 'value': 'Scienze Psicologiche', 'label': 'Scienze Psicologiche' },
    { 'school_id': 108, 'value': 'Scienze Psicologiche Applicate', 'label': 'Scienze Psicologiche Applicate' },
    { 'school_id': 6833, 'value': 'Scienze Psicologiche Cognitive e Psicobiologiche', 'label': 'Scienze Psicologiche Cognitive e Psicobiologiche' },
    { 'school_id': 458, 'value': 'Scienze Psicologiche delle Risorse Umane, delle Organizzazioni e delle Imprese', 'label': 'Scienze Psicologiche delle Risorse Umane, delle Organizzazioni e delle Imprese' },
    { 'school_id': 6833, 'value': 'Scienze Psicologiche dello Sviluppo, della Personalità e delle Relazioni Interpersonali', 'label': 'Scienze Psicologiche dello Sviluppo, della Personalità e delle Relazioni Interpersonali' },
    { 'school_id': 6830, 'value': 'Scienze Psicologiche per la Formazione', 'label': 'Scienze Psicologiche per la Formazione' },
    { 'school_id': 6833, 'value': 'Scienze Psicologiche Sociali e del Lavoro', 'label': 'Scienze Psicologiche Sociali e del Lavoro' },
    { 'school_id': 20, 'value': 'Agricoltura Sostenibile', 'label': 'Agricoltura Sostenibile' },
    { 'school_id': 14336, 'value': 'Agricoltura Sostenibile e di Precisione', 'label': 'Agricoltura Sostenibile e di Precisione' },
    { 'school_id': 14336, 'value': 'Agricultural And Food Economics', 'label': 'Agricultural And Food Economics' },
    { 'school_id': 70, 'value': 'Agrifood Innovation Management', 'label': 'Agrifood Innovation Management' },
    { 'school_id': 69, 'value': 'Imprenditorialita\' e Qualita\' per il Sistema Agroalimentare', 'label': 'Imprenditorialita\' e Qualita\' per il Sistema Agroalimentare' },
    { 'school_id': 72, 'value': 'Innovation Development in Agri-Food Systems (Ideas)', 'label': 'Innovation Development in Agri-Food Systems (Ideas)' },
    { 'school_id': 38, 'value': 'Innovazioni per le Produzioni Agrarie Mediterranee', 'label': 'Innovazioni per le Produzioni Agrarie Mediterranee' },
    { 'school_id': 24, 'value': 'International Horticultural Science', 'label': 'International Horticultural Science' },
    { 'school_id': 72, 'value': 'Medicina delle Piante', 'label': 'Medicina delle Piante' },
    { 'school_id': 28, 'value': 'Natural Resources Management For Tropical Rural Development', 'label': 'Natural Resources Management For Tropical Rural Development' },
    { 'school_id': 24, 'value': 'Precise And Sustainable Agriculture', 'label': 'Precise And Sustainable Agriculture' },
    { 'school_id': 146, 'value': 'Produzioni Agroalimentari e Gestione degli Agroecosistemi', 'label': 'Produzioni Agroalimentari e Gestione degli Agroecosistemi' },
    { 'school_id': 146, 'value': 'Progettazione e Gestione del Verde Urbano e del Paesaggio', 'label': 'Progettazione e Gestione del Verde Urbano e del Paesaggio' },
    { 'school_id': 244, 'value': 'Scienze Agrarie', 'label': 'Scienze Agrarie' },
    { 'school_id': 157, 'value': 'Scienze Agrarie', 'label': 'Scienze Agrarie' },
    { 'school_id': 46, 'value': 'Scienze Agrarie e Ambientali', 'label': 'Scienze Agrarie e Ambientali' },
    { 'school_id': 6837, 'value': 'Scienze Agrarie e del Territorio', 'label': 'Scienze Agrarie e del Territorio' },
    { 'school_id': 72, 'value': 'Scienze Agro-Ambientali e Territoriali', 'label': 'Scienze Agro-Ambientali e Territoriali' },
    { 'school_id': 157, 'value': 'Scienze della Produzione e Protezione delle Piante', 'label': 'Scienze della Produzione e Protezione delle Piante' },
    { 'school_id': 69, 'value': 'Scienze delle Produzioni e delle Tecnologie Agrarie', 'label': 'Scienze delle Produzioni e delle Tecnologie Agrarie' },
    { 'school_id': 6833, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 131, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 24, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 28, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 35, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 198, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 96, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 478, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 414, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 81, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 35, 'value': 'Scienze Enologiche', 'label': 'Scienze Enologiche' },
    { 'school_id': 115, 'value': 'Sistemi Agrari', 'label': 'Sistemi Agrari' },
    { 'school_id': 6833, 'value': 'Sustainable Agriculture', 'label': 'Sustainable Agriculture' },
    { 'school_id': 131, 'value': 'Viticoltura, Enologia e Mercati Vitivinicoli', 'label': 'Viticoltura, Enologia e Mercati Vitivinicoli' },
    { 'school_id': 137, 'value': 'Agribusiness', 'label': 'Agribusiness' },
    { 'school_id': 69, 'value': 'Agroingegneria', 'label': 'Agroingegneria' },
    { 'school_id': 157, 'value': 'Agrotecnologie per l\'Ambiente e il Territorio', 'label': 'Agrotecnologie per l\'Ambiente e il Territorio' },
    { 'school_id': 24, 'value': 'Economia e Marketing Nel Sistema Agro-Industriale', 'label': 'Economia e Marketing Nel Sistema Agro-Industriale' },
    { 'school_id': 14336, 'value': 'Food Production Management', 'label': 'Food Production Management' },
    { 'school_id': 38, 'value': 'Gestione e Valorizzazione delle Risorse Agrarie e delle Aree Protette', 'label': 'Gestione e Valorizzazione delle Risorse Agrarie e delle Aree Protette' },
    { 'school_id': 157, 'value': 'Produzione e Protezione delle Piante e dei Sistemi del Verde', 'label': 'Produzione e Protezione delle Piante e dei Sistemi del Verde' },
    { 'school_id': 46, 'value': 'Produzione Sementiera e Vivaismo', 'label': 'Produzione Sementiera e Vivaismo' },
    { 'school_id': 131, 'value': 'Scienze Agrarie', 'label': 'Scienze Agrarie' },
    { 'school_id': 28, 'value': 'Scienze Agrarie', 'label': 'Scienze Agrarie' },
    { 'school_id': 146, 'value': 'Scienze Agrarie', 'label': 'Scienze Agrarie' },
    { 'school_id': 20, 'value': 'Scienze Agrarie e Ambientali', 'label': 'Scienze Agrarie e Ambientali' },
    { 'school_id': 91, 'value': 'Scienze Agrarie e Forestali', 'label': 'Scienze Agrarie e Forestali' },
    { 'school_id': 273, 'value': 'Scienze Agrarie, degli Alimenti e dell\'Ambiente Montano', 'label': 'Scienze Agrarie, degli Alimenti e dell\'Ambiente Montano' },
    { 'school_id': 35, 'value': 'Scienze Agrarie, Forestali e Ambientali', 'label': 'Scienze Agrarie, Forestali e Ambientali' },
    { 'school_id': 115, 'value': 'Scienze Agro-Zootecniche', 'label': 'Scienze Agro-Zootecniche' },
    { 'school_id': 24, 'value': 'Scienze del Territorio e dell\'Ambiente Agro-Forestale', 'label': 'Scienze del Territorio e dell\'Ambiente Agro-Forestale' },
    { 'school_id': 46, 'value': 'Scienze della Montagna', 'label': 'Scienze della Montagna' },
    { 'school_id': 46, 'value': 'Scienze delle Foreste e della Natura', 'label': 'Scienze delle Foreste e della Natura' },
    { 'school_id': 244, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 157, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 14336, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 6837, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 72, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 69, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 115, 'value': 'Scienze e Tecnologie Agrarie', 'label': 'Scienze e Tecnologie Agrarie' },
    { 'school_id': 61, 'value': 'Scienze e Tecnologie Agrarie e degli Alimenti', 'label': 'Scienze e Tecnologie Agrarie e degli Alimenti' },
    { 'school_id': 198, 'value': 'Scienze e Tecnologie Agrarie e Forestali', 'label': 'Scienze e Tecnologie Agrarie e Forestali' },
    { 'school_id': 72, 'value': 'Scienze e Tecnologie del Territorio e dell\'Ambiente Agro-Forestale', 'label': 'Scienze e Tecnologie del Territorio e dell\'Ambiente Agro-Forestale' },
    { 'school_id': 28, 'value': 'Scienze e Tecnologie per la Gestione degli Spazi Verdi e del Paesaggio', 'label': 'Scienze e Tecnologie per la Gestione degli Spazi Verdi e del Paesaggio' },
    { 'school_id': 6830, 'value': 'Scienze e Tecnologie Viticole ed Enologiche', 'label': 'Scienze e Tecnologie Viticole ed Enologiche' },
    { 'school_id': 6833, 'value': 'Scienze e Tecnologie Viticole ed Enologiche', 'label': 'Scienze e Tecnologie Viticole ed Enologiche' },
    { 'school_id': 244, 'value': 'Scienze Forestali e Ambientali', 'label': 'Scienze Forestali e Ambientali' },
    { 'school_id': 6837, 'value': 'Scienze Forestali e Ambientali', 'label': 'Scienze Forestali e Ambientali' },
    { 'school_id': 28, 'value': 'Scienze Forestali e Ambientali', 'label': 'Scienze Forestali e Ambientali' },
    { 'school_id': 478, 'value': 'Scienze Forestali e Ambientali', 'label': 'Scienze Forestali e Ambientali' },
    { 'school_id': 414, 'value': 'Scienze Forestali e Ambientali', 'label': 'Scienze Forestali e Ambientali' },
    { 'school_id': 115, 'value': 'Scienze Forestali e Ambientali', 'label': 'Scienze Forestali e Ambientali' },
    { 'school_id': 69, 'value': 'Scienze Forestali ed Ambientali', 'label': 'Scienze Forestali ed Ambientali' },
    { 'school_id': 212, 'value': 'Sistemi Agricoli Sostenibili', 'label': 'Sistemi Agricoli Sostenibili' },
    { 'school_id': 6833, 'value': 'Tecnica e Gestione delle Produzioni Biologiche Vegetali', 'label': 'Tecnica e Gestione delle Produzioni Biologiche Vegetali' },
    { 'school_id': 24, 'value': 'Tecnologie Agrarie', 'label': 'Tecnologie Agrarie' },
    { 'school_id': 478, 'value': 'Tecnologie Agrarie', 'label': 'Tecnologie Agrarie' },
    { 'school_id': 223, 'value': 'Tecnologie Agrarie e Acquacoltura del Delta', 'label': 'Tecnologie Agrarie e Acquacoltura del Delta' },
    { 'school_id': 28, 'value': 'Tecnologie e Trasformazioni Avanzate per il Settore Legno Arredo Edilizia', 'label': 'Tecnologie e Trasformazioni Avanzate per il Settore Legno Arredo Edilizia' },
    { 'school_id': 6833, 'value': 'Tecnologie Forestali e Ambientali', 'label': 'Tecnologie Forestali e Ambientali' },
    { 'school_id': 157, 'value': 'Valorizzazione e Tutela dell\'Ambiente e del Territorio Montano', 'label': 'Valorizzazione e Tutela dell\'Ambiente e del Territorio Montano' },
    { 'school_id': 24, 'value': 'Verde Ornamentale e Tutela del Paesaggio', 'label': 'Verde Ornamentale e Tutela del Paesaggio' },
    { 'school_id': 157, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 70, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 131, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 28, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 35, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 80, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 69, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 61, 'value': 'Controllo e Sicurezza degli Alimenti', 'label': 'Controllo e Sicurezza degli Alimenti' },
    { 'school_id': 20, 'value': 'Economia e Cultura dell\'Alimentazione', 'label': 'Economia e Cultura dell\'Alimentazione' },
    { 'school_id': 6837, 'value': 'Food And Beverage Innovation And Management', 'label': 'Food And Beverage Innovation And Management' },
    { 'school_id': 14336, 'value': 'Food Processing: Innovation And Tradition', 'label': 'Food Processing: Innovation And Tradition' },
    { 'school_id': 228, 'value': 'Food Science And Technology', 'label': 'Food Science And Technology' },
    { 'school_id': 146, 'value': 'Innovazione Sostenibile in Viticoltura ed Enologia', 'label': 'Innovazione Sostenibile in Viticoltura ed Enologia' },
    { 'school_id': 6833, 'value': 'Italian Food And Wine', 'label': 'Italian Food And Wine' },
    { 'school_id': 69, 'value': 'Mediterranean Food Science And Technology', 'label': 'Mediterranean Food Science And Technology' },
    { 'school_id': 115, 'value': 'Qualità e Sicurezza dei Prodotti Alimentari', 'label': 'Qualità e Sicurezza dei Prodotti Alimentari' },
    { 'school_id': 273, 'value': 'Scienze degli Alimenti per l\'Innovazione e l\'Autenticità', 'label': 'Scienze degli Alimenti per l\'Innovazione e l\'Autenticità' },
    { 'school_id': 411, 'value': 'Scienze dell\'Alimentazione e Gastronomia', 'label': 'Scienze dell\'Alimentazione e Gastronomia' },
    { 'school_id': 77, 'value': 'Scienze e Culture Enogastronomiche', 'label': 'Scienze e Culture Enogastronomiche' },
    { 'school_id': 20, 'value': 'Scienze e Tecnologie Agro-Alimentari', 'label': 'Scienze e Tecnologie Agro-Alimentari' },
    { 'school_id': 69, 'value': 'Scienze e Tecnologie Agroalimentari', 'label': 'Scienze e Tecnologie Agroalimentari' },
    { 'school_id': 157, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 14336, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 6833, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 131, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 74, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 6837, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 228, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 198, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 96, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 72, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 414, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 81, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 244, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 24, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 28, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 13, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 35, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 478, 'value': 'Scienze e Tecnologie Alimentari', 'label': 'Scienze e Tecnologie Alimentari' },
    { 'school_id': 600, 'value': 'Scienze e Tecnologie Alimentari e Gestione di Filiera', 'label': 'Scienze e Tecnologie Alimentari e Gestione di Filiera' },
    { 'school_id': 157, 'value': 'Scienze e Tecnologie della Ristorazione', 'label': 'Scienze e Tecnologie della Ristorazione' },
    { 'school_id': 81, 'value': 'Scienze e Tecnologie per la Ristorazione e Distribuzione degli Alimenti Mediterranei', 'label': 'Scienze e Tecnologie per la Ristorazione e Distribuzione degli Alimenti Mediterranei' },
    { 'school_id': 96, 'value': 'Scienze Gastronomiche', 'label': 'Scienze Gastronomiche' },
    { 'school_id': 128, 'value': 'Scienze Gastronomiche', 'label': 'Scienze Gastronomiche' },
    { 'school_id': 244, 'value': 'Scienze Viticole ed Enologiche', 'label': 'Scienze Viticole ed Enologiche' },
    { 'school_id': 74, 'value': 'Sicurezza degli Alimenti e Gestione del Rischio Alimentare', 'label': 'Sicurezza degli Alimenti e Gestione del Rischio Alimentare' },
    { 'school_id': 6833, 'value': 'Sicurezza Igienico-Sanitaria degli Alimenti', 'label': 'Sicurezza Igienico-Sanitaria degli Alimenti' },
    { 'school_id': 244, 'value': 'Tecnologie Alimentari', 'label': 'Tecnologie Alimentari' },
    { 'school_id': 24, 'value': 'Tecnologie Alimentari', 'label': 'Tecnologie Alimentari' },
    { 'school_id': 28, 'value': 'Tecnologie Alimentari', 'label': 'Tecnologie Alimentari' },
    { 'school_id': 35, 'value': 'Tecnologie Alimentari', 'label': 'Tecnologie Alimentari' },
    { 'school_id': 478, 'value': 'Tecnologie Alimentari', 'label': 'Tecnologie Alimentari' },
    { 'school_id': 46, 'value': 'Tecnologie Alimentari ed Enologiche', 'label': 'Tecnologie Alimentari ed Enologiche' },
    { 'school_id': 6859, 'value': 'Tecnologie Alimentari per le Produzioni Dolciarie', 'label': 'Tecnologie Alimentari per le Produzioni Dolciarie' },
    { 'school_id': 20, 'value': 'Tecnologie e Biotecnologie degli Alimenti', 'label': 'Tecnologie e Biotecnologie degli Alimenti' },
    { 'school_id': 115, 'value': 'Tecnologie Viticole, Enologiche, Alimentari', 'label': 'Tecnologie Viticole, Enologiche, Alimentari' },
    { 'school_id': 244, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 24, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 146, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 228, 'value': 'Viticoltura ed Enologia', 'label': 'Viticoltura ed Enologia' },
    { 'school_id': 138, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 157, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 61, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 223, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 60, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 28, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 20, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 72, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 478, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 81, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 115, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 124, 'value': 'Chimica', 'label': 'Chimica' },
    { 'school_id': 151, 'value': 'Chimica Applicata', 'label': 'Chimica Applicata' },
    { 'school_id': 24, 'value': 'Chimica e Chimica dei Materiali', 'label': 'Chimica e Chimica dei Materiali' },
    { 'school_id': 148, 'value': 'Chimica e Chimica Industriale', 'label': 'Chimica e Chimica Industriale' },
    { 'school_id': 244, 'value': 'Chimica e Tecnologie Chimiche', 'label': 'Chimica e Tecnologie Chimiche' },
    { 'school_id': 109, 'value': 'Chimica e Tecnologie Chimiche', 'label': 'Chimica e Tecnologie Chimiche' },
    { 'school_id': 24, 'value': 'Chimica e Tecnologie per l\'Ambiente e per i Materiali', 'label': 'Chimica e Tecnologie per l\'Ambiente e per i Materiali' },
    { 'school_id': 157, 'value': 'Chimica Industriale', 'label': 'Chimica Industriale' },
    { 'school_id': 6833, 'value': 'Chimica Industriale', 'label': 'Chimica Industriale' },
    { 'school_id': 24, 'value': 'Chimica Industriale', 'label': 'Chimica Industriale' },
    { 'school_id': 81, 'value': 'Chimica Industriale', 'label': 'Chimica Industriale' },
    { 'school_id': 146, 'value': 'Chimica per l\'Industria e l\'Ambiente', 'label': 'Chimica per l\'Industria e l\'Ambiente' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Chimica', 'label': 'Corso di Laurea in Chimica' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Chimica Industriale', 'label': 'Corso di Laurea in Chimica Industriale' },
    { 'school_id': 66, 'value': 'Scienza dei Materiali', 'label': 'Scienza dei Materiali' },
    { 'school_id': 138, 'value': 'Scienza dei Materiali - Chimica', 'label': 'Scienza dei Materiali - Chimica' },
    { 'school_id': 244, 'value': 'Scienza e Tecnologia dei Materiali', 'label': 'Scienza e Tecnologia dei Materiali' },
    { 'school_id': 137, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 13, 'value': 'Scienze Chimiche', 'label': 'Scienze Chimiche' },
    { 'school_id': 108, 'value': 'Scienze e Tecnologie Chimiche e dei Materiali', 'label': 'Scienze e Tecnologie Chimiche e dei Materiali' },
    { 'school_id': 128, 'value': 'Sostenibilita\' ed Innovazione Ambientale', 'label': 'Sostenibilita\' ed Innovazione Ambientale' },
    { 'school_id': 24, 'value': 'Advanced Spectroscopy in Chemistry', 'label': 'Advanced Spectroscopy in Chemistry' },
    { 'school_id': 151, 'value': 'Chemical Nano-Engineering', 'label': 'Chemical Nano-Engineering' },
    { 'school_id': 244, 'value': 'Chimica Industriale', 'label': 'Chimica Industriale' },
    { 'school_id': 109, 'value': 'Chimica Industriale', 'label': 'Chimica Industriale' },
    { 'school_id': 74, 'value': 'Chimica Industriale', 'label': 'Chimica Industriale' },
    { 'school_id': 146, 'value': 'Chimica Industriale', 'label': 'Chimica Industriale' },
    { 'school_id': 13, 'value': 'Chimica Industriale', 'label': 'Chimica Industriale' },
    { 'school_id': 157, 'value': 'Industrial Chemistry', 'label': 'Industrial Chemistry' },
    { 'school_id': 24, 'value': 'Low Carbon Technologies And Sustainable Chemistry', 'label': 'Low Carbon Technologies And Sustainable Chemistry' },
    { 'school_id': 35, 'value': 'Scienze e Tecnologie della Chimica Industriale', 'label': 'Scienze e Tecnologie della Chimica Industriale' },
    { 'school_id': 82, 'value': 'Conduzione del Mezzo Navale', 'label': 'Conduzione del Mezzo Navale' },
    { 'school_id': 109, 'value': 'Maritime Science And Technology', 'label': 'Maritime Science And Technology' },
    { 'school_id': 72, 'value': 'Scienze e Gestione delle Attivita\' Marittime', 'label': 'Scienze e Gestione delle Attivita\' Marittime' },
    { 'school_id': 128, 'value': 'Scienze e Logistica del Trasporto Marittimo ed Aereo', 'label': 'Scienze e Logistica del Trasporto Marittimo ed Aereo' },
    { 'school_id': 6860, 'value': 'Scienze e Tecnologie dei Trasporti', 'label': 'Scienze e Tecnologie dei Trasporti' },
    { 'school_id': 128, 'value': 'Scienze e Tecnologie della Navigazione', 'label': 'Scienze e Tecnologie della Navigazione' },
    { 'school_id': 82, 'value': 'Scienze e Tecnologie della Navigazione', 'label': 'Scienze e Tecnologie della Navigazione' },
    { 'school_id': 82, 'value': 'Scienze Nautiche, Aeronautiche e Meteo-Oceanografiche', 'label': 'Scienze Nautiche, Aeronautiche e Meteo-Oceanografiche' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Controllo di Qualità', 'label': 'Corso di Laurea in Controllo di Qualità' },
    { 'school_id': 35, 'value': 'Corso di Laurea Interfacoltà in Scienze Erboristiche', 'label': 'Corso di Laurea Interfacoltà in Scienze Erboristiche' },
    { 'school_id': 6874, 'value': 'Informazione Scientifica del Farmaco e dei Prodotti per la Salute', 'label': 'Informazione Scientifica del Farmaco e dei Prodotti per la Salute' },
    { 'school_id': 60, 'value': 'Informazione Scientifica sul Farmaco e Scienze del Fitness e dei Prodotti della Salute', 'label': 'Informazione Scientifica sul Farmaco e Scienze del Fitness e dei Prodotti della Salute' },
    { 'school_id': 23, 'value': 'Scienza della Nutrizione', 'label': 'Scienza della Nutrizione' },
    { 'school_id': 146, 'value': 'Scienze dei Prodotti Erboristici e della Salute', 'label': 'Scienze dei Prodotti Erboristici e della Salute' },
    { 'school_id': 157, 'value': 'Scienze e Sicurezza Chimico-Tossicologiche dell\'Ambiente', 'label': 'Scienze e Sicurezza Chimico-Tossicologiche dell\'Ambiente' },
    { 'school_id': 14336, 'value': 'Scienze e Tecnologie Cosmetologiche', 'label': 'Scienze e Tecnologie Cosmetologiche' },
    { 'school_id': 157, 'value': 'Scienze e Tecnologie Erboristiche', 'label': 'Scienze e Tecnologie Erboristiche' },
    { 'school_id': 72, 'value': 'Scienze e Tecnologie Erboristiche e dei Prodotti per la Salute', 'label': 'Scienze e Tecnologie Erboristiche e dei Prodotti per la Salute' },
    { 'school_id': 6833, 'value': 'Scienze Farmaceutiche Applicate', 'label': 'Scienze Farmaceutiche Applicate' },
    { 'school_id': 24, 'value': 'Scienze Farmaceutiche Applicate', 'label': 'Scienze Farmaceutiche Applicate' },
    { 'school_id': 13, 'value': 'Scienze Farmaceutiche Applicate', 'label': 'Scienze Farmaceutiche Applicate' },
    { 'school_id': 81, 'value': 'Scienze Farmaceutiche Applicate', 'label': 'Scienze Farmaceutiche Applicate' },
    { 'school_id': 28, 'value': 'Scienze Farmaceutiche Applicate-Controllo Qualità', 'label': 'Scienze Farmaceutiche Applicate-Controllo Qualità' },
    { 'school_id': 35, 'value': 'Scienze Nutraceutiche', 'label': 'Scienze Nutraceutiche' },
    { 'school_id': 128, 'value': 'Scienze Nutraceutiche e Alimenti Funzionali', 'label': 'Scienze Nutraceutiche e Alimenti Funzionali' },
    { 'school_id': 124, 'value': 'Scienze Tossicologiche e Controllo di Qualità', 'label': 'Scienze Tossicologiche e Controllo di Qualità' },
    { 'school_id': 244, 'value': 'Tecniche Erboristiche', 'label': 'Tecniche Erboristiche' },
    { 'school_id': 38, 'value': 'Tecniche Erboristiche', 'label': 'Tecniche Erboristiche' },
    { 'school_id': 114, 'value': 'Tecnologie Eco-Sostenibili e Tossicologia Ambientale', 'label': 'Tecnologie Eco-Sostenibili e Tossicologia Ambientale' },
    { 'school_id': 6833, 'value': 'Astronomia', 'label': 'Astronomia' },
    { 'school_id': 24, 'value': 'Astronomia', 'label': 'Astronomia' },
    { 'school_id': 175, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 70, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 6833, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 61, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 24, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 223, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 60, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 91, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 72, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 128, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 81, 'value': 'Fisica', 'label': 'Fisica' },
    { 'school_id': 28, 'value': 'Fisica e Astrofisica', 'label': 'Fisica e Astrofisica' },
    { 'school_id': 137, 'value': 'Fisica e Tecnologie Avanzate', 'label': 'Fisica e Tecnologie Avanzate' },
    { 'school_id': 244, 'value': 'Ottica e Optometria', 'label': 'Ottica e Optometria' },
    { 'school_id': 66, 'value': 'Ottica e Optometria', 'label': 'Ottica e Optometria' },
    { 'school_id': 6833, 'value': 'Ottica e Optometria', 'label': 'Ottica e Optometria' },
    { 'school_id': 28, 'value': 'Ottica e Optometria', 'label': 'Ottica e Optometria' },
    { 'school_id': 77, 'value': 'Ottica e Optometria', 'label': 'Ottica e Optometria' },
    { 'school_id': 35, 'value': 'Ottica e Optometria', 'label': 'Ottica e Optometria' },
    { 'school_id': 80, 'value': 'Ottica e Optometria', 'label': 'Ottica e Optometria' },
    { 'school_id': 69, 'value': 'Ottica e Optometria', 'label': 'Ottica e Optometria' },
    { 'school_id': 109, 'value': 'Scienza dei Materiali', 'label': 'Scienza dei Materiali' },
    { 'school_id': 151, 'value': 'Scienza dei Materiali', 'label': 'Scienza dei Materiali' },
    { 'school_id': 6874, 'value': 'Scienza dei Materiali Innovativi e per le Nanotecnologie', 'label': 'Scienza dei Materiali Innovativi e per le Nanotecnologie' },
    { 'school_id': 69, 'value': 'Scienze Fisiche', 'label': 'Scienze Fisiche' },
    { 'school_id': 46, 'value': 'Conservazione e Restauro dell\'Ambiente e delle Foreste', 'label': 'Conservazione e Restauro dell\'Ambiente e delle Foreste' },
    { 'school_id': 6833, 'value': 'Forest Science - Scienze Forestali', 'label': 'Forest Science - Scienze Forestali' },
    { 'school_id': 46, 'value': 'Forestry And Environmental Sciences', 'label': 'Forestry And Environmental Sciences' },
    { 'school_id': 273, 'value': 'Gestione Sostenibile dell\'Ambiente Montano', 'label': 'Gestione Sostenibile dell\'Ambiente Montano' },
    { 'school_id': 24, 'value': 'Progettazione e Gestione degli Ecosistemi Agro-Territoriali, Forestali e del Paesaggio', 'label': 'Progettazione e Gestione degli Ecosistemi Agro-Territoriali, Forestali e del Paesaggio' },
    { 'school_id': 157, 'value': 'Scienze Agroambientali', 'label': 'Scienze Agroambientali' },
    { 'school_id': 244, 'value': 'Scienze e Tecnologie dei Sistemi e Territori Forestali', 'label': 'Scienze e Tecnologie dei Sistemi e Territori Forestali' },
    { 'school_id': 28, 'value': 'Scienze e Tecnologie dei Sistemi Forestali', 'label': 'Scienze e Tecnologie dei Sistemi Forestali' },
    { 'school_id': 69, 'value': 'Scienze e Tecnologie Forestali e Agro-Ambientali', 'label': 'Scienze e Tecnologie Forestali e Agro-Ambientali' },
    { 'school_id': 198, 'value': 'Scienze e Tecnologie Forestali ed Ambientali', 'label': 'Scienze e Tecnologie Forestali ed Ambientali' },
    { 'school_id': 6833, 'value': 'Scienze Forestali e Ambientali', 'label': 'Scienze Forestali e Ambientali' },
    { 'school_id': 35, 'value': 'Scienze Forestali ed Ambientali', 'label': 'Scienze Forestali ed Ambientali' },
    { 'school_id': 6837, 'value': 'Scienze Forestali, dei Suoli e del Paesaggio', 'label': 'Scienze Forestali, dei Suoli e del Paesaggio' },
    { 'school_id': 115, 'value': 'Sistemi Forestali e Ambientali', 'label': 'Sistemi Forestali e Ambientali' },
    { 'school_id': 35, 'value': 'Corso di Laurea Magistrale in Geologia e Geologia Applicata', 'label': 'Corso di Laurea Magistrale in Geologia e Geologia Applicata' },
    { 'school_id': 23, 'value': 'Geologia Ambientale e Gestione del Territorio', 'label': 'Geologia Ambientale e Gestione del Territorio' },
    { 'school_id': 13, 'value': 'Geologia Applicata all\'Ingegneria, al Territorio e Ai Rischi', 'label': 'Geologia Applicata all\'Ingegneria, al Territorio e Ai Rischi' },
    { 'school_id': 77, 'value': 'Geologia del Territorio e delle Risorse', 'label': 'Geologia del Territorio e delle Risorse' },
    { 'school_id': 13, 'value': 'Geologia di Esplorazione', 'label': 'Geologia di Esplorazione' },
    { 'school_id': 6833, 'value': 'Geologia e Geologia Tecnica', 'label': 'Geologia e Geologia Tecnica' },
    { 'school_id': 24, 'value': 'Geologia e Territorio', 'label': 'Geologia e Territorio' },
    { 'school_id': 20, 'value': 'Geology For Energy Resources', 'label': 'Geology For Energy Resources' },
    { 'school_id': 69, 'value': 'Georischi e Georisorse', 'label': 'Georischi e Georisorse' },
    { 'school_id': 55, 'value': 'Geoscienze', 'label': 'Geoscienze' },
    { 'school_id': 137, 'value': 'Geoscienze e Geologia Applicata', 'label': 'Geoscienze e Geologia Applicata' },
    { 'school_id': 478, 'value': 'Geoscienze e Georisorse', 'label': 'Geoscienze e Georisorse' },
    { 'school_id': 61, 'value': 'Geoscienze, Georischi e Georisorse', 'label': 'Geoscienze, Georischi e Georisorse' },
    { 'school_id': 6859, 'value': 'Geotecnologie per le Risorse, l\'Ambiente e i Rischi', 'label': 'Geotecnologie per le Risorse, l\'Ambiente e i Rischi' },
    { 'school_id': 60, 'value': 'Risorse e Rischi Geoambientali - Geoenvironmental Resources And Risks', 'label': 'Risorse e Rischi Geoambientali - Geoenvironmental Resources And Risks' },
    { 'school_id': 157, 'value': 'Scienze della Terra', 'label': 'Scienze della Terra' },
    { 'school_id': 20, 'value': 'Scienze della Terra per la Gestione dei Rischi e dell\'Ambiente', 'label': 'Scienze della Terra per la Gestione dei Rischi e dell\'Ambiente' },
    { 'school_id': 66, 'value': 'Scienze e Tecnologie Geologiche', 'label': 'Scienze e Tecnologie Geologiche' },
    { 'school_id': 28, 'value': 'Scienze e Tecnologie Geologiche', 'label': 'Scienze e Tecnologie Geologiche' },
    { 'school_id': 146, 'value': 'Scienze e Tecnologie Geologiche', 'label': 'Scienze e Tecnologie Geologiche' },
    { 'school_id': 124, 'value': 'Scienze e Tecnologie Geologiche', 'label': 'Scienze e Tecnologie Geologiche' },
    { 'school_id': 114, 'value': 'Scienze e Tecnologie Geologiche della Terra e dei Pianeti', 'label': 'Scienze e Tecnologie Geologiche della Terra e dei Pianeti' },
    { 'school_id': 109, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 6874, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 81, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 244, 'value': 'Scienze Geologiche Applicate', 'label': 'Scienze Geologiche Applicate' },
    { 'school_id': 175, 'value': 'Scienze Geologiche Applicate', 'label': 'Scienze Geologiche Applicate' },
    { 'school_id': 74, 'value': 'Scienze Geologiche Applicate alla Sostenibilita\' Ambientale', 'label': 'Scienze Geologiche Applicate alla Sostenibilita\' Ambientale' },
    { 'school_id': 72, 'value': 'Scienze Geologiche e Geofisiche', 'label': 'Scienze Geologiche e Geofisiche' },
    { 'school_id': 223, 'value': 'Scienze Geologiche, Georisorse e Territorio', 'label': 'Scienze Geologiche, Georisorse e Territorio' },
    { 'school_id': 13, 'value': 'Applied Computer Science And Artificial Intelligence', 'label': 'Applied Computer Science And Artificial Intelligence' },
    { 'school_id': 6830, 'value': 'Bioinformatica', 'label': 'Bioinformatica' },
    { 'school_id': 109, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 273, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 6830, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 6831, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 74, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 223, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 60, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 13, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 82, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 198, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 72, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 6874, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 128, 'value': 'Informatica', 'label': 'Informatica' },
    { 'school_id': 72, 'value': 'Informatica e Comunicazione Digitale', 'label': 'Informatica e Comunicazione Digitale' },
    { 'school_id': 273, 'value': 'Informatica e Management delle Aziende Digitali', 'label': 'Informatica e Management delle Aziende Digitali' },
    { 'school_id': 72, 'value': 'Informatica e Tecnologie per la Produzione del Software', 'label': 'Informatica e Tecnologie per la Produzione del Software' },
    { 'school_id': 157, 'value': 'Informatica Musicale', 'label': 'Informatica Musicale' },
    { 'school_id': 24, 'value': 'Informatica per il Management', 'label': 'Informatica per il Management' },
    { 'school_id': 157, 'value': 'Informatica per la Comunicazione Digitale', 'label': 'Informatica per la Comunicazione Digitale' },
    { 'school_id': 55, 'value': 'Intelligenza Artificiale e Data Analytics', 'label': 'Intelligenza Artificiale e Data Analytics' },
    { 'school_id': 131, 'value': 'Internet Of Things, Big Data, Machine Learning', 'label': 'Internet Of Things, Big Data, Machine Learning' },
    { 'school_id': 478, 'value': 'Scienze e Tecnologie Informatiche', 'label': 'Scienze e Tecnologie Informatiche' },
    { 'school_id': 157, 'value': 'Sicurezza dei Sistemi e delle Reti Informatiche', 'label': 'Sicurezza dei Sistemi e delle Reti Informatiche' },
    { 'school_id': 59, 'value': 'Tecniche Informatiche per la Gestione dei Dati', 'label': 'Tecniche Informatiche per la Gestione dei Dati' },
    { 'school_id': 69, 'value': 'Analisi e Gestione Ambientale', 'label': 'Analisi e Gestione Ambientale' },
    { 'school_id': 131, 'value': 'Analisi e Gestione dell\'Ambiente', 'label': 'Analisi e Gestione dell\'Ambiente' },
    { 'school_id': 24, 'value': 'Analisi e Gestione dell\'Ambiente', 'label': 'Analisi e Gestione dell\'Ambiente' },
    { 'school_id': 137, 'value': 'Ecotossicologia e Sostenibilità Ambientale', 'label': 'Ecotossicologia e Sostenibilità Ambientale' },
    { 'school_id': 157, 'value': 'Environmental Change And Global Sustainability', 'label': 'Environmental Change And Global Sustainability' },
    { 'school_id': 70, 'value': 'Environmental Meteorology', 'label': 'Environmental Meteorology' },
    { 'school_id': 115, 'value': 'Gestione dell\'Ambiente e del Territorio', 'label': 'Gestione dell\'Ambiente e del Territorio' },
    { 'school_id': 109, 'value': 'Hydrography And Oceanography', 'label': 'Hydrography And Oceanography' },
    { 'school_id': 66, 'value': 'Marine Sciences - Scienze Marine', 'label': 'Marine Sciences - Scienze Marine' },
    { 'school_id': 244, 'value': 'Monitoraggio Ambientale, Tutela e Ripristino', 'label': 'Monitoraggio Ambientale, Tutela e Ripristino' },
    { 'school_id': 13, 'value': 'Monitoraggio e Riqualificazione Ambientale', 'label': 'Monitoraggio e Riqualificazione Ambientale' },
    { 'school_id': 6837, 'value': 'Rischio Ambientale e Protezione Civile', 'label': 'Rischio Ambientale e Protezione Civile' },
    { 'school_id': 81, 'value': 'Salvaguardia del Territorio, dell\'Ambiente e del Paesaggio', 'label': 'Salvaguardia del Territorio, dell\'Ambiente e del Paesaggio' },
    { 'school_id': 148, 'value': 'Scienze Ambientali', 'label': 'Scienze Ambientali' },
    { 'school_id': 6831, 'value': 'Scienze Ambientali', 'label': 'Scienze Ambientali' },
    { 'school_id': 146, 'value': 'Scienze Ambientali', 'label': 'Scienze Ambientali' },
    { 'school_id': 38, 'value': 'Scienze Ambientali', 'label': 'Scienze Ambientali' },
    { 'school_id': 80, 'value': 'Scienze Ambientali', 'label': 'Scienze Ambientali' },
    { 'school_id': 66, 'value': 'Scienze e Tecnologie per l\'Ambiente e il Territorio', 'label': 'Scienze e Tecnologie per l\'Ambiente e il Territorio' },
    { 'school_id': 6833, 'value': 'Scienze e Tecnologie per l\'Ambiente e il Territorio', 'label': 'Scienze e Tecnologie per l\'Ambiente e il Territorio' },
    { 'school_id': 91, 'value': 'Scienze e Tecnologie per l\'Ambiente e il Territorio', 'label': 'Scienze e Tecnologie per l\'Ambiente e il Territorio' },
    { 'school_id': 74, 'value': 'Scienze e Tecnologie per l\'Ambiente e le Risorse', 'label': 'Scienze e Tecnologie per l\'Ambiente e le Risorse' },
    { 'school_id': 35, 'value': 'Tossicologia Chimica e Ambientale', 'label': 'Tossicologia Chimica e Ambientale' },
    { 'school_id': 60, 'value': 'Ambiente e Gestione Sostenibile delle Risorse Naturali', 'label': 'Ambiente e Gestione Sostenibile delle Risorse Naturali' },
    { 'school_id': 24, 'value': 'Scienze Ambientali', 'label': 'Scienze Ambientali' },
    { 'school_id': 13, 'value': 'Scienze Ambientali', 'label': 'Scienze Ambientali' },
    { 'school_id': 91, 'value': 'Scienze Ambientali', 'label': 'Scienze Ambientali' },
    { 'school_id': 72, 'value': 'Scienze Ambientali', 'label': 'Scienze Ambientali' },
    { 'school_id': 109, 'value': 'Scienze Ambientali e Naturali', 'label': 'Scienze Ambientali e Naturali' },
    { 'school_id': 137, 'value': 'Scienze Ambientali e Naturali', 'label': 'Scienze Ambientali e Naturali' },
    { 'school_id': 81, 'value': 'Scienze Ambientali e Naturali', 'label': 'Scienze Ambientali e Naturali' },
    { 'school_id': 124, 'value': 'Scienze Ambientali e Naturali', 'label': 'Scienze Ambientali e Naturali' },
    { 'school_id': 6837, 'value': 'Scienze Ambientali e Protezione Civile', 'label': 'Scienze Ambientali e Protezione Civile' },
    { 'school_id': 128, 'value': 'Scienze Ambientali Marine e Terrestri', 'label': 'Scienze Ambientali Marine e Terrestri' },
    { 'school_id': 148, 'value': 'Scienze dell\'Ambiente e della Natura', 'label': 'Scienze dell\'Ambiente e della Natura' },
    { 'school_id': 72, 'value': 'Scienze della Natura', 'label': 'Scienze della Natura' },
    { 'school_id': 74, 'value': 'Scienze della Natura e dell\'Ambiente', 'label': 'Scienze della Natura e dell\'Ambiente' },
    { 'school_id': 69, 'value': 'Scienze della Natura e dell\'Ambiente', 'label': 'Scienze della Natura e dell\'Ambiente' },
    { 'school_id': 157, 'value': 'Scienze e Politiche Ambientali', 'label': 'Scienze e Politiche Ambientali' },
    { 'school_id': 66, 'value': 'Scienze e Tecnologie per l\'Ambiente', 'label': 'Scienze e Tecnologie per l\'Ambiente' },
    { 'school_id': 6833, 'value': 'Scienze e Tecnologie per l\'Ambiente', 'label': 'Scienze e Tecnologie per l\'Ambiente' },
    { 'school_id': 108, 'value': 'Scienze e Tecnologie per l\'Ambiente', 'label': 'Scienze e Tecnologie per l\'Ambiente' },
    { 'school_id': 80, 'value': 'Scienze e Tecnologie per l\'Ambiente', 'label': 'Scienze e Tecnologie per l\'Ambiente' },
    { 'school_id': 55, 'value': 'Scienze e Tecnologie per l\'Ambiente e la Natura', 'label': 'Scienze e Tecnologie per l\'Ambiente e la Natura' },
    { 'school_id': 175, 'value': 'Scienze e Tecnologie per la Natura', 'label': 'Scienze e Tecnologie per la Natura' },
    { 'school_id': 244, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 157, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 6833, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 61, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 24, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 28, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 13, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 6874, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 115, 'value': 'Scienze Naturali', 'label': 'Scienze Naturali' },
    { 'school_id': 46, 'value': 'Scienze Naturali e Ambientali', 'label': 'Scienze Naturali e Ambientali' },
    { 'school_id': 146, 'value': 'Scienze Naturali ed Ambientali', 'label': 'Scienze Naturali ed Ambientali' },
    { 'school_id': 131, 'value': 'Scienze per l\'Ambiente e la Natura', 'label': 'Scienze per l\'Ambiente e la Natura' },
    { 'school_id': 35, 'value': 'Scienze per la Natura e per l\'Ambiente', 'label': 'Scienze per la Natura e per l\'Ambiente' },
    { 'school_id': 77, 'value': 'Scienze per la Protezione della Natura e la Sostenibilita\' Ambientale', 'label': 'Scienze per la Protezione della Natura e la Sostenibilita\' Ambientale' },
    { 'school_id': 6831, 'value': 'Commercio Estero e Turismo', 'label': 'Commercio Estero e Turismo' },
    { 'school_id': 244, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 63, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 79, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 175, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 77, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 96, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 6874, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 81, 'value': 'Economia', 'label': 'Economia' },
    { 'school_id': 24, 'value': 'Economia del Turismo', 'label': 'Economia del Turismo' },
    { 'school_id': 14336, 'value': 'Economia delle Imprese e dei Mercati', 'label': 'Economia delle Imprese e dei Mercati' },
    { 'school_id': 212, 'value': 'Economia e Azienda Digitale', 'label': 'Economia e Azienda Digitale' },
    { 'school_id': 109, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 66, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 6830, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 6831, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 131, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 6837, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 28, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 146, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 59, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 322, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 82, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 91, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 38, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 72, 'value': 'Economia e Commercio', 'label': 'Economia e Commercio' },
    { 'school_id': 61, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 151, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 80, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 69, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 124, 'value': 'Economia e Finanza', 'label': 'Economia e Finanza' },
    { 'school_id': 114, 'value': 'Economia e Informatica per l\'Impresa', 'label': 'Economia e Informatica per l\'Impresa' },
    { 'school_id': 148, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 82, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 244, 'value': 'Economia e Statistica per le Organizzazioni', 'label': 'Economia e Statistica per le Organizzazioni' },
    { 'school_id': 55, 'value': 'Economia Internazionale e Mercati Finanziari', 'label': 'Economia Internazionale e Mercati Finanziari' },
    { 'school_id': 128, 'value': 'Economia, Banca e Finanza', 'label': 'Economia, Banca e Finanza' },
    { 'school_id': 6830, 'value': 'Economia, Imprese e Mercati Internazionali', 'label': 'Economia, Imprese e Mercati Internazionali' },
    { 'school_id': 458, 'value': 'Economia, Management e Mercati Internazionali', 'label': 'Economia, Management e Mercati Internazionali' },
    { 'school_id': 24, 'value': 'Economia, Mercati e Istituzioni', 'label': 'Economia, Mercati e Istituzioni' },
    { 'school_id': 114, 'value': 'Economia, Mercati e Sviluppo', 'label': 'Economia, Mercati e Sviluppo' },
    { 'school_id': 234, 'value': 'Economia, Territorio e Ambiente', 'label': 'Economia, Territorio e Ambiente' },
    { 'school_id': 6848, 'value': 'Economics And Business', 'label': 'Economics And Business' },
    { 'school_id': 24, 'value': 'Economics And Finance', 'label': 'Economics And Finance' },
    { 'school_id': 14332, 'value': 'Economics, Management And Computer Science', 'label': 'Economics, Management And Computer Science' },
    { 'school_id': 14332, 'value': 'International Economics And Finance', 'label': 'International Economics And Finance' },
    { 'school_id': 13, 'value': 'Relazioni Economiche Internazionali', 'label': 'Relazioni Economiche Internazionali' },
    { 'school_id': 13, 'value': 'Scienze Economiche', 'label': 'Scienze Economiche' },
    { 'school_id': 6851, 'value': 'Scienze Economiche', 'label': 'Scienze Economiche' },
    { 'school_id': 414, 'value': 'Scienze Economiche', 'label': 'Scienze Economiche' },
    { 'school_id': 137, 'value': 'Scienze Economiche e Bancarie', 'label': 'Scienze Economiche e Bancarie' },
    { 'school_id': 273, 'value': 'Scienze Economiche e Sociali', 'label': 'Scienze Economiche e Sociali' },
    { 'school_id': 74, 'value': 'Gestione dei Sistemi Alimentari di Qualita\' e della Gastronomia', 'label': 'Gestione dei Sistemi Alimentari di Qualita\' e della Gastronomia' },
    { 'school_id': 6818, 'value': 'Management e Innovazione per l\'Impresa Alimentare', 'label': 'Management e Innovazione per l\'Impresa Alimentare' },
    { 'school_id': 46, 'value': 'Economia Circolare', 'label': 'Economia Circolare' },
    { 'school_id': 66, 'value': 'Economia del Turismo', 'label': 'Economia del Turismo' },
    { 'school_id': 6831, 'value': 'Economia e Gestione delle Arti e delle Attività Culturali', 'label': 'Economia e Gestione delle Arti e delle Attività Culturali' },
    { 'school_id': 157, 'value': 'Environmental And Food Economics', 'label': 'Environmental And Food Economics' },
    { 'school_id': 24, 'value': 'Innovation And Organization Of Culture And The Arts', 'label': 'Innovation And Organization Of Culture And The Arts' },
    { 'school_id': 35, 'value': 'Management del Patrimonio Culturale', 'label': 'Management del Patrimonio Culturale' },
    { 'school_id': 124, 'value': 'Management e Monitoraggio del Turismo Sostenibile', 'label': 'Management e Monitoraggio del Turismo Sostenibile' },
    { 'school_id': 13, 'value': 'Turismo e Gestione delle Risorse Ambientali', 'label': 'Turismo e Gestione delle Risorse Ambientali' },
    { 'school_id': 273, 'value': 'Accounting e Finanza', 'label': 'Accounting e Finanza' },
    { 'school_id': 28, 'value': 'Accounting e Libera Professione', 'label': 'Accounting e Libera Professione' },
    { 'school_id': 20, 'value': 'Amministrazione Aziendale', 'label': 'Amministrazione Aziendale' },
    { 'school_id': 244, 'value': 'Amministrazione e Controllo Aziendale', 'label': 'Amministrazione e Controllo Aziendale' },
    { 'school_id': 74, 'value': 'Amministrazione e Direzione Aziendale', 'label': 'Amministrazione e Direzione Aziendale' },
    { 'school_id': 24, 'value': 'Amministrazione e Gestione d\'Impresa', 'label': 'Amministrazione e Gestione d\'Impresa' },
    { 'school_id': 138, 'value': 'Amministrazione, Controllo e Professione', 'label': 'Amministrazione, Controllo e Professione' },
    { 'school_id': 108, 'value': 'Amministrazione, Economia e Finanza', 'label': 'Amministrazione, Economia e Finanza' },
    { 'school_id': 14332, 'value': 'Amministrazione, Finanza Aziendale e Controllo - Accounting, Financial Management And Control', 'label': 'Amministrazione, Finanza Aziendale e Controllo - Accounting, Financial Management And Control' },
    { 'school_id': 82, 'value': 'Amministrazione, Finanza e Consulenza Aziendale', 'label': 'Amministrazione, Finanza e Consulenza Aziendale' },
    { 'school_id': 109, 'value': 'Amministrazione, Finanza e Controllo', 'label': 'Amministrazione, Finanza e Controllo' },
    { 'school_id': 6831, 'value': 'Amministrazione, Finanza e Controllo', 'label': 'Amministrazione, Finanza e Controllo' },
    { 'school_id': 46, 'value': 'Amministrazione, Finanza e Controllo', 'label': 'Amministrazione, Finanza e Controllo' },
    { 'school_id': 6848, 'value': 'Amministrazione, Finanza e Controllo', 'label': 'Amministrazione, Finanza e Controllo' },
    { 'school_id': 146, 'value': 'Banca, Finanza Aziendale e Mercati Finanziari', 'label': 'Banca, Finanza Aziendale e Mercati Finanziari' },
    { 'school_id': 14336, 'value': 'Banking And Finance', 'label': 'Banking And Finance' },
    { 'school_id': 14336, 'value': 'Banking e Consulting', 'label': 'Banking e Consulting' },
    { 'school_id': 244, 'value': 'Business Administration', 'label': 'Business Administration' },
    { 'school_id': 6833, 'value': 'Business Administration', 'label': 'Business Administration' },
    { 'school_id': 151, 'value': 'Business Administration-Gestione d\'Impresa', 'label': 'Business Administration-Gestione d\'Impresa' },
    { 'school_id': 36, 'value': 'Business Management - Gestione Aziendale', 'label': 'Business Management - Gestione Aziendale' },
    { 'school_id': 212, 'value': 'Consulenza Aziendale e Libera Professione', 'label': 'Consulenza Aziendale e Libera Professione' },
    { 'school_id': 234, 'value': 'Consulenza e Direzione Aziendale', 'label': 'Consulenza e Direzione Aziendale' },
    { 'school_id': 128, 'value': 'Consulenza e Gestione di Impresa', 'label': 'Consulenza e Gestione di Impresa' },
    { 'school_id': 38, 'value': 'Consulenza e Management Aziendale', 'label': 'Consulenza e Management Aziendale' },
    { 'school_id': 146, 'value': 'Consulenza Professionale alle Aziende', 'label': 'Consulenza Professionale alle Aziende' },
    { 'school_id': 72, 'value': 'Consulenza Professionale per le Aziende', 'label': 'Consulenza Professionale per le Aziende' },
    { 'school_id': 6848, 'value': 'Corporate Finance', 'label': 'Corporate Finance' },
    { 'school_id': 114, 'value': 'Digital Marketing', 'label': 'Digital Marketing' },
    { 'school_id': 24, 'value': 'Direzione Aziendale', 'label': 'Direzione Aziendale' },
    { 'school_id': 81, 'value': 'Direzione Aziendale', 'label': 'Direzione Aziendale' },
    { 'school_id': 244, 'value': 'Direzione d\'Impresa, Marketing e Strategia', 'label': 'Direzione d\'Impresa, Marketing e Strategia' },
    { 'school_id': 14336, 'value': 'Direzione e Consulenza Aziendale', 'label': 'Direzione e Consulenza Aziendale' },
    { 'school_id': 61, 'value': 'Direzione e Consulenza di Impresa', 'label': 'Direzione e Consulenza di Impresa' },
    { 'school_id': 228, 'value': 'Diritto, Economia e Strategia d\'Impresa', 'label': 'Diritto, Economia e Strategia d\'Impresa' },
    { 'school_id': 13, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 77, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 6860, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 115, 'value': 'Economia Aziendale', 'label': 'Economia Aziendale' },
    { 'school_id': 58, 'value': 'Economia Aziendale e Management', 'label': 'Economia Aziendale e Management' },
    { 'school_id': 6874, 'value': 'Economia Aziendale e Management', 'label': 'Economia Aziendale e Management' },
    { 'school_id': 90, 'value': 'Economia Aziendale e Management', 'label': 'Economia Aziendale e Management' },
    { 'school_id': 79, 'value': 'Economia Aziendale, Direzione Amministrativa e Professione', 'label': 'Economia Aziendale, Direzione Amministrativa e Professione' },
    { 'school_id': 72, 'value': 'Economia degli Intermediari e dei Mercati Finanziari', 'label': 'Economia degli Intermediari e dei Mercati Finanziari' },
    { 'school_id': 82, 'value': 'Economia del Mare', 'label': 'Economia del Mare' },
    { 'school_id': 20, 'value': 'Economia e Direzione Aziendale', 'label': 'Economia e Direzione Aziendale' },
    { 'school_id': 27, 'value': 'Economia e Direzione delle Imprese', 'label': 'Economia e Direzione delle Imprese' },
    { 'school_id': 6833, 'value': 'Economia e Diritto', 'label': 'Economia e Diritto' },
    { 'school_id': 322, 'value': 'Economia e Diritto d\'Impresa', 'label': 'Economia e Diritto d\'Impresa' },
    { 'school_id': 137, 'value': 'Economia e Gestione degli Intermediari Finanziari', 'label': 'Economia e Gestione degli Intermediari Finanziari' },
    { 'school_id': 6831, 'value': 'Economia e Gestione delle Aziende', 'label': 'Economia e Gestione delle Aziende' },
    { 'school_id': 175, 'value': 'Economia e Gestione delle Imprese', 'label': 'Economia e Gestione delle Imprese' },
    { 'school_id': 175, 'value': 'Economia e Legislazione d\'Impresa', 'label': 'Economia e Legislazione d\'Impresa' },
    { 'school_id': 70, 'value': 'Economia e Legislazione d\'Impresa', 'label': 'Economia e Legislazione d\'Impresa' },
    { 'school_id': 6830, 'value': 'Economia e Legislazione d\'Impresa', 'label': 'Economia e Legislazione d\'Impresa' },
    { 'school_id': 14332, 'value': 'Economia e Legislazione per l\'Impresa', 'label': 'Economia e Legislazione per l\'Impresa' },
    { 'school_id': 24, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 6837, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 59, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 77, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 6859, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 91, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 72, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 382, 'value': 'Economia e Management', 'label': 'Economia e Management' },
    { 'school_id': 20, 'value': 'Economia e Management Internazionale', 'label': 'Economia e Management Internazionale' },
    { 'school_id': 351, 'value': 'Economia e Management Internazionale', 'label': 'Economia e Management Internazionale' },
    { 'school_id': 109, 'value': 'Economia e Management Marittimo e Portuale', 'label': 'Economia e Management Marittimo e Portuale' },
    { 'school_id': 223, 'value': 'Economia e Management per la Creazione di Valore', 'label': 'Economia e Management per la Creazione di Valore' },
    { 'school_id': 24, 'value': 'Economia e Professione', 'label': 'Economia e Professione' },
    { 'school_id': 74, 'value': 'Economia Internazionale e dello Sviluppo', 'label': 'Economia Internazionale e dello Sviluppo' },
    { 'school_id': 124, 'value': 'Economia Manageriale', 'label': 'Economia Manageriale' },
    { 'school_id': 148, 'value': 'Economia, Diritto e Finanza d\'Impresa', 'label': 'Economia, Diritto e Finanza d\'Impresa' },
    { 'school_id': 13, 'value': 'Economia, Management e Diritto d\'Impresa', 'label': 'Economia, Management e Diritto d\'Impresa' },
    { 'school_id': 389, 'value': 'Economia, Management e Innovazione', 'label': 'Economia, Management e Innovazione' },
    { 'school_id': 110, 'value': 'Economia, Management e Sostenibilità', 'label': 'Economia, Management e Sostenibilità' },
    { 'school_id': 13, 'value': 'Economics And Communication For Management And Innovation', 'label': 'Economics And Communication For Management And Innovation' },
    { 'school_id': 14332, 'value': 'Economics And Management in Arts, Culture, Media And Entertainment', 'label': 'Economics And Management in Arts, Culture, Media And Entertainment' },
    { 'school_id': 14332, 'value': 'Economics And Management Of Government And International Organizations', 'label': 'Economics And Management Of Government And International Organizations' },
    { 'school_id': 6833, 'value': 'Entrepreneurship And Innovation - Imprenditorialita\' e Innovazione', 'label': 'Entrepreneurship And Innovation - Imprenditorialita\' e Innovazione' },
    { 'school_id': 82, 'value': 'Fashion, Art And Food Management', 'label': 'Fashion, Art And Food Management' },
    { 'school_id': 81, 'value': 'Finanza Aziendale', 'label': 'Finanza Aziendale' },
    { 'school_id': 244, 'value': 'Finanza Aziendale e Mercati Finanziari', 'label': 'Finanza Aziendale e Mercati Finanziari' },
    { 'school_id': 74, 'value': 'Finanza e Risk Management', 'label': 'Finanza e Risk Management' },
    { 'school_id': 14336, 'value': 'Food Marketing e Strategie Commerciali', 'label': 'Food Marketing e Strategie Commerciali' },
    { 'school_id': 14336, 'value': 'Gestione d\'Azienda', 'label': 'Gestione d\'Azienda' },
    { 'school_id': 6848, 'value': 'Gestione d\'Impresa', 'label': 'Gestione d\'Impresa' },
    { 'school_id': 132, 'value': 'Gestione d\'Impresa e Tecnologie Digitali', 'label': 'Gestione d\'Impresa e Tecnologie Digitali' },
    { 'school_id': 14336, 'value': 'Global Business Management', 'label': 'Global Business Management' },
    { 'school_id': 148, 'value': 'Global Entrepreneurship Economics And Management /Imprenditorialità, Economia e Management Internazionale (Geem)', 'label': 'Global Entrepreneurship Economics And Management /Imprenditorialità, Economia e Management Internazionale (Geem)' },
    { 'school_id': 6848, 'value': 'Global Management And Politics', 'label': 'Global Management And Politics' },
    { 'school_id': 28, 'value': 'Governo e Direzione d\'Impresa', 'label': 'Governo e Direzione d\'Impresa' },
    { 'school_id': 273, 'value': 'Imprenditorialità e Innovazione', 'label': 'Imprenditorialità e Innovazione' },
    { 'school_id': 35, 'value': 'Innovation And International Management', 'label': 'Innovation And International Management' },
    { 'school_id': 14336, 'value': 'Innovation And Technology Management', 'label': 'Innovation And Technology Management' },
    { 'school_id': 70, 'value': 'Innovation Management - Management dell\'Innovazione', 'label': 'Innovation Management - Management dell\'Innovazione' },
    { 'school_id': 115, 'value': 'Innovation Management For Sustainable Tourism', 'label': 'Innovation Management For Sustainable Tourism' },
    { 'school_id': 128, 'value': 'Innovazione, Imprenditorialita\' e Turismo', 'label': 'Innovazione, Imprenditorialita\' e Turismo' },
    { 'school_id': 13, 'value': 'Intermediari, Finanza Internazionale e Risk Management', 'label': 'Intermediari, Finanza Internazionale e Risk Management' },
    { 'school_id': 137, 'value': 'International Accounting And Management', 'label': 'International Accounting And Management' },
    { 'school_id': 175, 'value': 'International Business And Entrepreneurship - Management Internazionale e Imprenditorialità', 'label': 'International Business And Entrepreneurship - Management Internazionale e Imprenditorialità' },
    { 'school_id': 14332, 'value': 'International Management', 'label': 'International Management' },
    { 'school_id': 70, 'value': 'International Management - Management Internazionale', 'label': 'International Management - Management Internazionale' },
    { 'school_id': 61, 'value': 'International Management - Management Internazionale', 'label': 'International Management - Management Internazionale' },
    { 'school_id': 79, 'value': 'International Management, Entrepreneurship And Finance', 'label': 'International Management, Entrepreneurship And Finance' },
    { 'school_id': 131, 'value': 'International Marketing, Management And Organization', 'label': 'International Marketing, Management And Organization' },
    { 'school_id': 109, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 14332, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 14336, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 212, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 70, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 6831, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 6848, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 447, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 322, 'value': 'Management', 'label': 'Management' },
    { 'school_id': 59, 'value': 'Management And Finance', 'label': 'Management And Finance' },
    { 'school_id': 80, 'value': 'Management Aziendale', 'label': 'Management Aziendale' },
    { 'school_id': 14336, 'value': 'Management dei Servizi', 'label': 'Management dei Servizi' },
    { 'school_id': 24, 'value': 'Management dell\'Economia Sociale', 'label': 'Management dell\'Economia Sociale' },
    { 'school_id': 70, 'value': 'Management della Sostenibilità e del Turismo', 'label': 'Management della Sostenibilità e del Turismo' },
    { 'school_id': 6837, 'value': 'Management della Sostenibilita\' ed Economia Circolare', 'label': 'Management della Sostenibilita\' ed Economia Circolare' },
    { 'school_id': 157, 'value': 'Management delle Aziende Sanitarie e del Settore Salute', 'label': 'Management delle Aziende Sanitarie e del Settore Salute' },
    { 'school_id': 13, 'value': 'Management delle Imprese', 'label': 'Management delle Imprese' },
    { 'school_id': 13, 'value': 'Management delle Tecnologie, Innovazione e Sostenibilita\'', 'label': 'Management delle Tecnologie, Innovazione e Sostenibilita\'' },
    { 'school_id': 61, 'value': 'Management e Comunicazione d\'Impresa', 'label': 'Management e Comunicazione d\'Impresa' },
    { 'school_id': 411, 'value': 'Management e Consulenza Aziendale', 'label': 'Management e Consulenza Aziendale' },
    { 'school_id': 146, 'value': 'Management e Controllo dei Processi Logistici', 'label': 'Management e Controllo dei Processi Logistici' },
    { 'school_id': 138, 'value': 'Management e Finanza', 'label': 'Management e Finanza' },
    { 'school_id': 137, 'value': 'Management e Governance', 'label': 'Management e Governance' },
    { 'school_id': 6830, 'value': 'Management e Strategia d\'Impresa', 'label': 'Management e Strategia d\'Impresa' },
    { 'school_id': 157, 'value': 'Management Of Human Resources', 'label': 'Management Of Human Resources' },
    { 'school_id': 157, 'value': 'Management Of Innovation And Entrepreneurship - Management dell\'Innovazione e Imprenditorialità', 'label': 'Management Of Innovation And Entrepreneurship - Management dell\'Innovazione e Imprenditorialità' },
    { 'school_id': 14336, 'value': 'Management per l\'Impresa', 'label': 'Management per l\'Impresa' },
    { 'school_id': 6837, 'value': 'Management Pubblico e dei Sistemi Socio-Sanitari', 'label': 'Management Pubblico e dei Sistemi Socio-Sanitari' },
    { 'school_id': 79, 'value': 'Management, Finanza e International Business', 'label': 'Management, Finanza e International Business' },
    { 'school_id': 198, 'value': 'Management, Imprenditorialità e Innovazione', 'label': 'Management, Imprenditorialità e Innovazione' },
    { 'school_id': 6848, 'value': 'Marketing', 'label': 'Marketing' },
    { 'school_id': 72, 'value': 'Marketing', 'label': 'Marketing' },
    { 'school_id': 6831, 'value': 'Marketing e Comunicazione', 'label': 'Marketing e Comunicazione' },
    { 'school_id': 6830, 'value': 'Marketing e Comunicazione d\'Impresa', 'label': 'Marketing e Comunicazione d\'Impresa' },
    { 'school_id': 23, 'value': 'Marketing e Comunicazione per le Aziende', 'label': 'Marketing e Comunicazione per le Aziende' },
    { 'school_id': 82, 'value': 'Marketing e Management Internazionale', 'label': 'Marketing e Management Internazionale' },
    { 'school_id': 66, 'value': 'Marketing e Mercati Globali', 'label': 'Marketing e Mercati Globali' },
    { 'school_id': 46, 'value': 'Marketing e Qualità', 'label': 'Marketing e Qualità' },
    { 'school_id': 146, 'value': 'Marketing e Ricerche di Mercato', 'label': 'Marketing e Ricerche di Mercato' },
    { 'school_id': 14332, 'value': 'Marketing Management', 'label': 'Marketing Management' },
    { 'school_id': 96, 'value': 'Marketing Management', 'label': 'Marketing Management' },
    { 'school_id': 234, 'value': 'Mercati e Intermediari Finanziari', 'label': 'Mercati e Intermediari Finanziari' },
    { 'school_id': 14336, 'value': 'Mercati e Strategie d\'Impresa', 'label': 'Mercati e Strategie d\'Impresa' },
    { 'school_id': 244, 'value': 'Professioni Contabili', 'label': 'Professioni Contabili' },
    { 'school_id': 61, 'value': 'Relazioni di Lavoro', 'label': 'Relazioni di Lavoro' },
    { 'school_id': 66, 'value': 'Scienze Economico-Aziendali', 'label': 'Scienze Economico-Aziendali' },
    { 'school_id': 69, 'value': 'Scienze Economico-Aziendali', 'label': 'Scienze Economico-Aziendali' },
    { 'school_id': 55, 'value': 'Strategia e Consulenza Aziendale', 'label': 'Strategia e Consulenza Aziendale' },
    { 'school_id': 146, 'value': 'Strategia, Management e Controllo', 'label': 'Strategia, Management e Controllo' },
    { 'school_id': 72, 'value': 'Strategie d\'Impresa e Management', 'label': 'Strategie d\'Impresa e Management' },
    { 'school_id': 74, 'value': 'Trade e Consumer Marketing', 'label': 'Trade e Consumer Marketing' },
    { 'school_id': 35, 'value': 'Corso di Laurea Magistrale in Filosofia', 'label': 'Corso di Laurea Magistrale in Filosofia' },
    { 'school_id': 138, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 55, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 74, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 108, 'value': 'Filosofia', 'label': 'Filosofia' },
    { 'school_id': 128, 'value': 'Filosofia Contemporanea', 'label': 'Filosofia Contemporanea' },
    { 'school_id': 6825, 'value': 'Filosofia del Mondo Contemporaneo', 'label': 'Filosofia del Mondo Contemporaneo' },
    { 'school_id': 23, 'value': 'Filosofia dell\'Informazione. Teoria e Gestione della Conoscenza', 'label': 'Filosofia dell\'Informazione. Teoria e Gestione della Conoscenza' },
    { 'school_id': 146, 'value': 'Filosofia e Forme del Sapere', 'label': 'Filosofia e Forme del Sapere' },
    { 'school_id': 70, 'value': 'Filosofia e Linguaggi della Modernità', 'label': 'Filosofia e Linguaggi della Modernità' },
    { 'school_id': 79, 'value': 'Filosofia e Storia delle Scienze Naturali e Umane', 'label': 'Filosofia e Storia delle Scienze Naturali e Umane' },
    { 'school_id': 124, 'value': 'Filosofia e Teorie della Comunicazione', 'label': 'Filosofia e Teorie della Comunicazione' },
    { 'school_id': 20, 'value': 'Filosofia ed Etica delle Relazioni', 'label': 'Filosofia ed Etica delle Relazioni' },
    { 'school_id': 28, 'value': 'Logica, Filosofia e Storia della Scienza', 'label': 'Logica, Filosofia e Storia della Scienza' },
    { 'school_id': 109, 'value': 'Metodologie Filosofiche', 'label': 'Metodologie Filosofiche' },
    { 'school_id': 157, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 6830, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 6831, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 6833, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 24, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 234, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 28, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 77, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 114, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 72, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 80, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 6874, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 81, 'value': 'Scienze Filosofiche', 'label': 'Scienze Filosofiche' },
    { 'school_id': 69, 'value': 'Scienze Filosofiche e Storiche', 'label': 'Scienze Filosofiche e Storiche' },
    { 'school_id': 115, 'value': 'Scienze Storiche e Filosofiche', 'label': 'Scienze Storiche e Filosofiche' },
    { 'school_id': 137, 'value': 'Storia e Filosofia', 'label': 'Storia e Filosofia' },
    { 'school_id': 146, 'value': 'Geofisica di Esplorazione e Applicata', 'label': 'Geofisica di Esplorazione e Applicata' },
    { 'school_id': 128, 'value': 'Geophysical Sciences For Seismic Risk', 'label': 'Geophysical Sciences For Seismic Risk' },
    { 'school_id': 6833, 'value': 'Geophysics For Natural Risks And Resources', 'label': 'Geophysics For Natural Risks And Resources' },
    { 'school_id': 81, 'value': 'Scienze Geofisiche', 'label': 'Scienze Geofisiche' },
    { 'school_id': 24, 'value': 'Geografia e Processi Territoriali', 'label': 'Geografia e Processi Territoriali' },
    { 'school_id': 244, 'value': 'Geografia e Scienze Territoriali', 'label': 'Geografia e Scienze Territoriali' },
    { 'school_id': 28, 'value': 'Geography, Spatial Management, Heritage For International Cooperation', 'label': 'Geography, Spatial Management, Heritage For International Cooperation' },
    { 'school_id': 13, 'value': 'Gestione e Valorizzazione del Territorio', 'label': 'Gestione e Valorizzazione del Territorio' },
    { 'school_id': 6833, 'value': 'Scienze per il Paesaggio', 'label': 'Scienze per il Paesaggio' },
    { 'school_id': 109, 'value': 'Valorizzazione dei Territori e Turismi Sostenibili', 'label': 'Valorizzazione dei Territori e Turismi Sostenibili' },
    { 'school_id': 55, 'value': 'Geologia', 'label': 'Geologia' },
    { 'school_id': 20, 'value': 'Geologia', 'label': 'Geologia' },
    { 'school_id': 124, 'value': 'Geologia', 'label': 'Geologia' },
    { 'school_id': 6859, 'value': 'Geologia per la Sostenibilità Ambientale', 'label': 'Geologia per la Sostenibilità Ambientale' },
    { 'school_id': 244, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 157, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 175, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 6833, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 74, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 61, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 24, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 223, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 28, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 146, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 137, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 13, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 77, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 35, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 114, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 72, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 478, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 69, 'value': 'Scienze Geologiche', 'label': 'Scienze Geologiche' },
    { 'school_id': 60, 'value': 'Scienze Geologiche e Tecnologie per l\'Ambiente', 'label': 'Scienze Geologiche e Tecnologie per l\'Ambiente' },
    { 'school_id': 146, 'value': 'Diritto dell\'Innovazione per l\'Impresa e le Istituzioni', 'label': 'Diritto dell\'Innovazione per l\'Impresa e le Istituzioni' },
    { 'school_id': 66, 'value': 'Diritto delle Organizzazioni Pubbliche e Private', 'label': 'Diritto delle Organizzazioni Pubbliche e Private' },
    { 'school_id': 72, 'value': 'Diritto dello Sviluppo Sostenibile', 'label': 'Diritto dello Sviluppo Sostenibile' },
    { 'school_id': 157, 'value': 'Law And Sustainable Development', 'label': 'Law And Sustainable Development' },
    { 'school_id': 6848, 'value': 'Law, Digital Innovation And Sustainability', 'label': 'Law, Digital Innovation And Sustainability' },
    { 'school_id': 24, 'value': 'Legal Studies', 'label': 'Legal Studies' },
    { 'school_id': 13, 'value': 'Risorse Umane, Scienze del Lavoro e Innovazione', 'label': 'Risorse Umane, Scienze del Lavoro e Innovazione' },
    { 'school_id': 212, 'value': 'Scienze Giuridiche dell\'Innovazione', 'label': 'Scienze Giuridiche dell\'Innovazione' },
    { 'school_id': 96, 'value': 'Scienze Giuridiche della Sicurezza', 'label': 'Scienze Giuridiche della Sicurezza' },
    { 'school_id': 234, 'value': 'Scienze Giuridiche per l\'Innovazione', 'label': 'Scienze Giuridiche per l\'Innovazione' },
    { 'school_id': 114, 'value': 'Scienze Giuridiche per l\'Internazionalizzazione e l\'Innovazione dell\'Impresa', 'label': 'Scienze Giuridiche per l\'Internazionalizzazione e l\'Innovazione dell\'Impresa' },
    { 'school_id': 244, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 138, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 109, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 157, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 14336, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 6825, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 66, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 6827, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 212, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 175, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 6830, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 6833, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 74, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 61, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 24, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 223, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 6837, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 28, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 146, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 137, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 20, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 13, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 151, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 35, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 91, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 108, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 114, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 198, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 72, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 90, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 69, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 128, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 81, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 115, 'value': 'Scienze Infermieristiche e Ostetriche', 'label': 'Scienze Infermieristiche e Ostetriche' },
    { 'school_id': 35, 'value': 'Corso di Laurea in Matematica', 'label': 'Corso di Laurea in Matematica' },
    { 'school_id': 137, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 151, 'value': 'Matematica', 'label': 'Matematica' },
    { 'school_id': 6830, 'value': 'Matematica Applicata', 'label': 'Matematica Applicata' },
    { 'school_id': 60, 'value': 'Matematica e Applicazioni', 'label': 'Matematica e Applicazioni' },
    { 'school_id': 18, 'value': 'Matematica per l\'Ingegneria', 'label': 'Matematica per l\'Ingegneria' },
    { 'school_id': 244, 'value': 'Matematica per la Finanza e l\'Assicurazione', 'label': 'Matematica per la Finanza e l\'Assicurazione' },
    { 'school_id': 14332, 'value': 'Mathematical And Computing Sciences For Artificial Intelligence', 'label': 'Mathematical And Computing Sciences For Artificial Intelligence' },
    { 'school_id': 151, 'value': 'Scienze e Tecnologie per i Media', 'label': 'Scienze e Tecnologie per i Media' },
    { 'school_id': 109, 'value': 'Statistica Matematica e Trattamento Informatico dei Dati (Smid)', 'label': 'Statistica Matematica e Trattamento Informatico dei Dati (Smid)' },
    { 'school_id': 20, 'value': 'Consulenza Pedagogica e Coordinamento di Interventi Formativi', 'label': 'Consulenza Pedagogica e Coordinamento di Interventi Formativi' },
    { 'school_id': 6833, 'value': 'Culture, Formazione e Societa\' Globale', 'label': 'Culture, Formazione e Societa\' Globale' },
    { 'school_id': 24, 'value': 'Pedagogia', 'label': 'Pedagogia' },
    { 'school_id': 23, 'value': 'Pedagogia', 'label': 'Pedagogia' },
    { 'school_id': 6851, 'value': 'Pedagogia', 'label': 'Pedagogia' },
    { 'school_id': 13, 'value': 'Pedagogia e Scienze dell\'Educazione e della Formazione', 'label': 'Pedagogia e Scienze dell\'Educazione e della Formazione' },
    { 'school_id': 109, 'value': 'Pedagogia, Progettazione e Ricerca Educativa', 'label': 'Pedagogia, Progettazione e Ricerca Educativa' },
    { 'school_id': 244, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 63, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 66, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 79, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 6830, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 61, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 234, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 151, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 169, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 322, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 208, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 38, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 114, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 72, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 6874, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 69, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 128, 'value': 'Scienze Pedagogiche', 'label': 'Scienze Pedagogiche' },
    { 'school_id': 81, 'value': 'Scienze Pedagogiche e Progettazione Educativa', 'label': 'Scienze Pedagogiche e Progettazione Educativa' },
    { 'school_id': 14336, 'value': 'Scienze Pedagogiche e Servizi alla Persona', 'label': 'Scienze Pedagogiche e Servizi alla Persona' },
    { 'school_id': 137, 'value': 'Scienze per la Formazione e la Consulenza Pedagogica nelle Organizzazioni', 'label': 'Scienze per la Formazione e la Consulenza Pedagogica nelle Organizzazioni' },
    { 'school_id': 13, 'value': 'Archaeological Materials Science', 'label': 'Archaeological Materials Science' },
    { 'school_id': 6831, 'value': 'Conservation Science And Technology For Cultural Heritage', 'label': 'Conservation Science And Technology For Cultural Heritage' },
    { 'school_id': 109, 'value': 'Metodologie per la Conservazione e il Restauro dei Beni Culturali', 'label': 'Metodologie per la Conservazione e il Restauro dei Beni Culturali' },
    { 'school_id': 24, 'value': 'Science For The Conservation-Restoration Of Cultural Heritage', 'label': 'Science For The Conservation-Restoration Of Cultural Heritage' },
    { 'school_id': 28, 'value': 'Scienze e Materiali per la Conservazione e il Restauro', 'label': 'Scienze e Materiali per la Conservazione e il Restauro' },
    { 'school_id': 13, 'value': 'Scienze e Tecnologie per la Conservazione dei Beni Culturali', 'label': 'Scienze e Tecnologie per la Conservazione dei Beni Culturali' },
    { 'school_id': 157, 'value': 'Scienze per la Conservazione e la Diagnostica dei Beni Culturali', 'label': 'Scienze per la Conservazione e la Diagnostica dei Beni Culturali' },
    { 'school_id': 244, 'value': 'Area & Global Studies For International Cooperation', 'label': 'Area & Global Studies For International Cooperation' },
    { 'school_id': 69, 'value': 'Cooperazione, Sviluppo e Migrazioni', 'label': 'Cooperazione, Sviluppo e Migrazioni' },
    { 'school_id': 6831, 'value': 'Crossing The Mediterranean: Towards Investment And Integration (Mim)', 'label': 'Crossing The Mediterranean: Towards Investment And Integration (Mim)' },
    { 'school_id': 79, 'value': 'Diritti dell\'Uomo, delle Migrazioni e della Cooperazione Internazionale', 'label': 'Diritti dell\'Uomo, delle Migrazioni e della Cooperazione Internazionale' },
    { 'school_id': 115, 'value': 'Gestione dei Flussi Migratori', 'label': 'Gestione dei Flussi Migratori' },
    { 'school_id': 24, 'value': 'International Cooperation On Human Rights And Intercultural Heritage', 'label': 'International Cooperation On Human Rights And Intercultural Heritage' },
    { 'school_id': 6833, 'value': 'Local Development', 'label': 'Local Development' },
    { 'school_id': 14336, 'value': 'Politiche per la Cooperazione Internazionale allo Sviluppo', 'label': 'Politiche per la Cooperazione Internazionale allo Sviluppo' },
    { 'school_id': 137, 'value': 'Public And Cultural Diplomacy - Diplomazia Pubblica e Culturale', 'label': 'Public And Cultural Diplomacy - Diplomazia Pubblica e Culturale' },
    { 'school_id': 13, 'value': 'Scienze dello Sviluppo e della Cooperazione Internazionale', 'label': 'Scienze dello Sviluppo e della Cooperazione Internazionale' },
    { 'school_id': 6874, 'value': 'Scienze per la Cooperazione e Lo Sviluppo', 'label': 'Scienze per la Cooperazione e Lo Sviluppo' },
    { 'school_id': 146, 'value': 'Scienze per la Pace: Trasformazione dei Conflitti e Cooperazione allo Sviluppo', 'label': 'Scienze per la Pace: Trasformazione dei Conflitti e Cooperazione allo Sviluppo' },
    { 'school_id': 6833, 'value': 'Sustainable Territorial Development – Climate Change, Diversity, Cooperation', 'label': 'Sustainable Territorial Development – Climate Change, Diversity, Cooperation' },
    { 'school_id': 24, 'value': 'Sviluppo Locale e Globale', 'label': 'Sviluppo Locale e Globale' },
    { 'school_id': 14332, 'value': 'International Politics And Government', 'label': 'International Politics And Government' },
    { 'school_id': 157, 'value': 'International Politics, Law And Economics', 'label': 'International Politics, Law And Economics' },
    { 'school_id': 151, 'value': 'Scienze dell\'Amministrazione e delle Relazioni Internazionali', 'label': 'Scienze dell\'Amministrazione e delle Relazioni Internazionali' },
    { 'school_id': 109, 'value': 'Scienze Internazionali e Diplomatiche', 'label': 'Scienze Internazionali e Diplomatiche' },
    { 'school_id': 55, 'value': 'Scienze Internazionali e Diplomatiche', 'label': 'Scienze Internazionali e Diplomatiche' },
    { 'school_id': 157, 'value': 'Scienze Internazionali e Istituzioni Europee', 'label': 'Scienze Internazionali e Istituzioni Europee' },
    { 'school_id': 244, 'value': 'Scienze Internazionali, dello Sviluppo e della Cooperazione', 'label': 'Scienze Internazionali, dello Sviluppo e della Cooperazione' },
    { 'school_id': 157, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 6833, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 28, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 146, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 137, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 6848, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 35, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 228, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 72, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 6874, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 115, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 124, 'value': 'Scienze Politiche', 'label': 'Scienze Politiche' },
    { 'school_id': 407, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 14336, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 175, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 74, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 46, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 6851, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 38, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 80, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 69, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 128, 'value': 'Scienze Politiche e delle Relazioni Internazionali', 'label': 'Scienze Politiche e delle Relazioni Internazionali' },
    { 'school_id': 59, 'value': 'Scienze Politiche e Internazionali', 'label': 'Scienze Politiche e Internazionali' },
    { 'school_id': 234, 'value': 'Scienze Politiche e Relazioni Internazionali', 'label': 'Scienze Politiche e Relazioni Internazionali' },
    { 'school_id': 20, 'value': 'Scienze Politiche e Relazioni Internazionali', 'label': 'Scienze Politiche e Relazioni Internazionali' },
    { 'school_id': 13, 'value': 'Scienze Politiche e Relazioni Internazionali', 'label': 'Scienze Politiche e Relazioni Internazionali' },
    { 'school_id': 77, 'value': 'Scienze Politiche e Relazioni Internazionali', 'label': 'Scienze Politiche e Relazioni Internazionali' },
    { 'school_id': 447, 'value': 'Scienze Politiche e Relazioni Internazionali', 'label': 'Scienze Politiche e Relazioni Internazionali' },
    { 'school_id': 169, 'value': 'Scienze Politiche e Relazioni Internazionali', 'label': 'Scienze Politiche e Relazioni Internazionali' },
    { 'school_id': 12, 'value': 'Scienze Politiche e Relazioni Internazionali', 'label': 'Scienze Politiche e Relazioni Internazionali' },
    { 'school_id': 244, 'value': 'Scienze Politiche e Sociali', 'label': 'Scienze Politiche e Sociali' },
    { 'school_id': 63, 'value': 'Scienze Politiche e Sociali', 'label': 'Scienze Politiche e Sociali' },
    { 'school_id': 77, 'value': 'Scienze Politiche per il Governo e l\'Amministrazione', 'label': 'Scienze Politiche per il Governo e l\'Amministrazione' },
    { 'school_id': 77, 'value': 'Scienze Politiche per la Cooperazione e Lo Sviluppo', 'label': 'Scienze Politiche per la Cooperazione e Lo Sviluppo' },
    { 'school_id': 23, 'value': 'Scienze Politiche, Economiche e del Governo', 'label': 'Scienze Politiche, Economiche e del Governo' },
    { 'school_id': 6833, 'value': 'Scienze Politiche, Relazioni Internazionali, Diritti Umani', 'label': 'Scienze Politiche, Relazioni Internazionali, Diritti Umani' },
    { 'school_id': 24, 'value': 'Scienze Politiche, Sociali e Internazionali', 'label': 'Scienze Politiche, Sociali e Internazionali' },
    { 'school_id': 81, 'value': 'Storia, Politica e Relazioni Internazionali', 'label': 'Storia, Politica e Relazioni Internazionali' },
    { 'school_id': 38, 'value': 'Studi Diplomatici, Internazionali e Sulla Sicurezza Globale', 'label': 'Studi Diplomatici, Internazionali e Sulla Sicurezza Globale' },
    { 'school_id': 70, 'value': 'Studi Internazionali', 'label': 'Studi Internazionali' },
    { 'school_id': 244, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 109, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 157, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 14336, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 6825, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 6830, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 6833, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 223, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 28, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 146, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 137, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 20, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 13, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 151, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 35, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 108, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 72, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 69, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 128, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 81, 'value': 'Scienze Riabilitative delle Professioni Sanitarie', 'label': 'Scienze Riabilitative delle Professioni Sanitarie' },
    { 'school_id': 13, 'value': 'Cooperazione Internazionale e Sviluppo', 'label': 'Cooperazione Internazionale e Sviluppo' },
    { 'school_id': 146, 'value': 'Scienze per la Pace: Cooperazione Internazionale e Trasformazione dei Conflitti', 'label': 'Scienze per la Pace: Cooperazione Internazionale e Trasformazione dei Conflitti' },
    { 'school_id': 60, 'value': 'Scienze Sociali per Gli Enti Non-Profit e la Cooperazione Internazionale', 'label': 'Scienze Sociali per Gli Enti Non-Profit e la Cooperazione Internazionale' },
    { 'school_id': 157, 'value': 'Scienze Sociali per la Globalizzazione', 'label': 'Scienze Sociali per la Globalizzazione' },
    { 'school_id': 541, 'value': 'Studi Internazionali per la Sostenibilita\' e la Sicurezza Sociale', 'label': 'Studi Internazionali per la Sostenibilita\' e la Sicurezza Sociale' },
    { 'school_id': 24, 'value': 'Sviluppo e Cooperazione Internazionale', 'label': 'Sviluppo e Cooperazione Internazionale' },
    { 'school_id': 69, 'value': 'Sviluppo Economico, Cooperazione Internazionale e Migrazioni', 'label': 'Sviluppo Economico, Cooperazione Internazionale e Migrazioni' },
    { 'school_id': 28, 'value': 'Sviluppo Economico, Cooperazione Internazionale Socio- Sanitaria e Gestione dei Conflitti', 'label': 'Sviluppo Economico, Cooperazione Internazionale Socio- Sanitaria e Gestione dei Conflitti' },
    { 'school_id': 66, 'value': 'Biostatistica', 'label': 'Biostatistica' },
    { 'school_id': 14336, 'value': 'Data Analytics For Business', 'label': 'Data Analytics For Business' },
    { 'school_id': 14332, 'value': 'Data Science And Business Analytics', 'label': 'Data Science And Business Analytics' },
    { 'school_id': 244, 'value': 'Metodi Statistici ed Economici per le Decisioni', 'label': 'Metodi Statistici ed Economici per le Decisioni' },
    { 'school_id': 6833, 'value': 'Scienze Statistiche', 'label': 'Scienze Statistiche' },
    { 'school_id': 13, 'value': 'Scienze Statistiche', 'label': 'Scienze Statistiche' },
    { 'school_id': 66, 'value': 'Scienze Statistiche ed Economiche', 'label': 'Scienze Statistiche ed Economiche' },
    { 'school_id': 35, 'value': 'Scienze Statistiche per le Decisioni', 'label': 'Scienze Statistiche per le Decisioni' },
    { 'school_id': 137, 'value': 'Scienze Statistiche per le Indagini Campionarie', 'label': 'Scienze Statistiche per le Indagini Campionarie' },
    { 'school_id': 28, 'value': 'Statistica e Data Science', 'label': 'Statistica e Data Science' },
    { 'school_id': 69, 'value': 'Statistica e Data Science', 'label': 'Statistica e Data Science' },
    { 'school_id': 6874, 'value': 'Statistica ed Informatica per le Decisioni e le Analisi di Mercato', 'label': 'Statistica ed Informatica per le Decisioni e le Analisi di Mercato' },
    { 'school_id': 24, 'value': 'Statistica, Economia e Impresa', 'label': 'Statistica, Economia e Impresa' },
    { 'school_id': 13, 'value': 'Statistical Methods And Applications', 'label': 'Statistical Methods And Applications' },
    { 'school_id': 24, 'value': 'Statistical Sciences', 'label': 'Statistical Sciences' },
    { 'school_id': 82, 'value': 'Metodi Quantitativi per le Valutazioni Economiche e Finanziarie', 'label': 'Metodi Quantitativi per le Valutazioni Economiche e Finanziarie' },
    { 'school_id': 244, 'value': 'Quantitative Finance And Insurance - Finanza Quantitativa e Assicurazioni', 'label': 'Quantitative Finance And Insurance - Finanza Quantitativa e Assicurazioni' },
    { 'school_id': 13, 'value': 'Scienze Attuariali e Finanziarie', 'label': 'Scienze Attuariali e Finanziarie' },
    { 'school_id': 55, 'value': 'Scienze Statistiche e Attuariali', 'label': 'Scienze Statistiche e Attuariali' },
    { 'school_id': 6859, 'value': 'Scienze Statistiche e Attuariali', 'label': 'Scienze Statistiche e Attuariali' },
    { 'school_id': 38, 'value': 'Scienze Statistiche per la Finanza', 'label': 'Scienze Statistiche per la Finanza' },
    { 'school_id': 24, 'value': 'Scienze Statistiche, Finanziarie e Attuariali', 'label': 'Scienze Statistiche, Finanziarie e Attuariali' },
    { 'school_id': 72, 'value': 'Statistica e Metodi per l\'Economia e la Finanza', 'label': 'Statistica e Metodi per l\'Economia e la Finanza' },
    { 'school_id': 14336, 'value': 'Statistical And Actuarial Sciences', 'label': 'Statistical And Actuarial Sciences' },
    { 'school_id': 234, 'value': 'Ricerca Storica e Risorse della Memoria', 'label': 'Ricerca Storica e Risorse della Memoria' },
    { 'school_id': 151, 'value': 'Scienze della Storia e del Documento', 'label': 'Scienze della Storia e del Documento' },
    { 'school_id': 244, 'value': 'Scienze Storiche', 'label': 'Scienze Storiche' },
    { 'school_id': 109, 'value': 'Scienze Storiche', 'label': 'Scienze Storiche' },
    { 'school_id': 157, 'value': 'Scienze Storiche', 'label': 'Scienze Storiche' },
    { 'school_id': 70, 'value': 'Scienze Storiche', 'label': 'Scienze Storiche' },
    { 'school_id': 6833, 'value': 'Scienze Storiche', 'label': 'Scienze Storiche' },
    { 'school_id': 28, 'value': 'Scienze Storiche', 'label': 'Scienze Storiche' },
    { 'school_id': 35, 'value': 'Scienze Storiche', 'label': 'Scienze Storiche' },
    { 'school_id': 6874, 'value': 'Scienze Storiche', 'label': 'Scienze Storiche' },
    { 'school_id': 24, 'value': 'Scienze Storiche e Orientalistiche', 'label': 'Scienze Storiche e Orientalistiche' },
    { 'school_id': 128, 'value': 'Scienze Storiche: Società, Culture e Istituzioni d\'Europa', 'label': 'Scienze Storiche: Società, Culture e Istituzioni d\'Europa' },
    { 'school_id': 13, 'value': 'Scienze Storiche. Medioevo, Età Moderna, Età Contemporanea', 'label': 'Scienze Storiche. Medioevo, Età Moderna, Età Contemporanea' },
    { 'school_id': 175, 'value': 'Storia d\'Europa', 'label': 'Storia d\'Europa' },
    { 'school_id': 6831, 'value': 'Storia Dal Medioevo all\'Età Contemporanea', 'label': 'Storia Dal Medioevo all\'Età Contemporanea' },
    { 'school_id': 146, 'value': 'Storia e Civiltà', 'label': 'Storia e Civiltà' },
    { 'school_id': 478, 'value': 'Storia e Civiltà Europee', 'label': 'Storia e Civiltà Europee' },
    { 'school_id': 81, 'value': 'Storia e Cultura dei Paesi Mediterranei', 'label': 'Storia e Cultura dei Paesi Mediterranei' },
    { 'school_id': 77, 'value': 'Storia e Società', 'label': 'Storia e Società' },
    { 'school_id': 124, 'value': 'Storia e Società', 'label': 'Storia e Società' },
    { 'school_id': 69, 'value': 'Studi Storici, Antropologici e Geografici', 'label': 'Studi Storici, Antropologici e Geografici' },
    { 'school_id': 55, 'value': 'Studi Storici. dall\'Antico al Contemporaneo', 'label': 'Studi Storici. dall\'Antico al Contemporaneo' },
    { 'school_id': 6833, 'value': 'Tecniche, Patrimonio, Territori dell\'Industria - Techniques, Patrimoine, Territoires De l\'Industrie', 'label': 'Tecniche, Patrimonio, Territori dell\'Industria - Techniques, Patrimoine, Territoires De l\'Industrie' },
    { 'school_id': 131, 'value': 'Allevamento e Benessere Animale', 'label': 'Allevamento e Benessere Animale' },
    { 'school_id': 35, 'value': 'Precision Livestock Farming', 'label': 'Precision Livestock Farming' },
    { 'school_id': 74, 'value': 'Produzioni Animali Innovative e Sostenibili', 'label': 'Produzioni Animali Innovative e Sostenibili' },
    { 'school_id': 244, 'value': 'Scienze Animali', 'label': 'Scienze Animali' },
    { 'school_id': 228, 'value': 'Scienze delle Produzioni Animali Sostenibili', 'label': 'Scienze delle Produzioni Animali Sostenibili' },
    { 'school_id': 115, 'value': 'Scienze delle Produzioni Zootecniche', 'label': 'Scienze delle Produzioni Zootecniche' },
    { 'school_id': 28, 'value': 'Scienze e Gestione delle Risorse Faunistico-Ambientali', 'label': 'Scienze e Gestione delle Risorse Faunistico-Ambientali' },
    { 'school_id': 6833, 'value': 'Scienze e Tecnologie Animali', 'label': 'Scienze e Tecnologie Animali' },
    { 'school_id': 157, 'value': 'Scienze e Tecnologie delle Produzioni Animali', 'label': 'Scienze e Tecnologie delle Produzioni Animali' },
    { 'school_id': 146, 'value': 'Scienze e Tecnologie delle Produzioni Animali', 'label': 'Scienze e Tecnologie delle Produzioni Animali' },
    { 'school_id': 35, 'value': 'Scienze e Tecnologie delle Produzioni Animali', 'label': 'Scienze e Tecnologie delle Produzioni Animali' },
    { 'school_id': 20, 'value': 'Scienze Zootecniche', 'label': 'Scienze Zootecniche' },
    { 'school_id': 72, 'value': 'Sicurezza degli Alimenti di Origine Animale e Salute', 'label': 'Sicurezza degli Alimenti di Origine Animale e Salute' },
    { 'school_id': 24, 'value': 'Sicurezza e Qualita\' delle Produzioni Animali', 'label': 'Sicurezza e Qualita\' delle Produzioni Animali' },
    { 'school_id': 128, 'value': 'Sicurezza e Qualita\' delle Produzioni Animali', 'label': 'Sicurezza e Qualita\' delle Produzioni Animali' },
    { 'school_id': 115, 'value': 'Wildlife Management, Conservation, And Control', 'label': 'Wildlife Management, Conservation, And Control' },
    { 'school_id': 24, 'value': 'Acquacoltura e Igiene delle Produzioni Ittiche', 'label': 'Acquacoltura e Igiene delle Produzioni Ittiche' },
    { 'school_id': 157, 'value': 'Allevamento e Benessere Animale', 'label': 'Allevamento e Benessere Animale' },
    { 'school_id': 131, 'value': 'Allevamento e Salute Animale', 'label': 'Allevamento e Salute Animale' },
    { 'school_id': 6833, 'value': 'Animal Care', 'label': 'Animal Care' },
    { 'school_id': 24, 'value': 'Produzioni Animali', 'label': 'Produzioni Animali' },
    { 'school_id': 20, 'value': 'Produzioni Animali', 'label': 'Produzioni Animali' },
    { 'school_id': 244, 'value': 'Produzioni e Gestione degli Animali in allevamento e Selvatici', 'label': 'Produzioni e Gestione degli Animali in allevamento e Selvatici' },
    { 'school_id': 72, 'value': 'Scienze Animali', 'label': 'Scienze Animali' },
    { 'school_id': 157, 'value': 'Scienze delle Produzioni Animali', 'label': 'Scienze delle Produzioni Animali' },
    { 'school_id': 90, 'value': 'Scienze e Tecnologie delle Produzioni Animali', 'label': 'Scienze e Tecnologie delle Produzioni Animali' },
    { 'school_id': 28, 'value': 'Scienze Faunistiche', 'label': 'Scienze Faunistiche' },
    { 'school_id': 74, 'value': 'Scienze Zootecniche e Tecnologie delle Produzioni Animali', 'label': 'Scienze Zootecniche e Tecnologie delle Produzioni Animali' },
    { 'school_id': 128, 'value': 'Scienze, Tecnologie e Sicurezza delle Produzioni Animali', 'label': 'Scienze, Tecnologie e Sicurezza delle Produzioni Animali' },
    { 'school_id': 60, 'value': 'Sicurezza delle Produzioni Zootecniche e Valorizzazione delle Tipicita\' Alimentari di Origine Animale', 'label': 'Sicurezza delle Produzioni Zootecniche e Valorizzazione delle Tipicita\' Alimentari di Origine Animale' },
    { 'school_id': 146, 'value': 'Tecniche di allevamento Animale ed Educazione Cinofila', 'label': 'Tecniche di allevamento Animale ed Educazione Cinofila' },
    { 'school_id': 35, 'value': 'Tecnologie delle Produzioni Animali', 'label': 'Tecnologie delle Produzioni Animali' },
    { 'school_id': 228, 'value': 'Tutela e Benessere Animale', 'label': 'Tutela e Benessere Animale' },
    { 'school_id': 382, 'value': 'Enogastronomia d\'Impresa', 'label': 'Enogastronomia d\'Impresa' },
    { 'school_id': 447, 'value': 'Gastronomia, Ospitalità e Territori', 'label': 'Gastronomia, Ospitalità e Territori' },
    { 'school_id': 131, 'value': 'Scienza e Cultura del Cibo', 'label': 'Scienza e Cultura del Cibo' },
    { 'school_id': 6833, 'value': 'Scienze e Cultura della Gastronomia', 'label': 'Scienze e Cultura della Gastronomia' },
    { 'school_id': 198, 'value': 'Scienze e Culture del Cibo', 'label': 'Scienze e Culture del Cibo' },
    { 'school_id': 6818, 'value': 'Scienze e Culture Gastronomiche', 'label': 'Scienze e Culture Gastronomiche' },
    { 'school_id': 228, 'value': 'Scienze e Culture Gastronomiche per la Sostenibilita\'', 'label': 'Scienze e Culture Gastronomiche per la Sostenibilita\'' },
    { 'school_id': 74, 'value': 'Scienze Gastronomiche', 'label': 'Scienze Gastronomiche' },
    { 'school_id': 60, 'value': 'Scienze Gastronomiche', 'label': 'Scienze Gastronomiche' },
    { 'school_id': 35, 'value': 'Scienze Gastronomiche Mediterranee', 'label': 'Scienze Gastronomiche Mediterranee' },
    { 'school_id': 13, 'value': 'Scienze, Culture e Politiche Gastronomiche per il Benessere', 'label': 'Scienze, Culture e Politiche Gastronomiche per il Benessere' },
    { 'school_id': 6877, 'value': 'Mediatori per l\'Intercultura e la Coesione Sociale in Europa', 'label': 'Mediatori per l\'Intercultura e la Coesione Sociale in Europa' },
    { 'school_id': 14336, 'value': 'Scienze del Servizio Sociale', 'label': 'Scienze del Servizio Sociale' },
    { 'school_id': 6830, 'value': 'Scienze del Servizio Sociale', 'label': 'Scienze del Servizio Sociale' },
    { 'school_id': 146, 'value': 'Scienze del Servizio Sociale', 'label': 'Scienze del Servizio Sociale' },
    { 'school_id': 137, 'value': 'Scienze del Servizio Sociale', 'label': 'Scienze del Servizio Sociale' },
    { 'school_id': 198, 'value': 'Scienze del Servizio Sociale', 'label': 'Scienze del Servizio Sociale' },
    { 'school_id': 72, 'value': 'Scienze del Servizio Sociale', 'label': 'Scienze del Servizio Sociale' },
    { 'school_id': 128, 'value': 'Scienze del Servizio Sociale', 'label': 'Scienze del Servizio Sociale' },
    { 'school_id': 59, 'value': 'Scienze del Servizio Sociale e del No Profit', 'label': 'Scienze del Servizio Sociale e del No Profit' },
    { 'school_id': 72, 'value': 'Scienze del Servizio Sociale e Sociologia', 'label': 'Scienze del Servizio Sociale e Sociologia' },
    { 'school_id': 6831, 'value': 'Scienze della Società e del Servizio Sociale', 'label': 'Scienze della Società e del Servizio Sociale' },
    { 'school_id': 13, 'value': 'Scienze e Tecniche del Servizio Sociale', 'label': 'Scienze e Tecniche del Servizio Sociale' },
    { 'school_id': 244, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 138, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 109, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 66, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 273, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 70, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 6833, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 55, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 74, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 24, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 28, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 20, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 13, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 322, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 35, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 114, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 80, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 6874, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 69, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 115, 'value': 'Servizio Sociale', 'label': 'Servizio Sociale' },
    { 'school_id': 27, 'value': 'Servizio Sociale e Scienze Criminologiche', 'label': 'Servizio Sociale e Scienze Criminologiche' },
    { 'school_id': 77, 'value': 'Servizio Sociale e Sociologia', 'label': 'Servizio Sociale e Sociologia' },
    { 'school_id': 23, 'value': 'Sociologia e Servizio Sociale', 'label': 'Sociologia e Servizio Sociale' },
    { 'school_id': 81, 'value': 'Sociologia e Servizio Sociale', 'label': 'Sociologia e Servizio Sociale' },
    { 'school_id': 234, 'value': 'Teorie, Culture e Tecniche per il Servizio Sociale', 'label': 'Teorie, Culture e Tecniche per il Servizio Sociale' },
    { 'school_id': 28, 'value': 'Disegno e Gestione degli Interventi Sociali', 'label': 'Disegno e Gestione degli Interventi Sociali' },
    { 'school_id': 23, 'value': 'Gestione delle Politiche dei Servizi Sociali e Multiculturalità', 'label': 'Gestione delle Politiche dei Servizi Sociali e Multiculturalità' },
    { 'school_id': 35, 'value': 'Gestione delle Politiche e dei Servizi Sociali', 'label': 'Gestione delle Politiche e dei Servizi Sociali' },
    { 'school_id': 273, 'value': 'Innovazione e Ricerca per Gli Interventi Socio-Assistenziali-Educativi', 'label': 'Innovazione e Ricerca per Gli Interventi Socio-Assistenziali-Educativi' },
    { 'school_id': 6833, 'value': 'Innovazione e Servizio Sociale', 'label': 'Innovazione e Servizio Sociale' },
    { 'school_id': 72, 'value': 'Innovazione Sociale e Politiche di Inclusione', 'label': 'Innovazione Sociale e Politiche di Inclusione' },
    { 'school_id': 14336, 'value': 'Lavoro Sociale e Servizi per le Famiglie, i Minori e le Comunità', 'label': 'Lavoro Sociale e Servizi per le Famiglie, i Minori e le Comunità' },
    { 'school_id': 6831, 'value': 'Lavoro, Cittadinanza Sociale, Interculturalità', 'label': 'Lavoro, Cittadinanza Sociale, Interculturalità' },
    { 'school_id': 70, 'value': 'Metodologia, Organizzazione e Valutazione dei Servizi Sociali', 'label': 'Metodologia, Organizzazione e Valutazione dei Servizi Sociali' },
    { 'school_id': 114, 'value': 'Politiche e Management per il Welfare', 'label': 'Politiche e Management per il Welfare' },
    { 'school_id': 234, 'value': 'Politiche e Programmazione dei Servizi alla Persona', 'label': 'Politiche e Programmazione dei Servizi alla Persona' },
    { 'school_id': 244, 'value': 'Politiche e Servizi Sociali', 'label': 'Politiche e Servizi Sociali' },
    { 'school_id': 20, 'value': 'Politiche e Servizi Sociali', 'label': 'Politiche e Servizi Sociali' },
    { 'school_id': 81, 'value': 'Politiche e Servizi Sociali', 'label': 'Politiche e Servizi Sociali' },
    { 'school_id': 6877, 'value': 'Politiche per l\'Innovazione e l\'Inclusione Sociale', 'label': 'Politiche per l\'Innovazione e l\'Inclusione Sociale' },
    { 'school_id': 322, 'value': 'Politiche Sociali e Servizio Sociale', 'label': 'Politiche Sociali e Servizio Sociale' },
    { 'school_id': 108, 'value': 'Progettazione e Gestione dei Servizi e degli Interventi Sociali ed Educativi', 'label': 'Progettazione e Gestione dei Servizi e degli Interventi Sociali ed Educativi' },
    { 'school_id': 80, 'value': 'Progettazione e Gestione delle Politiche e dei Servizi Sociali', 'label': 'Progettazione e Gestione delle Politiche e dei Servizi Sociali' },
    { 'school_id': 13, 'value': 'Progettazione Gestione e Valutazione dei Servizi Sociali', 'label': 'Progettazione Gestione e Valutazione dei Servizi Sociali' },
    { 'school_id': 74, 'value': 'Programmazione e Gestione dei Servizi Sociali', 'label': 'Programmazione e Gestione dei Servizi Sociali' },
    { 'school_id': 66, 'value': 'Programmazione e Gestione delle Politiche e dei Servizi Sociali', 'label': 'Programmazione e Gestione delle Politiche e dei Servizi Sociali' },
    { 'school_id': 59, 'value': 'Programmazione e Gestione delle Politiche e dei Servizi Sociali', 'label': 'Programmazione e Gestione delle Politiche e dei Servizi Sociali' },
    { 'school_id': 110, 'value': 'Programmazione, Amministrazione e Gestione delle Politiche e dei Servizi Sociali', 'label': 'Programmazione, Amministrazione e Gestione delle Politiche e dei Servizi Sociali' },
    { 'school_id': 6874, 'value': 'Scienze delle Politiche e dei Servizi Sociali', 'label': 'Scienze delle Politiche e dei Servizi Sociali' },
    { 'school_id': 109, 'value': 'Servizio Sociale e Politiche Sociali', 'label': 'Servizio Sociale e Politiche Sociali' },
    { 'school_id': 198, 'value': 'Servizio Sociale e Politiche Sociali', 'label': 'Servizio Sociale e Politiche Sociali' },
    { 'school_id': 69, 'value': 'Servizio Sociale e Politiche Sociali', 'label': 'Servizio Sociale e Politiche Sociali' },
    { 'school_id': 115, 'value': 'Servizio Sociale e Politiche Sociali', 'label': 'Servizio Sociale e Politiche Sociali' },
    { 'school_id': 6830, 'value': 'Servizio Sociale in Ambiti Complessi', 'label': 'Servizio Sociale in Ambiti Complessi' },
    { 'school_id': 128, 'value': 'Servizio Sociale, Politiche Sociali e Studi Sociologici e Ricerca Sociale', 'label': 'Servizio Sociale, Politiche Sociali e Studi Sociologici e Ricerca Sociale' },
    { 'school_id': 55, 'value': 'Servizio Sociale, Politiche Sociali, Programmazione e Gestione dei Servizi', 'label': 'Servizio Sociale, Politiche Sociali, Programmazione e Gestione dei Servizi' },
    { 'school_id': 138, 'value': 'Società e Sviluppo Locale', 'label': 'Società e Sviluppo Locale' },
    { 'school_id': 146, 'value': 'Sociologia e Management dei Servizi Sociali', 'label': 'Sociologia e Management dei Servizi Sociali' },
    { 'school_id': 24, 'value': 'Sociologia e Servizio Sociale', 'label': 'Sociologia e Servizio Sociale' },
    { 'school_id': 137, 'value': 'Sostenibilita\' Sociale e Management del Welfare', 'label': 'Sostenibilita\' Sociale e Management del Welfare' },
    { 'school_id': 6833, 'value': 'Cybersecurity', 'label': 'Cybersecurity' },
    { 'school_id': 146, 'value': 'Cybersecurity', 'label': 'Cybersecurity' },
    { 'school_id': 13, 'value': 'Cybersecurity', 'label': 'Cybersecurity' },
    { 'school_id': 198, 'value': 'Sicurezza dei Sistemi Software', 'label': 'Sicurezza dei Sistemi Software' },
    { 'school_id': 157, 'value': 'Sicurezza Informatica', 'label': 'Sicurezza Informatica' },
    { 'school_id': 72, 'value': 'Sicurezza Informatica', 'label': 'Sicurezza Informatica' },
    { 'school_id': 35, 'value': 'Culture Digitali e della Comunicazione', 'label': 'Culture Digitali e della Comunicazione' },
    { 'school_id': 20, 'value': 'Scienze per l\'Investigazione e la Sicurezza', 'label': 'Scienze per l\'Investigazione e la Sicurezza' },
    { 'school_id': 169, 'value': 'Scienze Sociali', 'label': 'Scienze Sociali' },
    { 'school_id': 6833, 'value': 'Scienze Sociologiche', 'label': 'Scienze Sociologiche' },
    { 'school_id': 14336, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 66, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 70, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 24, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 13, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 35, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 38, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 80, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 90, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 447, 'value': 'Sociologia dell\'Innovazione', 'label': 'Sociologia dell\'Innovazione' },
    { 'school_id': 114, 'value': 'Sociologia e Criminologia', 'label': 'Sociologia e Criminologia' },
    { 'school_id': 66, 'value': 'Analisi dei Processi Sociali', 'label': 'Analisi dei Processi Sociali' },
    { 'school_id': 35, 'value': 'Comunicazione Pubblica, Sociale e Politica', 'label': 'Comunicazione Pubblica, Sociale e Politica' },
    { 'school_id': 14336, 'value': 'Gestione del Lavoro e Comunicazione per le Organizzazioni', 'label': 'Gestione del Lavoro e Comunicazione per le Organizzazioni' },
    { 'school_id': 70, 'value': 'Gestione delle Organizzazioni e del Territorio', 'label': 'Gestione delle Organizzazioni e del Territorio' },
    { 'school_id': 35, 'value': 'Innovazione Sociale', 'label': 'Innovazione Sociale' },
    { 'school_id': 114, 'value': 'Ricerca Sociale, Politiche della Sicurezza e Criminalità', 'label': 'Ricerca Sociale, Politiche della Sicurezza e Criminalità' },
    { 'school_id': 24, 'value': 'Scienze Criminologiche per l\'Investigazione e la Sicurezza', 'label': 'Scienze Criminologiche per l\'Investigazione e la Sicurezza' },
    { 'school_id': 13, 'value': 'Scienze Sociali Applicate', 'label': 'Scienze Sociali Applicate' },
    { 'school_id': 244, 'value': 'Sociologia', 'label': 'Sociologia' },
    { 'school_id': 81, 'value': 'Sociologia delle Reti, dell\' Informazione e dell\' Innovazione', 'label': 'Sociologia delle Reti, dell\' Informazione e dell\' Innovazione' },
    { 'school_id': 35, 'value': 'Sociologia Digitale e Analisi del Web', 'label': 'Sociologia Digitale e Analisi del Web' },
    { 'school_id': 38, 'value': 'Sociologia e Politiche per il Territorio', 'label': 'Sociologia e Politiche per il Territorio' },
    { 'school_id': 169, 'value': 'Sociologia e Ricerca Sociale', 'label': 'Sociologia e Ricerca Sociale' },
    { 'school_id': 80, 'value': 'Sociologia e Ricerca Sociale', 'label': 'Sociologia e Ricerca Sociale' },
    { 'school_id': 6874, 'value': 'Sociologia e Ricerca Sociale', 'label': 'Sociologia e Ricerca Sociale' },
    { 'school_id': 28, 'value': 'Sociologia e Sfide Globali', 'label': 'Sociologia e Sfide Globali' },
    { 'school_id': 70, 'value': 'Sociology And Social Research - Sociologia e Ricerca Sociale', 'label': 'Sociology And Social Research - Sociologia e Ricerca Sociale' },
    { 'school_id': 91, 'value': 'Data Analytics', 'label': 'Data Analytics' },
    { 'school_id': 24, 'value': 'Finanza, Assicurazioni e Impresa', 'label': 'Finanza, Assicurazioni e Impresa' },
    { 'school_id': 24, 'value': 'Scienze Statistiche', 'label': 'Scienze Statistiche' },
    { 'school_id': 72, 'value': 'Scienze Statistiche', 'label': 'Scienze Statistiche' },
    { 'school_id': 28, 'value': 'Statistica', 'label': 'Statistica' },
    { 'school_id': 66, 'value': 'Statistica e Gestione delle Informazioni', 'label': 'Statistica e Gestione delle Informazioni' },
    { 'school_id': 55, 'value': 'Statistica e Informatica per l\'Azienda, la Finanza e l\'Assicurazione', 'label': 'Statistica e Informatica per l\'Azienda, la Finanza e l\'Assicurazione' },
    { 'school_id': 82, 'value': 'Statistica e Informatica per l\'Azienda, la Finanza e le Assicurazioni', 'label': 'Statistica e Informatica per l\'Azienda, la Finanza e le Assicurazioni' },
    { 'school_id': 13, 'value': 'Statistica Gestionale', 'label': 'Statistica Gestionale' },
    { 'school_id': 38, 'value': 'Statistica per i Big Data', 'label': 'Statistica per i Big Data' },
    { 'school_id': 69, 'value': 'Statistica per l\'Analisi dei Dati', 'label': 'Statistica per l\'Analisi dei Dati' },
    { 'school_id': 6874, 'value': 'Statistica per l\'Azienda', 'label': 'Statistica per l\'Azienda' },
    { 'school_id': 6833, 'value': 'Statistica per l\'Economia e l\'Impresa', 'label': 'Statistica per l\'Economia e l\'Impresa' },
    { 'school_id': 35, 'value': 'Statistica per l\'Impresa e la Societa\'', 'label': 'Statistica per l\'Impresa e la Societa\'' },
    { 'school_id': 6833, 'value': 'Statistica per le Tecnologie e le Scienze', 'label': 'Statistica per le Tecnologie e le Scienze' },
    { 'school_id': 13, 'value': 'Statistica, Economia e Società', 'label': 'Statistica, Economia e Società' },
    { 'school_id': 13, 'value': 'Statistica, Economia, Finanza e Assicurazioni', 'label': 'Statistica, Economia, Finanza e Assicurazioni' },
    { 'school_id': 24, 'value': 'Antropologia, Religioni, Civiltà Orientali', 'label': 'Antropologia, Religioni, Civiltà Orientali' },
    { 'school_id': 13, 'value': 'Global Humanities', 'label': 'Global Humanities' },
    { 'school_id': 77, 'value': 'Scienze Storiche, del Territorio e per la Cooperazione Internazionale', 'label': 'Scienze Storiche, del Territorio e per la Cooperazione Internazionale' },
    { 'school_id': 24, 'value': 'Società e Culture del Mediterraneo: Istituzioni, Sicurezza, Ambiente', 'label': 'Società e Culture del Mediterraneo: Istituzioni, Sicurezza, Ambiente' },
    { 'school_id': 244, 'value': 'Storia', 'label': 'Storia' },
    { 'school_id': 109, 'value': 'Storia', 'label': 'Storia' },
    { 'school_id': 157, 'value': 'Storia', 'label': 'Storia' },
    { 'school_id': 6831, 'value': 'Storia', 'label': 'Storia' },
    { 'school_id': 6833, 'value': 'Storia', 'label': 'Storia' },
    { 'school_id': 24, 'value': 'Storia', 'label': 'Storia' },
    { 'school_id': 28, 'value': 'Storia', 'label': 'Storia' },
    { 'school_id': 146, 'value': 'Storia', 'label': 'Storia' },
    { 'school_id': 35, 'value': 'Storia', 'label': 'Storia' },
    { 'school_id': 61, 'value': 'Storia e Culture Contemporanee', 'label': 'Storia e Culture Contemporanee' },
    { 'school_id': 72, 'value': 'Storia e Scienze Sociali', 'label': 'Storia e Scienze Sociali' },
    { 'school_id': 148, 'value': 'Storia e Storie del Mondo Contemporaneo', 'label': 'Storia e Storie del Mondo Contemporaneo' },
    { 'school_id': 13, 'value': 'Storia, Antropologia, Religioni', 'label': 'Storia, Antropologia, Religioni' },
    { 'school_id': 151, 'value': 'Art History in Rome, From Late Antiquity To The Present', 'label': 'Art History in Rome, From Late Antiquity To The Present' },
    { 'school_id': 6830, 'value': 'Arte', 'label': 'Arte' },
    { 'school_id': 14, 'value': 'Arte, Valorizzazione e Mercato', 'label': 'Arte, Valorizzazione e Mercato' },
    { 'school_id': 24, 'value': 'Arti Visive', 'label': 'Arti Visive' },
    { 'school_id': 108, 'value': 'Beni Culturali', 'label': 'Beni Culturali' },
    { 'school_id': 234, 'value': 'Management dei Beni Culturali', 'label': 'Management dei Beni Culturali' },
    { 'school_id': 244, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 6833, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 131, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 23, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 28, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 137, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 13, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 151, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 77, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 72, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 80, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 6874, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 69, 'value': 'Storia dell\'Arte', 'label': 'Storia dell\'Arte' },
    { 'school_id': 81, 'value': 'Storia dell\'Arte e Beni Culturali', 'label': 'Storia dell\'Arte e Beni Culturali' },
    { 'school_id': 109, 'value': 'Storia dell\'Arte e Valorizzazione del Patrimonio Artistico', 'label': 'Storia dell\'Arte e Valorizzazione del Patrimonio Artistico' },
    { 'school_id': 6831, 'value': 'Storia delle Arti e Conservazione dei Beni Artistici', 'label': 'Storia delle Arti e Conservazione dei Beni Artistici' },
    { 'school_id': 38, 'value': 'Storia e Critica d\'Arte', 'label': 'Storia e Critica d\'Arte' },
    { 'school_id': 157, 'value': 'Storia e Critica dell\'Arte', 'label': 'Storia e Critica dell\'Arte' },
    { 'school_id': 74, 'value': 'Storia e Critica delle Arti e dello Spettacolo', 'label': 'Storia e Critica delle Arti e dello Spettacolo' },
    { 'school_id': 175, 'value': 'Storia e Valorizzazione dei Beni Culturali', 'label': 'Storia e Valorizzazione dei Beni Culturali' },
    { 'school_id': 6825, 'value': 'Teoria e Storia delle Arti e dell\'Immagine', 'label': 'Teoria e Storia delle Arti e dell\'Immagine' },
    { 'school_id': 6833, 'value': 'European And Global Studies', 'label': 'European And Global Studies' },
    { 'school_id': 151, 'value': 'European Economy And Business Law', 'label': 'European Economy And Business Law' },
    { 'school_id': 244, 'value': 'European Legal Studies', 'label': 'European Legal Studies' },
    { 'school_id': 13, 'value': 'European Studies', 'label': 'European Studies' },
    { 'school_id': 14336, 'value': 'European Studies in Investor Relations And Financial Communication', 'label': 'European Studies in Investor Relations And Financial Communication' },
    { 'school_id': 60, 'value': 'Gestione dei Fenomeni Migratori e Politiche di Integrazione nell\'Unione Europea', 'label': 'Gestione dei Fenomeni Migratori e Politiche di Integrazione nell\'Unione Europea' },
    { 'school_id': 80, 'value': 'Governance Euro-Mediterranea delle Politiche Migratorie', 'label': 'Governance Euro-Mediterranea delle Politiche Migratorie' },
    { 'school_id': 20, 'value': 'Integrazione Giuridica Europea e Diritti Umani', 'label': 'Integrazione Giuridica Europea e Diritti Umani' },
    { 'school_id': 69, 'value': 'Migrazioni, Diritti, Integrazione', 'label': 'Migrazioni, Diritti, Integrazione' },
    { 'school_id': 14336, 'value': 'Applied Data Science For Banking And Finance', 'label': 'Applied Data Science For Banking And Finance' },
    { 'school_id': 14332, 'value': 'Cyber Risk Strategy And Governance', 'label': 'Cyber Risk Strategy And Governance' },
    { 'school_id': 6831, 'value': 'Data Analytics For Business And Society', 'label': 'Data Analytics For Business And Society' },
    { 'school_id': 66, 'value': 'Data Science', 'label': 'Data Science' },
    { 'school_id': 70, 'value': 'Data Science', 'label': 'Data Science' },
    { 'school_id': 6830, 'value': 'Data Science', 'label': 'Data Science' },
    { 'school_id': 6833, 'value': 'Data Science', 'label': 'Data Science' },
    { 'school_id': 13, 'value': 'Data Science', 'label': 'Data Science' },
    { 'school_id': 35, 'value': 'Data Science', 'label': 'Data Science' },
    { 'school_id': 72, 'value': 'Data Science', 'label': 'Data Science' },
    { 'school_id': 157, 'value': 'Data Science And Economics - Scienza dei Dati e Economia', 'label': 'Data Science And Economics - Scienza dei Dati e Economia' },
    { 'school_id': 38, 'value': 'Data Science And Innovation Management', 'label': 'Data Science And Innovation Management' },
    { 'school_id': 108, 'value': 'Data Science Applicata', 'label': 'Data Science Applicata' },
    { 'school_id': 81, 'value': 'Data Science per il Management', 'label': 'Data Science per il Management' },
    { 'school_id': 124, 'value': 'Data Science, Business Analytics e Innovazione', 'label': 'Data Science, Business Analytics e Innovazione' },
    { 'school_id': 46, 'value': 'Informazione Digitale', 'label': 'Informazione Digitale' },
    { 'school_id': 69, 'value': 'Comunicazione del Patrimonio Culturale', 'label': 'Comunicazione del Patrimonio Culturale' },
    { 'school_id': 244, 'value': 'Comunicazione e Culture dei Media', 'label': 'Comunicazione e Culture dei Media' },
    { 'school_id': 6874, 'value': 'Comunicazione e Tecnologie dell\'Informazione', 'label': 'Comunicazione e Tecnologie dell\'Informazione' },
    { 'school_id': 541, 'value': 'Comunicazione Pubblicitaria, Storytelling e Cultura d\'Immagine', 'label': 'Comunicazione Pubblicitaria, Storytelling e Cultura d\'Immagine' },
    { 'school_id': 109, 'value': 'Digital Humanities - Comunicazione e Nuovi Media', 'label': 'Digital Humanities - Comunicazione e Nuovi Media' },
    { 'school_id': 157, 'value': 'Editoria, Culture della Comunicazione e della Moda', 'label': 'Editoria, Culture della Comunicazione e della Moda' },
    { 'school_id': 77, 'value': 'Scienze Cognitive della Comunicazione e dell\'Azione', 'label': 'Scienze Cognitive della Comunicazione e dell\'Azione' },
    { 'school_id': 148, 'value': 'Scienze e Tecniche della Comunicazione', 'label': 'Scienze e Tecniche della Comunicazione' },
    { 'school_id': 24, 'value': 'Semiotica', 'label': 'Semiotica' },
    { 'school_id': 6833, 'value': 'Strategie di Comunicazione', 'label': 'Strategie di Comunicazione' },
    { 'school_id': 137, 'value': 'Strategie e Tecniche della Comunicazione', 'label': 'Strategie e Tecniche della Comunicazione' },
    { 'school_id': 66, 'value': 'Teoria e Tecnologia della Comunicazione', 'label': 'Teoria e Tecnologia della Comunicazione' },
    { 'school_id': 28, 'value': 'Teorie della Comunicazione', 'label': 'Teorie della Comunicazione' },
    { 'school_id': 14336, 'value': 'Media Education', 'label': 'Media Education' },
    { 'school_id': 6877, 'value': 'Interpretariato e Mediazione Interculturale', 'label': 'Interpretariato e Mediazione Interculturale' },
    { 'school_id': 351, 'value': 'Interpretariato e Traduzione', 'label': 'Interpretariato e Traduzione' },
    { 'school_id': 6831, 'value': 'Interpretariato e Traduzione Editoriale, Settoriale', 'label': 'Interpretariato e Traduzione Editoriale, Settoriale' },
    { 'school_id': 24, 'value': 'Interpretazione', 'label': 'Interpretazione' },
    { 'school_id': 24, 'value': 'Specialized Translation', 'label': 'Specialized Translation' },
    { 'school_id': 244, 'value': 'Traduzione', 'label': 'Traduzione' },
    { 'school_id': 109, 'value': 'Traduzione e Interpretariato', 'label': 'Traduzione e Interpretariato' },
    { 'school_id': 131, 'value': 'Traduzione e Mediazione Culturale', 'label': 'Traduzione e Mediazione Culturale' },
    { 'school_id': 12, 'value': 'Traduzione Specialistica', 'label': 'Traduzione Specialistica' },
    { 'school_id': 72, 'value': 'Traduzione Specialistica', 'label': 'Traduzione Specialistica' },
    { 'school_id': 124, 'value': 'Traduzione Specialistica dei Testi', 'label': 'Traduzione Specialistica dei Testi' },
    { 'school_id': 14, 'value': 'Traduzione Specialistica e Interpretariato di Conferenza', 'label': 'Traduzione Specialistica e Interpretariato di Conferenza' },
    { 'school_id': 55, 'value': 'Traduzione Specialistica e Interpretazione di Conferenza', 'label': 'Traduzione Specialistica e Interpretazione di Conferenza' },
    { 'school_id': 80, 'value': 'Traduzione Tecnico-Scientifica e Interpretariato', 'label': 'Traduzione Tecnico-Scientifica e Interpretariato' },
    { 'school_id': 382, 'value': 'Medicina e Chirurgia', 'label': 'Medicina e Chirurgia' },
]