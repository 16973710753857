import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import AuthRoute from './authRoutes/AuthRoute';
import './App.scss';
import CoachPage from './pages/CoachPage';
import CookieConsent from "react-cookie-consent";
import cookie from './assets/cookie.png'
import MissionControlCenter from './pages/MissionControlCenter';
import AuthHabacusRoute from './authRoutes/AuthHabacusRoute';
import AuthCoachRoute from './authRoutes/AuthCoachRoute';

// const firebaseApp = initializeApp(config.firebaseConfig);
// const db = initializeFirestore(firebaseApp, { experimentalForceLongPolling: true, ignoreUndefinedProperties: true })
// const analytics = initializeAnalytics(firebaseApp)

export interface IApplicationProps { }


const App: React.FunctionComponent<IApplicationProps> = (props) => {
  return (<>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute>
              <HomePage />
            </AuthRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard-formatore-h5tYl8xw" element={
          <AuthCoachRoute>
            <CoachPage />
          </AuthCoachRoute>
        } />

        <Route path="/mission-control-center" element={
          <AuthHabacusRoute>
            <MissionControlCenter />
          </AuthHabacusRoute>
        } />

      </Routes>
    </BrowserRouter>
    <CookieConsent
      overlay={true}
      cookieName="horienta.habacus.com"
      expires={150}
      // onAccept={() => {
      //     console.log('accettato');
      //     setIsCookieAccepted(true);
      // }}
      buttonText="ACCETTO"
      style={{
        backgroundColor: '#ffff',
        color: '#9D2085',
      }}
      buttonStyle={{
        backgroundColor: 'purple',
        color: '#ffff',
        padding: '10px',
        borderRadius: '5px'
      }}
    >
      <div className='flexLeft'>
        <img src={cookie} alt="cookie" style={{ height: '20px' }} />
        <span className='px-3'> Questo sito o gli strumenti di terze parti in esso integrati trattano dati personali (es. dati di navigazione o indirizzi IP) e fanno uso di cookie o altri identificatori necessari per il funzionamento e per il raggiungimento delle finalità descritte nella cookie policy. Dichiari di accettare l&apos;utilizzo di cookie o altri identificatori chiudendo o nascondendo questa informativa, proseguendo la navigazione di questa pagina, cliccando un link o un pulsante o continuando a navigare in altro modo. <a href="https://www.habacus.com/it/privacy" target="_blank" rel="noreferrer" className='text-uppercase'>Scopri di più</a>
        </span>

        <img src={cookie} alt="cookie" style={{ height: '20px' }} />

      </div>
    </CookieConsent>
  </>
  );
};

export default App;