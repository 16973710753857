import React, { useEffect, useRef, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Button, Modal, Nav } from 'react-bootstrap';
// import { collection, onSnapshot, updateDoc, doc, addDoc, getDoc } from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore';

import { database, useAuth } from '../firebase';
import { InlineWidget } from "react-calendly";
import { nextStage } from '../utils/Stages';
import Stepper from '../components/Stepper';
import floppy from '../assets/icons8-salva-100.png'
import placeHolder from '../assets/videoPlaceholder.jpeg'
import calendar from '../assets/lottie/calendar.json'
import calendly from '../assets/lottie/calendly.json'
import { Player } from '@lottiefiles/react-lottie-player';
import { fixPlayer } from '../utils/LottiePlayer';

export interface ICalendlyProps { }

const Calendly: React.FunctionComponent<ICalendlyProps> = (props) => {

    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const auth = getAuth();


    const currentUser = useAuth();

    const getUser = async () => {
        if (currentUser != null) {
            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            setUser(document.data());

            setFullName(document.data()?.inputData.firstName + ' ' + document.data()?.inputData.lastName);
            setEmail(document.data()?.email);
        }
    }


    useEffect(
        () => {
            getUser();

        }, [currentUser]
    );
    const [user, setUser] = useState<any>();

    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')

    // const fullName = document.data()?.inputData.firstName + ' ' + document.data()?.inputData.lastName;
    // const email = document.data()?.email;

    const iframe = useRef<HTMLIFrameElement>(null);





    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleCloseAndSendData = async () => {
        await nextStage(doc(database, 'users', currentUser.uid));
        setShow(false);
    };

    // const injectSomethingIntoSomethingElse = () => {

    //     let name = iframe.current?.contentWindow?.document?.getElementById('TextField0') as HTMLInputElement;

    //     if (name) {
    //         console.log('inject');
    //         name.value = 'luca';
    //     }

    // }

    // setTimeout(function () { injectSomethingIntoSomethingElse(); }, 2000);

    // const hackingCalendly = iframe.current?.contentWindow?.document?.getElementById('page-region') as HTMLInputElement;


    // const interval = () => {
    //     setInterval(function () {
    //         if (hackingCalendly && hackingCalendly.textContent?.includes('Confirmed')) {
    //             console.log('confirmed')

    //         } else {
    //             console.log('not confirmed');
    //         }
    //     }, 3000);
    // }

    // interval();

    const [coach, setCoach] = useState(-1);




    function isCalendlyEvent(e: MessageEvent<any>) {
        return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
    };

    window.addEventListener("message", async function (e) {
        if (isCalendlyEvent(e)) {
            // console.log(e);

            /* Example to get the name of the event */
            // console.log("Event name:", e.data.event);
            if (e.data.event == 'calendly.event_scheduled') {
                console.log('next stage');
                await nextStage(doc(database, 'users', currentUser.uid));
            }

            /* Example to get the payload of the event */
            // console.log("Event details:", e.data.payload);
        }
    });


    const player = React.useRef<Player>(null);
    return (
        <div className='calendly'>

            <Nav className='row navStepper'>
                <div className='col-4 colorPurple flexLeft titleStep'>

                </div>
                <div className='col-4'>
                </div>
                <div className='col-4 textAlignRight my-1'>
                    {/* <Button id="endQuizButton" className="purpleButt customButt" onClick={handleShow}>
                        <div className='flexButt'>
                            <img src={floppy} alt="" />
                            <div className='saveAndGo'>
                                <div>SALVA</div>
                                <div>E PROSEGUI</div>
                            </div>
                        </div>
                    </Button> */}


                </div>
            </Nav>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='textAlignCenter width100 colorBlue'>
                        Sessione con il formatore prenotata!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='textAlignLeft'>
                    <p>
                        Ti ricordiamo che la sessione sarà in <b>videochiamata</b>, quindi:
                    </p>
                    <ul>
                        <li>
                            Trova un <b>ambiente silenzioso</b>
                        </li>
                        <li>
                            Assicurati di avere una <b>connessione internet stabile</b>
                        </li>
                        <li>
                            Tieni a portata di mano i <b>risultati del test di orientamento</b>
                        </li>
                        <li>
                            Approfitta di questa occasione, <b>preparati qualche domanda</b>
                        </li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancella
                    </Button>
                    <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendData}>
                        Ho capito
                    </Button>
                </Modal.Footer>
            </Modal>

            <h1>Prenota la sessione con il formatore</h1>
            <p className='textAlignCenter'>Il formatore che hai selezionato non ha più disponibilità in agenda? Prenota la sessione con un altro formatore.</p>


            <Nav variant="tabs" defaultActiveKey="/">

                {
                    (user && user.email.includes('@habacus.com'))
                        ?
                        <Nav.Item>
                            <Nav.Link onClick={() => setCoach(0)} className="colorPurple fw-bold">Formatore - Habacus</Nav.Link>
                        </Nav.Item>
                        : null

                }
                <Nav.Item>
                    <Nav.Link onClick={() => setCoach(1)} className="colorPurple fw-bold">Formatore - Fabio</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={() => setCoach(2)} className="colorPurple fw-bold">Formatore - Caterina</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={() => setCoach(3)} className="colorPurple fw-bold">Formatore - Mario</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={() => setCoach(4)} className="colorPurple fw-bold">Formatore - Daniela</Nav.Link>
                </Nav.Item>
            </Nav>

            {
                coach == -1 ? <div className="lottieFrame mt-5">

                    <Player
                        autoplay
                        loop
                        src={calendar}
                        style={{ maxHeight: '450px', maxWidth: '450px' }}
                        ref={player}
                        onEvent={(e) => { if (e === 'load') { fixPlayer(player); } }}
                    >
                    </Player>
                    <h4 className='textAlignCenter mt-4'>Seleziona un Formatore!</h4>

                </div>
                    : ''
            }

            {

                coach == 0 ? <InlineWidget url="https://calendly.com/luca-cavicchioli-habacus"
                    prefill={{
                        email: email,
                        name: fullName,
                    }}
                /> : ''
            }


            {
                coach == 1 ? <InlineWidget url="https://calendly.com/volpif-coaching/horienta-sessione-di-coaching"
                    prefill={{
                        email: email,
                        name: fullName,
                    }}
                /> : ''
            }

            {
                coach == 2 ? <InlineWidget url="https://calendly.com/caterina-tavani-career-coach/horienta-one-to-caterina"
                    prefill={{
                        email: email,
                        name: fullName,
                    }}
                /> : ''
            }
            {
                coach == 3 ? <InlineWidget url="https://calendly.com/mfumagalli-2/horienta-1"
                    prefill={{
                        email: email,
                        name: fullName,
                    }}
                /> : ''
            }
            {
                coach == 4 ? <InlineWidget url="https://calendly.com/daniela-ferdeghini/sessione-di-coaching"
                    prefill={{
                        email: email,
                        name: fullName,
                    }}
                /> : ''
            }


            {/* <iframe
                ref={iframe}
                src='https://outlook.office365.com/owa/calendar/Horienta@habacus.com/bookings/'
                scrolling='yes'
                style={{ border: 0, width: '100vw', height: '90vh' }}
                referrerPolicy='no-referrer'
            ></iframe> 
            */}

        </div>

    );
};

export default Calendly;