import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap'
import logo from '../assets/lb.png';
import { database } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import dashboard from '../assets/lottie/dashboard.json'
import 'react-toastify/dist/ReactToastify.css';
import { Player } from '@lottiefiles/react-lottie-player';
import icon from '../assets/ib.png'
import { fixPlayer } from '../utils/LottiePlayer';

import ExcelJS from 'exceljs'
// const ExcelJS = require('exceljs');

export interface IMissionControlCenterProps { }

const MissionControlCenter: React.FunctionComponent<IMissionControlCenterProps> = (props) => {

    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);
    const createFirstRow = (maxEvents: number) => {
        const header = [
            'ID',
            'EMAIL',
            'FROM',
            'FIRST_NAME',
            'LAST_NAME',
            'BIRTHDATE',
            'GENDER',
            'PROVINCE_CITY',
            'PHONE',
            'SCHOOL',
            'COURSE_TYPE',
            'COURSE',
            'STAGE',
            'STAGE_KEY',
            'STAGES_LENGTH',
            'STAGES',
            'PROFESSIONS',
            'PROFESSIONS_TOP_1',
            'PROFESSIONS_TOP_2',
            'PROFESSIONS_TOP_3',
            'COACH_EMAIL',
            'COACH_TIMESTAMP',

        ];
        for (let index = 0; index < maxEvents; index++) {
            header.push('NEW_STAGE');
            header.push('NEW_STAGE_KEY');
            header.push('TIMESTAMP');
            header.push('CLIENT_TYPE');
            header.push('CLIENT_NAME');
            header.push('DEVICE_BRAND');
            header.push('OS_NAME');
        };

        return header;
    }




    const createRow = (id: string, user: any) => {

        const arrayEvents = user.events;

        // TEST1 -> estrae in una cella il singolo evento con newStage === 1 
        // let one = arrayEvents?.find((one: { newStage: number; }) => one.newStage === 1)
        const events: any[] = [];
        let cont = 0;

        arrayEvents?.map((event: any) => {

            events.push(event.newStage);
            events.push(event.newStageKey);
            events.push(event.timestamp);
            events.push(event.device?.client?.type);
            events.push(event.device?.client?.name);
            events.push(event.device?.device?.brand);
            events.push(event.device?.os?.name);
            cont++;
        });

        // const stagesLenght = user.stages?.length;
        // console.log(stagesLenght);

        const row = [
            id,
            user.email,
            user.from,
            user.inputData?.firstName,
            user.inputData?.lastName,
            user.inputData?.birthDate,
            user.inputData?.gender,
            user.inputData?.province_city,
            user.inputData?.phone,
            user.inputData?.school,
            user.inputData?.course_type,
            user.inputData?.course,
            user.stage,
            user.stage_key,
            user.stages?.length,
            user.stages,
            user.professions,
            user.professions_top?.[0],
            user.professions_top?.[1],
            user.professions_top?.[2],
            user.coach_mail,
            user.coach_timestamp,
            // user.events  ->>> butta in una cella tutto l'array di oggetti events
            // one  TEST1
            // event

        ];

        row.push(...events)
        return {
            row: row,
            events_count: cont

        };
    }

    const getExcel = async () => {

        console.log('ho cliccato');


        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Luca Cavicchioli';
        workbook.lastModifiedBy = 'Auto-Generated'
        workbook.created = new Date(1969, 4, 20);
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();
        // Set workbook dates to 1904 date system
        workbook.properties.date1904 = true;
        // Force workbook calculation on load
        workbook.calcProperties.fullCalcOnLoad = true;

        workbook.views = [
            {
                x: 0, y: 0, width: 10000, height: 20000,
                firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ]



        // Create worksheets with headers and footers
        const sheet = workbook.addWorksheet('Report Studenti Horienta');

        // sheet.properties.defaultRowHeight = 30;
        // sheet.properties.defaultRowHeight = 15;
        const querySnapshot = await getDocs(collection(database, "users"));

        const rows: any[][] = [];
        let maxEvents = 0;
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const item = createRow(doc.id, doc.data());
            rows.push(item.row);

            if (item.events_count > maxEvents) {
                maxEvents = item.events_count;
            }
        });


        sheet.addRow(createFirstRow(maxEvents));
        const firstRow = sheet.getRow(1);
        firstRow.height = 30;
        // firstRow.font.size = 30;
        firstRow.font = { name: 'Arial', color: { argb: 'FF003DA6' }, family: 4, size: 12, underline: 'double', bold: true };

        rows.forEach((row) => {
            sheet.addRow(row);
        });

        //download
        const filename = 'Report Studenti Horienta';
        workbook.xlsx.writeBuffer().then(function (buffer) {
            // done
            console.log(buffer);

            const blob = new Blob([buffer], { type: "applicationi/xlsx" });


            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);

            link.download = `${filename}-${+new Date()}.xlsx`;

            link.click();

        });





    };

    const player1 = React.useRef<Player>(null);

    return (
        <>
            {/* <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{
                    marginTop: '5vh'
                }}
            /> */}


            <header style={{ minHeight: '6vh', maxHeight: '6vh', width: '100vw', backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', zIndex: '10000' }}>
                <img
                    src={logo}
                    alt="Habacus Icon"
                    style={{ minHeight: '4vh', maxHeight: '4vh', paddingLeft: '1vh' }}
                    className="logoH"
                />
                <img
                    src={icon}
                    alt="Habacus Icon"
                    style={{ minHeight: '4vh', maxHeight: '4vh', paddingLeft: '1vh' }}
                    className="iconH"
                />

                <div className='miniStepper nav'>
                </div>
                <div>

                </div>
            </header>

            <Container className='control' style={{
                minHeight: '94vh', maxHeight: '95vh', minWidth: '100vw', maxWidth: '100vw', marginTop: '3vh', paddingLeft: '15vh', paddingRight: '15vh'
            }}>
                <div className="lottieFrameControl">

                    <h1>Mission Control Center</h1>

                    <Player
                        loop
                        autoplay
                        src={dashboard}
                        style={{ maxHeight: '550px', maxWidth: '550px' }}
                        ref={player1}
                        onEvent={(e) => { if (e === 'load') { fixPlayer(player1); } }}
                    >
                    </Player>
                </div>

                <div className='controlTextButt textAlignCenter'>
                    <h3>Scarica Excel</h3>
                    <Button className="purpleButt customButt" onClick={getExcel}>Click</Button>
                </div>

            </Container>
        </>
    );
};

export default MissionControlCenter;