export const provinces = [
    {
        label: "Piemonte",
        options: [
            { value: "Torino", label: "Torino" },
            { value: "Vercelli", label: "Vercelli" },
            { value: "Novara", label: "Novara" },
            { value: "Cuneo", label: "Cuneo" },
            { value: "Asti", label: "Asti" },
            { value: "Alessandria", label: "Alessandria" },
            { value: "Biella", label: "Biella" },
            { value: "Verbano-Cusio-Ossola", label: "Verbano-Cusio-Ossola" }
        ]
    }, {
        label: "Valle d'Aosta",
        options: [
            { value: "Aosta", label: "Aosta" }
        ]
    }, {
        label: "Lombardia",
        options: [
            { value: "Varese", label: "Varese" },
            { value: "Como", label: "Como" },
            { value: "Sondrio", label: "Sondrio" },
            { value: "Milano", label: "Milano" },
            { value: "Bergamo", label: "Bergamo" },
            { value: "Brescia", label: "Brescia" },
            { value: "Pavia", label: "Pavia" },
            { value: "Cremona", label: "Cremona" },
            { value: "Mantova", label: "Mantova" },
            { value: "Lecco", label: "Lecco" },
            { value: "Lodi", label: "Lodi" },
            { value: "Monza e della Brianza", label: "Monza e della Brianza" }
        ]
    }, {
        label: "Trentino-Alto Adige",
        options: [
            { value: "Bolzano", label: "Bolzano" },
            { value: "Trento", label: "Trento" }
        ]
    }, {
        label: "Veneto",
        options: [
            { value: "Verona", label: "Verona" },
            { value: "Vicenza", label: "Vicenza" },
            { value: "Belluno", label: "Belluno" },
            { value: "Treviso", label: "Treviso" },
            { value: "Venezia", label: "Venezia" },
            { value: "Padova", label: "Padova" },
            { value: "Rovigo", label: "Rovigo" }
        ]
    }, {
        label: "Friuli-Venezia Giulia",
        options: [
            { value: "Udine", label: "Udine" },
            { value: "Gorizia", label: "Gorizia" },
            { value: "Trieste", label: "Trieste" },
            { value: "Pordenone", label: "Pordenone" }
        ]
    }, {
        label: "Liguria",
        options: [
            { value: "Imperia", label: "Imperia" },
            { value: "Savona", label: "Savona" },
            { value: "Genova", label: "Genova" },
            { value: "La Spezia", label: "La Spezia" }
        ]
    }, {
        label: "Emilia-Romagna",
        options: [
            { value: "Piacenza", label: "Piacenza" },
            { value: "Parma", label: "Parma" },
            { value: "Reggio Emilia", label: "Reggio Emilia" },
            { value: "Modena", label: "Modena" },
            { value: "Bologna", label: "Bologna" },
            { value: "Ferrara", label: "Ferrara" },
            { value: "Ravenna", label: "Ravenna" },
            { value: "Forlì-Cesena", label: "Forlì-Cesena" },
            { value: "Rimini", label: "Rimini" }
        ]
    }, {
        label: "Toscana",
        options: [
            { value: "Massa-Carrara", label: "Massa-Carrara" },
            { value: "Lucca", label: "Lucca" },
            { value: "Pistoia", label: "Pistoia" },
            { value: "Firenze", label: "Firenze" },
            { value: "Livorno", label: "Livorno" },
            { value: "Pisa", label: "Pisa" },
            { value: "Arezzo", label: "Arezzo" },
            { value: "Siena", label: "Siena" },
            { value: "Grosseto", label: "Grosseto" },
            { value: "Prato", label: "Prato" }
        ]
    }, {
        label: "Umbria",
        options: [
            { value: "Perugia", label: "Perugia" },
            { value: "Terni", label: "Terni" }
        ]
    }, {
        label: "Marche",
        options: [
            { value: "Pesaro e Urbino", label: "Pesaro e Urbino" },
            { value: "Ancona", label: "Ancona" },
            { value: "Macerata", label: "Macerata" },
            { value: "Ascoli Piceno", label: "Ascoli Piceno" },
            { value: "Fermo", label: "Fermo" }
        ]
    }, {
        label: "Lazio",
        options: [
            { value: "Viterbo", label: "Viterbo" },
            { value: "Rieti", label: "Rieti" },
            { value: "Roma", label: "Roma" },
            { value: "Latina", label: "Latina" },
            { value: "Frosinone", label: "Frosinone" }
        ]
    }, {
        label: "Abruzzo",
        options: [
            { value: "L'Aquila", label: "L'Aquila" },
            { value: "Teramo", label: "Teramo" },
            { value: "Pescara", label: "Pescara" },
            { value: "Chieti", label: "Chieti" }
        ]
    }, {
        label: "Molise",
        options: [
            { value: "Campobasso", label: "Campobasso" },
            { value: "Isernia", label: "Isernia" }
        ]
    }, {
        label: "Campania",
        options: [
            { value: "Caserta", label: "Caserta" },
            { value: "Benevento", label: "Benevento" },
            { value: "Napoli", label: "Napoli" },
            { value: "Avellino", label: "Avellino" },
            { value: "Salerno", label: "Salerno" }
        ]
    }, {
        label: "Puglia",
        options: [
            { value: "Foggia", label: "Foggia" },
            { value: "Bari", label: "Bari" },
            { value: "Taranto", label: "Taranto" },
            { value: "Brindisi", label: "Brindisi" },
            { value: "Lecce", label: "Lecce" },
            { value: "Barletta-Andria-Trani", label: "Barletta-Andria-Trani" }
        ]
    }, {
        label: "Basilicata",
        options: [
            { value: "Potenza", label: "Potenza" },
            { value: "Matera", label: "Matera" }
        ]
    }, {
        label: "Calabria",
        options: [
            { value: "Cosenza", label: "Cosenza" },
            { value: "Catanzaro", label: "Catanzaro" },
            { value: "Reggio Calabria", label: "Reggio Calabria" },
            { value: "Crotone", label: "Crotone" },
            { value: "Vibo Valentia", label: "Vibo Valentia" }
        ]
    }, {
        label: "Sicilia",
        options: [
            { value: "Trapani", label: "Trapani" },
            { value: "Palermo", label: "Palermo" },
            { value: "Messina", label: "Messina" },
            { value: "Agrigento", label: "Agrigento" },
            { value: "Caltanissetta", label: "Caltanissetta" },
            { value: "Enna", label: "Enna" },
            { value: "Catania", label: "Catania" },
            { value: "Ragusa", label: "Ragusa" },
            { value: "Siracusa", label: "Siracusa" }
        ]
    }, {
        label: "Sardegna",
        options: [
            { value: "Sassari", label: "Sassari" },
            { value: "Nuoro", label: "Nuoro" },
            { value: "Cagliari", label: "Cagliari" },
            { value: "Oristano", label: "Oristano" },
            { value: "Olbia-Tempio", label: "Olbia-Tempio" },
            { value: "Ogliastra", label: "Ogliastra" },
            { value: "Medio Campidano", label: "Medio Campidano" },
            { value: "Carbonia-Iglesias", label: "Carbonia-Iglesias" }
        ]
    }
]