import React, { useCallback, useEffect, useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { Button, Container, Modal, Nav } from 'react-bootstrap';
import logo from '../assets/lb.png'
import { collection, DocumentReference, onSnapshot, updateDoc, doc, addDoc, getDoc } from 'firebase/firestore';
import { database, useAuth } from '../firebase';
import { Form } from 'react-bootstrap/lib/Navbar';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Lottie from 'react-lottie';
import * as animationData from '../assets/data_analysis.json'
import { nextStage } from '../utils/Stages';
// import developer from '../assets/developer.png'
// import office_manager from '../assets/office_manager.png'
// import project_manager from '../assets/project_manager.png'
import developer from '../assets/developer.jpeg'
import office_manager from '../assets/office_manager.jpeg'
import project_manager from '../assets/project_manager.jpeg'
import next from '../assets/next.png'
import together from '../assets/lottie/together.json'
import coworking from '../assets/lottie/coworking.json'
import { Player } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import { provinces } from '../utils/Provinces';
import { toast } from 'react-toastify';
import { fixPlayer } from '../utils/LottiePlayer';


export interface IEducationalAdvisoryProps { }

const EducationalAdvisory: React.FunctionComponent<IEducationalAdvisoryProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const auth = getAuth();


    const currentUser = useAuth();

    const getUser = async () => {
        if (currentUser != null) {
            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            setUser(document.data());
        }
    }
    useEffect(() => {
        // Wake UP container
        axios.get(`https://advisory-model-vtmgllutxq-ew.a.run.app/wake_me_up`, {
            headers: {
                'safe': '11051985'
            },
            withCredentials: true
        }).then(
            (data) => {
                console.log(data);
            }
        );
    }, []);

    useEffect(
        () => {
            getUser();
        }, [currentUser]
    );
    const [user, setUser] = useState<any>();

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleCloseAndSendData = async () => {
        setShow(false);
        await nextStage(doc(database, 'users', currentUser.uid));
    };


    const [showAnim, setShowAnim] = useState(false);

    const handleShowAnim = () => setShowAnim(true);
    const handleCloseAnim = () => setShowAnim(false);


    const handleCloseAndSendDataAnim = async () => {
        try {
            setIsLoading(true);
            // FOR THE ANIMATIONS
            setShowAnim(false);
            const advisoryModelContent = document.getElementById("advisoryModelContent");
            if (advisoryModelContent) {
                advisoryModelContent.style.display = "none";
            }
            const lottieFrame = document.getElementById("lottieFrame");
            if (lottieFrame) {
                lottieFrame.style.display = "block";
            }

            let isDone = 0;

            const professionals_paths: any = {};
            for (let i = 0; i < user?.professions_top.length; i++) {

                const profession = user?.professions_top[i];

                const formData = new FormData();
                formData.append('profession', profession);
                formData.append('city', user.inputData.province_city);

                let retry = 10;
                let isOk = false;
                while (retry > 0 && !isOk) {
                    try {
                        const resp = await axios.put(`https://advisory-model-vtmgllutxq-ew.a.run.app/calculate`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'safe': '11051985'
                            },
                            withCredentials: true
                        })
                        isOk = true;
                        console.log(resp);

                        const data = resp.data;//JSON.parse(resp.data.replaceAll("NaN", "0"));

                        const paths: any = {};

                        const path_acronyms = [
                            { 'from': 'most_frequent_nodes_hb', 'to': 'most_frequent' },
                            { 'from': 'most_payed_hb', 'to': 'premium' },
                            { 'from': 'shortest_path_hb', 'to': 'fastest' },
                        ];

                        const course_acronyms = [
                            { 'from': 'master1', 'to': 'm1' },
                            { 'from': 'triennale', 'to': 'lt' },
                            { 'from': 'magistrale', 'to': 'lm' },
                            { 'from': 'its', 'to': 'its' },
                            { 'from': 'master2', 'to': 'm2' },
                            { 'from': 'vocational', 'to': 'vocational' },
                            { 'from': 'cu', 'to': 'lmcu' },
                        ];

                        path_acronyms.forEach(path_acronym => {
                            const from = data[path_acronym.from];
                            const to: any = {};
                            let feeTotal = 0;
                            course_acronyms.forEach(element => {
                                if (from[element.from]) {
                                    let item = from[element.from];

                                    if (item['pos_1']) {
                                        item = item['pos_1'];
                                    }

                                    const school_path: any = {};
                                    school_path['school'] = item['ente'];
                                    school_path['course'] = item['corso'];
                                    school_path['place'] = item['città'];
                                    school_path['duration'] = item['durata_in_anni'];
                                    school_path['weight'] = item['weight'];
                                    school_path['type'] = item['type'];

                                    to[element.to] = school_path;
                                }
                            });
                            to['ral_0'] = from['ral_mediana'][0];
                            to['fee_total'] = feeTotal;
                            paths[path_acronym.to] = to;
                        });

                        professionals_paths[profession] = paths;

                        isDone++;
                    } catch (e) {
                        console.error(e);
                        retry--;
                    }
                }
                try {
                    const docRef = doc(database, 'users', currentUser.uid);
                    const document = await getDoc(docRef);
                    if (!document.exists()) {
                        throw "Document does not exist!";
                    }

                    await updateDoc(docRef, { professionals_paths: professionals_paths });

                } catch (e) {
                    // This will be a "Update is too big" error.
                    console.error(e);

                }
                // try {
                //     const docRef = doc(database, 'users', currentUser.uid);
                //     const constPaths = await runTransaction(database, async (transaction) => {
                //         const document = await transaction.get(docRef);
                //         if (!document.exists()) {
                //             throw "Document does not exist!";
                //         }

                //         transaction.update(docRef, { professionals_paths: professionals_paths });
                //     });

                // } catch (e) {
                //     // This will be a "Update is too big" error.
                //     console.error(e);

                // }

                console.log(professionals_paths);
            }
            if (isDone != 3) { return }

            await new Promise(r => setTimeout(r, 5000));
            await nextStage(doc(database, 'users', currentUser.uid));
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };


    // const handleCloseAndSendDataAndCallLottie = () => {
    //     setShow(false);
    //     setTimeout(() => {
    //       await nextStage(doc(database, 'users', currentUser.uid));
    //     }, 5000)
    // };

    const options = [
        { value: 'developer', label: 'Developer' },
        { value: 'project_manager', label: 'Project Manager' },
        { value: 'tester', label: 'Tester' }
    ]
    const animatedComponents = makeAnimated();

    const defaultAnimOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const lottieFrame = document.getElementById("lottieFrame");
        if (lottieFrame) {

            lottieFrame.style.display = "none";
        }
    }, []);


    //RANDOM TEXT START
    const textArray = [
        'Stiamo incrociando i dati',
        'Stiamo proiettando il tuo futuro',
        'Stiamo costruendo i prossimi step della tua formazione',
        'Stiamo definendo i percorsi di studio per te'
    ];

    const [textLoader, setTextLoader] = useState('');

    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * textArray.length);
        setTextLoader(textArray[index]);
    }, []);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 3000);
        return () => clearInterval(intervalID);
    }, [shuffle])
    //RANDOM TEXT END


    const player1 = React.useRef<Player>(null);
    const player2 = React.useRef<Player>(null);

    return (<div className='advisoryModel'>


        <Modal show={showAnim} onHide={handleCloseAnim}>
            <Modal.Header closeButton>
                <Modal.Title className='textAlignCenter width100 colorBlue'>
                    Conferma la tua Professione</Modal.Title>
            </Modal.Header>
            <Modal.Body className='textAlignLeft'>
                Conferma la tua scelta, lasciaci tessere la tela del tuo futuro e scegli il tuo destino!
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAnim}
                    disabled={isLoading}>
                    Cancella
                </Button>
                <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendDataAnim}
                    disabled={isLoading}>
                    {
                        isLoading
                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            : "Prosegui"
                    }
                </Button>
            </Modal.Footer>
        </Modal>


        <div className='advisoryModelContent' id="advisoryModelContent">

            <h1 >
                Le 3 professioni che più fanno per te
            </h1>

            <div className="lottieFrame">

                <Player
                    autoplay
                    loop
                    src={together}
                    style={{ maxHeight: '350px', maxWidth: '350px' }}
                    ref={player1}
                    onEvent={(e) => { if (e === 'load') { fixPlayer(player1); } }}
                >
                </Player>

            </div>

            <p className='textAlignCenter'>
                In base alle informazioni che hai condiviso nei precedenti step, ecco <b>le 3 professioni che più fanno per te</b>.
            </p>
            <p className='textAlignCenter'>
                Prosegui il tuo percorso di orientamento e scopri quali sono i <b>percorsi di studio più veloci, più diffusi e più sfidanti</b> per realizzare queste professioni.
            </p>

            <br />
            <div className='row textAlignJustify'>
                <div className="col-12 jobCard text-decoration-underline">
                    <h3 className='colorBlue textAlignCenter text-uppercase'>
                        {user?.professions_top[0]}
                    </h3>
                </div>
                <div className="col-12 jobCard text-decoration-underline">
                    <h3 className='colorBlue textAlignCenter text-uppercase'>
                        {user?.professions_top[1]}
                    </h3>
                </div>
                <div className="col-12 jobCard text-decoration-underline">
                    <h3 className='colorBlue textAlignCenter text-uppercase'>
                        {user?.professions_top[2]}
                    </h3>
                </div>
            </div>
            <br />

            <div className="textAlignRight mb-3">
                <Button className="purpleButt customButt" onClick={handleCloseAndSendDataAnim}>
                    <div className='flexButt'>
                        <img src={next} alt="" />
                        <div className='saveAndGo flexCenter'>
                            <div>PROSEGUI</div>
                        </div>
                    </div>
                </Button>
            </div>

        </div>
        <div id="lottieFrame">
            <Player
                autoplay
                loop
                src={coworking}
                style={{ maxHeight: '450px', maxWidth: '450px' }}
                ref={player2}
                onEvent={(e) => { if (e === 'load') { fixPlayer(player2); } }}
            >
            </Player>

            <div id="textLoader">{textLoader}</div>
        </div>
    </div>);


};

export default EducationalAdvisory
    ;