import React, { useEffect, useState } from 'react';
import { getAuth, } from 'firebase/auth';
import { Button, Modal } from 'react-bootstrap';
import { collection, onSnapshot, doc, addDoc, getDoc } from 'firebase/firestore';
import { database, useAuth } from '../firebase';
import { nextStage } from '../utils/Stages';
import next from '../assets/next.png'
import icon_1 from '../assets/icon_1.png'
import icon_2 from '../assets/icon_2.png'
import icon_3 from '../assets/icon_3.png'
import purpleHcool from '../assets/purpleHcool.png'


export interface ILoggedProps { }

const Logged: React.FunctionComponent<ILoggedProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const currentUser = useAuth();

    const getUser = async () => {
        if (currentUser != null) {
            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            setUser(document.data());
        }
    }

    useEffect(
        () => {
            getUser()
        }, [currentUser]
    );
    const [user, setUser] = useState<any>();

    // const DELAY = 8000;
    // const auth = getAuth();




    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleCloseAndSendData = async () => {
        try {
            setIsLoading(true);
            await nextStage(doc(database, 'users', currentUser.uid));
            setShow(false);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };


    // useEffect(() => {
    //     const iframeAndForm = document.getElementById("iframeAndForm");
    //     if (iframeAndForm) {

    //         iframeAndForm.style.visibility = "hidden";
    //     }
    //     setTimeout(() => {
    //         if (iframeAndForm) {
    //             iframeAndForm.style.visibility = "visible";
    //         }
    //     }, DELAY)
    // }, []);


    const renderLoggerText = () => {

        if (!user) {
            return null;
        } else if (user?.stages?.includes('VIDEO_MEETING')) {
            return <div id="withCoach">
                <h4>
                    Le tappe del percorso
                </h4>
                <p>
                    Per prima cosa, conoscerai meglio te stesso attraverso le <b>domande del test di orientamento</b>, individuerai quindi quali sono <b>le professioni più indicate per te</b> con l&apos;aiuto di un <b>formatore esperto di orientamento</b> secondo le tue caratteristiche. Infine ti saranno mostrati <b>i percorsi di studio</b> che puoi intraprendere per raggiungere i tuoi obiettivi. <br />
                    Al termine di ogni sezione, abbiamo previsto <b>un breve questionario</b> così che tu possa darci i tuoi commenti <b>per migliorare il percorso di Horienta</b>.
                </p>
                <h4>
                    Tempi
                </h4>
                <p>
                    Ti accompagneremo in questo viaggio e <b>ti guideremo passo passo</b>. Il nostro primo consiglio è quello di iniziare il percorso in un momento di tranquillità, in cui potrai <b>dedicare il giusto tempo ad ogni step</b>. <br />
                    Le sezioni sono semplici e snelle, ma per il test di orientamento (step 3) e per la sessione con il formatore (step 5) ti consigliamo di stimare almeno un&apos;oretta.
                </p>
                <h4>
                    Modalità
                </h4>
                <p>
                    Solo una volta concluso lo step, potrai cliccare sul pulsante <b>“Salva e prosegui”</b> o <b>“Prosegui”</b> per <b>accedere alla sezione successiva</b>. Se uscirai dal percorso, potrai accedere direttamente alla sezione successiva solo nel caso in cui tu abbia salvato. In caso contrario dovrai rifare l&apos;attività.
                </p>


            </div>

        } else {
            return <div id="withoutCoach">
                <h4>
                    Le tappe del percorso
                </h4>
                <p>
                    Per prima cosa, conoscerai meglio te stesso attraverso le <b>domande del test di orientamento</b>, individuerai quindi quali sono <b>le professioni più indicate per te</b> secondo le tue caratteristiche. Infine ti saranno mostrati <b>i percorsi di studio</b> che puoi intraprendere per raggiungere i tuoi obiettivi. <br />
                    Al termine di ogni sezione abbiamo previsto <b>un breve questionario</b> così che tu possa darci i tuoi commenti <b>per migliorare il percorso di Horienta</b>.

                </p>
                <h4>
                    Tempi
                </h4>
                <p>
                    Ti accompagneremo in questo viaggio e <b>ti guideremo passo passo</b>. Il nostro primo consiglio è quello di iniziare il percorso in un momento di tranquillità, in cui potrai <b>dedicare il giusto tempo ad ogni step</b>. <br />
                    Le sezioni sono semplici e snelle, ma per il test di orientamento (step 3) ti consigliamo di stimare almeno un&apos;oretta.
                </p>
                <h4>
                    Modalità
                </h4>
                <p>
                    Solo una volta concluso lo step potrai cliccare sul pulsante <b>“Salva e prosegui”</b> o <b>“Prosegui”</b> per <b>accedere alla sezione successiva</b>. Se uscirai dal percorso, potrai accedere direttamente alla sezione successiva solo nel caso in cui tu abbia salvato. In caso contrario dovrai rifare l&apos;attività.
                </p>
            </div>

        }

    }

    const renderGeneralRules = () => {
        return (<>
            <h2 className='textAlignCenter mt-5'>Regole Generali</h2>
            <div className="row my-3 textAlignCenter">
                <div className="col-12 col-md-4">
                    <div className="icon_frame">
                        <img src={icon_1} alt="" />
                    </div>
                    Per <b>tener traccia del percorso</b> tieni d&apos;occhio lo stepper in alto: ti indica quale sezione hai raggiunto e quali sono quelle successive.
                </div>
                <div className="col-12 col-md-4">
                    <div className="icon_frame">
                        <img src={icon_2} alt="" />
                    </div>
                    Per svolgere l&apos;intero processo ti suggeriamo di <b>usare un computer</b>. Nel caso in cui cambiassi dispositivo, ricordati di salvare prima di uscire!
                </div>
                <div className="col-12 col-md-4">
                    <div className="icon_frame">
                        <img src={icon_3} alt="" />
                    </div>
                    Per <b>contattarci nel caso di dubbi o di problemi tecnici</b>, puoi cliccare sul punto interrogativo e scrivici.
                </div>

            </div>
        </>);
    }

    return (<div className='logged containerPages'>

        {/* {renderNav()} */}
        {/* <Stepper /> */}

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='textAlignCenter width100  colorBlue'>
                    Versione Beta
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='textAlignLeft'>
                <p>
                    Quella che stai per intraprendere è la versione di prova di Horienta.
                </p>
                <p>
                    La tua partecipazione al test e il tuo feedback sono davvero importanti per noi, per poter realizzare un progetto a misura dei giovani studenti come te.
                </p>
                <p>
                    Proprio perché si tratta di un test, ti chiediamo discrezionalità, perciò <b>non condividere informazioni, materiali e screenshot</b> al di fuori dell&apos;ambiente di test.
                </p>
                <p>
                    Grazie,<br />
                    Team Habacus
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}
                    disabled={isLoading}>
                    Indietro
                </Button>
                <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendData}
                    disabled={isLoading}>
                    {
                        isLoading
                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            : "Ho Capito"
                    }
                </Button>
            </Modal.Footer>
        </Modal>


        <div className='containerLogger'>
            <h1 className='flexCenter my-3 desktopLoggedVersion'>
                {/* Ti diamo il benvenuto in <span className='colorPurple fst-italic fw-bolder'>H</span>orienta */}
                Ti diamo il benvenuto in <img src={purpleHcool} alt="" style={{ height: '3rem', paddingLeft: '1vh' }} />orienta
            </h1>
            <h1 className='flexCenter my-3 mobileLoggedVersion'>
                Ti diamo il benvenuto
            </h1>
            <h1 className='flexCenter my-3 mobileLoggedVersion'>
                in <img src={purpleHcool} alt="" style={{ height: '3rem', paddingLeft: '1vh' }} />orienta
            </h1>

            <h5 className='textAlignCenter mb-5'>
                Il percorso che ti guida nel trovare il tuo futuro.
            </h5>

            {renderLoggerText()}

            {renderGeneralRules()}
            <div className="textAlignRight mb-3">
                <Button className="purpleButt customButt" onClick={handleShow} >
                    <div className='flexButt'>
                        <img src={next} alt="" />
                        <div className='saveAndGo flexCenter'>
                            <div>PROSEGUI</div>
                        </div>
                    </div>
                </Button>
            </div >

        </div >

    </div >
    );
};

export default Logged;