// import firebase from 'firebase/app'
// import "firebase/auth"
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signOut, signInWithEmailAndPassword, fetchSignInMethodsForEmail, sendPasswordResetEmail, initializeAuth, browserSessionPersistence } from 'firebase/auth';
// import "firebase/database"
import { initializeApp } from "firebase/app";
import { getAnalytics, initializeAnalytics } from "firebase/analytics";
// import firebase from "firebase/app";
import { initializeFirestore } from 'firebase/firestore';
import { useEffect, useState } from 'react';


const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

// const db = firebase.firestore();
export const database = initializeFirestore(app, { experimentalForceLongPolling: true, ignoreUndefinedProperties: true });
export const auth = initializeAuth(app, { persistence: browserSessionPersistence, popupRedirectResolver: undefined });
export const analytics = initializeAnalytics(app);

export function signup(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function checksignup(email: string) {
  return fetchSignInMethodsForEmail(auth, email);
}

export function login(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
  return signOut(auth);
}

export function resetpass(email: string) {
  return sendPasswordResetEmail(auth, email);
}


export function useAuth() {
  const [currentUser, setCurrentUser] = useState<any>();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, user => setCurrentUser(user));
    return unsub;
  }, [])

  return currentUser;
}


export default app

/* 
https://github.com/firebase/firebase-js-sdk/issues/4211


databaseURL misssing
*/