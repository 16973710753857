import React, { useEffect, useState } from 'react';
import { Button, Modal, Nav } from 'react-bootstrap';
import { doc } from 'firebase/firestore';
import { database, useAuth } from '../firebase';
import { nextStage } from '../utils/Stages';
import floppy from '../assets/icons8-salva-100.png'


export interface IVideoCallSurveyProps { }

const VideoCallSurvey: React.FunctionComponent<IVideoCallSurveyProps> = (props) => {


    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const DELAY_BUTT = 5000; //salva e continua

    const currentUser = useAuth();

    const [show, setShow] = useState(false);

    const phrase = 'EMARB';
    const [surveyText, setSurveyText] = React.useState<string>('');
    const [surveyTextError, setSurveyTextError] = React.useState<string>('');
    const handleKeyUpSurveyText = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleCloseAndSendData();
        }
    }

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleCloseAndSendData = async () => {
        try {
            setIsLoading(true);
            setSurveyTextError("");
            if (!surveyText) {
                setSurveyTextError("Inserire il codice che apparirà dopo il questionario.");
            } else if (surveyText?.toUpperCase().trim() === phrase) {
                await nextStage(doc(database, 'users', currentUser.uid));
                setShow(false);
            } else {
                setSurveyTextError("Codice sbagliato!");
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };


    // useEffect(() => {

    //     let button = <Button id="endQuizButton" variant="light" onClick={handleShow}>
    //         <span className="colorBlue"> Clicca per finire il TEST</span>
    //     </Button>;


    //     let buttContainer = document.getElementById("stepperButtonContainer") as HTMLElement;

    // }, []);

    useEffect(() => {
        const endQuizButton = document.getElementById("endQuizButton");
        if (endQuizButton) {

            endQuizButton.style.visibility = "hidden";
        }
        setTimeout(() => {
            if (endQuizButton) {
                endQuizButton.style.visibility = "visible";
            }
        }, DELAY_BUTT)
    }, []);

    return (<div className='videoCallSurvey'>
        <Nav className='row navStepper'>
            <div className='col-4 colorPurple flexLeft titleStep'>
            </div>
            <div className='col-4'>

            </div>
            <div className='col-4 textAlignRight my-1'>

                <Button id="endQuizButton" className="purpleButt customButt" onClick={handleShow}>
                    <div className='flexButt'>
                        <img src={floppy} alt="" />
                        <div className='saveAndGo'>
                            <div>SALVA</div>
                            <div>E PROSEGUI</div>
                        </div>
                    </div>
                </Button>

            </div>
        </Nav>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='textAlignCenter width100'>
                    Questionario Completato!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='textAlignCenter'>
                <label htmlFor="surveyText">Inserisci il codice di fine questionario</label>
                <br /> <br />
                <input type="text" name="surveyText" id="surveyText"
                    value={surveyText}
                    onChange={(e) => setSurveyText(e.target.value)}
                    onKeyPress={handleKeyUpSurveyText} />
                <small className='colorRed'>
                    <br />
                    {surveyTextError}
                </small>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}
                    disabled={isLoading}>
                    Cancella
                </Button>
                <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendData}
                    disabled={isLoading}>
                    {
                        isLoading
                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            : "Prosegui"
                    }
                </Button>
            </Modal.Footer>
        </Modal>


        <h1>Sessione con formatore </h1> <h3 className='text-uppercase colorBlue textAlignCenter fw-bold '>  Com&apos;è andata?</h3>
        <iframe src={"https://survey.zohopublic.eu/zs/wJBjN9?email=" + currentUser?.email} frameBorder='0' style={{ height: '700px', width: '100%' }} marginWidth={0} marginHeight={0} scrolling='auto' allow='geolocation'></iframe>

    </div>);
};
export default VideoCallSurvey;