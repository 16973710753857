import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Button, Modal, Form } from 'react-bootstrap';
import { DocumentReference, doc, getDoc, DocumentData, updateDoc } from 'firebase/firestore';
import { database, useAuth } from '../firebase';
import { nextStage } from '../utils/Stages';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { provinces } from '../utils/Provinces';
import floppy from '../assets/icons8-salva-100.png'
import { schools, course_types, courses } from '../utils/Hcp';

export interface IBasicFormProps { }

const BasicForm: React.FunctionComponent<IBasicFormProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const auth = getAuth();

    const currentUser = useAuth();

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [gender, setGender] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')
    const [provinceCity, setProvinceCity] = useState('')
    const [school, setSchool] = useState('')
    const [courseType, setCourseType] = useState('')
    const [course, setCourse] = useState('')


    const [user, setUser] = useState<any>();
    const [show, setShow] = useState(false);
    const [myCourses, setMyCourses] = useState<any>();

    const [active, setActive] = React.useState(false);
    const [control, setControl] = React.useState(0);

    const getUser = async () => {
        if (currentUser != null) {
            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            setUser(document.data());

            if (document.data()?.inputData) {
                setFirstName(document.data()?.inputData.firstName);
                setLastName(document.data()?.inputData.lastName);
                setBirthDate(document.data()?.inputData.birthDate);
                setGender(document.data()?.inputData.gender);
                setPhone(document.data()?.inputData.phone);
                setProvinceCity(document.data()?.inputData.province_city);
            }

            if (document.data()?.from?.toUpperCase() === 'HABACUS') {
                setActive(false);
            } else {
                setActive(true);
                if (document.data()?.inputData) {
                    setSchool(document.data()?.inputData.school);
                    setCourse(document.data()?.inputData.course);
                    setCourseType(document.data()?.inputData.courseType);
                }
            }
        }
    }

    useEffect(() => {
        setMyCourses(courses);
    }, []);

    useEffect(
        () => {
            getUser()
        }, [currentUser]
    );

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    const sendFormData = async (docRef: DocumentReference<DocumentData>) => {
        // const docRef = doc(database, 'users', currentUser.uid);

        // Create a reference to the SF doc.

        try {

            const document = await getDoc(docRef);
            if (!document.exists()) {
                throw "Document does not exist!";
            }

            const inputData = {
                'firstName': firstName ?? null,
                'lastName': lastName ?? null,
                'birthDate': birthDate ?? null,
                'gender': gender ?? null,
                'phone': phone ?? null,
                'province_city': provinceCity ?? null,
                'school': school ?? null,
                'courseType': courseType ?? null,
                'course': course ?? null
            };

            await updateDoc(docRef, { inputData: inputData });

            // console.log("Update increased to ", inputData);
        } catch (e) {
            // This will be a "Update is too big" error.
            console.error(e);
        }

        // try {
        //     const constFormData = await runTransaction(database, async (transaction) => {
        //         const document = await transaction.get(docRef);
        //         if (!document.exists()) {
        //             throw "Document does not exist!";
        //         }

        //         const inputData = {
        //             'firstName': firstName ?? null,
        //             'lastName': lastName ?? null,
        //             'birthDate': birthDate ?? null,
        //             'gender': gender ?? null,
        //             'phone': phone ?? null,
        //             'province_city': provinceCity ?? null,
        //             'school': school ?? null,
        //             'courseType': courseType ?? null,
        //             'course': course ?? null
        //         };

        //         transaction.update(docRef, { inputData: inputData });
        //     });

        //     // console.log("Update increased to ", inputData);
        // } catch (e) {
        //     // This will be a "Update is too big" error.
        //     console.error(e);
        // }

    }

    const handleCloseAndSendData = async () => {

        try {
            setIsLoading(true);
            // qua devo mandare i dati
            await sendFormData(doc(database, 'users', currentUser.uid));
            await nextStage(doc(database, 'users', currentUser.uid));
            setShow(false);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const optionsGender = [
        { 'value': 'male', 'label': 'Uomo' },
        { 'value': 'female', 'label': 'Donna' },
        { 'value': 'other', 'label': 'Preferisco non dichiararlo' }
    ]

    const optionsProvince = provinces;

    const animatedComponents = makeAnimated();

    const handleChangeGender = (selectedOption: any) => {
        setGender(selectedOption?.value);
    };

    const getGenderValue = () => {
        for (let index = 0; index < optionsGender.length; index++) {

            if (optionsGender[index].value === gender) {
                return optionsGender[index];
            }
        }
        return null
    }

    const handleChangeProvince = (selectedOption: any) => {
        setProvinceCity(selectedOption?.value);
    };

    const getProvinceValue = () => {
        for (let index = 0; index < optionsProvince.length; index++) {
            for (let jndex = 0; jndex < optionsProvince[index].options.length; jndex++) {
                if (optionsProvince[index].options[jndex].value === provinceCity) {
                    return optionsProvince[index].options[jndex];
                }
            }
        }
        return null
    }

    //school
    const optionsSchool = schools;
    const handleChangeSchool = (selectedOption: any) => {
        setSchool(selectedOption?.value);
    };
    const getSchoolValue = () => {
        for (let index = 0; index < optionsSchool.length; index++) {

            if (optionsSchool[index].value === school) {
                return optionsSchool[index];
            }
        }
        return null
    }

    //CourseType
    const optionsCourseType = course_types;
    const handleChangeCourseType = (selectedOption: any) => {
        setCourseType(selectedOption?.value);
    };
    const getCourseTypeValue = () => {
        for (let index = 0; index < optionsCourseType.length; index++) {

            if (optionsCourseType[index].value === courseType) {
                return optionsCourseType[index];
            }
        }
        return null
    }
    const optionsCourse = courses;

    const handleChangeCourse = (selectedOption: any) => {
        setCourse(selectedOption?.value);
    };

    const getCourseValue = () => {


        for (let index = 0; index < optionsCourse.length; index++) {

            if (optionsCourse[index].value === course) {
                return optionsCourse[index];
            }
        }
        return null
    }



    const checkForm = () => {
        const checkbox = document.getElementById('checkPrivacy') as HTMLInputElement;

        if (!checkbox || checkbox.checked == false) {
            document.getElementById('privacyRequired')!.style.visibility = 'visible';
        } else {
            document.getElementById('privacyRequired')!.style.visibility = 'hidden';
        }

        setControl(1);


        if (firstName && lastName && birthDate && gender && phone && provinceCity && checkbox && checkbox.checked) {
            //todo controllare se ho checcato si allora controllo che ci siano anche ente corso e tipocorso.
            if (!active) {
                handleCloseAndSendData();
            } else {
                if (courseType) {
                    if (courseType === "Diploma Scuola Superiore") {
                        handleCloseAndSendData();
                    } else {
                        if (school && course) {
                            handleCloseAndSendData();
                        }
                    }
                }

            }
        }


    }

    const controlValue = (value: any) => {

        if (value == '' || value == null || value == undefined) {
            return <div className='colorRed'>Mancante</div>
        }
    }




    const studyTitleComponent = () => {
        return (
            <div>
                <div className='row mb-3 fw-bold'>
                    Inserisci il tuo ultimo titolo di studio.
                </div>

                <div className='row mb-3 '>
                    <Form.Group className="col-12 mb-3 mb-md-0" >

                        <Form.Label>Ultimo titolo di Studio</Form.Label>
                        <Select
                            options={optionsCourseType}
                            components={animatedComponents}
                            isClearable
                            placeholder={'Tipo di Corso'}
                            value={getCourseTypeValue()}
                            onChange={handleChangeCourseType}
                        />
                        {
                            control
                                ?
                                controlValue(getCourseTypeValue())
                                :
                                ''
                        }
                    </Form.Group>
                </div>
            </div>);
    }

    const studyPostDiplomaComponent = () => {
        return !courseType || courseType === "Diploma Scuola Superiore"
            ? null
            : (
                <div>
                    <div className='row mb-3 '>

                        <Form.Group className="col-12 mb-3 mb-md-0" > {/* controlId="formBasicDate" */}

                            <Form.Label>Ente</Form.Label>
                            <Select
                                options={optionsSchool}
                                components={animatedComponents}
                                isClearable
                                placeholder={'Scuola'}
                                value={getSchoolValue()}
                                onChange={handleChangeSchool}
                            />
                            {
                                control
                                    ?
                                    controlValue(getSchoolValue())
                                    :
                                    ''
                            }
                        </Form.Group>
                    </div>

                    <div className='row mb-3 '>

                        <Form.Group className="col-12 mb-3 mb-md-0" >
                            <Form.Label>Corso</Form.Label>
                            <Select
                                options={optionsCourse}
                                components={animatedComponents}
                                isClearable
                                placeholder={'Corso'}
                                value={getCourseValue()}
                                onChange={handleChangeCourse}
                            />
                            {
                                control
                                    ?
                                    controlValue(getCourseValue())
                                    :
                                    ''
                            }
                        </Form.Group>

                    </div>
                </div>);

    }

    return (<div className='basicForm containerPages'>
        {/* <Stepper /> */}

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='textAlignCenter width100 colorBlue'>
                    Form Completato
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='textAlignJustify'>
                Sei sicuro di aver inserito tutti i dati corretti o vuoi ricontrollare?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancella
                </Button>
                <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendData}>
                    Prosegui
                </Button>
            </Modal.Footer>
        </Modal>

        <div className='containerForm contentContainer'>

            <div className="text mt-3">
                <h1 className='textAlignCenter'>
                    Il tuo profilo
                </h1>
                <div className='textAlignCenter'>
                    Ti chiediamo di compilare il form con alcuni dei tuoi dati anagrafici, ci servono per conoscerti meglio e individuare il percorso più adatto a te.
                </div>
            </div>
            <br /><br />
            <Form>
                <div className='row mb-3 '>
                    <Form.Group className="col-12 col-md-6 mb-3 mb-md-0" >
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type="text" placeholder="Inserisci nome" value={firstName} onChange={event => setFirstName(event.target.value)} />
                        {
                            control
                                ?
                                controlValue(firstName)
                                :
                                ''
                        }
                    </Form.Group>
                    <Form.Group className="col-12 col-md-6 mb-3 mb-md-0" > {/* controlId="formBasicLastName" */}
                        <Form.Label>Cognome</Form.Label>
                        <Form.Control type="text" placeholder="Inserisci cognome" id='inputLastName' value={lastName} onChange={event => setLastName(event.target.value)} />
                        {
                            control
                                ?
                                controlValue(lastName)
                                :
                                ''
                        }
                    </Form.Group>
                </div>

                <div className='row mb-3 '>
                    <Form.Group className="col-12 col-md-6 mb-3 mb-md-0" > {/* controlId="formBasicBornDate" */}
                        <Form.Label>Data di nascita</Form.Label>
                        <Form.Control type="date" placeholder="Inserisci data di nascita" value={birthDate} onChange={event => setBirthDate(event.target.value)} />
                        {
                            control
                                ?
                                controlValue(birthDate)
                                :
                                ''
                        }
                    </Form.Group>

                    <Form.Group className="col-12 col-md-6 mb-3 mb-md-0" > {/* controlId="formBasicDate" */}
                        <Form.Label>Genere</Form.Label>
                        <Select
                            options={optionsGender}
                            components={animatedComponents}
                            isClearable={true}
                            placeholder={'Genere'}
                            value={getGenderValue()}
                            onChange={handleChangeGender}
                        />
                        {
                            control
                                ?
                                controlValue(getGenderValue())
                                :
                                ''
                        }
                    </Form.Group>
                </div>

                <div className='row mb-3 '>
                    <Form.Group className="col-12 col-md-6 mb-3 mb-md-0" >
                        <Form.Label>Telefono</Form.Label>
                        <Form.Control type="number" placeholder="Inserisci telefono" value={phone} onChange={event => setPhone(event.target.value)} />
                        {
                            control
                                ?
                                controlValue(phone)
                                :
                                ''
                        }
                    </Form.Group>

                    <Form.Group className="col-12 col-md-6 mb-3 mb-md-0" > {/* controlId="formBasicDate" */}

                        <Form.Label>Provincia</Form.Label>
                        <Select
                            options={optionsProvince}
                            components={animatedComponents}
                            isClearable
                            placeholder={'Provincia'}
                            value={getProvinceValue()}
                            onChange={handleChangeProvince}
                        />
                        {
                            control
                                ?
                                controlValue(getProvinceValue())
                                :
                                ''
                        }
                    </Form.Group>


                </div>

                {
                    active
                        ? studyTitleComponent()
                        : null
                }
                {
                    active
                        ? studyPostDiplomaComponent()
                        : null
                }
                {/* Stai frequentado una scuola post diploma? CHECKBOX CHE ATTIVA LA ROW BELOW */}

                {/* <div className='row mb-3 '>

                    <Form.Group className="col-12 col-md-6 mb-3 mb-md-0" >
                        <Form.Label>Nome scuola</Form.Label> 
                        <Select
                            options={optionsProvince}
                            components={animatedComponents}
                            isClearable
                            placeholder={'Provincia'}
                        />
                    </Form.Group>
                    
                    <Form.Group className="col-12 col-md-6 mb-3 mb-md-0" >

                        <Form.Label>Che cosa?</Form.Label>
                        <Select
                            options={optionsProvince}
                            components={animatedComponents}
                            isClearable
                            placeholder={'Provincia'}
                        />
                    </Form.Group>

                </div> */}

                <div className='row mb-3 '>
                    <Form.Group className="col-12 col-md-6 mb-3 mb-md-0 checkGroup" controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            id='checkPrivacy'
                        // onChange={event => setPrivacy(event.target.value)} 
                        />
                        <Form.Label>Accetto la <a href="https://www.habacus.com/it/privacy" target='_blank'>Privacy Policy</a></Form.Label>
                    </Form.Group>
                    <div id="privacyRequired" className='colorRed'>Privacy mancante</div>

                </div>


                <div className='textAlignRight my-3'>
                    {/* <Button className="purpleButt" onClick={handleShow}> */}
                    <Button className="purpleButt customButt" onClick={checkForm}
                        disabled={isLoading}>
                        {
                            isLoading
                                ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                : (
                                    <div className='flexButt'>
                                        <img src={floppy} alt="" />
                                        <div className='saveAndGo'>
                                            <div>SALVA</div>
                                            <div>E PROSEGUI</div>
                                        </div>
                                    </div>)
                        }
                    </Button>
                </div>
            </Form>

        </div>

    </div >);
};

export default BasicForm;