import React, { useEffect, useState } from 'react';
import { Button, Modal, Nav } from 'react-bootstrap';
import { doc } from 'firebase/firestore';
import { database, useAuth } from '../firebase';
import { nextStage } from '../utils/Stages';
import floppy from '../assets/icons8-salva-100.png'


export interface ITestMotivaSurveyProps { }

const TestMotivaSurvey: React.FunctionComponent<ITestMotivaSurveyProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const DELAY_BUTT = 5000; //salva e continua


    const currentUser = useAuth();

    const [show, setShow] = useState(false);


    const phrase = 'ALBUS';
    const [surveyText, setSurveyText] = React.useState<string>('');
    const [surveyTextError, setSurveyTextError] = React.useState<string>('');
    const handleKeyUpSurveyText = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleCloseAndSendData();
        }
    }


    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleCloseAndSendData = async () => {
        try {
            setIsLoading(true);
            setSurveyTextError("");
            if (!surveyText) {
                setSurveyTextError("Inserire il codice che apparirà dopo il questionario.");
            } else if (surveyText?.toUpperCase().trim() === phrase) {
                await nextStage(doc(database, 'users', currentUser.uid));
                setShow(false);
            } else {
                setSurveyTextError("Codice sbagliato!");
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const endQuizButton = document.getElementById("endQuizButton");
        if (endQuizButton) {

            endQuizButton.style.visibility = "hidden";
        }
        setTimeout(() => {
            if (endQuizButton) {
                endQuizButton.style.visibility = "visible";
            }
        }, DELAY_BUTT)
    }, []);

    useEffect(() => {

        let button = <Button id="endQuizButton" variant="light" onClick={handleShow}>
            <span className="colorBlue"> Clicca per finire il TEST</span>
        </Button>;


        let buttContainer = document.getElementById("stepperButtonContainer") as HTMLElement;
        // buttContainer?.html(button);





        // window.addEventListener("message", function (e) {
        //     /* Example to get the name of the event */
        //     console.log("Event name:", e.data.event);

        //     /* Example to get the payload of the event */
        //     console.log("Event details:", e.data.payload);

        //     console.log(e);

        // });

    }, []);
    // const checkSurveyIsFinished = () => {
    //     const ciaoqq = iframe?.current?.contentWindow?.document?.body?.innerHTML;
    //     if (ciaoqq && ciaoqq.toString().indexOf('Grazie per aver partecipato al questionario!') > -1) {
    //         alert("It contains 'something'");
    //     } else {
    //         console.log('non voluto ma con un sorriso');

    //     }

    // }

    // const iframe = useRef<HTMLIFrameElement>(null);

    // const onLoad = () => {
    //     const ciaoqq = iframe;
    //     if (iframe) {
    //         if (iframe.current?.src == 'https//google.com') {
    //             console.log('rediractati cazzone');

    //         }
    //         console.log('rediractati cazzone2');

    //     }

    // }

    return (<div className='testMotivaSurvey'>
        <Nav className='row navStepper'>
            <div className='col-4'>

            </div>
            <div className='col-4'>

            </div>
            <div className='col-4 textAlignRight my-1'>

                <Button id="endQuizButton" className="purpleButt customButt" onClick={handleShow}>
                    <div className='flexButt'>
                        <img src={floppy} alt="" />
                        <div className='saveAndGo'>
                            <div>SALVA</div>
                            <div>E PROSEGUI</div>
                        </div>
                    </div>
                </Button>

            </div>
        </Nav>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='textAlignCenter width100 colorBlue'>
                    Questionario Completato!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='textAlignCenter'>
                <label htmlFor="surveyText">Inserisci il codice di fine questionario</label>
                <br /> <br />
                <input type="text" name="surveyText" id="surveyText"
                    value={surveyText}
                    onChange={(e) => setSurveyText(e.target.value)}
                    onKeyPress={handleKeyUpSurveyText} />
                <small className='colorRed'>
                    <br />
                    {surveyTextError}
                </small>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}
                    disabled={isLoading}>
                    Cancella
                </Button>
                <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendData}
                    disabled={isLoading}>
                    {
                        isLoading
                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            : "Prosegui"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <h1>Test di orientamento </h1>
        <h3 className='text-uppercase colorBlue textAlignCenter fw-bold '>  Com&apos;è andata?</h3>

        <iframe
            // ref={iframe}
            // onLoad={onLoad}
            src={"https://survey.zohopublic.eu/zs/UuBjgk?email=" + currentUser?.email} frameBorder='0'
            style={{ height: '700px', width: '100%' }}
            marginWidth={0}
            marginHeight={0}
            scrolling='auto'
            allow='geolocation'
        ></iframe>

    </div >);
};
export default TestMotivaSurvey;