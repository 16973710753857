import { doc, DocumentData, DocumentReference, getDoc, updateDoc } from "firebase/firestore";
import { database, useAuth } from "../firebase";
import DeviceDetector from "device-detector-js";
import { useEffect, useState } from "react";

export enum Stage {
    DISCLAIMER = 'DISCLAIMER',
    FORM = 'FORM',
    ORIENTATION_TEST = 'ORIENTATION_TEST',
    ORIENTATION_TEST_SURVEY = 'ORIENTATION_TEST_SURVEY',
    CALENDAR = 'CALENDAR',
    VIDEO_MEETING = 'VIDEO_MEETING',
    VIDEO_MEETING_SURVEY = 'VIDEO_MEETING_SURVEY',
    ED_ADVISORY_SELECT = 'ED_ADVISORY_SELECT',
    ED_ADVISORY_RESULT = 'ED_ADVISORY_RESULT',
    ED_ADVISORY_RESULT_SURVEY = 'ED_ADVISORY_RESULT_SURVEY',
    THANK_YOU = 'THANK_YOU'
}

export const previousStage = async (docRef: DocumentReference<DocumentData>) => {
    // const docRef = doc(database, 'users', currentUser.uid);

    // Create a reference to the SF doc.

    // try {
    //     const newUpdate = await runTransaction(database, async (transaction) => {
    //         const document = await transaction.get(docRef);
    //         if (!document.exists()) {
    //             throw "Document does not exist!";
    //         }

    //         const actualStage = document.data()?.stage || 0;
    //         const newStage = actualStage - 1;
    //         const newStageKey = document.data()?.stages[newStage];
    //         if (actualStage > document.data()?.stages.length) throw new Error("codice errore: 284hd, contatta info@habacus.com");

    //         transaction.update(docRef, { stage: newStage, stage_key: newStageKey });
    //         window.location.reload();
    //     });

    //     // console.log("Update increased to ", newUpdate);
    // } catch (e) {
    //     // This will be a "Update is too big" error.
    //     console.error(e);
    // }

    try {
        const document = await getDoc(docRef);
        if (!document.exists()) {
            throw "Document does not exist!";
        }

        const actualStage = document.data()?.stage || 0;
        const newStage = actualStage - 1;
        const newStageKey = document.data()?.stages[newStage];
        if (actualStage > document.data()?.stages.length) throw new Error("codice errore: 284hd, contatta info@habacus.com");

        await updateDoc(docRef, { stage: newStage, stage_key: newStageKey });
        window.location.reload();

        // console.log("Update increased to ", newUpdate);
    } catch (e) {
        // This will be a "Update is too big" error.
        console.error(e);
    }

}


export const nextStage = async (docRef: DocumentReference<DocumentData>) => {
    // const docRef = doc(database, 'users', currentUser.uid);

    // Create a reference to the SF doc.

    // try {

    //     const newUpdate = await runTransaction(database, async (transaction) => {
    //         const document = await transaction.get(docRef);
    //         if (!document.exists()) {
    //             throw "Document does not exist!";
    //         }

    //         const actualStage = document.data()?.stage ?? 0;
    //         const newStage = actualStage + 1;
    //         const newStageKey = document.data()?.stages[newStage];
    //         if (newStage >= document.data()?.stages.length) {
    //             throw new Error("codice errore: 284hd, contatta info@habacus.com");
    //         }
    //         const events = document.data()?.events ?? [];

    //         let device = null;

    //         try {
    //             const ua = navigator.userAgent;
    //             const deviceDetector = new DeviceDetector();
    //             device = deviceDetector.parse(ua);

    //         } catch (error) {

    //         }

    //         events.push({
    //             'timestamp': new Date(),
    //             'newStage': newStage,
    //             'newStageKey': newStageKey,
    //             'type': 'CHANGE_STAGE',
    //             'device': device
    //         });

    //         transaction.update(docRef, { stage: newStage, stage_key: newStageKey, events: events });
    //         window.location.reload();
    //     });

    // } catch (e) {
    //     // This will be a "Update is too big" error.
    //     console.error(e);
    // }

    try {

        const document = await getDoc(docRef);
        if (!document.exists()) {
            throw "Document does not exist!";
        }

        const actualStage = document.data()?.stage ?? 0;
        const newStage = actualStage + 1;
        const newStageKey = document.data()?.stages[newStage];
        if (newStage >= document.data()?.stages.length) {
            throw new Error("codice errore: 284hd, contatta info@habacus.com");
        }
        const events = document.data()?.events ?? [];

        let device = null;

        try {
            const ua = navigator.userAgent;
            const deviceDetector = new DeviceDetector();
            device = deviceDetector.parse(ua);

        } catch (error) {

        }

        events.push({
            'timestamp': new Date(),
            'newStage': newStage,
            'newStageKey': newStageKey,
            'type': 'CHANGE_STAGE',
            'device': device
        });

        await updateDoc(docRef, { stage: newStage, stage_key: newStageKey, events: events });
        window.location.reload();

    } catch (e) {
        // This will be a "Update is too big" error.
        console.error(e);
    }


}
