import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export interface IAuthCoachRouteProps {
    children: any;
}

const AuthCoachRoute: React.FunctionComponent<IAuthCoachRouteProps> = (props) => {
    const { children } = props;
    const auth = getAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const AuthCheck = onAuthStateChanged(auth, (user) => {
            if (
                user && (
                    user.email === 'mfumagalli@cnpvita.it' ||
                    user.email === 'daniela.ferdeghini@gmail.com' ||
                    user.email === 'volpifcoaching@gmail.com' ||
                    user.email === 'ctavanicoaching@gmail.com')
            ) {
                setLoading(false);
            } else {
                console.log('unauthorized');
                navigate('/login?redirect=dashboard-formatore-h5tYl8xw&function=login');
            }
        });

        return () => AuthCheck();
    }, [auth]);

    if (loading) return <p>loading ...</p>;

    return <>{children}</>;
};

export default AuthCoachRoute;


