import React, { useEffect, useState } from 'react';
import { getAuth, } from 'firebase/auth';
import { Button, Modal, Nav } from 'react-bootstrap';
import { collection, onSnapshot, updateDoc, doc, addDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import { database, useAuth } from '../firebase';
import { nextStage } from '../utils/Stages';
import Lottie from 'react-lottie';
import * as animationData from '../assets/loading.json'
import bean from '../assets/bean.svg'


export interface ILoadingProps { }

const Loading: React.FunctionComponent<ILoadingProps> = (props) => {

    const DELAY = 8000;
    const auth = getAuth();

    // useEffect(() => {
    //     onSnapshot(collection(database, 'users'), (snapshot) => {
    //         console.log(snapshot.docs.map(doc => doc.data()));
    //         console.log(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    //     });
    // })

    const renderIframe = () => {
        // if (toShow) {
        return <div id='iframeAndForm'>
            <div className="form" id="myDIV"></div>

            <iframe
                id="myIFRAME"
                name="myIFRAME"
                className="iframeStyle"
            ></iframe>
        </div>
        // }
    }

    const renderLoader = () => {
        // if (!toShow) {
        return <div id='loaderAndError'>
            <div
                className="row text-center"
                id="spinnerContainer"
            >
                <div className="col">
                    <div className="spinner-grow text-primary" role="status" style={{ minHeight: '150px', minWidth: '150px' }}
                    >
                        <span className="sr-only"></span>
                    </div>
                </div>
            </div>

            <div
                className="row text-center warning"
            >
                <div className="col">
                    <h1 className='colorWhite'>Aspetta n'attimo!</h1>
                </div>
            </div>
        </div>
        // }
    }


    const [users, setUsers] = useState<any[]>([{ firstName: 'Tizio', lastName: 'Caio', id: '123 STELLAAAA' }]);

    useEffect(
        () =>
            // onSnapshot(collection(database, 'users'), (snapshot) =>
            //     setUsers(snapshot.docs.map((doc) => { doc.data() }))
            // ),
            onSnapshot(collection(database, 'users'), (snapshot) =>
                setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            ),

        []
    );
    // const baseURL = "https://dev-admin.habacus.com/api/course/startCourse?tag=POSTE_ITALIANE&uuid=648e9685-a593-4f13-bd70-4eb005b634a7&target=myIFRAME";
    // const [iframe, setIframe] = React.useState<any>();


    // useEffect(() => {
    //     const iframeAndForm = document.getElementById("iframeAndForm");
    //     if (iframeAndForm) {

    //         iframeAndForm.style.visibility = "hidden";
    //     }
    //     setTimeout(() => {
    //         if (iframeAndForm) {
    //             iframeAndForm.style.visibility = "visible";
    //         }
    //     }, DELAY)
    // }, []);

    // useEffect(() => {
    //     setTimeout(() => {
    //         const loaderAndError = document.getElementById("loaderAndError");

    //         if (loaderAndError) {
    //             loaderAndError.style.display = "none";
    //         }
    //     }, DELAY)
    // }, []);



    // useEffect(() => {
    //     axios
    //         .get(
    //             baseURL,
    //             {
    //                 params: {
    //                     target: "myIFRAME",
    //                 },
    //             },
    //         )
    //         .then((response) => {

    //             const html = response.data.result.html;
    //             document.getElementById("hiddenForm")?.remove();
    //             const newDiv = document.createElement("div");
    //             newDiv.setAttribute("id", "hiddenForm");
    //             newDiv.innerHTML = html;
    //             document.getElementById("myDIV")?.appendChild(newDiv);
    //             let element: HTMLElement = newDiv.querySelector("input[type=submit]") as HTMLElement;

    //             element.click();


    //         });
    // }, []);

    // const handleNew = async () => {
    //     // updateDoc devo passare in doc come terzo paramentro una id specifica.
    //     // const docRef = doc(database, 'users', 'user001');
    //     // const payload = { firstName: "Mister", lastName: "Misterioso", city: "Città del Mistero" }
    //     // await updateDoc(docRef, payload);

    //     // const collectionRef = collection(database, 'users');
    //     // const payload = { firstName: "Alberto", lastName: "Albertoso", city: "Alberta" }
    //     // await addDoc(collectionRef, payload);
    //     const firstName = prompt("enter firstName")
    //     const lastName = prompt("enter lastName")
    //     const city = prompt("enter city")

    //     const collectionRef = collection(database, 'users');
    //     const payload = { firstName, lastName, city }
    //     await addDoc(collectionRef, payload);

    // }

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleCloseAndSendData = async () => {
        setShow(false);
        await nextStage(doc(database, 'users', currentUser.uid));
    };


    useEffect(() => {
        const iframeAndForm = document.getElementById("iframeAndForm");
        if (iframeAndForm) {

            iframeAndForm.style.visibility = "hidden";
        }
        setTimeout(() => {
            if (iframeAndForm) {
                iframeAndForm.style.visibility = "visible";
            }
        }, DELAY)
    }, []);
    const currentUser = useAuth();


    const defaultAnimOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (<div className='Loading'>




    </div>
    );
};

export default Loading;