import { Watermark } from '@hirohe/react-watermark';
import { getAuth, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import icon from '../assets/ib.png';
import logo from '../assets/lb.png';
import question from '../assets/question.png';
import logOut from '../assets/sign-out.png';
import MiniStepper from '../components/MiniStepper';
import { database, useAuth } from '../firebase';
import { sendSupportMail } from '../utils/General';
import { Stage } from '../utils/Stages';
import BasicForm from './BasicForm';
import Calendly from './Calendly';
import EducationalAdvisory from './EducationalAdvisory';
import Loading from './Loading';
import Logged from './Logged';
import Result from './Result';
import ResultSurvey from './ResultSurvey';
import TestMotiva from './TestMotiva';
import TestMotivaSurvey from './TestMotivaSurvey';
import ThankYou from './ThankYou';
import VideoCall from './VideoCall';
import VideoCallSurvey from './VideoCallSurvey';

export interface IHomePageProps { }

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {

    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);
    const auth = getAuth();
    const currentUser = useAuth();


    const getUser = async () => {
        if (currentUser != null) {
            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            if (document && document?.data()?.deletedAt && document?.data()?.deletedAt != null && document?.data()?.deletedAt !== undefined && document?.data()?.deletedAt !== '') {
                signOut(auth);
                return;
            }

            setUser(document.data());
        }
    }

    useEffect(
        () => {
            getUser()
        }, [currentUser]
    );
    const [user, setUser] = useState<any>();


    const actualPageRender = () => {

        const actualStage = user?.stages[user?.stage];

        switch (actualStage) {
            case Stage.DISCLAIMER:
                return <Logged />;
            case Stage.FORM:
                return <BasicForm />;
            case Stage.ORIENTATION_TEST:
                return <TestMotiva />;
            case Stage.ORIENTATION_TEST_SURVEY:
                return <TestMotivaSurvey />;
            case Stage.CALENDAR:
                return <Calendly />;
            case Stage.VIDEO_MEETING:
                return <VideoCall />;
            case Stage.VIDEO_MEETING_SURVEY:
                return <VideoCallSurvey />;
            case Stage.ED_ADVISORY_SELECT:
                return <EducationalAdvisory />;
            case Stage.ED_ADVISORY_RESULT:
                return <Result />;
            case Stage.ED_ADVISORY_RESULT_SURVEY:
                return <ResultSurvey />;
            case Stage.THANK_YOU:
                return <ThankYou />;
            default:
                return <Loading />;
        }
    }

    return (
        <Watermark text="Do not share - HABACUS property">
            <div style={{ minHeight: '100vh' }}>

                <header className=''>
                    <img
                        src={logo}
                        alt="Habacus Icon"
                        style={{ minHeight: '40px', maxHeight: '40px', paddingLeft: '1vh' }}
                        className="logoH"
                    />
                    <img
                        src={icon}
                        alt="Habacus Icon"
                        style={{ minHeight: '40px', maxHeight: '40px', paddingLeft: '1vh' }}
                        className="iconH"
                    />

                    <div className='miniStepper nav'>
                        <MiniStepper />
                    </div>
                    <div>
                        <Button onClick={() => sendSupportMail()} className="transButt customButt hoverButtMail " title='richiedi aiuto'>
                            <img src={question} alt="Richiedi aiuto" />
                        </Button>

                        <Button onClick={() => signOut(auth)} className="transButt customButt hoverButtLogOut " title='esci'>
                            <img src={logOut} alt="Esci" className='logoutImg' />
                        </Button>
                    </div>
                </header>
                <header className='headerTextLogoutHelp'>
                    <a href="#" onClick={() => sendSupportMail()}>Help</a>
                    <a href="#" onClick={() => signOut(auth)} className='textAlignRight'>Sign Out</a>
                </header>
                <Container fluid className=''>
                    {actualPageRender()}
                </Container >
                {/* <footer className='footerForMobile'>
                    <div className="footerContent flexLeft">
                        Hai dubbi? scrivici a&nbsp;<a href="mailto:horienta.support@habacus.com?subject=Supporto per Horienta&body=Ciao Habacus, ">horienta.support@habacus.com</a>
                    </div>
                </footer> */}
            </div >
        </Watermark>
    );
};

export default HomePage;