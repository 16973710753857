import React, { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { getAuth, } from 'firebase/auth';
import { Button, Modal, Nav } from 'react-bootstrap';
import { collection, onSnapshot, updateDoc, doc, addDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import { database, useAuth } from '../firebase';
import { nextStage } from '../utils/Stages';
import Stepper from '../components/Stepper';
import floppy from '../assets/icons8-salva-100.png'
import next from '../assets/next.png'
import meditation from '../assets/lottie/meditation.json'
import harvard from '../assets/harvard.jpeg'
import { Player } from '@lottiefiles/react-lottie-player';
import { toast } from 'react-toastify';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { fixPlayer } from '../utils/LottiePlayer';


export interface ITestMotivaProps { }

const TestMotiva: React.FunctionComponent<ITestMotivaProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const DELAY = 1000 * 10; // togli lottie e apri iframe
    const DELAY_BUTT = 15000; //salva e continua

    const auth = getAuth();

    const [active, setActive] = React.useState(0);


    const currentUser = useAuth();

    const getUser = async () => {
        if (currentUser != null) {
            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            setUser(document.data());
        }
    }

    useEffect(
        () => {
            getUser()
        }, [currentUser]
    );
    const [user, setUser] = useState<any>();



    const showTestMotiva = () => {


        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isSafari) {
            const element: HTMLElement = document.querySelector("input[type=submit]") as HTMLElement;
            element?.click();
            const buttonThatMustDisappear = document.getElementById('showTestButt');
            if (buttonThatMustDisappear) {
                buttonThatMustDisappear.style.display = 'none';
            }


        } else {
            setActive(1);

        }
    }

    const renderIframe = () => {
        // if (toShow) {
        const style: any = {};

        if (active) {
            style.visibility = 'visible';
        } else {
            style.visibility = 'hidden';
        }
        return <div id='iframeAndForm' style={style}>
            <div className="form" id="myDIV"></div>

            <iframe
                id="myIFRAME"
                name="myIFRAME"
                className="iframeStyle"
            ></iframe>
        </div>
        // }
    }


    const [counter, setCounter] = React.useState(10);
    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);


    const player = React.useRef<Player>(null);

    const renderLoader = () => {
        if (active == 1) {
            return null;
        }

        return <div className='textAlignCenter containerPages' id='loaderAndError'>

            <div className="lottieFrame">

                <Player
                    autoplay
                    loop
                    src={meditation}
                    style={{ maxHeight: '350px', maxWidth: '350px' }}
                    ref={player}
                    onEvent={(e) => { if (e === 'load') { fixPlayer(player); } }}
                >
                </Player>

            </div>
            <h3 className='colorBlue'>
                Massima concentrazione!
            </h3>
            <p>
                Stai per accedere al test di orientamento. <br />
                Ricordati che il test è suddiviso in 3 parti: <br />
            </p>
            <p>
                <b>Video</b> <br />15 minuti per entrare nel mood
            </p>
            <p>
                <b>Test</b> <br />30 minuti per iniziare a conoscerti meglio
            </p>
            <p>
                <b>Esercizi</b> <br /> 20 minuti per metabolizzare i risultati
            </p>


            <div className="my-3 flexCenter">

                <Button className="purpleButt customButt" id="showTestButt" onClick={showTestMotiva}>
                    <div className='flexButt'>
                        <img src={next} alt="" />
                        <div className='saveAndGo flexCenter'>
                            <div>PROSEGUI</div>
                        </div>
                    </div>
                </Button>
                <div id='counterFrame' className='colorPurple fw-bold'>Prosegui tra: {counter}</div>
            </div>


        </div >

    }

    // const baseURL = "https://dev-admin.habacus.com/api/course/startCourse?tag=POSTE_ITALIANE&uuid=648e9685-a593-4f13-bd70-4eb005b634a7&target=myIFRAME";

    const actualUrl = "https://start-motiva-test-vtmgllutxq-ew.a.run.app/startTest";

    // let testUrl = '';
    // if (user?.email.includes('@habacus.com')) {
    //     testUrl = baseURL;
    // } else {
    //     testUrl = actualUrl;
    // }



    useEffect(() => {
        const endQuizButton = document.getElementById("endQuizButton");
        if (endQuizButton) {

            endQuizButton.style.visibility = "hidden";
        }
        setTimeout(() => {
            if (endQuizButton) {
                endQuizButton.style.visibility = "visible";
            }
        }, DELAY_BUTT)
    }, []);


    useEffect(() => {
        const showTestButt = document.getElementById("showTestButt");
        const counterFrame = document.getElementById("counterFrame");

        if (showTestButt) {
            showTestButt.style.display = "none";
        }
        if (counterFrame) {
            counterFrame.style.display = "block";
        }

        setTimeout(() => {

            if (showTestButt) {
                showTestButt.style.display = "block";
            }
            if (counterFrame) {
                counterFrame.style.display = "none";
            }

        }, DELAY)
    }, []);




    useEffect(() => {
        if (user) {

            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            // const isSafari = false;
            axios
                .get(
                    actualUrl,
                    {
                        params: {
                            target: isSafari ? "_blank" : "myIFRAME",
                            uuid: currentUser?.uid,
                            email: user?.email,
                            firstName: user?.inputData?.firstName,
                            lastName: user?.inputData?.lastName

                        },
                    },
                )
                .then((response) => {

                    // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

                    // console.log(isSafari);
                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);



                    const html = response.data.result.html;
                    document.getElementById("hiddenForm")?.remove();
                    const newDiv = document.createElement("div");
                    newDiv.setAttribute("id", "hiddenForm");
                    newDiv.innerHTML = html;
                    document.getElementById("myDIV")?.appendChild(newDiv);
                    if (isSafari) { return }
                    const element: HTMLElement = newDiv.querySelector("input[type=submit]") as HTMLElement;

                    element?.click();

                    // window.open("", "external", "status=0,title=0,height=600,width=800,scrollbars=1");

                });
        }
    }, [user]);


    const [show, setShow] = useState(false);

    const handleShow = () => {
        // Wake UP container
        axios.get(`https://habacus-document-analyzer-vtmgllutxq-ew.a.run.app/api/v1/wake_me_up`, {
            headers: {
                'safe': '11051985'
            },
            withCredentials: true
        }).then(
            (data) => {
                console.log(data);

            }
        );
        setShow(true);
    }
    const handleClose = () => setShow(false);

    const handleCloseAndSendData = async () => {
        try {
            setIsLoading(true);

            const emptyInputPdf = document.getElementById('emptyInputPdf');
            if (emptyInputPdf) {
                emptyInputPdf.style.visibility = 'hidden';
            }
            const wrongInputPdf = document.getElementById('wrongInputPdf');
            if (wrongInputPdf) {
                wrongInputPdf.style.visibility = 'hidden';
            }

            if (selectedFile) {
                await loadPdf();
            } else {
                if (emptyInputPdf) {
                    emptyInputPdf.style.visibility = 'visible';
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }


    };




    useEffect(() => {

        let button = <Button id="endQuizButton" variant="light" onClick={handleShow}>
            <span className="colorBlue"> Clicca per finire il TEST</span>
        </Button>;


        let buttContainer = document.getElementById("stepperButtonContainer") as HTMLElement;
        // buttContainer?.html(button);

    }, []);

    const [selectedFile, setSelectedFile] = React.useState<File | null>();

    const loadPdf = async () => {

        const formData = new FormData();
        formData.append('file', selectedFile!);

        //console.log(selectedFile);

        try {

            const res = await axios.post(`https://habacus-document-analyzer-vtmgllutxq-ew.a.run.app/api/v1/parse_pdf_motiva`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'safe': '11051985'
                },
                withCredentials: true
            });

            if (res.data) {

                const professions: string[] = [];
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i]) {
                        professions.push(res.data[i].trim());
                    }
                }

                // SAVE FILES
                try {

                    const storage = getStorage();

                    const storageRef = ref(storage, "motiva/" + currentUser.uid + ".pdf"); //  `motiva/${currentUser.uid}.pdf`

                    // 'file' comes from the Blob or File API
                    await uploadBytes(storageRef, selectedFile!);


                } catch (e) {
                    console.log(e)
                }

                // SAVE TO DB 
                try {
                    const docRef = doc(database, 'users', currentUser.uid);
                    const document = await getDoc(docRef);
                    if (!document.exists()) {
                        throw "Document does not exist!";
                    }

                    const professions_top = [];
                    if (!document.data().stages.includes('VIDEO_MEETING')) {
                        for (let i = 0; i < Math.min(3, professions.length); i++) {
                            professions_top.push(professions[i]);
                        }
                    }

                    await updateDoc(docRef, { professions: professions, professions_top: professions_top });

                } catch (e) {
                    // This will be a "Update is too big" error.
                    console.error(e);
                }

                // try {
                //     const docRef = doc(database, 'users', currentUser.uid);
                //     const constFormData = await runTransaction(database, async (transaction) => {
                //         const document = await transaction.get(docRef);
                //         if (!document.exists()) {
                //             throw "Document does not exist!";
                //         }

                //         const professions_top = [];
                //         if (!document.data().stages.includes('VIDEO_MEETING')) {
                //             for (let i = 0; i < Math.min(3, professions.length); i++) {
                //                 professions_top.push(professions[i]);
                //             }
                //         }

                //         transaction.update(docRef, { professions: professions, professions_top: professions_top });
                //     });

                // } catch (e) {
                //     // This will be a "Update is too big" error.
                //     console.error(e);
                // }


                await nextStage(doc(database, 'users', currentUser.uid));
                setShow(false);
            } else {
                alert('errore');
            }

        } catch (res) {
            console.log(res);
            const wrongInputPdf = document.getElementById('wrongInputPdf');
            if (wrongInputPdf) {
                wrongInputPdf.style.visibility = 'visible';
            }
        }
    }


    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(e?.currentTarget?.files?.[0])
    }


    const doYouHaveSomeCoach = () => {


        let doYouHaveSomeCoach = document.getElementById('doYouHaveSomeCoach');

        if (!user) {
            return null;
        } else if (user.stages?.includes('VIDEO_MEETING')) {

            return <>
                <p>
                    Tieni a portata di mano i risultati del test, ti saranno utili per la futura sessione con un formatore esperto nell&apos;orientamento.
                </p>
                <p>
                    Per la successiva sessione con il formatore esperto di orientamento, tieni a portata di mano i risultati del test, ti saranno utili.
                </p>
            </>

        } else {
            return null;
        }
    }

    return (<div className='testMotiva'>
        <Nav className='row navStepper'>
            <div className='col-4'>
            </div>
            <div className='col-4'>

            </div>
            <div className='col-4 textAlignRight my-1'>

                <Button id="endQuizButton" className="purpleButt customButt" onClick={handleShow}>
                    <div className='flexButt'>
                        <img src={floppy} alt="" />
                        <div className='saveAndGo'>
                            <div>SALVA</div>
                            <div>E PROSEGUI</div>
                        </div>
                    </div>
                </Button>

            </div>
        </Nav>
        {/* <Stepper /> */}


        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='textAlignCenter width100 colorBlue'>
                    Hai terminato il test?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='textAlignLeft'>
                <p>
                    Se hai terminato lo step, <b>carica il file pdf dei risultati</b> che hai ottenuto: queste informazioni ci aiuteranno a definire il percorso di studi più adatto a te.
                </p>

                {doYouHaveSomeCoach()}

                <form action="#" method="post" id="uploadPdfForm">
                    <div className="input-group mb-3">
                        <input type="file" className="form-control" id="motivaPDF" name='motivaPDF' onChange={handleInputChange} />
                        <label className="input-group-text" htmlFor="motivaPDF">Carica</label>
                    </div>
                </form>
                <div className="colorRed flexBetween ">
                    <div id="emptyInputPdf">
                        File vuoto
                    </div>
                    <div id="wrongInputPdf">
                        File errato
                    </div>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}
                    disabled={isLoading}>
                    Cancella
                </Button>
                <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendData}
                    disabled={isLoading}>
                    {
                        isLoading
                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            : "Carica PDF"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <h1>Il test di orientamento</h1>


        {renderLoader()}

        {renderIframe()}


    </div>
    );
};

export default TestMotiva;