import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Particles from 'react-tsparticles';
import { loadFull } from "tsparticles";
import { tsParticles } from "tsparticles-engine";
import logo from '../assets/lb.png';
import { database, login, resetpass, signup } from '../firebase';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import question from '../assets/question.png';
import { sendSupportMail } from '../utils/General';

export interface ILoginPageProps { }

const LoginPage: React.FunctionComponent<ILoginPageProps> = (props) => {

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const particlesInit = async (main: any) => {

        if (isLoaded) {
            return;
        }
        console.log(main);


        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(tsParticles);
        setIsLoaded(true);
    };

    return (
        <div>
            <header style={{ minHeight: '6vh', maxHeight: '6vh', minWidth: '100vw', backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', zIndex: '10000' }}>
                <img
                    src={logo}
                    alt="Habacus Icon"
                    style={{ minHeight: '40px', maxHeight: '40px', paddingLeft: '1vh' }}
                />
                <div>
                    <Button onClick={() => sendSupportMail()} className="transButt customButt">
                        <img src={question} alt="" />
                    </Button>

                </div>
            </header>
            <Container className='login d-flex flex-column align-items-center justify-content-center' style={{
                minHeight: '94vh', maxHeight: '95vh', minWidth: '100vw', maxWidth: '100vw'
            }}>

                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    // loaded={particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: "#003da6",
                            },
                        },
                        fpsLimit: 120,
                        interactivity: {
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "grab",
                                },
                                resize: true,
                            },
                            modes: {
                                bubble: {
                                    distance: 400,
                                    duration: 2,
                                    opacity: 0.8,
                                    size: 40,
                                },
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#c4cace",
                            },
                            links: {
                                color: "#ffffff",
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1,
                            },
                            collisions: {
                                enable: false,
                            },
                            move: {
                                direction: "none",
                                enable: true,
                                outMode: "bounce",
                                random: false,
                                speed: 1,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 1000,
                                },
                                value: 80,
                            },
                            opacity: {
                                value: 0.5,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                random: true,
                                value: 5,
                            },
                        },
                        detectRetina: true,
                    }}
                />

                <LoginPageIntern {...props} />


            </Container>
        </div >);

}

const LoginPageIntern: React.FunctionComponent<ILoginPageProps> = (props) => {
    // const STEP_NAME = 'LOGIN'
    // const STEP_NUMBER = 0;

    // const currentUser = useAuth();
    // const auth = getAuth();
    const navigate = useNavigate();
    const [authing, setAuthing] = useState(false);

    const [isLoading, setIsLoading] = useState(false);


    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');


    const [card, setCard] = React.useState<string>('register');

    const [searchParams, setSearchParams] = useSearchParams();




    const handleKeyUpSignUp = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSignUp();
        }
    }



    useEffect(() => {
        if (searchParams.get('function') == 'login') {
            setCard('login')
        }

    }, [searchParams]);


    async function handleSignUp() {
        try {
            setIsLoading(true);
            setAuthing(true);

            const q = query(collection(database, 'accepted'), where("email", "==", email?.trim().toLowerCase()));

            const querySnapshot = await getDocs(q);

            if (querySnapshot.size !== 1) {
                throw { code: 'not_accepted' };
            }

            if (querySnapshot.docs[0].data().deletedAt && querySnapshot.docs[0].data().deletedAt != null && querySnapshot.docs[0].data().deletedAt !== undefined && querySnapshot.docs[0].data().deletedAt !== '') {
                throw { code: 'not_accepted' };
            }

            const userCredentials = await signup(email?.trim().toLowerCase(), password);

            const docRef = doc(database, 'users', userCredentials.user.uid);


            const payload: any = {
                email: querySnapshot.docs[0].data().email?.trim().toLowerCase(),
                stages: querySnapshot.docs[0].data().stages,
                stage: 0,
            }

            if (querySnapshot.docs[0].data().inputData) {
                payload['inputData'] = querySnapshot.docs[0].data().inputData;
            }
            if (querySnapshot.docs[0].data().from) {
                payload['from'] = querySnapshot.docs[0].data().from;
            }

            await setDoc(docRef, payload);

            navigate('/');

        } catch (err: any) {
            console.log(err);

            if (err.code == 'auth/weak-password') {
                toast('La password deve essere di almeno 6 caratteri.', {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


            } else if (err.code == 'auth/invalid-email') {
                toast('La mail non è valida.', {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (err.code == 'auth/email-already-in-use') {
                toast('La mail è già registrata, effetuare il login.', {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }
            else if (err.code == 'not_accepted') {
                toast('La mail non è stata trovata.', {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }
            else {
                toast(err.code, {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }
            console.log(err);
            // alert(err);

        } finally {

            setAuthing(false);
            setIsLoading(false);
        }
    }

    const handleKeyUpLogIn = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleLogIn();
        }
    }



    async function handleLogIn() {
        try {

            setIsLoading(true);
            setAuthing(true);

            await login(email?.trim().toLowerCase(), password);


            navigate('/' + (searchParams.get('redirect') ?? ''));

        } catch (err: any) {
            console.log('Errore Login');
            if (err.code == 'auth/wrong-password') {
                toast('La password non è corretta.', {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


            } else if (err.code == 'auth/invalid-email') {
                toast('La mail non è corretta.', {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (err.code == 'auth/user-not-found') {
                toast('Studente non trovato.', {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


            } else {
                toast(err.code, {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } finally {

            setAuthing(false);
            setIsLoading(false);
        }
    }

    async function handleForgotPassword() {
        try {

            setIsLoading(true);
            setAuthing(true);
            await resetpass(email?.trim().toLowerCase());
        } catch (err: any) {
            console.log('Errore Forgot');
            if (err.code == 'auth/invalid-email') {
                toast('La mail non è corretta.', {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (err.code == 'auth/user-not-found') {
                toast('Studente non trovato.', {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


            } else {
                toast(err.code, {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } finally {

            setAuthing(false);
            setIsLoading(false);
        }
    }

    const loginFunc = () => {
        setCard('login')
    }

    const registerFunc = () => {
        setCard('register')
    }
    const resetFunc = () => {
        setCard('reset')
    }

    return (<>
        {
            card == 'login' ?

                <Card className='mb-3 loginCard' id="loginCard">
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ marginTop: '5vh' }}
                    />

                    <Card.Body>
                        <div className='colorBlue textAlignCenter'>
                            <h1>LOGIN</h1>
                        </div >
                        <div className="text-center mb-4">
                            Fai il login con l&apos;indirizzo email indicato per svolgere il test.
                        </div>

                        <div className='mb-2' id="auth_fields">
                            <input className='w-100 mb-2' value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='Email' />
                            <input className='w-100 mb-2' value={password} onChange={(e) => setPassword(e.target.value)} onKeyUp={handleKeyUpLogIn} type="password" placeholder='Password' />

                            <Button className='w-100 my-3' onClick={handleLogIn}
                                disabled={isLoading}>
                                {isLoading
                                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    : "Login"}
                            </Button>
                            <div className="changeCard" style={{ justifyContent: 'space-between' }}>
                                <a onClick={registerFunc}>Registrati</a>
                                <a onClick={resetFunc}>Recupera password</a>
                            </div>

                        </div>

                    </Card.Body>
                </Card>
                : null
        }

        {
            card == 'register' ?
                <Card className='mb-3 loginCard' id="registerCard">
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ marginTop: '5vh' }}
                    />

                    <Card.Body>
                        <div className='colorBlue textAlignCenter'>
                            <h1>REGISTRATI</h1>
                        </div >
                        <div className="text-center mb-4">
                            Registrati con l&apos;indirizzo email indicato per svolgere il test.
                        </div>

                        <div className='mb-2' id="auth_fields">
                            <input className='w-100 mb-2' value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='Email' />
                            <input className='w-100 mb-2' value={password} onChange={(e) => setPassword(e.target.value)}
                                onKeyPress={handleKeyUpSignUp} type="password" placeholder='Password' />
                            <Button className='w-100 mt-4 mb-2' onClick={handleSignUp} disabled={isLoading}>
                                {isLoading
                                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    : "Registrati"}
                            </Button>
                            <div className="changeCard" style={{ justifyContent: 'space-around' }}>
                                <a onClick={loginFunc}>Login</a>
                            </div>
                        </div>

                    </Card.Body>
                </Card>
                : null
        }


        {
            card == 'reset' ?


                <Card className='mb-3 loginCard' id="resetCard">
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ marginTop: '5vh' }}
                    />

                    <Card.Body>
                        <div className='colorBlue textAlignCenter'>
                            <h1>RECUPERA PASSWORD</h1>
                        </div >

                        <div className='mb-2' id="auth_fields">
                            <input className='w-100 mb-2' value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='Email' />
                            <Button onClick={handleForgotPassword} className='w-100 mt-4 mb-2'
                                disabled={isLoading}>
                                {isLoading
                                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    : "Invia Password"}
                            </Button>

                            <div className="changeCard" style={{ justifyContent: 'space-around' }}>
                                <a onClick={loginFunc}>Login</a>
                            </div>

                        </div>

                    </Card.Body>
                </Card>
                : null
        }
    </>
    );
};

export default LoginPage;
