import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap'
import logo from '../assets/lb.png';
import { database, useAuth } from '../firebase';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import teacher from '../assets/lottie/teacher.json'
import confets from '../assets/lottie/confets.json'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import floppy from '../assets/icons8-salva-100.png';
import newSt from '../assets/newSt.png';
import { professions } from '../utils/Professions';
import { Player } from '@lottiefiles/react-lottie-player';
import question from '../assets/question.png'
import icon from '../assets/ib.png'
import { fixPlayer } from '../utils/LottiePlayer';
import { sendSupportMail } from '../utils/General';


export interface ICoachPageProps { }

const CoachPage: React.FunctionComponent<ICoachPageProps> = (props) => {

    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const [email, setEmail] = useState('')
    const [profession1, setProfession1] = useState('')
    const [profession2, setProfession2] = useState('')
    const [profession3, setProfession3] = useState('')

    const optionsProfession = professions;

    const currentUser = useAuth();

    const getProfession1Value = () => {
        for (let index = 0; index < optionsProfession.length; index++) {

            if (optionsProfession[index].value === profession1) {
                return optionsProfession[index];
            }
        }
        return null
    }
    const handleChangeProfession1 = (selectedOption: any) => {
        setProfession1(selectedOption?.value);
    };
    //2
    const getProfession2Value = () => {
        for (let index = 0; index < optionsProfession.length; index++) {

            if (optionsProfession[index].value === profession2) {
                return optionsProfession[index];
            }
        }
        return null
    }
    const handleChangeProfession2 = (selectedOption: any) => {
        setProfession2(selectedOption?.value);
    };

    //3
    const getProfession3Value = () => {
        for (let index = 0; index < optionsProfession.length; index++) {

            if (optionsProfession[index].value === profession3) {
                return optionsProfession[index];
            }
        }
        return null
    }
    const handleChangeProfession3 = (selectedOption: any) => {
        setProfession3(selectedOption?.value);
    };


    const animatedComponents = makeAnimated();


    const checkForm = async () => {

        if (!email) {
            // console.log('no email no parti');
            toast("Inserisci l'indirizzo mail dello studente", {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return
        } else if (!email.includes('@') || !email.includes('.')) {
            toast('Inserisci un formato email corretto', {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        } else if (!profession1 || !profession2 || !profession3) {
            toast('Inserisci tutte e 3 le professioni consigliate', {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        } else if (profession1 == profession2 || profession2 == profession3 || profession1 == profession3) {
            toast('Le professioni consigliate devono essere diverse tra loro', {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        } else {

            handleCloseAndSendData()

        }


    }

    const sendStudentMail = (email: string) => {
        const textbody = "Ciao! \n \n Il formatore ha appena caricato sulla piattaforma Horienta le tre professioni che avete individuato durante la sessione.  \n \n Torna su horienta.habacus.com per proseguire il tuo percorso di orientamento.  \n \n Se hai bisogno di assistenza, se qualcosa non funziona, contattaci a horienta.support@habacus.com  \n \n  \n \n Team Habacus";
        window.open('mailto:' + email + '?subject=Professioni - Prosegui il tuo percorso su Horienta&body=' + encodeURIComponent(textbody));
    }

    const handleCloseAndSendData = async () => {


        const dat = collection(database, 'users');
        const q = query(dat, where('email', '==', email));

        const querySnap = await getDocs(q);
        let id = null;
        querySnap.forEach(doc => {
            // console.log(doc);
            id = doc.id;
        });
        if (id) {
            // console.log('found', id);

            // SAVE TO DB 
            // try {
            //     const docRef = doc(database, 'users', id);
            //     const constFormData = await runTransaction(database, async (transaction) => {
            //         const document = await transaction.get(docRef);
            //         if (!document.exists()) {
            //             throw "Document does not exist!";
            //         }

            //         const professions_top = [profession1, profession2, profession3];

            //         transaction.update(docRef, { professions_top: professions_top });
            //     });


            //     sendStudentMail(email);
            //     setActive(1);

            // } catch (e) {
            //     toast("Errore, contattaci!", {
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //     });

            //     console.error(e);
            // }

            try {
                const docRef = doc(database, 'users', id);
                const document = await getDoc(docRef);
                if (!document.exists()) {
                    throw "Document does not exist!";
                }

                const professions_top = [profession1, profession2, profession3];


                await updateDoc(docRef, {
                    professions_top: professions_top,
                    coach_mail: currentUser?.email,
                    coach_timestamp: new Date()
                });


                sendStudentMail(email);
                setActive(1);

            } catch (e) {
                toast("Errore, contattaci!", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                console.error(e);
            }

        } else {
            console.log('not found');
            toast("Studente non trovato, controlla che la mail sia corretta!", {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });


        }
        // console.log(querySnap);

    };

    const [active, setActive] = React.useState(0);


    const newStudent = () => {
        window.location.reload();
    }

    const player1 = React.useRef<Player>(null);
    const player2 = React.useRef<Player>(null);

    const insertComponent = () => {

        return <div id="formForDaCoach" className=''>

            <h1 className='textAlignCenter'>
                DASHBOARD FORMATORI
            </h1>

            <div className="lottieFrameCoach">

                <Player
                    loop
                    autoplay
                    src={teacher}
                    style={{ maxHeight: '550px', maxWidth: '550px' }}
                    ref={player1}
                    onEvent={(e) => { if (e === 'load') { fixPlayer(player1); } }}
                >
                </Player>

            </div>

            <h3 className='textAlignCenter colorBlue'>
                La sessione di orientamento con lo studente è terminata.
            </h3>

            <p>
                Compila la scheda dello studente in base ai risultati definiti insieme durante la sessione.
            </p>
            <p>
                Istruzioni:
            </p>

            <ol>
                <li>
                    Inserisci <b>l&apos;indirizzo email dello studente</b>
                </li>
                <li>
                    Seleziona <b>le prime 3 professioni</b> individuate nell&apos;ordine definito con lo studente. Le 3 professioni devono essere diverse tra loro
                </li>
                <li>
                    Clicca su “Salva e invia” per condividere ad Habacus i risultati
                </li>
            </ol>




            <Form>
                <div className='row mb-3 '>
                    <Form.Group className="col-12  mb-3 mb-md-0" >
                        <Form.Label>Email Studente</Form.Label>
                        <Form.Control type="email" placeholder="Inserisci email" value={email} onChange={event => setEmail(event.target.value)} />
                    </Form.Group>
                </div>

                <div className='row mb-3 '>
                    <Form.Group className="col-12  mb-3 mb-md-0" > {/* controlId="formBasicDate" */}
                        <Form.Label>Professione 1</Form.Label>
                        <Select
                            options={optionsProfession}
                            components={animatedComponents}
                            isClearable={true}
                            placeholder={'Professione 1'}
                            value={getProfession1Value()}
                            onChange={handleChangeProfession1}
                        />
                    </Form.Group>
                </div>
                <div className='row mb-3 '>
                    <Form.Group className="col-12  mb-3 mb-md-0" > {/* controlId="formBasicDate" */}
                        <Form.Label>Professione 2</Form.Label>
                        <Select
                            options={optionsProfession}
                            components={animatedComponents}
                            isClearable={true}
                            placeholder={'Professione 2'}
                            value={getProfession2Value()}
                            onChange={handleChangeProfession2}
                        />
                    </Form.Group>
                </div>
                <div className='row mb-3 '>
                    <Form.Group className="col-12  mb-3 mb-md-0" > {/* controlId="formBasicDate" */}
                        <Form.Label>Professione 3</Form.Label>
                        <Select
                            options={optionsProfession}
                            components={animatedComponents}
                            isClearable={true}
                            placeholder={'Professione 3'}
                            value={getProfession3Value()}
                            onChange={handleChangeProfession3}
                        />
                    </Form.Group>
                </div>
                <div className='textAlignRight mb-3'>
                    {/* <Button className="purpleButt" onClick={handleShow}> */}
                    <Button className="purpleButt customButt" onClick={checkForm}>
                        <div className='flexButt'>
                            <img src={floppy} alt="" />
                            <div className='saveAndGo'>
                                <div>SALVA</div>
                                <div>E PROSEGUI</div>
                            </div>
                        </div>
                    </Button>
                </div>
            </Form>
        </div>
    }

    const thanksComponent = () => {
        return <div id="thanksToDaCoach" className='textAlignCenter'>
            <h1>
                DASHBOARD FORMATORI
            </h1>

            <div className="lottieFrameCoach">
                <Player
                    autoplay
                    loop
                    src={confets}
                    style={{ maxHeight: '550px', maxWidth: '550px' }}
                    ref={player2}
                    onEvent={(e) => { if (e === 'load') { fixPlayer(player2); } }}
                >
                </Player>
            </div>

            <p>
                Dati inviati con successo.
            </p>
            <p>
                Grazie per aver compilato la scheda dello studente.
            </p>

            <div className='textAlignCenter mb-3'>
                {/* <Button className="purpleButt" onClick={handleShow}> */}
                <Button className="purpleButt customButt" onClick={newStudent}>
                    <div className='flexButt'>
                        <img src={newSt} alt="" />
                        <div className='saveAndGo'>
                            <div>NUOVO</div>
                            <div>STUDENTE</div>
                        </div>
                    </div>
                </Button>
            </div>

        </div>
    }


    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{
                    marginTop: '5vh'
                }}
            />


            {/* 
            <header style={{ minHeight: '6vh', maxHeight: '6vh', width: '100vw', backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', zIndex: '10000' }}>
                <img
                    src={logo}
                    alt="Habacus Icon"
                    style={{ minHeight: '4vh', maxHeight: '4vh', paddingLeft: '1vh' }}
                />
            </header>
            */}

            <header style={{ minHeight: '6vh', maxHeight: '6vh', width: '100vw', backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', zIndex: '10000' }}>
                <img
                    src={logo}
                    alt="Habacus Icon"
                    style={{ minHeight: '4vh', maxHeight: '4vh', paddingLeft: '1vh' }}
                    className="logoH"
                />
                <img
                    src={icon}
                    alt="Habacus Icon"
                    style={{ minHeight: '4vh', maxHeight: '4vh', paddingLeft: '1vh' }}
                    className="iconH"
                />

                <div className='miniStepper nav'>
                </div>
                <div>
                    {/* <Button onClick={() => previousStage(doc(database, 'users', currentUser.uid))} >&larr;</Button>
                        <Button onClick={async () => await nextStage(doc(database, 'users', currentUser.uid))}>&rarr;</Button> */}
                    <Button onClick={() => sendSupportMail()} className="transButt customButt">
                        <img src={question} alt="" />
                    </Button>

                </div>
            </header>

            <Container className='coach' style={{
                minHeight: '94vh', maxHeight: '95vh', minWidth: '100vw', maxWidth: '100vw', marginTop: '3vh', paddingLeft: '15vh', paddingRight: '15vh'
            }}>

                {
                    !active ?

                        insertComponent() :
                        thanksComponent()
                }
            </Container>
        </>
    );
};

export default CoachPage;