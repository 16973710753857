import React, { useEffect, useState } from 'react';
import { getAuth, } from 'firebase/auth';
import { Button, Modal, Nav } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import { database, useAuth } from '../firebase';
import { nextStage } from '../utils/Stages';


export interface IMiniStepperProps { }

const MiniStepper: React.FunctionComponent<IMiniStepperProps> = (props) => {
    const currentUser = useAuth();

    const getUser = async () => {
        if (currentUser != null) {
            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            setUser(document.data());
        }
    }

    useEffect(
        () => {
            getUser()
        }, [currentUser]
    );
    const [user, setUser] = useState<any>();

    if (!user) {
        return <div className='ballsContainer' id="ballsContainer">
        </div>

    }

    const stages = user.stages;
    let stage = user.stage;
    let fakeStage = -1;

    const stagesNoSurvey: (string | string[])[] = [];

    let stageFound = false;

    stages.forEach((element: string | string[]) => {

        if (element.includes('_SURVEY')) {
            if (element == stages[stage]) {
                stageFound = true;
            }
            return;

        } else {
            if (!stageFound) {
                fakeStage++;
            }

            if (element == stages[stage]) {
                stageFound = true;
            }

            stagesNoSurvey.push(element);
        }
    });

    const stagesLength = stages.length;

    const stagesNoSurveyLength = stagesNoSurvey.length;


    // if (stages[stage].includes('_SURVEY')) {
    //     stage--;
    // }

    let ballsContainer = document.getElementById("ballsContainer") as HTMLElement;
    ballsContainer.innerHTML = '';

    for (let i = 0; i < stagesNoSurveyLength; i++) {
        let div = document.createElement("div");

        ballsContainer?.append(div);
        div.innerHTML = i + 1 + '';
        div.style.color = '#ffff';

        if (i === fakeStage) {
            div.style.backgroundColor = '#003da6';
        } else {
            div.style.backgroundColor = '#c0c0c0';
        }
    }




    return <div className='ballsContainer' id="ballsContainer">
    </div>

};

export default MiniStepper;