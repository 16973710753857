import React, { useEffect, useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { Button, Card, Container, Modal, Nav } from 'react-bootstrap';
import logo from '../assets/lb.png'
import { collection, DocumentReference, onSnapshot, updateDoc, doc, addDoc, getDoc } from 'firebase/firestore';
import { database, useAuth } from '../firebase';
import ral from '../assets/ral.png'
import school from '../assets/school.png'
import ChartComponent from '../components/ChartComponent';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import PDFFile from '../components/PDFFile';
import { nextStage } from '../utils/Stages';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import jsPDF from 'jspdf';
import next from '../assets/next.png'
import down from '../assets/down.png'
import star from '../assets/lottie/star.json'
import { Player } from '@lottiefiles/react-lottie-player';
import { schoolFees, schoolWebsite, schoolLogos } from '../utils/Schools';
import html2canvas from 'html2canvas';
import { fixPlayer } from '../utils/LottiePlayer';


export interface IResultProps { }

const Result: React.FunctionComponent<IResultProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        try {
            window.scroll({ top: 0, left: 0, behavior: 'smooth', });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, []);

    const currentUser = useAuth();

    const getUser = async () => {
        if (currentUser != null) {
            const docRef = doc(database, 'users', currentUser.uid);
            const document = await getDoc(docRef);
            setUser(document.data());
        }
    }

    useEffect(
        () => {
            getUser()
        }, [currentUser]
    );
    const [user, setUser] = useState<any>();




    const [show, setShow] = useState(false);

    const handleCloseAndSendData = async () => {
        try {
            setIsLoading(true);
            await nextStage(doc(database, 'users', currentUser.uid));
            setShow(false);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const player = React.useRef<Player>(null);



    // const createPdf = async () => {
    //     console.log('ciao');
    //     const pdfDoc = await PDFDocument.create()

    //     const page = pdfDoc.addPage()
    //     const { width, height } = page.getSize()
    //     const fontSize = 30
    //     page.drawText('Creating PDFs in JavaScript is awesome!', {
    //         x: 50,
    //         y: 50,
    //         size: fontSize,
    //         color: rgb(0, 0.53, 0.71)
    //     })

    //     const pdfBytes = await pdfDoc.save()

    //     console.log('asdo');


    // }


    //THIS VERSION IS PERFECT FOR THE FIRST PAGA BUT DOESNT WRITE ANYTHING IN THE SECOND PAGE
    const clickToDownloadPdf = async () => {
        try {
            setIsLoading(true);

            const HTML_Width = document.getElementById("resultContent")!.offsetWidth;
            const HTML_Height = document.getElementById("resultContent")!.offsetHeight;

            console.log(HTML_Width + "  " + HTML_Height);
            const top_left_margin = 15;
            const PDF_Width = HTML_Width + (top_left_margin * 2);
            // const PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
            const PDF_Height = (HTML_Height * 1.1) + (top_left_margin * 2);
            const canvas_image_width = HTML_Width;
            const canvas_image_height = HTML_Height;

            const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;


            const canvas = await html2canvas(document.getElementById("resultContent")!, { allowTaint: true });

            canvas.getContext('2d');

            console.log(canvas.height + "  " + canvas.width);


            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


            for (let i = 1; i <= totalPDFPages; i++) {
                pdf.addPage([PDF_Width, PDF_Height]);
                pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
            }

            pdf.save("HabacusHorienta.pdf");

        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
        // const canvas = await html2canvas(document.getElementById("resultContent")!);

        // var imgData = canvas.toDataURL('image/png');


        // var imgWidth = 210;
        // var pageHeight = 295;
        // var imgHeight = canvas.height * imgWidth / canvas.width;
        // var heightLeft = imgHeight;
        // var doc = new jsPDF('p', 'mm');

        // var position = 10;

        // doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        // heightLeft -= pageHeight;
        // heightLeft -= position;

        // while (heightLeft >= 0) {
        //     position = heightLeft - imgHeight;
        //     doc.addPage();
        //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        //     heightLeft -= pageHeight;
        // }

        // doc.save();

    }

    // const clickToDownloadPdf = async () => {

    //     // const divContents = document.getElementById("resultContent")!.innerHTML;
    //     // const printWindow = window.open('', '', 'height=10080,width=1920')!;
    //     // printWindow.document.write('<html><head><title>Html to PDF</title>');
    //     // printWindow.document.write('</head><body >');
    //     // printWindow.document.write(divContents);
    //     // printWindow.document.write('</body></html>');
    //     // printWindow.document.close();

    //     const canvas = await html2canvas(document.getElementById("resultContent")!);

    //     var imgData = canvas.toDataURL('image/png');


    //     var imgWidth = 210;
    //     var pageHeight = 295;
    //     var imgHeight = canvas.height * imgWidth / canvas.width;
    //     var heightLeft = imgHeight;
    //     var doc = new jsPDF('p', 'mm');

    //     var position = 10;

    //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //     heightLeft -= position;

    //     while (heightLeft >= 0) {
    //         position = heightLeft - imgHeight;
    //         doc.addPage();
    //         doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;
    //     }

    //     doc.save();

    // }

    //LETS TRY AGAIN
    // const clickToDownloadPdf = async () => {
    //     const canvas = await html2canvas(document.getElementById("resultContent")!);

    //     var imgData = canvas.toDataURL('image/png');

    //     var imgWidth = 210;
    //     var pageHeight = 295;
    //     var imgHeight = canvas.height * imgWidth / canvas.width;
    //     var heightLeft = imgHeight;

    //     var doc = new jsPDF('p', 'mm');
    //     var position = 0;
    //     // console.log(canvas.height);

    //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;

    //     while (heightLeft >= 0) {
    //         position = heightLeft - imgHeight;
    //         doc.addPage();
    //         doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;
    //     }

    //     doc.save('file.pdf');

    // }


    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    const mapPaths: any = {
        'fastest': { title: 'Percorso più veloce' },
        'most_frequent': { title: 'Percorso più frequente' },
        'premium': { title: 'Percorso sky is the limit' },
    }

    const course_types = [
        { 'value': 'its', 'label': 'Corso ITS' },
        { 'value': 'vocational', 'label': 'Corso Professionalizzante' },
        { 'value': 'lt', 'label': 'Laurea Triennale' },
        { 'value': 'm1', 'label': 'Master Primo Livello' },
        { 'value': 'lm', 'label': 'Laurea Magistrale' },
        { 'value': 'lmcu', 'label': 'Laurea A Ciclo Unico' },
        { 'value': 'm2', 'label': 'Master Secondo Livello' },
        { 'value': 'phd', 'label': 'Dottorato' },
    ];

    const professions_top = user?.professions_top;
    const professionals_paths = user?.professionals_paths;

    if (!professionals_paths) return null

    const exists = (text: any) => {
        return text !== undefined && text !== null && text !== 'nan' && text !== '';
    }

    const renderPathItem = (cont: number, key: string, actualPath: any, course_type: any, schoolLower: string) => {

        const style: any = {};
        if (cont > 1) {
            style['paddingTop'] = '1rem';
        }
        return (
            <div key={key + '_' + course_type.label + '_' + actualPath.course}>
                <div className="row cardRow">
                    <div className="col-2 centerFlex">
                        {
                            schoolLogos[schoolLower] ? <img src={schoolLogos[schoolLower]} alt="" /> : <img src={school} alt="" />
                        }
                    </div>
                    <div className="col-10 cardRowTextContent labelCourseType" style={style}>
                        <span>
                            <b>{course_type.label}</b>
                        </span>
                    </div>
                </div>
                {
                    exists(actualPath.course) ?
                        <div className="row cardRow">
                            <div className="col-2 centerFlex">

                            </div>
                            <div className="col-10 cardRowTextContent containerTitleValue">
                                <span className='titleElemePath text-muted'>
                                    Tipo di corso
                                </span>
                                <span className='valueElemePath'>
                                    {actualPath.course}
                                </span>

                            </div>
                        </div>
                        : null
                }


                {
                    exists(actualPath.duration) ?
                        <div className="row cardRow">
                            <div className="col-2 centerFlex">
                            </div>
                            <div className="col-10 cardRowTextContent containerTitleValue" >
                                <span className='titleElemePath text-muted'>
                                    Durata
                                </span>
                                <span className='valueElemePath' style={{ textTransform: 'lowercase' }}>
                                    {actualPath.duration} anni
                                </span>

                            </div>
                        </div>
                        : null
                }

                {
                    exists(actualPath.school) ?
                        <div className="row cardRow">
                            <div className="col-2 centerFlex">
                                {/* <img src={school} alt="" /> */}
                            </div>
                            <div className="col-10 cardRowTextContent containerTitleValue">
                                <span className='titleElemePath text-muted'>
                                    Nome Ente
                                </span>
                                <span className='valueElemePath'>
                                    {actualPath.school}
                                </span>

                            </div>
                        </div>
                        : null
                }

                {
                    exists(actualPath.place) ?
                        <div className="row cardRow">
                            <div className="col-2 centerFlex">
                            </div>
                            <div className="col-10 cardRowTextContent containerTitleValue">
                                <span className='titleElemePath text-muted'>
                                    Città
                                </span>
                                <span className='valueElemePath'>
                                    {actualPath.place}
                                </span>

                            </div>
                        </div>
                        : null
                }

                {
                    schoolWebsite[schoolLower] ?
                        <div className="row cardRow">
                            <div className="col-2 centerFlex">
                            </div>
                            <div className="col-10 cardRowTextContent containerTitleValue">
                                <span className='titleElemePath text-muted'>
                                    Sito WEB
                                </span>
                                <span className='valueElemePath' style={{ textTransform: 'lowercase' }}>
                                    <a target="_blank" href={"https://" + schoolWebsite[schoolLower]}>{schoolWebsite[schoolLower]}</a>
                                </span>

                            </div>
                        </div>
                        : null
                }

                {

                    schoolFees[schoolLower] ?
                        <div className="row cardRow">
                            <div className="col-2 centerFlex">
                            </div>
                            <div className="col-10 cardRowTextContent containerTitleValue">
                                <span className='titleElemePath text-muted'>
                                    Rata Massima Annuale
                                </span>
                                <span className='valueElemePath'>
                                    {
                                        schoolFees[schoolLower]
                                    }&euro;
                                </span>
                            </div>
                        </div>
                        : null

                }


            </div >
        );
    }

    const renderPath = (key: string, path: any) => {


        let cont = 0;
        let feeTotal = 0;

        const alreadyDone: any[] = [];

        return <Card className="col-12 col-lg-4">
            <Card.Body>
                <Card.Title className='colorBlue textAlignCenter'>
                    {mapPaths[key].title}
                </Card.Title>
                <div className='r_parent'>
                    <div className="r_child">
                        {
                            course_types.map(course_type => {
                                if (!path[course_type.value]) {
                                    return null
                                }
                                const returnValue = [];


                                const actualPath = path[course_type.value];
                                const schoolLower = actualPath.school.toLowerCase();
                                if (schoolFees[schoolLower] && actualPath.duration) {
                                    feeTotal += (schoolFees[schoolLower] * actualPath.duration)
                                }

                                if (course_type.value === 'lm' && !alreadyDone.includes('lt')) {
                                    cont++;
                                    alreadyDone.push(course_type.value);
                                    returnValue.push(renderPathItem(cont, key, { 'course': 'Propedeutico alla Laurea Magistrale' }, { 'value': 'lt', 'label': 'Laurea Triennale' }, 'propedeutica'));
                                } else if (course_type.value === 'm1' && !alreadyDone.includes('lt')) {
                                    cont++;
                                    alreadyDone.push(course_type.value);
                                    returnValue.push(renderPathItem(cont, key, { 'course': 'Propedeutico al Master di Primo Livello' }, { 'value': 'lt', 'label': 'Laurea Triennale' }, 'propedeutica'));
                                } else if (course_type.value === 'm2') {
                                    if (!alreadyDone.includes('lt')) {
                                        cont++;
                                        alreadyDone.push(course_type.value);
                                        returnValue.push(renderPathItem(cont, key, { 'course': 'Propedeutico alla Laurea Magistrale' }, { 'value': 'lt', 'label': 'Laurea Triennale' }, 'propedeutica'));
                                    }
                                    if (!alreadyDone.includes('lm')) {
                                        cont++;
                                        alreadyDone.push(course_type.value);
                                        returnValue.push(renderPathItem(cont, key, { 'course': 'Propedeutico al Master di Secondo Livello' }, { 'value': 'lm', 'label': 'Laurea Magistrale' }, 'propedeutica'));
                                    }
                                } else if (course_type.value === 'phd') {
                                    if (!alreadyDone.includes('lt')) {
                                        cont++;
                                        alreadyDone.push(course_type.value);
                                        returnValue.push(renderPathItem(cont, key, { 'course': 'Propedeutico al Dottorato' }, { 'value': 'lt', 'label': 'Laurea Triennale' }, 'propedeutica'));
                                    }
                                    if (!alreadyDone.includes('lm')) {
                                        cont++;
                                        alreadyDone.push(course_type.value);
                                        returnValue.push(renderPathItem(cont, key, { 'course': 'Propedeutico al Dottorato' }, { 'value': 'lm', 'label': 'Laurea Magistrale' }, 'propedeutica'));
                                    }
                                }


                                cont++;
                                alreadyDone.push(course_type.value);
                                returnValue.push(renderPathItem(cont, key, actualPath, course_type, schoolLower));
                                return returnValue;
                            })

                        }
                    </div>
                    <div style={{ height: '100%' }} className="row">
                        <div className="col-2"></div>
                        <div className="col-10" style={{ height: '100%', borderLeft: '1px solid #9D2085' }} ></div>

                    </div>

                    {/* 
                    {
                        (feeTotal > 0) ? <div className="row cardRow ral">
                            <div className="col-2 centerFlex">
                                <img src={ral} alt="" />
                            </div>
                            <div className="col-10 ">
                                Costo totale: <span>{feeTotal}</span>&euro;
                            </div>
                        </div>
                            : ''
                    }
                    */}

                    {
                        (path['ral_0'] > 0) ? <div className="row cardRow ral">
                            <div className="col-2 centerFlex">
                                <img src={ral} alt="" />
                            </div>
                            <div className="col-10 ">
                                RAL Attesa al 1° Anno: <span>{path['ral_0']}</span>&euro;
                            </div>
                        </div>
                            : ''
                    }

                </div>
            </Card.Body>

        </Card >

    }

    const renderProfession = (title: string, profession: any) => {
        const fastest = renderPath('fastest', profession['fastest']);
        const most_frequent = renderPath('most_frequent', profession['most_frequent']);
        const premium = renderPath('premium', profession['premium']);
        return <div className="row my-5" key={title}>
            <h2 className='colorBlue textAlignCenter'>
                {/* {mapPaths[key].title} */} {title}
            </h2>
            {fastest}
            {most_frequent}
            {premium}
        </div>

    }

    return (
        <div className='result'>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='textAlignCenter width100  colorBlue'>
                        Un ultimo sforzo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='textAlignLeft'>
                    <p>
                        Sei quasi arrivato alla fine di questo test e ci teniamo a ringraziarti per aver contribuito con i tuoi feedback al miglioramento del nostro servizio. Grazie!
                    </p>
                    <p>
                        Hai già <b>scaricato i percorsi di studio</b> individuati per te?<br />
                        Se hai già fatto il download, vai avanti, altrimenti clicca su annulla, il bottone di download è al fondo della pagina precedente.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}
                        disabled={isLoading}>
                        Indietro
                    </Button>
                    <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendData}
                        disabled={isLoading}>
                        {
                            isLoading
                                ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                : "Avanti"
                        }
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className='resultContent' id="resultContent">


                <div style={{ textAlign: 'center', paddingBottom: '1rem' }}>
                    <img
                        src={logo}
                        alt="Habacus Icon"
                        style={{ minHeight: '40px', maxHeight: '40px', paddingLeft: '1vh' }}
                    />
                </div>
                <h1 className=''>
                    I migliori percorsi di studio per te
                </h1>
                <div className="lottieFrame">

                    <Player
                        autoplay
                        loop
                        src={star}
                        style={{ maxHeight: '350px', maxWidth: '350px' }}
                        ref={player}
                        onEvent={(e) => { if (e === 'load') { fixPlayer(player); } }}
                    >
                    </Player>

                </div>
                <p className='textAlignLeft'>
                    Abbiamo incrociato <b>migliaia di dati relativi a percorsi formativi e percorsi professionali</b> per capire, in base alle informazioni che ci hai condiviso, quali siano i migliori percorsi di studio per te:
                </p>
                <ul>
                    <li>
                        <b>Percorso più frequente</b>: è quello <b>più seguito</b> nella tua città per raggiungere la professione identificata
                    </li>
                    <li>
                        <b>Percorso più veloce</b>: è quello <b>più rapido</b>, permette di raggiungere la professione nel minor tempo
                    </li>
                    <li>
                        <b>Percorso sky is the limit</b>: è quello <b>più sfidante</b>, una volta intrapresa la professione, ti permetterà di guadagnare di più
                    </li>
                </ul>
                <p className='textAlignLeft'>
                    Per ogni percorso di studio abbiamo indicato <b>l&apos;ente formativo e la tipologia di corso</b> e <b>la retribuzione media al primo anno di lavoro</b>. I due aspetti sono correlati e, nel momento in cui si decide di investire nella propria formazione, riteniamo importante avere una proiezione di quello che il mondo del lavoro riserva per poter ponderare e pianificare al meglio l&apos;esperienza di studio.
                </p>

                <p className='textAlignLeft'>
                    Ecco a te i 3 percorsi di studio per ognuna delle professioni individuate per te.
                </p>
                <br />
                <br />

                {
                    professions_top.map((profession: any) => {
                        return renderProfession(profession, professionals_paths[profession]);
                    })
                }
                <p className='textAlignRight text-muted'>
                    *RAL Retribuzione Annuale Lorda, cioè comprensiva di tasse.
                </p>



            </div>

            <div className="resultContent">


                <div className='flexRight'>
                    <div className="textAlignLeft mb-3 mx-3">

                        <Button className="blueButt customButt" onClick={clickToDownloadPdf}
                            disabled={isLoading}>
                            {
                                isLoading
                                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    : (<div className='flexButt'>
                                        <img src={down} alt="" />
                                        <div className='saveAndGo'>
                                            <div>SCARICA I</div>
                                            <div>RISULTATI</div>
                                        </div>
                                    </div>)
                            }

                        </Button>
                    </div>

                    <div className="textAlignRight mb-3">

                        <Button className="purpleButt customButt" onClick={handleShow}>
                            <div className='flexButt'>
                                <img src={next} alt="" />
                                <div className='saveAndGo flexCenter'>
                                    <div>PROSEGUI</div>
                                </div>
                            </div>
                        </Button>
                    </div>

                </div>
            </div>


        </div >);


};

export default Result;